import { FormattedMessage } from "react-intl";
import { Button, useModal } from "ui";
import { NextButton, WizardForm } from "../../../../../components/Wizard";
import { buttonNames } from "../../../../messages/commonMessages";

import { FormLayout } from "form";
import { useCallback } from "react";
import { OnboardProvidersModal } from "../../../../../providers/ConnectProvider/components/OnboardProvidersModal";
import { type OnboardProvidersType } from "../../../../types";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import Scope from "./Scope";
import SecurityCapabilitiesAndPermissions from "./SecurityCapabilitiesAndPermissions";

type GetStartedProps = {
  context: {
    isEdit: boolean;
    fromProvidersPage?: OnboardProvidersType;
    closeModal: () => void;
  };
};

export default function GetStarted({
  context: { closeModal: closeOnboardingWizard, isEdit, fromProvidersPage },
}: GetStartedProps) {
  const { openModal: openCloudSelectionModal } = useModal(CloudSelection);
  const { openModal: openOnboardProvidersModal } = useModal(
    OnboardProvidersModal,
  );

  const handleClick = useCallback(() => {
    closeOnboardingWizard();
    fromProvidersPage
      ? openOnboardProvidersModal({ selectedProvider: fromProvidersPage })
      : openCloudSelectionModal();
  }, [
    closeOnboardingWizard,
    openCloudSelectionModal,
    fromProvidersPage,
    openOnboardProvidersModal,
  ]);

  return (
    <WizardForm
      actions={
        <>
          {!isEdit && (
            <Button onClick={handleClick}>
              <FormattedMessage {...buttonNames.previous} />
            </Button>
          )}
          <NextButton />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <Scope isEdit={isEdit} />
        <SecurityCapabilitiesAndPermissions />
      </FormLayout>
    </WizardForm>
  );
}
