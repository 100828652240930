import {
  keepPreviousData,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";
import { type z } from "zod";
import {
  getLicense,
  licenseKeys,
  type GetLicenseResponse,
} from "../../requests";

export type LicenseResponse = z.infer<typeof GetLicenseResponse>;
/**
 * Provides the license data
 */
export function useLicenseInfo(
  queryOptions?: Pick<
    UseQueryOptions<
      LicenseResponse,
      unknown,
      LicenseResponse,
      ReturnType<typeof licenseKeys.license>
    >,
    "refetchOnMount" | "placeholderData" | "staleTime" | "enabled" | "select"
  >,
) {
  const query = useQuery({
    queryKey: licenseKeys.license(),
    queryFn: getLicense,
    refetchOnMount: false,
    placeholderData: keepPreviousData,
    staleTime: 3 * 60 * 1000, // 3 minutes
    ...queryOptions,
  });

  return query;
}
