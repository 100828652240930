import {
  ConnectedDownloadButton,
  ConnectedPaginator,
  ConnectedTable,
  ConnectedTableFilter,
  TableProvider,
  type ColumnOptions,
} from "table";

import { ParentSize } from "@visx/responsive";
import { FormattedMessage, useIntl, type IntlShape } from "react-intl";
import { type AccountGroupInfosType } from "requests";
import { ALIBABA_CLOUD, IBM, OCI } from "../../../../../../constants";
import {
  useGetCloudAccountsCas,
  useGetCloudAccountsRest,
} from "../../../../../../hooks";
import { type CloudType } from "../../../../../../types";
import GroupNameCell from "./cellRenderers/GroupNameCell";

type TableType = {
  accountId: string;
  cloudType: CloudType;
};

const rowsLimit = 25;

const columns = (
  intl: IntlShape,
  totalWidth: number,
): ColumnOptions<AccountGroupInfosType>[] =>
  totalWidth > 0
    ? [
        {
          accessor: "groupName",
          Cell: GroupNameCell,
          exportHeader: () =>
            intl.formatMessage({
              defaultMessage: "Name",
              id: "4/Q9K0",
              description: "Name Column header in Data Inventory",
            }),
          Header: (
            <FormattedMessage
              defaultMessage="Name"
              id="DnHfQ2"
              description="label for Name"
            />
          ),
          width: totalWidth / 2,
        },
        {
          accessor: "lastModifiedBy",
          exportHeader: () =>
            intl.formatMessage({
              defaultMessage: "Last Modified By",
              id: "8w4LN+",
              description: "Last Modified By Column header in Data Inventory",
            }),
          Header: (
            <FormattedMessage
              defaultMessage="Last Modified By"
              id="hZ5KM9"
              description="Last Modified By"
            />
          ),

          width: totalWidth / 2.2,
        },
      ]
    : [];

export default function Table({ accountId, cloudType }: TableType) {
  const intl = useIntl();
  const { data: casAccountData, isLoading: isCasAccountLoading } =
    useGetCloudAccountsCas({
      accountId,
      cloudType,
      includeGroupInfo: true,
      options: {
        enabled: ![ALIBABA_CLOUD, OCI].includes(cloudType),
      },
    });

  // TODO: change this to CAS call
  const { data: restAccountData, isLoading: isRestAccountLoading } =
    useGetCloudAccountsRest({
      accountId,
      cloudType,
      includeGroupInfo: "true",
      options: {
        enabled: [ALIBABA_CLOUD, OCI, IBM].includes(cloudType),
      },
    });

  let accountGroups: AccountGroupInfosType[] = [];
  if ([ALIBABA_CLOUD, OCI, IBM].includes(cloudType?.toLowerCase())) {
    accountGroups = restAccountData?.accountGroupInfos ?? [];
  } else {
    accountGroups = casAccountData?.accountGroupInfos ?? [];
  }

  return (
    <ParentSize>
      {({ width }) => (
        <TableProvider
          isLoading={isCasAccountLoading || isRestAccountLoading}
          columns={columns(intl, width)}
          data={accountGroups}
          enablePagination
        >
          <div className="flex justify-end space-x-2">
            {Number(accountGroups?.length) > rowsLimit && (
              <ConnectedTableFilter />
            )}
            <ConnectedDownloadButton
              fileName={() =>
                `associatedAccountGroups-${new Date().toISOString()}.csv`
              }
            />
          </div>
          <div
            className="my-2 flex-auto overflow-auto"
            style={{ maxHeight: "54vh" }}
          >
            <ConnectedTable appearance="secondary" />
          </div>

          <div className="flex flex-none justify-end">
            {Number(accountGroups?.length) > rowsLimit && (
              <ConnectedPaginator />
            )}
          </div>
        </TableProvider>
      )}
    </ParentSize>
  );
}
