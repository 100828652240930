import { z } from "zod";

import { type QueryFunctionContext } from "@tanstack/react-query";

import { jsonApi } from "../../../apis";
import {
  ComplianceRequirementRequestSchema,
  ComplianceRequirementSchema,
  ComplianceRequirementsSchema,
  ComplianceSectionRequestSchema,
  ComplianceSectionsSchema,
  ComplianceStandardRequestSchema,
  ComplianceStandardSchema,
  ComplianceStandardsSchema,
} from "./schemas";

type ComplianceStandardRequest = z.infer<
  typeof ComplianceStandardRequestSchema
>;
type ComplianceRequirementRequest = z.infer<
  typeof ComplianceRequirementRequestSchema
>;
type ComplianceSectionRequest = z.infer<typeof ComplianceSectionRequestSchema>;

export const complianceStandardsKeys = {
  all: [{ scope: "complianceCRUD" }] as const,
  standards: () =>
    [
      {
        ...complianceStandardsKeys.all[0],
        entity: "standard",
      },
    ] as const,
  standard: ({ standardId }: { standardId: string }) =>
    [
      {
        ...complianceStandardsKeys.standards()[0],
        standardId,
      },
    ] as const,
  requirement: ({ requirementId }: { requirementId: string }) =>
    [
      {
        ...complianceStandardsKeys.all[0],
        entity: "requirement",
        requirementId,
      },
    ] as const,
  requirementsForStandard: ({ standardId }: { standardId: string }) =>
    [
      {
        ...complianceStandardsKeys.all[0],
        entity: "requirement",
        standardId,
      },
    ] as const,
  sectionsForRequirement: ({ requirementId }: { requirementId: string }) =>
    [
      {
        ...complianceStandardsKeys.all[0],
        entity: "section",
        requirementId,
      },
    ] as const,
} as const;

export const getComplianceStandards = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof complianceStandardsKeys)["standards"]>
  >,
) => {
  return jsonApi({
    path: "compliance",
    requestSchema: z.void(),
    responseSchema: ComplianceStandardsSchema,
  });
};

export const getComplianceStandard = async ({
  queryKey: [{ standardId }],
}: QueryFunctionContext<
  ReturnType<(typeof complianceStandardsKeys)["standard"]>
>) => {
  return jsonApi({
    path: `compliance/${standardId}`,
    requestSchema: z.void(),
    responseSchema: ComplianceStandardSchema,
  });
};

export const getComplianceRequirement = async ({
  queryKey: [{ requirementId }],
}: QueryFunctionContext<
  ReturnType<(typeof complianceStandardsKeys)["requirement"]>
>) => {
  return jsonApi({
    path: `compliance/requirement/${requirementId}`,
    requestSchema: z.void(),
    responseSchema: ComplianceRequirementSchema,
  });
};

export const getComplianceRequirementsForStandard = async ({
  queryKey: [{ standardId }],
}: QueryFunctionContext<
  ReturnType<(typeof complianceStandardsKeys)["requirementsForStandard"]>
>) => {
  return jsonApi({
    path: `compliance/${standardId}/requirement`,
    requestSchema: z.void(),
    responseSchema: ComplianceRequirementsSchema,
  });
};

export const getComplianceSectionsForRequirement = async ({
  queryKey: [{ requirementId }],
}: QueryFunctionContext<
  ReturnType<(typeof complianceStandardsKeys)["sectionsForRequirement"]>
>) => {
  return jsonApi({
    path: `compliance/${requirementId}/section`,
    requestSchema: z.void(),
    responseSchema: ComplianceSectionsSchema,
  });
};

export const newComplianceStandard = async ({
  name,
  description,
}: ComplianceStandardRequest) => {
  return jsonApi({
    path: "compliance",
    body: { name, description },
    config: {
      method: "post",
    },
    requestSchema: ComplianceStandardRequestSchema,
    responseSchema: z.string().optional(),
  });
};

export const cloneComplianceStandard = async (
  standardId: string,
  body: ComplianceStandardRequest,
) => {
  return jsonApi({
    path: `compliance/clone/${standardId}`,
    body,
    config: {
      method: "post",
    },
    requestSchema: ComplianceStandardRequestSchema,
    responseSchema: z.string(),
  });
};

export const editComplianceStandard = async (
  standardId: string,
  body: ComplianceStandardRequest,
) => {
  return jsonApi({
    path: `compliance/${standardId}`,
    body,
    config: {
      method: "put",
    },
    requestSchema: ComplianceStandardRequestSchema,
    responseSchema: z.string(),
  });
};

export const deleteComplianceStandard = async ({
  standardId,
}: {
  standardId: string;
}) => {
  return jsonApi({
    path: `compliance/${standardId}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};

export const newComplianceRequirement = async (
  standardId: string,
  body: ComplianceRequirementRequest,
) => {
  return jsonApi({
    path: `compliance/${standardId}/requirement`,
    body,
    config: {
      method: "post",
    },
    requestSchema: ComplianceRequirementRequestSchema,
    responseSchema: z.string().optional(),
  });
};

export const editComplianceRequirement = async (
  requirementId: string,
  body: ComplianceRequirementRequest,
) => {
  return jsonApi({
    path: `compliance/requirement/${requirementId}`,
    body,
    config: {
      method: "put",
    },
    requestSchema: ComplianceRequirementRequestSchema,
    responseSchema: z.string(),
  });
};

export const deleteComplianceRequirement = async ({
  requirementId,
}: {
  requirementId: string;
}) => {
  return jsonApi({
    path: `compliance/requirement/${requirementId}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};

export const newComplianceSection = async (
  requirementId: string,
  body: ComplianceSectionRequest,
) => {
  return jsonApi({
    path: `compliance/${requirementId}/section`,
    body,
    config: {
      method: "post",
    },
    requestSchema: ComplianceSectionRequestSchema,
    responseSchema: z.string().optional(),
  });
};

export const editComplianceSection = async (
  sectionId: string,
  body: ComplianceSectionRequest,
) => {
  return jsonApi({
    path: `compliance/requirement/section/${sectionId}`,
    body,
    config: {
      method: "put",
    },
    requestSchema: ComplianceSectionRequestSchema,
    responseSchema: z.string(),
  });
};

export const deleteComplianceSection = async ({
  sectionId,
}: {
  sectionId: string;
}) => {
  return jsonApi({
    path: `compliance/requirement/section/${sectionId}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};
