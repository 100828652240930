import { useMutation } from "@tanstack/react-query";
import { debounce } from "debounce";
import { useMemo, useState, type Dispatch, type SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useToastActions } from "stores";
import { Body, Input, Link } from "ui";

import { bucketCheck } from "requests";
import ErrorString from "./ErrorString";

const PRISMA_CLOUD_AMAZON_SIMPLE_STORAGE_SERVICE =
  "https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html";

type BucketInputPropTypes = {
  accountId: string;
  cloudTrailBucket?: string;
  disabled: boolean;
  externalId: string;
  roleArn: string;
  setCloudTrailBucket: Dispatch<SetStateAction<string | undefined>>;
};

export default function BucketInput({
  accountId,
  cloudTrailBucket = "",
  disabled,
  externalId,
  roleArn,
  setCloudTrailBucket,
}: BucketInputPropTypes) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const [bucket, setBucket] = useState(cloudTrailBucket);

  const [isDirty, setIsDirty] = useState(false);
  const [isUnique, setIsUnique] = useState(true); // bucket name is unique
  const [isValid, setIsValid] = useState(true); // bucket name was validated by BE regex

  const params = {
    accountId,
    roleArn,
    externalId,
  };

  const requiredErrorMessage = intl.formatMessage({
    defaultMessage: "Bucket name is required",
    id: "l4N8Bl",
    description: "Error message for empty bucket input",
  });

  const retrievalError = intl.formatMessage({
    defaultMessage: "Error retrieving bucket information",
    id: "uEdx9N",
    description: "Error message for failure to get bucket name information",
  });

  const { mutateAsync } = useMutation({
    mutationFn: (bucket: string) => bucketCheck({ ...params, bucket }),
    onError: () => {
      toast(retrievalError, { appearance: "warning" });
    },
    onSuccess: (data) => {
      const { isBucketExist, isBucketNameValid } = data;

      if (isBucketExist) {
        setIsUnique(false);
      } else if (!isBucketExist) {
        setIsUnique(true);
      }

      if (isBucketNameValid) {
        setIsValid(true);
      } else if (!isBucketNameValid) {
        setIsValid(false);
      }
    },
  });

  const debouncedOnChange = useMemo(
    () => debounce(mutateAsync, 200),
    [mutateAsync],
  );

  return (
    <>
      <Input
        addClassName="w-full"
        appearance={!isValid || !isUnique ? "error" : "default"}
        disabled={disabled}
        onChange={(e) => {
          if (!isDirty) setIsDirty(true);

          const newBucket = e.target.value;

          setBucket(newBucket);
          setCloudTrailBucket(newBucket); // set value in Wizard state
          debouncedOnChange(newBucket);
        }}
        placeholder="Enter Bucket Name..."
        value={bucket}
      />
      {!isValid && (
        <Body size="sm" appearance="error" addClassName="mt-1">
          <FormattedMessage
            defaultMessage="Invalid name. <a>Bucket naming rules - Amazon Simple Storage Service</a>"
            id="KZ/Qvm"
            description="Error message for bucket name input in AWS Onboarding wizard"
            values={{
              a: (chunks) => (
                <Link
                  addClassName="ml-2"
                  external
                  href={PRISMA_CLOUD_AMAZON_SIMPLE_STORAGE_SERVICE}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Body>
      )}
      {!isUnique && (
        <FormattedMessage
          defaultMessage="Bucket name already exists."
          id="hOoaIT"
          description="Error message for bucket name input in AWS Onboarding wizard"
        />
      )}
      {isDirty && bucket.length === 0 && (
        <ErrorString errorMessage={requiredErrorMessage} />
      )}
    </>
  );
}
