import { useCallback } from "react";
import {
  useGlobalModalContext,
  type RenderModalProps,
} from "./GlobalModalContext";

import { type FC } from "react";
type openModalProps<T = Record<string, unknown>> = (props?: T) => void;
type closeModalProps = () => void;

/**
 * The `useModal` hook takes a Modal Component, and returns an `openModal` and a `closeModal` function.
 * A Modal component is a component that has an `isOpen`, a `closeModal` and any other props.
 * When calling `isOpen`, pass the props that should be sent to the Modal Component.  Don't send `isOpen` or `closeModal`, those will be provided for you.
 * @param RenderModal
 */
export function useModal<T = Record<string, unknown>>(
  RenderModal: FC<RenderModalProps<T>>,
): {
  closeModal: closeModalProps;
  openModal: openModalProps<T>;
} {
  const { dispatch } = useGlobalModalContext();

  const openModal = useCallback<openModalProps<T>>(
    (props) => {
      dispatch({ payload: { RenderModal, props }, type: "openModal" });
    },
    [RenderModal, dispatch],
  );

  const closeModal = useCallback<closeModalProps>(() => {
    dispatch({ payload: { RenderModal }, type: "closeModal" });
  }, [RenderModal, dispatch]);

  return {
    closeModal: closeModal,
    openModal: openModal,
  };
}
