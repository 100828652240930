import { fetchAndDownloadFile } from "../../../utils";

export const downloadMemberRoleCft = async (
  payload: Record<string, unknown>,
) => {
  const response = await fetchAndDownloadFile({
    endPoint: `cas/v1/aws_template`,
    fileName: "prisma-cloud-aws-member-iam-role.template",
    payload,
  });
  const responseHeaders: Record<string, unknown> = {};
  response.headers.forEach((val, key) => (responseHeaders[key] = val));
  return responseHeaders;
};

export const downloadManagementRoleCft = async (
  payload: Record<string, unknown>,
) => {
  const response = await fetchAndDownloadFile({
    endPoint: `cas/v1/aws_template`,
    fileName: "prisma-cloud-aws-management-iam-role.template",
    payload,
  });
  const responseHeaders: Record<string, unknown> = {};
  response.headers.forEach((val, key) => (responseHeaders[key] = val));
  return responseHeaders;
};
