import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Bold } from "ui";
import CFTButton from "../CreateOrDownloadIAMRoleCft/CFTButton";

type ConfigHelpersProps = {
  downloadManagementIAMRoleCft: UseMutateAsyncFunction<
    unknown,
    unknown,
    void,
    unknown
  >;
  downloadMemberIAMRoleCft: UseMutateAsyncFunction<
    unknown,
    unknown,
    void,
    unknown
  >;
  isDownloadingMemberCft: boolean;
  isDownloadingManagementCft: boolean;
  isMemberCftDownloaded: boolean;
  isManagementCftDownloaded: boolean;
  closeModal: () => void;
  disabled: boolean;
};

export default function ConfigHelpers({
  downloadMemberIAMRoleCft,
  downloadManagementIAMRoleCft,
  isDownloadingMemberCft,
  isDownloadingManagementCft,
  isMemberCftDownloaded,
  isManagementCftDownloaded,
  closeModal,
  disabled,
}: ConfigHelpersProps) {
  return (
    <>
      <CFTButton
        dataSelector="download-management-cft-link"
        external={false}
        isLoading={isDownloadingManagementCft}
        isFileDownloaded={isManagementCftDownloaded}
        onButtonClick={downloadManagementIAMRoleCft}
        title={
          <Bold>
            <FormattedMessage
              defaultMessage="Download Management IAM Role CFT"
              id="ucjKpF"
              description="AWS cft download link"
            />
          </Bold>
        }
        closeModal={closeModal}
        disabled={disabled}
      />
      <CFTButton
        dataSelector="download-member-cft-link"
        external={false}
        isLoading={isDownloadingMemberCft}
        isFileDownloaded={isMemberCftDownloaded}
        onButtonClick={downloadMemberIAMRoleCft}
        title={
          <Bold>
            <FormattedMessage
              defaultMessage="Download Member IAM Role CFT"
              id="xrfuGS"
              description="AWS cft download link"
            />
          </Bold>
        }
        closeModal={closeModal}
        disabled={disabled}
      />
    </>
  );
}
