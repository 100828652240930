import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsSlackIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Slack",
    id: "k2vHBC",

    description: "Slack icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M27.1429 60.5489C27.1429 64.9445 23.1868 68.9006 18.7912 68.9006C14.3956 68.9006 10 64.9445 10 60.5489C10 56.1533 13.956 52.1973 18.3516 52.1973H26.7033V60.5489H27.1429Z"
        className="fill-[#E01E5A] dark:fill-white"
      />
      <path
        d="M31.0989 60.5489C31.0989 56.1533 35.0549 52.1973 39.4506 52.1973C43.8462 52.1973 47.8022 56.1533 47.8022 60.5489V81.6478C47.8022 86.0434 43.8462 89.9995 39.4506 89.9995C35.0549 89.9995 31.0989 86.0434 31.0989 81.6478C31.0989 81.2082 31.0989 60.5489 31.0989 60.5489Z"
        className="fill-[#E01E5A] dark:fill-white"
      />
      <path
        d="M39.4506 26.7043C35.0549 26.7043 31.0989 22.7482 31.0989 18.3526C31.0989 13.957 35.0549 10.001 39.4506 10.001C43.8462 10.001 47.8022 13.957 47.8022 18.3526V26.7043H39.4506Z"
        className="fill-[#36C5F0] dark:fill-white"
      />
      <path
        d="M39.4505 31.0986C43.8461 31.0986 47.8022 35.0547 47.8022 39.4503C47.8022 43.8459 44.2857 47.8019 39.4505 47.8019H18.7912C13.956 47.8019 10 43.8459 10 39.4503C10 35.0547 13.956 31.0986 18.7912 31.0986H39.4505Z"
        className="fill-[#36C5F0] dark:fill-white"
      />
      <path
        d="M73.2967 39.4503C73.2967 35.0547 77.2527 31.0986 81.6483 31.0986C86.0439 31.0986 90 35.0547 90 39.4503C90 43.8459 86.0439 47.8019 81.6483 47.8019H73.2967V39.4503Z"
        className="fill-[#2EB67D] dark:fill-white"
      />
      <path
        d="M68.9011 39.4515C68.9011 43.8471 64.945 47.8032 60.5494 47.8032C56.1538 47.8032 52.1978 43.8471 52.1978 39.4515V18.3526C52.1978 13.957 56.1538 10.001 60.5494 10.001C64.945 10.001 68.9011 13.957 68.9011 18.3526V39.4515Z"
        className="fill-[#2EB67D] dark:fill-white"
      />
      <path
        d="M60.5494 72.8574C64.945 72.8574 68.9011 76.8135 68.9011 81.2091C68.9011 85.6047 64.945 89.5607 60.5494 89.5607C56.1538 89.5607 52.1978 85.6047 52.1978 81.2091V72.8574H60.5494Z"
        className="fill-[#ECB22E] dark:fill-white"
      />
      <path
        d="M60.5494 68.9006C56.1538 68.9006 52.1978 64.9445 52.1978 60.5489C52.1978 56.1533 56.1538 52.1973 60.5494 52.1973H81.6483C86.0439 52.1973 90 56.1533 90 60.5489C90 64.9445 86.0439 68.9006 81.6483 68.9006H60.5494Z"
        className="fill-[#ECB22E] dark:fill-white"
      />
    </SvgIcon>
  );
};
