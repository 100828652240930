import { FormattedMessage } from "react-intl";

import { Banner, Body, Bold, Link } from "ui";

type InfoBannerProps = {
  computeScanLimit: number;
};

export function InfoBanner({ computeScanLimit }: InfoBannerProps) {
  return (
    <Banner appearance="standard" showIcon>
      <Body size="sm" appearance="secondary">
        <FormattedMessage
          defaultMessage="<b>Agentless, Serverless Scan and Auto protect</b> : Threshold for scans is limited to {limit} accounts per tenant. {br} <a>Click here</a> to Learn More."
          id="QI9J6m"
          description="standard info banner text for compute scan limit"
          values={{
            a: (chunks) => (
              <Link
                external
                href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/runtime-security/cloud-service-providers/cloud-service-providers"
                size="sm"
              >
                {chunks}
              </Link>
            ),
            b: (chunks) => <Bold>{chunks}</Bold>,
            br: <br />,
            limit: computeScanLimit,
          }}
        />
      </Body>
    </Banner>
  );
}

export function WarningBanner() {
  return (
    <Banner appearance="warning" showIcon>
      <Body size="sm" appearance="secondary">
        <FormattedMessage
          defaultMessage="You have reached the compute workload scan threshold limits. Newer accounts are disabled for scanning. <a>Learn more</a> about the workload scans and thresholds. Contact Prisma Cloud support for further assistance."
          id="EqYdFT"
          description="standard info banner text for compute scan limit"
          values={{
            a: (chunks) => (
              <Link
                external
                size="sm"
                href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/runtime-security/cloud-service-providers/cloud-service-providers"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </Body>
    </Banner>
  );
}
