import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

import { useIntl } from "react-intl";

export const IntegrationsJiraIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Jira",
    id: "SHY5eG",
    description: "Jira icon alt text",
  });

  const className = props?.className ?? "fill-[#2684FF] dark:fill-white";

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M87.6035 47.8495L53.1949 13.4409L49.754 10L23.9476 35.8064L12.3347 47.8495C11.0443 49.1398 11.0443 51.2903 12.3347 52.1505L35.9906 75.8064L50.1841 90L75.9906 64.1935L76.4207 63.7634L88.0336 52.1505C88.8938 50.8602 88.8938 49.1398 87.6035 47.8495ZM50.1841 61.6129L38.1411 49.5699L50.1841 37.5269L62.2271 49.5699L50.1841 61.6129Z"
        className={className}
      />
    </SvgIcon>
  );
};
