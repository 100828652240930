import { FormattedMessage } from "react-intl";
import { Body, Link } from "ui";

const jobUrl =
  "https://cloud.google.com/dataflow/docs/concepts/access-control#creating_job";

export const DataFlowCompressionDescription = () => {
  return (
    <Body as="p" appearance="secondary" size="sm">
      <FormattedMessage
        defaultMessage="Prisma Cloud requires permission to run jobs on Cloud Dataflow. Reference <a>Cloud Dataflow</a> roles to enable required admin permissions."
        id="vljIMu"
        description="Note for onboarding Org Type GCP account"
        values={{
          a: (chunks) => (
            <Link size="sm" href={jobUrl} external={true}>
              {chunks}
            </Link>
          ),
        }}
      />
    </Body>
  );
};
