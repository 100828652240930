import {
  useMutation,
  useQueryClient,
  type UseMutateAsyncFunction,
} from "@tanstack/react-query";
import { CheckIcon, DownloadIcon, ExternalLinkIcon, LoadingIcon } from "icons";
import { useMemo, type ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { cloudAccountsKeys, saveAwsTempAccount } from "requests";
import { useToastActions } from "stores";
import { Button } from "ui";
import { useWizardContext } from "../../../../../../components/Wizard";
import { ADD_DETAILS, AWS, ORGANIZATION } from "../../../../../constants";
import {
  type AwsAddDetailsStepValues,
  type UnKnownError,
} from "../../../../../types";
import { handleError } from "../../../../../utils";
import { getWizardStepIndex } from "../../../../Onboarding/utils";

type CFTButtonProps = {
  dataSelector: string;
  disabled?: boolean;
  external: boolean;
  isLoading: boolean;
  isFileDownloaded: boolean;
  onButtonClick: UseMutateAsyncFunction<unknown, unknown, void, unknown>;
  title: ReactNode;
  btnIcon?: ReactNode;
  closeModal?: () => void;
};

export default function CFTButton({
  dataSelector,
  disabled = false,
  external,
  isLoading,
  isFileDownloaded,
  onButtonClick,
  title,
  btnIcon,
  closeModal,
}: CFTButtonProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const { watch } = useFormContext();
  const {
    state: { steps, activeIndex },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);
  const queryClient = useQueryClient();

  let {
    accountType,
    name: accountName,
    groupIds,
    onboardType,
  } = (steps[addDetailsStepIndex]?.values ?? {}) as AwsAddDetailsStepValues;

  if (activeIndex === 0) {
    [accountType, accountName, groupIds, onboardType] = watch([
      "accountType",
      "name",
      "groupIds",
      "onboardType",
    ]);
  }

  const isOrg = accountType === ORGANIZATION;
  const isTemplateOnboardType = onboardType === "template";

  const icon = useMemo(
    () =>
      isLoading ? (
        <LoadingIcon />
      ) : isFileDownloaded ? (
        <CheckIcon />
      ) : btnIcon ? (
        btnIcon
      ) : external ? null : (
        <DownloadIcon />
      ),
    [isLoading, isFileDownloaded, btnIcon, external],
  );

  const payload = {
    accountName,
    accountType,
    cloudType: AWS,
    ...(isOrg
      ? { defaultAccountGroupId: groupIds as string }
      : { accountGroups: groupIds as string[] }),
    automatedFlow: true,
  };

  const { mutateAsync: saveTempAccount } = useMutation({
    mutationFn: () => saveAwsTempAccount({ payload }),
    onSuccess: () => {
      onButtonClick();
      queryClient.invalidateQueries({
        queryKey: cloudAccountsKeys.cloudAccountsTable({
          excludeAccountGroupDetails: "true",
          includePendingAccounts: "true",
        }),
      });
    },
    onError: (error: unknown) => {
      handleError({
        intl,
        toast,
        error: (error as unknown as UnKnownError)?.body?.error,
      });
      closeModal && closeModal();
    },
  });

  return (
    <section className="space-y-1">
      <Button
        disabled={disabled}
        data-selector={dataSelector}
        appearance="primary"
        icon={icon}
        onClick={async () =>
          isTemplateOnboardType ? onButtonClick() : await saveTempAccount()
        }
      >
        <span className="flex items-center text-sm">
          {title}
          {external && (
            <span className="ml-2">
              <ExternalLinkIcon size="sm" />
            </span>
          )}
        </span>
      </Button>
    </section>
  );
}
