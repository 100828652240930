import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { dataSettingsLayoutRoute } from "../dataSettingsLayoutRoute";

export const DataPatternsTable = lazy(() => import("./DataPatternsTable"));

export const dataPatternsLayoutRoute = new Route({
  getParentRoute: () => dataSettingsLayoutRoute,
  path: "dataPatterns",
  component: DataPatternsTable,
});
