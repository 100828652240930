import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

import { useIntl } from "react-intl";

export const VulnerabilityIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Vulnerability",
    id: "1xRHZA",

    description: "Finding Type Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <title>{alt}</title>
      <path d="M9 0V2.07C5.38 2.53 2.53 5.38 2.07 8.99998H0V11H2.07C2.53 14.62 5.38 17.47 9 17.93V20H11V17.93C14.62 17.47 17.47 14.62 17.93 11H20V8.99998H17.93C17.47 5.38 14.62 2.53 11 2.07V0M9 4.08V6H11V4.09C13.5 4.5 15.5 6.5 15.92 8.99998H14V11H15.91C15.5 13.5 13.5 15.5 11 15.92V14H9V15.91C6.5 15.5 4.5 13.5 4.08 11H6V8.99998H4.09C4.5 6.5 6.5 4.5 9 4.08ZM10 8.99998C9.7348 8.99998 9.4804 9.10538 9.2929 9.29288C9.1054 9.48038 9 9.73478 9 9.99998C9 10.2652 9.1054 10.5196 9.2929 10.7071C9.4804 10.8947 9.7348 11 10 11C10.2652 11 10.5196 10.8947 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 9.99998C11 9.73478 10.8946 9.48038 10.7071 9.29288C10.5196 9.10538 10.2652 8.99998 10 8.99998Z" />
    </SvgIcon>
  );
};
