import { useIntl } from "react-intl";
import { useTrimField } from "../../../hooks";

import { Input } from "form";

type DataFlowProjectIdProps = {
  disabled: boolean;
};

export const DataFlowProjectId = ({
  disabled = false,
}: DataFlowProjectIdProps) => {
  const intl = useIntl();
  useTrimField("dataflowEnabledProject");

  return (
    <Input
      data-testid="gcp-dataflow-enabled-project-input"
      description={intl.formatMessage({
        defaultMessage:
          "Using Dataflow to generate compressed logs will significantly reduces network egress costs",
        id: "0Hv6us",
        description: "Description for datafloe enabled project id",
      })}
      readOnly={disabled}
      label={intl.formatMessage({
        defaultMessage: "Dataflow Enabled Project ID",
        id: "lGT6Dx",
        description: "The name of an input field for onboarding a GCP account",
      })}
      name="dataflowEnabledProject"
      placeholder={intl.formatMessage({
        defaultMessage: "Dataflow Enabled Project ID",
        id: "0E9g1o",
        description:
          "Placeholder for the Dataflow Enabled Project ID of an input field for onboarding a GCP account",
      })}
      register={{
        required: false,
      }}
    />
  );
};
