import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

interface ApplicationSecurityIconProps extends SvgIconProps {
  color: string;
  alt?: string;
}

export const ApplicationSecurityIcon = ({
  color,
  alt,
  ...passThrough
}: ApplicationSecurityIconProps) => {
  return (
    <SvgIcon
      width={55}
      height={40}
      viewBox="0 0 55 40"
      fill="none"
      style={{ margin: "-10px 2px -10px -10px" }}
      {...passThrough}
    >
      <title>{alt}</title>
      <g filter="url(#filter0_d_12071_309911)">
        <g filter="url(#filter1_b_12071_309911)">
          <path
            d="M26.4 18.4C27.2837 19.2837 27.2837 20.7163 26.4 21.6L21.6 26.4C20.7163 27.2837 19.2837 27.2837 18.4 26.4L13.6 21.6C12.7163 20.7163 12.7163 19.2837 13.6 18.4L18.4 13.6C19.2837 12.7163 20.7163 12.7163 21.6 13.6L26.4 18.4Z"
            fill={color}
          />
        </g>
        <g filter="url(#filter2_b_12071_309911)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8 25.6L25.6 20.8C26.0418 20.3582 26.0418 19.6418 25.6 19.2L20.8 14.4C20.3582 13.9582 19.6418 13.9582 19.2 14.4L14.4 19.2C13.9582 19.6418 13.9582 20.3582 14.4 20.8L19.2 25.6C19.6418 26.0418 20.3582 26.0418 20.8 25.6ZM26.4 21.6C27.2837 20.7163 27.2837 19.2837 26.4 18.4L21.6 13.6C20.7163 12.7163 19.2837 12.7163 18.4 13.6L13.6 18.4C12.7163 19.2837 12.7163 20.7163 13.6 21.6L18.4 26.4C19.2837 27.2837 20.7163 27.2837 21.6 26.4L26.4 21.6Z"
            fill="#181C20"
          />
        </g>
      </g>
      <g filter="url(#filter3_b_12071_309911)">
        <path
          d="M35.4 18.4C36.2837 19.2837 36.2837 20.7163 35.4 21.6L30.6 26.4C29.7163 27.2837 28.2837 27.2837 27.4 26.4L22.6 21.6C21.7163 20.7163 21.7163 19.2837 22.6 18.4L27.4 13.6C28.2837 12.7163 29.7163 12.7163 30.6 13.6L35.4 18.4Z"
          fill={color}
        />
      </g>
      <g filter="url(#filter4_b_12071_309911)">
        <path
          d="M35.4 18.4C36.2837 19.2837 36.2837 20.7163 35.4 21.6L30.6 26.4C29.7163 27.2837 28.2837 27.2837 27.4 26.4L22.6 21.6C21.7163 20.7163 21.7163 19.2837 22.6 18.4L27.4 13.6C28.2837 12.7163 29.7163 12.7163 30.6 13.6L35.4 18.4Z"
          fill="black"
          fillOpacity="0.05"
        />
      </g>
      <g filter="url(#filter5_b_12071_309911)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8 25.6L34.6 20.8C35.0418 20.3582 35.0418 19.6418 34.6 19.2L29.8 14.4C29.3582 13.9582 28.6418 13.9582 28.2 14.4L23.4 19.2C22.9582 19.6418 22.9582 20.3582 23.4 20.8L28.2 25.6C28.6418 26.0418 29.3582 26.0418 29.8 25.6ZM35.4 21.6C36.2837 20.7163 36.2837 19.2837 35.4 18.4L30.6 13.6C29.7163 12.7163 28.2837 12.7163 27.4 13.6L22.6 18.4C21.7163 19.2837 21.7163 20.7163 22.6 21.6L27.4 26.4C28.2837 27.2837 29.7163 27.2837 30.6 26.4L35.4 21.6Z"
          fill="#181C20"
        />
      </g>
      <g filter="url(#filter6_b_12071_309911)">
        <path
          d="M44.4 18.4C45.2837 19.2837 45.2837 20.7163 44.4 21.6L39.6 26.4C38.7163 27.2837 37.2837 27.2837 36.4 26.4L31.6 21.6C30.7163 20.7163 30.7163 19.2837 31.6 18.4L36.4 13.6C37.2837 12.7163 38.7163 12.7163 39.6 13.6L44.4 18.4Z"
          className="fill-white dark:fill-[#3C4D55]"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter7_b_12071_309911)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8 25.6L43.6 20.8C44.0418 20.3582 44.0418 19.6418 43.6 19.2L38.8 14.4C38.3582 13.9582 37.6418 13.9582 37.2 14.4L32.4 19.2C31.9582 19.6418 31.9582 20.3582 32.4 20.8L37.2 25.6C37.6418 26.0418 38.3582 26.0418 38.8 25.6ZM44.4 21.6C45.2837 20.7163 45.2837 19.2837 44.4 18.4L39.6 13.6C38.7163 12.7163 37.2837 12.7163 36.4 13.6L31.6 18.4C30.7163 19.2837 30.7163 20.7163 31.6 21.6L36.4 26.4C37.2837 27.2837 38.7163 27.2837 39.6 26.4L44.4 21.6Z"
          className="fill-[#B8C1CC] dark:fill-[#181C20]"
        />
      </g>
      <g filter="url(#filter8_b_12071_309911)">
        <path
          d="M53.4 18.4C54.2837 19.2837 54.2837 20.7163 53.4 21.6L48.6 26.4C47.7163 27.2837 46.2837 27.2837 45.4 26.4L40.6 21.6C39.7163 20.7163 39.7163 19.2837 40.6 18.4L45.4 13.6C46.2837 12.7163 47.7163 12.7163 48.6 13.6L53.4 18.4Z"
          className="fill-white dark:fill-[#3C4D55]"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter9_b_12071_309911)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8 25.6L52.6 20.8C53.0418 20.3582 53.0418 19.6418 52.6 19.2L47.8 14.4C47.3582 13.9582 46.6418 13.9582 46.2 14.4L41.4 19.2C40.9582 19.6418 40.9582 20.3582 41.4 20.8L46.2 25.6C46.6418 26.0418 47.3582 26.0418 47.8 25.6ZM53.4 21.6C54.2837 20.7163 54.2837 19.2837 53.4 18.4L48.6 13.6C47.7163 12.7163 46.2837 12.7163 45.4 13.6L40.6 18.4C39.7163 19.2837 39.7163 20.7163 40.6 21.6L45.4 26.4C46.2837 27.2837 47.7163 27.2837 48.6 26.4L53.4 21.6Z"
          className="fill-[#B8C1CC] dark:fill-[#181C20]"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12071_309911"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.329412 0 0 0 0 0.745098 0 0 0 0 0.894118 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_12071_309911"
          x="2.93726"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_12071_309911"
          x="2.93726"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_b_12071_309911"
          x="11.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_b_12071_309911"
          x="11.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_b_12071_309911"
          x="11.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_b_12071_309911"
          x="20.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_b_12071_309911"
          x="20.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_b_12071_309911"
          x="29.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_b_12071_309911"
          x="29.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309911"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309911"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};
