import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { reportsLayoutRoute } from "../reportsLayoutRoute";

export const AlertsReports = lazy(() => import("./AlertsReports"));

export const alertsReportsRoute = new Route({
  getParentRoute: () => reportsLayoutRoute,
  path: "alerts",
  component: AlertsReports,
});
