import { z } from "zod";
import { AccountTypeSchema } from "../cloudAccountsCas";

export const GroupInfoSchema = z.object({
  autoCreated: z.boolean(),
  groupName: z.string(),
  lastModifiedBy: z.string(),
});

export const CloudAccountRestDataSchema = z.object({
  accountGroupInfos: z.array(GroupInfoSchema).optional(),
  cloudAccount: z
    .object({
      accountGroupInfos: z.array(GroupInfoSchema).optional(),
    })
    .optional(),
  accountType: AccountTypeSchema,
  enabled: z.boolean(),
  name: z.string(),
  ramArn: z.string().optional(),
  userName: z.string().optional(),
  homeRegion: z.string().optional(),
  policyName: z.string().optional(),
  groupName: z.string().optional(),
  userOcid: z.string().optional(),
  lastModifiedBy: z.string(),
  lastModifiedTs: z.number(),
  groupIds: z.string().array().optional(),
  defaultAccountGroupId: z.string().optional(),
  deploymentType: z.string().optional(),
});

export const CloudAccountsRestParamsSchema = z.object({
  includeGroupInfo: z.union([z.literal("true"), z.literal("false")]),
});

export type GroupInfo = z.infer<typeof GroupInfoSchema>;

export type CloudAccountRestData = z.infer<typeof CloudAccountRestDataSchema>;
