import { Set } from "immutable";
import { type TreeMapType } from "./types";

function getAncestors(treeMap: TreeMapType, id: string, accum = Set()) {
  const parentId = treeMap.getIn([id, "parent"]);

  if (!parentId) {
    return accum;
  }

  return getAncestors(treeMap, parentId, accum.add(parentId));
}

function getDescendants(treeMap: TreeMapType, id: string): Set<string> {
  const children = treeMap
    .getIn([id, "children", "folders"])
    .union(treeMap.getIn([id, "children", "projects"]));

  return children.concat(
    children.flatMap((child: string) => getDescendants(treeMap, child)),
  );
}

export default function calculateNodeStates(
  treeMap: TreeMapType,
  selectedNodes: Set<string>,
): {
  checked: Set<string>;
  disabled: Set<string>;
  halfChecked: Set<string>;
} {
  const descendantsOfSelectedNodes = selectedNodes.flatMap((nodeId = "") =>
    getDescendants(treeMap, nodeId),
  );

  return {
    checked: selectedNodes.union(descendantsOfSelectedNodes),
    disabled: descendantsOfSelectedNodes as unknown as Set<string>,
    halfChecked: selectedNodes.flatMap((nodeId = "") =>
      getAncestors(treeMap, nodeId),
    ) as unknown as Set<string>,
  };
}
