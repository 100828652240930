import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../hooks";
import { type CloudType } from "../../../../types";
import { getValidateAccountId } from "../../utils";

type AccountIdProps = {
  isEdit: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  tooltip?: string;
  cloudType?: CloudType;
  showLabel?: boolean;
};
export default function AccountId({
  isEdit,
  onBlur,
  tooltip,
  cloudType,
  ...rest
}: AccountIdProps) {
  const intl = useIntl();
  useTrimField("accountId");

  return (
    <Input
      name="accountId"
      label={intl.formatMessage({
        defaultMessage: "Account ID",
        id: "r7hgjl",
        description:
          "The name of an input field account id for onboarding an AWS account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Account ID",
        id: "19jqtx",
        description: "Placeholder for Account Account ID",
      })}
      readOnly={isEdit}
      register={{
        validate: getValidateAccountId(intl, cloudType),
      }}
      tooltip={tooltip}
      {...rest}
    />
  );
}
