import { defineMessages } from "react-intl";

export const ButtonLabelMap = defineMessages({
  false: {
    defaultMessage: "Subscribe",
    id: "IN8ULw",
    description:
      "Button value when Discovery and Exposure Management module is unsubscribed",
  },
  true: {
    defaultMessage: "Unsubscribe",
    id: "vrDTHg",
    description:
      "Button value when Discovery and Exposure Management module is subscribed",
  },
});

export const FeatureProvisionMessageMap = defineMessages({
  true: {
    defaultMessage:
      "Discovery and Exposure Management has been successfully subscribed.",
    id: "Oy3tBk",
    description:
      "Success message when Discovery and Exposure Management module subscription is successful",
  },
  false: {
    defaultMessage:
      "Discovery and Exposure Management has been successfully unsubscribed.",
    id: "LD14th",
    description:
      "Success message when Discovery and Exposure Management module subscription is successful",
  },
});

export const asmConfirmationTitleMessagesMap = defineMessages({
  afterSubscribe: {
    defaultMessage:
      "Discovery and Exposure Management module is now activated!",
    id: "IdKLT/",
    description: "CDEM Security activation complete title",
  },
  afterUnsubscribe: {
    defaultMessage: "You have successfully unsubscribed!",
    id: "kZ8Yhb",
    description: "CDEM unsubscribe complete header",
  },
});

export const asmConfirmationDescriptionMessagesMap = defineMessages({
  afterSubscribe: {
    defaultMessage:
      "Prisma Cloud needs some time to perform the cloud exposure discovery and we will update you via an email as soon as the discovery completes.",
    id: "HAvqg4",
    description: "CDEM Security activation complete description",
  },
  afterUnsubscribe: {
    defaultMessage:
      "After unsubscribing you won’t have access to the CDEM security module.",
    id: "9iGevR",
    description: "CDEM Security unsubscribe complete description",
  },
});
