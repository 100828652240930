import {
  autoUpdate,
  flip,
  limitShift,
  offset,
  shift,
  useFloating as useFloatingUi,
  type Placement,
  type UseFloatingOptions,
} from "@floating-ui/react";
export { type Placement, type UseFloatingOptions };

export function useFloating(options?: UseFloatingOptions) {
  const { refs, floatingStyles } = useFloatingUi<HTMLElement>({
    middleware: [offset(0), flip(), shift({ limiter: limitShift() })],
    whileElementsMounted: autoUpdate,
    ...options,
  });

  return {
    setReference: refs.setReference,
    setFloating: refs.setFloating,
    domReferenceRef: refs.domReference,
    floatingStyles,
  };
}
