import { z } from "zod";

export const GcpSelectMonitoredProjectsChildrenRequestSchema = z.object({
  cloudAccount: z.object({
    accountId: z.string(),
    accountType: z.string(),
  }),
  credentials: z.any(),
});

const HierarchySelection = z.object({
  id: z.string(),
  type: z.string(),
  displayName: z.string(),
  parent: z.string().optional(),
  hasChild: z.boolean(),
});

export const GcpSelectMonitoredProjectsChildrenResponseSchema = z.object({
  organizationName: z.string(),
  response: z.array(HierarchySelection),
});

export const GcpSelectMonitoredProjectsAncestorsRequestSchema = z.object({
  resourceIds: z.array(z.string()),
  credentials: z.any().optional(),
});

export const GcpSelectMonitoredProjectsAncestorsResponseSchema = z.array(
  z.object({
    resourceId: z.string(),
    ancestors: z.array(HierarchySelection),
    status: z.string(),
    responseCode: z.number(),
  }),
);

export type GcpSelectMonitoredProjectsAncestorsType = z.infer<
  typeof GcpSelectMonitoredProjectsAncestorsResponseSchema
>;
