import { Body } from "ui";

export default function ErrorString({
  errorMessage,
}: {
  errorMessage: string;
}) {
  return (
    <Body size="sm" appearance="error" addClassName="mt-2">
      {errorMessage}
    </Body>
  );
}
