import { useQuery } from "@tanstack/react-query";
import { cloudAccountsKeys, getCloudAccountRestData } from "requests";
import { type CloudType } from "../types";

type UseGetCloudAccountsRestProps = {
  accountId: string;
  includeGroupInfo: "true" | "false";
  cloudType: CloudType;
  options?: Record<string, string | boolean>;
};

export const useGetCloudAccountsRest = ({
  accountId,
  cloudType,
  includeGroupInfo,
  options = {},
}: UseGetCloudAccountsRestProps) => {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsRest({
      accountId,
      cloudType,
      includeGroupInfo,
    }),
    queryFn: getCloudAccountRestData,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
};
