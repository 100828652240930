import { lazy } from "react";
import { createRoute, privateRootRoute, redirect } from "router";

const PageNotFound = lazy(() =>
  import("../CatchAll/CatchAll").then((res) => ({ default: res.CatchAll })),
);

const InventoryLayout = lazy(() =>
  import("./InventoryLayout").then((res) => ({
    default: res.InventoryLayout,
  })),
);

export const inventoryLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "inventory",
  component: InventoryLayout,
});

export const inventoryCatchAllRoute = createRoute({
  getParentRoute: () => inventoryLayoutRoute,
  path: "$",
  component: PageNotFound,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/inventory/asset_inventory") {
      throw redirect({ to: "/inventory/assets", replace: true, search });
    } else if (pathname === "/inventory/data_inventory") {
      throw redirect({ to: "/inventory/data", replace: true, search });
    } else if (pathname === "/inventory/asset_explorer") {
      throw redirect({
        to: "/inventory/asset-explorer",
        replace: true,
        search,
      });
    }
  },
});
