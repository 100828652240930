import { useRef, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import useMeasure from "react-use-measure";

import { a, config, useSpring } from "@react-spring/web";
import { InfoTooltipIcon } from "icons";
import { Toggle, Tooltip, type BannerAppearance } from "ui";
import { classNames } from "utils";
import Alert from "../CustomAlert";

type ErrorsBannerProps = {
  errors: {
    errorType: BannerAppearance;
    description: ReactNode;
  }[];
};

type AccordionProps = {
  children: ReactNode;
  description: ReactNode;
  disabled?: boolean;
  errors?: ErrorsBannerProps["errors"];
  icon: ReactNode;
  isOpen: boolean;
  menuToggle: ReactNode;
  noPadding?: boolean;
  onClick?: () => void;
  title: ReactNode;
  tooltip?: string;
};

/**
 * A collapsible panel
 */
export default function Accordion({
  children,
  description,
  disabled = false,
  errors = [],
  icon,
  isOpen,
  menuToggle,
  noPadding = false,
  onClick,
  title,
  tooltip,
}: AccordionProps) {
  const outerRef = useRef(null);
  const [innerRef, bounds] = useMeasure();
  const [isAnimatingHeight, setIsAnimatingHeight] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { height, ...style } = useSpring({
    immediate: !isMounted,
    config: { ...config.stiff, clamp: true },
    from: {
      height: 0,
      opacity: 0,
      translateY: "-100%",
    },
    to: {
      height: bounds.height,
      opacity: 1,
      translateY: "0%",
    },
    onRest: {
      height: () => {
        setIsAnimatingHeight(false);
      },
    },
    onStart: {
      height: (animation) => {
        setIsMounted(true);
        setIsAnimatingHeight(!animation.finished);
      },
    },
    reverse: !isOpen,
  });

  return (
    <div className="flex flex-col rounded bg-white dark:bg-blue-steel-950">
      <div
        className={classNames(
          "w-full border border-gray-300 bg-white",
          "dark:border-blue-steel-850 dark:bg-blue-steel-950",
          isOpen || isAnimatingHeight ? "rounded-t" : "rounded",
          isOpen && "dark:bg-blue-steel-970",
          !isOpen && "hover:border-gray-800 dark:hover:bg-blue-steel-850",
        )}
      >
        <div
          aria-disabled={isOpen}
          aria-expanded={isOpen}
          className="flex w-full space-x-2 rounded p-4 pl-8 focus:outline-none focus:ring"
        >
          {icon && <span>{icon}</span>}

          <div className="grow text-sm">
            <div className="flex justify-between">
              <div className="flex items-center space-x-1 font-semibold leading-5">
                <span>{title}</span>
                {tooltip && (
                  <Tooltip label={tooltip}>
                    <span>
                      <InfoTooltipIcon aria-label={tooltip} />
                    </span>
                  </Tooltip>
                )}
              </div>

              <div className="flex items-end space-x-4 text-xs">
                {menuToggle || (
                  <AccordionToggle
                    checked={isOpen}
                    disabled={disabled}
                    onClick={onClick}
                  />
                )}
              </div>
            </div>

            {description && (
              <div className="mr-20 text-xs font-normal leading-5">
                {description}
              </div>
            )}

            {errors?.length > 0 && <ErrorsBanner errors={errors} />}
          </div>
        </div>
      </div>
      <a.div
        className={classNames(
          (isOpen || isAnimatingHeight) && "border",
          "overflow-hidden rounded-b border-t-0 border-gray-300",
          "dark:border-blue-steel-850",
        )}
        ref={outerRef}
        style={{ height }}
      >
        <a.div
          className={classNames(!noPadding && "p-4")}
          ref={innerRef}
          style={style}
        >
          {children}
        </a.div>
      </a.div>
    </div>
  );
}

type AccordionToggleProps = {
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export function AccordionToggle({
  checked,
  disabled = false,
  onClick,
}: AccordionToggleProps) {
  return (
    <div className="flex space-x-2">
      <span className="text-xs">
        {checked ? (
          <FormattedMessage
            defaultMessage="Enabled"
            id="HkHqeL"
            description="Toggle button for disabling the serverless scan feature"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Disabled"
            id="+nCaHs"
            description="Toggle button for disabling the serverless scan feature"
          />
        )}
      </span>
      <Toggle checked={checked} disabled={disabled} onClick={onClick} />
    </div>
  );
}

export function ErrorsBanner({ errors }: ErrorsBannerProps) {
  return (
    <div className="mt-2 flex flex-col space-y-1 text-xs">
      {errors.map(({ errorType, description }, index) => (
        <Alert
          key={index}
          dataSelector="select-projects-error"
          appearance={errorType}
          description={description}
          showIcon={true}
          closable={errorType === "warning"}
        />
      ))}
    </div>
  );
}
