import { useNavigate, type LinkOptions } from "@tanstack/react-router";
import { useSetUCDMode } from "prisma";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  fttvExperiencePreferenceSchema,
  usePreferences,
  useToken,
  type UCDMode,
} from "requests";
import { Button, ModalProvider } from "ui";
import { AnimatedBackground } from "../../components/AnimatedBackground";
import FtuPrismaUILayout from "./components/FtuPrismaUILayout";
import RoleOptions from "./components/RoleOptions";

export default function FirstTimeExperience() {
  const navigate = useNavigate();
  const setUCDMode = useSetUCDMode();
  const {
    update: { mutate: update },
  } = usePreferences(
    "fttvExperience",
    "config",
    fttvExperiencePreferenceSchema,
  );
  const { firstLogin, prismaId } = useToken();
  const [selectedRole, setRole] = useState<{ value: UCDMode }>({
    value: "cloud",
  });

  const saveThemeInfo = useCallback(() => {
    const chosenUCDMode = selectedRole?.value ?? "cloud";

    update({
      config: {
        isEnabled: true,
      },
    });
    setUCDMode(chosenUCDMode, prismaId);

    // If this is a brand new account that has never used the product before, redirect them to setup their accounts instead.

    if (chosenUCDMode) {
      if (firstLogin) {
        navigate({
          to: "/settings/providers/cloud-accounts?modalName=unifiedProviders",
        } as unknown as LinkOptions);
      } else if (chosenUCDMode === "runtime") {
        navigate({ to: "/home/runtime" });
      } else if (chosenUCDMode === "application") {
        navigate({ to: "/home/appsec/repositories" });
      } else if (chosenUCDMode === "cloud") {
        navigate({ to: "/home/cloud/welcome" });
      } else {
        navigate({ to: "/home" });
      }
    } else {
      navigate({ to: "/home" });
    }
  }, [firstLogin, navigate, prismaId, selectedRole?.value, setUCDMode, update]);

  return (
    <ModalProvider>
      <div className="z-10 h-full">
        <AnimatedBackground />
        <div className="relative flex h-full w-full">
          <>
            <div className="flex w-[60%] items-center justify-end">
              <FtuPrismaUILayout
                theme="theme-robinblue"
                selectedRole={selectedRole.value}
                setRole={setRole}
              />
            </div>
            <div className="flex w-[40%] items-center">
              <div className="h-5/6 min-h-[650px] w-[40rem] rounded-xl border-[.5px] border-blue-steel-850 bg-blue-steel-940/50 p-8 shadow-xl backdrop-blur-2xl">
                <RoleOptions
                  setRole={setRole}
                  selectedRole={selectedRole.value}
                />
                <div className="absolute bottom-6 right-8 flex w-[90%]">
                  <Button
                    appearance="primary"
                    addClassName="hover:bg-transparent dark:border-0"
                    fullWidth
                    centerText
                    onClick={saveThemeInfo}
                  >
                    <span className="text-white">
                      <FormattedMessage
                        defaultMessage="Continue Onboarding"
                        id="A6QGf4"
                        description="Go directly to Prisma Cloud App"
                      />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </ModalProvider>
  );
}
