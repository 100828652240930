import { getPolicies, policiesKeys } from "requests";

import { keepPreviousData, useQuery } from "@tanstack/react-query";

export const governancePoliciesQueryKey = policiesKeys.policies({
  slimView: "true",
  detailedComplianceMappings: "false",
});

export function usePoliciesData() {
  const {
    data: policiesResults = [],
    isLoading,
    isError,
    isFetching,
    isSuccess,
    error,
  } = useQuery({
    queryKey: governancePoliciesQueryKey,
    queryFn: getPolicies,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  return {
    allPolicies: policiesResults,
    error,
    isError,
    isFetching,
    isLoading: isLoading,
    isSuccess,
  };
}
