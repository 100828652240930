import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { dataSettingsLayoutRoute } from "../dataSettingsLayoutRoute";

export const ScanSettingsTable = lazy(() => import("./ScanSettingsTable"));

export const scanSettingsLayoutRoute = new Route({
  getParentRoute: () => dataSettingsLayoutRoute,
  path: "scan",
  component: ScanSettingsTable,
});
