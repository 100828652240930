import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { isString } from "remeda";

/**
 * Remove trailing and leading spaces from field and update it in form context.
 */
export const useTrimField = (fieldName: string) => {
  const { setValue, watch } = useFormContext();
  const fieldValue = watch(fieldName);
  useEffect(() => {
    if (isString(fieldValue)) {
      setValue(fieldName, fieldValue.trim());
    }
  }, [fieldName, fieldValue, setValue]);
};
