import { classNames } from "utils";

import {
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";

export interface CardFooterProps
  extends Omit<ComponentPropsWithoutRef<"div">, "className"> {
  /**
   * Change the look of the card footer.
   */
  appearance?: "primary" | "striped" | "clear";
  /**
   * Add a className to the card footer.
   */
  addClassName?: string;
  /**
   * The footer content, generally with links to more information.
   */
  children: ReactNode;
  /**
   * Secondary content, typically aligned on the left side of the card footer.
   */
  secondaryContent?: ReactNode;
  /**
   * Ignore `secondaryContent`, and disable flex layout for the `children`.
   */
  disableLayout?: boolean;
}

const appearanceClasses = {
  primary: "border-t border-primary",
  striped: "border-t border-primary bg-gray-100 dark:bg-black/[.30]",
  clear: "",
};

export function CardFooter({
  addClassName,
  appearance = "primary",
  children,
  secondaryContent,
  disableLayout,
  ...passThrough
}: CardFooterProps): ReactElement {
  return (
    <div
      data-testid="card-footer"
      {...passThrough}
      className={classNames(
        "flex-initial rounded-b py-2",
        disableLayout ? "px-2" : "flex justify-between px-4",
        appearanceClasses[appearance],
        addClassName,
      )}
    >
      {disableLayout ? (
        children
      ) : (
        <>
          <div className="flex items-center space-x-2">{secondaryContent}</div>
          <div className="flex items-center space-x-2">{children}</div>
        </>
      )}
    </div>
  );
}
