import { Body, Bold, Card, CardBody, Title } from "ui";

import addon_modal_bg from "./assets/addon_modal_bg.webp";

export type AddonModalMainCardProps = {
  icon: JSX.Element;
  title: JSX.Element;
  titleStyle: "addonNameHeader" | "secondaryHeader";
  description: JSX.Element;
};

export function AddonModalMainCard({
  icon,
  title,
  description,
  titleStyle,
}: AddonModalMainCardProps) {
  return (
    <>
      <img
        width={320}
        height={200}
        src={addon_modal_bg}
        className="absolute left-0 top-[33px] -z-10"
      />
      <Card addClassName="pb-6 opacity-80 dark:opacity-90">
        <CardBody addClassName="flex">
          <Body
            as="div"
            addClassName="h-fit bg-gray-100 p-2.5 dark:bg-blue-steel-950"
          >
            {icon}
          </Body>
          <Body as="div" addClassName="pl-2 pt-1">
            {titleStyle === "addonNameHeader" ? (
              <Title level={4} size="sm">
                {title}
              </Title>
            ) : (
              <Bold addClassName="text-base">{title}</Bold>
            )}
            <Body
              as="div"
              addClassName="mt-3 text-justify font-normal text-secondary dark:text-dark-bg"
            >
              {description}
            </Body>
          </Body>
        </CardBody>
      </Card>
    </>
  );
}
