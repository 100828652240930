import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const DataSecurityIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Data Security",
    id: "JEBT0N",

    description: "Security Capabilities Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <title>{alt}</title>
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="M0.875 5.875H6.125V0.625H0.875V5.875ZM2.625 2.375H4.375V4.125H2.625V2.375ZM7.875 0.625V5.875H13.125V0.625H7.875ZM11.375 4.125H9.625V2.375H11.375V4.125ZM0.875 12.875H6.125V7.625H0.875V12.875ZM2.625 9.375H4.375V11.125H2.625V9.375ZM12.25 7.625V8.5H10.5V7.625H7.875V12.875H9.625V10.25H10.5V11.125H13.125V7.625H12.25ZM12.25 12V12.875H13.125V12H12.25ZM10.5 12V12.875H11.375V12H10.5Z"
      />
    </SvgIcon>
  );
};
