// eslint-disable-next-line @typescript-eslint/ban-types
export function FailSafe(target: Function) {
  for (const key of Object.getOwnPropertyNames(target.prototype)) {
    if (key === "constructor") continue;
    const originalMethod = target.prototype[key];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    target.prototype[key] = function (...args: any[]) {
      try {
        return originalMethod.apply(this, args);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error occurred in method ${key}:`, error);
      }
    };
  }
}
