import { useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { featuresMessageMap } from "../../../../messages/featuresMessageMap";

import { Checkbox } from "form";
import { Body, Bold, DynamicFormattedMessage, Tag, Tooltip } from "ui";

import { InfoTooltipIcon, SecurityCapabilitiesIcon } from "icons";

import { REMEDIATION } from "../../../../constants";
import FeatureTooltip from "../FeatureTooltip/FeatureTooltip";

export default function RemediationCard() {
  const intl = useIntl();
  const label = intl.formatMessage({
    defaultMessage: "Remediation",
    id: "yVqm0O",
    description: "Feature name header for remediation card",
  });
  const name = "remediation";
  const checked = useWatch({ name });
  return (
    <div className="flex rounded border p-4 dark:border-blue-steel-500">
      <div className="w-7 [&>div>label:nth-child(1)]:hidden">
        <Checkbox label={label} name={name} showLabel={false} />
      </div>
      <div className="flex space-x-2">
        <SecurityCapabilitiesIcon type={REMEDIATION} />
        <div className="-mt-1">
          <Bold as="p" addClassName="flex items-center space-x-1">
            <span>{label}</span>
            <Tooltip
              label={
                <FeatureTooltip
                  id={name}
                  header={
                    <DynamicFormattedMessage
                      messageMap={featuresMessageMap}
                      token={name}
                    />
                  }
                  content={
                    <DynamicFormattedMessage
                      messageMap={featuresMessageMap}
                      token={`${name}_tooltip_content`}
                    />
                  }
                />
              }
            >
              <span>
                <InfoTooltipIcon aria-label={`infoIcon-${name}`} />
              </span>
            </Tooltip>
          </Bold>
          <Body appearance="secondary" size="sm">
            <DynamicFormattedMessage
              messageMap={featuresMessageMap}
              token={`${name}_desc`}
            />
          </Body>
        </div>
      </div>
      {checked && (
        <div className="ml-auto">
          <Tag size="md">
            <FormattedMessage
              defaultMessage="Requires additional permissions"
              id="s70rsq"
              description="Tag text when feature is checked"
            />
          </Tag>
        </div>
      )}
    </div>
  );
}
