import { LoadingIcon } from "icons";
import {
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";

export type SidecarBodyProps = {
  /**
   * Add a class name to the element
   */
  addClassName?: string;
  /**
   * Displays a loading indicator instead of the children
   */
  isLoading?: boolean;
  /**
   * Do not add padding around the body of the sidecar (default 16px)
   */
  noPadding?: boolean;
  /**
   * The content of the sidecar
   */
  children?: ReactNode;
} & Omit<ComponentPropsWithoutRef<"div">, "className">;

export function SidecarBody({
  addClassName,
  isLoading = false,
  noPadding = false,
  children,
  ...passThrough
}: SidecarBodyProps): ReactElement {
  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingIcon size="3xl" />
      </div>
    );
  }

  return (
    <div
      className={classNames(!noPadding && "p-4", addClassName)}
      {...passThrough}
    >
      {children}
    </div>
  );
}
