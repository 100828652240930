import { colorThemes } from "colors";
import { isDefined } from "remeda";
import {
  ftuExperiencePreferenceSchema,
  usePreferences,
  type ColorTheme,
} from "requests";
import { useDarkMode } from "ui";

const defaultColorTheme = "theme-base";

export interface UseThemeColorParams {
  themeOverride?: ColorTheme;
  isDarkOverride?: boolean;
}

/**
 * A hook that returns the hex color for the currently selected theme based on a combination of the
 * user preferences API and the current dark/light theme. Defaults to the base theme while fetching or
 * if there is no preference.
 * Results can be overrided with props.
 */
export const useThemeColor = ({
  themeOverride,
  isDarkOverride,
}: UseThemeColorParams) => {
  const { isDark } = useDarkMode();
  const shouldBeDark = isDefined(isDarkOverride) ? isDarkOverride : isDark;

  const { data: ftuData } = usePreferences(
    "ftuExperience",
    "config",
    ftuExperiencePreferenceSchema,
  );
  const theme = ftuData?.config?.theme?.color ?? defaultColorTheme;
  const colorTheme = colorThemes[themeOverride ?? theme];

  const themeColor = shouldBeDark ? colorTheme?.darkhex : colorTheme?.lighthex;

  return { themeColor };
};

export function themeColorClass(theme?: ColorTheme) {
  if (theme === "theme-lavender") {
    return "bg-[#cb4fea] dark:bg-[#efafff]";
  } else if (theme === "theme-scienceblue") {
    return "bg-[#006fcc] dark:bg-[#38a5ff]";
  } else if (theme === "theme-pacificblue") {
    return "bg-[#009dbe] dark:bg-[#33d0f1]";
  } else if (theme === "theme-robinblue") {
    return "bg-[#54bee4]";
  } else if (theme === "theme-surfiegreen") {
    return "bg-[#0f8268] dark:bg-[#2cbea2]";
  } else if (theme === "theme-bluechill") {
    return "bg-[#0fa487] dark:bg-[#87dfd0]";
  } else if (theme === "theme-buddhagold") {
    return "bg-[#c59402] dark:bg-[#ffd745]";
  } else if (theme === "theme-indochine") {
    return "bg-[#d17d00] dark:bg-[#ef9700]";
  } else if (theme === "theme-persianred") {
    return "bg-[#d13c3c] dark:bg-[#de5959]";
  } else {
    return "bg-[#6738a5] dark:bg-[#b176ff]";
  }
}
