import { iconColor } from "colors";
import { RedoIcon } from "icons";
import { useIntl } from "react-intl";
import { type AccountGroupInfosType } from "requests";
import { type RowInstance } from "table";
import { Tooltip } from "ui";

type GroupNameCellProps = {
  row: Pick<RowInstance<AccountGroupInfosType>, "original">;
};

export default function GroupNameCell({ row }: GroupNameCellProps) {
  const { autoCreated, groupName } = row.original;
  const intl = useIntl();

  return (
    <span className="space-x-2">
      <span>{groupName}</span>
      {autoCreated && (
        <Tooltip
          label={intl.formatMessage({
            defaultMessage: "Auto Created",
            id: "qMxjqO",
            description: "label for Auto Created",
          })}
        >
          <>
            <RedoIcon size="xs" color={iconColor.gray} />
          </>
        </Tooltip>
      )}
    </span>
  );
}
