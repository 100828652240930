import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { dashboardRoute } from "../../../../dashboardRoute";

export const AssetListSideCar = lazy(() => import("./AssetListSideCar"));

export const AssetListSearchSchema = z.object({
  cloudAccountId: z.union([z.number(), z.string()]).optional(),
  countryCode: z.string().optional(),
  convertible: z.boolean().optional(),
  assetsCount: z.number().optional(),
  cloudAccountName: z.string().optional(),
  alertCategories: z.string().optional(),
  enableRowSelect: z.boolean().optional(),
  countryName: z.string().optional(),
});

export const assetListSidecarRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/nonPrismaCloudAsset/$nonPrismaCloudAsset",
  component: AssetListSideCar,
  validateSearch: AssetListSearchSchema,
});
