import { useState, type ReactNode } from "react";
import { useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

type AccountDetailsProps = {
  children: ReactNode;
};

export default function AccountDetails({ children }: AccountDetailsProps) {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(true);
  const toggle = () => setOpen((prev) => !prev);

  return (
    <Accordion>
      <AccordionPanel
        canClose={true}
        isOpen={isOpen}
        onClick={toggle}
        title={intl.formatMessage({
          defaultMessage: "Account Details",
          id: "fEX82K",
          description: "Description for Account Details on review status",
        })}
      >
        <section className="my-2">{children}</section>
      </AccordionPanel>
    </Accordion>
  );
}
