import { defineMessages } from "react-intl";

export const fwScanMessages = defineMessages({
  addNew: {
    defaultMessage: "Add New",
    id: "FGu3dS",
    description: "Dropdown option",
  },
  bucketForLogFiles: {
    defaultMessage: "Bucket for Log Files",
    id: "M3djFw",
    description: "Heading for input fields",
  },
  cloudTrail: {
    defaultMessage: "CloudTrail",
    id: "laV/dk",
    description: "Heading for input fields",
  },
  consoleSetup: {
    defaultMessage: "Console Setup (Optional)",
    id: "5UJnAi",
    description: "Wizard page subtitle",
  },
  intro: {
    defaultMessage:
      "Set up forward scanning to continuously scan resources for data security, by creating a new or selecting an existing CloudTrail, SNS Topic, or Bucket for Log Files.",
    id: "2AE3aQ",
    description: "Introductory text at the top of a wizard page",
  },
  selectExisting: {
    defaultMessage: "Select Existing",
    id: "IrvLJS",
    description: "Dropdown option",
  },
  snsTopic: {
    defaultMessage: "SNS Topic",
    id: "IoQX3R",
    description: "Heading for input fields",
  },
  snsEndpoint: {
    defaultMessage: "SNS Endpoint",
    id: "CBhPoK",
    description: "Wizard page subtitle",
  },
  title: {
    defaultMessage: "Define Forward Scan",
    id: "NR2uyU",
    description: "Wizard page heading",
  },
});
