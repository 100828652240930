import { Outlet } from "@tanstack/react-router";
import { isGov } from "environment";
import { useCodeEnabled, usePermissions } from "prisma";
import { FormattedMessage } from "react-intl";
import { LoadingPage, PermissionDenied } from "ui";
import {
  SubNavHeader,
  SubNavRouterLink,
  UCDSubNav,
} from "../../components/SubNav";
import {
  casCicdRisksRoute,
  casProjectsRoute,
  casRepositoriesRoute,
  casSbomRoute,
  casTechnologiesRoute,
} from "./codeRoute";

export function HomeAppSecLayout() {
  const permissions = usePermissions();
  const { codeEnabled, isLoading } = useCodeEnabled();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!codeEnabled) {
    return <PermissionDenied />;
  }

  return (
    <>
      <UCDSubNav
        headerText={
          <FormattedMessage
            defaultMessage="Application Security Home"
            id="eCyx44"
            description="Header text for sub navigation"
          />
        }
      >
        {!isGov() && (
          <>
            <SubNavHeader>
              <FormattedMessage
                defaultMessage="Visibility"
                id="tRRzv9"
                description="nav header"
              />
            </SubNavHeader>
            <SubNavRouterLink to={casRepositoriesRoute.id}>
              <FormattedMessage
                defaultMessage="Repositories"
                id="nqSKTf"
                description="nav link"
              />
            </SubNavRouterLink>
            <SubNavRouterLink to={casTechnologiesRoute.id}>
              <FormattedMessage
                defaultMessage="Technologies"
                id="z8aKpN"
                description="nav link"
              />
            </SubNavRouterLink>
            <SubNavRouterLink to={casSbomRoute.id}>
              <FormattedMessage
                defaultMessage="SBOM"
                id="7VzvgB"
                description="nav link"
              />
            </SubNavRouterLink>
          </>
        )}
        <SubNavHeader>
          <FormattedMessage
            defaultMessage="Code"
            id="f3mrsm"
            description="nav header"
          />
        </SubNavHeader>
        {permissions.codeSecurityProjects.READ && (
          <SubNavRouterLink to={casProjectsRoute.id}>
            <FormattedMessage
              defaultMessage="Projects"
              id="jihGcR"
              description="nav link"
            />
          </SubNavRouterLink>
        )}
        {!isGov() && (
          <>
            <SubNavHeader>
              <FormattedMessage
                defaultMessage="CI/CD"
                id="LPkZww"
                description="nav header"
              />
            </SubNavHeader>
            <SubNavRouterLink to={casCicdRisksRoute.id}>
              <FormattedMessage
                defaultMessage="CI/CD Risks"
                id="9wxFWx"
                description="nav link"
              />
            </SubNavRouterLink>
          </>
        )}
      </UCDSubNav>
      <Outlet />
    </>
  );
}
