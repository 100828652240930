import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const OciIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "OCI",
    id: "7YPzAq",

    description: "cloud icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} viewBox="0 0 31.4 19.8" {...props}>
      {showNativeTooltip && <title>{alt}</title>}
      <path
        className="fill-[#E2231A] dark:fill-white"
        d="M9.9,22.4a9.9,9.9,0,0,1,0-19.8H21.5a9.9,9.9,0,0,1,0,19.8H9.9m11.3-3.5a6.4,6.4,0,0,0,0-12.8h-11a6.38,6.38,0,0,0-6.4,6.4,6.44,6.44,0,0,0,6.4,6.4h11"
        transform="translate(0 -2.6)"
      />
    </SvgIcon>
  );
};
