import {
  type AccountGroupParamsSchema,
  type AccountGroupsTableParamsSchema,
} from "./accountGroupsTable";

import { type z } from "zod";

export const accountGroupsKeys = {
  all: [{ scope: "accountGroups" }] as const,
  accountGroupsTable: ({
    excludeCloudAccountDetails,
  }: z.infer<typeof AccountGroupsTableParamsSchema>) =>
    [
      {
        ...accountGroupsKeys.all[0],
        entity: "accountGroupsTable",
        excludeCloudAccountDetails,
      },
    ] as const,

  flagData: () =>
    [
      {
        ...accountGroupsKeys.all[0],
        entity: "flagData",
      },
    ] as const,

  nestedAccountGroupFlagData: () =>
    [
      {
        ...accountGroupsKeys.all[0],
        entity: "nestedAccountGroupFlagData",
      },
    ] as const,

  accountGroupData: ({
    id,
    includeAccountInfo,
  }: z.infer<typeof AccountGroupParamsSchema>) =>
    [
      {
        ...accountGroupsKeys.all[0],
        entity: "accountGroupData",
        id,
        includeAccountInfo,
      },
    ] as const,
} as const;
