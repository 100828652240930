import { useTenantsFeatures } from "prisma";
import { useMemo } from "react";

export const useIdentitySubscription = () => {
  const { data: tenantFeatures, isLoading } = useTenantsFeatures({
    features: "iam-security",
  });

  const isSubscribed = useMemo(
    () =>
      tenantFeatures &&
      tenantFeatures[0]?.state === "PROVISION_SUCCESSFUL" &&
      tenantFeatures[0]?.enabled,
    [tenantFeatures],
  );
  return {
    isSubscribed: !!isSubscribed,
    isLoading,
  };
};
