import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const DatabricksIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Databricks",
    id: "Qnn6TM",
    description: "Databricks alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <path
        d="m21.912 9.882-9.895 5.61L1.504 9.517l-.491.265v4.336l11.016 6.277 9.895-5.61v2.307l-9.895 5.61-10.525-5.975-.491.265v.73L12.029 24l11.055-6.277v-4.336l-.492-.265-10.575 5.975-9.857-5.61V11.22l9.857 5.57 11.054-6.276V6.239l-.567-.302-10.487 5.937-9.328-5.307 9.328-5.269 7.701 4.336.694-.365v-.53L12.017 0 1 6.252v.668l11.017 6.277 9.895-5.609v2.294Z"
        fill="#FF3621"
      />
    </SvgIcon>
  );
};
