import { z } from "zod";

export const StatusSchema = z.union([
  z.literal("ok"),
  z.literal("error"),
  z.literal("warning"),
  z.literal("success"),
  z.literal("disabled"),
  z.literal("enabled"),
  z.literal("pending"),
]);

export const PermissionsStatusComponentSchema = z.object({
  displayName: z.string().optional(),
  errorCode: z.string().optional(),
  lastRunEpochMillis: z.number().optional(),
  lastSuccessEpochMillis: z.number().optional(),
  lastErrorEpochMillis: z.number().optional(),
  message: z.string(),
  remediation: z.string().optional(),
  status: StatusSchema,
});

const StatusComponent = z
  .object({
    components: z.array(PermissionsStatusComponentSchema).optional(),
  })
  .merge(PermissionsStatusComponentSchema);

export type PermissionsStatusComponent = z.infer<typeof StatusComponent>;

export const PermissionsStatusSchema = z
  .object({
    accountId: z.string().optional(),
    components: z.array(PermissionsStatusComponentSchema).optional(),
    feature: z.string().optional(),
  })
  .merge(PermissionsStatusComponentSchema);

export const PermissionsStatusResponseSchema = z.array(PermissionsStatusSchema);

export type PermissionsStatus = z.infer<typeof PermissionsStatusSchema>;
