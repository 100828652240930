import { type PropGetters } from "downshift";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  type CSSProperties,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { equals } from "remeda";

import { useVirtualizer } from "@tanstack/react-virtual";

import { useInfiniteScroll, type UseInfiniteScrollHookArgs } from "requests";
import { noop } from "utils";
import { List, ListItem } from "../List";
import { Body } from "../Typography";
import { type SelectItem } from "./Select";
import { SelectAllListItem } from "./SelectAllListItem";
import { SelectListLoadingSkeleton } from "./SelectListLoadingSkeleton";
import { type SelectWithoutSearchProps } from "./SelectWithoutSearch";

const ListWidthConst = { sm: "sm", md: "md", lg: "lg" } as const;
const SHIMMER_ITEMS_COUNT = 7;
// Number of element from the end
const WAYPOINT_POSITION = 2;
const DEFAULT_LIST_ITEM_STYLES: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
};

export type ListWidthType = keyof typeof ListWidthConst;

export type SelectListProps = Pick<
  SelectWithoutSearchProps,
  "menuStyle" | "enableMultiSelect"
> & {
  emptyState?: ReactNode;
  isLoading?: boolean;
  isOpen: boolean;
  isSelectAllDisabled?: boolean;
  items: SelectItem[];
  infiniteScroll?: UseInfiniteScrollHookArgs;
  highlightedIndex: number;
  getItemProps: PropGetters<SelectItem>["getItemProps"];
  getMenuProps: PropGetters<SelectItem>["getMenuProps"];
  handleSelectAll?: (action: string) => void;
  search?: ReactNode;
  showSelectAll: boolean;
  allSelected?: boolean;
  selectAllItem: SelectItem;
  selectedItems: SelectItem[];
  selectedValues: SelectItem["value"][];
  dataSelector?: string;
  /**
   * If a ReactElement is passed, the menu will not close when you click on it.
   * If you need to close the menu, pass a function that will accept
   * downshift's getItemProps and use that to add onClick, or any other props
   * returned by getItemProps to you footer item.  This onClick will cause the menu
   * to close.
   */
  footer?:
    | ReactElement
    | ((getItemProps: PropGetters<SelectItem>["getItemProps"]) => ReactElement);
  clearSelection: (event: MouseEvent) => void;
  allowClear: boolean;
  /**
   * Use `listHeight` + `listWidth` to hard code the size of the container.
   * Use `itemHeight` to rely on the virtualizer to figure out the container's size.
   */
  customListItem?: {
    listHeight?: string;
    listWidth?: ListWidthType;
    itemHeight?: number;
  };
};

export const listItemSize = 33;

export function SelectList(props: SelectListProps): ReactElement {
  const {
    emptyState = (
      <Body
        addClassName="flex h-full items-center p-2"
        appearance="secondary"
        as="div"
      >
        <FormattedMessage
          defaultMessage="No matching options"
          id="5fyT19"
          description="Message when no options are found in a list"
        />
      </Body>
    ),
    isLoading = false,
    allSelected = false,
    isSelectAllDisabled = false,
    isOpen,
    menuStyle,
    getMenuProps,
    handleSelectAll = noop,
    items,
    infiniteScroll = {
      loading: false,
      disabled: true,
      onLoadMore: noop,
    },
    highlightedIndex,
    search = null,
    footer,
    selectedItems,
    selectAllItem,
    enableMultiSelect,
    getItemProps,
    showSelectAll,
    selectedValues,
    dataSelector = "",
    clearSelection,
    allowClear,
    customListItem,
  } = props;
  const intl = useIntl();

  const measureRef = useRef<HTMLDivElement>();

  const [infiniteScrollRef, { rootRef }] = useInfiniteScroll(infiniteScroll);

  const estimateSize = useCallback(
    () => customListItem?.itemHeight ?? listItemSize,
    [customListItem?.itemHeight],
  );

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => measureRef.current,
    estimateSize,
    overscan: 10,
  });

  const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

  const loadingIndicationStart = useMemo(() => {
    if (!lastItem) {
      return 0;
    }

    if (showSelectAll) {
      return lastItem.start;
    }

    return lastItem.start + lastItem.size;
  }, [lastItem, showSelectAll]);

  useEffect(() => {
    if (isOpen && highlightedIndex > 0 && !enableMultiSelect)
      rowVirtualizer.scrollToIndex(highlightedIndex - 1);
  }, [rowVirtualizer, highlightedIndex, isOpen, enableMultiSelect]);

  const totalHeight = useMemo(() => {
    if (isLoading) return "auto";

    if (customListItem?.listHeight) return customListItem?.listHeight;

    if (!items?.length) return undefined;

    const totalSize = rowVirtualizer.getTotalSize();

    if (showSelectAll) return totalSize - listItemSize;

    return totalSize;
  }, [
    customListItem?.listHeight,
    isLoading,
    items?.length,
    rowVirtualizer,
    showSelectAll,
  ]);

  const width =
    customListItem?.listWidth === "md"
      ? "24rem"
      : customListItem?.listWidth === "lg"
        ? "30rem"
        : "";

  return (
    <List
      isOpen={isOpen}
      style={{
        margin: "0.1rem 0",
        ...(showSelectAll ? { minWidth: "18rem" } : {}),
        ...menuStyle,
        maxHeight: "",
      }}
      data-testid={`select-list ${dataSelector}`}
      {...getMenuProps()}
    >
      {(search || showSelectAll) && (
        <div className="rounded-t border-b bg-white dark:border-blue-steel-850 dark:bg-blue-steel-950">
          {search}
          {showSelectAll && (
            <SelectAllListItem
              highlightedIndex={highlightedIndex}
              selectedItems={selectedItems}
              selectAllItem={selectAllItem}
              isSelectAllDisabled={isSelectAllDisabled}
              allSelected={allSelected}
              isLoading={isLoading}
              getItemProps={getItemProps}
              onClick={handleSelectAll}
            />
          )}
        </div>
      )}

      {allowClear && !!selectedItems.length && (
        <ListItem
          onClick={(e) => {
            e.stopPropagation();
            clearSelection(e);
          }}
        >
          {intl.formatMessage({
            defaultMessage: "(None)",
            id: "/vtZJY",

            description: "Clear select item selection",
          })}
        </ListItem>
      )}
      <div
        className="overflow-auto bg-white dark:bg-blue-steel-950"
        data-testid="SelectList-items-wrapper"
        ref={(node) => {
          if (node) {
            measureRef.current = node;
            rootRef(node);
          }
        }}
        style={{ maxHeight: menuStyle?.maxHeight ?? `${listItemSize * 7.5}px` }}
      >
        <div className={"relative"} style={{ height: totalHeight, width }}>
          {isLoading && (
            <div className="flex items-center justify-center bg-white p-4 dark:bg-blue-steel-950">
              <SelectListLoadingSkeleton
                count={SHIMMER_ITEMS_COUNT}
                enableMultiSelect={enableMultiSelect}
              />
            </div>
          )}
          {!isLoading &&
            rowVirtualizer.getVirtualItems().length === 0 &&
            emptyState}
          {!isLoading &&
            rowVirtualizer.getVirtualItems().length >= 0 &&
            rowVirtualizer.getVirtualItems().map((virtualItem) => {
              if (
                virtualItem.index === 0 &&
                items[virtualItem.index] === selectAllItem
              )
                return null;
              const { onClick, ...item } = items[virtualItem.index];
              const start = showSelectAll
                ? virtualItem.start - virtualItem.size
                : virtualItem.start;
              const highlighted = highlightedIndex === virtualItem.index;
              const itemProps = getItemProps({
                disabled: item.disabled,
                item,
                index: virtualItem.index,
                onClick: (e) => {
                  e?.stopPropagation();
                  onClick?.(e as MouseEvent<HTMLLIElement>);
                },
              });
              const checked = selectedValues.some((v) => {
                return equals(v, item.value);
              });
              // override aria-selected when multiselect because getItemProps only sets the last selected item as true.
              // and it doesn't get set on single select when None is selected
              itemProps["aria-selected"] = checked;

              // Infinite scroll waypoint.
              // Handler, onLoadMore, will be triggered when the third element from the end is rendered
              if (
                !infiniteScroll.disabled &&
                lastItem.index - WAYPOINT_POSITION === virtualItem.index
              ) {
                itemProps["ref"] = infiniteScrollRef;
              }

              const style = customListItem?.listHeight
                ? { width: "100%", maxHeight: customListItem?.listHeight }
                : {
                    ...DEFAULT_LIST_ITEM_STYLES,
                    transform: `translateY(${start}px)`,
                  };

              return (
                <ListItem
                  active={highlighted}
                  checked={checked}
                  hasCheckbox={enableMultiSelect}
                  key={JSON.stringify(item.value)}
                  addClassName="space-x-2 border-b-0"
                  {...itemProps}
                  {...item}
                  hasCustomListItem={
                    !!customListItem?.listHeight || !!customListItem?.itemHeight
                  }
                  style={style}
                >
                  {item.children || item.value}
                </ListItem>
              );
            })}
          {infiniteScroll.loading && (
            <div
              className="px-4 py-2"
              style={{
                ...DEFAULT_LIST_ITEM_STYLES,
                transform: `translateY(${loadingIndicationStart}px)`,
              }}
            >
              <SelectListLoadingSkeleton
                count={SHIMMER_ITEMS_COUNT}
                enableMultiSelect={enableMultiSelect}
              />
            </div>
          )}
        </div>
      </div>
      {footer && (
        <div className="border-t border-primary bg-white dark:bg-blue-steel-950">
          {typeof footer === "function" ? footer(getItemProps) : footer}
        </div>
      )}
    </List>
  );
}
