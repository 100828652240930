import { defineMessage, defineMessages } from "react-intl";
import { AWS, AZURE, GCP } from "../../constants";

export const loadMoreMessages = defineMessages({
  [AWS]: {
    defaultMessage: "Load more in {parentName}",
    id: "LT0+t8",
    description: "Description for aws load more in parent",
  },
  [AZURE]: {
    defaultMessage: "Load more in {parentName}",
    id: "rQOxCr",
    description: "Description for azure load more in parent",
  },
  [GCP]: {
    defaultMessage: "Load more {childrenType} in {parentName}",
    id: "jlXLHZ",
    description: "Description for gcp load more {childrenType} in parent",
  },
});

export const loadMoreMessageFallback = defineMessage({
  defaultMessage: "Load more in {parentName}",
  id: "zELGR7",
  description: "Description for default load more in parent",
});
