/* c8 ignore start */
import { type Set } from "immutable";
import { dynamicFormatMessage } from "ui";

import { type IntlShape } from "react-intl";
import { type AzureSelectMonitoredProjectsAncestorsType } from "requests";
import {
  ALL,
  APPLICATION_ID,
  APPLICATION_KEY,
  AZURE_DEFAULT_EXPANDED_NODES,
  AZURE_FOLDER_VIEW_CREDENTIALS_ERROR,
  AZURE_LOADED_NODES,
  AZURE_OU_IS_LOADING,
  AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  AZURE_PROJECT_SELECT_MODE,
  AZURE_SELECTED_NODES,
  AZURE_SELECTED_NODES_INITIAL,
  AZURE_SELECT_PROJECTS_INITIALIZED,
  AZURE_TREE_MAP,
  CLEAR_FTU_FIELD_ERRORS,
  ENVIRONMENT_TYPE,
  INIT_AZURE_TREE_MAP,
  MONITOR_FLOW_LOGS,
  NAME,
  SERVICE_PRINCIPAL_ID,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  TENANT_ID,
  UPDATE_AZURE_NODES,
} from "../../../../../../../constants";
import { memberAccountValidationErrorMessages } from "../../../../../../../messages/memberAccountValidationErrorMessages";
import {
  getAzureProjectSelectMode,
  getAzureRootId,
  getAzureSelectedNodes,
} from "../getters";
import { type AzureSelectMonitoredProjectInitialStateType } from "../initialState";

export const setFTUFieldErrors = (payload: {
  field: string;
  errors: string[];
}) => ({
  payload,
  type: SET_FTU_FIELD_ERRORS,
});

export const clearFTUFieldErrors = (payload: { field: string }) => ({
  payload,
  type: CLEAR_FTU_FIELD_ERRORS,
});

export const setFTUFieldValue = (payload: {
  field: string;
  value: unknown;
}) => ({
  payload,
  type: SET_FTU_FIELD_VALUE,
});

export const initAzureTreeMap = (payload: Record<string, unknown>) => ({
  payload,
  type: INIT_AZURE_TREE_MAP,
});

export const updateAzureTreeData = (payload: Record<string, unknown>) => ({
  payload,
  type: UPDATE_AZURE_NODES,
});

export const setAzureProjectSelectMode = (
  value: string,
  dispatch: React.Dispatch<unknown>,
) => {
  dispatch(setFTUFieldValue({ field: AZURE_PROJECT_SELECT_MODE, value }));
  dispatch(clearFTUFieldErrors({ field: AZURE_SELECTED_NODES }));
};

export const setAzureSelectedNodes = (
  value: Set<string>,
  dispatch: React.Dispatch<unknown>,
) => {
  dispatch(setFTUFieldValue({ field: AZURE_SELECTED_NODES, value }));
  dispatch(clearFTUFieldErrors({ field: AZURE_SELECTED_NODES }));
};

export const setAzureFoldersCredentialError = (
  value:
    | {
        field: string;
        value: unknown;
      }
    | string,
) => setFTUFieldValue({ field: AZURE_FOLDER_VIEW_CREDENTIALS_ERROR, value });

export const setAzurePreviouslySelectedNodesNotFound = (
  value: AzureSelectMonitoredProjectsAncestorsType,
) =>
  setFTUFieldValue({ field: AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND, value });

export const setAzureSelectProjectsInitialized = (value: boolean) =>
  setFTUFieldValue({ field: AZURE_SELECT_PROJECTS_INITIALIZED, value });

export const setAzureSelectedNodesInitial = (value: {
  field: string;
  value: unknown;
}) => setFTUFieldValue({ field: AZURE_SELECTED_NODES_INITIAL, value });

export const setAzureOUSelectProjectsIsLoading = (value: boolean) =>
  setFTUFieldValue({ field: AZURE_OU_IS_LOADING, value });

export const setApplicationKey = (value: string) =>
  setFTUFieldValue({ field: APPLICATION_KEY, value });

export const setApplicationId = (value: string) =>
  setFTUFieldValue({ field: APPLICATION_ID, value });

export const setTenantId = (value: string) =>
  setFTUFieldValue({ field: TENANT_ID, value });

export const setServicePrincipalId = (value: string) =>
  setFTUFieldValue({ field: SERVICE_PRINCIPAL_ID, value });

export const setAccountName = (value: string) =>
  setFTUFieldValue({ field: NAME, value });

export const setMonitorFlowLogs = (value: boolean) =>
  setFTUFieldValue({ field: MONITOR_FLOW_LOGS, value });

export const setEnvironmentType = (value: string) =>
  setFTUFieldValue({ field: ENVIRONMENT_TYPE, value });

export const setAzureLoadedNodes = (payload: string) => ({
  payload,
  type: AZURE_LOADED_NODES,
});

export const setAzureTreeMap = (payload: Record<string, unknown>) => ({
  payload,
  type: AZURE_TREE_MAP,
});

export const setAzureExpandedNodes = (value: Set<string>) =>
  setFTUFieldValue({ field: AZURE_DEFAULT_EXPANDED_NODES, value });

export const handleSubmitAzureOrgUnitSelection = (
  intl: IntlShape,
  state: AzureSelectMonitoredProjectInitialStateType,
  dispatch: React.Dispatch<unknown>,
) => {
  const mode = getAzureProjectSelectMode(state);
  const selectedNodes = getAzureSelectedNodes(state);
  const rootId = getAzureRootId(state);

  const noItemSelected = selectedNodes.size === 0;
  const allItemsSelected = selectedNodes.has(rootId);

  let errors = [];
  if (mode !== ALL && (noItemSelected || allItemsSelected)) {
    const type = noItemSelected ? "none" : "all";
    errors = [
      dynamicFormatMessage(
        intl,
        memberAccountValidationErrorMessages,
        `azure_${type}_${mode.toLowerCase()}`,
      ),
    ];
    dispatch(setFTUFieldErrors({ errors, field: AZURE_SELECTED_NODES }));
  } else {
    dispatch(clearFTUFieldErrors({ field: AZURE_SELECTED_NODES }));
  }
  return errors.length === 0;
};
/* c8 ignore stop */
