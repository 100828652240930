import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const ApplicationsDefinition = lazy(
  () => import("./ApplicationsDefinition"),
);

export const settingsApplicationsDefinitionRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "applications",
  component: ApplicationsDefinition,
});
