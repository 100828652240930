import { type MouseEventHandler, type ReactElement } from "react";
import { useIntl } from "react-intl";
import { Button } from "../Button";
import { ConfirmationDialog } from "./ConfirmationDialog";

interface CloseConfirmationProps {
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onDiscard: MouseEventHandler<HTMLButtonElement>;
}

export const CloseConfirmation = ({
  onCancel,
  onDiscard,
}: CloseConfirmationProps): ReactElement => {
  const intl = useIntl();

  return (
    <ConfirmationDialog
      actions={
        <>
          <Button onClick={onCancel}>
            {intl.formatMessage({
              defaultMessage: "Cancel",
              id: "GiKY8T",

              description: "Label for button to cancel discarding changes",
            })}
          </Button>
          <Button onClick={onDiscard} focusOnMount appearance="danger">
            {intl.formatMessage({
              defaultMessage: "Discard Changes",
              id: "ONH5LC",

              description: "Label for button to confirm discarding changes",
            })}
          </Button>
        </>
      }
      prompt={intl.formatMessage({
        defaultMessage:
          "You have unsaved changes. Are you sure you want to proceed?",
        id: "Adu5MF",

        description:
          "Confirmation prompt to discard changes to the current form",
      })}
      title={intl.formatMessage({
        defaultMessage: "Discard Changes?",
        id: "ltXBo9",

        description:
          "Confirmation prompt to discard changes to the current form",
      })}
    />
  );
};
