import { z } from "zod";

const option = z.object({
  id: z.string().optional(),
  name: z.string(),
  key: z.string().optional(),
  displayName: z.string().optional(),
});

export const fieldNameProps = z.object({
  fieldName: z.string(),
  displayName: z.string().optional(),
  type: z.string().optional(),
  options: z.array(option.partial()).optional(),
  redlockMapping: z.boolean().optional(),
  required: z.boolean().optional(),
  disabled: z.boolean().optional(),
  value: z.string().optional(),
  maxLength: z.number().optional(),
  typeaheadUri: z.string().optional(),
});

export const templateConfig = z.object({
  open: z.array(fieldNameProps).optional(),
  snoozed: z.array(fieldNameProps).optional(),
  resolved: z.array(fieldNameProps).optional(),
  dismissed: z.array(fieldNameProps).optional(),
  basic_config: z.array(fieldNameProps),
});

export type ConfigType = z.infer<typeof templateConfig>;

const notificationTemplateType = z.enum(["jira", "service_now", "email"]);

export type NotificationTemplateType = z.infer<typeof notificationTemplateType>;

export const notificationTemplateSchema = z.object({
  id: z.string(),
  integrationId: z.string().optional(),
  integrationType: notificationTemplateType,
  integrationName: z.string().nullable().optional(),
  name: z.string(),
  templateConfig: templateConfig,
  enabled: z.boolean().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedTs: z.union([z.number(), z.date()]).optional(),
  createdBy: z.string().optional(),
  createdTs: z.union([z.number(), z.date()]).optional(),
  module: z.string().optional(),
  templateType: z.literal("ticketing").optional(),
  customNote: z.string().optional(),
});

const TemplateDataSchema = notificationTemplateSchema.partial();
export type TemplateDataType = z.infer<typeof TemplateDataSchema>;

export type InitializeWizardWithDataProps = {
  title: string;
  isEdit: boolean;
  templateData?: TemplateDataType;
};

const notificationTemplate = notificationTemplateSchema.partial();

export type NotificationTemplateDataWithoutId = z.infer<
  typeof notificationTemplate
>;

export type NotificationTemplateData = z.infer<
  typeof notificationTemplateSchema
>;

export const notificationTemplateStatus = z.enum([
  "success",
  "info",
  "error",
  "warning",
]);

export const notificationTemplateStatusErrorDataSchema = z.object({
  status: notificationTemplateStatus,
  errorDetails: z
    .object({
      errorType: z.string().optional(),
      lastUpdated: z.number().optional(),
      message: z.string().optional(),
    })
    .optional(),
});

export const notificationTemplateStatusAPIErrorType = z.object({
  body: z.object({
    debugMessage: z.string(),
    status: notificationTemplateStatus,
    timestamp: z.string(),
    subErrors: z.array(z.record(z.string(), z.any()).optional()),
  }),
});

export type NotificationTemplateStatusErrorDataType = z.infer<
  typeof notificationTemplateStatusErrorDataSchema
>;

export type NotificationTemplateStatusErrorType = z.infer<
  typeof notificationTemplateStatusAPIErrorType
>;

export const IntegrationSchema = z.object({
  createdBy: z.string(),
  createdTs: z.number(),
  customerId: z.string().nullable(),
  description: z.string(),
  enabled: z.boolean(),
  id: z.string(),
  integrationConfig: z.object({
    hostUrl: z.string(),
    isCustomPayloadEnabled: z.boolean(),
    login: z.string().optional(),
    password: z.string().optional(),
    table: z
      .array(
        z.object({
          incident: z.boolean().optional(),
          sn_si_incident: z.boolean().optional(),
        }),
      )
      .optional(),
  }),
  integrationType: z.string(),
  lastModifiedBy: z.string(),
  lastModifiedTs: z.number(),
  name: z.string(),
});

export type FieldsType = {
  value: string;
  id: string;
  disabled: boolean;
  required: boolean;
};

export type NotificationsStatus = "valid" | "error" | "pending";

export type BasicConfigType = {
  displayName: string;
  fieldName: string;
  options: { id: string; name: string; key?: string }[];
  redlockMapping: boolean;
  required: boolean;
  type: string;
  value: string;
}[];

export type InitialValuesType = {
  templateId?: string;
  name?: string;
  integration?: { id: string; value: string };
  project?: { id: string; value: string; field: BasicConfigType | undefined };
  issueType?: {
    id: string;
    value: string;
    field: BasicConfigType | undefined;
  };
  configureStates?: {
    [key: string]: object;
  };
  createIncidentOnAlertReOpen?: boolean;
  incidentType?: {
    id: string;
    value: string;
    field: z.infer<typeof fieldNameProps>;
  };
  CustomNote?: string;
};

export type TemplateDetailsProps = {
  context: {
    isEdit: boolean;
    closeModal: () => void;
  };
};
