import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { type PermissionsStatusComponent } from "requests";
import {
  ConnectedTable,
  TableLayout,
  TableProvider,
  type ColumnOptions,
  type RowInstance,
} from "table";

import {
  MessageCell,
  NameCell,
  RemediationCell,
  StatusCell,
} from "./cellRenderers";

type TableProps = {
  setBreadCrumbItemName: (value: string) => void;
  setTableData: (value: PermissionsStatusComponent[]) => void;
  setIsDrillDownTriggered: (value: boolean) => void;
  tableData: PermissionsStatusComponent[];
};

export default function Table({
  setBreadCrumbItemName,
  tableData,
  setTableData,
  setIsDrillDownTriggered,
}: TableProps) {
  const columns = useMemo<ColumnOptions<PermissionsStatusComponent>[]>(
    () => [
      {
        accessor: "displayName",
        Cell: ({
          row,
        }: {
          row: Pick<RowInstance<PermissionsStatusComponent>, "original">;
        }) => (
          <NameCell
            row={row}
            setBreadCrumbItemName={setBreadCrumbItemName}
            setTableData={setTableData}
            setIsDrillDownTriggered={setIsDrillDownTriggered}
          />
        ),
        Header: (
          <FormattedMessage
            defaultMessage="Name"
            id="PIZW8F"
            description="Name column header"
          />
        ),
        width: 220,
      },
      {
        accessor: "status",
        Cell: StatusCell,
        Header: (
          <FormattedMessage
            defaultMessage="Status"
            id="eLQPek"
            description="Status column header"
          />
        ),
        width: 100,
      },
      {
        accessor: "message",
        Cell: MessageCell,
        Header: (
          <FormattedMessage
            defaultMessage="Message"
            id="twtdaD"
            description="Message column header"
          />
        ),
        width: 350,
      },
      {
        accessor: "remediation",
        Cell: RemediationCell,
        Header: (
          <FormattedMessage
            defaultMessage="Remediation"
            id="SeZWbn"
            description="Remediation column header"
          />
        ),
        width: 350,
      },
    ],
    [setBreadCrumbItemName, setIsDrillDownTriggered, setTableData],
  );

  return (
    <div className="flex flex-col space-y-2.5">
      <TableProvider columns={columns} data={tableData} enableResizeColumns>
        <div
          className="flex-auto overflow-auto [&>div>div]:border-0"
          style={{ maxHeight: "58vh" }}
        >
          <TableLayout>
            <ConnectedTable appearance="secondary" selector="subComponents" />
          </TableLayout>
        </div>
      </TableProvider>
    </div>
  );
}
