import { FormattedMessage, useIntl } from "react-intl";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../../components";

const monitorFeatures = {
  vulnerabilities: [
    {
      featureName: "computeMonitorVuln",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorCompliance",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorCodeRepos",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorImages",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorHosts",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorServerless",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  ci: [
    {
      featureName: "computeMonitorCI",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  runtime: [
    {
      featureName: "computeMonitorRuntimeContainers",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorRuntimeHosts",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorRuntimeServerless",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorRuntimeIncidents",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeSandbox",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  waas: [
    {
      featureName: "computeMonitorWAAS",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  cnnf: [
    {
      featureName: "computeMonitorCNNF",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  access: [
    {
      featureName: "computeMonitorAccessDocker",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computeMonitorAccessKubernetes",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  updates: [
    {
      featureName: "computeUIEventSubscriber",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
};

export function MonitorTab() {
  const intl = useIntl();
  const { vulnerabilities, ci, runtime, waas, cnnf, access, updates } =
    monitorFeatures as {
      vulnerabilities: Features[];
      ci: Features[];
      runtime: Features[];
      waas: Features[];
      cnnf: Features[];
      access: Features[];
      updates: Features[];
    };
  const { selectionStatus, onClickSelectAll } = useOperationSelectAll([
    ...vulnerabilities,
    ...ci,
    ...runtime,
    ...waas,
    ...cnnf,
    ...access,
    ...updates,
  ]);
  return (
    <div className="divide-y divide-primary">
      <Row
        label={intl.formatMessage({
          defaultMessage: "Select All",
          id: "aYkfGO",
          description: "Select all checkbox row",
        })}
        addClassName="border-b-2"
        read={
          <SelectAllCheckbox
            status={selectionStatus.read}
            onChange={() => {
              onClickSelectAll("READ");
            }}
          >
            <FormattedMessage
              defaultMessage="Select view for all compute monitor rows"
              id="IIj7Rk"
              description="Permission group section header"
            />
          </SelectAllCheckbox>
        }
        update={
          <SelectAllCheckbox
            status={selectionStatus.update}
            onChange={() => {
              onClickSelectAll("UPDATE");
            }}
          >
            <FormattedMessage
              defaultMessage="Select update for all compute monitor rows"
              id="egH2eS"
              description="Permission group section header"
            />
          </SelectAllCheckbox>
        }
      />

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Vulnerabilities &amp; Compliance Results"
          id="Ez8vnp"
          description="Permission group section header"
        />
      </div>
      {vulnerabilities.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="CI Results"
          id="sNwy/h"
          description="Permission group section header"
        />
      </div>
      {ci.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Runtime Results"
          id="H9yX17"
          description="Permission group section header"
        />
      </div>
      {runtime.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="WAAS Results"
          id="fwtf+Q"
          description="Permission group section header"
        />
      </div>
      {waas.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="CNNF Results"
          id="a+Fnqi"
          description="Permission group section header"
        />
      </div>
      {cnnf.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Access Results"
          id="e/42yP"
          description="Permission group section header"
        />
      </div>
      {access.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Updates"
          id="AXSHTh"
          description="Permission group section header"
        />
      </div>
      {updates.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}
    </div>
  );
}
