import { z } from "zod";
import { features } from "../cloudAccountStatus";
import {
  AccountTypeSchema,
  SecurityCapabilityFeaturesEnumSchema,
} from "../cloudAccountsCas";

export const SupportedFeaturesSchema = z.object({
  cloudType: z.string(),
  deploymentType: z.string(),
  accountType: z.string(),
  licenseType: z.string(),
  supportedFeatures: z.array(SecurityCapabilityFeaturesEnumSchema),
});

export const SupportedFeaturesRequestSchema = z.object({
  accountType: AccountTypeSchema,
});

export const UpdateSupportedFeaturesResponseSchema = z.object({
  code: z.number(),
  message: z.string(),
});

export const UpdateMemberFeaturesResponseSchema = z.object({
  memberIds: z.array(z.string()),
  features: z.array(features),
});

export type UpdateMemberFeaturesResponseSchemaType = z.infer<
  typeof UpdateMemberFeaturesResponseSchema
>;
