import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const TrayRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 24 24"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM3.5 6.5C3.5 5.94772 3.94772 5.5 4.5 5.5H19.5C20.0523 5.5 20.5 5.94772 20.5 6.5V17.5C20.5 18.0523 20.0523 18.5 19.5 18.5H4.5C3.94772 18.5 3.5 18.0523 3.5 17.5V6.5ZM12.5 6.5C11.9477 6.5 11.5 6.94772 11.5 7.5V16.5C11.5 17.0523 11.9477 17.5 12.5 17.5H18.5C19.0523 17.5 19.5 17.0523 19.5 16.5V7.5C19.5 6.94772 19.0523 6.5 18.5 6.5H12.5Z"
      />
      {props.children}
    </SvgIcon>
  );
};
