import { InfoTooltipIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { Bold, Toggle, Tooltip } from "ui";

type AutoScanOrgToggleProps = {
  onChange: () => void;
  toggleChecked: boolean;
};

export default function AutoScanOrgToggle({
  toggleChecked,
  onChange,
}: AutoScanOrgToggleProps) {
  return (
    <div className="flex items-center justify-between rounded pb-2 pl-4 dark:border-blue-steel-500">
      <div className="flex items-center space-x-1">
        <div className="mr-2 flex flex-col">
          <Bold as="p" addClassName="ml-2 flex items-center space-x-1">
            <FormattedMessage
              defaultMessage="Organization scan"
              id="sebX99"
              description="Feature name header for Organization scan card"
            />
            <div className="ml-2">
              <Tooltip
                label={
                  <FormattedMessage
                    defaultMessage="Enable Organization Scan to scan all accounts linked to this organization. To scan only specific accounts, toggle this option off and set up the desired member accounts for scanning later in the Cloud Accounts organization view."
                    id="4CgcH6"
                    description="Organization scan description"
                  />
                }
              >
                <span>
                  <InfoTooltipIcon />
                </span>
              </Tooltip>
            </div>
          </Bold>
        </div>
      </div>
      <div className="mr-4 flex w-2/3 items-center space-x-2">
        <div className="ml-8 flex space-x-2">
          <span className="text-xs">
            <FormattedMessage
              defaultMessage="{toggleChecked, select, true {Enabled} other {Disabled}}"
              id="7UmeLx"
              description="label for the organization scan toggle"
              values={{ toggleChecked }}
            />
          </span>
          <Toggle checked={toggleChecked} onClick={onChange} />
        </div>
      </div>
    </div>
  );
}
