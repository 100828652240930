import {
  createContext,
  useContext,
  useRef,
  type ReactElement,
  type ReactNode,
} from "react";

import { ParentSize } from "@visx/responsive";
import { LoadingIcon } from "icons";
import { classNames } from "utils";

export const TableLayoutContext = createContext<boolean>(false);
export function useTableLayoutEnabled(): boolean {
  return useContext(TableLayoutContext);
}

export interface TableLayoutProps {
  appearance?: "primary" | "secondary" | "card";
  /**
   * The main body of the table layout. The ConnectedTable should go here.
   */
  children: ReactNode;
  /**
   * Left side of the controls. Typically a group select.
   */
  controlLeft?: ReactNode;
  /**
   * Right side of the controls. Filtering, downloads, and column picking typically go here.
   */
  controlRight?: ReactNode;
  /**
   * If the table is used in a container with no paddings,
   * please set `controlMargin` to `true` so it adds x and top margins to the control.
   */
  controlMargin?: boolean;
  /**
   * Use to signify the table is being updated by a background fetch.
   */
  isBackgroundFetching?: boolean;
  /**
   * Enable overflow-auto on the table so it can be made scrollable.
   *
   * The layout should be wrapped in a `flex` div with a set height for this to work.
   */
  overflow?: boolean;
  /**
   * The paginator component for the table if there is one.
   *
   * `enablePagination` must be passed to the TableProvider.
   */
  paginator?: ReactNode;
  sidecar?: ReactNode;
  /**
   * A custom message for results.
   */
  resultsSummary?: ReactNode;
  /**
   * The minimum height the table should have. When there is a sidecar present, this will default to 353px.
   */
  minimumHeight?: number | string;
  /**
   * Will not apply a min height when overflow is true
   */
  noMinHeight?: boolean;
}

/**
 * Helps layout controls and pagination of a table.
 * Will handle spacing and add a border to the Table.
 */
export function TableLayout({
  appearance = "card",
  children,
  controlLeft,
  controlRight,
  controlMargin,
  isBackgroundFetching,
  paginator,
  sidecar,
  overflow = false,
  resultsSummary,
  minimumHeight,
  noMinHeight = false,
}: TableLayoutProps): ReactElement {
  const groupClasses = classNames(
    "flex grow flex-col",
    overflow
      ? "overflow-auto print:overflow-visible"
      : "overflow-hidden print:overflow-visible",
  );
  const tableClasses = classNames(
    "grow",
    overflow && "overflow-auto print:overflow-visible",
  );
  const minHeight = sidecar ? minimumHeight || 353 : minimumHeight;

  const paginatorRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="flex min-w-0 grow flex-col"
      style={{ minHeight: overflow && !noMinHeight ? 289 : 0 }}
    >
      {(controlLeft || controlRight) && (
        <div
          className={classNames(
            "mb-4 flex justify-between space-x-1",
            controlMargin && "mx-4 mt-2",
          )}
        >
          <div className="flex grow items-center space-x-1">
            {controlLeft}
            {isBackgroundFetching && (
              <div>
                <LoadingIcon className="ml-3" />
              </div>
            )}
          </div>
          <div className="flex space-x-1">{controlRight}</div>
        </div>
      )}
      <div
        className={classNames(
          "relative flex flex-1 bg-white",
          "dark:border-blue-steel-850 dark:bg-blue-steel-950",
          overflow && "overflow-auto print:overflow-visible",
          appearance !== "card" && "border",
        )}
      >
        <div className={groupClasses}>
          <div className={tableClasses} style={{ minHeight }}>
            <TableLayoutContext.Provider value={true}>
              {children}
            </TableLayoutContext.Provider>
          </div>
          {(paginator || resultsSummary) && (
            <div className="border-t px-4 py-2 dark:border-blue-steel-850">
              <ParentSize parentSizeStyles={{}}>
                {(parent) => {
                  const paginatorWidth = paginatorRef.current?.offsetWidth ?? 0;
                  const resultsWidth = resultsRef.current?.offsetWidth ?? 0;
                  const offset = 10;
                  const wrapped =
                    paginatorWidth + resultsWidth > parent.width - offset;

                  return (
                    <div
                      className={classNames(
                        "flex flex-wrap items-center gap-x-1 gap-y-2",
                        wrapped ? "justify-center" : "justify-between",
                      )}
                    >
                      <div ref={resultsRef}>{resultsSummary}</div>
                      <div ref={paginatorRef}>{paginator}</div>
                    </div>
                  );
                }}
              </ParentSize>
            </div>
          )}
        </div>
        {sidecar}
      </div>
    </div>
  );
}
