import { type Status } from "../../../../../types";
import { CollapsibleDetails } from "../../../SecurityCapabilitiesPermissions/components";

type AuthenticationProps = {
  data?: {
    message?: string;
    name?: string;
    status?: Status;
  };
  isCollapsibleDetails?: boolean;
  isAccountDetails?: boolean;
  isLoading?: boolean;
};

export function Authentication({
  data,
  isCollapsibleDetails = true,
  isAccountDetails = true,
  isLoading = false,
}: AuthenticationProps) {
  return (
    <CollapsibleDetails
      isLoading={isLoading}
      label="authentication"
      isCollapsibleDetails={isCollapsibleDetails}
      isAccountDetails={isAccountDetails}
      {...(data ? data : {})}
    />
  );
}
