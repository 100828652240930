import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Breadcrumbs,
  Button,
  DynamicFormattedMessage,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  dynamicFormatMessage,
  type RenderModalProps,
} from "ui";
import { snakeCase } from "utils";

import { type PermissionsStatusComponent } from "requests";
import { statusHeaderMap } from "../../messages/statusHeaderMessages";
import StatusDetailsTable from "./Table";

type StatusDetailsProps = RenderModalProps<{
  components: PermissionsStatusComponent[];
  displayName: string;
}>;

export default function StatusDetails({
  closeModal,
  components,
  displayName,
  isOpen,
}: StatusDetailsProps) {
  const intl = useIntl();

  const [tableData, setTableData] = useState<PermissionsStatusComponent[]>([]);
  const [isDrillDownTriggered, setIsDrillDownTriggered] = useState(false);
  const [breadCrumbItemName, setBreadCrumbItemName] = useState("");

  const statusBreadcrumbConfig = useMemo(
    () => [
      <Button
        key="drilldown"
        size="sm"
        appearance="clear"
        onClick={() => setIsDrillDownTriggered(false)}
      >
        <DynamicFormattedMessage
          messageMap={statusHeaderMap}
          token={snakeCase(displayName)}
        />
      </Button>,
      <span key="itemName">{breadCrumbItemName}</span>,
    ],
    [breadCrumbItemName, displayName],
  );

  return (
    <Modal onClose={closeModal} isOpen={isOpen} size="xl">
      <ModalHeader
        enableClose
        title={dynamicFormatMessage(
          intl,
          statusHeaderMap,
          snakeCase(displayName),
        )}
      />

      <ModalBody>
        {isDrillDownTriggered && (
          <Breadcrumbs breadcrumbs={statusBreadcrumbConfig} />
        )}
        <StatusDetailsTable
          setBreadCrumbItemName={setBreadCrumbItemName}
          setTableData={setTableData}
          setIsDrillDownTriggered={setIsDrillDownTriggered}
          tableData={isDrillDownTriggered ? tableData : components}
        />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          {
            <FormattedMessage
              defaultMessage="Close"
              id="zXZG/C"
              description="Footer for Integration Status Modal"
            />
          }
        </Button>
      </ModalFooter>
    </Modal>
  );
}
