import {
  Navigate,
  Outlet,
  useNavigate,
  useRouterState,
  type LinkOptions,
} from "@tanstack/react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { PageHeader, PageLayout, Tab, TabList } from "ui";

import { SettingsPageIcon } from "icons";
import { usePerformanceTracker, usePermissions } from "prisma";
import { integrationAndNotificationRoute } from "./integrationAndNotificationRoute";
import { integrationsRoute } from "./integrations/integrationsRoute";
import { notificationsRoute } from "./notifications/notificationsRoute";

export default function IntegrationAndNotificationLayout() {
  const intl = useIntl();
  const {
    location: { pathname },
  } = useRouterState();
  const navigate = useNavigate();
  usePerformanceTracker(
    "IntegrationAndNotificationSettings",
    "IntegrationAndNotificationSettings",
  );

  const {
    settingsIntegrations: { READ: canReadSettingsIntegrations },
    alertsNotificationTemplates: { READ: canReadAlertsNotificationTemplates },
    actionPlanNotificationTemplates: {
      READ: canReadActionPlanNotificationTemplates,
    },
  } = usePermissions();

  const handleNavigate = (nextTabId: string) => {
    navigate({ to: nextTabId } as LinkOptions);
  };

  return (
    <PageLayout
      documentTitle={[
        intl.formatMessage({
          defaultMessage: "Settings",
          id: "Pgzph9",
          description: "page header",
        }),
        intl.formatMessage({
          defaultMessage: "Integrations & Notifications",
          id: "qi8sNS",
          description: "page header",
        }),
      ]}
      header={
        <PageHeader
          icon={<SettingsPageIcon size="lg" />}
          title={intl.formatMessage({
            defaultMessage: "Integrations & Notifications",
            id: "qi8sNS",
            description: "page header",
          })}
        />
      }
      overflow
      allowed={
        canReadSettingsIntegrations ||
        canReadAlertsNotificationTemplates ||
        canReadActionPlanNotificationTemplates
      }
      tabList={
        <TabList activeTabId={pathname} onActiveChange={handleNavigate}>
          {pathname === integrationAndNotificationRoute.id && ( //integrationAndNotification
            <Navigate
              to={
                !canReadSettingsIntegrations &&
                (canReadAlertsNotificationTemplates ||
                  canReadActionPlanNotificationTemplates)
                  ? notificationsRoute.id
                  : integrationsRoute.id
              }
            />
          )}

          {canReadSettingsIntegrations && (
            <Tab id={integrationsRoute.id}>
              <FormattedMessage
                defaultMessage="Integrations"
                id="B061a3"
                description="tab title"
              />
            </Tab>
          )}

          {(canReadAlertsNotificationTemplates ||
            canReadActionPlanNotificationTemplates) && (
            <Tab id={notificationsRoute.id}>
              <FormattedMessage
                defaultMessage="Notification Templates"
                id="i40bEK"
                description="tab title"
              />
            </Tab>
          )}
        </TabList>
      }
    >
      <Outlet />
    </PageLayout>
  );
}
