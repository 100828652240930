import { type ComponentPropsWithoutRef, type ReactElement } from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";

import { titleize } from "../../utils/titleize";

export type DynamicFormattedMessageProps = {
  /**
   * The default message to display if the token is not found in messageMap
   */
  fallback?: MessageDescriptor;
  /**
   * A map of intl messages, as returned from
   * [defineMessages](https://formatjs.io/docs/intl#definemessagesdefinemessage)
   */
  messageMap: Record<string, MessageDescriptor>;
  /**
   * The key of the desired message in the messageMap
   */
  token: string;
} & Omit<
  ComponentPropsWithoutRef<typeof FormattedMessage>,
  keyof MessageDescriptor
>;

/**
 * Gracefully handles translation when a key may be missing from a messageMap.
 *
 * Use case: Many APIs return tokens that are translated on the client. We know
 * most of these and can translate appropriately, but sometimes an API will
 * return an unexpected token. This util handles the unexpected token gracefully.
 *
 * If all possible values are known, prefer [`FormattedMessage`](https://formatjs.io/docs/react-intl/components#formattedmessage) instead.
 */
export function DynamicFormattedMessage({
  fallback: userFallback,
  messageMap,
  token,
  ...passThrough
}: DynamicFormattedMessageProps): ReactElement {
  const message = messageMap[token];

  if (!message) {
    /* c8 ignore next */
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.trace(`Missed translation: "${token}" not found in messageMap.`);
    }

    const fallback = userFallback || {
      defaultMessage: titleize(token),
      description: "This is a fallback message and should not be translated.",
      id: "defaultFallback",
    };

    return <FormattedMessage {...fallback} {...passThrough} />;
  }

  return <FormattedMessage {...message} {...passThrough} />;
}
