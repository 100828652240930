import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const IdentityAndSecurityIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Identity And Security",
    id: "4EQhZh",

    description: "Asset Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 15 19" {...props}>
      <title>{alt}</title>
      <path d="M7.5 0L0 3.33333V8.3333C0 14.8583 5.36583 17.905 7.5 18.3333C9.6342 17.905 15 14.8583 15 8.3333V3.33333L7.5 0ZM7.5 1.82454L13.3333 4.41732V8.3333C13.3333 9.863 12.9718 11.1631 12.4219 12.2559C11.1214 11.2758 8.7701 10.8333 7.5 10.8333C6.2298 10.8333 3.87775 11.2758 2.57812 12.2559C2.02818 11.1631 1.66667 9.863 1.66667 8.3333V4.41732L7.5 1.82454ZM7.5 3.95833C6.0067 3.95833 4.79167 5.17333 4.79167 6.66667C4.79167 8.16 6.0067 9.375 7.5 9.375C8.9933 9.375 10.2083 8.16 10.2083 6.66667C10.2083 5.17333 8.9933 3.95833 7.5 3.95833ZM7.5 5.625C8.0742 5.625 8.5417 6.0925 8.5417 6.66667C8.5417 7.2408 8.0742 7.7083 7.5 7.7083C6.9258 7.7083 6.4583 7.2408 6.4583 6.66667C6.4583 6.0925 6.9258 5.625 7.5 5.625ZM7.5 12.5C9.5389 12.5 10.8148 13.1 11.5902 13.5954C10.2835 15.3134 8.5472 16.2917 7.5 16.6146C6.4528 16.2917 4.71648 15.3134 3.40983 13.5954C4.18525 13.1 5.46106 12.5 7.5 12.5Z" />
    </SvgIcon>
  );
};
