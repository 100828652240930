import { InfoTooltipIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FormattedMessage, useIntl } from "react-intl";
import { Toggle, Tooltip } from "ui";

type AutoScanOrgToggle = {
  onChange: () => void;
  checked: boolean;
};

export default function AutoScanOrgToggle({
  checked,
  onChange,
}: AutoScanOrgToggle) {
  const intl = useIntl();
  const { cloudScanMode } = useFlags();

  if (cloudScanMode) {
    return (
      <div className="flex space-x-2">
        <div className="flex">
          <span className="mr-2 text-xs">
            <FormattedMessage
              defaultMessage="Organization Scan"
              id="RqFll4"
              description="label for the organization scan toggle"
            />
          </span>
          <Tooltip
            label={intl.formatMessage({
              defaultMessage: `Enable Organization Scan to scan all accounts linked to this organization.
          To scan only specific accounts, toggle this option off and set up the desired member accounts for scanning later in the Cloud Accounts organization view.`,
              id: "AWn2zI",
              description: "Message on auto-scan toggle tooltip",
            })}
          >
            <span>
              <InfoTooltipIcon aria-label="infoIcon-autoScanOrg" />
            </span>
          </Tooltip>
        </div>

        <div className="flex pl-16">
          <span className="mr-2 text-xs">
            {checked ? (
              <FormattedMessage
                defaultMessage="Enabled"
                id="vTHijX"
                description="Toggle button for Enable the Organization Scan"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Disabled"
                id="BzBRi7"
                description="Toggle button for Disable the Organization Scan"
              />
            )}
          </span>
          <Toggle checked={checked} onClick={onChange} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex space-x-2">
      <Toggle checked={checked} onClick={onChange} />
      <span className="text-xs">
        <FormattedMessage
          defaultMessage="Organization Scan"
          id="RqFll4"
          description="label for the organization scan toggle"
        />
      </span>
      <Tooltip
        label={intl.formatMessage({
          defaultMessage: `Enable Organization Scan to scan all accounts linked to this organization.
          To scan only specific accounts, toggle this option off and set up the desired member accounts for scanning later in the Cloud Accounts organization view.`,
          id: "AWn2zI",
          description: "Message on auto-scan toggle tooltip",
        })}
      >
        <span>
          <InfoTooltipIcon aria-label="infoIcon-autoScanOrg" />
        </span>
      </Tooltip>
    </div>
  );
}
