import { animated, config, useTransition } from "@react-spring/web";
import { CloseIcon } from "icons";
import { type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Link, Title } from "ui";
import { classNames } from "utils";

function Instructions({ instructions }: { instructions: ReactNode[] }) {
  return (
    <div className="my-2 px-5 py-4 leading-5">
      <ol className="mb-4 list-decimal pl-2">
        <span className="text-2xs">
          {instructions.map((item, index) => (
            <li key={index} className="mb-2 last:mb-0">
              {item}
            </li>
          ))}
        </span>
      </ol>
    </div>
  );
}

type InstructionsSidecarProps = {
  children?: ReactNode;
  className?: string;
  instructions: ReactNode[];
  isOpen?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
};

export default function InstructionsSidecar({
  children,
  className = "",
  instructions,
  isOpen = false,
  setOpen,
  title,
}: InstructionsSidecarProps) {
  const intl = useIntl();

  const sidecarClasses = classNames(
    "z-10 border-l bg-white shadow dark:border-blue-steel-850 dark:bg-blue-steel-990",
    "absolute inset-y-0 right-0 flex h-full flex-col overflow-hidden",
  );
  const sidecarFooterClasses = classNames(
    "flex flex-row-reverse justify-between px-4 py-2",
    "border-t border-gray-300 bg-gray-100 dark:border-blue-steel-850 dark:bg-black/[.30]",
  );
  const transitions = useTransition(isOpen, {
    from: { width: 0 },
    enter: { width: 400 },
    update: { width: 400 },
    leave: { width: 0 },
    config: { ...config.stiff, clamp: true },
  });

  return (
    <div className={className}>
      <Link onClick={() => setOpen(true)} size="sm">
        {children}
      </Link>

      {transitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className={sidecarClasses}>
              <div className="flex h-full flex-col">
                <div className="flex justify-between space-x-2 border-b px-4 py-2 dark:border-blue-steel-850">
                  <div
                    // Both min-w-0 here and below are required for text truncating to work
                    className="flex min-w-0 items-center space-x-1"
                  >
                    <div className="flex min-w-0 flex-col">
                      <Title level={2} size="xxs" addClassName="flex" truncate>
                        {title}
                      </Title>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      onClick={() => setOpen(false)}
                      appearance="tertiary-clear"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Close",
                        id: "Wg61eK",
                        description: "Close sidecar",
                      })}
                      size="sm"
                      icon={<CloseIcon />}
                    />
                  </div>
                </div>

                <div className="flex grow flex-col overflow-auto text-default dark:text-dark-bg">
                  {Array.isArray(instructions) ? (
                    <Instructions instructions={instructions} />
                  ) : (
                    instructions
                  )}
                </div>
                <div className={sidecarFooterClasses}>
                  <Button
                    size="sm"
                    onClick={() => setOpen(false)}
                    data-selector="close-instructions-sidecar"
                  >
                    <FormattedMessage
                      defaultMessage="Done"
                      id="kD5+jN"
                      description="Button text for closing InstructionsSidecar"
                    />
                  </Button>
                </div>
              </div>
            </animated.div>
          ),
      )}
    </div>
  );
}
