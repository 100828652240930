import {
  ApplicationSecurityIcon,
  CloudSecurityIcon,
  RuntimeSecurityIcon,
} from "icons";
import { type ColorTheme, type UCDMode } from "requests";
import { useThemeColor } from "../../../utils/useThemeColor";

interface UCDIconProps {
  mode?: UCDMode;
  theme?: ColorTheme;
  isDark?: boolean;
  alt?: string;
}

export function UCDIcon({
  mode,
  theme,
  isDark,
  alt,
  // Necessary since the graph might set the `x` `y` props to the underlying icon
  ...props
}: UCDIconProps) {
  const { themeColor } = useThemeColor({
    themeOverride: theme,
    isDarkOverride: isDark,
  });

  return mode === "runtime" ? (
    <RuntimeSecurityIcon color={themeColor} alt={alt} {...props} />
  ) : mode === "application" ? (
    <ApplicationSecurityIcon color={themeColor} alt={alt} {...props} />
  ) : (
    <CloudSecurityIcon color={themeColor} alt={alt} {...props} />
  );
}
