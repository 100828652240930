import { z } from "zod";

export const cloudTypes = [
  "aws",
  "azure",
  "gcp",
  "ibm",
  "alibaba",
  "alibaba_cloud",
  "oci",
  "all",
] as const;
export const cloudTypesWithOther = [...cloudTypes, "other"] as const;

export const CloudTypesEnumSchema = z.enum(cloudTypes);
export const CloudTypesWithOtherEnumSchema = z.enum(cloudTypesWithOther);

export const CloudTypeResponseSchema = z.array(CloudTypesEnumSchema);
export const CloudTypeWithOtherResponseSchema = z.array(
  CloudTypesWithOtherEnumSchema,
);
export type CloudTypes = z.infer<typeof CloudTypesEnumSchema>;
export type cloudTypesWithOther = z.infer<typeof CloudTypesWithOtherEnumSchema>;

export const CloudTypesWithoutAllSchema = CloudTypesWithOtherEnumSchema.exclude(
  ["all"],
);
export type CloudTypesWithoutAllType = z.infer<
  typeof CloudTypesWithoutAllSchema
>;
