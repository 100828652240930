import { type ReactNode } from "react";
import { type IntlShape } from "react-intl";
import { Bold, CopyButton, Input, Link, TruncateText } from "ui";

export const getInstructions = ({ intl }: { intl: IntlShape }) => {
  const applyCmdToCopy =
    'terraform apply -var="IBM_CLOUD_API_KEY=<User-API-KEY>"';

  const values = {
    b: (chunks: ReactNode) => <Bold>{chunks}</Bold>,
  };

  return [
    intl.formatMessage(
      {
        defaultMessage:
          "Click <a>here</a> to login to IBM Cloud with administrator credentials.",
        id: "n93D0o",
        description: "Instruction to generate IBM Account Id & User API Key",
      },
      {
        a: (chunks: ReactNode) => (
          <Link
            href="https://cloud.ibm.com/login"
            size="sm"
            external
            data-selector="ibm-console-link"
          >
            {chunks}
          </Link>
        ),
      },
    ),
    intl.formatMessage(
      {
        defaultMessage:
          "Create a user API key- <b>Manage -> Access (IAM) -> API Keys -> Create API key</b>.",
        id: "D5aOon",
        description: "Instruction to generate IBM Account Id & User API Key",
      },
      values,
    ),
    intl.formatMessage(
      {
        defaultMessage:
          "Open <a>IBM Cloud Shell</a> via the console and run the Terraform script with the below command: {intiCmd} {applyCmd}",
        id: "Ane5Wx",
        description: "Instruction to generate IBM Account Id & User API Key",
      },
      {
        a: (chunks) => (
          <Link
            href="https://www.ibm.com/cloud/cloud-shell"
            size="sm"
            external
            data-selector="ibm-cloud-shell-link"
          >
            {chunks}
          </Link>
        ),
        intiCmd: <Input allowCopy readOnly value="terraform init" />,
        applyCmd: (
          <div className="mt-2 box-border flex h-8 items-center space-x-1 rounded border border-gray-100 bg-gray-100 px-2 py-1 dark:border-gray-1000 dark:bg-blue-steel-850">
            <TruncateText>{applyCmdToCopy}</TruncateText>
            <CopyButton text={applyCmdToCopy} />
          </div>
        ),
      },
    ),
    intl.formatMessage({
      defaultMessage: "Copy the service API key and Service IAM ID.",
      id: "uEIAuA",
      description: "Instruction to generate IBM Account Id & User API Key",
    }),
  ];
};
