import { SecurityCapabilitiesIcon } from "icons";
import { isEmpty } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";
import Details from "../Details";

type ThreatDetectionProps = {
  data: SecurityCapabilitiesPermissionsStatus[];
  isCollapsibleDetails?: boolean;
};

export default function ThreatDetection({
  data,
  isCollapsibleDetails = true,
}: ThreatDetectionProps) {
  if (isEmpty(data)) return null;

  return (
    <>
      <Details
        icon={<SecurityCapabilitiesIcon type="Threat Detection" />}
        label="threat_detection"
      />
      {data?.map(
        ({
          status,
          name,
          message,
          remediation = "",
          components = [],
          ...rest
        }: SecurityCapabilitiesPermissionsStatus) => (
          <CollapsibleDetails
            detailsClassName="mt-4"
            key={name}
            label={name}
            message={message}
            normalFont={true}
            status={status}
            isCollapsibleDetails={isCollapsibleDetails}
            remediation={remediation}
            components={components}
            {...rest}
          />
        ),
      )}
    </>
  );
}
