import { FormattedMessage, useIntl } from "react-intl";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormLayout } from "form";
import { useCallback, useEffect, useMemo } from "react";
import {
  cloudAccountsKeys,
  getOciCloudAccountStatus,
  saveOciCloudAccount,
  updateOciCloudAccount,
  type OnboardingStatusResponseSchema,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { useModal } from "ui";
import { useCloudAccountPendoGuide } from "../../../../../../../utils/useCloudAccountPendoGuide";
import {
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import { CONFIGURE_ACCOUNT, GET_STARTED, OCI } from "../../../../constants";
import { useIsRenderedFirstTime } from "../../../../hooks";
import {
  type OciConfigureAccountStepValuesType,
  type OciGetStartedStepValuesType,
} from "../../../../types";
import { parseI18nKeyFromResponse } from "../../../../utils";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import SaveAndCloseButton from "../../components/SaveAndCloseButton";
import SaveAndOnboardButton from "../../components/SaveAndOnboardButton";
import {
  getAuthenticationData,
  getWizardStepIndex,
  handleSuccess,
} from "../../utils";
import AccountDetailsStatus from "./AccountDetailsStatus";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";

type ReviewStatusProps = {
  context: {
    closeModal: () => void;
    enabled: boolean;
    isEdit: boolean;
  };
};

export default function ReviewStatus({
  context: { closeModal, enabled, isEdit },
}: ReviewStatusProps) {
  const intl = useIntl();
  const { showGuide } = useCloudAccountPendoGuide();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { openModal } = useModal(CloudSelection);
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedForFirstTime = useIsRenderedFirstTime();
  const getStepIndex = getWizardStepIndex(steps);
  const getStartedStepIndex = getStepIndex(GET_STARTED);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);

  const { accountType } = steps[getStartedStepIndex]
    ?.values as OciGetStartedStepValuesType;
  const {
    userName = "",
    groupName = "",
    policyName = "",
    userOcid = "",
    groupIds,
    homeRegion,
    name,
    accountId,
  } = steps[configureAccountIndex]?.values as OciConfigureAccountStepValuesType;

  //TODO: enable this after supportedFeaturesList API support
  // const { supportedFeaturesList, isLoading: isFeaturesLoading } =
  //   useGetSupportedFeaturesList({
  //     cloudType: OCI,
  //     payload: {
  //       accountType,
  //     },
  //   });

  const payload = useMemo(
    () => ({
      accountId,
      accountType,
      defaultAccountGroupId: groupIds,
      enabled,
      groupName,
      homeRegion: homeRegion.value,
      policyName,
      userName,
      userOcid,
      name,
    }),
    [
      accountId,
      accountType,
      enabled,
      groupName,
      homeRegion,
      policyName,
      userName,
      userOcid,
      name,
      groupIds,
    ],
  );

  const {
    data: accountStatus = [] as OnboardingStatusResponseSchema,
    isFetching: isCloudStatusLoading,
    isError,
    error,
  } = useQuery({
    queryKey: cloudAccountsKeys.ociCloudAccountStatus({ payload }),
    queryFn: getOciCloudAccountStatus,
    enabled: !isRenderedForFirstTime,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isError) return;

    toast(parseI18nKeyFromResponse(intl, error), {
      appearance: "error",
    });
    closeModal();
  }, [isError, error, intl, toast, closeModal]);

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({ mutationFn: () => saveOciCloudAccount({ payload }) });

  const { mutateAsync: updateConfig, isPending: isAccountUpdating } =
    useMutation({
      mutationFn: () => updateOciCloudAccount({ accountId, payload }),
    });

  const onSubmit = useCallback(
    async ({ openNewOnboarding = false }) => {
      try {
        if (isEdit) {
          await updateConfig();
        } else {
          await createConfig();
        }

        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });

        if (isEdit) {
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.cloudAccountsRest({
              accountId,
              cloudType: OCI,
              includeGroupInfo: "true",
            }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.permissionsStatus({ accountId }),
          });
        }
        closeModal();
        openNewOnboarding && openModal();
        if (!isEdit) showGuide();
        toast(handleSuccess(isEdit, intl), { appearance: "success" });
      } catch (error) {
        toast(parseI18nKeyFromResponse(intl, error), {
          appearance: "error",
        });
      }
    },
    [
      isEdit,
      queryClient,
      accountId,
      closeModal,
      openModal,
      showGuide,
      toast,
      updateConfig,
      createConfig,
      intl,
    ],
  );

  const accountDetailsData = {
    accountGroups: Array.isArray(groupIds) ? groupIds : [groupIds],
    accountName: name,
    userOcid,
    accountType,
    policyName,
    homeRegion: homeRegion.value,
    accountId,
    groupName,
    userName,
    //TODO: enable this after supportedFeaturesList API support
    // securityCapabilities: supportedFeaturesList.filter((feature) =>
    //   [MISCONFIGURATIONS].includes(feature)
    // ) as SecurityCapabilitiesType[],
    securityCapabilities: [
      "Cloud Visibility Compliance and Governance",
    ] as SecurityCapabilitiesType[],
    authentication: getAuthenticationData(
      accountStatus as OnboardingStatusResponseSchema,
    ),
  };

  //TODO: enable this after supportedFeaturesList API support
  // const isLoading = isCloudStatusLoading || isFeaturesLoading;
  const isLoading = isCloudStatusLoading;
  const isSubmitting = isAccountCreating || isAccountUpdating;
  const disableBtn = isLoading || isSubmitting;

  return (
    <WizardForm
      actions={
        <>
          <PrevButton disabled={disableBtn} />
          {!isEdit && (
            <SaveAndOnboardButton disabled={disableBtn} onSubmit={onSubmit} />
          )}
          <SaveAndCloseButton disabled={disableBtn} onSubmit={onSubmit} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <section className="space-y-4">
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Review account details, security capabilities and permissions."
            id="ocHZwf"
            description="description for review status title"
          />
        </span>
        <FormLayout>
          <AccountDetailsStatus {...accountDetailsData} isLoading={isLoading} />
          <SecurityCapabilitiesPermissions
            accountStatus={accountStatus}
            isLoading={isLoading}
          />
        </FormLayout>
      </section>
    </WizardForm>
  );
}
