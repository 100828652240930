import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ModalWizardFooter,
  SubmitButton,
  WizardStepForm,
  useCreateRecord,
  useUpdateRecord,
  useWizard,
} from "form";
import { LoadingIcon } from "icons";
import { useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  PermissionGroup,
  editPermissionGroup,
  newPermissionGroup,
  permissionGroupsKeys,
  type FeatureNames,
  type Features,
  type PermissionGroupData,
} from "requests";
import { Button } from "ui";
import { useRoleModalForm } from "../../../../roles/Forms";
import {
  type PermissionGroupWizardContext,
  type PermissionGroupWizardValues,
} from "../../PermissionGroupWizard";
import { AssignPermissionsSummary } from "./AssignPermissionsSummary";
import { DetailsSummary } from "./DetailsSummary";

export const errorMessagesMap = defineMessages({
  limit_reached: {
    defaultMessage:
      "Unable to add the permission group. You have reached the limit of {subject} for custom permission groups.",
    id: "+2Fptb",
    description: "Toast error message for form",
  },
  exceeding_length_for_name: {
    defaultMessage:
      "The permission group name is too long. The maximum length for the name is {subject} characters.",
    id: "GTB/2K",
    description: "Toast error message for form",
  },
  exceeding_length_for_description: {
    defaultMessage:
      "The permission group description is too long. The maximum length for the description is {subject} characters.",
    id: "q+qCoP",
    description: "Toast error message for form",
  },
  permission_group_already_exists: {
    defaultMessage:
      "Duplicate Name Error: Permission group name is not unique.",
    id: "akf+zT",
    description: "Toast error message for form",
  },
  active_roles_for_permission_group_exists: {
    defaultMessage:
      "Unable to delete the permission group. Users are currently assigned to this group. You must remove all users assigned to this group before deleting it.",
    id: "5C0nJD",
    description: "Toast error message for form",
  },
  permission_group_can_not_be_deleted: {
    defaultMessage: "Unable to delete the permission group.",
    id: "6dce0B",
    description: "Toast error message for form",
  },
  no_valid_operation_selected: {
    defaultMessage:
      "At least one permission must be enabled on a permission group.",
    id: "ugPDoS",
    description: "Toast error message for form",
  },
  invalid_permission_group_name: {
    defaultMessage: "The Permission group name you have entered is not valid.",
    id: "nxB/OL",
    description: "Toast error message for form",
  },
  permission_group_name_reserved: {
    defaultMessage:
      "The permission group name you have entered already exists.",
    id: "gAZ6EW",
    description: "Toast error message for form",
  },
});

function SaveAndAddRole({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: () => void;
}) {
  const intl = useIntl();
  const {
    context: { shouldAddRoleAfterSubmitRef },
  } = useWizard<
    string,
    PermissionGroupWizardValues,
    PermissionGroupWizardContext
  >();

  return (
    <Button
      disabled={isLoading}
      appearance="secondary"
      onClick={() => {
        shouldAddRoleAfterSubmitRef.current = true;
        onSubmit();
      }}
    >
      {isLoading && shouldAddRoleAfterSubmitRef.current ? (
        <LoadingIcon />
      ) : (
        intl.formatMessage({
          defaultMessage: "Save and Add Role",
          id: "eNgbSf",
          description: "Save and add role label",
        })
      )}
    </Button>
  );
}

export const Summary = ({ closeModal }: { closeModal: () => void }) => {
  const intl = useIntl();
  const { openModal: openRoleForm } = useRoleModalForm();
  const {
    wizardValues,
    context: { shouldAddRoleAfterSubmitRef },
  } = useWizard<
    string,
    PermissionGroupWizardValues,
    PermissionGroupWizardContext
  >();
  const isEdit = !!wizardValues?.id;

  const preparedData = useMemo(() => {
    const draft = { ...wizardValues };

    if (!draft.features) {
      draft.features = {};
    }

    const featureNames = Object.keys(draft.features) as FeatureNames[];

    const newFeatures = featureNames.reduce<PermissionGroupData["features"]>(
      (acc, curr) => [
        ...acc,
        {
          featureName: curr,
          operations: !draft.features ? {} : draft.features[curr],
        } as Features,
      ],
      [],
    );
    return { ...draft, features: newFeatures };
  }, [wizardValues]);

  const createRecord = useCreateRecord<PermissionGroupData>();
  const updateRecord = useUpdateRecord<PermissionGroupData>();
  const queryClient = useQueryClient();

  const { isPending: isMutateLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      const record = preparedData;

      if (isEdit) {
        const parsedRecord = PermissionGroup.safeParse(record);
        if (!parsedRecord.success) return;

        await updateRecord(
          () =>
            editPermissionGroup({
              permissionGroup: parsedRecord.data,
              permissionGroupId: parsedRecord.data.id ?? "",
            }),
          {
            id: parsedRecord.data.id ?? "",
            record: {
              ...parsedRecord.data,
            },
            dataCacheKey: permissionGroupsKeys.permissionGroups({
              includeAssociatedRoles: "true",
              includeFeatures: "true",
            }),
            successMessage: intl.formatMessage(
              {
                defaultMessage: "{name} successfully edited.",
                id: "xH61Ix",
                description:
                  "Message when an API call successfully edits a record.",
              },
              { name: record.name },
            ),
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: permissionGroupsKeys.permissionGroup({
                  id: parsedRecord.data.id ?? "",
                }),
              });
              closeModal();
            },
            knownErrorMessages: errorMessagesMap,
          },
        );
      } else {
        const parsedRecord = PermissionGroup.omit({ id: true }).safeParse(
          record,
        );
        if (!parsedRecord.success) return;

        await createRecord(() => newPermissionGroup(parsedRecord.data), {
          record: {
            ...parsedRecord.data,
          },
          dataCacheKey: permissionGroupsKeys.all,
          successMessage: intl.formatMessage(
            {
              defaultMessage: "Permission Group {name} created successfully.",
              id: "HwW9LM",
              description: "Success message when permission group is updated",
            },
            { name: parsedRecord.data.name },
          ),
          knownErrorMessages: errorMessagesMap,
          onSuccess: () => {
            closeModal();
            shouldAddRoleAfterSubmitRef.current &&
              openRoleForm({
                // @ts-expect-error The InitialValues type from RolesForm needs to be fixed. I opened a ticket here https://redlock.atlassian.net/browse/PCUI-5778
                initialValues: { roleType: parsedRecord.data.name }, // The POST API doesn't return us any data on success, so use the name we submitted.
              });
          },
        });
      }
    },
  });

  return (
    <WizardStepForm
      onSubmit={() => {
        shouldAddRoleAfterSubmitRef.current = false;
        return mutateAsync();
      }}
      footer={
        <ModalWizardFooter
          customFooterActions={
            <>
              {!isEdit && (
                <SaveAndAddRole
                  isLoading={isMutateLoading}
                  onSubmit={mutateAsync}
                />
              )}
              <SubmitButton disabled={isMutateLoading}>
                <FormattedMessage
                  defaultMessage="Save"
                  id="P/VAfV"
                  description="Will save only"
                />
              </SubmitButton>
            </>
          }
        />
      }
    >
      <DetailsSummary data={preparedData as PermissionGroupData} />
      <AssignPermissionsSummary data={preparedData as PermissionGroupData} />
    </WizardStepForm>
  );
};
