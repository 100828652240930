import { downloadFile } from "utils";
import { type Data, type TableInstance } from "../types";
import makeCSV from "./makeCSV";

/**
 * Generates a CSV representation of the table and triggers a download for it.
 *
 * @param tableInstance
 * @param fileName
 */
export function downloadCSV<D extends Data>(
  tableInstance: TableInstance<D>,
  fileName: string,
): void {
  const file = makeCSV(tableInstance);
  downloadFile({ fileName, file });
}
