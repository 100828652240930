import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox, useFormContext } from "form";
import { Body, Bold, Link } from "ui";

type MonitorFlowLogsProps = {
  disabled?: boolean;
  onChange?: (val: boolean) => void;
};

export default function MonitorFlowLogs({
  disabled = false,
  onChange,
}: MonitorFlowLogsProps) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const monitorFlowLogs = watch("monitorFlowLogs") ?? false;

  useEffect(
    () => {
      onChange && onChange(monitorFlowLogs);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [monitorFlowLogs],
  );

  const label = intl.formatMessage({
    defaultMessage: "Ingest and Monitor Network Security Group Flow Logs",
    id: "JIsT/F",
    description: "Monitor flowLogs checkbox for onboarding an Azure account",
  });

  return (
    <div className="flex rounded border p-4 dark:border-blue-steel-500">
      <div className="w-7">
        <Checkbox
          disabled={disabled}
          data-selector="monitor-flowLogs-checkbox"
          name="monitorFlowLogs"
          label={label}
          showLabel={false}
        />
      </div>
      <div className="flex space-x-2">
        <div className="space-y-1">
          <Bold as="p" addClassName="flex items-center space-x-1">
            {label}
          </Bold>
          <Body appearance="secondary" size="sm">
            <FormattedMessage
              defaultMessage="Review the <a>onboarding documentation</a> to ensure you have completed the configuration steps to view NSG flow logs on Prisma Cloud."
              id="doVfO0"
              description="Description text for the monitor flowLogs card"
              values={{
                a: (chunks) => (
                  <Link
                    size="sm"
                    href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/connect/connect-cloud-accounts/onboard-your-azure-account/onboard-your-azure-account"
                    external
                    data-selector="onboarding-checklist-link"
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Body>
        </div>
      </div>
    </div>
  );
}
