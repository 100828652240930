import { DangerIcon, SuccessIcon, WarningIcon } from "icons";
import { useMemo } from "react";
import { defineMessages } from "react-intl";
import { isNil } from "remeda";
import { DynamicFormattedMessage, Tag } from "ui";
import { type Status } from "../../types";
type TagLayoutProps = {
  status: Status;
  onClick: () => void;
  readOnly: boolean;
};

const iconMapping = {
  error: DangerIcon,
  ok: SuccessIcon,
  warning: WarningIcon,
};

const statusTextMap = defineMessages<
  Extract<Status, "error" | "ok" | "warning">
>({
  ok: {
    defaultMessage: "Successful",
    id: "e5AJT4",
    description: "Status message",
  },
  warning: {
    defaultMessage: "Issues Found",
    id: "MHgVpS",
    description: "Status message",
  },
  error: {
    defaultMessage: "Checks Failed",
    id: "zD0Vmt",
    description: "Status message",
  },
});

export function getTagBgColor(status: Status) {
  switch (status) {
    case "error":
      return "red";
    case "warning":
      return "orange";
    default:
      return "standard";
  }
}

export default function TagLayout({
  status,
  onClick,
  readOnly = false,
}: TagLayoutProps) {
  const IconComponent = useMemo(() => {
    return (
      status &&
      iconMapping[status as Extract<Status, "error" | "ok" | "warning">]
    );
  }, [status]);

  const bgColor = getTagBgColor(status);

  if (isNil(status)) return null;

  return (
    <Tag
      appearance={bgColor}
      size="md"
      icon={IconComponent && <IconComponent size="sm" />}
      onClick={readOnly ? undefined : onClick}
    >
      <DynamicFormattedMessage messageMap={statusTextMap} token={status} />
    </Tag>
  );
}
