import { InfoIcon } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";

import { classNames } from "utils";
import { Tooltip } from "../Tooltip";

export type FieldProps = {
  /**
   * This is the form element to render in the field
   */
  control: ReactElement;
  /**
   * A label for the control.
   */
  label?: string;
  /**
   * Whether or not the label should connect to the control.
   * This will make the control be rendered inside the label.
   * Typically not wanted for checkboxes, where the checkbox renders its own label.
   * When labelId is set, this will be set to false.
   */
  connectLabel?: boolean;
  /**
   * A description to display on the field.
   */
  description?: string;
  disabled?: boolean;
  /**
   * An error to display on the field
   */
  error?: string;
  /**
   * An ID to apply to the label
   *
   * When set, connectLabel will be set to false.
   */
  labelId?: string;
  /**
   * The ID of the input the label is for.  This sets the label's htmlFor prop.
   */
  inputId?: string;
  /**
   * Mark the field as optional - Does not affect whether it's actually required or not.
   * Useful for when you're values are boolean.
   */
  optional?: boolean;
  /**
   * Makes the label invisible and removes it from the layout, but keeps it in the DOM for screen readers.
   */
  showLabel?: boolean;
  /**
   * A tooltip to display on the label
   */
  tooltip?: string;
  /**
   * Add cursor pointer for label
   */
  pointer?: boolean;
};

/*
 * Handles rendering of form controls and their related properties like labels and errors.
 */
export function Field({
  description,
  label,
  labelId,
  inputId,
  optional = false,
  error,
  disabled,
  control,
  tooltip,
  showLabel = true,
  connectLabel = true,
  pointer = false,
}: FieldProps): ReactElement {
  const intl = useIntl();
  const labelTextClasses = classNames(
    "mb-1 space-x-1",
    !showLabel && "sr-only",
    tooltip && "flex flex-row items-center",
    pointer && "cursor-pointer",
  );

  const shouldConnectLabel = labelId ? false : connectLabel;

  return (
    <div className="text-xs">
      <label className="block" id={labelId} htmlFor={inputId}>
        <div className={labelTextClasses}>
          <span>{label}</span>

          {optional && (
            <span className="text-secondary">
              {intl.formatMessage({
                defaultMessage: "(Optional)",
                id: "6Pi4kz",

                description: "Field is optional",
              })}
            </span>
          )}

          {tooltip && (
            <Tooltip label={tooltip}>
              <span>
                <InfoIcon aria-label={tooltip} />
              </span>
            </Tooltip>
          )}
        </div>

        {shouldConnectLabel && control}
      </label>

      {!shouldConnectLabel && control}

      {error && (
        <div className="mt-1 text-red dark:text-dark-bg-red">{error}</div>
      )}

      {description && (
        <div
          className={classNames(
            "mt-1",
            disabled
              ? "text-disabled dark:text-dark-bg-disabled"
              : "text-secondary dark:text-dark-bg-secondary",
          )}
        >
          {description}
        </div>
      )}
    </div>
  );
}
