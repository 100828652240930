import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "form";

import { useQuery } from "@tanstack/react-query";
import { isEmpty, isNil } from "remeda";
import { getCloudGroupAllNames, rolesKeys } from "requests";
import { type InitialValues } from "../roleTypes";
import { sortByKey } from "./utils";

export default function AccountGroupsField({
  initialValues,
}: {
  initialValues?: InitialValues;
}) {
  const intl = useIntl();

  const { data = [], isLoading } = useQuery({
    queryKey: rolesKeys.cloudGroupAllNames(),
    queryFn: getCloudGroupAllNames,
  });

  const accountGroups = useMemo(
    () =>
      !isLoading && !isNil(data) && !isEmpty(data)
        ? sortByKey(data, "name").map((a: { id: string; name: string }) => ({
            value: a.id,
            children: a.name,
          }))
        : [],
    [data, isLoading],
  );

  return (
    <Select
      defaultValue={
        initialValues &&
        initialValues?.accountGroupIds &&
        initialValues?.accountGroupIds?.length > 0 &&
        initialValues?.accountGroupIds[0] !== undefined
          ? initialValues?.accountGroupIds?.map((id) => ({
              value: id,
            }))
          : null
      }
      disabled={isLoading}
      enableMultiSelect
      enableSearch
      enableSelectAll={accountGroups.length > 1}
      label={intl.formatMessage({
        defaultMessage: "Account Group",
        id: "o7pO1m",
        description: "Label for account groups field",
      })}
      isLoading={isLoading}
      items={accountGroups}
      itemToString={(item) => `${item?.children}`}
      menuStyle={{ minWidth: "34rem" }}
      name="accountGroups"
      register={{ required: false }}
    />
  );
}
