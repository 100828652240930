import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import {
  AllAccessKeysResponseSchema,
  GenerateAccessKeyRequestSchema,
  GenerateAccessKeyResponseSchema,
} from "./schemas";

export const accessKeys = {
  all: [{ scope: "accessKeys" }] as const,
  allAccessKeys: () =>
    [{ ...accessKeys.all[0], entity: "allAccessKeys" }] as const,
  generateAccessKey: ({
    name,
    expiresOn,
  }: z.infer<typeof GenerateAccessKeyRequestSchema>) =>
    [
      {
        ...accessKeys.all[0],
        entity: "deleteAccessKey",
        name,
        expiresOn,
      },
    ] as const,
  deleteAccessKey: (id: string) =>
    [
      {
        ...accessKeys.all[0],
        entity: "deleteAccessKey",
        id,
      },
    ] as const,
} as const;

export const getAllAccessKeys = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof accessKeys)["allAccessKeys"]>
  >,
) => {
  return jsonApi({
    path: "access_keys",
    requestSchema: z.void(),
    responseSchema: AllAccessKeysResponseSchema,
  });
};

export const generateAccessKey = async ({
  name,
  expiresOn,
}: z.infer<typeof GenerateAccessKeyRequestSchema>) => {
  return await jsonApi({
    path: `access_keys`,
    body: { name, expiresOn },
    config: {
      method: "POST",
    },
    requestSchema: GenerateAccessKeyRequestSchema,
    responseSchema: GenerateAccessKeyResponseSchema,
  });
};

export const deleteAccessKey = async (id: string) => {
  return await jsonApi({
    path: `access_keys/${id}`,
    config: {
      method: "DELETE",
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};

export const updateAccessKeyStatus = async ({
  id,
  value,
}: {
  id: string;
  value: boolean;
}) => {
  return await jsonApi({
    path: `access_keys/${id}/status/${value}`,
    config: {
      method: "PATCH",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};
