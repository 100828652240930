import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const ResourceLists = lazy(() => import("./ResourceLists"));

export const resourceListsRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "resource-lists",
  component: ResourceLists,
});
