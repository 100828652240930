import { WarningIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { Body, Bold, Card, CardBody } from "ui";

export default function IssueWithRoleArn() {
  return (
    <Card>
      <CardBody>
        <div className="flex">
          <div className="w-10/12">
            <Bold>
              <FormattedMessage
                defaultMessage="We have encountered a issue with the Role ARN."
                id="Bdx6+D"
                description="Role ARN issue description"
              />
            </Bold>
            <Body appearance="secondary">
              <FormattedMessage
                defaultMessage="Please provide your Role ARN again manually and then submit it."
                id="c/0q9E"
                description="Role ARN issue description"
              />
            </Body>
          </div>
          <div className="w-2/12">
            <WarningIcon size="5xl" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
