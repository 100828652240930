import { FormattedMessage, useIntl } from "react-intl";
import { Bold, RadioCard, RadioGroup } from "ui";

import { IdentitySecurityModuleIcon } from "icons";
import { AddonModalMainCard } from "../AddonModalMainCard";

export type AsmSubscribeModalContentProps = {
  isActivating: boolean;
  isActivateRadioButtonSelected: boolean;
  setActivateRadioButtonSelected: React.Dispatch<React.SetStateAction<boolean>>;
};
export function ASMSubscribeTrailModalContent({
  isActivating,
  isActivateRadioButtonSelected,
  setActivateRadioButtonSelected,
}: AsmSubscribeModalContentProps) {
  const intl = useIntl();
  return (
    <>
      <AddonModalMainCard
        icon={<IdentitySecurityModuleIcon size="xxl" />}
        title={
          <FormattedMessage
            defaultMessage="Enable Discovery and Exposure Management"
            id="aEe2bK"
            description="CDEM Security modal header"
          />
        }
        titleStyle="addonNameHeader"
        description={
          <FormattedMessage
            defaultMessage="Unknown, unmanaged assets pose a significant risk to your organization. Implementing discovery and exposure management can help you compile a complete asset inventory so you can identify and eliminate exposures with confidence. Take action to reduce attack vectors and empower your security teams to win against adversaries"
            id="AOQkWQ"
            description="CDEM Security modal details"
          />
        }
      />

      {/* Margin left is to override the default ml-2 added by RadioGroup */}
      <RadioGroup name="cdem" addClassName="!ml-0 mt-5">
        <RadioCard
          tabIndex={1}
          autoFocus={true}
          checked={isActivateRadioButtonSelected}
          onChange={() => setActivateRadioButtonSelected(true)}
          title={intl.formatMessage({
            defaultMessage:
              "Activate Discovery and Exposure Management to monitor cloud infrastructure. (Free Usage for 30 days)",
            id: "XECsuy",
            description: "Radio button option",
          })}
          description={
            <span className="text-secondary dark:text-dark-bg-secondary">
              <FormattedMessage
                defaultMessage="Use the 30 days trial to get started with monitoring your cloud infrastructure. When the trial ends, you will be charged based on the credit consumption for CDEM"
                id="smUi2w"
                description="CDEM trial and pricing description"
                values={{
                  b: (chunk) => <Bold>{chunk}</Bold>,
                }}
              />
            </span>
          }
          value="cdemActivationSelected"
          disabled={isActivating}
          addClassName="text-justify text-base"
        />
      </RadioGroup>
    </>
  );
}
