import { useFormContext } from "form";
import { InfoIcon } from "icons";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Body, Link, Tooltip } from "ui";
import { useTrimField } from "../../../../hooks";
import { getInstructions } from "../../../Onboarding/AwsOnboarding/ConfigureAccount/ManagementOrMemberIAMRoleCft/instructions";
import InstructionsSidecar from "../../../Onboarding/components/InstructionsSidecar";
import RoleArn from "../../../Onboarding/components/RoleArn";

type RoleArnProps = {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  disabled?: boolean;
};

export default function IAMRoleARN({ onBlur, disabled }: RoleArnProps) {
  const intl = useIntl();
  const { watch } = useFormContext();
  useTrimField("roleArn");
  const [customMemberRoleNameEnabled, unifiedCftDisabled] = watch([
    "customMemberRoleNameEnabled",
    "unifiedCftDisabled",
  ]);
  const [showMemberIAMRoleSteps, setShowMemberIAMRoleSteps] = useState(false);
  const [isOpenConfigHelper, setIsOpenConfigHelper] = useState(false);

  const label = customMemberRoleNameEnabled
    ? intl.formatMessage({
        defaultMessage: "Management IAM Role ARN",
        id: "cS8zM5",
        description: "The name of an input field for onboarding an AWS account",
      })
    : intl.formatMessage({
        defaultMessage: "IAM Role ARN",
        id: "LewYn3",
        description: "The name of an input field for onboarding an AWS account",
      });

  const instructions = useMemo(
    () =>
      getInstructions({
        intl,
        isEdit: false,
        initUnifiedCftDisabled: false,
        showMemberIAMRoleSteps,
      }),
    [intl, showMemberIAMRoleSteps],
  );

  return (
    <section className="mt-1">
      <span className="flex">
        <Body size="sm" appearance="secondary" addClassName="mb-1 font-bold">
          {label}
        </Body>
        <Tooltip
          label={intl.formatMessage({
            defaultMessage: "ARN for Prisma Cloud Role",
            id: "Y0SbZ6",
            description:
              "Tooltip for a display field for onboarding an AWS account",
          })}
        >
          <span>
            <InfoIcon
              size="xs"
              aria-label={intl.formatMessage({
                defaultMessage: "ARN for Prisma Cloud Role",
                id: "Y0SbZ6",
                description:
                  "Tooltip for a display field for onboarding an AWS account",
              })}
              className="ml-1 mt-0.5"
            />
          </span>
        </Tooltip>
        {unifiedCftDisabled && (
          <Steps
            onClick={() => {
              setIsOpenConfigHelper(true);
              setShowMemberIAMRoleSteps(false);
            }}
          />
        )}
      </span>

      <RoleArn onBlur={onBlur} showLabel={false} disabled={disabled} />
      <InstructionsSidecar
        instructions={instructions}
        isOpen={isOpenConfigHelper}
        setOpen={setIsOpenConfigHelper}
        title={intl.formatMessage({
          defaultMessage: "Download Member IAM Role CFT",
          id: "7YnOo0",
          description: "Sidecar instruction heading",
        })}
      />
    </section>
  );
}

function Steps({ onClick }: { onClick: () => void }) {
  return (
    <div className="ml-2 text-2xs">
      <FormattedMessage
        defaultMessage="<a>View Steps</a> for more information"
        id="AO0igu"
        description="Open help section"
        values={{
          a: (chunks) => (
            <Link
              data-selector="config-helper-open-sidecar-instructions"
              size="sm"
              onClick={onClick}
              addClassName="!text-2xs"
            >
              {chunks}
            </Link>
          ),
        }}
      />
    </div>
  );
}
