import { DeploymentTypeSchema } from "./schemas";

import { z } from "zod";
import { jsonApi } from "../../../apis";

export const deploymentTypeKeys = {
  all: [{ scope: "deployemntTypes" }] as const,
};

export const getDeploymentType = async () =>
  jsonApi({
    path: `cas/v1/cloud/alibaba_cloud/deployment-type`,
    requestSchema: z.void(),
    responseSchema: DeploymentTypeSchema,
  });
