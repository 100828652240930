import { type OnboardingStatusResponseSchema } from "requests";
import { type Status } from "../../../../types";

type SecurityCapabilitiesDataProps = {
  message?: string;
  id?: string;
  name: string;
  status: Status;
  statusMessage?: {
    message: string;
    staticMessage: boolean;
  };
}[];

export function getSecurityCapabilitiesData(
  data: SecurityCapabilitiesDataProps,
) {
  const misconfigurationsData = data.filter(({ name }) =>
    [
      "guard_duty",
      "inspector",
      "config",
      "compute_discovery",
      "organization_role_viewer",
    ].includes(name),
  );
  const threatDetectionData = data.filter(({ name }) =>
    ["audit_log", "flow_log"].includes(name),
  );
  const agentlessWorkloadScanningData = data.find(
    ({ name }) => name === "agentless_scanning",
  );
  const serverlessFunctionScanningData = data.find(
    ({ name }) => name === "serverless_function_scanning",
  );
  const agentBasedWorkloadProtectionData = data.find(
    ({ name }) => name === "auto_protect",
  );
  const remediationData = data.find(({ name }) => name === "remediation");
  return {
    misconfigurationsData,
    threatDetectionData,
    agentlessWorkloadScanningData,
    serverlessFunctionScanningData,
    agentBasedWorkloadProtectionData,
    remediationData,
  };
}

export const getProjectViewer = (
  accountStatus: OnboardingStatusResponseSchema = [],
) =>
  accountStatus
    .filter(({ name }) => name.toLowerCase() === "project_viewer")
    .map((status) => ({ message: status?.statusMessage?.message }))
    .shift()?.message ?? undefined;
