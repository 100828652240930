import { z, ZodError } from "zod";
import { isNonEmpty, isRegexValid } from "../../utils";

export const LanguagesEnum = z.enum(["java", "javascript", "python"] as const);

const APPROACH_VALUE_REGEX = /^define failing$/;
export enum RegexErrorMessages {
  INVALID_REGEX = "Invalid regular expression",
  NO_UNBOUNDED_WILDCARDS = "No unbounded wildcards (/.*/ or /.+/) are allowed",
  NO_BACKREFERENCES = "No backreferences are allowed",
}

const validators = [
  {
    errorMessage: RegexErrorMessages.NO_UNBOUNDED_WILDCARDS,
    regex: [/(?<!\[[^\]]*)(?<!\\)\.[+*](?![^[\]]*])/g],
  },
  {
    errorMessage: RegexErrorMessages.NO_BACKREFERENCES,
    regex: [
      /\((?!\?:)[^\\)]*\)/g,
      /(?<!\[[^\]]*)(?<!\\)(\\\\)*\\\d(?![^[\]]])/g,
    ],
  },
];

const validateRegex = (value: string): boolean => {
  if (!isRegexValid(value)) {
    throw new ZodError([
      {
        message: RegexErrorMessages.INVALID_REGEX,
        code: z.ZodIssueCode.custom,
        path: [],
      },
    ]);
  }

  validators.forEach((v) => {
    if (!v.regex.some((exp) => exp.test(value))) {
      throw new ZodError([
        { message: v.errorMessage, code: z.ZodIssueCode.custom, path: [] },
      ]);
    }
  });

  return true;
};

const StringOrStringsArray = z.union([z.string(), z.array(z.string()).min(1)]);

const PatternValidation = z.union([
  z.lazy(() => DefinitionSchema),
  StringOrStringsArray,
]);

const objectWithMinMaxKeys = ({
  minKeys,
  maxKeys,
  baseObject,
}: {
  minKeys: number;
  maxKeys: number;
  baseObject: z.ZodRawShape;
}) =>
  z.object(baseObject).refine((value) => {
    const keys = Object.keys(value);
    return keys.length >= minKeys && keys.length <= maxKeys;
  });

const objectWithExactKeys = (keys: number, baseObject: z.ZodRawShape) =>
  z.object(baseObject).refine((value) => {
    return keys === Object.keys(value).length;
  });

const ConditionalDefinitionSchema = objectWithExactKeys(1, {
  or: z
    .array(
      z.union([
        z.lazy(() => DefinitionSchema),
        z.lazy(() => ConditionsSchema),
        z.string(),
      ]),
    )
    .min(1)
    .optional(),
  and: z
    .array(
      z.union([
        z.lazy(() => DefinitionSchema),
        z.lazy(() => ConditionsSchema),
        z.string(),
      ]),
    )
    .min(1)
    .optional(),
});

const ConditionalTaintDefinitionSchema = objectWithExactKeys(1, {
  or: z
    .array(z.lazy(() => TaintDefinitionSchema))
    .min(1)
    .optional(),
  and: z
    .array(z.lazy(() => TaintDefinitionSchema))
    .min(1)
    .optional(),
});

const ConditionSimpleSchema = objectWithExactKeys(1, {
  within: z.union([z.string(), z.lazy(() => DefinitionSchema)]).optional(),
  not_within: z.union([z.string(), z.lazy(() => DefinitionSchema)]).optional(),
  not_regex: z.string().refine(validateRegex).optional(),
  regex: z.string().refine(validateRegex).optional(),
  not_pattern: z.string().optional(),
});

const ConditionComplexSeSchema = objectWithMinMaxKeys({
  minKeys: 2,
  maxKeys: 3,
  baseObject: {
    metavariable: z.string().optional(),
    not_regex: z.string().refine(validateRegex).optional(),
    regex: z.string().refine(validateRegex).optional(),
    pattern: z.string().optional(),
    not_pattern: z.string().optional(),
    type: z.string().optional(),
    comparison: z.string().optional(),
    patterns: z
      .union([z.array(z.string()).min(1), ConditionalDefinitionSchema])
      .optional(),
  },
});

const ConditionsSchema = z.union([
  ConditionalDefinitionSchema,
  ConditionSimpleSchema,
  ConditionComplexSeSchema,
  z.array(ConditionSimpleSchema, ConditionComplexSeSchema),
]);

const DefinitionSchema = z.union([
  ConditionalDefinitionSchema,
  objectWithExactKeys(1, {
    conditions: ConditionsSchema.optional(),
    pattern: z.string().optional(),
    patterns: z
      .union([z.array(z.string()).min(1), ConditionalDefinitionSchema])
      .optional(),
    regex: z.string().refine(validateRegex).optional(),
  }),
]);

const TaintDefinitionSchema = z.union([
  ConditionalTaintDefinitionSchema,
  objectWithExactKeys(1, {
    source: PatternValidation.refine(isNonEmpty),
    sink: PatternValidation.refine(isNonEmpty),
    sanitizer: PatternValidation.optional(),
    propagator: z.object({
      pattern: z.string().optional(),
      patterns: z.array(z.string()).min(1).optional(),
    }),
  }),
]);

const SchemaMetadata = z.object({
  category: z.string().optional(),
  guidelines: z.string(),
});

const SchemaSastMetadata = SchemaMetadata.extend({
  id: z.string().optional(),
  name: z.string().optional(),
  cwe: StringOrStringsArray.optional(),
  owasp: StringOrStringsArray.optional(),
  approach: z.string().regex(APPROACH_VALUE_REGEX).optional(),
});

export const SastPolicyValidation = z.object({
  metadata: SchemaSastMetadata.refine(isNonEmpty, {
    message: '"metadata" is required in the policy',
  }),
  scope: z
    .object({
      languages: z.array(LanguagesEnum).min(1).refine(isNonEmpty),
    })
    .refine(isNonEmpty),
  definition: z
    .union([DefinitionSchema, TaintDefinitionSchema])
    .refine(isNonEmpty),
});

export const SastDefinition = z
  .union([DefinitionSchema, TaintDefinitionSchema])
  .refine(isNonEmpty);
