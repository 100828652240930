import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";

type AgentbasedWorkloadProtectionProps = {
  data?: SecurityCapabilitiesPermissionsStatus;
  isCollapsibleDetails?: boolean;
};

export default function AgentbasedWorkloadProtection({
  data,
  isCollapsibleDetails = true,
}: AgentbasedWorkloadProtectionProps) {
  if (isNil(data)) return null;

  return (
    <CollapsibleDetails
      icon={<SecurityCapabilitiesIcon type="Auto Protect" />}
      label="auto_protect"
      isCollapsibleDetails={isCollapsibleDetails}
      {...data}
    />
  );
}
