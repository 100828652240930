import { a, config, useSpring } from "@react-spring/web";
import {
  useMemo,
  useRef,
  useState,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from "react";

import { DropdownIcon } from "icons";
import useMeasure from "react-use-measure";
import { classNames } from "utils";
import { v4 as uuidV4 } from "uuid";
import { Title, type TitleProps } from "../Typography";

export interface AccordionPanelProps {
  /**
   * The panel can be closed.
   */
  canClose?: boolean;
  children: ReactNode;
  /**
   * An icon to render in the header.
   */
  icon?: ReactNode;
  /**
   * The open state of the panel.
   */
  isOpen?: boolean;
  /**
   * The level of the header. Set as appropriate for the information architecture of the page.
   */
  level?: TitleProps["level"];
  /**
   * Do not add border around the body of the panel.
   */
  noBorder?: boolean;
  /**
   * Do not add padding around the body of the panel.
   */
  noPadding?: boolean;
  /**
   * Invoked when the header is clicked on.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * The title of the panel.
   */
  title: ReactNode;
  /**
   * Testid helper of the panel.
   */
  testId?: string;
}

/**
 * A collapsible panel
 */
export function AccordionPanel({
  canClose = true,
  children,
  icon,
  isOpen = true,
  level = 3,
  noBorder = false,
  noPadding = false,
  onClick,
  title,
  testId,
}: AccordionPanelProps): ReactElement {
  const [buttonId, bodyId] = useMemo(() => {
    const baseId = uuidV4();
    return [`${baseId}-accordion-button`, `${baseId}-accordion-body`];
  }, []);
  const outerRef = useRef<HTMLDivElement>(null);
  const [innerRef, bounds] = useMeasure();
  const [isAnimatingHeight, setIsAnimatingHeight] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState(false);

  const { height, ...style } = useSpring({
    immediate: !isMounted,
    config: { ...config.stiff, clamp: true },
    from: {
      height: 0,
      opacity: 0,
      translateY: "-100%",
    },
    to: {
      height: bounds.height,
      opacity: 1,
      translateY: "0%",
    },
    /* animations are skipped in tests */
    onRest: {
      height: /* c8 ignore next */ () => {
        setIsAnimatingHeight(false);
      },
    },
    /* animations are skipped in tests */
    onStart: {
      height: /* c8 ignore next */ (animation) => {
        setIsMounted(true);
        setIsAnimatingHeight(!animation.finished);
      },
    },
    reverse: !isOpen,
  });

  return (
    <div
      className={classNames(
        "flex flex-col bg-white dark:bg-blue-steel-950",
        !noBorder && "rounded",
      )}
      data-testid={testId}
    >
      <Title
        addClassName={classNames(
          "w-full bg-white dark:bg-blue-steel-950",
          !noBorder && "border border-gray-300 dark:border-blue-steel-850",
          !noBorder && (isOpen || isAnimatingHeight) ? "rounded-t" : "rounded",
          isOpen && "bg-gray-100 dark:bg-blue-steel-970",
          (canClose || !isOpen) &&
            "hover:border-gray-800 hover:bg-gray-100 dark:hover:bg-blue-steel-850",
        )}
        level={level}
        size="xxs"
      >
        <button
          aria-controls={bodyId}
          aria-disabled={isOpen && !canClose}
          aria-expanded={isOpen}
          className={classNames(
            "flex w-full items-center space-x-1 px-4 py-2 focus:outline-none",
            !noBorder && "rounded focus:ring",
            isOpen && !canClose ? "cursor-default" : "cursor-pointer",
          )}
          id={buttonId}
          onClick={onClick}
          type="button"
        >
          {icon && <span className="flex flex-initial">{icon}</span>}
          <span className="grow text-left text-base font-semibold">
            {title}
          </span>
          <span className="flex-initial">
            <DropdownIcon isOpen={isOpen} />
          </span>
        </button>
      </Title>
      <a.div
        className={classNames(
          !noBorder && (isOpen || isAnimatingHeight) && "border",
          "overflow-hidden rounded-b border-t-0 border-gray-300",
          "dark:border-blue-steel-850",
        )}
        ref={outerRef}
        style={{ height }}
      >
        <a.div
          aria-labelledby={buttonId}
          className={classNames(!noPadding && "p-4")}
          id={bodyId}
          ref={innerRef}
          style={style}
        >
          {children}
        </a.div>
      </a.div>
    </div>
  );
}
