import { LoadingIcon, SecurityCapabilitiesIcon } from "icons";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Body,
  Bold,
  Button,
  Card,
  CardBody,
  CardFooter,
  DynamicFormattedMessage,
  Italic,
  Link,
  Tag,
  Toggle,
  dynamicFormatMessage,
} from "ui";
import { featuresMessageMap } from "../../../../../messages/featuresMessageMap";

import { useDisableFeature } from "../../../../../hooks";
import { type AccountType, type CloudType } from "../../../../../types";
import ComputeButton from "./ComputeButton";
import EnableFeatureButton from "./EnableFeatureButton";

type FeatureCardProps = {
  accountType: AccountType;
  accountId: string;
  cloudType: CloudType;
  featureKey: string;
  featureState: string;
  isAccountImported: boolean;
  name: string;
};

const iconsMap = {
  agentlessScan: <SecurityCapabilitiesIcon type="Agentless Scanning" />,
  serverlessScan: (
    <SecurityCapabilitiesIcon type="Serverless Function Scanning" />
  ),
  workloadDiscovery: (
    <SecurityCapabilitiesIcon type="Cloud Visibility Compliance and Governance" />
  ),
  autoProtect: <SecurityCapabilitiesIcon type="Auto Protect" />,
};

export default function FeatureCard({
  accountType,
  accountId,
  cloudType,
  featureKey,
  featureState,
  isAccountImported,
  name,
}: FeatureCardProps) {
  const intl = useIntl();

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(
    () => featureState === "enabled",
  );

  useEffect(() => {
    setIsFeatureEnabled(featureState === "enabled");
  }, [featureState]);

  const { isLoading: isUpdateFeatureLoading, update } = useDisableFeature({
    accountId,
    accountType,
    cloudType,
    featureKey,
    setIsFeatureEnabled,
  });

  const onClick = useCallback(() => {
    update();
  }, [update]);

  const subTextKey = isFeatureEnabled
    ? "enabled_message"
    : name === "autoProtect"
      ? "autoProtect_learn_more"
      : null;
  return (
    <Card>
      <CardBody addClassName="flex items-center justify-between">
        <div>
          <div className="flex items-center space-x-2">
            {iconsMap[name as keyof typeof iconsMap]}
            <Bold addClassName="grow text-left">
              {dynamicFormatMessage(intl, featuresMessageMap, name)}
            </Bold>
          </div>
          <Body size="sm" addClassName="mt-2" appearance="secondary">
            {dynamicFormatMessage(intl, featuresMessageMap, `${name}_desc`)}
          </Body>
          {subTextKey && (
            <Body size="sm" addClassName="mt-1" appearance="secondary">
              <Italic addClassName="font-bold">
                &#40;
                {dynamicFormatMessage(intl, featuresMessageMap, subTextKey)}
                &#41;
              </Italic>
            </Body>
          )}
        </div>
        <div className="flex space-x-2">
          {name === "workloadDiscovery" ? (
            <>
              <Tag appearance="standard" readOnly>
                <FormattedMessage
                  defaultMessage="Default"
                  id="MHuWAk"
                  description="Default plan indicator text for Workload Discovery"
                />
              </Tag>
            </>
          ) : (
            <>
              {isUpdateFeatureLoading ? (
                <LoadingIcon />
              ) : (
                <>
                  <Body as="label" size="sm">
                    {isFeatureEnabled ? (
                      <FormattedMessage
                        defaultMessage="Enabled"
                        id="HkHqeL"
                        description="Toggle button for disabling the serverless scan feature"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Disabled"
                        id="+nCaHs"
                        description="Toggle button for disabling the serverless scan feature"
                      />
                    )}
                  </Body>
                  <Toggle
                    checked={isFeatureEnabled}
                    disabled={!isFeatureEnabled}
                    onClick={onClick}
                  />
                </>
              )}
            </>
          )}
        </div>
      </CardBody>
      {isFeatureEnabled ? (
        <>
          {name === "autoProtect" ? (
            <CardFooter appearance="striped">
              <Button data-testid="auto-protect-documentation">
                <Link
                  href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/connect/connect-cloud-accounts/connect-cloud-accounts"
                  size="sm"
                  addClassName="hover:no-underline"
                  external
                >
                  <DynamicFormattedMessage
                    messageMap={featuresMessageMap}
                    token="autoProtect_learn_more"
                  />
                </Link>
              </Button>
            </CardFooter>
          ) : (
            <CardFooter appearance="striped">
              <ComputeButton
                accountType={accountType}
                accountId={accountId}
                disabled={!isAccountImported}
                feature={featureKey}
              />
            </CardFooter>
          )}
        </>
      ) : (
        <CardFooter appearance="striped">
          <EnableFeatureButton
            accountId={accountId}
            cloudType={cloudType}
            btnText={dynamicFormatMessage(
              intl,
              featuresMessageMap,
              `${name}_btn`,
            )}
            disabled={isUpdateFeatureLoading}
          />
        </CardFooter>
      )}
    </Card>
  );
}
