import { classNames } from "utils";
import { Title } from "../../Typography";
import { Modal, useModalContext } from "../Modal";

import { type ReactElement, type ReactNode } from "react";

export interface ModalWizardBodyProps {
  /**
   * The title for the active step.
   */
  activeStepTitle: ReactNode;
  /**
   * The step content, usually a form or summary.
   */
  children: ReactNode;
  /**
   * The navigation items, generally an array of `ModalWizardNavItem`s.
   */
  navItems: ReactNode;
  /**
   * Add a className to the `ModalWizardBody`'s content
   */
  addBodyClassName?: string;
  /**
   * opt to hide side navigation. defaults to false
   */
  hideSideNav?: boolean;
  /**
   * opt to hide header. defaults to false
   */
  hideTitle?: boolean;
}

/**
 * Wizards are multi-step workflows, generally involving forms.
 *
 * The wizard components here, in `@prismacloud/react-ui`, only provide the visual
 * elements of the wizard - navigation, layout, actions.
 *
 * See the sister wizard components in the `@prismacloud/react-form` for common
 * form use cases.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ModalWizardBody, ModalWizardNavItem, ModalWizardSummary, ModalWizardStepSummary } from '@prismacloud/react-ui';
 * ```
 */
export function ModalWizardBody({
  activeStepTitle,
  children,
  navItems,
  addBodyClassName,
  hideSideNav = false,
  hideTitle = false,
}: ModalWizardBodyProps): ReactElement {
  const { size } = useModalContext();
  return (
    <div
      className="flex w-full flex-1"
      style={{ maxHeight: Modal.bodyMeasurements[size].maxHeight }}
    >
      {!hideSideNav && (
        <nav className="flex w-52 flex-col overflow-y-auto bg-gray-100 dark:bg-black/[.30]">
          <ol className="z-10" role="list">
            {navItems}
          </ol>
        </nav>
      )}
      <div className={classNames("w-full overflow-auto p-4", addBodyClassName)}>
        {!hideTitle && (
          <Title addClassName="pb-4" level={4} size="xs">
            {activeStepTitle}
          </Title>
        )}
        <div
          className={classNames(
            "space-y-4",
            !hideTitle && "border-t border-primary pt-4",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
