import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { type ReactNode } from "react";
import { noop } from "utils";
import {
  SomethingWentWrongErrorPage,
  SomethingWentWrongErrorSmall,
} from "../SomethingWentWrongError";

/**
 * This component acts like an error boundary, reports to Sentry, and renders a generic fallback UI.
 */
export function ErrorBoundary({
  children,
  appearance = "page",
  fallback,
  onError = noop,
}: {
  children: ReactNode;
  appearance?: "page" | "small";
  fallback?: JSX.Element;
  onError?(eventId: string, error: Error, componentStack: string | null): void;
}) {
  return (
    <SentryErrorBoundary
      onError={(error, stack, eventId) => {
        if (eventId) onError(eventId, error, stack);
      }}
      fallback={({ error, componentStack, eventId }) =>
        fallback ? (
          fallback
        ) : appearance === "page" ? (
          <SomethingWentWrongErrorPage
            error={error}
            componentStack={componentStack}
            eventId={eventId}
          />
        ) : (
          <SomethingWentWrongErrorSmall
            error={error}
            componentStack={componentStack}
            eventId={eventId}
          />
        )
      }
    >
      {children}
    </SentryErrorBoundary>
  );
}
