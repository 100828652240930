import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { publicRootRoute } from "../../publicRootRoute";

export const LoginSSO = lazy(() => import("./LoginSSO"));

export const loginSSORoute = new Route({
  getParentRoute: () => publicRootRoute,
  path: "/sso",
  component: LoginSSO,
});
