import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { ContainerImagesFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

// TODO change file name to start with lower case - currently merge request is not approved when changing it
export const ContainerImages = lazy(() => import("./ContainerImages"));

const ContainerImagesSchema = z.object({
  filters: ContainerImagesFilterSchema.catch({}).optional(),
});

export const containerImagesRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "compute-workload/container-images",
  component: ContainerImages,
  validateSearch: ContainerImagesSchema,
});
