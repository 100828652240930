import { z } from "zod";
import {
  CloudTypesEnumSchema,
  CloudTypesWithOtherEnumSchema,
} from "../cloudType";

export const CloudRegionSchema = z.object({
  name: z.string(),
  apiIdentifier: z.string(),
  cloudType: CloudTypesEnumSchema,
  sdkId: z.string(),
});
export const CloudRegionWithOtherSchema = z.object({
  name: z.string(),
  apiIdentifier: z.string(),
  cloudType: CloudTypesWithOtherEnumSchema,
  sdkId: z.string(),
});

export const CloudRegionResponseSchema = z.array(CloudRegionSchema);
export const CloudRegionWithOtherResponseSchema = z.array(
  CloudRegionWithOtherSchema,
);

export type CloudRegion = z.infer<typeof CloudRegionSchema>;
export type CloudRegionWithOther = z.infer<typeof CloudRegionWithOtherSchema>;
