import { PrismaLogoIcon } from "icons";
import { type ReactElement, type ReactNode } from "react";
import { useIntl } from "react-intl";
import { Body, Link } from "ui";
import { AnimatedBackground } from "../../components/AnimatedBackground";
import { CrystalIcon } from "../../components/CrystalIcon";
import { RouterLink } from "../../components/Navigation/RouterLink/RouterLink";
import { CopyrightNotice } from "../login/components/CopyrightNotice";
import LoginSSOForm from "./LoginSSOForm";

export default function LoginSSO(): ReactElement {
  const intl = useIntl();

  return (
    <div className="dark flex min-h-screen flex-col overflow-auto bg-blue-steel-950 text-sm">
      <AnimatedBackground />
      <div className="relative z-10 mx-auto w-96 grow pb-4 pt-28 dark:text-dark-bg">
        <div className="flex justify-center">
          <RouterLink className="mx-auto mb-10 cursor-pointer" to="/login">
            <PrismaLogoIcon size="7xl" color="white" />
          </RouterLink>
        </div>
        <div className="absolute left-0 top-0 -z-10 -ml-32 mt-48">
          <CrystalIcon theme="theme-robinblue" width="640px" height="500px" />
        </div>
        <div className="mx-auto w-96 grow">
          <div className="flex flex-col rounded border border-blue-steel-850 bg-[#001F26]/50 p-10  shadow-xl backdrop-blur-2xl">
            <LoginSSOForm />
          </div>
          <Body appearance="secondary" addClassName="mt-2 text-center">
            {intl.formatMessage(
              {
                defaultMessage:
                  "Using SAML SSO? Click <a>here</a> for help finding your login URL.",
                id: "jdMR+A",
                description:
                  "Link to documentation regarding the Prisma Cloud Access SAML URL",
              },
              {
                a: (chunks: ReactNode[]) => (
                  <Link
                    href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/administration/setup-sso-integration-on-prisma-cloud/get-started-with-saml-sso/get-started-with-saml-sso"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link dark:text-dark-bg-link"
                  >
                    {chunks}
                  </Link>
                ),
              },
            )}
          </Body>
          <Body appearance="secondary" addClassName="mb-10 mt-8 text-center">
            {intl.formatMessage(
              {
                defaultMessage:
                  "Check Prisma Cloud system availability and upcoming maintenance schedule on our <a>status page</a>.",
                id: "sjsqey",
                description: "Availability and maintenance schedule link",
              },
              {
                a: (chunks: ReactNode[]) => (
                  <Link
                    href="https://status.paloaltonetworks.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link dark:text-dark-bg-link"
                  >
                    {chunks}
                  </Link>
                ),
              },
            )}
          </Body>
        </div>
      </div>
      <div className="absolute bottom-0 z-[11] w-full bg-[#23424D] p-3 text-center">
        <Body appearance="secondary" size="sm">
          <CopyrightNotice />
        </Body>
      </div>
    </div>
  );
}
