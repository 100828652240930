// This list comes from:
// https://redlock.atlassian.net/wiki/spaces/RED/pages/3820585008
export const productionSubdomains = [
  "app",
  "app0",
  "app2",
  "app3",
  "app4",
  "app5",
  "app.ca",
  "app.eu",
  "app2.eu",
  "app.uk",
  "app.sg",
  "app.anz",
  "app.gov",
  "app.ind",
  "app.jp",
  "app.fr",
  "app.id",
  // temporary prod until we deploy to app0.next and others
  "appnext.dev",
  "demo2",
  "demo3",
  "avengers",
  "private-preview",
  "darwin-latest",
  "sko-demo",
];

export const govSubdomains = ["app-qa-gov.gov", "app.gov"];
export type Environment =
  | "development"
  | "test"
  | "staging"
  | "preview"
  | "production"
  | "storybook";

function subdomain() {
  return window.location.hostname.replace(/.prismacloud.(io|cn|dev)/, "");
}

/**
 * Returns the current environment as a string.
 * QA environments can be individual QA stacks, k8s envs, or even app-stage.
 *
 * Note: A production environment could be Govcloud or China.
 */
export function currentEnvironment(): Environment {
  const { hostname, search } = window.location;

  if (
    productionSubdomains.includes(
      subdomain().replace(".next", "").replace("next-", ""),
    )
  )
    return "production";

  if (hostname.includes("mssp-preprod") || hostname === "mssp.prismacloud.io")
    return "production";

  if (process.env.NODE_ENV === "test") return "test";

  if (
    hostname.includes("storybook") ||
    search.includes("viewMode=story") ||
    search.includes("viewMode=docs")
  )
    return "storybook";

  if (
    hostname.includes("localhost") ||
    hostname.includes("local.prismacloud.io") ||
    hostname.includes("127.0.0.1") ||
    hostname.includes("0.0.0.0")
  )
    return "development";
  if (hostname.includes("prismacloud.dev") || hostname.includes("vercel.app"))
    return "preview";

  return "staging";
}

/**
 * Wether the current environment is hosted in China.
 */
export function isChina(): boolean {
  return (
    window.location.hostname.endsWith(".cn") ||
    (isPreview() && window.location.hostname.includes("-china"))
  );
}

/**
 * Wether the current environment is hosted in Govcloud.
 */
export function isGov(): boolean {
  return (
    govSubdomains.includes(subdomain()) ||
    (isPreview() && window.location.hostname.includes("-gov"))
  );
}

export function isProduction(): boolean {
  return currentEnvironment() === "production";
}

export function isTest(): boolean {
  return currentEnvironment() === "test";
}

export function isDevelopment(): boolean {
  return currentEnvironment() === "development";
}

export function isStaging(): boolean {
  return currentEnvironment() === "staging";
}

export function isPreview(): boolean {
  return currentEnvironment() === "preview";
}

export function isStorybook(): boolean {
  return currentEnvironment() === "storybook";
}

export function isCasDevelopment(): boolean {
  const { VITE_IS_CAS_LOCAL } = import.meta.env;

  return currentEnvironment() === "development" && !!VITE_IS_CAS_LOCAL;
}

// This environment is for UX/product to be able to test pendo on since we are disabling it for preview environments moving forward.
export function isPendoDev(): boolean {
  return window.location.hostname === "pendo.prismacloud.dev";
}

export function isTestAutomation(): boolean {
  return window.location.host === "localhost:4173";
}
