import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { baseApi } from "../../apis/baseApi";
import {
  ConversationEditNameRequestSchema,
  CopilotBetaFeaturesEnableSchema,
  CopilotCheckTosResponseSchema,
  CopilotFavoritesRequestSchema,
  CopilotFavoritesSchema,
  CopilotFeedbackRequestSchema,
  CopilotFeedbackResponseSchema,
  CopilotHistorySchema,
  CopilotPromptRequestSchema,
  CopilotPromptResponseSchema,
  CopilotRequestTypesSchema,
  CopilotSuggestionsResponseSchema,
  CopilotTosRequestSchema,
  CreateSupportCaseRequestSchema,
  CreateSupportCaseResponseSchema,
  type ConversationEditNameRequest,
  type CopilotBetaFeaturesEnable,
  type CopilotFavoritesRequest,
  type CopilotFeedbackRequest,
  type CopilotOnboardingTemplateRequest,
  type CopilotPromptRequest,
  type CopilotRequestType,
  type CopilotSuggestionsRequest,
  type CopilotTosRequest,
} from "./schemas";

export type CreateSupportCaseRequest = z.infer<
  typeof CreateSupportCaseRequestSchema
>;

export const copilotKeys = {
  all: [{ scope: "copilot" }] as const,
  tos: () => [{ ...copilotKeys.all[0], entity: "tos" }] as const,
  customers: () => [{ ...copilotKeys.all[0], entity: "customers" }] as const,
  users: () => [{ ...copilotKeys.all[0], entity: "users" }] as const,
  history: () => [{ ...copilotKeys.all[0], entity: "history" }] as const,
  listTelemetryData: (
    prismaId: string,
    intentRecognized: string[] | [],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    timeRange,
    userName: string[] | [],
    endUserFeedbackRating: string[] | [],
    // traceIds: string[] | [],
    // messageIds: string[] | [],
    conversationIds: string[] | [],
  ) =>
    [
      {
        ...copilotKeys.all[0],
        entity: "listTelemetryData",
        prismaId,
        intentRecognized,
        timeRange,
        userName,
        endUserFeedbackRating,
        // traceIds,
        // messageIds,
        conversationIds,
      },
    ] as const,
  conversationTelemetryData: (traceId: string) =>
    [
      {
        ...copilotKeys.all[0],
        entity: "conversationTelemetryData",
        traceId,
      },
    ] as const,
  aiopsHistory: (prisma_id: string, username: string) =>
    [
      { ...copilotKeys.all[0], entity: "aiopsHistory", prisma_id, username },
    ] as const,
  copilotTenants: () =>
    [{ ...copilotKeys.all[0], entity: "copilotTenants" }] as const,
  copilotTenantUsers: (prisma_id: string) =>
    [
      { ...copilotKeys.all[0], entity: "copilotTenantUsers", prisma_id },
    ] as const,
  favorites: () => [{ ...copilotKeys.all[0], entity: "favorites" }] as const,
  feedbackSuggestions: (requestType: CopilotRequestType) =>
    [
      { ...copilotKeys.all[0], entity: "feedbackSuggestions", requestType },
    ] as const,
  conversationDetails: (conversationId: string) =>
    [
      {
        ...copilotKeys.all[0],
        entity: "conversationDetails",
        conversationId,
      },
    ] as const,
  aiopsConversationDetails: (
    prisma_id: string,
    username: string,
    conversation_id: string,
  ) =>
    [
      {
        ...copilotKeys.all[0],
        entity: "aiopsConversationDetails",
        prisma_id,
        username,
        conversation_id,
      },
    ] as const,
  requestTypes: () =>
    [{ ...copilotKeys.all[0], entity: "request-types" }] as const,
};

export const PROMPT_API_PATH = "/copilot/api/v2/prompt";

export const copilotPrompt = async (
  body: CopilotPromptRequest & {
    baseUrl?: string;
  },
) => {
  return jsonApi({
    path: PROMPT_API_PATH,
    body,
    config: {
      method: "post",
    },
    requestSchema: CopilotPromptRequestSchema,
    responseSchema: CopilotPromptResponseSchema,
  });
};

export const copilotFeedback = async (body: CopilotFeedbackRequest) => {
  return jsonApi({
    path: "/copilot/api/v1/feedback",
    body,
    config: { method: "post" },
    requestSchema: CopilotFeedbackRequestSchema,
    responseSchema: CopilotFeedbackResponseSchema,
  });
};

export const copilotOnboardingTemplate = async (
  body: CopilotOnboardingTemplateRequest,
) => {
  return baseApi({
    path: "/copilot/api/v1/onboarding/template",
    config: {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      method: "post",
    },
  });
};

/** Returns a list of request types accepted by the fetch request */
export const listCopilotRequestTypes = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof copilotKeys)["requestTypes"]>
  >,
) => {
  return jsonApi({
    path: "/copilot/api/v1/request-types",
    requestSchema: z.void(),
    responseSchema: CopilotRequestTypesSchema,
  });
};

/** Provides recent history of copilot conversations */
export const getCopilotHistory = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["history"]>>,
) => {
  return jsonApi({
    path: `/copilot/api/v1/history`,
    requestSchema: z.void(),
    responseSchema: CopilotHistorySchema,
  });
};

/** Provides suggestions for copilot autosuggest feature */
export const getCopilotSuggestions = async ({
  request,
  ui_url,
}: CopilotSuggestionsRequest) => {
  return jsonApi({
    path: `/copilot/api/v1/suggest`,
    params: {
      request,
      ...(ui_url ? { ui_url } : {}),
    },
    requestSchema: z.void(),
    responseSchema: CopilotSuggestionsResponseSchema,
  });
};

/** Provides favorites of copilot conversations */
export const getCopilotFavorites = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["favorites"]>>,
) => {
  return jsonApi({
    path: `/copilot/api/v1/favorite`,
    requestSchema: z.void(),
    responseSchema: CopilotFavoritesSchema,
  });
};

export const updateCopilotFavorites = async (body: CopilotFavoritesRequest) => {
  return jsonApi({
    path: "/copilot/api/v1/favorite",
    body,
    config: {
      method: "post",
    },
    requestSchema: CopilotFavoritesRequestSchema,
    responseSchema: z.boolean(),
  });
};

/** Provides details of a copilot conversation */
export const getConversationDetails = async ({
  queryKey: [{ conversationId }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["conversationDetails"]>
>) => {
  return jsonApi({
    path: `/copilot/api/v1/conversation/${conversationId}`,
    requestSchema: z.void(),
    responseSchema: z.array(CopilotPromptResponseSchema),
  });
};

export const editConversationName = async ({
  conversationId,
  name,
}: ConversationEditNameRequest & {
  conversationId: string;
}) => {
  return jsonApi({
    path: `/copilot/api/v1/conversation/${conversationId}`,
    body: {
      name,
    },
    config: {
      method: "PATCH",
    },
    requestSchema: ConversationEditNameRequestSchema,
    responseSchema: z.boolean(),
  });
};

export const deleteConversation = async ({
  conversationId,
}: {
  conversationId: string;
}) => {
  return jsonApi({
    path: `/copilot/api/v1/conversation/${conversationId}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.boolean(),
  });
};

export const createSupportCase = async (body: CreateSupportCaseRequest) => {
  return jsonApi({
    path: `/copilot/api/v1/support-case-creation`,
    body,
    config: {
      method: "post",
    },
    requestSchema: CreateSupportCaseRequestSchema,
    responseSchema: CreateSupportCaseResponseSchema,
  });
};

export const getCopilotFeedbackSuggestions = async ({
  queryKey: [{ requestType }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["feedbackSuggestions"]>
>) => {
  return jsonApi({
    path: `/copilot/api/v1/feedback-suggestions`,
    params: { requestType },
    requestSchema: z.void(),
    responseSchema: z.array(z.string()),
  });
};

export const checkTos = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["tos"]>>,
) => {
  return jsonApi({
    path: "/copilot/api/v1/tos",
    requestSchema: z.void(),
    responseSchema: CopilotCheckTosResponseSchema,
  });
};

export const acceptTos = async (body: CopilotTosRequest) => {
  return jsonApi({
    path: "/copilot/api/v1/tos",
    body,
    config: {
      method: "post",
    },
    requestSchema: CopilotTosRequestSchema,
    responseSchema: CopilotCheckTosResponseSchema,
  });
};

export const enableBetaFeatures = async (body: CopilotBetaFeaturesEnable) => {
  return jsonApi({
    path: "/copilot/api/v1/tos",
    body,
    config: {
      method: "PATCH",
    },
    requestSchema: CopilotBetaFeaturesEnableSchema,
    responseSchema: CopilotCheckTosResponseSchema,
  });
};

// Copilot AIOps requests
export const getCustomers = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["customers"]>>,
) => {
  return jsonApi({
    path: "/_support/v1/filter/jit/suggest",
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};
export const getCopilotTenants = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof copilotKeys)["copilotTenants"]>
  >,
) => {
  return jsonApi({
    path: `/copilot/api/v1/impersonate/fetch-tenants`,
    params: {
      debug_enabled: "true",
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};
export const getCopilotTenantUsers = async ({
  queryKey: [{ prisma_id }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["copilotTenantUsers"]>
>) => {
  return jsonApi({
    path: `/copilot/api/v1/impersonate/fetch-users`,
    params: {
      debug_enabled: "true",
      prisma_id,
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};

export const getChatHistory = async ({
  queryKey: [{ prisma_id, username }],
}: QueryFunctionContext<ReturnType<(typeof copilotKeys)["aiopsHistory"]>>) => {
  return jsonApi({
    path: `/copilot/api/v1/impersonate/history`,
    params: {
      debug_enabled: "true",
      prisma_id,
      username,
    },
    requestSchema: z.void(),
    responseSchema: CopilotHistorySchema,
  });
};

export const getAiopsConversationDetails = async ({
  queryKey: [{ prisma_id, username, conversation_id }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["aiopsConversationDetails"]>
>) => {
  return jsonApi({
    path: `/copilot/api/v1/impersonate/details`,
    params: {
      debug_enabled: "true",
      prisma_id,
      username,
      conversation_id,
    },
    requestSchema: z.void(),
    responseSchema: z.array(CopilotPromptResponseSchema),
  });
};

export const getListTelemetryData = async ({
  queryKey: [
    {
      prismaId,
      intentRecognized,
      timeRange,
      userName,
      endUserFeedbackRating,
      // traceIds,
      // messageIds,
      conversationIds,
    },
  ],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["listTelemetryData"]>
>) => {
  return jsonApi({
    path: "/graphql",
    body: {
      query: `query { listTelemetryData(prismaId: "${prismaId}" userName: "${userName.join(
        ",",
      )}" enduserFeedbackRating: "${endUserFeedbackRating.join(
        ",",
      )}" intentRecognized: "${intentRecognized.join(
        ",",
      )}" conversationId: "${conversationIds.join(",")}" startTime: "${
        timeRange ? timeRange.value.startTime : ""
      }" endTime: "${
        timeRange ? timeRange.value.endTime : ""
      }") { traceId customerPrompt copilotPrompt intentRecognized conversationId messageId latency startTime userName rephrasedMessageText totalCostEstimate prismaId enduserFeedback { enduserFeedbackRating enduserFeedbackTags enduserFeedbackDescription enduserFeedbackTs} internalFeedback { feedbackId  author traceId rating feedbackDescription feedbackTs feedbackLabels feedbackJson} } }`,
    },
    config: {
      method: "post",
    },
    requestSchema: z.any(),
    responseSchema: z.any(),
  });
};

export const getUserNamesTelemetryData = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["users"]>>,
) => {
  return jsonApi({
    path: "/graphql",
    body: {
      query: `query { listTelemetryData { userName } }`,
    },
    config: {
      method: "post",
    },
    requestSchema: z.any(),
    responseSchema: z.any(),
  });
};

export const getConversationTelemetryData = async ({
  queryKey: [{ traceId }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["conversationTelemetryData"]>
>) => {
  return jsonApi({
    path: "/graphql",
    body: {
      query: `query { getTelemetryMetadata(traceId: "${traceId}") { latency llmCalls{error latencyMs inputCharCount model outputCharCount promptSource response } apiCalls{apiMethod apiPath latencyMs requestBody requestParam statusCode} } }`,
    },
    config: {
      method: "post",
    },
    requestSchema: z.any(),
    responseSchema: z.any(),
  });
};

export const addFeedback = async (
  traceId: string,
  author: string,
  description: string,
) => {
  return jsonApi({
    path: "/graphql",
    body: {
      query: `mutation { addFeedback(traceId: "${traceId}", author: "${author}", feedbackDescription: "${description}") { statusCode, responseMessage } }`,
    },
    config: {
      method: "post",
    },
    requestSchema: z.any(),
    responseSchema: z.any(),
  });
};

export const updateFeedback = async (
  feedbackId: string,
  description: string,
) => {
  return jsonApi({
    path: "/graphql",
    body: {
      query: `mutation { updateFeedback(feedbackId: "${feedbackId}", feedbackDescription: "${description}") { statusCode responseMessage } }`,
    },
    config: {
      method: "post",
    },
    requestSchema: z.any(),
    responseSchema: z.any(),
  });
};

export const deleteFeedback = async (feedbackId: string) => {
  return jsonApi({
    path: "/graphql",
    body: {
      query: `mutation { deleteFeedback(feedbackId: "${feedbackId}") { statusCode responseMessage } }`,
    },
    config: {
      method: "post",
    },
    requestSchema: z.any(),
    responseSchema: z.any(),
  });
};
