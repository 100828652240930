import { type Map } from "immutable";
import {
  EXTERNAL_ACCOUNT_KEY,
  SERVICE_ACCOUNT_KEY,
} from "../../../../../../../constants";

export const gcpCredentialsFileInitialState = {
  [SERVICE_ACCOUNT_KEY]: { value: {}, errors: "", name: "", size: "" },
  isValidServiceAccountKey: false,
  [EXTERNAL_ACCOUNT_KEY]: { value: {}, errors: "", name: "", size: "" },
  isValidExternalAccountKey: false,
};

type StateKeys = keyof typeof gcpCredentialsFileInitialState;
export type GcpCredentialsFileStateType = Map<
  StateKeys,
  { value: unknown; errors: unknown }
>;
