import { type ReactNode } from "react";

type RowProps = {
  label: ReactNode;
  addClassName?: string;
  create?: ReactNode;
  update?: ReactNode;
  del?: ReactNode;
  read?: ReactNode;
};
export function Row({
  label,
  addClassName = "",
  create,
  update,
  del,
  read,
}: RowProps) {
  return (
    <div className={`grid grid-cols-6 px-4 py-2 ${addClassName}`}>
      <div className="col-span-2">{label}</div>

      <div className="flex flex-col items-center gap-2">{read}</div>
      <div className="flex flex-col items-center gap-2">{create}</div>
      <div className="flex flex-col items-center gap-2">{update}</div>
      <div className="flex flex-col items-center gap-2">{del}</div>
    </div>
  );
}
