import { type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Button, Select, type SelectItem } from "ui";

type SNSTopicSelectPropTypes = {
  cloudTrailTopic?: string;
  disabled: boolean;
  items: SelectItem[];
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
};

export default function SNSTopicSelect({
  cloudTrailTopic = "",
  disabled,
  items,
  setCloudTrailTopic,
}: SNSTopicSelectPropTypes) {
  const intl = useIntl();

  const description = intl.formatMessage({
    defaultMessage: "Select an SNS Topic",
    id: "XOHArD",
    description: "Wizard field instruction",
  });

  const buttonLabel = cloudTrailTopic.length ? cloudTrailTopic : description;

  return (
    <Select
      button={
        <Button addClassName="min-w-full" isMenu>
          {buttonLabel}
        </Button>
      }
      disabled={disabled}
      enableSearch
      fullWidth
      items={items}
      onChange={({ selectedItem }) => {
        const newTopic = selectedItem?.value as string;

        setCloudTrailTopic(newTopic); // set value in Wizard state
      }}
      selectedItem={{ value: cloudTrailTopic }}
    />
  );
}
