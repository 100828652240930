import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../SvgIcon";

export const MediumSeverityVulnerabilityIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Medium",
    id: "Tsgevn",

    description: "severity icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} {...props} viewBox="0 0 20 20" fill="none">
      {showNativeTooltip && <title>{alt}</title>}
      <rect
        x="2"
        y="2"
        width="16"
        height="16"
        rx="4"
        className="fill-[#D86800] dark:fill-[#FFA451]"
      />
      <path
        d="M14.0095 6.047V14H12.706V8.863C12.706 8.65767 12.717 8.43583 12.739 8.1975L10.3355 12.713C10.2218 12.9293 10.0477 13.0375 9.813 13.0375H9.604C9.36933 13.0375 9.19517 12.9293 9.0815 12.713L6.6505 8.181C6.6615 8.302 6.67067 8.42117 6.678 8.5385C6.68533 8.65583 6.689 8.764 6.689 8.863V14H5.3855V6.047H6.502C6.568 6.047 6.62483 6.04883 6.6725 6.0525C6.72017 6.05617 6.76233 6.06533 6.799 6.08C6.83933 6.09467 6.87417 6.1185 6.9035 6.1515C6.9365 6.1845 6.96767 6.2285 6.997 6.2835L9.3785 10.7C9.44083 10.8173 9.49767 10.9383 9.549 11.063C9.604 11.1877 9.65717 11.316 9.7085 11.448C9.75983 11.3123 9.813 11.1822 9.868 11.0575C9.923 10.9292 9.98167 10.8063 10.044 10.689L12.3925 6.2835C12.4218 6.2285 12.453 6.1845 12.486 6.1515C12.519 6.1185 12.5538 6.09467 12.5905 6.08C12.6308 6.06533 12.6748 6.05617 12.7225 6.0525C12.7702 6.04883 12.827 6.047 12.893 6.047H14.0095Z"
        className="fill-white dark:fill-gray-900"
      />
    </SvgIcon>
  );
};
