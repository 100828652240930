import { type z } from "zod";
import { jsonApi } from "../../../apis";
import {
  GcpSelectMonitoredProjectsAncestorsRequestSchema,
  GcpSelectMonitoredProjectsAncestorsResponseSchema,
  GcpSelectMonitoredProjectsChildrenRequestSchema,
  GcpSelectMonitoredProjectsChildrenResponseSchema,
} from "./schemas";

export const getGcpSelectMonitoredProjectsChildrens = ({
  id,
  childrenType,
  body,
  params,
}: {
  id: string;
  childrenType: string;
  body: z.infer<typeof GcpSelectMonitoredProjectsChildrenRequestSchema>;
  params?: Record<string, string>;
}) => {
  return jsonApi({
    path: `cloud/gcp/parent/${id}/${
      childrenType === "all" ? "children" : childrenType
    }`,
    config: {
      method: "POST",
    },
    body,
    params,
    requestSchema: GcpSelectMonitoredProjectsChildrenRequestSchema,
    responseSchema: GcpSelectMonitoredProjectsChildrenResponseSchema,
  });
};

export const getGcpSelectMemberAccountAncestors = ({
  orgId,
  body,
}: {
  orgId: string;
  body: z.infer<typeof GcpSelectMonitoredProjectsAncestorsRequestSchema>;
}) => {
  return jsonApi({
    path: `cloud/gcp/accounts/${orgId}/ancestors`,
    config: {
      method: "POST",
    },
    body,
    requestSchema: GcpSelectMonitoredProjectsAncestorsRequestSchema,
    responseSchema: GcpSelectMonitoredProjectsAncestorsResponseSchema,
  });
};
