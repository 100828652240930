/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { justInTimeRoute } from "../../justInTimeRoute";

export const MyTasksTable = lazy(
  () => import("../tables/MyTasksTable/MyTasksTable"),
);

const actionTypes = ["approve", "deny"] as const;
export const MyTasksActionSchema = z.enum(actionTypes);
export type MyTasksActionType = z.infer<typeof MyTasksActionSchema>;

export const MyTasksSearchSchema = z.object({
  action: MyTasksActionSchema.optional(),
  updated_at: z.number().optional(),
});

export const myTasksRoute = new Route({
  getParentRoute: () => justInTimeRoute,
  path: "tasks",
  component: MyTasksTable,
  validateSearch: MyTasksSearchSchema,
});

export const myTasksByRequestIdRoute = new Route({
  getParentRoute: () => myTasksRoute,
  path: "$requestId",
  component: MyTasksTable,
  validateSearch: MyTasksSearchSchema,
});
