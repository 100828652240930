import { useQuery } from "@tanstack/react-query";
import { isChina } from "environment";
import { LoadingIcon } from "icons";
import { useMemo, type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getCloudTypeListItems, listItemKeys } from "requests";
import {
  Body,
  EmptyStateLayout,
  SomethingWentWrongErrorPage,
  Tag,
  Title,
  type RenderModalProps,
} from "ui";
import { CloudNames } from "../../../../cloudAccounts/CloudOnboarding/CloudSelection/cloudNames";
import {
  CHINA_CLOUD_TYPES,
  CLOUD_TYPES,
  FREEMIUM_CLOUDS,
} from "../../../../cloudAccounts/constants";
import {
  type CloudTypes,
  type OnboardProvidersType,
} from "../../../../cloudAccounts/types";
import { cloudTypeSort } from "../../../../cloudAccounts/utils";
import CloudCard from "./CloudCard";

type CloudSelectionProps = RenderModalProps<{
  fromFreemium: boolean;
  search: string;
  fromProvidersPage: OnboardProvidersType;
}>;

export default function CloudSelection({
  closeModal,
  fromFreemium,
  search = "",
  fromProvidersPage,
}: CloudSelectionProps) {
  const intl = useIntl();
  const {
    data = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: listItemKeys.cloudTypes,
    queryFn: getCloudTypeListItems,
  });

  const accessibleCloudTypes = cloudTypeSort(
    isChina() ? CHINA_CLOUD_TYPES : CLOUD_TYPES,
  );

  const tenantCloudTypes = useMemo(() => {
    let cloudTypes = cloudTypeSort(data as CloudTypes).filter(
      (cloudType) => accessibleCloudTypes.indexOf(cloudType) !== -1,
    );

    if (fromFreemium) {
      cloudTypes = tenantCloudTypes.filter((cloud) =>
        FREEMIUM_CLOUDS.includes(cloud),
      );
    }
    return cloudTypes;
  }, [accessibleCloudTypes, data, fromFreemium]);

  const cloudCards = useMemo(() => {
    const filteredCloudTypes = tenantCloudTypes.filter((cloud) =>
      intl
        .formatMessage(CloudNames[cloud])
        .toLowerCase()
        .includes(search.toLowerCase()),
    );

    if (!filteredCloudTypes.length) {
      return (
        <div className="flex grow items-center justify-center">
          <EmptyStateLayout />
        </div>
      );
    }

    return filteredCloudTypes.map((cloudType) => (
      <CloudCard
        cloudType={cloudType}
        key={cloudType}
        closeModal={closeModal}
        fromFreemium={fromFreemium}
        fromProvidersPage={fromProvidersPage}
      />
    ));
  }, [
    closeModal,
    intl,
    search,
    tenantCloudTypes,
    fromFreemium,
    fromProvidersPage,
  ]);

  return (
    <>
      {isError ? (
        <SomethingWentWrongErrorPage error={error} />
      ) : isLoading ? (
        <LoadingIcon size="xl" />
      ) : (
        <>
          <section className="mb-4">
            <div className="flex space-x-2">
              <Title level={2} size="xs">
                <FormattedMessage
                  defaultMessage="Cloud Providers"
                  id="35RZwi"
                  description="Title for Cloud selection screen"
                />
              </Title>
              <span className="space-x-2 pt-1">
                <FeatureTag>
                  <FormattedMessage
                    defaultMessage="Misconfiguration Detection"
                    id="9B1GTs"
                    description="Title for tag"
                  />
                </FeatureTag>
                <FeatureTag>
                  <FormattedMessage
                    defaultMessage="Vulnerability Management"
                    id="+wGK+X"
                    description="Title for tag"
                  />
                </FeatureTag>
                <FeatureTag>
                  <FormattedMessage
                    defaultMessage="Network Threat Detection"
                    id="OVIU1u"
                    description="Title for tag"
                  />
                </FeatureTag>
                <FeatureTag>
                  <FormattedMessage
                    defaultMessage="Identity Risk Preventions"
                    id="+4SAKy"
                    description="Title for tag"
                  />
                </FeatureTag>
                <FeatureTag>
                  <FormattedMessage
                    defaultMessage="DSPM"
                    id="lwT4Kj"
                    description="Title for tag"
                  />
                </FeatureTag>
              </span>
            </div>
            <div>
              <Body addClassName="pt-1" appearance="secondary">
                <FormattedMessage
                  defaultMessage="Establish continuous visibility and control over misconfigurations, privileges, data and vulnerabilities across your cloud environment."
                  id="toWeSz"
                  description="Subtitle for Cloud selection screen"
                />
              </Body>
            </div>
          </section>
          <section className="-m-2 flex flex-wrap content-start">
            {cloudCards}
          </section>
        </>
      )}
    </>
  );
}

function FeatureTag({ children }: { children: ReactNode }) {
  return (
    <Tag appearance="standard" size="md">
      <Body addClassName="text-xs" appearance="secondary">
        {children}
      </Body>
    </Tag>
  );
}
