import { classNames } from "utils";
import { TruncateText } from "../../TruncateText";
import { Body } from "../../Typography";
import { StatusIcon } from "./StatusIcon";

import {
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from "react";
import { type StatusIconProps } from "./StatusIcon";

export interface ModalWizardNavItemProps {
  children: ReactNode;
  disabled?: boolean;
  status?: StatusIconProps["status"];
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function ModalWizardNavItem({
  children,
  disabled = false,
  status,
  isActive = false,
  onClick,
}: ModalWizardNavItemProps): ReactElement {
  const buttonClassName = classNames(
    "flex items-center justify-between space-x-1",
    "w-full truncate py-2 pr-2 text-left",
    "focus:outline-none focus:ring focus:ring-inset",
    disabled && "cursor-not-allowed",
    isActive
      ? "cursor-default border-l-4 border-blue-600 bg-white pl-5 dark:bg-blue-steel-940/50"
      : "pl-6",
    !isActive && !disabled && "hover:bg-gray-200 dark:hover:bg-blue-steel-970",
  );

  return (
    <li>
      <button
        className={buttonClassName}
        data-testid="wizard-nav-item"
        disabled={disabled || isActive}
        onClick={onClick}
        type="button"
      >
        <Body
          addClassName={classNames(
            "flex overflow-hidden",
            isActive && "cursor-text",
          )}
          appearance={isActive ? "primary" : disabled ? "disabled" : "link"}
          as="span"
          size="sm"
        >
          <TruncateText addClassName="shrink">{children}</TruncateText>
        </Body>
        <StatusIcon status={status} />
      </button>
    </li>
  );
}
