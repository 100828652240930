import { FormattedNumber } from "react-intl";

import { type ComponentPropsWithoutRef, type ReactElement } from "react";

/**
 * Returns a cell renderer using the given formatting options.
 *
 * @param {*} options Formatter options.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export function makeNumberCell(
  options: Omit<ComponentPropsWithoutRef<typeof FormattedNumber>, "value"> = {},
) {
  return function NumberCell({ value }: { value?: number }): ReactElement {
    if (value === undefined || isNaN(value)) {
      return <span className="w-full text-left">-</span>;
    }

    return (
      <span className="w-full text-left">
        <FormattedNumber value={value} {...options} />
      </span>
    );
  };
}
