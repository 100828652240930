import { type ReactElement, type ReactNode } from "react";
import { Body, Title } from "../Typography";

export interface ConfirmationDialogProps {
  actions: ReactNode;
  prompt: ReactNode;
  title: ReactNode;
}

export const ConfirmationDialog = ({
  actions,
  prompt,
  title,
}: ConfirmationDialogProps): ReactElement => {
  return (
    <div className="flex h-full w-full">
      <div className="m-auto flex flex-col space-y-4">
        <Title level={3} size="md" addClassName="flex justify-center">
          {title}
        </Title>
        <Body size="md">{prompt}</Body>
        <div className="flex flex-row justify-center space-x-2">{actions}</div>
      </div>
    </div>
  );
};
