import { useCallback, useMemo, useRef, type FC } from "react";
import {
  get,
  useController,
  useFormContext,
  type FieldValue,
  type RegisterOptions,
} from "react-hook-form";
import { useIntl } from "react-intl";
import {
  Field,
  ChipInput as ReactChipInput,
  type FieldProps,
  type ChipInputProps as ReactChipInputProps,
  type SelectItem,
  type SelectItemValue,
} from "ui";
import { v4 as uuidV4 } from "uuid";

import { inputValueExistsMessage, requiredMessage } from "../../messages";

export type ChipInputProps = {
  /**
   * The name the select items will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  dataAutofocus?: boolean;
  /**
   * A function to validate the input before it is entered. Should return the error message if invalid.
   */
  inputValidation?: (value: string) => string | false | undefined;
  /**
   * A function to validate selectedItems before the one is removed. Should return the error message if invalid.
   */
  onSelectedItemsChange?: (
    selectedItems: SelectItem[] | undefined,
  ) => string | false | undefined;
} & (
  | {
      /**
       * A flag to indicate format of return type.
       *
       * Set this flag to true for return format to be of type `string | string[]`. Please note that if your `items` are dynamic and a selected `SelectItem` is removed from `items`, all associated metadata will be discarded, leaving only the value. If you have a dynamic list of `items`, it is not recommended to use `simpleFormat`.
       */
      simpleFormat: true;

      /**
       * A default value for ChipInput.
       */
      defaultValue?: SelectItem["value"] | SelectItem["value"][];
    }
  | {
      simpleFormat?: false;

      /**
       * A default value for the ChipInput
       */
      defaultValue?: SelectItem | SelectItem[];
    }
) &
  Omit<FieldProps, "error" | "control"> &
  Omit<
    ReactChipInputProps,
    "selectedItems" | "onSelectedItemsChange" | "onInputBlur" | "onInputSubmit"
  >;

/**
 * Renders a ChipInput connected to the form. All extra props will get passed to the base ChipInput.
 */
export const ChipInput: FC<ChipInputProps> = (props: ChipInputProps) => {
  const {
    name,
    label,
    register = {},
    defaultValue = [],
    description,
    tooltip,
    disabled,
    showLabel,
    inputValidation,
    onInputValueChange,
    onSelectedItemsChange,
    simpleFormat = false,
    dataAutofocus = false,
    ...rest
  } = props;
  const labelId = useMemo((): string => `field-${uuidV4()}-label`, []);
  const inputId = useMemo((): string => `field-${uuidV4()}-input`, []);
  const inputSubmitted = useRef(false);
  const context = useFormContext();
  const intl = useIntl();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render ChipInput outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState, setError, clearErrors, getValues } = context;
  register.required =
    register.required ?? intl.formatMessage(requiredMessage, { label });

  const {
    field: { onBlur, onChange, value },
  } = useController({
    name,
    defaultValue,
    rules: register,
  });
  const error = get(formState.errors, name);

  const inputValueChange = useCallback(
    (inputValue: string) => {
      onInputValueChange?.(inputValue);
      if (!inputValidation) return;
      if (!inputSubmitted.current) return;
      const error = inputValue && inputValidation(inputValue);

      if (error) {
        setError(name, { message: error, type: "input" });
      } else {
        clearErrors(name);
        if (inputValue === "") inputSubmitted.current = false;
      }
    },
    [clearErrors, inputValidation, name, onInputValueChange, setError],
  );

  const inputValueSubmit = useCallback(
    (inputValue: string) => {
      const isValueAlreadyExists = getValues(name)?.some(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: FieldValue<Record<string, any>>) => {
          const value = simpleFormat ? item : item.value;
          return value === inputValue;
        },
      );

      if (isValueAlreadyExists) {
        inputSubmitted.current = true;
        setError(name, {
          message: intl.formatMessage(inputValueExistsMessage),
          type: "input",
        });

        return false;
      }

      if (!inputValidation) {
        clearErrors(name);
        inputSubmitted.current = false;
        return true;
      }

      const error = inputValidation(inputValue);

      if (error) {
        inputSubmitted.current = true;
        setError(name, { message: error, type: "input" });
        return false;
      } else {
        clearErrors(name);
        inputSubmitted.current = false;
        return true;
      }
    },
    [
      clearErrors,
      getValues,
      inputValidation,
      intl,
      name,
      setError,
      simpleFormat,
    ],
  );

  const valueMap = useMemo(
    () =>
      rest.items
        ? rest.items.reduce<Record<string, SelectItem>>((acc, curr) => {
            acc[`${JSON.stringify(curr.value)}`] = curr;
            return acc;
          }, {})
        : {},
    [rest.items],
  );

  const selected = useMemo(() => {
    if (value == undefined) return;
    if (simpleFormat) {
      return value?.map(
        (value: SelectItemValue) =>
          valueMap[JSON.stringify(value)] ?? { value },
      );
    }

    return value?.map(
      (value: SelectItem) => valueMap[JSON.stringify(value.value)] ?? value,
    );
  }, [value, simpleFormat, valueMap]);

  return (
    <Field
      connectLabel={false}
      label={label}
      labelId={labelId}
      inputId={inputId}
      optional={!register.required}
      control={
        <ReactChipInput
          {...rest}
          appearance={
            error?.type === "input" || error?.type === "required"
              ? "input-error"
              : "default"
          }
          disabled={disabled}
          labelId={labelId}
          inputId={inputId}
          selectedItems={selected}
          onInputBlur={onBlur}
          onSelectedItemsChange={({ selectedItems }) => {
            if (onSelectedItemsChange) {
              const error = onSelectedItemsChange(selectedItems);

              if (error) {
                setError(name, { message: error, type: "input" });
              } else {
                clearErrors(name);
                simpleFormat
                  ? onChange(selectedItems?.map((item) => item.value))
                  : onChange(selectedItems);
              }
            } else {
              simpleFormat
                ? onChange(selectedItems?.map((item) => item.value))
                : onChange(selectedItems);
            }
          }}
          onInputValueChange={inputValueChange}
          onInputSubmit={inputValueSubmit}
          data-autofocus={dataAutofocus}
        />
      }
      error={error?.message}
      description={description}
      tooltip={tooltip}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
};
