import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AzureServiceBusQueueIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Azure Service Bus Queue",
    id: "AwFT2H",

    description: "Azure Service Bus Queue icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 41.4951C11.7959 43.4832 14.0182 45.8709 16.2301 48.0049C17.8403 49.5584 19.4653 50.9974 20.9306 52.0537C21.6631 52.5817 22.3741 53.0276 23.0377 53.3445C23.6891 53.6555 24.362 53.8752 25.0001 53.8752C25.6286 53.8752 26.2981 53.6693 26.9539 53.3726C27.6212 53.0707 28.3395 52.6446 29.0828 52.1367C30.5696 51.1209 32.2267 49.7297 33.8804 48.2062C36.0135 46.2412 38.1727 44.0266 40 42.0488V62H10V41.4951ZM10.1133 39C12.0453 41.1691 14.7582 44.1531 17.4451 46.7455C19.0307 48.2752 20.587 49.6487 21.954 50.6341C22.6376 51.1269 23.2555 51.5092 23.7918 51.7652C24.3402 52.0271 24.7382 52.1252 25.0001 52.1252C25.2716 52.1252 25.6782 52.029 26.2326 51.7781C26.7756 51.5325 27.4019 51.1657 28.0956 50.6917C29.4827 49.7441 31.0693 48.4165 32.6947 46.9191C35.3118 44.5083 37.98 41.7031 40 39.4489V39H10.1133Z"
        className="fill-[#6FB3D5] dark:fill-[#E0E0E0]"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 41.4951C61.7959 43.4832 64.0182 45.8709 66.2301 48.0049C67.8403 49.5584 69.4653 50.9974 70.9306 52.0537C71.6631 52.5817 72.3741 53.0276 73.0377 53.3445C73.6891 53.6555 74.362 53.8752 75.0001 53.8752C75.6286 53.8752 76.2981 53.6693 76.9539 53.3726C77.6212 53.0707 78.3395 52.6446 79.0828 52.1367C80.5696 51.1209 82.2267 49.7297 83.8804 48.2062C86.0135 46.2412 88.1727 44.0266 90 42.0488V62H60V41.4951ZM60.1133 39C62.0453 41.1691 64.7582 44.1531 67.4451 46.7455C69.0307 48.2752 70.587 49.6487 71.954 50.6341C72.6376 51.1269 73.2555 51.5092 73.7918 51.7652C74.3402 52.0271 74.7382 52.1252 75.0001 52.1252C75.2716 52.1252 75.6782 52.029 76.2326 51.7781C76.7756 51.5325 77.4019 51.1657 78.0956 50.6917C79.4827 49.7441 81.0693 48.4165 82.6947 46.9191C85.3118 44.5083 87.98 41.7031 90 39.4489V39H60.1133Z"
        className="fill-[#6FB3D5] dark:fill-[#E0E0E0]"
      />
      <path
        d="M48.9995 52.3474C49.0059 52.51 48.9518 52.6689 48.8485 52.7905C48.7452 52.9122 48.6008 52.9871 48.4457 52.9995H43.6235C43.4681 53.0062 43.3163 52.9495 43.2001 52.8414C43.0839 52.7333 43.0123 52.5822 43.0005 52.4199V48.6526C42.9941 48.49 43.0482 48.3311 43.1515 48.2095C43.2548 48.0878 43.3992 48.0129 43.5543 48.0005H48.3765C48.5319 47.9938 48.6837 48.0505 48.7999 48.1586C48.9161 48.2667 48.9877 48.4178 48.9995 48.5801V52.3474Z"
        className="fill-[#BED253] dark:fill-white"
      />
      <path
        d="M56.9995 52.3443C57.0059 52.5077 56.9518 52.6673 56.8485 52.7895C56.7452 52.9118 56.6008 52.987 56.4457 52.9995H51.6235C51.4681 53.0062 51.3163 52.9493 51.2001 52.8406C51.0839 52.732 51.0123 52.5801 51.0005 52.4171V48.6557C50.9941 48.4923 51.0482 48.3327 51.1515 48.2105C51.2548 48.0882 51.3992 48.013 51.5543 48.0005H56.3765C56.5319 47.9938 56.6837 48.0507 56.7999 48.1594C56.9161 48.268 56.9877 48.4199 56.9995 48.5829V52.3443Z"
        className="fill-[#BED253] dark:fill-white"
      />
      <path
        d="M12.6859 10.0361H18.7563V26.9765C18.7563 27.3385 18.6125 27.6856 18.3566 27.9415C18.1006 28.1974 17.7535 28.3412 17.3916 28.3412H11.3684C11.0064 28.3412 10.6593 28.1974 10.4034 27.9415C10.1475 27.6856 10.0037 27.3385 10.0037 26.9765V12.8595C9.98454 12.4956 10.0397 12.1316 10.1659 11.7897C10.2921 11.4478 10.4866 11.1352 10.7376 10.871C10.9886 10.6068 11.2908 10.3965 11.6258 10.2529C11.9608 10.1094 12.3215 10.0356 12.6859 10.0361Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        opacity={0.5}
        d="M12.6859 10.0361H18.7563V26.9765C18.7563 27.3385 18.6125 27.6856 18.3566 27.9415C18.1006 28.1974 17.7535 28.3412 17.3916 28.3412H11.3684C11.0064 28.3412 10.6593 28.1974 10.4034 27.9415C10.1475 27.6856 10.0037 27.3385 10.0037 26.9765V12.8595C9.98454 12.4956 10.0397 12.1316 10.1659 11.7897C10.2921 11.4478 10.4866 11.1352 10.7376 10.871C10.9886 10.6068 11.2908 10.3965 11.6258 10.2529C11.9608 10.1094 12.3215 10.0356 12.6859 10.0361Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        d="M81.2482 10.0361H87.3185C88.0299 10.0361 88.7121 10.3187 89.2152 10.8217C89.7182 11.3248 90.0008 12.007 90.0008 12.7184V26.8354C90.0008 27.1973 89.857 27.5444 89.6011 27.8003C89.3451 28.0562 88.998 28.2 88.6361 28.2H82.5658C82.2039 28.2 81.8568 28.0562 81.6009 27.8003C81.3449 27.5444 81.2012 27.1973 81.2012 26.8354V10.0361H81.2482Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        opacity={0.5}
        d="M81.2482 10.0361H87.3185C88.0299 10.0361 88.7121 10.3187 89.2152 10.8217C89.7182 11.3248 90.0008 12.007 90.0008 12.7184V26.8354C90.0008 27.1973 89.857 27.5444 89.6011 27.8003C89.3451 28.0562 88.998 28.2 88.6361 28.2H82.5658C82.2039 28.2 81.8568 28.0562 81.6009 27.8003C81.3449 27.5444 81.2012 27.1973 81.2012 26.8354V10.0361H81.2482Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        d="M90.001 12.7184V18.6004L10.0047 18.6004V12.7184C10.0047 12.007 10.2873 11.3248 10.7903 10.8217C11.2933 10.3187 11.9755 10.0361 12.6869 10.0361L87.3658 10.0361C88.069 10.0485 88.7392 10.3365 89.2321 10.8382C89.725 11.3399 90.0011 12.0151 90.001 12.7184Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        d="M11.3685 71.6584H17.4389C17.8008 71.6584 18.1479 71.8021 18.4038 72.0581C18.6597 72.314 18.8035 72.6611 18.8035 73.023V89.9634H12.6861C11.9748 89.9634 11.2925 89.6808 10.7895 89.1778C10.2865 88.6748 10.0039 87.9925 10.0039 87.2812V72.7877C10.0598 72.4679 10.228 72.1785 10.4781 71.9715C10.7282 71.7645 11.0439 71.6535 11.3685 71.6584Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        opacity={0.5}
        d="M11.3685 71.6584H17.4389C17.8008 71.6584 18.1479 71.8021 18.4038 72.0581C18.6597 72.314 18.8035 72.6611 18.8035 73.023V89.9634H12.6861C11.9748 89.9634 11.2925 89.6808 10.7895 89.1778C10.2865 88.6748 10.0039 87.9925 10.0039 87.2812V72.7877C10.0598 72.4679 10.228 72.1785 10.4781 71.9715C10.7282 71.7645 11.0439 71.6535 11.3685 71.6584Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        d="M82.5637 71.6582H88.634C88.9959 71.6582 89.343 71.802 89.5989 72.0579C89.8548 72.3138 89.9986 72.6609 89.9986 73.0228V87.1398C89.9986 87.8512 89.716 88.5334 89.213 89.0365C88.71 89.5395 88.0278 89.8221 87.3164 89.8221H81.2461V72.7876C81.3006 72.4757 81.4619 72.1925 81.7022 71.9865C81.9426 71.7804 82.2471 71.6644 82.5637 71.6582Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        opacity={0.5}
        d="M82.5637 71.6582H88.634C88.9959 71.6582 89.343 71.802 89.5989 72.0579C89.8548 72.3138 89.9986 72.6609 89.9986 73.0228V87.1398C89.9986 87.8512 89.716 88.5334 89.213 89.0365C88.71 89.5395 88.0278 89.8221 87.3164 89.8221H81.2461V72.7876C81.3006 72.4757 81.4619 72.1925 81.7022 71.9865C81.9426 71.7804 82.2471 71.6644 82.5637 71.6582Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
      <path
        d="M10.0039 87.2812V81.3991H90.0002V87.2812C90.0002 87.9925 89.7176 88.6748 89.2146 89.1778C88.7116 89.6808 88.0293 89.9634 87.318 89.9634H12.6861C11.9748 89.9634 11.2925 89.6808 10.7895 89.1778C10.2865 88.6748 10.0039 87.9925 10.0039 87.2812Z"
        className="fill-[#2D73C0] dark:fill-white"
      />
    </SvgIcon>
  );
};
