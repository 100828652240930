import { HourglassEndIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { Bold, Card, CardBody } from "ui";

export default function OnboardPending() {
  return (
    <Card>
      <CardBody>
        <div className="flex">
          <div className="w-10/12">
            <Bold>
              <FormattedMessage
                defaultMessage="Account Onboarding is in progress."
                id="hcV7io"
                description="Label for account onboarding in progress"
              />
            </Bold>
          </div>
          <div className="w-2/12">
            <HourglassEndIcon
              size="4xl"
              fill="rgb(var(--text-link) / var(--tw-text-opacity))"
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
