import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "ui";
import { useIsUnMounted } from "../../../../hooks";

type SaveAndOnboardButtonProps = {
  disabled: boolean;
  onSubmit: (arg: { openNewOnboarding: boolean }) => void;
};

export default function SaveAndOnboardButton({
  disabled,
  onSubmit,
}: SaveAndOnboardButtonProps) {
  const isUnMounted = useIsUnMounted();
  const [isLoading, setLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    onSubmit({ openNewOnboarding: true });
    if (!isUnMounted) {
      setLoading(false);
    }
  }, [isUnMounted, onSubmit]);

  return (
    <Button
      data-selector="submit-onboardAnother-button"
      appearance="secondary"
      onClick={handleOnClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      <FormattedMessage
        defaultMessage="Save and Onboard Another Account"
        id="wt3Myz"
        description="Button for saving and onboarding new cloud account"
      />
    </Button>
  );
}
