import { getComputeUrl } from "../apis";
import { baseApi } from "../apis/baseApi";

export const downloadFileRequest = async ({
  path,
  params = {},
  config = {},
  isCompute,
}: {
  path: string;
  params?: Record<string, string>;
  config?: RequestInit;
  isCompute?: boolean;
}) => {
  const useBaseHeaders = isCompute;

  return baseApi({
    ...(isCompute && { baseUrl: await getComputeUrl() }),
    path,
    params,
    config,
    useBaseHeaders,
  });
};
