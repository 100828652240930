import { type ReactNode } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type ToastAppearance =
  | "clear"
  | "info"
  | "warning"
  | "error"
  | "success";

export type Toast = {
  id: string;
  text: string | string[] | (string | ReactNode)[];
  autoDismiss: boolean;
  appearance: ToastAppearance;
};

export type ToastOptions = Omit<Partial<Toast>, "text">;

type ToastStoreState = {
  toasts: Array<Toast>;
};

const initialToasts: ToastStoreState["toasts"] = [];

type ToastActions = {
  actions: {
    toast: (text: Toast["text"], options?: ToastOptions) => string;
    remove: (id: string) => void;
    /**
     * DO NOT USE THIS unless you are sure you want to remove ALL toasts (including those that aren't yours).
     * Prefer the `remove` action instead.
     */
    clear: () => void;
  };
};

/**
 * In most cases, you should not use useToastStore directly. Please use the hook useToastActions instead.
 */
export const useToastStore = create(
  immer<ToastStoreState & ToastActions>((set) => ({
    toasts: initialToasts,
    actions: {
      toast: (text, options = {}) => {
        const id = options.id ?? crypto.randomUUID();

        set((state) => {
          // if the toast ID is already present, update it's message rather than creating a new toast
          const matchingToast = state.toasts.find((t) => t.id === id);
          if (matchingToast) {
            matchingToast.text = text;
            return;
          }

          state.toasts.unshift({
            autoDismiss: true,
            appearance: "clear",
            ...options,
            text,
            id,
          });
        });

        return id;
      },
      remove: (id) =>
        set((state) => {
          state.toasts = state.toasts.filter((toast) => toast.id !== id);
        }),
      clear: () =>
        set((state) => {
          state.toasts = [];
        }),
    },
  })),
);

export const useToastState = () => useToastStore((state) => state.toasts);

export const useToastActions = () => useToastStore((state) => state.actions);
