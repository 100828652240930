import { useIntl } from "react-intl";

import { Input } from "form";

export default function ExternalId() {
  const intl = useIntl();

  return (
    <Input
      allowCopy
      data-selector="aws-externalid-input"
      readOnly={true}
      name="externalId"
      label={intl.formatMessage({
        defaultMessage: "External ID",
        id: "jEllf8",
        description:
          "The name of a display field for onboarding an AWS account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "External ID",
        id: "tkzOVc",
        description: "Placeholder for AWS External Id",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "External ID is required",
          id: "pZoU7c",
          description: "Error message for field for onboarding an AWS account",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "External ID establishes trust relationship between Prisma Cloud Account and AWS Account to pull your data",
        id: "3Hh6Hi",
        description:
          "Tooltip for a display field for onboarding an AWS account",
      })}
    />
  );
}
