import { useEffect, useMemo } from "react";
import Dropzone, { type FileRejection } from "react-dropzone";
import { isEmpty, isNil } from "remeda";
import { Body, Bold, DynamicFormattedMessage } from "ui";
import {
  CREDENTIALS,
  EXTERNAL_ACCOUNT_KEY,
  SERVICE_ACCOUNT,
  SERVICE_ACCOUNT_KEY,
} from "../../../../../../constants";
import {
  clearExternalAccountKey,
  clearJsonFieldErrors,
  clearServiceAccountKey,
  setExternalAccountKey,
  setExternalAccountKeyErrors,
  setServiceAccountKey,
  setServiceAccountKeyErrors,
  validExternalAccountKeyJSON,
  validExternalAccountKeyJSONV2,
  validServiceAccountKeyJSON,
  validateExternalAccountKey,
  validateServiceAccountKey,
} from "../../../context/GcpCredentialsFileContext/state/actions";
import {
  getExternalAccountKey,
  getExternalAccountKeyErrors,
  getExternalAccountKeyName,
  getServiceAccountKey,
  getServiceAccountKeyErrors,
  getServiceAccountKeyName,
} from "../../../context/GcpCredentialsFileContext/state/getters";
import {
  DownloadTerraformScript,
  ExternalAccountFileInfo,
  FileUploadErrors,
  SelectAuthenticationType,
  ServiceAccountFileInfo,
  UploadFileBtn,
} from "./components";
import {
  errorMessages,
  jsonFileUploadMap,
  selectAuthenticationTypeMap,
} from "./messages";

import { FormLayout } from "form";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { classNames } from "utils";
import { useGCPCredentialsFileContext } from "../../../context/GcpCredentialsFileContext";
import CognitoBasedExternalAccountFileInfo from "./components/CognitoBasedExternalAccountFileInfo";

type JsonFileUploadProps = {
  isGcpWorkspace?: boolean;
  isEdit: boolean;
  isGcpWifEnabled: boolean;
  isFederatedGcpWifEnabled: boolean;
  setAuthenticationType: React.Dispatch<React.SetStateAction<string>>;
};

export default function JsonFileUpload({
  isGcpWorkspace = false,
  isEdit,
  isGcpWifEnabled,
  setAuthenticationType,
  isFederatedGcpWifEnabled = false,
}: JsonFileUploadProps) {
  const intl = useIntl();
  const { dispatch, state } = useGCPCredentialsFileContext();
  const { watch, setValue } = useFormContext();
  const JSON_TYPE = ".json";

  const selectedAuthenticationType = watch("authenticationType") ?? "";
  const isServiceAccount = selectedAuthenticationType === SERVICE_ACCOUNT;

  const isFileUploaded = useMemo(() => {
    const jsonData = isServiceAccount
      ? getServiceAccountKey(state)
      : getExternalAccountKey(state);
    return !isNil(jsonData) && !isEmpty(jsonData);
  }, [state, isServiceAccount]);
  useEffect(() => {
    if (selectedAuthenticationType)
      setAuthenticationType(selectedAuthenticationType);
  }, [selectedAuthenticationType, setAuthenticationType]);

  /* c8 ignore start */
  const setFileInfo = ({
    json,
    name,
    size,
  }: {
    json: Record<string, unknown>;
    name: string;
    size: number;
  }) => {
    if (isServiceAccount) {
      dispatch(setServiceAccountKey({ json, name, size }));
      dispatch(validateServiceAccountKey());
    } else {
      dispatch(setExternalAccountKey({ json, name, size }));
      dispatch(validateExternalAccountKey(isFederatedGcpWifEnabled));
    }
    setValue(CREDENTIALS, json);
  };

  const clearFileInfo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (isServiceAccount) {
      dispatch(clearServiceAccountKey());
      dispatch(validateServiceAccountKey());
    } else {
      dispatch(clearExternalAccountKey());
      dispatch(validateExternalAccountKey(isFederatedGcpWifEnabled));
    }
    setValue(CREDENTIALS, {});
  };

  const onDrop = (
    accepted: File[],
    rejected: FileRejection[],
    handleOnLoad: (props: {
      json: Record<string, unknown>;
      name: string;
      size: number;
    }) => void,
  ) => {
    const isAccepted = !isEmpty(accepted);
    let file = isAccepted ? accepted[0] : undefined;
    const isRejected = !file && !isEmpty(rejected);

    /**
     * Note: For some files, file type is different in Windows and Mac OS.
     * When we upload a '.json' file in Windows OS, the file type we get is empty and the file goes to rejected
     * list. In above scenario, we need to pull the file from rejected list then check for '.json' extension in the
     * file name and use the file if it has the required extension.
     */

    if (isRejected) {
      const { name } = (rejected[0] as unknown as { name?: string }) || {
        name: undefined,
      };
      if (name && name.endsWith(JSON_TYPE)) {
        file = rejected[0].file;
      }
    }

    if (file) {
      const f = file;
      const reader = new FileReader();
      reader.onload = (event) =>
        onReaderLoad(event, { name: f.name, size: f.size }, handleOnLoad);
      reader.readAsText(file);
    }
  };

  const onReaderLoad = (
    event: ProgressEvent<FileReader>,
    file: { name: string; size: number },
    handleOnLoad: (props: {
      json: Record<string, unknown>;
      name: string;
      size: number;
    }) => void,
  ) => {
    const errorMessage = errorMessages.invalid_json_file;
    try {
      if (isServiceAccount) {
        dispatch(clearJsonFieldErrors({ field: SERVICE_ACCOUNT_KEY }));
      } else {
        dispatch(clearJsonFieldErrors({ field: EXTERNAL_ACCOUNT_KEY }));
      }

      const json = file.size > 0 && JSON.parse(event?.target?.result as string);
      const { name, size } = file;
      handleOnLoad({ json, name, size });

      if (isServiceAccount) {
        const { isValid } = validServiceAccountKeyJSON(json, errorMessage);
        if (!isValid) {
          dispatch(setServiceAccountKeyErrors(errorMessage));
          dispatch(clearServiceAccountKey());
        }
      } else {
        const { isValid } = isFederatedGcpWifEnabled
          ? validExternalAccountKeyJSONV2(json, errorMessage)
          : validExternalAccountKeyJSON(json, errorMessage);
        if (!isValid) {
          dispatch(setExternalAccountKeyErrors(errorMessage));
          dispatch(clearExternalAccountKey());
        }
      }
    } catch (error) {
      if (isServiceAccount) {
        dispatch(setServiceAccountKeyErrors(errorMessage));
        dispatch(clearServiceAccountKey());
      } else {
        dispatch(setExternalAccountKeyErrors(errorMessage));
        dispatch(clearExternalAccountKey());
      }
    }
  };
  /* c8 ignore start */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnDrop = (accepted: any, rejected: FileRejection[]) =>
    onDrop(accepted, rejected, setFileInfo);

  const errors = isServiceAccount
    ? getServiceAccountKeyErrors(state)
    : getExternalAccountKeyErrors(state);

  const fileNotUploadedError = isServiceAccount
    ? isEmpty(getServiceAccountKeyName(state))
    : isEmpty(getExternalAccountKeyName(state));

  const hasErrors = isEdit
    ? !isEmpty(errors)
    : !isEmpty(errors) || fileNotUploadedError;

  return (
    <FormLayout>
      {(isGcpWifEnabled || isFederatedGcpWifEnabled) && (
        <SelectAuthenticationType isGcpWorkspace={isGcpWorkspace} />
      )}

      {!isGcpWorkspace && <DownloadTerraformScript />}

      <div className="space-y-1">
        <Body size="sm" addClassName="flex items-center space-x-1 leading-5">
          <DynamicFormattedMessage
            messageMap={jsonFileUploadMap}
            token="uploadJsonFileHeader"
            values={{
              b: (chunks) => <Bold addClassName="mx-0.5">{chunks}</Bold>,
              fileType: isServiceAccount
                ? intl.formatMessage(
                    selectAuthenticationTypeMap.serviceAccountKey,
                  )
                : intl.formatMessage(
                    selectAuthenticationTypeMap.workloadIdentityFederation,
                  ),
            }}
          />
        </Body>

        <Dropzone accept={JSON_TYPE} onDrop={handleOnDrop} multiple={false}>
          {({ getInputProps, getRootProps }) => (
            <div
              {...getRootProps()}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={classNames(
                "flex flex-col items-center rounded border-2 border-dotted border-gray-300 px-4 py-2 dark:border-blue-steel-850",
                isEmpty(errors) ? "border-slate-500" : "border-red-500",
              )}
            >
              <div className="flex items-center justify-center gap-2">
                <input {...getInputProps()} />
                <Body size="md" addClassName="text-center">
                  <DynamicFormattedMessage
                    messageMap={jsonFileUploadMap}
                    token="dragOrDrop"
                  />
                </Body>
                <UploadFileBtn isFileUploaded={isFileUploaded} />
              </div>
              <div
                className="w-full"
                onClick={(event) => event.stopPropagation()}
              >
                {isServiceAccount ? (
                  <ServiceAccountFileInfo
                    hasErrors={hasErrors}
                    handleClose={clearFileInfo}
                  />
                ) : isFederatedGcpWifEnabled ? (
                  <CognitoBasedExternalAccountFileInfo
                    hasErrors={hasErrors}
                    handleClose={clearFileInfo}
                  />
                ) : (
                  <ExternalAccountFileInfo
                    hasErrors={hasErrors}
                    handleClose={clearFileInfo}
                  />
                )}
                <FileUploadErrors errors={isEmpty(errors) ? [] : errors} />
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </FormLayout>
  );
}
