import { type ReactNode } from "react";
import { type IntlShape } from "react-intl";
import { Italic, Link } from "ui";

export const getInstructions = ({
  intl,
  isEdit,
  homeRegion,
}: {
  intl: IntlShape;
  isEdit: boolean;
  homeRegion: string;
}) => {
  const values = {
    i: (chunks: ReactNode) => <Italic>{chunks}</Italic>,
  };

  return [
    intl.formatMessage(
      {
        defaultMessage: "Login to the <a>OCI Tenant Console</a>",
        id: "oMYNTR",
        description:
          "Instructions for logging to OCI Tenant Console and generate OCID",
      },
      {
        a: (chunks: ReactNode) => (
          <Link
            size="sm"
            href={`https://console.${homeRegion}.oraclecloud.com/resourcemanager/stacks`}
            target="_blank"
            rel="noopener noreferrer"
            data-selector="oci-console-link"
          >
            {chunks}
          </Link>
        ),
      },
    ),
    intl.formatMessage({
      defaultMessage: "Switch to home region",
      id: "ZI0ULm",
      description:
        "Instructions for logging to OCI Tenant Console and generate OCID",
    }),
    intl.formatMessage(
      {
        defaultMessage:
          "Go to <i>Developer Services > Resource Manager > Stacks</i>",
        id: "9wMxPn",
        description:
          "Instructions for logging to OCI Tenant Console and generate OCID",
      },
      values,
    ),
    ...(isEdit
      ? [
          intl.formatMessage({
            defaultMessage: "Click on PrismaCloudApp and edit stack",
            id: "T6y0gW",
            description:
              "Instructions for logging to OCI Tenant Console and generate OCID",
          }),
        ]
      : [
          intl.formatMessage(
            {
              defaultMessage:
                "Select <i>Create Stack</i> and provide a stack name as PrismaCloudApp",
              id: "r3b8j4",
              description:
                "Instructions for logging to OCI Tenant Console and generate OCID",
            },
            values,
          ),
        ]),
    intl.formatMessage({
      defaultMessage:
        "In stack Information page, select .zip file under 'terraform configuration source'",
      id: "I+6tTs",
      description:
        "Instructions for logging to OCI Tenant Console and generate OCID",
    }),
    intl.formatMessage({
      defaultMessage:
        "Click on the browse link and select the downloaded terraform .zip file",
      id: "Y9eU3L",
      description:
        "Instructions for logging to OCI Tenant Console and generate OCID",
    }),
    intl.formatMessage({
      defaultMessage:
        "Click on Next, continue to review the configuration and save changes",
      id: "Jd/KWW",
      description:
        "Instructions for logging to OCI Tenant Console and generate OCID",
    }),
    intl.formatMessage(
      {
        defaultMessage: "In the current <i>Stack Details</i>, click on Apply",
        id: "HnZfQY",
        description:
          "Instructions for logging to OCI Tenant Console and generate OCID",
      },
      values,
    ),
    intl.formatMessage(
      {
        defaultMessage:
          "In the current Job Details, Navigate to Outputs and copy <i>user_ocid</i>",
        id: "z/EaDi",
        description:
          "Instructions for logging to OCI Tenant Console and generate OCID",
      },
      values,
    ),
  ];
};
