import { LoadingIcon } from "icons";
import { useState, type ReactElement } from "react";
import { useIntl } from "react-intl";
import {
  EmptyStateLayout,
  Portal,
  SomethingWentWrongErrorSmall,
  type EmptyStateLayoutProps,
} from "ui";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { type Data, type HeaderGroup } from "../../types";
import { useTableInstance } from "../TableContext";
import { type TableLayoutProps } from "../TableLayout";
import { Body } from "./Body";
import { Footer } from "./Footer";
import { FooterRow } from "./FooterRow";
import { Header } from "./Header";
import { HeaderRow } from "./HeaderRow";
import { Row, SortableRow, type RowProps } from "./Row";
import { Table } from "./Table";

export interface ConnectedTableProps<D extends Data> {
  enableEmptyState?: boolean;
  emptyStateProps?: EmptyStateLayoutProps;
  /**
   * When this function returns true, the given row will be highlighted.
   */
  shouldHighlightRow?: RowProps<D>["shouldHighlightRow"];
  /**
   * Render a footer row in the table.
   *
   * Use the `Footer` option on the columns to define the footer cell renderer.
   */
  showFooter?: boolean;
  /**
   * Used for QA automation.
   *
   * A unique value used to differentiate this table from others that may be on the page.
   */
  selector?: string;
}

/**
 * Renders the table.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConnectedTable, TableProvider } from "table";
 * ```
 */
export function ConnectedTable<D extends Data>({
  appearance = "card",
  enableEmptyState = true,
  emptyStateProps = {},
  shouldHighlightRow,
  showFooter = false,
  selector,
}: ConnectedTableProps<D> &
  Pick<TableLayoutProps, "appearance">): ReactElement {
  const {
    disableColumnOrder,
    disablePagination,
    footerGroups,
    getTableProps,
    headerGroups,
    isLoading,
    isError,
    error,
    setColumnOrder,
    getTableBodyProps,
    prepareRow,
    page,
    onRowSortEnd,
    rows,
  } = useTableInstance<D>();
  const intl = useIntl();
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const visibleRows = disablePagination ? rows : page;
  const activeRow = activeId && rows.find((row) => row.id === activeId);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id && onRowSortEnd) {
      const oldIndex = visibleRows.findIndex(
        (row) => String(row.id) === active.id,
      );
      const newIndex = visibleRows.findIndex(
        (row) => String(row.id) === over?.id,
      );

      const sortedData = arrayMove(visibleRows, oldIndex, newIndex).map(
        (row) => row.original,
      );
      onRowSortEnd({
        sortedData,
        activeId: active.id,
        overId: over?.id,
      });
    }
    setActiveId(null);
  }

  return (
    <>
      <Table data-testid={selector} {...getTableProps()}>
        <Header appearance={appearance}>
          {headerGroups.map((headerGroup, i) => (
            <HeaderRow
              appearance={appearance}
              disableColumnOrder={disableColumnOrder}
              headerGroup={headerGroup}
              setColumnOrder={setColumnOrder}
              key={i}
            />
          ))}
        </Header>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={visibleRows}
            strategy={verticalListSortingStrategy}
          >
            <Body {...getTableBodyProps()}>
              {visibleRows.map((row) => {
                prepareRow(row);
                return (
                  <SortableRow
                    appearance={appearance}
                    shouldHighlightRow={shouldHighlightRow}
                    row={row}
                    key={row.id}
                  />
                );
              })}
            </Body>
          </SortableContext>
          <Portal>
            <DragOverlay zIndex={20}>
              {activeRow ? (
                <Row
                  row={activeRow}
                  appearance={appearance}
                  shouldHighlightRow={shouldHighlightRow}
                  isHeld
                />
              ) : null}
            </DragOverlay>
          </Portal>
        </DndContext>
        {showFooter && (
          <Footer>
            {footerGroups.map((footerGroup, i) => (
              <FooterRow
                footerGroup={footerGroup as HeaderGroup<D>}
                appearance={appearance}
                key={i}
              />
            ))}
          </Footer>
        )}
      </Table>
      {isError || error ? (
        <SomethingWentWrongErrorSmall error={error} />
      ) : isLoading ? (
        <div className="flex h-full items-center justify-center">
          <LoadingIcon
            size="3xl"
            aria-label={intl.formatMessage({
              defaultMessage: "Loading Table",
              id: "Q55Fw1",

              description: "Loading Icon label",
            })}
          />
        </div>
      ) : enableEmptyState && rows.length === 0 ? (
        <EmptyStateLayout {...emptyStateProps} />
      ) : null}
    </>
  );
}
