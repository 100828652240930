import { type ComponentPropsWithoutRef, type ReactElement } from "react";
export function SomethingWentWrongSVG(
  props: ComponentPropsWithoutRef<"svg">,
): ReactElement {
  return (
    <svg
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="45"
        cy="45"
        r="45"
        className="fill-[#EFF8FF] dark:fill-[#354252]"
      />
      {/* <path d="M0.467773 19L89.4789 19" stroke="#DADBDB" /> */}
      <rect
        x="0.5"
        y="15.5"
        width="89"
        height="61"
        className="fill-white stroke-[#DADBDB] dark:fill-[#19222E] dark:stroke-[#354252]"
      />
      <path
        d="M0 19L89.0112 19"
        className="stroke-[#DADBDB] dark:stroke-[#354252]"
      />
      <ellipse
        cx="52.5926"
        cy="58.5185"
        rx="26.4074"
        ry="4.25926"
        className="fill-[#DADBDB] dark:fill-[#0B1117]"
      />
      <path
        d="M10.6075 58.3602V61.5674H34.6893L34.8822 61.3413L35.1534 59.3491L34.5771 58.8547L34.0009 58.3602H10.6075Z"
        className="fill-white dark:fill-[#19222E]"
      />
      <path
        d="M31.6305 58.3777H13.9681L15.0389 54.7722H30.5597L31.6305 58.3777Z"
        className="fill-[#85C8FF] dark:fill-[#8DD5E2]"
      />
      <path
        d="M28.8267 49.1733H16.6639L18.3429 43.519H27.1471L28.8267 49.1733Z"
        className="fill-[#85C8FF] dark:fill-[#8DD5E2]"
      />
      <path
        d="M32.099 58.7676H13.3911L19.5352 38.0825C19.7408 37.3907 20.1644 36.7838 20.7428 36.3522C21.3213 35.9206 22.0237 35.6875 22.7454 35.6875C23.4671 35.6875 24.1695 35.9206 24.7479 36.3522C25.3264 36.7838 25.75 37.3907 25.9555 38.0825L32.099 58.7676ZM14.4366 57.9879H31.0522L25.2044 38.3047C25.0466 37.7741 24.7217 37.3086 24.2779 36.9776C23.8342 36.6466 23.2954 36.4678 22.7418 36.4678C22.1882 36.4678 21.6494 36.6466 21.2057 36.9776C20.762 37.3086 20.437 37.7741 20.2792 38.3047L14.4366 57.9879Z"
        className="fill-[#5DB8E4] dark:fill-[#8DD5E2]"
      />
      <path
        d="M33.6773 61.9579H11.7836C11.3107 61.9574 10.8573 61.7693 10.523 61.4349C10.1886 61.1006 10.0005 60.6472 10 60.1743V59.7539C10.0005 59.2811 10.1886 58.8277 10.523 58.4933C10.8573 58.1589 11.3107 57.9709 11.7836 57.9703H33.6773C34.1501 57.9709 34.6035 58.1589 34.9379 58.4933C35.2723 58.8277 35.4603 59.2811 35.4609 59.7539V60.1743C35.4603 60.6472 35.2723 61.1006 34.9379 61.4349C34.6035 61.7693 34.1501 61.9574 33.6773 61.9579ZM11.7836 58.75C11.5175 58.7504 11.2623 58.8563 11.0741 59.0445C10.8859 59.2326 10.7801 59.4878 10.7797 59.7539V60.1743C10.7801 60.4405 10.8859 60.6956 11.0741 60.8838C11.2623 61.072 11.5175 61.1779 11.7836 61.1782H33.6773C33.9434 61.1779 34.1985 61.072 34.3867 60.8838C34.5749 60.6956 34.6808 60.4405 34.6811 60.1743V59.7539C34.6808 59.4878 34.5749 59.2326 34.3867 59.0445C34.1985 58.8563 33.9434 58.7504 33.6773 58.75H11.7836Z"
        className="fill-[#5DB8E4] dark:fill-[#8DD5E2]"
      />
      <path
        d="M27.1485 43.0753H18.4898V43.855H27.1485V43.0753Z"
        className="fill-[#5DB8E4] dark:fill-[#8DD5E2]"
      />
      <path
        d="M28.7254 48.7288H16.7504V49.5085H28.7254V48.7288Z"
        className="fill-[#5DB8E4] dark:fill-[#8DD5E2]"
      />
      <path
        d="M30.5038 54.3824H15.0648V55.1621H30.5038V54.3824Z"
        className="fill-[#5DB8E4] dark:fill-[#8DD5E2]"
      />
      <path
        d="M27.1006 48.0491C27.233 48.012 27.2166 47.5413 27.0642 46.9978C26.9117 46.4544 26.6808 46.0439 26.5485 46.081C26.4162 46.1181 26.4325 46.5888 26.585 47.1323C26.7374 47.6758 26.9683 48.0863 27.1006 48.0491Z"
        className="fill-white dark:fill-[#D9EFF3]"
      />
      <path
        d="M26.4327 45.6275C26.565 45.5903 26.6104 45.3398 26.5341 45.0679C26.4579 44.796 26.2888 44.6056 26.1564 44.6427C26.0241 44.6799 25.9786 44.9304 26.0549 45.2023C26.1312 45.4742 26.3003 45.6646 26.4327 45.6275Z"
        className="fill-white dark:fill-[#D9EFF3]"
      />
      <path
        d="M49.6553 31.4501C51.3772 30.2443 53.6695 30.2443 55.3915 31.4501L67.6778 40.054C69.4951 41.3266 70.2565 43.6445 69.5478 45.7469L64.9614 59.3521C64.2759 61.3856 62.3693 62.7549 60.2234 62.7549H44.8234C42.6774 62.7549 40.7708 61.3856 40.0853 59.3521L35.499 45.7469C34.7903 43.6445 35.5516 41.3266 37.3689 40.054L49.6553 31.4501Z"
        className="fill-white stroke-[#5DB8E4] dark:fill-[#19222E] dark:stroke-[#8DD5E2]"
        strokeWidth="4"
      />
      <path
        d="M53.1486 48.4997L53.9241 38.6765C53.9875 37.8726 53.3523 37.1852 52.5458 37.1852C51.7537 37.1852 51.1236 37.8494 51.1652 38.6404L51.6831 48.4805C51.7036 48.8706 52.0259 49.1763 52.4165 49.1763C52.7996 49.1763 53.1184 48.8817 53.1486 48.4997Z"
        className="fill-[#5DB8E4] stroke-[#5DB8E4] dark:fill-[#8DD5E2] dark:stroke-[#8DD5E2]"
        strokeWidth="0.5"
      />
      <path
        d="M51.7735 52.3057L51.7735 52.3057L51.7751 52.3019C51.8119 52.2129 51.8653 52.1316 51.9324 52.0625C51.9984 51.9968 52.0765 51.9443 52.1624 51.9081L52.1624 51.9081L52.1677 51.9058C52.2591 51.8661 52.3576 51.8455 52.4573 51.8455C52.5569 51.8455 52.6555 51.8661 52.7468 51.9058L52.7544 51.9091L52.7621 51.9122C52.8482 51.9464 52.9264 51.9979 52.9917 52.0636L52.9917 52.0636L52.9934 52.0654C53.0638 52.1355 53.1203 52.2184 53.1597 52.3096L53.1598 52.3097C53.2 52.4028 53.2208 52.5031 53.2208 52.6045C53.2208 52.7041 53.2008 52.8027 53.1619 52.8943C53.1206 52.9826 53.0645 53.0632 52.996 53.1326C52.9263 53.1989 52.8454 53.2523 52.757 53.2903C52.6623 53.3272 52.5615 53.3463 52.4598 53.3467L52.4598 53.3466L52.4509 53.3468C52.3543 53.3488 52.2582 53.3313 52.1685 53.2953L51.9821 53.7592L52.1685 53.2953C52.0788 53.2592 51.9974 53.2054 51.929 53.137C51.8606 53.0687 51.8068 52.9872 51.7708 52.8975C51.7347 52.8078 51.7172 52.7118 51.7193 52.6151L51.7195 52.6036L51.7192 52.592C51.7168 52.4938 51.7353 52.3962 51.7735 52.3057Z"
        className="fill-[#5DB8E4] stroke-[#5DB8E4] dark:fill-[#8DD5E2] dark:stroke-[#8DD5E2]"
      />
    </svg>
  );
}
