import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

export const APIEndpoints = lazy(() => import("./APIEndpoints"));

export const apiEndpointsRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "api-discovery",
  component: APIEndpoints,
});
