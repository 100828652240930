import { defineMessages } from "react-intl";

export const messageMap = defineMessages({
  label: {
    defaultMessage:
      "Automatically onboard projects that are accessible by this service account",
    id: "2ydp/g",
    description: "Label for Automatically onboarding projects for GCP account",
  },
  tooltip: {
    defaultMessage:
      "Select to allow Prisma Cloud to automatically onboard all existing and future projects that are accessible by the service account.",
    id: "JX+eqc",
    description: "The name of an checkbox field for onboarding a GCP account",
  },
});
