import { useMutation } from "@tanstack/react-query";
import { useDeleteRecord } from "form";
import { defineMessages, useIntl, type MessageDescriptor } from "react-intl";
import {
  deletePermissionGroup,
  permissionGroupsKeys,
  type PermissionGroupData,
} from "requests";
import { ConfirmationModal, useModal, type RenderModalProps } from "ui";
type ConfirmDeleteModalProps = RenderModalProps<{
  row: PermissionGroupData;
}>;

const errorMessages: Record<string, MessageDescriptor> = defineMessages({
  fallback_error_message: {
    defaultMessage:
      'Unable to delete Permission Group "{name}". Please contact your Prisma Cloud support team if the problem persists.',
    id: "D40SA4",
    description: "Message when an API call fails.",
  },
  active_roles_for_permission_group_exists: {
    defaultMessage:
      'Permission Group "{name}" cannot be deleted since it is associated with a user role.',
    id: "RHxTxt",
    description: "Error message when deleting a permission group",
  },
  operation_not_allowed_on_permission_group: {
    defaultMessage:
      'Permission Group "{name}" cannot be deleted. Only Custom Permission Groups can be deleted.',
    id: "axZWnE",
    description:
      "Error message when trying to delete a default permission group",
  },
  permission_group_can_not_be_deleted: {
    defaultMessage:
      'Permission Group "{name}" cannot be deleted. Please contact your Prisma Cloud support team if the problem persists.',
    id: "ZPTvSG",
    description: "Error message when deleting a permission group",
  },
});

export function ConfirmDeleteModal({
  row,
  isOpen,
  closeModal,
}: ConfirmDeleteModalProps) {
  const intl = useIntl();

  const { id, name } = row;

  const deleteRecord = useDeleteRecord();

  const { mutateAsync: deleteRow } = useMutation({
    mutationFn: async () => {
      await deleteRecord(() => deletePermissionGroup({ id: id ?? "" }), {
        id: id ?? "",
        record: row,
        dataCacheKey: permissionGroupsKeys.permissionGroups({
          includeAssociatedRoles: "true",
          includeFeatures: "true",
        }),
        knownErrorMessages: errorMessages,
        successMessage: intl.formatMessage(
          {
            defaultMessage: "Permission Group {name} deleted successfully.",
            id: "XAMiQW",
            description:
              "Message when permission group is successfully deleted",
          },
          { name },
        ),
        onSuccess: () => closeModal(),
      });
    },
  });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      closeModal={closeModal}
      onConfirm={deleteRow}
      title={intl.formatMessage({
        defaultMessage: "Confirm Delete Permission Group",
        id: "C38M+j",
        description: "Heading of modal to confirm deleting a permission group",
      })}
      prompt={intl.formatMessage(
        {
          defaultMessage:
            "Are you sure you want to delete permission group: {name}?",
          id: "S1UmdW",
          description: "Prompt to confirm deleting a permission group",
        },
        { name },
      )}
      isDelete={true}
      confirmationBtnText={intl.formatMessage({
        defaultMessage: "Delete Permission Group",
        id: "kkZPaZ",
        description: "Confirmation button text for deleting a Permission Group",
      })}
    />
  );
}

export function useConfirmDeleteModal() {
  return useModal<{
    row: PermissionGroupData;
  }>(ConfirmDeleteModal);
}
