import {
  CloseIcon,
  DangerIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from "icons";
import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type PropsWithChildren,
  type ReactElement,
  type ReactEventHandler,
} from "react";

import { useIntl } from "react-intl";
import { classNames } from "utils";

export type BannerAppearance = "standard" | "success" | "warning" | "error";
export type BannerProps = {
  appearance?: BannerAppearance;
  onClose?: ReactEventHandler<HTMLButtonElement>;
  showIcon?: boolean;
  type?: "inline" | "page";
} & Omit<ComponentPropsWithoutRef<"div">, "className">;

const icons: Record<BannerAppearance, ElementType> = {
  standard: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  error: DangerIcon,
};

const containerStyles: Record<BannerAppearance, string> = {
  standard: "bg-blue-100 dark:bg-blue-1000",
  success: "bg-green-100 dark:bg-green-1000",
  warning: "bg-yellow-100 dark:bg-yellow-1000",
  error: "bg-red-100 dark:bg-red-1000",
};

const pageBorders: Record<BannerAppearance, string> = {
  standard: "border-blue-400",
  success: "border-green-400",
  warning: "border-yellow-300",
  error: "border-red-400",
};

export const Banner = (props: PropsWithChildren<BannerProps>): ReactElement => {
  const {
    appearance = "standard",
    children,
    onClose,
    showIcon = false,
    type = "inline",
    ...rest
  } = props;

  const intl = useIntl();
  const containerClasses = classNames(
    "flex p-3",
    type === "inline" && "rounded",
    containerStyles[appearance],
    type === "page" && "border-b-2",
    type === "page" && pageBorders[appearance],
  );
  const Icon = icons[appearance];

  return (
    <div role="banner" {...rest} className={containerClasses}>
      {showIcon && (
        <div className="mr-3 grid content-center">
          <Icon aria-hidden appearance="light" />
        </div>
      )}
      <div className="grow">{children}</div>
      {onClose && (
        <div>
          <button
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              id: "4Cv5mw",

              description: "Close or dismiss notification",
            })}
            type="button"
            onClick={onClose}
            className="ml-4 rounded text-secondary hover:text-default focus:outline-none focus:ring"
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  );
};
