/* c8 ignore start */
import { type Status } from "../../../../types";

type SecurityCapabilitiesDataProps = {
  message?: string;
  id?: string;
  name: string;
  status: Status;
  statusMessage?: {
    message: string;
    staticMessage: boolean;
  };
}[];

export function getSecurityCapabilitiesData(
  data: SecurityCapabilitiesDataProps,
) {
  const misconfigurationsData = data.filter(({ name }) =>
    ["azure_config_metadata", "compute_discovery"].includes(name.toLowerCase()),
  );
  const threatDetectionData = data.filter(({ name }) =>
    ["audit_log", "azure_flow_logs"].includes(name.toLowerCase()),
  );
  const agentlessWorkloadScanningData = data.find(
    ({ name }) => name.toLowerCase() === "agentless_scanning",
  );
  const serverlessFunctionScanningData = data.find(
    ({ name }) => name.toLowerCase() === "serverless_function_scanning",
  );
  const agentBasedWorkloadProtectionData = data.find(
    ({ name }) => name.toLowerCase() === "auto_protect",
  );
  const remediationData = data.find(
    ({ name }) => name.toLowerCase() === "remediation",
  );
  return {
    misconfigurationsData,
    threatDetectionData,
    agentlessWorkloadScanningData,
    serverlessFunctionScanningData,
    agentBasedWorkloadProtectionData,
    remediationData,
  };
}
