import { useEffect } from "react";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "remeda";

import { snakeCase } from "utils";
import {
  CLIENT_EMAIL,
  EXTERNAL_ACCOUNT,
  GCP_ENVIRONMENT_ID,
  GCP_WIF_POOL_LOCATION,
  PROJECT_ID,
  SERVICE_ACCOUNT,
  TYPE,
} from "../../../../../../../../constants";
import { useGCPCredentialsFileContext } from "../../../../../context/GcpCredentialsFileContext";
import { setProjectIdWif } from "../../../../../context/GcpCredentialsFileContext/state/actions";
import {
  getExternalAccountKey,
  getExternalAccountKeyName,
} from "../../../../../context/GcpCredentialsFileContext/state/getters";
import { jsonFileKeys } from "../../messages";
import FileUploadDetails from "../FileUploadDetails";
import List from "../List";

type ExternalAccountFileInfoProps = {
  hasErrors: boolean;
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function ExternalAccountFileInfo({
  hasErrors,
  handleClose,
}: ExternalAccountFileInfoProps) {
  const intl = useIntl();

  const { dispatch, state } = useGCPCredentialsFileContext();

  const json = getExternalAccountKey(state);
  const name = getExternalAccountKeyName(state);

  const serviceAccountImpersonationUrl =
    !isEmpty(json) &&
    !isNil(json) &&
    json[TYPE] === EXTERNAL_ACCOUNT &&
    (json["service_account_impersonation_url"] as string).match(
      /https:\/\/iamcredentials.googleapis.com\/v1\/projects\/-\/serviceAccounts\/(.+?)@(.+?).iam.gserviceaccount.com:generateAccessToken/,
    );

  const audience =
    !isEmpty(json) &&
    !isNil(json) &&
    json[TYPE] === EXTERNAL_ACCOUNT &&
    (json["audience"] as string).match(
      /\/\/iam.googleapis.com\/projects\/(.+?)\/locations\/global\/workloadIdentityPools\/?(.*)/,
    );

  const projectId =
    serviceAccountImpersonationUrl && serviceAccountImpersonationUrl[2];

  useEffect(() => {
    projectId && dispatch(setProjectIdWif({ projectId }));
  }, [projectId, dispatch]);

  if (
    hasErrors ||
    isEmpty(json) ||
    (json[TYPE] && json[TYPE] !== EXTERNAL_ACCOUNT)
  )
    return null;

  if (!("credential_source" in json)) return null;

  const serviceAccount =
    serviceAccountImpersonationUrl && serviceAccountImpersonationUrl[1];
  const clientEmail =
    serviceAccountImpersonationUrl &&
    `${serviceAccountImpersonationUrl[1]}@${serviceAccountImpersonationUrl[2]}.iam.gserviceaccount.com`;
  const wifPoolLocation = audience && audience[2];
  const environmentType = json["credential_source"]["environment_id"];

  const jsonFileValues = {
    [snakeCase(PROJECT_ID)]: projectId,
    [SERVICE_ACCOUNT]: serviceAccount,
    [CLIENT_EMAIL]: clientEmail,
    [GCP_WIF_POOL_LOCATION]: wifPoolLocation,
    [GCP_ENVIRONMENT_ID]: environmentType,
  };

  const info = [
    PROJECT_ID,
    SERVICE_ACCOUNT,
    CLIENT_EMAIL,
    GCP_WIF_POOL_LOCATION,
    GCP_ENVIRONMENT_ID,
  ]
    .map(snakeCase)
    .map((key, index) => (
      <List
        key={index}
        index={index}
        label={intl.formatMessage(
          jsonFileKeys[key as keyof typeof jsonFileKeys],
        )}
        value={jsonFileValues[key]}
      />
    ));

  return (
    <FileUploadDetails
      handleClose={handleClose}
      isServiceAccount={false}
      name={name}
    >
      {info}
    </FileUploadDetails>
  );
}
