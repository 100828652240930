import { useContext, type ReactElement, type ReactNode } from "react";

import { classNames } from "utils";
import { RadioGroupContext } from "./RadioGroup";
import { RadioInput, type RadioInputProps } from "./RadioInput";

export type RadioProps = {
  /**
   * The children become the label next to the radio
   */
  children: ReactNode;
  /**
   * The value of the radio
   */
  value: string;
  /**
   * Disable selecting the radio
   */
  disabled?: boolean;
} & RadioInputProps;

/**
 * A radio that can be used to build forms. Radios must be wrapped in a RadioGroup.
 */
export function Radio(props: RadioProps): ReactElement {
  const { checked, children, disabled = false, value, ...rest } = props;
  const {
    disabled: groupDisabled,
    name,
    onChange,
    ref,
    value: selectedValue,
  } = useContext(RadioGroupContext);
  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && name === "") {
    throw new Error(
      "Attempted to render Radio outside of a RadioGroup context or RadioGroup name is not set. Make sure your component is rendered inside <RadioGroup> with a name.",
    );
  }
  const isDisabled = groupDisabled || disabled;
  const checkedProps: { checked?: boolean; defaultChecked?: boolean } = {};
  if (checked !== undefined) {
    checkedProps["checked"] = checked;
  } else {
    checkedProps["defaultChecked"] = value === selectedValue;
  }

  return (
    <label
      className={classNames(
        "flex items-center space-x-2 text-xs",
        isDisabled ? "cursor-not-allowed text-disabled" : "cursor-pointer",
      )}
    >
      <RadioInput
        name={name}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        ref={ref}
        {...checkedProps}
        {...rest}
      />

      <span>{children}</span>
    </label>
  );
}
