import { Input } from "form";
import { LoadingIcon } from "icons";
import { FormattedMessage, defineMessage, useIntl } from "react-intl";
import { Body } from "ui";
import { getValidateAwsAccountName } from "../../../Onboarding/utils";

type AccountNameProps = {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  isFetching?: boolean;
};

export default function AccountName({
  onBlur,
  isFetching = false,
}: AccountNameProps) {
  const intl = useIntl();
  const label = defineMessage({
    defaultMessage: "Prisma Cloud Account Name",
    id: "yLrYhi",
    description: "The name of an input field for onboarding a cloud account",
  });

  return (
    <section className="space-y-2">
      <Body size="sm" appearance="secondary" addClassName="mb-1 font-bold">
        <FormattedMessage {...label} />
      </Body>
      <Input
        showLabel={false}
        name="name"
        data-selector="cloud-account-name-input"
        label={intl.formatMessage(label)}
        onBlur={onBlur}
        placeholder={intl.formatMessage(label)}
        register={{
          validate: getValidateAwsAccountName(intl),
          maxLength: {
            message: intl.formatMessage({
              defaultMessage:
                "Account Name cannot be more than 50 characters long",
              id: "4EHnDw",
              description: "First Name field length validation",
            }),
            value: 50,
          },
        }}
        suffix={isFetching && <LoadingIcon size="xs" />}
      />
    </section>
  );
}
