import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { investigateLayoutRoute } from "../investigateLayoutRoute";

export const BackgroundJobs = lazy(() => import("./BackgroundJobs"));

const BackgroundJobsSearchSchema = z.object({
  reportId: z.string().optional(),
});

export const backgroundJobsRoute = new Route({
  getParentRoute: () => investigateLayoutRoute,
  path: "background-jobs",
  component: BackgroundJobs,
  validateSearch: BackgroundJobsSearchSchema,
});
