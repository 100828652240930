import { z } from "zod";

export const AwsCreateIamRoleCftRequestSchema = z.object({
  accountId: z.string().optional(),
  accountName: z.string().optional(),
  accountType: z.union([z.literal("account"), z.literal("organization")]),
  awsPartition: z.string(),
  features: z.array(z.string()),
  featureWithModes: z
    .array(
      z.object({
        feature: z.string(),
        mode: z.union([z.literal("cloud-scan"), z.literal("target-scan")]),
      }),
    )
    .optional(),
  customMemberRoleNameEnabled: z.boolean().optional(),
  useTenantExternalId: z.boolean().optional(),
  automatedFlow: z.boolean().optional(),
});

export const AwsCreateIamRoleCftResponseSchema = z.object({
  createStackLinkWithS3PresignedUrl: z.string(),
  eventBridgeRuleNamePrefix: z.string(),
  externalId: z.string(),
});

export const AwsSelectMemberAccountsChildrenRequestSchema = z.object({
  accountId: z.string(),
  accountType: z.string(),
  enabled: z.boolean(),
  name: z.string(),
  roleArn: z.string(),
});

const HierarchySelection = z.object({
  id: z.string(),
  type: z.string(),
  displayName: z.string(),
  parent: z.string().optional(),
  hasChild: z.boolean(),
});

export const AwsSelectMemberAccountsChildrenResponseSchema = z.object({
  response: z.array(HierarchySelection),
});

export const AwsSelectMemberAccountsAncestorsRequestSchema = z
  .object({
    resourceIds: z.array(z.string()),
  })
  .merge(AwsSelectMemberAccountsChildrenRequestSchema);

export const AwsSelectMemberAccountsAncestorsResponseSchema = z.array(
  z.object({
    resourceId: z.string(),
    ancestors: z.array(HierarchySelection),
    status: z.string(),
    responseCode: z.number(),
  }),
);

export type AwsSelectMemberAccountsAncestorsType = z.infer<
  typeof AwsSelectMemberAccountsAncestorsResponseSchema
>;
