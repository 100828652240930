import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { trustedIpAddressesRoute } from "../trustedIpAddressesRoute";

export const TrustedIpAlert = lazy(() => import("./TrustedIpAlert"));

export const trustedIpAlertRoute = new Route({
  getParentRoute: () => trustedIpAddressesRoute,
  path: "alert",
  component: TrustedIpAlert,
});
