import { CloneIcon, DeleteIcon, EditIcon } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import { type PermissionGroupData } from "requests";
import {
  ActionCellButton as ActionButton,
  ActionsCell as BaseActionsCell,
  type RowInstance,
} from "table";
import { usePermissionGroupWizard } from "../../PermissionGroupWizard/PermissionGroupWizard";
import { useConfirmDeleteModal } from "./ConfirmDeleteModal";

type ActionsCellProps = {
  row: Pick<RowInstance<PermissionGroupData>, "original">;
};

export function ActionsCell({ row }: ActionsCellProps): ReactElement {
  const intl = useIntl();
  const { openModal: openConfirmDeleteModal } = useConfirmDeleteModal();
  const { openModal: openPermissionGroupWizard } = usePermissionGroupWizard();
  const isCustom = row?.original?.type === "Custom";
  return (
    <BaseActionsCell>
      {isCustom && (
        <ActionButton
          label={intl.formatMessage({
            defaultMessage: "Edit",
            id: "crclvu",
            description: "Edit label",
          })}
          icon={<EditIcon />}
          onClick={() =>
            openPermissionGroupWizard({
              record: row.original,
              actionType: "edit",
            })
          }
        />
      )}
      <ActionButton
        label={intl.formatMessage({
          defaultMessage: "Clone",
          id: "DGDmGa",
          description: "Clone label",
        })}
        icon={<CloneIcon />}
        onClick={() =>
          openPermissionGroupWizard({
            record: row.original,
            actionType: "clone",
          })
        }
      />
      {isCustom && (
        <ActionButton
          label={intl.formatMessage({
            defaultMessage: "Delete",
            id: "JErLDT",
            description: "Delete label",
          })}
          icon={<DeleteIcon />}
          onClick={() =>
            openConfirmDeleteModal({
              row: row.original,
            })
          }
        />
      )}
    </BaseActionsCell>
  );
}
