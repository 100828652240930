/* eslint-disable react/jsx-no-literals */
import { Body, Link } from "ui";

import chinaIcon from "./mps_icon.webp";

export function ChinaFooter() {
  return (
    <div className="w-full items-center border-t bg-gray-100 p-3 text-secondary dark:border-blue-steel-850 dark:bg-blue-steel-950 dark:text-dark-bg-secondary">
      <Body
        as="div"
        appearance="secondary"
        size="sm"
        addClassName="flex items-center justify-center"
      >
        <Link
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030177"
          external
        >
          <img src={chinaIcon} className="mr-1 h-4" /> 京公网安备
          11010802030177号
        </Link>
        <div className="mx-2 border-x border-gray-700 px-2">
          @北京神州数码云计算有限公司版权所有
        </div>
        <Link href="http://www.beian.miit.gov.cn" external>
          京ICP备15035203号-11
        </Link>
      </Body>
    </div>
  );
}
