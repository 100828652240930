import { useMemo } from "react";

import { useAccountGroupItems, type AccountGroupItem } from "prisma";
import { isEmpty } from "remeda";

export function useGetDefaultAccountGroupId() {
  const { items = [], isLoading } = useAccountGroupItems({
    itemValue: "id",
  });
  const defaultAccountGroupId = useMemo(() => {
    let tempItems: AccountGroupItem[] = [];
    const item = items.filter(
      ({ children }) => children === "Default Account Group",
    );
    if (isEmpty(item)) {
      tempItems = [...items].sort((a, b) => (a.children > b.children ? -1 : 1));
    }
    return isEmpty(item) ? tempItems : item;
  }, [items]);
  return { isLoading, defaultAccountGroupId };
}
