import { useWizardContext } from "../../../../../../components/Wizard";

import { AccountGroupSelect } from "prisma";
import { OCI } from "../../../../../constants";
import {
  type OciConfigureAccountStepValuesType,
  type OciGetStartedStepValuesType,
} from "../../../../../types";
import AccountName from "../../../components/AccountName";
import { accountGroupsMultiSelect } from "../../../utils";
import DownloadTerraform from "../DownloadTerraform";
import GroupName from "./GroupName";
import HomeRegion from "./HomeRegion";
import PolicyName from "./PolicyName";
import TenantOCID from "./TenantOCID";
import UserName from "./UserName";
import UserOCID from "./UserOCID";

type AccountDetailsProps = {
  isEdit: boolean;
  accountId: string;
  closeModal: () => void;
};

export default function AccountDetails({
  isEdit,
  accountId,
  closeModal,
}: AccountDetailsProps) {
  const {
    state: { steps },
  } = useWizardContext();

  const { accountType } = steps[0]?.values as OciGetStartedStepValuesType;

  const {
    groupIds: accountGroupDefaultValue,
    userOcid,
    policyName,
    userName,
    groupName,
    regions,
    homeRegion,
  } = steps[1]?.values as OciConfigureAccountStepValuesType;

  const accountGroupsMultiSelectProps = {
    accountType,
    selectedCloud: OCI,
  };

  return (
    <section className="space-y-2">
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <AccountName />
          </div>
          <div className="w-1/2">
            <TenantOCID isEdit={isEdit} accountId={accountId} />
          </div>
        </div>

        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <HomeRegion regions={regions} defaultValue={homeRegion} />
          </div>
          <div className="w-1/2">
            <UserName userName={userName} />
          </div>
        </div>

        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <GroupName groupName={groupName} />
          </div>
          <div className="w-1/2">
            <PolicyName policyName={policyName} />
          </div>
        </div>

        <DownloadTerraform isEdit={isEdit} closeModal={closeModal} />

        <UserOCID name={userOcid} />

        <AccountGroupSelect
          defaultValue={accountGroupDefaultValue}
          enableMultiSelect={accountGroupsMultiSelect({
            ...accountGroupsMultiSelectProps,
          })}
          itemValue="id"
          name="groupIds"
        />
      </div>
    </section>
  );
}
