import { Set } from "immutable";

import { useCallback } from "react";

import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import { isEmpty } from "remeda";
import SelectMemberAccountTree from "../../../../../../../../components/SelectMemberAccountTree";
import { type FlatNodeType } from "../../../../../../../../components/SelectMemberAccountTree/types";
import { AWS } from "../../../../../../../../constants";
import ErrorMessages from "../../../../../../components/ErrorMessages";
import ModelRadioGroup from "../../../../../../components/ModelRadioGroup";
import { useAWSSelectMemberAccountsContext } from "../../../../../context/AwsSelectMemberAccountsContext";
import {
  setAwsOUExpandedNodes,
  setAwsOUProjectSelectMode,
  setAwsOUSelectedNodes,
} from "../../../../../context/AwsSelectMemberAccountsContext/state/actions";
import {
  getAwsOUDefaultExpandedNodes,
  getAwsOUFolderViewCredentialsError,
  getAwsOUProjectSelectMode,
  getAwsOURootId,
  getAwsOUSelectProjectsInitialized,
  getAwsOUSelectProjectsIsLoading,
  getAwsOUSelectedNodes,
  getAwsOUSelectedNodesErrors,
  getAwsOUTreeMap,
  getAwsOuLoadedNodes,
} from "../../../../../context/AwsSelectMemberAccountsContext/state/getters";

type AWSOrgUnitSelectionProps = {
  loadAwsOUNodeData: UseMutateAsyncFunction<
    void,
    unknown,
    {
      childrenType: string;
      loadType: string;
      parentId: string;
    },
    unknown
  >;
};

export default function AWSOrgUnitSelection({
  loadAwsOUNodeData,
}: AWSOrgUnitSelectionProps) {
  const { state, dispatch } = useAWSSelectMemberAccountsContext();

  const credentialError = getAwsOUFolderViewCredentialsError(state);
  const defaultExpandedKeys = getAwsOUDefaultExpandedNodes(state);
  const loadedNodes = getAwsOuLoadedNodes(state);
  const mode = getAwsOUProjectSelectMode(state);
  const rootId = getAwsOURootId();
  const selectedNodes = getAwsOUSelectedNodes(state);
  const selectedNodesErrors = getAwsOUSelectedNodesErrors(state);
  const treeMap = getAwsOUTreeMap(state);
  const initialized = getAwsOUSelectProjectsInitialized(state);
  const isLoading = getAwsOUSelectProjectsIsLoading(state);

  const renderLoader = isLoading;
  const renderTree = !isLoading && initialized;

  const onChangeSelectedNodes = useCallback(
    (checkedNodeList: Set<string>) =>
      setAwsOUSelectedNodes(checkedNodeList, dispatch),
    [dispatch],
  );

  const onExpandNode = useCallback(
    (expandedList: string[], treeNode: FlatNodeType) => {
      dispatch(setAwsOUExpandedNodes(Set(expandedList)));

      if (!loadedNodes.has(treeNode.id) && treeNode.expanded) {
        loadAwsOUNodeData({
          childrenType: "all",
          loadType: "initial",
          parentId: treeNode.id,
        });
      }
    },
    [loadedNodes, dispatch, loadAwsOUNodeData],
  );

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setAwsOUProjectSelectMode(event?.target?.value, dispatch),
    [dispatch],
  );

  return (
    <>
      {!renderLoader && (
        <ModelRadioGroup mode={mode} onChangeMode={onChangeMode} />
      )}

      {renderLoader && !credentialError && (
        <div className="flex justify-center text-center">
          <LoadingIcon size="xl" />
        </div>
      )}

      <ErrorMessages errors={selectedNodesErrors} />

      {renderTree && !credentialError ? (
        <SelectMemberAccountTree
          defaultExpandedKeys={defaultExpandedKeys}
          selectedNodes={selectedNodes}
          error={!isEmpty(selectedNodesErrors)}
          loadedNodes={loadedNodes}
          onLoadMore={loadAwsOUNodeData}
          onChangeSelectedNodes={onChangeSelectedNodes}
          onExpandNode={onExpandNode}
          rootId={rootId}
          treeMap={treeMap}
          selectedCloud={AWS}
        />
      ) : null}
    </>
  );
}
