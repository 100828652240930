import { useEffect } from "react";
import {
  type UseInfiniteScrollHookArgs,
  type UseInfiniteScrollHookResponse,
} from "./types";

import useVisibilityTracker from "./useVisibilityTracker";

const delay = 100;

export function useInfiniteScroll({
  loading,
  hasNextPage,
  onLoadMore,
  rootMargin,
  threshold,
  disabled,
  delayInMs = delay,
}: UseInfiniteScrollHookArgs): UseInfiniteScrollHookResponse {
  const [ref, { rootRef, isVisible }] = useVisibilityTracker({
    rootMargin,
    threshold,
  });

  const shouldLoadMore = !disabled && !loading && isVisible && hasNextPage;

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (shouldLoadMore) {
      timer = setTimeout(() => {
        onLoadMore();
      }, delayInMs);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [onLoadMore, shouldLoadMore, delayInMs]);

  return [ref, { rootRef, isVisible }];
}
