import { debounce } from "debounce";
import { useCallback, useEffect, useMemo } from "react";
import * as R from "remeda";
import {
  TablePreferencesSchema,
  usePreferences,
  type PageName,
  type Preferences,
  type TablePreferencesType,
} from "requests";

export default function useTablePreferences<
  P extends PageName,
  F extends keyof Required<Preferences>[P],
>(page: P, table: F) {
  const {
    data,
    isLoading,
    update: { mutate: update },
  } = usePreferences(page, table, TablePreferencesSchema);

  const debouncedUpdate = useMemo(() => debounce(update, 500), [update]);

  useEffect(() => {
    return () => {
      debouncedUpdate.clear();
    };
  }, [debouncedUpdate]);

  const onStateChange = useCallback(
    (tableState: Omit<TablePreferencesType, "globalFilter">) => {
      if (tableState.columnResizing?.isResizingColumn) return;
      const tablePreferences = TablePreferencesSchema.omit({
        globalFilter: true,
      }).safeParse(tableState);
      if (!tablePreferences.success) return;
      if (R.equals(data, tablePreferences.data)) return;

      debouncedUpdate(tablePreferences.data);
    },
    [data, debouncedUpdate],
  );

  return { initialState: data, isLoading, onStateChange };
}
