import { colorThemes } from "colors";
import { isDefined } from "remeda";
import { type ColorTheme } from "requests";
import { useDarkMode } from "ui";
import { classNames } from "utils";

export interface CircularColorPickerProps {
  selectedColorTheme: ColorTheme;
  onColorThemeChange: (theme: ColorTheme) => void;
  addClassName?: string;
}

export function CircularColorPicker({
  selectedColorTheme,
  onColorThemeChange,
  addClassName,
}: CircularColorPickerProps) {
  const { isDark } = useDarkMode();

  return (
    <div className={`isolate flex flex-wrap ${addClassName}`}>
      {(Object.keys(colorThemes) as ColorTheme[]).map((colorTheme) => {
        const colorThemeValue = colorThemes[colorTheme];
        const isSelected = colorTheme === selectedColorTheme;

        return (
          <div
            className="relative flex items-center justify-center"
            key={isDark ? colorThemeValue.darkhex : colorThemeValue.lighthex}
          >
            <BorderBounce isSelected={isSelected} />
            <div
              className="h-8 w-8 cursor-pointer rounded-full"
              style={{
                background: `${
                  isDark ? colorThemeValue.darkhex : colorThemeValue.lighthex
                }`,
              }}
              onClick={() => {
                if (isDefined(onColorThemeChange)) {
                  return onColorThemeChange(colorTheme);
                }
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

export function BorderBounce({
  isSelected,
  large,
}: {
  isSelected?: boolean;
  large?: boolean;
}) {
  return (
    <div
      className={classNames(
        "absolute -z-10",
        large ? "h-24 w-24" : "h-12 w-12",
        isSelected &&
          "flex items-center justify-center rounded-2xl border border-white animate-in motion-safe:duration-300 motion-safe:zoom-in",
      )}
      style={{
        boxShadow: isSelected
          ? "3px 2px 22px 1px rgba(245, 242, 242, 0.24)"
          : "",
      }}
    ></div>
  );
}
