import { CaretRightIcon } from "icons";
import { Fragment, type ReactElement, type ReactNode } from "react";

export interface BreadcrumbsProps {
  /**
   * An array of links to ancestor pages.
   */
  breadcrumbs: ReactNode[];
}

export function Breadcrumbs({
  breadcrumbs,
}: BreadcrumbsProps): ReactElement | null {
  if (!breadcrumbs.length) return null;

  return (
    <div className="flex items-center space-x-1 text-xs">
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment key={index}>
          {breadcrumb}
          {index < breadcrumbs.length - 1 && <CaretRightIcon size="sm" />}
        </Fragment>
      ))}
    </div>
  );
}
