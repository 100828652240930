import { type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Button, Select, type SelectItem } from "ui";

import { fwScanMessages } from "../messages";

type SNSTopicTypePropTypes = {
  selectedItem: SelectItem;
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
  setSelectedType: Dispatch<SetStateAction<SelectItem>>;
};

export default function SNSTopicType({
  selectedItem,
  setCloudTrailTopic,
  setSelectedType,
}: SNSTopicTypePropTypes) {
  const intl = useIntl();

  const addNew = intl.formatMessage(fwScanMessages.addNew);
  const selectExisting = intl.formatMessage(fwScanMessages.selectExisting);

  const items = [
    { children: selectExisting, value: "Select Existing" },
    { children: addNew, value: "Add New" },
  ];

  return (
    <Select
      button={
        <Button addClassName="w-36" isMenu>
          {selectedItem.value as string}
        </Button>
      }
      fullWidth
      items={items}
      menuStyle={{ width: "8rem" }}
      onChange={({ selectedItem }) => {
        setCloudTrailTopic("");

        setSelectedType(selectedItem as SelectItem);
      }}
      selectedItem={selectedItem}
    ></Select>
  );
}
