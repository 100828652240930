import { intervalToDuration, isAfter } from "date-fns";

import { type ComponentPropsWithoutRef, type ReactElement } from "react";
import { FormattedRelativeTime } from "react-intl";

export interface DistanceToNowProps
  extends Omit<
    ComponentPropsWithoutRef<typeof FormattedRelativeTime>,
    "value" | "unit"
  > {
  value: number | string | Date;
}

/**
 * Renders a localized phrase describing the amount of time between now and the given value.
 *
 * Only renders the phrase. Use `RelativeTimestamp` if you'd like the combined tooltip.
 *
 * Supports the [options for Intl.RelativeTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#parameters).
 *
 * ### Import Guide
 *
 * ```jsx
 * import { DistanceToNow } from "ui";
 * ```
 */
export function DistanceToNow({
  value,
  ...passThrough
}: DistanceToNowProps): ReactElement {
  const [timeAgo, unit, updateInterval] = getDistanceToNow(value);

  return (
    <FormattedRelativeTime
      value={timeAgo}
      unit={unit}
      updateIntervalInSeconds={updateInterval}
      {...passThrough}
    />
  );
}

/**
 * Calculates the distance between the given date and now.
 *
 * Returns a tuple of the value and the unit. Uses the same time units as `FormattedRelativeTime` from `react-intl`.
 */
export function getDistanceToNow(
  d: number | string | Date,
): [
  number,
  ComponentPropsWithoutRef<typeof FormattedRelativeTime>["unit"],
  number | undefined,
] {
  const now = Date.now();
  const isFuture = isAfter(d, now);
  const multiplier = isFuture ? 1 : -1;
  const duration = isFuture
    ? intervalToDuration({
        start: now,
        end: d,
      })
    : intervalToDuration({
        start: d,
        end: now,
      });

  if (duration.years) {
    return [duration.years * multiplier, "year", undefined];
  }
  if (duration.months) {
    return [duration.months * multiplier, "month", undefined];
  }
  if (duration.days) {
    return [duration.days * multiplier, "day", undefined];
  }
  if (duration.hours) {
    return [duration.hours * multiplier, "hour", 3600];
  }
  if (duration.minutes) {
    return [duration.minutes * multiplier, "minute", 60];
  }
  if (duration.seconds) {
    return [duration.seconds * multiplier, "second", 1];
  }

  return [0, "second", 1];
}
