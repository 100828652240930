import { type SecurityCapabilitiesType } from "requests";
import { type SvgIconProps } from "../SvgIcon";
import { AgentbasedWorkloadProtectionIcon } from "./AgentbasedWorkloadProtectionIcon";
import { AgentlessApiDiscoveryIcon } from "./AgentlessApiDiscoveryIcon";
import { AgentlessWorkloadScanningIcon } from "./AgentlessWorkloadScanningIcon";
import { CloudVisibilityComplianceAndGovernanceIcon } from "./CloudVisibilityComplianceAndGovernanceIcon";
import { DataSecurityIcon } from "./DataSecurityIcon";
import { IdentitySecurityIcon } from "./IdentitySecurityIcon";
import { RemediationIcon } from "./RemediationIcon";
import { ServerlessFunctionScanningIcon } from "./ServerlessFunctionScanningIcon";
import { ThreatDetectionIcon } from "./ThreatDetectionIcon";

export interface SecurityCapabilitiesIconProps
  extends Omit<SvgIconProps, "src"> {
  type: SecurityCapabilitiesType;
}

export const SecurityCapabilitiesIcon = (
  props: SecurityCapabilitiesIconProps,
) => {
  // eslint-disable-next-line react/prop-types
  const { type, ...passThrough } = props;

  switch (type) {
    case "Cloud Visibility Compliance and Governance":
      return <CloudVisibilityComplianceAndGovernanceIcon {...passThrough} />;
    case "Auto Protect":
      return <AgentbasedWorkloadProtectionIcon {...passThrough} />;
    case "Agentless Scanning":
      return <AgentlessWorkloadScanningIcon {...passThrough} />;
    case "Data Security":
      return <DataSecurityIcon {...passThrough} />;
    case "Identity Security":
      return <IdentitySecurityIcon {...passThrough} />;
    case "Remediation":
      return <RemediationIcon {...passThrough} />;
    case "Serverless Function Scanning":
      return <ServerlessFunctionScanningIcon {...passThrough} />;
    case "Threat Detection":
      return <ThreatDetectionIcon {...passThrough} />;
    case "Agentless API Discovery":
      return <AgentlessApiDiscoveryIcon {...passThrough} />;

    /* istanbul ignore next - The clause catches non-typescript errors */
    default:
      return null;
  }
};
