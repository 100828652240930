import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useRef } from "react";

/**
 * A helping hook for measuring Page Performance
 * @param {string} prismaPageName - page name
 * @param {string} prismaViewName - saved view name
 * @param {bool} createCustomTransaction - need to create custom Sentry transaction for generally slower pages
 * @returns finishTracking
 */
export function usePerformanceTracker(
  pageName: string,
  viewName: string,
  createCustomTransaction = true,
) {
  const startTime = useRef(Date.now());
  const finishTrackingFlag = useRef(false);

  const tracker = useMemo(() => {
    const transaction = createCustomTransaction
      ? Sentry.startTransaction({
          name:
            window.location.pathname.replace(/\/[a-zA-Z0-9-]{36}/g, "/:id") +
            "-Performance",
        })
      : Sentry?.getCurrentHub()?.getScope()?.getTransaction();

    let span;
    if (transaction) {
      span = transaction.startChild({ op: "page-perf-measurement" });
      transaction.setTag("pageName", pageName);
      transaction.setTag("viewName", viewName);
      transaction.setTag(
        "pageStatus",
        document.hidden ? "Browser-Hidden" : "Browser-Visible",
      );
    }
    return { transaction, span };
  }, [pageName, viewName, createCustomTransaction]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!finishTrackingFlag.current && noSpinnnersVisible()) {
        const endtime = Date.now();
        const pageTime = endtime - startTime.current;
        finishTrackingFlag.current = true;
        if (tracker.transaction && tracker.span) {
          tracker.transaction.setTag("pageLoadTime", pageTime);
          tracker.transaction.setTag(
            "pageStatus",
            document.hidden ? "Browser-Hidden" : "Browser-Visible",
          );
          tracker.span.finish();
          if (createCustomTransaction) {
            tracker.transaction.finish();
          }
        }
        finishTrackingFlag.current = true;

        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [pageName, viewName, tracker, createCustomTransaction]);
}

function noSpinnnersVisible() {
  const allSpinnerHtmlCollections =
    document.querySelectorAll('svg[aria-label="Loading"]') ?? [];

  const allSpinnerElements = Array.prototype.slice.call(
    allSpinnerHtmlCollections,
  );

  return allSpinnerElements.every(
    (element) => window.getComputedStyle(element).visibility !== "visible",
  );
}
