import { Link, Tooltip, TruncateText } from "ui";

import { type PermissionGroupData } from "requests";
import { type RowInstance } from "table";

export function PermissionGroupNameCell({
  row,
  setSelectedPermissionGroupId,
}: {
  row: Pick<RowInstance<PermissionGroupData>, "original">;
  setSelectedPermissionGroupId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) {
  return (
    <>
      <TruncateText>
        <Tooltip label={row?.original?.name}>
          <span>
            <Link
              data-testid={"permission-group-link"}
              onClick={() => setSelectedPermissionGroupId(row?.original?.id)}
              addClassName="text-xs"
              role="link"
            >
              {row?.original?.name}
            </Link>
          </span>
        </Tooltip>
      </TruncateText>
    </>
  );
}
