import { z } from "zod";
import { useTokenStore } from "../hooks";
import { GetComputeConfigResponse } from "../requests/compute/config/schemas";
import { convertComputeParamsToString } from "../utils";
import { baseJsonApi } from "./baseJsonApi";
import { jsonApi } from "./jsonApi";

let computeUrl: string;
let prismaId: string;

export async function getComputeUrl(): Promise<string> {
  const id: string = useTokenStore.getState().token.prismaId;

  // Compute api url is different for each prismaId. In case the user switches api url or tenant the relevant url should be retrieved.
  if (!computeUrl || id !== prismaId) {
    // Retrieve compute api url in case of first time use or prismaId change
    const computeConfig = await jsonApi({
      path: "compute/config",
      requestSchema: z.void(),
      responseSchema: GetComputeConfigResponse,
    });
    computeUrl = computeConfig.twistlockUrl;
    prismaId = id;
  }

  return computeUrl;
}

interface ComputeJsonApiProps<Request, Response>
  extends Omit<
    Parameters<typeof jsonApi<Request, Response>>[0],
    "baseUrl" | "useBaseHeaders" | "params"
  > {
  params?: Record<string, unknown>;
}

async function setupComputeApiRequest<Request, Response>({
  params,
  ...args
}: ComputeJsonApiProps<Request, Response>) {
  return {
    baseUrl: await getComputeUrl(),
    useBaseHeaders: true,
    params: convertComputeParamsToString(params),
    ...args,
  };
}

/**
 * `computeJsonApi` uses {@link jsonApi} to query compute api directly.
 * This function handles compute api base URL and Authorization
 */
export const computeJsonApi = async <Request, Response>(
  props: ComputeJsonApiProps<Request, Response>,
): ReturnType<typeof jsonApi<Request, Response>> => {
  const requestSetup = await setupComputeApiRequest(props);
  return jsonApi<Request, Response>(requestSetup);
};

/**
 * `paginatedComputeApi` uses {@link baseJsonApi} to query compute api directly and includes pagination.
 * This function handles compute api base URL and Authorization, and it also retrieves total count from headers.
 */
export const paginatedComputeJsonApi = async <Request, Response>(
  props: ComputeJsonApiProps<Request, Response>,
): Promise<{
  data: Response;
  total: number;
}> => {
  const requestSetup = await setupComputeApiRequest(props);
  const response = await baseJsonApi<Request, Response>(requestSetup);
  const totalCount = parseInt(response.headers["total-count"] ?? "0");

  return {
    data: response.body,
    total: totalCount,
  };
};
