/* c8 ignore start */
/* most of the logic here is difficult to test with jsdom and no CSS loaded */
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ReactElement,
  type ReactNode,
} from "react";
import { FormattedMessage } from "react-intl";
import { Body } from "ui";

export interface TruncateWithExpandCellProps {
  value?: ReactNode;
  isDisabled?: boolean;
}

export function TruncateWithExpandCell({
  value,
  isDisabled,
}: TruncateWithExpandCellProps): ReactElement | null {
  const inner = useRef<HTMLSpanElement>(null);
  const outer = useRef<HTMLSpanElement>(null);
  const [canExpand, setCanExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const collapse = useCallback(() => {
    setIsExpanded(false);
  }, []);
  const expand = useCallback(() => {
    setIsExpanded(true);
  }, []);

  useEffect(() => {
    const innerWidth = inner.current?.offsetWidth;
    const outerWidth = outer.current?.offsetWidth;

    if (!innerWidth || !outerWidth) {
      return;
    }

    // add 1px for a wrapper as width can be less than it should be in some cases
    const canExpand = innerWidth > outerWidth + 1;
    setCanExpand(canExpand);
  }, []);

  if (!value || (Array.isArray(value) && value?.length === 0)) {
    return null;
  }

  if (isExpanded) {
    return (
      <span className={`break-all ${isDisabled && "text-disabled"}`}>
        {value}
        {canExpand && (
          <>
            {" "}
            <Body
              as="button"
              appearance={isDisabled ? "disabled" : "link"}
              onClick={collapse}
              type="button"
              size="sm"
            >
              <FormattedMessage
                defaultMessage="Show Less"
                id="T7a+oV"
                description="Collapse table cell, reducing size and hiding overflow content"
              />
            </Body>
          </>
        )}
      </span>
    );
  }

  return (
    <span
      className={`flex space-x-2 truncate ${isDisabled && "text-disabled"}`}
    >
      <span
        className="relative flex-auto truncate after:absolute after:right-0 after:top-0 after:h-full after:w-3.5 after:content-['']"
        ref={outer}
      >
        <span ref={inner}>{value}</span>
      </span>

      {canExpand && (
        <span className="flex-none">
          <Body
            as="button"
            appearance="link"
            onClick={expand}
            type="button"
            size="sm"
          >
            <FormattedMessage
              defaultMessage="Show More"
              id="ttDNH6"
              description="Expand table cell to show more content"
            />
          </Body>
        </span>
      )}
    </span>
  );
}
/* c8 ignore stop */
