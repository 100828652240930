import { defineMessages } from "react-intl";
import {
  ADD_DETAILS,
  CONFIGURE_ACCOUNT,
  CUSTOM_CONFIGURATION,
  GET_STARTED,
  REVIEW_STATUS,
  SUMMARY,
} from "../constants";

export const buttonNames = defineMessages({
  save: {
    defaultMessage: "Save and Close",
    id: "nsv0PE",
    description: "Submit button on the onboarding wizard",
  },
  previous: {
    defaultMessage: "Previous",
    id: "DfWeUY",
    description: "Option for quiting onboarding wizard",
  },
});

export const wizardTitle = defineMessages({
  add: {
    defaultMessage: "Add Cloud Account",
    id: "0onCKz",
    description: "Title for AWS Cloud Account Onboarding",
  },
  edit: {
    defaultMessage: "Edit Cloud Account",
    id: "0vQP2g",
    description: "Title for Edit AWS Cloud Account Onboarding",
  },
});

export const stepTitle = defineMessages({
  [GET_STARTED]: {
    defaultMessage: "Get Started",
    id: "Izgglq",
    description: "Title for Get Started Step",
  },
  [CONFIGURE_ACCOUNT]: {
    defaultMessage: "Configure Account",
    id: "Jz8wOJ",
    description: "Title for Configure Account Step",
  },
  [REVIEW_STATUS]: {
    defaultMessage: "Review Status",
    id: "oM1IjP",
    description: "Title for Review Status Step",
  },
  [ADD_DETAILS]: {
    defaultMessage: "Add Details",
    id: "6NtfXF",
    description: "Title for Add Details Step",
  },
  [CUSTOM_CONFIGURATION]: {
    defaultMessage: "Custom Configuration",
    id: "CacJTC",
    description: "Title for Custom Configuration Step",
  },
  [SUMMARY]: {
    defaultMessage: "Summary",
    id: "5frZSz",
    description: "Title for Summary Step",
  },
});

export const sectionTitle = defineMessages({
  scope: {
    defaultMessage: "Scope",
    id: "5HHBbH",
    description: "Section header for Scope",
  },
});
