import { isChina } from "environment";
import { usePermissions } from "../usePermissions";
import { useTenantsFeatures } from "../useTenantsFeatures";

export function useComputeEnabled() {
  const permissions = usePermissions();
  const { data } = useTenantsFeatures();
  const enabled = data?.find((feature) => feature.feature_name === "compute")
    ?.enabled;

  return !isChina() && permissions.computeAccessUI.READ && !!enabled;
}
