import { Tooltip, TruncateText } from "ui";

type StatusMessageProps = {
  row: {
    original: {
      message: string;
    };
  };
};

export default function Message({ row }: StatusMessageProps) {
  const { message } = row.original;

  return (
    <Tooltip label={message}>
      <>
        <TruncateText>{message}</TruncateText>
      </>
    </Tooltip>
  );
}
