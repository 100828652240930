import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const GithubRegistryIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "GithubRegistry",
    id: "nRMXcu",
    description: "GithubRegistry alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <path
        d="M12 1C5.373 1 0 6.178 0 12.565c0 5.11 3.438 9.444 8.206 10.973.6.107.82-.25.82-.556 0-.276-.01-1.187-.016-2.153-3.338.7-4.043-1.365-4.043-1.365-.546-1.336-1.332-1.692-1.332-1.692-1.089-.717.082-.703.082-.703 1.205.082 1.84 1.192 1.84 1.192 1.07 1.768 2.807 1.257 3.491.961.108-.747.42-1.257.762-1.546-2.665-.293-5.467-1.284-5.467-5.715 0-1.263.469-2.295 1.236-3.104-.124-.292-.535-1.468.117-3.06 0 0 1.007-.312 3.3 1.185.98-.257 1.99-.388 3.004-.39a11.93 11.93 0 0 1 3.006.39c2.29-1.497 3.297-1.186 3.297-1.186.653 1.593.242 2.77.118 3.06a4.378 4.378 0 0 1 1.235 3.105c0 4.441-2.808 5.42-5.48 5.706.43.359.814 1.062.814 2.141 0 1.548-.014 2.793-.014 3.174 0 .308.216.668.825.555C20.566 22.006 24 17.673 24 12.565 24 6.178 18.627 1 12 1Z"
        fill="#161614"
        className="dark:fill-white"
      />
    </SvgIcon>
  );
};
