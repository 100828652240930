import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { reportsLayoutRoute } from "../reportsLayoutRoute";

export const CloudSecurityReport = lazy(() => import("./CloudSecurityReport"));

export const cloudSecurityReportRoute = new Route({
  getParentRoute: () => reportsLayoutRoute,
  path: "cloud-security-report",
  component: CloudSecurityReport,
});
