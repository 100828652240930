import { z } from "zod";
import { jsonApi } from "../../apis";
import { GetLogoutResponse } from "./schemas";

export const getLogoutUrl = async () => {
  return jsonApi({
    path: "/saml/config/logout",
    requestSchema: z.void(),
    responseSchema: GetLogoutResponse,
  });
};
