import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../hooks";

type ProjectIdProps = {
  isEdit: boolean;
  onBlur?: () => void;
};
export default function ProjectId({ isEdit, onBlur }: ProjectIdProps) {
  const intl = useIntl();
  useTrimField("projectId");

  return (
    <Input
      label={intl.formatMessage({
        defaultMessage: "Project ID",
        id: "UPPZv8",
        description:
          "The name of an input field project id for onboarding an GCP account",
      })}
      name="projectId"
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Project ID",
        id: "WRzvFt",
        description: "Placeholder for Account Project ID",
      })}
      readOnly={isEdit}
    />
  );
}
