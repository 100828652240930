import { type ComponentProps, type ReactElement } from "react";
import { classNames } from "utils";

import { useTableLayoutEnabled, type TableLayoutProps } from "../TableLayout";

export function Header(
  props: ComponentProps<"div"> & Pick<TableLayoutProps, "appearance">,
): ReactElement {
  const tableLayoutEnabled = useTableLayoutEnabled();

  const classes = classNames(
    "sticky top-0",
    tableLayoutEnabled && "dark:border-blue-steel-850",
    props?.appearance !== "card" && "border-b",
  );
  return (
    <div role="rowgroup" style={{ zIndex: 1 }} {...props} className={classes} />
  );
}
