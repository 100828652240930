import type * as Sentry from "@sentry/react";
import { Monitoring } from "../../Monitoring";
import { SentryAdapter } from "./SentryAdapter";

export const SentryIntegrationFactory = {
  create: (sentry: typeof Sentry) => {
    const sentryAdapter = new SentryAdapter(sentry);
    return new Monitoring(sentryAdapter);
  },
};
