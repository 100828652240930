import { FormattedMessage } from "react-intl";

import { Radio, RadioGroup } from "ui";
import { EXCLUDE, INCLUDE } from "../../../../constants";

type ModelRadioGroupProps = {
  mode: string;
  onChangeMode: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ModelRadioGroup({
  mode,
  onChangeMode,
}: ModelRadioGroupProps) {
  return (
    <div className="mb-4 ml-8 space-y-2">
      <RadioGroup
        data-selector="project-mode-radio-group"
        className="flex max-w-md space-x-8 pl-2"
        name="projectMode"
        onChange={onChangeMode}
      >
        <Radio
          value={INCLUDE}
          checked={mode === INCLUDE}
          data-selector="project-mode-include"
        >
          <span className="text-sm">
            <FormattedMessage
              defaultMessage="Include a subset"
              id="mC7ZtW"
              description="Only projects selected will be monitored"
            />
          </span>
        </Radio>

        <Radio
          value={EXCLUDE}
          checked={mode === EXCLUDE}
          data-selector="project-mode-exclude"
        >
          <span className="text-sm">
            <FormattedMessage
              defaultMessage="Exclude a subset"
              id="rFad6e"
              description="All current and future projects will be monitored except the projects selected"
            />
          </span>
        </Radio>
      </RadioGroup>
    </div>
  );
}
