import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { PermissionsStatusResponseSchema } from "./schemas";

import { jsonApi } from "../../../apis";
import { type cloudAccountsKeys } from "../cloudAccountsKeys";

export const getCloudAccountsPermissionStatus = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["permissionsStatus"]>
>) => {
  return jsonApi({
    path: `v1/cloudAccounts/permissionsStatus/${accountId}`,
    requestSchema: z.void(),
    responseSchema: PermissionsStatusResponseSchema,
  });
};
