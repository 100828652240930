import { type PropGetters } from "downshift";
import {
  forwardRef,
  type CSSProperties,
  type ComponentPropsWithRef,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { type SelectItem } from "../Select";

export const listDefaultMinWidth = 176;
export const listDefaultMaxWidth = 272;

export type ListProps = Omit<ComponentPropsWithRef<"ul">, "className"> &
  PropGetters<SelectItem>["getMenuProps"] & {
    /**
     * Generally expects children to be `<ListItem />`s, but this is not enforced.
     */
    children: ReactNode;
    isOpen?: boolean;
    style?: CSSProperties;
  };

const defaultStyle = {
  minWidth: `${listDefaultMinWidth}px`,
  maxHeight: `${listDefaultMaxWidth}px`,
};

/**
 * Displays a list of elements to the user.
 *
 * This component is rarely used directly. Usually, a Select or MultiSelect component
 * will meet your needs. But, when that isn't the case, use the List to display a menu
 * of options to the user.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { List, ListItem } from "ui";
 * ```
 *
 */
export const List = forwardRef<HTMLUListElement, ListProps>(
  ({ children, isOpen = true, style = {}, ...passThrough }: ListProps, ref) => {
    const className = classNames(
      "overflow-auto rounded shadow focus:outline-none motion-safe:transition-opacity motion-safe:duration-200",
      "text-default dark:text-dark-bg",
      isOpen && "border border-primary",
      !isOpen && "hidden opacity-0",
    );

    return (
      <ul
        {...passThrough}
        className={className}
        style={{ ...defaultStyle, ...style }}
        ref={ref}
      >
        {children}
      </ul>
    );
  },
);
List.displayName = "List";
