import { z } from "zod";

const CloudTrailBucketSchema = z.object({
  name: z.string(),
  bucket: z.string(),
  snsTopic: z.string(),
  commonLogging: z.boolean(),
  region: z.string(),
});

export const CloudTrailResponseSchema = z.object({
  hasValidPcdsCloudtrail: z.boolean(),
  snsSubscriptionEndpoint: z.string().optional(),
  cloudShellCommand: z.string().optional(),
  existingCloudtrails: z.array(CloudTrailBucketSchema).optional(),
  existingSnsTopic: z.array(z.string()).optional(),
  existingBuckets: z.array(z.string()).optional(),
  storageUUID: z.string().optional(),
  failureReason: z.string().optional(),
  pcdsCloudtrail: CloudTrailBucketSchema.optional(),
  isCommonLogging: z.boolean().optional(),
});

export const CloudTrailRequestSchema = z.object({
  accountId: z.string(),
  externalId: z.string(),
  roleArn: z.string(),
});

export const ScriptDataRequestSchema = z.object({
  accountId: z.string(),
  cloudtrailBucket: z.object({
    name: z.string(),
    requestType: z.string(),
  }),
  cloudtrailRequest: z.object({
    name: z.string(),
    requestType: z.string(),
  }),
  snsEndpoint: z.string(),
  snsTopic: z.object({
    name: z.string(),
    requestType: z.string(),
  }),
  isCommonLogging: z.boolean(),
  cloudtrailRegion: z.string().optional(),
});

export const ScriptDataResponseSchema = z.object({
  downloadScript: z.string(),
});

export const ValidateFwScanRequestSchema = z.object({
  accountId: z.string(),
  externalId: z.string(),
  cloudtrailName: z.string(),
  cloudtrailRegion: z.string().optional(),
  roleArn: z.string(),
  snsSubscriptionEndpoint: z.string(),
});

export const ValidateFwScanResponseSchema = z.object({
  hasValidPcdsCloudtrail: z.boolean(),
  pcdsCloudtrail: z
    .object({
      name: z.string(),
      bucket: z.string(),
      snsTopic: z.string(),
      commonLogging: z.boolean(),
      region: z.string(),
    })
    .optional(),
  failureReason: z.string().optional(),
});

export const BucketCheckRequestSchema = z.object({
  accountId: z.string(),
  externalId: z.string(),
  roleArn: z.string(),
  bucket: z.string(),
});

export const BucketCheckResponseSchema = z.object({
  isBucketNameValid: z.boolean(),
  isBucketExist: z.boolean(),
});

export const CreateDataSecurityRequestSchema = z.object({
  storageUUID: z.string(),
  accountId: z.string(),
  cloudType: z.string(),
  accountType: z.string(),
  dssTenantId: z.string(),
  storageScanStatus: z.string(),
  scanOption: z.string(),
  cloudConfig: z.object({
    externalId: z.string(),
    roleArn: z.string(),
  }),
});

export const UpdateDataSecurityRequestSchema = z.object({
  cloudType: z.string(),
  accountType: z.string(),
  dssTenantId: z.string(),
  storageScanStatus: z.string(),
  scanOption: z.string(),
  cloudConfig: z.object({
    externalId: z.string(),
    roleArn: z.string(),
  }),
});

export type CloudTrailType = z.infer<typeof CloudTrailResponseSchema>;
export type ScriptDataRequestType = z.infer<typeof ScriptDataRequestSchema>;
export type ValidateFwScanRequestType = z.infer<
  typeof ValidateFwScanRequestSchema
>;
export type BucketCheckRequestType = z.infer<typeof BucketCheckRequestSchema>;
export type BucketCheckResponseType = z.infer<typeof BucketCheckResponseSchema>;
export type CloudTrailBucketType = z.infer<typeof CloudTrailBucketSchema>;
export type CreateDataSecurityRequestType = z.infer<
  typeof CreateDataSecurityRequestSchema
>;
export type UpdateDataSecurityRequestType = z.infer<
  typeof UpdateDataSecurityRequestSchema
>;
