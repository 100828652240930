import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const CloneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 16 16"}>
      <path d="M4.62 0H15.38C15.72 0 16 0.28003 16 0.62V11.38C16 11.72 15.72 12 15.38 12H13.5V3.5C13.5 2.94995 13.05 2.5 12.5 2.5H4V0.62C4 0.28003 4.28003 0 4.62 0Z" />
      <path d="M0.625 4H11.375C11.7201 4 12 4.27979 12 4.625V15.375C12 15.7202 11.7201 16 11.375 16H0.625C0.27985 16 0 15.7202 0 15.375V4.625C0 4.27979 0.27985 4 0.625 4ZM9.20654 10.75C9.48266 10.75 9.70654 10.5261 9.70654 10.25V9.75C9.70654 9.47388 9.48266 9.25 9.20654 9.25H6.75V6.79297C6.75 6.51685 6.52612 6.29297 6.25 6.29297H5.75C5.47388 6.29297 5.25 6.51685 5.25 6.79297V9.25H2.79346C2.51734 9.25 2.29346 9.47388 2.29346 9.75V10.25C2.29346 10.5261 2.51734 10.75 2.79346 10.75H5.25V13.207C5.25 13.4832 5.47388 13.707 5.75 13.707H6.25C6.52612 13.707 6.75 13.4832 6.75 13.207V10.75H9.20654Z" />
    </SvgIcon>
  );
};
