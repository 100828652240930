import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { PreferencesSchema } from "./schemas";

const PreferencesKeysSchema = PreferencesSchema.keyof();

export type Preferences = z.infer<typeof PreferencesSchema>;
export type PageName = z.infer<typeof PreferencesKeysSchema>;

export const preferencesKeys = {
  all: [{ scope: "preferences" }] as const,
} as const;

export const getPreferences = async (
  _props: QueryFunctionContext<(typeof preferencesKeys)["all"]>,
) => {
  return jsonApi({
    path: "user/me/preference",
    requestSchema: z.void(),
    responseSchema: PreferencesSchema,
  });
};

export const updatePreference = async <
  K extends PageName,
  S extends keyof Required<Preferences>[K],
  P extends Required<Preferences>[K],
>(
  key: K,
  subkey: S,
  preferences: P,
) => {
  return jsonApi({
    path: `user/me/preference/${key}/${String(subkey)}`,
    body: preferences,
    config: {
      method: "PUT",
    },
    requestSchema:
      // @ts-expect-error typing of feature is a bit funky since it's dynamic based on type P
      // TS says we can't use type F as an index, but once P is passed in, it knows its type is
      // a array of strings that are the second key in the object.
      PreferencesSchema.required().shape[key].shape[subkey],
    responseSchema: z.any(),
  });
};

export const deletePreference = async <
  K extends PageName,
  S extends keyof Required<Preferences>[K],
>(
  key: K,
  subkey: S,
) => {
  return jsonApi({
    path: `user/me/preference/${key}/${String(subkey)}`,
    config: {
      method: "DELETE",
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};
