import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import {
  CREATE_IAM_ROLE_CFT,
  DOWNLOAD_IAM_ROLE_CFT,
} from "../../../../constants";
import { AWSIcon } from "../../icons/AWSIcon";
import CFTButton from "./CFTButton";

type ConfigHelpersProps = {
  createIAMRoleCft: UseMutateAsyncFunction<unknown, unknown, void, unknown>;
  downloadIAMRoleCft: UseMutateAsyncFunction<unknown, unknown, void, unknown>;
  iamRoleCftMode: string;
  isIAMRoleCftDownloaded: boolean;
  isDownloadingCft: boolean;
  isCreatingCft: boolean;
  onboardType: string;
  disabled: boolean;
  closeModal: () => void;
};

export default function ConfigHelpers({
  createIAMRoleCft,
  downloadIAMRoleCft,
  iamRoleCftMode,
  isIAMRoleCftDownloaded,
  isDownloadingCft,
  isCreatingCft,
  onboardType,
  disabled,
  closeModal,
}: ConfigHelpersProps) {
  return (
    <>
      {onboardType === "template" && (
        <CFTButton
          dataSelector="config-helper-download-dynamic-cft-link"
          external={false}
          isLoading={isDownloadingCft}
          isFileDownloaded={
            isIAMRoleCftDownloaded && iamRoleCftMode === DOWNLOAD_IAM_ROLE_CFT
          }
          onButtonClick={downloadIAMRoleCft}
          title={
            <FormattedMessage
              defaultMessage="Download CFT Template"
              id="RJXp8s"
              description="AWS cft download link"
            />
          }
          disabled={disabled}
          closeModal={closeModal}
        />
      )}

      <CFTButton
        dataSelector="config-helper-create-stack-link"
        external={true}
        isLoading={isCreatingCft}
        isFileDownloaded={
          isIAMRoleCftDownloaded && iamRoleCftMode === CREATE_IAM_ROLE_CFT
        }
        onButtonClick={createIAMRoleCft}
        title={
          <FormattedMessage
            defaultMessage="Connect to AWS"
            id="RCIgGN"
            description="Button to launch AWS console"
          />
        }
        btnIcon={<AWSIcon size="4xl" className="-mr-4 mt-3" />}
        disabled={disabled}
        closeModal={closeModal}
      />
    </>
  );
}
