import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import { type Column, type Hooks } from "react-table";
import { Checkbox, RadioInput } from "ui";

import {
  type CellInstance,
  type ColumnOptions,
  type Data,
  type TableInstance,
  type TableOptions,
} from "../../types";
import { useTableInstance } from "./TableContext";

function Cell<D extends Data>({ row }: CellInstance<D>): ReactElement {
  const { rowSelectMode, onRowSelect } = useTableInstance();
  const intl = useIntl();
  const label = row.isSelected
    ? intl.formatMessage(
        {
          defaultMessage: "Deselect row {number}",
          id: "agKOkN",

          description: "Label for deselecting a selected table row",
        },
        {
          number: row.index,
        },
      )
    : intl.formatMessage(
        {
          defaultMessage: "Select row {number}",
          id: "93pvB7",

          description: "Label for selecting a table row",
        },
        {
          number: row.index,
        },
      );
  const { indeterminate, ...props } = row.getToggleRowSelectedProps({
    title: undefined,
  });

  if (rowSelectMode === "single") {
    return (
      <RadioInput
        aria-label={label}
        checked={row.isSelected}
        {...props}
        onChange={(event) =>
          onRowSelect
            ? onRowSelect(
                row as CellInstance<Data>["row"],
                event.target.checked,
              )
            : props.onChange?.(event)
        }
      />
    );
  }

  return (
    <Checkbox
      {...props}
      onChange={(event) => {
        return onRowSelect
          ? onRowSelect(row as CellInstance<Data>["row"], event.target.checked)
          : props.onChange?.(event);
      }}
      indeterminate={indeterminate}
      aria-label={label}
    />
  );
}

function Header<D extends Data>({
  isAllRowsSelected,
  getToggleAllRowsSelectedProps,
}: TableInstance<D>): ReactElement | null {
  const { rowSelectMode, onAllRowSelect, rows } = useTableInstance();
  const intl = useIntl();
  if (rowSelectMode === "single") return null;

  const props = getToggleAllRowsSelectedProps({ title: undefined });

  return (
    <div style={{ lineHeight: 0 }}>
      <Checkbox
        {...props}
        onChange={(event) => {
          onAllRowSelect
            ? onAllRowSelect(event.target.checked, rows)
            : props.onChange?.(event);
        }}
        aria-label={
          isAllRowsSelected
            ? intl.formatMessage({
                defaultMessage: "Deselect all rows",
                id: "GDJaqT",

                description: "Label for checkbox to deselect all rows",
              })
            : intl.formatMessage({
                defaultMessage: "Select all rows",
                id: "lJOsMK",

                description: "Label for checkbox that will select all rows",
              })
        }
      />
    </div>
  );
}

export const SelectRowColumn: ColumnOptions<Data> = {
  id: "select-row",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Cell,
  disableResizing: true,
  disableExport: true,
  disableGlobalFilter: true,
  disableHideColumn: true,
  disableSortBy: true,
  disableGroupBy: true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Header,
  isSelectRowColumn: true,
  sticky: true,
  stickyPosition: "left",
  width: 48,
};

export function useInsertSelectRowColumn<D extends Data>(
  hooks: Hooks<D>,
): void {
  hooks.visibleColumns.push((columns, meta: { instance: TableOptions<D> }) => {
    if (!meta.instance.disableRowSelect) {
      return [SelectRowColumn as Column<D>, ...columns];
    }
    return columns;
  });
}
