import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

import { useIntl } from "react-intl";

export const CodeIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Code",
    id: "BarZyi",

    description: "Code Icon alt text",
  });

  return (
    <SvgIcon {...props} viewBox={"0 0 16 14"}>
      {showNativeTooltip && <title>{alt}</title>}
      <path d="M5.13088 12.707L8.96342 0.685602C9.0473 0.422507 9.32858 0.277222 9.59167 0.361099L10.5442 0.664774C10.8073 0.748652 10.9526 1.02993 10.8687 1.29302L7.03615 13.3144C6.95228 13.5775 6.671 13.7228 6.4079 13.6389L5.45538 13.3352C5.19228 13.2513 5.047 12.9701 5.13088 12.707Z" />
      <path d="M3.40327 10.8042L0.332454 7.3312C0.165224 7.14207 0.165224 6.85793 0.332454 6.6688L3.40327 3.19581C3.58617 2.98896 3.90212 2.96952 4.109 3.15239L4.85788 3.81437C5.06477 3.99725 5.08424 4.31322 4.90137 4.52012L2.70953 7L4.90137 9.47988C5.08424 9.68678 5.06477 10.0027 4.85788 10.1856L4.109 10.8476C3.90212 11.0305 3.58617 11.011 3.40327 10.8042Z" />
      <path d="M11.8911 10.8476L11.1422 10.1856C10.9353 10.0028 10.9159 9.68678 11.0987 9.47988L13.2906 7L11.0987 4.52012C10.9159 4.31322 10.9353 3.99726 11.1422 3.81437L11.8911 3.15239C12.098 2.96952 12.4139 2.98896 12.5968 3.19581L15.6677 6.6688C15.8349 6.85793 15.8349 7.14207 15.6677 7.3312L12.5968 10.8042C12.4139 11.011 12.098 11.0305 11.8911 10.8476Z" />
    </SvgIcon>
  );
};
