import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../SvgIcon";

export const InformationalSeverityIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Informational",
    id: "XbB/QW",

    description: "severity icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} {...props} viewBox="0 0 20 20" fill="none">
      {showNativeTooltip && <title>{alt}</title>}
      <path
        d="M10 2C5.30527 2 2 5.25312 2 9.7375V10.2625C2 14.7481 5.30527 18 10 18C14.6947 18 18 14.7469 18 10.2625V9.7375C18 5.25182 14.6948 2 10 2ZM8.93333 5.6983C8.93333 5.10038 9.37403 4.66667 10 4.66667C10.626 4.66667 11.0667 5.10026 11.0667 5.6983V5.76822C11.0667 6.36614 10.626 6.8 10 6.8C9.37403 6.8 8.93333 6.36641 8.93333 5.76822V5.6983ZM12.1357 13.4715C12.1331 13.7641 11.8951 14 11.6024 14H8.39759C8.103 14 7.86426 13.7612 7.86426 13.4667V12.9333C7.86426 12.6388 8.103 12.4 8.39759 12.4H9.19759V9.73332H8.39759C8.103 9.73332 7.86426 9.49452 7.86426 9.19999V8.66666C7.86426 8.37213 8.103 8.13332 8.39759 8.13332H10.2643C10.5588 8.13332 10.7976 8.37213 10.7976 8.66666V12.3755L11.6171 12.3904C11.9096 12.3957 12.1433 12.6358 12.1407 12.9285L12.1357 13.4715Z"
        className="fill-blue-600 dark:fill-blue-400"
      />
    </SvgIcon>
  );
};
