import {
  AssetSuggestionRequestSchema,
  RqlCatalogSuggestionsResponseSchema,
  RqlSuggestionRequestSchema,
  RqlSuggestionResponseSchema,
  type NullableAssetSuggestionParams,
  type RqlSuggestionResponse,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z, type ZodType, type ZodTypeDef } from "zod";
import { jsonApi } from "../../../apis";
import { RqlSearchType } from "../searchHistory";

export type RqlSuggestionQueryKeyParams = {
  constraints?: NullableAssetSuggestionParams;
  cursor?: number;
  query?: string;
  searchType?: RqlSearchType | null;
  baseUrl?: string;
  namespace?: string;
  advancedMode?: boolean;
};

export const rqlSuggestionKeys = {
  all: [{ scope: "rqlSuggestionsAll" }] as const,
  catalog: [{ scope: "rqlSuggestionsCatalog" }] as const,
  suggestions: ({
    constraints,
    cursor,
    query = "",
    searchType,
    baseUrl,
    namespace,
    advancedMode,
  }: RqlSuggestionQueryKeyParams) => {
    return [
      {
        ...rqlSuggestionKeys.all[0],
        constraints,
        cursor: cursor ? cursor : query.length,
        query,
        searchType,
        baseUrl,
        namespace,
        advancedMode,
      },
    ] as const;
  },
  infiniteSuggestions: ({
    searchValue,
    ...suggestionQueryKeyParams
  }: RqlSuggestionQueryKeyParams & { searchValue: string }) => {
    return [
      {
        ...rqlSuggestionKeys.suggestions(suggestionQueryKeyParams)[0],
        searchValue,
      },
    ] as const;
  },
};

export type RqlSuggestionsQueryKey = ReturnType<
  (typeof rqlSuggestionKeys)["suggestions"]
>;

export const getSuggestions = async ({
  queryKey: [{ constraints, cursor, query, searchType, baseUrl, namespace }],
}: {
  queryKey: RqlSuggestionsQueryKey;
}): Promise<RqlSuggestionResponse> => {
  const requestSchema = getRequestSchema(searchType);
  let body: z.infer<typeof requestSchema>;
  switch (searchType) {
    case RqlSearchType.asset:
      body = constraints;
      break;
    default:
      body = {
        cursor: cursor || query?.length || 0,
        query,
      };
  }

  const response = await jsonApi({
    body,
    baseUrl,
    path: getUrl(searchType),
    config: {
      method: "post",
      credentials: baseUrl ? "include" : undefined,
      headers: namespace ? { "x-namespace": namespace } : undefined,
    },
    requestSchema,
    responseSchema: RqlSuggestionResponseSchema,
  });

  if (!searchType || searchType === RqlSearchType.config) {
    // @ts-expect-error suggestions is defined as an array of only strings or objects; all returns will be of like kind
    response.suggestions = response.suggestions.map((curSuggestion) => {
      if (typeof curSuggestion === "string") {
        return curSuggestion;
      } else if (typeof curSuggestion === "boolean") {
        return curSuggestion.toString();
      }

      return {
        displayName: curSuggestion.displayName,
        name: curSuggestion.name,
      };
    });
  }

  return response;
};

export const getCatalogSuggestions = async (
  _props: QueryFunctionContext<(typeof rqlSuggestionKeys)["catalog"]>,
) => {
  const catalogueSuggestions = await jsonApi({
    path: "search/api/v1/asset/autosuggest-catalog",
    requestSchema: z.void(),
    responseSchema: RqlCatalogSuggestionsResponseSchema,
  });

  return catalogueSuggestions;
};

type ApiSearchTypeDefRecordss = Record<
  RqlSearchType,
  {
    requestSchema?: ZodType<unknown, ZodTypeDef, unknown>;
    url?: string;
  }
>;

const defaultUrl = "search/suggest";
const defaultRequestSchema = RqlSuggestionRequestSchema;

const apiSearchTypeDefs: Partial<ApiSearchTypeDefRecordss> = {
  [RqlSearchType.asset]: {
    requestSchema: AssetSuggestionRequestSchema,
    url: "search/api/v1/asset/autosuggest",
  },
  [RqlSearchType.iam]: {
    url: "api/v1/suggest",
  },
  [RqlSearchType.network_config]: {
    url: "cnssuggestions",
  },
  [RqlSearchType.vulnerability]: {
    url: "uve/api/v1/vulnerabilities/suggest",
  },
  [RqlSearchType.application]: {
    url: "appid/search/api/v1/suggest",
  },
};

const getRequestSchema = (searchType?: RqlSearchType | null) =>
  (searchType && apiSearchTypeDefs?.[searchType]?.requestSchema) ||
  defaultRequestSchema;

const getUrl = (searchType?: RqlSearchType | null) =>
  (searchType && apiSearchTypeDefs?.[searchType]?.url) || defaultUrl;
