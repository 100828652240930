import {
  NextButton,
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import { ORGANIZATION } from "../../../../constants";
import {
  type GcpConfigureAccountStepValuesType,
  type GcpGetStartedStepValuesType,
} from "../../../../types";
import { ProductDocumentationLink } from "../../components";

import { FormLayout } from "form";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useGCPSelectMonitoredProjectsContext } from "../context/GCPSelectMonitoredProjectContext";
import { handleSubmitGCPOrgUnitSelection } from "../context/GCPSelectMonitoredProjectContext/state/actions";
import { getGCPOUSelectProjectsIsLoading } from "../context/GCPSelectMonitoredProjectContext/state/getters";
import { useGCPCredentialsFileContext } from "../context/GcpCredentialsFileContext";
import { handleSubmitGCPJsonData } from "../context/GcpCredentialsFileContext/state/actions";
import { jsonUploadErrorMessages } from "../context/GcpCredentialsFileContext/state/setters";
import AccountDetails from "./AccountDetails";

type ConfigureAccountProps = {
  context: {
    closeModal: () => void;
    isEdit: boolean;
    isGcpWifEnabled: boolean;
    isFederatedGcpWifEnabled: boolean;
  };
};

export default function ConfigureAccount({
  context: { closeModal, isEdit, isGcpWifEnabled, isFederatedGcpWifEnabled },
}: ConfigureAccountProps) {
  const intl = useIntl();
  const {
    state: { steps },
  } = useWizardContext();

  const { authenticationType: selectedAuthenticationType = "" } = steps[1]
    .values as GcpConfigureAccountStepValuesType;

  const [authenticationType, setAuthenticationType] = useState<string>(
    () => selectedAuthenticationType,
  );

  const { state: fileUploadData, dispatch: fileUploadDispatch } =
    useGCPCredentialsFileContext();

  const { accountType } = steps[0]?.values as GcpGetStartedStepValuesType;
  const isOrg = accountType === ORGANIZATION;

  const {
    state: selectMonitoredProjectsState,
    dispatch: selectMonitoredProjectsDispatch,
  } = useGCPSelectMonitoredProjectsContext();

  const isLoading = getGCPOUSelectProjectsIsLoading(
    selectMonitoredProjectsState,
  );

  const onNextBtnClick = () => {
    const isValidFileUploaded = handleSubmitGCPJsonData(
      fileUploadData,
      fileUploadDispatch,
      jsonUploadErrorMessages.invalid_json_file,
      authenticationType,
      isFederatedGcpWifEnabled,
    );

    const isValidMonitoredProjectsLoaded = isOrg
      ? handleSubmitGCPOrgUnitSelection(
          intl,
          selectMonitoredProjectsState,
          selectMonitoredProjectsDispatch,
        )
      : true;

    return isValidFileUploaded && isValidMonitoredProjectsLoaded;
  };

  const disabled = isLoading;

  return (
    <WizardForm
      addClassName="[&>div:nth-child(2)]:relative"
      actions={
        <>
          <PrevButton />
          <NextButton
            disabled={disabled}
            validateStep={onNextBtnClick}
            validateForm={true}
          />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <AccountDetails
          closeModal={closeModal}
          isEdit={isEdit}
          isGcpWifEnabled={isGcpWifEnabled}
          setAuthenticationType={setAuthenticationType}
          isFederatedGcpWifEnabled={isFederatedGcpWifEnabled}
        />
      </FormLayout>
    </WizardForm>
  );
}
