import { useQuery } from "@tanstack/react-query";
import { getDlpStatus, tenantKeys } from "requests";

export const useDlpStatus = () => {
  const dlpStatus = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return dlpStatus;
};
