import { createContext, useReducer, type ReactElement } from "react";
import { noop } from "utils";

import { initialState as defaultInitialState, reducer } from "../../state";
import { type GlobalFiltersInstance, type GlobalState } from "../../types";

export const FilterContext = createContext<GlobalFiltersInstance>({
  state: defaultInitialState,
  dispatch: noop,
});

export type GlobalFilterProviderProps = {
  /**
   * The children to render inside the global provider
   */
  children: React.ReactNode;
  /**
   * State to start with.
   */
  initialState?: GlobalState;
};

/**
 * Manages the state for all filters. Place it at the root of your React tree.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { GlobalFilterProvider } from "filters";
 * ```
 */
export const GlobalFilterProvider = (
  props: GlobalFilterProviderProps,
): ReactElement => {
  const { children, initialState } = props;
  const [state, dispatch] = useReducer(
    reducer,
    initialState || defaultInitialState,
  );

  return (
    <FilterContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
