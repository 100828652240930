import { type Dispatch, type SetStateAction } from "react";
import { type CloudTrailBucketType } from "requests";
import { type SelectItem } from "ui";
import CloudTrailInput from "./subcomponents/CloudTrailInput";
import CloudTrailSelect from "./subcomponents/CloudTrailSelect";
import CloudTrailType from "./subcomponents/CloudTrailType";

type CloudTrailRowPropTypes = {
  cloudTrailName: string | undefined;
  cloudTrailType: SelectItem;
  cloudTrailItems: SelectItem[] | undefined;
  cloudTrailObjects: CloudTrailBucketType[];
  setCloudTrailBucket: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailName: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailType: Dispatch<SetStateAction<SelectItem>>;
  setTopicType: Dispatch<SetStateAction<SelectItem>>;
  setTopicForSelectedCloudTrail: Dispatch<SetStateAction<string | undefined>>;
  showClTrailSelect: boolean;
};

export default function CloudTrailRow({
  cloudTrailName = "",
  cloudTrailType,
  cloudTrailItems = [],
  cloudTrailObjects = [],
  setCloudTrailBucket,
  setCloudTrailName,
  setCloudTrailTopic,
  setCloudTrailType,
  setTopicType,
  setTopicForSelectedCloudTrail,
  showClTrailSelect,
}: CloudTrailRowPropTypes) {
  return (
    <div className="mb-3 mt-1 flex w-full">
      <div className="w-1/4">
        <CloudTrailType
          setCloudTrailBucket={setCloudTrailBucket}
          setCloudTrailName={setCloudTrailName}
          setCloudTrailTopic={setCloudTrailTopic}
          selectedItem={cloudTrailType}
          setSelectedItem={setCloudTrailType}
          setTopicType={setTopicType}
          setTopicForSelectedCloudTrail={setTopicForSelectedCloudTrail}
        />
      </div>
      <div className="mx-2 w-3/4">
        {showClTrailSelect && (
          <CloudTrailSelect
            cloudTrailName={cloudTrailName}
            cloudTrailObjects={cloudTrailObjects}
            items={cloudTrailItems}
            setCloudTrailBucket={setCloudTrailBucket}
            setCloudTrailName={setCloudTrailName}
            setCloudTrailTopic={setCloudTrailTopic}
            setTopicForSelectedCloudTrail={setTopicForSelectedCloudTrail}
          />
        )}
        {!showClTrailSelect && (
          <CloudTrailInput
            cloudTrailName={cloudTrailName}
            disabled={showClTrailSelect}
            setCloudTrailName={setCloudTrailName}
          />
        )}
      </div>
    </div>
  );
}
