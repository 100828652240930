import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { isEmpty } from "remeda";
import {
  featureStatuskeys,
  getMetaInfo,
  useLicenseInfo,
  useToken,
} from "requests";

import { isDarkMode } from "ui";
import { useUCDMode } from "../useUCDMode";
import { usePendoSnippet } from "./usePendoSnippet";
import { isPendoEnabled } from "./utils";

export function usePendo() {
  const [userData, setUserData] = useState<{
    account: unknown;
    visitor: unknown;
  }>();
  const { pendoSnippetLoaded, pendoInstance } = usePendoSnippet();
  const token = useToken();
  const { data: licenseData, isLoading: isLicenseDataLoading } =
    useLicenseInfo();
  const { data: metaInfoData, isLoading: isMetaInfoDataLoading } = useQuery({
    queryKey: featureStatuskeys.metaInfo(),
    queryFn: getMetaInfo,
    staleTime: Infinity,
  });
  const selectedUCDMode = useUCDMode();

  useEffect(() => {
    if (
      !pendoSnippetLoaded ||
      !pendoInstance ||
      isEmpty(token) ||
      isLicenseDataLoading ||
      isMetaInfoDataLoading
    ) {
      return;
    }

    const visitor = {
      // Add "darwin" metadata to differentiate darwin users from the old app since we're using the same pendo application API key.
      application: "darwin",
      id: token.username,
      role: token.userRoleName,
      roleTypeName: token.userRoleTypeName,
      // Email and company are for intercom integration through pendo
      email: token.username,
      company: {
        id: token.selectedCustomerName,
        name: token.selectedCustomerName,
      },
      persona: selectedUCDMode,
      theme: isDarkMode() ? "dark" : "light",
    };
    const account = {
      // Add "darwin" metadata to differentiate darwin users from the old app since we're using the same pendo application API key.
      application: "darwin",
      id: token.selectedCustomerName,
      licenseType: metaInfoData?.licenseType,
      marketplace: metaInfoData?.marketplace,
      name: token.selectedCustomerName,
      stack: window.location.hostname.replace(".prismacloud.io", ""),
      startDate: licenseData?.startTs,
      endDate: licenseData?.endTs,
      billingModel: licenseData?.billingModel,
      serialNumber: licenseData?.serialNumber,
      supportType: licenseData?.supportType,
      workloadsPurchased: licenseData?.workloads,
      tenantId: licenseData?.tenantId,
    };

    // saving user data for compute
    setUserData({ visitor, account });

    if (isPendoEnabled() && !pendoInstance?.isReady?.()) {
      pendoInstance.initialize({
        visitor,
        account,
      });
    }
  }, [
    pendoSnippetLoaded,
    isLicenseDataLoading,
    isMetaInfoDataLoading,
    licenseData,
    metaInfoData,
    token,
    pendoInstance,
    selectedUCDMode,
  ]);

  return {
    ...(pendoInstance?.isReady?.() && { pendoInstance }),
    userData,
  };
}
