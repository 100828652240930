import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { cloudAccountsKeys, getSupportedFeatures } from "requests";
import {
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  DATA_SECURITY,
  REMEDIATION,
  SERVERLESS_SCAN,
} from "../constants";
import { type AccountType, type CloudType } from "../types";

type UseGetFeaturesListProps = {
  cloudType: CloudType;
  options?: Record<string, string | boolean>;
  payload: {
    accountType: AccountType;
    awsPartition?: string;
    deploymentType?: string;
  };
};

const sortOrderOfFeatures = [
  AGENTLESS_SCAN,
  SERVERLESS_SCAN,
  AUTO_PROTECT,
  DATA_SECURITY,
  REMEDIATION,
];

export function useGetFeaturesList({
  cloudType,
  options = {},
  payload,
}: UseGetFeaturesListProps) {
  const {
    data: { supportedFeatures: supportedFeaturesList = [] } = {},
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: cloudAccountsKeys.supportedFeatures({ cloudType, payload }),
    queryFn: getSupportedFeatures,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 30000,
    ...options,
  });

  const supportedFeatures = useMemo(
    () =>
      supportedFeaturesList
        .filter(
          (feature) => feature !== "Cloud Visibility Compliance and Governance",
        )
        .sort(
          (a, b) =>
            sortOrderOfFeatures.indexOf(a) - sortOrderOfFeatures.indexOf(b),
        ),
    [supportedFeaturesList],
  );

  return {
    supportedFeaturesList: supportedFeatures,
    isLoading: isLoading && isFetching,
  };
}
