import { useSetUCDMode, useUCDModeStore } from "prisma";
import { useToken, useTokenActions } from "requests";
import {
  logoutRedirectUrlStorageKey,
  signinRedirectLocationStorageKey,
} from "stores";

import { useQueryClient } from "@tanstack/react-query";
import { isProduction } from "environment";
import jsCookie from "js-cookie";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useNavigate, useSearch } from "router";

export default function Logout() {
  const navigate = useNavigate();
  const token = useToken();
  const { removeToken } = useTokenActions();
  const queryClient = useQueryClient();
  const setUCDMode = useSetUCDMode();
  const search = useSearch({ from: "/logout" });
  const { logoutRedirectTempFlag } = useFlags();

  useEffect(() => {
    jsCookie.remove("x-ui-version", {
      domain: ".prismacloud.io",
    });
    removeToken();
    queryClient.clear();
    useUCDModeStore.persist.clearStorage();
    setUCDMode(undefined);

    sessionStorage.removeItem("legendSelection");
    sessionStorage.removeItem("modifiedLicensingFilter");

    const logoutUrl = localStorage.getItem(logoutRedirectUrlStorageKey);
    const redirectLocationInStorage = localStorage.getItem(
      signinRedirectLocationStorageKey,
    );
    const redirect = redirectLocationInStorage
      ? JSON.parse(redirectLocationInStorage)
      : {};

    if (logoutUrl) {
      if (
        logoutUrl.startsWith("/login") ||
        logoutUrl.startsWith("/auth/signin") ||
        logoutUrl.startsWith("/legacy/signin")
      ) {
        localStorage.setItem(logoutRedirectUrlStorageKey, "/login");
        navigate({ to: "/login", search: search, replace: true });
      } else if (logoutUrl.includes("mssp")) {
        // specific for MSSP project
        localStorage.setItem(logoutRedirectUrlStorageKey, "/login");
        window.location.assign(logoutUrl);
      } else {
        window.location.assign(logoutUrl);
      }
    } else {
      if (
        isProduction() &&
        (redirect?.pathname === "/auth/signin" ||
          redirect?.pathname === "/legacy/signin")
      ) {
        localStorage.removeItem(signinRedirectLocationStorageKey);
      }
      navigate({ to: "/login", search: search, replace: true });
    }
  }, [
    queryClient,
    navigate,
    removeToken,
    token.iss,
    setUCDMode,
    search,
    logoutRedirectTempFlag,
  ]);

  return <div />;
}
