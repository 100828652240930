import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const AccessControlLayout = lazy(() => import("./AccessControlLayout"));

export const accessControlRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "access-control",
  component: AccessControlLayout,
});
