import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

export const AssetInventoryCloudAccount = lazy(
  () => import("./AssetInventoryCloudAccount"),
);

export const assetInventoryCloudAccountRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "account/$accountId/",
  component: AssetInventoryCloudAccount,
});
