import { useQuery } from "@tanstack/react-query";
import { cloudAccountsKeys, getCloudAccountCas } from "requests";
import { type CloudType } from "../types";

type UseGetCloudAccountsCasProps = {
  accountId: string;
  cloudType: CloudType;
  includeGroupInfo?: boolean;
  options?: Record<string, string | boolean>;
};

export const useGetCloudAccountsCas = ({
  accountId,
  cloudType,
  includeGroupInfo = false,
  options = {},
}: UseGetCloudAccountsCasProps) => {
  const { data, isError, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsCas({
      accountId,
      cloudType,
      includeGroupInfo,
    }),
    queryFn: getCloudAccountCas,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isLoading: isLoading && isFetching,
    refetch,
  };
};
