import { type ReactElement } from "react";
import { classNames } from "utils";

import { type ColumnInstance, type Data } from "../../types";
import { useTableLayoutEnabled, type TableLayoutProps } from "../TableLayout";

export interface FooterCellProps<D extends Data> {
  column: ColumnInstance<D>;
}

export function FooterCell<D extends Data>({
  appearance = "primary",
  column,
}: FooterCellProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  const tableLayoutEnabled = useTableLayoutEnabled();
  const classes = classNames(
    "flex h-full w-full items-center px-4 py-1 font-bold leading-tight dark:border-blue-steel-850",
    appearance === "primary" && "border-r",
    (tableLayoutEnabled || appearance === "secondary") && "border-t",
  );
  return (
    <div
      data-table-column-id={column.id}
      data-testid={`table-footer-column-${column.id}`}
      {...column.getFooterProps({ role: "cell" })}
    >
      <div className={classes}>{column.render("Footer")}</div>
    </div>
  );
}
