import { useCallback, useMemo } from "react";
import {
  get,
  useController,
  useFormContext,
  type RegisterOptions,
} from "react-hook-form";
import { useIntl } from "react-intl";
import {
  Field,
  Select as ReactSelect,
  mergeRefs,
  type ComboboxChanges,
  type FieldProps,
  type SelectChanges,
  type SelectItem,
  type SelectItemValue,
  type SelectWithSearchProps,
  type SelectWithoutSearchProps,
} from "ui";
import { v4 as uuidV4 } from "uuid";

import { requiredMessage } from "../../../messages";

export type SelectProps = {
  /**
   * The name the select items will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  /**
   * This will override the default onChange.
   *
   * If you want to add functionality to the default onChange, then pass
   * onChange inside the register prop. i.g. register={{onChange}}
   *
   * If you want to conditionally override the default call back,
   * have your onChange return false to prevent the default callback from running.
   * This behavior is used in TimeRangeSelect
   *
   * If nothing is returned by onChange, the default callback will also be run.
   */
  onChange?: (changes: SelectChanges | ComboboxChanges) => void;
} & (
  | {
      /**
       * A flag to indicate format of return type.
       *
       * Set this flag to true for return format to be of type `string | string[]`. Please note that if your `items` are dynamic and a selected `SelectItem` is removed from `items`, all associated metadata will be discarded, leaving only the value. If you have a dynamic list of `items`, it is not recommended to use `simpleFormat`.
       */
      simpleFormat: true;

      /**
       * A default value for Select.
       */
      defaultValue?: SelectItem["value"] | SelectItem["value"][] | null;
    }
  | {
      simpleFormat?: false;

      defaultValue?: SelectItem | SelectItem[] | null;
    }
) &
  Omit<FieldProps, "error" | "control" | "labelId"> &
  (
    | ({
        /**
         * Enable searching on the select items. When enabled the select will handle filtering the provided items.
         *
         * If you need to override how search behaves you may provide an `onSearch` function.
         */
        enableSearch: true;
      } & Omit<SelectWithSearchProps, "labelId" | "toggleButtonId">)
    | ({
        /**
         * Enable searching on the select items. When enabled the select will handle filtering the provided items.
         *
         * If you need to override how search behaves you may provide an `onSearch` function.
         */
        enableSearch?: false | undefined;
      } & Omit<SelectWithoutSearchProps, "labelId" | "toggleButtonId">)
  );

/**
 * Renders a select connected to the form. All extra props will get passed to the select.
 */
export const Select = (props: SelectProps) => {
  const {
    name,
    label,
    register = {},
    defaultValue = null,
    description,
    tooltip,
    disabled,
    showLabel,
    simpleFormat = false,
    enableSearch = false,
    buttonRef,
    optional,
    selectedItem,
    onChange: userOnChange,
    ...rest
  } = props;
  const labelId = useMemo((): string => `field-${uuidV4()}-label`, []);
  const buttonId = useMemo((): string => `field-${uuidV4()}-button`, []);
  const context = useFormContext();
  const intl = useIntl();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render Select outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState } = context;
  register.required =
    register.required ??
    intl.formatMessage(requiredMessage, {
      label,
    });
  const {
    field: { onChange, ref, value },
  } = useController({
    name,
    defaultValue: defaultValue,
    rules: register,
  });

  const valueMap = useMemo(
    () =>
      rest.items
        ? rest.items.reduce<Record<string, SelectItem>>((acc, curr) => {
            acc[`${JSON.stringify(curr.value)}`] = curr;
            return acc;
          }, {})
        : {},
    [rest.items],
  );

  const selected = useMemo(() => {
    if (selectedItem) return selectedItem;
    if (value == undefined) return;
    if (simpleFormat) {
      return rest.enableMultiSelect
        ? value?.map(
            (value: SelectItemValue) =>
              valueMap[JSON.stringify(value)] ?? { value },
          )
        : value != undefined && (valueMap[JSON.stringify(value)] ?? { value });
    }

    return rest.enableMultiSelect
      ? value?.map(
          (value: SelectItem) => valueMap[JSON.stringify(value.value)] ?? value,
        )
      : valueMap[JSON.stringify(value.value)] ?? value;
  }, [value, simpleFormat, rest.enableMultiSelect, valueMap, selectedItem]);
  const allRefs = useMemo(() => mergeRefs([buttonRef, ref]), [buttonRef, ref]);

  const handleOnChange = useCallback(
    (changes: ComboboxChanges | SelectChanges) => {
      const runDefaultCallback = userOnChange?.(changes) ?? true;

      if (!runDefaultCallback) return;

      simpleFormat
        ? onChange(
            rest.enableMultiSelect
              ? changes.selectedItems.map((item) => item.value)
              : changes.selectedItem?.value ?? null,
          )
        : onChange(
            rest.enableMultiSelect
              ? changes.selectedItems
              : changes.selectedItem ?? null,
          );
    },
    [onChange, rest.enableMultiSelect, simpleFormat, userOnChange],
  );

  return (
    <Field
      label={label}
      labelId={labelId}
      inputId={buttonId}
      optional={optional ?? !register.required}
      control={
        <ReactSelect
          fullWidth
          enableSearch={enableSearch}
          allowClear={!rest.enableMultiSelect && !register.required && value}
          {...rest}
          buttonRef={allRefs}
          labelId={labelId}
          toggleButtonId={buttonId}
          disabled={disabled}
          selectedItem={selected}
          onChange={handleOnChange}
        />
      }
      error={get(formState.errors, name)?.message}
      description={description}
      tooltip={tooltip}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
};
