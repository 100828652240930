import { Route, redirect } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { homeLayoutRoute } from "../home/homeLayoutRoute";

const HomeDataLayout = lazy(() =>
  import("./HomeDataLayout").then((res) => ({
    default: res.HomeDataLayout,
  })),
);

const DataOverview = lazy(() => import("./DataOverview"));

export const homeDataLayoutRoute = new Route({
  getParentRoute: () => homeLayoutRoute,
  path: "/data",
  component: HomeDataLayout,
  validateSearch: z.object({
    skipAnimation: z.optional(z.boolean()),
  }),
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/home/data") {
      throw redirect({
        to: "/home/data/overview",
        replace: true,
        search,
      });
    }
  },
});

export const homeDataOverviewRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "overview",
  component: DataOverview,
});

export const homeDataOverviewNestedRoutes = new Route({
  getParentRoute: () => homeDataOverviewRoute,
  path: "$",
  component: DataOverview,
});

export const homeDataInventoryRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "assets",
  component: DataOverview,
});

export const homeDataInventoryNestedRoutes = new Route({
  getParentRoute: () => homeDataInventoryRoute,
  path: "$",
  component: DataOverview,
});

export const homeDataAiInventoryRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "ai",
  component: DataOverview,
});

export const homeDataFilesRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "files",
  component: DataOverview,
});

export const homeDataFindingsRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "entities",
  component: DataOverview,
});

export const homeDataFindingsNestedRoutes = new Route({
  getParentRoute: () => homeDataFindingsRoute,
  path: "$",
  component: DataOverview,
});

export const homeDataRisksRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "risks",
  component: DataOverview,
});
export const homeDataRisksNestedRoutes = new Route({
  getParentRoute: () => homeDataRisksRoute,
  path: "$",
  component: DataOverview,
});

export const homeDataComplianceRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "compliance",
  component: DataOverview,
});

export const homeDataPoliciesRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "policies",
  component: DataOverview,
});

export const homeDataAlertsRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "alerts",
  component: DataOverview,
});

export const homeDataAlertsNestedRoutes = new Route({
  getParentRoute: () => homeDataAlertsRoute,
  path: "$",
  component: DataOverview,
});

export const homeDataReportsRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "reports",
  component: DataOverview,
});

export const homeDataSettingsRoute = new Route({
  getParentRoute: () => homeDataLayoutRoute,
  path: "settings",
  component: DataOverview,
});

export const homeDataSettingsNestedRoutes = new Route({
  getParentRoute: () => homeDataSettingsRoute,
  path: "$",
});
