import { type ComponentPropsWithoutRef, type ReactElement } from "react";
import { FormattedDate } from "react-intl";

import { Tooltip } from "../Tooltip";
import { Body, type BodyProps } from "../Typography";
import { DistanceToNow, type DistanceToNowProps } from "./DistanceToNow";

export interface AbsoluteTimestampProps
  extends Omit<BodyProps, "as" | "children"> {
  /**
   * Supports the same options as [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters)
   */
  formatOptions?: Omit<ComponentPropsWithoutRef<typeof FormattedDate>, "value">;
  /**
   * Supports the same options as [Intl.RelativeTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#parameters)
   */
  tooltipFormatOptions?: Omit<DistanceToNowProps, "value">;
  value: number | string | Date;
}

/**
 * Renders an absolute timestamp with the relative time in a tooltip.
 *
 * The base formatting, `formatOptions`, supports the same options as [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters).
 *
 * The tooltip formatting, `tooltipFormatOptions`, supports the same options as [Intl.RelativeTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#parameters).
 *
 * ### Import Guide
 *
 * ```jsx
 * import { AbsoluteTimestamp } from "ui";
 * ```
 */
export function AbsoluteTimestamp({
  formatOptions,
  tooltipFormatOptions,
  value,
  ...passThrough
}: AbsoluteTimestampProps): ReactElement {
  return (
    <Tooltip label={<DistanceToNow value={value} {...tooltipFormatOptions} />}>
      <span>
        <Body as="span" {...passThrough}>
          <FormattedDate value={value} {...formatOptions} />
        </Body>
      </span>
    </Tooltip>
  );
}
