import {
  CloudAccountNameRequestSchema,
  CloudAccountNameResponseSchema,
  CloudAccountNameWithOtherResponseSchema,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { jsonApi } from "../../apis";
import { type CloudType } from "../cloudType";

export type CloudAccountNameKeysProps = {
  onlyActive?: "true" | "false";
  includeOthers?: "true" | "false";
  cloudType?: CloudType;
  accountGroupIds?: string[];
  accountIds?: string[];
  includeNonOnboardedAccounts?: "true" | "false";
};

export const cloudAccountNameKeys = {
  all: [{ scope: "cloudAccountNameAll" }] as const,
  cloudAccountNames: (params?: CloudAccountNameKeysProps) => {
    return [
      {
        ...cloudAccountNameKeys.all[0],
        entity: "cloudAccountName",
        params,
      },
    ] as const;
  },
} as const;

// Doc - https://pan.dev/prisma-cloud/api/cspm/get-cloud-account-names-by-post/
// includeNonOnboardedAccounts are not mentioned in the doc, default value is false (Ingestion team)
export const getCloudAccountNames = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountNameKeys)["cloudAccountNames"]>
>) => {
  return jsonApi({
    path: "cloud/name",
    config: {
      method: "post",
    },
    params: {
      includeOthers: params?.includeOthers || "false",
      onlyActive: params?.onlyActive || "true",
      ...(params?.includeNonOnboardedAccounts && {
        includeNonOnboardedAccounts: params.includeNonOnboardedAccounts,
      }),
      ...(params?.cloudType && {
        cloudType: params.cloudType,
      }),
    },
    body: {
      ...(params?.accountGroupIds && {
        accountGroupIds: params.accountGroupIds,
      }),
      ...(params?.accountIds && {
        accountIds: params.accountIds,
      }),
    },
    requestSchema: CloudAccountNameRequestSchema,
    responseSchema:
      params?.includeOthers === "true"
        ? CloudAccountNameWithOtherResponseSchema
        : CloudAccountNameResponseSchema,
  });
};
