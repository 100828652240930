import { useFormContext } from "form";
import { useIsBusinessLicenseType } from "prisma";
import { useEffect } from "react";
import { usePrevious } from "ui";
import { useWizardContext } from "../../../../../../components/Wizard";
import {
  ACCOUNT,
  ACTIVE_DIRECTORY,
  AZURE,
  TENANT,
} from "../../../../../constants";
import { useIsRenderedFirstTime } from "../../../../../hooks";
import AccountType from "./AzureAccountType";

type ScopeProps = {
  isEdit: boolean;
  apiAzureActiveDir?: boolean;
};

export default function Scope({ isEdit, apiAzureActiveDir }: ScopeProps) {
  const {
    state: { steps, activeIndex },
    valuesUpdate,
  } = useWizardContext();
  const { watch } = useFormContext();
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isFirstRender = useIsRenderedFirstTime();

  const { isBusinessLicenseType } = useIsBusinessLicenseType();

  const accountType = watch("accountType");
  const prevAccountType = usePrevious(accountType);
  const accountGroupsValue = steps[activeIndex + 1]?.values?.groupIds;

  useEffect(() => {
    if (!isEdit) {
      if (accountType === ACCOUNT || accountType === ACTIVE_DIRECTORY) {
        valuesUpdate({
          index: activeIndex + 1,
          values: {
            ...steps[activeIndex + 1]?.values,
            groupIds: Array.isArray(accountGroupsValue)
              ? accountGroupsValue
              : [accountGroupsValue],
            monitorFlowLogs:
              accountType !== ACTIVE_DIRECTORY && !isBusinessLicenseType,
          },
        });
      } else if (accountType === TENANT && Array.isArray(accountGroupsValue)) {
        valuesUpdate({
          index: activeIndex + 1,
          values: {
            ...steps[activeIndex + 1]?.values,
            groupIds: accountGroupsValue[0],
          },
        });
      }
    } else if (isEdit && prevAccountType && accountType && !isFirstRender) {
      // Set monitorFlowLogs to true when Account changes from AD to Tenant
      const isADToTenant =
        prevAccountType === ACTIVE_DIRECTORY && accountType === TENANT;
      valuesUpdate({
        index: activeIndex + 1,
        values: {
          ...steps[activeIndex + 1]?.values,
          monitorFlowLogs: isADToTenant,
          groupIds:
            isADToTenant && Array.isArray(accountGroupsValue)
              ? accountGroupsValue[0]
              : Array.isArray(accountGroupsValue)
                ? accountGroupsValue
                : [accountGroupsValue],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  return (
    <section className="space-y-2">
      <div className="-ml-2">
        <AccountType
          cloudType={AZURE}
          isEdit={isEdit}
          apiAzureActiveDir={apiAzureActiveDir}
          name="accountType"
        />
      </div>
    </section>
  );
}
