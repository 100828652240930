/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { justInTimeRoute } from "../../justInTimeRoute";

export const SettingsPage = lazy(() => import("../pages/JITSettingsLayout"));

const actionTypes = ["activate_jit_slack"] as const;
export const SettingsActionSchema = z.enum(actionTypes);
export type SettingsActionType = z.infer<typeof SettingsActionSchema>;

export const SettingsSearchSchema = z.object({
  action: SettingsActionSchema.optional(),
  slack_user_id: z.string().optional(),
  workspace_id: z.string().optional(),
  user_id: z.string().optional(),
});

export const jitSettingsRoute = new Route({
  getParentRoute: () => justInTimeRoute,
  path: "settings",
  component: SettingsPage,
  validateSearch: SettingsSearchSchema,
});
