import { isStorybook } from "environment";
import { useCallback, useEffect, useState } from "react";
import { noop } from "utils";

export type Themes = "light" | "dark" | "system";

export function currentTheme(): Themes {
  return window.localStorage.theme || "system";
}

export function isDarkMode(): boolean {
  const theme = currentTheme();

  return theme === "system"
    ? window.matchMedia("(prefers-color-scheme: dark)").matches
    : theme === "dark";
}

/**
 * Returns wether the theme or system settings are set to dark mode
 */
export function useDarkMode(): {
  isDark: boolean;
  theme: Themes;
  setTheme: (theme: Themes) => void;
} {
  const [theme, setTheme] = useTheme();

  // Storybook will set the `dark` class on the <body />, but some components still use this hook to do additional stuff (e.g. <Card /> sets a glass appearance when isDark === 'true') so this needs to be synced separately.
  if (isStorybook()) {
    const isStorybookDark = document.body.classList.contains("dark");

    return {
      isDark: isStorybookDark,
      theme: isStorybookDark ? "dark" : "light",
      setTheme: noop,
    };
  }

  const isDark =
    theme === "system"
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
      : theme === "dark";

  return { isDark, theme, setTheme };
}
/**
 * Returns the current theme and subscribes to updates.
 * Also handles setting the dark class on the document element when changing setting.
 */
function useTheme(): [Themes, (theme: Themes) => void] {
  const [theme, setTheme] = useState(currentTheme);

  const setAndSave = useCallback(
    (value: Themes) => {
      setTheme(value);
      const isDark =
        value === "system"
          ? window.matchMedia("(prefers-color-scheme: dark)").matches
          : value === "dark";
      if (isDark) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      if (value === "system") {
        localStorage.removeItem("theme");
      } else {
        window.localStorage.setItem("theme", value);
      }

      window.dispatchEvent(
        new StorageEvent("storage", { key: "theme", newValue: value }),
      );
    },
    [setTheme],
  );

  useEffect(() => {
    const handleChanges = (storageEvent: StorageEvent) => {
      if (storageEvent.key === "theme") {
        const newState = storageEvent.newValue;
        storageEvent.newValue && setTheme(newState as Themes);
      }
    };

    window.addEventListener("storage", handleChanges);
    return () => {
      window.removeEventListener("storage", handleChanges);
    };
  }, [setTheme]);

  return [theme, setAndSave];
}
