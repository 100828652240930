import { Select } from "form";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Body } from "ui";
import { ACCOUNT } from "../../../../constants";
import { type CloudType } from "../../../../types";
import { getAccountTypeItems } from "./items";

type AccountTypeProps = {
  cloudType: CloudType;
  defaultValue?: string;
  name: string;
  isChinaPartition?: boolean;
};

AccountType.defaultProps = {
  name: "accountType",
};

export default function AccountType({
  cloudType,
  defaultValue,
  name,
  isChinaPartition,
}: AccountTypeProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Scope",
    id: "Zt3b2v",
    description: "Description for Scope/AccountType label",
  });

  const items = useMemo(() => {
    const accountTypeItems = getAccountTypeItems(cloudType);

    return isChinaPartition
      ? accountTypeItems.filter(({ value }) => value === ACCOUNT)
      : accountTypeItems;
  }, [cloudType, isChinaPartition]);

  return (
    <section className="space-y-2">
      <Body size="sm" appearance="secondary" addClassName="mb-1 font-bold">
        {title}
      </Body>
      <Select
        simpleFormat
        name={name}
        showLabel={false}
        label={title}
        items={items}
        defaultValue={defaultValue}
      />
    </section>
  );
}
