import { setDefaultOptions } from "date-fns";
import { enUS, fr, ja, zhCN } from "date-fns/locale";
import { useEffect, useState, type ReactElement, type ReactNode } from "react";
import { IntlProvider as BaseIntlProvider } from "react-intl";

import { defaultLocale, useLocale } from "prisma";

type IntlProviderProps = {
  children: ReactNode;
};

export default function IntlProvider({
  children,
}: IntlProviderProps): ReactElement {
  const [messages, setMessages] =
    useState<React.ComponentProps<typeof BaseIntlProvider>["messages"]>();
  const { derivedLocale: locale } = useLocale();

  useEffect(() => {
    if (locale === "en") {
      setMessages(undefined);
      setDefaultOptions({ locale: enUS });
    } else if (locale === "ja") {
      import("../langCompiled/ja.json").then((data) => {
        setMessages(data.default);
      });
      setDefaultOptions({ locale: ja });
    } else if (locale === "fr") {
      import("../langCompiled/fr.json").then((data) => {
        setMessages(data.default);
      });
      setDefaultOptions({ locale: fr });
    } else if (locale === "zh") {
      import("../langCompiled/zh.json").then((data) => {
        setMessages(data.default);
      });
      setDefaultOptions({ locale: zhCN });
    }
  }, [locale]);

  return (
    <BaseIntlProvider
      defaultLocale={defaultLocale}
      locale={locale}
      messages={messages}
    >
      {children}
    </BaseIntlProvider>
  );
}
