import { isEmpty } from "remeda";
import { snakeCase } from "utils";
import {
  CLEAR_FTU_FIELD_ERRORS,
  ERRORS,
  EXTERNAL_ACCOUNT,
  EXTERNAL_ACCOUNT_KEY,
  PRIVATE_KEY,
  PRIVATE_KEY_ID,
  PROJECT_ID,
  PROJECT_ID_WIF,
  SERVICE_ACCOUNT,
  SERVICE_ACCOUNT_KEY,
  SET_EXTERNAL_ACCOUNT_KEY,
  SET_FTU_FIELD_ERRORS,
  SET_SERVICE_ACCOUNT_KEY,
  TYPE,
  VALUE,
} from "../../../../../../../constants";
import { getExternalAccountKey, getServiceAccountKey } from "../getters";
import { type GcpCredentialsFileStateType } from "../initialState";
import { fieldRequired } from "../setters";

export const getFieldValue = ({
  field,
  state,
}: {
  field: string;
  state: GcpCredentialsFileStateType;
}) => state.getIn([field, VALUE]);

export const getFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: GcpCredentialsFileStateType;
}) => state.getIn([field, ERRORS]);

const validateField = ({
  field,
  validations = [],
}: {
  field: string;
  validations: ((val: string) => {
    isValid: boolean;
    message: { defaultMessage: string; description: string; id: string };
  })[];
}) => {
  return (
    dispatch: React.Dispatch<unknown>,
    getState: () => { state: GcpCredentialsFileStateType },
  ) => {
    const { state } = getState();

    const value = getFieldValue({ field, state });

    const errors = validations
      .map((validation) => validation(value))
      .reduce((result: string[], { isValid, message }) => {
        const validationMsg = message as unknown as string;
        if (!isValid && !result.includes(validationMsg))
          return result.concat([validationMsg]);

        return result;
      }, []);

    if (isEmpty(errors)) dispatch(clearJsonFieldErrors({ field }));
    else dispatch(setFTUFieldErrors({ errors, field }));

    return errors;
  };
};

export const handleSubmitGCPJsonData = (
  state: GcpCredentialsFileStateType,
  dispatch: React.Dispatch<unknown>,
  errorMessage: {
    defaultMessage: string;
    description: string;
    id: string;
  },
  authenticationTypeSelected: string,
  isFederatedGcpWifEnabled: boolean,
) => {
  if (authenticationTypeSelected === SERVICE_ACCOUNT) {
    // required field validation
    const serviceAccountKey = getServiceAccountKey(state);
    const emptyFieldValidation = fieldRequired(serviceAccountKey);

    if (!emptyFieldValidation.isValid) {
      dispatch(
        setFTUFieldErrors({
          errors: emptyFieldValidation.message,
          field: SERVICE_ACCOUNT_KEY,
        }),
      );
      return false;
    } else {
      const { isValid, message } = validServiceAccountKeyJSON(
        serviceAccountKey,
        errorMessage,
      );
      if (!isValid) {
        dispatch(
          setFTUFieldErrors({
            errors: message,
            field: SERVICE_ACCOUNT_KEY,
          }),
        );
        return false;
      }
      return true;
    }
  } else {
    // required field validation
    const externalAccountKey = getExternalAccountKey(state);
    const emptyFieldValidation = fieldRequired(externalAccountKey);
    if (!emptyFieldValidation.isValid) {
      dispatch(
        setFTUFieldErrors({
          errors: emptyFieldValidation.message,
          field: EXTERNAL_ACCOUNT_KEY,
        }),
      );
      return false;
    } else {
      const { isValid, message } = isFederatedGcpWifEnabled
        ? validExternalAccountKeyJSONV2(externalAccountKey, errorMessage)
        : validExternalAccountKeyJSON(externalAccountKey, errorMessage);
      if (!isValid) {
        dispatch(
          setFTUFieldErrors({
            errors: message,
            field: EXTERNAL_ACCOUNT_KEY,
          }),
        );
        return false;
      }
      return true;
    }
  }
};

//validation for service account json
export const validServiceAccountKeyJSON = (
  json: Record<string, unknown>,
  message: Record<string, unknown>,
) => {
  if (!json) return { isValid: false, message };

  const VALIDATION_KEYS = [PROJECT_ID, PRIVATE_KEY_ID, PRIVATE_KEY].map(
    snakeCase,
  );

  const allKeysPresent =
    VALIDATION_KEYS.map((key) => json[key]).filter((val) => val).length ===
    VALIDATION_KEYS.length;

  const jsonIsServiceAccount = json[TYPE] && json[TYPE] === SERVICE_ACCOUNT;

  const isValid = !!(allKeysPresent && jsonIsServiceAccount);

  return { isValid, message };
};

//validation for external key validation
export const validExternalAccountKeyJSON = (
  json: Record<string, unknown>,
  message: {
    defaultMessage: string;
    description: string;
    id: string;
  },
) => {
  if (!json) return { isValid: false, message };

  const VALIDATION_KEYS = [
    "audience",
    "subject_token_type",
    "service_account_impersonation_url",
    "token_url",
  ].map(snakeCase);

  const allKeysPresent =
    VALIDATION_KEYS.map((key) => json[key]).filter((val) => val).length ===
    VALIDATION_KEYS.length;

  const jsonIsExternalAccount = json[TYPE] && json[TYPE] === EXTERNAL_ACCOUNT;

  const isValid = !!(allKeysPresent && jsonIsExternalAccount);

  return { isValid, message };
};
export const validExternalAccountKeyJSONV2 = (
  json: Record<string, unknown>,
  message: {
    defaultMessage: string;
    description: string;
    id: string;
  },
) => {
  if (!json) return { isValid: false, message };

  const VALIDATION_KEYS = ["audience", "client_email"].map(snakeCase);

  const allKeysPresent =
    VALIDATION_KEYS.map((key) => json[key]).filter((val) => val).length ===
    VALIDATION_KEYS.length;

  const jsonIsExternalAccount = json[TYPE] && json[TYPE] === EXTERNAL_ACCOUNT;

  const isValid = !!(allKeysPresent && jsonIsExternalAccount);

  return { isValid, message };
};

export const clearJsonFieldErrors = (payload: { field: string }) => ({
  payload,
  type: CLEAR_FTU_FIELD_ERRORS,
});

export const setFTUFieldErrors = (payload: Record<string, unknown>) => ({
  payload,
  type: SET_FTU_FIELD_ERRORS,
});

/*----------------- External Account Actions --------------*/
export const setExternalAccountKey = (payload: {
  json: Record<string, unknown>;
  name: string;
  size: number;
}) => ({
  type: SET_EXTERNAL_ACCOUNT_KEY,
  payload,
});

export const setProjectIdWif = (payload: unknown) => ({
  type: PROJECT_ID_WIF,
  payload,
});

export const clearExternalAccountKey = () => ({
  type: SET_EXTERNAL_ACCOUNT_KEY,
  payload: { json: null, name: null, size: 0 },
});

export const setExternalAccountKeyErrors = (errors: {
  defaultMessage: string;
  description: string;
}) => setFTUFieldErrors({ errors, field: EXTERNAL_ACCOUNT_KEY });

export const validateExternalAccountKey = (isFederatedGcpWifEnabled: boolean) =>
  validateField({
    field: EXTERNAL_ACCOUNT_KEY,
    validations: [
      fieldRequired,
      isFederatedGcpWifEnabled
        ? validExternalAccountKeyJSONV2
        : validExternalAccountKeyJSON,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as any,
  });

/*----------------- Service Account Actions --------------*/

export const setServiceAccountKey = (payload: {
  json: Record<string, unknown>;
  name: string;
  size: number;
}) => ({
  type: SET_SERVICE_ACCOUNT_KEY,
  payload,
});

export const clearServiceAccountKey = () => ({
  type: SET_SERVICE_ACCOUNT_KEY,
  payload: { json: null, name: null, size: 0 },
});

export const setServiceAccountKeyErrors = (errors: {
  defaultMessage: string;
  description: string;
}) => setFTUFieldErrors({ errors, field: SERVICE_ACCOUNT_KEY });

export const validateServiceAccountKey = () =>
  validateField({
    field: SERVICE_ACCOUNT_KEY,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validations: [fieldRequired, validServiceAccountKeyJSON] as any,
  });
