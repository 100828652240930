/* c8 ignore start */
import {
  currentEnvironment,
  isChina,
  isDevelopment,
  isGov,
  isProduction,
  isTest,
} from "environment";
import { useTokenStore } from "requests";

import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { BrowserTracing } from "@sentry/tracing";

export function setupSentry() {
  if (isChina() || isGov() || isDevelopment() || isTest()) return;

  const dsn = isProduction()
    ? "https://db97f870a12c441283d1b8411c42937a@o237308.ingest.sentry.io/4504238324776960"
    : "https://f46c8f3aba030c2846a5241a8218c912@o237308.ingest.sentry.io/4504238324776960";

  Sentry.init({
    dsn,
    integrations: [
      new BrowserTracing({
        // CNS backend doesn't allow sentry headers so don't send to network.prismacloud.io
        tracePropagationTargets: [/^((?!network\.).)*prismacloud\.io/],
      }),
      new Replay({
        // Capture 10% of all sessions
        sessionSampleRate: 0.1,

        // Of the remaining 90% of sessions, if an error happens start capturing
        errorSampleRate: 1.0,

        // Mask all text content with asterisks (*). Passes text
        // content through to `maskTextFn` before sending to server.
        //
        // Defaults to true, uncomment to change
        // maskAllText: true,

        // Block all media elements (img, svg, video, object,
        // picture, embed, map, audio)
        //
        // Defaults to true, uncomment to change
        blockAllMedia: false,
      }),
    ],
    environment: currentEnvironment(),
    // Set tracesSampleRate to 0.75 to capture 75%
    // of transactions for performance monitoring
    // to prevent going over plan limits.
    tracesSampleRate: 0.75,
    initialScope: setupSentryScope(),
    ignoreErrors: ["status: 401"],
  });
}

export function stopBootstrapTracking() {
  performance.mark("app-bootstrap");
}

export function setupSentryScope() {
  const token = useTokenStore.getState().token;

  if (!token) return {};

  return {
    user: {
      // TODO: see if we can send this in production - we do to pendo
      email: token.username,
    },
    tags: {
      api: token.aud ? new URL(token.aud).host : undefined,
      tenant: token.prismaId,
    },
  };
}
