import { type OperationName } from "prisma";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { type Features } from "requests";

export function useOperationSelectAll(features: Features[] = []) {
  const { setValue, watch } = useFormContext();

  const availableFeatures = useMemo(
    () => ({
      READ: features.filter((feature) => "READ" in feature.operations),
      CREATE: features.filter((feature) => "CREATE" in feature.operations),
      UPDATE: features.filter((feature) => "UPDATE" in feature.operations),
      DELETE: features.filter((feature) => "DELETE" in feature.operations),
    }),
    [features],
  );

  const readValues = watch(
    availableFeatures.READ.map(
      (feature) => `features.${feature.featureName}.READ`,
    ),
  );
  const createValues = watch(
    availableFeatures.CREATE.map(
      (feature) => `features.${feature.featureName}.CREATE`,
    ),
  );
  const updateValues = watch(
    availableFeatures.UPDATE.map(
      (feature) => `features.${feature.featureName}.UPDATE`,
    ),
  );
  const deleteValues = watch(
    availableFeatures.DELETE.map(
      (feature) => `features.${feature.featureName}.DELETE`,
    ),
  );

  const counts = useMemo(
    () => ({
      READ: readValues.filter((value) => value).length,
      CREATE: createValues.filter((value) => value).length,
      UPDATE: updateValues.filter((value) => value).length,
      DELETE: deleteValues.filter((value) => value).length,
    }),
    [readValues, createValues, updateValues, deleteValues],
  );

  const onClickSelectAll = (operation: OperationName) => {
    if (
      counts[operation] !== availableFeatures[operation].length ||
      availableFeatures[operation].length === 0
    ) {
      availableFeatures[operation].forEach((feature) =>
        setValue(`features.${feature.featureName}.${operation}`, true),
      );
    } else {
      availableFeatures[operation].forEach((feature) =>
        setValue(`features.${feature.featureName}.${operation}`, false),
      );
    }
  };

  const getSelectionStatus = (operation: OperationName) => {
    if (
      availableFeatures[operation].length &&
      counts[operation] === availableFeatures[operation].length
    )
      return "all";
    if (
      counts[operation] > 0 &&
      counts[operation] < availableFeatures[operation].length
    )
      return "indeterminate";
    return "none";
  };

  return {
    selectionStatus: {
      read: getSelectionStatus("READ"),
      create: getSelectionStatus("CREATE"),
      update: getSelectionStatus("UPDATE"),
      delete: getSelectionStatus("DELETE"),
    },
    onClickSelectAll,
  };
}
