import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Step1Icon } from "../../../icons";
import { DottedLine, StepNumber } from "./AWSConsoleSteps";

export default function Step1() {
  const { watch } = useFormContext();
  const onboardType = watch("onboardType");

  return (
    <div className="flex">
      <span className="inline-block">
        <Step1Icon size="5xl" />
      </span>
      <span className="mx-2 inline-block">
        <StepNumber stepNumber={1} />
      </span>
      <span className="mt-2 inline-block text-xs">
        {onboardType === "automated" ? (
          <FormattedMessage
            defaultMessage="The AWS console will open in a new tab."
            id="dzCMvy"
            description="Connect to the AWS Console Step1 description"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Download template > Log into AWS Console to Upload"
            id="F07UCZ"
            description="Connect to the AWS Console Step1 description"
          />
        )}
      </span>
      <span className="mt-4 inline-block">
        <DottedLine />
      </span>
    </div>
  );
}
