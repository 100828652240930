import { useEffect, type FC } from "react";
import { useTableInstance } from "../TableContext";
import { TableFilter } from "./TableFilter";

export interface Props {
  /**
   * Set the placeholder for the filter input
   */
  placeholder?: string;
  /**
   * Defines whether the tooltip should be visible on hover or not (default: hidden).
   */
  isTooltipVisible?: boolean;
  /**
   * Set the tooltip for the filter input
   */
  tooltip?: string;
}

/**
 * Filters the table data locally that matches the string entered in the Search input.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConnectedTable, ConnectedTableFilter, TableFilter, TableProvider } from "table";
 * ```
 */
export const ConnectedTableFilter: FC<Props> = ({
  placeholder,
  isTooltipVisible,
  tooltip,
}: Props) => {
  const {
    autoResetGlobalFilter,
    data,
    setGlobalFilter,
    state: { globalFilter = "" },
  } = useTableInstance();

  useEffect(() => {
    if (autoResetGlobalFilter) {
      setGlobalFilter("");
    }
  }, [autoResetGlobalFilter, setGlobalFilter, data]);

  return (
    <TableFilter
      onChange={setGlobalFilter}
      placeholder={placeholder}
      tooltip={tooltip}
      value={globalFilter}
      isTooltipVisible={isTooltipVisible}
    />
  );
};
