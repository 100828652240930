import { type GraphqlResponse } from "../schemas";
import { baseJsonApi } from "./baseJsonApi";

export const ASSET_NOT_FOUND_ERROR = "ASSET_NOT_FOUND";
export const PERMISSION_DENIED_ERROR = "PERMISSION_DENIED";

/**
 * jsonApi should only be used inside this package. Do not use it directly in an application or other packages.
 */
export async function jsonApi<Request, Response>(
  options: Parameters<typeof baseJsonApi<Request, Response>>[0],
): Promise<Response> {
  const response = await baseJsonApi(options);
  return response.body;
}

/**
 * Wraps {@link jsonApi} in order to handle response body errors (e.g. in 'uai/v1/asset' api)
 * Expects an array of errors under the **errors** field.
 *
 * **Notice:** this function throws an error in case of the response's errors array is not empty,
 * if you want to receive the response anyway, do not use this function!
 */
export async function jsonApiWithBodyErrors<
  Request,
  Response extends GraphqlResponse,
>(options: Parameters<typeof jsonApi<Request, Response>>[0]) {
  const response = await jsonApi(options);

  if (response.errors?.length) {
    if (
      response.errors.some(
        (error) => error.extensions?.errorType === "NOT_FOUND",
      )
    ) {
      throw new Error(ASSET_NOT_FOUND_ERROR);
    }
    if (
      response.errors.some(
        (error) => error.extensions?.errorType === "PERMISSION_DENIED",
      )
    ) {
      throw new Error(PERMISSION_DENIED_ERROR);
    }
    throw new Error(response.errors.map((e) => e.message).join("\r\n"));
  }

  return response as Response;
}
