import { type ComponentPropsWithoutRef, type ReactElement } from "react";

export function AllGoodSVG(
  props: ComponentPropsWithoutRef<"svg">,
): ReactElement {
  return (
    <svg
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="45"
        cy="45"
        r="45"
        className="fill-[#EFF8FF] dark:fill-[#354252]"
      />
      <rect
        x="0.5"
        y="15.5"
        width="89"
        height="61"
        className="fill-white stroke-[#DADBDB] dark:fill-[#19222E] dark:stroke-[#354252]"
      />
      <path
        d="M0 19L89.0112 19"
        className="stroke-[#DADBDB] dark:stroke-[#354252]"
      />
      <circle
        cx="45.0001"
        cy="47"
        r="19.4344"
        className="stroke-[#CFE8FC] dark:stroke-[#618099]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 6 1 6"
      />
      <circle
        cx="44.9997"
        cy="47"
        r="16.2707"
        className="stroke-[#85C8FF] dark:stroke-[#8DD5E2]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 6 1 6"
      />
      <circle
        cx="45"
        cy="47"
        r="22.5981"
        className="stroke-[#DADBDB] dark:stroke-[#375067]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 6 1 6"
      />
      <circle
        cx="45"
        cy="47"
        r="26"
        className="stroke-[#EAEBEB] dark:stroke-[#273547]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 6 1 6"
      />
      <path
        d="M52.5423 47.0789C52.8347 46.5569 53 46 53 45.3268C53 43.7794 51.7594 42.3181 50.1393 42.3181H48.9233C49.0873 41.8677 49.2183 41.3291 49.2183 40.6819C49.2183 38.1228 47.962 37 46.0427 37C43.9891 37 44.1062 40.3375 43.6507 40.818C42.8924 41.6177 41.9968 43.154 41.3587 43.75H38.0667C37.4776 43.75 37 44.2537 37 44.875V53.3125C37 53.9338 37.4776 54.4375 38.0667 54.4375H40.2C40.6964 54.4375 41.1136 54.0798 41.2326 53.5955C42.7162 53.6307 43.7346 54.9996 47.1593 54.9996C47.4 54.9996 47.6667 55 47.9 55C50.4706 55 51.6329 53.614 51.6647 51.6486C52.1086 51.0008 52.3413 50.1325 52.2427 49.2934C52.5711 48.6447 52.6981 47.8751 52.5423 47.0789ZM50.484 48.9714C50.9027 49.7143 50.526 50.7085 50.0193 50.9954C50.276 52.7103 49.4324 53.3121 48.2487 53.3121H46.988C44.6 53.3121 43.0537 51.9825 41.2667 51.9825V45.4375H41.6307C42.576 45.4375 43.8967 42.9453 44.782 42.0112C45.7273 41.0141 45.4123 39.3523 46.0427 38.6875C47.6183 38.6875 47.6183 39.847 47.6183 40.6819C47.6183 42.059 46.673 42.676 46.673 44.0056H50.1393C50.843 44.0056 51.397 44.6704 51.4 45.3352C51.403 45.9996 50.9727 46.6645 50.6577 46.6645C51.1073 47.1762 51.2034 48.2548 50.484 48.9714ZM39.9333 52.1875C39.9333 52.6535 39.5752 53.0312 39.1333 53.0312C38.6915 53.0312 38.3333 52.6535 38.3333 52.1875C38.3333 51.7215 38.6915 51.3438 39.1333 51.3438C39.5752 51.3438 39.9333 51.7215 39.9333 52.1875Z"
        className="fill-[#85C8FF] dark:fill-[#8DD5E2]"
      />
    </svg>
  );
}
