import { CloudAccountsTableResponseSchema } from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";
import { type CloudType } from "../../cloudType";
import { type cloudAccountsKeys } from "../cloudAccountsKeys";

export const getCloudAccountsTableData = async ({
  queryKey: [{ excludeAccountGroupDetails, includePendingAccounts }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["cloudAccountsTable"]>
>) =>
  jsonApi({
    path: "cloud",
    params: {
      excludeAccountGroupDetails,
      ...(includePendingAccounts && { includePendingAccounts }),
    },
    requestSchema: z.void(),
    responseSchema: CloudAccountsTableResponseSchema,
  });

export const enableCloudAccount = async ({
  accountId,
  enabled,
  updateChildren,
}: {
  accountId: string;
  enabled: boolean;
  updateChildren: boolean;
}) =>
  jsonApi({
    path: `cloud/${accountId}/status/${enabled}`,
    config: {
      method: "PATCH",
    },
    params: updateChildren ? { updateChildren: "true" } : {},
    requestSchema: z.void(),
    responseSchema: z.string(),
  });

export const deleteCloudAccount = async ({
  accountId,
  cloudType,
}: {
  accountId: string;
  cloudType: CloudType;
}) =>
  jsonApi({
    path: `cloud/${cloudType}/${accountId}`,
    config: {
      method: "DELETE",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });

export const deleteTempCloudAccount = async ({
  accountName,
}: {
  accountName: string;
}) =>
  jsonApi({
    path: `cas/api/v1/cloud-account/temp/aws/account/${accountName}`,
    config: {
      method: "DELETE",
    },
    requestSchema: z.void(),
    responseSchema: z.void(),
  });
