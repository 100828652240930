import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { LaptopCodeIcon } from "icons";
import { Body } from "ui";
import { useWizardContext } from "../../../../../components/Wizard";
import {
  DataFlowCheckbox,
  DataFlowCompressionDescription,
  DataFlowProjectId,
  FlowLogsStorageBucket,
} from "../../../../components/Inputs";
import { ORGANIZATION } from "../../../../constants";
import { type GcpGetStartedStepValuesType } from "../../../../types";
import { Accordion, AccordionToggle } from "../CustomAccordion";
import { messageMap } from "./messages";

export default function FlowLogs() {
  const intl = useIntl();
  const { watch, setValue } = useFormContext();
  const {
    state: { steps },
  } = useWizardContext();

  const { accountType } = steps[0]?.values as GcpGetStartedStepValuesType;
  const isOrg = accountType === ORGANIZATION;

  const isFlowLogStorageBucketEnabled = !!watch("flowLogStorageBucket");
  const [enabled, setEnabled] = useState(isFlowLogStorageBucketEnabled);

  useEffect(() => {
    if (!isFlowLogStorageBucketEnabled) {
      setValue("dataFlowCheckbox", false);
      setValue("dataflowEnabledProject", "");
    }
  }, [isFlowLogStorageBucketEnabled, setValue]);

  const toggle = () => {
    if (isFlowLogStorageBucketEnabled) {
      setValue("flowLogStorageBucket", "");
      setValue("dataFlowCheckbox", false);
      setValue("dataflowEnabledProject", "");
    }
    setEnabled((prev) => !prev);
  };

  return (
    <Accordion
      description={intl.formatMessage(messageMap.description)}
      isOpen={enabled}
      icon={<LaptopCodeIcon size="lg" />}
      menuToggle={<AccordionToggle checked={enabled} onClick={toggle} />}
      title={intl.formatMessage(messageMap.title)}
    >
      <Body as="div" addClassName="space-y-4">
        {enabled && <FlowLogsStorageBucket />}

        <Body as="div" addClassName="space-y-2">
          {isOrg ? (
            <DataFlowProjectId disabled={!isFlowLogStorageBucketEnabled} />
          ) : (
            <DataFlowCheckbox />
          )}
          <DataFlowCompressionDescription />
        </Body>
      </Body>
    </Accordion>
  );
}
