import { useIntl } from "react-intl";

import { Input } from "form";
import { Tooltip } from "ui";
import { useTrimField } from "../../../../hooks";
import { validateMemberRoleName } from "../../utils";

type MemberRoleNameProps = {
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  showLabel?: boolean;
  disabled?: boolean;
};

export default function MemberRoleName({
  onBlur,
  disabled = false,
  ...rest
}: MemberRoleNameProps) {
  const intl = useIntl();
  useTrimField("memberRoleName");

  const memberroleArnInputField = (
    <Input
      name="memberRoleName"
      data-selector="aws-memeber-role-name-input"
      label={intl.formatMessage({
        defaultMessage: "Member IAM Role Name",
        id: "TUyV9S",
        description: "The name of an input field for onboarding an AWS account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "PrismaCloudReadOnlyMemberRole",
        id: "tCwSG8",
        description: "Placeholder for Member IAM Role Name",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Member IAM Role Name is required",
          id: "ISkLNH",
          description: "Error message for field for onboarding an AWS account",
        }),
        validate: (value) => validateMemberRoleName(value, intl),
      }}
      tooltip={intl.formatMessage({
        defaultMessage: "IAM Role Name for Prisma Cloud Member Accounts",
        id: "V51mbH",
        description:
          "Tooltip for a display field for onboarding an AWS account",
      })}
      disabled={disabled}
      {...rest}
    />
  );
  return disabled ? (
    <Tooltip
      label={intl.formatMessage({
        defaultMessage:
          "Download member CFT to unblock Member IAM Role ARN entry.",
        id: "QwkueE",
        description: "tooltip for disabled IAM Role ARN field",
      })}
    >
      {memberroleArnInputField}
    </Tooltip>
  ) : (
    memberroleArnInputField
  );
}
