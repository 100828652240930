import {
  GCP,
  MASTER_SERVICE_ACCOUNT,
  ORGANIZATION,
  WORKSPACE_DOMAIN,
  WORKSPACE_DOMAIN_PAYLOAD,
} from "../../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../../../hooks";

import { LoadingIcon } from "icons";
import { useFormContext } from "react-hook-form";
import { useWizardContext } from "../../../../../../components/Wizard";
import { type GcpConfigureAccountStepValuesType } from "../../../../../types";
import ComputeLimitBanner from "../../../components/ComputeLimitBanner";
import SecurityCapabilities from "../../../components/SecurityCapabilities";

export default function SecurityCapabilitiesAndPermissions() {
  const {
    state: { steps, activeIndex },
  } = useWizardContext();
  const { watch } = useFormContext();

  const accountType = watch("accountType");
  const { autoOnboardProjects } = steps[activeIndex + 1]
    ?.values as GcpConfigureAccountStepValuesType;
  const isMSA = accountType !== ORGANIZATION && autoOnboardProjects;
  const payload = {
    accountType: isMSA
      ? MASTER_SERVICE_ACCOUNT
      : accountType === WORKSPACE_DOMAIN
        ? WORKSPACE_DOMAIN_PAYLOAD
        : accountType,
  };

  const { isLoading, licenseType } = useGetSupportedFeaturesList({
    cloudType: GCP,
    payload,
  });

  const { isLoading: isIdentitySubscriptionLoading } =
    useIdentitySubscription();

  if (isLoading || isIdentitySubscriptionLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <ComputeLimitBanner />
      <SecurityCapabilities
        cloudType={GCP}
        isMSA={isMSA}
        licenseType={licenseType}
        payload={payload}
      />
    </>
  );
}
