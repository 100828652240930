import { CloseIcon, type IconSizes } from "icons";
import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { classNames } from "utils";
import { Button, type ButtonProps } from "../Button";

export interface ClearButtonProps extends ButtonProps {
  iconSize?: IconSizes;
}

/**
 * A clear button for a text input
 */
export const ClearButton = forwardRef<HTMLButtonElement, ClearButtonProps>(
  (
    {
      addClassName,
      appearance = "tertiary-clear",
      iconSize = "sm",
      size = "sm",
      ...passThrough
    }: ClearButtonProps,
    ref,
  ) => {
    const intl = useIntl();
    const label = intl.formatMessage({
      defaultMessage: "Clear",
      id: "i6LYAv",

      description: "Clear text input",
    });

    return (
      <Button
        addClassName={classNames("items-center text-icon-gray", addClassName)}
        aria-label={label}
        appearance={appearance}
        icon={<CloseIcon size={iconSize} />}
        ref={ref}
        size={size}
        {...passThrough}
      />
    );
  },
);

ClearButton.displayName = "ClearButton";
