import { useQuery } from "@tanstack/react-query";
import { isChina } from "environment";
import { useTenantsFeatures } from "prisma";
import { useMemo } from "react";
import { featureStatuskeys, getMetaInfo, useLicenseInfo } from "requests";

export type IamStatus = {
  isIamModuleEnabled: boolean;
  isIamSupportedForCustomer: boolean;
  showIamUpsell: boolean;
  isLoading: boolean;
  isFeatureExists: boolean;
  isError: boolean;
};

export function useIamStatus(useMetaInfoAPI = false): IamStatus {
  const {
    data: tenantFeatures,
    isLoading: tenantFeaturesLoading,
    isError: isTenantFeatureError,
  } = useTenantsFeatures();

  const {
    data: licenseInfo,
    isLoading: licenseInfoLoading,
    isError: isLicenseInfoError,
  } = useLicenseInfo({ enabled: !useMetaInfoAPI });

  const {
    data: metaInfo,
    isLoading: metaInfoLoading,
    isError: isMetaInfoError,
  } = useQuery({
    queryKey: featureStatuskeys.metaInfo(),
    queryFn: getMetaInfo,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: useMetaInfoAPI,
  });

  const licenseType = useMetaInfoAPI
    ? metaInfo?.licenseType
    : licenseInfo?.licenseType;

  const isIamSupportedForCustomer = licenseType == "enterprise" && !isChina();

  const isIamModuleEnabled = useMemo(
    () =>
      tenantFeatures?.some(
        (feature) =>
          feature.feature_name == "iam-security" &&
          feature.enabled &&
          feature.state === "PROVISION_SUCCESSFUL",
      ) ?? false,
    [tenantFeatures],
  );
  const isLoading =
    tenantFeaturesLoading ||
    (!useMetaInfoAPI ? licenseInfoLoading : metaInfoLoading);
  const isError =
    isTenantFeatureError ||
    (!useMetaInfoAPI ? isLicenseInfoError : isMetaInfoError);
  const showIamUpsell = isIamSupportedForCustomer && !isIamModuleEnabled;
  const isFeatureExists =
    tenantFeatures?.some(
      (feature) => feature.feature_name === "iam-security",
    ) ?? false;

  return {
    isLoading,
    isError,
    isIamModuleEnabled,
    isIamSupportedForCustomer,
    showIamUpsell,
    isFeatureExists,
  };
}
