import { Checkbox, useWatch } from "form";
import { FormattedMessage, useIntl } from "react-intl";
import { Body, Bold, Title } from "ui";
import { useWizardContext } from "../../../../../../components/Wizard";
import { ORGANIZATION } from "../../../../../constants";
import { type AwsGetStartedStepValuesType } from "../../../../../types";

export default function MemberAccountSettings() {
  const intl = useIntl();
  const {
    state: { steps },
  } = useWizardContext();

  const isCustomMemberRoleNameEnabled = useWatch({
    name: "customMemberRoleNameEnabled",
  });
  const { accountType } = steps[0]?.values as AwsGetStartedStepValuesType;
  const isOrg = accountType === ORGANIZATION;

  return (
    <div className={`${isOrg && "grid grid-rows-3 px-10"}`}>
      <div className="flex px-4">
        <div className="w-7 [&>div>label:nth-child(1)]:hidden ">
          <Checkbox
            name="useTenantExternalId"
            showLabel={false}
            label={intl.formatMessage({
              defaultMessage: "Use Tenant Specific External ID",
              id: "ldQCba",
              description: "Checkbox label",
            })}
          />
        </div>

        <div className="flex space-x-2">
          <div className="-mt-1">
            <Bold as="p" addClassName="flex items-center space-x-1">
              <FormattedMessage
                defaultMessage="Use Tenant Specific External ID"
                id="/p81Zw"
                description="Advance Configuration Settings card body"
              />
            </Bold>
          </div>
        </div>
      </div>

      {isOrg && (
        <>
          <div className="-ml-10">
            <Title level={2} size="xxs">
              <FormattedMessage
                defaultMessage="Member Account Settings"
                id="oMl3Eh"
                description="Advance Configuration Settings card body"
              />
            </Title>
          </div>

          <div className="flex px-4 pt-2">
            <div className="w-7 [&>div>label:nth-child(1)]:hidden">
              <Checkbox
                name="customMemberRoleNameEnabled"
                label={intl.formatMessage({
                  defaultMessage:
                    "Use a different role name for Member Accounts.",
                  id: "Py0BWw",
                  description: "Checkbox label",
                })}
                showLabel={false}
              />
            </div>
            <div className="flex space-x-2">
              <div className="-mt-1">
                <Bold as="p" addClassName="flex items-center space-x-1">
                  <FormattedMessage
                    defaultMessage="Use a different role name for Member Accounts."
                    id="uQutKb"
                    description="Advance Configuration Settings card body"
                  />
                </Bold>
                <Body appearance="secondary" size="sm">
                  <FormattedMessage
                    defaultMessage="On enabling, you must enter the same Member Account IAM Role Name below that you entered while running the CFT"
                    id="vNhg1H"
                    description="Advance Configuration Settings card body"
                  />
                </Body>
              </div>
            </div>
          </div>

          <div className="flex px-12 pt-4">
            <div className="w-7 [&>div>label:nth-child(1)]:hidden">
              <Checkbox
                name="unifiedCftDisabled"
                disabled={!isCustomMemberRoleNameEnabled}
                label={intl.formatMessage({
                  defaultMessage:
                    "Use separate CFTs for Management & Member account.",
                  id: "rcAqlO",
                  description: "Checkbox label",
                })}
                showLabel={false}
              />
            </div>
            <div className="flex space-x-2">
              <div className="-mt-1">
                <Bold as="p" addClassName="flex items-center space-x-1">
                  <FormattedMessage
                    defaultMessage="Use separate CFTs for Management & Member account."
                    id="79uIjO"
                    description="Advance Configuration Settings card body"
                  />
                </Bold>
                <Body appearance="secondary" size="sm">
                  <FormattedMessage
                    defaultMessage="On enabling, you must download and run both the CFTs."
                    id="nNbojK"
                    description="Advance Configuration Settings card body"
                  />
                </Body>
              </div>
            </div>
          </div>

          <div className="flex px-4 pt-4">
            <div className="w-7 [&>div>label:nth-child(1)]:hidden">
              <Checkbox
                name="skipOverrideMemberRoleName"
                showLabel={false}
                label={intl.formatMessage({
                  defaultMessage:
                    "Preserve Role ARN and External ID of already onboarded accounts that belong to this Org.",
                  id: "xlqdmO",
                  description: "Checkbox label",
                })}
              />
            </div>
            <div className="flex space-x-2">
              <div className="-mt-1">
                <Bold as="p" addClassName="flex items-center space-x-1">
                  <FormattedMessage
                    defaultMessage="Preserve Role ARN and External ID of already onboarded accounts that belong to this Org."
                    id="WsUv20"
                    description="Advance Configuration Settings card body"
                  />
                </Bold>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
