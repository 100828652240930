import { type FeatureItem } from "./FeatureGroup";

import { type AccountType, type CloudType } from "../../../../types";
import ToggleFeatureCard from "../ToggleFeatureCard";

type FeaturesListViewProps = {
  cloudType?: CloudType;
  isComputeWorkloadSupported?: boolean;
  features: FeatureItem[];
  values: Record<string, boolean>;
  onChange: (...event: unknown[]) => void;
  orgValues?: Record<string, boolean>;
  onToggleChange?: (...event: unknown[]) => void;
  accountType?: AccountType;
  tag?: string;
  deploymentType?: string;
};

export default function FeaturesListView({
  cloudType,
  isComputeWorkloadSupported,
  features,
  values,
  onChange,
  orgValues,
  onToggleChange,
  accountType,
  deploymentType,
}: FeaturesListViewProps) {
  return (
    <div className="my-2 rounded border border-gray-300 bg-white dark:border-blue-steel-500 dark:bg-blue-steel-950">
      {features.map(({ value, orgScanToggleValue, ...rest }) => {
        return (
          <ToggleFeatureCard
            key={value}
            {...rest}
            cloudType={cloudType}
            isComputeWorkloadSupported={isComputeWorkloadSupported}
            accountType={accountType}
            checked={!!values[value]}
            deploymentType={deploymentType}
            onChange={() => {
              const newValues = { ...values };
              newValues[value] = !newValues[value];
              onChange(newValues);
            }}
            toggleChecked={Boolean(
              orgScanToggleValue &&
                orgValues &&
                !!orgValues[orgScanToggleValue],
            )}
            onToggle={() => {
              if (!orgScanToggleValue || !onToggleChange) {
                return;
              }
              const newValues = { ...orgValues };
              newValues[orgScanToggleValue] = !newValues[orgScanToggleValue];
              onToggleChange(newValues);
            }}
          />
        );
      })}
    </div>
  );
}
