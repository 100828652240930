import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsDemistoIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Cortex XSOAR",
    id: "slp1AZ",

    description: "Cortex XSOAR icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M35.7143 50C35.7143 62.7473 45.8242 72.8571 58.5714 72.8571V90C36.5934 90 18.5714 71.978 18.5714 50C18.5714 28.022 36.5934 10 58.5714 10V27.1429C45.8242 27.1429 35.7143 37.2527 35.7143 50Z"
        className="fill-[#35B558] dark:fill-white"
      />
      <path
        d="M81.4286 49.9997C81.4286 62.747 71.3187 72.8569 58.5714 72.8569V27.1426C71.3187 27.1426 81.4286 37.2525 81.4286 49.9997Z"
        className="fill-[#35B558] dark:fill-white"
      />
    </SvgIcon>
  );
};
