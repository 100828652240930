import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { RadioGroup as FormRadioGroup } from "form";
import { Banner, Body, Bold } from "ui";

export default function ScanOptionsRadioGroup() {
  const intl = useIntl();
  const items = [
    {
      value: "Custom",
      title: intl.formatMessage({
        defaultMessage: "Custom Scan Resources",
        id: "7ZHyQA",
        description:
          "An option for an input field for onboarding an aws account",
      }),
      description: (
        <Body appearance="secondary" size="sm" as="span">
          <FormattedMessage
            defaultMessage="Customize Scan Mode (forward and/or backward) and Scan Capability (public exposure, sensitive data and/or malware) for all eligible objects in selected Resources."
            id="OrjVQu"
            description="Description of an option for an input field for onboarding an aws account"
          />
        </Body>
      ),
    },
    {
      value: "Full",
      title: intl.formatMessage({
        defaultMessage: "Scan All Resources",
        id: "3n80sp",
        description:
          "An option for an input field for onboarding an aws account",
      }),
      description: (
        <Body appearance="secondary" size="sm" as="span">
          <FormattedMessage
            defaultMessage="Forward & backward scan all eligible objects in Resources for public exposure, sensitive data & malware."
            id="9wRewg"
            description="Description of an option for an input field for onboarding an aws account"
          />
        </Body>
      ),
    },
  ];

  const { watch } = useFormContext();
  const selectedScanOption = watch("scanOption");

  return (
    <>
      <div>
        <FormattedMessage
          defaultMessage="Choose whether to scan all or customize what you want to scan within your cloud storage account."
          id="kFXPGo"
          description="Descriptions of two different AWS onboarding options"
        />
        <div className="mt-3">
          <FormRadioGroup
            label={intl.formatMessage({
              defaultMessage:
                "Choose whether to scan all or customize what you want to scan within your cloud storage account.",
              id: "kFXPGo",
              description:
                "Descriptions of two different AWS onboarding options",
            })}
            showLabel={false}
            name="scanOption"
            items={items}
            addClassName="flex space-x-2 space-y-0 [&>label>div]:h-full [&>label]:relative [&>label]:flex-1"
          />
        </div>
      </div>
      <Banner type="inline" appearance="standard" showIcon>
        {selectedScanOption === "Custom" ? (
          <FormattedMessage
            defaultMessage="To ensure you complete data security configuration, navigate to <b>Settings > Data</b> to configure custom scan for resource(s) after final <b>Review Status</b> step."
            id="lqlSa2"
            description="Message to be displayed in a banner if user selects Custom Scan Resources"
            values={{
              b: (chunks) => <Bold>{chunks}</Bold>,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="All existing and new buckets will be automatically scanned for public exposure, sensitive data, and malware. See <b>Settings > Licensing</b> to monitor consumption."
            id="AslMEp"
            description="Message to be displayed in a banner if user selects Custom Scan Resources"
            values={{
              b: (chunks) => <Bold>{chunks}</Bold>,
            }}
          />
        )}
      </Banner>
    </>
  );
}
