import { keepPreviousData, useQuery } from "@tanstack/react-query";

import {
  getTenantFeatures,
  tenantKeys,
  useToken,
  type GetTenantFeaturesParams,
} from "requests";

/**
 * Provides the tenant feature data
 */

export function useTenantsFeatures(
  queryOptions: Omit<GetTenantFeaturesParams, "prismaId"> = {},
) {
  const { prismaId } = useToken();

  const query = useQuery({
    queryKey: tenantKeys.features({ prismaId, ...queryOptions }),
    queryFn: getTenantFeatures,
    refetchOnMount: false,
    placeholderData: keepPreviousData,
    staleTime: 3 * 60 * 1000, // 3 minutes
  });

  return query;
}
