import { z } from "zod";
import { FeatureSchema } from "../profile";

export type AssociatedRoles = PermissionGroupData["associatedRoles"];
export type PermissionGroupData = z.infer<typeof PermissionGroup>;

export const PermissionGroup = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  type: z.enum(["Default", "Custom"]).optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedTs: z.number().optional(),
  associatedRoles: z.record(z.string(), z.string()).optional(),
  features: z.array(FeatureSchema),
  acceptAccountGroups: z.boolean().optional(),
  acceptResourceLists: z.boolean().optional(),
  acceptCodeRepositories: z.boolean().optional(),
  custom: z.boolean().optional(),
});

export const PermissionGroupsResponseSchema = z.array(PermissionGroup);
export type PermissionGroupsResponse = z.infer<
  typeof PermissionGroupsResponseSchema
>;
