import { useIntl } from "react-intl";

import { Input } from "form";

type FlowLogsStorageBucketProps = {
  disabled?: boolean;
};

export const FlowLogsStorageBucket = ({
  disabled = false,
}: FlowLogsStorageBucketProps) => {
  const intl = useIntl();

  return (
    <Input
      data-testid="gcp-flow-log-storage-bucket-input"
      disabled={disabled}
      label={intl.formatMessage({
        defaultMessage: "Flow Logs Storage Bucket",
        id: "tQImpe",
        description: "The name of an input field for onboarding a GCP account",
      })}
      name="flowLogStorageBucket"
      placeholder={intl.formatMessage({
        defaultMessage: "Flow Logs Storage Bucket",
        id: "IzhN94",
        description: "The placeholder for Flow Logs Storage Bucket",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Flow Logs Storage Bucket is required",
          id: "Q7WUdu",
          description: "Error message for field for onboarding a GCP account",
        }),
      }}
    />
  );
};
