import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

import { useIntl } from "react-intl";

export const IntegrationsOktaIdpIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Okta IDP",
    id: "K2EXUP",

    description: "Integrations Okta IDP icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M50 10C27.9626 10 10 27.8337 10 50C10 72.1663 27.835 90 50 90C72.165 90 90 72.165 90 50C90 27.835 72.0374 10 50 10ZM50 70C38.9174 70 30 61.0826 30 50C30 38.9174 38.9174 30 50 30C61.0826 30 70 38.9174 70 50C70 61.0826 61.0826 70 50 70Z"
        className="fill-[#007DC1] dark:fill-white"
      />
    </SvgIcon>
  );
};
