// existing versions of getAssets (scan_info)
// `ValidApiVersions` below expects an ordered array
// Only add new values to end, else update logic below
const validApiVersions = [0, 2, 4];

// checks input parameters (usually LaunchDarkly flag values)
// to return the closest valid version less than or equal to
// the version determined by the given parameters
export function determineApiVersion({
  requestedApiVersion,
  useV2Api,
}: {
  requestedApiVersion?: number;
  useV2Api?: boolean;
}) {
  // logic to determine api version from input parameters
  const translatedApiVersion = requestedApiVersion ?? (useV2Api ? 2 : 0);

  // find closest valid version that is not greater than the translated version
  const apiVersion = validApiVersions.includes(translatedApiVersion)
    ? translatedApiVersion
    : validApiVersions.reduce(
        (closest, current) =>
          current <= translatedApiVersion && current > closest
            ? current
            : closest,
        validApiVersions[0],
      );

  return apiVersion;
}
