import { LoadingIcon } from "icons";
import { useState, type ReactNode } from "react";
import { useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

type SecurityCapabilitiesPermissionsProps = {
  children: ReactNode;
  isLoading?: boolean;
  defaultOpen?: boolean;
};

export default function SecurityCapabilitiesPermissions({
  children,
  isLoading = false,
  defaultOpen = false,
}: SecurityCapabilitiesPermissionsProps) {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(defaultOpen);
  const toggle = () => setOpen((prev) => !prev);

  return (
    <Accordion>
      <AccordionPanel
        canClose={true}
        isOpen={isOpen}
        onClick={toggle}
        title={intl.formatMessage({
          defaultMessage: "Security Capabilities and Permissions",
          id: "9HmS9g",
          description:
            "Description for Security Capabilities and Permissions on review status",
        })}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingIcon size="xl" />
          </div>
        ) : (
          <section className="my-2">{children}</section>
        )}
      </AccordionPanel>
    </Accordion>
  );
}
