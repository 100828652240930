import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import {
  CloudTypeResponseSchema,
  CloudTypeWithOtherResponseSchema,
  type cloudTypes,
} from "./schemas";

export type CloudType = (typeof cloudTypes)[number];

export const cloudTypeKeys = {
  all: [{ scope: "cloudTypeAll" }] as const,
  cloudTypes: (params?: {
    onlyAccessible?: "false" | "false";
    includeOthers?: "true" | "false";
  }) =>
    [
      {
        ...cloudTypeKeys.all[0],
        entity: "cloudType",
        params,
      },
    ] as const,
} as const;

export const getCloudTypes = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<ReturnType<(typeof cloudTypeKeys)["cloudTypes"]>>) => {
  return jsonApi({
    path: "cloud/type",
    params,
    requestSchema: z.void(),
    responseSchema: params?.includeOthers
      ? CloudTypeWithOtherResponseSchema
      : CloudTypeResponseSchema,
  });
};
