/* c8 ignore start */
import { usePerformanceTracker } from "prisma";
import { PermissionGroupsTable } from "./PermissionGroupsTable/PermissionGroupsTable";

export function PermissionGroups() {
  usePerformanceTracker(
    "AccessControlPermissionGroupSettings",
    "AccessControlPermissionGroupSettings",
  );
  return <PermissionGroupsTable />;
}
/* c8 ignore stop */
