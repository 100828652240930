import { CaretRightIcon } from "icons";
import { type ReactElement } from "react";

import { type Data, type RowInstance } from "../../types";

interface RowExpansionToggleProps<D extends Data> {
  row: RowInstance<D>;
}

export function RowExpansionToggle<D extends Data>({
  row,
}: RowExpansionToggleProps<D>): ReactElement {
  return (
    <button
      aria-label={row.isExpanded ? "Collapse row" : "Expand row"}
      {...row.getToggleRowExpandedProps({
        className: "focus:outline-none focus:ring bg-transparent rounded mr-1",
      })}
      type="button"
    >
      <CaretRightIcon
        style={{
          transform: row.isExpanded ? "rotate(90deg)" : "rotate(0)",
        }}
        className="motion-safe:transition-transform motion-safe:duration-200"
      />
    </button>
  );
}
