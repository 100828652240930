import { type ColorTheme } from "requests";

/* eslint-disable index/only-import-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const black = "#000000";
export const white = "#FFFFFF";
export const overlay = "#1F2733";

export const grayScale = {
  DEFAULT: "#B8B8B8",
  100: "#F4F5F5",
  200: "#EAEBEB",
  300: "#DADBDB",
  400: "#B8B8B8",
  500: "#929191",
  600: "#707070",
  700: "#575757",
  800: "#484848",
  900: "#333333",
  1000: "#1A1A1A",
} as const;

export const redScale = {
  DEFAULT: "#DE5959",
  100: "#FFF2F2",
  200: "#FDCFCF",
  300: "#FC9696",
  400: "#DE5959",
  500: "#D13C3C",
  550: "#FC3737",
  600: "#AC1818",
  650: "#FF7979",
  700: "#8F000E",
  800: "#6B0000",
  900: "#540000",
  1000: "#330000",
} as const;

export const orangeScale = {
  DEFAULT: "#EF9700",
  100: "#FFF4E2",
  200: "#FEE4B7",
  300: "#FCC464",
  400: "#EF9700",
  500: "#D17D00",
  600: "#945600",
  700: "#804800",
  800: "#663800",
  900: "#472700",
  1000: "#331C00",
} as const;

export const yellowScale = {
  DEFAULT: "#F4BB01",
  100: "#FFF6D5",
  200: "#FFEA8F",
  300: "#FFD745",
  400: "#F4BB01",
  450: "#FFA451",
  500: "#C59402",
  600: "#906A02",
  700: "#785902",
  800: "#614500",
  900: "#453100",
  1000: "#2E2000",
} as const;

export const greenScale = {
  DEFAULT: "#1DB846",
  100: "#EEFBE5",
  200: "#CFF0C0",
  300: "#6BD66B",
  400: "#1DB846",
  500: "#119E39",
  600: "#02832A",
  650: "#9CE9B0",
  700: "#007024",
  800: "#005E1E",
  900: "#004716",
  1000: "#002E0F",
} as const;

export const tealScale = {
  100: "#E8FFF8",
  200: "#AFF0E0",
  300: "#6FD7C4",
  400: "#2CBEA2",
  500: "#0FA487",
  600: "#0F8268",
  700: "#0A644E",
  800: "#074635",
  900: "#063326",
  1000: "#041F17",
} as const;

export const pinkScale = {
  DEFAULT: "#E02765",
  100: "#FFA8EC",
} as const;

export const blueScale = {
  DEFAULT: "#38A5FF",
  100: "#EFF8FF",
  200: "#CFE8FC",
  300: "#85C8FF",
  400: "#38A5FF",
  500: "#128DF2",
  600: "#006FCC",
  700: "#004C9D",
  800: "#003474",
  900: "#00265B",
  1000: "#001533",
} as const;

export const blueSteelScale = {
  DEFAULT: "#9AA5B2",
  100: "#F0F4F7",
  200: "#DFE5EB",
  300: "#B8C1CC",
  400: "#9AA5B2",
  500: "#768494",
  600: "#5D6A7A",
  700: "#475566",
  800: "#354252",
  830: "#343940",
  850: "#2C3239",
  900: "#19222E",
  940: "#191F26",
  950: "#181E26",
  970: "#141A20",
  990: "#11151B",
  1000: "#0B1117",
} as const;

export const visScaleA = {
  100: "#303866",
  200: "#4E296C",
  300: "#742D7D",
  400: "#903280",
  500: "#A22C75",
  600: "#CA386A",
  700: "#EB5757",
} as const;

export const visScaleB = {
  100: "#661300",
  200: "#A62F0C",
  300: "#D95221",
  400: "#FF7B41",
  500: "#FF9A42",
  600: "#FFC74F",
  700: "#FFDE65",
} as const;

export const visScaleC = {
  100: "#1D7918",
  200: "#088D10",
  300: "#3EAE15",
  400: "#81C42E",
  500: "#AFD620",
  600: "#D6E31B",
  700: "#FFDE65",
} as const;

export const visScaleD = {
  100: "#357387",
  200: "#45959A",
  300: "#49A392",
  400: "#28C2A6",
  500: "#3ED6BA",
  600: "#7CE9CC",
  700: "#C5F5E9",
} as const;

export const visScaleE = {
  100: "#282F78",
  200: "#26418D",
  300: "#1F75B2",
  400: "#2C95C7",
  500: "#5DB8E4",
  600: "#7AC8ED",
  700: "#BCE4FA",
} as const;

export const error = {
  DEFAULT: redScale[500],
  light: redScale[300],
  lighter: redScale[200],
  lightest: redScale[100],
} as const;

export const warning = {
  DEFAULT: orangeScale[400],
  light: yellowScale[300],
  lighter: yellowScale[200],
  lightest: yellowScale[100],
} as const;

export const success = {
  DEFAULT: greenScale[400],
  light: greenScale[300],
  lighter: greenScale[200],
  lightest: greenScale[100],
} as const;

export const severityColors = {
  critical: "var(--severity-critical)",
  high: "var(--severity-high)",
  medium: "var(--severity-medium)",
  low: "var(--severity-low)",
  informational: "var(--severity-informational)",
} as const;

export const severityColorsInHex = {
  low: yellowScale[300],
  medium: orangeScale[400],
  high: redScale[500],
  critical: redScale[700],
  informational: blueScale["DEFAULT"],
} as const;

export const riskColors = {
  "1": blueScale[300],
  pass: blueScale[300],
  "2": greenScale[400],
  warning: yellowScale[300],
  "3": yellowScale[300],
  "4": orangeScale[400],
  fail: redScale[500],
  "5": redScale[500],
  critical: redScale[700],
} as const;

export const domainColors = {
  configuration: {
    color: "#F8EEEB",
    colorBorder: "#BD5838",
    colorDark: "#312C2E",
    colorDarkBorder: "#F6C1B0",
  },
  identity: {
    color: "#EFF0FD",
    colorBorder: "#6065EF",
    colorDark: "#232A3A",
    colorDarkBorder: "#9AB0FF",
  },
  network: {
    color: "#F6F0E9",
    colorBorder: "#AA6525",
    colorDark: "#2E2E2F",
    colorDarkBorder: "#E3CBB4",
  },
  vulnerability_malware: {
    color: "#FCE9F0",
    colorBorder: "#E02765",
    colorDark: "#312C33",
    colorDarkBorder: "#F5BCCF",
  },
  vulnerability: {
    color: "#FCE9F0",
    colorBorder: "#E02765",
    colorDark: "#312C33",
    colorDarkBorder: "#F5BCCF",
  },
  data: {
    color: "#F4EDFD",
    colorBorder: "#934FEA",
    colorDark: "#282536",
    colorDarkBorder: "#B893E8",
  },
  other: {
    color: "#F1F1F1",
    colorBorder: "#747474",
    colorDark: "#282B2F",
    colorDarkBorder: "#B8B8B8",
  },
  applicationServices: {
    color: "#E6F2F3",
    colorBorder: "#058089",
    colorDark: "#1F3339",
    colorDarkBorder: "#80F1F9",
  },
  compute: {
    color: "#EBF0F7",
    colorBorder: "#356EB0",
    colorDark: "#1F2B3A",
    colorDarkBorder: "#82BBFF",
  },
  development: {
    color: "#EDF2F5",
    colorBorder: "#4D7B9D",
    colorDark: "#262F36",
    colorDarkBorder: "#B0D0E8",
  },
} as const;

export const assetIconColors = {
  code: {
    color: domainColors.development.colorBorder,
    colorDark: domainColors.development.colorDarkBorder,
  },
  compute: {
    color: domainColors.compute.colorBorder,
    colorDark: domainColors.compute.colorDarkBorder,
  },
  database: {
    color: domainColors.data.colorBorder,
    colorDark: domainColors.data.colorDarkBorder,
  },
  identityAndSecurity: {
    color: domainColors.identity.colorBorder,
    colorDark: domainColors.identity.colorDarkBorder,
  },
  network: {
    color: domainColors.network.colorBorder,
    colorDark: domainColors.network.colorDarkBorder,
  },
  other: {
    color: domainColors.other.colorBorder,
    colorDark: domainColors.other.colorDarkBorder,
  },
  storage: {
    color: domainColors.data.colorBorder,
    colorDark: domainColors.data.colorDarkBorder,
  },
  kubernetes: {
    color: domainColors.compute.colorBorder,
    colorDark: domainColors.compute.colorDarkBorder,
  },
  applicationAndContentDelivery: {
    color: domainColors.applicationServices.colorBorder,
    colorDark: domainColors.applicationServices.colorDarkBorder,
  },
};

export const colorThemes: Record<
  ColorTheme,
  { darkhex: string; lighthex: string }
> = {
  "theme-base": {
    darkhex: "#B176FF",
    lighthex: "#6738A5",
  },
  "theme-lavender": {
    darkhex: "#EFAFFF",
    lighthex: "#CB4FEA",
  },
  "theme-scienceblue": {
    darkhex: "#38A5FF",
    lighthex: "#006FCC",
  },
  "theme-pacificblue": {
    darkhex: "#33D0F1",
    lighthex: "#009DBE",
  },
  "theme-robinblue": {
    darkhex: "#54BEE4",
    lighthex: "#54BEE4",
  },
  "theme-surfiegreen": {
    darkhex: "#2CBEA2",
    lighthex: "#0F8268",
  },
  "theme-bluechill": {
    darkhex: "#87DFD0",
    lighthex: "#0FA487",
  },
  "theme-buddhagold": {
    darkhex: "#FFD745",
    lighthex: "#C59402",
  },
  "theme-indochine": {
    darkhex: "#EF9700",
    lighthex: "#D17D00",
  },
  "theme-persianred": {
    darkhex: "#DE5959",
    lighthex: "#D13C3C",
  },
} as const;

export const iconColor = {
  gray: grayScale[400],
  red: redScale[400],
  orange: orangeScale[400],
  yellow: yellowScale[400],
  green: greenScale[400],
  blue: blueScale[400],
  pink: pinkScale["DEFAULT"],

  ["dark-bg"]: {
    pink: pinkScale[100],
  },
  ["blue-steel"]: blueSteelScale[400],
} as const;

export type IconColor = keyof typeof iconColor;

export const baseColors = {
  transparent: "transparent",
  current: "currentColor",
  black,
  white,
  overlay,

  gray: grayScale,
  red: redScale,
  orange: orangeScale,
  yellow: yellowScale,
  green: greenScale,
  teal: tealScale,
  blue: blueScale,
  ["blue-steel"]: blueSteelScale,

  error: error,
  warning: warning,
  success: success,

  risk: riskColors,
  domain: domainColors,
  icon: iconColor,

  ["vis-a"]: visScaleA,
  ["vis-b"]: visScaleB,
  ["vis-c"]: visScaleC,
  ["vis-d"]: visScaleD,
  ["vis-e"]: visScaleE,
} as const;

export const textColor = {
  default: grayScale[900],
  secondary: grayScale[600],
  disabled: grayScale[400],
  link: blueScale[600],

  red: redScale[700],
  orange: orangeScale[700],
  yellow: yellowScale[700],
  green: greenScale[700],
  blue: blueScale[700],
  ["blue-steel"]: blueSteelScale[700],

  ["dark-bg"]: {
    DEFAULT: grayScale[100],
    secondary: grayScale[400],
    disabled: grayScale[600],
    link: blueScale[400],

    red: redScale[400],
    orange: orangeScale[400],
    yellow: yellowScale[400],
    green: tealScale[400],
    blue: blueScale[400],
    ["blue-steel"]: blueSteelScale[400],
  },

  risk: riskColors,
  icon: iconColor,
} as const;

export const brandColor = "#0BA4E8";

export type Domain = keyof typeof domainColors;
