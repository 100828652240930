import {
  AlertCSVTypeRequest,
  AlertCSVTypeResponse,
  AlertDismissTypeRequest,
  AlertGraph,
  AlertRemediationCliResponse,
  AlertRemediationInfoResponse,
  AlertRemediationInfoTypeRequest,
  AlertReopenTypeRequest,
  AlertSummaryResponseSchema,
  AssetByAssetIdRequest,
  AssetByAssetIdResponse,
  CNSDataRequest,
  CNSDataResponse,
  CSVTypeResponse,
  CreatePRSchema,
  GetAlertByIdForAnomalyResponse,
  GetAlertByIdForAttackPathResponse,
  GetAlertByIdForAuditEventResponse,
  GetAlertByIdForConfigResponse,
  GetAlertByIdForNetworkResponse,
  GetAlertByIdForPolicyTypeDataResponse,
  GetAlertByIdForWorkloadIncidentsResponse,
  GetAlertByIdForWorkloadVulnerabilityResponse,
  GetAlertsAggregateRequest,
  GetAlertsAggregateRequestWithoutPaging,
  GetAlertsAggregateResponse,
  GetAlertsAggregateResponseWithoutPaging,
  GetAlertsDetailsResponse,
  GetAlertsRequest,
  GetAlertsResponse,
  GetAlertsV3PolicyRequest,
  GetAlertsV3PolicyResponse,
  GetPolicyAlertAllFilterRequestSchema,
  GetPolicyAlertAllFilterResponse,
  GetPolicyAlertsRequest,
  GetPolicyAlertsResponse,
  GetTopVulnerabilitiesRequest,
  GetVulnerabilitiesRequest,
  IAMDataRequest,
  IAMDataResponse,
  MeanResolutionTimeResponseSchema,
  ResolutionReasonResponseSchema,
  SearchAlertResponseSchema,
  StatusResponseSchema,
  TopAlertAssetsResponseSchema,
  TopAlertPoliciesResponseSchema,
  TopVulnerabilitiesResponseSchema,
  VulnerabilitiesResponseSchema,
  type AlertReopenType,
  type AlertSummaryCategoriesSchema,
  type AlertsAggregateDataCacheType,
  type AlertsDismissType,
  type AlertsFilterType,
  type AlertsFiltersPostBodySchema,
  type AlertsGroupByType,
  type PolicyAlertDataCacheZodType,
  type TopVulnerableAssetsType,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { type TimeRangeSchema } from "../../schemas";
import { type CloudTypesWithOtherEnumSchema } from "../cloudType";
import {
  CopilotPromptRequestSchema,
  CopilotPromptResponseSchema,
  PROMPT_API_PATH,
  type CopilotPromptRequest,
} from "../copilot";
import { SEARCH_IAM_ALERT } from "../iam";

// L1 alertsKeys.alertPolicy > L2 alertsKeys.policyAlert > L3 alertsKeys.alert

export const alertsKeys = {
  all: [{ scope: "alerts" }] as const,
  vulnerabilities: ({
    filters,
    timeRange,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
  }) =>
    [
      { ...alertsKeys.all[0], entity: "vulnerability", filters, timeRange },
    ] as const,
  topVulnerabilities: ({
    filters,
    timeRange,
    type,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
    type: TopVulnerableAssetsType;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "topVulnerabilities",
        filters,
        timeRange,
        type,
      },
    ] as const,

  alertPolicies: () =>
    [{ ...alertsKeys.all[0], entity: "alertPolicy" }] as const,

  alertPolicy: ({
    filters,
    timeRange,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
  }) => [{ ...alertsKeys.alertPolicies(), filters, timeRange }] as const,

  createPR: ({
    resourceId,
    accountId,
    prismaId,
    policyId,
  }: {
    resourceId: string;
    accountId: string;
    prismaId: string;
    policyId: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "createPR",
        resourceId,
        accountId,
        prismaId,
        policyId,
      },
    ] as const,

  alertInfoWorkloadIncident: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoWorkloadIncident",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,
  policyAlertAllFilter: (
    body: z.infer<typeof GetPolicyAlertAllFilterRequestSchema>,
  ) =>
    [{ ...alertsKeys.all[0], entity: "policyAlertAllFilter", body }] as const,
  alertsAggregateWithoutPaging: (
    body: z.infer<typeof GetAlertsAggregateRequestWithoutPaging>,
  ) =>
    [
      { ...alertsKeys.all[0], entity: "alertsAggregateWithoutPaging", body },
    ] as const,
  policyAlerts: ({ policyId }: { policyId: string }) =>
    [{ ...alertsKeys.all[0], entity: "policyAlerts", policyId }] as const,
  policyAlert: ({
    policyId,
    filters,
    timeRange,
  }: PolicyAlertDataCacheZodType) =>
    [
      {
        ...alertsKeys.policyAlerts({ policyId })[0],
        filters,
        timeRange,
      },
    ] as const,

  alertsAggregate: ({
    filters,
    timeRange,
    groupBy,
    sortBy,
    searchText,
  }: AlertsAggregateDataCacheType) =>
    [
      { ...alertsKeys.all[0], filters, timeRange, groupBy, sortBy, searchText },
    ] as const,

  alertInfo: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      { ...alertsKeys.all[0], entity: "alertInfo", alertId, withAlertRuleInfo },
    ] as const,

  alertInfoConfig: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoConfig",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,

  alertInfoAuditEvent: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoAuditEvent",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,

  alertInfoWorkloadVulnerability: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoWorkloadVulnerability",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,

  alertInfoNetwork: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoNetwork",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,

  alertInfoForPolicyTypeData: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoForPolicyTypeData",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,

  alertInfoAttackPath: ({
    alertId,
    withAlertRuleInfo,
  }: {
    alertId: string;
    withAlertRuleInfo: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertInfoAttackPath",
        alertId,
        withAlertRuleInfo,
      },
    ] as const,

  alertInfoAnomaly: ({ alertId }: { alertId: string }) =>
    [{ ...alertsKeys.all[0], entity: "alertInfoAnomaly", alertId }] as const,

  alertRemediationInfo: ({
    alerts,
    endpoint,
    findingId,
  }: {
    alerts: string[];
    endpoint: string;
    findingId?: string | undefined;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        findingId,
        entity: "alertRemediationInfo",
        alerts,
        endpoint,
      },
    ] as const,
  alertDownload: ({ endpoint }: { endpoint: string }) =>
    [{ ...alertsKeys.all[0], entity: "alertDownload", endpoint }] as const,
  alertRemediationCli: ({ cloudType }: { cloudType: string }) =>
    [
      { ...alertsKeys.all[0], entity: "alertRemediationCli", cloudType },
    ] as const,

  alertsPolicy: ({
    filters,
    timeRange,
    sortBy,
    searchText,
  }: Partial<AlertsAggregateDataCacheType>) =>
    [{ ...alertsKeys.all[0], filters, timeRange, sortBy, searchText }] as const,

  assetInfo: ({ assetId }: { assetId: string }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "assetInfo",
        assetId,
      },
    ] as const,

  cnsInfo: ({
    cloudType,
    resourceId,
    baseUrl,
  }: {
    cloudType: z.infer<typeof CloudTypesWithOtherEnumSchema>;
    resourceId: string;
    baseUrl: string;
  }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "cnsInfo",
        cloudType,
        resourceId,
        baseUrl,
      },
    ] as const,

  iamInfo: ({ resourceId }: { resourceId: string }) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "iamInfo",
        resourceId,
      },
    ] as const,

  graph: (alertID: string) => [
    {
      ...alertsKeys.all[0],
      entity: "graph",
      alertID,
    },
  ],

  alertSummary: ({
    filters,
    timeRange,
    swimlane,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
    swimlane: z.infer<typeof AlertSummaryCategoriesSchema>;
  }) =>
    [
      { entity: "alerts", scope: "summary", filters, timeRange, swimlane },
    ] as const,

  topAlertPolicies: ({
    filters,
    timeRange,
    swimlane,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
    swimlane: z.infer<typeof AlertSummaryCategoriesSchema>;
  }) =>
    [
      {
        entity: "alerts",
        scope: "topPolicies",
        filters,
        timeRange,
        swimlane,
      },
    ] as const,

  topAlertAssets: ({
    filters,
    timeRange,
    swimlane,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
    swimlane: z.infer<typeof AlertSummaryCategoriesSchema>;
  }) =>
    [
      {
        entity: "alerts",
        scope: "topAssets",
        filters,
        timeRange,
        swimlane,
      },
    ] as const,
  alertMeanResolutionTime: ({
    filters,
    timeRange,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
  }) =>
    [
      {
        entity: "alerts",
        scope: "meanResolutionTime",
        filters,
        timeRange,
      },
    ] as const,
  alertResolutionReason: ({
    filters,
    timeRange,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
  }) =>
    [
      {
        entity: "alerts",
        scope: "resolutionReason",
        filters,
        timeRange,
      },
    ] as const,
  alertStatus: ({
    filters,
    timeRange,
  }: {
    filters: z.infer<typeof AlertsFiltersPostBodySchema>;
    timeRange: z.infer<typeof TimeRangeSchema>;
  }) =>
    [
      {
        entity: "alerts",
        scope: "status",
        filters,
        timeRange,
      },
    ] as const,
  searchAlert: ({ alertId }: { alertId: string }) =>
    [{ ...alertsKeys.all[0], alertId }] as const,

  alertsGraphAIDescription: (body: CopilotPromptRequest) =>
    [
      {
        ...alertsKeys.all[0],
        entity: "alertsGraphAIDescription",
        body,
      },
    ] as const,
} as const;

export const getAlertsGraphAIDescription = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertsGraphAIDescription"]>
>) => {
  return jsonApi({
    body,
    path: PROMPT_API_PATH,
    config: {
      method: "post",
    },
    requestSchema: CopilotPromptRequestSchema,
    responseSchema: CopilotPromptResponseSchema,
  });
};

export const getVulnerabilityAlerts = async ({
  queryKey: [{ filters, timeRange }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["vulnerabilities"]>
>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: "commandcenter/v1/vulnerabilities/summary",
    config: {
      method: "post",
    },
    requestSchema: GetVulnerabilitiesRequest,
    responseSchema: VulnerabilitiesResponseSchema,
  });
};
export const getTopVulnerableImages = async ({
  queryKey: [{ filters, timeRange, type }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["topVulnerabilities"]>
>) => {
  return jsonApi({
    body: { filters, timeRange },
    params: { type, limit: "5" },
    path: `commandcenter/v1/top-vulnerabilities`,
    config: {
      method: "post",
    },
    requestSchema: GetTopVulnerabilitiesRequest,
    responseSchema: TopVulnerabilitiesResponseSchema,
  });
};

export const getAlertPolicy = async ({
  queryKey: [{ filters, timeRange }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["alertPolicy"]>>) => {
  return jsonApi({
    body: { detailed: false, webClient: true, filters, timeRange },
    path: "alert/policy",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest,
    responseSchema: GetAlertsResponse,
  });
};

export const getPolicyAlert = async ({
  policyId,
  filters,
  timeRange,
  pageParam,
  searchText,
}: {
  policyId: string;
  filters: z.infer<typeof AlertsFiltersPostBodySchema>;
  timeRange: z.infer<typeof TimeRangeSchema>;
  pageParam: string;
  searchText?: string;
}) => {
  return jsonApi({
    body: {
      detailed: false,
      filters: [
        { name: "policy.id", operator: "=", value: policyId },
        ...filters,
      ],
      timeRange,
      limit: 100,
      webClient: true,
      pageToken: pageParam,
      searchText,
    },
    path: `v2/alert`,
    config: {
      method: "post",
    },
    requestSchema: GetPolicyAlertsRequest,
    responseSchema: GetPolicyAlertsResponse,
  });
};

export const getPolicyAlertAllFilter = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["policyAlertAllFilter"]>
>) => {
  return jsonApi({
    body,
    path: "v2/alert",
    config: {
      method: "post",
    },
    requestSchema: GetPolicyAlertAllFilterRequestSchema,
    responseSchema: GetPolicyAlertAllFilterResponse,
  });
};

export const getAlertsAggregateWithoutPaging = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertsAggregateWithoutPaging"]>
>) => {
  return jsonApi({
    body,
    path: "alert/v1/aggregate",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsAggregateRequestWithoutPaging,
    responseSchema: GetAlertsAggregateResponseWithoutPaging,
  });
};

export const getAlertsAggregate = async ({
  filters,
  timeRange,
  pageParam,
  groupBy,
  sortBy,
  searchText,
}: {
  filters: z.infer<typeof AlertsFiltersPostBodySchema>;
  timeRange: z.infer<typeof TimeRangeSchema>;
  pageParam: string;
  groupBy: AlertsGroupByType;
  sortBy: Array<string>;
  searchText?: string;
}) => {
  return jsonApi({
    body: {
      filters,
      timeRange,
      groupBy,
      sortBy,
      size: 10,
      nextPageToken: pageParam,
      searchText,
    },
    path: "alert/v1/aggregate",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsAggregateRequest,
    responseSchema: GetAlertsAggregateResponse,
  });
};

export const getAlertV1Policy = async ({
  filters,
  timeRange,
  pageParam,
  sortBy,
  searchText,
  size = 10,
}: {
  filters: z.infer<typeof AlertsFiltersPostBodySchema>;
  timeRange: z.infer<typeof TimeRangeSchema>;
  pageParam: string;
  sortBy: Array<string>;
  searchText?: string;
  size?: number;
}) => {
  return jsonApi({
    body: {
      filters,
      timeRange,
      sortBy,
      size,
      nextPageToken: pageParam,
      searchText,
    },
    path: "alert/v1/policy",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsV3PolicyRequest,
    responseSchema: GetAlertsV3PolicyResponse,
  });
};

export const getAlert = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["alertInfo"]>>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertsDetailsResponse,
  });
};

export const getAlertForConfig = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoConfig"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForConfigResponse,
  });
};

export const getAlertForWorkloadVulnerbility = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoWorkloadVulnerability"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForWorkloadVulnerabilityResponse,
  });
};

export const getAlertForWorkloadIncident = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoWorkloadIncident"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForWorkloadIncidentsResponse,
  });
};

export const getAlertForNetwork = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoNetwork"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForNetworkResponse,
  });
};

export const getAlertForPolicyTypeData = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoForPolicyTypeData"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForPolicyTypeDataResponse,
  });
};

export const getAlertForAttackPath = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoAttackPath"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForAttackPathResponse,
  });
};

export const getAlertForAuditEvent = async ({
  queryKey: [{ alertId, withAlertRuleInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoAuditEvent"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForAuditEventResponse,
  });
};

export const getAlertForAnomaly = async ({
  queryKey: [{ alertId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertInfoAnomaly"]>
>) => {
  return jsonApi({
    params: { detailed: "false", withAlertRuleInfo: "false" },
    path: `alert/${alertId}`,
    requestSchema: z.void(),
    responseSchema: GetAlertByIdForAnomalyResponse,
  });
};

export const getAssetByAssetId = async ({
  queryKey: [{ assetId }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["assetInfo"]>>) => {
  return jsonApi({
    body: {
      type: "asset",
      assetId,
    },
    path: "uai/v1/asset",
    config: {
      method: "post",
    },
    requestSchema: AssetByAssetIdRequest,
    responseSchema: AssetByAssetIdResponse,
  });
};

export const getCNSData = async ({
  queryKey: [{ cloudType, resourceId, baseUrl }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["cnsInfo"]>>) => {
  return jsonApi({
    body: {
      ModelVersion: 1,
      addressMatchCriteria: "NotApplicable",
      alertOn: "None",
      sourceIP: "0.0.0.0/0",
      sourceSelector: {
        ModelVersion: 1,
        resourceType: "Instance",
      },
      effectiveAction: "Allowed",
      name: "RQL search",
      rawRQL: `config from network where dest.resource.type = 'Instance' and dest.cloud.type = '${cloudType?.toUpperCase()}' and dest.instance.id = '${resourceId}' and source.network = '0.0.0.0/0'`,
      destinationSelector: {
        ModelVersion: 1,
        cloudTypes: [cloudType?.toUpperCase()],
        objectIDs: [resourceId],
        resourceType: "Instance",
      },
      type: "NetworkPath",
    },
    path: "cloudgraphs",
    config: {
      method: "post",
    },
    requestSchema: CNSDataRequest,
    responseSchema: CNSDataResponse,
    baseUrl,
  });
};

export const getIAMData = async ({
  queryKey: [{ resourceId }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["iamInfo"]>>) => {
  return jsonApi({
    body: {
      query: `config from iam where source.cloud.resource.name = '${resourceId}'`,
    },
    path: "api/v1/permission/graph/granter_to_dest",
    config: {
      method: "post",
    },
    requestSchema: IAMDataRequest,
    responseSchema: IAMDataResponse,
  });
};

export const alertCSV = async ({
  filters,
  timeRange,
}: {
  filters: AlertsFilterType;
  timeRange: z.infer<typeof TimeRangeSchema>;
}) => {
  return jsonApi({
    body: {
      filters,
      timeRange,
    },
    path: "alert/csv",
    config: {
      method: "post",
    },
    requestSchema: AlertCSVTypeRequest,
    responseSchema: AlertCSVTypeResponse,
  });
};

export const getCSVStatus = async ({ endpoint }: { endpoint: string }) => {
  return jsonApi({
    path: endpoint,
    requestSchema: z.void(),
    responseSchema: CSVTypeResponse,
  });
};

export const getAlertRemediationInfo = async ({
  queryKey: [{ alerts, endpoint, findingId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertRemediationInfo"]>
>) => {
  return jsonApi({
    body: {
      alerts,
      findingId,
      filter: {
        timeRange: {
          type: "to_now",
          value: "epoch",
        },
      },
    },
    path: endpoint,
    config: {
      method: "post",
    },
    requestSchema: AlertRemediationInfoTypeRequest,
    responseSchema: AlertRemediationInfoResponse,
  });
};

export const dismissAlert = async ({
  alerts,
  dismissalNote,
  dismissalTimeRange,
  filter,
  policies,
}: AlertsDismissType) => {
  return jsonApi({
    body: {
      alerts,
      dismissalNote,
      dismissalTimeRange,
      filter,
      policies,
    },
    path: "alert/dismiss",
    config: {
      method: "post",
    },
    requestSchema: AlertDismissTypeRequest,
    responseSchema: z.string(),
  });
};

export const reopenAlert = async ({
  alerts,
  filter,
  policies,
}: AlertReopenType) => {
  return jsonApi({
    body: {
      alerts,
      filter,
      policies,
    },
    path: "alert/reopen",
    config: {
      method: "post",
    },
    requestSchema: AlertReopenTypeRequest,
    responseSchema: z.string().nullish(),
  });
};

export const remediateAlert = async ({
  id,
  findingId,
}: {
  id: string;
  findingId?: string;
}) => {
  return jsonApi({
    path: findingId
      ? `alert/remediation/${id}?findingId=${findingId}`
      : `alert/remediation/${id}`,
    config: {
      method: "PATCH",
    },
    requestSchema: z.void(),
    responseSchema: z.string().nullish(),
  });
};

export const getAlertRemediationCli = async ({
  queryKey: [{ cloudType }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertRemediationCli"]>
>) => {
  return jsonApi({
    path: `alert/remediation/cli/param?custom_policy_supported=true&cloud_type=${cloudType}`,
    requestSchema: z.void(),
    responseSchema: AlertRemediationCliResponse,
  });
};

export const getAlertGraph = ({
  queryKey: [{ alertID }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["graph"]>>) =>
  jsonApi({
    path: `/alert/v1/${alertID}/graph`,
    requestSchema: z.void(),
    responseSchema: AlertGraph,
  });

export const getAlertSummary = async ({
  queryKey: [{ filters, timeRange, swimlane }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["alertSummary"]>>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: `/commandcenter/api/v1/summary/${swimlane}`,
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest.pick({ filters: true, timeRange: true }),
    responseSchema: AlertSummaryResponseSchema,
  });
};

export const getTopAlertPolicies = async ({
  queryKey: [{ filters, timeRange, swimlane }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["topAlertPolicies"]>
>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: `/commandcenter/api/v1/top-policies/${swimlane}`,
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest.pick({ filters: true, timeRange: true }),
    responseSchema: TopAlertPoliciesResponseSchema,
  });
};

export const getTopAlertAssets = async ({
  queryKey: [{ filters, timeRange, swimlane }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["topAlertAssets"]>>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: `/commandcenter/api/v1/top-assets/${swimlane}`,
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest.pick({ filters: true, timeRange: true }),
    responseSchema: TopAlertAssetsResponseSchema,
  });
};

export const getAlertMeanResolutionTime = async ({
  queryKey: [{ filters, timeRange }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertMeanResolutionTime"]>
>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: "/commandcenter/api/v1/metrics/alert-mean-resolution-time",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest.pick({ filters: true, timeRange: true }),
    responseSchema: MeanResolutionTimeResponseSchema,
  });
};

export const getAlertResolutionReason = async ({
  queryKey: [{ filters, timeRange }],
}: QueryFunctionContext<
  ReturnType<(typeof alertsKeys)["alertResolutionReason"]>
>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: "/commandcenter/api/v1/metrics/alert-count-by-resolution-reason",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest.pick({ filters: true, timeRange: true }),
    responseSchema: ResolutionReasonResponseSchema,
  });
};

export const getAlertStatus = async ({
  queryKey: [{ filters, timeRange }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["alertStatus"]>>) => {
  return jsonApi({
    body: { filters, timeRange },
    path: "/alert/v1/alert-aggregate/status",
    config: {
      method: "post",
    },
    requestSchema: GetAlertsRequest.pick({ filters: true, timeRange: true }),
    responseSchema: StatusResponseSchema,
  });
};

export const createPullRequest = async ({
  resourceId,
  accountId,
  prismaId,
  policyId,
}: {
  resourceId: string;
  accountId: string;
  prismaId: string;
  policyId: string;
}) => {
  return jsonApi({
    body: {
      resourceId,
      accountId,
      prismaId,
      policyId,
    },
    path: "/bridgecrew/api/v1/pc-alerts-receiver/fix",
    config: {
      method: "POST",
    },
    requestSchema: CreatePRSchema,
    responseSchema: z.string().nullish(),
  });
};

export const searchAlert = ({
  queryKey: [{ alertId }],
}: QueryFunctionContext<ReturnType<(typeof alertsKeys)["searchAlert"]>>) => {
  return jsonApi({
    path: alertId.startsWith("I-") ? SEARCH_IAM_ALERT : "/search/alert",
    params: { alertId },
    requestSchema: z.void(),
    responseSchema: SearchAlertResponseSchema,
  });
};
