import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";

type ServiceApiKeyProps = {
  isEdit: boolean;
};
export default function ServiceApiKey({ isEdit }: ServiceApiKeyProps) {
  const intl = useIntl();
  useTrimField("serviceApiKey");

  return (
    <Input
      type="password"
      data-selector="ibm-service-api-key-input"
      name="serviceApiKey"
      autoComplete="new-password"
      defaultValue={isEdit ? "********" : ""} //display 8 dots in serviceApiKey field for EDIT mode as BE doesnt send this data in get details call
      label={intl.formatMessage({
        defaultMessage: "Service API Key",
        id: "gwFJlA",
        description: "The name of an input field for onboarding an IBM account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "Service API Key",
        id: "6EJqI1",
        description: "Placeholder for Account Service API Key",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Service API Key is required",
          id: "ye/6we",
          description: "Error message for field for onboarding an IBM account",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage: "API key associated with the IBM Service ID",
        id: "ByaJuN",
        description:
          "Tooltip for a display field for onboarding an IBM account",
      })}
    />
  );
}
