import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsProvidersRoute } from "../providers/settingsProvidersRoute";
const CasProviders = lazy(() => import("./CasProviders"));

export const settingsPrivateRegistriesRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "/private-registries",
  component: CasProviders,
});

export const settingsPrivateRegistriesNestedRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "$",
  component: CasProviders,
});
