import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { trustedIpAddressesRoute } from "../trustedIpAddressesRoute";

export const TrustedIpLogin = lazy(() => import("./TrustedIpLogin"));

export const trustedIpLoginRoute = new Route({
  getParentRoute: () => trustedIpAddressesRoute,
  path: "login",
  component: TrustedIpLogin,
});
