import { type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { sortBy } from "remeda";
import { Bold, Tag } from "ui";

type DeploymentTypeValuesProps = {
  deploymentType: string;
  displayName: string;
}[];

export function useGetAlibabaDeploymentType(
  deploymentTypeValues: DeploymentTypeValuesProps,
) {
  const intl = useIntl();

  const deploymentTypeItems: {
    key: string;
    title: string;
    value: string;
    description: ReactNode;
    tag?: ReactNode;
  }[] = [];

  sortBy(deploymentTypeValues, (item) => item.displayName).forEach(
    ({ deploymentType }) => {
      if (deploymentType === "ali-fn") {
        deploymentTypeItems.push({
          key: deploymentType,
          title: intl.formatMessage({
            defaultMessage: "Finance",
            id: "71cVsU",
            description: "Description for alibaba finance deployment type",
          }),
          value: deploymentType,
          description: (
            <span className="text-xs">
              <FormattedMessage
                defaultMessage="Secure your specific Alibaba Subscriptions"
                id="IuaDV+"
                description="Description for deployment type for onboarding an Alibaba account"
              />
            </span>
          ),
        });
      } else if (deploymentType === "ali-cn") {
        deploymentTypeItems.push({
          key: "ali-cn",
          title: intl.formatMessage({
            defaultMessage: "Public",
            id: "lqhOyN",
            description: "Description for alibaba public deployment type",
          }),
          value: deploymentType,
          description: (
            <span className="text-xs">
              <FormattedMessage
                defaultMessage="Secure all Management Groups and Active Directory"
                id="eypR/o"
                description="Description for deployment type for onboarding an Alibaba account"
              />
            </span>
          ),
          tag: (
            <Bold>
              <Tag>
                <FormattedMessage
                  defaultMessage="Recommended"
                  id="pnEgtL"
                  description="Description for Recommended label"
                />
              </Tag>
            </Bold>
          ),
        });
      }
    },
  );
  return deploymentTypeItems;
}
