import { type QueryFunctionContext } from "@tanstack/react-query";
import { type z } from "zod";
import { getAccountGroupNames } from "../accountGroupName";
import {
  getAmazonS3Integration,
  getAmazonSQSIntegration,
  getAwsSDLIntegration,
  getAWSSecurityHubIntegration,
  getAzureServiceBusIntegration,
  getCortexXSOARIntegration,
  getGoogleCSCCIntegration,
  getMicrosoftTeamsIntegration,
  getPagerDutyIntegration,
  getSlackIntegration,
  getSnowflakeIntegration,
  getSplunkIntegration,
  getWebhookIntegration,
} from "../alertRule";
import {
  getCloudAccountIds,
  type CloudAccountIdResponseSchema,
} from "../cloudAccountId";
import {
  getCloudAccountNames,
  type CloudAccountNameResponseSchema,
  type CloudAccountNameWithOtherResponseSchema,
} from "../cloudAccountName";
import {
  getCloudRegions,
  type CloudRegionWithOtherResponseSchema,
} from "../cloudRegion";
import {
  getCloudTypes,
  type CloudTypeResponseSchema,
  type CloudTypeWithOtherResponseSchema,
} from "../cloudType";
import { getComplianceStandards } from "../compliance";
import { getNotificationTemplates } from "../notificationTemplate";
import { getPermissionGroups } from "../permissionGroups";
import { getResourceListByType } from "../resourceList";
import { getUserNames } from "../userNames";
import { type listItemKeys } from "./listItemKeys";

export const getCloudTypeListItems = async (
  props: QueryFunctionContext<typeof listItemKeys.cloudTypes>,
) => {
  return getCloudTypes(props) as Promise<
    z.infer<typeof CloudTypeResponseSchema>
  >;
};

export const getCloudTypeListItemsWithOther = async (
  props: QueryFunctionContext<typeof listItemKeys.cloudTypesWithOther>,
) => {
  return getCloudTypes(props) as Promise<
    z.infer<typeof CloudTypeWithOtherResponseSchema>
  >;
};

export const getAccountGroupNameListItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.accountGroupNames>
  >,
) => {
  return getAccountGroupNames(props);
};

export const getCloudAccountNameListItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.cloudAccountNames>
  >,
) => {
  return getCloudAccountNames(props) as Promise<
    z.infer<typeof CloudAccountNameResponseSchema>
  >;
};

export const getCloudAccountNameListItemsWithOther = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.cloudAccountNamesWithOther>
  >,
) => {
  return getCloudAccountNames(props) as Promise<
    z.infer<typeof CloudAccountNameWithOtherResponseSchema>
  >;
};

export const getCloudAccountIdListItems = async (
  props: QueryFunctionContext<ReturnType<typeof listItemKeys.cloudAccountIds>>,
) => {
  return getCloudAccountIds(props) as Promise<
    z.infer<typeof CloudAccountIdResponseSchema>
  >;
};

export const getCloudRegionListItems = async (
  props: QueryFunctionContext<typeof listItemKeys.cloudRegions>,
) => {
  return getCloudRegions(props);
};

export const getCloudRegionListItemsWithOther = async (
  props: QueryFunctionContext<typeof listItemKeys.cloudRegionsWithOther>,
) => {
  return getCloudRegions(props) as Promise<
    z.infer<typeof CloudRegionWithOtherResponseSchema>
  >;
};

export const getComplianceStandardListItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.complianceStandards>
  >,
) => {
  return getComplianceStandards(props);
};

export const getUserNameItems = async (
  props: QueryFunctionContext<typeof listItemKeys.userNames>,
) => {
  return getUserNames(props);
};

export const getUserNameSSOItems = async (
  props: QueryFunctionContext<typeof listItemKeys.userNamesSSO>,
) => {
  return getUserNames(props);
};

export const getAmazonSQSIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.amazonSQSIntegrations>
  >,
) => {
  return getAmazonSQSIntegration(props);
};

export const getAmazonS3IntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.amazonS3Integrations>
  >,
) => {
  return getAmazonS3Integration(props);
};

export const getAwsSDLIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.awsSDLIntegrations>
  >,
) => {
  return getAwsSDLIntegration(props);
};

export const getAWSSecurityHubIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.awsSecurityHubIntegrations>
  >,
) => {
  return getAWSSecurityHubIntegration(props);
};

export const getAzureServiceBusQueueIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.azureServiceBusQueueIntegrations>
  >,
) => {
  return getAzureServiceBusIntegration(props);
};

export const getCortexXSOARIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.cortexXSOARIntegrations>
  >,
) => {
  return getCortexXSOARIntegration(props);
};

export const getGoogleCSCCIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.googleCSCCIntegrations>
  >,
) => {
  return getGoogleCSCCIntegration(props);
};

export const getMicrosoftTeamsIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.microsoftTeamsIntegrations>
  >,
) => {
  return getMicrosoftTeamsIntegration(props);
};

export const getPagerDutyIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.pagerDutyIntegrations>
  >,
) => {
  return getPagerDutyIntegration(props);
};

export const getSlackIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.slackIntegrations>
  >,
) => {
  return getSlackIntegration(props);
};

export const getSnowflakeIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.snowflakeIntegrations>
  >,
) => {
  return getSnowflakeIntegration(props);
};

export const getSplunkIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.splunkIntegrations>
  >,
) => {
  return getSplunkIntegration(props);
};

export const getWebhookIntegrationItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.webhookIntegrations>
  >,
) => {
  return getWebhookIntegration(props);
};

export const getResourceListTagItems = async (
  props: QueryFunctionContext<ReturnType<typeof listItemKeys.tagResourceList>>,
) => {
  return getResourceListByType(props);
};

export const getTemplateListItems = async (
  props: QueryFunctionContext<ReturnType<typeof listItemKeys.template>>,
) => {
  return getNotificationTemplates(props);
};

export const getResourceListComputeItems = async (
  props: QueryFunctionContext<
    ReturnType<typeof listItemKeys.computeAccessGroupResourceList>
  >,
) => {
  return getResourceListByType(props);
};

export const getPermissionGroupListItems = async (
  props: QueryFunctionContext<ReturnType<typeof listItemKeys.permissionGroups>>,
) => {
  return getPermissionGroups(props);
};
