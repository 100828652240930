import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { LoadingIconOnFilterBackground } from "ui";
import { alertsOverviewRoute } from "./alertsOverviewRoute";

export const RouteBasedPolicyModalInAlerts = lazy(
  () => import("./RouteBasedPolicyModalInAlerts"),
);

export const policyWizardInAlertsRoute = new Route({
  getParentRoute: () => alertsOverviewRoute,
  path: "$policyId/edit",
  component: RouteBasedPolicyModalInAlerts,
  pendingComponent: LoadingIconOnFilterBackground,
});
