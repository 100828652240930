import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { cloudAccountsKeys, deleteCloudAccount } from "requests";
import { useToastActions } from "stores";
import { Button, ConfirmationModal, Popover } from "ui";

import { MoreIcon } from "icons";
import { useState } from "react";
import { ACCOUNT } from "../../../constants";
import { type CloudType } from "../../../types";

type DeleteAccountProps = {
  accountId: string;
  accountType: string;
  cloudType: CloudType;
  setCloseModal: () => void;
};

export default function DeleteAccount({
  accountId,
  accountType,
  cloudType,
  setCloseModal,
}: DeleteAccountProps) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();

  const [isDeleteActionOpen, setIsDeleteActionOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      try {
        setIsConfirmationModalOpen(false);
        await deleteCloudAccount({ accountId, cloudType });
        toast(
          intl.formatMessage({
            defaultMessage:
              "The account and associated data will be deleted in 24 hours. If you accidentally deleted the account, you can add it back within 24 hours and have all the associated data restored.",
            id: "ReVwKg",
            description: "success toast",
          }),
          { appearance: "success" },
        );
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });
      } catch (error) {
        toast(
          intl.formatMessage({
            defaultMessage:
              "Deletion failed due to an unknown error. Please contact your Prisma Cloud support team.",
            id: "6QnFmD",
            description: "warning toast",
          }),
          { appearance: "warning" },
        );
      } finally {
        setCloseModal();
      }
    },
  });

  return (
    <>
      <Popover
        button={
          <Button
            data-testid="more-actions-button"
            appearance="tertiary-clear"
            icon={<MoreIcon />}
          />
        }
        isOpen={isDeleteActionOpen}
        label={intl.formatMessage({
          defaultMessage: "Delete Action",
          id: "MEErNW",
          description: "Label for delete action",
        })}
        showLabel={false}
        onIsOpenChange={(next: boolean) => setIsDeleteActionOpen(next)}
      >
        <Button
          appearance="danger"
          onClick={() => {
            setIsDeleteActionOpen(false);
            setIsConfirmationModalOpen(true);
          }}
          data-testid="cloud-accounts-delete-cloud-account"
        >
          <FormattedMessage
            defaultMessage="Delete Account"
            id="GH8x9t"
            description="delete Cloud Account button"
          />
        </Button>
      </Popover>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        closeModal={() => setIsConfirmationModalOpen(false)}
        onConfirm={() => mutateAsync()}
        prompt={
          accountType === ACCOUNT
            ? intl.formatMessage({
                defaultMessage: "Are you sure you want to delete this account?",
                id: "8PK7M8",
                description: "Delete confirmation message",
              })
            : intl.formatMessage({
                defaultMessage:
                  "Warning: Deleting the Parent Account will also delete all the associated Child Accounts. Do you want to proceed?",
                id: "hXBAte",
                description: "Delete confirmation message for Org account",
              })
        }
        title={intl.formatMessage({
          defaultMessage: "Confirmation",
          id: "RBzLhc",
          description: "Heading of modal to confirm deletion of cloud account",
        })}
        isDelete={true}
        confirmationBtnText={intl.formatMessage({
          defaultMessage: "Delete Account",
          id: "nQ+wp0",
          description: "Confirmation button text for deleting an Account",
        })}
      />
    </>
  );
}
