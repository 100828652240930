import { Route } from "@tanstack/react-router";
import { z } from "zod";
import { settingsCloudAccountsRoute } from "../../settingsCloudAccountsRoute";
import EditModalOpener from "./EditModalOpener";

export const settingsCloudAccountsEditModalRoute = new Route({
  getParentRoute: () => settingsCloudAccountsRoute,
  path: "/$cloudAccountId/$cloudType/edit",
  component: EditModalOpener,
  validateSearch: z.object({
    tempAccount: z.boolean().optional(),
  }),
});
