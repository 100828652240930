import { isChina } from "environment";
import { defineMessages, type IntlShape } from "react-intl";
import {
  downloadFileRequest,
  type OnboardingStatusResponseSchema,
  type SecurityCapabilitiesType,
} from "requests";
import { type BannerAppearance } from "ui";
import { downloadFile, regexes } from "utils";
import { type WizardStepState } from "../../../components/Wizard";
import {
  IBM,
  IDENTITY_SECURITY,
  MISCONFIGURATIONS,
  OCI,
  ORGANIZATION,
  TENANT,
  THREAT_DETECTION,
} from "../../constants";
import { type CloudType } from "../../types";

export function accountGroupsMultiSelect({
  accountType,
  isMsa = false,
  isProject = false,
  selectedCloud,
}: {
  accountType: string;
  isMsa?: boolean;
  isProject?: boolean;
  selectedCloud: string;
}) {
  if (accountType === TENANT) {
    return false;
  }

  if (isMsa || selectedCloud === OCI) {
    return false;
  } else if (isProject || accountType !== ORGANIZATION) {
    return true;
  } else return false;
}

export const getWizardStepIndex =
  (steps: WizardStepState[]) => (key: string) => {
    return steps.findIndex((step) => step.key === key);
  };

export const validatorName = (intl: IntlShape, value: string) => {
  if (value.trim().toLowerCase() === "none") {
    return intl.formatMessage({
      defaultMessage: "Account Name cannot be NONE",
      id: "Z+p/fh",
      description: "Validation message for cloud account name when it is none",
    });
  }

  if (!regexes.onlySpacesInInputField.test(value)) {
    return intl.formatMessage({
      defaultMessage: "Please enter valid Account Name",
      id: "NLaVpf",
      description: "Error message for field for onboarding an AWS account",
    });
  }
  return;
};

export const getValidateAccountName = (intl: IntlShape) => (value: string) =>
  validatorName(intl, value);

export const getValidateAwsAccountName =
  (intl: IntlShape) => (value: string) => {
    if (!regexes.awsAccountName.test(value)) {
      return intl.formatMessage({
        defaultMessage: "Please enter valid Account Name",
        id: "NLaVpf",
        description: "Error message for field for onboarding an AWS account",
      });
    }
    return validatorName(intl, value);
  };

export const getValidateAccountId =
  (intl: IntlShape, cloudType?: CloudType) => (value: string) => {
    const regexp =
      cloudType === IBM ? regexes.ibmAccountId : regexes.awsAccountId;
    if (!regexp.test(value)) {
      return intl.formatMessage({
        defaultMessage: "Please enter valid Account ID",
        id: "jzAzP6",
        description: "Error message for field for onboarding an AWS account",
      });
    }
    return;
  };

export const getValidateRoleARN = (intl: IntlShape) => (value: string) => {
  if (
    !(
      regexes.awsIamRoleArn.test(value) ||
      regexes.awsIamRoleArnGov.test(value) ||
      regexes.awsIamRoleArnChina.test(value)
    )
  ) {
    return intl.formatMessage({
      defaultMessage: "Please enter valid Role ARN",
      id: "q/Rxq4",
      description: "Error message for field for onboarding an AWS account",
    });
  }
  return;
};

export function validateMemberRoleName(value: string, intl: IntlShape) {
  if (!regexes.memberRoleName.test(value)) {
    return intl.formatMessage({
      defaultMessage: "Please enter valid Member IAM Role Name",
      id: "pzFQHN",
      description: "Error message for field for onboarding an AWS account",
    });
  }
  return;
}

const validateRoleNamePresent = (
  roleArnValue: string,
  accountId: string,
  intl: IntlShape,
) => {
  if (!roleArnValue.includes(accountId)) {
    return intl.formatMessage({
      defaultMessage: "Account ID should match the id in Role ARN",
      id: "2IbbUl",
      description:
        "Validation message for Role ARN when Account ID is not present in Role ARN",
    });
  }
  return;
};

export const validateRoleArn =
  (accountId: string, intl: IntlShape) => (value: string) => {
    const validations = [getValidateRoleARN(intl), validateRoleNamePresent];
    const errors = validations
      .map((validation) => validation(value, accountId, intl))
      .filter((message) => !!message);

    if (errors.length) return errors.join(", ");
    return;
  };

export async function fetchAndDownloadFile({
  endPoint,
  fileName = "terraform",
  payload = {},
  method = "POST",
}: {
  endPoint: string;
  fileName?: string;
  payload?: Record<string, unknown>;
  method?: string;
}) {
  const config: Record<string, unknown> = {
    headers: {
      "Content-Type": "application/json",
    },
    method,
  };

  if (method?.toLowerCase() === "post") {
    config.body = JSON.stringify(payload);
  }

  const response = await downloadFileRequest({
    path: endPoint,
    config,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const file = await response.blob();
  downloadFile({ fileName, file });

  return response;
}

export const getValidateAlibabaRamARN =
  (intl: IntlShape) => (value: string) => {
    if (!regexes.alibabaRamArn.test(value)) {
      return intl.formatMessage({
        defaultMessage: "Please enter valid RAM Role",
        id: "EkimLF",
        description: "Error message for field when RAM Role is not valid",
      });
    }
    return;
  };

export const getValidateOCID = (intl: IntlShape) => (value: string) => {
  if (!regexes.oracleTenantOCID.test(value)) {
    return intl.formatMessage({
      defaultMessage: "Please enter a valid Tenant OCID",
      id: "DfDUkF",
      description: "Error message for field for onboarding an OCI account",
    });
  }
  return;
};

export const getValidateUserOCID = (intl: IntlShape) => (value: string) => {
  if (!regexes.oracleUserOCID.test(value)) {
    return intl.formatMessage({
      defaultMessage: "Please enter a valid User OCID",
      id: "HPL+GN",
      description: "Error message for field for onboarding an OCI account",
    });
  }
  return;
};

export const getSecurityCapabilities = (
  securityCapabilities: Record<SecurityCapabilitiesType, boolean>,
  supportedFeatures: SecurityCapabilitiesType[],
  isIamSubscribed: boolean,
  isMSA = false,
) => {
  const securityCapabilitisFeatures = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeatures.includes(feature as SecurityCapabilitiesType),
    );

  let defaultFeatures = supportedFeatures.filter((feature) =>
    [THREAT_DETECTION, IDENTITY_SECURITY, MISCONFIGURATIONS].includes(feature),
  );

  if (!isIamSubscribed) {
    defaultFeatures = defaultFeatures.filter(
      (feature) => feature !== IDENTITY_SECURITY,
    );
  }

  // GCP Master Service Account don't have Identity Security
  if (isMSA) {
    defaultFeatures = defaultFeatures.filter(
      (feature) => feature !== IDENTITY_SECURITY,
    );
  }

  return [
    ...defaultFeatures,
    ...securityCapabilitisFeatures,
  ] as SecurityCapabilitiesType[];
};

export const getAuthenticationData = (
  accountStatus: OnboardingStatusResponseSchema = [],
) =>
  accountStatus
    ?.filter(({ name }) => name.toLowerCase() === "authentication")
    ?.map((status) => ({ ...status, message: status?.statusMessage?.message }))
    ?.shift();

export const handleSuccess = (isEdit: boolean, intl: IntlShape) => {
  if (isEdit) {
    return intl.formatMessage({
      defaultMessage: "You successfully updated this cloud account.",
      id: "NM4fob",
      description: "Success message to save AWS Onboarding",
    });
  } else {
    return intl.formatMessage({
      defaultMessage: "You successfully completed the onboarding process!",
      id: "e0sVcQ",
      description: "Success message to save AWS Onboarding",
    });
  }
};
export function getAccountIdFromRoleArn(roleArn: string) {
  try {
    if (isChina() || roleArn.includes("arn:aws-cn:iam")) {
      return roleArn.split("arn:aws-cn:iam::")[1]?.split(":")[0];
    } else if (roleArn.includes("arn:aws-us-gov:iam::")) {
      return roleArn.split("arn:aws-us-gov:iam::")[1]?.split(":")[0];
    } else {
      return roleArn.split("arn:aws:iam::")[1]?.split(":")[0];
    }
  } catch (error) {
    return null;
  }
}

export const getOUPreviouslySelectedNodesErrorMessage = ({
  data = [],
  intl,
}: {
  data: { resourceId: string; status: string }[];
  intl: IntlShape;
}) => {
  const messageMap = defineMessages({
    internal_error: {
      defaultMessage: "Internal Error",
      id: "iRvMPt",
      description: "Internal error",
    },
  });

  return data.map(({ resourceId, status }) => ({
    description: intl.formatMessage(
      {
        defaultMessage:
          "{resourceId} was previously selected, but could not be loaded. Reason: {status}",
        id: "tfuR3Q",
        description: "The number of policies",
      },
      {
        resourceId,
        status: messageMap[status as keyof typeof messageMap]
          ? intl.formatMessage(messageMap[status as keyof typeof messageMap])
          : status,
      },
    ),
    errorType: "warning" as BannerAppearance,
  }));
};
