import { z } from "zod";

export type ResourceListType = z.infer<typeof ResourceListSchema>;

const ResourceListSchema = z.enum([
  "TAG",
  "STRING",
  "RESOURCE_GROUP",
  "IP_ADDRESS",
  "COMPUTE_ACCESS_GROUP",
  "NUMBER",
  "RESOURCE_ID",
]);

export type ResourceList = z.infer<typeof ResourceList>;

export const ResourceList = z.object({
  id: z.string().uuid(),
  name: z.string(),
  resourceListType: ResourceListSchema,
  description: z.string().nullish().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedTs: z.number().optional(),
  members: z
    .array(
      z.union([
        z.record(z.string(), z.string().nullish()),
        z.string(),
        z.number(),
        z.record(z.string(), z.array(z.string()).nullish()),
      ]),
    )
    .optional(),
});

export const ResourceListRequest = z.object({
  description: z.string().nullish(),
  members: z.union([
    z.array(z.record(z.string(), z.union([z.string(), z.array(z.string())]))),
    z.array(z.string()),
  ]),
  name: z.string(),
  resourceListType: z.string(),
});
