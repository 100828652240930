import { useIntl } from "react-intl";
import { classNames } from "utils";

import { CaretDownIcon } from "../icons/CaretDownIcon";
import { CautionIcon } from "../icons/CautionIcon";
import { CircleCheckIcon } from "../icons/CircleCheckIcon";
import { CircleXmarkIcon } from "../icons/CircleXmarkIcon";
import { InfoCircleIcon } from "../icons/InfoCircleIcon";
import { SpinnerIcon } from "../icons/SpinnerIcon";
import { type SvgIconProps } from "./SvgIcon";
/**
 * TODO: Do we even need semantic icons?
 * TODO Move these to icon package
 * TODO finish converting.
 *
/**
 * The semantic icons take the same props as `FaIcon`, filling in various default values.
 */
export const DangerIcon = ({ className, ...iconProps }: SvgIconProps) => {
  const intl = useIntl();
  return (
    <InfoCircleIcon
      aria-label={intl.formatMessage({
        defaultMessage: "Danger",
        id: "rVKYSV",
        description: "Danger Icon label",
      })}
      {...iconProps}
      className={classNames(className, "text-risk-fail dark:text-icon-red")}
    />
  );
};

export const ErrorIcon = ({
  className,
  size = "md",
  ...iconProps
}: SvgIconProps) => {
  const intl = useIntl();
  return (
    <CircleXmarkIcon
      aria-label={intl.formatMessage({
        defaultMessage: "Error",
        id: "9M0c6R",
        description: "Error Icon label",
      })}
      size={size}
      {...iconProps}
      className={classNames(className, "text-risk-fail dark:text-icon-red")}
    />
  );
};

export const InfoIcon = ({ className, ...iconProps }: SvgIconProps) => {
  const intl = useIntl();

  return (
    <InfoCircleIcon
      aria-label={intl.formatMessage({
        defaultMessage: "More info",
        id: "PVaBXM",
        description: "More info Icon label",
      })}
      {...iconProps}
      className={classNames(className, "text-link dark:text-icon-blue")}
    />
  );
};

export const SuccessIcon = ({ className, ...iconProps }: SvgIconProps) => {
  const intl = useIntl();
  return (
    <CircleCheckIcon
      aria-label={intl.formatMessage({
        defaultMessage: "Success",
        id: "a6vCkm",
        description: "Success Icon label",
      })}
      {...iconProps}
      className={classNames(className, "text-risk-2 dark:text-icon-green")}
    />
  );
};

export const WarningIcon = ({ className, ...iconProps }: SvgIconProps) => {
  const intl = useIntl();
  return (
    <CautionIcon
      aria-label={intl.formatMessage({
        defaultMessage: "Warning",
        id: "2NNMLm",
        description: "Warning Icon label",
      })}
      {...iconProps}
      className={classNames(className, "text-risk-4")}
    />
  );
};

export const LoadingIcon = (iconProps: SvgIconProps) => {
  const intl = useIntl();
  return (
    <SpinnerIcon
      aria-label={intl.formatMessage({
        defaultMessage: "Loading",
        id: "rVslWc",
        description: "Loading Icon label",
      })}
      {...iconProps}
    />
  );
};

export const InfoTooltipIcon = ({ className, ...iconProps }: SvgIconProps) => {
  return (
    <InfoIcon
      {...iconProps}
      className={classNames(className, "text-disabled")}
    />
  );
};

export interface DropdownIconProps extends SvgIconProps {
  isOpen?: boolean;
}

export const DropdownIcon = ({
  className,
  isOpen,
  ...iconProps
}: DropdownIconProps) => {
  return (
    <CaretDownIcon
      {...iconProps}
      className={classNames(
        "motion-safe:transition-transform motion-safe:duration-200",
        isOpen && "rotate-180",
        className,
      )}
    />
  );
};
