import { useEffect, useRef } from "react";

import { isDevelopment } from "environment";
import { type MutableRefObject } from "react";

/**
 * Returns a ref that indicates if the component is mounted or not.
 */
export function useIsMounted(): MutableRefObject<boolean> {
  const isMounted = useRef(true);

  useEffect(() => {
    // https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
    let react18StrictModeUnmountingOnFirstMountInDev = isDevelopment();
    return () => {
      if (!react18StrictModeUnmountingOnFirstMountInDev) {
        isMounted.current = false;
      }
      react18StrictModeUnmountingOnFirstMountInDev = false;
    };
  }, []);

  return isMounted;
}
