import { type ReactElement, type ReactNode } from "react";

import { cloneElement } from "react";
import { classNames } from "utils";
import { useTitle } from "../../utils";
import { ErrorBoundary } from "../ErrorBoundary";
import { PermissionDenied } from "../PermissionDenied";
import { LoadingPage } from "./LoadingPage";

export type PageLayoutProps = {
  /**
   * The main body of the page layout.
   */
  children: ReactNode;
  /**
   * A title which will be set before documentTitle, projectNameTitle | documentTitle.
   */
  projectNameTitle?: string;
  /**
   * A title to set on document, gets passed to `useTitle`.
   */
  documentTitle: Parameters<typeof useTitle>[0];
  /**
   * Page header displays the top level page content.
   * This is generally the PageHeader component.
   */
  header: ReactElement;
  /**
   * Render a loading spinner in the body.
   */
  isLoading?: boolean;
  /**
   * This is generally the TabList component.
   *
   * If you have a tabList, you likely should not pass the filterBar prop.
   * Instead, use the TabPanelLayout and pass it's filterBar prop.
   */
  tabList?: ReactNode;
  /**
   * The Filter Bar from react-filters
   *
   * If you have a tabList, you likely should not pass the filterBar prop.
   * Instead, use the TabPanelLayout and pass it's filterBar prop.
   */
  filterBar?: ReactNode;
  /**
   * The CuratedViewWarning from saved-views
   */
  savedViewWarning?: ReactNode;
  /**
   * Don't add any padding to the page body.
   */
  noPadding?: boolean;
  /**
   * Enable overflow-auto on the main page content so it can be made scrollable.
   *
   * This is required if the content is a table where overflow is true on the
   * TableLayout
   */
  overflow?: boolean;
  /**
   * Whether the user has permission to see this page. Will hide the children & filter bar if not allowed.
   */
  allowed?: boolean;
};
/**
 * Helps layout controls of a page.
 * Will handle spacing of common page elements.
 */
export function PageLayout({
  children,
  projectNameTitle = "Prisma Cloud",
  documentTitle,
  header,
  isLoading = false,
  tabList,
  savedViewWarning,
  filterBar,
  noPadding = false,
  overflow = false,
  allowed = true,
}: PageLayoutProps): ReactElement {
  const title = [projectNameTitle, ...[documentTitle].flat()];
  useTitle(title);
  const headerClasses = classNames(
    "border-b-[1px] bg-white dark:border-blue-steel-850 dark:bg-blue-steel-990",
    !tabList && "border-b border-gray-300 dark:border-blue-steel-850",
  );
  const mainContentClasses = classNames(
    "flex grow flex-col bg-white dark:bg-blue-steel-950",
    !noPadding && "p-4",
    overflow && "overflow-auto print:overflow-visible",
  );

  const clonedHeader = cloneElement(header, { tabList: tabList });

  return (
    <ErrorBoundary>
      <div
        className={classNames(
          "flex min-w-0 grow flex-col",
          overflow && "overflow-auto print:overflow-visible",
        )}
      >
        <div className={headerClasses}>{clonedHeader}</div>
        {savedViewWarning && savedViewWarning}
        {filterBar && filterBar}
        <div
          className={mainContentClasses}
          style={{ background: "transparent" }}
        >
          {isLoading ? (
            <LoadingPage />
          ) : allowed ? (
            children
          ) : (
            <PermissionDenied />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}
