import { type ReactElement, type ReactNode } from "react";
import { TruncateText } from "ui";

export interface TruncateCellProps {
  value?: ReactNode;
}

/**
 * Truncates the cell's text to a single line, if it overflows the width.
 * Adds a tooltip, if and only if truncation occurs.
 */
export function TruncateCell({
  value,
}: TruncateCellProps): ReactElement | null {
  if (!value) {
    return null;
  }

  return (
    <TruncateText>
      <span className="text-xs text-default dark:text-dark-bg">{value}</span>
    </TruncateText>
  );
}
