import { type ReactElement } from "react";
import { get, useFormContext, type RegisterOptions } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  Field,
  Checkbox as ReactCheckbox,
  type FieldProps,
  type CheckboxProps as ReactCheckboxProps,
} from "ui";

import { requiredMessage } from "../../messages";

export type CheckboxProps = {
  /**
   * The name the checkbox values will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Use to control displaying of the label
   */
  showLabel?: boolean;
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  /**
   * A default value for the Checkbox
   */
  defaultValue?: boolean;
} & Omit<FieldProps, "error" | "control" | "tooltip"> &
  Omit<
    ReactCheckboxProps,
    "checked" | "indeterminate" | "label" | "defaultValue"
  >;

/**
 * Creates a checkbox and lets the user toggle a boolean value.
 */
export const Checkbox = (props: CheckboxProps): ReactElement | null => {
  const {
    label,
    name,
    register: registerOptions = {},
    disabled,
    showLabel = true,
    defaultValue = false,
    description,
    ...rest
  } = props;
  const intl = useIntl();
  const context = useFormContext();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render Checkbox outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState, register } = context;
  if (registerOptions.required === true) {
    registerOptions.required = intl.formatMessage(requiredMessage, {
      label,
    });
  }
  const { onBlur, onChange, ...formRegister } = register(name, registerOptions);

  return (
    <Field
      label=""
      control={
        <ReactCheckbox
          defaultChecked={defaultValue}
          disabled={disabled}
          {...formRegister}
          {...rest}
          onBlur={(event) => {
            onBlur(event);
            rest.onBlur?.(event);
          }}
          onChange={(event) => {
            onChange(event);
            rest.onChange?.(event);
          }}
        >
          <span className={showLabel ? "" : "sr-only"}>{label}</span>
        </ReactCheckbox>
      }
      error={get(formState.errors, name)?.message}
      description={description}
      disabled={disabled}
      connectLabel={false}
    />
  );
};
