import { DownloadIcon } from "icons";
import { useFileDownloader } from "prisma";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "ui";

export function DownloadNetworkACLScript({
  accountId,
  forCloudAccount = true,
}: {
  accountId?: string;
  forCloudAccount?: boolean;
}) {
  const downloadFile = useFileDownloader();
  const aclScriptApi = forCloudAccount
    ? `pcds/config/v3/account/${accountId}/acl-script`
    : "pcds/config/v3/tenant/acl-script";

  const onClick = useCallback(() => {
    downloadFile({
      downloadEndpoint: aclScriptApi,
      downloadRequestOptions: {
        headers: {
          "content-type": "application/octet-stream",
        },
        method: "GET",
      },
      fileName: "pcds_azure_network_acl_config.sh",
    });
  }, [aclScriptApi, downloadFile]);

  return (
    <div className="flex-col">
      <FormattedMessage
        defaultMessage="To fix the error Download Script below and run the script in Azure Bash"
        id="S9SiiB"
        description="Label in the recommendation section for Download Script to fix Network ACL."
        tagName="div"
      />
      <div
        className={forCloudAccount ? "mt-4 flex justify-center" : "mt-4 flex"}
      >
        <Button
          icon={<DownloadIcon />}
          appearance="secondary"
          onClick={() => onClick()}
        >
          <FormattedMessage
            defaultMessage="Download Script"
            id="aXL+66"
            description="Label for the button to download script to fix Network ACL issue."
          />
        </Button>
      </div>
    </div>
  );
}
