import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { CheckboxGroup } from "form";

import { isEmpty, isNil } from "remeda";
import { useIsBridgeCrewEnabled, useIsSystemAdmin } from "requests";
import { type InitialValues, type RoleTypes } from "../roleTypes";
import AccountGroupsField from "./AccountGroupsField";
import DisabledRepositoriesField from "./DisabledRepositoriesField";
import RepositoriesField from "./RepositoriesField";
import ResourceListField from "./ResourceListField";
import {
  BUILD_AND_DEPLOY_SECURITY,
  DEVELOPER,
  HAS_ADVANCED_OPTIONS_ROLES,
  RESTRICT_HAS_DEFENDER_PERMISSIONS,
  SYSTEM_AUDITOR,
  getAdditionalAttributesList,
} from "./utils";

export function DynamicFields({
  initialValues,
  roleTypes,
}: {
  initialValues?: InitialValues;
  roleTypes: RoleTypes[];
}) {
  const intl = useIntl();

  // This is being used while BC has yet to implement GRBAC. Once they do, we will need to replace this check
  // with one on the actual current user permissions.
  const isSystemAdmin = useIsSystemAdmin();
  const bridgecrewEnabled = useIsBridgeCrewEnabled();

  const { watch } = useFormContext();
  const roleType = watch("roleType", {
    value: initialValues?.roleType || "",
    context: {},
  });
  const onlyAllowCIAccess = watch("additionalAttributes.onlyAllowCIAccess");
  if (
    roleType?.value &&
    (isNil(roleType.context) || isEmpty(roleType.context))
  ) {
    roleType.context = {
      ...roleTypes.find((role: { value: string }) => {
        return roleType.value === role.value;
      })?.context,
    };
  }

  const computeAllowed = roleType?.context?.features?.some(
    (feature: { featureName: string; operations: { READ: boolean } }) => {
      return feature.featureName.match(/^compute/) && feature.operations.READ;
    },
  );

  const roleTypeContext = roleType?.context;

  const role = roleType?.value;

  const additionalAttributes = initialValues
    ? {
        ...(initialValues?.additionalAttributes ?? {}),
        restrictDismissalAccess: !!initialValues?.restrictDismissalAccess,
      }
    : undefined;
  const hasAdvancedOption =
    HAS_ADVANCED_OPTIONS_ROLES.includes(role) ||
    (role &&
      role !== DEVELOPER &&
      role !== SYSTEM_AUDITOR &&
      !RESTRICT_HAS_DEFENDER_PERMISSIONS.includes(role) &&
      computeAllowed);

  const additionalAttributesList = useMemo(() => {
    return getAdditionalAttributesList(role, intl, computeAllowed);
  }, [role, intl, computeAllowed]);

  const shouldRenderResourceListField =
    role !== "System Admin" &&
    (roleTypeContext?.acceptResourceLists ||
      !!initialValues?.resourceLists?.length);

  if (!roleTypeContext) {
    return null;
  }
  return (
    <>
      {roleTypeContext?.acceptAccountGroups && (
        <AccountGroupsField initialValues={initialValues} />
      )}
      {shouldRenderResourceListField && (
        <ResourceListField roleType={roleType} initialValues={initialValues} />
      )}
      <Repositories
        acceptCodeRepositories={roleTypeContext.acceptCodeRepositories}
        bridgecrewEnabled={bridgecrewEnabled}
        isSystemAdmin={isSystemAdmin}
        role={role}
        onlyAllowCIAccess={onlyAllowCIAccess}
        initialValues={initialValues}
      />
      {hasAdvancedOption && (
        <CheckboxGroup
          name="additionalAttributes"
          // @ts-expect-error Default Value is a Record<string, boolean>. We are passing additionalAttributes
          // which is a Record<string, boolean> but TS is complaining that it does not match properly.
          defaultValue={additionalAttributes}
          label={intl.formatMessage({
            defaultMessage: "Advanced options",
            id: "+Dwok7",
            description: "Label for Advanced options field",
          })}
          items={additionalAttributesList}
        />
      )}
    </>
  );
}

function Repositories({
  acceptCodeRepositories,
  bridgecrewEnabled,
  isSystemAdmin,
  role,
  onlyAllowCIAccess,
  initialValues,
}: {
  acceptCodeRepositories: boolean;
  bridgecrewEnabled: boolean;
  isSystemAdmin: boolean;
  role: string;
  onlyAllowCIAccess: boolean;
  initialValues?: InitialValues;
}) {
  if (acceptCodeRepositories) {
    if (
      (bridgecrewEnabled && isSystemAdmin) ||
      (role === BUILD_AND_DEPLOY_SECURITY && onlyAllowCIAccess)
    ) {
      return <RepositoriesField initialValues={initialValues} role={role} />;
    } else if (!isNil(initialValues?.codeRepositories)) {
      return <DisabledRepositoriesField initialValues={initialValues} />;
    }
  }

  return null;
}
