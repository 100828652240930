import { useContext, type ReactElement } from "react";

import { classNames } from "utils";
import { RadioGroupContext } from "./RadioGroup";
import { RadioInputCard, type RadioInputCardProps } from "./RadioInputCard";

export type RadioCardProps = {
  /**
   * The value of the radio
   */
  value: string;
  /**
   * Disable selecting the radio
   */
  disabled?: boolean;
} & RadioInputCardProps;

/**
 * A radio styled like a card that can be used to build forms. Radios must be wrapped in a RadioGroup.
 */
export function RadioCard(props: RadioCardProps): ReactElement {
  const { checked, disabled = false, value, ...rest } = props;
  const {
    disabled: groupDisabled,
    name,
    onChange,
    ref,
    value: selectedValue,
  } = useContext(RadioGroupContext);
  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && name === "") {
    throw new Error(
      "Attempted to render RadioCard outside of a RadioGroup context or RadioGroup name is not set. Make sure your component is rendered inside <RadioGroup> with a name.",
    );
  }
  const isDisabled = groupDisabled || disabled;
  const checkedProps: { checked?: boolean; defaultChecked?: boolean } = {};
  if (checked !== undefined) {
    checkedProps["checked"] = checked;
  } else {
    checkedProps["defaultChecked"] = value === selectedValue;
  }

  return (
    <label
      className={classNames(
        "flex items-center space-x-2 text-xs",
        isDisabled ? "cursor-not-allowed text-disabled" : "cursor-pointer",
      )}
    >
      <RadioInputCard
        name={name}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        ref={ref}
        {...checkedProps}
        {...rest}
      />
    </label>
  );
}
