import { Form, FormLayout } from "form";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Body,
  Button,
  Card,
  EmptyStateLayout,
  SomethingWentWrongSVG,
} from "ui";

import { useMutation } from "@tanstack/react-query";
import { PrismaLogoIcon } from "icons";
import { postAuthTokenRegenerate } from "requests";
import { useToastActions } from "stores";
import { AnimatedBackground } from "../../components/AnimatedBackground";
import { CrystalIcon } from "../../components/CrystalIcon";
import { RouterLink } from "../../components/Navigation/RouterLink/RouterLink";
import { CopyrightNotice } from "../login/components/CopyrightNotice";

export function ExpiredToken({
  fpt,
  forgotPassword,
}: {
  fpt: string;
  forgotPassword: boolean;
}) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: async () => {
      await postAuthTokenRegenerate({ resetToken: fpt });
    },
    onSuccess: () => {
      toast(
        [
          intl.formatMessage({
            defaultMessage: "Link sent successfully!",
            id: "BniHDL",
            description: "Toast that new link was sent",
          }),
          intl.formatMessage({
            defaultMessage:
              "Something went wrong resending the link. Please try again.",
            id: "GAB75a",
            description: "Error message on failed request",
          }),
        ],
        { autoDismiss: false },
      );
    },
    onError: () => {
      toast(
        intl.formatMessage({
          defaultMessage:
            "Something went wrong resending the link. Please try again.",
          id: "GAB75a",
          description: "Error message on failed request",
        }),
        { appearance: "error" },
      );
    },
  });

  return (
    <div className="dark flex min-h-screen flex-col bg-white bg-auto bg-top bg-no-repeat text-sm text-default dark:bg-blue-steel-950 dark:text-dark-bg">
      <AnimatedBackground />
      <div className="relative z-10 mx-auto w-96 grow pb-4 pt-28 dark:text-dark-bg">
        <div className="flex justify-center">
          <RouterLink className="mx-auto mb-10 cursor-pointer" to="/login">
            <PrismaLogoIcon size="7xl" color="white" />
          </RouterLink>
        </div>
        <div className="absolute left-0 top-0 -z-10 -ml-32 mt-48">
          <CrystalIcon theme="theme-robinblue" width="640px" height="500px" />
        </div>
        <Card addClassName="p-10" shadow="md" appearance="glass">
          <Form onSubmit={() => mutate()}>
            <FormLayout>
              <EmptyStateLayout
                heading={
                  forgotPassword ? (
                    <FormattedMessage
                      defaultMessage="Forgot Password Link Expired"
                      id="eX24E8"
                      description="sso error page title"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Activation Link Expired"
                      id="Y0NNYJ"
                      description="sso error page title"
                    />
                  )
                }
                graphic={<SomethingWentWrongSVG className="w-48" />}
              />

              <Body size="sm">
                <FormattedMessage
                  defaultMessage="Please click on the button below to generate a new link."
                  id="aQXEbG"
                  description="Reset password link expired instructions"
                />
              </Body>
              <Button
                isLoading={isPending}
                fullWidth
                centerText
                disabled={isSuccess}
                type="submit"
                appearance="primary"
              >
                <FormattedMessage
                  defaultMessage="Resend Link"
                  id="Uz2WXP"
                  description="Submit button for requesting a new reset password link"
                />
              </Button>
            </FormLayout>
          </Form>
        </Card>
      </div>
      <div className="absolute bottom-0 z-[2] w-full bg-gray-100 p-3 text-center dark:bg-[#23424D]">
        <Body appearance="secondary" size="sm">
          <CopyrightNotice />
        </Body>
      </div>
    </div>
  );
}
