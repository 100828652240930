import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsProvidersRoute } from "../providers/settingsProvidersRoute";
const CasProviders = lazy(() => import("./CasProviders"));

export const Repositories = lazy(() => import("./SettingsRepositories"));

export const settingsVCSRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "/vcs",
  component: CasProviders,
});

export const settingsVCSNestedRoute = new Route({
  getParentRoute: () => settingsVCSRoute,
  path: "$",
  component: CasProviders,
});
