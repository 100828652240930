import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../../../types";
import Details from "../Details";
import StatusDetails from "../StatusDetails";

type MultipleSecurityCapabilityProps = {
  data: SecurityCapabilitiesPermissionsStatus[];
  type: SecurityCapabilitiesType;
  label: string;
};

export default function MultipleSecurityCapability({
  data,
  type,
  label,
}: MultipleSecurityCapabilityProps) {
  if (isNil(data)) return null;

  return (
    <div className="border">
      <Details
        icon={<SecurityCapabilitiesIcon type={type} />}
        label={label}
        className="border-b pl-2 pt-4"
      />
      {data?.map(
        (
          {
            status,
            name,
            message = "",
            remediation = "",
            components = [],
            ...rest
          }: SecurityCapabilitiesPermissionsStatus,
          index,
        ) => (
          <div
            className={`${
              index % 2 === 0
                ? "rounded-md bg-gray-100 dark:bg-blue-steel-950 dark:text-dark-bg"
                : ""
            }`}
            key={name}
          >
            <StatusDetails
              detailsClassName="mt-1 p-2 ml-8"
              key={name}
              label={name}
              message={message}
              normalFont={true}
              status={status}
              remediation={remediation}
              components={components}
              {...rest}
            />
          </div>
        ),
      )}
    </div>
  );
}
