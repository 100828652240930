import { createRoute } from "@tanstack/react-router";
import { useTokenStore } from "requests";
import { rootRoute } from "./rootRoute";

export const publicRootRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: "public",
  beforeLoad: ({ navigate }) => {
    const { isAuthenticated } = useTokenStore.getState();
    if (isAuthenticated()) {
      throw navigate({
        to: "/home",
      });
    }
  },
});
