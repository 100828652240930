import { awsFeatureStatus, featureStatuskeys, getMetaInfo } from "requests";

import { useQuery } from "@tanstack/react-query";

export function useDLPFeature(policyTypeAsData: boolean): boolean | undefined {
  const { data } = useQuery({
    queryKey: featureStatuskeys.aws(),
    queryFn: awsFeatureStatus,
  });
  const { data: metaInfo } = useQuery({
    queryKey: featureStatuskeys.metaInfo(),
    queryFn: getMetaInfo,
  });
  if (!policyTypeAsData) return;

  return (
    !!data?.is_aws_storage_scan_available &&
    metaInfo?.licenseType === "enterprise"
  );
}
