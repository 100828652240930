import { FormattedMessage, useIntl } from "react-intl";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormLayout } from "form";
import { useCallback, useEffect } from "react";
import {
  cloudAccountsKeys,
  getAlibabaCloudAccountStatus,
  saveAlibabaCloudAccount,
  updateAlibabaCloudAccount,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { useModal } from "ui";
import { useCloudAccountPendoGuide } from "../../../../../../../utils/useCloudAccountPendoGuide";
import {
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import { ALIBABA_CLOUD } from "../../../../constants";
import { useIsRenderedFirstTime } from "../../../../hooks";
import {
  type AlibabaConfigureAccountStepValuesType,
  type AlibabaGetStartedStepValuesType,
  type UnKnownError,
} from "../../../../types";
import { parseI18nKeyFromResponse } from "../../../../utils";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import SaveAndCloseButton from "../../components/SaveAndCloseButton";
import SaveAndOnboardButton from "../../components/SaveAndOnboardButton";
import { getAuthenticationData, handleSuccess } from "../../utils";
import AccountDetailsStatus from "./AccountDetailsStatus";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";

type ReviewStatusProps = {
  context: {
    closeModal: () => void;
    enabled: boolean;
    isEdit: boolean;
    accountId: string;
  };
};

export default function ReviewStatus({
  context: { closeModal, enabled, isEdit, accountId },
}: ReviewStatusProps) {
  const intl = useIntl();
  const { showGuide } = useCloudAccountPendoGuide();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { openModal } = useModal(CloudSelection);
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedForFirstTime = useIsRenderedFirstTime();

  const {
    name = "",
    groupIds = [],
    ramArn = "",
  } = steps[1]?.values as AlibabaConfigureAccountStepValuesType;

  const { deploymentType, accountType } = steps[0]
    ?.values as AlibabaGetStartedStepValuesType;

  const payload = {
    accountId: accountId
      ? accountId
      : ramArn.split("acs:ram::")[1]?.split(":")[0],
    ramArn,
    groupIds,
    enabled,
    name,
    vulnerabilityAssessmentEnabled: false,
    ...(deploymentType && { deploymentType }),
  };

  //TODO: enable this after API support
  // const { supportedFeaturesList, isLoading: isFeaturesLoading } =
  //   useGetSupportedFeaturesList({
  //     cloudType: ALIBABA_CLOUD,
  //     payload: {
  //       accountType,
  //     },
  //   });

  const {
    data: accountStatus = [],
    isFetching: isCloudStatusLoading,
    isError,
    error,
  } = useQuery({
    queryKey: cloudAccountsKeys.alibabaCloudAccountStatus({ payload }),
    queryFn: getAlibabaCloudAccountStatus,
    enabled: !isRenderedForFirstTime,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isError) return;

    toast(parseI18nKeyFromResponse(intl, error as unknown as UnKnownError), {
      appearance: "error",
    });
    closeModal();
  }, [isError, closeModal, error, intl, toast]);

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({ mutationFn: () => saveAlibabaCloudAccount({ payload }) });

  const { mutateAsync: updateConfig, isPending: isAccountUpdating } =
    useMutation({
      mutationFn: () => updateAlibabaCloudAccount({ accountId, payload }),
    });

  const onSubmit = useCallback(
    async ({ openNewOnboarding = false }) => {
      try {
        if (isEdit) {
          await updateConfig();
        } else {
          await createConfig();
        }

        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });

        if (isEdit) {
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.cloudAccountsRest({
              accountId,
              cloudType: ALIBABA_CLOUD,
              includeGroupInfo: "true",
            }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.permissionsStatus({ accountId }),
          });
        }
        closeModal();
        openNewOnboarding && openModal();
        if (!isEdit) showGuide();
        toast(handleSuccess(isEdit, intl), { appearance: "success" });
      } catch (error) {
        toast(parseI18nKeyFromResponse(intl, error), {
          appearance: "error",
        });
      }
    },
    [
      isEdit,
      queryClient,
      accountId,
      closeModal,
      openModal,
      showGuide,
      toast,
      updateConfig,
      createConfig,
      intl,
    ],
  );

  const accountDetailsData = {
    accountGroups: groupIds,
    accountName: name,
    accountType,
    ramArn,
    //TODO: enable this after supportedFeaturesList API support
    // securityCapabilities: supportedFeaturesList.filter((feature) =>
    //   [MISCONFIGURATIONS].includes(feature)
    // ) as SecurityCapabilitiesType[],
    securityCapabilities: [
      "Cloud Visibility Compliance and Governance",
    ] as SecurityCapabilitiesType[],
    authentication: getAuthenticationData(accountStatus),
    ...(deploymentType && { deploymentType }),
  };

  //TODO: enable this after supportedFeaturesList API support
  // const isLoading = isCloudStatusLoading || isFeaturesLoading;

  const isLoading = isCloudStatusLoading;
  const isSubmitting = isAccountCreating || isAccountUpdating;
  const disableBtn = isLoading || isSubmitting;

  return (
    <WizardForm
      actions={
        <>
          <PrevButton disabled={disableBtn} />
          {!isEdit && (
            <SaveAndOnboardButton disabled={disableBtn} onSubmit={onSubmit} />
          )}
          <SaveAndCloseButton disabled={disableBtn} onSubmit={onSubmit} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <section className="space-y-4">
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Review account details, security capabilities and permissions."
            id="ocHZwf"
            description="description for review status title"
          />
        </span>
        <FormLayout>
          <AccountDetailsStatus {...accountDetailsData} isLoading={isLoading} />
          <SecurityCapabilitiesPermissions
            accountStatus={accountStatus}
            isLoading={isLoading}
          />
        </FormLayout>
      </section>
    </WizardForm>
  );
}
