import {
  useWizardContext,
  type WizardStepState,
} from "../../../../../../components/Wizard";
import {
  AWS,
  CONFIGURE_DATA_SECURITY,
  CONFIGURE_FW,
  DATA_SECURITY,
  DEFINE_FW,
  ORGANIZATION,
} from "../../../../../constants";
import {
  useGetAwsCloudAccountsCas,
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../../../hooks";
import ComputeLimitBanner from "../../../components/ComputeLimitBanner";
import SecurityCapabilities from "../../../components/SecurityCapabilities";

import { useFormContext } from "react-hook-form";

import { LoadingIcon } from "icons";

import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useToastActions } from "stores";

import ConfigureDataSecurity from "../../ConfigureDataSecurity";
import ConfigureFwScan from "../../ConfigureFwScan";
import DefineFwScan from "../../DefineFwScan";

type SecurityCapabilitiesAndPermissionsPropTypes = {
  fromFreemium: boolean;
  accountId: string;
  isEdit: boolean;
};

export default function SecurityCapabilitiesAndPermissions({
  fromFreemium,
  accountId,
  isEdit,
}: SecurityCapabilitiesAndPermissionsPropTypes) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    removeStep,
    addStep,
    state: { steps },
  } = useWizardContext();

  const { watch, setValue } = useFormContext();

  const [accountType, awsPartition, securityCapabilities] = watch([
    "accountType",
    "partitionType",
    "securityCapabilities",
  ]);

  const { isLoading, licenseType } = useGetSupportedFeaturesList({
    cloudType: AWS,
    payload: { accountType, awsPartition },
  });

  const { data: awsData, isLoading: isAwsDataLoading } =
    useGetAwsCloudAccountsCas({
      accountId,
      options: { enabled: isEdit },
    });

  const status = isEdit ? "valid" : "pending";

  const {
    storageScanConfig: { scanOption = "Custom", snsTopicArn = "" } = {},
  } = awsData || {};

  const dlpSteps: WizardStepState[] = useMemo(
    () => [
      {
        Component: ConfigureDataSecurity,
        disabled: !isEdit,
        hasBeenSubmitted: isEdit,
        status,
        key: CONFIGURE_DATA_SECURITY,
        label: intl.formatMessage({
          defaultMessage: "Configure Data Security",
          id: "rmpD2C",
          description: "Cloud Account Wizard page heading",
        }),
        values: {
          scanOption,
        },
      },
      {
        Component: DefineFwScan,
        disabled: !isEdit,
        hasBeenSubmitted: isEdit,
        status,
        key: DEFINE_FW,
        label: intl.formatMessage({
          defaultMessage: "Define Forward Scan",
          id: "/0Aclu",
          description: "Cloud Account Wizard page heading",
        }),
        values: {
          cloudTrailTopic: snsTopicArn,
        },
      },
      {
        Component: ConfigureFwScan,
        disabled: !isEdit,
        hasBeenSubmitted: isEdit,
        status,
        key: CONFIGURE_FW,
        label: intl.formatMessage({
          defaultMessage: "Configure Forward Scan",
          id: "Ufg/5/",
          description: "Cloud Account Wizard page heading",
        }),
      },
    ],
    [intl, isEdit, scanOption, snsTopicArn, status],
  );

  useEffect(() => {
    if (accountType === ORGANIZATION && securityCapabilities?.[DATA_SECURITY]) {
      delete securityCapabilities[DATA_SECURITY];
      setValue("securityCapabilities", securityCapabilities);
    }
    if (securityCapabilities?.[DATA_SECURITY]) {
      if (!steps.some((step) => step.key === DEFINE_FW)) {
        addStep({ index: 2, steps: dlpSteps });
      }
    } else {
      if (steps.some((step) => step.key === DEFINE_FW)) {
        removeStep({ index: 2 });
        removeStep({ index: 2 });
        removeStep({ index: 2 });
      }
    }
    if (fromFreemium && !securityCapabilities?.[DATA_SECURITY]) {
      toast(
        intl.formatMessage({
          defaultMessage:
            "Data Security checkbox must be selected to enable Data Security",
          id: "fmk5gd",
          description: "Warning in a toast for the Data Security option",
        }),
        { appearance: "warning" },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityCapabilities, accountType]);

  const { isLoading: isIdentitySubscriptionLoading } =
    useIdentitySubscription();

  if (isLoading || isIdentitySubscriptionLoading || isAwsDataLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <ComputeLimitBanner />
      <SecurityCapabilities
        cloudType={AWS}
        licenseType={licenseType}
        payload={{
          accountType,
          awsPartition,
        }}
      />
    </>
  );
}
