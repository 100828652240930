import { FormattedMessage } from "react-intl";
import { DynamicFormattedMessage } from "ui";
import { scopeMap } from "../../../../../../../messages/reviewStatusMessages";
import { type AccountType } from "../../../../../../../types";
import { AccountTypeIcon } from "../../../../../../../utils";
import { Details } from "../Details";

export function AccountType({
  accountType,
  classNames,
}: {
  accountType: AccountType;
  classNames: string;
}) {
  const Icon = AccountTypeIcon(accountType);
  return (
    <Details
      label={
        <FormattedMessage
          defaultMessage="Scope"
          id="jnIAbW"
          description="Description for Scope on review status"
        />
      }
      classNames={classNames}
    >
      <div className="flex">
        <span>
          <Icon className="mr-1" size="sm" />
        </span>
        <span>
          <DynamicFormattedMessage messageMap={scopeMap} token={accountType} />
        </span>
      </div>
    </Details>
  );
}
