import { useWatch } from "form";
import { CogsIcon } from "icons";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { AccordionPanel, Banner, Tag } from "ui";
import { useWizardContext } from "../../../../../../components/Wizard";
import { ORGANIZATION } from "../../../../../constants";
import { type AwsGetStartedStepValuesType } from "../../../../../types";
import MemberAccountSettings from "./MemberAccountSettings";

export default function AdvancedConfiguration() {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { setValue } = useFormContext();
  const {
    state: { steps },
  } = useWizardContext();

  const isCustomMemberRoleNameEnabled = useWatch({
    name: "customMemberRoleNameEnabled",
  });

  const { accountType } = steps[0]?.values as AwsGetStartedStepValuesType;
  const isOrg = accountType === ORGANIZATION;

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    !isCustomMemberRoleNameEnabled && setValue("unifiedCftDisabled", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomMemberRoleNameEnabled]);

  return (
    <>
      <AccordionPanel
        isOpen={isOpen}
        onClick={onClick}
        icon={<CogsIcon />}
        title={
          <>
            <div className="flex">
              <span className="flex-1">
                {intl.formatMessage({
                  defaultMessage: "Advanced Settings",
                  id: "hR5KZa",
                  description: "description for Advanced Settings card header",
                })}
              </span>

              {isCustomMemberRoleNameEnabled && isOrg && (
                <span className="-ml-16">
                  <Tag size="md" appearance="blue">
                    <FormattedMessage
                      defaultMessage="One or more settings enabled"
                      id="I8wcX+"
                      description="Tag text when feature is checked"
                    />
                  </Tag>
                </span>
              )}
            </div>
          </>
        }
      >
        <div className="space-y-4 divide-y">
          <MemberAccountSettings />
        </div>
      </AccordionPanel>
      {isCustomMemberRoleNameEnabled && isOrg && (
        <Banner
          type="inline"
          appearance="warning"
          showIcon
          data-testid="advanced-settings-banner"
        >
          <ol className="list-decimal pl-2">
            <li>
              <FormattedMessage
                defaultMessage="Do not delete the existing Prisma role from the member accounts until you have downloaded and executed the CFT below in the member accounts and saved the changes."
                id="vq1cin"
                description="Banner messagE for Separate CFTs for Management & Member account"
              />
            </li>
            <li>
              <FormattedMessage
                defaultMessage="Do not proceed to the next step until you downloaded and executed the CFT."
                id="UBdYRq"
                description="Banner messagE for Separate CFTs for Management & Member account"
              />
            </li>
          </ol>
        </Banner>
      )}
    </>
  );
}
