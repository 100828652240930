import { RadioGroup, useFormContext } from "form";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Body, Bold, Card, CardBody, CardHeader } from "ui";
import {
  useWizardContext,
  type WizardStepState,
} from "../../../../../../components/Wizard";

type OnboardTypeProps = {
  customConfigurationStep: WizardStepState[];
  setCustomConfigEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OnboardType({
  customConfigurationStep,
  setCustomConfigEnabled,
}: OnboardTypeProps) {
  const { addStep, removeStep } = useWizardContext();
  const { clearErrors } = useFormContext();
  const intl = useIntl();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value === "template") {
        setCustomConfigEnabled(true);
        clearErrors("name");
        addStep({ index: 1, steps: customConfigurationStep });
      } else {
        setCustomConfigEnabled(false);
        removeStep({ index: 1 });
      }
    },
    [
      addStep,
      clearErrors,
      customConfigurationStep,
      removeStep,
      setCustomConfigEnabled,
    ],
  );

  return (
    <Card>
      <CardHeader
        title={
          <Body appearance="secondary">
            <FormattedMessage
              defaultMessage="How would you like to onboard?"
              id="TGR6jS"
              description="Title for Onboard type section"
            />
          </Body>
        }
        appearance="clear"
      />
      <CardBody>
        <RadioGroup
          onChange={handleChange}
          className="flex flex-row"
          label={intl.formatMessage({
            defaultMessage: "OnboardType",
            id: "t9XYoX",
            description: "AWS Onboarding selection field label",
          })}
          name="onboardType"
          showLabel={false}
          items={[
            {
              value: "automated",
              children: (
                <div className="flex pr-6">
                  <Body>
                    <Bold>
                      <FormattedMessage
                        defaultMessage="Automated"
                        id="wyokiy"
                        description="OnboardType option"
                      />
                    </Bold>
                  </Body>
                  <Body appearance="secondary" addClassName="pl-1">
                    <FormattedMessage
                      defaultMessage="(Recommended)"
                      id="/dZNlz"
                      description="Description for Recommended label"
                    />
                  </Body>
                </div>
              ),
            },
            {
              value: "template",
              children: (
                <Body>
                  <Bold>
                    <FormattedMessage
                      defaultMessage="Generate Template"
                      id="s3Z8BK"
                      description="OnboardType option"
                    />
                  </Bold>
                </Body>
              ),
            },
          ]}
        />
      </CardBody>
    </Card>
  );
}
