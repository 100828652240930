import { useQuery } from "@tanstack/react-query";
import { usePendo, usePermissions } from "prisma";
import {
  cloudAccountsKeys,
  getCloudAccountsTableData,
  useToken,
} from "requests";

const CLOUD_ACCOUNT_GUIDE_ID = "es1nCQP4V8hfzLoierb7cE0H5cE";

let guideOpened = false;

export function useCloudAccountPendoGuide() {
  const token = useToken();
  const { settingsCloudAccounts } = usePermissions();

  const { pendoInstance } = usePendo();

  const { data: cloudAccounts } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsTable({
      excludeAccountGroupDetails: "true",
    }),
    queryFn: getCloudAccountsTableData,
    staleTime: Infinity,
    enabled: settingsCloudAccounts.READ,
  });

  const userCloudAccounts = cloudAccounts?.filter(
    (ca) => ca.lastModifiedBy === token.username,
  );

  const showGuide = (): boolean => {
    if (userCloudAccounts && userCloudAccounts?.length < 1) {
      guideOpened = !!pendoInstance?.showGuideById(CLOUD_ACCOUNT_GUIDE_ID);
      return true;
    }

    return false;
  };

  const setGuideOpened = (state: boolean) => (guideOpened = state);

  return { showGuide, guideOpened, setGuideOpened };
}
