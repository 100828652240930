import { isChina } from "environment";
import { useFormContext } from "form";
import { useEffect } from "react";
import { ACCOUNT, AWS } from "../../../../../constants";
import AccountType from "../../../components/AccountType";

export default function Scope() {
  const { watch, setValue } = useFormContext();
  const awsPartition = watch("partitionType");

  const isChinaPartition = awsPartition?.includes("cn") || isChina();

  useEffect(() => {
    if (isChinaPartition) {
      setValue("accountType", ACCOUNT);
    }
  }, [isChinaPartition, setValue]);

  return (
    <AccountType
      cloudType={AWS}
      name="accountType"
      isChinaPartition={isChinaPartition}
    />
  );
}
