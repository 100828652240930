import { z } from "zod";

export const TimeStampSchema = z.number().nonnegative();

const ToNowTimeSchema = z.object({
  type: z.literal("to_now"),
  value: z.enum(["year", "epoch", "login"]),
});
export type ToNowTime = z.infer<typeof ToNowTimeSchema>;

const RelativeTimeSchema = z.object({
  relativeTimeType: z.enum(["BACKWARD", "FORWARD"]).optional(),
  type: z.literal("relative"),
  value: z.object({
    amount: z.union([z.string(), z.number()]),
    unit: z.enum(["hour", "day", "week", "month", "year"]),
  }),
});
export type RelativeTimeType = z.infer<typeof RelativeTimeSchema>;

export const AbsoluteTimeSchema = z.object({
  type: z.literal("absolute"),
  value: z.object({
    startTime: z.coerce.number().nonnegative().optional(),
    endTime: z.coerce.number().positive(),
  }),
});
export type AbsoluteTimeType = z.infer<typeof AbsoluteTimeSchema>;

export const TimeRangeTypeSchema = z.enum(["to_now", "relative", "absolute"]);

// Changed the name of the union to discriminatedUnion because the previous union was unable to distinguish between type literals, resulting in an error.
export const TimeRangeSchema = z.discriminatedUnion("type", [
  ToNowTimeSchema,
  RelativeTimeSchema,
  AbsoluteTimeSchema,
]);
export type TimeRange = z.infer<typeof TimeRangeSchema>;

export const FilterSchema = z.object({
  name: z.string(),
  operator: z.literal("="),
  value: z.string(),
});

export const ServerPaginatedTableParamsSchema = z
  .object({
    offset: z.number(),
    nextPageToken: z.string(),
    limit: z.number().int(),
    sort: z.string(),
    reverse: z.boolean(),
    search: z.string(),
  })
  .partial();

export type ServerPaginatedTableParams = z.infer<
  typeof ServerPaginatedTableParamsSchema
>;

export function PaginatedResponseSchema<T extends z.ZodTypeAny>(dataSchema: T) {
  return z.object({
    value: z.array(dataSchema).nullish(),
    total: z.number(),
    nextPageToken: z.string().nullish(),
  });
}

export const GraphqlErrorsSchema = z
  .array(
    z.object({
      message: z.string().nullish(),
      path: z.array(z.string()).nullish(),
      locations: z.array(z.string()).nullish(),
      extensions: z
        .object({
          errorType: z.string().nullish(),
          errorDetail: z.string().nullish(),
          origin: z.string().nullish(),
          debugInfo: z
            .object({
              subquery: z.string().nullish(),
              variables: z.object({}).nullish(),
            })
            .nullish(),
          classification: z.string().nullish(),
        })
        .nullish(),
      pathAsString: z.string().nullish(),
    }),
  )
  .nullish();

export function GraphqlResponseSchema<T extends z.ZodTypeAny>(dataSchema: T) {
  return z.object({
    data: dataSchema,
    errors: GraphqlErrorsSchema,
  });
}

export type GraphqlResponse = z.infer<ReturnType<typeof GraphqlResponseSchema>>;
