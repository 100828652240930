import { IdentitySecurityModuleIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { AddonModalMainCard } from "../AddonModalMainCard";

export function ASMUnsubscribeModalContent() {
  return (
    <AddonModalMainCard
      icon={<IdentitySecurityModuleIcon size="xxl" />}
      title={
        <FormattedMessage
          defaultMessage="We're sorry that you have decided to unsubscribe."
          id="pbbYsc"
          description="Unsubscribe modal header"
        />
      }
      titleStyle="secondaryHeader"
      description={
        <>
          <FormattedMessage
            defaultMessage="After unsubscribing you won’t have access to the CDEM security module."
            id="Ib3esP"
            description="Unsubscribe modal description"
          />
          <div className="pt-3">
            <FormattedMessage
              defaultMessage="In addition, unsubscribing will result in the loss of your visibility to unknown and unmanaged assets. If you choose to re-enable the module, you will miss the unmanaged asset discovery and remediation. Please consider this before unsubscribing."
              id="tC37lG"
              description="Unsubscribe modal description part 2"
            />
          </div>
        </>
      }
    />
  );
}
