import { type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Button, Select, type SelectItem } from "ui";

import { fwScanMessages } from "../messages";

type CloudTrailTypePropTypes = {
  selectedItem: SelectItem;
  setCloudTrailBucket: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailName: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
  setSelectedItem: Dispatch<SetStateAction<SelectItem>>;
  setTopicType: Dispatch<SetStateAction<SelectItem>>;
  setTopicForSelectedCloudTrail: Dispatch<SetStateAction<string | undefined>>;
};

export default function CloudTrailType({
  selectedItem,
  setCloudTrailBucket,
  setCloudTrailName,
  setCloudTrailTopic,
  setSelectedItem,
  setTopicType,
  setTopicForSelectedCloudTrail,
}: CloudTrailTypePropTypes) {
  const intl = useIntl();

  const addNew = intl.formatMessage(fwScanMessages.addNew);
  const selectExisting = intl.formatMessage(fwScanMessages.selectExisting);

  const items = [
    { children: selectExisting, value: "Select Existing" },
    { children: addNew, value: "Add New" },
  ];

  const handleOnChange = (selectedItem: SelectItem) => {
    setSelectedItem(selectedItem);

    if (selectedItem?.value === "Select Existing") {
      setTopicType(items[0]);
    }

    // reset values in Wizard
    setCloudTrailBucket("");
    setCloudTrailName("");
    setCloudTrailTopic("");

    setTopicForSelectedCloudTrail("");
  };

  return (
    <Select
      button={
        <Button addClassName="w-36" isMenu>
          {selectedItem.value as string}
        </Button>
      }
      fullWidth
      items={items}
      menuStyle={{ width: "8rem" }}
      onChange={({ selectedItem }) =>
        handleOnChange(selectedItem as SelectItem)
      }
      selectedItem={selectedItem}
    ></Select>
  );
}
