import { useState } from "react";
import {
  type IntersectionObserverHookArgs,
  type VisibilityTrackerHookReponse,
} from "./types";
import useIntersectionObserver from "./useIntersectionObserver";

function useVisibilityTracker(
  args?: IntersectionObserverHookArgs,
): VisibilityTrackerHookReponse {
  const [ref, result] = useIntersectionObserver(args);
  const isVisible = Boolean(result.entry?.isIntersecting);
  const [wasEverVisible, setWasEverVisible] = useState(isVisible);

  if (isVisible && !wasEverVisible) {
    setWasEverVisible(true);
  }

  return [ref, { ...result, isVisible, wasEverVisible }];
}
export default useVisibilityTracker;
