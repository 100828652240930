import { Set } from "immutable";
import { useCallback } from "react";
import { isEmpty } from "remeda";

import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import SelectMemberAccountTree from "../../../../../../components/SelectMemberAccountTree";
import { type FlatNodeType } from "../../../../../../components/SelectMemberAccountTree/types";
import { AZURE } from "../../../../../../constants";
import ErrorMessages from "../../../../components/ErrorMessages";
import ModelRadioGroup from "../../../../components/ModelRadioGroup";
import { useAzureSelectMonitoredProjectContext } from "../../../context/AzureSelectMonitoredProjectContext";
import {
  setAzureExpandedNodes,
  setAzureProjectSelectMode,
  setAzureSelectedNodes,
} from "../../../context/AzureSelectMonitoredProjectContext/state/actions";
import {
  getAzureDefaultExpandedNodes,
  getAzureFoldersCredentialError,
  getAzureLoadedNodes,
  getAzureProjectSelectMode,
  getAzureRootId,
  getAzureSelectProjectsInitialized,
  getAzureSelectProjectsIsLoading,
  getAzureSelectedNodes,
  getAzureSelectedNodesErrors,
  getAzureTreeMap,
} from "../../../context/AzureSelectMonitoredProjectContext/state/getters";

type AzureSelectProjectsProps = {
  loadAzureNodeData: UseMutateAsyncFunction<
    void,
    unknown,
    {
      childrenType: string;
      loadType: string;
      parentId: string;
    },
    unknown
  >;
};

export default function AzureSelectProjects({
  loadAzureNodeData,
}: AzureSelectProjectsProps) {
  const { state, dispatch } = useAzureSelectMonitoredProjectContext();

  const credentialError = getAzureFoldersCredentialError(state);
  const defaultExpandedKeys = getAzureDefaultExpandedNodes(state);
  const initialized = getAzureSelectProjectsInitialized(state);
  const loadedNodes = getAzureLoadedNodes(state);
  const mode = getAzureProjectSelectMode(state);
  const rootId = getAzureRootId(state);
  const selectedNodes = getAzureSelectedNodes(state);
  const treeMap = getAzureTreeMap(state);
  const selectedNodesErrors = getAzureSelectedNodesErrors(state);
  const isLoading = getAzureSelectProjectsIsLoading(state);

  const renderLoader = isLoading;
  const renderTree = !isLoading && initialized;

  const onChangeSelectedNodes = useCallback(
    (checkedNodeList: Set<string>) => {
      setAzureSelectedNodes(checkedNodeList, dispatch);
    },
    [dispatch],
  );

  const onExpandNode = useCallback(
    (expandedList: string[], treeNode: FlatNodeType) => {
      dispatch(setAzureExpandedNodes(Set(expandedList)));
      if (!loadedNodes.has(treeNode.id) && treeNode.expanded) {
        loadAzureNodeData({
          childrenType: "all",
          loadType: "initial",
          parentId: treeNode.id,
        });
      }
    },
    [loadedNodes, dispatch, loadAzureNodeData],
  );

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setAzureProjectSelectMode(event?.target?.value, dispatch),
    [dispatch],
  );

  return (
    <>
      {!renderLoader && (
        <ModelRadioGroup mode={mode} onChangeMode={onChangeMode} />
      )}

      {renderLoader && !credentialError && (
        <div className="flex justify-center text-center">
          <LoadingIcon size="xl" />
        </div>
      )}

      <ErrorMessages errors={selectedNodesErrors} />

      {renderTree && !credentialError && (
        <SelectMemberAccountTree
          defaultExpandedKeys={defaultExpandedKeys}
          selectedNodes={selectedNodes}
          error={!isEmpty(selectedNodesErrors)}
          loadedNodes={loadedNodes}
          onLoadMore={loadAzureNodeData}
          onChangeSelectedNodes={onChangeSelectedNodes}
          onExpandNode={onExpandNode}
          rootId={rootId}
          treeMap={treeMap}
          selectedCloud={AZURE}
        />
      )}
    </>
  );
}
