import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const SortIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 15 15"}>
      <path d="M11.3112 0.47027L14.7681 3.22005C15.1385 3.51471 14.9301 4.11135 14.4568 4.11135H11.9999V13.5C11.9999 13.7762 11.776 14 11.4999 14H10.4999C10.2238 14 9.99989 13.7762 9.99989 13.5V4.11135H7.54298C7.06964 4.11135 6.86128 3.51471 7.23172 3.22005L10.6886 0.47027C10.8708 0.32534 11.129 0.32534 11.3112 0.47027Z" />
      <path d="M3.49989 1H4.49989C4.77603 1 4.99989 1.22386 4.99989 1.5V10.8887H7.4568C7.93014 10.8887 8.1385 11.4853 7.76806 11.78L4.31115 14.5297C4.12895 14.6747 3.87083 14.6747 3.68863 14.5297L0.231718 11.78C-0.138712 11.4853 0.069638 10.8887 0.542978 10.8887H2.99989V1.5C2.99989 1.22386 3.22375 1 3.49989 1Z" />
    </SvgIcon>
  );
};
