import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Card, CardHeader } from "ui";
import { PermissionGroupCardBody } from "../PermissionGroupCardBody";
import { type PermissionGroupMap } from "../PermissionGroupsSidecar";

type DashboardCardProps = {
  featureMap: PermissionGroupMap;
};

export const dashboardFeatureKeys = [
  "codeSecurityDashboard",
  "vulnerabilityDashboard",
] as const;

export function DashboardCard({ featureMap }: DashboardCardProps) {
  const intl = useIntl();
  const features = useMemo(
    () =>
      dashboardFeatureKeys
        .filter((key) => !!featureMap[key])
        .map((key) => featureMap[key]),
    [featureMap],
  );

  if (!features.length) return null;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Dashboard",
          id: "wWtgL5",
          description: "Permission Group sidecar card header title",
        })}
      />
      <PermissionGroupCardBody features={features} />
    </Card>
  );
}
