import { useMutation, useQuery } from "@tanstack/react-query";
import {
  awsAccountDataSecurity,
  getCloudTrailData,
  getScriptData,
  validateFwScan,
  type CloudTrailType,
} from "requests";

import { FormLayout } from "form";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useToastActions } from "stores";
import { Body, Link, Textarea } from "ui";
import {
  NextButton,
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import { CONFIGURE_ACCOUNT, CUSTOM_CONFIGURATION } from "../../../../constants";
import { ProductDocumentationLink } from "../../components";

import { useFlags } from "launchdarkly-react-client-sdk";
import SaveAndNextButton from "../../../SimplifiedOnboarding/components/SaveAndNextButton";
import { getAccountIdFromRoleArn, getWizardStepIndex } from "../../utils";
import DownloadButton from "./ConfigureFwScanComponents/DownloadButton";
import ErrorRow from "./ConfigureFwScanComponents/ErrorRow";
import ValidateButton from "./ConfigureFwScanComponents/ValidateButton";
import { configureMessages } from "./ConfigureFwScanComponents/messages";
import {
  generateScriptParams,
  generateValidateParams,
} from "./ConfigureFwScanComponents/utils";

export default function ConfigureFwScan() {
  const intl = useIntl();
  const [settled, setSettled] = useState(false);
  const { toast } = useToastActions();
  const {
    state: { activeIndex, steps },
  } = useWizardContext();
  const { simplifiedOnboardingExperience } = useFlags();
  const defineStepIndex = activeIndex - 1;

  // for errors
  const [validated, setValidated] = useState(false);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  );

  const getStepIndex = getWizardStepIndex(steps);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);
  const customConfigStepIndex = getStepIndex(CUSTOM_CONFIGURATION);

  // get values from earlier page
  let externalId = steps[configureAccountIndex]?.values?.externalId;
  let roleArn = steps[configureAccountIndex]?.values?.roleArn;

  if (simplifiedOnboardingExperience) {
    externalId = steps[customConfigStepIndex]?.values?.externalId;
    roleArn = steps[customConfigStepIndex]?.values?.roleArn;
  }
  const accountId = getAccountIdFromRoleArn(roleArn as string);

  // getting values from wizard state for Define FW Scan page
  const defineFWVals = steps[defineStepIndex]?.values;
  const cloudTrailBucket = defineFWVals?.cloudTrailBucket;
  const cloudTrailName = defineFWVals?.cloudTrailName;
  const snsTopicName = defineFWVals?.cloudTrailTopic;
  const uuid = defineFWVals?.storageUUID;

  const { data: cloudTrailAPIData } = useQuery({
    queryKey: awsAccountDataSecurity.cloudTrail({
      accountId: accountId as string,
      externalId: externalId as string,
      roleArn: roleArn as string,
    }),
    queryFn: getCloudTrailData,
  });

  const cloudTrailShellCommand = cloudTrailAPIData?.cloudShellCommand;
  const snsSubscriptionEndpoint = cloudTrailAPIData?.snsSubscriptionEndpoint;

  const wizardDataIsIncomplete = useMemo(
    () => !cloudTrailBucket || !cloudTrailName || !snsTopicName,
    [cloudTrailBucket, cloudTrailName, snsTopicName],
  );
  const paramsFromState = useMemo(
    () => ({
      accountId: accountId as string,
      cloudTrailBucket: cloudTrailBucket as string,
      cloudTrailName: cloudTrailName as string,
      externalId: externalId as string,
      roleArn: roleArn as string,
      snsSubscriptionEndpoint: snsSubscriptionEndpoint as string,
      snsTopicName: snsTopicName as string,
      uuid: uuid as string,
    }),
    [
      accountId,
      cloudTrailBucket,
      cloudTrailName,
      externalId,
      roleArn,
      snsSubscriptionEndpoint,
      snsTopicName,
      uuid,
    ],
  );

  const scriptParams = useMemo(
    () =>
      generateScriptParams(
        paramsFromState,
        cloudTrailAPIData as CloudTrailType,
      ),
    [cloudTrailAPIData, paramsFromState],
  );

  const {
    data: scriptData,
    isError,
    isLoading: isCreatingScript,
  } = useQuery({
    queryKey: awsAccountDataSecurity.scriptData({ ...scriptParams }),
    queryFn: getScriptData,
    enabled: !wizardDataIsIncomplete,
  });

  useEffect(() => {
    if (!isError) return;

    toast(
      intl.formatMessage({
        defaultMessage: "Error generating script",
        id: "sro/1Y",
        description:
          "Error message for failure to generate AWS Onboarding script",
      }),
      { appearance: "warning" },
    );
  }, [isError, intl, toast]);

  const downloadScript = scriptData?.downloadScript;

  const validateParams = generateValidateParams(
    paramsFromState,
    cloudTrailAPIData as CloudTrailType,
  );

  const { isPending: isValidating, mutateAsync: validateClTrail } = useMutation(
    {
      mutationFn: () => validateFwScan({ ...validateParams }),
      onError: () => {
        setValidated(false);
      },
      onMutate: () => {
        // optimistically clear errors
        setValidationError(undefined);
      },
      onSettled: () => {
        setSettled(true);
      },
      onSuccess: (data) => {
        if (data.failureReason) {
          const errorMessage = intl.formatMessage(configureMessages.error);

          setValidated(false);
          setValidationError(errorMessage);
        } else {
          setValidated(true);
          setValidationError(undefined);
        }
      },
    },
  );

  const wizardDataIncompleteMessage = intl.formatMessage({
    defaultMessage:
      'Forward Scan not defined. Please click the "Previous" button below and complete the Define Forward Scan page.',
    id: "3qD2C9",
    description:
      "Instructions for fixing an error in the AWS Account onboarding wizard",
  });

  return (
    <WizardForm
      actions={
        <>
          <PrevButton />
          {simplifiedOnboardingExperience ? (
            <SaveAndNextButton />
          ) : (
            <NextButton />
          )}
        </>
      }
      secondaryFooterContent={
        !simplifiedOnboardingExperience && <ProductDocumentationLink />
      }
    >
      <FormLayout>
        <>
          <div>
            <Body size="sm">
              {intl.formatMessage(configureMessages.instruction1)}
            </Body>
            <DownloadButton
              downloadUrl={downloadScript || ""}
              isCreatingScript={isCreatingScript || false}
              wizardDataIsIncomplete={wizardDataIsIncomplete}
            />
            {wizardDataIsIncomplete && (
              <ErrorRow message={wizardDataIncompleteMessage} />
            )}
          </div>
          <div className="my-4">
            <Body size="sm">
              <FormattedMessage
                defaultMessage="2. Log in to <a>Amazon CloudShell</a>, upload to CloudShell the .zip file you downloaded in step 1, unzip it to the directory of your choice, and run the following command:"
                id="sccQYh"
                description="Wizard page subtitle"
                values={{
                  a: (chunks) => (
                    <Link
                      size="sm"
                      external
                      href="https://console.aws.amazon.com/cloudshell/home?region=us-east-1"
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Body>
            <Textarea
              addClassName="ml-2 mt-2"
              allowCopy
              readOnly
              value={cloudTrailShellCommand}
            />
          </div>
          <Body size="sm">
            {intl.formatMessage(configureMessages.instruction3)}
          </Body>
          <div className="ml-2 mt-2">
            <ValidateButton
              isCreatingScript={isCreatingScript}
              isError={isError}
              isValidating={isValidating}
              settled={settled}
              validateClTrail={validateClTrail}
              validated={validated}
              wizardDataIsIncomplete={wizardDataIsIncomplete}
            />
          </div>
          {validationError && <ErrorRow message={validationError} />}
        </>
      </FormLayout>
    </WizardForm>
  );
}
