import { animated, useTransition } from "@react-spring/web";
import { useMediaQuery, useWindowSize } from "@uidotdev/usehooks";
import { CloseIcon } from "icons";
import { Resizable } from "re-resizable";
import {
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";
import { useIntl } from "react-intl";
import { classNames } from "utils";
import { Button } from "../Button";
import { Portal } from "../Portal";
import { Body, Title } from "../Typography";
import { useSidecarStore } from "./useSidecarStore";

export const sidecarSizes = {
  xs: 320,
  sm: 400,
  md: 600,
  lg: 800,
  xl: 1000,
};

export type SidecarProps = {
  /**
   * The children get rendered inside the sidecar
   */
  children: ReactNode;
  customHeader?: ReactNode;
  customPinned?: string;
  /**
   * Whether the background mask should be rendered with the sidecar.
   */
  showMask?: boolean;
  /**
   * Set if the sidecar should be pinned initially.
   * @deprecated No longer used
   */
  initialPinned?: boolean;
  /**
   * When the user changes if the sidecar is Pinned or not.
   * @deprecated No longer used
   */
  onPinnedChange?: (pinned: boolean) => void;
  /**
   * Prevent the user from changing the sidecar from it's initial pinned state.
   * @deprecated No longer used
   */
  disablePinnedChange?: boolean;
  /**
   * Any action buttons to display.
   */
  actions?: ReactNode;
  /**
   * A title to display.
   */
  title: ReactNode;
  /**
   * A subtitle to display
   */
  subtitle?: string | ReactNode;
  /**
   * An html wrapper for the subtitle, by default it's 'p' but when you want to use ReactElement you should change to div/span.
   */
  subtitleAs?: ElementType;
  /**
   * An icon on the left side of the title and subtitle.
   */
  icon?: ReactNode;
  /**
   * This must be passed as true to have the sidecar slide out.
   */
  isOpen?: boolean;
  /**
   * A function that will get called if the mask is clicked on
   */
  onClose: () => unknown;
  /**
   * @deprecated No longer used
   */
  size?: keyof typeof sidecarSizes;
} & Omit<ComponentPropsWithoutRef<"div">, "className" | "style" | "title">;

/** This component renders a sidecar that can be pinned to its container or overlay the whole page.
 *
 * Make sure to place it in a * flex container next to your content.
 * The parent element will need to have a `relative` position.
 */
export function Sidecar({
  children,
  customHeader,
  showMask = false,
  title,
  subtitle,
  icon,
  customPinned,
  actions,
  isOpen = false,
  onClose,
  subtitleAs = "p",
  size: _a,
  initialPinned: _b,
  onPinnedChange: _c,
  disablePinnedChange: _d,
  ...passthrough
}: SidecarProps): ReactElement {
  const width = useSidecarStore((s) => s.width);
  const isMotionSafe = useMediaQuery("(prefers-reduced-motion: no-preference)");
  const setWidth = useSidecarStore((s) => s.setWidth);
  const intl = useIntl();
  const windowSize = useWindowSize();
  const [controlledOpen, setControlledOpen] = useState(isOpen);
  const sidecarClasses = classNames(
    "fixed right-0 top-0 h-full",
    "z-10 border-l bg-white shadow backdrop-blur-2xl dark:border-blue-steel-850 dark:bg-blue-steel-940/50",
    "flex flex-col overflow-hidden",
    "duration-300 fill-mode-both",
    isOpen
      ? "animate-in motion-safe:slide-in-from-right"
      : "animate-out motion-safe:slide-out-to-right",
    customPinned,
  );

  useEffect(() => {
    if (isOpen) {
      setControlledOpen(true);
      return;
    }
    const timeout = setTimeout(
      () => setControlledOpen(false),
      isMotionSafe ? 300 : 0,
    );

    return () => clearTimeout(timeout);
  }, [isOpen, isMotionSafe]);

  return (
    <>
      {showMask && (
        <OverlayMask
          isOpen={isOpen}
          onClick={onClose}
          label={intl.formatMessage({
            defaultMessage: "Close",
            id: "Wg61eK",
            description: "Close sidecar",
          })}
        />
      )}
      {controlledOpen && (
        <Portal>
          <div className={sidecarClasses} {...passthrough}>
            <Resizable
              size={{ width: width, height: "100%" }}
              minWidth={400}
              maxWidth={(windowSize.width ?? window.innerWidth) - 32}
              handleClasses={{
                left: "hover:bg-gray-300/20 duration-50",
              }}
              enable={{
                left: true,
              }}
              onResize={(_event, _direction, ref, _d) => {
                setWidth(ref.offsetWidth);
              }}
            >
              <div className="flex h-full flex-col" data-testid="sidecar">
                {customHeader ? (
                  <div>{customHeader}</div>
                ) : (
                  <SidecarHeader
                    title={title}
                    icon={icon}
                    subtitle={subtitle}
                    subtitleAs={subtitleAs}
                    actions={actions}
                    onClose={onClose}
                  />
                )}
                <div className="flex grow flex-col overflow-auto text-default dark:text-dark-bg">
                  {children}
                </div>
              </div>
            </Resizable>
          </div>
        </Portal>
      )}
    </>
  );
}

export function SidecarHeader({
  icon,
  title,
  subtitle,
  subtitleAs,
  actions,
  onClose,
}: Pick<
  SidecarProps,
  "icon" | "title" | "subtitle" | "subtitleAs" | "actions" | "onClose"
>) {
  return (
    <div className="flex justify-between space-x-2 border-b px-4 py-2 dark:border-blue-steel-850">
      <div
        // Both min-w-0 here and below are required for text truncating to work
        className="flex min-w-0 items-center space-x-1"
      >
        {icon && (
          <div
            // Avoid the icon being squeezed when the title is long
            className="shrink-0"
          >
            {icon}
          </div>
        )}
        <div className="flex min-w-0 flex-col">
          <Title level={2} size="xxs" addClassName="flex" truncate>
            {title}
          </Title>
          {subtitle && (
            <Body
              size="sm"
              addClassName="flex text-secondary"
              truncate
              as={subtitleAs}
            >
              {subtitle}
            </Body>
          )}
        </div>
      </div>
      <div className="flex space-x-2">
        {actions}
        <CloseSidecarButton onClose={onClose} />
      </div>
    </div>
  );
}

export type OverlayMaskProps = {
  pinned?: boolean;
  isOpen: boolean;
  label?: string;
  /**
   * Used to track when the mask is clicked on so you can close the mask/popup.
   */
  onClick?: () => unknown;
};

/**
 * The OverlayMask component is used to create a mask, often behind a Modal or some other popup.
 */
export function OverlayMask({
  pinned = false,
  isOpen,
  label,
  onClick,
}: OverlayMaskProps): ReactElement {
  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
    config: { clamp: true },
  });
  const classes = classNames(
    "inset-0 z-10 bg-black",
    pinned ? "absolute" : "fixed",
    onClick && "cursor-default",
  );

  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className={classes}
          aria-label={label}
          onClick={onClick}
        />
      ),
  );
}

export function CloseSidecarButton({ onClose }: { onClose: () => unknown }) {
  const intl = useIntl();
  return (
    <Button
      onClick={onClose}
      appearance="tertiary-clear"
      aria-label={intl.formatMessage({
        defaultMessage: "Close",
        id: "Wg61eK",

        description: "Close sidecar",
      })}
      size="sm"
      icon={<CloseIcon />}
    />
  );
}
