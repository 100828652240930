import { type ReactElement, type ReactNode } from "react";

export type FormLayoutProps = {
  children: ReactNode;
};

/**
 * FormLayout will properly space multiple inputs inside of a form.
 */
export const FormLayout = ({ children }: FormLayoutProps): ReactElement => {
  return <div className="space-y-4">{children}</div>;
};
