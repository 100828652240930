import {
  createContext,
  useCallback,
  useContext,
  useState,
  type CSSProperties,
  type Dispatch,
  type FC,
  type ReactNode,
  type RefObject,
  type SetStateAction,
} from "react";
import { classNames, noop } from "utils";

import { FocusOn } from "react-focus-on";
import { useDarkMode } from "../../utils";
import { Card } from "../Card";
import { Portal } from "../Portal";
import { CloseConfirmation } from "./CloseConfirmation";

// See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role

// NOTE: `react-focus-on` may not play well with other instances on the page at the same time.
// See https://github.com/theKashey/react-focus-lock#warning
// I don't expect this to be an issue as long as there is only one focus lock controlling one part
// of the page at a time. If necessary, we can use the `whitelist` prop, as outlined
// above to specify control of different parts of the app.

// TODO: Connect aria-labelledby to Title in ModalHeader, if present
// TODO: Create an API to easily connect aria-describedby.

type Shard = RefObject<HTMLElement> | HTMLElement;
type BodyMeasurement = {
  maxHeight?: string;
  height?: string;
  width: string;
};

const sizes = {
  sm: { maxHeight: "48vh", width: "420px" },
  md: { maxHeight: "72vh", width: "576px" },
  lg: { maxHeight: "84vh", width: "816px" },
  xl: { maxHeight: "92vh", width: "1152px" },
  full: { maxHeight: "92vh", height: "92vh", width: "92vw" },
};

const widthOffset = 35;
const maxHeightOffset = "5.625rem";

type Sizes = keyof typeof sizes;
const bodyMeasurements: Record<Sizes, BodyMeasurement> = {
  sm: {
    maxHeight: `calc(${sizes.sm.maxHeight} - ${maxHeightOffset})`,
    width: `calc(${sizes.sm.width} - ${widthOffset})`,
  },
  md: {
    maxHeight: `calc(${sizes.md.maxHeight} - ${maxHeightOffset})`,
    width: `calc(${sizes.md.width} - ${widthOffset})`,
  },
  lg: {
    maxHeight: `calc(${sizes.lg.maxHeight} - ${maxHeightOffset})`,
    width: `calc(${sizes.lg.width} - ${widthOffset})`,
  },
  xl: {
    maxHeight: `calc(${sizes.xl.maxHeight} - ${maxHeightOffset})`,
    width: `calc(${sizes.xl.width} - ${widthOffset})`,
  },
  full: {
    maxHeight: `calc(${sizes.full.height} - ${maxHeightOffset})`,
    height: `calc(${sizes.full.height} - ${maxHeightOffset})`,
    width: `calc(${sizes.full.width} - ${widthOffset})`,
  },
};

export interface ModalProps {
  /**
   * The contents of the Modal. Generally, use ModalHeader, ModalBody, and ModalFooter.
   */
  children: ReactNode;
  /**
   * A component, likely a ConfirmationDialog, that will be displayed when
   * showConfirmationDialog is true
   */
  confirmationDialog?: ReactNode;
  /**
   * Handle the closing of a Modal.  By default a Modal will close when clicked
   * outside, and when the Escape Key is pressed.  That is configurable with
   * the eventsToClose prop.
   */
  onClose?: () => void;
  isOpen: boolean;
  size?: Sizes;
  /**
   * If you are using an element in the modal that renders to a different DOM tree, pass
   * a ref to the shards to ensure the focus lock works correctly with the detatched element.
   */
  shards?: Shard[];
  style?: CSSProperties;
  /**
   * When true, a confirmation will be added to onClose to ensure the user wants to close it.
   */
  confirmClose?: boolean;
  /**
   * An object that determines what events should close the Modal.  When the user clicks
   * outside the Modal?  When the Escape Key is pressed?  By default all events will
   * close the Modal.
   */
  eventsToClose?: { enableClickOutside?: boolean; enableEscapeKey?: boolean };
}

const defaultEventsToClose = {
  enableClickOutside: true,
  enableEscapeKey: true,
};

const invariantViolation = () => {
  throw new Error("Attempted to call useModalContext outside of Modal.");
};

export interface ModalContextProps {
  closeModal: () => void;
  closeModalWithConfirm: () => void;
  size: Sizes;
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
}

export const ModalContext = createContext<ModalContextProps>({
  closeModal: invariantViolation,
  closeModalWithConfirm: invariantViolation,
  size: "md",
  isFullScreen: false,
  setIsFullScreen: invariantViolation,
});

export const useModalContext = (): ModalContextProps =>
  useContext(ModalContext);

export const ModalConfirmCloseContext =
  createContext<Dispatch<SetStateAction<boolean>>>(noop);

export const useModalConfirmCloseContext = (): Dispatch<
  SetStateAction<boolean>
> => useContext(ModalConfirmCloseContext);

/**
 * Renders its children in a focused mode in front of a backdrop.
 *
 * When the Modal is open, all other actions on the page are disabled and focus is trapped within the Modal.
 *
 * Set the initial focus within in the modal with one of two attributes:
 *
 *   - `data-autofocus` - the specific element will get focus on mount
 *   - `data-autofocus-inside` - the first interactive child will get focus on mount
 *
 *
 * ### Import Guide
 *
 * ```jsx
 * import {
 * useModal,
 * Modal,
 * ModalBody,
 * ModalFooter,
 * ModalHeader,
 * ModalCloseButton,
 * ConfirmationDialog
 * } from '@prismacloud/react-ui';
 * ```
 */
export const Modal: FC<ModalProps> & {
  bodyMeasurements: typeof bodyMeasurements;
} = ({
  children,
  confirmClose: incomingConfirmClose,
  confirmationDialog,
  eventsToClose = defaultEventsToClose,
  onClose = noop,
  isOpen,
  size = "md",
  shards,
  style = {},
}: ModalProps) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState(size === "full");
  const [internalConfirmClose, setInternalConfirmClose] =
    useState<boolean>(false);
  const { isDark } = useDarkMode();
  const confirmClose = incomingConfirmClose ?? internalConfirmClose;
  const handleOnClose = useCallback(() => {
    confirmClose ? setShowConfirmation(true) : onClose();
  }, [confirmClose, setShowConfirmation, onClose]);

  if (!isOpen) {
    return null;
  }

  const dialogClassName = classNames(
    "fixed top-0 max-w-full -translate-x-1/2 translate-y-10",
  );

  const maskClassName = classNames(
    "fixed inset-0 bg-gray-900/75 dark:bg-black/30",
  );

  const shouldHandleOnClose = { ...defaultEventsToClose, ...eventsToClose };

  return (
    <Portal>
      <div
        className={maskClassName}
        style={{ zIndex: 15 }}
        onClick={
          shouldHandleOnClose["enableClickOutside"] ? handleOnClose : undefined
        }
      />
      <FocusOn
        noIsolation
        onEscapeKey={
          shouldHandleOnClose["enableEscapeKey"] ? handleOnClose : undefined
        }
        shards={shards}
      >
        <ModalContext.Provider
          value={{
            closeModal: onClose,
            closeModalWithConfirm: handleOnClose,
            size: isFullScreen ? "full" : size,
            isFullScreen,
            setIsFullScreen,
          }}
        >
          <Card
            addClassName={dialogClassName}
            aria-modal={true}
            role="dialog"
            appearance={isDark ? "glass" : "primary"}
            style={{
              ...sizes[isFullScreen ? "full" : size],
              zIndex: 15,
              ...style,
              minHeight: style.minHeight
                ? `min(${
                    typeof style.minHeight === "number"
                      ? `${style.minHeight}px`
                      : style.minHeight
                  }, ${sizes[size].maxHeight})`
                : 0,
              left: "50%",
            }}
          >
            <ModalConfirmCloseContext.Provider value={setInternalConfirmClose}>
              <div
                className={classNames(
                  "flex flex-1 flex-col overflow-auto",
                  (showConfirmation || !!confirmationDialog) && "opacity-0",
                )}
              >
                {children}
              </div>
            </ModalConfirmCloseContext.Provider>
            {confirmClose && showConfirmation && (
              <div className="fixed inset-0 z-10 rounded">
                <CloseConfirmation
                  onCancel={() => {
                    setShowConfirmation(false);
                  }}
                  onDiscard={() => {
                    setShowConfirmation(false);
                    onClose();
                  }}
                />
              </div>
            )}
            {confirmationDialog && (
              <div className="absolute inset-0">{confirmationDialog}</div>
            )}
          </Card>
        </ModalContext.Provider>
      </FocusOn>
    </Portal>
  );
};
Modal.bodyMeasurements = bodyMeasurements;
