import { isChina } from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFallbackPermissions } from "../useFallbackPermissions";
import { usePermissions } from "../usePermissions";
import { useTenantsFeatures } from "../useTenantsFeatures";

export function useCodeEnabled() {
  const { pcBridgecrewEnabled } = useFlags();
  const permissions = usePermissions();
  const { data, isLoading } = useTenantsFeatures();
  const enabled = data?.find((feature) => feature.feature_name === "bridgecrew")
    ?.enabled;

  const fallbackPermissions = useFallbackPermissions();

  const repositories = fallbackPermissions("repositories");
  const technologies = fallbackPermissions("technologies");
  const sbom = fallbackPermissions("sbom");
  const pipelineRisks = fallbackPermissions("pipelineRisks");
  const projects = permissions.codeSecurityProjects.READ;

  const navItems = [repositories, technologies, sbom, pipelineRisks, projects];

  const codeEnabled = !isChina() && !!pcBridgecrewEnabled && !!enabled;

  if (!codeEnabled || navItems.every((x) => !x))
    return { codeEnabled: false, isLoading };

  return { codeEnabled, isLoading };
}
