import { Body, Bold } from "ui";
import { classNames } from "utils";

import { CheckIcon, CloseIcon } from "icons";
import { type ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { passwordValidators } from "./NewPassword";

export function PasswordCheck() {
  const password = useWatch({ name: "newPassword" });

  return (
    <div>
      <Body appearance="secondary" size="sm">
        <Bold>
          <FormattedMessage
            defaultMessage="Make sure your new password:"
            id="SeBoSl"
            description="Preface for password requirements"
          />
        </Bold>
      </Body>

      <ul>
        <Check valid={passwordValidators.hasNumber(password)}>
          <FormattedMessage
            defaultMessage="Contains at least 1 digit (0-9)."
            id="vT9flX"
            description="Password number requirement label"
          />
        </Check>
        <Check valid={passwordValidators.hasLowercase(password)}>
          <FormattedMessage
            defaultMessage="Contains at least 1 lowercase letter (a-z)."
            id="2e7shD"
            description="Password lowercase letter requirement label"
          />
        </Check>
        <Check valid={passwordValidators.hasUppercase(password)}>
          <FormattedMessage
            defaultMessage="Contains at least 1 uppercase letter (A-Z)."
            id="hZvRfL"
            description="Password lowercase letter requirement label"
          />
        </Check>
        <Check valid={passwordValidators.hasSymbols(password)}>
          <FormattedMessage
            defaultMessage="Contains at least 1 special character ({characters})."
            id="hmjZZp"
            description="Password special character requirement label"
            values={{ characters: "~`!@#$%^&*()-_+={}[]|\\:;',\".<>?/" }}
          />
        </Check>
        <Check
          valid={
            passwordValidators.minLength(password) &&
            passwordValidators.maxLength(password)
          }
        >
          <FormattedMessage
            defaultMessage="Has a minimum of 8 characters and 30 maximum."
            id="+xtrZq"
            description="Password min/max requirement label"
          />
        </Check>
        <Check valid={passwordValidators.noSpaces(password)}>
          <FormattedMessage
            defaultMessage="Does not contain any space."
            id="v874qN"
            description="Password space requirement label"
          />
        </Check>
      </ul>
    </div>
  );
}

function Check({
  valid,
  children,
}: {
  valid: boolean | string;
  children: ReactNode;
}) {
  const isValid = valid === true;
  return (
    <li className="flex items-center text-secondary">
      <div
        className={classNames(
          "mr-2",
          isValid ? "text-icon-green" : "text-icon-red",
        )}
      >
        {isValid ? <CheckIcon /> : <CloseIcon />}
      </div>
      {children}
    </li>
  );
}
