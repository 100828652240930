import { useQuery } from "@tanstack/react-query";
import { CloudTypeIcon } from "icons";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  DynamicFormattedMessage,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SomethingWentWrongErrorPage,
  Subtitle,
  Title,
  useModal,
} from "ui";
import {
  AlibabaOnboarding,
  AwsOnboarding,
  AzureOnboarding,
  GcpOnboarding,
  IbmOnboarding,
  OciOnboarding,
} from "../Onboarding";

import { isChina } from "environment";
import { getCloudTypeListItems, listItemKeys } from "requests";
import { type RenderModalProps } from "ui";
import {
  CHINA_CLOUD_TYPES,
  CLOUD_TYPES,
  FREEMIUM_CLOUDS,
  WIZARD_HEIGHT,
} from "../../constants";
import {
  type CloudType,
  type CloudTypes,
  type OnboardingProps,
} from "../../types";
import { cloudTypeSort } from "../../utils";
import { CloudNames } from "./cloudNames";

type CloudSelectionProps = RenderModalProps<{
  fromFreemium: boolean;
}>;

export default function CloudSelection({
  closeModal,
  fromFreemium,
  isOpen,
}: CloudSelectionProps) {
  const intl = useIntl();
  const {
    data = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: listItemKeys.cloudTypes,
    queryFn: getCloudTypeListItems,
  });

  const accessibleCloudTypes = cloudTypeSort(
    isChina() ? CHINA_CLOUD_TYPES : CLOUD_TYPES,
  );

  let tenantCloudTypes = cloudTypeSort(data as CloudTypes).filter(
    (cloudType) => accessibleCloudTypes.indexOf(cloudType) !== -1,
  );

  if (fromFreemium) {
    tenantCloudTypes = tenantCloudTypes.filter((cloud) =>
      FREEMIUM_CLOUDS.includes(cloud),
    );
  }

  const cloudCards = tenantCloudTypes.map((cloudType) => (
    <CloudCard
      cloudType={cloudType}
      key={cloudType}
      closeModal={closeModal}
      fromFreemium={fromFreemium}
    />
  ));

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      size="xl"
      style={{ minHeight: WIZARD_HEIGHT }}
    >
      <ModalHeader
        enableClose
        title={intl.formatMessage({
          description: "Modal title for Add Cloud Account",
          defaultMessage: "Connect Provider",
          id: "ArI0Gi",
        })}
      />
      <ModalBody
        isLoading={isLoading}
        addClassName={isLoading ? "flex items-center justify-center" : ""}
      >
        {isError ? (
          <SomethingWentWrongErrorPage error={error} />
        ) : (
          <>
            <section className="mb-4 flex flex-col">
              <Title level={2} size="xs">
                <FormattedMessage
                  defaultMessage="Cloud Providers"
                  id="35RZwi"
                  description="Title for Cloud selection screen"
                />
              </Title>
              <Subtitle appearance="semibold" size="sm">
                <FormattedMessage
                  defaultMessage="Onboard and secure your accounts across multiple cloud providers"
                  id="FWmnjP"
                  description="Subtitle for Cloud selection screen"
                />
              </Subtitle>
            </section>
            <section className="-m-2 flex flex-wrap content-start">
              {cloudCards}
            </section>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button data-testid="cancel-cloud-selection" onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Cancel"
            id="qz9XeG"
            description="Cancel button"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type CloudCardProps = {
  cloudType: CloudType;
  closeModal: (...args: unknown[]) => unknown;
  fromFreemium: boolean;
};

const cloudWizards: Record<string, React.FC<OnboardingProps>> = {
  aws: AwsOnboarding,
  alibaba_cloud: AlibabaOnboarding,
  azure: AzureOnboarding,
  gcp: GcpOnboarding,
  ibm: IbmOnboarding,
  oci: OciOnboarding,
};

function CloudCard({ cloudType, closeModal, fromFreemium }: CloudCardProps) {
  const CloudWizard = cloudWizards[cloudType];
  const { openModal: openCloudAccountWizard } = useModal(CloudWizard);

  const cloudCardProps = {
    "data-testid": `cloudSelection${cloudType}`,
    onClick: () => {
      closeModal();
      openCloudAccountWizard({ fromFreemium, accountId: "" });
    },
  };

  return (
    <div className="flex h-28 w-1/3 p-2">
      <div
        role="button"
        className="flex h-full w-full items-center space-x-2 rounded border border-gray-300 p-4 hover:shadow-md dark:border-blue-steel-850 dark:bg-blue-steel-940"
        {...cloudCardProps}
      >
        <div className="flex h-8 w-8 items-center justify-center rounded bg-gray-100 dark:bg-blue-steel-800">
          <CloudTypeIcon showNativeTooltip={false} type={cloudType} size="lg" />
        </div>
        <Title level={3} size="xxs" addClassName="font-medium">
          <DynamicFormattedMessage messageMap={CloudNames} token={cloudType} />
        </Title>
      </div>
    </div>
  );
}
