import { CloudTypeIcon } from "icons";
import { type cloudTypesWithOther } from "requests";
import { DynamicFormattedMessage, Title, useModal } from "ui";
import { CloudNames } from "../../../../cloudAccounts/CloudOnboarding/CloudSelection/cloudNames";
import {
  AlibabaOnboarding,
  AzureOnboarding,
  GcpOnboarding,
  IbmOnboarding,
  OciOnboarding,
} from "../../../../cloudAccounts/CloudOnboarding/Onboarding";
import { AwsOnboarding } from "../../../../cloudAccounts/CloudOnboarding/SimplifiedOnboarding";
import {
  type OnboardProvidersType,
  type OnboardingProps,
} from "../../../../cloudAccounts/types";

type CloudCardProps = {
  cloudType: Exclude<cloudTypesWithOther, "all">;
  closeModal: (...args: unknown[]) => unknown;
  fromFreemium: boolean;
  fromProvidersPage: OnboardProvidersType;
};

const cloudWizards: Record<string, React.FC<OnboardingProps>> = {
  aws: AwsOnboarding,
  alibaba_cloud: AlibabaOnboarding,
  azure: AzureOnboarding,
  gcp: GcpOnboarding,
  ibm: IbmOnboarding,
  oci: OciOnboarding,
};

export default function CloudCard({
  cloudType,
  closeModal,
  fromFreemium,
  fromProvidersPage,
}: CloudCardProps) {
  const CloudWizard = cloudWizards[cloudType];
  const { openModal: openCloudAccountWizard } = useModal(CloudWizard);

  const cloudCardProps = {
    "data-testid": `cloudSelection${cloudType}`,
    onClick: () => {
      closeModal();
      openCloudAccountWizard({
        fromFreemium,
        accountId: "",
        fromProvidersPage,
      });
    },
  };

  return (
    <div className="flex h-24 w-1/4 p-2">
      <div
        role="button"
        className="flex h-full w-full items-center space-x-2 rounded border border-gray-300 p-4 hover:shadow-md dark:border-blue-steel-850 dark:bg-blue-steel-940"
        {...cloudCardProps}
      >
        <CloudTypeIcon showNativeTooltip={false} type={cloudType} size="xl" />
        <Title level={3} size="xxs" addClassName="font-medium">
          <DynamicFormattedMessage messageMap={CloudNames} token={cloudType} />
        </Title>
      </div>
    </div>
  );
}
