import {
  useMutation,
  useQuery,
  useQueryClient,
  type DefinedInitialDataOptions,
} from "@tanstack/react-query";
import { type z } from "zod";
import {
  deletePreference,
  getPreferences,
  preferencesKeys,
  updatePreference,
  type PageName,
  type Preferences,
} from "../../requests";

/**
 * A hook that returns the data for the passed in page's feature.
 * page and feature combined need to be unique throughout the application.
 *
 * Returns the data, an update function to update the data for that page's feature,
 * a remove function to remove the feature preferences from the page, as well
 * as everything returned from useQuery.
 *
 * @param page
 * @param feature
 * @param schema // Provide the page/feature schema from requests/preferences/schemas.ts
 *                  that is returned by PreferencesSchema[page][feature] to enable
 *                  type checking in the editor.  This is purely a developer feature.
 *                  The actual schema used will be derived from PreferencesSchema using the
 *                  page and feature passed in.  But the editor can't use that for type checking
 *                  because the page and feature are only known at runtime.
 */
export function usePreferences<
  P extends PageName,
  F extends keyof Required<Preferences>[P],
  S extends z.ZodTypeAny,
>(
  page: P,
  feature: F,
  _schema: S,
  queryOptions?: DefinedInitialDataOptions<
    z.infer<S>,
    unknown,
    z.infer<S>,
    typeof preferencesKeys.all
  >,
) {
  type PageFeaturePreferences = z.infer<S>;

  const cacheKey = preferencesKeys.all;
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: cacheKey,
    queryFn: getPreferences,
    staleTime: 5 * 60 * 1000,
    ...queryOptions,
    select: (data) => {
      return data[page]?.[feature] as z.infer<S>;
    },
  });

  const update = useMutation({
    mutationFn: (preferences: PageFeaturePreferences) => {
      return updatePreference(page, feature, preferences);
    },
    onError: (_err, _newPreferences, rollback: (() => void) | undefined) => {
      rollback?.();
    },
    onMutate: (newPreferences) => {
      queryClient.cancelQueries({ queryKey: cacheKey });
      const previousPreferences = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: Preferences = {}) => {
        const clone = JSON.parse(JSON.stringify(old));
        const pageData = clone?.[page] ?? {};
        return {
          ...clone,
          [page]: {
            ...pageData,
            [feature]: newPreferences,
          },
        };
      });

      return () => {
        queryClient.setQueryData(cacheKey, previousPreferences);
      };
    },
  });

  const remove = useMutation({
    mutationFn: () => deletePreference(page, feature),
    onError: (_err, _newPreferences, rollback) => rollback?.(),
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: cacheKey });
      const previousPreferences = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: Preferences = {}) => {
        const clone = JSON.parse(JSON.stringify(old));
        const pageData = clone?.[page] ?? {};

        delete pageData[feature];

        return { ...clone };
      });

      return () => {
        queryClient.setQueryData(cacheKey, previousPreferences);
      };
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });

  return {
    ...query,
    remove,
    update,
  };
}
