import { type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { type CloudTrailBucketType } from "requests";
import { Button, Select, type SelectItem } from "ui";

type CloudTrailSelectPropTypes = {
  cloudTrailName?: string;
  cloudTrailObjects?: CloudTrailBucketType[];
  items: SelectItem[];
  setCloudTrailBucket: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailName: Dispatch<SetStateAction<string | undefined>>;
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
  setTopicForSelectedCloudTrail: Dispatch<SetStateAction<string | undefined>>;
};

export default function CloudTrailSelect({
  cloudTrailName = "",
  cloudTrailObjects,
  items,
  setCloudTrailBucket,
  setCloudTrailName,
  setCloudTrailTopic,
  setTopicForSelectedCloudTrail,
}: CloudTrailSelectPropTypes) {
  const intl = useIntl();

  const description = intl.formatMessage({
    defaultMessage: "Select a CloudTrail",
    id: "8GNfSc",
    description: "Wizard field instruction",
  });

  const buttonLabel = cloudTrailName.length ? cloudTrailName : description;

  const handleOnChange = (selectedItem: SelectItem) => {
    const matchingCloudTrail = cloudTrailObjects?.find(
      (clTrail) => clTrail.name === selectedItem?.value,
    );

    const { bucket, name, snsTopic } =
      matchingCloudTrail as CloudTrailBucketType;

    // set values in wizard state
    setCloudTrailBucket(bucket);
    setCloudTrailName(name);
    setCloudTrailTopic(snsTopic);
    setTopicForSelectedCloudTrail(snsTopic);
  };

  return (
    <Select
      button={
        <Button addClassName="min-w-full" isMenu>
          {buttonLabel}
        </Button>
      }
      enableSearch
      items={items}
      onChange={({ selectedItem }) =>
        handleOnChange(selectedItem as SelectItem)
      }
      selectedItem={{ value: cloudTrailName }}
    />
  );
}
