import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { FeatureNamesSchema } from "../profile";
import {
  PermissionGroup,
  PermissionGroupsResponseSchema,
  type PermissionGroupData,
} from "./schemas";

export const permissionGroupsKeys = {
  all: [{ scope: "permissionGroups" }] as const,
  features: () =>
    [
      {
        ...permissionGroupsKeys.all[0],
        entity: "feature",
      },
    ] as const,
  permissionGroups: (params: {
    includeAssociatedRoles?: "true" | "false";
    includeFeatures?: "true" | "false";
  }) =>
    [
      {
        ...permissionGroupsKeys.all[0],
        entity: "permissionGroup",
        params,
      },
    ] as const,
  permissionGroup: ({ id }: { id: string }) =>
    [
      {
        ...permissionGroupsKeys.permissionGroups({})[0],
        id,
      },
    ] as const,
} as const;

export const getPermissionGroups = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof permissionGroupsKeys)["permissionGroups"]>
>) => {
  const { ...rest } = params ?? {};
  return jsonApi({
    path: "authz/v1/permission_group",
    params: rest,
    requestSchema: z.void(),
    responseSchema: PermissionGroupsResponseSchema,
  });
};

export const getPermissionGroup = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<
  ReturnType<(typeof permissionGroupsKeys)["permissionGroup"]>
>) => {
  return jsonApi({
    path: `authz/v1/permission_group/${id}`,
    requestSchema: z.void(),
    responseSchema: PermissionGroup,
  });
};

export const editPermissionGroup = async ({
  permissionGroup,
  permissionGroupId,
}: {
  permissionGroup: PermissionGroupData;
  permissionGroupId: string;
}) => {
  return jsonApi({
    path: `authz/v1/permission_group/${permissionGroupId}`,
    body: permissionGroup,
    config: {
      method: "put",
    },
    requestSchema: PermissionGroup,
    responseSchema: z.string(),
  });
};

export const newPermissionGroup = async (
  permissionGroup: Omit<PermissionGroupData, "id">,
) => {
  return jsonApi({
    path: "authz/v1/permission_group",
    body: permissionGroup,
    config: {
      method: "post",
    },
    requestSchema: PermissionGroup.omit({ id: true }),
    responseSchema: z.void(),
  });
};

export const deletePermissionGroup = async ({ id }: { id: string }) => {
  return jsonApi({
    path: `authz/v1/permission_group/${id}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};

export const getFeatures = async () => {
  return jsonApi({
    path: "authz/v1/feature",
    requestSchema: z.void(),
    responseSchema: FeatureNamesSchema,
  });
};
