import { ExternalLinkIcon } from "icons";
import {
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { bodyAppearanceVariants, sizeVariants } from "../Typography";

export type LinkProps = Omit<ComponentPropsWithoutRef<"a">, "children"> & {
  children: ReactNode;
  /**
   * Add a class name to the element.
   */
  addClassName?: string;
  /**
   * The font size.
   */
  size?: keyof typeof sizeVariants;
  /**
   * Sets `target='_blank'` and `rel='noreferrer'` on the link.
   */
  external?: boolean;
  /**
   * Disable the link (false by default)
   */
  disabled?: boolean;
  /**
   * This prop is to be used if you would like to disable all of the
   * associated styles with the Link. It should be used in cases
   * where you are wrapping another component with <Link> with
   * the intention of utilizing the other component's styles.
   *
   * For Example:
   * <Link withChild to="/">
   *   <Button as="span">Link that looks like a button</Button>
   * </Link>
   */
  withChild?: boolean;
};

/**
 * An anchor element styled properly.
 */
export function Link({
  children,
  external = false,
  addClassName,
  disabled = false,
  size = "md",
  withChild,
  ...props
}: LinkProps): ReactElement {
  const attributes: Record<string, string> = {};

  if (external) {
    attributes["target"] = "_blank";
    attributes["rel"] = "noreferrer";
  }

  const classes = classNames(
    external && "inline-flex items-center",
    disabled && "pointer-events-none opacity-25",
    addClassName,
  );

  return (
    <a
      {...attributes}
      {...props}
      className={classNames(
        !withChild && bodyAppearanceVariants.link,
        sizeVariants[size],
        classes,
      )}
    >
      {children}
      {external && <ExternalLinkIcon className="ml-1 shrink-0" size="xs" />}
    </a>
  );
}
