import { FormattedMessage } from "react-intl";
import { Body, Title } from "ui";
import { useWizardContext } from "../../../../../../components/Wizard";
import { ADD_DETAILS } from "../../../../../constants";
import { type AwsAddDetailsStepValues } from "../../../../../types";
import { useAWSSelectMemberAccountsContext } from "../../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext";
import { setAccountId } from "../../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext/state/actions";
import AccountId from "../../../../Onboarding/components/AccountId";
import { getWizardStepIndex } from "../../../../Onboarding/utils";

export default function AccountDetails() {
  const {
    state: { steps },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);

  const { onboardType, accountType } = (steps[addDetailsStepIndex]?.values ??
    {}) as AwsAddDetailsStepValues;

  const { dispatch: selectMemberAccountsDispatch } =
    useAWSSelectMemberAccountsContext();

  const onAccountIdBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => selectMemberAccountsDispatch(setAccountId(event?.target?.value ?? ""));

  return (
    <section className="space-y-2">
      <Title
        level={5}
        size="xxs"
        addClassName="text-secondary dark:text-dark-bg-secondary"
      >
        <FormattedMessage
          defaultMessage="{accountType, select, organization {Organization} other {Account}} Details"
          id="qNi8Cb"
          description="Title for Account Details section"
          values={{
            accountType,
          }}
        />
      </Title>

      <div className="space-y-4">
        {onboardType === "template" && (
          <>
            <div className="space-y-4">
              <Body
                size="sm"
                appearance="secondary"
                addClassName="mb-1 font-bold"
              >
                <FormattedMessage
                  defaultMessage="Account ID"
                  id="r7hgjl"
                  description="The name of an input field account id for onboarding an AWS account"
                />
              </Body>
              <AccountId
                isEdit={false}
                onBlur={onAccountIdBlur}
                showLabel={false}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
}
