import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

export const AssetByCloudType = lazy(() => import("./AssetByCloudType"));

export const AssetByResourceType = lazy(() => import("./AssetByResourceType"));

export const AssetList = lazy(() => import("./AssetList"));

export const unmanagedAssetRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "asset/unmanaged",
  component: AssetByCloudType,
});

export const unmanagedAssetByResourceTypeRoute = new Route({
  getParentRoute: () => unmanagedAssetRoute,
  path: "$cloudType",
  component: AssetByResourceType,
});

export const unmanagedAssetListRoute = new Route({
  getParentRoute: () => unmanagedAssetByResourceTypeRoute,
  path: "list",
  component: AssetList,
});
