import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const NotionIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Notion",
    id: "4Z01Ti",
    description: "Notion alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 26" {...props}>
      <title>{alt}</title>
      <g clipPath="url(#notion_svg__a)">
        <path
          d="M3.97 5.192c.747.607 1.027.56 2.428.467l13.215-.794c.28 0 .047-.28-.046-.326l-2.195-1.586c-.42-.327-.98-.7-2.054-.607l-12.796.933c-.467.046-.56.28-.374.467L3.97 5.192Zm.793 3.08v13.904c0 .747.374 1.027 1.214.98l14.523-.84c.841-.046.935-.56.935-1.167V7.34c0-.607-.233-.934-.748-.887L5.51 7.338c-.56.047-.747.327-.747.934Zm14.338.746c.093.42 0 .84-.421.887l-.7.14V20.31c-.608.326-1.168.513-1.635.513-.747 0-.935-.233-1.494-.933l-4.578-7.186v6.953l1.449.326s0 .84-1.169.84l-3.221.187c-.094-.187 0-.653.327-.747l.84-.233v-9.192l-1.167-.093c-.094-.42.14-1.027.794-1.074l3.456-.233 4.763 7.28v-6.44l-1.214-.14c-.094-.513.28-.886.746-.933l3.224-.187ZM1.447 2.019l13.31-.98c1.635-.14 2.055-.046 3.083.7l4.248 2.986c.701.514.935.654.935 1.213v16.378c0 1.027-.374 1.634-1.681 1.727l-15.457.933c-.981.047-1.449-.093-1.962-.747l-3.13-4.06C.234 19.424 0 18.865 0 18.21V3.653c0-.84.374-1.54 1.447-1.633Z"
          fill="#000"
          className="dark:fill-white"
        />
      </g>
      <defs>
        <clipPath id="notion_svg__a">
          <path
            fill="#fff"
            className="dark:fill-white"
            transform="translate(0 .984)"
            d="M0 0h24v24H0z"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
