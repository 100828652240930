import { type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ftuExperiencePreferenceSchema, usePreferences } from "requests";
import { Title, useDarkMode } from "ui";
import { textureStyle } from "../../pages/ftuExperience/components/PrismaUILayout";
import UCDSubNavHeaderDark from "./UCDSubNavHeaderDark.webp";
import UCDSubNavHeaderLight from "./UCDSubNavHeaderLight.webp";

export function UCDSubNav({
  children,
  headerText,
}: {
  children: ReactNode;
  headerText: ReactNode;
}) {
  const intl = useIntl();
  const { isDark } = useDarkMode();
  const { data: ftuData } = usePreferences(
    "ftuExperience",
    "config",
    ftuExperiencePreferenceSchema,
  );

  return (
    <nav
      className="m-4 flex w-60 shrink-0 flex-col space-y-2 overflow-auto rounded-2xl border border-gray-300 backdrop-blur-2xl duration-500 animate-in motion-safe:slide-in-from-top dark:border-blue-steel-850"
      aria-label={intl.formatMessage({
        defaultMessage: "Sub Navigation",
        id: "y5FPKA",
        description: "sub nav label",
      })}
      style={{
        ...textureStyle(ftuData?.config?.theme?.background === "sand", isDark),
      }}
    >
      <div
        className="h-[102px] p-4"
        style={{
          backgroundImage: `url(${
            isDark ? UCDSubNavHeaderDark : UCDSubNavHeaderLight
          })`,
        }}
      >
        <Title level={1} size="xxs">
          {headerText}
        </Title>
        <div className={`py-2 text-xs text-[#707070] dark:text-[#DADBDB]`}>
          <FormattedMessage
            defaultMessage="This hub offers you navigation to workflows specific to this focus."
            id="7FzZfv"
            description="Information about hub menu"
          />
        </div>
      </div>
      <div
        className="flex w-full shrink-0 grow flex-col space-y-2 overflow-auto rounded-b-2xl bg-gray-100/30 p-4 dark:bg-blue-steel-990/30"
        style={{ marginTop: 0 }}
      >
        {children}
      </div>
    </nav>
  );
}
