import { defineMessage } from "react-intl";

export const requiredMessage = defineMessage({
  defaultMessage: "{label} is required",
  id: "bAUoRd",

  description: "Error message indicating field with label is required",
});

export const inputValueExistsMessage = defineMessage({
  defaultMessage: "The value already exists",
  id: "XsII4r",

  description: "Error message when the value in ChipInput already exists",
});
