import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const RemediationIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Remediation",
    id: "2XZa1F",

    description: "Security Capabilities Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <title>{alt}</title>
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="M7.125 3.375L7.5625 2.5L8.4375 2.0625L7.5625 1.625L7.125 0.75L6.6875 1.625L5.8125 2.0625L6.6875 2.5L7.125 3.375ZM3.1875 5.125L3.89844 3.67578L5.375 2.9375L3.89844 2.22656L3.1875 0.75L2.44922 2.22656L1 2.9375L2.44922 3.67578L3.1875 5.125ZM12.8125 8.625L12.0742 10.1016L10.625 10.8125L12.0742 11.5508L12.8125 13L13.5234 11.5508L15 10.8125L13.5234 10.1016L12.8125 8.625ZM14.7266 3.34766L12.4023 1.02344C12.2383 0.859375 12.0195 0.75 11.8008 0.75C11.5547 0.75 11.3359 0.859375 11.1719 1.02344L1.24609 10.9492C0.890625 11.3047 0.890625 11.8516 1.24609 12.1797L3.57031 14.5039C3.73438 14.668 3.95312 14.75 4.17188 14.75C4.41797 14.75 4.63672 14.668 4.80078 14.5039L14.7266 4.57812C15.082 4.22266 15.082 3.67578 14.7266 3.34766ZM10.8164 6.32812L9.42188 4.93359L11.8008 2.55469L13.1953 3.94922L10.8164 6.32812Z"
      />
    </SvgIcon>
  );
};
