import { defineMessages } from "react-intl";

export const messageMap = defineMessages({
  description: {
    defaultMessage:
      "Prisma Cloud recommends selecting all projects (default). Disable to include or exclude specific projects from monitoring.",
    id: "WBRD18",
    description: "description for Select All Monitored Projects card header",
  },
  title: {
    defaultMessage: "Select All Monitored Projects",
    id: "f5miuJ",
    description: "description for Select All Monitored Projects card header",
  },
  rateLimitLabel: {
    defaultMessage:
      "Prisma Cloud has reached the Google API rate limit and cannot retrieve the GCP folder hierarchy. Please try again later",
    id: "jA2xGh",
    description: "Description for Rate limit warning",
  },
});
