// This is the pendo snippet which downloads the agent from pendo and sets it to a global variable "pendo".
// https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application

/* eslint-disable */
// @ts-nocheck
export const pendoSnippet = function (apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0)),
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
};
/* eslint-enable */

// This has been gathered by going through pendo's documentation online, such as https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application
declare global {
  interface Identity {
    /** visitor.id is required if user is logged in, otherwise an anonymous ID is generated and tracked by a cookie (if enabled for a domain) */
    visitor?: IdentityMetadata | undefined;
    account?: IdentityMetadata | undefined;
    parentAccount?: IdentityMetadata | undefined;
    /** ensure that the same anonymous visitor.id is used on all subdomains  */
    cookieDomain?: IdentityCookieDomain | undefined;
  }

  interface Metadata {
    [key: string]: string | number | boolean | string[] | null;
  }

  type IdentityMetadata = { id?: string | undefined } & Metadata;

  type IdentityCookieDomain = `.${string}`;

  // eslint-disable-next-line no-var
  var pendo:
    | {
        initialize: ({
          visitor,
          account,
          additionalApiKeys,
        }: {
          visitor: Record<
            string,
            | string
            | number
            | Record<string, string | number | undefined>
            | undefined
          >;
          account: Record<
            string,
            | string
            | number
            | Record<string, string | number | undefined>
            | undefined
          >;
          additionalApiKeys?: string[];
        }) => void;
        isReady: () => boolean; // Returns true if pendo has been initialized (docs weren't clear on this lol).
        showGuideById: (id: string, reason?: string) => boolean; // Return false if pendo is not initialized
        track: (message: string, params?: Record<string, unknown>) => void;
        updateOptions(options: Identity): void;
      }
    | undefined;
}
