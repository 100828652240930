import { ComputeLimitSchema } from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";
import { type cloudAccountsKeys } from "../cloudAccountsKeys";

export const getComputeLimit = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["computeLimit"]>
>) =>
  jsonApi({
    path: "cas/api/v1/module/compute/limit",
    ...(accountId && {
      params: {
        accountId,
      },
    }),
    requestSchema: z.void(),
    responseSchema: ComputeLimitSchema,
  });
