import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AmazonSimpleQueueServiceSqsIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Amazon Simple Queue Service SQS",
    id: "/NLbF9",

    description: "Amazon Simple Queue Service SQS icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M28.2231 28.644C31.8817 24.9921 36.4065 22.3275 41.3746 20.8991C46.3426 19.4708 51.5917 19.3254 56.6313 20.4765C61.6708 21.6276 66.3362 24.0376 70.1914 27.4813C74.0466 30.925 76.9658 35.29 78.676 40.1682L81.2814 39.2584C78.9998 32.7331 74.7459 27.0786 69.1087 23.0778C63.4715 19.0769 56.7299 16.9277 49.8172 16.9277C42.9045 16.9277 36.1629 19.0769 30.5257 23.0778C24.8885 27.0786 20.6346 32.7331 18.353 39.2584L20.9584 40.1682C22.4676 35.8222 24.9528 31.8799 28.2231 28.644Z"
        className="fill-[url(#paint0_linear_6619_101870)] dark:fill-white"
      />
      <path
        d="M71.3843 71.3771C65.649 77.1046 57.8749 80.3217 49.7695 80.3217C41.6641 80.3217 33.89 77.1046 28.1547 71.3771C24.8799 68.1126 22.3951 64.1424 20.89 59.7702L18.2847 60.68C20.1373 66.0119 23.3136 70.7859 27.5158 74.5546C31.7179 78.3232 36.8083 80.963 42.3097 82.2264C47.811 83.4898 53.5431 83.3354 58.9685 81.7777C64.3938 80.2201 69.3348 77.3102 73.328 73.3208C76.9316 69.7417 79.6586 65.3776 81.2957 60.5697L78.6904 59.6875C77.1846 64.0932 74.6847 68.0931 71.3843 71.3771Z"
        className="fill-[url(#paint1_linear_6619_101870)] dark:fill-white"
      />
      <path
        d="M26.1008 49.9554C26.1037 48.0924 25.4603 46.286 24.2803 44.8442C23.1003 43.4024 21.4567 42.4146 19.6299 42.0491C17.803 41.6835 15.9059 41.9629 14.262 42.8397C12.6181 43.7164 11.3292 45.1362 10.615 46.8569C9.9008 48.5777 9.80555 50.4929 10.3455 52.276C10.8854 54.0592 12.0271 55.5998 13.5759 56.6354C15.1246 57.6709 16.9847 58.1372 18.8388 57.9547C20.6929 57.7723 22.4264 56.9524 23.7436 55.6348C24.4931 54.8915 25.0875 54.0067 25.4922 53.0317C25.8968 52.0567 26.1037 51.011 26.1008 49.9554ZM21.7586 53.6912C20.752 54.6462 19.4173 55.1786 18.0297 55.1786C16.6422 55.1786 15.3075 54.6462 14.3009 53.6912C13.5671 52.9529 13.0679 52.0142 12.866 50.993C12.6641 49.9718 12.7687 48.9138 13.1664 47.9518C13.5641 46.9899 14.2374 46.1669 15.1014 45.5865C15.9655 45.0061 16.9819 44.694 18.0228 44.6896C19.4227 44.6928 20.7649 45.2475 21.7586 46.2335C22.746 47.2233 23.3005 48.5642 23.3005 49.9623C23.3005 51.3604 22.746 52.7014 21.7586 53.6912Z"
        className="fill-[url(#paint2_linear_6619_101870)] dark:fill-white"
      />
      <path
        d="M87.6509 44.3728C86.529 43.2506 85.0995 42.4864 83.5431 42.1767C81.9868 41.867 80.3736 42.0258 78.9075 42.633C77.4415 43.2402 76.1884 44.2685 75.3068 45.5879C74.4252 46.9073 73.9546 48.4585 73.9546 50.0453C73.9546 51.6321 74.4252 53.1833 75.3068 54.5027C76.1884 55.8221 77.4415 56.8504 78.9075 57.4576C80.3736 58.0648 81.9868 58.2236 83.5431 57.9139C85.0995 57.6042 86.529 56.84 87.6509 55.7178C89.1552 54.2133 90.0003 52.1729 90.0003 50.0453C90.0003 47.9178 89.1552 45.8773 87.6509 44.3728ZM85.6935 53.7604C84.7061 54.7469 83.3674 55.3011 81.9715 55.3011C80.5757 55.3011 79.237 54.7469 78.2496 53.7604C77.7594 53.2724 77.3705 52.6924 77.1051 52.0536C76.8397 51.4149 76.7031 50.7301 76.7031 50.0384C76.7031 49.3468 76.8397 48.6619 77.1051 48.0232C77.3705 47.3845 77.7594 46.8045 78.2496 46.3165C78.733 45.8041 79.3143 45.3938 79.959 45.1101C80.6038 44.8263 81.299 44.6748 82.0033 44.6646C82.7077 44.6543 83.407 44.7855 84.0597 45.0503C84.7125 45.3151 85.3055 45.7082 85.8036 46.2064C86.3017 46.7045 86.6948 47.2975 86.9597 47.9502C87.2245 48.603 87.3556 49.3023 87.3454 50.0066C87.3351 50.711 87.1836 51.4061 86.8999 52.0509C86.6161 52.6957 86.2059 53.277 85.6935 53.7604Z"
        className="fill-[url(#paint3_linear_6619_101870)] dark:fill-white"
      />
      <path
        d="M38.5481 63.3543C44.2 57.7024 55.7104 57.7024 61.3623 63.3543C61.619 63.6089 61.9656 63.7525 62.3272 63.754C62.6928 63.754 63.0434 63.6088 63.3019 63.3503C63.5605 63.0918 63.7057 62.7411 63.7057 62.3755C63.7067 62.1941 63.672 62.0143 63.6034 61.8463C63.5348 61.6784 63.4337 61.5256 63.3059 61.3968C60.4918 58.2825 59.0075 54.1909 59.1704 49.9967C59.009 45.8026 60.4931 41.7116 63.3059 38.5965C63.5627 38.3382 63.7068 37.9888 63.7068 37.6246C63.7068 37.2605 63.5627 36.9111 63.3059 36.6528C63.0477 36.3961 62.6983 36.252 62.3341 36.252C61.9699 36.252 61.6205 36.3961 61.3623 36.6528C55.7104 42.2909 44.2 42.2909 38.5481 36.6528C38.2899 36.3961 37.9405 36.252 37.5763 36.252C37.2121 36.252 36.8627 36.3961 36.6045 36.6528C36.3477 36.9111 36.2036 37.2605 36.2036 37.6246C36.2036 37.9888 36.3477 38.3382 36.6045 38.5965C39.4423 41.7008 40.9516 45.7931 40.8089 49.9967C40.9531 54.2005 39.4436 58.2933 36.6045 61.3968C36.4767 61.5256 36.3756 61.6784 36.307 61.8463C36.2384 62.0143 36.2037 62.1941 36.2047 62.3755C36.2037 62.557 36.2384 62.7368 36.307 62.9048C36.3756 63.0727 36.4767 63.2255 36.6045 63.3543C36.8627 63.611 37.2121 63.7551 37.5763 63.7551C37.9405 63.7551 38.2899 63.611 38.5481 63.3543ZM42.1185 42.1806C47.1569 44.1523 52.7535 44.1523 57.7919 42.1806C55.8619 47.2173 55.8619 52.7898 57.7919 57.8265C52.7535 55.8548 47.1569 55.8548 42.1185 57.8265C44.0485 52.7898 44.0485 47.2173 42.1185 42.1806Z"
        className="fill-[url(#paint4_linear_6619_101870)] dark:fill-white"
      />
      <path
        d="M66.739 55.6485L71.3845 50.9478C71.6413 50.6896 71.7854 50.3402 71.7854 49.976C71.7854 49.6118 71.6413 49.2624 71.3845 49.0042L66.7114 44.3311L64.7539 46.2747L67.1112 48.6182H60.8804V51.3752H67.1112L64.7815 53.7048L66.739 55.6485Z"
        className="fill-[url(#paint5_linear_6619_101870)] dark:fill-white"
      />
      <path
        d="M33.4206 55.6761L38.1213 50.9754C38.3781 50.7171 38.5222 50.3677 38.5222 50.0036C38.5222 49.6394 38.3781 49.29 38.1213 49.0317L33.4206 44.3311L31.477 46.2747L33.8204 48.6182H27.7964V51.3752H33.8204L31.477 53.7324L33.4206 55.6761Z"
        className="fill-[url(#paint6_linear_6619_101870)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_101870"
          x1={18.353}
          y1={16.9277}
          x2={33.4588}
          y2={57.8298}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_101870"
          x1={18.2847}
          y1={59.6875}
          x2={33.5404}
          y2={100.795}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6619_101870"
          x1={10}
          y1={41.8926}
          x2={26.1009}
          y2={57.9934}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6619_101870"
          x1={73.9546}
          y1={42.0225}
          x2={90.0003}
          y2={58.0682}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6619_101870"
          x1={36.2036}
          y1={36.252}
          x2={63.7068}
          y2={63.7551}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6619_101870"
          x1={60.8804}
          y1={44.3311}
          x2={72.19}
          y2={55.2285}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6619_101870"
          x1={27.7964}
          y1={44.3311}
          x2={39.1236}
          y2={55.04}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
