import { SecurityCapabilitiesIcon } from "icons";
import {
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  ALIBABA_CLOUD,
  AUTO_PROTECT,
  DATA_SECURITY,
  IBM,
  IDENTITY_SECURITY,
  MISCONFIGURATIONS,
  OCI,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
  THREAT_DETECTION,
} from "../../../../constants";

import { type CloudType } from "../../../../types";
import { type FeatureItemType } from "../FeatureGroup/FeatureGroup";

export function getSecurityCapabilitiesItems(
  cloudType: CloudType,
): FeatureItemType[] {
  const items: FeatureItemType[] = [
    {
      id: "misconfigurations",
      icon: <SecurityCapabilitiesIcon type={MISCONFIGURATIONS} />,
      value: MISCONFIGURATIONS,
      tag: "default",
      category: "foundational",
      showCheckbox: false,
    },
    {
      id: "identitySecurity",
      icon: <SecurityCapabilitiesIcon type={IDENTITY_SECURITY} />,
      value: IDENTITY_SECURITY,
      category: "foundational",
      showCheckbox: false,
    },
    {
      id: "agentlessScan",
      icon: <SecurityCapabilitiesIcon type={AGENTLESS_SCAN} />,
      value: AGENTLESS_SCAN,
      category: "foundational",
      showCheckbox: true,
      showToggle: true,
      toggleValue: AGENTLESS_DEFAULT_MEMBER_STATE,
    },
    {
      id: "threatDetection",
      icon: <SecurityCapabilitiesIcon type={THREAT_DETECTION} />,
      value: THREAT_DETECTION,
      tag: "default",
      category: "advanced",
      showCheckbox: false,
    },
    {
      id: "serverlessScan",
      icon: <SecurityCapabilitiesIcon type={SERVERLESS_SCAN} />,
      value: SERVERLESS_SCAN,
      category: "advanced",
      showCheckbox: true,
      showToggle: true,
      toggleValue: SERVERLESS_DEFAULT_MEMBER_STATE,
    },
    {
      id: "agentlessApiDiscovery",
      icon: <SecurityCapabilitiesIcon type={AGENTLESS_API_DISCOVERY} />,
      value: AGENTLESS_API_DISCOVERY,
      category: "advanced",
      showCheckbox: true,
      showToggle: true,
      toggleValue: AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
    },
    {
      id: "autoProtect",
      icon: <SecurityCapabilitiesIcon type={AUTO_PROTECT} />,
      value: AUTO_PROTECT,
      category: "advanced",
    },
    {
      id: "dataSecurity",
      icon: <SecurityCapabilitiesIcon type={DATA_SECURITY} />,
      value: DATA_SECURITY,
      category: "advanced",
    },
  ];

  const otherItems = [
    {
      id: "misconfigurations",
      icon: <SecurityCapabilitiesIcon type={MISCONFIGURATIONS} />,
      value: MISCONFIGURATIONS,
      tag: "default",
      category: "foundational",
      showCheckbox: false,
    },
  ];

  switch (cloudType) {
    case IBM:
    case ALIBABA_CLOUD:
    case OCI:
      return otherItems;
    default:
      return items;
  }
}
