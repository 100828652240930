import { type QueryFunctionContext } from "@tanstack/react-query";
import { type z } from "zod";
import { jsonApi } from "../../apis";
import { type TimeRangeSchema } from "../../schemas";
import { determineApiVersion } from "./apiVersioning";
import {
  AssetCountRequestSchema,
  AssetCountResponseSchema,
  AssetScanInfoRequest,
  AssetScanInfoResponse,
  AsyncDownloadRequestSchema,
  AsyncDownloadResponseSchema,
  type AssetsFiltersPostBodyType,
  type AsyncDownloadRequest,
} from "./schemas";

export const assetKeys = {
  all: [{ scope: "assets" }] as const,
  assetResults: ({
    apiVersion: requestedApiVersion,
    filters,
    limit = 100,
    timeRange,
    useV2Api,
  }: {
    apiVersion?: number;
    filters: AssetsFiltersPostBodyType;
    limit?: number;
    timeRange: z.infer<typeof TimeRangeSchema>;
    useV2Api?: boolean;
  }) => {
    const apiVersion = determineApiVersion({ requestedApiVersion, useV2Api });

    const body = {
      filters,
      limit,
      timeRange: apiVersion < 2 ? timeRange : undefined,
    };

    return [
      {
        ...assetKeys.all[0],
        entity: "scan_info",
        apiVersion,
        body,
      },
    ] as const;
  },

  count: ({ filters }: { filters: AssetsFiltersPostBodyType }) => [
    {
      ...assetKeys.all[0],
      entity: "count",
      body: { filters },
    },
  ],
};

export const getAssets = async ({
  queryKey: [{ apiVersion, body }],
  pageParam,
}: QueryFunctionContext<ReturnType<(typeof assetKeys)["assetResults"]>>) => {
  let pathVersion = "";
  if (apiVersion > 2) {
    pathVersion = `api/v${apiVersion}/`;
  } else if (apiVersion === 2) {
    pathVersion = "v2/";
  }
  const pageToken = pageParam as unknown as string;

  return jsonApi({
    path: `${pathVersion}resource/scan_info`,
    body: {
      ...body,
      ...(pageParam
        ? apiVersion >= 4
          ? {
              nextPageToken: pageToken,
            }
          : { pageToken }
        : {}),
    },
    config: {
      method: "post",
    },
    requestSchema: AssetScanInfoRequest,
    responseSchema: AssetScanInfoResponse,
  });
};

export const triggerAsyncDownload = async ({
  data,
}: {
  data: AsyncDownloadRequest;
}) => {
  return jsonApi({
    body: {
      ...data,
    },
    path: `v1/download-job`,
    config: {
      method: "post",
    },
    requestSchema: AsyncDownloadRequestSchema,
    responseSchema: AsyncDownloadResponseSchema,
  });
};

export const getAssetsCount = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<ReturnType<(typeof assetKeys)["count"]>>) => {
  return jsonApi({
    path: `api/v4/aggregation/count`,
    body,
    config: {
      method: "post",
    },
    requestSchema: AssetCountRequestSchema,
    responseSchema: AssetCountResponseSchema,
  });
};
