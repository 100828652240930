import {
  useCallback,
  useRef,
  useState,
  type ReactElement,
  type RefObject,
} from "react";

export interface UseTextareaAutosizeOptions {
  className: string;
  textareaRef: RefObject<HTMLTextAreaElement>;
  rowsMax?: number;
  rowsMin?: number;
}

export interface UseTextareaAutosizeReturn {
  height?: number;
  shadowEl: ReactElement;
  syncHeight: () => void;
}

export function useTextareaAutosize({
  className,
  rowsMax,
  rowsMin,
  textareaRef,
}: UseTextareaAutosizeOptions): UseTextareaAutosizeReturn {
  const [height, setHeight] = useState<number>();
  const shadowRef = useRef<HTMLTextAreaElement>(null);

  /* c8 ignore next */
  /* Hard to test DOM node sizes in Jest/JSDOM */
  const syncHeight = useCallback(() => {
    if (!shadowRef.current) {
      if (process.env.NODE_ENV === "development") {
        throw new Error(
          "Shadow ref is `null`. Make sure to render the `shadowEl` from `useTextareaAutosize`.",
        );
      }
      return;
    }

    if (!textareaRef.current) {
      return;
    }

    const computedStyle = window.getComputedStyle(textareaRef.current);

    // If input's width is shrunk and it's not visible, don't sync height.
    if (computedStyle.width === "0px") {
      return;
    }

    shadowRef.current.style.width = computedStyle.width;
    shadowRef.current.value =
      textareaRef.current.value || textareaRef.current.placeholder || "x";

    // The height of the inner content
    const innerHeight = shadowRef.current.scrollHeight;

    // Measure height of a textarea with a single row
    shadowRef.current.value = "x";
    const singleRowHeight = shadowRef.current.scrollHeight;

    // The height of the outer content
    let outerHeight = innerHeight;

    if (rowsMin) {
      outerHeight = Math.max(rowsMin * singleRowHeight, outerHeight);
    }
    if (rowsMax) {
      outerHeight = Math.min(rowsMax * singleRowHeight, outerHeight);
    }
    outerHeight = Math.max(outerHeight, singleRowHeight);

    setHeight((prevHeight) => {
      if (outerHeight > 0 && Math.abs((prevHeight || 0) - outerHeight) > 1) {
        return outerHeight;
      }
      return prevHeight;
    });
  }, [rowsMax, rowsMin, textareaRef]);

  const shadowEl = (
    <textarea
      aria-hidden
      className={className}
      readOnly
      ref={shadowRef}
      tabIndex={-1}
      style={{
        visibility: "hidden",
        // Remove from the content flow
        position: "absolute",
        // Ignore the scrollbar width
        overflow: "hidden",
        height: 0,
        top: 0,
        left: 0,
        // Create a new layer, increase the isolation of the computed values
        transform: "translateZ(0)",
        padding: 0,
      }}
    />
  );

  return {
    height,
    shadowEl,
    syncHeight,
  };
}
