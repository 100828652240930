import { type CloudType } from "../../../types";
import Features from "./Features";

import { SidecarBody } from "ui";

type ComputeTabProps = {
  accountId: string;
  cloudType: CloudType;
};

export default function ComputeTab({ accountId, cloudType }: ComputeTabProps) {
  return (
    <SidecarBody>
      <Features accountId={accountId} cloudType={cloudType} />
    </SidecarBody>
  );
}
