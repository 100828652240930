import { isNil, isTruthy } from "remeda";
import {
  type FlatNodeType,
  type TreeModelType,
  type TreeNodeType,
} from "../../types";

type ListKeys = "loaded" | "expanded" | "checked";

class NodeModel {
  props: TreeModelType;
  flatNodes: Record<string, FlatNodeType>;
  constructor(props: TreeModelType, nodes: Record<string, FlatNodeType> = {}) {
    this.props = props;
    this.flatNodes = nodes;
  }

  // Set the props
  setProps(props: TreeModelType) {
    this.props = props;
  }

  // Clone the NodeModel
  clone() {
    const clonedNodes: Record<string, FlatNodeType> = {};

    // Re-construct nodes one level deep to avoid shallow copy of mutable characteristics
    Object.keys(this.flatNodes).forEach((value) => {
      const node = this.flatNodes[value as keyof FlatNodeType];
      clonedNodes[value] = { ...node };
    });

    return new NodeModel(this.props, clonedNodes);
  }

  // Get Node from flattenNodes
  getNode(value: string) {
    return this.flatNodes[value];
  }

  // Reset/Clear the flattenNodes
  reset() {
    this.flatNodes = {};
  }

  // Flatten the parent and child nodes to add in single object
  flattenNodes(
    nodes: TreeNodeType[],
    parent: TreeNodeType = {
      id: "",
      isLeaf: false,
      title: "",
    },
    depth = 0,
  ) {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    const { noCascade, showCheckbox } = this.props;

    // Flatten the `node` property for internal lookups
    nodes.forEach((node, index) => {
      // If isLeaf value is passed, used the passed value else check its children and find the value
      const isParent = !isNil(node.isLeaf)
        ? !node.isLeaf
        : this.nodeHasChildren(node);
      const nodeId = node.id;
      const label = node.title!;

      this.flatNodes[nodeId] = {
        checkState: 0,
        checked: false,
        loaded: false,
        expanded: false,
        title: label,
        id: nodeId,
        children: node.children ?? [],
        parent: parent as TreeNodeType,
        isChild: isTruthy(parent?.id),
        isParent,
        isLeaf: !isParent,
        showCheckbox: showCheckbox !== undefined ? showCheckbox : true,
        disabled: this.getDisabledState(
          node,
          parent as TreeNodeType,
          Boolean(node.disabled),
          noCascade,
        ),
        treeDepth: depth,
        index,
        checkable: node.checkable,
      };
      this.flattenNodes(node.children ?? [], node, depth + 1);
    });
  }

  // Check if node has children or not
  nodeHasChildren(node: TreeNodeType) {
    return Boolean(node?.children && node.children?.length > 0);
  }

  // Check and return boolean to disable the node
  getDisabledState(
    node: TreeNodeType,
    parent: TreeNodeType,
    disabledProp: boolean,
    noCascade: boolean,
  ) {
    if (disabledProp) {
      return true;
    }

    // if parent is disabled and noCascade is false, all child will be disabled
    if (!noCascade && parent?.disabled) {
      return true;
    }

    return Boolean(node.disabled);
  }

  // Deserialize the nodes
  deserializeLists(lists: {
    loaded: string[];
    expanded: string[];
    checked: string[];
  }) {
    const listKeys: ListKeys[] = ["checked", "expanded", "loaded"];

    // Reset values to false
    Object.keys(this.flatNodes).forEach((value) => {
      listKeys.forEach((listKey) => {
        this.flatNodes[value][listKey] = false;
      });
    });

    // Deserialize values and set their nodes to true
    listKeys.forEach((listKey) => {
      lists[listKey].forEach((value) => {
        if (this.flatNodes[value] !== undefined) {
          this.flatNodes[value][listKey] = true;
        }
      });
    });
  }

  // Serialize the nodes
  serializeList(key: ListKeys) {
    const list: string[] = [];

    Object.keys(this.flatNodes).forEach((value) => {
      const node = this.flatNodes[value];
      if (node[key] && node.checkable !== false) {
        list.push(value);
      }
    });

    return list;
  }

  // Update the checked status
  toggleChecked(
    node: TreeNodeType,
    isChecked: boolean,
    noCascade: boolean,
    percolateUpward = true,
  ) {
    const nodeId = node?.id;
    const flatNode = this.flatNodes[nodeId];

    if (flatNode.isLeaf || noCascade) {
      if (node.disabled && noCascade) {
        return this;
      }

      this.toggleNode(nodeId, "checked", isChecked);
    } else {
      this.toggleNode(nodeId, "checked", isChecked);

      // Percolate check status down to all children
      flatNode.children
        ?.filter((child) => child.checkable !== false)
        .forEach((child) => {
          this.toggleChecked(child, isChecked, noCascade, false);
        });
    }

    // Percolate check status up to parent (Toggle Parent node)
    // parent (relevant only when percolating through children)
    if (percolateUpward && !noCascade && flatNode.isChild) {
      this.toggleParentStatus(flatNode.parent);
    }

    return this;
  }

  // Update the parent status
  toggleParentStatus(node: TreeNodeType) {
    const nodeId = node?.id;

    const flatNode = this.flatNodes[nodeId];

    if (flatNode.isChild) {
      this.toggleNode(nodeId, "checked", this.isEveryChildChecked(flatNode));

      this.toggleParentStatus(flatNode.parent);
    } else {
      this.toggleNode(nodeId, "checked", this.isEveryChildChecked(flatNode));
    }
  }

  // Check if every child of node is checked
  isEveryChildChecked(node: TreeNodeType) {
    return Boolean(
      node.children?.every((child) => this.getNode(child?.id)?.checked),
    );
  }

  // Toggle the node status
  toggleNode(nodeValue: string, key: ListKeys, toggleValue: boolean) {
    this.flatNodes[nodeValue][key] = toggleValue;

    return this;
  }
}

export default NodeModel;
