import { iconColor } from "colors";
import {
  BanIcon,
  DangerIcon,
  ExclamationCircleIcon,
  SuccessIcon,
  WarningIcon,
} from "icons";
import { useMemo, type ReactNode } from "react";
import { defineMessages, type MessageDescriptor } from "react-intl";
import { Button, DynamicFormattedMessage } from "ui";
import { classNames } from "utils";
import { type Status } from "../../../../../types";

const IconMapping: Record<Status, ReactNode> = {
  disabled: <BanIcon size="md" />,
  error: <DangerIcon size="md" />,
  ok: <SuccessIcon size="md" />,
  success: <SuccessIcon size="md" />,
  enabled: <SuccessIcon size="md" />,
  warning: <WarningIcon size="md" />,
  pending: <ExclamationCircleIcon fill={iconColor.orange} size="md" />,
};

const statusTextMap: Record<Status, MessageDescriptor> = defineMessages({
  ok: {
    defaultMessage: "Successful",
    id: "e5AJT4",
    description: "Status message",
  },
  success: {
    defaultMessage: "Successful",
    id: "e5AJT4",
    description: "Status message",
  },
  warning: {
    defaultMessage: "Issues Found",
    id: "MHgVpS",
    description: "Status message",
  },
  error: {
    defaultMessage: "Checks Failed",
    id: "zD0Vmt",
    description: "Status message",
  },
  disabled: {
    defaultMessage: "Not Enabled",
    id: "A7KCXo",
    description: "Status message",
  },
  enabled: {
    defaultMessage: "Enabled",
    id: "cdh8Ka",
    description: "Status message",
  },
  pending: {
    defaultMessage: "Pending",
    id: "1H9gBv",
    description: "Status message",
  },
});

type StatusWithIconProps = {
  status: Status;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const colors: Record<Status, string> = {
  disabled: "text-gray-400 text-dark-bg-disabled",
  ok: "",
  enabled: "",
  error: "",
  warning: "",
  success: "",
  pending: "",
};

export default function StatusWithIcon({
  status,
  onClick,
}: StatusWithIconProps) {
  const Icon = useMemo(() => {
    return status && IconMapping[status];
  }, [status]);

  return (
    <div className="flex items-center">
      <span className={classNames(colors[status])}>{Icon}</span>
      <span className={typeof onClick === "function" ? "ml-0.5" : "ml-2.5"}>
        {onClick ? (
          <Button onClick={onClick} appearance="clear" size="sm">
            <DynamicFormattedMessage
              messageMap={statusTextMap}
              token={status}
            />
          </Button>
        ) : (
          <DynamicFormattedMessage messageMap={statusTextMap} token={status} />
        )}
      </span>
    </div>
  );
}
