import { useIntl } from "react-intl";

import { Checkbox, useFormContext } from "form";
import {
  Bold,
  Card,
  CardBody,
  Checkbox as DisabledCheckbox,
  DynamicFormattedMessage,
  Tooltip,
  dynamicFormatMessage,
} from "ui";

import { InfoTooltipIcon } from "icons";
import { messageMap } from "./messages";

export default function AutoOnboardProjects({
  isDisabled,
}: {
  isDisabled: boolean;
}) {
  const intl = useIntl();
  const { watch } = useFormContext();

  const autoOnboardProjects = !!watch("autoOnboardProjects");

  const label = (
    <Bold appearance="semibold" addClassName="text-xs leading-5">
      <DynamicFormattedMessage messageMap={messageMap} token="label" />
    </Bold>
  );

  return (
    <Card>
      <CardBody addClassName="flex items-center space-x-1 p-2 pl-4">
        {isDisabled ? (
          <DisabledCheckbox
            checked={autoOnboardProjects}
            data-selector="gcp-auto-onboard-projects-checkbox"
            disabled
            readOnly
          >
            {label}
          </DisabledCheckbox>
        ) : (
          <Checkbox
            data-selector="gcp-auto-onboard-projects-checkbox-form"
            defaultValue={false}
            disabled={isDisabled}
            label={dynamicFormatMessage(intl, messageMap, "label")}
            name="autoOnboardProjects"
          />
        )}
        {/* eslint-disable-next-line tailwindcss/classnames-order,tailwindcss/no-custom-classname */}
        <div className={`flex mt-${isDisabled ? "0.5" : "1"}`}>
          <Tooltip label={intl.formatMessage(messageMap.tooltip)}>
            <span>
              <InfoTooltipIcon
                aria-label={intl.formatMessage(messageMap.tooltip)}
              />
            </span>
          </Tooltip>
        </div>
      </CardBody>
    </Card>
  );
}
