import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";
import { getValidateAlibabaRamARN } from "../../../../utils";

export default function RamArn() {
  const intl = useIntl();
  useTrimField("ramArn");

  return (
    <Input
      name="ramArn"
      label={intl.formatMessage({
        defaultMessage: "RAM Role",
        id: "LlsUIe",
        description:
          "The name of an input field for onboarding an Alibaba account",
      })}
      placeholder={"acs:ram::1234567890123456:role/samplerole"}
      register={{
        required: intl.formatMessage({
          defaultMessage: "RAM Role is required",
          id: "qhfU9N",
          description: "Required message for cloud account",
        }),
        validate: getValidateAlibabaRamARN(intl),
      }}
    />
  );
}
