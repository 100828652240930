import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const SlackIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Slack",
    id: "rkb5nm",
    description: "Slack alt text",
  });

  return (
    <SvgIcon {...props} viewBox={"0 0 16 16"}>
      <title>{alt}</title>
      <path
        d="M5.894 8.43457C4.96588 8.43457 4.21338 9.18739 4.21338 10.1152V14.3193C4.21338 15.2474 4.96619 15.9999 5.894 15.9999C6.82213 15.9999 7.57463 15.2471 7.57463 14.3193V10.1152C7.574 9.18707 6.82119 8.43457 5.894 8.43457Z"
        fill="#575757"
      />
      <path
        d="M1.69335 8.43359C0.7646 8.43359 0.0114746 9.18703 0.0114746 10.1155C0.0114746 11.0442 0.764912 11.7973 1.69335 11.7973C2.6221 11.7973 3.37522 11.0439 3.37522 10.1155V8.43359H1.6946C1.69397 8.43359 1.69397 8.43359 1.69335 8.43359Z"
        fill="#575757"
      />
      <path
        d="M5.89403 3.36406H7.57466C7.57466 2.41 7.57466 2.64219 7.57466 1.67875C7.57403 0.75125 6.82278 0 5.89591 0C5.89528 0 5.89466 0 5.89403 0C4.96528 0 4.21216 0.753438 4.21216 1.68187C4.21216 2.61031 4.96528 3.36406 5.89403 3.36406Z"
        fill="#575757"
      />
      <path
        d="M5.89406 4.2168H1.68187C0.753438 4.2168 0 4.97023 0 5.89898C0 6.82773 0.753438 7.58086 1.68187 7.58086H5.89375C6.8225 7.58086 7.57562 6.82742 7.57562 5.89898C7.57594 4.97023 6.82281 4.2168 5.89406 4.2168Z"
        fill="#575757"
      />
      <path
        d="M15.987 5.8977C15.987 4.96895 15.2336 4.21582 14.3051 4.21582C14.3045 4.21582 14.3039 4.21582 14.3033 4.21582C13.3761 4.21582 12.6245 4.96707 12.6245 5.89457V5.89801V7.57988H14.3051C15.2339 7.57957 15.987 6.82613 15.987 5.8977Z"
        fill="#575757"
      />
      <path
        d="M8.42603 5.89848C8.42603 6.8266 9.17884 7.5791 10.1067 7.5791C11.0348 7.5791 11.7873 6.82629 11.7873 5.89848V1.6816C11.7873 0.753477 11.0345 0.000976562 10.1067 0.000976562C9.17853 0.000976562 8.42603 0.753789 8.42603 1.6816V5.89848Z"
        fill="#575757"
      />
      <path
        d="M10.1056 12.6367H8.42493V14.3186C8.42555 15.2458 9.17774 15.998 10.1056 15.998C11.0337 15.998 11.7862 15.2452 11.7862 14.3173C11.7862 13.3895 11.0337 12.6367 10.1056 12.6367Z"
        fill="#575757"
      />
      <path
        d="M14.3179 8.43359H10.1057C9.17695 8.43359 8.42383 9.18703 8.42383 10.1155C8.42383 11.0442 9.17727 11.7973 10.1057 11.7973H14.3176C15.2463 11.7973 15.9995 11.0439 15.9995 10.1155C15.9998 9.18672 15.2463 8.43359 14.3179 8.43359Z"
        fill="#575757"
      />
    </SvgIcon>
  );
};
