import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = { width: number };
type Actions = { setWidth: (width: number) => void };

export const useSidecarStore = create(
  persist(
    immer<State & Actions>((set) => {
      return {
        width: 1100,

        setWidth: (width) => {
          set((state) => {
            state.width = width;
          });
        },
      };
    }),
    {
      name: "sidecarState",
    },
  ),
);
