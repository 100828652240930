import { useCallback } from "react";
import { useIntl } from "react-intl";
import { downloadFileRequest } from "requests";
import { useToastActions } from "stores";
import { downloadFile } from "utils";

export type GenerateFileParams = {
  downloadEndpoint: string;
  downloadRequestParams?: Record<string, string>;
  downloadRequestOptions?: RequestInit;
  fileName?: string;
  isCompute?: boolean;
  noFileExtension?: boolean;
};

type GenerateFileFunction = (params: GenerateFileParams) => void;

const fileTypeMapping = {
  "text/csv": ".csv",
  "application/pdf": ".pdf",
  "application/gzip": ".csv.gz",
  "application/xml": ".xml",
  "text/plain": ".plain",
};

export function useFileDownloader(): GenerateFileFunction {
  const { toast } = useToastActions();
  const intl = useIntl();

  const generateFile = useCallback(
    async ({
      downloadEndpoint,
      downloadRequestParams,
      downloadRequestOptions = {},
      fileName,
      isCompute = false,
      noFileExtension = false,
    }: GenerateFileParams) => {
      toast(
        intl.formatMessage({
          defaultMessage: "File downloading",
          id: "xTVB88",

          description: "Message to user after clicking the download button",
        }),
        { appearance: "info" },
      );

      const response = await downloadFileRequest({
        path: downloadEndpoint,
        config: downloadRequestOptions,
        params: downloadRequestParams,
        isCompute,
      });

      if (response.ok) {
        const body = await response.blob();
        const contentType = response.headers.get("content-type");

        const contentDisposition = response.headers.get("content-disposition");
        const parsedContentDisposition = contentDisposition?.match(
          /filename[^;\n]*(?:=UTF-\d['"]*)?="([.]$|[^;\n"]*)?/,
        );
        const responseFileName =
          parsedContentDisposition?.[1] ?? contentDisposition;

        const [name, ext] = splitFileName(fileName);
        const [responseName, responseExt] = splitFileName(responseFileName);

        const finalName = name || responseName || "";
        const finalFileExtension =
          ext ||
          responseExt ||
          fileTypeMapping[contentType as keyof typeof fileTypeMapping] ||
          ".csv";
        const blob = new Blob([body], { type: contentType ?? undefined });

        downloadFile({
          fileName: `${finalName}${noFileExtension ? "" : finalFileExtension}`,
          file: blob,
        });
        toast(
          intl.formatMessage({
            defaultMessage: "File downloaded successfully",
            id: "M8515C",

            description: "Success compliance report email link download",
          }),
          { appearance: "success" },
        );
      } else {
        toast(
          intl.formatMessage({
            defaultMessage:
              "Unable to download file. Please contact your Prisma Cloud support team if the problem persists.",
            id: "jBOl3F",

            description: "Download script error message",
          }),
          {
            appearance: "error",
          },
        );
      }
    },
    [intl, toast],
  );

  return generateFile;
}

export function splitFileName(fileName?: string | null) {
  if (!fileName) {
    return [null, null];
  }

  const extension =
    (fileName.match(/\.gz$/)
      ? fileName.match(/\.\w+\.\w+$/)?.[0] // If it's .gz, there's another extension infront of the zip extension e.g. .csv.gz
      : fileName.match(/\.\w+$/)?.[0]) || "";

  const name = extension.length
    ? fileName.slice(0, -extension.length)
    : fileName;

  return [name, extension];
}
