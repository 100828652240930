import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";
import {
  BucketCheckRequestSchema,
  BucketCheckResponseSchema,
  CloudTrailRequestSchema,
  CloudTrailResponseSchema,
  CreateDataSecurityRequestSchema,
  ScriptDataRequestSchema,
  ScriptDataResponseSchema,
  UpdateDataSecurityRequestSchema,
  ValidateFwScanRequestSchema,
  ValidateFwScanResponseSchema,
  type BucketCheckRequestType,
  type CreateDataSecurityRequestType,
  type ScriptDataRequestType,
  type UpdateDataSecurityRequestType,
  type ValidateFwScanRequestType,
} from "./schemas";

export const awsAccountDataSecurity = {
  all: [{ scope: "awsAccountDataSecurity" }] as const,
  cloudTrail: ({
    accountId,
    externalId,
    roleArn,
  }: {
    accountId: string;
    externalId: string;
    roleArn: string;
  }) =>
    [
      {
        ...awsAccountDataSecurity.all[0],
        entity: "cloudTrail",
        accountId,
        externalId,
        roleArn,
      },
    ] as const,
  scriptData: ({
    accountId,
    cloudtrailBucket,
    cloudtrailRequest,
    snsEndpoint,
    snsTopic,
    isCommonLogging,
    cloudtrailRegion,
  }: ScriptDataRequestType) =>
    [
      {
        ...awsAccountDataSecurity.all[0],
        entity: "scriptData",
        accountId,
        cloudtrailBucket,
        cloudtrailRequest,
        snsEndpoint,
        snsTopic,
        isCommonLogging,
        cloudtrailRegion,
      },
    ] as const,
  validateFwScan: ({
    accountId,
    cloudtrailBucket,
    cloudtrailRequest,
    snsEndpoint,
    snsTopic,
    isCommonLogging,
    cloudtrailRegion,
  }: ScriptDataRequestType) =>
    [
      {
        ...awsAccountDataSecurity.all[0],
        entity: "validateFwScan",
        accountId,
        cloudtrailBucket,
        cloudtrailRequest,
        snsEndpoint,
        snsTopic,
        isCommonLogging,
        cloudtrailRegion,
      },
    ] as const,
} as const;

export const getCloudTrailData = async ({
  queryKey: [{ accountId, externalId, roleArn }],
}: QueryFunctionContext<
  ReturnType<(typeof awsAccountDataSecurity)["cloudTrail"]>
>) => {
  return jsonApi({
    path: "pcds/config/v3/cloudTrail",
    body: {
      accountId,
      externalId,
      roleArn,
    },
    config: {
      method: "POST",
    },
    requestSchema: CloudTrailRequestSchema,
    responseSchema: CloudTrailResponseSchema,
  });
};

export const getScriptData = async ({
  queryKey: [
    {
      accountId,
      cloudtrailBucket,
      cloudtrailRequest,
      snsEndpoint,
      snsTopic,
      isCommonLogging,
      cloudtrailRegion,
    },
  ],
}: QueryFunctionContext<
  ReturnType<(typeof awsAccountDataSecurity)["scriptData"]>
>) => {
  return jsonApi({
    path: "pcds/config/v3/account/sfs/script",
    body: {
      accountId,
      cloudtrailBucket,
      cloudtrailRequest,
      snsEndpoint,
      snsTopic,
      isCommonLogging,
      cloudtrailRegion,
    },
    config: {
      method: "POST",
    },
    requestSchema: ScriptDataRequestSchema,
    responseSchema: ScriptDataResponseSchema,
  });
};

export const validateFwScan = async ({
  accountId,
  externalId,
  cloudtrailName,
  cloudtrailRegion,
  roleArn,
  snsSubscriptionEndpoint,
}: ValidateFwScanRequestType) => {
  return jsonApi({
    path: "pcds/config/v3/account/cloudTrail/validate",
    body: {
      accountId,
      externalId,
      cloudtrailName,
      cloudtrailRegion,
      roleArn,
      snsSubscriptionEndpoint,
    },
    config: {
      method: "POST",
    },
    requestSchema: ValidateFwScanRequestSchema,
    responseSchema: ValidateFwScanResponseSchema,
  });
};

export const bucketCheck = async ({
  accountId,
  externalId,
  bucket,
  roleArn,
}: BucketCheckRequestType) => {
  return jsonApi({
    path: "pcds/config/v3/cloudTrail/bucket-check",
    body: {
      accountId,
      externalId,
      bucket,
      roleArn,
    },
    config: {
      method: "POST",
    },
    requestSchema: BucketCheckRequestSchema,
    responseSchema: BucketCheckResponseSchema,
  });
};

export const createDataSecurityConfig = async ({
  payload,
}: {
  payload: CreateDataSecurityRequestType;
}) => {
  return jsonApi({
    path: "pcds/config/v3/account",
    body: {
      ...payload,
    },
    config: {
      method: "POST",
    },
    requestSchema: CreateDataSecurityRequestSchema,
    responseSchema: z.any(),
  });
};

export const updateDataSecurityConfig = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: UpdateDataSecurityRequestType;
}) => {
  return jsonApi({
    path: `pcds/config/v3/account/${accountId}`,
    body: {
      ...payload,
    },
    config: {
      method: "PUT",
    },
    requestSchema: UpdateDataSecurityRequestSchema,
    responseSchema: z.any(),
  });
};
