import { LoadingIcon } from "icons";
import { isValidElement, type ReactNode } from "react";
import { TruncateText } from "ui";

export const ModalWizardFieldSummary = ({
  name,
  value,
  isLoading,
}: {
  isLoading?: boolean;
  name: ReactNode;
  value:
    | ReactNode
    | (
        | ReactNode
        | {
            name?: string;
            children?: string;
            icon?: ReactNode;
          }
      )[];
}) => {
  const isArray = Array.isArray(value);
  const isString = !isArray && typeof value === "string";

  return (
    <div key={name?.toString()} className="flex flex-col space-y-1 text-xs">
      <dt className="text-secondary">{name}</dt>
      <dd className="flex w-11/12 pl-8">
        {isLoading ? (
          <LoadingIcon />
        ) : !value || (isArray && !value.length) ? (
          <div>-</div>
        ) : isString ? (
          <StringValue value={value} />
        ) : isArray ? (
          <ArrayValue value={value} />
        ) : (
          value
        )}
      </dd>
    </div>
  );
};

const StringValue = ({ value }: { value: string }) => {
  return <TruncateText addClassName="w-full">{value}</TruncateText>;
};

const ArrayValue = ({
  value,
}: {
  value: (
    | ReactNode
    | {
        name?: string;
        children?: string;
        icon?: ReactNode;
      }
  )[];
}) => {
  const isArrayOfStrings = typeof value[0] === "string";
  const isArrayOfElements = isValidElement(value[0]);

  if (!isArrayOfElements && value.length > 40) {
    return (
      <StringValue
        value={(isArrayOfStrings
          ? value
          : (value as { name?: string; children?: string }[]).map(
              (v) => v.children ?? v.name,
            )
        ).join(", ")}
      />
    );
  }

  return (
    <div className="w-full columns-4 flex-col">
      {value.map((item) => {
        const fieldValue =
          isArrayOfStrings || isArrayOfElements
            ? item
            : // @ts-expect-error We've already checked that it's not string or element
              item.children ?? item.name;

        return (
          <div
            key={fieldValue?.key ?? fieldValue}
            className="flex items-center space-x-1 p-1"
          >
            {
              // @ts-expect-error We've already checked that it's not string or element
              !isArrayOfStrings && !isArrayOfElements && item.icon && (
                <div>
                  {
                    // @ts-expect-error We've already checked that it's not string or element
                    item.icon
                  }
                </div>
              )
            }
            <div className="flex w-full">
              {typeof fieldValue === "string" ? (
                <TruncateText>{fieldValue}</TruncateText>
              ) : (
                fieldValue
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
