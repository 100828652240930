import { getHomeRegions, homeRegionsKeys } from "requests";
import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import {
  CONFIGURE_ACCOUNT,
  GET_STARTED,
  OCI,
  REVIEW_STATUS,
  TENANT,
  WIZARD_HEIGHT,
} from "../../../constants";
import {
  useGetCloudAccountsRest,
  useGetDefaultAccountGroupId,
} from "../../../hooks";
import { stepTitle, wizardTitle } from "../../../messages/commonMessages";

import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import LoadingModal from "../../../components/LoadingModal";
import { type OnboardingProps } from "../../../types";
import { ActiveStepTitle } from "../components";
import ConfigureAccount from "./ConfigureAccount";
import GetStarted from "./GetStarted";
import ReviewStatus from "./ReviewStatus";

export function OciOnboarding({
  accountId,
  closeModal,
  isOpen,
  isEdit = false,
  fromProvidersPage,
}: OnboardingProps) {
  const intl = useIntl();

  const { data: ociData, isLoading } = useGetCloudAccountsRest({
    accountId,
    cloudType: OCI,
    includeGroupInfo: "true",
    options: { enabled: isEdit },
  });

  const { data: { regions = [] } = {}, isLoading: isRegionsLoading } = useQuery(
    {
      queryKey: homeRegionsKeys.all,
      queryFn: getHomeRegions,
    },
  );

  const {
    defaultAccountGroupId: newDefaultAccountGroupId = [],
    isLoading: isAccountGroupItemsLoading,
  } = useGetDefaultAccountGroupId();

  const {
    accountType = TENANT,
    enabled = true,
    userName,
    groupName,
    policyName,
    userOcid,
    homeRegion = "us-phoenix-1",
    name,
    defaultAccountGroupId = "",
  } = ociData || {};

  const status = isEdit ? "valid" : "pending";
  const accountGroupDefaultValue = useMemo(
    () => (isEdit ? defaultAccountGroupId : newDefaultAccountGroupId[0]?.key),
    [defaultAccountGroupId, isEdit, newDefaultAccountGroupId],
  );

  const steps = useMemo((): WizardStepState[] => {
    const wizardSteps: WizardStepState[] = [
      {
        Component: GetStarted,
        disabled: false,
        key: GET_STARTED,
        label: intl.formatMessage(stepTitle[GET_STARTED]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountType,
        },
      },
      {
        Component: ConfigureAccount,
        disabled: !isEdit,
        key: CONFIGURE_ACCOUNT,
        label: intl.formatMessage(stepTitle[CONFIGURE_ACCOUNT]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountId,
          userName,
          groupName,
          policyName,
          userOcid,
          groupIds: accountGroupDefaultValue,
          regions,
          homeRegion: { value: homeRegion },
          name,
        },
      },
      {
        Component: ReviewStatus,
        disabled: !isEdit,
        key: REVIEW_STATUS,
        label: intl.formatMessage(stepTitle[REVIEW_STATUS]),
        hasBeenSubmitted: isEdit,
        status,
      },
    ];
    return wizardSteps;
  }, [
    intl,
    isEdit,
    status,
    accountType,
    accountId,
    userName,
    groupName,
    policyName,
    userOcid,
    regions,
    homeRegion,
    accountGroupDefaultValue,
    name,
  ]);

  const title = (
    <ActiveStepTitle
      label={intl.formatMessage(isEdit ? wizardTitle.edit : wizardTitle.add)}
      cloudType={OCI}
      iconSize={"lg"}
    />
  );

  if (isLoading || isRegionsLoading || isAccountGroupItemsLoading) {
    return <LoadingModal title={title} />;
  }

  return (
    <Wizard
      context={{
        accountId,
        closeModal,
        isEdit,
        enabled,
        fromProvidersPage,
      }}
      initialActiveIndex={0}
      isOpen={isOpen}
      onClose={closeModal}
      size="xl"
      steps={steps}
      style={{ minHeight: WIZARD_HEIGHT }}
      title={title}
    />
  );
}
