import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { PolicyFilterSchema } from "requests";
import { LoadingIconOnFilterBackground } from "ui";
import { z } from "zod";
import { governanceRoute } from "../../governanceRoute";

export const RouteBasedPolicyModal = lazy(
  () => import("./RouteBasedPolicyModal"),
);

export const RouteBasedPolicyModalSearchSchema = z.object({
  filters: PolicyFilterSchema.catch({}).optional(),
  viewId: z.string().catch("default").optional(),
  ruleCriteria: z.string().optional(),
});

export const policyWizardRoute = new Route({
  getParentRoute: () => governanceRoute,
  path: "$policyId/edit",
  component: RouteBasedPolicyModal,
  validateSearch: RouteBasedPolicyModalSearchSchema,
  pendingComponent: LoadingIconOnFilterBackground,
});
