import {
  SupportedFeaturesRequestSchema,
  SupportedFeaturesSchema,
  UpdateMemberFeaturesResponseSchema,
  UpdateSupportedFeaturesResponseSchema,
  type UpdateMemberFeaturesResponseSchemaType,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";
import { type cloudAccountsKeys } from "../cloudAccountsKeys";

export const getSupportedFeatures = async ({
  queryKey: [{ cloudType, payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["supportedFeatures"]>
>) =>
  jsonApi({
    path: `cas/v1/features/cloud/${cloudType}`,
    body: {
      ...payload,
    },
    config: {
      method: "POST",
    },
    requestSchema: SupportedFeaturesRequestSchema,
    responseSchema: SupportedFeaturesSchema,
  });

export const updateSupportedFeatures = async ({
  accountId,
  featureKey,
}: {
  accountId: string;
  featureKey: string;
}) =>
  jsonApi({
    path: `cas/v1/cloud_account/${accountId}/feature/${featureKey}`,
    body: {
      state: "disabled",
    },
    config: {
      method: "PATCH",
    },
    requestSchema: z.any(),
    responseSchema: UpdateSupportedFeaturesResponseSchema,
  });

export const updateMemberAccountFeatures = async ({
  orgId,
  payload,
}: {
  orgId: string;
  payload: UpdateMemberFeaturesResponseSchemaType;
}) =>
  jsonApi({
    path: `cas/api/v1/org/${orgId}/features`,
    body: {
      ...payload,
    },
    config: {
      method: "PUT",
    },
    requestSchema: UpdateMemberFeaturesResponseSchema,
    responseSchema: z.any(),
  });
