import { FormattedMessage } from "react-intl";

import { iconColor, yellowScale } from "colors";
import { WarningIcon } from "icons";
import { Body, Bold } from "ui";

export default function ErrorRow() {
  return (
    <div
      className="ml-2 mt-2 flex p-4"
      style={{ backgroundColor: `${yellowScale[100]}` }}
    >
      <div className="content-start pr-4">
        <WarningIcon aria-label={"error"} color={iconColor.yellow} />
      </div>
      <div className="content-end">
        <Body size="sm">
          <FormattedMessage
            defaultMessage="Enter the correct <b>External ID</b> and/or <b>Role ARN</b> on the <b>Configure Account</b> step or deselect the <b>Data Security</b> checkbox on the <b>Get Started</b> step in order to proceed to the <b>Next</b> step."
            id="OS6Nhx"
            description="Instructions to fix an error"
            values={{
              b: (chunks) => <Bold>{chunks}</Bold>,
            }}
          />
        </Body>
      </div>
    </div>
  );
}
