import { useCallback, useEffect, useRef, useState } from "react";
import {
  type IntersectionObserverHookArgs,
  type IntersectionObserverHookRefCb,
  type IntersectionObserverHookRefCbNode,
  type IntersectionObserverHookResponse,
  type IntersectionObserverHookRootRefCb,
  type IntersectionObserverHookRootRefCbNode,
} from "./types";
const defaultRootMargin = "0px";
const defaultThreshold = [0];

function useIntersectionObserver(
  args?: IntersectionObserverHookArgs,
): IntersectionObserverHookResponse {
  const rootMargin = args?.rootMargin ?? defaultRootMargin;
  const threshold = args?.threshold ?? defaultThreshold;

  const nodeRef = useRef<IntersectionObserverHookRefCbNode>(null);
  const rootRef = useRef<IntersectionObserverHookRootRefCbNode>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const unobserve = useCallback(() => {
    // Disconnect the current observer (if there is one)
    const currentObserver = observerRef.current;
    currentObserver?.disconnect();
    observerRef.current = null;
  }, []);

  const observe = useCallback(() => {
    const node = nodeRef.current;
    if (node) {
      const root = rootRef.current;
      const options = { root, rootMargin, threshold };
      // Create a observer for current "node" with given options.
      const observer = new IntersectionObserver(([newEntry]) => {
        setEntry(newEntry);
      }, options);
      observer.observe(node);
      observerRef.current = observer;
    }
  }, [rootMargin, threshold]);

  const initializeObserver = useCallback(() => {
    unobserve();
    observe();
  }, [observe, unobserve]);

  const refCallback = useCallback<IntersectionObserverHookRefCb>(
    (node) => {
      nodeRef.current = node;
      initializeObserver();
    },
    [initializeObserver],
  );

  const rootRefCallback = useCallback<IntersectionObserverHookRootRefCb>(
    (rootNode) => {
      rootRef.current = rootNode;
      initializeObserver();
    },
    [initializeObserver],
  );

  useEffect(() => {
    initializeObserver();
    return () => {
      // We disconnect the observer on unmount to prevent memory leaks etc.
      unobserve();
    };
  }, [initializeObserver, unobserve]);

  return [refCallback, { entry, rootRef: rootRefCallback }];
}

export default useIntersectionObserver;
