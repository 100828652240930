import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { LoadingIconOnFilterBackground } from "ui";
import { rolesRoute } from "./rolesRoute";

export const RouteBasedAccountGroupModalInRoles = lazy(
  () => import("./RouteBasedAccountGroupModalInRoles"),
);

export const accountGroupModalInRolesRoute = new Route({
  getParentRoute: () => rolesRoute,
  path: "$accountGroupId/edit",
  component: RouteBasedAccountGroupModalInRoles,
  pendingComponent: LoadingIconOnFilterBackground,
});
