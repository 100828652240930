import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

import { useIntl } from "react-intl";

export const IntegrationsTenableIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Tenable",
    id: "pOGZpz",

    description: "Integrations Tenable icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M80.0911 22.5639C80.5336 23.4489 80.9761 26.5465 81.8611 29.2016L88.0563 51.7699C88.9414 54.8675 89.8264 57.5226 89.8264 58.4077C90.2689 59.7352 89.8264 61.0628 88.9414 61.9478C88.4989 62.3903 86.2863 64.6029 84.0737 66.8155L67.2581 83.6311C65.0455 85.8436 62.833 88.0562 62.3904 88.4987C61.5054 89.3838 60.1779 89.8263 58.8503 89.3838C57.9653 89.3838 54.8677 88.4987 52.2126 87.6137L29.6443 81.4185C26.9892 80.5334 23.8916 79.6484 23.0066 79.6484C22.564 79.6484 21.679 79.2059 21.2365 78.7634C20.794 78.3209 20.3515 77.8783 20.3515 76.9933C20.3515 76.1083 19.4664 73.0107 18.5814 70.3556L11.9437 48.2298C11.5011 45.1322 10.6161 42.0346 10.1736 41.5921L11.0586 41.1496L11.9437 40.707C12.3862 41.5921 13.2712 44.6897 13.7137 47.7873L19.9089 70.3556C20.794 73.0107 21.679 76.1083 21.679 76.9933L22.1215 77.8783L23.0066 78.3209C23.8916 78.7634 26.9892 79.2059 29.6443 80.0909L52.2126 86.2861C54.8677 87.1712 57.9653 88.0562 58.8503 88.0562L60.6204 87.6137C61.0629 87.1712 63.2755 84.9586 65.488 82.746L82.3036 65.9304C84.5162 63.7178 86.7288 61.5053 87.1713 61.0628L87.6138 59.2927C87.1713 58.4077 86.7288 55.3101 85.8438 52.655L80.0911 30.0867C79.206 26.9891 78.321 24.334 78.321 23.4489L77.8785 22.5639L76.9934 22.1214C76.1084 21.6789 73.0108 21.2364 70.3557 20.3513L47.3449 14.1561C44.6898 13.2711 41.5922 12.386 40.7072 12.386L38.9371 12.8286C38.4946 13.2711 36.282 15.4837 34.0694 17.6962L17.2538 34.0693C15.0413 36.2819 12.8287 38.4945 12.3862 38.937L11.9437 40.707L11.0586 41.1496L10.1736 41.5921C9.73109 40.2645 10.1736 38.937 11.0586 38.0519C11.9437 37.1669 14.1562 34.9543 16.3688 33.1843L33.1844 16.3687C35.397 14.1561 37.6096 11.9435 38.0521 11.501C38.9371 10.616 40.2647 10.1735 41.5922 10.616C42.4772 11.0585 45.5748 11.501 48.2299 12.386L70.7982 18.5813C73.4533 19.4663 76.5509 20.3513 77.436 20.3513C77.8785 20.3513 78.7635 20.7938 79.206 21.2364C79.6485 21.6789 79.6485 22.1214 80.0911 22.5639Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M51.3276 16.8097C51.7701 17.2522 53.9827 18.5798 56.1953 19.4648L73.0109 29.2001C75.2234 30.5277 77.436 31.8552 77.8785 31.8552C78.7636 32.2977 79.2061 33.1828 79.2061 34.5103V65.4864C79.2061 66.3714 78.7636 67.699 77.8785 68.1415C77.436 68.584 75.2234 69.9116 73.0109 70.7966L56.1953 80.5319C53.9827 81.8595 51.7701 83.187 51.3276 83.6296L50 84.0721L48.6725 83.6296C48.23 83.187 46.0174 81.8595 43.8048 80.5319L26.9892 70.7966C24.7767 69.4691 22.5641 68.1415 22.1216 68.1415L22.5641 67.699V66.814C23.0066 67.2565 25.2192 68.584 27.4318 69.9116L44.2473 79.6469C46.4599 80.9745 48.6725 82.302 49.115 82.302H50H50.8851C51.3276 81.8595 53.5402 80.5319 55.7527 79.6469L72.5683 69.9116C74.7809 68.584 76.9935 67.2565 77.436 66.814L78.321 65.4864V34.5103L77.436 33.1828C76.9935 32.7403 74.7809 31.4127 72.5683 30.5277L55.7527 20.7923C53.5402 19.4648 51.3276 18.1373 50.8851 18.1373H50H49.115C48.6725 18.1373 46.4599 19.4648 44.2473 20.7923L27.4318 30.5277C25.2192 31.8552 23.0066 33.1828 22.5641 33.6253L21.679 34.5103V65.4864L22.5641 66.814L22.1216 67.2565L21.679 67.699C20.794 67.2565 20.3515 66.3714 20.3515 65.0439C20.3515 64.1589 20.3515 61.9463 20.3515 59.2912V39.8205V34.5103C20.3515 33.6253 20.794 32.2977 21.679 31.8552C22.5641 31.8552 24.7767 30.5277 26.5467 29.2001L43.3623 19.4648C45.5749 18.1372 47.7875 16.8097 48.23 16.8097L49.5575 16.3672L51.3276 16.8097Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M30.0868 27.8722C30.5294 27.8722 32.7419 26.9872 34.9545 26.5447L51.7701 22.1195C53.9827 21.677 56.1953 20.792 56.6378 20.792C57.5228 20.792 58.4078 20.792 59.2929 21.677C59.7354 22.1195 61.5055 23.8896 62.833 25.2171L75.2234 37.6076C76.551 38.9351 78.321 40.7052 78.7636 41.1477C79.2061 42.0327 79.6486 42.9178 79.6486 43.8028C79.6486 44.2453 78.7636 46.4579 78.321 48.6705L73.8959 65.4861C73.4534 67.6986 72.5683 69.9112 72.5683 70.3537L71.2408 71.2388L69.9132 72.1238C69.4707 72.1238 67.2582 73.0088 65.0456 73.4513L48.23 77.8765C46.0174 78.319 43.8048 79.204 43.3623 79.204V78.7615V78.319C43.8048 78.319 46.0174 77.434 48.23 76.9915L65.0456 72.5663C67.2582 72.1238 69.4707 71.2388 69.9132 71.2388L70.3558 70.7963L70.7983 70.3537C70.7983 69.9112 71.6833 67.6986 72.1258 65.4861L76.551 48.6705C76.9935 46.4579 77.8785 44.2453 77.8785 43.8028L77.436 42.4753C76.9935 42.0327 75.2234 40.2627 73.8959 38.9351L61.5055 26.1022C60.1779 24.7746 58.4078 23.0046 57.9653 22.5621L56.6378 22.1195C56.1953 22.1195 53.9827 23.0046 51.7701 23.4471L34.9545 27.8722C32.7419 28.3148 30.5294 29.1998 30.0868 29.1998L29.6443 29.6423L29.2018 30.0848C29.2018 30.5273 28.3168 32.7399 27.8743 34.9525L23.4491 51.7681C23.0066 53.9807 22.1216 56.1932 22.1216 56.6357L22.5641 57.9633C23.0066 58.4058 24.7767 60.1759 26.1042 61.5034L38.4946 73.8939C39.8222 75.2214 41.5922 76.9915 42.0348 77.434L43.3623 77.8765V78.319V78.7615C42.4773 78.7615 41.5923 78.7615 40.7072 77.8765C40.2647 77.434 38.4946 75.6639 37.1671 74.3364L24.7767 61.9459C23.4491 60.6184 21.679 58.8483 21.2365 58.4058C20.794 57.5208 20.3515 56.6357 20.3515 55.7507C20.3515 55.3082 21.2365 53.0956 21.679 50.8831L26.1042 34.0675C26.5467 31.8549 27.4318 29.6423 27.4318 29.1998L28.3168 27.8722H30.0868Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M25.6617 74.7808C11.9437 61.0628 11.9437 39.3795 25.6617 25.6615C39.3796 11.9436 61.0629 11.9436 74.7809 25.6615C88.4989 39.3795 88.4989 61.0628 74.7809 74.7808C61.0629 88.0562 38.9371 88.0562 25.6617 74.7808ZM73.4533 73.4532C86.2863 60.6203 86.2863 39.3795 73.4533 26.5466C60.6204 13.7136 39.8222 13.7136 26.5467 26.5466C13.7137 39.822 13.7137 60.6203 26.5467 73.8957C39.3796 86.7287 60.6204 86.7287 73.4533 73.4532Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M50.885 25.2189C51.3275 25.6614 53.0976 26.5464 54.4251 27.4315L66.8156 34.5117C68.5856 35.3967 69.9132 36.2818 70.3557 36.7243C70.7982 37.1668 71.2407 38.0518 71.2407 38.4944V61.5052C71.2407 62.3902 70.7982 62.8327 70.3557 63.2752C69.9132 63.7177 68.1431 64.6028 66.8156 65.4878L54.4251 72.5681C52.6551 73.4531 51.3275 74.3381 50.885 74.7806H50H49.115C48.6724 74.3381 46.9024 73.4531 45.5748 72.5681L33.1844 65.4878C31.4143 64.6028 30.0868 63.7177 29.6443 63.2752L30.0868 62.8327L30.5293 62.3902C30.9718 62.8327 32.7419 63.7177 34.0694 64.6028L46.4599 71.683C48.2299 72.5681 49.5575 73.4531 50 73.8956H50.4425H50.885C51.3275 73.4531 53.0976 72.5681 54.4251 71.683L66.8156 64.6028C68.5856 63.7177 69.9132 62.8327 70.3557 62.3902L70.7982 61.5052V38.4944L70.3557 37.6093C69.9132 37.1668 68.1431 36.2818 66.8156 35.3967L54.4251 28.3165C52.6551 26.9889 50.885 26.1039 50.4425 26.1039H50H49.5575C49.115 26.1039 47.3449 26.9889 45.5748 28.3165L33.1844 35.3967C31.8568 36.2818 30.0868 37.1668 29.6443 37.6093L29.2018 38.4944V61.5052L29.6443 62.3902L29.2018 62.8327L28.7592 63.2752C28.3167 62.8327 27.8742 61.9477 27.8742 61.5052V38.4944C27.8742 37.6093 28.3167 37.1668 28.7592 36.7243C29.6443 36.2818 31.4143 35.3967 32.7419 34.5117L45.1323 27.4315C46.9024 26.1039 48.2299 25.2189 49.115 25.2189L50 24.7764L50.885 25.2189Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M31.8569 68.1433C21.679 57.9654 21.679 42.0349 31.8569 31.857C42.0348 21.6792 57.9653 21.6792 68.1432 31.857C78.321 42.0349 78.321 57.9654 68.1432 68.1433C57.9653 78.3212 42.0348 77.8786 31.8569 68.1433ZM67.2581 67.2583C76.9935 57.5229 76.9935 42.0349 67.2581 32.7421C57.5228 23.0067 42.0348 23.0067 32.7419 32.7421C23.0066 42.4774 23.0066 57.9654 32.7419 67.2583C42.0348 76.9936 57.5228 76.9936 67.2581 67.2583Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M80.0911 22.5639C80.5336 23.4489 80.9761 26.5465 81.8611 29.2016L88.0563 51.7699C88.9414 54.8675 89.8264 57.5226 89.8264 58.4077C90.2689 59.7352 89.8264 61.0628 88.9414 61.9478C88.4989 62.3903 86.2863 64.6029 84.0737 66.8155L67.2581 83.6311C65.0455 85.8436 62.833 88.0562 62.3904 88.4987C61.5054 89.3838 60.1779 89.8263 58.8503 89.3838C57.9653 89.3838 54.8677 88.4987 52.2126 87.6137L29.6443 81.4185C26.9892 80.5334 23.8916 79.6484 23.0066 79.6484C22.564 79.6484 21.679 79.2059 21.2365 78.7634C20.794 78.3209 20.3515 77.8783 20.3515 76.9933C20.3515 76.1083 19.4664 73.0107 18.5814 70.3556L11.9437 48.2298C11.5011 45.1322 10.6161 42.0346 10.1736 41.5921L11.0586 41.1496L11.9437 40.707C12.3862 41.5921 13.2712 44.6897 13.7137 47.7873L19.9089 70.3556C20.794 73.0107 21.679 76.1083 21.679 76.9933L22.1215 77.8783L23.0066 78.3209C23.8916 78.7634 26.9892 79.2059 29.6443 80.0909L52.2126 86.2861C54.8677 87.1712 57.9653 88.0562 58.8503 88.0562L60.6204 87.6137C61.0629 87.1712 63.2755 84.9586 65.488 82.746L82.3036 65.9304C84.5162 63.7178 86.7288 61.5053 87.1713 61.0628L87.6138 59.2927C87.1713 58.4077 86.7288 55.3101 85.8438 52.655L80.0911 30.0867C79.206 26.9891 78.321 24.334 78.321 23.4489L77.8785 22.5639L76.9934 22.1214C76.1084 21.6789 73.0108 21.2364 70.3557 20.3513L47.3449 14.1561C44.6898 13.2711 41.5922 12.386 40.7072 12.386L38.9371 12.8286C38.4946 13.2711 36.282 15.4837 34.0694 17.6962L17.2538 34.0693C15.0413 36.2819 12.8287 38.4945 12.3862 38.937L11.9437 40.707L11.0586 41.1496L10.1736 41.5921C9.73109 40.2645 10.1736 38.937 11.0586 38.0519C11.9437 37.1669 14.1562 34.9543 16.3688 33.1843L33.1844 16.3687C35.397 14.1561 37.6096 11.9435 38.0521 11.501C38.9371 10.616 40.2647 10.1735 41.5922 10.616C42.4772 11.0585 45.5748 11.501 48.2299 12.386L70.7982 18.5813C73.4533 19.4663 76.5509 20.3513 77.436 20.3513C77.8785 20.3513 78.7635 20.7938 79.206 21.2364C79.6485 21.6789 79.6485 22.1214 80.0911 22.5639Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M51.3276 16.8097C51.7701 17.2522 53.9827 18.5798 56.1953 19.4648L73.0109 29.2001C75.2234 30.5277 77.436 31.8552 77.8785 31.8552C78.7636 32.2977 79.2061 33.1828 79.2061 34.5103V65.4864C79.2061 66.3714 78.7636 67.699 77.8785 68.1415C77.436 68.584 75.2234 69.9116 73.0109 70.7966L56.1953 80.5319C53.9827 81.8595 51.7701 83.187 51.3276 83.6296L50 84.0721L48.6725 83.6296C48.23 83.187 46.0174 81.8595 43.8048 80.5319L26.9892 70.7966C24.7767 69.4691 22.5641 68.1415 22.1216 68.1415L22.5641 67.699V66.814C23.0066 67.2565 25.2192 68.584 27.4318 69.9116L44.2473 79.6469C46.4599 80.9745 48.6725 82.302 49.115 82.302H50H50.8851C51.3276 81.8595 53.5402 80.5319 55.7527 79.6469L72.5683 69.9116C74.7809 68.584 76.9935 67.2565 77.436 66.814L78.321 65.4864V34.5103L77.436 33.1828C76.9935 32.7403 74.7809 31.4127 72.5683 30.5277L55.7527 20.7923C53.5402 19.4648 51.3276 18.1373 50.8851 18.1373H50H49.115C48.6725 18.1373 46.4599 19.4648 44.2473 20.7923L27.4318 30.5277C25.2192 31.8552 23.0066 33.1828 22.5641 33.6253L21.679 34.5103V65.4864L22.5641 66.814L22.1216 67.2565L21.679 67.699C20.794 67.2565 20.3515 66.3714 20.3515 65.0439C20.3515 64.1589 20.3515 61.9463 20.3515 59.2912V39.8205V34.5103C20.3515 33.6253 20.794 32.2977 21.679 31.8552C22.5641 31.8552 24.7767 30.5277 26.5467 29.2001L43.3623 19.4648C45.5749 18.1372 47.7875 16.8097 48.23 16.8097L49.5575 16.3672L51.3276 16.8097Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M30.0868 27.8722C30.5294 27.8722 32.7419 26.9872 34.9545 26.5447L51.7701 22.1195C53.9827 21.677 56.1953 20.792 56.6378 20.792C57.5228 20.792 58.4078 20.792 59.2929 21.677C59.7354 22.1195 61.5055 23.8896 62.833 25.2171L75.2234 37.6076C76.551 38.9351 78.321 40.7052 78.7636 41.1477C79.2061 42.0327 79.6486 42.9178 79.6486 43.8028C79.6486 44.2453 78.7636 46.4579 78.321 48.6705L73.8959 65.4861C73.4534 67.6986 72.5683 69.9112 72.5683 70.3537L71.2408 71.2388L69.9132 72.1238C69.4707 72.1238 67.2582 73.0088 65.0456 73.4513L48.23 77.8765C46.0174 78.319 43.8048 79.204 43.3623 79.204V78.7615V78.319C43.8048 78.319 46.0174 77.434 48.23 76.9915L65.0456 72.5663C67.2582 72.1238 69.4707 71.2388 69.9132 71.2388L70.3558 70.7963L70.7983 70.3537C70.7983 69.9112 71.6833 67.6986 72.1258 65.4861L76.551 48.6705C76.9935 46.4579 77.8785 44.2453 77.8785 43.8028L77.436 42.4753C76.9935 42.0327 75.2234 40.2627 73.8959 38.9351L61.5055 26.1022C60.1779 24.7746 58.4078 23.0046 57.9653 22.5621L56.6378 22.1195C56.1953 22.1195 53.9827 23.0046 51.7701 23.4471L34.9545 27.8722C32.7419 28.3148 30.5294 29.1998 30.0868 29.1998L29.6443 29.6423L29.2018 30.0848C29.2018 30.5273 28.3168 32.7399 27.8743 34.9525L23.4491 51.7681C23.0066 53.9807 22.1216 56.1932 22.1216 56.6357L22.5641 57.9633C23.0066 58.4058 24.7767 60.1759 26.1042 61.5034L38.4946 73.8939C39.8222 75.2214 41.5922 76.9915 42.0348 77.434L43.3623 77.8765V78.319V78.7615C42.4773 78.7615 41.5923 78.7615 40.7072 77.8765C40.2647 77.434 38.4946 75.6639 37.1671 74.3364L24.7767 61.9459C23.4491 60.6184 21.679 58.8483 21.2365 58.4058C20.794 57.5208 20.3515 56.6357 20.3515 55.7507C20.3515 55.3082 21.2365 53.0956 21.679 50.8831L26.1042 34.0675C26.5467 31.8549 27.4318 29.6423 27.4318 29.1998L28.3168 27.8722H30.0868Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M25.6617 74.7808C11.9437 61.0628 11.9437 39.3795 25.6617 25.6615C39.3796 11.9436 61.0629 11.9436 74.7809 25.6615C88.4989 39.3795 88.4989 61.0628 74.7809 74.7808C61.0629 88.0562 38.9371 88.0562 25.6617 74.7808ZM73.4533 73.4532C86.2863 60.6203 86.2863 39.3795 73.4533 26.5466C60.6204 13.7136 39.8222 13.7136 26.5467 26.5466C13.7137 39.822 13.7137 60.6203 26.5467 73.8957C39.3796 86.7287 60.6204 86.7287 73.4533 73.4532Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M50.885 25.2189C51.3275 25.6614 53.0976 26.5464 54.4251 27.4315L66.8156 34.5117C68.5856 35.3967 69.9132 36.2818 70.3557 36.7243C70.7982 37.1668 71.2407 38.0518 71.2407 38.4944V61.5052C71.2407 62.3902 70.7982 62.8327 70.3557 63.2752C69.9132 63.7177 68.1431 64.6028 66.8156 65.4878L54.4251 72.5681C52.6551 73.4531 51.3275 74.3381 50.885 74.7806H50H49.115C48.6724 74.3381 46.9024 73.4531 45.5748 72.5681L33.1844 65.4878C31.4143 64.6028 30.0868 63.7177 29.6443 63.2752L30.0868 62.8327L30.5293 62.3902C30.9718 62.8327 32.7419 63.7177 34.0694 64.6028L46.4599 71.683C48.2299 72.5681 49.5575 73.4531 50 73.8956H50.4425H50.885C51.3275 73.4531 53.0976 72.5681 54.4251 71.683L66.8156 64.6028C68.5856 63.7177 69.9132 62.8327 70.3557 62.3902L70.7982 61.5052V38.4944L70.3557 37.6093C69.9132 37.1668 68.1431 36.2818 66.8156 35.3967L54.4251 28.3165C52.6551 26.9889 50.885 26.1039 50.4425 26.1039H50H49.5575C49.115 26.1039 47.3449 26.9889 45.5748 28.3165L33.1844 35.3967C31.8568 36.2818 30.0868 37.1668 29.6443 37.6093L29.2018 38.4944V61.5052L29.6443 62.3902L29.2018 62.8327L28.7592 63.2752C28.3167 62.8327 27.8742 61.9477 27.8742 61.5052V38.4944C27.8742 37.6093 28.3167 37.1668 28.7592 36.7243C29.6443 36.2818 31.4143 35.3967 32.7419 34.5117L45.1323 27.4315C46.9024 26.1039 48.2299 25.2189 49.115 25.2189L50 24.7764L50.885 25.2189Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
      <path
        d="M31.8569 68.1433C21.679 57.9654 21.679 42.0349 31.8569 31.857C42.0348 21.6792 57.9653 21.6792 68.1432 31.857C78.321 42.0349 78.321 57.9654 68.1432 68.1433C57.9653 78.3212 42.0348 77.8786 31.8569 68.1433ZM67.2581 67.2583C76.9935 57.5229 76.9935 42.0349 67.2581 32.7421C57.5228 23.0067 42.0348 23.0067 32.7419 32.7421C23.0066 42.4774 23.0066 57.9654 32.7419 67.2583C42.0348 76.9936 57.5228 76.9936 67.2581 67.2583Z"
        className="fill-[#1B9FAC] dark:fill-white"
      />
    </SvgIcon>
  );
};
