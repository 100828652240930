import { Outlet, useNavigate, useRouterState } from "@tanstack/react-router";
import { usePerformanceTracker, usePermissions } from "prisma";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PageHeader, PageLayout, Tab, TabList } from "ui";

export default function TrustedIpAddresses() {
  const permissions = usePermissions();
  const loginViewPermissions = permissions.settingsLoginIpAddresses.READ;
  const alertViewPermissions = permissions.settingsAlertIpAddresses.READ;
  usePerformanceTracker(
    "TrustedIpAddressesSettings",
    "TrustedIpAddressesSettings",
  );

  const intl = useIntl();
  const navigate = useNavigate();

  const {
    location: { pathname },
  } = useRouterState();

  useEffect(() => {
    if (pathname === "/settings/trusted-ip-addresses") {
      if (alertViewPermissions) {
        navigate({ to: "/settings/trusted-ip-addresses/alert" });
      } else if (loginViewPermissions) {
        navigate({ to: "/settings/trusted-ip-addresses/login" });
      }
    }
  }, [pathname, navigate, alertViewPermissions, loginViewPermissions]);

  return (
    <PageLayout
      documentTitle={[
        intl.formatMessage({
          defaultMessage: "Trusted IP Addresses",
          id: "cp0ZgF",
          description: "page header",
        }),
      ]}
      header={
        <PageHeader
          title={intl.formatMessage({
            defaultMessage: "Trusted IP Addresses",
            id: "cp0ZgF",
            description: "page header",
          })}
        />
      }
      overflow
      noPadding
      tabList={
        <TabList activeTabId={pathname}>
          {alertViewPermissions && (
            <Tab
              id="/settings/trusted-ip-addresses/alert"
              onClick={() =>
                navigate({ to: "/settings/trusted-ip-addresses/alert" })
              }
            >
              <FormattedMessage
                defaultMessage="Trusted Alert IP Addresses"
                id="RlCYBF"
                description="tab title"
              />
            </Tab>
          )}
          {loginViewPermissions && (
            <Tab
              id="/settings/trusted-ip-addresses/login"
              onClick={() =>
                navigate({ to: "/settings/trusted-ip-addresses/login" })
              }
            >
              <FormattedMessage
                defaultMessage="Trusted Login IP Addresses"
                id="c3gQ02"
                description="tab title"
              />
            </Tab>
          )}
        </TabList>
      }
      allowed={loginViewPermissions || alertViewPermissions}
    >
      <Outlet />
    </PageLayout>
  );
}
