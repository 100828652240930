import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";

import { type cloudAccountsKeys } from "../cloudAccountsKeys";
import { CloudAccountRestDataSchema } from "./schemas";

export const getCloudAccountRestData = async ({
  queryKey: [{ accountId, cloudType, includeGroupInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["cloudAccountsRest"]>
>) => {
  return jsonApi({
    path: `cloud/${cloudType}/${accountId}`,
    params: {
      includeGroupInfo,
    },
    requestSchema: z.void(),
    responseSchema: CloudAccountRestDataSchema,
  });
};
