import { useState, type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Input } from "ui";

import ErrorString from "./ErrorString";

type SNSTopicInputPropTypes = {
  cloudTrailTopic: string;
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
};

export default function SNSTopicInput({
  cloudTrailTopic,
  setCloudTrailTopic,
}: SNSTopicInputPropTypes) {
  const intl = useIntl();
  const [isDirty, setIsDirty] = useState(false);
  const [topic, setTopic] = useState(cloudTrailTopic);

  const requiredErrorMessage = intl.formatMessage({
    defaultMessage: "SNS Topic is required",
    id: "ILX7vx",
    description: "Error message for empty SNS Topic input",
  });

  return (
    <>
      <Input
        placeholder="Enter SNS Topic Name..."
        onChange={(e) => {
          if (!isDirty) setIsDirty(true);
          const newTopic = e?.target?.value;

          setTopic(newTopic);

          // set wizard value
          setCloudTrailTopic(newTopic);
        }}
        value={topic}
      />
      {isDirty && topic.length === 0 && (
        <ErrorString errorMessage={requiredErrorMessage} />
      )}
    </>
  );
}
