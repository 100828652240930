import { useRouter } from "@tanstack/react-router";
import { useIntl } from "react-intl";
import { Modal, ModalHeader } from "ui";
import { LoadingPage } from "ui/src/components/PageLayout";

export default function LoadingEditCollectionWizard() {
  const intl = useIntl();
  const router = useRouter();

  return (
    <Modal isOpen={true} onClose={() => router.history.back()} size="xl">
      <ModalHeader
        title={intl.formatMessage({
          defaultMessage: "Edit Collection",
          id: "i5gM1l",
          description: "Modal title",
        })}
      />
      <LoadingPage />
    </Modal>
  );
}
