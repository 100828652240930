import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  AWS,
  MASTER_SERVICE_ACCOUNT,
  ORGANIZATION,
  TENANT,
} from "../../../../constants";
import { featuresMessageMap } from "../../../../messages/featuresMessageMap";

import {
  Body,
  Bold,
  Checkbox,
  DynamicFormattedMessage,
  Tag,
  Tooltip,
} from "ui";

import { CloudScanIcon, InfoTooltipIcon, ScanSameAccountIcon } from "icons";

import { RadioGroup, useWatch } from "form";
import { useFlags } from "launchdarkly-react-client-sdk";
import { type ReactNode } from "react";
import { type CloudType } from "../../../../types";
import AutoScanOrgToggle from "../AutoScanOrgToggle";
import FeatureTooltip from "../FeatureTooltip";
import ScanTypeOptions from "./ScanTypeOptions";

export const tagMessageMap = defineMessages({
  checked: {
    defaultMessage: "Requires additional permissions",
    id: "s70rsq",
    description: "Tag text when feature is checked",
  },
  default: {
    defaultMessage: "Default",
    id: "F7/OIU",
    description: "Tag text when feature is default",
  },
  subscribed: {
    defaultMessage: "Subscribed",
    id: "raQiSg",
    description: "Tag text when feature is subscribed",
  },
  not_subscribed: {
    defaultMessage: "Not Subscribed",
    id: "WiE7DC",
    description: "Tag text when feature is not subscribed",
  },
});

type CheckboxFeatureCardPropTypes = {
  checked: boolean;
  id: string;
  icon: ReactNode;
  onChange: (...event: unknown[]) => void;
  toggleChecked: boolean;
  cloudType?: CloudType;
  accountType?: string;
  isComputeWorkloadSupported?: boolean;
  showCheckbox?: boolean;
  showToggle?: boolean;
  tag?: string;
  deplomentType?: string;
  onToggle?: (...event: unknown[]) => void;
};

export default function CheckboxFeatureCard({
  checked,
  id,
  icon,
  toggleChecked,
  cloudType,
  accountType,
  isComputeWorkloadSupported = false,
  onChange,
  showCheckbox = true,
  showToggle = false,
  tag,
  onToggle,
  deplomentType,
  ...passThrough
}: CheckboxFeatureCardPropTypes) {
  const intl = useIntl();
  const { cloudScanMode, hubAccountScanType } = useFlags();
  const isGovCloud = deplomentType?.includes("gov");
  const mode = useWatch({ name: "mode" });

  let featureId = id;
  if (cloudType === AWS && id === "threatDetection") {
    featureId = "threatDetection_aws";
  }

  const isGroupedAccount =
    isComputeWorkloadSupported &&
    (accountType === ORGANIZATION ||
      accountType === TENANT ||
      accountType === MASTER_SERVICE_ACCOUNT);

  const items = [
    {
      value: "cloud-scan",
      tag: (
        <Bold>
          <Tag>
            <FormattedMessage
              defaultMessage="Recommended"
              id="pnEgtL"
              description="Description for Recommended label"
            />
          </Tag>
        </Bold>
      ),
      title: "",
      description: (
        <div className="-mt-10 flex">
          <CloudScanIcon size="6xl" />
          <div className="pl-2">
            <Bold addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Cloud Scan"
                id="6IlfMe"
                description="Title for agentless scanning modes"
              />
            </Bold>
            <Body addClassName="pt-2 text-xs" appearance="secondary">
              <FormattedMessage
                defaultMessage="Scan your environments with Prisma Cloud."
                id="YgU5co"
                description="Description for agentless scanning mode"
              />
            </Body>
          </div>
        </div>
      ),
    },
    {
      value: "target-scan",
      title: "",
      description: (
        <div className="-mt-10 flex">
          <ScanSameAccountIcon size="6xl" />
          <div className="pl-2">
            <Bold addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Scan in your environment"
                id="05mm7m"
                description="Title for agentless scanning modes"
              />
            </Bold>
            <Body addClassName="pt-2 text-xs" appearance="secondary">
              <FormattedMessage
                defaultMessage="Incurs additional costs, permissions and configuration steps."
                id="s2Ni+8"
                description="Description for agentless scanning mode"
              />
            </Body>
            {hubAccountScanType && mode === "target-scan" && (
              <ScanTypeOptions accountType={accountType} />
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className="border-b p-4 last:border-none dark:border-blue-steel-500"
        data-testid="checkbox-feature-card"
      >
        <div className="flex">
          <div className="w-7">
            {showCheckbox && (
              <Checkbox
                data-selector={id}
                checked={checked}
                onChange={onChange}
                {...passThrough}
              />
            )}
          </div>
          <div className="flex space-x-2">
            {icon}
            <div className="-mt-1">
              <Bold as="p" addClassName="flex items-center space-x-1">
                <span>
                  <DynamicFormattedMessage
                    messageMap={featuresMessageMap}
                    token={id}
                  />
                </span>
                <Tooltip
                  label={
                    <FeatureTooltip
                      id={id}
                      header={
                        <DynamicFormattedMessage
                          messageMap={featuresMessageMap}
                          token={id}
                        />
                      }
                      content={
                        <DynamicFormattedMessage
                          messageMap={featuresMessageMap}
                          token={`${featureId}_tooltip_content`}
                          fallback={
                            featuresMessageMap[
                              "misconfigurations_tooltip_content"
                            ]
                          }
                        />
                      }
                    />
                  }
                >
                  <span>
                    <InfoTooltipIcon aria-label={`infoIcon-${id}`} />
                  </span>
                </Tooltip>
              </Bold>
              <Body appearance="secondary" size="sm">
                <DynamicFormattedMessage
                  messageMap={featuresMessageMap}
                  token={`${featureId}_desc`}
                />
              </Body>
              {isGroupedAccount && showToggle && onToggle && checked && (
                <div className="mt-3">
                  <AutoScanOrgToggle
                    checked={toggleChecked}
                    onChange={onToggle}
                  />
                </div>
              )}
            </div>
          </div>

          {(!!tag || checked) && (
            <div className="ml-auto">
              <Tag size={checked ? "md" : "sm"}>
                <DynamicFormattedMessage
                  messageMap={tagMessageMap}
                  token={checked ? "checked" : (tag as string)}
                />
              </Tag>
            </div>
          )}
        </div>

        {cloudScanMode &&
          id === "agentlessScan" &&
          !isGovCloud &&
          checked &&
          cloudType === AWS && (
            <div className="mt-8 space-y-4 pl-12">
              <Body>
                <FormattedMessage
                  defaultMessage="Select where to scan"
                  id="LRbe9m"
                  description="Scanning options title"
                />
              </Body>
              <div className="-ml-2">
                <RadioGroup
                  addClassName="flex space-x-2 space-y-0 [&>label>div>div:last-child>p]:items-start [&>label>div]:h-full [&>label]:relative [&>label]:flex-1"
                  style={{ flexDirection: "row" }}
                  data-testid="scanning-mode-radio-group"
                  name="mode"
                  items={items}
                  label={intl.formatMessage({
                    defaultMessage: "Scanning Modes",
                    id: "0A3Fqp",
                    description: "radio group description",
                  })}
                  showLabel={false}
                />
              </div>
            </div>
          )}
        {!cloudScanMode &&
          id === "agentlessScan" &&
          checked &&
          hubAccountScanType &&
          cloudType === AWS && <ScanTypeOptions accountType={accountType} />}
      </div>
    </>
  );
}
