import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../SvgIcon";

export const HighSeverityVulnerabilityIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "High",
    id: "qyk8Br",

    description: "severity icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} {...props} viewBox="0 0 20 20" fill="none">
      {showNativeTooltip && <title>{alt}</title>}
      <rect
        x="2"
        y="2"
        width="16"
        height="16"
        rx="4"
        className="fill-[#D13C3C] dark:fill-[#FF7979]"
      />
      <path
        d="M13.09 6.047V14H11.605V10.524H7.8705V14H6.3855V6.047H7.8705V9.468H11.605V6.047H13.09Z"
        className="fill-white dark:fill-gray-900"
      />
    </SvgIcon>
  );
};
