export const addItemIfMissing = (arr: string[], item: string) => {
  if (!arr) {
    return [];
  } else if (arr.includes(item)) {
    return arr;
  } else {
    return [item, ...arr];
  }
};

export const getExistingOrNew = (item: string, arr: string[]) => {
  return arr.some((value) => value === item)
    ? { value: "Select Existing" }
    : { value: "Add New" };
};

// used when input is an array of strings
export const itemize = (arr: string[] = []) => arr.map((value) => ({ value }));
