import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../SvgIcon";

export const LowSeverityVulnerabilityIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Low",
    id: "hYDdpC",

    description: "severity icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} {...props} viewBox="0 0 20 20" fill="none">
      {showNativeTooltip && <title>{alt}</title>}
      <rect
        x="2"
        y="2"
        width="16"
        height="16"
        rx="4"
        className="fill-[#CE8200] dark:fill-[#FFE588]"
      />
      <path
        d="M12.544 12.779V14H7.8855V6.047H9.365V12.779H12.544Z"
        className="fill-white dark:fill-gray-900"
      />
    </SvgIcon>
  );
};
