import { defineMessages } from "react-intl";

export const statusHeaderMap = defineMessages({
  agentless_scanning: {
    defaultMessage: "Agentless Workload Scanning",
    id: "nY5Zm/",
    description: "Agentless Workload Scanning Status Header",
  },
  ami_scan: {
    defaultMessage: "AMI Scan",
    id: "+saeCB",
    description: "AMI Scan Status Header",
  },
  audit_log: {
    defaultMessage: "Audit Logs",
    id: "V9cJpm",
    description: "Audit Log Status Header",
  },
  audit_logs: {
    defaultMessage: "Audit Logs",
    id: "JPdODa",
    description: "Audit Logs Status Header",
  },
  authentication: {
    defaultMessage: "Authentication",
    id: "SdJRsp",
    description: "Description for Authentication on review status",
  },
  azure_active_directory_authentication: {
    defaultMessage: "Azure Active Directory Authentication",
    id: "pAwSvx",
    description: "Azure Active Directory Authentication Status Header",
  },
  azure_config_metadata: {
    defaultMessage: "Asset Configuration",
    id: "XkaXSV",
    description: "Azure Config Metadata Status Header",
  },
  azure_flow_logs: {
    defaultMessage: "Flow Logs",
    id: "gJJnWV",
    description: "Flow Logs Status Header",
  },
  compute: {
    defaultMessage: "Compute",
    id: "G1qzpZ",
    description: "Compute Status Header",
  },
  config: {
    defaultMessage: "Asset Configuration",
    id: "D9GNi6",
    description: "Config Status Header",
  },
  compute_discovery: {
    defaultMessage: "Workload Discovery",
    id: "VIK4yQ",
    description: "Workload Discovery Status Header",
  },
  flow_log: {
    defaultMessage: "Flow Logs",
    id: "gJJnWV",
    description: "Flow Logs Status Header",
  },
  flow_logs: {
    defaultMessage: "Flow Logs",
    id: "gJJnWV",
    description: "Flow Logs Status Header",
  },
  guard_duty: {
    defaultMessage: "GuardDuty",
    id: "yAeQL9",
    description: "GuardDuty Status Header",
  },
  aws_guard_duty: {
    defaultMessage: "GuardDuty",
    id: "yAeQL9",
    description: "GuardDuty Status Header",
  },
  inspector: {
    defaultMessage: "Inspector",
    id: "GsiqhQ",
    description: "Inspector Status Header",
  },
  aws_inspector: {
    defaultMessage: "Inspector",
    id: "GsiqhQ",
    description: "Inspector Status Header",
  },
  org: {
    defaultMessage: "Organization",
    id: "LXETK6",
    description: "Organization Status Header",
  },
  organization: {
    defaultMessage: "Organization",
    id: "LXETK6",
    description: "Organization Status Header",
  },
  vpc_flow: {
    defaultMessage: "Flow Logs",
    id: "gJJnWV",
    description: "Flow Logs Status Header",
  },
  misconfigurations: {
    defaultMessage: "Misconfigurations (CSPM)",
    id: "iz+38E",
    description: "Misconfigurations (CSPM) column Header",
  },
  identity_security: {
    defaultMessage: "Identity Security",
    id: "wW2QdZ",
    description: "Identity Security column Header",
  },
  agentless_workload_scanning: {
    defaultMessage: "Agentless Workload Scanning",
    id: "Egb+J+",
    description: "Agentless Workload Scanning column Header",
  },
  threat_detection: {
    defaultMessage: "Threat Detection",
    id: "iumMiC",
    description: "Threat Detection column Header",
  },
  auto_protect: {
    defaultMessage: "Agent-Based Workload Protection",
    id: "D3JonK",
    description: "Agent Based Workload Protection column Header",
  },
  data_security: {
    defaultMessage: "Data Security",
    id: "zVXgog",
    description: "Data Security Status Header",
  },
  remediation: {
    defaultMessage: "Remediation",
    id: "eIqM0C",
    description: "Remediation Status Header",
  },
  serverless_function_scanning: {
    defaultMessage: "Serverless Function Scanning",
    id: "9fZo6R",
    description: "Serverless Function Scanning Status Header",
  },
  organization_role_viewer: {
    defaultMessage: "Organization Role Viewer",
    id: "YWLqWu",
    description: "Organization Role Viewer provided status message",
  },
  agentless_api_discovery: {
    defaultMessage: "Agentless API Discovery",
    id: "nbeOVO",
    description: "Agentless API Discovery provided status message",
  },
  default_member_state: {
    defaultMessage: "Organization Scan",
    id: "5ysFq4",
    description: "Agentless API Discovery provided status message",
  },
  scan_mode: {
    defaultMessage: "Where to scan",
    id: "dAlMcK",
    description: "Agentless API Discovery provided status message",
  },
  hub_account_ids: {
    defaultMessage: "Hub account IDs",
    id: "iw+6mk",
    description: "Hub account IDs status message",
  },
});

export const scanModeTextMap = defineMessages({
  "cloud-scan": {
    defaultMessage: "Scan in Prisma",
    id: "0dcF2N",
    description: "Status message",
  },
  "target-scan": {
    defaultMessage: "Scan with same account",
    id: "uGJ4d2",
    description: "Status message",
  },
  "hub-scan": {
    defaultMessage: "Scan with Hub account",
    id: "a8N4RC",
    description: "Status message",
  },
  hub: {
    defaultMessage: "Set as hub",
    id: "yb+61g",
    description: "Status message",
  },
});
