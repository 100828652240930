import {
  type GetIdentitiesResponseSchema,
  type IdentitySchema,
} from "./identities";
import {
  type AccessActivationAPIResponseSchema,
  type AccessActivationConsoleResponseSchema,
  type AccessActivationResponseSchema,
  type AccessKeySchema,
  type AccessRequestRowItemSchema,
  type AccessRequestStatusSchema,
  type CreateAccessRequestRequestSchema,
  type GetAccessRequestsResponseSchema,
  type PoliciesSchema,
  type TimeIntervalSchema,
  type UpdateMultipleAccessRequestSchema,
  type UpdateMultipleAccessResponseSchema,
} from "./requests";
import {
  type GetRoleBindingsResponseSchema,
  type GetRolePoliciesResponseSchema,
  type GetRolesResponseSchema,
  type RoleBindingRequestBodySchema,
  type RoleBindingResponseItemSchema,
  type RoleItemSchema,
  type RolePolicyItemSchema,
} from "./roles";

import { type UseQueryResult } from "@tanstack/react-query";
import { type z } from "zod";
import { type jsonApi } from "../../apis";
import {
  type CreateSlackWorkspaceUserRequestSchema,
  type GetUserSettingsResponseSchema,
  type GetUsersSettingsResponseSchema,
  type UpdateUsersSettingsFailedSchema,
  type UpdateUsersSettingsResponseSchema,
  type UserSettingsItemSchema,
  type UserSettingsRequestBodySchema,
  type UsersSettingsRequestBodySchema,
} from "./settings";

export type RequestProperties = Omit<
  Parameters<typeof jsonApi>[0],
  "requestSchema" | "responseSchema" | "path"
>;

export type TimeIntervalType = z.infer<typeof TimeIntervalSchema>;
export enum AccessRequestType {
  ALL = "access-request",
  READY_FOR_ACTIVATION = "ready-for-activation-request",
  APPROVER_REQUEST = "approver-request",
  SESSION = "session",
}
export enum RequestActivationType {
  CONSOLE = "management-console-activation",
  API = "api-access-activation",
}

export type AccessRequestStatusType = z.infer<typeof AccessRequestStatusSchema>;

export type AccessRequestRowItemType = z.infer<
  typeof AccessRequestRowItemSchema
>;
export type GetAccessRequestResponseType = z.infer<
  typeof GetAccessRequestsResponseSchema
>;
export type CreateAccessRequestRequestType = z.infer<
  typeof CreateAccessRequestRequestSchema
>;
export type UpdateMultipleAccessRequestType = z.infer<
  typeof UpdateMultipleAccessRequestSchema
>;
export type UpdateMultipleAccessResponseType = z.infer<
  typeof UpdateMultipleAccessResponseSchema
>;

export type PoliciesType = z.infer<typeof PoliciesSchema>;
export type RolePolicyItemType = z.infer<typeof RolePolicyItemSchema>;
export type GetRolesResponseType = z.infer<typeof GetRolesResponseSchema>;
export type GetRolePoliciesResponseType = z.infer<
  typeof GetRolePoliciesResponseSchema
>;

export type AccessKeyType = z.infer<typeof AccessKeySchema>;
export type AccessActivationResponseType = z.infer<
  typeof AccessActivationResponseSchema
>;
export type AccessActivationAPIResponseType = z.infer<
  typeof AccessActivationAPIResponseSchema
>;
export type AccessActivationConsoleResponseType = z.infer<
  typeof AccessActivationConsoleResponseSchema
>;

export type RoleItemType = z.infer<typeof RoleItemSchema>;
export type GetRoleBindingsResponseType = z.infer<
  typeof GetRoleBindingsResponseSchema
>;
export type RoleBindingResponseItem = z.infer<
  typeof RoleBindingResponseItemSchema
>;
export type RoleBindingRequestBody = z.infer<
  typeof RoleBindingRequestBodySchema
>;

export type IdentityType = z.infer<typeof IdentitySchema>;
export type GetIdentitiesDataResponseType = z.infer<
  typeof GetIdentitiesResponseSchema
>;
export type GetIdentitiesDataResult = Omit<
  UseQueryResult<GetIdentitiesDataResponseType>,
  "data"
> & {
  items: IdentityType[];
};

export type CreateSlackWorkspaceUserRequestType = z.infer<
  typeof CreateSlackWorkspaceUserRequestSchema
>;

export type UserSettingsRequestBody = z.infer<
  typeof UserSettingsRequestBodySchema
>;
export type GetUserSettingsResponseType = z.infer<
  typeof GetUserSettingsResponseSchema
>;
export type UserSettingsItemType = z.infer<typeof UserSettingsItemSchema>;

export type GetUsersSettingsResponseType = z.infer<
  typeof GetUsersSettingsResponseSchema
>;
export type UsersSettingsRequestBody = z.infer<
  typeof UsersSettingsRequestBodySchema
>;
export type UpdateUsersSettingsResponseType = z.infer<
  typeof UpdateUsersSettingsResponseSchema
>;
export type UpdateUsersSettingsFailedType = z.infer<
  typeof UpdateUsersSettingsFailedSchema
>;
