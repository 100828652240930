import {
  AZURE_LOADED_NODES,
  CLEAR_FTU_FIELD_ERRORS,
  INIT_AZURE_TREE_MAP,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  UPDATE_AZURE_NODES,
} from "../../../../../../../constants";
import {
  clearFTUFieldErrors,
  initializeAzureSelectSubscriptions,
  setFTUFieldErrors,
  setFTUFieldValue,
  updateAzureLoadedNodes,
  updateAzureTreeMap,
} from "../setters";

type Actions =
  | {
      type: "Update Azure nodes";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }
  | {
      type: "Initialize Azure tree map";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }
  | {
      type: "AZURE loaded nodes";
      payload: string;
    }
  | {
      type: "clear_ftu_field_errors";
      payload: {
        field: string;
      };
    }
  | {
      type: "set_ftu_field_errors";
      payload: {
        field: string;
        errors: string[];
      };
    }
  | {
      type: "set_ftu_field_value";
      payload: {
        field: string;
        value: unknown;
      };
    };

export function azureSelectMonitoredProjectReducer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  action: Actions,
) {
  switch (action?.type) {
    case UPDATE_AZURE_NODES:
      return updateAzureTreeMap({
        state,
        payload: action.payload,
      });
    case INIT_AZURE_TREE_MAP:
      return initializeAzureSelectSubscriptions({
        state,
        payload: action.payload,
      });
    case AZURE_LOADED_NODES:
      return updateAzureLoadedNodes({
        state,
        payload: action.payload,
      });
    case CLEAR_FTU_FIELD_ERRORS:
      return clearFTUFieldErrors({
        ...action.payload,
        state,
      });
    case SET_FTU_FIELD_ERRORS:
      return setFTUFieldErrors({
        ...action.payload,
        state,
      });
    case SET_FTU_FIELD_VALUE:
      return setFTUFieldValue({
        ...action.payload,
        state,
      });
    default:
      return state;
  }
}

export type AzureSelectMonitoredProjectReducerType =
  typeof azureSelectMonitoredProjectReducer;
