import { type ReactElement } from "react";
import {
  get,
  useController,
  useFormContext,
  type RegisterOptions,
} from "react-hook-form";
import {
  CheckboxCard,
  ControlGroup,
  Field,
  Checkbox as ReactCheckbox,
  type CheckboxCardProps,
  type CheckboxProps,
  type FieldProps,
} from "ui";

export type CheckboxGroupProps = {
  /**
   * The name the checkbox values will be stored under.
   */
  name: string;
  /**
   * A list of items to render checkboxes for.
   */
  items: CheckboxItem[] | CheckboxCardItem[];
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  /**
   * A default value for the CheckboxGroup
   */
  defaultValue?: Record<string, boolean>;
} & Omit<FieldProps, "error" | "control"> &
  CheckboxProps;

/**
 * Renders a group of Checkboxes connected to the form. All extra props will get passed to each Checkbox.
 */
export interface CheckboxItem
  extends Omit<CheckboxProps, "checked" | "onChange" | "indeterminate"> {
  value: string | number;
}

export interface CheckboxCardItem
  extends Omit<CheckboxCardProps, "checked" | "onChange" | "indeterminate"> {
  value: string | number;
}

function isCardItem(
  obj: CheckboxProps | CheckboxCardProps,
): obj is CheckboxCardProps {
  return "title" in obj;
}

/**
 * Creates a group of checkboxes. These will be added as an object with
 * the checkbox value as the key, and true/false representing if it was checked.
 */
export const CheckboxGroup = (
  props: CheckboxGroupProps,
): ReactElement | null => {
  const {
    items,
    label,
    name,
    register: registerOptions = {},
    description,
    tooltip,
    disabled,
    showLabel,
    defaultValue = {},
  } = props;
  const context = useFormContext();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render CheckboxGroup outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState } = context;
  const {
    field: { onChange, value: values },
  } = useController({
    name,
    defaultValue: defaultValue,
    rules: registerOptions,
  });

  return (
    <Field
      label={label}
      optional={!registerOptions.required}
      control={
        <ControlGroup>
          {items.map(({ value, ...rest }) => {
            return isCardItem(rest) ? (
              <CheckboxCard
                key={value}
                {...rest}
                checked={!!values[value]}
                onChange={() => {
                  const newValues = { ...values };
                  newValues[value] = !newValues[value];
                  onChange(newValues);
                }}
              />
            ) : (
              <ReactCheckbox
                key={value}
                {...rest}
                checked={!!values[value]}
                onChange={() => {
                  const newValues = { ...values };
                  newValues[value] = !newValues[value];
                  onChange(newValues);
                }}
              />
            );
          })}
        </ControlGroup>
      }
      error={get(formState.errors, name)?.message}
      description={description}
      tooltip={tooltip}
      disabled={disabled}
      connectLabel={false}
      showLabel={showLabel}
    />
  );
};
