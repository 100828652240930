import { Fragment, type ReactElement } from "react";
import { Body, Bold, Button } from "ui";

import { FormattedMessage } from "react-intl";
import { type ColumnInstance, type Data } from "../../types";
import { SelectColumns } from "../SelectColumns";

interface GroupBySelectProps<D extends Data> {
  columns: ColumnInstance<D>[];
  groupBy: string[];
  toggleGroupBy: (columnId: string) => void;
}

function ButtonLabel<D extends Data>({
  columns,
  groupBy,
}: Pick<GroupBySelectProps<D>, "columns" | "groupBy">) {
  if (groupBy.length === 0) {
    return (
      <Body as="div" addClassName="inline-flex">
        <Bold as="span" addClassName="mr-1 text-xs">
          <FormattedMessage
            defaultMessage="Group By:"
            id="QUafyx"
            description="Precedes a variable that identifies how items in a list are grouped together"
          />
        </Bold>
        <Bold addClassName="text-xs text-link">
          <FormattedMessage
            defaultMessage="None"
            id="/6LazM"
            description="Name of a variable that identifies how items in a list are grouped together"
          />
        </Bold>
      </Body>
    );
  }
  const groupedColumns = groupBy
    .map((columnId) => columns.find((c) => c.id === columnId))
    .filter((c) => !!c) as ColumnInstance<D>[];

  return (
    <>
      <Body as="div" addClassName="inline-flex">
        <Bold as="span" addClassName="mr-1 text-xs">
          <FormattedMessage
            defaultMessage="Group By:"
            id="QUafyx"
            description="Precedes a variable that identifies how items in a list are grouped together"
          />
        </Bold>
        <Bold addClassName="text-xs text-link">
          {groupedColumns.map((column, index) => (
            <Fragment key={column.id}>
              {column.render("Header")}
              {index + 1 < groupedColumns.length && ", "}
            </Fragment>
          ))}
        </Bold>
      </Body>
    </>
  );
}

export function GroupBySelect<D extends Data>({
  columns,
  groupBy,
  toggleGroupBy,
}: GroupBySelectProps<D>): ReactElement {
  const groupableColumns = columns.filter(
    (c) => c.isVisible && !c.disableGroupBy,
  );
  return (
    <SelectColumns
      button={
        <Button isMenu style={{ marginLeft: 0 }}>
          <ButtonLabel columns={columns} groupBy={groupBy} />
        </Button>
      }
      columns={groupableColumns}
      label="Select columns"
      showLabel={false}
      selectedColumnIds={groupBy}
      onSelectColumn={(c: string) => {
        toggleGroupBy(c);
      }}
    />
  );
}
