import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { DataInventoryFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";
import { BreadcrumbsSchema, TableLevelEnumSchema } from "../types";

export const ObjectLayout = lazy(() => import("./ObjectLayout"));

export const DataInventoryObjectSearchSchema = z.object({
  filters: DataInventoryFilterSchema.catch({}).optional(),
  tableLevel: TableLevelEnumSchema.optional(),
  breadcrumbs: BreadcrumbsSchema,
  page: z
    .object({
      number: z.number().nullish(),
      size: z.number().nullish(),
    })
    .optional(),
  tableFilter: z.union([z.string(), z.number()]).optional(),
});

export const objectLayoutRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "dataObject/$assetName",
  component: ObjectLayout,
  validateSearch: DataInventoryObjectSearchSchema,
});
