import { isChina } from "environment";
import { SecurityCapabilitiesIcon } from "icons";
import { useIsBusinessLicenseType } from "prisma";
import { isNil } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../../../types";
import StatusDetails from "../StatusDetails";

type SingleSecurityCapabilityProps = {
  data?: SecurityCapabilitiesPermissionsStatus;
  type: SecurityCapabilitiesType;
  label: string;
};

export default function SingleSecurityCapability({
  data,
  label,
  type,
}: SingleSecurityCapabilityProps) {
  const { isBusinessLicenseType } = useIsBusinessLicenseType();
  if (isChina() || isBusinessLicenseType || isNil(data)) return null;
  return (
    <StatusDetails
      icon={<SecurityCapabilitiesIcon type={type} />}
      label={label}
      {...data}
      detailsClassName="border p-2"
      isSingleSecurityCapabity
    />
  );
}
