import { Route } from "@tanstack/react-router";
import { lazy } from "react";

import { collectionsRoute } from "../settingsCollectionsRoute";
import LoadingEditCollectionWizard from "./LoadingEditCollectionWizard";

export const CollectionWizardEdit = lazy(() => import("./CollectionWizard"));

export const collectionWizardEditRoute = new Route({
  getParentRoute: () => collectionsRoute,
  path: "/$collectionId/edit",
  component: CollectionWizardEdit,
  pendingComponent: () => <LoadingEditCollectionWizard />,
});
