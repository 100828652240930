import { z } from "zod";
import { jsonApi } from "../../apis";

export const createLogs = async (logs: {
  sentryEventId?: string | null;
  error?: Error | string | null;
  componentStack?: string | null;
}) => {
  return jsonApi({
    path: `client/log`,
    body: logs,
    config: {
      method: "POST",
    },
    requestSchema: z
      .object({
        sentryEventId: z.string().nullish(),
        error: z.string().nullish(),
        componentStack: z.string().nullish(),
      })
      .partial(),
    responseSchema: z.boolean(),
  });
};
