import { FormattedMessage } from "react-intl";

export default function RunTimeSecurity() {
  return (
    <div>
      <p>
        <FormattedMessage
          defaultMessage="TODO"
          id="ZQfRxm"
          description="Temp message"
        />
      </p>
    </div>
  );
}
