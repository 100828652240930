import invariant from "tiny-invariant";
import isIconOnly from "../Button/isIconOnly";

import { type TagProps } from "./Tag";

export default function validateProps(props: TagProps): void {
  const { appearance, closeButtonProps, onClick } = props;

  invariant(
    !(appearance === "simple" && isIconOnly(props)),
    "Tag appearance `simple` doesn't support an `icon-only`.",
  );
  invariant(
    !(isIconOnly(props) && closeButtonProps),
    "Tag which are icon-only doesn't support `closeButton` props.",
  );
  invariant(
    !(onClick && closeButtonProps),
    "Invalid props: Both `onClick` and `closeButton` props provided. Expected one or the other.",
  );
}
