import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";

export default function ServiceIamId() {
  const intl = useIntl();
  useTrimField("serviceApiKey");

  return (
    <Input
      name="serviceIamId"
      data-selector="ibm-service-iam-id-input"
      label={intl.formatMessage({
        defaultMessage: "Service IAM ID",
        id: "flh0ZY",
        description: "The name of an input field for onboarding an IBM account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "Service IAM ID",
        id: "lePH6R",
        description: "Placeholder for Account Service IAM ID",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Service IAM ID is required",
          id: "0JcsPk",
          description: "Error message for field for onboarding an IBM account",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage: "IAM ID associated with the Prisma Service ID",
        id: "UZo/yq",
        description:
          "Tooltip for a display field for onboarding an IBM account",
      })}
    />
  );
}
