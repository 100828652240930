import { EyeIcon, EyeSlashIcon, LayerGroupIcon, PushpinIcon } from "icons";
import {
  useMemo,
  useState,
  type CSSProperties,
  type DetailedHTMLProps,
  type LiHTMLAttributes,
  type ReactElement,
  type Ref,
} from "react";
import { useIntl } from "react-intl";
import { Button, Portal, Tooltip } from "ui";
import { classNames } from "utils";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { textColor } from "colors";
import { type ColumnInstance, type Data } from "../../types";
import { DragHandle } from "../Table/DragHandle";

type ColumnMap<D extends Data> = { [key: string]: ColumnInstance<D> };

interface ItemProps<D extends Data> {
  column: ColumnInstance<D>;
  disableColumnOrder?: boolean;
  style?: CSSProperties;
  sortRef?: Ref<HTMLLIElement>;
  dragHandleProps?: Record<string, unknown>;
  isDragging?: boolean;
  isHeld?: boolean;
}

function Item<D extends Data>({
  column,
  disableColumnOrder = false,
  dragHandleProps,
  isHeld = false,
  isDragging,
  sortRef,
  style = {},
}: ItemProps<D>) {
  const intl = useIntl();
  const isGrouped = column.isGrouped;
  const isSticky = !!column.sticky;
  const isVisible = column.isVisible;
  const canMoveColumn = !isGrouped && !isSticky && !disableColumnOrder;

  return (
    <li
      ref={sortRef}
      style={
        style as DetailedHTMLProps<
          LiHTMLAttributes<HTMLLIElement>,
          HTMLLIElement
        >
      }
      className={classNames(
        "flex items-center space-x-1",
        "w-full px-2 py-1 text-xs",
        "border-b border-primary last:border-b-0",
        isDragging && "opacity-50",
      )}
    >
      <Tooltip
        label={
          isVisible
            ? intl.formatMessage({
                defaultMessage: "Hide column",
                id: "LOaeNb",

                description: "Tooltip of button",
              })
            : intl.formatMessage({
                defaultMessage: "Show column",
                id: "D5/yhu",

                description: "Tooltip of button",
              })
        }
      >
        <Button
          addClassName={"flex-none"}
          appearance="clear"
          aria-label={
            isVisible
              ? intl.formatMessage({
                  defaultMessage: "Hide column",
                  id: "rClZkk",

                  description: "Button that toggles displaying column",
                })
              : intl.formatMessage({
                  defaultMessage: "Show column",
                  id: "g+AWFW",

                  description: "Button that toggles displaying column",
                })
          }
          disabled={isGrouped || column.disableHideColumn}
          icon={
            isVisible ? (
              <EyeIcon color={!isVisible ? textColor.disabled : undefined} />
            ) : (
              <EyeSlashIcon
                color={!isVisible ? textColor.disabled : undefined}
              />
            )
          }
          onClick={() => {
            column.toggleHidden();
          }}
          size="sm"
        />
      </Tooltip>
      <span className="flex-auto">{column.render("Header")}</span>
      <span className="flex flex-none space-x-1">
        {isGrouped && (
          <Tooltip
            label={intl.formatMessage({
              defaultMessage: "Column is grouped",
              id: "qT8el/",

              description: "Tooltip for grouped column",
            })}
          >
            <span
              aria-label={intl.formatMessage({
                defaultMessage: "Grouped",
                id: "+GaDte",

                description: "Label for grouped column icon",
              })}
              className="p-1"
            >
              <LayerGroupIcon />
            </span>
          </Tooltip>
        )}
        {isSticky && (
          <Tooltip
            label={intl.formatMessage({
              defaultMessage: "Column is pinned",
              id: "VgA2Qz",

              description: "Tooltip for pinned column",
            })}
          >
            <span
              aria-label={intl.formatMessage({
                defaultMessage: "Pinned",
                id: "Rv+jvv",

                description: "Label for pinned column icon",
              })}
              className="p-1"
            >
              <PushpinIcon className="text-disabled" />
            </span>
          </Tooltip>
        )}
        {canMoveColumn && (
          <DragHandle
            {...dragHandleProps}
            isHeld={isHeld}
            label={intl.formatMessage({
              defaultMessage: "Reorder column",
              id: "L2ECbY",

              description: "Drag icon user can click on to reorder",
            })}
          />
        )}
      </span>
    </li>
  );
}

function SortableItem<D extends Data>(props: ItemProps<D>) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: String(props.column.id),
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item
      {...props}
      style={style}
      sortRef={setNodeRef}
      isDragging={isDragging}
      dragHandleProps={{ ...attributes, ...listeners }}
    />
  );
}

export interface Props<D extends Data> {
  columns: ColumnInstance<D>[];
  columnOrder: string[];
  disableColumnOrder?: boolean;
  setColumnOrder: (columnIds: string[]) => void;
}
export function ColumnList<D extends Data>({
  columns,
  columnOrder,
  disableColumnOrder,
  setColumnOrder,
}: Props<D>): ReactElement {
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const columnMap = useMemo(
    () =>
      columns.reduce((accum: ColumnMap<D>, c) => {
        accum[c.id] = c;
        return accum;
      }, {}),
    [columns],
  );

  const filteredColumnOrder = columnOrder.filter((cId) => columnMap[cId]);
  columns.forEach(
    (column) =>
      !filteredColumnOrder.includes(column.id) &&
      filteredColumnOrder.push(column.id),
  );

  const groupedItems = filteredColumnOrder.filter(
    (cId) => columnMap[cId].isGrouped,
  );

  const leftStickyItems = filteredColumnOrder.filter(
    (cId) => columnMap[cId].sticky && columnMap[cId].stickyPosition === "left",
  );
  const rightStickyItems = filteredColumnOrder.filter(
    (cId) => columnMap[cId].sticky && columnMap[cId].stickyPosition === "right",
  );
  const sortableItems = filteredColumnOrder.filter(
    (cId) => !columnMap[cId].isGrouped && !columnMap[cId].sticky,
  );

  /* c8 ignore next */
  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }

  /* c8 ignore next */
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    setActiveId(null);
    if (active.id === over?.id) return;

    const oldIndex = sortableItems.findIndex(
      (columnId) => String(columnId) === active.id,
    );
    const newIndex = sortableItems.findIndex(
      (columnId) => String(columnId) === over?.id,
    );
    const next = groupedItems
      .concat(leftStickyItems)
      .concat(arrayMove(sortableItems, oldIndex, newIndex))
      .concat(rightStickyItems);
    setColumnOrder(next);
  }

  return (
    <div className="flex-auto">
      {!!groupedItems.length && (
        <ul className="border-b border-primary">
          {groupedItems.map((cId) => (
            <Item key={cId} column={columnMap[cId]} />
          ))}
        </ul>
      )}
      {!!leftStickyItems.length && (
        <ul className="border-b dark:border-blue-steel-850">
          {leftStickyItems.map((cId) => (
            <Item key={cId} column={columnMap[cId]} />
          ))}
        </ul>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={sortableItems}
          strategy={verticalListSortingStrategy}
        >
          <ul>
            {sortableItems.map((cId) => (
              <SortableItem
                key={cId}
                column={columnMap[cId]}
                disableColumnOrder={disableColumnOrder}
              />
            ))}
          </ul>
        </SortableContext>
        <Portal>
          <DragOverlay zIndex={20}>
            {activeId ? (
              <Item
                column={columnMap[activeId]}
                disableColumnOrder={disableColumnOrder}
                isHeld
              />
            ) : null}
          </DragOverlay>
        </Portal>
      </DndContext>
      {!!rightStickyItems.length && (
        <ul className="border-t dark:border-blue-steel-850">
          {rightStickyItems.map((cId) => (
            <Item key={cId} column={columnMap[cId]} />
          ))}
        </ul>
      )}
    </div>
  );
}
