import { useNavigate, useRouterState, useSearch } from "@tanstack/react-router";

import { useCallback } from "react";
import { type z } from "zod";
import { type pageTableProviderSchema } from "./pageTableProviderSchema";

type SearchType = z.infer<typeof pageTableProviderSchema>;

export const useOnTableFilterChange = () => {
  const {
    location: { pathname },
  } = useRouterState();
  const navigate = useNavigate();
  const search: SearchType = useSearch({ strict: false });

  const onTableFilterChange = useCallback(
    (newTableFilter: string) => {
      if (search.tableFilter !== newTableFilter) {
        navigate({
          to: pathname,
          search: { ...search, tableFilter: newTableFilter },
          replace: true,
        });
      }
    },
    // Only run when tableFilter param changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, navigate, search.tableFilter],
  );

  return { onTableFilterChange };
};
