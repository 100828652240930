import { z } from "zod";

const AccessLevelEnumSchema = z.enum([
  "DATA_READ",
  "DATA_WRITE",
  "METADATA_READ",
  "METADATA_WRITE",
]);

export const RqlPermissionResultsItemSchema = z.object({
  id: z.string().optional(),
  sourcePublic: z.boolean().nullable().optional(),
  sourceCloudType: z.string().nullable().optional(),
  sourceCloudAccount: z.string().nullable().optional(),
  sourceCloudRegion: z.string().nullable().optional(),
  sourceCloudResourceGroup: z.string().nullable().optional(),
  sourceCloudServiceName: z.string().nullable().optional(),
  sourceResourceName: z.string().nullable().optional(),
  sourceResourceType: z.string().nullable().optional(),
  sourceResourceId: z.string().nullable().optional(),
  sourceIdpService: z.string().nullable().optional(),
  sourceIdpDomain: z.string().nullable().optional(),
  sourceIdpEmail: z.string().nullable().optional(),
  sourceIdpUsername: z.string().nullable().optional(),
  sourceIdpGroup: z.string().nullable().optional(),
  sourceIdpUai: z.string().nullable().optional(),
  sourceCloudResourceUai: z.string().nullable().optional(),
  destCloudType: z.string().nullable().optional(),
  destCloudAccount: z.string().optional(),
  destCloudRegion: z.string().nullable().optional(),
  destCloudServiceName: z.string().nullable().optional(),
  destCloudResourceGroup: z.string().nullable().optional(),
  destResourceName: z.string().nullable().optional(),
  destResourceType: z.string().nullable().optional(),
  destResourceId: z.string().nullable().optional(),
  destCloudResourceUai: z.string().nullable().optional(),
  accessLevels: z.array(AccessLevelEnumSchema).optional(),
  isAdministrative: z.boolean().nullable().optional(),
  effectiveActionName: z.string().nullable().optional(),
  grantedAtLevelType: z.string().nullable().optional(),
  grantedAtLevelId: z.string().nullable().optional(),
  grantedAtLevelName: z.string().nullable().optional(),
  grantedByCloudType: z.string().nullable().optional(),
  grantedByCloudPolicyId: z.string().nullable().optional(),
  grantedByCloudPolicyName: z.string().nullable().optional(),
  grantedByCloudPolicyType: z.string().nullable().optional(),
  grantedByCloudPolicyUai: z.string().nullable().optional(),
  grantedByCloudPolicyAccount: z.string().optional(),
  grantedByCloudEntityId: z.string().nullable().optional(),
  grantedByCloudEntityName: z.string().nullable().optional(),
  grantedByCloudEntityType: z.string().nullable().optional(),
  grantedByCloudEntityUai: z.string().nullable().optional(),
  grantedByCloudEntityAccount: z.string().optional(),
  grantedByLevelType: z.string().nullable().optional(),
  grantedByLevelId: z.string().nullable().optional(),
  grantedByLevelName: z.string().nullable().optional(),
  grantedByLevelUai: z.string().nullable().optional(),
  accessedResourcesCount: z.number().nullable().optional(),
  lastAccessDate: z.string().nullable().optional(),
  lastAccessStatus: z.string().nullable().optional(),
  isWildCardDestCloudResourceName: z.boolean().nullable().optional(),
  exceptions: z.array(
    z.object({
      messageCode: z.string(),
    }),
  ),
});

export const RqlPermissionResultsItemsSchema = z.array(
  RqlPermissionResultsItemSchema,
);

export type RqlPermissionResultsItem = z.infer<
  typeof RqlPermissionResultsItemSchema
>;
