import { useEffect, useRef } from "react";
/**
 * Returns a ref that indicates if the component is unmounted or not.
 */
export function useIsUnMounted() {
  const isUnMounted = useRef(false);
  useEffect(function () {
    return function () {
      isUnMounted.current = true;
    };
  }, []);
  return isUnMounted;
}
