import jsCookie from "js-cookie";
import { inflateRaw } from "pako";

export function getCookie(name: string) {
  return jsCookie.get(name);
}

export function removeCookie(name: string) {
  jsCookie.remove(name);
  jsCookie.remove(name, { domain: ".prismacloud.io" });
  jsCookie.remove(name, { domain: ".prismacloud.cn" });
}

export function decompressCookie(cookie: string) {
  // Decompress array of character codes
  const inflated = inflateRaw(
    Uint8Array.from(atob(cookie), (c) => c.charCodeAt(0)),
  );

  // Create string from decompressed array of character codes
  return String.fromCharCode(...inflated);
}
