import {
  AmazonSimpleQueueServiceSqsIcon,
  AwsS3Icon,
  AwsSecurityHubIcon,
  AzureServiceBusQueueIcon,
  GcpGoogleCsccIcon,
  IntegrationsDemistoIcon,
  IntegrationsEmailIcon,
  IntegrationsJiraIcon,
  IntegrationsMicrosoftTeamsIcon,
  IntegrationsOktaIdpIcon,
  IntegrationsPagerDutyIcon,
  IntegrationsQualysIcon,
  IntegrationsServiceNowIcon,
  IntegrationsSlackIcon,
  IntegrationsSnowflakeIcon,
  IntegrationsSplunkIcon,
  IntegrationsTenableIcon,
  IntegrationsWebhookIcon,
} from "../../icons";

import { type AlertRuleNotificationsType } from "requests";
import { AwsSecurityLakeIcon } from "../../icons/CloudProviderIcons/AwsSecurityLakeIcon";
import { type SvgIconProps } from "../SvgIcon";

export const IntegrationTypeIcons = (
  props: Omit<SvgIconProps, "src"> & {
    type: AlertRuleNotificationsType;
  },
) => {
  const { type, ...passThrough } = props;
  switch (type) {
    case "amazon_sqs":
      return <AmazonSimpleQueueServiceSqsIcon {...passThrough} />;
    case "aws_s3":
      return <AwsS3Icon {...passThrough} />;
    case "aws_sdl":
      return <AwsSecurityLakeIcon {...passThrough} />;
    case "aws_security_hub":
      return <AwsSecurityHubIcon {...passThrough} />;
    case "azure_service_bus_queue":
      return <AzureServiceBusQueueIcon {...passThrough} />;
    case "demisto":
      return <IntegrationsDemistoIcon {...passThrough} />;
    case "email":
      return <IntegrationsEmailIcon {...passThrough} />;
    case "google_cscc":
      return <GcpGoogleCsccIcon {...passThrough} />;
    case "jira":
      return <IntegrationsJiraIcon {...passThrough} />;
    case "microsoft_teams":
      return <IntegrationsMicrosoftTeamsIcon {...passThrough} />;
    case "pager_duty":
      return <IntegrationsPagerDutyIcon {...passThrough} />;
    case "service_now":
      return <IntegrationsServiceNowIcon {...passThrough} />;
    case "slack":
      return <IntegrationsSlackIcon {...passThrough} />;
    case "snowflake":
      return <IntegrationsSnowflakeIcon {...passThrough} />;
    case "splunk":
      return <IntegrationsSplunkIcon {...passThrough} />;
    case "webhook":
      return <IntegrationsWebhookIcon {...passThrough} />;
    case "okta_idp":
      return <IntegrationsOktaIdpIcon {...passThrough} />;
    case "tenable":
      return <IntegrationsTenableIcon {...passThrough} />;
    case "qualys":
      return <IntegrationsQualysIcon {...passThrough} />;
    /* c8 ignore next */
    /* The clause catches non-typescript errors  */
    default:
      if (process.env.NODE_ENV === "development") {
        throw new Error(`Unexpected cloud type: ${type}`);
      }
      return null;
  }
};
