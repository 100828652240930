import { type Set } from "immutable";
import { type CloudType } from "../../types";
import TreeHierarchy from "./TreeHierarchy";
import ancestorSelected from "./ancestorSelected";
import calculateNodeStates from "./calculateNodeStates";
import convertTreeMapToTreeData from "./convertTreeMapToTreeData/convertTreeMapToTreeData";
import {
  type FlatNodeType,
  type LoadDataType,
  type TreeMapType,
} from "./types";

type TreeProps = {
  defaultExpandedKeys: Set<string>;
  error: boolean;
  onLoadMore: LoadDataType;
  loadedNodes: Set<string>;
  onChangeSelectedNodes: (val: Set<string>) => void;
  onExpandNode: (list: string[], node: FlatNodeType) => void;
  rootId: string;
  selectedNodes: Set<string>;
  treeMap: TreeMapType;
  selectedCloud: CloudType;
  showNodeIcon?: boolean;
  showCheckbox?: boolean;
  expandOnLabelClick?: boolean;
};

const style = { maxHeight: 300, overflow: "auto", marginLeft: 24 };
const errorClassName =
  "border border-red-400 invalid-form-field-ant-override shake-animation";

export default function Tree({
  defaultExpandedKeys,
  error,
  loadedNodes,
  onLoadMore,
  onChangeSelectedNodes,
  onExpandNode,
  rootId,
  selectedNodes,
  treeMap,
  selectedCloud,
  showNodeIcon = false,
  showCheckbox = true,
  expandOnLabelClick = false,
}: TreeProps) {
  const { checked, disabled } = calculateNodeStates(treeMap, selectedNodes);

  const treeData = [
    convertTreeMapToTreeData({
      nodeId: rootId,
      treeMap,
      disabled,
      loadMoreProps: {
        loadData: onLoadMore,
      },
      selectedCloud,
    }),
  ];

  function onCheck(
    _checkedList: string[],
    { checked, id }: { checked: boolean; id: string },
  ) {
    const next = checked ? selectedNodes.add(id) : selectedNodes.delete(id);

    // Remove any descendants of selected nodes from the Set
    const filteredNext = next.filter(
      (id = "", _key, selectedNodes) =>
        !ancestorSelected({
          id,
          treeMap,
          selectedNodes: selectedNodes as unknown as Set<string>,
        }),
    );

    onChangeSelectedNodes(filteredNext as unknown as Set<string>);
  }

  return (
    <TreeHierarchy
      nodes={treeData}
      checked={checked.toJS()}
      loaded={loadedNodes.toJS()}
      className={error ? errorClassName : "border border-gray-200"}
      style={style}
      expanded={defaultExpandedKeys.toJS()}
      onCheck={onCheck}
      onExpand={onExpandNode}
      showNodeIcon={showNodeIcon}
      noCascade={false}
      showCheckbox={showCheckbox}
      expandOnLabelClick={expandOnLabelClick}
    />
  );
}
