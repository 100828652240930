import { Set } from "immutable";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty, partition } from "remeda";

import { useToastActions } from "stores";
import { usePrevious } from "ui";

import { useMutation } from "@tanstack/react-query";
import {
  getGcpSelectMemberAccountAncestors,
  getGcpSelectMonitoredProjectsChildrens,
} from "requests";
import { snakeCase } from "utils";
import {
  ALL,
  GCP_LOAD_MORE_NO_TOKEN,
  ORGANIZATION,
  SERVICE_ACCOUNT,
} from "../../../../../../../constants";
import { useIsRenderedFirstTime } from "../../../../../../../hooks";
import { parseErrorsFromResponse } from "../../../../../../../utils";
import { useGCPSelectMonitoredProjectsContext } from "../../../../context/GCPSelectMonitoredProjectContext";
import {
  initGCPTreeMap,
  setGCPFoldersCredentialError,
  setGCPLoadedNodes,
  setGCPOUSelectProjectsIsLoading,
  setGCPPreviouslySelectedNodesNotFound,
  setGCPProjectSelectMode,
  setGCPSelectProjectsInitialized,
  setGCPSelectedNodes,
  setIsRateLimit503,
  setOrganizationName,
  updateGCPTreeData,
} from "../../../../context/GCPSelectMonitoredProjectContext/state/actions";
import {
  getGCPFoldersCredentialError,
  getGCPNextPageToken,
  getGCPRootId,
  getGCPSelectProjectsInitialized,
  getGCPSelectedNodesInitial,
  getGCPTreeMap,
  getIsRateLimit503,
  getOrgId,
  getOrgName,
} from "../../../../context/GCPSelectMonitoredProjectContext/state/getters";
import { useGCPCredentialsFileContext } from "../../../../context/GcpCredentialsFileContext";
import {
  getExternalAccountKey,
  getExternalAccountKeyErrors,
  getServiceAccountKey,
  getServiceAccountKeyErrors,
} from "../../../../context/GcpCredentialsFileContext/state/getters";
import GCPSelectProjects from "./GCPSelectProjects";

type GCPChooseMonitoredProjectsProps = {
  closeModal: () => void;
};

export default function GCPChooseMonitoredProjects({
  closeModal,
}: GCPChooseMonitoredProjectsProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const { watch, setError } = useFormContext();

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();

  const { state, dispatch } = useGCPSelectMonitoredProjectsContext();
  const { state: jsonFileState } = useGCPCredentialsFileContext();

  const authenticationType = watch("authenticationType");

  const orgId = getOrgId(state);
  const credentials =
    authenticationType === SERVICE_ACCOUNT
      ? getServiceAccountKey(jsonFileState)
      : getExternalAccountKey(jsonFileState);
  const credentialsErrors =
    authenticationType === SERVICE_ACCOUNT
      ? getServiceAccountKeyErrors(jsonFileState)
      : getExternalAccountKeyErrors(jsonFileState);

  const isOrgIdChanged = usePrevious(orgId) !== orgId;
  const isCredentialsChanged =
    usePrevious(JSON.stringify(credentials)) !== JSON.stringify(credentials);

  const isGCPSelectProjectsInitialized = getGCPSelectProjectsInitialized(state);
  const folderCredentialError = getGCPFoldersCredentialError(state);
  const hasRateLimitHit = getIsRateLimit503(state);

  // Children Api
  const { mutateAsync: loadGCPNodeData } = useMutation({
    mutationFn: async ({
      childrenType,
      loadType,
      parentId,
      isTreeMapInitialized = false,
    }: {
      childrenType: string;
      loadType: string;
      parentId: string;
      isTreeMapInitialized?: boolean;
    }) => {
      try {
        const cursorToken = getGCPNextPageToken(state, {
          childrenType,
          parentId,
        });

        // If the token is the `GCP_LOAD_MORE_NO_TOKEN`, omit it from the request
        let nextPageToken;
        if (cursorToken !== GCP_LOAD_MORE_NO_TOKEN) {
          nextPageToken = cursorToken;
        }

        const [_val, parentType, id] = /(folders|organizations)\/(.*)/.exec(
          parentId,
        ) as string[];

        const data = await getGcpSelectMonitoredProjectsChildrens({
          id,
          childrenType,
          body: {
            cloudAccount: {
              accountId: orgId,
              accountType: ORGANIZATION,
            },
            credentials,
          },
          params: { ...(nextPageToken && { nextPageToken }), parentType },
        });

        // Set OrgName to tree Root
        const treeMap = getGCPTreeMap(state);
        if (!isTreeMapInitialized && !treeMap.size) {
          const orgName = data?.organizationName ?? "Root";
          dispatch(setOrganizationName(orgName));
          dispatch(initGCPTreeMap({ orgName, rootId: parentId }));
        }

        dispatch(updateGCPTreeData({ childrenType, data, loadType, parentId }));
        dispatch(setGCPLoadedNodes(parentId));
      } catch (error) {
        const errorMessages = [
          "folder_viewer_permission_required",
          "unauthorized_access",
        ];

        const errorMessage = snakeCase(
          parseErrorsFromResponse(intl, error)[0] ?? "",
        );

        if (errorMessages.includes(errorMessage)) {
          dispatch(setGCPFoldersCredentialError(errorMessage));
        } else if (errorMessage === "invalid_org_id") {
          setError("organizationId", {
            type: "custom",
            message: "Please enter valid Org ID",
          });
        } else {
          if ((error as { status: number }).status === 503) {
            dispatch(setIsRateLimit503(true));
          } else {
            toast(parseErrorsFromResponse(intl, error), {
              appearance: "error",
            });

            closeModal();
          }
        }
      }
    },
  });

  // Ancestor Api
  const { mutateAsync: loadGCPNodeAncestorData } = useMutation({
    mutationFn: async ({
      orgId,
      selectedNodes,
      orgName,
      rootId,
    }: {
      selectedNodes: string[];
      orgId: string;
      orgName: string;
      rootId: string;
    }) => {
      try {
        const data = await getGcpSelectMemberAccountAncestors({
          orgId,
          body: {
            resourceIds: selectedNodes,
            ...(isCredentialsChanged && { credentials }),
          },
        });

        const [ancestorsOfSelected, notFound] = partition(
          data,
          (el) => !!el.ancestors,
        );

        dispatch(setGCPPreviouslySelectedNodesNotFound(notFound));
        dispatch(initGCPTreeMap({ ancestorsOfSelected, orgName, rootId }));
        return false;
      } catch (error) {
        return true;
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const flag =
        isEmpty(orgId) || // organizationId is empty
        isEmpty(credentials) || // credentials is empty
        authenticationType !== credentials.type || // uploaded files should be of correct type
        !isEmpty(credentialsErrors) || // invalid credentials file uploaded
        (!isOrgIdChanged && !isCredentialsChanged && !isRenderedFirstTime) || // organizationId and credentials doesn't changed
        (!folderCredentialError &&
          !hasRateLimitHit &&
          isRenderedFirstTime &&
          isGCPSelectProjectsInitialized); // On first render, if there is no credential or rate limit error then don't make new api call

      // Don't make new api call or return
      if (flag) {
        return;
      }

      if (getIsRateLimit503(state)) {
        dispatch(setIsRateLimit503(false));
      }

      dispatch(setGCPOUSelectProjectsIsLoading(true));
      dispatch(setGCPSelectProjectsInitialized(false));

      const rootId = getGCPRootId(state);
      const orgName = getOrgName(state);
      const nodeInitial = getGCPSelectedNodesInitial(state);
      const hierarchySelection =
        nodeInitial && "size" in nodeInitial ? nodeInitial.toJS() : nodeInitial;
      const selectionMode =
        (Array.isArray(hierarchySelection) &&
          hierarchySelection.length &&
          hierarchySelection[0]?.selectionType) ||
        ALL;
      const selectedNodes: string[] = hierarchySelection?.map(
        (node: { resourceId: string }) => node.resourceId,
      );

      setGCPProjectSelectMode(selectionMode, dispatch);
      setGCPSelectedNodes(Set(selectedNodes), dispatch);
      dispatch(setGCPFoldersCredentialError(""));

      let isAncestorApiFailed = false;
      let isTreeMapInitialized = false;
      if (hierarchySelection?.length && selectionMode !== ALL) {
        isAncestorApiFailed = await loadGCPNodeAncestorData({
          orgId,
          selectedNodes,
          orgName,
          rootId,
        });
        isTreeMapInitialized = !isAncestorApiFailed;
      }

      await loadGCPNodeData({
        childrenType: "all",
        loadType: "initial",
        parentId: rootId,
        isTreeMapInitialized,
      });

      dispatch(setGCPOUSelectProjectsIsLoading(false));

      if (!isAncestorApiFailed) {
        dispatch(setGCPSelectProjectsInitialized(true));
      }
    };

    try {
      fetchData();
    } catch (error) {
      dispatch(setGCPOUSelectProjectsIsLoading(false));
      dispatch(setGCPSelectProjectsInitialized(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authenticationType,
    credentials,
    dispatch,
    isOrgIdChanged,
    isCredentialsChanged,
    orgId,
  ]);

  return <GCPSelectProjects loadGCPNodeData={loadGCPNodeData} />;
}
