import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsProvidersRoute } from "../providers/settingsProvidersRoute";

const CasProviders = lazy(() => import("./CasProviders"));

export const settingsThirdPartyRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "/3rd-party",
  component: CasProviders,
});

export const settingsThirdPartyNestedRoute = new Route({
  getParentRoute: () => settingsThirdPartyRoute,
  path: "$",
  component: CasProviders,
});
