import {
  IntegrationSchema,
  fieldNameProps,
  notificationTemplateSchema,
  type NotificationTemplateData,
  type NotificationTemplateDataWithoutId,
  type NotificationTemplateType,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";

export const notificationTemplateKeys = {
  all: [{ scope: "notificationTemplateAll" }] as const,
  notificationTemplates: (
    prismaId: string,
    params?: {
      type: NotificationTemplateType;
    },
  ) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "notificationTemplate",
        prismaId,
        params,
      },
    ] as const,
  project: (prismaId: string, integrationId: string) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "project",
        prismaId,
        integrationId,
      },
    ] as const,
  issueType: (prismaId: string, integrationId: string, projectId: string) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "issueType",
        prismaId,
        integrationId,
        projectId,
      },
    ] as const,
  jiraFields: (
    prismaId: string,
    integrationId: string,
    projectId: string,
    issueTypeValue: string,
  ) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "jiraFields",
        prismaId,
        integrationId,
        projectId,
        issueTypeValue,
      },
    ] as const,
  jiraIssueType: (params: {
    integrationId: string;
    projectId: string;
    nsEndPoint: string;
  }) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "jira",
        params,
      },
    ] as const,
  integration: (prismaId: string, integrationId: string) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "integrations",
        prismaId,
        integrationId,
      },
    ] as const,
  serviceType: (params: { prismaId: string; integrationId: string }) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "serviceType",
        params,
      },
    ] as const,
  serviceTypeFields: (params: {
    prismaId: string;
    integrationId: string;
    incidentTypeValue: string;
  }) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "serviceTypeFields",
        params,
      },
    ] as const,
  asyncTypeAhead: (endPoint: string) =>
    [
      {
        ...notificationTemplateKeys.all[0],
        entity: "asyncTypeAhead",
        endPoint,
      },
    ] as const,
} as const;

export const getNotificationTemplates = async ({
  queryKey: [{ prismaId, params }],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["notificationTemplates"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${prismaId}/template`,
    params,
    requestSchema: z.void(),
    responseSchema: z.array(notificationTemplateSchema),
  });
};

export const deleteTemplate = async ({
  id,
  prismaId,
}: {
  id: string;
  prismaId: string;
}) => {
  return await jsonApi({
    path: `api/v1/tenant/${prismaId}/template/${id}`,
    config: {
      method: "DELETE",
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};

export const cloneTemplate = async ({
  name,
  id,
  prismaId,
}: {
  name: string;
  id: string;
  prismaId: string;
}) => {
  return jsonApi({
    path: `api/v1/tenant/${prismaId}/template/${id}/clone`,
    body: { name },
    config: {
      method: "post",
    },
    requestSchema: z.object({ name: z.string() }),
    responseSchema: z.any(),
  });
};

export const testNotificationTemplate = async ({
  data,
  prismaId,
}: {
  data: NotificationTemplateData;
  prismaId: string;
}) => {
  return jsonApi({
    path: `api/v1/tenant/${prismaId}/template/test`,
    body: data,
    config: {
      method: "post",
    },
    requestSchema: notificationTemplateSchema.partial(),
    responseSchema: z.any(),
  });
};

export const getNotificationProjects = async ({
  queryKey: [{ prismaId, integrationId }],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["project"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${prismaId}/template/jira/${integrationId}/projects`,
    requestSchema: z.void(),
    responseSchema: fieldNameProps.partial(),
  });
};

export const getNotificationIssueTypes = async ({
  queryKey: [{ prismaId, integrationId, projectId }],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["issueType"]>
>) =>
  jsonApi({
    path: `api/v1/tenant/${prismaId}/template/jira/${integrationId}/${projectId}/issues`,
    requestSchema: z.void(),
    responseSchema: fieldNameProps.partial(),
  });

export const getJiraFields = async ({
  queryKey: [{ prismaId, integrationId, projectId, issueTypeValue }],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["jiraFields"]>
>) =>
  jsonApi({
    path: `api/v1/tenant/${prismaId}/template/jira/${integrationId}/${projectId}/${issueTypeValue}/fields`,
    requestSchema: z.void(),
    responseSchema: z.array(fieldNameProps),
  });

export const getReporters = async (endPoint: string) => {
  return jsonApi({
    path: endPoint,
    requestSchema: z.void(),
    responseSchema: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
      }),
    ),
  });
};

export const getIssueTypeForJira = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["jiraIssueType"]>
>) =>
  jsonApi({
    path: `${params.nsEndPoint}/jira/${params.integrationId}/${params.projectId}/issues`,
    requestSchema: z.void(),
    responseSchema: fieldNameProps,
  });

export const saveTemplate = async ({
  payload,
  prismaId,
  templateId,
}: {
  payload: NotificationTemplateDataWithoutId;
  prismaId: string;
  templateId?: string;
}) => {
  const method = templateId ? "PATCH" : "POST";
  const endPoint = templateId
    ? `api/v1/tenant/${prismaId}/template/${templateId}`
    : `api/v1/tenant/${prismaId}/template`;

  return jsonApi({
    path: `${endPoint}`,
    body: payload,
    config: {
      method: method,
    },
    requestSchema: notificationTemplateSchema.partial(),
    responseSchema: z.union([notificationTemplateSchema, z.number()]),
  });
};

export const getIntegrations = async ({
  queryKey: [{ prismaId, integrationId }],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["integration"]>
>) =>
  jsonApi({
    path: `api/v1/tenant/${prismaId}/integration/${integrationId}`,
    requestSchema: z.void(),
    responseSchema: IntegrationSchema,
  });

export const getServiceType = async ({
  queryKey: [
    {
      params: { prismaId, integrationId },
    },
  ],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["serviceType"]>
>) =>
  jsonApi({
    path: `api/v1/tenant/${prismaId}/template/servicenow/${integrationId}/types`,
    requestSchema: z.void(),
    responseSchema: fieldNameProps,
  });

export const getServiceNowFields = async ({
  queryKey: [
    {
      params: { prismaId, integrationId, incidentTypeValue },
    },
  ],
}: QueryFunctionContext<
  ReturnType<(typeof notificationTemplateKeys)["serviceTypeFields"]>
>) =>
  jsonApi({
    path: `api/v1/tenant/${prismaId}/template/servicenow/${integrationId}/${incidentTypeValue}/fields`,
    requestSchema: z.void(),
    responseSchema: z.array(fieldNameProps),
  });
