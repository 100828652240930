/* c8 ignore start */
import { type Set } from "immutable";
import {
  ACCOUNT_ID,
  ALL,
  AWS_OU_DEFAULT_EXPANDED_NODES,
  AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR,
  AWS_OU_IS_LOADING,
  AWS_OU_LOADED_NODES,
  AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  AWS_OU_PROJECT_SELECT_MODE,
  AWS_OU_SELECTED_NODES,
  AWS_OU_SELECTED_NODES_INITIAL,
  AWS_OU_SELECT_PROJECTS_INITIALIZED,
  CLEAR_FTU_FIELD_ERRORS,
  INIT_AWS_OU_TREE_MAP,
  MEMBER_ROLE_NAME,
  NAME,
  ROLE_ARN,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  UPDATE_AWS_OU_NODES,
} from "../../../../../../../constants";
import { memberAccountValidationErrorMessages } from "../../../../../../../messages/memberAccountValidationErrorMessages";

import { dynamicFormatMessage } from "ui";

import { type IntlShape } from "react-intl";
import { type AwsSelectMemberAccountsAncestorsType } from "requests";
import {
  getAwsOUHierarchySelectionPayload,
  getAwsOUPreviouslySelectedNodesNotFound,
  getAwsOUProjectSelectMode,
  getAwsOURootId,
  getAwsOUSelectedNodes,
} from "../getters";
import { type AwsSelectMemberAccountsInitialStateType } from "../initialState";

export const setFTUFieldErrors = (payload: {
  field: string;
  errors: string[];
}) => ({
  payload,
  type: SET_FTU_FIELD_ERRORS,
});

export const clearFTUFieldErrors = (payload: { field: string }) => ({
  payload,
  type: CLEAR_FTU_FIELD_ERRORS,
});

export const setFTUFieldValue = (payload: {
  field: string;
  value: unknown;
}) => ({
  payload,
  type: SET_FTU_FIELD_VALUE,
});

export const updateAwsOUTreeData = (payload: Record<string, unknown>) => ({
  payload,
  type: UPDATE_AWS_OU_NODES,
});

export const initAwsOUTreeMap = (payload: Record<string, unknown>) => ({
  payload,
  type: INIT_AWS_OU_TREE_MAP,
});

export const setRoleArn = (value: string) =>
  setFTUFieldValue({ field: ROLE_ARN, value });

export const setAccountId = (value: string) =>
  setFTUFieldValue({ field: ACCOUNT_ID, value });

export const setAwsOULoadedNodes = (payload: string) => ({
  payload,
  type: AWS_OU_LOADED_NODES,
});

export const setAwsOUSelectedNodesInitial = (value: {
  field: string;
  value: unknown;
}) => setFTUFieldValue({ field: AWS_OU_SELECTED_NODES_INITIAL, value });

export const setAwsOUExpandedNodes = (value: Set<string>) =>
  setFTUFieldValue({ field: AWS_OU_DEFAULT_EXPANDED_NODES, value });

export const setAwsOUFoldersCredentialError = (
  value:
    | {
        field: string;
        value: unknown;
      }
    | string,
) => setFTUFieldValue({ field: AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR, value });

export const setAwsOUPreviouslySelectedNodesNotFound = (
  value: AwsSelectMemberAccountsAncestorsType,
) =>
  setFTUFieldValue({
    field: AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
    value,
  });

export const setAwsOUSelectProjectsInitialized = (value: boolean) =>
  setFTUFieldValue({ field: AWS_OU_SELECT_PROJECTS_INITIALIZED, value });

export const setAccountName = (value: string) =>
  setFTUFieldValue({ field: NAME, value });

export const setMemberRoleName = (value: string) =>
  setFTUFieldValue({ field: MEMBER_ROLE_NAME, value });

export const setAwsOUSelectProjectsIsLoading = (value: boolean) =>
  setFTUFieldValue({ field: AWS_OU_IS_LOADING, value });

export const setAwsOUProjectSelectMode = (
  value: string,
  dispatch: React.Dispatch<unknown>,
) => {
  dispatch(setFTUFieldValue({ field: AWS_OU_PROJECT_SELECT_MODE, value }));
  dispatch(clearFTUFieldErrors({ field: AWS_OU_SELECTED_NODES }));
};

export const setAwsOUSelectedNodes = (
  value: Set<string>,
  dispatch: React.Dispatch<unknown>,
) => {
  dispatch(setFTUFieldValue({ field: AWS_OU_SELECTED_NODES, value }));
  dispatch(clearFTUFieldErrors({ field: AWS_OU_SELECTED_NODES }));
};

export const handleSubmitAWSOrgUnitSelection = (
  intl: IntlShape,
  state: AwsSelectMemberAccountsInitialStateType,
  dispatch: React.Dispatch<unknown>,
) => {
  const mode = getAwsOUProjectSelectMode(state);
  const selectedNodes = getAwsOUSelectedNodes(state);
  const rootId = getAwsOURootId();

  // if all the previously selected nodes are deleted from AWS end
  const deletedNodes = getAwsOUPreviouslySelectedNodesNotFound(state);
  let previousSelectedNodes = getAwsOUHierarchySelectionPayload(state);
  previousSelectedNodes = previousSelectedNodes.filter(
    (ar: { resourceId: string }) =>
      !deletedNodes.find(
        (rm: { resourceId: string }) => rm.resourceId === ar.resourceId,
      ),
  );

  const noItemSelected =
    selectedNodes.size === 0 || previousSelectedNodes.length === 0;
  const allItemsSelected = selectedNodes.has(rootId);
  let errors = [];
  if (mode !== ALL && (noItemSelected || allItemsSelected)) {
    const type = noItemSelected ? "none" : "all";
    errors = [
      dynamicFormatMessage(
        intl,
        memberAccountValidationErrorMessages,
        `aws_${type}_${mode.toLowerCase()}`,
      ),
    ];
    dispatch(setFTUFieldErrors({ errors, field: AWS_OU_SELECTED_NODES }));
  } else {
    dispatch(clearFTUFieldErrors({ field: AWS_OU_SELECTED_NODES }));
  }
  return errors.length === 0;
};
/* c8 ignore stop */
