import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const CloudVisibilityComplianceAndGovernanceIcon = (
  props: SvgIconProps,
) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Cloud Visibility Compliance And Governance",
    id: "miGKYN",

    description: "Security Capabilities Class Icon alt text",
  });

  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <title>{alt}</title>
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="m12.1261 13.0149v-8.33493l4.4058 8.33493z"
      />
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="m12.1263 4.6798-4.40581 8.335h4.40581v5.9852l-9.1263-9 9.1263-9z"
      />
    </SvgIcon>
  );
};
