import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { licensingRoute } from "../settingsLicensingRoute";

export const LicensingCreditAllocation = lazy(
  () => import("./CreditAllocation"),
);

export const licensingCreditAllocationRoute = new Route({
  getParentRoute: () => licensingRoute,
  path: "credit_allocation",
  component: LicensingCreditAllocation,
});
