import { defineMessages } from "react-intl";

export const additionalAttributesMessages = defineMessages({
  restrictDismissalAccess: {
    defaultMessage: "Restricted alert dismissal",
    id: "WB5NXV",
    description: "Label for Restricted alert dismissal checkbox",
  },
  onlyAllowCIAccess: {
    defaultMessage: "Access key only",
    id: "7E1Hm5",
    description: "Label for Access key only checkbox",
  },
  onlyAllowComputeAccess: {
    defaultMessage: "Only allow Compute capabilities",
    id: "Z3zQLv",
    description: "Label for Only for Compute capabilities checkbox",
  },
  hasDefenderPermissions: {
    defaultMessage: "On-prem/ Other cloud providers",
    id: "ZC9VWO",
    description: "Label for On-prem/ Other cloud providers checkbox",
  },
  onlyAllowReadAccess: {
    defaultMessage: "Read-only access to network security module",
    id: "IE5vQv",
    description:
      "Label for Read-only access to network security module checkbox",
  },
});

export const additionalAttributesTooltipMessages = defineMessages({
  restrictDismissalAccess: {
    defaultMessage:
      "This permission prevents admins belonging to this permission group from dismissing alerts generated by System Admin policies. This permission is not applicable to Compute only roles.",
    id: "KrM0Ko",
    description: "Label for Restricted alert dismissal tooltip",
  },
  onlyAllowCIAccess: {
    defaultMessage:
      "Run IDE, SCM, CI/CD plugins and other tools for Vulnerability & IaC scan via access key only",
    id: "kDzNvy",
    description: "Label for Access key only tooltip",
  },
  onlyAllowComputeAccess: {
    defaultMessage:
      "This permission provides access to only the Compute & Access Key tabs",
    id: "nziiTM",
    description: "Label for Only for Compute capabilities tooltip",
  },
  hasDefenderPermissions: {
    defaultMessage:
      "This permission allows access to view data coming from hosts deployed in clouds other than AWS, GCP, and Azure (supported for account ID discovery).",
    id: "Ffn7ba",
    description: "Label for On-prem/ Other cloud providers tooltip",
  },
  onlyAllowReadAccess: {
    defaultMessage:
      "This permission applies to the network security module and provides the user with read-only access to network security.",
    id: "GdjLqB",
    description:
      "Label for Read-only access to network security module tooltip",
  },
});

export const errorMessagesMap = defineMessages({
  user_role_name_already_exists: {
    defaultMessage:
      'There was an error creating a new role. The role name "{roleName}" already exists.',
    id: "aFofRm",
    description: "Duplicate role name error message",
  },
  unknown_error: {
    defaultMessage:
      "Role {action} failed due to an unknown error. Please contact your Prisma Cloud support team.",
    id: "xdI3X2",
    description: "An unknown error occurred during role creation/update",
  },
  invalid_user_role_type: {
    defaultMessage:
      "Role {action} failed. Select a valid user role and try again.",
    id: "6OONt+",
    description: "Invalid user role error message",
  },
  exceeding_tag_resource_list_role_limit: {
    defaultMessage:
      "Tag Resource List limit exceeded, maximum number of Tag Resource Lists allowed is {subject}",
    id: "uHIrHM",
    description: "Tag Resource List limit exceeded error message",
  },
});
