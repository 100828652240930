import { useEffect, useState, type ReactNode } from "react";
import { useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

type AccountDetailsProps = {
  children: ReactNode;
  defaultOpen?: boolean;
  isAccordianOpen?: boolean;
};

export default function AccountDetails({
  children,
  defaultOpen = false,
  isAccordianOpen = false,
}: AccountDetailsProps) {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(defaultOpen);
  const toggle = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (isAccordianOpen) setOpen(true);
  }, [isAccordianOpen]);

  return (
    <Accordion>
      <AccordionPanel
        isOpen={isOpen}
        onClick={toggle}
        title={intl.formatMessage({
          defaultMessage: "Account Details",
          id: "fEX82K",
          description: "Description for Account Details on review status",
        })}
      >
        <section className="my-2">{children}</section>
      </AccordionPanel>
    </Accordion>
  );
}
