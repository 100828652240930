import { Form, FormLayout, Input, SubmitButton, useFormContext } from "form";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getDefaultProxy, loginSSO } from "requests";
import { useToastActions } from "stores";
import { Body, Button } from "ui";

import { useMutation } from "@tanstack/react-query";
import { isProduction } from "environment";
import { PANWLogoDarkIcon } from "icons";

type FormProps = {
  loginProxy: string;
  username: string;
  prismaId: string;
};

export default function LoginSSOForm() {
  const intl = useIntl();
  const { toast } = useToastActions();

  const [showPrismaId, setShowPrismaId] = useState(false);
  const [currentPrismaId, setCurrentPrismaId] = useState("");

  const { mutateAsync } = useMutation({
    mutationFn: async ({ loginProxy, ...formValues }: FormProps) => {
      try {
        let baseUrl = loginProxy;
        // TODO: add loginProxyEnabled
        // if (baseUrl?.length === 0) baseUrl = getDefaultProxy(loginProxyEnabled);
        if (baseUrl?.length === 0) baseUrl = getDefaultProxy(false);
        const { username, prismaId } = formValues;
        const response = await loginSSO({
          username,
          prismaId,
          baseUrl,
        });
        const url = response?.url;
        window.open(url, "_self");
      } catch (e: unknown) {
        if (!showPrismaId) {
          setShowPrismaId(true);
        } else if (currentPrismaId) {
          toast(
            intl.formatMessage({
              defaultMessage:
                "OAuth2 configuration not found, please contact customer support.",
              id: "vOQvME",
              description: "Error message on failed request",
            }),
            { appearance: "error" },
          );
        }
      }
    },
  });

  const handleSubmit = useCallback(
    (values: FormProps) => mutateAsync(values),
    [mutateAsync],
  );

  return (
    <Form
      defaultValues={{
        loginProxy: getDefaultProxy(false),
      }}
      onSubmit={handleSubmit}
    >
      <FormLayout>
        <Body size="lg">
          <span className="text-[#ffffff]">
            {intl.formatMessage({
              defaultMessage: "Continue with SSO Sign In",
              id: "5iGRDM",
              description: "SSO sign in header",
            })}
          </span>
        </Body>

        <div>
          <span className="mb-2 inline-block text-xs text-[#ffffff]">
            {intl.formatMessage({
              defaultMessage: "Email",
              id: "zbBwEg",
              description: "Sign in Email label",
            })}
          </span>
          <Input
            name="username"
            showLabel={false}
            label={intl.formatMessage({
              defaultMessage: "Email",
              id: "zbBwEg",
              description: "Sign in Email label",
            })}
          />
        </div>

        {showPrismaId && (
          <div>
            <span className="mb-2 inline-block text-xs text-[#ffffff]">
              {intl.formatMessage({
                defaultMessage: "Prisma ID",
                id: "h0OYla",
                description: "Prisma ID label",
              })}
            </span>
            <Input
              name="prismaId"
              showLabel={false}
              label={intl.formatMessage({
                defaultMessage: "Prisma ID",
                id: "n/NQh2",
                description: "Prisma ID input label",
              })}
              onChange={(value) => {
                setCurrentPrismaId(value?.target?.value);
              }}
              register={{ required: false }}
            />
            <div className="mt-1 text-xs text-dark-bg-red">
              {intl.formatMessage({
                defaultMessage:
                  "The user name was either invalid or not unique to a tenant, please provide prisma id to continue.",
                id: "xApC0y",
                description: "Description for Prisma ID input",
              })}
            </div>
          </div>
        )}

        {!isProduction() && (
          <div>
            <span className="mb-2 inline-block text-xs text-[#ffffff]">
              {intl.formatMessage({
                defaultMessage: "API URL (Optional)",
                id: "ozATJU",
                description: "API URL Input Label",
              })}
            </span>
            <Input
              name="loginProxy"
              label={intl.formatMessage({
                defaultMessage: "API URL",
                id: "6DZyOc",
                description: "API URL Input Label",
              })}
              showLabel={false}
              register={{ required: false }}
            />
          </div>
        )}

        <SubmitButton fullWidth centerText>
          <FormattedMessage
            defaultMessage="Sign in with OIDC SSO"
            id="KSeT17"
            description="Message for logging into an application"
          />
        </SubmitButton>

        <div className="flex justify-center">
          <FormattedMessage
            defaultMessage="or"
            id="Kf7G0w"
            description="Or text"
          />
        </div>

        <PANWLoginButton />
      </FormLayout>
    </Form>
  );
}

function PANWLoginButton() {
  const { watch } = useFormContext();
  const username = watch("username");
  return (
    <Button
      fullWidth
      centerText
      appearance="tertiary"
      onClick={() => {
        username
          ? window.location.assign(
              `https://sso.paloaltonetworks.com/?username=${username}`,
            )
          : window.location.assign(`https://sso.paloaltonetworks.com`);
      }}
    >
      <div className="flex items-center justify-center space-x-2">
        <FormattedMessage
          defaultMessage="Sign in with "
          id="EKrH4s"
          description="Message for logging into an application"
        />
        <PANWLogoDarkIcon width="85" height="22" />
      </div>
    </Button>
  );
}
