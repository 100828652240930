import { defineMessages } from "react-intl";

export const accountTypeTranslations = defineMessages({
  account: {
    defaultMessage: "Account",
    id: "IanIt2",
    description: "Cloud Account type",
  },
  activeDirectory: {
    defaultMessage: "Active Directory",
    id: "RbTi5l",
    description: "Description for AD on review status",
  },
  compartment: {
    defaultMessage: "Compartment",
    id: "zyQNaZ",
    description: "Cloud Account type",
  },
  management_group: {
    defaultMessage: "Management Group",
    id: "C6aY+O",
    description: "Cloud Account type",
  },
  masterServiceAccount: {
    defaultMessage: "Master Service Account",
    id: "4Gtpc9",
    description: "Cloud Account type",
  },
  organization: {
    defaultMessage: "Organization",
    id: "mKaPkL",
    description: "Cloud Account type",
  },
  subscription: {
    defaultMessage: "Subscription",
    id: "1xjriA",
    description: "Cloud Account type",
  },
  tenant: {
    defaultMessage: "Tenant",
    id: "aUgW80",
    description: "Cloud Account type",
  },
  workspace_domain: {
    defaultMessage: "Workspace Domain",
    id: "X+rT5A",
    description: "Cloud Account type",
  },
  capabilities: {
    defaultMessage: "Capabilities",
    id: "eHmqqO",
    description: "Cloud Account capabilities",
  },
});
