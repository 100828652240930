import OciSecurityCapabilitiesPermissions, {
  Misconfigurations,
} from "../../../../../components/ReviewStatus/SecurityCapabilitiesPermissions";
import { type Status } from "../../../../../types";

type SecurityCapabilitiesDataProps = {
  message: string;
  id: string;
  name: string;
  status: Status;
  statusMessage: {
    message: string;
    staticMessage: boolean;
  };
}[];

function getSecurityCapabilitiesData(data: SecurityCapabilitiesDataProps) {
  const misconfigurationsData = data.filter(({ name }) =>
    ["config"].includes(name.toLowerCase()),
  );
  return {
    misconfigurationsData,
  };
}

type SecurityCapabilitiesPermissionsPropTypes = {
  accountStatus: {
    id: string;
    name: string;
    statusMessage: {
      message: string;
      staticMessage: boolean;
    };
    status: Status;
  }[];
  isLoading: boolean;
};

export default function SecurityCapabilitiesPermissions({
  accountStatus = [],
  isLoading,
}: SecurityCapabilitiesPermissionsPropTypes) {
  const statusData = accountStatus.map((data) => ({
    ...data,
    message: data?.statusMessage?.message,
  }));

  const { misconfigurationsData } = getSecurityCapabilitiesData(statusData);

  return (
    <OciSecurityCapabilitiesPermissions isLoading={isLoading}>
      <Misconfigurations
        data={misconfigurationsData}
        isCollapsibleDetails={true}
      />
    </OciSecurityCapabilitiesPermissions>
  );
}
