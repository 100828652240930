import { type ReactElement, type ReactNode } from "react";
import { Modal, flexRender } from "ui";
import {
  type UseWizardStateOptions,
  type WizardStepState,
} from "./useWizardState";

import { type ModalProps } from "ui";
import { WizardProvider } from "./WizardContext";
import { useWizardState } from "./useWizardState";

export interface WizardProps
  extends Pick<ModalProps, "isOpen" | "onClose" | "size" | "style"> {
  /**
   * Advanced/Uncommon
   *
   * Arbitrary data that will be passed to each step renderer.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: any;
  /**
   * The initial active step.
   *
   * For a create process, this is generally 0.
   *
   * For an edit process, this will often be the last step (often the summry step).
   */
  initialActiveIndex: number;
  /**
   * Advanced.
   *
   * Allows complete customization of the behavior of the Wizard. In many cases, you'll want to extend the `defaultReducer`.
   *
   * Some use cases where a custom reducer might be helpful:
   * * Injecting new steps based on a user actions
   * * Resetting form data based on a user action
   *
   * See the source code or ask for assistance if you need a custom reducer.
   */
  reducer?: UseWizardStateOptions["reducer"];
  /**
   * The wizard's steps. See `WizardStepState` for the expected properties.
   */
  steps: WizardStepState[];
  /**
   * The title of the wizard.
   */
  title: ReactNode;
}

/**
 * Wizards are multi-step forms for complex workflows.
 *
 * Steps are passed as an array of config objects. See `WizardStepState`.
 *
 * The "Component" for each step can be built independently. Most will use the `WizardForm` component.
 *
 * See how each individual step is constructed in the examples below and combined together in the [WizardWorkflow](#wizard-workflow) example.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { FormLayout, WizardForm, Wizard } from '@prismacloud/react-form';
 * ```
 *
 */
export function Wizard({
  context: userContext,
  initialActiveIndex,
  steps: initialSteps,
  title,
  ...modalProps
}: WizardProps): ReactElement {
  const context = useWizardState({
    initialState: {
      activeIndex: initialActiveIndex,
      isDirty: false,
      steps: initialSteps,
      title,
    },
  });
  const {
    state: { activeIndex, isDirty, steps },
  } = context;

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const step = flexRender(steps[activeIndex].Component, {
    context: userContext,
  });

  return (
    <Modal
      confirmClose={isDirty}
      eventsToClose={{
        enableClickOutside: false,
        enableEscapeKey: true,
      }}
      {...modalProps}
    >
      <WizardProvider context={context}>{step}</WizardProvider>
    </Modal>
  );
}
