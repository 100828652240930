import { useIntl } from "react-intl";

import { Select } from "form";
import { useMemo } from "react";
import { useTrimField } from "../../../../../../hooks";

export default function HomeRegion({
  regions,
  defaultValue,
}: {
  regions: string[];
  defaultValue: { value: string };
}) {
  const intl = useIntl();
  useTrimField("homeRegion");

  const homeRegions = useMemo(
    () => regions?.map((region) => ({ value: region })),
    [regions],
  );
  return (
    <Select
      data-selector="oci-home-region-select"
      name="homeRegion"
      label={intl.formatMessage({
        defaultMessage: "Home Region",
        id: "0NdVkd",
        description: "The name of an input field for onboarding an OCI account",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Home Region is required",
          id: "nKVWbC",
          description: "Error message for field for onboarding a OCI tenant",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "Region where Oracle Cloud Infrastructure tenancy is created",
        id: "BjPMKA",
        description: "Tooltip for Home Region input field",
      })}
      items={homeRegions}
      defaultValue={[defaultValue]}
    />
  );
}
