import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isNil } from "remeda";
import {
  type PermissionsStatus,
  type SecurityCapabilitiesType,
} from "requests";
import { dynamicFormatMessage } from "ui";
import AwsAccountDetails, {
  Authentication as AuthenticationStatus,
  DeploymentType,
  Details,
  SecurityCapabilities,
} from "../../../../../components/ReviewStatus/AccountDetails";
import {
  accountDetailsLabelMap,
  scopeMap,
} from "../../../../../messages/reviewStatusMessages";
import { type AccountType } from "../../../../../types";
import { AccountTypeIcon } from "../../../../../utils";

type AccountDetailsStatusPropTypes = {
  accountGroups: string[];
  accountName: string;
  accountType: AccountType;
  authentication?: PermissionsStatus;
  accountId: string;
  roleArn: string;
  securityCapabilities: SecurityCapabilitiesType[];
  deploymentType?: string;
  isLoading: boolean;
};

type Field = {
  renderComponent: (key: string) => JSX.Element;
  key: string;
  label: string;
  transform?: (value: string | string[]) => JSX.Element;
  rightIcon?: JSX.Element;
  defaultCount?: number;
  allowCopy?: boolean;
};

export default function AccountDetailsStatus(
  props: AccountDetailsStatusPropTypes,
) {
  const intl = useIntl();
  const {
    accountType,
    securityCapabilities,
    deploymentType = "",
    authentication,
    isLoading,
  } = props;

  const Icon = AccountTypeIcon(accountType);

  const fields = useMemo(() => {
    return [
      {
        renderComponent: (key: string) => (
          <AuthenticationStatus
            isLoading={isLoading}
            key={key}
            data={authentication}
            isCollapsibleDetails={true}
            isAccountDetails
          />
        ),
        key: "authentication",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "authentication",
        ),
      },
      {
        key: "accountType",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "scope"),
        transform: (value: string) =>
          dynamicFormatMessage(intl, scopeMap, value),
        rightIcon: <Icon className="mr-1" size="sm" />,
      },
      {
        renderComponent: (key: string) => (
          <SecurityCapabilities
            key={key}
            defaultCount={2}
            value={securityCapabilities}
          />
        ),
        key: "securityCapabilities",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "securityCapabilities",
        ),
      },
      {
        key: "accountName",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountName",
        ),
      },
      {
        key: "accountId",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          `${accountType}Id`,
        ),
        allowCopy: true,
      },
      {
        key: "accountGroups",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountGroups",
        ),
        transform: (value: string[]) =>
          intl.formatMessage(
            {
              defaultMessage: "{count} Account {label} Selected",
              id: "RyshgG",
              description: "Description for selected Account Group with count",
            },
            {
              count: value?.length,
              label: value?.length > 1 ? "Groups" : "Group",
            },
          ),
      },
      {
        key: "roleArn",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "roleArn"),
        allowCopy: true,
      },
      {
        renderComponent: (key: string) => (
          <DeploymentType key={key} value={deploymentType} />
        ),
        key: "deploymentType",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "deploymentType",
        ),
      },
    ].filter((field) => !isNil(field)) as Field[];
  }, [
    Icon,
    accountType,
    authentication,
    deploymentType,
    intl,
    isLoading,
    securityCapabilities,
  ]);

  const getContent = (
    transform: Field["transform"],
    key: string,
    value: unknown,
  ): JSX.Element => {
    if (!isNil(transform)) {
      if (key === "accountType") return transform(value as string);
      if (key === "accountGroups") return transform(value as string[]);
    }
    return value as JSX.Element;
  };

  return (
    <AwsAccountDetails>
      {fields.map(({ key, renderComponent, transform, ...rest }) =>
        !isNil(renderComponent) ? (
          renderComponent(key)
        ) : (
          <Details key={key} {...rest}>
            {getContent(transform, key, props[key as keyof typeof props])}
          </Details>
        ),
      )}
    </AwsAccountDetails>
  );
}
