import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { CheckboxInput, type CheckboxInputProps } from "./CheckboxInput";

export type CheckboxProps = ComponentPropsWithoutRef<"input"> &
  CheckboxInputProps & {
    /**
     * The text to display next to the checkbox. This will be the label.
     */
    children?: ReactNode;
  };

/**
 * Lets users toggle a boolean value.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Checkbox } from "ui";
 * ```
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      disabled = false,
      readOnly = false,
      children,
      ...passThrough
    }: CheckboxProps,
    ref,
  ) => {
    const labelClasses = classNames(
      "flex items-center space-x-2",
      disabled && "cursor-not-allowed",
      !disabled && !readOnly && "cursor-pointer",
    );

    return (
      <label className={labelClasses}>
        <CheckboxInput
          disabled={disabled}
          ref={ref}
          readOnly={readOnly}
          {...passThrough}
        />
        {children && <span>{children}</span>}
      </label>
    );
  },
);
Checkbox.displayName = "Checkbox";
