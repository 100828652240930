import { ActionsCell as BaseActionsCell } from "table";

import { type ReactElement } from "react";
import { type AllAccessKeysResponseObjectType } from "requests";
import { type RowInstance } from "table";
import Delete from "./Delete";

export default function ActionsCell({
  row,
}: {
  row: RowInstance<AllAccessKeysResponseObjectType>;
}): ReactElement {
  return (
    <BaseActionsCell>
      <Delete row={row} />
    </BaseActionsCell>
  );
}
