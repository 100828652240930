import { useIntl } from "react-intl";

import { RadioGroup } from "form";
import { LoadingIcon } from "icons";
import { useMemo } from "react";
import {
  RadioCard as DisabledRadioCard,
  RadioGroup as DisabledRadioGroup,
  Title,
} from "ui";
import {
  useGetAlibabaDeploymentType,
  useGetCloudDeploymentTypes,
} from "../../../../../hooks";

type DeploymentTypeProps = {
  isEdit: boolean;
  deploymentType: string;
};

export default function DeploymentType({
  isEdit,
  deploymentType,
}: DeploymentTypeProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Deployment Type",
    id: "f4w5cf",
    description: "Description for deployment type label",
  });

  const { data: { value: deploymentTypeValues = [] } = {}, isLoading } =
    useGetCloudDeploymentTypes({
      options: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    });
  const deploymentTypesData = useGetAlibabaDeploymentType(deploymentTypeValues);

  const defaultValue = useMemo(() => {
    const defaultDeploymentType = isEdit ? deploymentType : "ali-cn";
    return (
      deploymentTypesData.find((dep) => dep.value === defaultDeploymentType) ??
      deploymentTypesData[0]
    );
  }, [deploymentTypesData, deploymentType, isEdit]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (!deploymentTypesData?.some((depType) => depType.key === "ali-cn")) {
    return null;
  }

  let component;
  if (isEdit) {
    component = (
      <DisabledRadioGroup
        name={intl.formatMessage({
          defaultMessage: "Deployment Type",
          id: "xseWkh",
          description: "Label for alibaba deployment type",
        })}
        value={defaultValue.value}
        addClassName="flex gap-2 !space-y-0 [&>label]:flex-1"
        style={{ flexDirection: "row" }}
      >
        {deploymentTypesData?.map(({ title, description, value }) => (
          <DisabledRadioCard
            key={value}
            title={title}
            description={description}
            value={value}
            disabled
          />
        ))}
      </DisabledRadioGroup>
    );
  } else {
    component = (
      <RadioGroup
        addClassName="flex gap-2 [&>label]:flex-1"
        style={{ flexDirection: "row" }}
        disabled={isEdit}
        defaultValue={defaultValue.value}
        items={deploymentTypesData}
        label={title}
        name="deploymentType"
        register={{
          required: true,
        }}
        showLabel={false}
      />
    );
  }

  return (
    <section className="space-y-2">
      <Title level={5} size="xxs">
        {title}
      </Title>
      <div className="-ml-2">{component}</div>
    </section>
  );
}
