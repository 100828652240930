import { type ReactElement } from "react";
import { AbsoluteTimestamp, type AbsoluteTimestampProps } from "ui";

/**
 * Returns a cell renderer using the given formatting options.
 *
 * See `AbsoluteTimestamp` from @prismacloud/react-ui for formatting options.
 */
export function makeAbsoluteTimestampCell(
  options: Omit<AbsoluteTimestampProps, "value"> = {},
) {
  return function AbsoluteTimestampCell({
    value,
  }: {
    value?: number | string | Date;
  }): ReactElement {
    if (value == undefined) {
      return <span className="w-full text-left">-</span>;
    }
    return <AbsoluteTimestamp size="sm" {...options} value={value} />;
  };
}
