import { grayScale } from "colors";
import { Toggle, useWatch } from "form";
import { UsersIcon } from "icons";
import { AccountGroupSelect } from "prisma";
import { useIntl } from "react-intl";
import { useWizardContext } from "../../../../../../../components/Wizard";
import { AZURE } from "../../../../../../constants";
import {
  type AzureConfigureAccountStepValuesType,
  type AzureGetStartedStepValuesType,
} from "../../../../../../types";
import { Accordion } from "../../../../components/CustomAccordion";
import { accountGroupsMultiSelect } from "../../../../utils";

export default function AccountGroups() {
  const {
    state: { steps, activeIndex },
  } = useWizardContext();
  const intl = useIntl();

  const autoMap = useWatch({ name: "autoMap" });

  const { accountType } = steps[0]?.values as AzureGetStartedStepValuesType;
  const { groupIds: accountGroupDefaultValue } = steps[activeIndex]
    ?.values as AzureConfigureAccountStepValuesType;

  return (
    <Accordion
      description=""
      isOpen={!autoMap}
      icon={<UsersIcon size="lg" color={grayScale[600]} />}
      menuToggle={
        <Toggle
          name="autoMap"
          label={intl.formatMessage({
            defaultMessage: "Auto Create Account Groups",
            id: "BGefB2",
            description:
              "Description for GCP Account Groups toggle button Title",
          })}
          showLabel={false}
        />
      }
      title={intl.formatMessage({
        defaultMessage: "Auto Create Account Groups",
        id: "trMJRr",
        description: "Description for GCP Account Groups Card Title",
      })}
    >
      <AccountGroupSelect
        defaultValue={accountGroupDefaultValue}
        enableMultiSelect={accountGroupsMultiSelect({
          accountType,
          selectedCloud: AZURE,
        })}
        itemValue="id"
        name="groupIds"
      />
    </Accordion>
  );
}
