import { type Map } from "immutable";
import { AZURE } from "../../../constants";
import LoadMore from "../LoadMore";
import {
  type ConvertAwsTreeMapToTreeDataType,
  type TreeNodeType,
} from "../types";

export default function convertAzureTreeMapToTreeData({
  nodeId,
  treeMap,
  disabled,
  loadMoreProps,
}: ConvertAwsTreeMapToTreeDataType): TreeNodeType {
  const node = treeMap.get(nodeId) as unknown as Map<string, string>;
  if (!node) return {} as TreeNodeType;

  const loadMoreProjects = node.getIn(["cursorToken", "projects"]) && {
    checkable: false,
    isLeaf: true,
    key: `${node.get("id")}:load-more-projects`,
    title: (
      <LoadMore
        {...loadMoreProps}
        childrenType="projects"
        parentId={node.get("id")}
        parentName={node.get("displayName")}
        type={AZURE}
      />
    ),
  };

  const childFolders = node.getIn(["children", "folders"]).map((id = "") =>
    convertAzureTreeMapToTreeData({
      nodeId: id,
      treeMap,
      disabled,
      loadMoreProps,
    }),
  );

  const childProjects = node.getIn(["children", "projects"]).map((id = "") =>
    convertAzureTreeMapToTreeData({
      nodeId: id,
      treeMap,
      disabled,
      loadMoreProps,
    }),
  );

  const children = childFolders
    .union(childProjects)
    .add(loadMoreProjects)
    .filter((c: unknown) => !!c)
    .toJS();

  return {
    children,
    disabled: disabled.has(node.get("id")),
    id: node.get("id"),
    isLeaf: !node.get("hasChild"),
    key: node.get("id"),
    title: node.get("displayName"),
  };
}
