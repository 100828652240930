import { Map, Set } from "immutable";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  ALL,
  AWS_OU_DEFAULT_EXPANDED_NODES,
  AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR,
  AWS_OU_IS_LOADING,
  AWS_OU_LOADED_NODES,
  AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  AWS_OU_PROJECT_SELECT_MODE,
  AWS_OU_ROOT_Id,
  AWS_OU_SELECTED_NODES,
  AWS_OU_SELECTED_NODES_INITIAL,
  AWS_OU_SELECT_PROJECTS_INITIALIZED,
  AWS_OU_TREE_MAP,
  ENABLED,
  ERRORS,
  INIT_AWS_OU_TREE_MAP,
  IS_EDIT,
  NAME,
  ROLE_ARN,
  VALUE,
} from "../../../../../../../constants";

export const awsSelectMemberAccountsInitialState = {
  [AWS_OU_PROJECT_SELECT_MODE]: { [VALUE]: ALL, [ERRORS]: [] },
  [AWS_OU_SELECTED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [AWS_OU_TREE_MAP]: { [VALUE]: Map(), [ERRORS]: [] },
  [AWS_OU_ROOT_Id]: { [VALUE]: "root", [ERRORS]: [] },
  [NAME]: { [VALUE]: "", [ERRORS]: [] },
  [ACCOUNT_ID]: { [VALUE]: "", [ERRORS]: [] },
  [AWS_OU_SELECTED_NODES_INITIAL]: { [VALUE]: [], [ERRORS]: [] },
  [ENABLED]: { [VALUE]: true, [ERRORS]: [] },
  [ROLE_ARN]: { [VALUE]: "", [ERRORS]: [] },
  [ACCOUNT_TYPE]: { [VALUE]: "", [ERRORS]: [] },
  [INIT_AWS_OU_TREE_MAP]: { [VALUE]: Set(), [ERRORS]: [] },
  [AWS_OU_DEFAULT_EXPANDED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [AWS_OU_LOADED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND]: { [VALUE]: [], [ERRORS]: [] },
  [AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR]: { [VALUE]: "", [ERRORS]: [] },
  [IS_EDIT]: { [VALUE]: false },
  [AWS_OU_IS_LOADING]: { [VALUE]: false },
  [AWS_OU_SELECT_PROJECTS_INITIALIZED]: { [VALUE]: false },
};

type StateKeys = keyof typeof awsSelectMemberAccountsInitialState;
export type AwsSelectMemberAccountsInitialStateType = Map<
  StateKeys,
  { value: unknown; errors: unknown }
>;
