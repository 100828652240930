import {
  AWS_OU_LOADED_NODES,
  CLEAR_FTU_FIELD_ERRORS,
  INIT_AWS_OU_TREE_MAP,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  UPDATE_AWS_OU_NODES,
} from "../../../../../../../constants";

import {
  clearFTUFieldErrors,
  initializeAwsOUSelectProjects,
  setFTUFieldErrors,
  setFTUFieldValue,
  updateAwsOULoadedNodes,
  updateAwsOUTreeMap,
} from "../setters";

type Actions =
  | {
      type: "Update AWS nodes";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }
  | {
      type: "Initialize AWS tree map";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }
  | {
      type: "AWS loaded nodes";
      payload: string;
    }
  | {
      type: "clear_ftu_field_errors";
      payload: {
        field: string;
      };
    }
  | {
      type: "set_ftu_field_errors";
      payload: {
        field: string;
        errors: string[];
      };
    }
  | {
      type: "set_ftu_field_value";
      payload: {
        field: string;
        value: unknown;
      };
    };

export function awsSelectMemberAccountsReducer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  action: Actions,
) {
  switch (action?.type) {
    case UPDATE_AWS_OU_NODES:
      return updateAwsOUTreeMap({
        state,
        payload: action.payload,
      });
    case INIT_AWS_OU_TREE_MAP:
      return initializeAwsOUSelectProjects({
        state,
        payload: action.payload,
      });
    case AWS_OU_LOADED_NODES:
      return updateAwsOULoadedNodes({
        state,
        payload: action.payload,
      });
    case CLEAR_FTU_FIELD_ERRORS:
      return clearFTUFieldErrors({ state, field: action?.payload?.field });
    case SET_FTU_FIELD_ERRORS:
      return setFTUFieldErrors({
        errors: action?.payload?.errors,
        field: action?.payload?.field,
        state,
      });
    case SET_FTU_FIELD_VALUE:
      return setFTUFieldValue({
        field: action?.payload?.field,
        state,
        value: action?.payload?.value,
      });
    default:
      return state;
  }
}

export type AwsSelectMemberAccountsReducerType =
  typeof awsSelectMemberAccountsReducer;
