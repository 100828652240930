import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { dashboardsLayoutRoute } from "./dashboardsLayoutRoute";

const DashboardRedirect = lazy(() =>
  import("./DashboardRedirect").then((res) => ({
    default: res.DashboardRedirect,
  })),
);

export const dashboardsRedirectRoute = createRoute({
  getParentRoute: () => dashboardsLayoutRoute,
  path: "/",
  component: DashboardRedirect,
});
