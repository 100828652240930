import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { settingsCloudAccountsRoute } from "../../settingsCloudAccountsRoute";
import { type OnboardingProps } from "../../types";
import { EditTempAccount } from "../SimplifiedOnboarding/EditTempAccount/EditTempAccount";
import { AlibabaOnboarding } from "./AlibabaOnboarding";
import { AwsOnboarding } from "./AwsOnboarding";
import { AzureOnboarding } from "./AzureOnboarding";
import { GcpOnboarding } from "./GcpOnboarding";
import { IbmOnboarding } from "./IbmOnboarding";
import { OciOnboarding } from "./OciOnboarding";
import { settingsCloudAccountsEditModalRoute } from "./settingsCloudAccountsEditModalRoute";

const cloudWizards: Record<string, React.FC<OnboardingProps>> = {
  aws: AwsOnboarding,
  alibaba_cloud: AlibabaOnboarding,
  azure: AzureOnboarding,
  gcp: GcpOnboarding,
  ibm: IbmOnboarding,
  oci: OciOnboarding,
};

export default function EditModalOpener() {
  const navigate = useNavigate();
  const { cloudAccountId: accountId, cloudType } = useParams({
    from: settingsCloudAccountsEditModalRoute.id,
  });
  const { tempAccount } = useSearch({
    from: settingsCloudAccountsEditModalRoute.id,
  });

  const closeModalWizard = () =>
    navigate({
      to: settingsCloudAccountsRoute.id,
    });

  const props = {
    accountId,
    cloudType,
    isEdit: true,
    closeModal: closeModalWizard,
    isOpen: true,
  };

  const CloudWizard = cloudWizards[cloudType];

  return tempAccount ? (
    <EditTempAccount closeModal={closeModalWizard} isOpen />
  ) : (
    <CloudWizard {...props} />
  );
}
