import { createRoute, redirect } from "@tanstack/react-router";
import { lazy } from "react";
import { privateRootRoute } from "../../privateRootRoute";

const CatchAll = lazy(() =>
  import("./CatchAll").then((res) => ({ default: res.CatchAll })),
);

export const catchAllRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "$",
  component: CatchAll,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/dashboard") {
      throw redirect({ to: "/dashboards", replace: true, search });
    } else if (pathname === "/policies") {
      throw redirect({ to: "/governance", replace: true, search });
    } else if (pathname === "/adoption_advisor") {
      throw redirect({
        to: "/home/cloud/adoption-advisor",
        replace: true,
        search,
      });
    } else if (pathname === "/compute") {
      throw redirect({ to: "/home/runtime", replace: true, search });
    } else if (pathname === "/home") {
      throw redirect({ to: "/home/cloud/welcome", replace: true, search });
    } else if (pathname === "/auth/signin" || pathname === "/legacy/signin") {
      throw redirect({ to: "/home", replace: true, search });
    }
  },
});
