/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { pageTableProviderSchema } from "../../../../../utils/PageTableProvider";
import { accessControlRoute } from "../../accessControlRoute";
import { PermissionGroups } from "./PermissionGroups";

export const permissionGroupsRoute = new Route({
  getParentRoute: () => accessControlRoute,
  path: "permission-groups",
  component: PermissionGroups,
  validateSearch: pageTableProviderSchema,
});
/* c8 ignore stop */
