import { useIntl } from "react-intl";

import {
  Bold,
  Checkbox,
  DynamicFormattedMessage,
  Tooltip,
  dynamicFormatMessage,
} from "ui";

import { InfoTooltipIcon } from "icons";
import { messageMap } from "../../messages";

type RecurseHierarchyCheckBoxProps = {
  checked: boolean;
  disabled: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export default function RecurseHierarchyCheckBox({
  checked,
  disabled,
  onChange,
}: RecurseHierarchyCheckBoxProps) {
  const intl = useIntl();
  const tooltip = dynamicFormatMessage(
    intl,
    messageMap,
    "recurseHierarchyTooltip",
  );
  return (
    <div className="flex items-center gap-x-1">
      <Checkbox
        checked={checked}
        data-selector="gcp-recurse-hierarchy-checkbox"
        disabled={disabled}
        onChange={onChange}
      >
        <Bold>
          <DynamicFormattedMessage
            messageMap={messageMap}
            token="recurseHierarchyLabel"
          />
        </Bold>
      </Checkbox>
      <Tooltip label={tooltip}>
        <span>
          <InfoTooltipIcon aria-label={tooltip} />
        </span>
      </Tooltip>
    </div>
  );
}
