import { DangerIcon, EyeIcon, EyeSlashIcon, SuccessIcon } from "icons";
import {
  forwardRef,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  type MouseEvent,
  type ReactNode,
} from "react";

import { useIntl } from "react-intl";
import { classNames } from "utils";
import { Tooltip } from "../..";
import { mergeRefs } from "../../utils";
import { Button, CopyButton } from "../Button";
import { ClearButton } from "./ClearButton";

export interface InputProps
  extends Omit<ComponentPropsWithoutRef<"input">, "prefix"> {
  /**
   * Add a className to the wrapper
   */
  addClassName?: string;
  allowClear?: boolean;
  allowCopy?: boolean;
  /**
   * Specifies the appearance of the input.
   */
  appearance?: "default" | "success" | "error" | "emphasis";
  defaultValue?: string;
  /**
   * Disables the input.
   */
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /**
   * Text to display when value is empty.
   */
  placeholder?: string;
  /**
   * Content to be rendered before the value, within the same border.
   */
  prefix?: ReactNode;
  readOnly?: boolean;
  /**
   * Content to be rendered after the value, within the same border.
   */
  suffix?: ReactNode;
  value?: string;
  /**
   * Indicating that an element should be focused on page load.
   */
  autoFocus?: boolean;

  /**
   * Indicating that the element will have a mask button that mask the input content
   */
  allowMask?: boolean;
}

/**
 * Lets users enter and edit text.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Input } from "ui";
 * ```
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      addClassName,
      allowClear,
      allowCopy = false,
      appearance,
      disabled = false,
      onChange,
      placeholder,
      prefix,
      readOnly = false,
      suffix,
      value,
      allowMask,
      ...passThrough
    }: InputProps,
    userRef,
  ) => {
    const isControlled = useRef(value !== undefined);
    const internalRef = useRef<HTMLInputElement>();
    const ref = useMemo(
      () => mergeRefs([userRef, internalRef]),
      [userRef, internalRef],
    );
    const hasError = appearance === "error";
    const isSuccess = appearance === "success";
    const intl = useIntl();
    const [isMaskOn, setMaskOn] = useState(allowMask);

    const maskActionLabel = isMaskOn
      ? intl.formatMessage({
          defaultMessage: "Show",
          id: "SrRDiy",
          description: "Label for show content",
        })
      : intl.formatMessage({
          defaultMessage: "Hide",
          id: "IsUPGA",
          description: "Label for hide content",
        });

    const borderColor = isSuccess
      ? "border-green-400"
      : hasError
        ? "border-red-700 dark:border-red-400"
        : readOnly
          ? "border-gray-100 dark:border-gray-1000"
          : disabled
            ? "border-gray-300 dark:border-blue-steel-850"
            : appearance === "emphasis"
              ? "focus-within:border-blue-600 dark:focus-within:border-blue-600 focus-within:hover:border-blue-600 dark:focus-within:hover:border-blue-600 hover:border-gray-200 dark:hover:border-blue-steel-850 border-white dark:border-blue-steel-950"
              : "border-gray-300 dark:border-blue-steel-850";

    const rootClassName = classNames(
      "box-border flex h-8 items-center space-x-1 rounded border px-2 py-1",
      borderColor,
      readOnly
        ? "bg-gray-200 dark:bg-blue-steel-850"
        : appearance === "emphasis"
          ? "bg-gray-100 focus-within:bg-white dark:bg-blue-steel-1000 dark:focus-within:bg-blue-steel-950"
          : "bg-white focus-within:ring dark:bg-blue-steel-1000",
      disabled && "cursor-not-allowed text-disabled dark:text-dark-bg-disabled",
      addClassName,
    );
    // TODO fix placeholder style
    // eslint-disable-next-line tailwindcss/no-custom-classname
    const inputClassName = classNames(
      "flex-auto bg-transparent text-xs focus:outline-none",
      appearance === "emphasis"
        ? "placeholder:text-gray-600 dark:placeholder:text-gray-400"
        : "placeholder:text-gray-300 dark:placeholder:text-gray-600",
      disabled && "cursor-not-allowed ",
      !isMaskOn && "truncate",
    );

    return (
      <span className={rootClassName}>
        {prefix}
        <input
          ref={ref}
          className={inputClassName}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          type={isMaskOn ? "password" : "text"}
          {...passThrough}
        />
        {suffix}
        {allowMask && (
          <Tooltip label={maskActionLabel}>
            <Button
              addClassName="p-0.5"
              style={{ marginRight: "-0.125rem" }}
              appearance="tertiary-clear"
              icon={
                isMaskOn ? <EyeIcon size="md" /> : <EyeSlashIcon size="md" />
              }
              onClick={() => setMaskOn(!isMaskOn)}
              aria-label={maskActionLabel}
              data-testid="mask-button"
            />
          </Tooltip>
        )}
        {allowClear && (
          <ClearButton
            addClassName="p-0.5"
            style={{ marginRight: "-0.125rem" }}
            onClick={(e) => {
              e.stopPropagation();
              if (isControlled.current) {
                if (internalRef.current && onChange) {
                  onChange(makeMockClearEvent(internalRef.current, e));
                }
              } else {
                internalRef.current?.setRangeText(
                  "",
                  0,
                  Number.MAX_SAFE_INTEGER,
                );
              }
            }}
          />
        )}
        {allowCopy && (
          <CopyButton
            addClassName="p-0.5"
            style={{ marginRight: "-0.125rem" }}
            text={() => internalRef.current?.value as string}
          />
        )}
        {isSuccess && <SuccessIcon />}
        {hasError && <DangerIcon aria-hidden />}
      </span>
    );
  },
);

function makeMockClearEvent(
  inputRef: HTMLInputElement,
  clickEvent: MouseEvent<HTMLButtonElement>,
): ChangeEvent<HTMLInputElement> {
  const updatedTarget = { ...inputRef, value: "" };
  const {
    nativeEvent,
    bubbles,
    cancelable,
    defaultPrevented,
    eventPhase,
    isTrusted,
    preventDefault,
    isDefaultPrevented,
    stopPropagation,
    isPropagationStopped,
    persist,
    timeStamp,
  } = clickEvent;
  return {
    currentTarget: updatedTarget,
    target: updatedTarget,
    nativeEvent,
    bubbles,
    cancelable,
    defaultPrevented,
    eventPhase,
    isTrusted,
    preventDefault,
    isDefaultPrevented,
    stopPropagation,
    isPropagationStopped,
    persist,
    timeStamp,
    type: "clear",
  };
}

Input.displayName = "Input";
