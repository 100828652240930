import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsProvidersRoute } from "../settingsProvidersRoute";

export const K8sSatellite = lazy(() => import("./K8sSatellite/K8sSatellite"));

export const settingsK8sSatelliteRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "k8sSatellite",
  component: K8sSatellite,
});
