import { useEffect, useMemo, useState } from "react";
import {
  AUTO,
  EXCLUDE,
  GCP,
  MANUAL,
  ORGANIZATION,
  RECURSIVE,
  SERVICE_ACCOUNT,
} from "../../../../../../constants";
import {
  Accordion,
  AccordionToggle,
} from "../../../../components/CustomAccordion";
import {
  getGCPAccountGroupCreationMode,
  getGCPFoldersCredentialError,
  getGCPProjectSelectMode,
  getGCPSelectProjectsInitialized,
} from "../../../context/GCPSelectMonitoredProjectContext/state/getters";
import {
  getExternalAccountKey,
  getServiceAccountKey,
} from "../../../context/GcpCredentialsFileContext/state/getters";

import { grayScale } from "colors";
import { UsersIcon } from "icons";
import { AccountGroupSelect } from "prisma";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";
import { useWizardContext } from "../../../../../../../components/Wizard";
import { getCredentialsErrorMessage } from "../../../../components/SelectMemberAccountCredentialError";
import { useGCPSelectMonitoredProjectsContext } from "../../../context/GCPSelectMonitoredProjectContext";
import { setGCPAccountGroupCreationMode } from "../../../context/GCPSelectMonitoredProjectContext/state/actions";
import { useGCPCredentialsFileContext } from "../../../context/GcpCredentialsFileContext";
import RecurseHierarchyCheckBox from "./components/RecurseHierarchyCheckBox";
import { messageMap } from "./messages";

export default function SelectAccountGroups({ isEdit }: { isEdit: boolean }) {
  const {
    state: { steps },
  } = useWizardContext();
  const { watch, setValue } = useFormContext();

  const [autoOnboardProjects = false, accountGroupsValue = []] = watch([
    "autoOnboardProjects",
    "groupIds",
  ]);

  const accountType = steps[0]?.values?.accountType;
  const isOrg = accountType === ORGANIZATION;
  const isMSA = accountType !== ORGANIZATION && autoOnboardProjects;
  const enableMultiSelect = !isMSA && Array.isArray(accountGroupsValue);

  useEffect(() => {
    if (isEdit) return;

    if (isMSA && Array.isArray(accountGroupsValue)) {
      setValue("groupIds", accountGroupsValue[0]);
    } else if (
      !isOrg &&
      !isMSA &&
      !Array.isArray(accountGroupsValue) &&
      accountGroupsValue
    ) {
      setValue("groupIds", [accountGroupsValue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isMSA, isOrg]);

  return isOrg ? (
    <AccountGroups accountGroupDefaultValue={accountGroupsValue} />
  ) : (
    <AccountGroupSelect
      defaultValue={accountGroupsValue}
      enableMultiSelect={enableMultiSelect}
      itemValue="id"
      name="groupIds"
    />
  );
}

type AccountGroupsProps = {
  accountGroupDefaultValue: string | string[];
};

function AccountGroups({ accountGroupDefaultValue }: AccountGroupsProps) {
  const intl = useIntl();
  const { watch } = useFormContext();

  const { state: jsonFileState } = useGCPCredentialsFileContext();
  const {
    state: selectMonitoredProjectsState,
    dispatch: selectMonitoredProjectsDispatch,
  } = useGCPSelectMonitoredProjectsContext();

  const organizationId = watch("organizationId") ?? "";
  const authenticationType = watch("authenticationType") ?? "";

  const selectionMode = getGCPProjectSelectMode(selectMonitoredProjectsState);
  const accountGroupCreationMode = getGCPAccountGroupCreationMode(
    selectMonitoredProjectsState,
  );
  const apiFolderCredentialError = getGCPFoldersCredentialError(
    selectMonitoredProjectsState,
  );
  const isGCPSelectProjectsInitialized = getGCPSelectProjectsInitialized(
    selectMonitoredProjectsState,
  );

  const credentials =
    authenticationType === SERVICE_ACCOUNT
      ? getServiceAccountKey(jsonFileState)
      : getExternalAccountKey(jsonFileState);

  const disabled = isEmpty(organizationId) || isEmpty(credentials);

  const [autoMap, setAutoMap] = useState({
    checked: accountGroupCreationMode === AUTO,
    disabled: disabled,
  });
  const [recurse, setRecurse] = useState({
    checked: accountGroupCreationMode === RECURSIVE,
    disabled: disabled,
  });

  // Get credentials and prevSelectedNode Error Messages
  const selectProjectErrors = useMemo(() => {
    return getCredentialsErrorMessage({
      errorMessage:
        apiFolderCredentialError === "folder_viewer_permission_required"
          ? "folder_viewer_permission_required_no_automap"
          : apiFolderCredentialError,
      type: GCP,
      intl,
    }).filter((v) => !isEmpty(v));
  }, [apiFolderCredentialError, intl]);

  const updateAutoMapAndRecurseState = () => {
    if (disabled) return;
    if (accountGroupCreationMode === AUTO) {
      setAutoMap((prevState) => ({
        ...prevState,
        checked: true,
        disabled: false,
      }));
      setRecurse((prevState) => ({
        ...prevState,
        checked: false,
        disabled: false,
      }));
    } else if (accountGroupCreationMode === RECURSIVE) {
      if (selectionMode === EXCLUDE) {
        setAutoMap((prevState) => ({
          ...prevState,
          checked: true,
          disabled: false,
        }));
        setRecurse((prevState) => ({
          ...prevState,
          checked: true,
          disabled: true,
        }));
      } else {
        setAutoMap((prevState) => ({
          ...prevState,
          checked: true,
          disabled: false,
        }));
        setRecurse((prevState) => ({
          ...prevState,
          checked: true,
          disabled: false,
        }));
      }
    } else {
      setAutoMap((prevState) => ({
        ...prevState,
        checked: false,
        disabled: false,
      }));
      setRecurse((prevState) => ({
        ...prevState,
        checked: false,
        disabled: true,
      }));
    }
  };

  useEffect(() => {
    if (disabled || !isGCPSelectProjectsInitialized) return;
    if (!isEmpty(apiFolderCredentialError)) {
      setAutoMap((prevState) => ({
        ...prevState,
        checked: false,
        disabled: true,
      }));
      setRecurse((prevState) => ({
        ...prevState,
        checked: false,
        disabled: true,
      }));
      updateAccountGroupCreationMode({
        autoMapChecked: false,
        recurseChecked: false,
      });
    } else {
      updateAutoMapAndRecurseState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiFolderCredentialError,
    accountGroupCreationMode,
    isGCPSelectProjectsInitialized,
    selectionMode,
  ]);

  const handleAutoMapChecked = () => {
    let isRecurseChecked = recurse.checked;
    if (selectionMode === EXCLUDE) {
      isRecurseChecked = !autoMap.checked;
      setAutoMap({ ...autoMap, checked: !autoMap.checked });
      setRecurse({ ...recurse, checked: isRecurseChecked, disabled: true });
    } else {
      if (autoMap.checked) {
        isRecurseChecked = false;
      }
      setAutoMap({ ...autoMap, checked: !autoMap.checked });
      setRecurse({
        ...recurse,
        checked: isRecurseChecked,
        disabled: autoMap.checked,
      });
    }
    updateAccountGroupCreationMode({
      autoMapChecked: !autoMap.checked,
      recurseChecked: isRecurseChecked,
    });
  };

  const handleRecurseChecked = () => {
    setRecurse({ ...recurse, checked: !recurse.checked });
    updateAccountGroupCreationMode({
      autoMapChecked: autoMap.checked,
      recurseChecked: !recurse.checked,
    });
  };

  const updateAccountGroupCreationMode = ({
    autoMapChecked,
    recurseChecked,
  }: {
    autoMapChecked: boolean;
    recurseChecked: boolean;
  }) => {
    let gcpAccountGroupCreationMode = MANUAL;
    if (autoMapChecked && recurseChecked) {
      gcpAccountGroupCreationMode = RECURSIVE;
    } else if (autoMapChecked) {
      gcpAccountGroupCreationMode = AUTO;
    }
    selectMonitoredProjectsDispatch(
      setGCPAccountGroupCreationMode(gcpAccountGroupCreationMode),
    );
  };

  return (
    <Accordion
      description={
        <RecurseHierarchyCheckBox
          {...recurse}
          onChange={handleRecurseChecked}
        />
      }
      disabled={autoMap.checked}
      errors={selectProjectErrors}
      isOpen={!autoMap.checked}
      icon={<UsersIcon size="lg" color={grayScale[600]} />}
      menuToggle={
        <AccordionToggle {...autoMap} onClick={handleAutoMapChecked} />
      }
      title={intl.formatMessage(messageMap.title)}
      tooltip={intl.formatMessage(messageMap.tooltip)}
    >
      {!autoMap.checked ? (
        <AccountGroupSelect
          defaultValue={accountGroupDefaultValue}
          enableMultiSelect={false}
          fullWidth
          itemValue="id"
          name="groupIds"
          showLabel={false}
        />
      ) : null}
    </Accordion>
  );
}
