import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const AuditLogs = lazy(() => import("./AuditLogs"));

export const auditLogsRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "audit-logs",
  component: AuditLogs,
});
