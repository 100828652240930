import {
  defineMessage,
  defineMessages,
  type MessageDescriptor,
} from "react-intl";

export const accountDetailsLabelMap = defineMessages({
  downloadCFT: {
    defaultMessage: "CFT Template",
    id: "ep6k+N",
    description: "label for download CFT button",
  },
  authentication: {
    defaultMessage: "Authentication",
    id: "SdJRsp",
    description: "Description for Authentication on review status",
  },
  authenticationMode: {
    defaultMessage: "Authentication Mode",
    id: "73LPUq",
    description: "Description for Authentication Mode on review status",
  },
  domainName: {
    defaultMessage: "Domain Name",
    id: "wTBRIg",
    description: "Description for Workspace on review status",
  },
  scope: {
    defaultMessage: "Scope",
    id: "jnIAbW",
    description: "Description for Scope on review status",
  },
  securityCapabilities: {
    defaultMessage: "Security Capabilities",
    id: "TxQBx9",
    description: "Description for Security Capabilities on status",
  },
  accountName: {
    defaultMessage: "Account Name",
    id: "gl0JQw",
    description: "Description for Account Name on review status screen",
  },
  organizationId: {
    defaultMessage: "Organization ID",
    id: "MUbaXu",
    description: "Description for Organization ID on on review status",
  },
  accountId: {
    defaultMessage: "Account ID",
    id: "+qM2rQ",
    description: "Description for Account ID on on review status",
  },
  subscriptionId: {
    defaultMessage: "Subscription ID",
    id: "HzZeeg",
    description: "Description for Subscription ID on on review status",
  },
  accountGroups: {
    defaultMessage: "Account Groups",
    id: "t4c9+0",
    description: "Description for Account Groups label on review status",
  },
  roleArn: {
    defaultMessage: "IAM Role ARN",
    id: "BP4YkE",
    description: "Description for IAMRoleArn on review status",
  },
  lastModifiedBy: {
    defaultMessage: "Last Modified By",
    id: "TXpyts",
    description: "Description for Last Modified By on review status",
  },
  lastModifiedEpochMillis: {
    defaultMessage: "Last Modified",
    id: "TC8re/",
    description: "Description for Last Modified review status",
  },
  clientId: {
    defaultMessage: "Client ID",
    id: "paic7r",
    description: "Description for Client ID on review status",
  },
  tenantId: {
    defaultMessage: "Tenant ID",
    id: "FtIdIt",
    description: "Description for Tenant ID on review status",
  },
  servicePrincipalId: {
    defaultMessage: "Enterprise Application Object ID",
    id: "UNrpMd",
    description:
      "Description for Enterprise Application Object ID on review status",
  },
  deploymentType: {
    defaultMessage: "Deployment Type",
    id: "oqdL5e",
    description: "Description for Deployment Type on review status",
  },
  projectId: {
    defaultMessage: "Project ID",
    id: "uAePVr",
    description: "Description for Project ID on review status",
  },
  clientEmail: {
    defaultMessage: "Client Email",
    id: "NHgmJP",
    description: "Description for Client Email on review status",
  },
  serviceIamId: {
    defaultMessage: "Service IAM ID",
    id: "uyK5aj",
    description: "Description for Service IAM ID on review status",
  },
  privateKeyId: {
    defaultMessage: "Private Key ID",
    id: "/8V6ei",
    description: "Description for Private Key ID on review status",
  },
  projectName: {
    defaultMessage: "Project Name",
    id: "FW3pEZ",
    description: "Description for Project Name on review status screen",
  },
  dataFlowEnabled: {
    defaultMessage: "DataFlow Enabled",
    id: "5xtlTC",
    description: "Description for DataFlow Enabled on review status screen",
  },
  organization_externalId: {
    defaultMessage: "Management External ID",
    id: "DwTrwn",
    description: "Description for Management External ID on review status",
  },
  account_externalId: {
    defaultMessage: "External ID",
    id: "AY0Ncr",
    description: "Description for External ID on review status",
  },
  cftTemplate: {
    defaultMessage: "CFT Template",
    id: "V+g4FV",
    description: "Description for CFT Template on review status",
  },
  masterServiceAccountId: {
    defaultMessage: "Master Service Account Id",
    id: "8cMIRa",
    description: "Description for Master Service Account Id on review status",
  },
  serviceAccount: {
    defaultMessage: "Service Account",
    id: "DuhVqF",
    description: "Description for Service Account on review status",
  },
  service_account: {
    defaultMessage: "Service Account",
    id: "DuhVqF",
    description: "Description for Service Account on review status",
  },
  external_account: {
    defaultMessage: "External Account",
    id: "7AH+Ky",
    description: "Description for External Account on review status",
  },
  projectViewer: {
    defaultMessage: "Project Viewer",
    id: "k0P+i/",
    description: "Description for Project Viewer on review status",
  },
  svcIdIamId: {
    defaultMessage: "Service IAM ID",
    id: "uyK5aj",
    description: "Description for Service IAM ID on review status",
  },
  ramArn: {
    defaultMessage: "RAM Role",
    id: "fCJdR5",
    description: "Description for RAM Role on review status",
  },
  userOcid: {
    defaultMessage: "User OCID",
    id: "DvyaII",
    description: "Description for User OCID on review status",
  },
  homeRegion: {
    defaultMessage: "Home Region",
    id: "bSsROx",
    description: "Description for Home Region on review status",
  },
  policyName: {
    defaultMessage: "Policy Name",
    id: "xn0p3V",
    description: "Description for Policy Name on review status",
  },
  rootOcid: {
    defaultMessage: "Tenant/Root OCID",
    id: "DIZWZM",
    description: "Description for Tenant/Root OCID on review status",
  },
  groupName: {
    defaultMessage: "Group Name",
    id: "xHPx1e",
    description: "Description for Group Name on review status",
  },
  userName: {
    defaultMessage: "User Name",
    id: "Xx5wH3",
    description: "Description for ser Name on review status",
  },
});

export const scopeMap = defineMessages({
  account: {
    defaultMessage: "Account",
    id: "5jrsvC",
    description: "Description for Account on review status",
  },
  organization: {
    defaultMessage: "Organization",
    id: "AbMewA",
    description: "Description for Organization on review status",
  },
  masterServiceAccount: {
    defaultMessage: "Master Service Account",
    id: "TR3uvY",
    description: "Description for MSA on review status",
  },
  subscription: {
    defaultMessage: "Subscription",
    id: "tUXfdU",
    description: "Description for Subscription on review status",
  },
  tenant: {
    defaultMessage: "Tenant",
    id: "mcp3pC",
    description: "Description for Tenant on review status",
  },
  activeDirectory: {
    defaultMessage: "Active Directory",
    id: "RbTi5l",
    description: "Description for AD on review status",
  },
  project: {
    defaultMessage: "Project",
    id: "ZZyE2o",
    description: "Description for Tenant on review status",
  },
  compartment: {
    defaultMessage: "Compartment",
    id: "46sd6N",
    description: "Tool tip for Account type",
  },
  workspace_domain: {
    defaultMessage: "Google Workspace",
    id: "OoUiP8",
    description: "Description for Workspace on review status",
  },
});

export const securityCapabilitiesMap = defineMessages({
  cloud_visibility_compliance_and_governance: {
    defaultMessage: "Misconfigurations (CSPM)",
    id: "dYkCVk",
    description: "Description for Misconfigurations (CSPM) on review status",
  },
  agentless_scanning: {
    defaultMessage: "Agentless Workload Scanning",
    id: "FsVrFx",
    description: "Description for Agentless Workload Scanning on review status",
  },
  threat_detection: {
    defaultMessage: "Threat Detection",
    id: "ysV87f",
    description: "Description for Threat Detection on review status",
  },
  serverless_function_scanning: {
    defaultMessage: "Serverless Function Scanning",
    id: "VDsrZM",
    description:
      "Description for Serverless Function Scanning on review status",
  },
  auto_protect: {
    defaultMessage: "Agent-Based Workload Protection",
    id: "+8tOhA",
    description:
      "Description for Agent Based Workload Protection on review status",
  },
  data_security: {
    defaultMessage: "Data Security",
    id: "NmdwxP",
    description: "Description for Data Security on review status",
  },
  remediation: {
    defaultMessage: "Remediation",
    id: "yMiWWj",
    description: "Description for Remediation on review status",
  },
  identity_security: {
    defaultMessage: "Identity Security",
    id: "am96kQ",
    description: "Description for Identity Security on review status",
  },
  agentless_api_discovery: {
    defaultMessage: "Agentless API Discovery",
    id: "nbeOVO",
    description: "Agentless API Discovery provided status message",
  },
});

export const misconfigurationsMap = defineMessages({
  config: {
    defaultMessage: "Asset Configuration",
    id: "j3BUBt",
    description: "Description for Asset Configuration on review status",
  },
  aws_guard_duty: {
    defaultMessage: "Guard Duty",
    id: "3qMOwe",
    description: "Description for Guard Duty on review status",
  },
  aws_inspector: {
    defaultMessage: "Inspector",
    id: "4T2IdW",
    description: "Description for Inspector on review status",
  },
});

export const fallback = defineMessage({
  defaultMessage: "Status not found",
  id: "yNJRjc",
  description: "Fallback status message",
});

export const dataFlowEnabledMap = defineMessages({
  true: {
    defaultMessage: "Yes",
    id: "qyt7uW",
    description: "Description for Yes on review status",
  },
  false: {
    defaultMessage: "No",
    id: "EC7hng",
    description: "Description for No on review status",
  },
});

export const statusToastMessagesMap: Record<string, MessageDescriptor> =
  defineMessages({
    external_id_empty_or_not_generated: {
      defaultMessage:
        "External ID not generated. To generate External ID, download the CFT. Execute the CFT before you proceed.",
      id: "yTIL6H",
      description: "Description for unsuccessful onboard error message",
    },
    duplicate_cloud_account: {
      defaultMessage:
        "This Cloud Account is already onboarded in Prisma Cloud application. Please choose another account to continue.",
      id: "7pTqnX",
      description: "Description for unsuccessful onboard error message",
    },
    duplicate_cloud_account_name: {
      defaultMessage:
        "Account Name is already configured. Cannot re-use the same.",
      id: "k4e6U7",
      description: "Description for unsuccessful onboard error message",
    },
    invalid_role_arn_account_id: {
      defaultMessage: "Number in the ARN must match the AWS Account ID.",
      id: "sY2uLI",
      description: "Description for unsuccessful onboard error message",
    },
    empty_external_id: {
      defaultMessage: "External ID is required",
      id: "WfpPTi",
      description: "Description for unsuccessful onboard error message",
    },
    invalid_role_name: {
      defaultMessage: "Invalid Role Name",
      id: "WS0eOx",
      description: "Description for unsuccessful onboard error message",
    },
    storage_scan_config_incorrect: {
      defaultMessage:
        "Role ARN does not have access to perform AssumeRole the resources.",
      id: "E83xTl",
      description: "Description for unsuccessful onboard error message",
    },
    invalid_external_id_pattern: {
      defaultMessage: "Invalid External Id",
      id: "TOTFWx",
      description: "Description for unsuccessful onboard error message",
    },
    invalid_account_id_format: {
      defaultMessage: "Account ID should be 12 digits long",
      id: "b1W/U1",
      description: "Description for unsuccessful onboard error message",
    },
    cloud_account_arn_validation: {
      defaultMessage: "Please enter valid Role ARN",
      id: "bfUy45",
      description: "Description for unsuccessful onboard error message",
    },
    not_account_owner: {
      defaultMessage: "Not Account Owner. Please contact Prisma Cloud support.",
      id: "53wLLR",
      description: "Description for unsuccessful onboard error message",
    },
    status_error_message_subscription_not_a_member_of_tenant: {
      defaultMessage: "Subscription Id provided is not a member of the tenant",
      id: "jboiVI",
      description: "Subscription id mismatch error",
    },
    eventbridge_config_not_found: {
      defaultMessage:
        "Please download and rerun the IAM role CFT before proceeding with the eventbridge configuration.",
      id: "R0toSq",
      description: "Bad request error for event bridge cft download",
    },
    cloud_account_ram_arn_validation: {
      defaultMessage: "Please enter valid RAM Role",
      id: "IEPuYH",
      description: "Missing parameter error",
    },
    invalid_parameter_value: {
      defaultMessage: "Invalid parameter value : {subject}",
      id: "xGhGuy",
      description: "Duplicate account name error message",
    },
    unknown_error: {
      defaultMessage:
        "Something went wrong, please try again later. If issue persists, please contact your Prisma Cloud support team.",
      id: "qH2F1r",
      description: "Description for unsuccessful onboard error message",
    },
    unknown: {
      defaultMessage:
        "Something went wrong, please try again later. If issue persists, please contact your Prisma Cloud support team.",
      id: "qH2F1r",
      description: "Description for unsuccessful onboard error message",
    },
    invalid_gcp_org_id: {
      defaultMessage:
        "Organization ID does not exist with GCP. If you have just created one, please try onboard after some time.",
      id: "SJGOYS",
      description: "Invalid GCP organization id error message",
    },
    invalid_gcp_org_name: {
      defaultMessage:
        "Organization Name does not match with name for provided Organization ID",
      id: "UT8/Mx",
      description: "Invalid GCP organization name error message",
    },
    invalid_gcp_project_id: {
      defaultMessage: "GCP Project ID Format is Not Valid",
      id: "rPtoXu",
      description: "Invalid GCP Project ID error message",
    },
    invalid_group_ids: {
      defaultMessage: "Account groups are required",
      id: "NUJ42C",
      description: "Invalid GCP Account groups error message",
    },
    cannot_enable_flowlogs_for_azure_tenant_without_children: {
      defaultMessage:
        "Cannot Enable Flowlogs for Azure Tenant without children.",
      id: "kSarD8",
      description: "Cannot Enable Flowlogs error message",
    },
    missing_required_parameter: {
      defaultMessage: 'Missing required parameter: "{subject}"',
      id: "kqfAFa",
      description: "Missing parameter error",
    },
    invalid_azure_subscription_id: {
      defaultMessage: "The provided Azure Subscription ID is invalid",
      id: "05tNlu",
      description: "Invalid subscription ID error",
    },
    only_monitor_mode_is_allowed_for_azure_tenant_without_children: {
      defaultMessage:
        "Only monitor mode is allowed for azure tenant without children",
      id: "GkQEam",
      description: "Auth error",
    },
    bad_request: {
      defaultMessage: "Bad Request",
      id: "/kTmX4",
      description: "Bad request error",
    },
    invalid_credentials: {
      defaultMessage: "Invalid credentials",
      id: "cKyWcf",
      description: "Description for invalid credentials",
    },
    assume_role_token_fetch_issue: {
      defaultMessage:
        "Please download and rerun the IAM role CFT before proceeding with the eventbridge configuration.",
      id: "iMxdrd",
      description: "Description for unsuccessful cft download error message",
    },
  });
export type StatusToastMessagesMap = keyof typeof statusToastMessagesMap;
