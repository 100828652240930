import { SettingsPageIcon } from "icons";
import { useIntl } from "react-intl";
import { PageHeader, PageLayout } from "ui";

export default function SettingsRepositories() {
  const intl = useIntl();
  return (
    <PageLayout
      documentTitle={[
        intl.formatMessage({
          defaultMessage: "Settings",
          id: "Pgzph9",
          description: "page header",
        }),
        intl.formatMessage({
          defaultMessage: "Repositories",
          id: "o6AJ2i",
          description: "page header",
        }),
      ]}
      header={
        <PageHeader
          icon={<SettingsPageIcon size="lg" />}
          title={intl.formatMessage({
            defaultMessage: "Settings Repositories",
            id: "YPbhWB",
            description: "page header",
          })}
        />
      }
      overflow
    >
      <></>
    </PageLayout>
  );
}
