import { z } from "zod";

export const ValueSchema = z
  .union([
    z.string(),
    z.number(),
    z.boolean(),
    z.record(z.string(), z.unknown()),
  ])
  .optional();
export const FilterValueSchema = z.union([
  ValueSchema,
  z.array(ValueSchema),
  z.record(z.string(), z.union([ValueSchema, z.array(ValueSchema)])),
]);
export const FilterObjectSchema = z
  .record(z.string(), FilterValueSchema)
  .optional();
export const FilterStateSchema = z.object({
  filters: FilterObjectSchema,
  displayedFilters: z.array(z.string()).optional(),
});
