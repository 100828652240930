import { classNames } from "utils";
import { Body, Bold } from "../Typography";

import {
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";
export interface InputCardProps
  extends Omit<ComponentPropsWithoutRef<"div">, "title"> {
  /**
   * Describes the purpose of the Card.
   */
  title: ReactNode;
  /**
   * Describes the details for the Card.
   */
  description: ReactNode;
  icon?: ReactNode;
  /**
   * Whether or not this card is disabled.
   */
  disabled?: boolean;
  /**
   * Whether or not this card is selected.
   */
  selected?: boolean;
  readOnly?: boolean;
  /**
   * Show Tag on right side of title
   */
  tag?: ReactNode;
  /**
   * Add a className to the card
   */
  addClassName?: string;
  input: ReactNode;
}

/**
 * This component is meant to be used for the custom radio and checkbox inputs
 *
 * ### Import Guide
 *
 * ```jsx
 * import { InputCard } from '@prismacloud/react-ui';
 * ```
 */
export function InputCard({
  title,
  description,
  icon,
  addClassName,
  selected = false,
  readOnly = false,
  disabled = false,
  tag,
  input,
  ...passThrough
}: InputCardProps): ReactElement {
  const className = classNames(
    "flex grow gap-x-2 rounded border p-4",
    !readOnly &&
      "focus-within:ring focus-within:ring-blue-300 focus-within:ring-offset-0 focus-within:dark:ring-blue-800",
    !disabled &&
      !readOnly &&
      "cursor-pointer hover:bg-blue-600/[.08] hover:ring dark:hover:bg-blue-300/[.08]",
    selected
      ? "border-blue-600 bg-blue-600/[.12] dark:border-blue-300 dark:bg-blue-300/[.12]"
      : "border-gray-300 dark:border-blue-steel-500",
    disabled
      ? "bg-gray-100 dark:bg-blue-steel-850"
      : selected
        ? ""
        : "bg-white dark:bg-blue-steel-950",
    tag ? "relative" : "",
    addClassName,
  );

  return (
    <div className={className} {...passThrough}>
      {tag ? <div className="absolute right-1 top-4">{tag}</div> : null}
      <div className="flex flex-col items-center gap-4">
        <div className="flex h-6 items-center">{input}</div>

        <div className="flex w-6 grow items-center justify-center">{icon}</div>
      </div>
      <div className="flex flex-col gap-4">
        {typeof title === "string" ? (
          <Bold addClassName="flex h-6 items-center">{title}</Bold>
        ) : (
          title
        )}
        <Body addClassName="flex grow items-center">{description}</Body>
      </div>
    </div>
  );
}
