import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { Button } from "ui";

export function SaveAndAddAnotherButton({
  isLoading = false,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: ({
    saveAndAddAnother,
    reset,
    requirementId,
    sectionId,
    name,
    description,
  }: {
    saveAndAddAnother?: boolean;
    reset?: () => void;
    requirementId?: string;
    sectionId?: string;
    name: string;
    description?: string;
  }) => Promise<void>;
}) {
  const intl = useIntl();
  const {
    formState: { isValid },
    getValues,
    reset,
    trigger,
  } = useFormContext();

  const saveAndAddAnother = useCallback(async () => {
    await trigger();

    if (!isValid) return;
    const values = getValues();
    const clonedValues = { ...values } as {
      saveAndAddAnother: boolean;
      reset: () => void;
      requirementId?: string;
      sectionId?: string;
      name: string;
      description?: string;
    };

    clonedValues.saveAndAddAnother = true;
    clonedValues.reset = reset;

    onSubmit(clonedValues);
  }, [isValid, getValues, onSubmit, reset, trigger]);

  return (
    <Button
      isLoading={isLoading}
      appearance="secondary"
      onClick={saveAndAddAnother}
    >
      {intl.formatMessage({
        defaultMessage: "Save and Add Another",
        id: "YlZpmY",
        description: "Save and add another label",
      })}
    </Button>
  );
}
