import { FormattedMessage, useIntl } from "react-intl";
import {
  Bold,
  Button,
  ButtonGroup,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";

import { BookIcon, SearchIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePermissions } from "prisma";
import { useState } from "react";
import { SearchFilter } from "ui";
import { UCDIcon } from "../../../../../components/Navigation/UCDSelect/UCDIcon";
import { WIZARD_HEIGHT } from "../../../cloudAccounts/constants";
import ApplicationSecurity from "./ApplicationSecurity";
import CloudSecurity from "./CloudSecurity";
import RunTimeSecurity from "./RunTimeSecurity";

type OnboardProvidersModalProps = RenderModalProps<{
  selectedProvider: string;
}>;

export const OnboardProvidersModal = ({
  isOpen,
  closeModal,
  selectedProvider = "cloudSecurity",
}: OnboardProvidersModalProps) => {
  const [search, setSearch] = useState("");
  const [selectedButton, setButtonValue] = useState(selectedProvider);
  const intl = useIntl();

  const { computeRegistries } = useFlags();

  const permissions = usePermissions();
  const { settingsCloudAccounts, computePolicyContainers } = permissions;

  // const isAppSecReposPermitted =
  //   settingsRepositories.CREATE && settingsRepositories.UPDATE && codeEnabled;
  const canManageRegistries =
    computeRegistries && computePolicyContainers.UPDATE;

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      size="xl"
      style={{ minHeight: WIZARD_HEIGHT }}
    >
      <ModalHeader
        title={intl.formatMessage({
          defaultMessage: "Onboard Provider",
          id: "nbRlD4",
          description: "Modal title for Add Cloud Account",
        })}
      />
      <ModalBody addClassName="p-8">
        <div className="space-y-4">
          <div className="flex">
            <Bold addClassName="text-lg">
              <FormattedMessage
                defaultMessage="Your Journey For"
                id="kdmWNI"
                description="Button label for manage Advanced deployments button"
              />
            </Bold>

            <div className="flex space-x-4 pl-2 pt-1">
              <ButtonGroup>
                {settingsCloudAccounts.CREATE && (
                  <Button
                    icon={<UCDIcon mode="cloud" />}
                    isSelected={selectedButton === "cloudSecurity"}
                    onClick={() => {
                      setButtonValue("cloudSecurity");
                    }}
                    addClassName="mr-2"
                  >
                    <FormattedMessage
                      defaultMessage="Cloud Security"
                      id="k1eYQ6"
                      description="Cloud Security UCD dropdown option"
                    />
                  </Button>
                )}

                {/* Uncomment the below code after Application Security code is implemented */}
                {/* {enableCasIntegrationsCatalog && isAppSecReposPermitted && (
                  <Button
                    icon={<UCDIcon mode="application" />}
                    isSelected={selectedButton === "applicationSecurity"}
                    onClick={() => {
                      setButtonValue("applicationSecurity");
                    }}
                    addClassName="mr-2"
                  >
                    <FormattedMessage
                      defaultMessage="Application Security"
                      id="rog4IP"
                      description="Application Security UCD dropdown option"
                    />
                  </Button>
                )} */}

                {canManageRegistries && (
                  <Button
                    icon={<UCDIcon mode="runtime" />}
                    isSelected={selectedButton === "runTimeSecurity"}
                    onClick={() => {
                      setButtonValue("runTimeSecurity");
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Runtime Security"
                      id="SM88rH"
                      description="Runtime Security UCD dropdown option"
                    />
                  </Button>
                )}
              </ButtonGroup>
            </div>
          </div>
          <div>
            <SearchFilter
              value={search}
              onChange={setSearch}
              placeholder={intl.formatMessage({
                defaultMessage: "Search by provider or outcome...",
                id: "ldMFfu",
                description: "Search bar placeholder message",
              })}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              prefix={<SearchIcon className="text-secondary" />}
              suffix=""
            />
          </div>
          <div>
            {selectedButton === "cloudSecurity" && (
              <CloudSecurity
                closeModal={closeModal}
                isOpen={isOpen}
                search={search}
                fromFreemium={false}
                fromProvidersPage="cloudSecurity"
              />
            )}
            {selectedButton === "applicationSecurity" && (
              <ApplicationSecurity />
            )}
            {selectedButton === "runTimeSecurity" && <RunTimeSecurity />}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <BookIcon />
        <Link
          href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/connect/connect-cloud-accounts/connect-cloud-accounts"
          external
        >
          <FormattedMessage
            defaultMessage="Visit Tech Docs"
            id="YJZ6AX"
            description="Visit Tech Docs link"
          />
        </Link>
      </ModalFooter>
    </Modal>
  );
};
