import { FormattedMessage } from "react-intl";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  type RenderModalProps,
} from "ui";

import { Table } from "./Table";

function CodeRolesModal({
  data,
  isOpen,
  closeModal,
}: RenderModalProps<{
  data: { id: string; name: string }[];
}>) {
  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <ModalHeader
        enableClose
        title={
          <FormattedMessage
            defaultMessage="Roles"
            id="e6Q4k9"
            description="Modal title for code repositories list"
          />
        }
      />
      <ModalBody>
        <Table data={data} />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Done"
            id="V/OVLW"
            description="Modal done button text"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function useCodeRolesModal() {
  return useModal(CodeRolesModal);
}
