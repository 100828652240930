import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { TruncateText } from "../TruncateText";

const sizeVariants = {
  xxs: "text-base font-semibold",
  xs: "text-lg font-medium",
  sm: "text-xl font-normal",
  md: "text-2xl font-light",
  lg: "text-3xl font-light",
  xl: "text-4xl font-light",
  xxl: "text-5xl font-light",
};

export type TitleProps<T = Omit<ComponentPropsWithoutRef<"h1">, "className">> =
  T & {
    /**
     * Add a class name to the element.
     */
    addClassName?: string;
    children: ReactNode;
    /**
     * The heading level of the rendered html element.
     *
     * The level represents the semantic level of the heading within the larger
     * page hierarchy. It is unrelated to the size of the content.
     *
     * In general, there should only be one level 1 heading on the page, and each
     * heading below should incrememted by one for each semantic level.
     *
     * The heading information can be used to generate a table of contents of the
     * document to facilitate accessible navigation. Set heading levels with this
     * in mind.
     *
     * See [MDN Heading](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements#usage_notes)
     * for more guidance on usage.
     */
    level: 1 | 2 | 3 | 4 | 5 | 6;
    /**
     * The size of the text.
     *
     * Note that size is separate from level. Size is purely visual - how large the font size is.
     * Level, on the otherhand is semantic - where the title fits in within the page hierarchy. The highest
     * level title may (and often won't) be the largest title.
     */
    size?: keyof typeof sizeVariants;
    /**
     * Truncate text when there is no room.
     *
     * This will add a flex class to the Title, so it will no longer wrap.
     */
    truncate?: boolean;
  };

/**
 * Renders a styled heading element, used for titles and headings.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Title } from "ui";
 * ```
 */
export function Title<T = Omit<ComponentPropsWithoutRef<"h1">, "className">>({
  addClassName,
  children,
  level,
  size = "md",
  truncate = false,
  ...passThrough
}: TitleProps<T>): ReactElement {
  const Component = `h${level}` as ElementType;
  const className = classNames(
    "m-0 text-primary",
    sizeVariants[size],
    truncate && "flex",
    addClassName,
  );

  return (
    <Component className={className} {...passThrough}>
      {truncate ? <TruncateText>{children}</TruncateText> : children}
    </Component>
  );
}
