import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { LoadingIconOnFilterBackground } from "ui";
import { accountGroupsRoute } from "../settingsAccountGroupsRoute";

export const RouteBasedAccountGroupModal = lazy(
  () => import("./RouteBasedAccountGroupModal"),
);

export const accountGroupModalRoute = new Route({
  getParentRoute: () => accountGroupsRoute,
  path: "$accountGroupId/edit",
  component: RouteBasedAccountGroupModal,
  pendingComponent: LoadingIconOnFilterBackground,
});
