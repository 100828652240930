import { useCallback } from "react";
import { type FieldValues } from "react-hook-form";
import { useWizard } from ".";

export const useWizardSubmitHandlers = () => {
  const {
    setWizardValues,
    currentStepName,
    setCurrentStepName,
    setVisitedSteps,
  } = useWizard();

  const appendValuesMoveForward = useCallback(
    (formValues: FieldValues, goTo?: string) => {
      // We need to aggregate the form values into a single store.
      // We just need to store the values that get passed
      // in, since each step's defaultValue is the wizardValues. No need to actually append.
      // By not calling setWizardValues, formValues is only the values for the current form.
      setWizardValues((values) => ({ ...values, ...formValues }));
      setVisitedSteps((visitedSteps) => {
        visitedSteps[currentStepName] = {
          ...visitedSteps[currentStepName],
          status: "valid",
        };
        return visitedSteps;
      });
      goTo && setCurrentStepName(goTo);
    },
    [currentStepName, setCurrentStepName, setVisitedSteps, setWizardValues],
  );

  const appendValuesMoveBackward = useCallback(
    (formValues: FieldValues, goTo?: string) => {
      // We need to aggregate the form values into a single store.
      // We just need to store the values that get passed
      // in, since each step's defaultValue is the wizardValues. No need to actually append.
      // By not calling setWizardValues, formValues is only the values for the current form.
      setWizardValues((values) => ({ ...values, ...formValues }));
      goTo && setCurrentStepName(goTo);
    },
    [setCurrentStepName, setWizardValues],
  );

  return { appendValuesMoveForward, appendValuesMoveBackward };
};
