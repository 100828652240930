import { type AccountType, type CloudType } from "../../types";
import DeleteAccount from "./DeleteAccount";
import EditButton from "./EditButton";
import IngestionEnabled from "./IngestionEnabled";

type ActionsProps = {
  accountId: string;
  accountType: AccountType;
  cloudType: CloudType;
  enabled: boolean;
  setCloseModal: () => void;
};

export default function Actions({
  accountId,
  accountType,
  cloudType,
  enabled,
  setCloseModal,
}: ActionsProps) {
  return (
    <div className="flex h-6 space-x-4">
      <IngestionEnabled
        accountId={accountId}
        accountType={accountType}
        cloudType={cloudType}
        enabled={enabled}
      />
      <EditButton accountId={accountId} cloudType={cloudType} />
      <div className="-mt-1">
        <DeleteAccount
          accountId={accountId}
          accountType={accountType}
          cloudType={cloudType}
          setCloseModal={setCloseModal}
        />
      </div>
    </div>
  );
}
