export const isNonEmpty = (
  value:
    | string
    | string[]
    | number
    | undefined
    | { [key: string]: string | string[] },
): boolean => {
  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === "string") {
    return value.trim() !== "";
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
};

export const isRegexValid = (regex: string) => {
  try {
    new RegExp(regex);
    return true;
  } catch {
    return false;
  }
};
