import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

const DataSettingsLayout = lazy(() =>
  import("./DataSettingsLayout").then((res) => ({
    default: res.DataSettingsLayout,
  })),
);

export const dataSettingsLayoutRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "data",
  component: DataSettingsLayout,
});
