import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { getTenantFeatures, tenantKeys } from "../../requests";
import { useToken } from "../useTokenStore";

export function useIsBridgeCrewEnabled(): boolean {
  const { pcBridgecrewEnabled } = useFlags();
  const { selectedCustomerName, prismaId } = useToken();

  const tenantFeatures = useQuery({
    queryKey: tenantKeys.features({
      customerName: selectedCustomerName,
      prismaId,
    }),
    queryFn: getTenantFeatures,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const bridgecrewEnabled = useMemo(() => {
    if (tenantFeatures.isLoading) return false;

    const bridgecrew = tenantFeatures?.data?.find(
      (feature) => feature.feature_name === "bridgecrew",
    );

    return !!bridgecrew && bridgecrew.enabled;
  }, [tenantFeatures]);

  return pcBridgecrewEnabled && bridgecrewEnabled === true;
}
