import { FormattedMessage } from "react-intl";
import { Title } from "ui";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

export default function AWSConsoleSteps() {
  return (
    <div className="ml-4 mt-4">
      <Title level={5} size="xxs">
        <FormattedMessage
          defaultMessage="Connect to the AWS Console"
          id="hd5oi7"
          description="Title for Connect to the AWS Console section"
        />
      </Title>
      <div className="mt-3 flex space-x-2">
        <Step1 />
        <Step2 />
        <Step3 />
      </div>
    </div>
  );
}

export function DottedLine() {
  return (
    <div className="inline-block h-0 w-14 border border-dashed border-gray-400"></div>
  );
}

export function StepNumber({ stepNumber }: { stepNumber: number }) {
  return (
    <div className="mt-4 inline-flex h-6 w-6 items-center rounded-full border-2 border-gray-300 bg-white px-[0.4rem] py-1  align-middle text-xs text-link dark:border-blue-steel-850 dark:bg-blue-steel-950 dark:text-dark-bg-secondary">
      <span>{stepNumber}</span>
    </div>
  );
}
