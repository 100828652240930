import {
  cloneElement,
  useCallback,
  useMemo,
  useState,
  type ReactElement,
} from "react";
import { FocusOn } from "react-focus-on";
import { FormattedMessage } from "react-intl";
import * as R from "remeda";
import { type TimeRange } from "requests";
import {
  Card,
  Portal,
  useFloating,
  type ComboboxChanges,
  type SelectChanges,
  type SelectItem,
} from "ui";
import { type DateItemNames } from "..";
import { type TimeRangeItemNames } from "../timeRange/useTimeRange/useTimeRange";

export type useCustomDateProps = {
  getItemsFn: ({
    itemNames,
    customItemOnClick,
  }: {
    itemNames: TimeRangeItemNames[] | DateItemNames[];
    customItemOnClick?: (() => void) | undefined;
  }) => SelectItem[];
  selectedValue?: TimeRange;
  /**
   * The names of the pre-defined items to include in the dropdown
   */
  itemNames: TimeRangeItemNames[];
  /**
   * The Picker component to render when Custom is selected
   */
  picker: ReactElement;
};

export const useCustomDate = ({
  getItemsFn,
  itemNames,
  picker,
  selectedValue,
}: useCustomDateProps) => {
  const { setReference, setFloating, floatingStyles } = useFloating({
    placement: "bottom-start",
    strategy: "fixed",
  });
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const items = useMemo(() => {
    return getItemsFn({
      itemNames,
      customItemOnClick: () => {
        setDatePickerOpen(!datePickerOpen);
      },
    });
  }, [itemNames, datePickerOpen, getItemsFn]);

  const selectedItem = useMemo(() => {
    return !selectedValue
      ? undefined
      : items.find(({ value }) => R.equals(value, selectedValue)) ??
          (itemNames.includes("custom") ? customTimeRangeItem : undefined);
  }, [selectedValue, items, itemNames]);

  const onCancel = useCallback(() => {
    setDatePickerOpen(false);
  }, []);

  const clonedPicker = cloneElement(picker, {
    onCancel,
  });

  const pickerPortal = (
    <Portal>
      <div className="z-20" ref={setFloating} style={floatingStyles}>
        {datePickerOpen && (
          <FocusOn
            enabled={!!datePickerOpen}
            noIsolation
            onClickOutside={onCancel}
            onEscapeKey={onCancel}
            scrollLock={false}
          >
            <Card role="dialog" shadow style={{ margin: "0.1rem 0" }}>
              {clonedPicker}
            </Card>
          </FocusOn>
        )}
      </div>
    </Portal>
  );

  const onChange = useCallback((changes: SelectChanges | ComboboxChanges) => {
    return changes.selectedItem?.value !== "custom";
  }, []);

  const getSelectProps = useCallback(() => {
    return {
      buttonRef: setReference,
      onChange,
    };
  }, [setReference, onChange]);

  return {
    items,
    selectedItem,
    getSelectProps,
    pickerPortal,
    datePickerOpen,
    setDatePickerOpen,
    onCancel,
  };
};

export const customTimeRangeItem = {
  value: "custom",
  children: (
    <FormattedMessage
      defaultMessage="Custom"
      id="rCGO7K"
      description="A drop down item to open a calendar to select a custom date."
    />
  ),
  key: "custom",
};
