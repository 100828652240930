import { defineMessages } from "react-intl";
import { type PolicyType } from "requests";

export const cloudTypeMessages = defineMessages({
  alibaba_cloud: {
    defaultMessage: "Alibaba",
    id: "kUO0eV",

    description: "The name of the cloud provider in a table",
  },
  alibaba: {
    defaultMessage: "Alibaba",
    id: "kUO0eV",

    description: "The name of the cloud provider in a table",
  },
  all: {
    defaultMessage: "All",
    id: "76uhR+",

    description: "Pertains to any or all cloud providers",
  },
  aws: {
    defaultMessage: "AWS",
    id: "Yhy6tS",

    description: "The name of the cloud provider in a table",
  },
  azure: {
    defaultMessage: "Azure",
    id: "Du9sGf",

    description: "The name of the cloud provider in a table",
  },
  gcp: {
    defaultMessage: "GCP",
    id: "ZaBGm4",

    description: "The name of the cloud provider in a table",
  },
  ibm: {
    defaultMessage: "IBM",
    id: "Xehawe",

    description: "The name of the cloud provider in a table",
  },
  oci: {
    defaultMessage: "OCI",
    id: "npLpRI",

    description: "The name of the cloud provider in a table",
  },
  other: {
    defaultMessage: "Other",
    id: "sJoFlr",

    description: "The name of the cloud provider in a table",
  },
});

export const cloudTypeAggregatedMessages = defineMessages({
  alibaba: {
    defaultMessage: "{count} Alibaba policies",
    id: "vurDYJ",

    description: "The count of policies that apply to Alibaba Cloud",
  },
  alibaba_cloud: {
    defaultMessage: "{count} Alibaba policies",
    id: "vurDYJ",

    description: "The count of policies that apply to Alibaba Cloud",
  },
  all: {
    defaultMessage: "{count} policies apply to any environment",
    id: "k4yi3Y",

    description: "The count of policies that apply to any environment",
  },
  aws: {
    defaultMessage: "{count} AWS policies",
    id: "iaaXpp",

    description: "The count of policies that apply to AWS",
  },
  azure: {
    defaultMessage: "{count} Azure policies",
    id: "Lunaph",

    description: "The count of policies that apply to Azure",
  },
  gcp: {
    defaultMessage: "{count} GCP policies",
    id: "TBUaHF",

    description: "The count of policies that apply to GCP",
  },
  ibm: {
    defaultMessage: "{count} IBM policies",
    id: "2z5wGo",

    description: "The count of policies that apply to IBM",
  },
  oci: {
    defaultMessage: "{count} Oracle Cloud policies",
    id: "QZ6i23",

    description: "The count of policies that apply to Oracle Cloud",
  },
  other: {
    defaultMessage: "{count} Other policies",
    id: "MEmkCE",

    description: "The count of policies that apply to other clouds",
  },
});

export const policyTypeMessages = defineMessages<PolicyType>({
  anomaly: {
    defaultMessage: "Anomaly",
    id: "wBFfgC",

    description: "The name of a policy type",
  },
  api: {
    defaultMessage: "API",
    id: "KPZhws",

    description: "The name of a policy type",
  },
  audit_event: {
    defaultMessage: "Audit Event",
    id: "PHo6+d",

    description: "The name of a policy type",
  },
  attack_path: {
    defaultMessage: "Attack Path",
    id: "h3S45F",

    description: "The name of a policy type",
  },
  config: {
    defaultMessage: "Config",
    id: "BFacJC",

    description: "The name of a policy type",
  },
  data: {
    defaultMessage: "Data",
    id: "n1U9y2",

    description: "The name of a policy type",
  },
  iam: {
    defaultMessage: "IAM",
    id: "3Yc/HW",

    description: "The name of a policy type",
  },
  network: {
    defaultMessage: "Network",
    id: "rnfrcK",

    description: "The name of a policy type",
  },
  workload_vulnerability: {
    defaultMessage: "Workload Vulnerability",
    id: "q3qJrr",

    description: "The name of a policy type",
  },
  workload_incident: {
    defaultMessage: "Workload Incident",
    id: "Xv4vFA",

    description: "The name of a policy type",
  },
  waas_event: {
    defaultMessage: "WAAS Event",
    id: "g1ALkO",

    description: "The name of a policy type",
  },
  malware: {
    defaultMessage: "Malware",
    id: "Ba+CPb",

    description: "The name of a policy type",
  },
  grayware: {
    defaultMessage: "Grayware",
    id: "kkngDl",

    description: "The name of a policy type",
  },
});

export const policyAggregatedMessages = defineMessages({
  anomaly: {
    defaultMessage: "{count} anomaly policies",
    id: "vksyAd",

    description: "The count of anomaly policies",
  },
  audit_event: {
    defaultMessage: "{count} audit event policies",
    id: "kOvDTd",

    description: "The count of audit event policies",
  },
  config: {
    defaultMessage: "{count} configuration policies",
    id: "TSyOFW",

    description: "The count of configuration policies",
  },
  data: {
    defaultMessage: "{count} data policies",
    id: "Er+6cs",

    description: "The count of data policies",
  },
  iam: {
    defaultMessage: "{count} IAM policies",
    id: "Ug2Tqp",

    description: "The count of IAM policies",
  },
  network: {
    defaultMessage: "{count} network policies",
    id: "hHVpT3",

    description: "The count of network policies",
  },
  workloads: {
    defaultMessage: "{count} workload policies",
    id: "Qweszl",

    description: "The count of workload policies",
  },
});

export const severityAggregatedMessages = defineMessages({
  critical: {
    defaultMessage: "{count} critical severity policies",
    id: "ceOIec",

    description: "The count of critical severity policies",
  },
  high: {
    defaultMessage: "{count} high severity policies",
    id: "zWi8vE",

    description: "The count of high severity policies",
  },
  low: {
    defaultMessage: "{count} low severity policies",
    id: "ZUWZ75",

    description: "The count of low severity policies",
  },
  medium: {
    defaultMessage: "{count} medium severity policies",
    id: "GLM+fF",

    description: "The count of medium severity policies",
  },
  informational: {
    defaultMessage: "{count} informational severity policies",
    id: "zTCWff",

    description: "The count of informational severity policies",
  },
});
