import { isChina } from "environment";
import { useEffect, useRef } from "react";
import { FormattedMessage, defineMessage, defineMessages } from "react-intl";
import {
  Body,
  Card,
  DynamicFormattedMessage,
  EmptyStateLayout,
  Logo,
  SomethingWentWrongSVG,
} from "ui";
import { RouterLink } from "../../components/Navigation/RouterLink/RouterLink";
import { getCookie, removeCookie } from "../../setup/processSSO/cookieUtils";
import backgroundImage from "../login/background.webp";
import { CopyrightNotice } from "../login/components/CopyrightNotice";
import { ChinaFooter } from "../requestPasswordReset/ChinaFooter";

export const defaultError = defineMessage({
  defaultMessage:
    "We were unable to successfully authenticate you with your SSO Identity Provider.",
  id: "ClpWbZ",
  description: "SSO error page general error message",
});

export const errorMessages = defineMessages({
  saml_authentication_failure_msg: {
    defaultMessage:
      "We were unable to successfully authenticate you with your SSO Identity Provider.",
    id: "KNB/tT",
    description: "SSO error message",
  },
  saml_bypass_success: {
    defaultMessage:
      "You have successfully updated the SSO/API allow list users.",
    id: "1c78Ch",
    description: "SSO error message",
  },
  saml_certificate_info_tip: {
    defaultMessage:
      "You can integrate with Identity Providers (IdP) for SSO. When you add Prisma Cloud as an SSO app in your IdP, you will get a certificate associated with the app which you need to provide here.",
    id: "Og4Kdw",
    description: "SSO error message",
  },
  saml_configuration_disabled: {
    defaultMessage: "SAML configuration is disabled in your environment.",
    id: "bEiMmQ",
    description: "SSO error message",
  },
  saml_configuration_invalid: {
    defaultMessage: "Provided SAML configuration is invalid.",
    id: "+yDGH3",
    description: "SSO error message",
  },
  saml_contact_admin_msg: {
    defaultMessage: "Please contact your administrator for more information.",
    id: "s59oiO",
    description: "SSO error message",
  },
  saml_error: {
    defaultMessage: "Saml Error",
    id: "1i6XXb",
    description: "SSO error message",
  },
  saml_expired_authn_statement: {
    defaultMessage:
      "Provided SAML response has expired AuthnStatements in assertion.",
    id: "sP5ayi",
    description: "SSO error message",
  },
  saml_invalid_assertion: {
    defaultMessage: "Provided SAML response has invalid assertion.",
    id: "hoBLxO",
    description: "SSO error message",
  },
  saml_invalid_audience_uri: {
    defaultMessage: "Provided SAML Audience URI is invalid.",
    id: "68isXM",
    description: "SSO error message",
  },
  saml_invalid_certificate: {
    defaultMessage: "Provided SAML Certificate is invalid.",
    id: "t6rAyl",
    description: "SSO error message",
  },
  saml_invalid_destination_uri: {
    defaultMessage: "Provided SAML Destination URI is invalid.",
    id: "Q2pENs",
    description: "SSO error message",
  },
  saml_invalid_issuer_uri: {
    defaultMessage: "Provided SAML Issuer URI is invalid.",
    id: "G7/1In",
    description: "SSO error message",
  },
  saml_invalid_login_redirect_url: {
    defaultMessage: "Provided SAML IdP Login Redirect URL is invalid.",
    id: "8ygDEg",
    description: "SSO error message",
  },
  saml_invalid_logout_redirect_url: {
    defaultMessage: "Provided SAML IdP Logout Redirect URL is invalid.",
    id: "hmzBxz",
    description: "SSO error message",
  },
  saml_invalid_relay_state_param: {
    defaultMessage: "Relay State param name should be alphanumeric.",
    id: "PSUPuz",
    description: "SSO error message",
  },
  saml_invalid_user: {
    defaultMessage:
      "Either the user does not exist or (s)he does not have SSO login access.",
    id: "cG03T6",
    description: "SSO error message",
  },
  saml_no_active_customer_entitlements_available: {
    defaultMessage:
      "No active entitlements found. You may need to contact your system administrator to add you into the appropriate tenant from within the Prisma Cloud user management flow.",
    id: "qcTsu2",
    description: "SSO error message",
  },
  saml_no_active_user_entitlements_available: {
    defaultMessage:
      "No active entitlements found. You may need to contact your system administrator to add you into the appropriate tenant from within the Prisma Cloud user management flow.",
    id: "qcTsu2",
    description: "SSO error message",
  },
  saml_parsing_error: {
    defaultMessage: "Provided SAML response could not be parsed properly.",
    id: "TsTvXr",
    description: "SSO error message",
  },
  saml_unable_to_configure_contact_redlock_support: {
    defaultMessage:
      "Unable to configure SAML using provided details. Please contact Prisma Cloud support.",
    id: "CuPiSN",
    description: "SSO error message",
  },
});

export default function SsoError() {
  const errorDetails = useRef(getCookie("x-pan-error-details") ?? "");
  const errorMessage = useRef(getCookie("x-pan-error-message") ?? "");

  useEffect(() => {
    removeCookie("x-pan-error-details");
    removeCookie("x-pan-error-message");
  }, []);

  return (
    <div
      className="flex min-h-screen flex-col bg-white bg-auto bg-top bg-no-repeat text-sm text-default dark:bg-blue-steel-950 dark:text-dark-bg"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="mx-auto w-96 grow pb-4 pt-28">
        <Card addClassName="p-10" shadow="md" appearance="glass">
          <RouterLink className="mx-auto mb-8 cursor-pointer" to="/login">
            <Logo />
          </RouterLink>
          <EmptyStateLayout
            heading={
              <FormattedMessage
                defaultMessage="Authentication Failed"
                id="gZq7nf"
                description="sso error page title"
              />
            }
            graphic={<SomethingWentWrongSVG className="w-48" />}
          />
          <div className="space-y-4">
            <Body>
              <DynamicFormattedMessage
                fallback={defaultError}
                messageMap={errorMessages}
                token={errorMessage.current}
              />
            </Body>

            {errorDetails.current.split("\\n").map((v) => (
              <Body key={v}>{v}</Body>
            ))}

            <Body>
              <FormattedMessage
                defaultMessage="Please contact your administrator for more information."
                id="6+tivp"
                description="SSO error page contact admin"
              />
            </Body>
          </div>
        </Card>

        <div className="pt-4 text-center">
          <Body appearance="secondary" size="sm">
            <CopyrightNotice />
          </Body>
        </div>
      </div>
      {isChina() && <ChinaFooter />}
    </div>
  );
}
