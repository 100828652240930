import { mutateData, type MutateDataProps } from "./mutateData";

import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useToastActions } from "stores";
import { noop } from "utils";

type ValueType = { name?: string };

export function useCreateRecord<T extends ValueType>() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();

  return useCallback(
    (
      updateFn: () => Promise<unknown>,
      {
        record,
        dataCacheKey,
        cacheUpdaterFn,
        successMessage,
        defaultErrorMessage,
        knownErrorMessages,
        onSuccess = noop,
        onError = noop,
      }: Omit<MutateDataProps<T>, "intl" | "queryClient" | "toast">,
    ) => {
      const defaultCacheUpdaterFn = (data: T[] = []) => {
        const newData = [...data];
        // @ts-expect-error expect record to be Partial<T>
        newData.unshift(record);
        return newData;
      };

      return mutateData(updateFn, {
        intl,
        queryClient,
        toast,
        dataCacheKey,
        cacheUpdaterFn: cacheUpdaterFn ?? defaultCacheUpdaterFn,
        record,
        successMessage,
        defaultErrorMessage,
        knownErrorMessages,
        onSuccess,
        onError,
      });
    },
    [intl, queryClient, toast],
  );
}
