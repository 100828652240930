import { Input, Textarea, WizardStepForm, useWizard } from "form";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { type PermissionGroupData } from "requests";
import { Body } from "ui";
import { PermissionGroupCloneSelect } from "./components/PermissionGroupCloneSelect";

const nameMaxLength = 300;
const descriptionMaxLength = 5000;

export const Details = () => {
  const intl = useIntl();

  const { wizardValues } = useWizard<string, PermissionGroupData>();

  const isEdit = useMemo(() => !!wizardValues?.id, [wizardValues?.id]);

  return (
    <WizardStepForm focusOnMount="name">
      <Body>
        {intl.formatMessage({
          defaultMessage:
            "Permission Groups provide a list of the access privileges associated with each role for different parts of the Prisma Cloud administrative console.",
          id: "eNiIi8",
          description: "Sub header explaining permission groups",
        })}
      </Body>
      <div className="space-y-4">
        <Input
          name="name"
          label={intl.formatMessage({
            defaultMessage: "Name",
            id: "NHiSmp",
            description: "Header for the table column",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Name this permission group...",
            id: "g/69A8",
            description: "Placeholder text",
          })}
          register={{
            maxLength: {
              value: nameMaxLength,
              message: intl.formatMessage(
                {
                  defaultMessage:
                    "The maximum length for the name is {number} characters.",
                  id: "wdoptd",
                  description: "Input error message",
                },
                { number: nameMaxLength },
              ),
            },
          }}
        />
        <Textarea
          rowsMin={3}
          name="description"
          label={intl.formatMessage({
            defaultMessage: "Description",
            id: "ex2bkI",
            description: "Permission group form field placeholder",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Describe this permission group...",
            id: "QJCp3X",
            description: "Permission group form field placeholder",
          })}
          register={{
            required: false,
            maxLength: {
              value: descriptionMaxLength,
              message: intl.formatMessage(
                {
                  defaultMessage:
                    "The maximum length for the description is {number} characters.",
                  id: "4xLmve",
                  description: "Input error message",
                },
                { number: descriptionMaxLength },
              ),
            },
          }}
        />
      </div>
      {!isEdit && <PermissionGroupCloneSelect />}
    </WizardStepForm>
  );
};
