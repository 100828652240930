import { SpinnerIcon } from "icons";
import { useState } from "react";
import { DynamicFormattedMessage, useIsMounted } from "ui";
import { loadMoreMessageFallback, loadMoreMessages } from "./loadMoreMessages";
import { type LoadDataType } from "./types";

type LoadMoreProps = {
  childrenType: "folders" | "projects";
  loadData: LoadDataType;
  parentId: string;
  parentName: string;
  type: string;
};

export default function LoadMore({
  childrenType,
  loadData,
  parentId,
  parentName,
  type,
}: LoadMoreProps) {
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  async function onClickHandler() {
    setLoading(true);

    await loadData({ childrenType, loadType: "more", parentId });

    if (isMounted.current) {
      setLoading(false);
    }
  }

  return (
    <span
      className="relative cursor-pointer text-link"
      onClick={onClickHandler}
      role="button"
      {...{ disabled: loading }}
    >
      {loading && (
        <span className="absolute -ml-6 mt-1">
          <SpinnerIcon size="xs" aria-label="Loading" />
        </span>
      )}
      <DynamicFormattedMessage
        fallback={loadMoreMessageFallback}
        messageMap={loadMoreMessages}
        token={type}
        values={{ childrenType, parentName }}
      />
    </span>
  );
}
