import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";
import { Banner, Body } from "ui";
import {
  EXTERNAL_ACCOUNT_KEY,
  SERVICE_ACCOUNT,
  SERVICE_ACCOUNT_KEY,
} from "../../../../../../../../constants";
import { useGCPCredentialsFileContext } from "../../../../../context/GcpCredentialsFileContext";
import { clearJsonFieldErrors } from "../../../../../context/GcpCredentialsFileContext/state/actions";

export default function FileUploadErrors({
  errors,
}: {
  errors: { defaultMessage: string; description: string; id?: string }[];
}) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const { dispatch } = useGCPCredentialsFileContext();

  const selectedAuthenticationType = watch("authenticationType");

  const isServiceAccount = selectedAuthenticationType === SERVICE_ACCOUNT;

  const setClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    if (isServiceAccount) {
      dispatch(clearJsonFieldErrors({ field: SERVICE_ACCOUNT_KEY }));
    } else {
      dispatch(clearJsonFieldErrors({ field: EXTERNAL_ACCOUNT_KEY }));
    }
  };
  if (isEmpty(errors)) return null;

  const errorMessages = errors.map((error, i) => {
    return <Body key={i}>{intl.formatMessage(error)}</Body>;
  });

  return (
    <div className="mt-2">
      <Banner
        style={{ alignItems: "center" }}
        data-selector="gcp-upload-json-error"
        appearance="error"
        showIcon={true}
        onClose={setClose}
        type="inline"
      >
        {errorMessages}
      </Banner>
    </div>
  );
}
