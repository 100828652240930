import { type ReactElement } from "react";
import { Skeleton } from "../Skeleton";

export type SelectListLoadingSkeletonProps = {
  enableMultiSelect?: boolean;
  count?: number;
};

/**
 * Animated loading skeleton for SelectList component
 */
export function SelectListLoadingSkeleton({
  enableMultiSelect,
  count = 7,
}: SelectListLoadingSkeletonProps): ReactElement {
  return (
    <div
      aria-label="Select list is loading options"
      className="flex min-h-0 w-full flex-1 flex-col space-y-4"
    >
      {[...Array(count).keys()].map((index) => (
        <div key={index} className="flex w-full flex-row space-x-2">
          {enableMultiSelect && (
            <div aria-label="select-list-loading-skeleton-checkbox">
              <Skeleton width="1rem" height="1rem" gradient={{ width: 32 }} />
            </div>
          )}

          <div className="flex shrink-0 grow">
            <Skeleton
              gradient={{ width: 32 }}
              animationDelay={index * 100}
              height="1rem"
              width="100%"
            />
          </div>
        </div>
      ))}
    </div>
  );
}
