import { greenScale } from "colors";
import { ShieldCheckIcon } from "icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader, useModal } from "ui";
import { OnboardProvidersModal } from "../../../../../providers/ConnectProvider/components/OnboardProvidersModal";

export default function OnboardRepository({
  closeModal,
}: {
  closeModal: () => void;
}) {
  const intl = useIntl();
  const { openModal: openOnboardProvidersModal } = useModal(
    OnboardProvidersModal,
  );

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Onboard A Repository",
          id: "k5rt1L",
          description: "Onboard Success message title",
        })}
        appearance="clear"
      />
      <CardBody>
        <Card appearance="glass">
          <CardBody>
            <div className="flex flex-row">
              <div className="basis-3/4">
                <ul>
                  <li className="flex">
                    <ShieldCheckIcon
                      className="mr-1 mt-1"
                      color={greenScale[400]}
                    />
                    <FormattedMessage
                      defaultMessage="Secure IaC Templates, Secrets and CI/CD Pipelines"
                      id="mXCO5+"
                      description="Card description text"
                    />
                  </li>
                  <li className="flex">
                    <ShieldCheckIcon
                      className="mr-1 mt-1"
                      color={greenScale[400]}
                    />
                    <FormattedMessage
                      defaultMessage="Open Source Vulnerability Prevention"
                      id="5Y+2Ww"
                      description="Card description text"
                    />
                  </li>
                </ul>
              </div>
              <div className="basis-1/4">
                <Button
                  fullWidth
                  centerText
                  onClick={() => {
                    closeModal();
                    openOnboardProvidersModal();
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Continue Onboarding"
                    id="6Lrr0C"
                    description="Button label text"
                  />
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
}
