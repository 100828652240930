import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

export const Hosts = lazy(() => import("./Hosts"));

export const hostsRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "compute-workload/hosts",
  component: Hosts,
});
