import { Set, is } from "immutable";
import {
  CREDENTIALS,
  ERRORS,
  GCP_ACCOUNT_GROUP_CREATION_MODE,
  GCP_DEFAULT_EXPANDED_NODES,
  GCP_FOLDER_VIEW_CREDENTIALS_ERROR,
  GCP_LOADED_NODES,
  GCP_OU_IS_LOADING,
  GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  GCP_PROJECT_SELECT_MODE,
  GCP_SELECTED_NODES,
  GCP_SELECTED_NODES_INITIAL,
  GCP_SELECT_PROJECTS_INITIALIZED,
  GCP_TREE_MAP,
  IS_RATE_LIMIT_HIT,
  NAME,
  ORG_ID,
  ORG_NAME,
  VALUE,
} from "../../../../../../../constants";
import { type GcpSelectMonitoredProjectsInitialStateType } from "../initialState";

export const getFieldValue = ({
  field,
  state,
}: {
  field: string;
  state: GcpSelectMonitoredProjectsInitialStateType;
}) => state.getIn([field, VALUE]);

export const getFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: GcpSelectMonitoredProjectsInitialStateType;
}) => state.getIn([field, ERRORS]);

export const getOrgId = (state: GcpSelectMonitoredProjectsInitialStateType) =>
  getFieldValue({ field: ORG_ID, state });

export const getName = (state: GcpSelectMonitoredProjectsInitialStateType) =>
  getFieldValue({ field: NAME, state });

export const getOrgName = (state: GcpSelectMonitoredProjectsInitialStateType) =>
  getFieldValue({ field: ORG_NAME, state });

export const getCredentials = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: CREDENTIALS, state });

export const getGCPDefaultExpandedNodes = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_DEFAULT_EXPANDED_NODES, state });

export const getGCPAccountGroupCreationMode = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_ACCOUNT_GROUP_CREATION_MODE, state });

export const getGCPFoldersCredentialError = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_FOLDER_VIEW_CREDENTIALS_ERROR, state });

export const getGCPLoadedNodes = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_LOADED_NODES, state });

export const getGCPPreviouslySelectedNodesNotFound = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND, state });

export const getGCPProjectSelectMode = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_PROJECT_SELECT_MODE, state });

export const getGCPSelectProjectsInitialized = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_SELECT_PROJECTS_INITIALIZED, state });

export const getGCPSelectedNodes = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_SELECTED_NODES, state });

export const getGCPSelectedNodesInitial = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_SELECTED_NODES_INITIAL, state });

export const getGCPTreeMap = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_TREE_MAP, state });

export const getGCPOUSelectProjectsIsLoading = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_OU_IS_LOADING, state });

export const getGCPRootId = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => `organizations/${getOrgId(state)}`;

export const getGCPTreeRoot = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getGCPTreeMap(state).get(getGCPRootId(state));

export const getGCPNextPageToken = (
  state: GcpSelectMonitoredProjectsInitialStateType,
  { childrenType, parentId }: { childrenType: string; parentId: string },
) => getGCPTreeMap(state).getIn([parentId, "cursorToken", childrenType]);

export const getGCPAccountGroupCreationModeErrors = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldErrors({ field: GCP_ACCOUNT_GROUP_CREATION_MODE, state });

export const getGCPSelectedNodesErrors = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldErrors({ field: GCP_SELECTED_NODES, state });

export const getIsRateLimit503 = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: IS_RATE_LIMIT_HIT, state });

export const getGcpAccountGroupCreationMode = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => getFieldValue({ field: GCP_ACCOUNT_GROUP_CREATION_MODE, state });

function selectionChanged(state: GcpSelectMonitoredProjectsInitialStateType) {
  const selectionType = getGCPProjectSelectMode(state);
  const selectedNodes = getGCPSelectedNodes(state);
  const initialSelectedNodes = getGCPSelectedNodesInitial(state);
  const initialSelectionType = initialSelectedNodes?.[0]?.selectionType;

  if (!getGCPSelectProjectsInitialized(state)) {
    return false;
  }

  if (!initialSelectedNodes || !initialSelectionType) {
    return true;
  }

  if (selectionType !== initialSelectionType) {
    return true;
  }

  const initial = Set(
    initialSelectedNodes.map(
      ({ resourceId }: { resourceId: string }) => resourceId,
    ),
  );
  const current = Set(selectedNodes);

  return !is(initial, current);
}

const convertNodeToAPIPayload = (
  node: Map<string, unknown>,
  selectionType: string,
) => ({
  displayName: node.get("displayName"),
  nodeType: node.get("type"),
  resourceId: node.get("id"),
  selectionType,
});

export const getHierarchySelection = (
  state: GcpSelectMonitoredProjectsInitialStateType,
) => {
  const selectionType = getGCPProjectSelectMode(state);
  const treeMap = getGCPTreeMap(state);
  const selectedNodes = getGCPSelectedNodes(state);

  if (!selectionChanged(state)) {
    return getGCPSelectedNodesInitial(state);
  }

  if (selectionType === "ALL") {
    const rootNode = getGCPTreeRoot(state);

    if (!rootNode) return [];
    return [convertNodeToAPIPayload(rootNode, selectionType)];
  }

  return selectedNodes
    .toJS()
    .map((id: string) =>
      convertNodeToAPIPayload(treeMap.get(id), selectionType),
    );
};
