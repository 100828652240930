import { type FC, type ReactElement } from "react";

import { useTableInstance } from "../TableContext";
import { ConfigureColumns } from "./ConfigureColumns";

/**
 * Renders a button that opens a modal allowing the user to configure the columns, inlcuding hiding or showing them and changing their order.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConfigureColumns, ConnectedConfigureColumns, ConnectedTable, TableProvider } from "table";
 * ```
 */

type ConnectedConfigureColumnsProps = {
  /**
   * Triggered on modal close, provided with the visible columns in the new order.
   */
  onModalClose?: (columnIds: string[]) => void;
  /**
   * If provided, the banner will be rendered at the top of the modal.
   */
  banner?: ReactElement;
  /**
   * If true, The modal wont be able to be closed.
   */
  disableModalClosing?: boolean;
};

export const ConnectedConfigureColumns: FC<ConnectedConfigureColumnsProps> = ({
  onModalClose,
  banner,
  disableModalClosing,
}: ConnectedConfigureColumnsProps) => {
  const {
    columns,
    disableColumnOrder,
    state: { columnOrder },
    setColumnOrder,
    setHiddenColumns,
  } = useTableInstance();

  return (
    <ConfigureColumns
      columns={columns}
      columnOrder={columnOrder}
      disableColumnOrder={disableColumnOrder}
      setColumnOrder={setColumnOrder}
      setHiddenColumns={setHiddenColumns}
      onModalClose={onModalClose}
      banner={banner}
      disableModalClosing={disableModalClosing}
    />
  );
};
