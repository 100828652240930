import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { TruncateText } from "../TruncateText";

export const sizeVariants = {
  sm: "text-xs",
  md: "text-sm",
  lg: "text-base",
  xl: "text-lg",
};

export const bodyAppearanceVariants = {
  disabled: "text-disabled dark:text-dark-bg-disabled",
  link: "text-link dark:text-dark-bg-link hover:underline hover:cursor-pointer",
  primary: "text-default dark:text-dark-bg",
  secondary: "text-secondary dark:text-dark-bg-secondary",
  error: "text-red dark:text-dark-bg-red",
};

export type BodyProps<T = Omit<ComponentPropsWithoutRef<"p">, "className">> =
  T & {
    /**
     * Add a class name to the element.
     */
    addClassName?: string;
    /**
     * The appearance of the text.
     */
    appearance?: keyof typeof bodyAppearanceVariants;
    children: ReactNode;
    /**
     * The type of element to render.
     *
     * By default, `Body` renders a `p` element, but any element type can be used.
     */
    as?: ElementType;
    /**
     * The font size.
     */
    size?: keyof typeof sizeVariants;
    /**
     * Truncate text when there is no room.
     *
     * This will add a flex class to the Body, so it will no longer wrap.
     */
    truncate?: boolean;
  };

/**
 * Renders text content in a `p` element by default.
 *
 * Link, `Bold`, and `Italic` components can be used within the text content.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Body, Bold, Italic } from "ui";
 * ```
 */
export function Body<T = Omit<ComponentPropsWithoutRef<"p">, "className">>({
  addClassName,
  appearance = "primary",
  children,
  as: Component = "p",
  size = "md",
  truncate = false,
  ...passThrough
}: BodyProps<T>): ReactElement {
  const className = classNames(
    bodyAppearanceVariants[appearance],
    sizeVariants[size],
    truncate && "flex",
    addClassName,
  );

  return (
    <Component {...passThrough} className={className}>
      {truncate ? <TruncateText>{children}</TruncateText> : children}
    </Component>
  );
}
