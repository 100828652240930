import { type PermissionsStatusComponent } from "requests";
import { type RowInstance } from "table";
import { Tooltip, TruncateText } from "ui";

type RemediationProps = {
  row: Pick<RowInstance<PermissionsStatusComponent>, "original">;
};

export default function Remediation({ row }: RemediationProps) {
  const { remediation } = row.original;

  return (
    <Tooltip label={remediation}>
      <>
        <TruncateText>{remediation}</TruncateText>
      </>
    </Tooltip>
  );
}
