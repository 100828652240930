import { useCallback } from "react";
import { type TablePreferencesType } from "requests";
import { actions, type ActionType, type Data, type TableOptions } from "table";

export const useStateReducerWithResetPagination = <D extends Data>() => {
  /**
   * When a specified action occurs, set the page back to 0.
   */
  const stateReducer: TableOptions<D>["stateReducer"] = useCallback(
    (newState: TablePreferencesType, action: ActionType) => {
      switch (action.type) {
        case actions.clearSortBy:
        case actions.resetGlobalFilter:
        case actions.resetGroupBy:
        case actions.resetSortBy:
        case actions.setGlobalFilter:
        case actions.setGroupBy:
        case actions.setSortBy:
        case actions.toggleGroupBy:
        case actions.toggleSortBy:
          return { ...newState, pageIndex: 0 };
        default:
          return newState;
      }
    },
    [],
  );

  return { stateReducer };
};
