import { CircleCheckIcon, ClockIcon, DeleteIcon, RemoveIcon } from "icons";

import { type ReactNode } from "react";
import { type requestStatuses } from "../requests/messages";

export const StatusIcon = ({
  status,
  addClassName,
}: {
  status: keyof typeof requestStatuses;
  addClassName?: string;
}) => {
  const icon = (): ReactNode => {
    switch (status) {
      case "WAITING_APPROVAL":
        return <ClockIcon color="orange" size="sm" />;
      case "READY_FOR_ACTIVATION":
        return <CircleCheckIcon color="blue" size="sm" />;
      case "DENIED":
        return <RemoveIcon color="red" size="sm" />;
      case "DELETED_BY_JIT_ADMIN":
      case "DELETED_IN_AWS":
        return <DeleteIcon size="sm" />;
      case "APPROVED":
        return <CircleCheckIcon color="green" size="sm" />;
      case "INACTIVE":
        return <RemoveIcon color="gray" size="sm" />;
      default:
        return <></>;
    }
  };

  return <span className={addClassName}>{icon()}</span>;
};
