import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const GcpGoogleCsccIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "GCP Google CSCC",
    id: "hx4f+a",

    description: "GCP Google CSCC icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M33.7394 11.75H66.7104C69.5259 11.75 72.1342 13.2301 73.5782 15.6472L91.7829 46.1202C93.301 48.6614 93.2918 51.8331 91.7591 54.3655L73.5849 84.3924C72.1355 86.787 69.5399 88.25 66.7408 88.25H33.7088C30.937 88.25 28.3627 86.8152 26.9048 84.4578L8.33524 54.4304C6.74944 51.8661 6.74 48.6281 8.31082 46.0546L26.911 15.582C28.3638 13.2019 30.951 11.75 33.7394 11.75Z"
        className="fill-[#4285F4] dark:fill-white"
      />
      <path
        d="M53.3818 37.3262C55.5369 37.886 57.5032 39.0112 59.0776 40.5856C60.652 42.16 61.7773 44.1264 62.337 46.2814H70.7497V33.4691L53.3818 25.75V37.3262Z"
        fill="white"
      />
      <path
        d="M38.1627 46.2814C38.7224 44.1264 39.8477 42.16 41.4221 40.5856C42.9965 39.0112 44.9629 37.886 47.118 37.3262V25.75L29.75 33.4691V46.2814H38.1627Z"
        fill="white"
      />
      <path
        d="M47.1182 61.5005C44.9632 60.9408 42.9968 59.8156 41.4224 58.2412C39.848 56.6668 38.7227 54.7004 38.163 52.5454H30.2876C32.13 61.8949 38.5909 70.0777 47.1182 73.466V61.5005Z"
        fill="white"
      />
      <path
        d="M62.337 52.5454C61.7773 54.7004 60.652 56.6668 59.0776 58.2412C57.5032 59.8156 55.5369 60.9408 53.3818 61.5005V73.466C61.9089 70.0777 68.37 61.8949 70.2125 52.5454H62.337Z"
        fill="white"
      />
      <path
        d="M50.2502 55.6772C53.7096 55.6772 56.5141 52.8727 56.5141 49.4133C56.5141 45.9538 53.7096 43.1494 50.2502 43.1494C46.7908 43.1494 43.9863 45.9538 43.9863 49.4133C43.9863 52.8727 46.7908 55.6772 50.2502 55.6772Z"
        fill="white"
      />
    </SvgIcon>
  );
};
