import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Card, CardHeader } from "ui";
import { PermissionGroupCardBody } from "../PermissionGroupCardBody";
import { type PermissionGroupMap } from "../PermissionGroupsSidecar";

type AssetInventoryCardProps = {
  featureMap: PermissionGroupMap;
};

export const assetInventoryFeatureKeys = ["assetInventoryOverview"] as const;

export function AssetInventoryCard({ featureMap }: AssetInventoryCardProps) {
  const intl = useIntl();

  const features = useMemo(
    () =>
      assetInventoryFeatureKeys
        .filter((key) => !!featureMap[key])
        .map((key) => featureMap[key]),
    [featureMap],
  );

  if (!features.length) return null;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Asset Inventory",
          id: "n1TSfU",
          description: "Permission Group sidecar card header title",
        })}
      />
      <PermissionGroupCardBody features={features} />
    </Card>
  );
}
