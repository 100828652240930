import {
  Body,
  RadioCard as DisabledRadioCard,
  RadioGroup as DisabledRadioGroup,
  Title,
} from "ui";

import { RadioGroup, useWatch } from "form";

import { FormattedMessage, useIntl } from "react-intl";
import { AZURE, AZURE_GOV } from "../../../../../constants";

type DeploymentTypePropTypes = {
  isEdit: boolean;
};

export default function DeploymentType({ isEdit }: DeploymentTypePropTypes) {
  const intl = useIntl();
  const addClassName =
    "flex space-x-2 [&>label]:flex-1 space-y-0 [&>label]:relative [&>label>div]:h-full [&>label>div>div:last-child>p]:items-start";

  const value = useWatch({ name: "environmentType" });

  const title = intl.formatMessage({
    defaultMessage: "Deployment Type",
    id: "GL5eu1",
    description: "Section header for Deployment Type",
  });

  const deploymentTypeItems = [
    {
      title: intl.formatMessage({
        defaultMessage: "Commercial",
        id: "w9hdXC",
        description: "Option text for environment type select",
      }),
      value: AZURE,
      description: (
        <Body size="sm" as="span">
          <FormattedMessage
            defaultMessage="Secure your Commercial account used by non-government businesses."
            id="naXi29"
            description="Description for cloud type for onboarding an Azure account"
          />
        </Body>
      ),
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Government",
        id: "7fFlCV",
        description: "Option text for environment type select",
      }),
      value: AZURE_GOV,
      description: (
        <Body size="sm" as="span">
          <FormattedMessage
            defaultMessage="Secure your GovCloud account used by US government agencies or their partners."
            id="Faknx0"
            description="Description for cloud type for onboarding an Azure account"
          />
        </Body>
      ),
    },
  ];

  return (
    <section className="space-y-2">
      <Title level={5} size="xxs">
        {title}
      </Title>
      <div className="-ml-2">
        {isEdit ? (
          <DisabledRadioGroup
            name="environmentType"
            value={value}
            addClassName={addClassName}
            style={{ flexDirection: "row" }}
          >
            {deploymentTypeItems?.map(({ description, title, value }) => (
              <DisabledRadioCard
                key={value}
                title={title}
                description={description}
                value={value}
                disabled
              />
            ))}
          </DisabledRadioGroup>
        ) : (
          <RadioGroup
            label={title}
            addClassName={addClassName}
            disabled={isEdit}
            items={deploymentTypeItems}
            name="environmentType"
            register={{
              required: true,
            }}
            showLabel={false}
            style={{ flexDirection: "row" }}
          />
        )}
      </div>
    </section>
  );
}
