import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const PythonIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Python",
    id: "P7aHfF",
    description: "Python alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <path
        d="M11.108 0h1.64c.011.03.038.021.06.022.517.021 1.033.077 1.543.166.76.133 1.491.35 2.156.753.365.22.69.49.935.844.302.434.422.912.419 1.444-.014 1.724-.005 3.45-.005 5.174.003.281-.02.562-.07.839-.113.587-.36 1.102-.804 1.511-.664.613-1.473.873-2.351.882-1.874.02-3.749.007-5.623.005-.289 0-.577.005-.864.047-.879.13-1.602.523-2.095 1.28-.405.621-.585 1.315-.592 2.05-.008.931-.003 1.863 0 2.793 0 .099-.027.123-.123.122-.636-.004-1.273 0-1.91-.003-.564-.002-1.067-.192-1.522-.517-.562-.4-.943-.944-1.224-1.565-.386-.853-.55-1.758-.631-2.683-.014-.156-.004-.315-.047-.469v-1.43c.03-.01.022-.037.023-.059A9.179 9.179 0 0 1 .24 9.608c.191-.826.498-1.597 1.067-2.245.43-.49.953-.83 1.58-1.009.365-.104.733-.123 1.11-.121 1.753.008 3.507.003 5.26.003.898 0 1.796 0 2.694.002.088 0 .123-.016.119-.114a6.404 6.404 0 0 1 0-.586c.005-.11-.03-.134-.136-.134-1.858.003-3.717.002-5.575.005-.103 0-.13-.024-.13-.128.003-.863.01-1.727-.008-2.59-.007-.454.138-.84.417-1.183.329-.403.757-.668 1.228-.87C8.752.264 9.689.12 10.64.047c.156-.012.315-.002.468-.046ZM8.731 3.948a1.04 1.04 0 0 0 1.057-1.046A1.044 1.044 0 0 0 8.733 1.85c-.567 0-1.04.473-1.044 1.044A1.05 1.05 0 0 0 8.73 3.948Z"
        fill="#1D73A7"
      />
      <path
        d="M16.338 17.766c-1.398 0-2.796 0-4.194-.002-.104 0-.132.026-.127.128.009.192.01.384 0 .575-.006.109.032.126.13.126 1.84-.003 3.679-.004 5.518-.002.19 0 .188 0 .19.191.003.883.011 1.767.008 2.65 0 .314-.11.6-.28.867-.328.517-.819.822-1.36 1.06-.623.273-1.282.413-1.951.508a15.67 15.67 0 0 1-2.228.133 13.16 13.16 0 0 1-1.654-.088c-.79-.1-1.57-.251-2.3-.583-.67-.303-1.248-.71-1.605-1.377-.198-.37-.263-.765-.258-1.186.013-1.118.005-2.237.005-3.355 0-.653-.004-1.307.002-1.96.005-.587.117-1.148.425-1.657.395-.652.99-1.034 1.702-1.255.4-.122.815-.182 1.233-.179 1.882.003 3.764.003 5.646 0 .57 0 1.127-.08 1.649-.327.748-.352 1.223-.95 1.5-1.715.167-.472.249-.97.242-1.472-.006-.884 0-1.768-.004-2.652 0-.095.022-.125.121-.125.688.007 1.375-.006 2.062.012.552.014 1.036.239 1.467.574.672.522 1.065 1.232 1.337 2.021.188.544.298 1.106.372 1.676.005.047.007.094.005.141 0 .81-.012 1.62.005 2.429a6.045 6.045 0 0 1-.217 1.737c-.176.645-.428 1.256-.844 1.787-.683.872-1.57 1.328-2.687 1.325-1.304-.004-2.608 0-3.912 0l.002-.005Zm-.993 4.382c.538 0 1.056-.412 1.048-1.061-.008-.592-.446-1.027-1.05-1.038-.56-.011-1.115.444-1.042 1.18.048.47.437.916 1.044.92Z"
        fill="#FED100"
      />
    </SvgIcon>
  );
};
