/* c8 ignore start */
import { type ReactNode } from "react";
import { type IntlShape } from "react-intl";
import { Bold, Italic } from "ui";

export const getInstructions = ({
  intl,
  isEdit,
  showMemberIAMRoleSteps,
  initUnifiedCftDisabled,
}: {
  intl: IntlShape;
  isEdit: boolean;
  showMemberIAMRoleSteps: boolean;
  initUnifiedCftDisabled: boolean;
}) => {
  const values = {
    b: (chunks: ReactNode) => <Bold>{chunks}</Bold>,
  };

  if (showMemberIAMRoleSteps) {
    return [
      intl.formatMessage({
        defaultMessage: "Download Member IAM Role CFT",
        id: "wWjnsV",
        description: "Instructions for creating Member iam role cft",
      }),

      ...(isEdit && initUnifiedCftDisabled
        ? [
            intl.formatMessage(
              {
                defaultMessage:
                  "Login to your <b>AWS Master Account</b> using <i>AWS console</i> and go to <i>Services > CloudFormation > StackSets</i> & click previously created StackSet",
                id: "nDe6af",
                description: "Instructions for creating Member iam role cft",
              },
              {
                ...values,
                i: (chunks) => <Italic>{chunks}</Italic>,
              },
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Click <b>Actions</b> and select <b>Edit StackSet details</b>.",
                id: "cU9LhJ",
                description: "Instructions for creating Member iam role cft",
              },
              values,
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Choose <b>Replace current template file</b> and upload the template and click <i>Next</i>.",
                id: "aIoL1/",
                description: "Instructions for creating Member iam role cft",
              },
              {
                ...values,
                i: (chunks) => <Italic>{chunks}</Italic>,
              },
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Click <i>Next</i> and give <b>AWS OU ID</b> in <b>Organizational units (OUs)</b>.",
                id: "Bx8b4j",
                description: "Instructions for creating Member iam role cft",
              },
              {
                ...values,
                i: (chunks) => <Italic>{chunks}</Italic>,
              },
            ),
          ]
        : [
            intl.formatMessage(
              {
                defaultMessage:
                  "Login to your <b>AWS Master Account</b> using <i>AWS console</i> and go to <i>Services > CloudFormation > StackSets</i> & click Create StackSet",
                id: "eQkrIh",
                description: "Instructions for creating Member iam role cft",
              },
              {
                ...values,
                i: (chunks) => <Italic>{chunks}</Italic>,
              },
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Choose <b>Upload a template</b> file and upload the template, click Next and provide a StackSet name of your choice In <b>Parameters</b>, enter values for <b>PrismaCloudRoleName</b>.",
                id: "a5eAr+",
                description: "Instructions for creating Member iam role cft",
              },
              values,
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Click Next and select <b>Inactive</b> in <b>Execution configuration</b>.",
                id: "I6yOyU",
                description: "Instructions for creating Member iam role cft",
              },
              values,
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Click Next and select <b>Deploy to organization</b> under <b>Deployment targets</b>",
                id: "Gmtnb+",
                description: "Instructions for creating Member iam role cft",
              },
              values,
            ),
            intl.formatMessage(
              {
                defaultMessage:
                  "Set <b>Automatic deployment to Activated</b> and <b>Account removal behavior to Delete stacks</b>.",
                id: "AwkUV+",
                description: "Instructions for creating Member iam role cft",
              },
              values,
            ),
          ]),
      intl.formatMessage(
        {
          defaultMessage: "Select any one region under <b>Specify regions</b>",
          id: "8Gpo9k",
          description: "Instructions for creating Member iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "In <b>Deployment Options</b>, under <b>Maximum concurrent accounts</b>, select <b>Percentage</b> and set it to <b>100</b>",
          id: "RH7wJi",
          description: "Instructions for creating Member iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "In <b>Deployment Options</b>, under <b>Failure tolerance</b>, select <b>Percentage</b> and set it to <b>100</b>",
          id: "VC8XVm",
          description: "Instructions for creating Member iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage: "Click <i>Next</i>, review the configuration",
          id: "wydtlI",
          description: "Instructions for creating Member iam role cft",
        },
        {
          i: (chunks: ReactNode) => <Italic>{chunks}</Italic>,
        },
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and click <b>Submit</b>",
          id: "xi9qxd",
          description: "Instructions for creating Member iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "When status is <b>SUCCEEDED</b>, copy values for <b>PrismaCloudRoleName</b> from Parameters tab and paste it in <b>Member IAM Role Name</b> text box.",
          id: "fV3s3b",
          description: "Instructions for creating Member iam role cft",
        },
        values,
      ),
    ].filter(Boolean);
  } else {
    const steps = [
      intl.formatMessage({
        defaultMessage: "Download Management IAM Role CFT",
        id: "KALExT",
        description: "Instructions for creating Management iam role cft",
      }),
      intl.formatMessage(
        {
          defaultMessage:
            "Login to your <b>AWS Master Account</b> using AWS console and go to <b>Select Services > CloudFormation > Stacks</b>.",
          id: "OiFocj",
          description: "Instructions for creating Management iam role cft",
        },
        values,
      ),
    ];

    if (isEdit) {
      return [
        ...steps,
        intl.formatMessage(
          {
            defaultMessage:
              "Select previously created Stack and click <b>Update</b> Stack.",
            id: "SUg35v",
            description: "Instructions for creating Management iam role cft",
          },
          values,
        ),
        intl.formatMessage(
          {
            defaultMessage:
              "Replace the current template with the downloaded template.",
            id: "itUKir",
            description: "Instructions for creating Management iam role cft",
          },
          values,
        ),
        intl.formatMessage(
          {
            defaultMessage:
              "Click <b>Next</b>, Verify the settings and preview your changes",
            id: "S8KvqF",
            description: "Instructions for creating Management iam role cft",
          },
          values,
        ),
        intl.formatMessage(
          {
            defaultMessage:
              "Select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and click <b>Update</b> stack.",
            id: "aA4oc2",
            description: "Instructions for creating Management iam role cft",
          },
          values,
        ),
      ];
    }

    return [
      ...steps,
      intl.formatMessage(
        {
          defaultMessage:
            "Click on <b>Create Stack</b> and select <b>With new resources</b>.",
          id: "cPRp28",
          description: "Instructions for creating Management iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Choose <b>Upload a template file</b> and upload the IAM Role CFT file, click <b>Next</b> and provide a Stack name of your choice.",
          id: "cMPvRW",
          description: "Instructions for creating Management iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage: "Click <b>Next</b>.",
          id: "cFDCCH",
          description: "Instructions for creating Management iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and click <b>Create stack</b>",
          id: "aleYx+",
          description: "Instructions for creating Management iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Once the <b>CREATE_COMPLETE</b> status is displayed, copy <b>PrismaCloudRoleARN</b> from the Outputs tab and paste it in <b>Management IAM Role ARN</b> text box.",
          id: "JYdUpl",
          description: "Instructions for creating Management iam role cft",
        },
        values,
      ),
    ];
  }
};
