import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsServiceNowIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Service Now",
    id: "tfl+hJ",

    description: "Service Now icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2749 13.9697C27.9 13.9697 10 32.3172 10 54.6921C10 65.8796 14.475 76.1721 22.53 83.7795C25.215 86.4645 29.6899 86.912 32.8224 84.227C43.1149 76.6196 56.9874 76.6196 67.2798 84.227C70.4123 86.912 74.8873 86.4645 77.5723 83.7795C93.6823 68.1171 94.1298 42.6096 78.9148 26.4997C71.3073 18.4447 61.0149 13.9697 50.2749 13.9697ZM49.8274 74.3821C39.0874 74.8296 30.1374 66.3271 29.6899 55.5871C29.6899 55.1396 29.6899 54.6921 29.6899 54.2446C29.2424 43.0571 37.7449 33.6597 48.9324 33.6597C60.1199 33.6597 69.5173 42.1621 69.5173 52.9021C69.5173 53.3496 69.5173 53.7971 69.5173 54.2446C69.9648 64.9846 61.4624 73.9346 50.2749 74.3821C50.2749 74.3821 50.2749 74.3821 49.8274 74.3821Z"
        className="fill-[#81B5A1] dark:fill-white"
      />
    </SvgIcon>
  );
};
