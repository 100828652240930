/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

export function useContinuousFetchInfiniteQuery<
  Data,
  Key extends QueryKey,
  Opts,
>({
  autoFetch = false,
  enabled,
  getNextPageParam,
  queryFn,
  queryKey,
  queryOptions,
}: {
  autoFetch?: boolean;
  enabled: boolean;
  getNextPageParam: (lastGroup: Data) => string | null | undefined;
  queryFn: (params: {
    queryKey: Key;
    pageParam?: string;
    signal: any;
    meta: any;
  }) => Promise<Data>;
  queryKey: Key;
  queryOptions?: Opts;
}) {
  const queryReturn = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled,
    ...queryOptions,
    getNextPageParam,
    initialPageParam: "",
  });

  const nextPageToken = useMemo(() => {
    const { pages } = (queryReturn?.data ?? {
      pages: [],
    }) as InfiniteData<Data>;
    const numPages = pages.length;
    const nextPageToken = pages.length
      ? getNextPageParam(pages[numPages - 1])
      : null;
    return nextPageToken;
  }, [getNextPageParam, queryReturn?.data]);

  useEffect(() => {
    autoFetch && nextPageToken && queryReturn.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFetch, nextPageToken]);

  return {
    ...queryReturn,
    isFetchingNextPage:
      queryReturn.isFetchingNextPage || (autoFetch && queryReturn.hasNextPage),
  } as UseInfiniteQueryResult<Data>;
}
