import {
  DlpStatusResponseSchema,
  FeatureStatusResponseSchema,
  TenantEntitlementsSchema,
  TenantFeaturesResponseSchema,
  UpdateTenantFeatureResponseSchema,
  UpdateTenantFeatureSchema,
  type GetTenantFeaturesSchema,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";

export const tenantKeys = {
  all: [{ scope: "tenant" }] as const,
  features: ({
    customerName,
    prismaId,
    features,
  }: z.infer<typeof GetTenantFeaturesSchema>) =>
    [
      {
        ...tenantKeys.all[0],
        entity: "features",
        customerName,
        prismaId,
        features,
      },
    ] as const,
  dlpStatus: () => [{ ...tenantKeys.all[0], entity: "dlpStatus" }] as const,
  updateFeatures: ({
    customer_name = "",
    provision_status_list = [],
  }: z.infer<typeof UpdateTenantFeatureSchema>) =>
    [
      {
        ...tenantKeys.all[0],
        entity: "updateFeatures",
        customer_name,
        provision_status_list,
      },
    ] as const,
  featureStatus: [{ scope: "featureStatus" }] as const,
  entitlements: ({ prismaId }: { prismaId: string }) =>
    [{ ...tenantKeys.all[0], prismaId }] as const,
} as const;

export const getTenantFeatures = async ({
  queryKey: [{ customerName, prismaId, features }],
}: QueryFunctionContext<ReturnType<(typeof tenantKeys)["features"]>>) => {
  const params: Record<string, string> = {};
  if (customerName) {
    params.customer_name = customerName;
  }
  if (features) {
    params.features = features;
  }
  return jsonApi({
    params,
    path: `/api/v1/provision/tenants/${prismaId}/features`,
    requestSchema: z.void(),
    responseSchema: TenantFeaturesResponseSchema,
  });
};

export const getFeatureStatus = async () => {
  return jsonApi({
    path: "feature/status/aws",
    requestSchema: z.void(),
    responseSchema: TenantFeaturesResponseSchema,
  });
};

type UpdateTenantFeature = z.infer<typeof UpdateTenantFeatureSchema> & {
  prismaId: string;
};

export const getEntitlements = async ({
  queryKey: [{ prismaId }],
}: QueryFunctionContext<ReturnType<(typeof tenantKeys)["entitlements"]>>) => {
  return jsonApi({
    path: `/api/v1/tenant/${prismaId}/entitlement`,
    requestSchema: z.void(),
    responseSchema: TenantEntitlementsSchema,
  });
};
export const updateTenantFeatures = async ({
  customer_name,
  prismaId,
  provision_status_list,
}: UpdateTenantFeature) => {
  return jsonApi({
    path: `api/v1/provision/tenants/${prismaId}/features`,
    body: { customer_name, provision_status_list },
    config: {
      method: "PATCH",
    },
    requestSchema: UpdateTenantFeatureSchema,
    responseSchema: UpdateTenantFeatureResponseSchema,
  });
};

export const getDlpStatus = async (
  _props: QueryFunctionContext<ReturnType<(typeof tenantKeys)["dlpStatus"]>>,
) => {
  return jsonApi({
    path: "/api/v1/provision/dlp/status",
    requestSchema: z.void(),
    responseSchema: DlpStatusResponseSchema,
  });
};

export const updateSupportTenantsFeatures = async ({
  customer_name,
  provision_status_list,
}: UpdateTenantFeature) => {
  return jsonApi({
    path: "/api/v1/provision/_support/tenants/features",
    body: { customer_name, provision_status_list },
    config: {
      method: "PATCH",
    },
    requestSchema: UpdateTenantFeatureSchema,
    responseSchema: FeatureStatusResponseSchema,
  });
};
