import { type ReactNode } from "react";
import { type CloudAccountFeature } from "requests";
import { type AccountType, type CloudType } from "../../../../../types";
import {
  AgentlessScan,
  AutoProtect,
  ServerlessScan,
  WorkloadDiscovery,
} from "../../FeatureCards";

type CloudFeaturesComponentProps = {
  accountType: AccountType;
  accountId: string;
  cloudType: CloudType;
  children: ReactNode;
  features: CloudAccountFeature[];
  isAccountImported: boolean;
};

export default function CloudFeaturesComponent({
  accountType,
  accountId,
  cloudType,
  children,
  features = [],
  isAccountImported,
}: CloudFeaturesComponentProps) {
  const getFeatureProps = ({
    featureName,
    featureState,
  }: {
    featureName: string;
    featureState: string;
  }) => ({
    accountType,
    accountId,
    cloudType,
    featureKey: featureName,
    featureState,
    isAccountImported,
    key: featureName,
  });

  return (
    <div className="space-y-4">
      {children}
      {features.map(({ featureName, featureState }) => {
        if (featureName === "compute-discovery")
          return (
            <WorkloadDiscovery
              {...getFeatureProps({ featureName, featureState })}
            />
          );
        else if (featureName === "compute-agentless")
          return (
            <AgentlessScan
              {...getFeatureProps({ featureName, featureState })}
            />
          );
        else if (featureName === "compute-serverless-scan")
          return (
            <ServerlessScan
              {...getFeatureProps({ featureName, featureState })}
            />
          );
        else if (featureName === "compute-auto-protect")
          return (
            <AutoProtect {...getFeatureProps({ featureName, featureState })} />
          );
        else return null;
      })}
    </div>
  );
}
