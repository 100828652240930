import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

import { useIntl } from "react-intl";

export const IntegrationsWebhookIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Webhook",
    id: "FUGqRy",

    description: "Webhook icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M47.3415 44.004C44.2364 49.7708 40.6876 55.0939 37.5824 60.4171C36.6952 61.7479 36.2516 63.0787 37.1388 64.8531C38.9132 69.7326 36.2516 74.1686 31.372 75.4994C26.9361 76.8302 22.5001 73.725 21.6129 68.8454C20.7257 64.4095 24.2745 60.4171 29.1541 59.5299C29.5977 59.5299 30.0413 59.5299 30.4849 59.5299L37.5824 47.1092C33.1464 42.6732 30.4849 37.3501 30.9285 30.6961C31.372 25.8165 33.1464 21.8242 36.6952 18.719C42.9056 12.5086 53.1083 11.1779 60.6494 16.0574C67.747 20.937 71.2957 29.8089 68.1906 37.7937C65.9726 37.3501 63.7546 36.4629 61.5366 36.0193C62.4238 31.5833 61.9802 28.0345 58.875 24.4858C57.1007 22.2678 54.4391 20.937 51.7775 20.4934C46.0107 19.1626 40.6876 23.155 38.9132 28.4781C37.1388 34.6885 39.8004 40.0116 47.3415 44.004Z"
        className="fill-[#C73A63] dark:fill-white"
      />
      <path
        d="M57.1007 37.3477C59.3186 41.3401 61.9802 45.776 64.1982 49.7684C76.1753 46.2196 85.0472 52.8736 88.1524 59.5275C92.1448 67.9559 89.4832 78.1586 81.9421 83.4817C73.9573 88.8049 64.1982 87.9177 57.5443 80.8202C59.3186 79.4894 61.093 77.715 62.8674 76.3842C69.5214 80.8202 75.7317 80.8202 80.1677 75.497C84.1601 71.061 83.7165 64.4071 80.1677 59.9711C75.7317 55.0916 69.5214 54.648 62.4238 59.5275C59.3186 54.2044 56.2135 48.8812 53.5519 43.558C52.6647 41.7837 51.3339 40.8965 49.1159 40.4529C44.68 39.5657 42.462 36.4605 42.0184 32.9117C42.0184 29.3629 43.7928 26.2578 47.3415 24.927C50.4467 23.5962 54.4391 24.4834 56.6571 27.5886C58.4314 29.8065 58.875 32.4681 57.9879 35.1297C57.5443 35.5733 57.5443 36.4605 57.1007 37.3477Z"
        className="fill-[#4B4B4B] dark:fill-white"
      />
      <path
        d="M62.4238 70.6203H48.2287C46.8979 76.387 43.7927 80.823 38.9132 83.4846C34.9208 85.7025 30.4848 86.5897 26.0489 85.7025C17.6205 84.3718 10.9666 76.8306 10.0794 68.4023C9.19221 58.6432 15.8462 50.2148 24.7181 48.4404C25.1617 50.6584 26.0489 52.8764 26.4925 55.0944C18.9513 59.5303 15.8462 64.8535 18.0641 71.0639C19.8385 76.8306 25.6053 79.9358 31.8156 78.605C38.026 77.2742 41.1312 71.9511 40.6876 63.9663C46.4543 63.9663 52.6647 63.9663 58.4314 63.9663C60.6494 63.9663 62.4238 63.9663 64.1982 61.7483C66.8598 58.1996 72.1829 58.6432 75.2881 61.7483C78.3933 64.8535 78.3933 70.1767 74.8445 73.2818C71.7393 76.387 66.4162 75.9434 63.311 72.8382C63.7546 72.3947 63.311 71.5075 62.4238 70.6203Z"
        className="fill-[#4A4A4A] dark:fill-white"
      />
    </SvgIcon>
  );
};
