import { useEffect } from "react";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";

import { snakeCase } from "utils";
import {
  CLIENT_EMAIL,
  EXTERNAL_ACCOUNT,
  GCP_WIF_POOL_LOCATION,
  PROJECT_ID,
  SERVICE_ACCOUNT,
  TYPE,
} from "../../../../../../../../constants";
import { useGCPCredentialsFileContext } from "../../../../../context/GcpCredentialsFileContext";
import { setProjectIdWif } from "../../../../../context/GcpCredentialsFileContext/state/actions";
import {
  getExternalAccountKey,
  getExternalAccountKeyName,
} from "../../../../../context/GcpCredentialsFileContext/state/getters";
import { jsonFileKeys } from "../../messages";
import FileUploadDetails from "../FileUploadDetails";
import List from "../List";

type CognitoBasedExternalAccountFileInfoProps = {
  hasErrors: boolean;
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function CognitoBasedExternalAccountFileInfo({
  hasErrors,
  handleClose,
}: CognitoBasedExternalAccountFileInfoProps) {
  const intl = useIntl();

  const { dispatch, state } = useGCPCredentialsFileContext();

  const json = getExternalAccountKey(state);
  const name = getExternalAccountKeyName(state);

  const audience =
    !!json &&
    json[TYPE] === EXTERNAL_ACCOUNT &&
    (json["audience"] as string).match(
      /\/\/iam.googleapis.com\/projects\/(.+?)\/locations\/global\/workloadIdentityPools\/?(.*)/,
    );

  const projectId = audience && audience[1];

  useEffect(() => {
    projectId && dispatch(setProjectIdWif({ projectId }));
  }, [projectId, dispatch]);

  if (
    hasErrors ||
    isEmpty(json) ||
    (json[TYPE] && json[TYPE] !== EXTERNAL_ACCOUNT)
  )
    return null;

  const clientEmail = json["client_email"];
  const serviceAccount = clientEmail && clientEmail.match(/^(.+)@/)[1];
  const wifPoolLocation = audience && audience[2];

  const jsonFileValues = {
    [snakeCase(PROJECT_ID)]: projectId,
    [SERVICE_ACCOUNT]: serviceAccount,
    [CLIENT_EMAIL]: clientEmail,
    [GCP_WIF_POOL_LOCATION]: wifPoolLocation,
  };

  const info = [
    PROJECT_ID,
    SERVICE_ACCOUNT,
    CLIENT_EMAIL,
    GCP_WIF_POOL_LOCATION,
  ]
    .map(snakeCase)
    .map((key, index) => (
      <List
        key={index}
        index={index}
        label={intl.formatMessage(
          jsonFileKeys[key as keyof typeof jsonFileKeys],
        )}
        value={jsonFileValues[key]}
      />
    ));

  return (
    <FileUploadDetails
      handleClose={handleClose}
      isServiceAccount={false}
      name={name}
    >
      {info}
    </FileUploadDetails>
  );
}
