import { forwardRef, useEffect, useMemo, useRef } from "react";
import { classNames } from "utils";
import { mergeRefs } from "../../utils";
import { CopyButton } from "../Button";
import { useTextareaAutosize } from "./useTextareaAutosize";

import { type ChangeEventHandler, type ComponentPropsWithoutRef } from "react";

export type TextareaProps = {
  /**
   * Add a className to the wrapper
   */
  addClassName?: string;
  allowCopy?: boolean;
  /**
   * Specifies the appearance of the text area.
   */
  appearance?: "default" | "success" | "error";
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  /**
   * The contents of the textarea is read only and cannot be edited
   */
  readOnly?: boolean;
  /**
   * The max number of rows the Textarea can grow to
   */
  rowsMax?: number;
  /**
   * The minimum number of rows the Textarea will have
   */
  rowsMin?: number;
  value?: string;
} & Omit<
  ComponentPropsWithoutRef<"textarea">,
  "className" | "onChange" | "style" | "value"
>;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      allowCopy = false,
      addClassName,
      appearance,
      disabled,
      onChange,
      readOnly,
      rowsMax,
      rowsMin = 1,
      value,
      ...passThrough
    }: TextareaProps,
    userRef,
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { current: isControlled } = useRef(value != null);
    const ref = useMemo(() => mergeRefs([textareaRef, userRef]), [userRef]);

    const borderColor =
      appearance === "success"
        ? "border-green-700"
        : appearance === "error"
          ? "border-red-700"
          : readOnly
            ? "border-gray-100"
            : "border-gray-300";

    const rootClassName = classNames(
      "flex rounded border p-2 focus-within:ring dark:border-blue-steel-850",
      borderColor,
      readOnly
        ? "bg-gray-100 dark:bg-gray-900"
        : "bg-white dark:bg-blue-steel-950",
      addClassName,
    );
    // TODO fix placeholder style
    // eslint-disable-next-line tailwindcss/no-custom-classname
    const textareaClassName = classNames(
      "flex-auto bg-transparent text-xs placeholder:text-gray-300 focus:outline-none dark:placeholder:text-gray-600",
      "resize-none",
      borderColor,
      disabled && "cursor-not-allowed text-disabled dark:text-dark-bg-disabled",
    );

    const { height, shadowEl, syncHeight } = useTextareaAutosize({
      className: textareaClassName,
      rowsMax,
      rowsMin,
      textareaRef,
    });

    useEffect(() => {
      syncHeight();
    }, [syncHeight, value]);

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      if (!isControlled) {
        syncHeight();
      }

      if (onChange) {
        onChange(event);
      }
    };

    return (
      <span
        className={rootClassName}
        style={{
          paddingBottom: "0.4375rem",
          paddingTop: "0.4375rem",
        }}
      >
        <textarea
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          rows={rowsMin}
          {...passThrough}
          className={textareaClassName}
          onChange={handleChange}
          style={{ height }}
          value={value}
        />
        {allowCopy && (
          <CopyButton
            addClassName="p-0.5"
            style={{ marginRight: "-0.125rem" }}
            text={() => textareaRef.current?.value as string}
          />
        )}
        {shadowEl}
      </span>
    );
  },
);
Textarea.displayName = "Textarea";
