import {
  getPermissionGroups,
  getUserRole,
  permissionGroupsKeys,
  rolesKeys,
  type PermissionGroupData,
} from "requests";
import {
  ConnectedConfigureColumns,
  ConnectedDownloadButton,
  ConnectedTable,
  ConnectedTableFilter,
  ConnectedTableLayout,
} from "table";
import { usePermissionGroupsColumns } from "./usePermissionGroupsColumns";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PageTableProvider } from "../../../../../../utils/PageTableProvider";
import { PermissionGroupsSidecar } from "../PermissionGroupsSidecar/PermissionGroupsSidecar";

const getRowId = (row: PermissionGroupData) => row.id ?? "";

export function PermissionGroupsTable() {
  const [selectedPermissionGroupId, setSelectedPermissionGroupId] =
    useState<string>();
  const columns = usePermissionGroupsColumns({
    setSelectedPermissionGroupId,
  });

  const {
    data: permissionGroups = [],
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: permissionGroupsKeys.permissionGroups({
      includeAssociatedRoles: "true",
      includeFeatures: "true",
    }),
    queryFn: getPermissionGroups,
  });

  const { data: roles = [] } = useQuery({
    queryKey: rolesKeys.userRole(),
    queryFn: getUserRole,
    staleTime: 0.5 * 60 * 1000,
  });

  return (
    <div className="flex w-full overflow-auto">
      <PageTableProvider
        columns={columns}
        data={permissionGroups}
        pageName="permissionGroups"
        tableName="table"
        enableColumnSort
        enablePagination
        enableResizeColumns
        enableColumnOrder
        initialState={{
          sortBy: [{ desc: true, id: "lastModifiedTs" }],
        }}
        isLoading={isLoading}
        getRowId={getRowId}
        isError={isError}
        error={error}
        enableFilterQueryParams
        enablePaginationQueryParams
        context={{
          roles,
        }}
      >
        <ConnectedTableLayout
          overflow
          isBackgroundFetching={isFetching}
          sidecar={
            selectedPermissionGroupId && (
              <PermissionGroupsSidecar
                selectedPermissionGroupId={selectedPermissionGroupId}
                setSelectedPermissionGroupId={setSelectedPermissionGroupId}
              />
            )
          }
          controlRight={
            <>
              <ConnectedTableFilter />
              <ConnectedDownloadButton
                fileName={`Permission-Groups-${new Date().toISOString()}.csv`}
              />
              <ConnectedConfigureColumns />
            </>
          }
        >
          <ConnectedTable
            shouldHighlightRow={({ row }) => {
              return !!(
                selectedPermissionGroupId &&
                row?.original?.id === selectedPermissionGroupId
              );
            }}
          />
        </ConnectedTableLayout>
      </PageTableProvider>
    </div>
  );
}
