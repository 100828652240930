import { LoadingIcon } from "icons";
import { type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Body } from "ui";

type ResultsSummaryProps = {
  startingRecord?: number;
  endingRecord?: number;
  selected?: number;
  totalRecords: number;
  isBackgroundFetching?: boolean;
  isLoading?: boolean;
};

export function ResultsSummary({
  startingRecord,
  endingRecord,
  selected,
  totalRecords,
  isBackgroundFetching = false,
  isLoading,
}: ResultsSummaryProps): ReactElement {
  if (isLoading) {
    return <div style={{ minHeight: "1rem" }} />;
  }

  return (
    <Body
      as="div"
      size="sm"
      addClassName="flex items-center whitespace-nowrap"
      data-testid="table-results-summary"
    >
      {totalRecords === 0 ? (
        "0 results"
      ) : (
        <>
          {selected !== undefined && (
            <>
              <FormattedMessage
                defaultMessage="{selected} Selected"
                id="wnC2BE"
                description="the count of how many items in a list are selected"
                values={{ selected }}
              />
              {" | "}
            </>
          )}
          <FormattedMessage
            defaultMessage="Displaying {startingRecord} - {endingRecord} of {totalRecords}"
            id="kT3eQo"
            description="Summary of which items are being shown, like 1 - 10 of 1000"
            values={{ startingRecord, endingRecord, totalRecords }}
          />
        </>
      )}
      {isBackgroundFetching && <LoadingIcon className="ml-4" />}
    </Body>
  );
}
