import { type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Button, Select, type SelectItem } from "ui";

type BucketSelectPropTypes = {
  cloudTrailBucket?: string;
  disabled: boolean;
  items?: SelectItem[];
  setCloudTrailBucket: Dispatch<SetStateAction<string | undefined>>;
};

export default function BucketSelect({
  cloudTrailBucket = "",
  disabled,
  items = [],
  setCloudTrailBucket,
}: BucketSelectPropTypes) {
  const intl = useIntl();

  const description = intl.formatMessage({
    defaultMessage: "Select a Bucket",
    id: "Z+7icB",
    description: "Wizard field instruction",
  });

  const buttonLabel = cloudTrailBucket.length ? cloudTrailBucket : description;

  return (
    <Select
      button={
        <Button addClassName="min-w-full" isMenu>
          {buttonLabel}
        </Button>
      }
      disabled={disabled}
      enableSearch
      fullWidth
      items={items}
      onChange={({ selectedItem }) => {
        const newBucket = selectedItem?.value as string;

        setCloudTrailBucket(newBucket); // set value in Wizard state
      }}
      selectedItem={{ value: cloudTrailBucket }}
    />
  );
}
