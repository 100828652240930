import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const JustInTimeLayout = lazy(() => import("./JustInTimeLayout"));

export const justInTimeRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "jit",
  component: JustInTimeLayout,
});
