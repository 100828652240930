/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { justInTimeRoute } from "../../justInTimeRoute";

export const ActiveSessionsTable = lazy(
  () => import("../tables/ActiveSessionsTable/ActiveSessionsTable"),
);

export const activeSessionsRoute = new Route({
  getParentRoute: () => justInTimeRoute,
  path: "sessions",
  component: ActiveSessionsTable,
});
