import { Link, type LinkComponent } from "@tanstack/react-router";
import { type ComponentPropsWithoutRef } from "react";
import { bodyAppearanceVariants } from "ui";

type CustomLinkProps = {
  /**
   * This prop is to be used if you would like to disable all of the
   * associated styles with the RouterLink. It should be used in cases
   * where you are wrapping another component with <RouterLink> with
   * the intention of utilizing the other component's styles.
   *
   * For Example:
   * <RouterLink withChild to="/">
   *   <Button as="span">Link that looks like a button</Button>
   * </RouterLink>
   */
  withChild?: boolean;
};

type CustomLinkComponent = (
  props: CustomLinkProps & ComponentPropsWithoutRef<"a">,
) => JSX.Element;

/**
 * A link styled for react-router.
 */
export const RouterLink: LinkComponent<CustomLinkComponent> = ({
  // eslint-disable-next-line react/prop-types
  withChild,
  ...props
}) => {
  return (
    // @ts-expect-error Link expects withChild in spread, but the browser would throw an error if we do that
    <Link className={withChild ? "" : bodyAppearanceVariants.link} {...props} />
  );
};
