import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsProvidersRoute } from "../providers/settingsProvidersRoute";
const CasProviders = lazy(() => import("./CasProviders"));

export const settingsCICDSystemsRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "/cicd-systems",
  component: CasProviders,
});

export const settingsCICDSystemsNestedRoute = new Route({
  getParentRoute: () => settingsCICDSystemsRoute,
  path: "$",
  component: CasProviders,
});
