import { type CSSProperties, type ReactElement } from "react";
import { classNames } from "utils";

const defaultGradientWidth = 320; // in px
const defaultGradientIndentation = 16; //in px

export type SkeletonVariantType =
  | "circular"
  | "rectangular"
  | "rounded"
  | "rounded-lg";

export type SkeletonRootProps = {
  addClassName?: string;
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  index?: number;
  variant?: SkeletonVariantType;
  animationDelay?: number;
  gradient?: {
    width?: number;
    indentation?: number;
  };
};

const classesByVariant: Record<SkeletonVariantType, string> = {
  circular: "rounded-full",
  rectangular: "rounded-none",
  rounded: "rounded",
  "rounded-lg": "rounded-lg",
};

export function SkeletonRoot({
  addClassName,
  animationDelay,
  height = "auto",
  width = "auto",
  variant = "rounded",
  index = 1,
  gradient,
}: SkeletonRootProps): ReactElement {
  const gradientWidth = gradient?.width || defaultGradientWidth;
  const gradientIndentation =
    gradient?.indentation || defaultGradientIndentation;

  return (
    <span
      data-before="&#160;"
      className={classNames(
        "relative my-0 block overflow-hidden bg-gray-100 before:content-[attr(data-before)] dark:bg-blue-steel-940",
        classesByVariant[variant],
        addClassName,
      )}
      style={{
        height,
        width,
      }}
      aria-label="skeleton-loading"
    >
      <span
        className="absolute inset-0 motion-safe:animate-shimmer"
        style={{
          animationDelay: `${animationDelay}ms`,
        }}
      >
        <span
          className={classNames(
            "flex h-full w-full bg-gradient-to-r",
            "from-transparent via-white to-transparent opacity-60",
            "dark:via-gray-200 dark:opacity-10",
          )}
          style={{
            width: gradientWidth,
            marginLeft: -(gradientWidth / 2) + index * gradientIndentation,
          }}
        />
      </span>
    </span>
  );
}
