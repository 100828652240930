import { isChina } from "environment";
import { isEmpty, isNil } from "remeda";
import {
  type CloudAccountFeature,
  type PermissionsStatus,
  type SecurityCapabilitiesType,
} from "requests";
import {
  ACCOUNT,
  ACTIVE_DIRECTORY,
  ALIBABA_CLOUD,
  AWS,
  AZURE,
  EXTERNAL_ACCOUNT,
  FEATURE_ITEMS,
  GCP,
  IBM,
  IDENTITY_SECURITY,
  MISCONFIGURATIONS,
  OCI,
  REMEDIATION,
  SUBSCRIPTION,
  TENANT,
  THREAT_DETECTION,
  TYPE,
} from "./constants";
import {
  type AccountType,
  type CloudType,
  type CloudTypes,
  type IdentitySecurityType,
  type SecurityCapabilitiesPermissionsStatus,
} from "./types";

import { ThreeUsersIcon, UserAtIcon, UserCogIcon, UserIcon } from "icons";
import { defineMessages, type IntlShape } from "react-intl";
import { type useToastActions } from "stores";
import { dynamicFormatMessage, titleize, type BannerAppearance } from "ui";
import { regexes, snakeCase } from "utils";
import { statusToastMessagesMap } from "./messages/reviewStatusMessages";

export const cloudTypeSort = (cloudTypes: CloudTypes) => {
  const sortingArray: CloudType[] = [AWS, AZURE, GCP, OCI, ALIBABA_CLOUD, IBM];

  return [...cloudTypes].sort(
    (a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b),
  );
};

export function getAzureAccountType({
  accountType,
  rootSyncEnabled = false,
}: {
  accountType?: string;
  rootSyncEnabled: boolean;
}) {
  const isActiveDirectory = accountType === TENANT && !rootSyncEnabled;
  const isSubscription = accountType === ACCOUNT;
  return (
    isActiveDirectory
      ? ACTIVE_DIRECTORY
      : isSubscription
        ? SUBSCRIPTION
        : accountType
  ) as string;
}

export const getAuthenticationModeInfo = (
  credentials: Record<string, string>,
  isFederatedGcpWifEnabled: boolean,
): {
  authenticationMode: string;
  clientEmail: string;
  projectId: string;
  privateKeyId?: string;
  serviceAccount?: string;
} => {
  if (isNil(credentials))
    return {
      authenticationMode: "",
      clientEmail: "",
      projectId: "",
      privateKeyId: "",
      serviceAccount: "",
    };

  if (credentials[TYPE] === EXTERNAL_ACCOUNT) {
    let projectId = "";
    let clientEmail = "";
    let serviceAccount = "";

    if (isFederatedGcpWifEnabled) {
      const audience = credentials["audience"].match(
        /\/\/iam.googleapis.com\/projects\/(.+?)\/locations\/global\/workloadIdentityPools\/?(.*)/,
      );
      projectId = (audience && audience[1]) ?? "";
      clientEmail = credentials["client_email"] ?? "";
      serviceAccount = clientEmail.match(/^(.+)@/)?.[1] ?? "";
    } else {
      const serviceAccountImpersonationUrl = credentials[
        "service_account_impersonation_url"
      ].match(
        /https:\/\/iamcredentials.googleapis.com\/v1\/projects\/-\/serviceAccounts\/(.+?)@(.+?).iam.gserviceaccount.com:generateAccessToken/,
      );

      projectId =
        (serviceAccountImpersonationUrl && serviceAccountImpersonationUrl[2]) ??
        "";

      serviceAccount =
        (serviceAccountImpersonationUrl && serviceAccountImpersonationUrl[1]) ??
        "";

      clientEmail =
        (serviceAccountImpersonationUrl &&
          `${serviceAccountImpersonationUrl[1]}@${serviceAccountImpersonationUrl[2]}.iam.gserviceaccount.com`) ??
        "";
    }

    return {
      clientEmail,
      projectId,
      serviceAccount,
      authenticationMode: credentials["type"],
    };
  } else {
    return {
      clientEmail: credentials["client_email"],
      projectId: credentials["project_id"],
      privateKeyId: credentials["private_key_id"],
      authenticationMode: credentials["type"],
    };
  }
};

export function getSecurityCapabilitiesList({
  features = [],
  identitySecurity,
  isAzureActiveDirectory = false,
  permissionStatusData,
  isMSA = false,
  isIbm = false,
}: {
  features?: CloudAccountFeature[];
  identitySecurity?: IdentitySecurityType;
  isAzureActiveDirectory?: boolean;
  permissionStatusData: PermissionsStatus[];
  isMSA?: boolean;
  isIbm?: boolean;
}) {
  const filteredFeatures = features?.filter(
    ({ featureName }) => featureName !== REMEDIATION.toLowerCase(),
  );

  const isEnabled = (values: string[] = []) => {
    return !!filteredFeatures?.filter(
      (obj) =>
        values.includes(obj["featureName"]) && obj?.featureState === "enabled",
    ).length;
  };

  let enabledItems = FEATURE_ITEMS.filter((item) =>
    isEnabled(item.children),
  ).map((item) => item.value);

  // Add (MISCONFIGURATIONS) CSPM if enableItems don't have it
  if (!enabledItems.includes(MISCONFIGURATIONS)) {
    enabledItems.unshift(MISCONFIGURATIONS);
  }

  if (identitySecurity?.status === "enabled") {
    enabledItems.push(IDENTITY_SECURITY);
  }

  // Add Threat Detection by checking value in Permission Api
  if (
    permissionStatusData?.find(({ displayName = "" }) =>
      ["audit logs", "flow logs"].includes(displayName?.toLowerCase()),
    )
  ) {
    enabledItems.push(THREAT_DETECTION);
  }

  // Azure Active Directory account don't have Identity Security
  // GCP Master Service Account don't have Identity Security
  // China Master Service Account don't have Identity Security
  // IBM cloudType doesn't support Identity Security
  if (isMSA || isAzureActiveDirectory || isChina() || isIbm) {
    enabledItems = enabledItems.filter((item) => item !== IDENTITY_SECURITY);
  }

  return enabledItems as SecurityCapabilitiesType[];
}

export const handleError = ({
  intl,
  toast,
  closeModal,
  error,
}: {
  intl: IntlShape;
  toast: ReturnType<typeof useToastActions>["toast"];
  closeModal?: () => void;
  error: unknown;
}) => {
  const _error = error as Record<string, string>;

  const errorKey = snakeCase(
    _error?.message || _error?.statusText,
  ) as keyof typeof statusToastMessagesMap;

  const toastMessage = isEmpty(_error?.message)
    ? intl.formatMessage(
        statusToastMessagesMap[errorKey] ??
          statusToastMessagesMap["unknown_error"],
      )
    : _error?.message;

  toast(toastMessage, {
    appearance: "error",
  });
  closeModal && closeModal();
};

export const parseI18nKeyFromResponse = (
  intl: IntlShape,
  errorResponse: unknown,
) => {
  const errors = errorResponse as {
    headers: {
      [key: string]: { i18nKey: string; subject: string }[];
    };
  };
  const statusError = errors?.headers?.["x-redlock-status"];

  if (!statusError?.length)
    return intl.formatMessage({
      defaultMessage: "Something went wrong.",
      id: "YDsiin",
      description: "Description for something_went_wrong",
    });

  return statusError.map(
    ({ i18nKey, subject }: { i18nKey: string; subject: string }) => {
      return dynamicFormatMessage(
        intl,
        statusToastMessagesMap,
        snakeCase(i18nKey),
        {
          subject,
        },
      );
    },
  );
};

export const parseErrorsFromResponse = (
  intl: IntlShape,
  errorResponse: unknown,
) => {
  const errors = errorResponse as {
    headers: {
      [key: string]: { i18nKey: string; subject: string }[];
    };
  };

  const statusError = errors?.headers?.["x-redlock-status"];

  if (!statusError?.length)
    return intl.formatMessage({
      defaultMessage: "Something went wrong.",
      id: "YDsiin",
      description: "Description for something_went_wrong",
    });

  return statusError.map(
    ({ i18nKey, subject }: { i18nKey: string; subject: string }) => {
      if (i18nKey === "internal_error" && subject) return subject;
      if (i18nKey === "invalid_parameter_value" && subject) {
        return intl.formatMessage(
          {
            defaultMessage: "Invalid parameter value: %{param}",
            id: "y9jAti",
            description: "The number of policies",
          },
          { param: subject },
        );
      }

      return titleize(i18nKey);
    },
  );
};

export function getSecurityCapabilitiesData({
  permissionStatusData,
  features = [],
  identitySecurity = {},
}: {
  permissionStatusData: PermissionsStatus[];
  features?: CloudAccountFeature[];
  identitySecurity?: IdentitySecurityType;
}): SecurityCapabilitiesPermissionsStatus[] {
  const disabledItems = features
    .filter((item) => item.featureState === "disabled")
    .map(({ displayName, featureState }) => ({
      displayName,
      status: featureState,
    }));

  const permissionStatusDataWithName = permissionStatusData.map(
    ({ displayName, ...rest }) => ({ name: displayName, ...rest }),
  );
  const disabledItemsWithName = disabledItems.map(
    ({ displayName, ...rest }) => ({ name: displayName, ...rest }),
  );
  const identitySecurityWithName = {
    name: identitySecurity?.displayName,
    ...identitySecurity,
  };

  return [
    ...permissionStatusDataWithName,
    ...disabledItemsWithName,
    identitySecurityWithName,
  ] as SecurityCapabilitiesPermissionsStatus[];
}

export const validGuid = (intl: IntlShape) => (value: string) => {
  if (!regexes.guid.test(value)) {
    return intl.formatMessage({
      defaultMessage: "ID should be valid GUID",
      id: "gMIxlo",
      description: "Error message for field for onboarding an Azure account",
    });
  }
  return;
};

export const getOUPreviouslySelectedNodesErrorMessage = ({
  data = [],
  intl,
}: {
  data: { resourceId: string; status: string }[];
  intl: IntlShape;
}) => {
  const messageMap = defineMessages({
    internal_error: {
      defaultMessage: "Internal Error",
      id: "iRvMPt",
      description: "Internal error",
    },
  });

  return data.map(({ resourceId, status }) => ({
    description: intl.formatMessage(
      {
        defaultMessage:
          "{resourceId} was previously selected, but could not be loaded. Reason: {status}",
        id: "tfuR3Q",
        description: "The number of policies",
      },
      {
        resourceId,
        status: messageMap[status as keyof typeof messageMap]
          ? intl.formatMessage(messageMap[status as keyof typeof messageMap])
          : status,
      },
    ),
    errorType: "warning" as BannerAppearance,
  }));
};

export const AccountTypeIcon = (type: AccountType) => {
  switch (type) {
    case "account":
      return UserIcon;
    case "workspace_domain":
      return UserAtIcon;
    case "masterServiceAccount":
      return UserCogIcon;
    default:
      return ThreeUsersIcon;
  }
};
