import { fromJS } from "immutable";
import { useReducer, type ReactNode } from "react";

import { createCtx } from "ui";

import {
  awsSelectMemberAccountsInitialState,
  type AwsSelectMemberAccountsInitialStateType,
} from "./state/initialState";
import { awsSelectMemberAccountsReducer } from "./state/reducers";

type UseAWSSelectMemberAccountsContextType = {
  state: AwsSelectMemberAccountsInitialStateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
};
const [useAWSSelectMemberAccountsContext, CtxProvider] =
  createCtx<UseAWSSelectMemberAccountsContextType>();

export { useAWSSelectMemberAccountsContext };

export interface AwsSelectMemberAccountsContextProps {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any;
}

export function AwsSelectMemberAccountsContextProvider({
  children,
  initialState,
}: AwsSelectMemberAccountsContextProps) {
  const [state, dispatch] = useReducer(
    awsSelectMemberAccountsReducer,
    fromJS(awsSelectMemberAccountsInitialState).merge(initialState),
  );

  return (
    <CtxProvider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CtxProvider>
  );
}
