import { Body, Bold } from "ui";

import { IdentitySecurityModuleIcon } from "icons";
import { type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { AddonModalMainCard } from "../AddonModalMainCard";

export function ASMSubscribeBuyModalContent(): ReactElement {
  return (
    <Body as="div">
      <AddonModalMainCard
        icon={<IdentitySecurityModuleIcon size="xxl" />}
        title={
          <FormattedMessage
            defaultMessage="Enable Discovery and Exposure Management"
            id="aEe2bK"
            description="CDEM Security modal header"
          />
        }
        titleStyle="addonNameHeader"
        description={
          <>
            <Body>
              <FormattedMessage
                defaultMessage="Prisma Discovery and Exposure Management discovers Cloud Exposures offers near real time exposure management related to the unknown, shadowIT assets. Evaluate your Cloud security posture and build true asset inventory."
                id="bkiWGY"
                description="CDEM Security modal details -1"
              />
            </Body>
            <Body addClassName="mt-5">
              <FormattedMessage
                defaultMessage="Please reach out to your account executive for credit Consumption details"
                id="xeFJ/8"
                description="CDEM Security modal details-2"
                values={{
                  b: (chunks) => <Bold>{chunks}</Bold>,
                }}
              />
            </Body>
          </>
        }
      />
    </Body>
  );
}
