import { Modal, ModalBody, ModalHeader } from "ui";

import { type ReactNode } from "react";
import { WIZARD_HEIGHT } from "../../constants";

type LoadingModalProps = {
  height?: string;
  size?: "sm" | "md" | "lg" | "xl" | "full";
  title: ReactNode;
};

export default function LoadingModal({
  height,
  size = "xl",
  title,
}: LoadingModalProps) {
  return (
    <Modal
      isOpen={true}
      size={size}
      style={{ minHeight: height || WIZARD_HEIGHT }}
    >
      <ModalHeader title={title} />
      <ModalBody isLoading addClassName="flex items-center justify-center">
        <></>
      </ModalBody>
    </Modal>
  );
}
