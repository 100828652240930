import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  AWS_OU_LOAD_MORE_NO_TOKEN,
  ENABLED,
  NAME,
  ORGANIZATION,
  ROLE_ARN,
} from "../../../../../../../../../constants";
import {
  getAccountName,
  getAwsAccountNextPageToken,
  getAwsOUNextPageToken,
  getEnabled,
  getRoleARN,
} from "../../../../../../context/AwsSelectMemberAccountsContext/state/getters";

import { isNil } from "remeda";
import { getAccountIdFromRoleArn } from "../../../../../../../utils";
import { type AwsSelectMemberAccountsInitialStateType } from "../../../../../../context/AwsSelectMemberAccountsContext/state/initialState";

type PayloadType = {
  accountId: string;
  accountType: string;
  enabled: boolean;
  name: string;
  roleArn: string;
};
export const getPayload = (
  state: AwsSelectMemberAccountsInitialStateType,
): PayloadType => {
  // Request payload for API calls
  return {
    [ACCOUNT_ID]: getAccountIdFromRoleArn(getRoleARN(state)) as string,
    [ACCOUNT_TYPE]: ORGANIZATION,
    [ENABLED]: getEnabled(state),
    [NAME]: getAccountName(state),
    [ROLE_ARN]: getRoleARN(state),
  };
};

export const getChildrenApiPayload = (
  loadParams: {
    parentId: string;
  },
  accountId: string,
  state: AwsSelectMemberAccountsInitialStateType,
): {
  id: string;
  body: {
    accountId: string;
    accountType: string;
    enabled: boolean;
    name: string;
    roleArn: string;
  };
  displayErrorMessage: boolean;
  params?: Record<string, string>;
} => {
  const { parentId } = loadParams;
  let id = "root"; // 1st  call is through account id as root
  if (parentId !== accountId) {
    id = parentId;
  }

  const ouCursorToken = getAwsOUNextPageToken(state, {
    parentId,
  });

  const accountCursorToken = getAwsAccountNextPageToken(state, {
    parentId,
  });

  // If the token is the `AWS_LOAD_MORE_NO_TOKEN`, omit it from the request
  const ouFetchCount = 0;
  const accountFetchCount = 0;
  let accountNextPageToken;
  let ouNextPageToken: unknown;
  const requestPayload = getPayload(state);
  let payload: Record<string, unknown> = {
    requestPayload,
  };

  if (
    ouCursorToken !== AWS_OU_LOAD_MORE_NO_TOKEN &&
    accountCursorToken !== AWS_OU_LOAD_MORE_NO_TOKEN
  ) {
    ouNextPageToken = ouCursorToken;
    accountNextPageToken = accountCursorToken;
    payload = {
      requestPayload,
      ouNextPageToken,
      accountNextPageToken,
      accountFetchCount,
    };
  } else if (
    ouCursorToken !== AWS_OU_LOAD_MORE_NO_TOKEN &&
    accountCursorToken === AWS_OU_LOAD_MORE_NO_TOKEN
  ) {
    ouNextPageToken = ouCursorToken;
    payload = {
      requestPayload,
      ouNextPageToken,
      accountFetchCount,
    };
  } else if (
    ouCursorToken === AWS_OU_LOAD_MORE_NO_TOKEN &&
    accountCursorToken !== AWS_OU_LOAD_MORE_NO_TOKEN
  ) {
    accountNextPageToken = accountCursorToken;
    payload = {
      requestPayload,
      accountNextPageToken,
      ouFetchCount,
    };
  } else {
    payload = {
      requestPayload,
    };
  }

  return {
    id,
    body: payload.requestPayload as PayloadType,
    displayErrorMessage: false,
    params: (!isNil(payload.ouNextPageToken) &&
    !isNil(payload.accountNextPageToken)
      ? {
          ouNextPageToken: payload.ouNextPageToken,
          accountNextPageToken: payload.accountNextPageToken,
        }
      : !isNil(payload.ouNextPageToken) && isNil(payload.accountNextPageToken)
        ? {
            ouNextPageToken: payload.ouNextPageToken,
          }
        : isNil(payload.ouNextPageToken) && !isNil(payload.accountNextPageToken)
          ? {
              accountNextPageToken: payload.accountNextPageToken,
            }
          : undefined) as Record<string, string>,
  };
};
