import { type z } from "zod";
import { jsonApi } from "../../../apis";
import {
  AwsCreateIamRoleCftRequestSchema,
  AwsCreateIamRoleCftResponseSchema,
  AwsSelectMemberAccountsAncestorsRequestSchema,
  AwsSelectMemberAccountsAncestorsResponseSchema,
  AwsSelectMemberAccountsChildrenRequestSchema,
  AwsSelectMemberAccountsChildrenResponseSchema,
} from "./schemas";

export const createDynamicIAMRoleCft = (
  payload: z.infer<typeof AwsCreateIamRoleCftRequestSchema>,
) => {
  return jsonApi({
    path: "cas/v1/aws_template/presigned_url",
    config: {
      method: "POST",
    },
    body: payload,
    requestSchema: AwsCreateIamRoleCftRequestSchema,
    responseSchema: AwsCreateIamRoleCftResponseSchema,
  });
};

export const getAwsSelectMemberAccountChildrens = ({
  id,
  body,
  params,
}: {
  id: string;
  body: z.infer<typeof AwsSelectMemberAccountsChildrenRequestSchema>;
  params?: Record<string, string>;
}) => {
  return jsonApi({
    path: `cas/v1/aws_account/${id}/children`,
    config: {
      method: "POST",
    },
    body,
    params,
    requestSchema: AwsSelectMemberAccountsChildrenRequestSchema,
    responseSchema: AwsSelectMemberAccountsChildrenResponseSchema,
  });
};

export const getAwsSelectMemberAccountAncestors = ({
  accountId,
  body,
}: {
  accountId: string;
  body: z.infer<typeof AwsSelectMemberAccountsAncestorsRequestSchema>;
}) => {
  return jsonApi({
    path: `cas/v1/aws_account/${accountId}/ancestors`,
    config: {
      method: "POST",
    },
    body,
    requestSchema: AwsSelectMemberAccountsAncestorsRequestSchema,
    responseSchema: AwsSelectMemberAccountsAncestorsResponseSchema,
  });
};
