import { useMutation, useQueryClient } from "@tanstack/react-query";
import { tenantKeys, updateTenantFeatures, useToken } from "requests";
import { useToastActions } from "stores";
import { dynamicFormatMessage } from "ui";

import { useIntl } from "react-intl";
import { isNil } from "remeda";
import { FeatureProvisionMessageMap } from "./messages";

type UseASMEnableProps = {
  onSuccess?: () => void;
};

export function useASMEnable({
  onSuccess: onSuccessCallback,
}: UseASMEnableProps = {}) {
  const intl = useIntl();
  const { toast } = useToastActions();

  const queryClient = useQueryClient();

  const { prismaId, selectedCustomerName: customerName } = useToken();

  const { isPending: isUpdatingAsmFeature, mutate: updateAsmFeature } =
    useMutation<
      unknown,
      unknown,
      {
        enabled: boolean;
        operation: "ADD" | "UPDATE";
        additionalData?: Record<string, string | string[]>;
      },
      () => void
    >({
      mutationFn: ({ enabled, operation, additionalData }) => {
        return updateTenantFeatures({
          prismaId,
          customer_name: customerName,
          provision_status_list: [
            {
              op: operation,
              value: {
                enabled,
                feature_name: "asm",
                additional_data: additionalData,
              },
            },
          ],
        });
      },

      onError: async () => {
        toast(
          intl.formatMessage({
            defaultMessage:
              "Unable to find Discovery and Exposure Management module, please contact Prisma Cloud support.",
            id: "Tp72HK",
            description:
              "Error message when Discovery and Exposure Management module subscription failed",
          }),
          { appearance: "error" },
        );
      },
      onSuccess: async (data, { enabled }) => {
        if (!isNil(data) && Array.isArray(data) && data.length > 0) {
          if (
            data.every(
              (response) => response.status >= 200 && response.status <= 299,
            )
          ) {
            queryClient.invalidateQueries({
              queryKey: tenantKeys.features({ prismaId }),
            });

            onSuccessCallback?.();

            toast(
              dynamicFormatMessage(
                intl,
                FeatureProvisionMessageMap,
                enabled.toString(),
              ),
              {
                appearance: "success",
              },
            );
          } else {
            const errorMessage = data?.[0]?.message;
            toast(errorMessage, {
              appearance: "error",
            });
          }
        }
      },
    });

  return { isUpdatingAsmFeature, updateAsmFeature };
}
