import { type FC } from "react";
import {
  get,
  useController,
  useFormContext,
  type RegisterOptions,
} from "react-hook-form";
import {
  Field,
  Toggle as ReactToggle,
  type FieldProps,
  type ToggleProps as ReactToggleProps,
} from "ui";

export type ToggleProps = {
  /**
   * The name the value will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  /**
   * A default value for the Toggle
   */
  defaultValue?: boolean;
} & Omit<FieldProps, "error" | "control"> &
  Omit<ReactToggleProps, "checked" | "defaultValue">;

/**
 * Renders a Toggle connected to the form. All extra props will get passed to the Toggle.
 */
export const Toggle: FC<ToggleProps> = ({
  name,
  label,
  description,
  tooltip,
  disabled,
  register = {},
  defaultValue = false,
  showLabel,
  ...rest
}: ToggleProps) => {
  const context = useFormContext();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render Toggle outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState } = context;
  const {
    field: { onChange, value },
  } = useController({
    name,
    defaultValue: defaultValue,
    rules: register,
  });

  return (
    <Field
      label={label}
      control={
        <ReactToggle
          {...rest}
          disabled={disabled}
          checked={value}
          onClick={() => onChange(!value)}
        />
      }
      error={get(formState.errors, name)?.message}
      description={description}
      tooltip={tooltip}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
};
