import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { DataInventoryFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";
import { BreadcrumbsSchema, TableLevelEnumSchema } from "../types";

export const DataInventoryLayout = lazy(() => import("./DataInventoryLayout"));

export const DataInventorySearchSchema = z.object({
  filters: DataInventoryFilterSchema.catch({}).optional(),
  tableLevel: TableLevelEnumSchema.optional(),
  breadcrumbs: BreadcrumbsSchema,
});

export const dataInventoryLayoutRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "data",
  component: DataInventoryLayout,
  validateSearch: DataInventorySearchSchema,
});
