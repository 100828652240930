import { defineMessages } from "react-intl";

export const azureDescriptionMessages = defineMessages({
  no_automap_permissions: {
    defaultMessage:
      "The credentials uploaded do not have sufficient permissions to view the Management Groups. Please select a Default Account Group",
    id: "qO5HnU",
    description:
      "Description for azure credential no auto map permission error message",
  },
  no_folder_view_permissions: {
    defaultMessage:
      "The credentials uploaded do not have sufficient permissions to view the Management Groups. Continue with 'Select All Member Accounts' enabled or update the credentials to include or exclude subscriptions",
    id: "hSoZf6",
    description:
      "Description for azure credential no folder view permission error message",
  },
  unauthorized_access: {
    defaultMessage:
      "Application Client Secret is invalid. Please update to the correct value to continue.",
    id: "kTguCo",
    description: "Description for azure credential unauthorized error message",
  },
  wif_unauthorized_access: {
    defaultMessage:
      "Workload Identity Federation is not correctly configured. Please update to the correct values to continue",
    id: "l8ONzx",
    description: "Description for azure credential unauthorized error message",
  },
});

export const gcpDescriptionMessages = defineMessages({
  folder_viewer_permission_required_no_automap: {
    defaultMessage:
      "The credentials uploaded do not have sufficient permissions to view the folders, hence auto creating of account group is not available for selection. Please select a Default Account Group.",
    id: "18hreI",
    description:
      "Description for gcp credential no aut map permission error message",
  },
  folder_viewer_permission_required: {
    defaultMessage:
      "The credentials uploaded do not have sufficient permissions to view the folders. Continue with 'Select All Monitored Projects' enabled or update the credentials to include or exclude projects",
    id: "DO/+Dk",
    description:
      "Description for gcp credential no folder view permission error message",
  },
  unauthorized_access: {
    defaultMessage:
      "The Service Account Key uploaded is not valid. Please update to continue.",
    id: "wcaLM3",
    description: "Description for gcp credential unauthorized error message",
  },
});

export const awsDescriptionMessages = defineMessages({
  permission_error: {
    defaultMessage:
      "The credentials provided do not have sufficient permissions to view the Accounts/OUs. Continue with 'Select All Member Accounts' enabled or update the credentials to include or exclude accounts/OUs",
    id: "1gwhUq",
    description: "Description for aws credential permission error message",
  },
  external_id_empty_or_not_generated: {
    defaultMessage:
      "External ID not generated. To generate External ID, download the CFT. Execute the CFT before you proceed.",
    id: "a82O+Q",
    description:
      "Description for aws ExternalId either not generated or is different from Role arn account id",
  },
});
