import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";
import { useToastActions } from "stores";
import { Bold } from "ui";
import { useWizardContext } from "../../../../../../components/Wizard";
import { type OciConfigureAccountStepValuesType } from "../../../../../types";
import { parseErrorsFromResponse } from "../../../../../utils";
import InstructionsSidecar from "../../../components/InstructionsSidecar";
import RotateKeys from "../AccountDetails/RotateKeys";
import ConfigHelpers from "./ConfigHelpers";
import { getInstructions } from "./instructions";
import { downloadDynamicTerraform } from "./utils";

type DownloadTerraformProps = {
  isEdit: boolean;
  closeModal: () => void;
};

export default function DownloadTerraform({
  isEdit,
  closeModal,
}: DownloadTerraformProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();

  const { watch } = useFormContext();
  const [isOpenConfigHelper, setIsOpenConfigHelper] = useState(false);
  const [isTerraformDownloaded, setTerraformDownloaded] = useState(false);

  const { homeRegion } = steps[1]?.values as OciConfigureAccountStepValuesType;
  const [groupName, policyName, accountId, userName, isKeyRotated] = watch([
    "groupName",
    "policyName",
    "accountId",
    "userName",
    "rotateKeys",
  ]);

  const instructions = useMemo(
    () =>
      getInstructions({
        intl,
        isEdit,
        homeRegion: homeRegion.value,
      }),
    [intl, isEdit, homeRegion],
  );

  const { mutateAsync: downloadTerraform, isPending: isDownloadingTerraform } =
    useMutation({
      mutationFn: async () => {
        try {
          const requestData = {
            groupName,
            policyName,
            accountId,
            userName,
            ...(isEdit && { generateKeys: isKeyRotated }),
          };

          await downloadDynamicTerraform(requestData);
          setTerraformDownloaded(true);
        } catch (err: unknown) {
          toast(parseErrorsFromResponse(intl, err), {
            appearance: "error",
          });
          closeModal();
        }
      },
    });

  return (
    <section>
      {isEdit && <RotateKeys />}
      <Bold addClassName="flex items-center">
        <FormattedMessage
          defaultMessage="Download the terraform and complete the steps to configure account."
          id="J1+26l"
          description="description for create and download stack link"
        />
      </Bold>

      <ConfigHelpers
        downloadTerraform={downloadTerraform}
        isTerraformDownloaded={isTerraformDownloaded}
        isDownloadingTerraform={isDownloadingTerraform}
        setIsOpenConfigHelper={setIsOpenConfigHelper}
      />
      <InstructionsSidecar
        instructions={instructions}
        isOpen={isOpenConfigHelper}
        setOpen={setIsOpenConfigHelper}
        title={intl.formatMessage({
          defaultMessage: "Generate User OCID",
          id: "qH0RU7",
          description: "Sidecar instruction heading",
        })}
      />
    </section>
  );
}
