import { defineMessages } from "react-intl";

export const tabNames = defineMessages({
  status: {
    defaultMessage: "Status",
    id: "bxYhlB",
    description: "Cloud Accounts Status tab header",
  },
  misconfigurations: {
    defaultMessage: "Misconfigurations (CSPM)",
    id: "esMUhb",
    description: "Cloud Accounts Misconfigurations tab header",
  },
  threatDetection: {
    defaultMessage: "Threat Detection",
    id: "Y88OrT",
    description: "Cloud Accounts Threat Detection tab header",
  },
  workloadSecurity: {
    defaultMessage: "Workload Security",
    id: "5gGatA",
    description: "Cloud Accounts Workload Security tab header",
  },
  dataSecurity: {
    defaultMessage: "Data Security",
    id: "57jp2i",
    description: "Cloud Accounts data security tab header",
  },
  apiDiscovery: {
    defaultMessage: "API Security",
    id: "DkfX01",
    description: "Cloud Accounts API Security tab header",
  },
});
