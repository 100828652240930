import {
  type IntlFormatters,
  type IntlShape,
  type MessageDescriptor,
} from "react-intl";

import { titleize } from "../../utils/titleize";

/**
 * Gracefully handles translation when a key may be missing from a messageMap.
 *
 * Use case: Many APIs return tokens that are translated on the client. We know
 * most of these and can translate appropriately, but sometimes an API will
 * return an unexpected token. This util handles the unexpected token gracefully.
 *
 * If all possible values are known, prefer [`intl.formatMessage`](https://formatjs.io/docs/react-intl/api#formatmessage) instead.
 *
 * @param {intl} intl [FormatJS intl object](https://formatjs.io/docs/intl)
 * @param {Object} messageMap A map of intl messages, as returned from
 *    [defineMessages](https://formatjs.io/docs/intl#definemessagesdefinemessage)
 * @param {String} key The key of the desired message in the messageMap
 * @param {Object} values The values passed to [formatMessage](https://formatjs.io/docs/intl#usage)
 * @returns {String} The formatted message
 */
export function dynamicFormatMessage(
  intl: IntlShape,
  messageMap: Record<string, MessageDescriptor>,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Record<string, any>,
  options?: Parameters<IntlFormatters["formatMessage"]>[2],
): string {
  const message = messageMap[key];

  if (!message) {
    /* c8 ignore next */
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.trace(`Missed translation: "${key}" not found in messageMap.`);
    }

    return titleize(key);
  }

  return intl.formatMessage(messageMap[key], values, options);
}
