import { EditIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { Button, useModal } from "ui";
import {
  AlibabaOnboarding,
  AwsOnboarding,
  AzureOnboarding,
  GcpOnboarding,
  IbmOnboarding,
  OciOnboarding,
} from "../../../CloudOnboarding/Onboarding";

import { useCloudAccountEditPrivileges } from "../../../hooks";

import { usePermissions } from "prisma";
import { type CloudType, type OnboardingProps } from "../../../types";

const cloudWizards: Record<CloudType, React.FC<OnboardingProps>> = {
  aws: AwsOnboarding,
  alibaba_cloud: AlibabaOnboarding,
  azure: AzureOnboarding,
  gcp: GcpOnboarding,
  ibm: IbmOnboarding,
  oci: OciOnboarding,
};

type EditButtonPropTypes = {
  accountId: string;
  cloudType: Exclude<CloudType, "all">;
};

export default function EditButton({
  accountId,
  cloudType,
}: EditButtonPropTypes) {
  const fallbackPermissions = useCloudAccountEditPrivileges();
  const permissions = usePermissions();

  const allowed =
    permissions.settingsCloudAccounts.UPDATE ?? fallbackPermissions;

  const CloudWizard = cloudWizards[cloudType];
  const { openModal: openEditModal } = useModal(CloudWizard);
  const props = { accountId, cloudType, isEdit: true };

  if (!allowed) return null;

  return (
    <Button
      size="sm"
      data-selector="edit-cloud-account-button"
      onClick={() => openEditModal({ ...props })}
      icon={<EditIcon />}
    >
      <FormattedMessage
        defaultMessage="Edit"
        id="Bsabop"
        description="Edit Cloud Account button"
      />
    </Button>
  );
}
