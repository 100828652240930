import { type ReactElement, type ReactNode } from "react";
import { classNames } from "utils";

export const appearanceClassName = {
  red: "bg-red-500 dark:bg-red-400",
  blue: "bg-blue-600 dark:bg-blue-500",
  green: "bg-green-500 dark:bg-teal-400",
  critical: "bg-red-700 dark:bg-red-550",
  high: "bg-red-500 dark:bg-red-400",
  medium: "bg-orange-500 dark:bg-orange-400",
  low: "bg-yellow-500 dark:bg-yellow-300",
  lightRed: "bg-red-100",
  lightBlue: "bg-blue-100",
  lightGreen: "bg-green-100",
  lightOrange: "bg-orange-100",
  disabled: "bg-gray-200",
};

export type BadgeProps = {
  appearance: keyof typeof appearanceClassName;
  /**
   * The content of the Badge
   */
  children: ReactNode;
  "data-testid"?: string;
};

export const Badge = ({
  children,
  appearance,
  "data-testid": dataTestId,
}: BadgeProps): ReactElement => {
  const className = classNames(
    "inline-block rounded p-1 text-xs leading-none text-dark-bg",
    appearanceClassName[appearance] && appearanceClassName[appearance],
  );

  return (
    <div data-testid={dataTestId} className={className}>
      {children}
    </div>
  );
};
