import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsMicrosoftTeamsIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Microsoft Teams",
    id: "El9v58",

    description: "Microsoft Teams icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <g clipPath="url(#clip0_10671_277499)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.9935 42.1876C79.1402 41.8243 78.3675 41.3029 77.7125 40.647C77.0608 39.991 76.5367 39.214 76.1739 38.3629C75.4281 36.5767 75.4281 34.5685 76.1739 32.7857C76.9029 31.0634 78.2735 29.6943 79.9935 28.961C81.7773 28.2142 83.7829 28.2142 85.5633 28.961C87.2833 29.691 88.6506 31.0634 89.3829 32.7857C90.1287 34.5719 90.1287 36.5801 89.3829 38.3629C88.6539 40.0852 87.2833 41.4543 85.5633 42.1876C83.7795 42.9344 81.7739 42.9344 79.9935 42.1876ZM75.6162 68.8997V47.3006H89.9943V61.4556C90.0447 62.7305 89.6853 63.9852 88.9697 65.0381C88.2878 65.9867 87.4009 66.7738 86.3729 67.3322C85.2845 67.9242 84.1087 68.3346 82.8893 68.5499C81.6765 68.7786 80.447 68.893 79.2108 68.8997H75.6162ZM66.7475 37.6834C65.8673 38.0602 64.92 38.2519 63.9659 38.2452C63.0119 38.2519 62.0679 38.0635 61.1911 37.6834C60.8736 37.5474 60.6629 37.3922 60.4262 37.2179C60.1771 37.0343 59.8992 36.8296 59.4375 36.6036V25.5366C59.8979 25.3122 60.176 25.1072 60.4258 24.9231C60.6626 24.7485 60.8739 24.5927 61.1911 24.4569C62.0645 24.0801 63.0052 23.8884 63.9559 23.8951C64.9133 23.8884 65.8606 24.0801 66.7408 24.4569C68.4608 25.1868 69.828 26.5592 70.5604 28.2815C71.3095 30.0677 71.3095 32.0759 70.5637 33.8588C69.8348 35.581 68.4675 36.9501 66.7475 37.6834ZM60.349 74.8372C60.1046 74.7711 59.8244 74.6954 59.4375 74.6115V42.7998H72.9187V67.793C72.9657 69.0478 72.6533 70.2924 72.015 71.3722C71.4372 72.2972 70.6544 73.0776 69.7239 73.6495C68.7766 74.2247 67.7351 74.6317 66.6501 74.8504C65.5852 75.0791 64.4967 75.1935 63.4083 75.2002C62.7028 75.1968 61.9973 75.1464 61.2986 75.0455C60.9346 74.9956 60.6733 74.9249 60.349 74.8372ZM56.7399 89.6007L10.001 81.5006V18.4995L56.7399 10.3994V89.6007ZM22.5853 36.0155L44.1558 34.7002V40.0991L36.0664 40.4591V64.0866L30.6746 63.7704V40.671L22.5853 40.9973V36.0155Z"
          className="fill-[#5558AF] dark:fill-white"
        />
      </g>
      <path
        d="M44.1557 34.7002L22.5852 36.0155V40.9973L30.6746 40.671V63.7704L36.0664 64.0866V40.4591L44.1557 40.0991V34.7002Z"
        className="fill-white dark:fill-none"
      />
      <defs>
        <clipPath id="clip0_10671_277499">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
