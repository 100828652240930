import { FormattedMessage, type IntlShape } from "react-intl";

import { Bold, Input, Link } from "ui";

import { selectAuthenticationTypeMap } from "../../messages";

export const getInstructions = (intl: IntlShape, isServiceAccount: boolean) => [
  <FormattedMessage
    defaultMessage="Download the Terraform script"
    id="t3AuwC"
    description="Instructions for downloading the terraform script"
    key="Download the Terraform script"
  />,
  <FormattedMessage
    defaultMessage="Login to the <a>Google Cloud shell</a>"
    id="s6u7de"
    description="Instructions for logging to GCP portal"
    key="Login to the Google Cloud shell"
    values={{
      a: (chunks) => (
        <Link
          data-selector="gcp-config-login-in-cloud-shell"
          size="sm"
          href="https://console.cloud.google.com/cloudshell"
          external
        >
          {chunks}
        </Link>
      ),
    }}
  />,
  <>
    <FormattedMessage
      defaultMessage="Upload the script to the Cloud Shell and run the following commands"
      id="U8xxbv"
      description="Instructions for uploading the script to cloud shell"
    />
    <Input
      allowCopy
      readOnly
      value="terraform init"
      data-selector="gcp-terraform-textarea"
    />
    <Input
      addClassName="mt-2"
      allowCopy
      readOnly
      value="terraform apply"
      data-selector="gcp-terraform-textarea"
    />
  </>,
  <FormattedMessage
    defaultMessage="Upload your <b>{fileType}</b> (JSON) file, review if the GCP onboarding configuration displayed on screen is correct, and click <b>Next</b>"
    id="oHY2dV"
    description="Instructions for onboarding a GCP account"
    key="Upload your (JSON) file"
    values={{
      b: (chunks) => <Bold>{chunks}</Bold>,
      fileType: isServiceAccount
        ? intl.formatMessage(selectAuthenticationTypeMap.serviceAccountKey)
        : intl.formatMessage(
            selectAuthenticationTypeMap.workloadIdentityFederation,
          ),
    }}
  />,
];
