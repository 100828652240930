import { v4 as uuidV4 } from "uuid";
import { useTokenStore } from "../hooks";
import { baseApiUrl, makeUrl } from "../utils";

/**
 * A base API function. Returns the response from fetch.
 *
 * Note: Do not use this directly in your request functions
 *
 * @private
 */
export async function baseApi({
  path,
  params,
  config = {},
  baseUrl,
  useBaseHeaders,
}: {
  path: string;
  params?: Record<string, string>;
  config?: RequestInit;
  baseUrl?: string;
  useBaseHeaders?: boolean;
}): Promise<Response> {
  const requestId = uuidV4().replace(/-/gi, "");
  const defaultUrl = baseUrl ?? baseApiUrl();

  const fullUrl = makeUrl(
    defaultUrl,
    `${path}${
      Object.keys(params ?? {}).length
        ? `?${new URLSearchParams(params).toString()}`
        : ""
    }`,
  );

  const headers: Record<string, string> = {};
  const token = useTokenStore.getState().rawToken || "";

  if (!baseUrl || useBaseHeaders) {
    headers["x-prisma-route"] = window.location.pathname;
    headers["x-redlock-auth"] = token;
    headers["x-redlock-request-id"] = requestId;
    headers["x-b3-traceid"] = requestId;
    headers["x-b3-spanid"] = requestId.slice(-16);
  }

  const customConfig = {
    method: "GET",
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
  };

  const response = await fetch(fullUrl, customConfig);

  if (!response.ok) {
    // if baseUrl is set that means we're going to a different backend like CNS that most likely is using a different
    // token, so don't remove the main token for those requests
    if (response.status === 401 && token && !baseUrl) {
      useTokenStore.getState().actions.removeToken();
    }
    if (response.status === 400) {
      const errors = JSON.parse(
        response.headers.get("x-redlock-status") ?? "[]",
      );

      if (errors[0]?.i18nKey === "active_role_unassigned") {
        useTokenStore.getState().actions.removeToken();
      }
    }
  }

  return response;
}
