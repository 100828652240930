import { Body, Button, Link, Modal, ModalBody, ModalHeader } from "ui";

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { postTermsOfService } from "requests";

type TermsOfServiceProps = {
  isOpen?: boolean;
  customerName: string;
  isSSOSession?: boolean;
  onAccept?: (response: { token: string }) => void;
  baseUrl: string;
};

export default function TermsOfService({
  isOpen = false,
  customerName,
  isSSOSession = false,
  onAccept,
  baseUrl,
}: TermsOfServiceProps) {
  const [readAgreement, setReadAgreement] = useState<boolean>(false);

  const username = useWatch({ name: "username" });
  const password = useWatch({ name: "password" });

  const {
    mutate,
    isPending,
  }: {
    mutate: () => void;
    isPending: boolean;
  } = useMutation({
    mutationFn: () =>
      postTermsOfService({
        customerName: customerName,
        ssoSession: isSSOSession,
        username: username,
        password: password,
        baseUrl,
      }),
    onSuccess: (response: { token: string }): void => {
      onAccept?.(response);
    },
  });

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title="Prisma Cloud Terms of Service" enableClose={false} />
      <ModalBody>
        <Body>
          <FormattedMessage
            defaultMessage="By clicking “Agree and Submit” below, you agree to the terms and conditions of our <a>END USER AGREEMENT</a>"
            id="+MUy4V"
            description="TOS agreement message"
            values={{
              a: (chunks) => (
                <Link
                  onClick={() => {
                    setReadAgreement(true);
                    window.open("https://www.paloaltonetworks.com/legal/eula");
                  }}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Body>

        <div className="mt-4">
          <Button
            onClick={() => mutate()}
            isLoading={isPending}
            disabled={!readAgreement || isPending}
            appearance="primary"
          >
            <FormattedMessage
              defaultMessage="Agree and Submit"
              id="gh3rne"
              description="TOS agree checkbox"
            />
          </Button>
        </div>
        <Body size="sm" addClassName="mt-4">
          <FormattedMessage
            defaultMessage="* You can only accept the terms once you have clicked on the above link"
            id="OtJukF"
            description="TOS fine print"
          />
        </Body>
      </ModalBody>
    </Modal>
  );
}
