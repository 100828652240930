import { animated, config, useSpring } from "@react-spring/web";
import { CaretDownIcon, CaretUpIcon, LoadingIcon } from "icons";
import {
  useCallback,
  useRef,
  useState,
  type CSSProperties,
  type ReactNode,
} from "react";
import { useIntl, type IntlShape } from "react-intl";
import useMeasure from "react-use-measure";
import { isEmpty, isNil, isString, isTruthy } from "remeda";
import {
  type PermissionsStatus,
  type PermissionsStatusComponent,
} from "requests";
import {
  Banner,
  DynamicFormattedMessage,
  dynamicFormatMessage,
  useModal,
  type BannerAppearance,
} from "ui";
import { classNames, snakeCase } from "utils";
import { scanModeTextMap } from "../../../../../messages/statusHeaderMessages";
import { statusModalMessagesMap } from "../../../../../messages/statusModalMessages";
import { type Status } from "../../../../../types";
import StatusDetailsModal from "../../../../StatusDetails";
import Details from "../Details";
import StatusWithIcon from "../StatusWithIcon";

const appearance: Record<Status, BannerAppearance> = {
  ok: "success",
  enabled: "success",
  warning: "warning",
  error: "error",
  disabled: "standard",
  success: "success",
  pending: "warning",
};

function sanitizeMessage(intl: IntlShape, message: string) {
  if (!isTruthy(message) || (isString(message) && !message?.trim()))
    return message;
  return statusModalMessagesMap[
    snakeCase(message) as keyof typeof statusModalMessagesMap
  ]
    ? dynamicFormatMessage(intl, statusModalMessagesMap, message)
    : message;
}

type CollapsibleDetailsProps = PermissionsStatus & {
  className?: string;
  components?: PermissionsStatusComponent[];
  detailsClassName?: string;
  errorCode?: string;
  icon?: ReactNode;
  isAccountDetails?: boolean;
  isCollapsibleDetails?: boolean;
  label: string;
  normalFont?: boolean;
  name?: string;
  isLoading?: boolean;
  message?: string;
  status?: string;
  isNoStatusIcon?: boolean;
};

CollapsibleDetails.defaultProps = {
  className: "",
  message: "",
  status: "",
  isAccountDetails: false,
};

export default function CollapsibleDetails({
  className,
  components,
  detailsClassName,
  isCollapsibleDetails,
  isAccountDetails,
  icon,
  label,
  message,
  name,
  normalFont,
  remediation,
  status,
  isLoading = false,
  isNoStatusIcon = false,
}: CollapsibleDetailsProps): JSX.Element {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);
  const displayName = isNil(name) ? label : name;

  const { openModal: openStatusDetailsModal } = useModal(StatusDetailsModal);

  const isComponentsEmpty = isNil(components) || components?.length === 0;
  const isMessageEmpty = isNil(message) || message?.length === 0;
  const isRemediationEmpty = isNil(remediation) || remediation?.length === 0;

  if (isComponentsEmpty && !isMessageEmpty && !isRemediationEmpty) {
    components = [
      {
        displayName,
        message,
        remediation,
        status,
        errorCode: "",
        lastRunEpochMillis: 0,
        lastSuccessEpochMillis: 0,
        lastErrorEpochMillis: 0,
      },
    ];
  }

  const handleClick = useCallback(() => {
    components?.length &&
      openStatusDetailsModal({
        displayName,
        components,
      });
  }, [components, displayName, openStatusDetailsModal]);

  const isOnClickEnabled = !(
    isComponentsEmpty &&
    isMessageEmpty &&
    isRemediationEmpty
  );

  const outerRef = useRef<HTMLDivElement>(null);
  const [innerRef, bounds] = useMeasure();
  const [isMounted, setIsMounted] = useState(false);

  const { ...style } = useSpring({
    immediate: !isMounted,
    config: { ...config.stiff, clamp: true },
    from: {
      height: 0,
      opacity: 0,
      translateY: "-100%",
    },
    to: {
      height: bounds.height,
      opacity: 1,
      translateY: "0%",
    },
    onStart: {
      height: () => {
        setIsMounted(true);
      },
    },
    reverse: !open,
  });

  if (!status && !isLoading) return <></>;

  return (
    <Details
      isAccountDetails={isAccountDetails}
      className={detailsClassName}
      icon={icon}
      description={
        isCollapsibleDetails ? (
          <animated.div
            ref={outerRef}
            className={classNames(
              "max-h-48 overflow-auto break-all",
              open && "mt-1",
            )}
          >
            {open ? (
              <Banner
                appearance={appearance[status as Exclude<Status, "pending">]}
                style={style as unknown as CSSProperties}
                type="inline"
              >
                <span ref={innerRef} className="text-xs">
                  {sanitizeMessage(intl, message)}
                </span>
              </Banner>
            ) : null}
          </animated.div>
        ) : null
      }
      label={label}
      normalFont={normalFont}
    >
      <div className={`flex items-center space-x-2 ${className}`}>
        {isLoading ? (
          <LoadingIcon size="md" />
        ) : isNoStatusIcon ? (
          <>
            <div className="flex items-center">
              <DynamicFormattedMessage
                messageMap={scanModeTextMap}
                token={status}
              />
            </div>
          </>
        ) : (
          <>
            <StatusWithIcon
              status={status}
              onClick={
                isOnClickEnabled && !isCollapsibleDetails
                  ? handleClick
                  : undefined
              }
            />
            {!isEmpty(message) && isCollapsibleDetails && (
              <span className="cursor-pointer">
                {open ? (
                  <CaretUpIcon id="caretUp" onClick={toggle} />
                ) : (
                  <CaretDownIcon id="caretDown" onClick={toggle} />
                )}
              </span>
            )}
          </>
        )}
      </div>
    </Details>
  );
}
