import { SearchIcon } from "icons";
import { useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { Input } from "../Input";
import { type SelectItem } from "../Select";
import { Combobox, type ComboboxProps } from "./Combobox";

export type SelectWithSearchProps = Omit<ComboboxProps, "input">;

const emptyItems: SelectItem[] = [];
const defaultFilterFn = (filter: string, value: string) =>
  value.toLowerCase().includes(filter.toLowerCase());

const defaultItemToString = (item: SelectItem | null): string => {
  return item ? String(item.value) : "";
};
/**
 * This component is a `Select` that handles searching in the Select.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { SelectWithSearch } from "ui";
 * ```
 */
export function SelectWithSearch({
  items = emptyItems,
  filterFn = defaultFilterFn,
  itemToString = defaultItemToString,
  onMenuItemKeyEnter,
  onSearch,
  ...rest
}: SelectWithSearchProps) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const searchInput = useCallback<ComboboxProps["input"]>(
    ({ getInputProps, highlightedIndex }) => {
      return (
        <div className="p-2">
          <Input
            tabIndex={-1}
            data-testid="search-input"
            placeholder={intl.formatMessage({
              defaultMessage: "Search",
              id: "oPXkY9",

              description: "Placeholder for search input",
            })}
            allowClear
            suffix={
              <>
                <div className="text-icon-gray dark:text-dark-bg">
                  <SearchIcon />
                </div>
              </>
            }
            {...getInputProps({
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                onSearch?.(event.target.value ?? "");
              },
              ref: inputRef,
              onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onMenuItemKeyEnter && event.key === "Enter") {
                  onMenuItemKeyEnter(event, items[highlightedIndex]);
                }
              },
            })}
          />
        </div>
      );
    },
    [intl, items, onMenuItemKeyEnter, onSearch],
  );

  return (
    <Combobox
      items={items}
      filterFn={filterFn}
      itemToString={itemToString}
      input={searchInput}
      onSearch={onSearch}
      {...rest}
    />
  );
}
