import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { settingsProvidersRoute } from "../providers/settingsProvidersRoute";

export const CloudAccounts = lazy(() => import("./CloudAccounts"));

export const settingsCloudAccountsRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "cloud-accounts",
  component: CloudAccounts,
  validateSearch: z.object({
    modalName: z.string().optional(),
    id: z.string().optional(),
    type: z.string().optional(),
  }),
});

export const casProvidersNestedRoutes = new Route({
  getParentRoute: () => settingsCloudAccountsRoute,
  path: "$",
});
