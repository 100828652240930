import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../../inventoryLayoutRoute";

export const AssetInventoryCloudTypeServiceName = lazy(
  () => import("./AssetInventoryCloudTypeServiceName"),
);

export const assetInventoryCloudTypeServiceNameRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "cloud/$cloudType/service/$serviceName",
  component: AssetInventoryCloudTypeServiceName,
});
