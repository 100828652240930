import { createContext, type ReactNode } from "react";
import { type Monitoring } from "../Monitoring";

export const MonitoringContext = createContext<null | Monitoring>(null);

export const MonitoringProvider = ({
  children,
  monitoringInstance,
}: {
  children: ReactNode;
  monitoringInstance: Monitoring;
}) => {
  return (
    <MonitoringContext.Provider value={monitoringInstance}>
      {children}
    </MonitoringContext.Provider>
  );
};

export default MonitoringProvider;
