import { type Severity } from "requests";

import { CriticalSeverityIcon } from "./CriticalSeverityIcon";
import { HighSeverityIcon } from "./HighSeverityIcon";
import { InformationalSeverityIcon } from "./InformationalSeverityIcon";
import { LowSeverityIcon } from "./LowSeverityIcon";
import { MediumSeverityIcon } from "./MediumSeverityIcon";

import { CriticalSeverityVulnerabilityIcon } from "./CriticalSeverityVulnerabilityIcon";
import { HighSeverityVulnerabilityIcon } from "./HighSeverityVulnerabilityIcon";
import { LowSeverityVulnerabilityIcon } from "./LowSeverityVulnerabilityIcon";
import { MediumSeverityVulnerabilityIcon } from "./MediumSeverityVulnerabilityIcon";

import { type SvgIconProps } from "../SvgIcon";

export interface SeverityIconProps extends SvgIconProps {
  level: Severity;
  type?: "alert" | "vulnerability";
}

/**
 * Icons for severity level
 *
 * Level-specific icons are available as well as a generic icon that accepts a type and renders the appropriate severity icon.
 */
export const SeverityIcon = (props: SeverityIconProps) => {
  // eslint-disable-next-line react/prop-types
  const { level, type = "alert", ...passThrough } = props;
  switch (type) {
    case "alert": {
      switch (level) {
        case "critical":
          return <CriticalSeverityIcon {...passThrough} />;
        case "high":
          return <HighSeverityIcon {...passThrough} />;
        case "medium":
          return <MediumSeverityIcon {...passThrough} />;
        case "low":
          return <LowSeverityIcon {...passThrough} />;
        case "informational":
          return <InformationalSeverityIcon {...passThrough} />;
        /* c8 ignore next */
        /* The clause catches non-typescript errors */
        default:
          if (process.env.NODE_ENV === "development") {
            throw new Error(`Unexpected severity level: ${level}`);
          }
          return null;
      }
    }
    case "vulnerability": {
      switch (level) {
        case "critical":
          return <CriticalSeverityVulnerabilityIcon {...passThrough} />;
        case "high":
          return <HighSeverityVulnerabilityIcon {...passThrough} />;
        case "medium":
          return <MediumSeverityVulnerabilityIcon {...passThrough} />;
        case "low":
          return <LowSeverityVulnerabilityIcon {...passThrough} />;
        case "informational":
          return <InformationalSeverityIcon {...passThrough} />;
        /* c8 ignore next */
        /* The clause catches non-typescript errors */
        default:
          if (process.env.NODE_ENV === "development") {
            throw new Error(`Unexpected severity level: ${level}`);
          }
          return null;
      }
    }
    default:
      if (process.env.NODE_ENV === "development") {
        throw new Error(`Unexpected severity type: ${type}`);
      }
      return null;
  }
};
