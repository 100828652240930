import { FormattedMessage } from "react-intl";

import { Input, Link } from "ui";

export const getInstructions = ({
  isAzureActiveDirSelected,
  isGov,
}: {
  isAzureActiveDirSelected: boolean;
  isGov: boolean;
}) =>
  [
    <FormattedMessage
      defaultMessage="Download the Terraform script"
      id="t3AuwC"
      description="Instructions for downloading the terraform script"
      key="Download the Terraform script"
    />,
    <FormattedMessage
      defaultMessage="Login to the <a>Azure portal cloud shell(Bash)</a>"
      id="ojNkgp"
      description="Instructions for logging to Azure portal"
      key="Login to the Azure portal cloud shell(Bash)"
      values={{
        a: (chunks) => (
          <Link
            size="sm"
            href={
              isGov
                ? "https://portal.azure.us/#cloudshell"
                : "https://portal.azure.com/#cloudshell"
            }
            external
            data-selector="azure-console-link"
          >
            {chunks}
          </Link>
        ),
      }}
    />,
    <>
      <FormattedMessage
        defaultMessage="Upload the script to the Cloud Shell and run the following commands"
        id="U8xxbv"
        description="Instructions for uploading the script to cloud shell"
      />
      <Input
        allowCopy
        readOnly
        value="terraform init"
        data-selector="gcp-terraform-textarea"
      />
      <Input
        addClassName="mt-2"
        allowCopy
        readOnly
        value="terraform apply"
        data-selector="gcp-terraform-textarea"
      />
    </>,
    isAzureActiveDirSelected ? (
      <FormattedMessage
        defaultMessage="After the terraform script is run, click on the link and click the Grant admin consent"
        id="I1o7Z8"
        description="Instructions for granting the admin consent"
      />
    ) : null,
    <FormattedMessage
      defaultMessage="Enter the form details from the script output"
      id="/XyNUR"
      description="Instructions for entering the form fields"
      key="Enter the form details from the script output"
    />,
  ].filter((v) => v);
