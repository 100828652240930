import { forwardRef } from "react";
import { classNames } from "utils";

import { type ComponentPropsWithRef } from "react";

export type RadioInputProps = Omit<
  ComponentPropsWithRef<"input">,
  "className" | "type" | "enterKeyHint"
>;

/**
 * A styled radio input
 */
export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (props: RadioInputProps, ref) => {
    return (
      <input
        className={classNames(
          "form-radio h-4 w-4 border border-gray-300 text-link",
          "dark:border-blue-steel-850 dark:bg-blue-steel-950",
          "focus:ring-blue-300 focus:ring-offset-0 dark:ring-blue-800",
          props.disabled ? "bg-gray-100" : "cursor-pointer hover:ring",
        )}
        type="radio"
        {...props}
        ref={ref}
      />
    );
  },
);

RadioInput.displayName = "RadioInput";
