import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 14 15"}>
      <path d="M2.25 1H0.5C0.22388 1 0 1.22382 0 1.5V3.5C0 3.77612 0.22388 4 0.5 4H13.5C13.7761 4 14 3.77612 14 3.5V1.5C14 1.22382 13.7761 1 13.5 1H11.75V0.5C11.75 0.22382 11.5261 0 11.25 0H10.75C10.4739 0 10.25 0.22382 10.25 0.5V1H3.75V0.5C3.75 0.22382 3.52612 0 3.25 0H2.75C2.47388 0 2.25 0.22382 2.25 0.5V1Z" />
      <path d="M0 14.5V6C0 5.72386 0.22386 5.5 0.5 5.5H13.5C13.7761 5.5 14 5.72386 14 6V9C14 10.1046 13.1046 11 12 11H10.5C10.2239 11 10 11.2239 10 11.5V13C10 14.1046 9.10457 15 8 15H0.5C0.22386 15 0 14.7761 0 14.5ZM2.90169 7.30338C2.57127 7.30338 2.30338 7.5712 2.30338 7.90169V9.09831C2.30338 9.42873 2.57128 9.69662 2.90169 9.69662H4.09831C4.42873 9.69662 4.69662 9.42872 4.69662 9.09831V7.90169C4.69662 7.5712 4.42872 7.30338 4.09831 7.30338H2.90169Z" />
    </SvgIcon>
  );
};
