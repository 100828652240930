import { Route, redirect } from "@tanstack/react-router";
import { isGov } from "environment";
import { useUCDModeStore } from "prisma";
import { lazy } from "react";
import { z } from "zod";
import { privateRootRoute } from "../../privateRootRoute";

const HomeLayout = lazy(() =>
  import("./HomeLayout").then((res) => ({
    default: res.HomeLayout,
  })),
);

export const homeLayoutRoute = new Route({
  getParentRoute: () => privateRootRoute,
  path: "/home",
  component: HomeLayout,
  validateSearch: z.object({
    skipAnimation: z.optional(z.boolean()),
  }),
});

export const homeIndexRoute = new Route({
  getParentRoute: () => homeLayoutRoute,
  path: "/",
  beforeLoad: ({ search }) => {
    const ucdMode = useUCDModeStore.getState().mode;

    if (ucdMode === "runtime") {
      throw redirect({ to: "/home/runtime", replace: true, search });
    }
    if (ucdMode === "application") {
      if (isGov()) {
        throw redirect({
          to: "/home/appsec/projects",
          replace: true,
        });
      } else {
        throw redirect({
          to: "/home/appsec/repositories",
          replace: true,
          search,
        });
      }
    }
    if (ucdMode === "data") {
      throw redirect({
        to: "/home/data/overview",
        replace: true,
        search,
      });
    }

    throw redirect({
      to: "/home/cloud/welcome",
      replace: true,
      search,
    });
  },
});
