import IBMSecurityCapabilitiesPermissions, {
  Misconfigurations,
} from "../../../../../components/ReviewStatus/SecurityCapabilitiesPermissions";
import { type Status } from "../../../../../types";

type SecurityCapabilitiesDataProps = {
  accountStatus: {
    id: string;
    name: string;
    status: Status;
    statusMessage: {
      message: string;
      staticMessage: boolean;
    };
  }[];
  isLoading: boolean;
};

function getSecurityCapabilitiesData(
  data: SecurityCapabilitiesDataProps["accountStatus"],
) {
  const misconfigurationsData = data.filter(
    ({ name }) => name?.toLowerCase() === "config",
  );
  return {
    misconfigurationsData,
  };
}

export default function SecurityCapabilitiesPermissions({
  accountStatus = [],
  isLoading,
}: SecurityCapabilitiesDataProps) {
  const statusData = accountStatus.map((data) => ({
    ...data,
    message: data?.statusMessage?.message,
  }));

  const { misconfigurationsData } = getSecurityCapabilitiesData(statusData);

  return (
    <IBMSecurityCapabilitiesPermissions isLoading={isLoading}>
      <Misconfigurations
        data={misconfigurationsData}
        isCollapsibleDetails={true}
      />
    </IBMSecurityCapabilitiesPermissions>
  );
}
