import { z } from "zod";
import { CloudTypesEnumSchema } from "../../cloudType";

export const CloudTableAccountTypeSchema = z.union([
  z.literal("account"),
  z.literal("management_group"),
  z.literal("masterServiceAccount"),
  z.literal("organization"),
  z.literal("subscription"),
  z.literal("tenant"),
  z.literal("compartment"),
  z.literal("workspace_domain"),
]);

export type CloudTableAccountType = z.infer<typeof CloudTableAccountTypeSchema>;

export const CloudAccountsTableSchema = z.object({
  name: z.string(),
  cloudType: CloudTypesEnumSchema,
  accountType: CloudTableAccountTypeSchema,
  enabled: z.boolean(),
  lastModifiedTs: z.number(),
  lastModifiedBy: z.string(),
  ingestionMode: z.number(),
  associatedAccountGroupsCount: z.number(),
  groups: z.array(z.string()),
  status: z.string(),
  numberOfChildAccounts: z.number(),
  accountId: z.string(),
  addedOn: z.number(),
  groupIds: z.array(z.unknown()),
  cloudAccountOwner: z.string().optional(),
  cloudAccountOwnerCount: z.number().optional(),
  deploymentType: z.string().optional(),
});

export const CloudAccountsTableParamsSchema = z.object({
  excludeAccountGroupDetails: z.union([z.literal("true"), z.literal("false")]),
  includePendingAccounts: z
    .union([z.literal("true"), z.literal("false")])
    .optional(),
});

export const CloudAccountsTableResponseSchema = z.array(
  CloudAccountsTableSchema,
);

export const STATUS_KEYS = {
  ok: "ok",
  disabled: "disabled",
  error: "error",
  warning: "warning",
  pending: "pending",
} as const;

export type StatusKeys = keyof typeof STATUS_KEYS;

export type CloudAccountsTable = z.infer<typeof CloudAccountsTableSchema>;
