import { type ComponentPropsWithoutRef, type ReactElement } from "react";
import { FormattedDate } from "react-intl";

import { Tooltip } from "../Tooltip";
import { Body, type BodyProps } from "../Typography";
import { DistanceToNow, type DistanceToNowProps } from "./DistanceToNow";

export interface RelativeTimestampProps
  extends Omit<BodyProps, "as" | "children"> {
  /**
   * Supports the same options as [Intl.RelativeTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#parameters)
   */
  formatOptions?: Omit<DistanceToNowProps, "value">;
  /**
   * Supports the same options as [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters)
   */
  tooltipFormatOptions?: Omit<
    ComponentPropsWithoutRef<typeof FormattedDate>,
    "value"
  >;
  value: number | string | Date;
}

/**
 * Renders a relative timestamp with the absolute time in a tooltip.
 *
 * The base formatting, `formatOptions`, supports the same options as [Intl.RelativeTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#parameters).
 *
 * The tooltip formatting, `tooltipFormatOptions`, supports the same options as [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters).
 *
 * ### Import Guide
 *
 * ```jsx
 * import { RelativeTimestamp } from "ui";
 * ```
 */
export function RelativeTimestamp({
  formatOptions,
  suppressTooltip = false,
  tooltipFormatOptions,
  value,
  ...passThrough
}: RelativeTimestampProps & { suppressTooltip?: boolean }): ReactElement {
  return suppressTooltip ? (
    <span>
      <Body as="span" {...passThrough}>
        <DistanceToNow value={value} {...formatOptions} />
      </Body>
    </span>
  ) : (
    <Tooltip label={<FormattedDate value={value} {...tooltipFormatOptions} />}>
      <span>
        <Body as="span" {...passThrough}>
          <DistanceToNow value={value} {...formatOptions} />
        </Body>
      </span>
    </Tooltip>
  );
}
