import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";
import { classNames } from "utils";

export const AgentlessApiDiscoveryIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Agentless API Discovery",
    id: "yd4yVb",
    description: "Security Capabilities Class Icon alt text",
  });

  // eslint-disable-next-line react/prop-types
  const { disabled, ...passThrough } = props;
  const iconClassName = classNames(
    "dark:fill-white",
    disabled ? "fill-[#DADBDB]" : "fill-[#7A7B7B]",
  );

  return (
    <SvgIcon viewBox="0 0 16 12" {...passThrough}>
      <title>{alt}</title>

      <path
        className={iconClassName}
        d="M8.57362 3.85292C7.99153 4.44156 7.39095 5.44046 7.39095 6.66234C7.39095 8.86528 9.24811 10.658 11.5303 10.658C13.8125 10.658 15.6696 8.86528 15.6696 6.66234C15.6696 4.66607 14.5241 3.64296 13.5215 2.7475C12.7413 2.05069 12.0477 1.43117 12.0477 0.490534C12.057 0.187295 11.7982 0 11.5395 0C11.3825 0 11.2161 0.0713504 11.1053 0.22297C9.3712 2.67296 10.3648 3.60337 11.2746 4.45536C11.7657 4.91532 12.2325 5.35242 12.2325 5.99343C12.2325 6.73369 11.6135 7.32233 10.8558 7.32233H10.8373C10.0704 7.32233 9.46986 6.70693 9.46986 5.97559V4.19183C9.46986 3.88859 9.21115 3.69238 8.95245 3.69238C8.81385 3.69238 8.67526 3.74589 8.57362 3.85292ZM0.895264 2.73726H5.25635V5.82316H0.895264V2.73726ZM0.895264 11.1842H5.25635V14.2701H0.895264V11.1842ZM11.4145 11.5543C11.4561 11.5565 11.4977 11.5588 11.5393 11.5588C12.2138 11.5588 12.8605 11.425 13.4519 11.1842H15.6786V14.2701H11.2898V11.5498C11.3314 11.5498 11.373 11.5521 11.4145 11.5543ZM9.61741 11.1842H6.40203V14.2701H10.2642V11.3893C10.0424 11.3358 9.82068 11.2734 9.61741 11.1842ZM0.895264 6.96558H6.48522C6.54066 7.77719 6.79013 8.52637 7.20591 9.18636V10.0515H0.895264V6.96558Z"
      />
    </SvgIcon>
  );
};
