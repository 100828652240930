import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { investigateLayoutRoute } from "../investigateLayoutRoute";

const QueryLibrary = lazy(() => import("./QueryLibrary"));

export const queryLibraryRoute = new Route({
  getParentRoute: () => investigateLayoutRoute,
  path: "query-library",
  component: QueryLibrary,
});
