import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const OtherIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Other",
    id: "FrvitM",

    description: "cloud icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} viewBox="0 0 24 24" {...props}>
      {showNativeTooltip && <title>{alt}</title>}
      <path
        d="M19.3555 10.0352C18.6758 6.59375 15.6406 4 12 4C9.10938 4 6.60156 5.64063 5.35156 8.03516C2.34375 8.35938 0 10.9062 0 14C0 17.3125 2.6875 20 6 20H19C21.7617 20 24 17.7617 24 15C24 12.3594 21.9492 10.2227 19.3555 10.0352ZM19 18H6C3.79297 18 2 16.207 2 14C2 11.9531 3.53125 10.2422 5.56641 10.0234L6.62891 9.91016L7.125 8.96094C8.07812 7.13672 9.94531 6 12 6C14.6172 6 16.8867 7.85938 17.3945 10.4258L17.6914 11.9219L19.2148 12.0312C20.7773 12.1406 22 13.4453 22 15C22 16.6523 20.6523 18 19 18Z"
        className="fill-[#333333] dark:fill-white"
      />
    </SvgIcon>
  );
};
