import { useId } from "react";
import { useIntl } from "react-intl";
import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AzureIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Azure",
    id: "xuLUUB",

    description: "cloud icon alt text",
  });
  const id = useId();

  return (
    <SvgIcon aria-label={alt} viewBox="0 0 24 24" {...props}>
      {showNativeTooltip && <title>{alt}</title>}
      <path
        d="M8.67156 3.01905H14.5827L8.44631 21.2113C8.41493 21.3042 8.36927 21.3917 8.31096 21.4705C8.25278 21.5495 8.1826 21.6186 8.10294 21.6758C8.02328 21.733 7.93538 21.7775 7.84198 21.8074C7.74859 21.8374 7.65119 21.8529 7.5533 21.8529H2.95292C2.80358 21.8529 2.65625 21.8174 2.52339 21.749C2.3904 21.6808 2.2756 21.5818 2.18851 21.4603C2.10138 21.3388 2.04437 21.1984 2.0222 21.0505C1.99997 20.9027 2.01346 20.7516 2.06116 20.61L7.7783 3.66009C7.80952 3.56719 7.85522 3.47979 7.91365 3.40088C7.97184 3.32197 8.04226 3.25279 8.12167 3.19561C8.20133 3.13842 8.28923 3.09397 8.38263 3.064C8.47603 3.03404 8.57342 3.01855 8.67131 3.01855V3.0188L8.67156 3.01905Z"
        fill={`url(#${id}-0)`}
      />
      <path
        d="M19.5685 21.8531H14.2606C14.2014 21.8531 14.1423 21.8476 14.0838 21.8363C14.0256 21.8253 13.9684 21.8089 13.9133 21.7871C13.8581 21.7654 13.8051 21.7384 13.7549 21.707C13.7048 21.6753 13.6577 21.639 13.6143 21.5986L7.59103 15.9733C7.52733 15.9139 7.48296 15.8366 7.46367 15.7516C7.44441 15.6666 7.45119 15.5777 7.48312 15.4966C7.51506 15.4155 7.57067 15.3459 7.64273 15.2968C7.71489 15.2479 7.80005 15.2217 7.8872 15.2217H17.2608L19.568 21.8533L19.5685 21.8531Z"
        className="fill-[#0078D4] dark:fill-[#E1E1E1]"
      />
      <path
        d="M14.604 3.01905L10.4661 15.2227L17.2271 15.2212L19.5463 21.8529H14.2584C14.2037 21.8524 14.149 21.8474 14.0951 21.8376C14.0411 21.8279 13.9882 21.8137 13.9367 21.7947C13.8336 21.7571 13.7376 21.7021 13.6531 21.6321L9.58708 17.8373L8.44885 21.1941C8.41938 21.278 8.37892 21.3577 8.32848 21.4308C8.22738 21.578 8.08908 21.6958 7.92767 21.7722C7.84731 21.8102 7.76224 21.8374 7.67471 21.8529H2.9557C2.80487 21.8534 2.65603 21.8174 2.52193 21.748C2.38781 21.6786 2.27236 21.578 2.18534 21.4546C2.09832 21.3312 2.04229 21.1887 2.02199 21.039C2.00176 20.8893 2.01777 20.737 2.06869 20.5948L7.77659 3.67283C7.80631 3.57818 7.85101 3.48903 7.90895 3.40862C7.96663 3.32821 8.03705 3.25729 8.11696 3.19885C8.19688 3.14042 8.28578 3.09497 8.37992 3.0645C8.47407 3.03404 8.57271 3.01856 8.67185 3.01905H14.6043H14.604Z"
        fill={`url(#${id}-1)`}
      />
      <path
        d="M21.9388 20.61C21.9865 20.7517 22 20.9026 21.978 21.0505C21.9558 21.1983 21.8989 21.3387 21.812 21.4603C21.7249 21.5817 21.6101 21.6806 21.4772 21.7489C21.3443 21.8172 21.197 21.8528 21.0476 21.8529H14.4594C14.6087 21.8529 14.7561 21.8172 14.8892 21.749C15.022 21.6808 15.1369 21.5817 15.224 21.4603C15.3112 21.3389 15.3681 21.1983 15.3904 21.0505C15.4126 20.9027 15.3991 20.7516 15.3514 20.61L9.634 3.65958C9.57107 3.47286 9.45115 3.31058 9.29113 3.1956C9.21172 3.13841 9.12382 3.09421 9.03067 3.06424C8.93753 3.03428 8.84013 3.01904 8.74249 3.01904H15.3304C15.4283 3.01904 15.5255 3.03428 15.6188 3.06424C15.712 3.09421 15.7999 3.13866 15.8796 3.1956C15.959 3.25278 16.0291 3.32196 16.0873 3.40062C16.1455 3.47928 16.191 3.56668 16.2224 3.65958L21.9398 20.61H21.9388Z"
        fill={`url(#${id}-2)`}
      />
      <defs>
        <linearGradient
          id={`${id}-0`}
          x1="10.8289"
          y1="4.415"
          x2="4.68574"
          y2="22.5623"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="[stop-color:#114A8B] dark:[stop-color:#C2C1C1]" />
          <stop
            offset="1"
            className="[stop-color:#0669BC] dark:[stop-color:#D9D9D9]"
          />
        </linearGradient>
        <linearGradient
          id={`${id}-1`}
          x1="12.7443"
          y1="12.8706"
          x2="11.3234"
          y2="13.3526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.1" stopOpacity="0.2" />
          <stop offset="0.3" stopOpacity="0.1" />
          <stop offset="0.6" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={`${id}-2`}
          x1="11.9602"
          y1="3.88558"
          x2="18.7027"
          y2="21.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="[stop-color:#3CCBF4] dark:[stop-color:#F4F4F4]" />
          <stop
            offset="1"
            className="[stop-color:#2892DF] dark:[stop-color:#D6D6D6]"
          />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
