import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

export const AssetInventoryRegion = lazy(
  () => import("./AssetInventoryRegion"),
);

export const assetInventoryRegionRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "region/$regionName/cloud/$cloud",
  component: AssetInventoryRegion,
});
