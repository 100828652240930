import { useNavigate, useRouterState, useSearch } from "@tanstack/react-router";
import { useCallback, useRef } from "react";
import * as R from "remeda";
import { type z } from "zod";
import { type pageTableProviderSchema } from "./pageTableProviderSchema";

type SearchType = z.infer<typeof pageTableProviderSchema>;

export const useOnPaginationChange = () => {
  const {
    location: { pathname },
  } = useRouterState();
  const navigate = useNavigate();
  const search: SearchType = useSearch({ strict: false });
  const previousPage = useRef<SearchType["page"] | undefined>();

  const onPaginationChange = useCallback(
    ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
      const page = { ...search.page };

      if (pageIndex + 1 !== search.page?.number) page.number = pageIndex + 1;
      if (pageSize !== search.page?.size) page.size = pageSize;

      const pageChanged = !R.equals(page, previousPage.current);

      if (previousPage.current && !pageChanged) return;

      navigate({
        to: pathname,
        search: { ...search, page },
        replace: true,
      });

      previousPage.current = page;
    },
    // Only run when page params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.page, navigate, pathname],
  );

  return { onPaginationChange };
};
