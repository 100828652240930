import { ftuExperiencePreferenceSchema, usePreferences } from "requests";

import { Link } from "@tanstack/react-router";
import { useMemo } from "react";

export const SubNavRouterLink: typeof Link = (props) => {
  const { data: ftuData } = usePreferences(
    "ftuExperience",
    "config",
    ftuExperiencePreferenceSchema,
  );

  const link = useMemo(() => {
    const theme = ftuData?.config?.theme?.color;

    return {
      linkClass: `rounded py-2 px-1.5 font-semibold ${theme} hover:bg-skin-fill hover:no-underline active:text-link  text-xs`,
      activeLinkClass: `${theme} bg-skin-fill text-black dark:text-white`,
    };
  }, [ftuData]);

  return (
    <Link
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      className={link?.linkClass}
      activeProps={{ className: link?.activeLinkClass }}
    />
  );
};
