import { FormattedMessage } from "react-intl";
import { Body, Button, Title, useDarkMode } from "ui";
import { RouterLink } from "../../components/Navigation/RouterLink/RouterLink";

import BaseDark from "./base-dark.svg";
import BaseLight from "./base-light.svg";
import CrystalCenterDark from "./crystal-center-dark.svg";
import CrystalCenterLight from "./crystal-center-light.svg";
import CrystalLeftDark from "./crystal-left-dark.svg";
import CrystalLeftLight from "./crystal-left-light.svg";
import CrystalRightDark from "./crystal-right-dark.svg";
import CrystalRightLight from "./crystal-right-light.svg";

export function CatchAll() {
  const { isDark } = useDarkMode();

  return (
    <div className="flex grow flex-col items-center justify-center">
      <div className="relative h-80 w-64">
        <img
          src={isDark ? BaseDark : BaseLight}
          className="absolute inset-x-0 bottom-0 mx-auto"
          alt=""
        />
        <img
          src={isDark ? CrystalLeftDark : CrystalLeftLight}
          className="absolute left-0 top-0 mt-12 [animation-delay:5s] motion-safe:animate-hover"
          alt=""
        />
        <img
          src={isDark ? CrystalRightDark : CrystalRightLight}
          className="absolute right-0 top-0 mr-2 mt-4 [animation-delay:5s] motion-safe:animate-hover"
          alt=""
        />
        <img
          src={isDark ? CrystalCenterDark : CrystalCenterLight}
          className="absolute inset-x-0 top-0 mx-auto -mt-4 motion-safe:animate-hover"
          alt=""
        />
      </div>
      <div className="mt-4 space-y-4 text-center">
        <Title level={1}>
          <FormattedMessage
            defaultMessage="This Page Is Empty"
            id="E0210y"
            description="404 missing page message"
          />
        </Title>
        <Body>
          <FormattedMessage
            defaultMessage="Uh oh! This page doesn’t exist. Let’s help you get home."
            id="ojWQl0"
            description="404 missing page description"
          />
        </Body>
        <RouterLink withChild to={"/home"}>
          <Button as="span" appearance="primary" addClassName="mx-auto mt-4">
            <FormattedMessage
              defaultMessage="Take me back to Home"
              id="LbJ3fk"
              description="404 missing page button"
            />
          </Button>
        </RouterLink>
      </div>
    </div>
  );
}
