import { Bold } from "ui";

export function Version() {
  return (
    <ul className="m-4 space-y-2">
      <li>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Bold>Tag</Bold>: {import.meta.env.VITE_UI_TAG || "unknown"}
      </li>
      <li>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Bold>Version</Bold>: {import.meta.env.VITE_UI_SHA || "unknown"}
      </li>
      <li>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Bold>Branch</Bold>: {import.meta.env.VITE_UI_REF_NAME || "unknown"}
      </li>
    </ul>
  );
}
