import { type Dispatch, type SetStateAction } from "react";
import { type SelectItem } from "ui";
import SNSTopicInput from "./subcomponents/SNSTopicInput";
import SNSTopicSelect from "./subcomponents/SNSTopicSelect";
import SNSTopicType from "./subcomponents/SNSTopicType";

type SNSTopicRowPropTypes = {
  cloudTrailTopic: string | undefined;
  setCloudTrailTopic: Dispatch<SetStateAction<string | undefined>>;
  setTopicType: Dispatch<SetStateAction<SelectItem>>;
  topicType: SelectItem;
  topicItems: SelectItem[] | undefined;
};

export default function SNSTopicRow({
  cloudTrailTopic = "",
  setCloudTrailTopic,
  setTopicType,
  topicType,
  topicItems = [],
}: SNSTopicRowPropTypes) {
  return (
    <div className="mb-3 mt-1 flex w-full">
      <div className="w-1/4">
        <SNSTopicType
          selectedItem={topicType}
          setCloudTrailTopic={setCloudTrailTopic}
          setSelectedType={setTopicType}
        />
      </div>
      <div className="mx-2 w-3/4">
        {topicType.value === "Select Existing" && (
          <SNSTopicSelect
            cloudTrailTopic={cloudTrailTopic}
            disabled={!topicItems?.length}
            items={topicItems}
            setCloudTrailTopic={setCloudTrailTopic}
          />
        )}
        {topicType.value === "Add New" && (
          <SNSTopicInput
            cloudTrailTopic={cloudTrailTopic}
            setCloudTrailTopic={setCloudTrailTopic}
          />
        )}
      </div>
    </div>
  );
}
