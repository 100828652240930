import { useIntl } from "react-intl";

import { Input } from "form";

export default function ApplicationKey({
  onBlur,
}: {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}) {
  const intl = useIntl();
  return (
    <Input
      data-selector="application-key-input"
      name="key"
      label={intl.formatMessage({
        defaultMessage: "Application Client Secret",
        id: "SAZ7ec",
        description:
          "The Application Client Secret of an input field for onboarding an Azure account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Your Application Client Secret",
        id: "dmgY0t",
        description:
          "Placeholder for the Application Client Secret of an input field for onboarding an Azure account",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Application Client Secret is required",
          id: "k3UViz",
          description: "Required message for cloud account",
        }),
      }}
      type="password"
      autoComplete="new-password"
    />
  );
}
