import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

import { useIntl } from "react-intl";

export const IntegrationsQualysIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Qualys",
    id: "qkWyCi",

    description: "Integrations Qualys icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M49.9063 10C71.9753 10 83.0098 21.4943 83.0098 21.4943C83.0098 21.4943 84.3891 30.2299 83.4696 47.7011C82.0902 73.908 49.9063 90 49.9063 90C49.9063 90 17.7224 73.908 16.3431 47.7011C15.8833 30.2299 16.8029 21.4943 16.8029 21.4943C16.8029 21.4943 27.8374 10 49.9063 10Z"
        className="fill-[#ED2E26] dark:fill-white"
      />
      <path
        d="M33.3546 44.4827C33.3546 35.2873 40.7109 27.4712 49.9063 27.4712C59.1017 27.4712 66.458 34.8275 66.458 44.0229C66.458 51.3792 60.0212 57.3562 55.4235 60.1148C57.2626 61.0344 61.4006 62.4137 65.5385 62.8735C71.5155 58.2758 74.7339 51.3792 74.7339 44.0229C74.7339 38.5057 72.8948 32.9884 69.6764 28.8505C65.0787 23.3332 58.1822 19.1953 50.3661 19.1953C36.573 19.1953 25.5385 30.2298 25.5385 43.5631C25.5385 51.839 28.7569 59.1953 35.1937 64.7126C41.1707 69.77 49.9063 73.4482 60.0212 74.8275C62.3201 73.4482 65.9983 70.2298 67.3776 67.9309C59.1017 67.0114 50.8258 64.7126 45.7684 61.9539C37.4925 58.7355 33.3546 52.2988 33.3546 44.4827Z"
        className="fill-white dark:fill-none"
      />
    </SvgIcon>
  );
};
