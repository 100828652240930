import { z } from "zod";
import { TimeStampSchema } from "../../schemas";
import { FeatureSchema } from "./features";

export const ActiveRoleSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  active: z.literal(true),
  default: z.boolean(),
  permissionGroup: z.object({
    name: z.string(),
    custom: z.boolean(),
    features: z.array(FeatureSchema),
  }),
});

export const InactiveRoleSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  active: z.literal(false),
  permissionGroup: z.null(),
  default: z.boolean(),
});

export type Features = z.infer<typeof FeatureSchema>;
export type FeatureNames = Features["featureName"];
export type PermissionByName<E extends FeatureNames, T = Features> = T extends {
  featureName: E;
}
  ? T
  : never;

export type PermissionOperations = {
  [P in FeatureNames]: PermissionByName<P>["operations"];
};

export const GetProfileResponse = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  displayName: z.string(),
  timeZone: z.string(),
  // The first time a user logs in the lastLoginTs will be -1
  lastLoginTs: z.union([TimeStampSchema, z.literal(-1)]),
  accessKeysAllowed: z.boolean(),
  roles: z.array(
    z.discriminatedUnion("active", [ActiveRoleSchema, InactiveRoleSchema]),
  ),
});

export const UserDomainsResponse = z.array(z.string()).optional();
