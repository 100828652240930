import { useTableInstance } from "../TableContext";
import { ResultsSummary } from "./ResultsSummary";

import { type ReactElement } from "react";

interface ConnectedResultsSummaryProps {
  total?: number;
}

export function ConnectedResultsSummary({
  total,
}: ConnectedResultsSummaryProps): ReactElement {
  const {
    enableRowSelect,
    enablePagination,
    isBackgroundFetching,
    isLoading,
    rows,
    page,
    state: { selectedRowIds, pageIndex, pageSize },
  } = useTableInstance();
  const pageRecord = pageSize * pageIndex;
  const totalRecords = total ?? rows.length;
  const startingRecord = pageRecord + 1;
  const endingRecord = enablePagination
    ? pageRecord + page.length
    : totalRecords;
  const selectedCount = Object.keys(selectedRowIds).length;

  return (
    <ResultsSummary
      startingRecord={startingRecord}
      endingRecord={endingRecord}
      selected={enableRowSelect ? selectedCount : undefined}
      totalRecords={totalRecords}
      isBackgroundFetching={isBackgroundFetching}
      isLoading={isLoading}
    />
  );
}
