import { lazy } from "react";
import { createRoute, privateRootRoute } from "router";

const Template = lazy(() =>
  import("./Template").then((res) => ({
    default: res.Template,
  })),
);

export const templateRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "template",
  component: Template,
});
