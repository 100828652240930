import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "ui";

type SaveAndCloseButtonProps = {
  disabled: boolean;
  onSubmit: (arg: { openNewOnboarding: boolean }) => void;
};

export default function SaveAndCloseButton({
  disabled,
  onSubmit,
}: SaveAndCloseButtonProps) {
  const [isLoading, setLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    onSubmit({ openNewOnboarding: false });
    setLoading(false);
  }, [onSubmit]);

  return (
    <Button
      data-selector="submit-close-button"
      appearance="primary"
      onClick={handleOnClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      <FormattedMessage
        defaultMessage="Save and Close"
        id="CE9CCp"
        description="Button for saving cloud account"
      />
    </Button>
  );
}
