import { z } from "zod";
import { TimeRangeSchema } from "../../../schemas";
import { CloudTypesEnumSchema } from "../../cloudType";
import { type ComplianceDetailType } from "../posture";

export const ComplianceStandardsFiltersSchema = z
  .object({
    "account.group": z.array(z.string()),
    "cloud.account": z.array(z.string()),
    "cloud.region": z.array(z.string()),
    "resource.group": z.array(z.string()),
    "cloud.type": z.array(CloudTypesEnumSchema),
    "policy.complianceStandard": z.array(z.string()),
    "policy.complianceRequirement": z.array(z.string()),
    "policy.complianceSection": z.array(z.string()),
    "resource.tagv2": z.array(z.string()).optional(), // TODO: remove optional after complianceSearchAssetTags flag is fully enabled
    "resourceList.tag": z.array(z.string()).optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
    timeRange: TimeRangeSchema,
  })
  .partial();
export type ComplianceStandardsFilters = z.infer<
  typeof ComplianceStandardsFiltersSchema
>;

export type ComplianceStandardType = z.infer<typeof ComplianceStandardSchema>;

export type MergedComplianceStandardsDataType = ComplianceStandardType &
  Omit<ComplianceDetailType, keyof ComplianceStandardType>;

export const ComplianceStandardSchema = z.object({
  id: z.string().uuid(),
  description: z.string().optional(),
  name: z.string(),
  policiesAssignedCount: z.number().nonnegative().optional(),
  createdBy: z.string().optional(),
  createdOn: z.number().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedOn: z.number().optional(),
  systemDefault: z.boolean().optional(),
  cloudType: z.array(CloudTypesEnumSchema).optional(),
});

export const ComplianceStandardsSchema = z.array(ComplianceStandardSchema);

export const ComplianceSectionsFiltersSchema = z
  .object({
    "account.group": z.array(z.string()),
    "cloud.account": z.array(z.string()),
    "cloud.region": z.array(z.string()),
    "resource.group": z.array(z.string()),
    "cloud.type": CloudTypesEnumSchema,
    "policy.complianceSection": z.array(z.string()),
    "resource.tagv2": z.array(z.string()).optional(), // TODO: remove optional after complianceSearchAssetTags flag is fully enabled
    "resourceList.tag": z.array(z.string()).optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
    timeRange: TimeRangeSchema,
  })
  .partial();

export const ComplianceRequirementsFiltersSchema = z
  .object({
    "account.group": z.array(z.string()),
    "cloud.account": z.array(z.string()),
    "cloud.region": z.array(z.string()),
    "resource.group": z.array(z.string()),
    "cloud.type": CloudTypesEnumSchema,
    "policy.complianceRequirement": z.array(z.string()),
    "policy.complianceSection": z.array(z.string()),
    "resource.tagv2": z.array(z.string()).optional(), // TODO: remove optional after complianceSearchAssetTags flag is fully enabled
    "resourceList.tag": z.array(z.string()).optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
    timeRange: TimeRangeSchema,
  })
  .partial();

export type MergedComplianceRequirementDataType = ComplianceRequirementType &
  Omit<ComplianceDetailType, keyof ComplianceRequirementType>;

export type ComplianceRequirementType = z.infer<
  typeof ComplianceRequirementSchema
>;

export const ComplianceRequirementSchema = z.object({
  id: z.string(),
  description: z.string().optional(),
  createdBy: z.string().optional(),
  createdOn: z.number().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedOn: z.number().optional(),
  systemDefault: z.boolean().optional(),
  policiesAssignedCount: z.number().optional(),
  name: z.string(),
  requirementId: z.string(),
  viewOrder: z.number().nonnegative().optional(),
});

export const ComplianceRequirementsSchema = z.array(
  ComplianceRequirementSchema,
);

export type MergedComplianceSectionDataType = ComplianceSectionType &
  Omit<ComplianceDetailType, keyof ComplianceSectionType>;

export type ComplianceSectionType = z.infer<typeof ComplianceSectionSchema>;

export const ComplianceSectionSchema = z.object({
  id: z.string(),
  description: z.string().optional(),
  createdBy: z.string().optional(),
  createdOn: z.number().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedOn: z.number().optional(),
  systemDefault: z.boolean().optional(),
  policiesAssignedCount: z.number().optional(),
  standardName: z.string().optional(),
  requirementName: z.string().optional(),
  requirementId: z.string().optional(),
  sectionId: z.string().optional(),
  viewOrder: z.number().positive().optional(),
  label: z.string().optional(),
});

export const ComplianceSectionsSchema = z.array(ComplianceSectionSchema);

export const ComplianceStandardRequestSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
});
export type ComplianceStandardRequest = z.infer<
  typeof ComplianceStandardRequestSchema
>;

export const ComplianceRequirementRequestSchema = z.object({
  requirementId: z.string(),
  name: z.string(),
  description: z.string().optional(),
});
export type ComplianceRequirementRequest = z.infer<
  typeof ComplianceRequirementRequestSchema
>;

export const ComplianceSectionRequestSchema = z.object({
  sectionId: z.string(),
  description: z.string().optional(),
});

export type ComplianceSectionRequest = z.infer<
  typeof ComplianceSectionRequestSchema
>;
