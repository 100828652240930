import {
  Children,
  cloneElement,
  useCallback,
  useState,
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from "react";

import { noop } from "utils";
import { type TabProps } from "./Tab";

export type TabListProps = {
  /**
   * The ID of the selected tab.
   */
  activeTabId: string;
  /**
   * Tab components.
   *
   * This type will not actually enforce that all the children are Tabs,
   * but the code expects it and will break if it's not.
   *
   * Falsy values will be filtered out, so it is acceptable to conditionally render Tabs.
   */
  children: ReactNode;
  /**
   * Disable all the tabs.
   */
  disabled?: boolean;
  /**
   * A function that is called when a tab is clicked.
   */
  onActiveChange?: (tabId: string) => void;
  /**
   * Boolean to indicate that tablist is part of the Page Header
   */
  isHeader?: boolean;
};

const buttonId = (target: EventTarget) => {
  const button =
    target instanceof Element ? (target as Element).closest("button") : null;

  return button?.disabled ? null : button?.id;
};

export const TabList = ({
  activeTabId,
  children,
  disabled = false,
  onActiveChange = noop,
  isHeader = false,
}: TabListProps): ReactElement => {
  const [focusedTabId, setFocusedTabId] = useState<string | null>(null);

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      const id = buttonId(e.target);

      if (!id) return;

      onActiveChange(id);
      setFocusedTabId(id);
    },
    [onActiveChange, setFocusedTabId],
  );

  const tabArray = Children.toArray(children).filter(
    Boolean,
  ) as ReactElement<TabProps>[];
  const enabledTabs = tabArray.filter((tab) => !tab.props.disabled);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
    /* c8 ignore next */
    /* testing arrow keys don't */
    (e) => {
      const id = buttonId(e.target);
      const rightArrow = "ArrowRight";
      const leftArrow = "ArrowLeft";
      const focusedTab = enabledTabs.find((tab) => tab.props.id === id);
      let nextFocusedTabIndex = focusedTab
        ? enabledTabs.indexOf(focusedTab)
        : 0;

      if (e.key === rightArrow || e.key === leftArrow) {
        e.preventDefault();

        // Move right
        if (e.key === rightArrow) {
          nextFocusedTabIndex++;

          // If we're at the end, go to the start
          if (nextFocusedTabIndex >= enabledTabs.length) {
            nextFocusedTabIndex = 0;
          }
        }

        // Move left
        if (e.key === leftArrow) {
          nextFocusedTabIndex--;

          // If we're at the start, move to the end
          if (nextFocusedTabIndex < 0) {
            nextFocusedTabIndex = enabledTabs.length - 1;
          }
        }

        setFocusedTabId(enabledTabs[nextFocusedTabIndex].props.id);
      }
    },
    [enabledTabs],
  );

  return (
    <>
      <div
        role="tablist"
        className={`flex grow overflow-auto ${
          !isHeader ? "border-b" : ""
        } border-gray-300 dark:border-blue-steel-850`}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <div className="flex w-full">
          {tabArray.map((tab) => {
            const focusOnTab =
              focusedTabId !== null && focusedTabId === tab.props.id;
            return cloneElement<TabProps>(tab, {
              ...tab.props,
              active: activeTabId === tab.props.id,
              disabled: disabled || tab.props.disabled,
              hasFocus:
                tab.props.hasFocus !== null ? tab.props.hasFocus : focusOnTab,
              isHeader: isHeader,
            });
          })}
        </div>
      </div>
    </>
  );
};
