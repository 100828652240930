import SecurityCapabilities from "../../../components/SecurityCapabilities";

import { IBM } from "../../../../../constants";

import { useFormContext } from "react-hook-form";

export default function SecurityCapabilitiesAndPermissions() {
  const { watch } = useFormContext();

  const [accountType] = watch(["accountType"]);

  return (
    <SecurityCapabilities
      cloudType={IBM}
      payload={{
        accountType,
      }}
    />
  );
}
