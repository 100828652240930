import {
  type ErrorRouteProps,
  type RouteComponent,
} from "@tanstack/react-router";
import { createLogs } from "requests";

export default function ErrorComponentWrapper(Component: RouteComponent) {
  return function ErrorComponentCallback(props: ErrorRouteProps) {
    return (
      <Component
        onError={(
          sentryEventId: string,
          error: Error,
          componentStack: string | null,
        ) => {
          createLogs({
            sentryEventId,
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            componentStack,
          });
        }}
        {...props}
      />
    );
  };
}

export function logError(
  sentryEventId: string,
  error: Error,
  componentStack: string | null,
): void {
  createLogs({
    sentryEventId,
    error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
    componentStack,
  });
}
