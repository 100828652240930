import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const StorageIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Storage",
    id: "SnVmbS",

    description: "Asset Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 14 17" {...props}>
      <title>{alt}</title>
      <path d="M1.99997 0C1.07914 0 0.333313 0.74583 0.333313 1.66666V15C0.333313 15.9209 1.07914 16.6667 1.99997 16.6667H12C12.9208 16.6667 13.6667 15.9209 13.6667 15V1.66666C13.6667 0.74583 12.9208 0 12 0H1.99997ZM1.99997 1.66666H12V5H1.99997V1.66666ZM1.99997 6.66665H12V9.99995H1.99997V6.66665ZM1.99997 11.6666H12V15H1.99997V11.6666Z" />
    </SvgIcon>
  );
};
