import { useToken, type UCDMode } from "requests";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const defaultUCDMode = undefined;

export type UCDModeState = {
  mode?: UCDMode;
  prismaId?: string;
};

export type UCDModeActions = {
  setMode: (
    mode?: UCDModeState["mode"],
    prismaId?: UCDModeState["prismaId"],
  ) => void;
};

export const useUCDModeStore = create(
  persist(
    immer<UCDModeState & UCDModeActions>((set) => ({
      mode: defaultUCDMode,
      prismaId: "",
      setMode: (mode, prismaId) => {
        set({ mode, prismaId });
      },
    })),
    {
      name: "ucdMode",
    },
  ),
);

export const useUCDMode = () => {
  const { prismaId } = useToken();

  return useUCDModeStore((state) => {
    if (state.prismaId === prismaId) {
      return state.mode;
    }

    return defaultUCDMode;
  });
};

export const useSetUCDMode = () => useUCDModeStore((state) => state.setMode);
