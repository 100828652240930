import { useCallback, useEffect, type ReactNode } from "react";
import { Form, type FormProps } from "../components/Form";

import { produce } from "immer";
import { useFormContext, type FieldValues } from "react-hook-form";
import { usePrevious } from "ui";
import { useWizardSubmitHandlers } from ".";
import { ModalWizardBody } from "./ModalWizardBody";
import { ModalWizardFooter } from "./ModalWizardFooter";
import { useWizard } from "./WizardContext";

export const WizardStepForm = <T extends FieldValues>({
  children,
  prepareData,
  onSubmit: submitForm,
  footer,
  ...formProps
}: FormProps<T> & {
  prepareData?: (draft: T) => void;
  footer?: ReactNode;
}) => {
  const { wizardValues, currentStep, setVisitedSteps, hideSideNav, hideTitle } =
    useWizard<string, T>();
  const { appendValuesMoveForward } = useWizardSubmitHandlers();
  useEffect(() => {
    setVisitedSteps((visitedSteps) => {
      visitedSteps[currentStep.name] = {
        ...visitedSteps[currentStep.name],
        visited: true,
      };
      return visitedSteps;
    });
  }, [currentStep.name, setVisitedSteps]);

  const saveValuesAndMoveNext = useCallback(
    (formValues: T, goTo: string) => {
      const preparedValues = prepareData
        ? produce(formValues, prepareData)
        : formValues;

      appendValuesMoveForward(preparedValues, goTo);
    },
    [appendValuesMoveForward, prepareData],
  );
  return (
    <Form
      onSubmit={async (formValues) => {
        submitForm
          ? await submitForm(formValues)
          : saveValuesAndMoveNext(formValues, currentStep.nextStepName ?? "");
      }}
      defaultValues={wizardValues}
      addClassName="flex flex-auto flex-col overflow-auto"
      {...formProps}
    >
      <>
        <ModalWizardBody<T>
          submit={saveValuesAndMoveNext}
          hideSideNav={hideSideNav}
          hideTitle={hideTitle}
        >
          {children}
        </ModalWizardBody>
        {footer ?? <ModalWizardFooter />}
        <SyncFormAndWizardState />
      </>
    </Form>
  );
};

// Having separate state for form and wizard is a slippery slope.  It works well
// until you start manually updating one of the states and not the other.  Manually
// updating form state is the preferred practice, as that will automatically get synced.
// However, there is a situation when a wizard first loads that relies on data, ie. edit,
// where the form may be initialized without all the data.  The ModalWizard handles updating
// it's state when the data is ready, but we need to enure the form data gets updated as
// well.  This component handles that.
const SyncFormAndWizardState = () => {
  const { getValues, reset } = useFormContext();
  const { wizardValues } = useWizard();
  const previousWizardValues = usePrevious(wizardValues);

  useEffect(() => {
    if (
      JSON.stringify(wizardValues) === JSON.stringify(previousWizardValues) ||
      JSON.stringify(wizardValues) === JSON.stringify(getValues())
    )
      return;

    reset(wizardValues);
  }, [getValues, previousWizardValues, reset, wizardValues]);
  return null;
};
