import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { anomaliesLayoutRoute } from "../anomaliesLayoutRoute";

export const AlertsThresholds = lazy(() => import("./AlertsThresholds"));

export const alertsThresholdsRoute = new Route({
  getParentRoute: () => anomaliesLayoutRoute,
  path: "alerts-thresholds",
  component: AlertsThresholds,
});
