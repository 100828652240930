import { FormattedMessage } from "react-intl";
import { Step2Icon } from "../../../icons";
import { DottedLine, StepNumber } from "./AWSConsoleSteps";

export default function Step2() {
  return (
    <div className="flex">
      <span className="inline-block">
        <Step2Icon size="5xl" />
      </span>
      <span className="mx-2 inline-block">
        <StepNumber stepNumber={2} />
      </span>
      <span className="mt-2 inline-block text-xs">
        <FormattedMessage
          defaultMessage="Select 'I acknowledge...' checkbox and click 'Create stack'."
          id="OX2VLc"
          description="Connect to the AWS Console Step2 description"
        />
      </span>
      <span className="mt-4 inline-block">
        <DottedLine />
      </span>
    </div>
  );
}
