import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { isChina } from "environment";

import { useIsBusinessLicenseType } from "prisma";
import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";

type IdentitySecurity = {
  data?: SecurityCapabilitiesPermissionsStatus;
  isCollapsibleDetails?: boolean;
};

export default function IdentitySecurity({
  data,
  isCollapsibleDetails = true,
}: IdentitySecurity) {
  const { isBusinessLicenseType } = useIsBusinessLicenseType();
  if (isChina() || isBusinessLicenseType) return null;
  if (isNil(data)) return null;

  return (
    <CollapsibleDetails
      icon={<SecurityCapabilitiesIcon type="Identity Security" />}
      label="identity_security"
      isCollapsibleDetails={isCollapsibleDetails}
      {...data}
    />
  );
}
