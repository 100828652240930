import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { noop } from "utils";
import {
  SomethingWentWrongErrorPage,
  SomethingWentWrongErrorSmall,
} from "../../SomethingWentWrongError";

export default function RouterErrorBoundary({
  error,
  info = { componentStack: null },
  appearance = "page",
  onError = noop,
}: {
  error: Error;
  info?: { componentStack: string | null };
  appearance?: "page" | "small";
  onError?(eventId: string, error: Error, componentStack: string | null): void;
}) {
  const [eventId, setEventId] = useState<string | undefined>(undefined);
  const { componentStack } = info;

  useEffect(() => {
    const sentryEventId = Sentry.captureException(error);
    if (sentryEventId && onError) onError(sentryEventId, error, componentStack);
    setEventId(sentryEventId);
  }, [componentStack, error, onError]);

  if (!eventId) return null;

  return appearance === "page" ? (
    <SomethingWentWrongErrorPage
      error={error}
      eventId={eventId}
      componentStack={componentStack}
    />
  ) : (
    <SomethingWentWrongErrorSmall
      error={error}
      eventId={eventId}
      componentStack={componentStack}
    />
  );
}
