import { useMemo } from "react";
import { useIntl } from "react-intl";
import { type RenderModalProps } from "ui";
import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import { AWS, SUMMARY } from "../../../constants";

import { stepTitle, wizardTitle } from "../../../messages/commonMessages";
import { ActiveStepTitle } from "../../Onboarding/components";
import Summary from "./Summary";

export function EditTempAccount({ closeModal, isOpen }: RenderModalProps) {
  const intl = useIntl();

  const title = (
    <ActiveStepTitle
      label={intl.formatMessage(wizardTitle.edit)}
      cloudType={AWS}
      iconSize="lg"
    />
  );

  const steps = useMemo(
    (): WizardStepState[] => [
      {
        Component: Summary,
        disabled: false,
        key: SUMMARY,
        label: intl.formatMessage(stepTitle[SUMMARY]),
        hasBeenSubmitted: false,
      },
    ],
    [intl],
  );

  return (
    <Wizard
      context={closeModal}
      onClose={closeModal}
      size="xl"
      steps={steps}
      title={title}
      initialActiveIndex={0}
      isOpen={isOpen}
    />
  );
}
