import { z } from "zod";
import { TimeRangeSchema } from "../../../schemas";
import { CloudTypesWithOtherEnumSchema } from "../../cloudType";
import { findingTypesSchema } from "../../findingTypesSchema";
import { RqlConfigResultsItemsSchema } from "../config";
import { RqlEventResultsItemsSchema } from "../events/schemas";
import { RqlPermissionResultsItemsSchema } from "../permission";
import { RqlSearchModelSchema, type RqlSearchType } from "../searchHistory";

const SortSchema = z.object({
  direction: z.string(),
  field: z.string(),
});

export const RqlResultsRequestSchema = z.object({
  heuristicSearch: z.boolean().optional(),
  id: z.string().optional(),
  limit: z.number().optional(),
  pageToken: z.string().optional(),
  preview: z.boolean().optional(),
  query: z.string(),
  sort: SortSchema.array().optional(),
  timeRange: TimeRangeSchema.optional(),
  withResourceJson: z.boolean().optional(),
});

/**
 * This is a subsequent "load more" pagination response after the initial request
 */
export const RqlResultsDataSchema = z.object({
  heuristicSearch: z.boolean().optional(),
  infoMsg: z.string().optional(),
  items: z.union([RqlConfigResultsItemsSchema, RqlEventResultsItemsSchema]),
  nextPageToken: z.string().optional(),
  preview: z.boolean().optional(),
  totalRows: z.number(),
  dynamicColumns: z.string().array().optional(),
});

export const PermissionsResponseSchema = RqlSearchModelSchema.merge(
  z.object({
    data: z.object({
      searchedDestCloudResourceNames: z.string().array(),
      items: RqlPermissionResultsItemsSchema,
      nextPageToken: z.string().optional(),
      totalRows: z.number(),
    }),
  }),
);

export const RqlResultsInitialResponseSchema = RqlSearchModelSchema.merge(
  z.object({ data: RqlResultsDataSchema }),
);

export type RqlResultsInitialResponseType = z.infer<
  typeof RqlResultsInitialResponseSchema
>;

export type PermissionsResponseType = z.infer<typeof PermissionsResponseSchema>;

export const SecurityIssuesSchema = z.object({
  type: z.union([z.enum(["vulnerability", "secret"]), findingTypesSchema]),
  count: z.number(),
});
export type SecurityIssuesType = z.infer<typeof SecurityIssuesSchema>;

/**
 * This Schema is for both the initial search responses and subsequent paginated responses
 */
export const RqlResultsResponseSchema = z.union([
  RqlResultsInitialResponseSchema,
  RqlResultsDataSchema,
]);
export type RqlResultsResponseType = z.infer<typeof RqlResultsResponseSchema>;

export const RqlAssetResultsRequestSchema = z.object({
  nextPageToken: z.string().optional(),
  query: z.string(),
  savedSearchId: z.string().optional(),
});

export const RqlAssetResultsItemSchema = z.object({
  unifiedAssetId: z.string().optional(),
  externalAssetId: z.string().optional(),
  assetName: z.string().optional(),
  assetCategory: z.string().optional(),
  assetClass: z.string().optional(),
  cloudAccountId: z.string().optional(),
  cloudAccountName: z.string().optional(),
  cloudServiceName: z.string().optional(),
  cloudRegion: z.string().optional(),
  findingCount: z.number().optional(),
  lastModifiedAt: z.number().optional(),
  findingTypesBySeverityOrder: z.string().array().optional(),
  matchedSecurityIssues: SecurityIssuesSchema.array().optional(),
  totalSecurityIssuesCount: z.number().optional(),
  matchingSecurityIssuesCount: z.number().optional(),
});

export const RqlAssetResultsDataSchema = z.object({
  value: RqlAssetResultsItemSchema.array().optional(),
  nextPageToken: z.string().optional(),
  resultMetadata: z.object({
    searchId: z.string(),
    convertedQuery: z.string().optional(),
    cloudType: CloudTypesWithOtherEnumSchema,
  }),
});

export const RqlResourceResponseSchema = z
  .object({
    accountId: z.string().optional(),
    accountName: z.string().optional(),
    allowDrillDown: z.boolean().optional(),
    cloudType: z.string().optional(),
    data: z
      .object({
        firewallRules: z
          .array(
            z
              .object({
                endIpAddress: z.string().optional(),
                id: z.string().optional(),
                name: z.string().optional(),
                startIpAddress: z.string().optional(),
                type: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
        locks: z.object({}).optional(),
        serverAdmins: z
          .array(
            z
              .object({
                id: z.string().optional(),
                name: z.string().optional(),
                properties: z
                  .object({
                    administratorType: z.string().optional(),
                    azureADOnlyAuthentication: z.boolean().optional(),
                    login: z.string().optional(),
                    sid: z.string().optional(),
                    tenantId: z.string().optional(),
                  })
                  .optional(),
                type: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
        serverBlobAuditingPolicy: z
          .object({
            id: z.string().optional(),
            name: z.string().optional(),
            properties: z
              .object({
                auditActionsAndGroups: z.array(z.any()).optional(),
                isAzureMonitorTargetEnabled: z.boolean().optional(),
                isStorageSecondaryKeyInUse: z.boolean().optional(),
                retentionDays: z.number().optional(),
                state: z.string().optional(),
                storageAccountSubscriptionId: z.string().optional(),
                storageEndpoint: z.string().optional(),
              })
              .optional(),
            type: z.string().optional(),
          })
          .optional(),
        serverSecurityAlertPolicy: z
          .object({
            id: z.string().optional(),
            name: z.string().optional(),
            properties: z
              .object({
                creationTime: z.string().optional(),
                disabledAlerts: z.array(z.string()).optional(),
                emailAccountAdmins: z.boolean().optional(),
                retentionDays: z.number().optional(),
                state: z.string().optional(),
                storageAccountSubscriptionId: z.string().optional(),
                storageEndpoint: z.string().optional(),
              })
              .optional(),
            type: z.string().optional(),
          })
          .optional(),
        sqlEncryptionProtectors: z
          .array(
            z
              .object({
                id: z.string().optional(),
                kind: z.string().optional(),
                name: z.string().optional(),
                properties: z
                  .object({
                    autoRotationEnabled: z.boolean().optional(),
                    serverKeyName: z.string().optional(),
                    serverKeyType: z.string().optional(),
                  })
                  .optional(),
                type: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
        sqlServer: z
          .object({
            id: z.string().optional(),
            kind: z.string().optional(),
            location: z.string().optional(),
            name: z.string().optional(),
            "properties.administratorLogin": z.string().optional(),
            "properties.administrators": z
              .object({
                administratorType: z.string().optional(),
                azureADOnlyAuthentication: z.boolean().optional(),
                login: z.string().optional(),
                principalType: z.string().optional(),
                sid: z.string().optional(),
                tenantId: z.string().optional(),
              })
              .optional(),
            "properties.fullyQualifiedDomainName": z.string().optional(),
            "properties.minimalTlsVersion": z.string().optional(),
            "properties.privateEndpointConnections": z
              .array(z.any())
              .optional(),
            "properties.publicNetworkAccess": z.string().optional(),
            "properties.state": z.string().optional(),
            "properties.version": z.string().optional(),
            tags: z
              .object({
                name: z.string().optional(),
              })
              .optional(),
            type: z.string().optional(),
          })
          .optional(),
        vulnerabilityAssessments: z
          .array(
            z
              .object({
                id: z.string().optional(),
                name: z.string().optional(),
                properties: z
                  .object({
                    recurringScans: z
                      .object({
                        emailSubscriptionAdmins: z.boolean().optional(),
                        isEnabled: z.boolean().optional(),
                      })
                      .optional(),
                  })
                  .optional(),
                type: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    deleted: z.boolean().optional(),
    hasExtFindingRiskFactors: z.boolean().optional(),
    hasExternalFinding: z.boolean().optional(),
    hasExternalIntegration: z.boolean().optional(),
    hasNetwork: z.boolean().optional(),
    id: z.string().optional(),
    insertTs: z.number().optional(),
    name: z.string().optional(),
    regionId: z.string().optional(),
    regionName: z.string().optional(),
    resourceConfigJsonAvailable: z.boolean().optional(),
    resourceType: z.string().optional(),
    riskGrade: z.string().optional(),
    rrn: z.string().optional(),
    service: z.string().optional(),
    tags: z
      .object({
        name: z.string().optional(),
      })
      .optional(),
    url: z.string().optional(),
    vpcId: z.string().optional(),
    vpcName: z.string().optional(),
  })
  .optional();

export const SEARCH_RESOURCE_KEYS = {
  config: "config",
  event: "event",
  network: "network",
} as const;

export const RqlSecretResultsRequestSchema = z.object({
  nextPageToken: z.string().optional(),
  query: z.string(),
  savedSearchId: z.string(),
  capabilityName: z.string(),
});

export const RqlSecretResultsItemSchema = z.object({
  unifiedAssetId: z.string(),
  externalAssetId: z.string(),
  assetName: z.string(),
  assetType: z.string(),
  capabilityData: z.object({
    type: z.string(),
    path: z.string(),
    severity: z.string(),
    locationInFile: z.string(),
    modifiedTime: z.number(),
    id: z.string(),
    snippet: z.string(),
  }),
});

export const RqlSecretResultsDataSchema = z.object({
  value: RqlSecretResultsItemSchema.array().optional(),
  nextPageToken: z.string().optional(),
  resultMetadata: z
    .object({
      searchId: z.string(),
      convertedQuery: z.string().optional(),
      cloudType: CloudTypesWithOtherEnumSchema,
    })
    .optional(),
});

export type SearchResourceKeys = keyof typeof SEARCH_RESOURCE_KEYS;

export type RqlResultsRequestBody = z.infer<typeof RqlResultsRequestSchema>;

/**
 * Use this to cast one of the ResultsResponse union types to a specific type
 */
export type RqlResultsResponseGeneric<T> = Omit<RqlResultsResponse, "items"> & {
  items: T[];
};

export type RqlSearchModel = Omit<
  z.infer<typeof RqlSearchModelSchema>,
  "id" | "name" | "queryWithFindingNames" | "saved"
> & {
  displayQuery?: string;
  queryWithFindingIDs?: string;
  heuristicSearch?: boolean;
  id?: string;
  name?: string;
  saved?: boolean;
  searchType?: RqlSearchType | null;
};

/**
 * The front end request function combines the two different backend responses into one consistent, flattened shape.
 *
 * Application code should only use this type, not the two different response schemas
 */
export type RqlResultsResponse = z.infer<typeof RqlResultsDataSchema> &
  Partial<z.infer<typeof RqlSearchModelSchema>>;

export type RqlAssetResultsItemType = z.infer<typeof RqlAssetResultsItemSchema>;
export type RqlAssetResultsDataType = z.infer<typeof RqlAssetResultsDataSchema>;
export type RqlSecretResultsDataType = z.infer<
  typeof RqlSecretResultsDataSchema
>;
export type RqlSecretResultsItemType = z.infer<
  typeof RqlSecretResultsItemSchema
>;
