import { debounce } from "debounce";
import { SearchIcon } from "icons";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Input, type InputProps } from "../Input";
import { Tooltip } from "../Tooltip";

export interface SearchFilterProps extends Omit<InputProps, "onChange"> {
  /**
   * Callback for search term updates.
   */
  onChange: (term: string) => void;
  /**
   * Custom tooltip for the SearchFilter. Uses default if not provided.
   */
  tooltip?: string;
  /**
   * Controlled value of the SearchFilter input.
   */
  value?: string;
  /**
   * Defines whether the tooltip should be visible on hover or not (default: hidden).
   */
  isTooltipVisible?: boolean;
}

export function SearchFilter({
  tooltip,
  onChange,
  value = "",
  isTooltipVisible = false,
  ...inputProps
}: SearchFilterProps) {
  const intl = useIntl();
  const [localSearch, setLocalSearch] = useState(value);

  const debouncedOnChange = useMemo(() => debounce(onChange, 200), [onChange]);

  useEffect(() => {
    setLocalSearch(value);
  }, [value]);

  useEffect(() => {
    return () => {
      debouncedOnChange.clear();
    };
  }, [debouncedOnChange]);

  return (
    <Tooltip
      label={
        tooltip ||
        intl.formatMessage({
          defaultMessage: "Search for specific data",
          id: "5ez1Vd",

          description: "Tooltip text for search bar",
        })
      }
      isVisible={isTooltipVisible ? undefined : false}
    >
      <Input
        aria-label={intl.formatMessage({
          defaultMessage: "Search bar",
          id: "8jSdeI",

          description: "Label for input bar",
        })}
        placeholder={intl.formatMessage({
          defaultMessage: "Search...",
          id: "l34S1E",

          description: "Placeholder text for search bar",
        })}
        allowClear
        suffix={<SearchIcon className="text-secondary" />}
        {...inputProps}
        onChange={({ currentTarget }) => {
          setLocalSearch(currentTarget.value);
          debouncedOnChange(currentTarget.value);
        }}
        value={localSearch}
      />
    </Tooltip>
  );
}
