import { greenScale } from "colors";
import { ShieldCheckIcon } from "icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Body, Bold, Card, CardBody, CardHeader } from "ui";
import { useIdentitySubscription } from "../../../../hooks";

export default function OnboardSuccess() {
  const intl = useIntl();

  const { isSubscribed } = useIdentitySubscription();

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage:
            "Prisma Cloud has been successfully onboarded to your AWS account!",
          id: "I76bHn",
          description: "Onboard Success message title",
        })}
      />
      <CardBody>
        <Card appearance="glass">
          <CardBody>
            <Bold>
              <Body addClassName="pb-4">
                <FormattedMessage
                  defaultMessage="Stay tuned! We're scanning your environment so you can:"
                  id="61lCUX"
                  description="Onboard Success message title"
                />
              </Body>
            </Bold>
            <ul>
              <li className="flex">
                <ShieldCheckIcon
                  className="mr-1 mt-1"
                  color={greenScale[400]}
                />
                <FormattedMessage
                  defaultMessage="Detect Misconfigurations and Network Threats"
                  id="myRKjy"
                  description="Card description text"
                />
              </li>
              <li className="flex">
                <ShieldCheckIcon
                  className="mr-1 mt-1"
                  color={greenScale[400]}
                />
                <FormattedMessage
                  defaultMessage="Manage Vulnerabilities and Discover Risks"
                  id="oV0D2k"
                  description="Card description text"
                />
              </li>
              {isSubscribed && (
                <li className="flex">
                  <ShieldCheckIcon
                    className="mr-1 mt-1"
                    color={greenScale[400]}
                  />
                  <FormattedMessage
                    defaultMessage="Manage Identity Risks"
                    id="oGYPsO"
                    description="Card description text"
                  />
                </li>
              )}
            </ul>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
}
