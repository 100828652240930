import { IBM } from "../../../../../constants";
import AccountType from "../../../components/AccountType";

export default function Scope() {
  return (
    <section className="space-y-2">
      <div className="-ml-2">
        <AccountType cloudType={IBM} name="accountType" />
      </div>
    </section>
  );
}
