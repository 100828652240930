import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { Input } from "form";
import { type ReactElement } from "react";
import { Tooltip } from "ui";
import { useTrimField } from "../../../../hooks";
import { validateRoleArn } from "../../utils";

type RoleArnProps = {
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  showLabel?: boolean;
  disabled?: boolean;
};

export default function RoleArn({ onBlur, disabled, ...rest }: RoleArnProps) {
  const intl = useIntl();

  const { getValues, watch } = useFormContext();
  const { accountId } = getValues();

  useTrimField("roleArn");
  const customMemberRoleNameEnabled = watch("customMemberRoleNameEnabled");

  const label = customMemberRoleNameEnabled
    ? intl.formatMessage({
        defaultMessage: "Management IAM Role ARN",
        id: "cS8zM5",
        description: "The name of an input field for onboarding an AWS account",
      })
    : intl.formatMessage({
        defaultMessage: "IAM Role ARN",
        id: "LewYn3",
        description: "The name of an input field for onboarding an AWS account",
      });

  const RoleArnInputComponent: ReactElement = (
    <Input
      name="roleArn"
      data-selector="aws-role-arn-input"
      disabled={disabled}
      label={label}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "eg - arn:aws:iam::123456789012:role/abcd",
        id: "zt4YXv",
        description: "Placeholder for IAM Role ARN",
      })}
      register={{
        required: intl.formatMessage(
          {
            defaultMessage: "{management}IAM Role ARN is required",
            id: "1zVunT",
            description:
              "Error message for field for onboarding an AWS account",
          },
          { management: customMemberRoleNameEnabled && "Management " },
        ),
        validate: validateRoleArn(accountId, intl),
      }}
      tooltip={intl.formatMessage({
        defaultMessage: "ARN for Prisma Cloud Role",
        id: "Y0SbZ6",
        description:
          "Tooltip for a display field for onboarding an AWS account",
      })}
      {...rest}
    />
  );

  return disabled ? (
    <Tooltip
      label={
        customMemberRoleNameEnabled
          ? intl.formatMessage({
              defaultMessage:
                "Download management CFT to unblock Management IAM Role ARN entry.",
              id: "DSlXMP",
              description: "tooltip for disabled IAM Role ARN field",
            })
          : intl.formatMessage({
              defaultMessage:
                "Download the CFT/Connect to AWS to obtain and enter the IAM role ARN.",
              id: "1vS7jy",
              description: "tooltip for disabled IAM Role ARN field",
            })
      }
    >
      {RoleArnInputComponent}
    </Tooltip>
  ) : (
    RoleArnInputComponent
  );
}
