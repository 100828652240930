import { DangerIcon, SuccessIcon } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";

export interface StatusIconProps {
  status?: "valid" | "error" | "pending";
}

export function StatusIcon({ status }: StatusIconProps): ReactElement | null {
  const intl = useIntl();
  switch (status) {
    case "valid":
      return (
        <SuccessIcon
          className="flex-initial shrink-0"
          aria-label={intl.formatMessage({
            defaultMessage: "Valid",
            id: "uDKMYM",

            description: "Indicates that the form is valid",
          })}
          size="sm"
        />
      );
    case "error":
      return (
        <DangerIcon
          className="flex-initial shrink-0"
          aria-label={intl.formatMessage({
            defaultMessage: "Error",
            id: "Wg2zoV",

            description: "Indicates that the form has an error",
          })}
          size="sm"
        />
      );
    case "pending":
    case undefined:
      return null;
    /* c8 ignore next */
    default: {
      if (process.env.NODE_ENV === "development") {
        throw new Error(`Unexpected status: ${status}`);
      }
      return null;
    }
  }
}
