import { type z } from "zod";
import { type FilterSchema } from "../../schemas";

export function filtersForPostBody<F>(filters: Record<string, unknown>): F {
  const filtersForPost = Object.entries(filters).reduce<
    z.infer<typeof FilterSchema>[]
  >((accum, [name, value]) => {
    const values = Array.isArray(value) ? value : [value];
    values.forEach((val) => accum.push({ name, operator: "=", value: val }));

    return accum;
  }, []);
  return filtersForPost as F;
}
