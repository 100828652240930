import { useIntl } from "react-intl";
import { isEmpty, isNil } from "remeda";

import { snakeCase } from "utils";

import {
  CLIENT_EMAIL,
  CLIENT_ID,
  PRIVATE_KEY_ID,
  PROJECT_ID,
  SERVICE_ACCOUNT,
  TYPE,
} from "../../../../../../../../constants";
import { useGCPCredentialsFileContext } from "../../../../../context/GcpCredentialsFileContext";
import {
  getServiceAccountKey,
  getServiceAccountKeyName,
} from "../../../../../context/GcpCredentialsFileContext/state/getters";
import { jsonFileKeys } from "../../messages";
import FileUploadDetails from "../FileUploadDetails";
import List from "../List";

type ServiceAccountFileInfoProps = {
  hasErrors: boolean;
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function ServiceAccountFileInfo({
  hasErrors,
  handleClose,
}: ServiceAccountFileInfoProps) {
  const intl = useIntl();

  const { state } = useGCPCredentialsFileContext();

  const json = getServiceAccountKey(state);
  const name = getServiceAccountKeyName(state);

  if (
    hasErrors ||
    isNil(json) ||
    isEmpty(json) ||
    (json[TYPE] && json[TYPE] !== SERVICE_ACCOUNT)
  )
    return null;
  const info = [PROJECT_ID, PRIVATE_KEY_ID, CLIENT_EMAIL, CLIENT_ID]
    .map(snakeCase)
    .map((key, index) => (
      <List
        key={index}
        index={index}
        label={intl.formatMessage(
          jsonFileKeys[key as keyof typeof jsonFileKeys],
        )}
        value={json[key]}
      />
    ));

  return (
    <FileUploadDetails
      handleClose={handleClose}
      isServiceAccount={true}
      name={name}
    >
      {info}
    </FileUploadDetails>
  );
}
