import { HomeRegionsSchema } from "./schemas";

import { z } from "zod";
import { jsonApi } from "../../../apis";

export const homeRegionsKeys = {
  all: [{ scope: "homeRegionsKeys" }] as const,
};

export const getHomeRegions = async () =>
  jsonApi({
    path: `v1/cloudAccounts/OCI/regions`,
    requestSchema: z.void(),
    responseSchema: HomeRegionsSchema,
  });
