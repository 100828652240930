import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import {
  ACCOUNT,
  ALIBABA_CLOUD,
  CONFIGURE_ACCOUNT,
  GET_STARTED,
  REVIEW_STATUS,
  WIZARD_HEIGHT,
} from "../../../constants";
import {
  useGetCloudAccountsRest,
  useGetDefaultAccountGroupId,
} from "../../../hooks";
import { stepTitle, wizardTitle } from "../../../messages/commonMessages";

import { useMemo } from "react";
import { useIntl } from "react-intl";
import LoadingModal from "../../../components/LoadingModal";
import { type OnboardingProps } from "../../../types";
import { ActiveStepTitle } from "../components";
import ConfigureAccount from "./ConfigureAccount";
import GetStarted from "./GetStarted";
import ReviewStatus from "./ReviewStatus";

export function AlibabaOnboarding({
  accountId,
  closeModal,
  isOpen,
  isEdit = false,
  fromProvidersPage,
}: OnboardingProps) {
  const intl = useIntl();

  const { data: alibabaData, isLoading } = useGetCloudAccountsRest({
    accountId,
    cloudType: ALIBABA_CLOUD,
    includeGroupInfo: "true",
    options: { enabled: isEdit },
  });

  const {
    accountType = ACCOUNT,
    enabled = true,
    name = "Alibaba Cloud Account",
    groupIds = [],
    ramArn,
    deploymentType,
  } = alibabaData || {};

  const {
    defaultAccountGroupId: newDefaultAccountGroupId = [],
    isLoading: isAccountGroupItemsLoading,
  } = useGetDefaultAccountGroupId();

  const accountGroupDefaultValue = useMemo(
    () => (isEdit ? groupIds : [newDefaultAccountGroupId[0]?.key]),
    [groupIds, isEdit, newDefaultAccountGroupId],
  );

  const status = isEdit ? "valid" : "pending";

  const steps = useMemo((): WizardStepState[] => {
    const wizardSteps: WizardStepState[] = [
      {
        Component: GetStarted,
        disabled: false,
        key: GET_STARTED,
        label: intl.formatMessage(stepTitle[GET_STARTED]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountType,
          deploymentType,
        },
      },
      {
        Component: ConfigureAccount,
        disabled: !isEdit,
        key: CONFIGURE_ACCOUNT,
        label: intl.formatMessage(stepTitle[CONFIGURE_ACCOUNT]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          name,
          ramArn,
          groupIds: accountGroupDefaultValue,
        },
      },
      {
        Component: ReviewStatus,
        disabled: !isEdit,
        key: REVIEW_STATUS,
        label: intl.formatMessage(stepTitle[REVIEW_STATUS]),
        hasBeenSubmitted: isEdit,
        status,
      },
    ];
    return wizardSteps;
  }, [
    intl,
    isEdit,
    status,
    accountType,
    ramArn,
    name,
    accountGroupDefaultValue,
    deploymentType,
  ]);

  const title = (
    <ActiveStepTitle
      label={intl.formatMessage(isEdit ? wizardTitle.edit : wizardTitle.add)}
      cloudType={ALIBABA_CLOUD}
      iconSize={"lg"}
    />
  );

  if (isLoading || isAccountGroupItemsLoading) {
    return <LoadingModal title={title} />;
  }

  return (
    <Wizard
      context={{
        accountId,
        closeModal,
        isEdit,
        enabled,
        fromProvidersPage,
      }}
      initialActiveIndex={0}
      isOpen={isOpen}
      onClose={closeModal}
      size="xl"
      steps={steps}
      style={{ minHeight: WIZARD_HEIGHT }}
      title={title}
    />
  );
}
