import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";

export default function GroupName({ groupName }: { groupName: string }) {
  const intl = useIntl();
  useTrimField("groupName");

  return (
    <Input
      data-selector="oci-group-name-input"
      name="groupName"
      label={intl.formatMessage({
        defaultMessage: "Group Name",
        id: "nWpKgY",
        description: "The name of an input field for onboarding an OCI account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "eg - prisma-cloud-group",
        id: "DFCi6A",
        description: "Placeholder for Group Name",
      })}
      defaultValue={groupName}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Group Name is required",
          id: "SvuJqh",
          description: "Error message for field for onboarding a OCI tenant",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "Oracle Cloud Infrastructure Identity Group Name. New group will be created (recommended) or you can use an existing group",
        id: "5MQY4w",
        description: "Tooltip for Group Name input field",
      })}
    />
  );
}
