import { z } from "zod";

export const pageTableProviderSchema = z.object({
  tableFilter: z.optional(z.union([z.string(), z.number()]).catch("")),
  page: z.optional(
    z
      .object({
        number: z.number().nonnegative().optional(),
        size: z.number().nonnegative().optional(),
      })
      .catch({}),
  ),
});

export type PageTableProviderType = z.infer<typeof pageTableProviderSchema>;
