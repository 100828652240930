import { FormattedMessage } from "react-intl";

import { Body, Link } from "ui";

export function ProductDocumentationLink() {
  return (
    <Body size="sm" as="span">
      <FormattedMessage
        defaultMessage="For product documentation please click <a>&nbsp;here</a>"
        id="QPidQB"
        description="Product documentation link"
        values={{
          a: (chunks) => (
            <Link
              data-testid="product-documentation-link"
              href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/connect/connect-cloud-accounts/connect-cloud-accounts"
              external
              size="sm"
            >
              {chunks}
            </Link>
          ),
        }}
      />
    </Body>
  );
}
