import { type ReactElement, type ReactNode } from "react";
import { Body, Link, useTitle } from "ui";

import { PrismaLogoIcon } from "icons";
import { useIntl } from "react-intl";
import { AnimatedBackground } from "../../components/AnimatedBackground";
import { CrystalIcon } from "../../components/CrystalIcon";
import { CopyrightNotice } from "./components/CopyrightNotice";
import LoginForm from "./components/LoginForm";
import TimeoutModal from "./components/TimeoutModal";

export default function Login(): ReactElement {
  const intl = useIntl();

  useTitle([
    "Prisma Cloud",
    intl.formatMessage({
      defaultMessage: "Sign In",
      id: "+jGRvm",
      description: "Signin page tab title",
    }),
  ]);

  return (
    <div className="dark flex min-h-screen flex-col overflow-auto text-sm">
      <AnimatedBackground />
      <div className="relative z-10 mx-auto w-96 grow pb-4 pt-28 dark:text-dark-bg">
        <div className="flex justify-center">
          <PrismaLogoIcon size="7xl" color="white" />
        </div>
        <div className="absolute left-0 top-0 -z-10 -ml-32 mt-48">
          <CrystalIcon theme="theme-robinblue" width="640px" height="500px" />
        </div>
        <div className="m-10 mx-auto w-96 grow">
          <div className="flex flex-col rounded border border-blue-steel-850 bg-[#001F26]/50 p-10  shadow-xl backdrop-blur-2xl">
            <LoginForm />
          </div>
          <Body appearance="secondary" addClassName="mt-10 text-center">
            {intl.formatMessage(
              {
                defaultMessage:
                  "Check Prisma Cloud system availability and upcoming maintenance schedule on our <a>status page</a>.",
                id: "sjsqey",
                description: "Availability and maintenance schedule link",
              },
              {
                a: (chunks: ReactNode[]) => (
                  <Link
                    href="https://status.paloaltonetworks.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link dark:text-dark-bg-link"
                  >
                    {chunks}
                  </Link>
                ),
              },
            )}
          </Body>
        </div>
      </div>
      <div className="absolute bottom-0 z-[11] w-full bg-[#23424D] p-3 text-center">
        <Body appearance="secondary" size="sm">
          <CopyrightNotice />
        </Body>
      </div>
      <TimeoutModal />
    </div>
  );
}
