import { mutateData, type MutateDataProps, type ValueType } from "./mutateData";

import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useToastActions } from "stores";
import { noop } from "utils";

export function useDeleteRecord<T extends ValueType>() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();

  return useCallback(
    (
      updateFn: () => Promise<unknown>,
      {
        id,
        getRow,
        record,
        dataCacheKey,
        cacheUpdaterFn,
        successMessage,
        defaultErrorMessage,
        knownErrorMessages,
        onSuccess = noop,
        onError = noop,
      }: Omit<MutateDataProps<T>, "intl" | "queryClient" | "toast"> & {
        id: string;
        /**
         * Function to return the row from cache that is being updated.  By default
         * it will find the row by the id passed in to the `id` prop.
         *
         * @param rows All rows in cache
         * @returns A single row from the cache
         */
        getRow?: (rows: T[], id: string) => T | undefined;
      },
    ) => {
      const defaultGetRow = (rows: T[], id: string) =>
        rows.find((r) => r.id === id);

      const rowSelector = getRow ?? defaultGetRow;

      const defaultCacheUpdaterFn = (data: T[] = []) => {
        const newData = [...data];
        const deletedRow = rowSelector(newData, id);
        const deletedIndex = deletedRow ? newData.indexOf(deletedRow) : -1;
        if (deletedIndex > -1) {
          newData.splice(deletedIndex, 1);
        }
        return newData;
      };

      return mutateData(updateFn, {
        intl,
        queryClient,
        toast,
        dataCacheKey,
        cacheUpdaterFn: cacheUpdaterFn ?? defaultCacheUpdaterFn,
        record,
        successMessage,
        defaultErrorMessage,
        knownErrorMessages,
        onSuccess,
        onError,
      });
    },
    [intl, queryClient, toast],
  );
}
