import {
  CloseIcon,
  DangerIcon,
  InfoCircleIcon,
  SuccessIcon,
  WarningIcon,
} from "icons";
import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { animated, type SpringValue } from "@react-spring/web";

import {
  blueScale,
  blueSteelScale,
  greenScale,
  redScale,
  white,
  yellowScale,
} from "colors";
import { type ReactNode } from "react";
import { type ToastAppearance } from "stores";
import { classNames } from "utils";

const icons: Record<ToastAppearance, ReactNode | null> = {
  clear: null,
  info: <InfoCircleIcon style={{ color: blueScale[400] }} />,
  warning: <WarningIcon style={{ color: yellowScale[450] }} />,
  error: <DangerIcon style={{ color: redScale[650] }} />,
  success: <SuccessIcon style={{ color: greenScale[650] }} />,
};

export type ToastProps = {
  appearance: keyof typeof icons;
  life: SpringValue<string>;
  remove: () => void;
  text: string | string[] | readonly string[] | readonly ReactNode[];
};

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ appearance, remove, life, text }: ToastProps, ref) => {
    const intl = useIntl();
    const message = Array.isArray(text) ? text : [text];

    return (
      <div className="pr-4 pt-4" ref={ref}>
        <div
          data-testid="toast"
          role="alert"
          style={{ backgroundColor: blueSteelScale[1000] }}
          className={classNames(
            "relative w-80 overflow-hidden p-4",
            "bg-black/60 text-dark-bg",
            "flex items-baseline gap-4 rounded shadow-xl",
          )}
        >
          {icons[appearance] && <div>{icons[appearance]}</div>}

          <div className="min-w-0 grow space-y-1">
            {message.map((paragraph) => (
              <div
                key={paragraph as string}
                className="break-words text-xs"
                data-testid="toast-message"
              >
                {paragraph}
              </div>
            ))}
          </div>

          <button
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              id: "atoMCu",

              description: "Closes/removes system message",
            })}
            className="text-icon-gray hover:text-disabled dark:text-secondary dark:hover:text-dark-bg"
            onClick={remove}
            type="button"
          >
            <CloseIcon style={{ color: white }} />
          </button>

          <animated.div
            className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-blue-700 to-blue-300"
            data-testid="toast-progress"
            data-chromatic="ignore"
            style={{ width: life }}
          />
        </div>
      </div>
    );
  },
);
Toast.displayName = "Toast";
