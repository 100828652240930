import { useQuery } from "@tanstack/react-query";
import { cloudAccountsKeys, getGcpCloudAccountsCas } from "requests";

type UseGetGcpCloudAccountsCasProps = {
  accountId: string;
  options?: Record<string, string | boolean>;
};

export const useGetGcpCloudAccountsCas = ({
  accountId,
  options = {},
}: UseGetGcpCloudAccountsCasProps) => {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.gcpCloudAccountsCas({
      accountId,
    }),
    queryFn: getGcpCloudAccountsCas,
    retry: false,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
};
