import {
  useEffect,
  useImperativeHandle,
  useMemo,
  type ReactElement,
  type ReactNode,
  type RefObject,
} from "react";
import {
  actions,
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
  type ActionType,
  type Column,
  type UseTableOptions,
} from "react-table";
import { type SomethingWentWrongError } from "ui";
import { TruncateCell } from "../../columns/cellRenderers";

import { isObjectType, omitBy } from "remeda";
import {
  type ColumnInstance,
  type ColumnOptions,
  type Context,
  type Data,
  type RowInstance,
  type TableInstance,
  type TableOptions,
  type TableState,
} from "../../types";
import { useInsertSelectRowColumn } from "./SelectRowColumn";
import { TableContext } from "./TableContext";
import { useInsertSortRowColumn } from "./useInsertSortRowColumn";

const MinWidthWrap = (props: {
  minWidth?: number;
  children: ReactNode;
}): JSX.Element => {
  const { minWidth, children } = props;

  return (
    <div
      style={{
        minWidth: minWidth + "px",
      }}
    >
      {children}
    </div>
  );
};

export interface TableProviderProps<D extends Data, C extends Context = Context>
  extends Omit<TableOptions<D>, "columns" | "defaultColumn" | "globalFilter"> {
  /**
   * Add wrapper with Min-width for table
   */
  minWidth?: number;
  /**
   * Reset the expanded table rows when data changes.
   */
  autoResetExpanded?: boolean;
  /**
   * Reset the table filter when data changes.
   *
   * Note the naming difference here: `autoResetGlobalFilter` is the parameter name from `react-table`, but only impacts the table data and the table filter.
   */
  autoResetGlobalFilter?: boolean;
  /**
   * Reset the table groupings when data changes.
   */
  autoResetGroupBy?: boolean;
  /**
   * Resets the page index of the table when any of the following change:
   *
   * * data
   * * sortBy
   * * globalFilter (aka tableFilter)
   * * groupBy
   *
   */
  autoResetPage?: boolean;
  /**
   * Reset the table sort when data changes.
   */
  autoResetSortBy?: boolean;
  children?: ReactNode;
  columns: ColumnOptions<D>[];
  /**
   * If no overrides are provided in this prop the column settings will default to
   * width: 175
   * stickyPosition: "right"
   * Cell: TruncateCell
   * sortType: "basic"
   */
  defaultColumn?: Partial<ColumnOptions<D>>;
  /**
   * Allows the user to reorder the columns.
   */
  enableColumnOrder?: boolean;
  /**
   * Allows the user to sort the table by a column.
   *
   * Pass the `sortType` option to the column to override the default sorting function.
   */
  enableColumnSort?: boolean;
  /**
   * Only render a single page of rows.
   *
   * Should be used with a Paginator control.
   */
  enablePagination?: boolean;
  /**
   * Allows the user to resize the columns.
   */
  enableResizeColumns?: boolean;
  /**
   * Allows sorting of rows.
   *
   * *Note*: Provide a `getRowId` function to identify a unique ID per row from your data.
   * Without it, the animations may not behave as expected.
   */
  enableRowSort?: boolean;
  /**
   * Disable the row sort button. Typically used when controlling the filtering & using a filter function.
   * If a string is passed it will be used as the tooltip.
   */
  disableRowSortHandle?: TableInstance<D>["disableRowSortHandle"];
  /**
   * Allows the user to select rows.
   */
  enableRowSelect?: boolean;
  getRowId?: UseTableOptions<D>["getRowId"];
  initialState?: Partial<TableState<D>>;
  /**
   * Indicates that the initial table data is loading.
   */
  isLoading?: boolean;
  /**
   * If provided, indicates that an error has occured.
   */
  isError?: boolean;
  /**
   * An error object with a message to show to the user.
   */
  error?: SomethingWentWrongError;
  /**
   * Indicates that data is being re-fetched in the background. The initial data is already loaded.
   *
   * This indicator will keep the current data while the latest data is fetched.
   */
  isBackgroundFetching?: boolean;
  /**
   * Enables groupBy detection and functionality, but does not automatically perform row grouping.
   *
   * **Note:** `manualPagination` is also set when manually grouping.
   */
  manualGroupBy?: boolean;
  /**
   * Enables pagination functionality, but does not automatically perform row pagination.
   * `pageCount` must be set when using `manualPagination`.
   *
   * **Note:** `manualGroupBy` is also set when manually paginating.
   */
  manualPagination?: boolean;
  /**
   * Enables sorting detection functionality, but does not automatically perform row sorting.
   */
  manualSortBy?: boolean;
  /**
   * Invoked when the column order changes.
   *
   * It's passed an array of column id's in the new order.
   */
  onColumnOrderChange?: (columnOrder: string[]) => void;
  /**
   * Invoked when the user toggles the visibility of columns.
   *
   * It's passed an array of the hidden column id's.
   */
  onColumnVisibilityChange?: (hiddenColumns: string[] | undefined) => void;
  /**
   * Invoked when the user changes a column width.
   *
   * It's passed an object describing the current changes from the initial state. The keys are the id's of the columns that have changed. The values are the updated widths, in pixels.
   */
  onColumnWidthChange?: (columnWidths: { [key: string]: number }) => void;
  /**
   * Invoked when the user changes a group by.
   *
   * It's passed an array of column id's in the order that they are grouped.
   */
  onGroupByChange?: (groupBy: string[]) => void;
  /**
   * Invoked when the page index or size changes.
   *
   * It is passed an object with the current pageIndex(0 based) and pageSize.
   */
  onPaginationChange?: (pagination: {
    pageIndex: number;
    pageSize: number;
  }) => void;
  /**
   * Invoked when the selected row set changes.
   */
  onSelectedRowsChange?: (selectedRowIds: Record<string, boolean>) => void;
  /**
   * Invoked when a column sort is changed
   *
   * It is passed an array of object. Each object has an `id` representing the column sorted, and optionally has a `desc` key specifying if the sort is descending or not.
   */
  onSortChange?: (sortBy: { id: string; desc?: boolean }[]) => void;
  onRowSortEnd?: TableInstance<D>["onRowSortEnd"];
  /**
   * Invoked when the react-table state changes.
   *
   * It's pass an object with all of the react-table state.
   */
  onStateChange?: (state: TableState<D>) => void;
  onTableFilterChange?: (tableFilter: string) => void;
  /**
   * The number of pages when manualPagination is enabled.
   */

  pageCount?: number;
  /**
   * Obtain a reference to the table instance.
   *
   * This can be useful for things like updating the current page, or resetting the sort from outside the table provider.
   */
  tableInstanceRef?: RefObject<TableInstance<D>>;
  globalFilter?: (
    row: RowInstance<D>[],
    ids: string[],
    value: string,
  ) => RowInstance<D>[];
  /**
   * When enableRowSelection is true, this determines what type of row selection.
   */
  rowSelectMode?: "multiple" | "single";
  stateReducer?: TableOptions<D>["stateReducer"];
  /**
   * Any arbitrary props.  This will be sent to cell renderers
   */
  context?: C;
  /**
   * A function that gets called when selecting a row. Replaces the default onChange functionality.
   * This is used for controlling the table and should be paired with useControlledState.
   */
  onRowSelect?: TableInstance<D>["onRowSelect"];
  /**
   * A function that gets called when selecting all rows. Replaces the default onChange functionality.
   * This is used for controlling the table and should be paired with useControlledState.
   */
  onAllRowSelect?: TableInstance<D>["onAllRowSelect"];
}

/**
 * The main entry point for the table. Pass in the basic table props to `TableProvider` and it will provide a `tableInstance` via context to its children.
 *
 * See the examples for common use cases.
 *
 * The table is implemented with [react-table](https://react-table-omega.vercel.app/docs/overview).
 *
 * We apply the following hooks to `useTable`:
 *
 * - [useColumnOrder](https://react-table-omega.vercel.app/docs/api/useColumnOrder)
 * - [useResizeColumns](https://react-table-omega.vercel.app/docs/api/useResizeColumns)
 * - [useGroupBy](https://react-table-omega.vercel.app/docs/api/useGroupBy)
 * - [useGlobalFilter](https://react-table-omega.vercel.app/docs/api/useGlobalFilter)
 * - [useSortBy](https://react-table-omega.vercel.app/docs/api/useSortBy)
 * - [useExpanded](https://react-table-omega.vercel.app/docs/api/useExpanded)
 * - [usePagination](https://react-table-omega.vercel.app/docs/api/usePagination)
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConnectedTable, TableProvider } from "table";
 * ```
 *
 */
export function TableProvider<D extends Data, C extends Context = Context>(
  props: TableProviderProps<D, C>,
): ReactElement {
  const defaultedProps = useMemo(
    () =>
      Object.assign(
        {
          autoResetExpanded: false,
          autoResetGlobalFilter: false,
          autoResetGroupBy: false,
          autoResetPage: true,
          autoResetSortBy: false,
          columns: [],
          data: [],
          defaultColumn: {},
          enableColumnOrder: false,
          enableColumnSort: false,
          enableResizeColumns: false,
          enableRowSelect: false,
          enableRowSort: false,
          enablePagination: false,
          globalFilter: defaultGlobalFilter,
          initialState: {},
          isLoading: false,
          isBackgroundFetching: false,
          manualGroupBy: false,
          manualPagination: false,
          manualSortBy: false,
          pageCount: -1,
          rowSelectMode: "multiple",
        },
        omitBy(props, (value) => value === undefined),
      ),
    [props],
  );
  const {
    minWidth,
    children,
    columns,
    data,
    defaultColumn,
    enableColumnOrder,
    enableColumnSort,
    enablePagination,
    enableResizeColumns,
    enableRowSelect,
    rowSelectMode,
    manualGroupBy,
    manualPagination,
    manualSortBy,
    onColumnOrderChange,
    onColumnVisibilityChange,
    onColumnWidthChange,
    onGroupByChange,
    onPaginationChange,
    onSelectedRowsChange,
    onSortChange,
    onRowSortEnd,
    onStateChange,
    onTableFilterChange,
    pageCount,
    tableInstanceRef,
    globalFilter,
    stateReducer,
    enableRowSort,
    onRowSelect,
    disableRowSortHandle,
    onAllRowSelect,
    isLoading,
    isError,
    error,
    ...rest
  } = defaultedProps;
  const initialState = useMemo(
    () => makeInitialState(defaultedProps),
    [defaultedProps],
  );

  /* c8 ignore next */
  if (
    process.env.NODE_ENV === "development" &&
    enableRowSort &&
    (enablePagination || enableColumnSort || manualGroupBy)
  ) {
    throw new Error(
      "Row sorting is not compatible with pagination, column sorting, or grouping.",
    );
  }

  const tableInstance = useTable<D>(
    {
      ...rest,
      columns: columns as Column<D>[],
      data,
      defaultColumn: {
        width: 175,
        stickyPosition: "right",
        Cell: TruncateCell,
        sortType: "basic",
        ...defaultColumn,
      },
      disableColumnOrder: !enableColumnOrder,
      disableGroupBy: enableRowSort,
      disableResizing: !enableResizeColumns,
      disableRowSelect: !enableRowSelect,
      disableSortBy: !enableColumnSort,
      disablePagination: !enablePagination,
      enableRowSort: enableRowSort,
      globalFilter,
      manualPagination: manualPagination || manualGroupBy,
      manualGroupBy: manualGroupBy || manualPagination,
      manualSortBy,
      initialState,
      pageCount,
      stateReducer: (
        newState: TableState<D>,
        action: ActionType,
        prevState: TableState<D>,
      ): TableState<D> => {
        let state: TableState<D>;
        switch (action.type) {
          case actions.toggleRowSelected:
            if (rowSelectMode === "single") {
              state = {
                ...newState,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedRowIds: { [action.id]: true } as any,
              };
            } else {
              state = newState;
            }
            break;
          default:
            state = newState;
        }
        return stateReducer
          ? (stateReducer(state, action, prevState) as TableState<D>)
          : state;
      },
      // Below props are our own props that we're passing through to TableInstance
      isLoading,
      isError,
      error,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    useBlockLayout,
    useColumnOrder,
    useResizeColumns,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useInsertSelectRowColumn,
    useInsertSortRowColumn,
    useRowState,
  ) as TableInstance<D>;

  useImperativeHandle(tableInstanceRef, () => tableInstance);

  useEffect(() => {
    onColumnOrderChange?.(tableInstance.state.columnOrder);
  }, [onColumnOrderChange, tableInstance.state.columnOrder]);

  useEffect(() => {
    onColumnVisibilityChange?.(tableInstance.state.hiddenColumns);
  }, [onColumnVisibilityChange, tableInstance.state.hiddenColumns]);

  useEffect(() => {
    onColumnWidthChange?.(tableInstance.state.columnResizing.columnWidths);
  }, [onColumnWidthChange, tableInstance.state.columnResizing.columnWidths]);

  useEffect(() => {
    onGroupByChange?.(tableInstance.state.groupBy);
  }, [onGroupByChange, tableInstance.state.groupBy]);

  useEffect(() => {
    onPaginationChange?.({
      pageIndex: tableInstance.state.pageIndex,
      pageSize: tableInstance.state.pageSize,
    });
  }, [
    onPaginationChange,
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
  ]);

  useEffect(() => {
    onSelectedRowsChange?.(tableInstance.state.selectedRowIds);
  }, [onSelectedRowsChange, tableInstance.state.selectedRowIds]);

  useEffect(() => {
    onSortChange?.(tableInstance.state.sortBy);
  }, [onSortChange, tableInstance.state.sortBy]);

  useEffect(() => {
    onStateChange?.(tableInstance.state);
  }, [onStateChange, tableInstance.state]);

  useEffect(() => {
    onTableFilterChange?.(tableInstance.state.globalFilter);
  }, [onTableFilterChange, tableInstance.state.globalFilter]);

  return (
    <TableContext.Provider
      value={{
        enableColumnOrder,
        enableColumnSort,
        enablePagination,
        enableResizeColumns,
        enableRowSelect,
        enableRowSort,
        onRowSortEnd,
        rowSelectMode,
        onRowSelect,
        onAllRowSelect,
        disableRowSortHandle,
        manualPagination: manualPagination || manualGroupBy,
        manualGroupBy: manualGroupBy || manualPagination,
        ...tableInstance,
      }}
    >
      {minWidth ? (
        <MinWidthWrap minWidth={minWidth}>{children}</MinWidthWrap>
      ) : (
        children
      )}
    </TableContext.Provider>
  );
}

function makeInitialState<D extends Data, C extends Context = Context>(
  props: TableProviderProps<D, C>,
): Partial<TableState<D>> {
  const pageSize = props.initialState?.pageSize || 25;

  const columnOrder =
    props.initialState?.columnOrder ||
    props.columns.map((c) => c?.id || (c.accessor as string));

  const hiddenColumns =
    props.initialState?.hiddenColumns ||
    props.columns
      .filter((c) => c.defaultHidden)
      .map((c) => c?.id || (c.accessor as string));

  return { ...props.initialState, columnOrder, hiddenColumns, pageSize };
}

function defaultGlobalFilter<D extends Data>(
  rows: RowInstance<D>[],
  ids: string[],
  filterValue: string,
) {
  const cellsToString = ids.reduce(
    (acc: Record<string, ColumnInstance<D>["cellToString"]>, id: string) => {
      const cell = rows[0]?.cells.find((cell) => cell?.column?.id === id);
      acc[id] = cell?.column.cellToString;
      return acc;
    },
    {},
  );
  rows = rows.filter((row) => {
    return ids.some((id: string) => {
      const valueToString = isObjectType(row.values[id])
        ? JSON.stringify(row.values[id])
        : String(row.values[id]);

      const column =
        cellsToString[id]?.({ row, value: row.values[id] }) ?? valueToString;

      return column.toLowerCase().includes(String(filterValue).toLowerCase());
    });
  });
  return rows;
}
