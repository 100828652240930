import {
  ERRORS,
  EXTERNAL_ACCOUNT_KEY,
  NAME,
  PROJECT_ID_WIF,
  SERVICE_ACCOUNT_KEY,
  SIZE,
  VALUE,
} from "../../../../../../../constants";
import { type GcpCredentialsFileStateType } from "../initialState";

export const getFieldValue = ({
  field,
  state,
}: {
  field: string;
  state: GcpCredentialsFileStateType;
}) => state.getIn([field, VALUE]);

export const getFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: GcpCredentialsFileStateType;
}) => state.getIn([field, ERRORS]);

export const getServiceAccountKey = (state: GcpCredentialsFileStateType) => {
  const result = getFieldValue({ field: SERVICE_ACCOUNT_KEY, state });
  return result && "size" in result ? result.toJS() : result;
};

export const getProjectIdWif = (state: GcpCredentialsFileStateType) =>
  getFieldValue({ field: PROJECT_ID_WIF, state });

export const getServiceAccountKeyErrors = (
  state: GcpCredentialsFileStateType,
) => getFieldErrors({ field: SERVICE_ACCOUNT_KEY, state });

export const getServiceAccountKeyName = (state: GcpCredentialsFileStateType) =>
  state.getIn([SERVICE_ACCOUNT_KEY, NAME]);

export const getServiceAccountKeySize = (state: GcpCredentialsFileStateType) =>
  state.getIn([SERVICE_ACCOUNT_KEY, SIZE]);

export const getExternalAccountKey = (state: GcpCredentialsFileStateType) => {
  const result = getFieldValue({ field: EXTERNAL_ACCOUNT_KEY, state });
  return result && "size" in result ? result.toJS() : result;
};

export const getExternalAccountKeyErrors = (
  state: GcpCredentialsFileStateType,
) => getFieldErrors({ field: EXTERNAL_ACCOUNT_KEY, state });

export const getExternalAccountKeyName = (state: GcpCredentialsFileStateType) =>
  state.getIn([EXTERNAL_ACCOUNT_KEY, NAME]);

export const getExternalAccountKeySize = (state: GcpCredentialsFileStateType) =>
  state.getIn([EXTERNAL_ACCOUNT_KEY, SIZE]);
