import {
  ModalFooter,
  ModalHeader,
  ModalWizardBody,
  type ModalFooterProps,
  type ModalWizardBodyProps,
} from "ui";

import { Form, type FormProps } from "form";
import { useEffect, type ReactElement } from "react";
import { type FieldValues } from "react-hook-form";
import { useWizardContext } from "./WizardContext";
import { useFormStatus } from "./useFormStatus";

export interface WizardFormProps<T extends FieldValues>
  extends Partial<ModalWizardBodyProps>,
    Omit<FormProps<T>, "children" | "defaultValues"> {
  actions: ModalFooterProps["children"];
  secondaryFooterContent?: ModalFooterProps["secondaryContent"];
  title?: string;
}

/**
 * Wizards are multi-step forms for complex workflows.
 *
 * Each of the core steps of a wizard is a form using the `WizardForm`.
 *
 * The `WizardForm` composes the `Form` from this package with the
 * `ModalWizardBody` from `@prismacloud/react-ui`.
 *
 * The `onNext`, `onPrevious`, and `onSave` callbacks are provided the current step's
 * form values. Store these values, along with those of the other steps in a parent,
 * Wizard component.
 *
 * The navigation items are passed through the `WizardForm` via `navItems`.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { WizardForm, FormLayout } from '@prismacloud/react-form';
 * import { Modal, ModalWizardBody, ModalWizardNavItem, ModalWizardSummary, ModalWizardStepSummary, ModalHeader } from '@prismacloud/react-ui';
 * ```
 *
 */
export function WizardForm<T extends FieldValues>({
  actions,
  activeStepTitle,
  addClassName,
  children,
  navItems,
  onSubmit: userOnSubmit,
  secondaryFooterContent,
  title: titleOverride,
  ...formProps
}: WizardFormProps<T>): ReactElement {
  const {
    state: { activeIndex, steps, title },
    submitStep,
  } = useWizardContext();

  const onSubmit =
    userOnSubmit ||
    ((values) => {
      submitStep({ index: activeIndex, values });
    });

  return (
    <Form
      formProps={{
        className: `flex flex-col flex-auto overflow-hidden ${addClassName}`,
      }}
      mode="onBlur"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - T needs to be passed to useWizardContext, but this would require a lot of refactoring of types for a deprecated component
      defaultValues={steps[activeIndex].values}
      {...formProps}
      onSubmit={onSubmit}
    >
      <ModalHeader title={titleOverride || title} />
      <ConnectedModalWizardBody
        activeStepTitle={activeStepTitle}
        navItems={navItems}
      >
        {children}
      </ConnectedModalWizardBody>
      <ModalFooter secondaryContent={secondaryFooterContent}>
        {actions}
      </ModalFooter>
    </Form>
  );
}

function ConnectedModalWizardBody<T extends FieldValues>({
  activeStepTitle,
  children,
  navItems,
}: Partial<WizardFormProps<T>>): ReactElement {
  const status = useFormStatus();
  const wizardContext = useWizardContext();

  const activeIndex = wizardContext.state.activeIndex;
  const activeStep = wizardContext.state.steps[activeIndex];

  useEffect(() => {
    wizardContext.statusUpdate({ index: activeIndex, status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, status]);

  return (
    <ModalWizardBody
      activeStepTitle={activeStepTitle || activeStep.label}
      navItems={navItems || wizardContext.navItems}
    >
      {children}
    </ModalWizardBody>
  );
}
