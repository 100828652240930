import { defineMessages } from "react-intl";
import { isEmpty, isNil } from "remeda";
import {
  ERRORS,
  EXTERNAL_ACCOUNT_KEY,
  NAME,
  PROJECT_ID_WIF,
  SERVICE_ACCOUNT_KEY,
  SIZE,
  VALUE,
} from "../../../../../../../constants";
import { type GcpCredentialsFileStateType } from "../initialState";

export const jsonUploadErrorMessages = defineMessages({
  invalid_json_file: {
    defaultMessage:
      "The uploaded file does not meet the .json requirements, please upload the correct file.",
    id: "+yNUdC",
    description:
      "Error message for Invalid JSON file uploaded in Configure Account page",
  },
  required_json_file: {
    defaultMessage: "Please upload a JSON file before proceeding.",
    id: "2/Ka/b",
    description: "Error message for mandatory field",
  },
});

export const setExternalAccountKey = ({
  state,
  json,
  name,
  size,
}: {
  state: GcpCredentialsFileStateType;
  json: unknown | null;
  name: string | null;
  size: number;
}) =>
  state
    .setIn([EXTERNAL_ACCOUNT_KEY, VALUE], json)
    .setIn([EXTERNAL_ACCOUNT_KEY, NAME], name)
    .setIn([EXTERNAL_ACCOUNT_KEY, SIZE], size);

export const clearExternalAccountKey = (state: GcpCredentialsFileStateType) =>
  setExternalAccountKey({ state, json: null, name: null, size: 0 });

export const setServiceAccountKey = ({
  state,
  json,
  name,
  size,
}: {
  state: GcpCredentialsFileStateType;
  json: Record<string, unknown> | null;
  name: string | null;
  size: number;
}) => {
  return state
    .setIn([SERVICE_ACCOUNT_KEY, VALUE], json)
    .setIn([SERVICE_ACCOUNT_KEY, NAME], name)
    .setIn([SERVICE_ACCOUNT_KEY, SIZE], size);
};

export const fieldRequired = (value: string) => {
  return {
    isValid: !isNil(value) && !isEmpty(value),
    message: jsonUploadErrorMessages.required_json_file,
  };
};

export const clearJsonFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: GcpCredentialsFileStateType;
}) => {
  return state.setIn([field, ERRORS], []);
};

export const setFTUFieldErrors = ({
  errors,
  field,
  state,
}: {
  errors: string;
  state: GcpCredentialsFileStateType;
  field: string;
}) => {
  const errorsArray = Array.isArray(errors) ? errors : [errors];
  return state.setIn([field, ERRORS], errorsArray);
};

export const setFTUFieldValue = ({
  field,
  state,
  value,
}: {
  field: string;
  state: GcpCredentialsFileStateType;
  value: string;
}) => {
  return state.setIn([field, VALUE], value);
};

export const setProjectIdWif = ({
  state,
  projectId,
}: {
  state: GcpCredentialsFileStateType;
  projectId: string;
}) => {
  return state.setIn([PROJECT_ID_WIF, VALUE], projectId);
};
