import { useEffect, useMemo, type FC, type ReactNode } from "react";
import { createPortal } from "react-dom";

export interface PortalProps {
  children: ReactNode;
}

export const Portal: FC<PortalProps> = ({ children }: PortalProps) => {
  const portalEl = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.body.appendChild(portalEl);

    return () => {
      document.body.removeChild(portalEl);
    };
  }, [portalEl]);

  return createPortal(children, portalEl);
};
