/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

export const AssetInventoryServiceName = lazy(
  () => import("./AssetInventoryServiceName"),
);

export const assetInventoryServiceNameRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "service/$serviceName/cloud/$cloud",
  component: AssetInventoryServiceName,
});
