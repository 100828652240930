import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";
import Details from "../Details";

type MisconfigurationsProps = {
  data: SecurityCapabilitiesPermissionsStatus[];
  isCollapsibleDetails?: boolean;
};

export default function Misconfigurations({
  data,
  isCollapsibleDetails = true,
}: MisconfigurationsProps) {
  if (isNil(data)) return null;

  return (
    <>
      <Details
        icon={
          <SecurityCapabilitiesIcon type="Cloud Visibility Compliance and Governance" />
        }
        label="misconfigurations"
      />
      {data?.map(
        ({
          status,
          name,
          message = "",
          remediation = "",
          components = [],
          ...rest
        }: SecurityCapabilitiesPermissionsStatus) => (
          <CollapsibleDetails
            detailsClassName="mt-4"
            key={name}
            label={name}
            message={message}
            normalFont={true}
            status={status}
            isCollapsibleDetails={isCollapsibleDetails}
            remediation={remediation}
            components={components}
            {...rest}
          />
        ),
      )}
    </>
  );
}
