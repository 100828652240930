import { CloseIcon } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import { Button, type ButtonProps } from "../Button";
import { CardHeader, type CardHeaderProps } from "../Card";
import { FullScreenButton } from "./FullScreenButton";
import { useModalContext } from "./Modal";

function CloseButton(props: ButtonProps) {
  const intl = useIntl();
  return (
    <Button
      appearance="tertiary-clear"
      aria-label={intl.formatMessage({
        defaultMessage: "Close modal",
        id: "4VwnfS",

        description: "Label for a button to close a modal",
      })}
      icon={<CloseIcon />}
      size="sm"
      {...props}
    />
  );
}

export interface ModalHeaderProps extends CardHeaderProps {
  /**
   * When true, the ModalHeader will render a close button in the upper right corner.
   */
  enableClose?: boolean;
  /**
   * When true, the ModalHeader will render a expand button in the upper right corner.
   */
  enableFullScreen?: boolean;
}

/**
 * A styled container to be used within a Modal.
 */
export function ModalHeader({
  actions,
  appearance = "striped",
  enableClose = true,
  enableFullScreen = false,
  ...passThrough
}: ModalHeaderProps): ReactElement {
  const { closeModalWithConfirm, isFullScreen, setIsFullScreen } =
    useModalContext();
  return (
    <CardHeader
      {...passThrough}
      appearance={appearance}
      actions={
        <>
          {actions}
          {enableFullScreen && (
            <FullScreenButton
              isFullScreen={isFullScreen}
              onClick={() => setIsFullScreen(!isFullScreen)}
            />
          )}
          {enableClose && <CloseButton onClick={closeModalWithConfirm} />}
        </>
      }
    />
  );
}
