import { defineMessages } from "react-intl";

export const messageMap = defineMessages({
  title: {
    defaultMessage: "Auto Create Account Groups",
    id: "trMJRr",
    description: "Description for GCP Account Groups Card Title",
  },
  tooltip: {
    defaultMessage: "Auto-creation of account groups cannot be undone.",
    id: "Uekf4x",
    description: "Tooltip content for Auto Map toggle button",
  },
  recurseHierarchyLabel: {
    defaultMessage: "Recurse Hierarchy",
    id: "WT6K3M",
    description: "Recurse Hierarchy checkbox label",
  },
  recurseHierarchyTooltip: {
    defaultMessage:
      "Iterates selected folders to create account groups and map projects to the account group.",
    id: "bvSyJx",
    description: "Recurse Hierarchy checkbox tooltip",
  },
});
