import {
  useCallback,
  useState,
  type CSSProperties,
  type ReactNode,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Body,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  type ModalProps,
  type RenderModalProps,
} from "ui";
import { classNames } from "utils";

type ConfirmationModalProps = {
  disabledPrimary?: boolean;
  onConfirm: (() => void) | (() => Promise<void>);
  prompt: ReactNode;
  style?: CSSProperties;
  title: string;
  size?: ModalProps["size"];
  additionalInfo?: string;
  confirmButtonName?: string;
  waitConfirm?: boolean;
  isDelete?: boolean;
  isCheckbox?: boolean;
  confirmationText?: string;
};

// TODO: IVG-10531 use useDeleteRecord hook
export default function ConfirmationModal({
  closeModal,
  disabledPrimary = false,
  isOpen,
  onConfirm,
  prompt,
  style = {},
  title,
  size,
  additionalInfo,
  confirmButtonName,
  waitConfirm,
  isDelete = false,
  isCheckbox = false,
  confirmationText,
}: RenderModalProps<ConfirmationModalProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const confirmWait = useCallback(async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    closeModal();
  }, [closeModal, onConfirm]);

  return (
    <Modal
      onClose={closeModal}
      style={style}
      isOpen={isOpen}
      size={size}
      eventsToClose={{
        enableClickOutside: !isLoading,
        enableEscapeKey: !isLoading,
      }}
    >
      <ModalHeader enableClose={!isLoading} title={title} />
      <ModalBody>
        {additionalInfo && <Body addClassName={"mb-6"}>{additionalInfo}</Body>}
        <p className={classNames(additionalInfo && "font-bold")}>{prompt}</p>
        {isCheckbox && (
          <div className="mt-4 flex">
            {confirmationText || (
              <FormattedMessage
                defaultMessage="I agree"
                id="/EhT45"
                description="Confirmation text for checkbox"
              />
            )}
            <Checkbox
              checked={isConfirmed}
              onClick={() => {
                setIsConfirmed(!isConfirmed);
              }}
              addClassName="ml-2"
            ></Checkbox>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          data-testid="modal-cancel-button"
          data-autofocus
          onClick={closeModal}
          disabled={isLoading}
        >
          <FormattedMessage
            defaultMessage="Cancel"
            id="pr1jUo"
            description="Button label Confirmation Modal"
          />
        </Button>
        <Button
          data-testid="modal-submit-button"
          appearance={isDelete ? "danger" : "primary"}
          onClick={waitConfirm ? confirmWait : onConfirm}
          disabled={
            (isCheckbox && !isConfirmed) || disabledPrimary || isLoading
          }
          isLoading={isLoading}
        >
          {confirmButtonName || (
            <FormattedMessage
              defaultMessage="Confirm"
              id="fLjFma"
              description="Button label Confirmation Modal"
            />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function useConfirmationModal() {
  return useModal(ConfirmationModal);
}
