import { useQuery } from "@tanstack/react-query";
import { cloudAccountsKeys, getAwsCloudAccountsCas } from "requests";

type UseGetAwsCloudAccountsCasCasProps = {
  accountId: string;
  options?: Record<string, string | boolean>;
};

export const useGetAwsCloudAccountsCas = ({
  accountId,
  options = {},
}: UseGetAwsCloudAccountsCasCasProps) => {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.awsCloudAccountsCas({
      accountId,
    }),
    queryFn: getAwsCloudAccountsCas,
    retry: false,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
};
