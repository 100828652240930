/* eslint-disable react/jsx-no-literals */
import { Link, RouterLink } from "router";
import { InternalComponent } from "../../components/InternalComponent/InternalComponent";
import { templateRoute } from "./templateRoute";

export const Template = () => {
  return (
    <div className="m-4">
      <InternalComponent />
      <RouterLink to={templateRoute.id}>Template</RouterLink>
      <RouterLink withChild to={"/template"} aria-label="Template Page">
        Template
      </RouterLink>
      {/* @ts-expect-error bad route should raise an error */}
      <RouterLink to="/foo">foo</RouterLink>
      {/* @ts-expect-error bad route should raise an error */}
      <Link to="/foo">foo</Link>
      <Link to={"/template"}>Template</Link>
      <Link to="/alerts/overview">alerts</Link>
    </div>
  );
};
