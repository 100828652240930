import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const IdentitySecurityIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Identity Security",
    id: "Ndsd6M",

    description: "Security Capabilities Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <title>{alt}</title>
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="M17.2578 8.1875L14.1133 6.95703C14.0039 6.90234 13.7578 6.84766 13.4844 6.95703L10.3398 8.1875C10.0664 8.29688 9.875 8.57031 9.875 8.84375C9.875 11.9062 11.7344 14.0117 13.4844 14.6953C13.7578 14.8047 13.9766 14.75 14.1133 14.6953C15.5078 14.1484 17.75 12.2617 17.75 8.84375C17.75 8.57031 17.5586 8.29688 17.2578 8.1875ZM13.8125 13.4102V8.24219L16.4102 9.25391C16.2461 11.6328 14.7422 12.9453 13.8125 13.4102ZM6.375 7.75C8.28906 7.75 9.875 6.19141 9.875 4.25C9.875 2.33594 8.28906 0.75 6.375 0.75C4.43359 0.75 2.875 2.33594 2.875 4.25C2.875 6.19141 4.43359 7.75 6.375 7.75ZM9 8.84375C9 8.78906 9 8.73438 9.02734 8.65234C8.94531 8.65234 8.89062 8.625 8.80859 8.625H8.34375C7.74219 8.92578 7.08594 9.0625 6.375 9.0625C5.66406 9.0625 4.98047 8.92578 4.37891 8.625H3.91406C1.89062 8.625 0.25 10.293 0.25 12.3164V13.4375C0.25 14.1758 0.824219 14.75 1.5625 14.75H11.1875C11.3516 14.75 11.543 14.7227 11.707 14.6406C10.2305 13.4922 9 11.4688 9 8.84375Z"
      />
    </SvgIcon>
  );
};
