import { defineMessages } from "react-intl";

export const memberAccountValidationErrorMessages = defineMessages({
  aws_all_exclude: {
    defaultMessage:
      "Root cannot be excluded from onboarding, please select a specific OU/Member account to proceed",
    id: "TFPz0e",
    description: "Description for all nodes are selected in exclude mode",
  },
  aws_all_include: {
    defaultMessage:
      "Select the 'All' tab if you would like to onboard all the member accounts",
    id: "zUpHF6",
    description: "Description for all nodes are selected in include mode",
  },
  aws_none_exclude: {
    defaultMessage: "Select OU/Account to exclude",
    id: "CWI5jU",
    description: "Description for all nodes are unselected in exclude mode",
  },
  aws_none_include: {
    defaultMessage: "Select OU/Account to include",
    id: "oebOuJ",
    description: "Description for all nodes are unselected in include mode",
  },
  azure_all_exclude: {
    defaultMessage:
      "Select the “All Subscriptions” tab if you would like to onboard everything in your Tenant",
    id: "+4ExT+",
    description: "Description for all nodes are unselected in exclude mode",
  },
  azure_all_include: {
    defaultMessage:
      'Select the “All Subscriptions" tab if you would like to onboard everything in your Tenant',
    id: "5OSzAc",
    description: "Description for all nodes are selected in include mode",
  },
  azure_none_exclude: {
    defaultMessage: "Select subscriptions to exclude",
    id: "W60nXZ",
    description: "Description for all nodes are selected in exclude mode",
  },
  azure_none_include: {
    defaultMessage: "Select subscriptions to include",
    id: "RBGHh/",
    description: "Description for all nodes are selected in include mode",
  },
  gcp_all_exclude: {
    defaultMessage:
      "Select the “All projects” tab if you would like to onboard everything in your organization.",
    id: "wXcXbL",
    description: "Description for all nodes are unselected in exclude mode",
  },
  gcp_all_include: {
    defaultMessage:
      "Select the “All projects” tab if you would like to onboard everything in your organization.",
    id: "J9lL8q",
    description: "Description for all nodes are selected in include mode",
  },
  gcp_none_exclude: {
    defaultMessage: "Select projects to exclude",
    id: "ClB7EW",
    description: "Description for all nodes are selected in exclude mode",
  },
  gcp_none_include: {
    defaultMessage: "Select projects to include",
    id: "YNL2YR",
    description: "Description for all nodes are selected in include mode",
  },
});
