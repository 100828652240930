/* c8 ignore start */

import { fetchAndDownloadFile } from "../../../utils";

export const downloadDynamicTerraform = async (
  payload: Record<string, unknown>,
) => {
  const response = await fetchAndDownloadFile({
    endPoint: "cloud/oci/terraform",
    fileName: "terraform.tf.zip",
    payload,
  });
  const responseHeaders: Record<string, unknown> = {};
  response.headers.forEach((val, key) => (responseHeaders[key] = val));
  return responseHeaders;
};
/* c8 ignore stop */
