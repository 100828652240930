/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { isDevelopment } from "environment";
import { lazy } from "react";
import { InventoryFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

export const AssetInventory = lazy(() => import("./AssetInventory"));

export const AssetInventorySearchSchema = z.object({
  filters: InventoryFilterSchema.catch((ctx: unknown) => {
    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.warn(ctx);
    }
    return {};
  }).optional(),
});

export const assetInventoryRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "assets",
  component: AssetInventory,
  validateSearch: AssetInventorySearchSchema,
});
