import { useFormContext } from "react-hook-form";
import { Button } from "ui";
import { useWizardContext } from "./WizardContext";

import { type ReactElement } from "react";
import { type ButtonProps } from "ui";
import { type WizardStepState } from "./useWizardState";

export function NextButton({
  children = "Next",
  validateStep,
  onClick,
  disabled = false,
  validateForm,
  ...passThrough
}: ButtonProps & {
  validateStep?: () => boolean;
  validateForm?: boolean;
}): ReactElement {
  const {
    gotoStep,
    submitStep,
    state: { activeIndex, steps },
  } = useWizardContext();
  const {
    getValues,
    trigger,
    formState: { isValid },
  } = useFormContext();

  return (
    <Button
      appearance="primary"
      onClick={async (e) => {
        const values = getValues();
        const isStepValid = await trigger();

        if (!isStepValid) return;
        if (validateStep && !validateStep()) return;

        submitStep({
          index: activeIndex,
          values: values,
        });

        gotoStep({
          next: Math.min(activeIndex + 1, steps.length - 1),
        });

        onClick?.(e);
      }}
      data-selector="next-button"
      disabled={disabled || (validateForm && !isValid)}
      {...passThrough}
    >
      {children}
    </Button>
  );
}

export function PrevButton({
  children = "Previous",
  onClick,
  ...passThrough
}: ButtonProps): ReactElement {
  const {
    gotoStep,
    state: { activeIndex },
  } = useWizardContext();
  const { getValues } = useFormContext();

  return (
    <Button
      onClick={(e) => {
        const values = getValues() as Record<string, unknown>;
        gotoStep({
          next: Math.max(activeIndex - 1, 0),
          currentStep: { index: activeIndex, values } as {
            index: number & Partial<WizardStepState>;
          },
        });
        onClick?.(e);
      }}
      {...passThrough}
    >
      {children}
    </Button>
  );
}
