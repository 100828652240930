import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { GetProfileResponse, UserDomainsResponse } from "./schemas";

export const profileKeys = {
  all: [{ scope: "profile" }] as const,
  lists: () => [{ ...profileKeys.all[0], entity: "list" }] as const,
  list: () => profileKeys.lists(),
  domains: () => [{ ...profileKeys.all[0], entity: "domain" }] as const,
};

export const getProfile = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: QueryFunctionContext<ReturnType<(typeof profileKeys)["list"]>>,
) => {
  return jsonApi({
    path: "/user/v1/profile/me",
    requestSchema: z.void(),
    responseSchema: GetProfileResponse,
  });
};

export const getDomains = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: QueryFunctionContext<ReturnType<(typeof profileKeys)["domains"]>>,
) => {
  return jsonApi({
    path: "user/domain",
    requestSchema: z.void(),
    responseSchema: UserDomainsResponse,
  });
};
