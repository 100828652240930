import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsEmailIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Email",
    id: "qOLUuE",

    description: "Email icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path d="M88.2979 39.7874C84.8936 42.7661 80.2128 45.7448 64.4681 57.6597C61.4894 60.2129 55.5319 65.3193 50 65.3193C44.4681 65.3193 38.9362 60.2129 35.5319 57.6597C19.7872 46.1703 15.1064 42.7661 11.7021 39.7874C10.8511 39.3618 10 39.7874 10 40.6384V72.1278C10 76.3831 13.4043 79.7874 17.6596 79.7874H82.3404C86.5957 79.7874 90 76.3831 90 72.1278V40.6384C89.5745 39.7874 88.7234 39.3618 88.2979 39.7874ZM50 59.7874C53.4043 59.7874 58.5106 55.1065 61.4894 53.4044C82.3404 38.5108 83.617 36.8086 88.2979 33.4044C89.1489 32.9788 90 31.7023 90 30.4257V27.8725C90 23.6171 86.5957 20.2129 82.3404 20.2129H17.6596C13.4043 20.2129 10 23.6171 10 27.8725V30.8512C10 32.1278 10.4255 33.4044 11.2766 33.8299C16.383 37.2342 17.6596 38.5108 38.5106 53.4044C41.0638 55.532 46.1702 60.2129 50 59.7874Z" />
    </SvgIcon>
  );
};
