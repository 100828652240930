/* c8 ignore start */
export function downloadFile({
  fileName,
  file,
  type,
}: {
  fileName: string;
  file: Blob | string;
  type?: string;
}): void {
  const blob = type ? new Blob([file], { type }) : (file as Blob);

  const element = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  element.setAttribute("href", url);
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);

  element.dispatchEvent(
    new MouseEvent("click", {
      bubbles: false,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(element);

  window.setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 0);
}
/* c8 ignore stop */
