import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const AnomaliesLayout = lazy(() => import("./AnomaliesLayout"));

export const anomaliesLayoutRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "anomalies",
  component: AnomaliesLayout,
});
