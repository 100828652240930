import {
  ALL,
  AWS,
  AWS_OU_HIERARCHY_SELECTION,
  INCLUDE,
  ORGANIZATION,
} from "../../../../../../constants";
import {
  Accordion,
  AccordionToggle,
} from "../../../../components/CustomAccordion";
import { getCredentialsErrorMessage } from "../../../../components/SelectMemberAccountCredentialError";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { grayScale } from "colors";
import { LoadingIcon, UserAtIcon } from "icons";
import { isEmpty } from "remeda";
import { useWizardContext } from "../../../../../../../components/Wizard";
import {
  useIsRenderedFirstTime,
  useIsUnMounted,
} from "../../../../../../hooks";
import {
  getAccountIdFromRoleArn,
  getOUPreviouslySelectedNodesErrorMessage,
} from "../../../../utils";
import { useAWSSelectMemberAccountsContext } from "../../../context/AwsSelectMemberAccountsContext";
import { setAwsOUProjectSelectMode } from "../../../context/AwsSelectMemberAccountsContext/state/actions";
import {
  getAccountId,
  getAwsOUFolderViewCredentialsError,
  getAwsOUPreviouslySelectedNodesNotFound,
  getAwsOUProjectSelectMode,
  getAwsOUSelectProjectsIsLoading,
  getHierarchySelection,
  getRoleARN,
} from "../../../context/AwsSelectMemberAccountsContext/state/getters";
import AWSChooseMemberAccounts from "./AWSChooseMemberAccounts";

type SelectMemberAccountsProps = {
  closeModal: () => void;
};

export default function SelectMemberAccounts({
  closeModal,
}: SelectMemberAccountsProps) {
  const {
    state: { steps },
  } = useWizardContext();

  const { accountType = "" } = steps[0]?.values ?? {};

  if (accountType !== ORGANIZATION) {
    return null;
  }
  return <TreeHierarchySelection closeModal={closeModal} />;
}

function TreeHierarchySelection({ closeModal }: SelectMemberAccountsProps) {
  const intl = useIntl();
  const { getValues, watch } = useFormContext();
  const isUnMounted = useIsUnMounted();
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();

  const { state, dispatch } = useAWSSelectMemberAccountsContext();

  const {
    valuesUpdate,
    state: { activeIndex },
  } = useWizardContext();

  const externalId = watch("externalId");

  const mode = getAwsOUProjectSelectMode(state);
  const roleArn = getRoleARN(state);
  const accountId = getAccountId(state);
  const accountIdFromRoleArn = getAccountIdFromRoleArn(roleArn);
  const isLoading = getAwsOUSelectProjectsIsLoading(state) && mode === ALL;
  const credentialError = getAwsOUFolderViewCredentialsError(state);
  const previouslySelectedNodesNotFound =
    getAwsOUPreviouslySelectedNodesNotFound(state);

  const disabled =
    isEmpty(externalId) ||
    isEmpty(roleArn) ||
    !isEmpty(credentialError) ||
    accountId !== accountIdFromRoleArn;

  const [isDisabled, setDisabled] = useState(() => mode !== ALL);

  const toggle = () =>
    setAwsOUProjectSelectMode(isDisabled ? ALL : INCLUDE, dispatch);

  // Open/close accordion on mode change
  useEffect(() => {
    if (!isRenderedFirstTime) {
      setDisabled(mode !== ALL);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Change mode to all if toggle state is disabled
  useEffect(() => {
    if (disabled) {
      setAwsOUProjectSelectMode(ALL, dispatch);
    }
  }, [disabled, dispatch]);

  // Get credentials and prevSelectedNode Error Messages
  const selectProjectErrors = useMemo(() => {
    const credErr = getCredentialsErrorMessage({
      errorMessage: credentialError,
      type: AWS,
      intl,
    });

    const prevNodeErr = getOUPreviouslySelectedNodesErrorMessage({
      data: previouslySelectedNodesNotFound,
      intl,
    });

    return [...prevNodeErr, ...credErr].filter((v) => !isEmpty(v));
  }, [credentialError, intl, previouslySelectedNodesNotFound]);

  // Update step context and save tree nodes selection on unmount of component
  const updateStepValues = useCallback(() => {
    if (isUnMounted.current) {
      const values = getValues();
      const hierarchySelectionSelectedNodes = getHierarchySelection(state);
      valuesUpdate({
        index: activeIndex,
        values: {
          ...values,
          [AWS_OU_HIERARCHY_SELECTION]: hierarchySelectionSelectedNodes,
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    return () => updateStepValues();
  }, [updateStepValues]);

  const isCardOpen = isDisabled;
  const isToggleChecked = !isDisabled;

  return (
    <Accordion
      description={intl.formatMessage({
        defaultMessage:
          "Prisma Cloud recommends selecting all member accounts (default). Disable to include or exclude specific accounts from monitoring.",
        id: "HPh5jk",
        description: "description for Select All Member Accounts card header",
      })}
      disabled={disabled}
      errors={selectProjectErrors}
      isOpen={isCardOpen}
      icon={<UserAtIcon size="lg" color={grayScale[600]} />}
      menuToggle={
        isLoading ? (
          <LoadingIcon size="md" />
        ) : (
          <AccordionToggle
            checked={isToggleChecked}
            disabled={disabled}
            onClick={toggle}
          />
        )
      }
      title={intl.formatMessage({
        defaultMessage: "Select All Member Accounts",
        id: "3Za+Qz",
        description: "description for Select All Member Accounts card header",
      })}
    >
      <AWSChooseMemberAccounts closeModal={closeModal} />
    </Accordion>
  );
}
