import { useIsBusinessLicenseType } from "prisma";
import {
  GCP,
  ORGANIZATION,
  REMEDIATION,
  WORKSPACE_DOMAIN,
  WORKSPACE_DOMAIN_PAYLOAD,
} from "../../../../../constants";
import { useGetSupportedFeaturesList } from "../../../../../hooks";
import {
  setAccountName,
  setOrgId,
} from "../../context/GCPSelectMonitoredProjectContext/state/actions";

import { useMemo } from "react";
import { useWizardContext } from "../../../../../../components/Wizard";
import { type GcpGetStartedStepValuesType } from "../../../../../types";
import AccountName from "../../../components/AccountName";
import AutoOnboardProjects from "../../../components/AutoOnboardProjects";
import FlowLogs from "../../../components/FlowLogs";
import OrganizationId from "../../../components/OrganizationId";
import ProjectId from "../../../components/ProjectId";
import RemediationCard from "../../../components/RemediationCard";
import { useGCPSelectMonitoredProjectsContext } from "../../context/GCPSelectMonitoredProjectContext";
import GcpWorkspaceConfiguration from "./GcpWorkspaceConfiguration/GcpWorkspaceConfiguration";
import JsonFileUpload from "./JsonFileUpload";
import SelectAccountGroups from "./SelectAccountGroups";
import SelectMonitoredProjects from "./SelectMonitoredProjects";

export type AccountDetailsProps = {
  closeModal: () => void;
  isEdit: boolean;
  isGcpWifEnabled: boolean;
  isFederatedGcpWifEnabled: boolean;
  setAuthenticationType: React.Dispatch<React.SetStateAction<string>>;
};

export default function AccountDetails({
  closeModal,
  isEdit,
  isGcpWifEnabled,
  setAuthenticationType,
  isFederatedGcpWifEnabled,
}: AccountDetailsProps) {
  const { isBusinessLicenseType } = useIsBusinessLicenseType();
  const {
    state: { steps },
  } = useWizardContext();

  const { dispatch: selectMonitoredProjectsDispatch } =
    useGCPSelectMonitoredProjectsContext();

  const { accountType } = steps[0]?.values as GcpGetStartedStepValuesType;
  const isOrg = accountType === ORGANIZATION;
  const isGcpWorkspace = accountType === WORKSPACE_DOMAIN;
  const onOrgIdBlur = (event: React.FocusEvent<HTMLInputElement, Element>) =>
    selectMonitoredProjectsDispatch(setOrgId(event?.target?.value ?? ""));

  const onAccountNameBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) =>
    selectMonitoredProjectsDispatch(setAccountName(event?.target?.value ?? ""));

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: GCP,
    payload: {
      accountType:
        accountType === WORKSPACE_DOMAIN
          ? WORKSPACE_DOMAIN_PAYLOAD
          : accountType,
    },
  });

  const isRemediationSupported = useMemo(
    () => supportedFeaturesList.some((feature) => feature === REMEDIATION),
    [supportedFeaturesList],
  );

  if (isGcpWorkspace) {
    return (
      <GcpWorkspaceConfiguration
        isEdit={isEdit}
        isGcpWifEnabled={isGcpWifEnabled}
        isFederatedGcpWifEnabled={isFederatedGcpWifEnabled}
        closeModal={closeModal}
        onAccountNameBlur={onAccountNameBlur}
        setAuthenticationType={setAuthenticationType}
      />
    );
  }

  return (
    <section className="space-y-2">
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            {isOrg ? (
              <OrganizationId isEdit={isEdit} onBlur={onOrgIdBlur} />
            ) : (
              <ProjectId isEdit={isEdit} />
            )}
          </div>
          <div className="w-1/2">
            <AccountName onBlur={onAccountNameBlur} />
          </div>
        </div>

        {isRemediationSupported && <RemediationCard />}
        {!isBusinessLicenseType && <FlowLogs />}
        {!isOrg && <AutoOnboardProjects isDisabled={isEdit} />}

        <JsonFileUpload
          isEdit={isEdit}
          isGcpWifEnabled={isGcpWifEnabled}
          setAuthenticationType={setAuthenticationType}
          isFederatedGcpWifEnabled={isFederatedGcpWifEnabled}
        />

        <SelectMonitoredProjects closeModal={closeModal} />

        <SelectAccountGroups isEdit={isEdit} />
      </div>
    </section>
  );
}
