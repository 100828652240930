import { RadioGroup, useFormContext } from "form";
import {
  CaretDownIcon,
  CaretUpIcon,
  CloudScanIcon,
  InfoTooltipIcon,
  ScanSameAccountIcon,
} from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState, type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "remeda";
import {
  Body,
  Bold,
  Button,
  Card,
  CardBody,
  CardHeader,
  DynamicFormattedMessage,
  Tag,
  Toggle,
  Tooltip,
} from "ui";
import { classNames } from "utils";
import {
  AWS,
  MASTER_SERVICE_ACCOUNT,
  ORGANIZATION,
  TENANT,
} from "../../../../constants";
import { featuresMessageMap } from "../../../../messages/featuresMessageMap";
import { type AccountType, type CloudType } from "../../../../types";
import { tagMessageMap } from "../../../Onboarding/components/CheckboxFeatureCard/CheckboxFeatureCard";
import FeatureTooltip from "../../../Onboarding/components/FeatureTooltip";
import AutoScanOrgToggle from "../AutoScanOrgToggle";

type ToggleFeatureCardProps = {
  checked: boolean;
  id: string;
  icon: ReactNode;
  onChange: (...event: unknown[]) => void;
  toggleChecked: boolean;
  cloudType?: CloudType;
  accountType?: AccountType;
  isComputeWorkloadSupported?: boolean;
  onToggle: () => void;
  showToggle?: boolean;
  tag?: string;
  deploymentType?: string;
};

export default function ToggleFeatureCard({
  checked,
  id,
  icon,
  toggleChecked,
  cloudType,
  onChange,
  onToggle,
  isComputeWorkloadSupported,
  accountType,
  showToggle = false,
  tag,
  deploymentType,
  ...passThrough
}: ToggleFeatureCardProps) {
  const { cloudScanMode } = useFlags();
  const intl = useIntl();
  const { getValues, getFieldState } = useFormContext();
  const { roleArn } = getValues();
  const errorMessage = getFieldState("roleArn");
  const isGovCloud = deploymentType?.includes("gov");

  let featureId = id;
  if (cloudType === AWS && id === "threatDetection") {
    featureId = "threatDetection_aws";
  }

  const isOrg = accountType === ORGANIZATION;

  const isGroupedAccount =
    isComputeWorkloadSupported &&
    (accountType === ORGANIZATION ||
      accountType === TENANT ||
      accountType === MASTER_SERVICE_ACCOUNT);

  const [isOpen, setIsOpen] = useState(id === "agentlessScan" ? true : false);

  const toggleDisabled =
    id === "dataSecurity" &&
    (isEmpty(roleArn) || !isEmpty(errorMessage?.error?.message));

  const defaultCards = id === "misconfigurations" || id === "identitySecurity";

  const items = [
    {
      value: "cloud-scan",
      tag: (
        <Bold>
          <Tag>
            <FormattedMessage
              defaultMessage="Recommended"
              id="pnEgtL"
              description="Description for Recommended label"
            />
          </Tag>
        </Bold>
      ),
      title: "",
      description: (
        <div className="-mt-10 flex">
          <CloudScanIcon size="4xl" />
          <div className="pl-2">
            <Bold addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Cloud Scan"
                id="6IlfMe"
                description="Title for agentless scanning modes"
              />
            </Bold>
            <Body addClassName="pt-2 text-xs" appearance="secondary">
              <FormattedMessage
                defaultMessage="Scan your environments with Prisma Cloud."
                id="YgU5co"
                description="Description for agentless scanning mode"
              />
            </Body>
          </div>
        </div>
      ),
    },
    {
      value: "target-scan",
      title: "",
      description: (
        <div className="-mt-10 flex">
          <ScanSameAccountIcon size="4xl" />
          <div className="pl-2">
            <Bold addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Scan in your environment"
                id="05mm7m"
                description="Title for agentless scanning modes"
              />
            </Bold>
            <Body addClassName="pt-2 text-xs" appearance="secondary">
              <FormattedMessage
                defaultMessage="Incurs additional costs, permissions and configuration steps."
                id="s2Ni+8"
                description="Description for agentless scanning mode"
              />
            </Body>
          </div>
        </div>
      ),
    },
  ];

  const title = (
    <div className="mb-1 mt-2 flex flex-row items-center space-x-1">
      <span className="text-sm">
        <DynamicFormattedMessage messageMap={featuresMessageMap} token={id} />
      </span>
      <Tooltip
        label={
          <FeatureTooltip
            id={id}
            header={
              <DynamicFormattedMessage
                messageMap={featuresMessageMap}
                token={id}
              />
            }
            content={
              <DynamicFormattedMessage
                messageMap={featuresMessageMap}
                token={`${featureId}_tooltip_content`}
                fallback={
                  featuresMessageMap["misconfigurations_tooltip_content"]
                }
              />
            }
          />
        }
      >
        <span>
          <InfoTooltipIcon aria-label={`infoIcon-${id}`} />
        </span>
      </Tooltip>
    </div>
  );

  const subTitle = (
    <span className="mb-2">
      <DynamicFormattedMessage
        messageMap={featuresMessageMap}
        token={`${featureId}_desc`}
      />
    </span>
  );

  const onToggleClick = () => {
    onChange();
    if (toggleChecked) setIsOpen(true);
  };

  return (
    <Card>
      <CardHeader
        title={title}
        subTitle={subTitle}
        icon={icon}
        actions={
          !(id === "misconfigurations" || id === "identitySecurity") ? (
            <>
              <div className="ml-auto flex space-x-2">
                <span className="text-xs">
                  <FormattedMessage
                    defaultMessage="{checked, select, true {Enabled} other {Disabled}}"
                    id="Nxexul"
                    description="label for the organization scan toggle"
                    values={{ checked: checked || defaultCards }}
                  />
                </span>

                <ConditionalWrapper
                  condition={toggleDisabled}
                  wrapper={(children: ReactNode) => (
                    <Tooltip
                      label={intl.formatMessage({
                        defaultMessage:
                          "Please provide the RoleARN to enabled the Data Security",
                        id: "HVnYwT",
                        description: "Tooltip message to enable Data Security",
                      })}
                    >
                      {children}
                    </Tooltip>
                  )}
                >
                  <Toggle
                    checked={checked || defaultCards}
                    onClick={onToggleClick}
                    {...passThrough}
                    data-selector={id}
                    disabled={toggleDisabled || defaultCards}
                  />
                </ConditionalWrapper>
              </div>

              <Button
                aria-label="Accordion icon-only button"
                icon={isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
                appearance="clear"
                onClick={() => {
                  setIsOpen((prev) => !prev);
                }}
                addClassName={classNames(
                  id === "remediation" ||
                    id === "autoProtect" ||
                    !checked ||
                    !isOrg
                    ? "invisible"
                    : "visible",
                )}
              />
            </>
          ) : (
            <>
              {!!tag && (
                <div className="ml-auto">
                  <Tag size={checked ? "md" : "sm"}>
                    <DynamicFormattedMessage
                      messageMap={tagMessageMap}
                      token={tag as string}
                    />
                  </Tag>
                </div>
              )}
            </>
          )
        }
      />
      {(isOrg || (id === "agentlessScan" && cloudScanMode && !isGovCloud)) && (
        <CardBody addClassName={classNames((!isOpen || !checked) && "hidden")}>
          {id !== "remediation" &&
            id !== "autoProtect" &&
            isGroupedAccount &&
            showToggle &&
            onToggle && (
              <AutoScanOrgToggle
                onChange={onToggle}
                toggleChecked={toggleChecked}
              />
            )}

          {cloudScanMode &&
            id === "agentlessScan" &&
            !isGovCloud &&
            checked &&
            cloudType === AWS && (
              <div className={`ml-6 space-y-4 ${isOrg && "mt-2"}`}>
                <Body>
                  <FormattedMessage
                    defaultMessage="Select where to scan"
                    id="LRbe9m"
                    description="Scanning options title"
                  />
                </Body>
                <div className="-ml-2">
                  <RadioGroup
                    addClassName="flex !flex-row space-x-2 space-y-0 [&>label>div>div:last-child>p]:items-start [&>label>div]:h-full [&>label]:relative [&>label]:flex-1"
                    data-testid="scanning-mode-radio-group"
                    name="mode"
                    items={items}
                    label={intl.formatMessage({
                      defaultMessage: "Scanning Modes",
                      id: "0A3Fqp",
                      description: "radio group description",
                    })}
                    showLabel={false}
                  />
                </div>
              </div>
            )}
        </CardBody>
      )}
    </Card>
  );
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  children: ReactNode;
  wrapper: (children: ReactNode) => JSX.Element;
}) => {
  return <>{condition ? wrapper(children) : children}</>;
};
