import { type ComponentProps, type FC } from "react";
import { classNames } from "utils";

export const Footer: FC<ComponentProps<"div">> = (props) => {
  const className = classNames("sticky bottom-0");

  return (
    <div
      role="rowgroup"
      style={{ zIndex: 1 }}
      {...props}
      className={className}
    />
  );
};
