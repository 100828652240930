import { Route } from "@tanstack/react-router";
import { z } from "zod";
import { publicRootRoute } from "../../publicRootRoute";
import Login from "./Login";

const LoginSearchSchema = z.object({
  loginProxy: z.optional(z.boolean().catch(false)),
  timeout: z.optional(z.boolean().catch(false)),
  skipAnimation: z.optional(z.boolean()),
  apiUrl: z.optional(z.string()),
});

export const loginRoute = new Route({
  getParentRoute: () => publicRootRoute,
  path: "/login",
  component: Login,
  validateSearch: LoginSearchSchema,
});
