import { useEffect } from "react";

import { pendoSnippet } from "./pendoSnippet";
import { isPendoEnabled } from "./utils";

export function usePendoSnippet() {
  useEffect(() => {
    if (isPendoEnabled() && !globalThis.pendo) {
      pendoSnippet("0157cf0f-15cc-47cc-6a2f-e92efcbed150");
    }
  }, []);

  return {
    pendoSnippetLoaded: !!globalThis.pendo,
    pendoInstance: globalThis.pendo,
  };
}
