import { fetchAndDownloadFile } from "../../../Onboarding/utils";

/* c8 ignore start */
export const downloadDynamicIAMRoleCft = async (
  payload: Record<string, unknown>,
) => {
  const response = await fetchAndDownloadFile({
    endPoint: `cas/v1/aws_template`,
    fileName: "prisma-cloud-aws-iam-role.template",
    payload,
  });
  const responseHeaders: Record<string, unknown> = {};
  response.headers.forEach((val, key) => (responseHeaders[key] = val));
  return responseHeaders;
};

export function redirectUrl(redirectLink: string) {
  if (!redirectLink) return;

  const element = document.createElement("a");
  element.setAttribute("target", "_blank");
  element.setAttribute("rel", "noopener noreferrer");
  element.setAttribute("href", redirectLink);
  element.style.display = "none";
  document.body.appendChild(element);

  element.dispatchEvent(
    new MouseEvent("click", {
      bubbles: false,
      cancelable: true,
      view: window,
    }),
  );
  document.body.removeChild(element);

  window.setTimeout(() => {
    window.URL.revokeObjectURL(redirectLink);
  }, 0);
}
/* c8 ignore stop */
