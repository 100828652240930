import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsVCSRoute } from "./settingsVCSRoute";

export const IntegrationsCatalog = lazy(
  () => import("./SettingsIntegrationsCatalog"),
);

export const settingsIntegrationsCatalogRoute = new Route({
  getParentRoute: () => settingsVCSRoute,
  path: "catalog",
  component: IntegrationsCatalog,
});

export const settingsIntegrationsCatalogNestedRoute = new Route({
  getParentRoute: () => settingsIntegrationsCatalogRoute,
  path: "$",
  component: IntegrationsCatalog,
});
