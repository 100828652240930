import { useQuery } from "@tanstack/react-query";
import { isChina, isGov } from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsAppDnaSupported, useRole } from "prisma";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  getPermissionGroup,
  permissionGroupsKeys,
  type Features,
} from "requests";
import { Sidecar, SidecarBody } from "ui";
import {
  ActionPlanCard,
  AlarmCentreCard,
  AlertsCard,
  ApplicationInventoryCard,
  AssetInventoryCard,
  CodeSecurityCard,
  ComplianceCard,
  ComputeCard,
  DashboardCard,
  DataSecurityCard,
  DataSecurityPostureManagementCard,
  InvestigateCard,
  PoliciesCard,
  SettingsCard,
} from "./Cards";
import { SidecarActions } from "./SidecarActions";

export type PermissionGroupMap = Record<string, Features>;

type PermissionGroupsSidecarProps = {
  selectedPermissionGroupId: string;
  setSelectedPermissionGroupId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

export function PermissionGroupsSidecar({
  selectedPermissionGroupId,
  setSelectedPermissionGroupId,
}: PermissionGroupsSidecarProps) {
  const intl = useIntl();
  const { actionPlans } = useFlags();
  const isAppDnaSupported = useIsAppDnaSupported();

  const { syntheticRole } = useRole();
  const isSystemAdmin = syntheticRole === "System Admin";

  const onCloseSidecar = useCallback(() => {
    setSelectedPermissionGroupId(undefined);
  }, [setSelectedPermissionGroupId]);

  const { data: permissionGroup, isLoading } = useQuery({
    queryKey: permissionGroupsKeys.permissionGroup({
      id: selectedPermissionGroupId ?? "",
    }),
    queryFn: getPermissionGroup,
    retry: false,
  });

  const featureMap = useMemo(
    () =>
      permissionGroup
        ? permissionGroup.features.reduce<PermissionGroupMap>(
            (acc, curr) => ({ ...acc, [`${curr.featureName}`]: curr }),
            {},
          )
        : {},
    [permissionGroup],
  );

  return (
    <Sidecar
      isOpen={!!selectedPermissionGroupId}
      onClose={onCloseSidecar}
      title={permissionGroup?.name ?? ""}
      subtitle={intl.formatMessage({
        defaultMessage: "Permission Group",
        id: "E8z//e",
        description: "Sidecar sub title",
      })}
      disablePinnedChange
      initialPinned={false}
      size="md"
      actions={
        isSystemAdmin &&
        permissionGroup && <SidecarActions row={permissionGroup} />
      }
    >
      <div className="mt-4 flex flex-col overflow-auto">
        <SidecarBody
          isLoading={isLoading || !permissionGroup}
          addClassName="space-y-4"
        >
          <DashboardCard featureMap={featureMap} />
          <AssetInventoryCard featureMap={featureMap} />
          {isAppDnaSupported && (
            <ApplicationInventoryCard featureMap={featureMap} />
          )}
          <InvestigateCard featureMap={featureMap} />
          <PoliciesCard featureMap={featureMap} />
          <ComplianceCard featureMap={featureMap} />
          <CodeSecurityCard featureMap={featureMap} />
          <AlertsCard featureMap={featureMap} />
          {!isChina() && !isGov() && (
            <DataSecurityPostureManagementCard featureMap={featureMap} />
          )}
          <DataSecurityCard featureMap={featureMap} />
          <SettingsCard featureMap={featureMap} />
          <ComputeCard featureMap={featureMap} />
          <AlarmCentreCard featureMap={featureMap} />
          {actionPlans && <ActionPlanCard featureMap={featureMap} />}
        </SidecarBody>
      </div>
    </Sidecar>
  );
}
