import {
  cloneElement,
  type ComponentPropsWithoutRef,
  type CSSProperties,
  type ReactElement,
} from "react";
import { Button, Tooltip, type ButtonProps } from "ui";

const className = "flex space-x-1";

export function ActionsCell(
  props: ComponentPropsWithoutRef<"div">,
): ReactElement {
  return <div {...props} className={className} />;
}

interface ActionButtonProps extends ButtonProps {
  icon: ReactElement;
  label: string;
  style?: CSSProperties;
}

export function ActionCellButton({
  icon,
  label,
  style = {},
  ...passThrough
}: ActionButtonProps): ReactElement {
  return (
    <Tooltip label={label}>
      <Button
        appearance="tertiary-clear"
        aria-label={label}
        icon={cloneElement(icon, { size: "sm" })}
        size="sm"
        style={style}
        {...passThrough}
      />
    </Tooltip>
  );
}
