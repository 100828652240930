import {
  animated,
  config,
  useChain,
  useSpringRef,
  useTransition,
} from "@react-spring/web";
import { useMemo } from "react";
import { Toast } from "./Toast";

import { type ReactElement } from "react";
import { useToastActions, useToastState } from "stores";

export function Toasts(): ReactElement {
  const toasts = useToastState();
  const { remove } = useToastActions();
  const refMap = useMemo(() => new Map(), []);
  const springRef = useSpringRef();
  const transitions = useTransition(toasts, {
    ref: springRef,
    from: { opacity: 0, height: 0, translateY: "-100%", life: "0%" },
    keys: (toast) => toast.id,
    enter:
      (toast) =>
      /* c8 ignore next */
      /* animations are skipped in tests */
      async (next) => {
        await next({
          opacity: 1,
          height: refMap.get(toast.id).offsetHeight,
          translateY: "0%",
        });
        toast.autoDismiss &&
          (await next({
            life: "100%",
            config: { duration: 5000 },
          }));
      },
    leave: [{ opacity: 0, height: 0, translateY: "-100%" }],
    onRest:
      /* c8 ignore next */
      /* animations are skipped in tests */
      (_result, _ctrl, toast) => {
        toast.autoDismiss && remove(toast.id);
      },
    config: { ...config.stiff, clamp: true },
  });
  useChain([springRef]);

  return (
    <div
      className="absolute right-0 top-0 z-50 max-h-full overflow-auto"
      onMouseEnter={
        /* c8 ignore next */
        () => springRef.pause("life")
      }
      onMouseLeave={
        /* c8 ignore next */
        () => springRef.resume()
      }
    >
      {transitions(({ life, ...style }, toast) => {
        return (
          <animated.div style={style} className="overflow-hidden rounded">
            <Toast
              {...toast}
              ref={(ref) => ref && refMap.set(toast.id, ref)}
              life={life}
              remove={() => remove(toast.id)}
            />
          </animated.div>
        );
      })}
    </div>
  );
}
