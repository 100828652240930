import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../hooks";
import { validGuid } from "../../../../utils";

export default function ServicePrincipalId({
  onBlur,
}: {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}) {
  const intl = useIntl();

  useTrimField("servicePrincipalId");

  return (
    <Input
      data-selector="service-principalid-input"
      name="servicePrincipalId"
      label={intl.formatMessage({
        defaultMessage: "Enterprise Application Object ID",
        id: "MO9xeQ",
        description:
          "The Enterprise Application Object ID of an input field for onboarding an Azure account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Your Enterprise Application Object ID",
        id: "lebuiS",
        description:
          "Placeholder for the Enterprise Application Object ID of an input field for onboarding an Azure account",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Enterprise Application Object ID is required",
          id: "f0g/RA",
          description: "Required message for cloud account",
        }),
        validate: validGuid(intl),
      }}
    />
  );
}
