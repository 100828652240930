import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

export const BusinessCriticalAssets = lazy(
  () => import("./BusinessCriticalAssets"),
);

export const businessCriticalAssetsRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "business-critical-assets",
  component: BusinessCriticalAssets,
});
