import { useMemo } from "react";
import { type ColorTheme } from "requests";
import { themeColorClass } from "../../utils/useThemeColor";
import Crystal from "./crystal.webp";

const halfOfAnimationTime = 5;

export function CrystalIcon({
  theme,
  width,
  height,
  addDelay,
}: {
  theme: ColorTheme;
  width?: string;
  height?: string;
  addDelay?: boolean;
}) {
  const delay = useMemo(
    () => (halfOfAnimationTime * Math.random()).toFixed(2),
    [],
  );

  return (
    <div
      className={`bg-contain bg-no-repeat bg-blend-multiply motion-safe:animate-hover ${themeColorClass(
        theme,
      )}`}
      style={{
        width: width ?? "200px",
        height: height ?? "400px",
        backgroundImage: `url(${Crystal})`,
        WebkitMaskImage: `url(${Crystal})`,
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskSize: "contain",
        maskImage: `url(${Crystal})`,
        maskMode: "alpha",
        maskSize: "contain",
        animationDelay: addDelay ? `${delay}s` : undefined,
      }}
    ></div>
  );
}
