import { z } from "zod";

export const CreateUserRoleItemSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().nullish(),
  lastModifiedBy: z.string().optional(),
  lastModifiedTs: z.number().optional(),
  accountGroupIds: z.array(z.string().optional()),
  resourceListIds: z.array(z.string().optional()).optional(),
  codeRepositoryIds: z.array(z.string().optional()),
  associatedUsers: z.array(z.string().optional()).nullish(),
  restrictDismissalAccess: z.boolean().optional(),
  permissionGroup: z
    .union([
      z.array(z.string().optional()),
      z.object({
        name: z.string(),
        custom: z.boolean(),
        id: z.string(),
      }),
    ])
    .nullish(),
  additionalAttributes: z.object({
    onlyAllowCIAccess: z.boolean().optional(),
    onlyAllowComputeAccess: z.boolean().optional(),
    hasDefenderPermissions: z.boolean().optional(),
    onlyAllowReadAccess: z.boolean().optional(),
  }),
  accountGroups: z
    .array(z.object({ id: z.string(), name: z.string() }).optional())
    .optional(),
  resourceLists: z
    .array(z.object({ id: z.string(), name: z.string() }).optional())
    .optional(),
  codeRepositories: z
    .array(z.object({ id: z.string(), name: z.string() }).optional())
    .optional(),
  roleType: z.string(),
});

export const UserRoleItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  lastModifiedBy: z.string(),
  lastModifiedTs: z.number(),
  accountGroupIds: z.array(z.string().optional()),
  resourceListIds: z.array(z.string().optional()),
  codeRepositoryIds: z.array(z.string().optional()),
  associatedUsers: z.array(z.string().optional()),
  restrictDismissalAccess: z.boolean(),
  permissionGroup: z.array(z.string().optional()).nullish(),
  additionalAttributes: z.object({
    onlyAllowCIAccess: z.boolean(),
    onlyAllowComputeAccess: z.boolean(),
    hasDefenderPermissions: z.boolean().optional(),
    onlyAllowReadAccess: z.boolean(),
  }),
  accountGroups: z.array(
    z.object({ id: z.string(), name: z.string() }).optional(),
  ),
  resourceLists: z.array(
    z.object({ id: z.string(), name: z.string() }).optional(),
  ),
  codeRepositories: z.array(
    z.object({ id: z.string(), name: z.string() }).optional(),
  ),
  roleType: z.string(),
});

export type UserRoleItemType = z.infer<typeof UserRoleItemSchema>;

export const UserRoleResponseSchema = z.array(UserRoleItemSchema);

export type UserRolesType = z.infer<typeof UserRoleResponseSchema>;

export const CloudGroupNamesResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  autoCreated: z.boolean().optional(),
  parentGroupId: z.string().optional(),
});

export type CloudGroupNamesResponseType = z.infer<
  typeof CloudGroupNamesResponseSchema
>;

export const CloudGroupAllNamesResponseSchema = z.array(
  CloudGroupNamesResponseSchema,
);

export type CloudGroupAllName = z.infer<
  typeof CloudGroupAllNamesResponseSchema
>;

export const ResourceListNameSchema = z.object({
  id: z.string(),
  name: z.string(),
  resourceListType: z.string(),
});

export type ResourceListNameType = z.infer<typeof ResourceListNameSchema>;

export const ResourceListAllNamesResponseSchema = z.array(
  ResourceListNameSchema,
);

export type ResourceListNames = z.infer<
  typeof ResourceListAllNamesResponseSchema
>;

export const BCRepositoriesResponseSchema = z.array(
  z.object({
    id: z.string(),
    repository: z.string(),
    source: z.string(),
    owner: z.string(),
    defaultBranch: z.string().nullish(),
    isPublic: z.boolean(),
    runs: z.number(),
    creationDate: z.string(),
    lastScanDate: z.string().nullish(),
    vcsTokens: z.array(z.string()).nullish(),
    description: z.string().nullish(),
    url: z.string().nullish(),
  }),
);

export type BCRepositories = z.infer<typeof BCRepositoriesResponseSchema>;

export const UserRoleNameResponseSchema = z.array(
  z.object({ id: z.string(), name: z.string() }),
);

export type UserRoleNameResponseType = z.infer<typeof UserRoleResponseSchema>;

export const UpdateRolesRequestSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().nullish(),
  lastModifiedBy: z.string().optional(),
  lastModifiedTs: z.number().optional(),
  accountGroupIds: z.array(z.string().optional()),
  resourceListIds: z.array(z.string().optional()),
  codeRepositoryIds: z.array(z.string().optional()),
  associatedUsers: z.array(z.string().optional()).optional(),
  restrictDismissalAccess: z.boolean(),
  permissionGroup: z.array(z.string().optional()).nullish(),
  additionalAttributes: z
    .object({
      onlyAllowCIAccess: z.boolean().optional(),
      onlyAllowComputeAccess: z.boolean().optional(),
      hasDefenderPermissions: z.boolean().optional(),
      onlyAllowReadAccess: z.boolean().optional(),
    })
    .optional(),
  accountGroups: z
    .array(z.object({ id: z.string(), name: z.string() }).optional())
    .optional(),
  resourceLists: z
    .array(z.object({ id: z.string(), name: z.string() }).optional())
    .optional(),
  codeRepositories: z
    .array(z.object({ id: z.string(), name: z.string() }).optional())
    .optional(),
  roleType: z.string(),
});
