import { Set } from "immutable";
import { useCallback } from "react";
import { isEmpty } from "remeda";

import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import SelectMemberAccountTree from "../../../../../../../components/SelectMemberAccountTree";
import { type FlatNodeType } from "../../../../../../../components/SelectMemberAccountTree/types";
import { GCP } from "../../../../../../../constants";
import ErrorMessages from "../../../../../components/ErrorMessages";
import ModelRadioGroup from "../../../../../components/ModelRadioGroup";
import { useGCPSelectMonitoredProjectsContext } from "../../../../context/GCPSelectMonitoredProjectContext";
import {
  setGCPAccountGroupCreationMode,
  setGCPExpandedNodes,
  setGCPProjectSelectMode,
  setGCPSelectedNodes,
} from "../../../../context/GCPSelectMonitoredProjectContext/state/actions";
import {
  getGCPDefaultExpandedNodes,
  getGCPFoldersCredentialError,
  getGCPLoadedNodes,
  getGCPOUSelectProjectsIsLoading,
  getGCPProjectSelectMode,
  getGCPRootId,
  getGCPSelectProjectsInitialized,
  getGCPSelectedNodes,
  getGCPSelectedNodesErrors,
  getGCPTreeMap,
  getIsRateLimit503,
} from "../../../../context/GCPSelectMonitoredProjectContext/state/getters";

type GCPSelectProjectsProps = {
  loadGCPNodeData: UseMutateAsyncFunction<
    void,
    unknown,
    {
      childrenType: string;
      loadType: string;
      parentId: string;
    },
    unknown
  >;
};

export default function GCPSelectProjects({
  loadGCPNodeData,
}: GCPSelectProjectsProps) {
  const { state, dispatch } = useGCPSelectMonitoredProjectsContext();

  const credentialError = getGCPFoldersCredentialError(state);
  const defaultExpandedKeys = getGCPDefaultExpandedNodes(state);
  const hasRateLimitHit = getIsRateLimit503(state);
  const loadedNodes = getGCPLoadedNodes(state);
  const mode = getGCPProjectSelectMode(state);
  const rootId = getGCPRootId(state);
  const selectedNodes = getGCPSelectedNodes(state);
  const selectedNodesErrors = getGCPSelectedNodesErrors(state);
  const treeMap = getGCPTreeMap(state);

  const initialized = getGCPSelectProjectsInitialized(state);
  const isLoading = getGCPOUSelectProjectsIsLoading(state);

  const renderLoader = isLoading;
  const renderTree = !isLoading && initialized;

  /* c8 ignore next */
  const onChangeSelectedNodes = useCallback(
    (checkedNodeList: Set<string>) => {
      setGCPSelectedNodes(checkedNodeList, dispatch);
    },
    [dispatch],
  );

  /* c8 ignore next */
  const onExpandNode = useCallback(
    (expandedList: string[], treeNode: FlatNodeType) => {
      dispatch(setGCPExpandedNodes(Set(expandedList)));
      if (!loadedNodes.has(treeNode.id) && treeNode.expanded) {
        loadGCPNodeData({
          childrenType: "all",
          loadType: "initial",
          parentId: treeNode.id,
        });
      }
    },
    [loadedNodes, dispatch, loadGCPNodeData],
  );

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const mode = event?.target?.value;
      setGCPProjectSelectMode(mode, dispatch);
      if (mode === "ALL" || mode === "INCLUDE") {
        dispatch(setGCPAccountGroupCreationMode("AUTO"));
      } else {
        dispatch(setGCPAccountGroupCreationMode("RECURSIVE"));
      }
    },
    [dispatch],
  );

  return (
    <>
      {!renderLoader && (
        <ModelRadioGroup mode={mode} onChangeMode={onChangeMode} />
      )}

      {renderLoader && !credentialError && (
        <div className="flex justify-center text-center">
          <LoadingIcon size="xl" />
        </div>
      )}

      <ErrorMessages errors={selectedNodesErrors} />

      {renderTree && !hasRateLimitHit && !credentialError && (
        <SelectMemberAccountTree
          defaultExpandedKeys={defaultExpandedKeys}
          selectedNodes={selectedNodes}
          error={!isEmpty(selectedNodesErrors)}
          loadedNodes={loadedNodes}
          onLoadMore={loadGCPNodeData}
          onChangeSelectedNodes={onChangeSelectedNodes}
          onExpandNode={onExpandNode}
          rootId={rootId}
          treeMap={treeMap}
          selectedCloud={GCP}
        />
      )}
    </>
  );
}
