import { memo, useEffect, useMemo, useState } from "react";
import { type TreeModelType, type TreeNodeType } from "../../types";
import {
  ExpandClose,
  ExpandOpen,
  File,
  Folder,
  FolderOpen,
  Spinner,
} from "../icons";
import NodeModel from "./NodeModel";
import TreeNode from "./TreeNode";

const defaultProps = {
  id: "tree-hierarchy",
  style: {},
  icons: {
    expandClose: ExpandClose,
    expandOpen: ExpandOpen,
    folder: Folder,
    folderOpen: FolderOpen,
    file: File,
    spinner: Spinner,
  },
  showCheckbox: true,
  expandOnLabelClick: true,
  showNodeIcon: false,
  noCascade: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCheck: () => {},
  onClick: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onExpand: () => {},
  className: "",
};

function TreeHierarchy(props: TreeModelType) {
  // Initialize state
  const [state, setState] = useState(() => {
    const model = new NodeModel(props);

    model.flattenNodes(props.nodes);
    model.deserializeLists({
      checked: props.checked,
      expanded: props.expanded,
      loaded: props.loaded,
    });

    return {
      id: props.id,
      model,
    };
  });

  // Update state if any of the props (checked, expanded, loaded) are updated
  useEffect(() => {
    const model = new NodeModel(props);

    model.flattenNodes(props.nodes);
    model.deserializeLists({
      checked: props.checked,
      expanded: props.expanded,
      loaded: props.loaded,
    });

    setState((prevState) => ({
      ...prevState,
      model,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checked, props.expanded, props.loaded, props.nodes]);

  // Merge and memorize the icons
  const Icons = useMemo(() => {
    const { icons: defaultIcons } = TreeHierarchy.defaultProps;
    return {
      ...defaultIcons,
      ...props.icons,
    };
  }, [props.icons]);

  // Handle checkbox onClick
  const _onCheck = (nodeInfo: { checked: boolean; value: string }) => {
    const { noCascade, onCheck } = props;
    const model = state.model.clone();
    const node = model.getNode(nodeInfo.value);

    model.toggleChecked(node, nodeInfo.checked, noCascade);
    onCheck(model.serializeList("checked"), { ...node, ...nodeInfo });
    setState((prevState) => ({
      ...prevState,
      model,
    }));
  };

  // Handle collapse/expand icon onClick
  const _onExpand = (nodeInfo: { expanded: boolean; value: string }) => {
    const { onExpand } = props;
    const model = state.model.clone();
    const node = state.model.getNode(nodeInfo.value);
    model.toggleNode(nodeInfo.value, "expanded", nodeInfo.expanded);
    onExpand(model.serializeList("expanded"), { ...node, ...nodeInfo });
    setState((prevState) => ({
      ...prevState,
      model,
    }));
  };

  // Handle label onClick
  const _onNodeClick = (nodeInfo: { checked: boolean; value: string }) => {
    const { onClick } = props;
    const node = state.model.getNode(nodeInfo.value);

    onClick && onClick({ ...node, ...nodeInfo });
  };

  // Determine the current node checked state based on child nodes checked state
  // 0 if all child nodes are not checked
  // 1 if all child nodes are checked
  // 2 if some of child nodes are checked and some are not checked
  const _determineShallowCheckState = (
    treeNode: TreeNodeType,
    noCascade: boolean,
  ) => {
    const flatNode = state.model.getNode(treeNode?.id);

    if (flatNode.isLeaf || flatNode.children?.length === 0 || noCascade) {
      return flatNode.checked ? 1 : 0;
    }

    if (_isEveryChildChecked(treeNode) && flatNode.checked) {
      return 1;
    }

    if (_isSomeChildChecked(treeNode)) {
      return 2;
    }

    return 0;
  };

  // Check all child's of node are checked
  const _isEveryChildChecked = (treeNode: TreeNodeType) => {
    return Boolean(
      treeNode.children
        ?.filter((child) => child.checkable !== false)
        .every((child) => state.model.getNode(child?.id)?.checkState === 1),
    );
  };

  // Check some child's of node are checked
  const _isSomeChildChecked = (treeNode: TreeNodeType) => {
    return Boolean(
      treeNode.children
        ?.filter((child) => !child.checkable !== false)
        .some((child) => {
          const node = state.model.getNode(child?.id);
          return node && node.checkState > 0;
        }),
    );
  };

  // Render checkbox with icon and labels
  const _renderTreeNodes = (
    nodes: TreeNodeType[],
    parent: TreeNodeType = {
      isLeaf: false,
      key: "",
      title: "",
      id: "",
    },
  ) => {
    const { expandOnLabelClick, noCascade, onClick, showNodeIcon, loaded } =
      props;
    const { id, model } = state;

    const treeNodes = nodes.map((node) => {
      const nodeId = node.id;
      const nodeTitle = node.title;

      const key = node.checkable === false ? node.key : nodeId;
      const flatNode = model.getNode(nodeId);

      if (!flatNode) return undefined;

      // show loading spinner in place of expand icon when user clicks on expand icon of parent
      const loading = Boolean(
        flatNode.expanded &&
          node.id &&
          node.children &&
          !loaded?.includes(node.id) &&
          node?.children &&
          node.children?.length === 0,
      );

      const children =
        node?.children && node.children?.length > 0
          ? _renderTreeNodes(node.children, node)
          : null;

      // Determine the check state after all children check states have been determined
      // This is done during rendering as to avoid an additional loop during the
      // deserialization of the `checked` property
      flatNode.checkState = _determineShallowCheckState(node, noCascade);

      // Show checkbox only if showCheckbox is true
      const showCheckbox = flatNode.showCheckbox;

      // Render only if parent is expanded or if there is no root parent
      const parentExpanded = parent?.id
        ? model.getNode(parent?.id)?.expanded
        : true;

      if (!parentExpanded) {
        return null;
      }

      return (
        <TreeNode
          key={key}
          treeId={id}
          icons={Icons}
          disabled={flatNode.disabled}
          checked={flatNode.checkState}
          expanded={flatNode.expanded}
          loading={loading}
          showCheckbox={showCheckbox}
          showNodeIcon={showNodeIcon}
          icon={node.icon}
          isLeaf={flatNode.isLeaf}
          isParent={flatNode.isParent}
          value={nodeId}
          label={nodeTitle}
          onCheck={_onCheck}
          onClick={onClick && _onNodeClick}
          onExpand={_onExpand}
          expandOnLabelClick={expandOnLabelClick}
          checkable={node.checkable}
        >
          {children}
        </TreeNode>
      );
    });

    return <ol>{treeNodes}</ol>;
  };

  const { style, nodes, className = "" } = props;
  const { id } = state;

  const view = _renderTreeNodes(nodes);

  return (
    <div
      id={id}
      data-selector="tree-hierarchy"
      className={`flex flex-col text-sm ${className}`}
      style={style}
    >
      {view}
    </div>
  );
}

TreeHierarchy.defaultProps = defaultProps;

export default memo(TreeHierarchy);
