import { blueScale, grayScale } from "colors";
import { ArrowDownIcon, ArrowUpIcon, SortIcon } from "icons";
import { cloneElement, type ReactElement } from "react";
import { Tooltip } from "ui";

import { useIntl } from "react-intl";
import { type ColumnInstance, type Data } from "../../types";

export interface SortButtonProps<D extends Data> {
  column: ColumnInstance<D>;
}

export function SortButton<D extends Data>({
  column,
}: SortButtonProps<D>): ReactElement | null {
  const intl = useIntl();
  if (!column.canSort) {
    return null;
  }
  const { getSortByToggleProps, isSorted, isSortedDesc } = column;

  const active = !!isSorted;
  const color = active ? blueScale.DEFAULT : grayScale.DEFAULT;
  const icon = isSorted ? (
    isSortedDesc ? (
      <ArrowDownIcon />
    ) : (
      <ArrowUpIcon />
    )
  ) : (
    <SortIcon />
  );

  return (
    <span className="flex-none">
      <Tooltip label="Toggle the sort direction of the column">
        <button
          aria-label={
            isSorted
              ? isSortedDesc
                ? intl.formatMessage({
                    defaultMessage: "Sort column remove",
                    id: "72VE8x",
                    description: "table sort button",
                  })
                : intl.formatMessage({
                    defaultMessage: "Sort column descending",
                    id: "Kn7fkv",
                    description: "table sort button",
                  })
              : intl.formatMessage({
                  defaultMessage: "Sort column ascending",
                  id: "kvCzwP",
                  description: "table sort button",
                })
          }
          className="rounded bg-transparent focus:outline-none focus:ring"
          {...getSortByToggleProps()}
          title={undefined}
          type="button"
        >
          {cloneElement(icon, { color: color, size: "xs" })}
        </button>
      </Tooltip>
    </span>
  );
}
