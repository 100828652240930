import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const GcloudIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Gcloud",
    id: "QFU1iK",
    description: "Gcloud alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <path
        d="m15.274 7.683.769.013 2.089-2.089.101-.885a9.349 9.349 0 0 0-6.234-2.374c-4.328 0-7.98 2.944-9.062 6.933.229-.16.716-.04.716-.04l4.174-.686s.214-.356.323-.338a5.205 5.205 0 0 1 3.851-1.701 5.215 5.215 0 0 1 3.273 1.163v.004Z"
        fill="#EA4335"
      />
      <path
        d="M21.064 9.289a9.403 9.403 0 0 0-2.834-4.567L15.273 7.68a5.205 5.205 0 0 1 1.945 4.058v.521a2.611 2.611 0 0 1 2.608 2.608 2.611 2.611 0 0 1-2.608 2.608h-5.216L11.48 18v3.13l.522.52h5.216A6.791 6.791 0 0 0 24 14.867a6.786 6.786 0 0 0-2.936-5.579Z"
        fill="#4285F4"
      />
      <path
        d="M6.781 21.652h5.213v-4.177H6.78c-.37 0-.736-.08-1.073-.233l-.753.23-2.088 2.09-.183.704A6.75 6.75 0 0 0 6.78 21.65v.003Z"
        fill="#34A853"
      />
      <path
        d="M6.782 8.089A6.791 6.791 0 0 0 0 14.87a6.772 6.772 0 0 0 2.687 5.401l3.024-3.024a2.61 2.61 0 0 1-1.537-2.377 2.611 2.611 0 0 1 2.608-2.607A2.62 2.62 0 0 1 9.159 13.8l3.024-3.024a6.771 6.771 0 0 0-5.4-2.687h-.001Z"
        fill="#FBBC05"
      />
    </SvgIcon>
  );
};
