import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";

type AgentlessWorkloadScanningProps = {
  data?: SecurityCapabilitiesPermissionsStatus;
  isCollapsibleDetails?: boolean;
};

export default function AgentlessWorkloadScanning({
  data,
  isCollapsibleDetails = true,
}: AgentlessWorkloadScanningProps) {
  if (isNil(data)) return null;
  return (
    <>
      <CollapsibleDetails
        icon={<SecurityCapabilitiesIcon type="Agentless Scanning" />}
        label="agentless_workload_scanning"
        isCollapsibleDetails={isCollapsibleDetails}
        {...data}
      />

      {data?.orgSecurityCapabilities?.map(({ name, status, statusIcon }) => (
        <CollapsibleDetails
          detailsClassName="mt-4"
          key={name}
          label={name}
          name={name}
          normalFont={true}
          status={status}
          isCollapsibleDetails={false}
          isNoStatusIcon={statusIcon}
        />
      ))}
    </>
  );
}
