import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { type UCDMode } from "../preferences";
import {
  GetSavedViewsResponseSchema,
  creatableCustomViewFields,
  editableCustomViewFields,
  orderRequestSchema,
  type Collections,
  type CreatableSavedView,
  type EditableSavedView,
  type SavedViewBaseSchema,
  type SavedViewBaseWithSettings,
} from "./schemas";

type IDField = z.infer<typeof SavedViewBaseSchema>["id"];

export const savedViewKeys = {
  all: [{ scope: "saved-views" }] as const,
  allViews: ({ collection }: { collection: Collections }) =>
    [{ ...savedViewKeys.all[0], entity: "views", collection }] as const,
  views: ({
    collection,
    persona,
  }: {
    collection: Collections;
    persona: UCDMode;
  }) =>
    [
      { ...savedViewKeys.all[0], entity: "views", collection, persona },
    ] as const,
  view: ({ id, collection }: { id: IDField; collection: Collections }) =>
    [{ ...savedViewKeys.all[0], entity: "view", collection, id }] as const,
  original: ({ id, collection }: { id: IDField; collection: Collections }) =>
    [
      { ...savedViewKeys.all[0], entity: "original-view", collection, id },
    ] as const,
};

export const getSavedViews = async ({
  queryKey: [{ collection, persona }],
}: QueryFunctionContext<ReturnType<(typeof savedViewKeys)["views"]>>) => {
  return jsonApi({
    path: `api/v1/saved-views/${collection}`,
    params: { persona },
    requestSchema: z.void(),
    responseSchema: GetSavedViewsResponseSchema,
  });
};

export const getSavedView = <T extends z.ZodTypeAny>(schema: T) => {
  return async ({
    queryKey: [{ collection, id }],
  }:
    | QueryFunctionContext<ReturnType<(typeof savedViewKeys)["view"]>>
    | QueryFunctionContext<ReturnType<(typeof savedViewKeys)["original"]>>) => {
    return jsonApi({
      path: `api/v1/saved-views/${collection}/${id}`,
      requestSchema: z.void(),
      responseSchema: schema,
    });
  };
};

export const createSavedView = async <
  SavedView extends SavedViewBaseWithSettings,
  Schema extends z.ZodTypeAny,
>({
  schema,
  collection,
  view,
}: {
  schema: Schema;
  collection: Collections;
  view: CreatableSavedView<SavedView>;
}) => {
  return jsonApi<typeof view, SavedView>({
    path: `api/v1/saved-views/${collection}`,
    body: view,
    config: { method: "POST" },
    // @ts-expect-error Zod doesn't have a z.ZodTypeObjectAny or equivalent
    requestSchema: schema.pick(creatableCustomViewFields),
    responseSchema: schema,
  });
};

export const updateSavedView = async <
  SavedView extends SavedViewBaseWithSettings,
  Schema extends z.AnyZodObject,
>({
  schema,
  collection,
  id,
  view,
}: {
  schema: Schema;
  collection: Collections;
  id: IDField;
  view: EditableSavedView<SavedView>;
}) => {
  const { settings, ...rest } = view;

  return jsonApi<typeof view, SavedView>({
    path: `api/v1/saved-views/${collection}/${id}`,
    body: { ...settings, ...rest },
    config: { method: "PATCH" },
    // @ts-expect-error z.AnyZodObject has issues with the jsonAPI implementation
    requestSchema: schema
      .pick(editableCustomViewFields)
      .partial()
      .merge(schema.shape.settings),
    // @ts-expect-error z.AnyZodObject has issues with the jsonAPI implementation
    responseSchema: schema,
  });
};

export const deleteSavedView = async ({
  collection,
  id,
}: {
  collection: Collections;
  id: IDField;
}) => {
  return jsonApi({
    path: `api/v1/saved-views/${collection}/${id}`,
    config: { method: "DELETE" },
    requestSchema: z.void(),
    responseSchema: z.void(),
  });
};

export const reorderSavedViews = async ({
  collection,
  order,
  persona,
}: {
  collection: Collections;
  order: z.infer<typeof orderRequestSchema>["order"];
  persona: z.infer<typeof orderRequestSchema>["persona"];
}) => {
  return jsonApi({
    path: `api/v1/saved-views/${collection}/order`,
    body: { order, persona },
    config: { method: "PATCH" },
    requestSchema: orderRequestSchema,
    responseSchema: GetSavedViewsResponseSchema.shape.order,
  });
};
