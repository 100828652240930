import { useToken, type Token } from "requests";

/**
 * @deprecated Should be using the userRoleTypeName schema from TokenSchema instead.
 */
export const roleNames = {
  "System Admin": "System Admin",
  "Account Group Admin": "Account Group Admin",
  "Account Group Read Only": "Account Group Read Only",
  "Account and Cloud Provisioning Admin":
    "Account and Cloud Provisioning Admin",
  "Cloud Provisioning Admin": "Cloud Provisioning Admin",
  "Build and Deploy Security": "Build and Deploy Security",
  NetSecOps: "NetSecOps",
  Developer: "Developer",
};
/**
 * These are additional roles that are determined based on the role & permissions
 */
export const syntheticRoleNames = {
  "Compute Admin": "Compute Admin",
  "Enhanced Compute": "Enhanced Compute",
  "Build and Deploy Security CI": "Build and Deploy Security CI",
};

function isToken(token: Token | Record<string, never>): token is Token {
  return !!Object.keys(token).length;
}

export type UseRoleReturn = {
  role: Token["userRoleTypeName"];
  syntheticRole: Token["userRoleTypeName"] | keyof typeof syntheticRoleNames;
};

/**
 * Uses the token to determine the current role. Will return extra roles based on permissions.
 */
export function useRole(): UseRoleReturn | Record<string, never> {
  const token = useToken();

  if (!isToken(token)) return {};
  const syntheticRole = roleFromToken(token);

  return {
    role: token.userRoleTypeName,
    syntheticRole,
  };
}

export function roleFromToken(token: Token): UseRoleReturn["syntheticRole"] {
  const roleName = token.userRoleTypeName;

  if (
    roleName === roleNames["System Admin"] &&
    token.userRoleTypeDetails?.hasOnlyComputeAccess
  ) {
    return "Compute Admin";
  } else if (
    [
      roleNames["Account and Cloud Provisioning Admin"],
      roleNames["Account Group Admin"],
      roleNames["Account Group Read Only"],
    ].includes(roleName) &&
    token.userRoleTypeDetails?.hasOnlyComputeAccess
  ) {
    // On the BE this is called Compute Account Admin,
    // the name was too close to Compute Admin so Enhanced Compute was chosen.
    return "Enhanced Compute";
  } else if (
    roleName === roleNames["Build and Deploy Security"] &&
    token.userRoleTypeDetails?.hasOnlyCIAccess
  ) {
    return "Build and Deploy Security CI";
  }

  return token.userRoleTypeName;
}
