import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { integrationAndNotificationRoute } from "../integrationAndNotificationRoute";

export const Integrations = lazy(() => import("./Integrations"));

export const integrationsRoute = new Route({
  getParentRoute: () => integrationAndNotificationRoute,
  path: "integrations",
  component: Integrations,
});
