import { defineMessages, type MessageDescriptor } from "react-intl";
import { type PostLoginResponseSchema } from "requests";
import type z from "zod";

export type SuccessMessagesMapKeyType = Extract<
  z.infer<typeof PostLoginResponseSchema>["message"],
  "login_successful" | "login_needs_customer_name" | "tos_not_accepted"
>;

export type ErrorMessagesMapKeyType = Exclude<
  z.infer<typeof PostLoginResponseSchema>["message"],
  SuccessMessagesMapKeyType
>;
export const errorMessagesMap: Record<
  ErrorMessagesMapKeyType,
  MessageDescriptor
> = defineMessages({
  access_key_login_not_permitted: {
    defaultMessage: "Sign in using an access key is not permitted.",
    id: "ookj4l",
    description:
      "Login error message letting the user know they cannot login using an access key",
  },
  invalid_src_credentials: {
    defaultMessage: "Current security settings do not permit you to sign in.",
    id: "wtOPos",
    description: "Login error because security settings do not allow it",
  },
  tenant_expired: {
    defaultMessage: "Tenant Expired",
    id: "Eq18cr",
    description: "Login error because user tenant account is expired",
  },
  user_locked: {
    defaultMessage:
      "Your account has been locked out. Please contact the admin.",
    id: "/dR1i2",
    description: "Login error because users account is locked",
  },
  sso_enabled_password_login_attempt: {
    defaultMessage: "Please sign in using your SSO Identity Provider.",
    id: "18cfzk",
    description: "Login error message because user needs to use SSO",
  },
  invalid_credentials: {
    defaultMessage: "Invalid credentials",
    id: "ERU6VI",
    description: "Invalid email or password provided",
  },
  login_failed_saml_error: {
    defaultMessage: "We can’t sign you in. Please review your SSO settings.",
    id: "8Z5ysG",
    description: "Login via SSO failed",
  },
  login_failed_src_error: {
    defaultMessage: "Current security settings do not permit you to sign in.",
    id: "qRgYSy",
    description: "Login failed due to setting",
  },
  login_failed_unknown_error: {
    defaultMessage:
      "Sign in failed due to an unknown error. Please contact your Prisma Cloud support team.",
    id: "GmdDLl",
    description: "An unknown error occurred during login",
  },
  no_permissions_available: {
    defaultMessage:
      "We're sorry, you do not have permissions to log in to this Prisma Cloud instance. If you believe that you should have access, please reach out to your Prisma Cloud Customer Success or Support representative for further assistance.",
    id: "QOEJrV",
    description: "Login error message",
  },
  Unauthorized: {
    defaultMessage: "Invalid credentials",
    id: "ERU6VI",
    description: "Invalid email or password provided",
  },
});
