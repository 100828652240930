import { type z } from "zod";
import { jsonApi } from "../../../apis";
import {
  AzureSelectMonitoredProjectsAncestorsRequestSchema,
  AzureSelectMonitoredProjectsAncestorsResponseSchema,
  AzureSelectMonitoredProjectsChildrenRequestSchema,
  AzureSelectMonitoredProjectsChildrenResponseSchema,
} from "./schemas";

export const getAzureSelectMonitoredProjectChildrens = ({
  id,
  body,
  params,
}: {
  id: string;
  body: z.infer<typeof AzureSelectMonitoredProjectsChildrenRequestSchema>;
  params?: Record<string, string>;
}) => {
  return jsonApi({
    path: `cloud-accounts-manager/v1/cloudAccounts/azureAccounts/${id}/children`,
    config: {
      method: "POST",
    },
    body,
    params,
    requestSchema: AzureSelectMonitoredProjectsChildrenRequestSchema,
    responseSchema: AzureSelectMonitoredProjectsChildrenResponseSchema,
  });
};

export const getAzureSelectMonitoredProjectAncestors = ({
  id,
  body,
}: {
  id: string;
  body: z.infer<typeof AzureSelectMonitoredProjectsAncestorsRequestSchema>;
}) => {
  return jsonApi({
    path: `cloud-accounts-manager/v1/cloudAccounts/azureAccounts/${id}/ancestors`,
    config: {
      method: "POST",
    },
    body,
    requestSchema: AzureSelectMonitoredProjectsAncestorsRequestSchema,
    responseSchema: AzureSelectMonitoredProjectsAncestorsResponseSchema,
  });
};
