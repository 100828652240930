import { Button, Modal, ModalBody, ModalFooter } from "ui";

import { useNavigate, useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { loginRoute } from "../loginRoute";

export default function TimeoutModal() {
  const search = useSearch({ from: loginRoute.id });
  const navigate = useNavigate();
  const { timeout: showTimeout = false } = search;
  const [modalOpen, setModalOpen] = useState<boolean>(showTimeout);
  const closeModal = () => {
    navigate({ replace: true });
    setModalOpen(false);
  };

  return (
    <Modal onClose={closeModal} isOpen={modalOpen} size="sm">
      <ModalBody>
        <FormattedMessage
          defaultMessage="Due to inactivity, your session has expired and is no longer active."
          id="4DuT+C"
          description="inactivity message for user"
        />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Close"
            id="PyDwDF"
            description="Close button label"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
