import { createRoute } from "@tanstack/react-router";
import { useUCDModeStore } from "prisma";
import { lazy } from "react";
import { DashboardGlobalFilterSchema, type UCDMode } from "requests";
import { dashboardsSavedViews } from "saved-views-next";
import { z } from "zod";
import { privateRootRoute } from "../../privateRootRoute";

const DashboardsLayout = lazy(() =>
  import("./DashboardsLayout").then((res) => ({
    default: res.DashboardsLayout,
  })),
);

const DashboardsSearchSchema = z.object({
  filters: DashboardGlobalFilterSchema.partial().catch({}).optional(),
  test: z.string().optional(),
});

export const dashboardsLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "dashboards",
  component: DashboardsLayout,
  validateSearch: DashboardsSearchSchema,
  loader: ({ context }) => {
    const persona = useUCDModeStore.getState().mode as UCDMode;
    if (persona) {
      context?.queryClient.prefetchQuery(
        dashboardsSavedViews.getViewsQueryOptions(),
      );
    }
  },
});
