/* eslint-disable react/jsx-no-literals */
import { CopyButton, TruncateText } from "ui";

type ListProps = {
  index: number;
  label: string;
  value: string;
};

export default function List({ index, label, value }: ListProps) {
  return (
    <li className="flex items-baseline space-y-0.5 text-xs">
      <div className="w-[15%]">
        <span className="ml-1">{index + 1}.</span>
        <span className="mx-1">{label}:</span>
      </div>
      <span className="flex w-[75%] items-center break-all">
        <CopyButton text={value} />
        <TruncateText>{value}</TruncateText>
      </span>
    </li>
  );
}
