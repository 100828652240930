import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import {
  CloudRegionResponseSchema,
  CloudRegionWithOtherResponseSchema,
} from "./schemas";

export const cloudRegionKeys = {
  all: [{ scope: "cloudRegionAll" }] as const,
  cloudRegions: (params?: { includeOthers?: "true" | "false" }) =>
    [
      {
        ...cloudRegionKeys.all[0],
        entity: "cloudRegion",
        params,
      },
    ] as const,
} as const;

export const getCloudRegions = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudRegionKeys)["cloudRegions"]>
>) => {
  return jsonApi({
    path: "v2/cloud/region",
    params,
    requestSchema: z.void(),
    responseSchema: params?.includeOthers
      ? CloudRegionWithOtherResponseSchema
      : CloudRegionResponseSchema,
  });
};
