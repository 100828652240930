import { CloudTypeIcon, type IconSizes } from "icons";

import { type cloudTypesWithOther } from "requests";

type ActiveStepTitlePropTypes = {
  cloudType: Exclude<(typeof cloudTypesWithOther)[number], "all">;
  label: string;
  iconSize: IconSizes;
};

export function ActiveStepTitle({
  cloudType,
  label,
  iconSize = "md",
}: ActiveStepTitlePropTypes) {
  return (
    <div className="flex items-center gap-2">
      <span>{label}</span>
      <CloudTypeIcon type={cloudType} size={iconSize} />
    </div>
  );
}
