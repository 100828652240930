/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useEffect,
  useRef,
  useState,
  type ComponentPropsWithoutRef,
  type PropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { Tooltip } from "../Tooltip";

export interface TruncateTextProps
  extends Omit<ComponentPropsWithoutRef<"span">, "className"> {
  addClassName?: string;
  children: ReactNode;
  /**
   * Pass props to the internal `span` element.
   */
  innerElementProps?: PropsWithoutRef<JSX.IntrinsicElements["span"]>;
  suppressTooltip?: boolean;
}

/**
 * Renders the child text, truncating it if it is longer than its container. If truncated, it will add a tooltip with the full text.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { TruncateText } from "ui";
 * ```
 */
export function TruncateText({
  addClassName,
  children,
  innerElementProps,
  suppressTooltip = false,
  ...passThrough
}: TruncateTextProps): ReactElement {
  const inner = useRef<HTMLSpanElement>(null!);
  const outer = useRef<HTMLSpanElement>(null!);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    /* c8 ignore next */
    if (!inner.current || !outer.current) {
      return;
    }

    /* c8 ignore next */
    if (inner.current.offsetWidth > outer.current.offsetWidth) {
      setTooltip(true);
    } else {
      setTooltip(false);
    }
  }, [setTooltip, inner.current?.offsetWidth, outer.current?.offsetWidth]);

  const content = (
    <span
      ref={outer}
      {...passThrough}
      className={classNames("truncate", addClassName)}
    >
      <span ref={inner} {...innerElementProps}>
        {children}
      </span>
    </span>
  );

  /* c8 ignore next */
  if (tooltip && !suppressTooltip) {
    return <Tooltip label={children}>{content}</Tooltip>;
  }
  return content;
}
