import { useContext } from "react";
import { type Monitoring } from "../Monitoring";
import { MonitoringContext } from "./MonitoringProvider";

export const useMonitoring = () => {
  const context = useContext<Monitoring | null>(MonitoringContext);

  if (!context) {
    throw new Error("useMonitoring must be used within a MonitoringProvider");
  }

  return context;
};
