import { useIntl } from "react-intl";

import { Checkbox } from "form";
import { type Features } from "requests";
import { Body, dynamicFormatMessage } from "ui";
import {
  featureNameMap,
  subtitleMap,
} from "../../../../PermissionGroupsSidecar/messages";
import { Row } from "./Row";

export function CheckboxRow({ feature }: { feature: Features }) {
  const intl = useIntl();

  return (
    <Row
      label={
        <>
          {dynamicFormatMessage(intl, featureNameMap, feature.featureName)}
          {feature.featureName in subtitleMap && (
            <Body addClassName="text-xs text-secondary">
              {
                // @ts-expect-error Already handling the typescript error by ensuring that
                // the feature name is present in the map but typescript is not acknowledging that.
                intl.formatMessage(subtitleMap[feature.featureName])
              }
            </Body>
          )}
        </>
      }
      read={
        "READ" in feature.operations && (
          <Checkbox
            addClassName="ml-2"
            showLabel={false}
            name={`features.${feature.featureName}.READ`}
            label={`${feature.featureName}.READ`}
          />
        )
      }
      create={
        "CREATE" in feature.operations && (
          <Checkbox
            addClassName="ml-2"
            showLabel={false}
            name={`features.${feature.featureName}.CREATE`}
            label={`${feature.featureName}.CREATE`}
          />
        )
      }
      update={
        "UPDATE" in feature.operations && (
          <Checkbox
            addClassName="ml-2"
            showLabel={false}
            name={`features.${feature.featureName}.UPDATE`}
            label={`${feature.featureName}.UPDATE`}
          />
        )
      }
      del={
        "DELETE" in feature.operations && (
          <Checkbox
            addClassName="ml-2"
            showLabel={false}
            name={`features.${feature.featureName}.DELETE`}
            label={`${feature.featureName}.DELETE`}
          />
        )
      }
    />
  );
}
