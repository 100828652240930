import { useNavigate, useSearch } from "@tanstack/react-router";
import { validateResetKey, validateResetToken } from "requests";

import { useQuery } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useToastActions } from "stores";
import { ExpiredToken } from "./ExpiredToken";
import { NewPassword } from "./NewPassword";
import { resetPasswordRoute } from "./resetPasswordRoute";

export default function ResetPassword() {
  const intl = useIntl();
  const { toast } = useToastActions();
  const navigate = useNavigate();
  const search = useSearch({ from: resetPasswordRoute.id });
  const { fpt } = search;
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: validateResetKey(fpt),
    queryFn: validateResetToken,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isSuccess) return;

    if (data?.status !== "INVALID") return;
    toast(
      intl.formatMessage({
        defaultMessage:
          'This link has expired. Please click "Forgot password?" in the sign in form below to generate a new link.',
        id: "hu8UEV",
        description: "Error toast",
      }),
      { autoDismiss: false, appearance: "error" },
    );
    navigate({ to: "/login" });
  }, [data?.status, intl, isSuccess, navigate, toast]);

  if (isLoading || !data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingIcon size="5xl" />
      </div>
    );
  }

  if (data.status === "INVALID") return null;

  if (
    data.status === "FORGOT_PASSWORD_TOKEN_EXPIRED" ||
    data.status === "USER_ACTIVATION_TOKEN_EXPIRED"
  ) {
    return (
      <ExpiredToken
        fpt={fpt}
        forgotPassword={data.status === "FORGOT_PASSWORD_TOKEN_EXPIRED"}
      />
    );
  }

  return <NewPassword token={data.token ?? ""} />;
}
