import { ParentSize } from "@visx/responsive";
import { useIntl } from "react-intl";
import { type StatusHealthComponent } from "requests";
import {
  ConnectedTable,
  TableProvider,
  TruncateCell,
  type ColumnOptions,
} from "table";
import { RelativeTimestamp } from "ui";

export default function Table({ data }: { data: StatusHealthComponent[] }) {
  const intl = useIntl();

  const columns = (totalWidth: number) =>
    [
      {
        accessor: "errorType",
        Header: intl.formatMessage({
          defaultMessage: "Error Type",
          id: "9QSug1",
          description: "Column header for Error Type column",
        }),
        width: totalWidth * 0.25,
      },
      {
        accessor: "message",
        Cell: ({ value }: { value: string }) => <TruncateCell value={value} />,
        Header: intl.formatMessage({
          defaultMessage: "Message",
          id: "fqTIdE",
          description: "Column header for Message column",
        }),
        width: totalWidth * 0.25,
      },
      {
        accessor: "lastUpdated",
        Cell: RelativeTimestamp,
        Header: intl.formatMessage({
          defaultMessage: "Last Updated",
          id: "rbB4QP",
          description: "Column header for Last Updated column",
        }),
        width: totalWidth * 0.25,
      },
    ] as ColumnOptions<StatusHealthComponent>[];

  return (
    <ParentSize>
      {({ width }) => (
        <TableProvider columns={columns(width)} data={data}>
          <ConnectedTable selector="integration-status-health-table" />
        </TableProvider>
      )}
    </ParentSize>
  );
}
