import { FilterContext } from "filters";
import { useContext, useEffect } from "react";
import { useTableInstance } from "table";
import { usePrevious } from "ui";

/**
 *
 * This will reset the page to 0 whenever a filter bar filter changes.
 *
 * This component must be a child of FilterProvider.
 */
export const ResetPaginatorOnFilterBarChangeEffect = () => {
  // Not using useFilters because we don't what to throw an error if there is no FilterProvider.
  const { state, name } = useContext(FilterContext);
  /* c8 ignore next */
  const filterState = state[name ?? ""];
  const initialized = filterState?.initialized;
  const previouslyInitialized = usePrevious(initialized);
  const filters = filterState?.filters;
  const stringifiedFilters = JSON.stringify(filters);
  const previousFilters = usePrevious(stringifiedFilters);
  const {
    gotoPage,
    state: { pageIndex },
  } = useTableInstance();

  useEffect(() => {
    if (!initialized) return;
    if (!previouslyInitialized) return;
    // If shouldn't even be coming in here if stringifiedFilters hasn't changed...?
    if (previousFilters === stringifiedFilters) return;
    if (pageIndex === 0) return;
    gotoPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFilters]); // only run when filters change

  return null;
};
