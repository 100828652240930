import { z } from "zod";

// Zod schemas

const anomalyTypes = [
  "brute_force_anomaly_title",
  "crypto_jacking_feature_ip",
  "crypto_jacking_feature_location",
  "crypto_jacking_feature_service",
  "device_fingerprint",
  "geo_feature",
  "geo_service_feature",
  "impossible_time_travel_title",
  "none",
  "service_feature",
] as const;

const AnomalyTypeSchema = z.enum(anomalyTypes);

export const RqlEventResultItemSchema = z.object({
  accessKey: z.string().optional(),
  accessKeyUsed: z.boolean().optional(),
  account: z.string().optional(),
  accountName: z.string().optional(),
  anomalyId: z.string().optional(),
  browser: z.string().optional(),
  cityId: z.number().int().optional(),
  cityLatitude: z.number().optional(),
  cityLongitude: z.number().optional(),
  cityName: z.string().optional(),
  countryId: z.number().int().optional(),
  countryName: z.string().optional(),
  dynamicData: z.record(z.string(), z.any()).optional(),
  metadata: z.record(z.any()).optional(),
  eventTs: z.number().int(),
  flaggedFeature: AnomalyTypeSchema.optional(),
  id: z.number().int().optional(),
  ingestionTs: z.number().int().optional(),
  internal: z.boolean().optional(),
  ip: z.string().optional(),
  location: z.string().optional(),
  name: z.string().optional(),
  notPersisted: z.boolean().optional(),
  os: z.string().optional(),
  rawEvent: z.record(z.any()).optional(),
  reasonIds: z.array(z.number().int()).optional(),
  regionApiIdentifier: z.string().optional(),
  regionId: z.number().int().optional(),
  regionName: z.string().optional(),
  role: z.string().optional(),
  source: z.string().optional(),
  stateId: z.number().int().optional(),
  stateName: z.string().optional(),
  subject: z.string().optional(),
  success: z.boolean().optional(),
  timezone: z.string().optional(),
  type: z
    .enum([
      "UNKNOWN",
      "CREATE",
      "READ",
      "UPDATE",
      "DELETE",
      "LOGIN",
      "TEST",
      "AUDITD",
    ])
    .optional(),
});

export const RqlEventResultsItemsSchema = z.array(RqlEventResultItemSchema);

// Inferred types

export type RqlEventResultItem = z.infer<typeof RqlEventResultItemSchema>;
export type RqlEventResultItems = z.infer<typeof RqlEventResultsItemsSchema>;
export type AnomalyType = z.infer<typeof AnomalyTypeSchema>;
