import { animated, useSpring } from "@react-spring/web";
import { ShieldCheckIcon } from "icons";
import { useCodeEnabled, useComputeEnabled } from "prisma";
import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { type ColorTheme, type UCDMode } from "requests";
import { classNames } from "utils";
import { UCDIcon } from "../../../components/Navigation/UCDSelect/UCDIcon";

const focusRoles: {
  headerText: JSX.Element;
  roleInfo: JSX.Element;
  value: UCDMode;
}[] = [
  {
    headerText: (
      <FormattedMessage
        defaultMessage="Application Security"
        id="VS5ubS"
        description="Card header text"
      />
    ),
    roleInfo: (
      <ul>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Secure Infrastructure as Code and Prevent Open Source Vulnerability"
            id="mWSxOm"
            description="Card description text"
          />
        </li>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Secrets Exposure Prevention"
            id="hA0Hm/"
            description="Card description text"
          />
        </li>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Hardened CI/CD Pipelines"
            id="H3RBJJ"
            description="Card description text"
          />
        </li>
      </ul>
    ),
    value: "application",
  },
  {
    headerText: (
      <FormattedMessage
        defaultMessage="Cloud Security"
        id="2iw/RY"
        description="Card header text"
      />
    ),
    roleInfo: (
      <ul>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Detect Misconfigurations and Network Threats"
            id="myRKjy"
            description="Card description text"
          />
        </li>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Manage Vulnerabilities and Discover Risks"
            id="oV0D2k"
            description="Card description text"
          />
        </li>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Manage Identity Risks and Your Data Security Posture"
            id="lKGRNY"
            description="Card description text"
          />
        </li>
      </ul>
    ),
    value: "cloud",
  },
  {
    headerText: (
      <FormattedMessage
        defaultMessage="Runtime Security"
        id="ivqmys"
        description="Card header text"
      />
    ),
    roleInfo: (
      <ul>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Secure Your Workloads"
            id="sDxB4B"
            description="Card description text"
          />
        </li>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Secure Your APIs"
            id="E/8FOu"
            description="Card description text"
          />
        </li>
        <li className="flex">
          <ShieldCheckIcon className="mr-1 mt-1" />
          <FormattedMessage
            defaultMessage="Prevent Web Application Attacks"
            id="5Xjo68"
            description="Card description text"
          />
        </li>
      </ul>
    ),
    value: "runtime",
  },
];

type SelectedRoleProp = {
  headerText: ReactNode;
  roleInfo: ReactNode;
  value: UCDMode;
  isSelected: boolean;
  theme?: ColorTheme;
};

function RoleCard({
  headerText,
  roleInfo,
  theme,
  value,
  isSelected,
}: SelectedRoleProp) {
  return (
    <div
      className={classNames(
        "mt-10 flex flex-col rounded-xl bg-blue-steel-940/50 shadow-xl dark:shadow-xl min-[1500px]:mt-8",
        isSelected
          ? "border border-white dark:border-white"
          : "border border-blue-steel-850 dark:bg-blue-steel-940/50",
      )}
    >
      <div className="flex items-center gap-2 pl-4 pt-2">
        <UCDIcon mode={value} theme={theme} isDark />

        <p className="m-0 text-base font-semibold text-white dark:text-white">
          {headerText}
        </p>
      </div>
      <div className="w-full flex-1 p-4 text-default dark:text-dark-bg">
        <p className="text-white dark:text-white">{roleInfo}</p>
      </div>
    </div>
  );
}

export default function RoleOptions({
  setRole,
  selectedRole,
}: {
  setRole: React.Dispatch<
    React.SetStateAction<{
      value: UCDMode;
    }>
  >;
  selectedRole: UCDMode;
}) {
  const { codeEnabled } = useCodeEnabled();
  const computeEnabled = useComputeEnabled();

  const [cardProps] = useSpring(() => ({
    delay: 100,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      mass: 100,
      tension: 90,
      friction: 100,
      clamp: true,
    },
  }));

  return (
    <div>
      <animated.div style={cardProps} className="relative">
        <p className="mt-2 text-4xl font-bold leading-none text-dark-bg">
          <FormattedMessage
            defaultMessage="Where do you want to begin?"
            id="nNApYs"
            description="Card Title"
          />
        </p>
        <p className="mt-4 text-sm text-white dark:text-white">
          <FormattedMessage
            defaultMessage="Let us curate your code to cloud journey to best suit your workflows."
            id="v7ke5t"
            description="Card Title"
          />
        </p>
        <div className="flex flex-col">
          {focusRoles.map(({ headerText, roleInfo, value }) => {
            if (value === "application" && !codeEnabled) return null;
            if (value === "runtime" && !computeEnabled) return null;

            return (
              <div
                key={value}
                className="cursor-pointer"
                onClick={() => {
                  setRole({ value });
                }}
              >
                <RoleCard
                  headerText={headerText}
                  roleInfo={roleInfo}
                  isSelected={value === selectedRole}
                  value={value}
                />
              </div>
            );
          })}
        </div>
      </animated.div>
    </div>
  );
}
