import { captureMessage } from "@sentry/react";
import { isDevelopment, isTestAutomation } from "environment";
import { useToastStore } from "stores";
import { type z } from "zod";
import { ApiErrorClass } from "../apiErrorType";
import { baseApi } from "./baseApi";

type ApiJsonResponse<TResponse> = Omit<Response, "headers" | "body"> & {
  headers: Record<string, string>;
  body: TResponse;
};

async function parseResponseBody(
  responseHeaders: Record<string, string>,
  response: Response,
) {
  const contentType = responseHeaders["content-type"];
  const contentLength = responseHeaders["content-length"];
  const isEmptyBody = contentLength && !Number(contentLength);

  if (isEmptyBody) return;

  return /application\/json/.test(contentType ?? "")
    ? await response.json()
    : await response.text();
}

/**
 * baseJsonApi should only be used inside this package. Do not use it directly in an application or other packages.
 */
export async function baseJsonApi<Request, Response>({
  path,
  body,
  params,
  requestSchema,
  responseSchema,
  config: customConfig,
  baseUrl,
  useBaseHeaders,
}: {
  requestSchema: z.ZodType<Request>;
  responseSchema: z.ZodType<Response>;
  path: string;
  params?: Parameters<typeof baseApi>[0]["params"];
  body?: z.infer<typeof requestSchema>;
  config?: RequestInit;
  baseUrl?: string;
  useBaseHeaders?: boolean;
}): Promise<ApiJsonResponse<Response>> {
  const request = requestSchema.safeParse(body);

  if (!request.success) {
    if (isDevelopment()) {
      /* eslint-disable no-console */
      console.error(`Invalid request for ${path}: ${request.error}`);

      if (!isTestAutomation()) {
        useToastStore
          .getState()
          .actions.toast(
            `Invalid request for ${path}. Details are in the console, please fix it if you can.`,
            {
              autoDismiss: false,
              appearance: "warning",
            },
          );
      }
    } else {
      captureMessage(`Invalid Request for ${path}`, (scope) => {
        scope.addAttachment({
          filename: "zodError.txt",
          data: request.error.toString(),
        });
        return scope;
      });
    }
  }

  const config = {
    ...customConfig,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...customConfig?.headers,
    },
  };
  if (body) {
    config.body = typeof body === "string" ? body : JSON.stringify(body);
  }

  const response = await baseApi({
    path,
    params,
    config,
    baseUrl,
    useBaseHeaders,
  });

  const responseHeaders: Record<string, string> = {};
  response.headers.forEach((val, key) => (responseHeaders[key] = val));

  const responseBody = await parseResponseBody(responseHeaders, response);

  if (!response.ok) {
    if (responseHeaders["x-redlock-status"]) {
      responseHeaders["x-redlock-status"] = JSON.parse(
        responseHeaders["x-redlock-status"],
      );
    }

    return Promise.reject(
      new ApiErrorClass({
        bodyUsed: response.bodyUsed,
        headers: responseHeaders,
        ok: response.ok,
        redirected: response.redirected,
        status: response.status,
        statusText: response.statusText,
        type: response.type,
        url: response.url,
        body: responseBody,
      }),
    );
  }

  const apiJsonResponse: ApiJsonResponse<Response> = {
    ...response,
    // Zod removes the properties that are not defined in the schema when parsing,
    // which means `responseSchema.data` and `responseBody` might not be equal.
    // Sometimes, crash can only be triggered by the unparsed `responseBody`.
    // To avoid production-only crashes, we should return `responseBody` here too.
    body: responseBody,
    headers: responseHeaders,
  };

  // Parsing can freeze the UI for seconds for huge payloads, even if it's async.
  // Disable parsing for production.
  if (!isDevelopment()) {
    return apiJsonResponse;
  }

  // Below for development
  const parsedResponseBody = responseSchema.safeParse(responseBody);

  if (!parsedResponseBody.success) {
    console.error(`Invalid response for ${path}: ${parsedResponseBody.error}`);

    if (!isTestAutomation()) {
      useToastStore
        .getState()
        .actions.toast(
          `Invalid response for ${path}. Details are in the console, please fix it if you can.`,
          {
            autoDismiss: false,
            appearance: "warning",
          },
        );
    }
  }

  return apiJsonResponse;
}
