import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const FolderOpenIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 16 15"}>
      <path d="M3 4.00001V6.25001H1.5C1.22386 6.25001 1 6.02615 1 5.75001V0.5C1 0.22386 1.22386 0 1.5 0H5.63831C5.85231 0 6.04231 0.13695 6.10999 0.33997L6.54999 1.65992C6.61769 1.86302 6.80776 2.00001 7.02184 2.00001H14.5C14.7761 2.00001 15 2.22387 15 2.50001V5.75001C15 6.02615 14.7761 6.25001 14.5 6.25001H13V4.00001H3Z" />
      <path d="M0.940578 14.5683L0.0785075 8.31832C0.0370676 8.01786 0.270518 7.75 0.573818 7.75H15.4264C15.7297 7.75 15.9632 8.01786 15.9217 8.31832L15.0597 14.5683C15.0255 14.8157 14.8141 15 14.5643 15H1.43589C1.18615 15 0.974698 14.8157 0.940578 14.5683Z" />
    </SvgIcon>
  );
};
