import { z } from "zod";
import { jsonApi } from "../../apis";
import { AwsFeatureResponseSchema } from "./schemas";

export const awsFeatureKeys = {
  all: [{ scope: "awsFeature" }] as const,
  awsFeature: () =>
    [{ ...awsFeatureKeys.all[0], entity: "awsFeature" }] as const,
} as const;

export const getAWSFeature = async () => {
  return jsonApi({
    path: "feature/status/aws",
    requestSchema: z.void(),
    responseSchema: AwsFeatureResponseSchema,
  });
};
