import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useToastActions } from "stores";
import { ActionCellButton as ActionButton, type RowInstance } from "table";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  type RenderModalProps,
} from "ui";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeleteIcon } from "icons";
import { usePermissions } from "prisma";
import {
  accessKeys,
  deleteAccessKey,
  useToken,
  type AllAccessKeysResponseObjectType,
  type AllAccessKeysResponseType,
} from "requests";

export default function Delete({
  row,
}: {
  row: RowInstance<AllAccessKeysResponseObjectType>;
}) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const token = useToken();
  const {
    original: { id, username },
  } = row;

  const { closeModal, openModal } = useDeleteConfirmModal();
  const permissions = usePermissions();
  const deletePermissionAllowed = permissions?.settingsAccessKeys?.["DELETE"];
  const currentUsersRole = username === token.username;
  const queryClient = useQueryClient();

  const cacheKey = accessKeys.allAccessKeys();

  const { mutate: deleteKey } = useMutation({
    mutationFn: (id: string) => deleteAccessKey(id),
    onError: (_err, _newData, rollback: (() => void) | undefined) =>
      rollback?.(),
    onMutate: (deleteId) => {
      queryClient.cancelQueries({ queryKey: cacheKey });
      queryClient.invalidateQueries({ queryKey: cacheKey });
      const previous =
        queryClient.getQueryData<AllAccessKeysResponseType>(cacheKey);
      queryClient.setQueryData(cacheKey, (keys?: AllAccessKeysResponseType) => {
        const cloned = JSON.parse(JSON.stringify(keys));
        const findRule = ({
          policyScanConfigId: id,
        }: {
          policyScanConfigId: string;
        }) => id === deleteId;
        const changedIndex = cloned.findIndex(findRule);
        cloned.splice(changedIndex, 1);
        return cloned;
      });
      return () =>
        queryClient.setQueryData<AllAccessKeysResponseType>(
          cacheKey,
          () => previous,
        );
    },
    onSuccess: () => {
      toast(
        intl.formatMessage({
          defaultMessage: "Access key deleted successfully.",
          id: "zgyBPz",
          description: "Toast message",
        }),
      );
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });

  const onDelete = useCallback(() => {
    deleteKey(id);

    closeModal();
  }, [closeModal, deleteKey, id]);

  if (!currentUsersRole && !deletePermissionAllowed) return null;

  return (
    <ActionButton
      label={intl.formatMessage({
        defaultMessage: "Delete",
        id: "WodcPq",
        description: "Delete button label",
      })}
      icon={<DeleteIcon />}
      onClick={() =>
        openModal({
          id,
          onDelete,
        })
      }
    />
  );
}

interface ModalProps {
  id: string;
  onDelete: (id: string) => void;
}

type DeleteConfirmModalProps = RenderModalProps<ModalProps>;

function DeleteConfirmModal({
  id,
  onDelete,
  isOpen,
  closeModal,
}: DeleteConfirmModalProps) {
  return (
    <Modal onClose={closeModal} isOpen={isOpen} size="sm">
      <ModalHeader
        title={
          <FormattedMessage
            defaultMessage="Delete Access Key"
            id="Ec0AuS"
            description="Delete modal header"
          />
        }
        enableClose
      />
      <ModalBody>
        <FormattedMessage
          defaultMessage="Are you sure you want to delete this access key?"
          id="XYt9t2"
          description="Delete modal body warning for Access Key"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Cancel"
            id="0GT0SI"
            description="Cancel button label"
          />
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            onDelete(id);
          }}
        >
          <FormattedMessage
            defaultMessage="Confirm"
            id="MLwQFB"
            description="Confirm button label"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function useDeleteConfirmModal() {
  return useModal(DeleteConfirmModal);
}
