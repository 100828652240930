import { type ElementType, type ReactNode } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isExoticComponent(component: any): boolean {
  return (
    typeof component === "object" &&
    typeof component.$$typeof === "symbol" &&
    ["react.memo", "react.forward_ref"].includes(component.$$typeof.description)
  );
}

export function isClassComponent(component: unknown): boolean {
  return (
    typeof component === "function" &&
    (() => {
      const proto = Object.getPrototypeOf(component);
      return !!proto.prototype && !!proto.prototype.isReactComponent;
    })()
  );
}

export function isReactComponent(component: unknown): boolean {
  return (
    isClassComponent(component) ||
    typeof component === "function" ||
    isExoticComponent(component)
  );
}

export function flexRender(
  target: ElementType | ReactNode,
  props: Record<string, unknown> = {},
): ReactNode {
  if (isReactComponent(target)) {
    const Comp = target as ElementType;

    return <Comp {...props} />;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return target;
}
