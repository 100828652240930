import { Navigate } from "@tanstack/react-router";
import { SpinnerIcon } from "icons";
import { useUCDMode } from "prisma";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { type UCDMode } from "requests";
import { dashboardsSavedViews } from "saved-views-next";
import { dashboardRoute } from "./dashboardRoute";

export function DashboardRedirect() {
  const intl = useIntl();
  const persona = useUCDMode() as UCDMode;

  const allViews = dashboardsSavedViews.useViews();
  const order = dashboardsSavedViews.useOrder();
  const isLoading = allViews.isLoading || order.isLoading;

  const visibleOrder = useMemo(
    () =>
      (order.data &&
        order.data.filter(
          (id: string) =>
            allViews.data &&
            allViews.data.find((view) => view.id === id)?.visible[persona],
        )) ??
      [],
    [allViews.data, order.data, persona],
  );

  if (isLoading)
    return (
      <div className="flex flex-col items-center">
        <SpinnerIcon size="4xl" />
      </div>
    );

  const dashboardId = visibleOrder[0];

  if (dashboardId) {
    return <Navigate to={dashboardRoute.id} params={{ dashboardId }} replace />;
  } else {
    return (
      <div className="flex flex-col items-center">
        {intl.formatMessage({
          defaultMessage:
            "No dashboards found. Please click on Add dashboard above to create one.",
          id: "narhW1",
          description: "Message if a user has no dashboards",
        })}
      </div>
    );
  }
}
