import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";

type ServerlessFunctionScanningProps = {
  data?: SecurityCapabilitiesPermissionsStatus;
  isCollapsibleDetails?: boolean;
};

export default function ServerlessFunctionScanning({
  data,
  isCollapsibleDetails = true,
}: ServerlessFunctionScanningProps) {
  if (isNil(data)) return null;

  return (
    <>
      <CollapsibleDetails
        icon={<SecurityCapabilitiesIcon type="Serverless Function Scanning" />}
        label="serverless_function_scanning"
        isCollapsibleDetails={isCollapsibleDetails}
        {...data}
      />
      {data?.orgSecurityCapabilities?.map(
        ({ name, status, statusIcon = false }) => (
          <CollapsibleDetails
            detailsClassName="mt-4"
            key={name}
            label={name}
            name={name}
            normalFont={true}
            status={status}
            isCollapsibleDetails={false}
            isNoStatusIcon={statusIcon}
          />
        ),
      )}
    </>
  );
}
