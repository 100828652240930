import { Button, useModal } from "ui";
import {
  AlibabaOnboarding,
  AwsOnboarding,
  AzureOnboarding,
  GcpOnboarding,
  IbmOnboarding,
  OciOnboarding,
} from "../../../../../CloudOnboarding/Onboarding";
import { type CloudType, type OnboardingProps } from "../../../../../types";

type EnableFeatureButtonProps = {
  accountId: string;
  cloudType: CloudType;
  btnText: string;
  disabled: boolean;
};

const cloudWizards: Record<string, React.FC<OnboardingProps>> = {
  aws: AwsOnboarding,
  alibaba_cloud: AlibabaOnboarding,
  azure: AzureOnboarding,
  gcp: GcpOnboarding,
  ibm: IbmOnboarding,
  oci: OciOnboarding,
};

export default function EnableFeatureButton({
  accountId,
  cloudType,
  btnText,
  disabled,
}: EnableFeatureButtonProps) {
  const CloudWizard = cloudWizards[cloudType];
  const { openModal: openCloudAccountWizard } = useModal(CloudWizard);

  return (
    <Button
      disabled={disabled}
      onClick={() => openCloudAccountWizard({ accountId, isEdit: true })}
    >
      {btnText}
    </Button>
  );
}
