import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 15 15"}>
      <path d="M10.5 0C10.78 0 11 0.21997 11 0.5V2.5H3.5C2.95001 2.5 2.5 2.94995 2.5 3.5V11H0.5C0.21997 11 0 10.78 0 10.5V0.5C0 0.21997 0.21997 0 0.5 0H10.5Z" />
      <path d="M4.5 4H14.5C14.7761 4 15 4.22388 15 4.5V10.48C15 11.3049 14.325 11.98 13.5 11.98H12.51C12.2295 11.98 12 12.2095 12 12.49V13.5C12 14.325 11.325 15 10.5 15H4.5C4.22388 15 4 14.7761 4 14.5V4.5C4 4.22388 4.22388 4 4.5 4ZM8.96582 10.75C9.24194 10.75 9.46582 10.5261 9.46582 10.25V9.75C9.46582 9.47388 9.24194 9.25 8.96582 9.25H7.03418C6.75806 9.25 6.53418 9.47388 6.53418 9.75V10.25C6.53418 10.5261 6.75806 10.75 7.03418 10.75H8.96582ZM11.9658 7.75C12.2419 7.75 12.4658 7.52612 12.4658 7.25V6.75C12.4658 6.47388 12.2419 6.25 11.9658 6.25H7.03418C6.75806 6.25 6.53418 6.47388 6.53418 6.75V7.25C6.53418 7.52612 6.75806 7.75 7.03418 7.75H11.9658Z" />
    </SvgIcon>
  );
};
