import { CloudAccountIdResponseSchema } from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";

export type CloudAccountIdKeysProps = {
  onlyActive?: boolean;
  includeOthers: boolean;
};

export const cloudAccountIdKeys = {
  all: [{ scope: "cloudAccountNameAll" }] as const,
  cloudAccountIds: (params: CloudAccountIdKeysProps) => {
    return [
      {
        ...cloudAccountIdKeys.all[0],
        entity: "cloudAccountId",
        params,
      },
    ] as const;
  },
} as const;

export const getCloudAccountIds = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountIdKeys)["cloudAccountIds"]>
>) => {
  const { onlyActive, includeOthers } = params;
  return jsonApi({
    path: "cloud/name",
    params: {
      onlyActive: String(onlyActive),
      includeOthers: String(includeOthers),
    },
    requestSchema: z.void(),
    responseSchema: CloudAccountIdResponseSchema,
  });
};
