import { type ReactElement } from "react";
import { useIntl } from "react-intl";

export function Logo({ ...rest }): ReactElement {
  const intl = useIntl();

  return (
    <svg
      aria-label={intl.formatMessage({
        defaultMessage: "Prisma Cloud Logo",
        id: "y2is6a",

        description: "label for prisma logo",
      })}
      role="img"
      width="215"
      height="44"
      viewBox="0 0 215 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M45 38H48.1963C49.2466 38 49.9087 38.5112 49.9087 39.5067C49.9087 40.1794 49.6804 40.6368 49.0411 40.8789C49.7032 41.1749 50 41.5785 50 42.3049C50 43.5426 49.2009 44 48.0822 44H45V38ZM47.8082 40.3946C48.2192 40.3946 48.4018 40.2332 48.4018 39.8565C48.4018 39.5067 48.2192 39.3453 47.8082 39.3453H46.5068V40.3946H47.8082ZM47.8767 42.6278C48.3333 42.6278 48.4932 42.4664 48.4932 42.0628C48.4932 41.6861 48.3105 41.4978 47.8767 41.4978H46.5068V42.6278H47.8767Z"
        fill="#939598"
      />
      <path
        d="M53.6084 42.0628L51 38H53.0228L54.4867 40.3946H54.5665L56.057 38H58L55.3916 42.0628V44H53.6084V42.0628Z"
        fill="#939598"
      />
      <path
        d="M63 38H66.6667C68.1944 38 69 38.861 69 40.2332C69 41.7937 67.9722 42.5202 66.5278 42.5202H64.8611V44H63V38ZM66.3333 41.0942C66.9167 41.0942 67.1667 40.7444 67.1667 40.2601C67.1667 39.722 66.8889 39.426 66.3333 39.426H64.8889V41.0942H66.3333Z"
        fill="#939598"
      />
      <path
        d="M71.076 38H73.9506L76 44H74.1369L73.711 42.7623H71.2624L70.8365 44H69L71.076 38ZM71.6882 41.3901H73.2852L72.6464 39.5067H72.327L71.6882 41.3901Z"
        fill="#939598"
      />
      <path d="M78 38H79.7838V42.4395H83L82.9189 44H78V38Z" fill="#939598" />
      <path
        d="M84 41C84 39.1695 85.1765 38 87.0118 38C88.8471 38 90 39.1695 90 41C90 42.8305 88.8471 44 87.0118 44C85.1765 44 84 42.8559 84 41ZM88.4471 41C88.4471 40.0085 87.9294 39.4492 87.0118 39.4492C86.0941 39.4492 85.5765 40.0339 85.5765 41C85.5765 41.9915 86.0941 42.5508 87.0118 42.5508C87.9059 42.5763 88.4471 41.9915 88.4471 41Z"
        fill="#939598"
      />
      <path
        d="M97.084 38H99.9427L102 44H100.13L99.7023 42.7623H97.271L96.8435 44H95L97.084 38ZM97.6985 41.3901H99.3015L98.6603 39.5067H98.3397L97.6985 41.3901Z"
        fill="#939598"
      />
      <path d="M103 38H104.811V42.4395H108L107.946 44H103V38Z" fill="#939598" />
      <path
        d="M110.749 39.5336H109L109.045 38H113.955L114 39.5336H112.251V44H110.749V39.5336Z"
        fill="#939598"
      />
      <path
        d="M116 41C116 39.1441 117.157 38 119 38C120.843 38 122 39.1695 122 41C122 42.8305 120.843 44 119 44C117.157 44 116 42.8559 116 41ZM120.441 41C120.441 40.0085 119.921 39.4492 119 39.4492C118.079 39.4492 117.559 40.0339 117.559 41C117.559 41.9915 118.079 42.5508 119 42.5508C119.898 42.5763 120.441 41.9915 120.441 41Z"
        fill="#939598"
      />
      <path
        d="M127 38H128.808L131.323 41.3901H131.349V38H133V44H131.245L128.677 40.5022H128.651V44H127V38Z"
        fill="#939598"
      />
      <path
        d="M135 38H140.821L140.881 39.4529H136.97V40.2601H140.045V41.6054H136.97V42.5202H141L140.94 44H135V38Z"
        fill="#939598"
      />
      <path
        d="M144.089 39.5336H142L142.08 38H147.92L148 39.5336H145.911V44H144.089V39.5336Z"
        fill="#939598"
      />
      <path
        d="M149 38H150.91L151.865 42.1435H151.944L153.191 38H154.942L156.162 42.1435H156.241L157.196 38H159L157.462 44H155.127L154.04 40.3677H153.987L152.899 44H150.565L149 38Z"
        fill="#939598"
      />
      <path
        d="M160 41C160 39.1695 161.35 38 163.5 38C165.65 38 167 39.1695 167 41C167 42.8305 165.65 44 163.5 44C161.35 44 160 42.8559 160 41ZM165.181 41C165.181 40.0085 164.575 39.4492 163.5 39.4492C162.425 39.4492 161.819 40.0339 161.819 41C161.819 41.9915 162.425 42.5508 163.5 42.5508C164.575 42.5763 165.181 41.9915 165.181 41Z"
        fill="#939598"
      />
      <path
        d="M169 38H172.421C173.974 38 174.737 38.7534 174.737 40.1525C174.737 41.1211 174.342 41.7668 173.605 42.0628L175 44H173L171.921 42.4126H170.763V44H169V38ZM172.184 41.0135C172.763 41.0135 172.974 40.6906 172.974 40.2332C172.974 39.7758 172.763 39.4529 172.184 39.4529H170.763V41.0135H172.184Z"
        fill="#939598"
      />
      <path
        d="M176 38H177.675V40.2063H178.35L179.975 38H181.875L179.725 40.8789L182 43.9731L181.975 44H180.025L178.35 41.6861H177.675V44H176V38Z"
        fill="#939598"
      />
      <path
        d="M184.048 43.4874V41.9305C184.933 42.4154 185.818 42.6451 186.56 42.6451C187.134 42.6451 187.421 42.492 187.421 42.2113C187.421 41.9305 187.23 41.8539 186.081 41.6498C184.837 41.4456 184 41.0372 184 39.8887C184 38.8167 184.885 38 186.584 38C187.278 38 187.995 38.1531 188.641 38.4594V40.0163C188.019 39.6335 187.158 39.3782 186.464 39.3782C185.866 39.3782 185.603 39.5314 185.603 39.7866C185.603 40.1184 185.986 40.1439 186.919 40.3226C188.163 40.5523 189 40.9351 189 42.1347C189 43.079 188.354 43.9979 186.464 43.9979C185.555 44.0234 184.813 43.8192 184.048 43.4874Z"
        fill="#939598"
      />
      <path
        d="M21.6719 29.1109V8.89404L32.1543 29.1109H21.6719Z"
        fill="#4DC6E0"
      />
      <path
        d="M21.6668 8.89419L11.1844 29.111H21.6668V43.6614L0 21.8307L11.0826 10.6669L12.8424 8.89419L21.6668 0V8.89419Z"
        fill="#4DC6E0"
      />
      <path
        d="M55.376 29.448C58.932 29.448 61.648 28.272 63.02 27.236V22.868H62.992C60.948 24.408 58.736 25.528 55.684 25.528C51.344 25.528 49.16 22.784 49.16 19.284C49.16 15.616 51.512 12.872 55.712 12.872C58.82 12.872 60.892 14.244 62.992 15.644H63.02V11.276C61.592 10.156 59.016 8.952 55.796 8.952C48.46 8.952 44.736 13.88 44.736 19.312C44.736 24.632 47.844 29.448 55.376 29.448ZM76.131 29H91.475L91.615 25.024H80.443V9.4H76.131V29ZM112.867 29.448C119.475 29.448 123.647 25.416 123.647 19.2C123.647 12.984 119.475 8.952 112.867 8.952C106.259 8.952 102.087 12.984 102.087 19.2C102.087 25.416 106.259 29.448 112.867 29.448ZM112.839 25.556C108.779 25.556 106.511 23.148 106.511 19.2C106.511 15.252 108.835 12.844 112.895 12.844C116.955 12.844 119.223 15.252 119.223 19.2C119.223 23.148 116.899 25.556 112.839 25.556ZM145.81 29.448C151.074 29.448 155.246 27.292 155.246 21.3V9.4H150.934V21.384C150.934 24.492 148.498 25.472 145.866 25.472C143.234 25.472 140.798 24.436 140.798 21.244V9.4H136.486V21.44C136.486 27.264 140.546 29.448 145.81 29.448ZM169.226 29H178.83C184.99 29 188.686 25.276 188.686 19.172C188.686 13.04 184.99 9.4 178.83 9.4H169.226V29ZM173.538 25.108V13.292H178.802C182.274 13.292 184.262 15.196 184.262 19.228C184.262 23.288 182.274 25.108 178.802 25.108H173.538Z"
        className="fill-[#333333] dark:fill-white"
      />
    </svg>
  );
}
