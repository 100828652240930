import {
  ActionPlansSavedViewSchema,
  AlertSavedViewSchema,
  ComplianceStandardSavedViewSchema,
  DashboardsSavedViewSchema,
  PolicySavedViewSchema,
  type ActionPlansSavedView,
  type AlertSavedView,
  type ComplianceStandardSavedView,
  type DashboardSavedView,
  type PolicySavedView,
} from "requests";
import { createSavedViews } from "./query";

export const alertsSavedViews = createSavedViews<
  AlertSavedView,
  typeof AlertSavedViewSchema
>({
  collection: "alerts",
  schema: AlertSavedViewSchema,
});

export const policiesSavedViews = createSavedViews<
  PolicySavedView,
  typeof PolicySavedViewSchema
>({
  collection: "policies",
  schema: PolicySavedViewSchema,
});

export const complianceStandardsSavedViews = createSavedViews<
  ComplianceStandardSavedView,
  typeof ComplianceStandardSavedViewSchema
>({
  collection: "complianceStandards",
  schema: ComplianceStandardSavedViewSchema,
});

export const dashboardsSavedViews = createSavedViews<
  DashboardSavedView,
  typeof DashboardsSavedViewSchema
>({
  collection: "dashboards",
  schema: DashboardsSavedViewSchema,
});

export const actionPlansSavedViews = createSavedViews<
  ActionPlansSavedView,
  typeof ActionPlansSavedViewSchema
>({
  collection: "actionPlans",
  schema: ActionPlansSavedViewSchema,
});
