import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { getFeatures, permissionGroupsKeys } from "requests";

export function useEnabledFeatures() {
  const query = useQuery({
    queryKey: permissionGroupsKeys.features(),
    queryFn: getFeatures,
    staleTime: 3 * 60 * 1000,
  });

  const featureObject = useMemo(() => {
    return query.data?.reduce<Record<string, boolean>>((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
  }, [query.data]);

  const isFeatureEnabled = useCallback(
    (name: string) => {
      return !!featureObject?.[name];
    },
    [featureObject],
  );

  return { ...query, isFeatureEnabled };
}
