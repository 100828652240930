import { defineMessages, type MessageDescriptor } from "react-intl";
import { ALIBABA_CLOUD, AWS, AZURE, GCP, IBM, OCI } from "../../constants";
import { type CloudType } from "../../types";

export const CloudNames = defineMessages<CloudType, MessageDescriptor>({
  [AWS]: {
    defaultMessage: "Amazon Web Services",
    id: "p7o+cB",
    description: "AWS Cloud Name",
  },
  [AZURE]: {
    defaultMessage: "Azure",
    id: "1/ybBX",
    description: "Azure Cloud Name",
  },
  [GCP]: {
    defaultMessage: "Google Cloud Platform",
    id: "OZmbJJ",
    description: "GCP Cloud Name",
  },
  [ALIBABA_CLOUD]: {
    defaultMessage: "Alibaba Cloud",
    id: "cW/DZk",
    description: "Alibaba Cloud Name",
  },
  [OCI]: {
    defaultMessage: "Oracle Cloud",
    id: "4xnpYB",
    description: "OCI Cloud Name",
  },
  [IBM]: {
    defaultMessage: "IBM Cloud",
    id: "6Bfsxn",
    description: "IBM Cloud Name",
  },
});
