import { useMemo } from "react";
import { useIntl, type IntlShape } from "react-intl";

import { usePermissions } from "prisma";
import { ConnectedTable, TableProvider } from "table";
import { Body, Button, TruncateText } from "ui";
import { type UserProfileType } from "../../../../users/Forms/User/UserForm";

function useColumns(intl: IntlShape, closeModal: () => void) {
  const permissions = usePermissions();
  const readUserAllowed = permissions?.settingsUsers?.["READ"];

  return useMemo(
    () => [
      {
        accessor: "email",
        Cell: ({ value }: { value: string }) => {
          return readUserAllowed ? (
            <Button
              appearance="clear"
              size="sm"
              onClick={() => {
                // TODO: Uncomment when user forms is implemented
                // openUserModal({
                //   // eslint-disable-next-line react/prop-types
                //   initialValues: row.original.userProfile,
                //   role,
                // });

                closeModal();
              }}
            >
              <TruncateText>{value}</TruncateText>
            </Button>
          ) : (
            <Body as="span" size="sm">
              <TruncateText>{value}</TruncateText>
            </Body>
          );
        },
        Header: intl.formatMessage({
          defaultMessage: "Email",
          id: "C4LxH1",
          description: "Email header",
        }),
        sortType: "basic",
        width: 350,
      },
    ],
    [intl, readUserAllowed, closeModal],
  );
}

export default function Table({
  data,
  closeModal,
}: {
  data: UserProfileType[];
  closeModal: () => void;
}) {
  const intl = useIntl();
  // const { openModal } = useUserModalForm();
  // const columns = useColumns(intl, closeModal, openModal);
  const columns = useColumns(intl, closeModal);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore TODO: update columns type in Users ts MR
    <TableProvider columns={columns} data={data || []}>
      <div className="mt-4 space-y-4">
        <div className="overflow-x-auto">
          <ConnectedTable appearance="secondary" />
        </div>
      </div>
    </TableProvider>
  );
}
