import { type ReactElement } from "react";

import { SkeletonRoot, type SkeletonRootProps } from "./SkeletonRoot";

export type SkeletonProps = Omit<SkeletonRootProps, "index"> & {
  count?: number;
};

/**
 * Animated loading skeleton
 *
 * The Skeleton component should be used directly in place of content that is loading.
 *
 * @returns {React.ReactElement} The Skeleton.
 * @example
 *
 * <Skeleton
 *   count={1}
 *   addClassName=""
 *   height={50}
 *   width={50}
 *   variant={"circular" | "rectangular" | "rounded" | "rounded-lg"}
 *   animationDelay={300}
 *   gradient={{
 *     width: 16,
 *     indentation: 16,
 *   }}
 * />
 */
export function Skeleton({
  count,
  ...otherProps
}: SkeletonProps): ReactElement {
  if (count) {
    // shimmer effect should be slanted to the right, and since the negative margin
    // of the gradient div element is calculated based on the card index, they need to be in a reverse order.
    const cards = [...Array(count).keys()].reverse();

    return (
      <>
        {cards.map((index) => (
          <SkeletonRoot key={index} index={index} {...otherProps} />
        ))}
      </>
    );
  }

  return <SkeletonRoot {...otherProps} />;
}
