import { type FC } from "react";
import { get, useFormContext, type RegisterOptions } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  Field,
  Textarea as ReactTextarea,
  type FieldProps,
  type TextareaProps as ReactTextareaProps,
} from "ui";

import { requiredMessage } from "../../messages";

export type TextareaProps = {
  /**
   * The name the entered value will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  /**
   * A default value for the Textarea
   */
  defaultValue?: string;
} & Omit<FieldProps, "error" | "control"> &
  ReactTextareaProps;

/**
 * Renders an Textarea connected to the form. All extra props will get passed to the Textarea.
 */
export const Textarea: FC<TextareaProps> = (props: TextareaProps) => {
  const {
    name,
    label,
    register: registerOptions = {},
    description,
    tooltip,
    disabled,
    showLabel,
    ...rest
  } = props;
  const context = useFormContext();
  const intl = useIntl();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render Textarea outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState, register } = context;
  registerOptions.required =
    registerOptions.required ?? intl.formatMessage(requiredMessage, { label });
  const errorMessage = get(formState.errors, name)?.message;
  const { onBlur, onChange, ...formRegister } = register(name, registerOptions);

  return (
    <Field
      label={label}
      optional={!registerOptions.required}
      control={
        <ReactTextarea
          appearance={errorMessage ? "error" : "default"}
          disabled={disabled}
          {...formRegister}
          {...rest}
          onBlur={(event) => {
            onBlur(event);
            rest.onBlur?.(event);
          }}
          onChange={(event) => {
            onChange(event);
            rest.onChange?.(event);
          }}
        />
      }
      error={errorMessage}
      description={description}
      tooltip={tooltip}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
};
