import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsPagerDutyIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Pager Duty",
    id: "sSMlVH",

    description: "Pager Duty icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M18.5714 83.8095H10V41.9047C10 37.619 11.9048 34.7619 13.3333 33.3333C16.6667 30 20.9524 30 21.9048 30H34.7619C39.5238 30 42.3809 31.9047 43.8095 33.3333C46.6667 36.6666 46.6667 40.4761 46.6667 41.9047V57.619C46.6667 62.3809 44.7619 64.7619 43.3333 66.1904C40 69.0476 35.7143 69.0476 34.7619 69.0476H18.5714V83.8095ZM35.2381 61.4285C35.7143 61.4285 37.1429 61.4285 37.619 60.4761C38.0952 59.9999 38.5714 59.0476 38.5714 57.619V41.9047C38.5714 41.4285 38.5714 40.4761 37.619 39.5238C37.1429 38.5714 35.7143 38.5714 34.7619 38.5714H21.9048C18.5714 38.5714 18.5714 40.9523 18.5714 41.9047V61.4285H35.2381ZM81.4286 16.1904H90V58.0952C90 62.3809 88.0952 65.238 86.6667 66.6666C83.3333 70 79.0476 69.9999 78.0952 69.9999H64.7619C60 69.9999 57.1428 68.0952 55.7143 66.6666C52.8571 63.3333 52.8571 59.5238 52.8571 58.0952V41.9047C52.8571 37.1428 54.7619 34.7619 56.1905 33.3333C59.5238 30.4761 63.8095 30.4761 64.7619 30.4761H81.4286V16.1904ZM64.7619 38.5714C64.2857 38.5714 62.8571 38.5714 62.3809 39.5238C61.9048 40 61.4286 40.9523 61.4286 42.3809V58.5714C61.4286 59.0476 61.4286 59.9999 62.3809 60.9523C62.8571 61.4285 64.2857 61.9047 65.2381 61.9047H78.5714C81.9048 61.9047 81.9048 59.5238 81.9048 58.5714V38.5714H64.7619Z"
        className="fill-[#25C151] dark:fill-white"
      />
    </SvgIcon>
  );
};
