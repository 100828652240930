import { createCtx } from "ui";

import { type ReactElement, type ReactNode } from "react";
import { type UseWizardStateReturn } from "./useWizardState";

const [useWizardContext, InnerWizardProvider] =
  createCtx<UseWizardStateReturn>();

export { useWizardContext };

export interface WizardContextProps {
  children: ReactNode;
  context: UseWizardStateReturn;
}

export function WizardProvider({
  children,
  context,
}: WizardContextProps): ReactElement {
  return <InnerWizardProvider value={context}>{children}</InnerWizardProvider>;
}
