import { Children, cloneElement } from "react";
import { useOptionallyControlledState } from "../../utils/useOptionallyControlledState";

import { type ReactElement, type ReactNode } from "react";
import { classNames } from "utils";
import { type SetControlledState } from "../../utils/useOptionallyControlledState";
import { type TitleProps } from "../Typography";

export interface AccordionProps {
  /**
   * additional classes.
   */
  addClassName?: string;
  /**
   * Allow multiple accordion panels to be open simultaneously.
   */
  allowMultipleOpen?: boolean;
  /**
   * Allow all accordion panels to be closed.
   */
  allowNoneOpen?: boolean;
  /**
   * Remove space between items.
   */
  noSpaceBetween?: boolean;
  /**
   * Generally `AccordionPanel`s.
   *
   * `children` will be cloned and appropriate props passed.
   */
  children: ReactNode;
  /**
   * Default open panels, by index in the `children`.
   */
  defaultOpenPanels?: number[];
  /**
   * The heading level of the accorion headers. Set as appropriate for the information architecture of the page.
   */
  level?: TitleProps["level"];
  /**
   * The currently open panels.
   *
   * Only use when controlling the accordions state. Use in conjunction with `onChangeOpenPanels`.
   */
  openPanels?: number[];
  /**
   * Invoked when the user toggles a panel open or closed. Use in conjunction with `openPanels` to control the accordion state.
   */
  onChangeOpenPanels?: SetControlledState<number[]>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function canClone(arg: any): arg is ReactElement {
  return arg?.props !== undefined;
}

/**
 * An accordion is a vertically stacked list of headers that reveal or hide associated sections of content.
 *
 * The accordion component delivers large amounts of content in a small space through progressive disclosure.
 * The header title give the user a high level overview of the content allowing the user to decide which sections to read.
 */
export function Accordion({
  addClassName,
  allowMultipleOpen = false,
  allowNoneOpen = false,
  noSpaceBetween = false,
  children,
  defaultOpenPanels = [0],
  level = 3,
  openPanels: userOpenPanels,
  onChangeOpenPanels,
}: AccordionProps): ReactElement {
  const [openPanels, setOpenPanels] = useOptionallyControlledState({
    currentValue: userOpenPanels,
    defaultValue: defaultOpenPanels,
    onChange: onChangeOpenPanels,
  });

  const panels = Children.map(children, (child, index) => {
    if (!canClone(child)) return child;

    const isOpen = openPanels.includes(index);
    const canClose = allowNoneOpen || openPanels.length > 1;
    return cloneElement(child, {
      canClose,
      isOpen,
      level,
      onClick() {
        if (canClose && isOpen) {
          return setOpenPanels(
            openPanels.filter((panelIndex) => panelIndex !== index),
          );
        }

        if (!isOpen && allowMultipleOpen) {
          return setOpenPanels(openPanels.concat(index));
        }

        setOpenPanels([index]);
      },
      ...child.props,
    });
  });

  const accordionClassNames = classNames(
    "flex flex-col",
    !noSpaceBetween && "space-y-4",
    addClassName,
  );

  return <div className={accordionClassNames}>{panels}</div>;
}
