import { AZURE, GCP } from "../../../constants";
import { type CloudType } from "../../../types";
import { type ConvertAwsTreeMapToTreeDataType } from "../types";

import convertAwsTreeMapToTreeData from "./convertAwsTreeMapToTreeData";
import convertAzureTreeMapToTreeData from "./convertAzureTreeMapToTreeData";
import convertGcpTreeMapToTreeData from "./convertGcpTreeMapToTreeData";

export default function convertTreeMapToTreeData({
  nodeId,
  treeMap,
  disabled,
  loadMoreProps,
  selectedCloud,
}: ConvertAwsTreeMapToTreeDataType & { selectedCloud: CloudType }) {
  if (selectedCloud === AZURE) {
    return convertAzureTreeMapToTreeData({
      nodeId,
      treeMap,
      disabled,
      loadMoreProps,
    });
  } else if (selectedCloud === GCP) {
    return convertGcpTreeMapToTreeData({
      nodeId,
      treeMap,
      disabled,
      loadMoreProps,
    });
  } else {
    return convertAwsTreeMapToTreeData({
      nodeId,
      treeMap,
      disabled,
      loadMoreProps,
    });
  }
}
