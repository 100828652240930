import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";

import { FileMedicalIcon, LoadingIcon } from "icons";

import { grayScale } from "colors";
import { type BannerAppearance } from "ui";
import { useWizardContext } from "../../../../../../../components/Wizard";
import {
  ALL,
  AUTO,
  GCP,
  GCP_ACCOUNT_GROUP_CREATION_MODE,
  GCP_HIERARCHY_SELECTION,
  INCLUDE,
  ORGANIZATION,
  SERVICE_ACCOUNT,
} from "../../../../../../constants";
import {
  useIsRenderedFirstTime,
  useIsUnMounted,
} from "../../../../../../hooks";
import { getOUPreviouslySelectedNodesErrorMessage } from "../../../../../../utils";
import {
  Accordion,
  AccordionToggle,
} from "../../../../components/CustomAccordion";
import { getCredentialsErrorMessage } from "../../../../components/SelectMemberAccountCredentialError";
import { useGCPSelectMonitoredProjectsContext } from "../../../context/GCPSelectMonitoredProjectContext";
import {
  setGCPAccountGroupCreationMode,
  setGCPProjectSelectMode,
} from "../../../context/GCPSelectMonitoredProjectContext/state/actions";
import {
  getGCPFoldersCredentialError,
  getGCPOUSelectProjectsIsLoading,
  getGCPPreviouslySelectedNodesNotFound,
  getGCPProjectSelectMode,
  getGcpAccountGroupCreationMode,
  getHierarchySelection,
  getIsRateLimit503,
} from "../../../context/GCPSelectMonitoredProjectContext/state/getters";
import { useGCPCredentialsFileContext } from "../../../context/GcpCredentialsFileContext";
import {
  getExternalAccountKey,
  getExternalAccountKeyErrors,
  getServiceAccountKey,
  getServiceAccountKeyErrors,
} from "../../../context/GcpCredentialsFileContext/state/getters";
import GCPChooseMonitoredProjects from "./GCPChooseMonitoredProjects";
import { messageMap } from "./messages";

type SelectMonitoredProjectsProps = {
  closeModal: () => void;
};

export default function SelectMonitoredProjects(
  props: SelectMonitoredProjectsProps,
) {
  const {
    state: { steps },
  } = useWizardContext();

  const { accountType } = steps[0]?.values ?? {};

  if (accountType !== ORGANIZATION) {
    return null;
  }
  return <TreeHierarchySelection {...props} />;
}

function TreeHierarchySelection({ closeModal }: SelectMonitoredProjectsProps) {
  const intl = useIntl();
  const { getValues, watch } = useFormContext();
  const isUnMounted = useIsUnMounted();

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();

  const { state, dispatch } = useGCPSelectMonitoredProjectsContext();
  const { state: jsonFileState } = useGCPCredentialsFileContext();

  const {
    valuesUpdate,
    state: { activeIndex },
  } = useWizardContext();

  const organizationId = watch("organizationId");
  const authenticationType = watch("authenticationType");

  const mode = getGCPProjectSelectMode(state);
  const [isDisabled, setDisabled] = useState(() => mode !== ALL);
  const isLoading = getGCPOUSelectProjectsIsLoading(state) && mode === ALL;

  const credentials =
    authenticationType === SERVICE_ACCOUNT
      ? getServiceAccountKey(jsonFileState)
      : getExternalAccountKey(jsonFileState);
  const credentialsErrors =
    authenticationType === SERVICE_ACCOUNT
      ? getServiceAccountKeyErrors(jsonFileState)
      : getExternalAccountKeyErrors(jsonFileState);

  const hasRateLimitHit = getIsRateLimit503(state);
  const apiFolderCredentialError = getGCPFoldersCredentialError(state);
  const previouslySelectedNodesNotFound =
    getGCPPreviouslySelectedNodesNotFound(state);

  const toggle = () => {
    setGCPProjectSelectMode(isDisabled ? ALL : INCLUDE, dispatch);
    if (isDisabled) {
      dispatch(setGCPAccountGroupCreationMode(AUTO));
    }
  };

  const disabled =
    !isEmpty(apiFolderCredentialError) ||
    !isEmpty(credentialsErrors) ||
    isEmpty(organizationId) ||
    isEmpty(credentials) ||
    hasRateLimitHit;

  // Open/close accordion on mode change
  useEffect(() => {
    if (!isRenderedFirstTime) {
      setDisabled(mode !== ALL);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Change mode to all if toggle state is disabled
  useEffect(() => {
    if (disabled) {
      setGCPProjectSelectMode(ALL, dispatch);
    }
  }, [disabled, dispatch]);

  // Get credentials and prevSelectedNode Error Messages
  const selectProjectErrors = useMemo(() => {
    const credErr = getCredentialsErrorMessage({
      errorMessage: apiFolderCredentialError,
      type: GCP,
      intl,
    });

    const prevNodeErr = getOUPreviouslySelectedNodesErrorMessage({
      data: previouslySelectedNodesNotFound,
      intl,
    });

    let rateLimitError: {
      description: string;
      errorType: BannerAppearance;
    }[] = [];
    if (hasRateLimitHit) {
      rateLimitError = [
        {
          description: intl.formatMessage(messageMap.rateLimitLabel),
          errorType: "warning",
        },
      ];
    }

    return [...credErr, ...prevNodeErr, ...rateLimitError].filter(
      (v) => !isEmpty(v),
    );
  }, [
    apiFolderCredentialError,
    intl,
    previouslySelectedNodesNotFound,
    hasRateLimitHit,
  ]);

  // Update step context and save treeNodes selection on unmount of component
  const updateStepValues = useCallback(() => {
    if (isUnMounted.current) {
      const values = getValues();
      const hierarchySelectionSelectedNodes = getHierarchySelection(state);
      const gcpAccountGroupCreationMode = getGcpAccountGroupCreationMode(state);

      valuesUpdate({
        index: activeIndex,
        values: {
          ...values,
          [GCP_HIERARCHY_SELECTION]: hierarchySelectionSelectedNodes,
          [GCP_ACCOUNT_GROUP_CREATION_MODE]: gcpAccountGroupCreationMode,
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    return () => updateStepValues();
  }, [updateStepValues]);

  const isCardOpen = isDisabled;
  const isToggleChecked = !isDisabled;

  return (
    <Accordion
      description={intl.formatMessage(messageMap.description)}
      disabled={disabled}
      errors={selectProjectErrors}
      isOpen={isCardOpen}
      icon={<FileMedicalIcon size="md" color={grayScale[600]} />}
      menuToggle={
        isLoading ? (
          <LoadingIcon size="md" />
        ) : (
          <AccordionToggle
            checked={isToggleChecked}
            disabled={disabled}
            onClick={toggle}
          />
        )
      }
      title={intl.formatMessage(messageMap.title)}
    >
      <GCPChooseMonitoredProjects closeModal={closeModal} />
    </Accordion>
  );
}
