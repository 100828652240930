import { Map, OrderedSet, Set } from "immutable";
import { type TreeMapType } from "../../../../../../../components/SelectMemberAccountTree/types";
import {
  AWS_OU_DEFAULT_EXPANDED_NODES,
  AWS_OU_LOADED_NODES,
  AWS_OU_LOAD_MORE_NO_TOKEN,
  AWS_OU_TREE_MAP,
  ERRORS,
  VALUE,
} from "../../../../../../../constants";
import { getAwsOUSelectedNodesInitial } from "../getters";
import { type AwsSelectMemberAccountsInitialStateType } from "../initialState";

type NodeType = {
  displayName: string;
  hasChild: boolean;
  id: string;
  parent: string | null;
  type: string;
  children?: {
    folders: OrderedSet<string>;
    projects: OrderedSet<string>;
  };
  cursorToken?: Map<string, unknown>;
};
/**
 * Takes a node object, as returned by the `cloud-accounts-manager/v1/cloudAccounts/awsAccounts/${id}/*` and
 * `cloud-accounts-manager/v1/cloudAccounts/awsAccounts/${id}/ancestors` endpoints and converts it to an
 * immutable Map, as used to store the nodes in redux.
 * @param {object} node
 */
function makeImmutableNode(node: NodeType) {
  return Map(node)
    .set("children", Map({ folders: OrderedSet(), projects: OrderedSet() }))
    .set("cursorToken", Map());
}

type AncestorsOfSelectedType = {
  ancestors: NodeType[];
  resourceId: string;
  responseCode: number;
  status: string;
}[];

/**
 * Takes the payload returned from `cloud-accounts-manager/v1/cloudAccounts/awsAccounts/${id}/ancestors`
 * and returns an array of parent-child tuples
 * @param {object} ancestorsOfSelected
 */
function makeParentChildTuples(ancestorsOfSelected: AncestorsOfSelectedType) {
  return ancestorsOfSelected.reduce(
    (accum: string[][], { ancestors, resourceId }) =>
      accum.concat(
        ancestors.map((node, index, original) => {
          if (index === 0) {
            return [node.id, resourceId];
          }

          return [node.id, original[index - 1].id];
        }),
      ),
    [],
  );
}

/**
 * Takes an array of parent-child tuples and returns a map of child to parent
 * @param {array} parentChildTuples
 */
function makeChildParentMap(parentChildTuples: string[][]) {
  return parentChildTuples.reduce((accum, tuple) => {
    const [parentId, childId] = tuple;

    return accum.set(childId, parentId);
  }, Map());
}

/**
 * Takes an array of parent-child tuples and a map of tree nodes and returns a
 * new map with references to the children added to the parents
 * @param {array} parentChildTuples
 * @param {Map} treeMap
 */
function addChildrenToParentsInTreeMap(
  parentChildTuples: string[][],
  treeMap: TreeMapType,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return parentChildTuples.reduce((accum: any, tuple: string[]) => {
    const [parentId, childId] = tuple;

    const childType = treeMap.getIn([childId, "type"]);

    if (childType === "OU") {
      return accum.updateIn(
        [parentId, "children", "folders"],
        (folders: OrderedSet<string>) => folders.add(childId),
      );
    } else if (childType === "ACCOUNT") {
      return accum.updateIn(
        [parentId, "children", "projects"],
        (projects: OrderedSet<string>) => projects.add(childId),
      );
    }
  }, treeMap);
}

/**
 * Makes the initial tree map, a map of node id's to node data, with
 * two-way references between child and parent nodes.
 */
function makeInitialTreeMap({
  ancestorsOfSelected,
  initialSelectedNodes,
  rootId,
}: {
  ancestorsOfSelected: AncestorsOfSelectedType;
  initialSelectedNodes: {
    displayName: string;
    nodeType: string;
    resourceId: string;
    selectionType: string;
  }[];
  rootId: string;
}) {
  const parentChildTuples = makeParentChildTuples(ancestorsOfSelected);
  const childParentMap = makeChildParentMap(parentChildTuples);

  const rootNode = makeImmutableNode({
    displayName: "Root",
    hasChild: true,
    id: rootId,
    parent: null,
    type: "ORG",
  });

  const selectedNodes = initialSelectedNodes.map((node) =>
    // Convert to immutable nodes, adding parent reference
    makeImmutableNode({
      displayName: node.displayName,
      hasChild: node.nodeType === "OU" || node.nodeType === "ORG",
      id: node.resourceId,
      parent: childParentMap.get(node.resourceId) as string | null,
      type: node.nodeType,
    }),
  );

  const ancestorNodes = ancestorsOfSelected
    // Pull the array of ancestors out of the payload
    .map((entry) => entry.ancestors)
    // Flatten to a single dimensional array
    .reduce((acc, value) => acc.concat(value), [])
    // Convert to immutable nodes, adding parent reference
    .map((node) =>
      makeImmutableNode({
        ...node,
        parent: childParentMap.get(node.id) as string | null,
      }),
    )
    // Add load more tokens to folders loaded via the ancestory endpoint
    .map((node) =>
      node
        .setIn(["cursorToken", "folders"], AWS_OU_LOAD_MORE_NO_TOKEN)
        .setIn(["cursorToken", "projects"], AWS_OU_LOAD_MORE_NO_TOKEN),
    );

  // Concat the three sources of initial nodes into a single array
  const initialNodes = [rootNode].concat(selectedNodes).concat(ancestorNodes);

  // Convert the array of nodes into a map of node id's to nodes
  const treeMapWithoutChildren = initialNodes.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (accum, node: any) => accum.set(node.get("id"), node),
    Map(),
  ) as unknown as TreeMapType;

  return addChildrenToParentsInTreeMap(
    parentChildTuples,
    treeMapWithoutChildren,
  );
}

/**
 * Takes the payload returned from `cloud-accounts-manager/v1/cloudAccounts/awsAccounts/${id}/ancestors`
 * and the rootId and returns Set of node ids to be expanded when the tree
 * mounts
 * @param {object} ancestorsOfSelected
 * @param {string} rootId
 */
function makeDefaultExpandedNodes(
  ancestorsOfSelected: AncestorsOfSelectedType,
  rootId: string,
) {
  const nodes = ancestorsOfSelected
    // Flatten the ancestors into a single dimensional array
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce((accum: any, { ancestors }) => accum.concat(ancestors), [])
    // Pluck the id's
    .map((node: NodeType) => node.id);

  // Remove any duplicates and add the root node
  return Set(nodes).add(rootId);
}

/**
 * Initializes the AWS select projects form
 */
export function initializeAwsOUSelectProjects({
  state,
  payload: { ancestorsOfSelected = [], rootId },
}: {
  state: AwsSelectMemberAccountsInitialStateType;
  payload: { ancestorsOfSelected: AncestorsOfSelectedType; rootId: string };
}) {
  const initialSelectedNodes = getAwsOUSelectedNodesInitial(state)?.toJS();

  const initialTreeMap = makeInitialTreeMap({
    ancestorsOfSelected,
    initialSelectedNodes,
    rootId,
  });

  const defaultExpandedNodes = makeDefaultExpandedNodes(
    ancestorsOfSelected,
    rootId,
  );

  return state
    .setIn([AWS_OU_LOADED_NODES, VALUE], Set())
    .setIn([AWS_OU_TREE_MAP, VALUE], initialTreeMap)
    .setIn([AWS_OU_DEFAULT_EXPANDED_NODES, VALUE], defaultExpandedNodes);
}

/**
 * Updates the AWS select projects form with new data from the payload
 */
export function updateAwsOUTreeMap({
  state,
  payload,
}: {
  state: AwsSelectMemberAccountsInitialStateType;
  payload: {
    childrenType: string;
    data: {
      response: {
        displayName: string;
        hasChild: boolean;
        id: string;
        parent: string;
        type: string;
      }[];
      ouNextPageToken?: string;
      accountNextPageToken?: string;
    };
    loadType: string;
    parentId: string;
  };
}) {
  return state
    .updateIn([AWS_OU_TREE_MAP, VALUE], (prevTreeMap) => {
      const { data, parentId } = payload;
      const newNodes = data.response;
      const newFolders = newNodes
        .filter((node) => node.type === "OU")
        .map((node) => node.id);
      const newProjects = newNodes
        .filter((node) => node.type === "ACCOUNT")
        .map((node) => node.id);
      const newNodesMap = newNodes.reduce(
        (treeMap, node) => treeMap.set(node.id, makeImmutableNode(node)),
        Map(),
      );

      return prevTreeMap
        .mergeDeep(newNodesMap)
        .updateIn([parentId, "children", "folders"], (f: Set<string>) =>
          f.union(newFolders),
        )
        .updateIn([parentId, "children", "projects"], (p: Set<string>) =>
          p.union(newProjects),
        )
        .updateIn(
          [parentId, "cursorToken"],
          (cursorToken: Map<"folders" | "projects", string | undefined>) => {
            return cursorToken
              .set("folders", data.ouNextPageToken)
              .set("projects", data.accountNextPageToken);
          },
        );
    })
    .updateIn([AWS_OU_LOADED_NODES, VALUE], (set) => {
      const { loadType, parentId } = payload;

      if (loadType === "initial") {
        return set.add(parentId);
      }

      return set;
    });
}

export const setFTUFieldErrors = ({
  errors,
  field,
  state,
}: {
  errors: string[];
  field: string;
  state: Map<string, unknown>;
}) => {
  const errorsArray = Array.isArray(errors) ? errors : [errors];
  return state.setIn([field, ERRORS], errorsArray);
};

export const setFTUFieldValue = ({
  field,
  state,
  value,
}: {
  field: string;
  state: Map<string, unknown>;
  value: unknown;
}) => {
  return state.setIn([field, VALUE], value);
};

export const clearFTUFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: Map<string, unknown>;
}) => {
  return state.setIn([field, ERRORS], []);
};

export const updateAwsOULoadedNodes = ({
  state,
  payload,
}: {
  state: Map<string, unknown>;
  payload: string;
}) => {
  return state.setIn(
    [AWS_OU_LOADED_NODES, VALUE],
    state.getIn([AWS_OU_LOADED_NODES, VALUE]).add(payload),
  );
};
