import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";

export type ItalicProps<
  T = Omit<ComponentPropsWithoutRef<"span">, "className">,
> = T & {
  /**
   * Add a class name to the element.
   */
  addClassName?: string;
  children: ReactNode;
  /**
   * The type of element to render.
   *
   * By default, `Italic` renders a `span` element, but any element type can be used.
   */
  as?: ElementType;
};

/**
 * Renders its children in _italic_ font.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Italic } from "ui";
 * ```
 */
export function Italic<
  T = Omit<ComponentPropsWithoutRef<"span">, "className">,
>({
  addClassName,
  children,
  as: Component = "span",
  ...passThrough
}: ItalicProps<T>): ReactElement {
  const className = classNames("italic tracking-wide", addClassName);

  return (
    <Component {...passThrough} className={className}>
      {children}
    </Component>
  );
}
