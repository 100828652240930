import { useCallback, useMemo, useState, type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CheckIcon, FileDownloadIcon, LoadingIcon, WarningIcon } from "icons";
import { downloadFileRequest } from "requests";
import { useToastActions } from "stores";
import { Button, dynamicFormatMessage, type ButtonAppearances } from "ui";
import { downloadFile } from "utils";
import { type ScriptDownloadApiError } from "../../Sidecar/AwsSidecar/Misconfigurations/Findings/ConfigureFindingsWizard/ConfigDetails/ConfigDetailsLayout";
import { statusToastMessagesMap } from "../../messages/reviewStatusMessages";
import { handleError } from "../../utils";

type DownloadTerraformScriptProps = {
  btnLabel?: ReactNode;
  className?: string;
  closeModal?: () => void;
  endPoint: string;
  fileName: string;
  hasJsonErrorResponse?: boolean;
  isDownloadDisabled?: boolean;
  method: "post" | "get";
  onSuccess?: (resp?: Response) => void;
  onError?: (arg: ScriptDownloadApiError) => void;
  payload?: Record<string, unknown>;
  appearance?: ButtonAppearances;
  showWarningIcon?: boolean;
};

export default function DownloadTerraformScript({
  btnLabel,
  className = "",
  closeModal,
  endPoint,
  fileName = "",
  hasJsonErrorResponse = false,
  isDownloadDisabled = false,
  method,
  onSuccess,
  onError,
  appearance = "primary",
  payload,
  showWarningIcon = false,
}: DownloadTerraformScriptProps) {
  const { toast } = useToastActions();
  const intl = useIntl();

  const [isScriptDownloaded, setScriptDownloaded] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    try {
      const config: Record<string, unknown> = {
        headers: {
          "Content-Type": "application/json",
        },
        method,
      };

      if (method?.toLowerCase() === "post") {
        config.body = JSON.stringify(payload);
      }

      const response = await downloadFileRequest({
        path: endPoint,
        config,
      });

      if (!response.ok) {
        let body;
        if (response.status === 403) {
          body = JSON.parse(response.headers.get("x-redlock-status") ?? "[]");
          throw new Error(body, { cause: { status: 403, headers: body } });
        } else if (hasJsonErrorResponse) {
          body = await response.json();
          body = body?.error?.message;
        } else {
          body = await response.text();
        }
        throw new Error(body || response.statusText);
      }

      const file = await response.blob();
      downloadFile({ fileName, file });

      setScriptDownloaded(true);
      onSuccess && typeof onSuccess === "function" && onSuccess(response);
    } catch (_error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = _error as Record<string, any>;
      if (onError && typeof onError === "function") {
        onError(JSON.parse(error?.message));
      } else if ("cause" in error) {
        const i18nKey = error?.cause?.headers?.[0]?.i18nKey;
        toast(
          dynamicFormatMessage(
            intl,
            statusToastMessagesMap,
            i18nKey ?? "unknown_error",
          ),
          {
            appearance: "error",
          },
        );
      } else {
        handleError({ intl, toast, error, closeModal });
      }
    } finally {
      setLoading(false);
    }
  }, [
    method,
    endPoint,
    fileName,
    onSuccess,
    payload,
    hasJsonErrorResponse,
    onError,
    intl,
    toast,
    closeModal,
  ]);

  const icon = useMemo(
    () =>
      isLoading ? (
        <LoadingIcon />
      ) : isScriptDownloaded ? (
        <CheckIcon />
      ) : showWarningIcon ? (
        <WarningIcon />
      ) : (
        <FileDownloadIcon size="md" />
      ),
    [isLoading, showWarningIcon, isScriptDownloaded],
  );
  return (
    <Button
      appearance={appearance}
      data-selector="download-terraform-script"
      icon={icon}
      onClick={onClick}
      addClassName={className}
      disabled={isDownloadDisabled}
    >
      <span className="ml-2 text-sm">
        {btnLabel || (
          <FormattedMessage
            defaultMessage="Download Terraform Script"
            id="QoX2rN"
            description="Download Terraform Script Button"
          />
        )}
      </span>
    </Button>
  );
}
