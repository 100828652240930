import { Input } from "form";
import { useIntl } from "react-intl";
import { useTrimField } from "../../../../hooks";
import { validGuid } from "../../../../utils";

export default function ApplicationId({
  onBlur,
}: {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}) {
  const intl = useIntl();

  useTrimField("clientId");

  return (
    <Input
      data-selector="clientid-input"
      name="clientId"
      label={intl.formatMessage({
        defaultMessage: "Application (Client) ID",
        id: "JzEsF1",
        description:
          "The Application (Client) ID of an input field for onboarding an Azure account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Your Application (Client) ID",
        id: "Us3lzk",
        description:
          "Placeholder for the Application (Client) ID of an input field for onboarding an Azure account",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Application (Client) ID is required",
          id: "AecDbB",
          description: "Required message for cloud account",
        }),
        validate: validGuid(intl),
      }}
    />
  );
}
