import { z } from "zod";

export const RqlConfigResultsItemSchema = z.object({
  accountId: z.string(),
  accountName: z.string(),
  allowDrillDown: z.boolean(),
  assetId: z.string(),
  cloudType: z.string(),
  createdTs: z.number(),
  deleted: z.boolean(),
  dynamicData: z.record(z.string(), z.any()).optional(),
  hasExtFindingRiskFactors: z.boolean(),
  hasExternalFinding: z.boolean(),
  hasExternalIntegration: z.boolean(),
  hasNetwork: z.boolean(),
  id: z.string(),
  insertTs: z.number(),
  name: z.string(),
  regionId: z.string(),
  regionName: z.string(),
  resourceCategory: z.string().optional(),
  resourceConfigJsonAvailable: z.boolean(),
  resourceType: z.string(),
  rrn: z.string(),
  secureStatus: z.string().optional(),
  service: z.string(),
  stateId: z.string(),
  url: z.string().optional(),
});

export const RqlConfigResultsItemsSchema = z.array(RqlConfigResultsItemSchema);

export type RqlConfigResultsItem = z.infer<typeof RqlConfigResultsItemSchema>;
