/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { homeCloudLayoutRoute } from "../home/cloud/homeCloudLayoutRoute";

const AdoptionAdvisor = lazy(() =>
  import("./AdoptionAdvisor").then((res) => ({
    default: res.AdoptionAdvisor,
  })),
);

export const adoptionAdvisorRoute = new Route({
  getParentRoute: () => homeCloudLayoutRoute,
  path: "/adoption-advisor",
  component: AdoptionAdvisor,
  validateSearch: z.object({
    modalName: z.string().optional(),
    categoryKey: z.string().optional(),
  }),
});
/* c8 ignore stop */
