import { useTokenStore, type SavedViewBase } from "requests";

export const isReadOnly = (view: SavedViewBase): boolean => {
  return view?.category !== "default" && !isOwner(view);
};

export const isOwner = (view: SavedViewBase): boolean => {
  if (view.category === "curated") return false;
  if (view.category === "default") return false;

  const email = useTokenStore.getState().token.username;

  return (
    !!view?.createdBy &&
    (typeof view.createdBy === "string"
      ? view.createdBy === email
      : view.createdBy.username === email)
  );
};

export const isSystemView = (view: SavedViewBase): boolean => {
  if (view.category === "curated") return true;
  if (view.category === "default") return true;

  return false;
};
