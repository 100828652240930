import { LoadingIcon } from "icons";
import {
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";

export interface CardBodyProps
  extends Omit<ComponentPropsWithoutRef<"div">, "className"> {
  /**
   * Add a className to the body
   */
  addClassName?: string;
  /**
   * The main content of the Card.
   */
  children: ReactNode;
  /**
   * Render a loading indicator instead of the content
   */
  isLoading?: boolean;
  /**
   * Apply overflow-auto
   */
  overflow?: boolean;
}

/**
 * Container for the main content of the Card.
 */
export function CardBody({
  children,
  addClassName,
  isLoading = false,
  overflow,
  ...passThrough
}: CardBodyProps): ReactElement {
  return (
    <div
      data-testid="card-body"
      {...passThrough}
      className={classNames(
        "w-full flex-1 p-4 text-default dark:text-dark-bg",
        overflow && "overflow-auto print:overflow-visible",
        addClassName,
      )}
    >
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <LoadingIcon size="3xl" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
