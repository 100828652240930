import { useFormContext } from "form";
import { useWizardContext } from "../../../../../components/Wizard";
import { ADD_DETAILS } from "../../../../constants";
import { getWizardStepIndex } from "../../../Onboarding/utils";
import CreateOrDownloadIAMRoleCft from "../CreateOrDownloadIAMRoleCft";
import ManagementOrMemberIAMRoleCft from "../ManagementOrMemberIAMRoleCft";

type CFTDownloadButtonGroupProps = {
  closeModal: () => void;
  setCftDownloadedOrCreated: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

export default function CFTDownloadButtonGroup({
  closeModal,
  setCftDownloadedOrCreated,
  disabled = false,
}: CFTDownloadButtonGroupProps) {
  const { watch } = useFormContext();
  const {
    state: { steps, activeIndex },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);
  const [unifiedCftDisabled, onboardType] = watch([
    "unifiedCftDisabled",
    "onboardType",
  ]);

  return (
    <>
      {onboardType === "template" &&
      activeIndex === addDetailsStepIndex ? null : (
        <>
          {unifiedCftDisabled ? (
            <ManagementOrMemberIAMRoleCft
              closeModal={closeModal}
              setCftDownloadedOrCreated={setCftDownloadedOrCreated}
              disabled={disabled}
            />
          ) : (
            <CreateOrDownloadIAMRoleCft
              closeModal={closeModal}
              setCftDownloadedOrCreated={setCftDownloadedOrCreated}
              disabled={disabled}
            />
          )}
        </>
      )}
    </>
  );
}
