import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { licensingRoute } from "../../settingsLicensingRoute";

export const LicensingOverview = lazy(() => import("./LicensingOverview"));

export const licensingOverviewRoute = new Route({
  getParentRoute: () => licensingRoute,
  path: "overview",
  component: LicensingOverview,
});
