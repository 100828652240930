import { BookIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { Link } from "ui";

export default function ConnectToAwsLink() {
  return (
    <div className="flex items-center space-x-2">
      <Link
        href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/connect/connect-cloud-accounts/onboard-aws/onboard-aws"
        external
        addClassName="flex"
      >
        <BookIcon className="mr-2 mt-1" />
        <FormattedMessage
          defaultMessage="How to connect to AWS"
          id="kbNwSE"
          description="How to connect to AWS link text"
        />
      </Link>
    </div>
  );
}
