import { useNavigate } from "@tanstack/react-router";
import { useMonitoring } from "monitoring";
import { useMemo } from "react";
import { useTokenActions } from "requests";
import {
  logoutRedirectUrlStorageKey,
  signinRedirectLocationStorageKey,
} from "stores";
import { setupSentryScope } from "../../../setupSentry";

export const useSignIn = () => {
  const navigate = useNavigate();
  const { setToken } = useTokenActions();
  const monitoring = useMonitoring();
  const redirectTo = useMemo(() => {
    let basePath = "/";
    let legacyPath = basePath;
    let search: Record<string, unknown> | string | undefined;

    const redirectLocationInStorage = localStorage.getItem(
      signinRedirectLocationStorageKey,
    );

    if (redirectLocationInStorage) {
      const {
        pathname,
        search: redirectSearch,
        hash,
      } = JSON.parse(redirectLocationInStorage);
      if (pathname !== "/auth/signin") {
        search = redirectSearch;
        legacyPath = `${pathname}${
          // if someone times out from Darwin and then logs in to a legacy tenant, Darwin will save an object for search.
          // Legacy only supports string search, so only set search for legacy if it's a string
          typeof search === "string" ? search : ""
        }${hash ? JSON.stringify(hash) : ""}`;
        basePath = pathname;
      }
    }

    return { basePath, search, legacyPath };
  }, []);

  return function signIn(token: string, isLegacyLogin = false) {
    localStorage.removeItem(signinRedirectLocationStorageKey);
    localStorage.setItem(logoutRedirectUrlStorageKey, "/login");
    setToken(token);
    monitoring.setupScope(setupSentryScope());

    if (isLegacyLogin) {
      setTimeout(() => (window.location.href = redirectTo.legacyPath), 100);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore something crazy going on here
      navigate({
        to: redirectTo.basePath,
        replace: true,
        search: redirectTo.search,
      });
    }
  };
};
