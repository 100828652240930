import { type FC } from "react";

import { useTableInstance } from "../TableContext";
import { GroupBySelect } from "./GroupBySelect";

/**
 * A Multi-Select that enables the user to set columns to group by.
 *
 * To disable gouping for a specific column pass `disableGroupBy: true` to that column.
 *
 * To customize how data is aggregated for a column, pass a custom `aggregate` function.
 *
 * To customize how aggregated cells are rendered, pass a custom `Aggregated` component.
 *
 * See [useGroupBy#column-options](https://react-table-omega.vercel.app/docs/api/useGroupBy#column-options) for details.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConnectedGroupBySelect, ConnectedTable, GroupBySelect, TableProvider } from "table";
 * ```
 */
export const ConnectedGroupBySelect: FC = () => {
  const {
    columns,
    enableRowSort,
    state: { groupBy },
    toggleGroupBy,
  } = useTableInstance();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && enableRowSort) {
    throw new Error(
      "Row sorting is not compatible with grouping. Turn off row sorting or do not render the ConnectedGroupSelect.",
    );
  }

  return (
    <GroupBySelect
      columns={columns}
      groupBy={groupBy}
      toggleGroupBy={toggleGroupBy}
    />
  );
};
