const IAM_PREFIX = "api/v1";
const PERMISSION_BASE_URL = `${IAM_PREFIX}/permission`;
const GRAPH_BASE_URL = `${PERMISSION_BASE_URL}/graph`;
export const SEARCH_PERMISSION_SUGGEST = `${IAM_PREFIX}/suggest`;
export const SEARCH_IAM_ALERT = `${PERMISSION_BASE_URL}/alert/search`;
const PERMISSION_LAST_ACCESS_BASE_URL = `${PERMISSION_BASE_URL}/access`;

export const PERMISSION_URLS = {
  getUniqueSourceToGranted: `${GRAPH_BASE_URL}/source_to_granter`,
  getUniqueGrantedToDest: `${GRAPH_BASE_URL}/granter_to_dest`,
  graphSearch: `${GRAPH_BASE_URL}/search`,
  searchV4: "iam/api/v4/search/permission",
  raw: (id: string) => `${PERMISSION_BASE_URL}/raw/${id}`,
};

export const PERMISSION_LAST_ACCESS_URLS = {
  list: PERMISSION_LAST_ACCESS_BASE_URL,
  show: `${PERMISSION_LAST_ACCESS_BASE_URL}/page`,
};
