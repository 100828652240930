import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isNil } from "remeda";
import { WarningBanner } from "../../../../components/ComputeScanLimitBanner";
import {
  AWS,
  AZURE,
  AZURE_CHINA,
  AZURE_GOV,
  CVCG,
} from "../../../../constants";
import CloudFeaturesComponent from "./CloudFeatures/CloudFeaturesComponent";

import { isChina } from "environment";
import { Card, CardBody, CardHeader } from "ui";
import {
  useGetAwsFeatureFlags,
  useGetCloudAccountsCas,
} from "../../../../hooks";
import { useGetComputeLimit } from "../../../../hooks/useGetComputeLimit";
import { useGetSupportedFeaturesList } from "../../../../hooks/useGetSupportedFeaturesList";
import { useTransformAwsPartitionType } from "../../../../hooks/useTransformAwsPartitionType";
import { type CloudType } from "../../../../types";

const sortOrderOfFeatures = [
  "compute-discovery",
  "compute-agentless",
  "compute-serverless-scan",
  "compute-auto-protect",
];

type FeaturesProps = {
  cloudType: CloudType;
  accountId: string;
};

export default function Features({ cloudType, accountId }: FeaturesProps) {
  const intl = useIntl();

  const {
    data,
    isLoading: isComputeLimitLoading,
    error: computeScanLimitError = {},
    isError: isComputeScanLimitError = false,
  } = useGetComputeLimit({
    accountId,
    options: {
      enabled: !isChina(),
      retry: false,
      refetchOnWindowFocus: false,
    },
  });
  const { limit: computeScanLimit = -1, isAccountImported = false } =
    data ?? {};

  const isComputeScanLimitAccountImported = useMemo(
    () =>
      isComputeScanLimitError &&
      (computeScanLimitError as { status: number })?.status === 404
        ? true
        : isAccountImported,
    [computeScanLimitError, isAccountImported, isComputeScanLimitError],
  );

  const {
    data: { cloudAccount, memberSyncEnabled } = {},
    isError,
    isLoading: isCloudAccountCasLoading,
  } = useGetCloudAccountsCas({
    accountId,
    cloudType,
    options: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  });
  const {
    accountType,
    deploymentType,
    features: featuresList = [],
  } = cloudAccount! ?? {};

  const { awsPartitionsEnabled, isLoading: isAwsFeatureLoading } =
    useGetAwsFeatureFlags({
      enabled: cloudType === AWS,
      retry: false,
      refetchOnWindowFocus: false,
    });

  const { awsPartition, isLoading: isAwsPartitionLoading } =
    useTransformAwsPartitionType({
      accountId,
      awsPartitionsEnabled,
      options: {
        enabled: cloudType === AWS && !isAwsFeatureLoading,
        retry: false,
        refetchOnWindowFocus: false,
      },
    });

  const azureDeploymentType = useMemo(() => {
    switch (deploymentType) {
      case "az_china":
        return AZURE_CHINA;
      case "az_gov":
        return AZURE_GOV;
      default:
        return AZURE;
    }
  }, [deploymentType]);

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType,
      payload: {
        accountType,
        ...(cloudType === AWS && { awsPartition }),
        ...(cloudType === AZURE && {
          deploymentType: azureDeploymentType,
          rootSyncEnabled: memberSyncEnabled?.toString(),
        }),
      },
      options: {
        enabled: !isCloudAccountCasLoading && !isAwsPartitionLoading,
      },
    });

  const sortedFeaturesList = [...featuresList]
    ?.sort(
      (a, b) =>
        sortOrderOfFeatures.indexOf(a.featureName) -
        sortOrderOfFeatures.indexOf(b.featureName),
    )
    .filter(({ displayName }) =>
      [...supportedFeaturesList, ...[CVCG]].includes(displayName),
    );

  const isFeatureExists = (obj: { featureName: string }) =>
    sortOrderOfFeatures.includes(obj.featureName);

  if (
    isNil(featuresList) ||
    !featuresList?.length ||
    !featuresList.some(isFeatureExists) ||
    isError
  )
    return null;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Compute Workload Protection",
          id: "138FvH",
          description: "Compute Workload Protection card header",
        })}
      />
      <CardBody
        isLoading={
          isCloudAccountCasLoading ||
          isComputeLimitLoading ||
          isFeaturesLoading ||
          isAwsPartitionLoading ||
          isAwsFeatureLoading
        }
      >
        <CloudFeaturesComponent
          accountType={accountType!}
          accountId={accountId}
          cloudType={cloudType}
          features={sortedFeaturesList}
          isAccountImported={isComputeScanLimitAccountImported}
        >
          {Number(computeScanLimit) > 0 ? <WarningBanner /> : null}
        </CloudFeaturesComponent>
      </CardBody>
    </Card>
  );
}
