import { type FC } from "react";

export const StickyBorder: FC = () => {
  return (
    <div
      style={{
        boxShadow: "-2px 0px 3px rgba(0, 0, 0, 0.1)",
        height: "100%",
        position: "absolute",
        top: 0,
        width: 3,
      }}
    />
  );
};
