import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "ui";

import { type PermissionGroupData } from "requests";
import { usePermissionGroupWizard } from "../PermissionGroupWizard/PermissionGroupWizard";

type SidecarActionsProps = {
  row: PermissionGroupData;
};

export function SidecarActions({ row }: SidecarActionsProps) {
  const { openModal: openPermissionGroupWizard } = usePermissionGroupWizard();

  const onEditPermissionGroup = useCallback(() => {
    openPermissionGroupWizard({
      record: row,
      actionType: "edit",
    });
  }, [openPermissionGroupWizard, row]);

  if (row.type === "Default") return null;

  return (
    <Button appearance="secondary" size="sm" onClick={onEditPermissionGroup}>
      <FormattedMessage
        defaultMessage="Edit"
        id="Z7Tg6R"
        description="Edit permission group button text"
      />
    </Button>
  );
}
