import { WarningIcon } from "icons";
import { Body } from "ui";

type ErrorRowPropTypes = {
  message: string;
};

export default function ErrorRow({ message }: ErrorRowPropTypes) {
  return (
    <div className="ml-2 mt-2 flex bg-yellow-100 p-4 dark:bg-transparent">
      <div className="content-start pr-4">
        <WarningIcon aria-label={message} />
      </div>
      <div className="mt-1 content-end">
        <Body size="sm">{message}</Body>
      </div>
    </div>
  );
}
