import { useNavigate, useParams } from "@tanstack/react-router";
import { LoadingIcon } from "icons";
import { Suspense, lazy, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  Sidecar,
  SidecarBody,
  SomethingWentWrongErrorPage,
  dynamicFormatMessage,
} from "ui";
import { ALIBABA_CLOUD, AWS, AZURE, GCP, IBM, OCI } from "../constants";
import { useGetCloudAccountsCas, useGetCloudAccountsRest } from "../hooks";
import { accountTypeTranslations } from "../messages/accountTypeTranslations";
import { settingsCloudAccountsRoute } from "../settingsCloudAccountsRoute";
import { type CloudType } from "../types";
import { getAzureAccountType } from "../utils";
import Actions from "./Actions";
const AlibabaSidecar = lazy(() => import("./AlibabaSidecar"));
const AwsSidecar = lazy(() => import("./AwsSidecar"));
const AzureSidecar = lazy(() => import("./AzureSidecar"));
const GcpSidecar = lazy(() => import("./GcpSidecar"));
const IbmSidecar = lazy(() => import("./IbmSidecar"));
const OciSidecar = lazy(() => import("./OciSidecar"));

import { settingsCloudAccountsSidecarRoute } from "./settingsCloudAccountsSidecarRoute";

export default function CloudAccountsSidecar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { cloudAccountId, cloudType: selectedCloudType } = useParams({
    from: settingsCloudAccountsSidecarRoute.id,
  });

  const {
    data: restAccountData,
    isError: isRestAccountError,
    isLoading: isRestAccountLoading,
    error: restAccountError,
  } = useGetCloudAccountsRest({
    accountId: cloudAccountId,
    cloudType: selectedCloudType as CloudType,
    includeGroupInfo: "true",
    options: {
      enabled: [ALIBABA_CLOUD, OCI].includes(selectedCloudType),
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  const {
    data: casAccountData,
    isError: isCasAccountError,
    isLoading: isCasAccountLoading,
    error: casAccountError,
  } = useGetCloudAccountsCas({
    accountId: cloudAccountId,
    cloudType: selectedCloudType as CloudType,
    options: {
      enabled: ![ALIBABA_CLOUD, OCI].includes(selectedCloudType),
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  const {
    accountType = "",
    enabled = true,
    name = "",
  } = [ALIBABA_CLOUD, OCI].includes(selectedCloudType)
    ? restAccountData ?? {}
    : casAccountData?.cloudAccount ?? {};
  const rootSyncEnabled = casAccountData?.memberSyncEnabled ?? false;

  let CloudTypeComponent;
  if (selectedCloudType === GCP) {
    CloudTypeComponent = GcpSidecar;
  } else if (selectedCloudType === AWS) {
    CloudTypeComponent = AwsSidecar;
  } else if (selectedCloudType === AZURE) {
    CloudTypeComponent = AzureSidecar;
  } else if (selectedCloudType === IBM) {
    CloudTypeComponent = IbmSidecar;
  } else if (selectedCloudType === ALIBABA_CLOUD) {
    CloudTypeComponent = AlibabaSidecar;
  } else if (selectedCloudType === OCI) {
    CloudTypeComponent = OciSidecar;
  }

  const accountTypeId = useMemo(
    () =>
      selectedCloudType === AZURE
        ? getAzureAccountType({ accountType, rootSyncEnabled })
        : (accountType as string),
    [accountType, rootSyncEnabled, selectedCloudType],
  );

  const isError = isRestAccountError || isCasAccountError;
  const isLoading = isRestAccountLoading || isCasAccountLoading;
  const title = isError ? "" : name;
  let subTitle = "";
  if (!isLoading && !isError) {
    subTitle = dynamicFormatMessage(
      intl,
      accountTypeTranslations,
      accountTypeId,
    );
  }
  const onClose = () =>
    navigate({
      to: settingsCloudAccountsRoute.id,
    });

  return (
    <Sidecar
      disablePinnedChange={true}
      initialPinned={false}
      isOpen={true}
      data-testid="cloud-accounts-sidecar"
      onClose={onClose}
      title={<span className="whitespace-pre-wrap">{title}</span>}
      subtitle={subTitle}
      showMask
      size="lg"
      actions={
        !isLoading &&
        !isError &&
        cloudAccountId &&
        accountType && (
          <Actions
            accountId={cloudAccountId}
            accountType={accountType}
            cloudType={selectedCloudType as CloudType}
            enabled={enabled}
            setCloseModal={onClose}
          />
        )
      }
    >
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <LoadingIcon size="3xl" />
          </div>
        }
      >
        {isLoading ? (
          <SidecarBody isLoading={isLoading} />
        ) : isError ? (
          <SidecarBody>
            <SomethingWentWrongErrorPage
              error={isRestAccountError ? restAccountError : casAccountError}
            />
          </SidecarBody>
        ) : (
          CloudTypeComponent &&
          cloudAccountId &&
          accountType && (
            <CloudTypeComponent
              accountId={cloudAccountId}
              accountType={accountType}
            />
          )
        )}
      </Suspense>
    </Sidecar>
  );
}
