import { ConnectedPaginator } from "../Paginator";
import { ConnectedResultsSummary } from "../ResultsSummary";
import { useTableInstance } from "../TableContext";
import { TableLayout } from "./TableLayout";

import { type ReactElement } from "react";
import { type TableLayoutProps } from "./TableLayout";

/**
 * Helps layout controls and pagination of a table.
 * Will handle spacing and add a border to the Table.
 */
export function ConnectedTableLayout({
  paginator = <ConnectedPaginator />,
  resultsSummary,
  overflow = false,
  ...rest
}: TableLayoutProps): ReactElement {
  const { enablePagination, isBackgroundFetching, manualPagination } =
    useTableInstance();
  const paginationEnabled = enablePagination || manualPagination;

  const results = resultsSummary ?? <ConnectedResultsSummary />;

  return (
    <TableLayout
      isBackgroundFetching={isBackgroundFetching}
      overflow={overflow}
      resultsSummary={results}
      paginator={paginationEnabled && paginator}
      {...rest}
    />
  );
}
