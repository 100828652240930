import {
  CLEAR_FTU_FIELD_ERRORS,
  GCP_LOADED_NODES,
  INIT_GCP_TREE_MAP,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  SET_IS_GCP_WIF_ENABLED,
  UPDATE_GCP_NODES,
} from "../../../../../../../constants";
import {
  clearFTUFieldErrors,
  initializeGCPSelectProjects,
  setFTUFieldErrors,
  setFTUFieldValue,
  setIsGCPWifEnabled,
  updateGCPLoadedNodes,
  updateGCPTreeMap,
} from "../setters/setters";

type Actions =
  | {
      type: "Update GCP nodes";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }
  | {
      type: "Initialize GCP tree map";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }
  | {
      type: "set_is_gcp_wif_enabled";
      payload: boolean;
    }
  | {
      type: "GCP loaded nodes";
      payload: string;
    }
  | {
      type: "clear_ftu_field_errors";
      payload: {
        field: string;
      };
    }
  | {
      type: "set_ftu_field_errors";
      payload: {
        field: string;
        errors: string[];
      };
    }
  | {
      type: "set_ftu_field_value";
      payload: {
        field: string;
        value: unknown;
      };
    };

export function gcpSelectMonitoredProjectsReducer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  action: Actions,
) {
  switch (action?.type) {
    case INIT_GCP_TREE_MAP:
      return initializeGCPSelectProjects({
        state,
        payload: action.payload,
      });
    case UPDATE_GCP_NODES:
      return updateGCPTreeMap({
        state,
        payload: action.payload,
      });
    case SET_IS_GCP_WIF_ENABLED:
      return setIsGCPWifEnabled({
        state,
        isAvailable: action.payload,
      });
    case GCP_LOADED_NODES:
      return updateGCPLoadedNodes({
        state,
        payload: action.payload,
      });
    case CLEAR_FTU_FIELD_ERRORS:
      return clearFTUFieldErrors({
        ...action.payload,
        state,
      });
    case SET_FTU_FIELD_ERRORS:
      return setFTUFieldErrors({
        ...action.payload,
        state,
      });
    case SET_FTU_FIELD_VALUE:
      return setFTUFieldValue({
        ...action.payload,
        state,
      });
    default:
      return state;
  }
}
