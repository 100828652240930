import { useQuery } from "@tanstack/react-query";
import { Select } from "form";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  getPermissionGroupListItems,
  listItemKeys,
  type PermissionGroupData,
} from "requests";

export function PermissionGroupCloneSelect() {
  const intl = useIntl();

  const { setValue } = useFormContext();

  const { data: permissionGroupsList = [], isLoading } = useQuery({
    queryKey: listItemKeys.permissionGroups(),
    queryFn: getPermissionGroupListItems,
  });

  const permissionGroupsSelectItems = useMemo(
    () =>
      permissionGroupsList.map((group: PermissionGroupData) => ({
        value: group.name,
        children: group.name,
        id: group.id,
        context: {
          features: group.features?.reduce(
            (acc, curr) => ({
              ...acc,
              [`${curr.featureName}`]: curr.operations,
            }),
            {},
          ),
        },
      })),
    [permissionGroupsList],
  );

  return (
    <Select
      name="permissionGroupsList"
      data-selector="permissionGroupsList"
      items={permissionGroupsSelectItems}
      isLoading={isLoading}
      fullWidth
      enableSearch
      onChange={({ selectedItem }) => {
        setValue("features", selectedItem?.context?.features ?? null);
      }}
      register={{
        required: false,
      }}
      label={intl.formatMessage({
        defaultMessage: "Copy Permissions from Existing Permission Group",
        id: "6dskiv",
        description: "Permission group form field label",
      })}
    />
  );
}
