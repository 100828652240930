import { defineMessages } from "react-intl";

export const messageMap = defineMessages({
  description: {
    defaultMessage:
      "Flow Logs records a sample of network flows sent from and received by VM instances.",
    id: "J4ThT2",
    description: "Description for GCP Flow Logs Card SubTitle",
  },
  title: {
    defaultMessage: "Flow Logs",
    id: "dwqFOM",
    description: "Description for GCP Flow Logs Card Title",
  },
});
