import { type ReactNode } from "react";
import { type IntlShape } from "react-intl";
import { Bold } from "ui";

export const getInstructions = ({
  intl,
  isEdit,
  isOrg,
  showCreateIAMRoleSteps,
}: {
  intl: IntlShape;
  isEdit: boolean;
  isOrg: boolean;
  showCreateIAMRoleSteps: boolean;
}) => {
  const values = {
    b: (chunks: ReactNode) => <Bold>{chunks}</Bold>,
  };

  if (showCreateIAMRoleSteps) {
    return [
      intl.formatMessage({
        defaultMessage:
          "Click on the Create IAM Role to grant access to Prisma Cloud link.",
        id: "0YpGmU",
        description: "Instructions for creating iam role cft",
      }),
      isOrg
        ? intl.formatMessage(
            {
              defaultMessage:
                "Provide the <b>OrganizationalUnitIds</b> and Click <b>Next</b>.",
              id: "LwVcG9",
              description: "Instructions for creating iam role cft",
            },
            values,
          )
        : null,
      intl.formatMessage(
        {
          defaultMessage:
            "Select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and click <b>{mode}</b> stack.",
          id: "csZ+jB",
          description: "Instructions for creating iam role cft",
        },
        {
          ...values,
          mode: isEdit
            ? intl.formatMessage({
                defaultMessage: "Update",
                id: "oj/5Yo",
                description: "description for Update label",
              })
            : intl.formatMessage({
                defaultMessage: "Create",
                id: "arqnkv",
                description: "description for Create label",
              }),
        },
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Once the <b>CREATE_COMPLETE</b> status is displayed, copy <b>PrismaCloudRoleARN</b> from the Outputs tab.",
          id: "0wUzSK",
          description: "Instructions for creating iam role cft",
        },
        values,
      ),
    ].filter(Boolean);
  }

  let steps = [
    intl.formatMessage({
      defaultMessage: "Download IAM Role CFT.",
      id: "RsO8Sh",
      description: "Instructions for downloading iam role cft",
    }),
    intl.formatMessage(
      {
        defaultMessage:
          "Login to AWS console and go to Select <b>Services > CloudFormation > Stacks</b>.",
        id: "AlN+GX",
        description: "Instructions for downloading iam role cft",
      },
      values,
    ),
  ];

  if (isEdit) {
    steps = [
      ...steps,
      intl.formatMessage(
        {
          defaultMessage:
            "Select PrismaCloudApp Stack and click <b>Update</b> Stack.",
          id: "hrrCpa",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
      intl.formatMessage({
        defaultMessage:
          "Replace the current template with the downloaded template.",
        id: "2djl2y",
        description: "Instructions for downloading iam role cft",
      }),
      isOrg
        ? intl.formatMessage(
            {
              defaultMessage:
                "Provide the <b>OrganizationalUnitIds</b> and Click <b>Next</b>.",
              id: "OCRc1T",
              description: "Instructions for downloading iam role cft",
            },
            values,
          )
        : null,
      intl.formatMessage(
        {
          defaultMessage:
            "Click <b>Next</b>, Verify the settings and preview your changes",
          id: "nIy3cb",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and click <b>Update</b> stack.",
          id: "ztofSd",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
    ];
  } else {
    steps = [
      ...steps,
      intl.formatMessage(
        {
          defaultMessage:
            "Click on <b>Create Stack</b> and select <b>With new resources</b>.",
          id: "3wuKgg",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Choose <b>Upload a template file</b> and upload the IAM Role CFT file, click <b>Next</b> and provide a Stack name of your choice.",
          id: "IdjJ2u",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
      isOrg
        ? intl.formatMessage(
            {
              defaultMessage:
                "Provide the <b>OrganizationalUnitIds</b> and Click <b>Next</b>.",
              id: "OCRc1T",
              description: "Instructions for downloading iam role cft",
            },
            values,
          )
        : null,
      intl.formatMessage(
        {
          defaultMessage:
            "Select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and click <b>Create</b> stack.",
          id: "Cvr8Ph",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
      intl.formatMessage(
        {
          defaultMessage:
            "Once the <b>CREATE_COMPLETE</b> status is displayed, copy <b>PrismaCloudRoleARN</b> from the Outputs tab.",
          id: "9K0L24",
          description: "Instructions for downloading iam role cft",
        },
        values,
      ),
    ];
  }
  return steps.filter(Boolean);
};
