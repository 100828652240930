import { type ComponentPropsWithoutRef, type ReactElement } from "react";

export function InvalidPermissionsSVG(
  props: ComponentPropsWithoutRef<"svg">,
): ReactElement {
  return (
    <svg
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="45"
        cy="45"
        r="45"
        className="fill-[#EFF8FF] dark:fill-[#354252]"
      />
      <path d="M0.467773 19L89.4789 19" stroke="#DADBDB" />
      <rect
        x="0.5"
        y="15.5"
        width="89"
        height="61"
        className="fill-white stroke-[#DADBDB] dark:fill-[#19222E] dark:stroke-[#354252]"
      />
      <path
        d="M0 19L89.0112 19"
        className="stroke-[#DADBDB] dark:stroke-[#354252]"
      />
      <path
        d="M5 37.8H20.4769"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M5 51.4H20.4769"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M5 65H20.4769"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M5 31H20.4769"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M5 44.6H20.4769"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M5 58.2H20.4769"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.2003 37.8H84.6772"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.2003 51.4H84.6772"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.2003 65H84.6772"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.2003 31H84.6772"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.2003 44.6H84.6772"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.2003 58.2H84.6772"
        className="stroke-[#CFE8FC] dark:stroke-[#D9EFF3]"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M41.3993 51.6125C41.3993 51.1334 41.5354 50.6888 41.7548 50.296C41.5441 50.2744 41.3379 50.2312 41.1184 50.2312H40.3855C39.4112 50.6715 38.3272 50.9219 37.1862 50.9219C36.0451 50.9219 34.9655 50.6715 33.9868 50.2312H33.2539C29.9975 50.2312 27.3555 52.8297 27.3555 56.0325V57.8281C27.3555 58.972 28.299 59.9 29.462 59.9H41.7899C41.5485 59.4899 41.3993 59.0238 41.3993 58.5187V51.6125ZM37.1862 48.85C40.289 48.85 42.8037 46.3767 42.8037 43.325C42.8037 40.2733 40.289 37.8 37.1862 37.8C34.0833 37.8 31.5686 40.2733 31.5686 43.325C31.5686 46.3767 34.0833 48.85 37.1862 48.85Z"
        className="fill-[#B8C1CC] dark:fill-[#88AFC0]"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7726 48.85C51.5594 48.85 50.5708 49.8287 50.5708 51.0282C50.5708 51.9749 51.1877 52.7721 52.0397 53.0727V55.3844C52.0397 55.7866 52.3687 56.1105 52.7741 56.1105C53.1795 56.1105 53.5085 55.7866 53.5085 55.3844V53.0727C54.3605 52.7721 54.9774 51.972 54.9774 51.0282C54.9774 49.8287 53.9874 48.85 52.7726 48.85Z"
        className="fill-[#85C8FF] dark:fill-[#8DD5E2]"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.8529 58.263C60.8529 58.6769 60.5224 59.0152 60.1184 59.0152H45.4312C45.0258 59.0152 44.6953 58.6769 44.6953 58.263V46.6972C44.6953 46.2833 45.0258 45.9464 45.4312 45.9464H60.1184C60.5224 45.9464 60.8529 46.2833 60.8529 46.6972V58.263ZM49.1019 42.3162C49.1019 40.1773 50.6119 38.686 52.7741 38.686C54.9363 38.686 56.4463 40.1773 56.4463 42.3162V44.4944H49.1019V42.3162ZM60.1184 44.4944H57.9151V42.3162C57.9151 39.3714 55.753 37.2339 52.7741 37.2339C49.7952 37.2339 47.6331 39.3714 47.6331 42.3162V44.4944H45.4312C44.215 44.4944 43.2264 45.4803 43.2264 46.6972V58.263C43.2264 59.4799 44.215 60.4673 45.4312 60.4673H60.1184C61.3347 60.4673 62.3218 59.4799 62.3218 58.263V46.6972C62.3218 45.4803 61.3347 44.4944 60.1184 44.4944Z"
        className="fill-[#85C8FF] dark:fill-[#8DD5E2]"
      />
    </svg>
  );
}
