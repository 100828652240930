import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";
import { classNames } from "utils";

export const AgentlessWorkloadScanningIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Agentless Workload Scanning",
    id: "3oaipv",

    description: "Security Capabilities Class Icon alt text",
  });

  // eslint-disable-next-line react/prop-types
  const { disabled, ...passThrough } = props;
  const iconClassName = classNames(
    "dark:fill-white",
    disabled ? "fill-[#DADBDB]" : "fill-[#7A7B7B]",
  );

  return (
    <SvgIcon viewBox="0 0 16 12" {...passThrough}>
      <title>{alt}</title>
      <path
        className={iconClassName}
        d="M5.375 0.0644846L9.375 1.78407C9.75 1.95603 10 2.32145 10 2.72985C10 3.92171 9.82324 4.98083 9.53011 5.9112C9.355 5.87552 9.17696 5.85714 9 5.85714C8.77345 5.85714 8.515 5.88945 8.28283 5.93584C8.23674 5.88931 8.18926 5.84395 8.14036 5.79987C8.44314 4.95625 8.6258 4.00653 8.64583 2.96629L5 1.39717V5.1333C3.80433 5.45632 2.79747 6.34548 2.55538 7.57043C2.20537 7.76414 1.88991 8.01975 1.63603 8.32666C0.671739 6.90265 0 5.00472 0 2.72985C0 2.32145 0.229167 1.95603 0.604167 1.78407L4.60417 0.0644846C4.70833 0.0214949 4.875 0 5 0C5.10417 0 5.27083 0.0214949 5.375 0.0644846Z"
      />
      <path
        className={iconClassName}
        d="M9.17139 6.86547C8.19563 9.08184 6.55818 10.4084 5.375 10.9194C5.125 11.0269 4.85417 11.0269 4.60417 10.9194C3.80671 10.5734 2.99059 9.97426 2.27122 9.1516C2.53473 8.7354 2.97182 8.41169 3.5 8.25V8.14286C3.5 7.26373 4.11686 6.50866 5 6.17815V9.58671C6.06091 9.03353 7.03438 8.07431 7.70833 6.78672C7.78949 6.88191 7.86558 6.9837 7.9375 7.09091C8.175 6.95698 8.69375 6.85714 9 6.85714C9.05793 6.85714 9.11512 6.85997 9.17139 6.86547Z"
      />
      <path
        className={iconClassName}
        d="M10.4 8.60893C10.4625 8.46429 10.5 8.30893 10.5 8.14286C10.5 7.43036 9.825 6.85714 9 6.85714C8.69375 6.85714 8.175 6.95698 7.9375 7.09091C7.50625 6.44805 6.925 6 6 6C4.61875 6 3.5 6.95893 3.5 8.14286C3.5 8.18036 3.5 8.2125 3.5 8.25C2.625 8.51786 2 9.23036 2 10.0714C2 11.1375 3.00625 12 4.25 12H10C11.1063 12 12 11.2339 12 10.2857C12 9.45536 11.3125 8.76429 10.4 8.60893Z"
      />
    </SvgIcon>
  );
};
