import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const ComputeIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Compute",
    id: "UUHAAv",

    description: "Asset Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <title>{alt}</title>
      <path d="M10.6667 5.33333H5.33333V10.6667H10.6667V5.33333ZM8.8889 8.8889H7.1111V7.1111H8.8889V8.8889ZM16 7.1111V5.33333H14.2222V3.55556C14.2222 2.57778 13.4222 1.77778 12.4444 1.77778H10.6667V0H8.8889V1.77778H7.1111V0H5.33333V1.77778H3.55556C2.57778 1.77778 1.77778 2.57778 1.77778 3.55556V5.33333H0V7.1111H1.77778V8.8889H0V10.6667H1.77778V12.4444C1.77778 13.4222 2.57778 14.2222 3.55556 14.2222H5.33333V16H7.1111V14.2222H8.8889V16H10.6667V14.2222H12.4444C13.4222 14.2222 14.2222 13.4222 14.2222 12.4444V10.6667H16V8.8889H14.2222V7.1111H16ZM12.4444 12.4444H3.55556V3.55556H12.4444V12.4444Z" />
    </SvgIcon>
  );
};
