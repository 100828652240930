import { useQueryClient, type QueryClient } from "@tanstack/react-query";
import {
  copilotFeedback,
  copilotKeys,
  copilotPrompt,
  type CopilotPromptRequest,
  type CopilotPromptResponse,
} from "requests";
import { v4 as uuidV4 } from "uuid";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export const paddingForFullExpansion = 480;

export type UserMessage = {
  id: string;
  type: "user";
  content: string;
  display?: string;
  createdAt: Date;
};

export type CopilotMessage = {
  id: string;
  type: "copilot";
  createdAt: Date;
  rating?: number;
  comment?: string;
  suggestions?: string[];
  response: CopilotPromptResponse;
};

export type ErrorMessage = {
  id: string;
  type: "error";
  createdAt: Date;
};

export type AddMessageBody = {
  /* The message from the user which will be sent to the API */
  text: string;
  /* An optional message to display as the sent message. Useful if you want to display something different than what was sent to the API */
  display?: string;
  requestType?: CopilotPromptRequest["requestType"];
  /* Whether a new conversation should be started */
  startNewConversation?: boolean;
  /* Provides a hint of the intent of the user message to the backend */
  intentHint?: string;
  /* Provides the parent message Id to backend for alert accordion when clicking on message follow up prompt */
  parentMessageId?: string;
};

export type Conversation = {
  id: string;
  /** The backend ID for this conversation */
  apiId?: CopilotPromptResponse["conversationId"];
  /** If the converstaion currently has a pending request */
  pending: boolean;
  /** The context from previous messages to continue on the next message */
  context?: CopilotPromptResponse["currentContext"];
  messages: {
    [uuid: UserMessage["id"]]: UserMessage | CopilotMessage | ErrorMessage;
  };
  name: string;
};

type CurrentView = "home" | "history" | "conversation";

type State = {
  isOpen: boolean;
  width: number;
  /** The UUID of the active conversation */
  currentConversationId?: Conversation["id"];
  apiConversationId?: string;
  conversations: { [uuid: string]: Conversation };
  currentView: CurrentView;
};

export type Actions = {
  toggleOpen: () => void;
  saveBackendHistoryMessages: (
    messages: CopilotPromptResponse[],
    conversation: string,
  ) => void;
  setCurrentView: (currentView: CurrentView) => void;
  setWidth: (width: number) => void;
  updateConversationDetails: (
    conversationId: string,
    updatedConversationDetails: Partial<Conversation>,
  ) => void;
  clearConversation: () => void;
  setViewAsHistoryAndConversationDetails: (
    conversationId: Conversation["id"],
    conversationName: Conversation["name"],
  ) => void;
  addMessage: (
    message: AddMessageBody & {
      queryClient?: QueryClient;
    },
  ) => void;
  addCopilotMessage: (response: CopilotPromptResponse) => void;
  rateMessage: (rating: {
    id: CopilotMessage["id"];
    rating: Exclude<CopilotMessage["rating"], undefined>;
    comment?: CopilotMessage["comment"];
    suggestions?: CopilotMessage["suggestions"];
  }) => void;
};

function newConversation(messageContent: string): Conversation {
  const uuid = uuidV4();
  return { id: uuid, pending: false, messages: {}, name: messageContent };
}

function newUserMessage(content: string, display?: string): UserMessage {
  const uuid = uuidV4();
  return { id: uuid, type: "user", content, display, createdAt: new Date() };
}

function newCopilotMessage(response: CopilotPromptResponse): CopilotMessage {
  const uuid = uuidV4();
  return { id: uuid, type: "copilot", createdAt: new Date(), response };
}
function newErrorMessage(): ErrorMessage {
  const uuid = uuidV4();
  return { id: uuid, type: "error", createdAt: new Date() };
}

export const useCopilotStore = create(
  persist(
    immer<State & Actions>((set, get) => {
      return {
        isOpen: false,
        width: window.innerWidth - paddingForFullExpansion,
        conversations: {},
        toggleOpen: () =>
          set((state) => {
            state.isOpen = !state.isOpen;
          }),
        currentView: "home",
        setCurrentView: (currentView: CurrentView) =>
          set((state) => {
            state.currentView = currentView;
          }),
        setWidth: (width: number) => {
          set((state) => {
            state.width = width;
          });
        },
        updateConversationDetails: (
          conversationId: string,
          updatedConversationDetails: Partial<Conversation>,
        ) => {
          set((state) => {
            const currentConversationDetails =
              state.conversations[conversationId];
            state.conversations[conversationId] = {
              ...currentConversationDetails,
              ...updatedConversationDetails,
            };
          });
        },
        clearConversation: () => {
          set((state) => {
            state.currentConversationId = undefined;
            state.conversations = {};
            state.apiConversationId = undefined;
          });
        },
        setViewAsHistoryAndConversationDetails: (
          conversationId: Conversation["id"],
          conversationName: Conversation["name"],
        ) => {
          set((state) => {
            state.currentView = "history";
            state.currentConversationId = conversationId;
            if (!state.conversations[conversationId]) {
              const conversation = {
                id: conversationId,
                pending: false,
                messages: {} as {
                  [key: string]: UserMessage | CopilotMessage;
                },
                name: conversationName,
              };
              state.conversations[conversationId] = conversation;
            }
          });
        },
        saveBackendHistoryMessages: (
          messagesFromHistoryApi: CopilotPromptResponse[],
          conversationId: string,
        ) => {
          set((state) => {
            const conversation = state.conversations[conversationId];
            const messagesFromHistory = {} as {
              [key: string]: UserMessage | CopilotMessage;
            };
            messagesFromHistoryApi.forEach((message) => {
              if (message.author === "You") {
                const { messageId, text, responseTs } = message;
                messagesFromHistory[messageId] = {
                  id: messageId,
                  type: "user",
                  content: text,
                  createdAt: responseTs ? new Date(responseTs) : undefined,
                } as UserMessage;
              }

              const SUPPORT_TICKET_FORMATS = ["SUPPORT_TICKET_FORM"];

              if (message.author === "Prisma Cloud Copilot") {
                const { messageId, responseTs, format } = message;

                const historyMessage = SUPPORT_TICKET_FORMATS.includes(format)
                  ? {
                      text: "This message is related to Support Ticket creation.",
                    }
                  : message;
                messagesFromHistory[messageId] = {
                  id: messageId,
                  type: "copilot",
                  response: historyMessage,
                  createdAt: responseTs ? new Date(responseTs) : undefined,
                } as CopilotMessage;
              }
            });
            state.conversations[conversationId] = {
              ...conversation,
              messages: messagesFromHistory,
            };
          });
        },
        addMessage: async ({
          text,
          display,
          requestType,
          startNewConversation = false,
          intentHint,
          queryClient,
          parentMessageId,
        }) => {
          set((state) => {
            const conversation = getCurrentConversation(state);
            state.isOpen = true;
            state.currentView = "conversation";
            if (!startNewConversation && conversation) {
              const message = newUserMessage(text, display);
              conversation.messages[message.id] = message;
              conversation.pending = true;
            } else {
              const conv = newConversation(text);
              const message = newUserMessage(text, display);

              conv.messages[message.id] = message;
              conv.pending = true;
              state.conversations[conv.id] = conv;
              state.currentConversationId = conv.id;
            }
          });

          const currentConversation = getCurrentConversation(get());
          const currentApiConversationId = get().apiConversationId;
          try {
            const response = await copilotPrompt({
              requestText: text,
              requestType,
              conversationId:
                currentConversation?.apiId ?? currentApiConversationId,
              context: {
                currentContext: currentConversation?.context,
                requestType,
                intentHint,
                ...(parentMessageId && { parentMessageId }),
              },
            });

            // Automation caught this issue. Right after creating a new conversation,
            // recent history is not invalidated, hence it only updates when the cache reaches stale time.
            // To improve UX, I am invalidating history cache upon new conversation started.
            if (startNewConversation || !currentConversation?.apiId) {
              if (queryClient)
                queryClient.invalidateQueries({
                  queryKey: copilotKeys.history(),
                });
            }

            set((state) => {
              if (currentConversation) {
                state.apiConversationId = response.conversationId;
                const conversation =
                  state.conversations[
                    currentConversation.id ?? state.currentConversationId
                  ];
                if (conversation) {
                  conversation.pending = false;
                  conversation.apiId = response.conversationId;
                  conversation.context = response.currentContext;
                  const message = newCopilotMessage(response);
                  conversation.messages[message.id] = message;
                }
              }
            });
          } catch (e) {
            set((state) => {
              if (currentConversation) {
                const conversation =
                  state.conversations[currentConversation.id];
                conversation.pending = false;
                const message = newErrorMessage();
                conversation.messages[message.id] = message;
              }
            });
          }
        },
        addCopilotMessage: (response: CopilotPromptResponse) => {
          const currentConversation = getCurrentConversation(get());

          try {
            set((state) => {
              if (currentConversation) {
                const conversation =
                  state.conversations[currentConversation.id];
                conversation.pending = false;
                conversation.apiId = response.conversationId;
                conversation.context = response.currentContext;
                const message = newCopilotMessage(response);
                conversation.messages[message.id] = message;
              }
            });
          } catch (e) {
            set((state) => {
              if (currentConversation) {
                const conversation =
                  state.conversations[currentConversation.id];
                conversation.pending = false;
                const message = newErrorMessage();
                conversation.messages[message.id] = message;
              }
            });
          }
        },
        rateMessage: async ({ id, rating, comment, suggestions }) => {
          set((state) => {
            const conversation = getCurrentConversation(state);

            if (!conversation) return;

            const message = conversation.messages[id];

            if (!message || message.type !== "copilot") return;

            message.rating = rating;
            message.comment ??= comment;
            message.suggestions ??= suggestions;
          });

          const currentConversation = getCurrentConversation(get());
          const message = currentConversation?.messages[id];
          if (!message || message.type !== "copilot") return;

          await copilotFeedback({
            messageId: message.response.messageId,
            responseValidity: rating,
            comments: comment || message.comment || "",
            suggestions: suggestions || message.suggestions,
          });
        },
      };
    }),
    {
      name: "copilot",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["conversations"].includes(key),
          ),
        ),
    },
  ),
);

function getCurrentConversation(state: State) {
  const id = state.currentConversationId;

  if (id) return state.conversations[id];

  return;
}

/**
 * This function is a wrapper over the addMessage zustand action in order to use
 * hooks such as useFlags and useQueryClient.
 *
 * @returns the copilot add message action.
 */
export const useAddMessage = () => {
  const queryClient = useQueryClient();
  return ({
    text,
    display,
    requestType,
    startNewConversation = false,
    intentHint,
    parentMessageId,
  }: AddMessageBody) =>
    useCopilotStore.getState().addMessage({
      text,
      display,
      requestType,
      startNewConversation,
      intentHint,
      queryClient,
      parentMessageId,
    });
};
