import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const CircleCiIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "CircleCi",
    id: "Dwf/Pt",

    description: "provider icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 26 26" {...props}>
      <title>{alt}</title>
      <path
        d="M12.1441 15.2077C13.7223 15.2077 15.0017 13.9283 15.0017 12.3501C15.0017 10.772 13.7223 9.49261 12.1441 9.49261C10.566 9.49261 9.28662 10.772 9.28662 12.3501C9.28662 13.9283 10.566 15.2077 12.1441 15.2077Z"
        className="fill-black dark:fill-white"
      />
      <path
        d="M12.143 0.350685C6.55145 0.350685 1.85381 4.17536 0.521351 9.3503C0.508907 9.39675 0.502229 9.44456 0.501465 9.49265C0.501465 9.80666 0.756862 10.0642 1.07297 10.0642H5.91191C6.02144 10.0645 6.12871 10.0331 6.2207 9.9736C6.31268 9.91414 6.38541 9.82923 6.43003 9.7292C6.43003 9.7292 6.43736 9.7156 6.4405 9.70827C7.43801 7.55624 9.61621 6.06258 12.1451 6.06258C15.617 6.06258 18.4316 8.87614 18.4316 12.3481C18.4316 15.82 15.618 18.6336 12.1461 18.6336C9.61831 18.6336 7.4401 17.1399 6.44154 14.9879C6.4384 14.9805 6.43107 14.9669 6.43107 14.9669C6.33896 14.7639 6.1359 14.632 5.91295 14.632H1.07401C0.757908 14.632 0.502511 14.8874 0.502511 15.2035C0.503055 15.2516 0.509737 15.2994 0.522398 15.3458C1.85486 20.5208 6.55354 24.3455 12.144 24.3455C18.7718 24.3444 24.154 18.9727 24.154 12.346C24.154 5.71926 18.7812 0.346497 12.1545 0.346497L12.143 0.350685Z"
        className="fill-black dark:fill-white"
      />
    </SvgIcon>
  );
};
