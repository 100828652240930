/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { privateRootRoute } from "../../privateRootRoute";

const FirstTimeExperience = lazy(() => import("./FirstTimeExperience"));

export const ftuExperienceRoute = new Route({
  getParentRoute: () => privateRootRoute,
  path: "/ftuExperience",
  component: FirstTimeExperience,
});
/* c8 ignore stop */
