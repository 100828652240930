import { forwardRef } from "react";
import { InputCard, type InputCardProps } from "../../components/InputCard";
import { RadioInput } from "./RadioInput";

import { type ComponentPropsWithRef } from "react";
export type RadioInputCardProps = Omit<
  ComponentPropsWithRef<"input">,
  "className" | "type" | "enterKeyHint"
> &
  Pick<
    InputCardProps,
    "title" | "description" | "icon" | "addClassName" | "tag"
  >;

/**
 * A styled radio to look like a card
 */
export const RadioInputCard = forwardRef<HTMLInputElement, RadioInputCardProps>(
  (
    {
      title,
      description,
      icon,
      addClassName,
      tag,
      ...props
    }: RadioInputCardProps,
    ref,
  ) => {
    return (
      <>
        <InputCard
          title={title}
          description={description}
          icon={icon}
          disabled={props.disabled}
          selected={props.checked || props.defaultChecked}
          addClassName={addClassName}
          tag={tag}
          input={<RadioInput {...props} ref={ref} />}
        />
      </>
    );
  },
);

RadioInputCard.displayName = "RadioInputCard";
