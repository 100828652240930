import { useMemo, type ReactElement } from "react";
import { Field, Select } from "ui";
import { v4 as uuidV4 } from "uuid";

import { type ColumnInstance, type Data } from "../../types";

export interface SelectColumnsProps<D extends Data> {
  button: ReactElement;
  columns: ColumnInstance<D>[];
  label: string;
  onSelectColumn: (columnId: string) => void;
  selectedColumnIds: string[];
  showLabel?: boolean;
}

export function SelectColumns<D extends Data>({
  button,
  columns,
  label,
  onSelectColumn,
  selectedColumnIds,
  showLabel,
}: SelectColumnsProps<D>): ReactElement {
  const labelId = useMemo((): string => `table-select-${uuidV4()}-label`, []);
  const buttonId = useMemo((): string => `table-select-${uuidV4()}-button`, []);
  const items = useMemo(
    () =>
      columns.map((c) => ({
        value: c.id,
        children: c.render("Header"),
      })),
    [columns],
  );

  const selectedItems = useMemo(
    () =>
      items.filter((item) => selectedColumnIds.some((s) => s === item.value)),
    [items, selectedColumnIds],
  );

  return (
    <Field
      label={label}
      labelId={labelId}
      showLabel={showLabel}
      control={
        <Select
          labelId={labelId}
          toggleButtonId={buttonId}
          enableMultiSelect
          button={button}
          items={items}
          menuStyle={{ maxWidth: "20rem" }}
          onChange={({ selectedItem }) => {
            selectedItem && onSelectColumn(selectedItem.value as string);
          }}
          selectedItem={selectedItems}
        />
      }
    />
  );
}
