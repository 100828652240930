import { flip, limitShift, offset, shift } from "@floating-ui/react";
import { useSelect, type UseSelectStateChange } from "downshift";
import {
  cloneElement,
  useEffect,
  useMemo,
  useState,
  type CSSProperties,
  type KeyboardEvent,
  type ReactElement,
  type Ref,
} from "react";
import { type UseInfiniteScrollHookArgs } from "requests";
import { classNames, noop } from "utils";
import { v4 as uuidV4 } from "uuid";
import { type SelectItem, type SelectListProps } from ".";
import { mergeRefs, useFloating, type Placement } from "../../utils";
import { Button } from "../Button";
import { listDefaultMinWidth } from "../List";
import { Portal } from "../Portal";
import { SelectList, type ListWidthType } from "./SelectList";
import { useSelectAll } from "./useSelectAll";
import {
  getSelectAll,
  isDomElement,
  onSelectedItemChange,
  selectedItemLabel,
  sortBySelected,
} from "./utils";

export type SelectChanges = Partial<UseSelectStateChange<SelectItem>> & {
  selectedItems: SelectItem[];
  selectedItem?: SelectItem;
};

export type SelectWithoutSearchProps = {
  /**
   * A list of items for the user to select from.
   */
  items?: SelectItem[];
  /**
   * A custom button element. This button must accept onMouseUp and onKeyDown props.
   * If the button's type is 'button' it will receive an isOpen prop.
   */
  button?: ReactElement;
  buttonRef?: Ref<HTMLElement>;
  /**
   * When inside a modal, apply focus to the select once the modal mounts.
   */
  ["data-autofocus"]?: boolean;
  /**
   * Placement of the menu relative to the button anchor element
   */
  defaultPlacement?: Placement;
  /**
   * Disables selection
   */
  disabled?: boolean;
  /**
   * Enable multiple selection.
   */
  enableMultiSelect?: boolean;
  /**
   * Enable an option to select all items. **MultiSelect must be enabled**.
   */
  enableSelectAll?: boolean;
  /**
   * When using the default button, pass `fullWidth` to fill the available width. This is common in forms.
   */
  fullWidth?: boolean;
  /**
   * Set to true to add a loading icon if the items are still pending.
   */
  isLoading?: boolean;
  /**
   * Use to pass what the label ID is. This will be used to set the appropriate aria attributes
   * on the button and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `toggleButtonId`.
   *
   * Be sure to also set `toggleButtonId`.
   */
  labelId?: string;
  /**
   * Use to pass what the button ID should be. This will be used to set the appropriate aria attributes
   * on the button and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `toggleButtonId`.
   *
   * Be sure to also set `labelID`.
   */
  toggleButtonId?: string;
  /**
   * Apply styles to the menu.
   *
   * Useful for overriding the default width and height.
   */
  menuStyle?: CSSProperties;
  /**
   * When using dark mode on a subset of the page, pass the appropriate value here.
   * This is a local override of the global theme and is **only** necessary when a
   * section of the page has a different theme than the global.
   *
   * Wrapping the select in a div with the dark class does not work because the menu
   * is in a portal and rendered outside the DOM structure of the trigger so it will
   * not be a child of the div with the dark class.
   */
  darkMode?: boolean;
  /**
   * Invoked on changes to the selection, providing an individual selected item and all of the selected items.
   */
  onChange?: (changes: SelectChanges) => void;
  /**
   * Invoked when the user presses enter on a menu item. If searching is off it will also be invoked when pressing space.
   */
  onMenuItemKeyEnter?: (
    event: KeyboardEvent<HTMLElement>,
    activeItem: SelectItem,
  ) => void;

  /**
   * The selected item(s).
   *
   * Currently, this component only supports the [controlled](https://reactjs.org/docs/forms.html#controlled-components) pattern.
   */
  selectedItem?: SelectItem | SelectItem[] | null;
  dataSelector?: string;
  footer?: SelectListProps["footer"];
  /**
   * Allows displaying option to clear selected items in the dropdown
   */
  allowClear?: boolean;
  /**
   * This is to add placeholder text in the Select if no items are present.
   */
  placeholder?: string;
  isOpen?: boolean;
  customListItem?: {
    listHeight?: string;
    listWidth?: ListWidthType;
    itemHeight?: number;
  };
  onClose?: () => void;
  selectorRef?: Ref<HTMLElement>;
  /**
   * Use to pass infinite scroll options.
   */
  infiniteScroll?: UseInfiniteScrollHookArgs;
  isSelectAllDisabled?: boolean;
  /**
   * Use to pass the total count of items. Useful when you use infinite scroll.
   * This number will be used instead of `items.length` inside SelectAll component.
   */
  totalCount?: number;
  /**
   * Extra classnames to the container of the trigger button
   */
  addClassName?: string;
};

const emptyItems: SelectItem[] = [];

/**
 * This component is a `Select` but does not handle searching in the Select at all.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { SelectWithoutSearch } from "ui";
 * ```
 */
export function SelectWithoutSearch({
  buttonRef,
  items = emptyItems,
  button,
  "data-autofocus": dataAutofocus,
  disabled,
  defaultPlacement = "bottom-start",
  enableSelectAll = false,
  fullWidth = false,
  isLoading = false,
  labelId,
  toggleButtonId,
  darkMode,
  menuStyle = {},
  enableMultiSelect = false,
  onChange = noop,
  onClose = noop,
  onMenuItemKeyEnter,
  selectedItem,
  dataSelector,
  footer,
  allowClear = false,
  placeholder = "",
  isOpen: willOpenOrCloseMenu = false,
  customListItem,
  selectorRef = null,
  infiniteScroll,
  isSelectAllDisabled,
  totalCount,
  addClassName,
}: SelectWithoutSearchProps): ReactElement {
  const baseId = useMemo((): string => `field-${uuidV4()}-label`, []);
  const selectedItems = useMemo(
    () => (selectedItem ? [selectedItem].flat() : []),
    [selectedItem],
  );
  const selectedValues = useMemo(
    () => selectedItems.map((item) => item?.value).filter(Boolean),
    [selectedItems],
  );
  const { showSelectAll, selectAllItem, allSelected } = useSelectAll({
    items,
    enableSelectAll,
    selectedValues,
    enableMultiSelect,
    totalCount,
  });
  const [selectMenuStyle, setSelectMenuStyle] = useState(menuStyle);
  const sortedItems = useMemo(
    () =>
      enableMultiSelect
        ? [...items].sort(sortBySelected(selectedValues))
        : items,
    [enableMultiSelect, items, selectedValues],
  );

  const normalizedItems = useMemo(() => {
    return enableSelectAll && showSelectAll
      ? [selectAllItem, ...sortedItems]
      : sortedItems;
  }, [enableSelectAll, selectAllItem, showSelectAll, sortedItems]);

  const {
    closeMenu,
    isOpen,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    getToggleButtonProps,
    openMenu,
  } = useSelect({
    items: normalizedItems,
    labelId,
    id: baseId,
    toggleButtonId,
    itemToString(item: SelectItem | null): string {
      return item ? String(item.value) : "";
    },
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            // onSelectedItemChange is only called when selectedItem changes.
            //   It is not called when selected and re-selected without selecting another item first.
            //   Add a prop so the item always changes so onSelectedItemChange will be called to
            //   update the state.
            // I believe this may be a bug in downshift 7.0.  It's worth checking on upgrades if
            // overriding selectedItem can be removed.
            selectedItem: changes?.selectedItem?.value
              ? { ...(changes.selectedItem as SelectItem), ensureChange: true }
              : (changes.selectedItem as SelectItem),
            isOpen: enableMultiSelect, // keep menu open after selection.
          };
        default:
          // remove active state from Select/Deselect All except key down events
          if (
            enableSelectAll &&
            changes.highlightedIndex === 0 &&
            type !== useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown &&
            type !== useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp
          ) {
            return {
              ...changes,
              highlightedIndex: -1,
            };
          }

          return changes;
      }
    },
    onSelectedItemChange: (changes) => {
      // This can happen when a footer is clicked
      if (changes.selectedItem?.value === undefined) return;

      onSelectedItemChange({
        changes,
        enableMultiSelect,
        selectedItems,
        onChange,
        selectedValues,
        selectAllItem,
        allSelected,
        itemsForSelectAll: items,
      });
    },
    onStateChange: ({ type, isOpen }) => {
      if (type === useSelect.stateChangeTypes.ToggleButtonClick && isOpen) {
        const buttonWidth = referenceRef?.current?.offsetWidth;

        /* c8 ignore next */
        if (buttonWidth && buttonWidth > listDefaultMinWidth) {
          setSelectMenuStyle({ minWidth: `${buttonWidth}px`, ...menuStyle });
        }
      }
      referenceRef?.current?.focus();
    },
    onIsOpenChange: ({ isOpen }) => {
      !isOpen && onClose();
    },
  });

  const handleSelectAll = (action: string) => {
    onChange?.(
      getSelectAll({
        action,
        selectAllItem,
        selectedItems,
        items,
        selectedValues,
      }),
    );
  };

  useEffect(() => {
    willOpenOrCloseMenu ? openMenu() : closeMenu();
  }, [closeMenu, openMenu, willOpenOrCloseMenu]);

  const {
    domReferenceRef: referenceRef,
    setReference,
    setFloating,
    floatingStyles,
  } = useFloating({
    placement: defaultPlacement,
    strategy: "fixed",
    middleware: [offset(4), flip(), shift({ limiter: limitShift() })],
  });

  const memoizedButtonRef = useMemo(() => {
    if (buttonRef) {
      return mergeRefs([buttonRef, setReference]);
    } else {
      return setReference;
    }
  }, [buttonRef, setReference]);

  const memoizedContainerRef = useMemo(
    () => mergeRefs([setFloating, selectorRef]),
    [setFloating, selectorRef],
  );

  const trigger = useMemo(() => {
    const addProps = isDomElement(button?.type.toString()) ? {} : { isOpen }; // This allows the flippy caret to work when a button is passed in.
    const DefaultButton = (
      <Button
        data-autofocus={dataAutofocus}
        data-testid="select-trigger"
        fullWidth={fullWidth}
        isMenu
        isOpen={isOpen}
      >
        {!selectedItem ? placeholder : selectedItemLabel(selectedItem)}
      </Button>
    );

    return cloneElement(
      button || DefaultButton,
      getToggleButtonProps({
        ...addProps,
        ...(button?.props ?? {}),
        ref: memoizedButtonRef,
        disabled,
        onKeyDown: (event) => {
          if (
            onMenuItemKeyEnter &&
            (event.key === "Enter" || event.key === " ")
          ) {
            onMenuItemKeyEnter(
              event as React.KeyboardEvent<HTMLElement>,
              items[highlightedIndex],
            );
          }
          if (event.key === "Escape") {
            // Prevent the escape action from cascading, so that it only
            // closes this select and not a Popover or Modal that it may
            // be inside of.
            event.preventDefault();
          }
        },
        ...(button?.props["aria-label"] && { "aria-labelledby": undefined }), // Don't add orphaned aria-labelledby if there is an aria-label on the button already.
      }),
    );
  }, [
    dataAutofocus,
    fullWidth,
    isOpen,
    selectedItem,
    placeholder,
    button,
    getToggleButtonProps,
    memoizedButtonRef,
    disabled,
    onMenuItemKeyEnter,
    items,
    highlightedIndex,
  ]);

  return (
    <div className={classNames(darkMode && "dark", addClassName)}>
      {trigger}
      <Portal>
        <div
          className={classNames("z-30", darkMode && "dark")}
          ref={memoizedContainerRef}
          style={floatingStyles}
        >
          <SelectList
            isLoading={isLoading}
            isOpen={isOpen}
            menuStyle={selectMenuStyle}
            clearSelection={(event) => {
              getMenuProps()?.onBlur?.(event);
              onChange?.({
                selectedItem: undefined,
                selectedItems: [],
              });
            }}
            allowClear={allowClear}
            getMenuProps={getMenuProps}
            handleSelectAll={handleSelectAll}
            items={normalizedItems}
            highlightedIndex={highlightedIndex}
            selectedItems={selectedItems}
            selectAllItem={selectAllItem}
            enableMultiSelect={enableMultiSelect}
            getItemProps={getItemProps}
            showSelectAll={showSelectAll}
            allSelected={allSelected}
            selectedValues={selectedValues}
            dataSelector={dataSelector}
            footer={footer}
            customListItem={customListItem}
            infiniteScroll={infiniteScroll}
            isSelectAllDisabled={isSelectAllDisabled}
          />
        </div>
      </Portal>
    </div>
  );
}
