import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Card, CardHeader } from "ui";
import { PermissionGroupCardBody } from "../PermissionGroupCardBody";
import { type PermissionGroupMap } from "../PermissionGroupsSidecar";

type DataSecurityPostureManagementCardsProps = {
  featureMap: PermissionGroupMap;
};

export const dataSecurityPostureManagementFeatures = [
  "dataSecurityPostureManagement",
] as const;

export function DataSecurityPostureManagementCard({
  featureMap,
}: DataSecurityPostureManagementCardsProps) {
  const intl = useIntl();

  const features = useMemo(
    () =>
      dataSecurityPostureManagementFeatures
        .filter((key) => !!featureMap[key])
        .map((key) => featureMap[key]),
    [featureMap],
  );

  if (!features.length) return null;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Data Security Posture Management",
          id: "a189sO",
          description: "Permission Group sidecar card header title",
        })}
      />
      <PermissionGroupCardBody features={features} />
    </Card>
  );
}
