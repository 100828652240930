import { useQuery } from "@tanstack/react-query";
import { awsFeatureStatus, featureStatuskeys } from "requests";

type UseGetAwsFeatureFlagsProps = Record<string, string | boolean>;

export const useGetAwsFeatureFlags = (
  options: UseGetAwsFeatureFlagsProps = {},
) => {
  const { data, error, isError, isLoading, isFetching } = useQuery({
    queryKey: featureStatuskeys.aws(),
    queryFn: awsFeatureStatus,
    ...options,
  });

  const {
    is_aws_event_bridge_enabled: isAwsEventBridgeEnabled = false,
    aws_audit_logs_s3_read_write_onboarding_enabled:
      isS3ReadWriteAuditLogsEnabled = false,
    aws_flow_logs_s3_feature_enabled: isS3FlowLogsEnabled = false,
    awsPartitionsEnabled = [],
  } = data ?? {};

  return {
    isAwsEventBridgeEnabled,
    isS3ReadWriteAuditLogsEnabled,
    isS3FlowLogsEnabled,
    awsPartitionsEnabled,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
};
