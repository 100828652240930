import { baseApiUrl, getLogoutUrl, parseToken, useTokenStore } from "requests";
import { defaultParseSearch } from "router";
import { logoutRedirectUrlStorageKey } from "stores";
import { decompressCookie, getCookie, removeCookie } from "./cookieUtils";

export const PAN_SSO_KEY = "panSSO";
export const SSO_TOS_ACCEPTED = "ssoTosAccepted";

async function getLogoutUrlFromAPI() {
  const response = await getLogoutUrl();

  if (response.logoutRedirectUrl) {
    localStorage.setItem(
      logoutRedirectUrlStorageKey,
      response.logoutRedirectUrl,
    );
  } else {
    localStorage.setItem(logoutRedirectUrlStorageKey, "/login");
  }
}

const compressedCookies = [
  "x-pan-auth-accesstoken",
  "x-pan-auth-pingcert",
  "x-pan-auth-supportaccountids",
];

/**
 * Processes SSO logins,
 *
 * When someone logs in from SSO they will first come to our app with an sa & sn query param.
 * We redirect them to the backend for processing, and then they get sent back with cookies.
 * From here we convert the cookies to localstorage so they are logged in.
 *
 * We return a boolean on whether or not to proceed with rendering the app. On that initial
 * redirect we want to make sure the app does not load and do any redirects of its own that
 * would cancel the SSO login.
 */
export function processSSO() {
  const cookieToken = getCookie("x-redlock-auth");

  if (!cookieToken) {
    return signInFromSSO();
  }

  convertCookiesToLocalStorage(cookieToken);

  setLogoutUrl(cookieToken);

  // Signin redirect post sso login happens in Layout.tsx
  return true;
}

function signInFromSSO() {
  const params = defaultParseSearch(window.location.search) as {
    sa?: string;
    sn?: string;
  };

  const isSigningInViaSSO = params.sa && params.sn;

  if (!isSigningInViaSSO) return true;

  window.location.assign(`${baseApiUrl()}/panw/sso${window.location.search}`);
  return false;
}

function convertCookiesToLocalStorage(cookieToken: string) {
  removeCookie("x-redlock-auth");

  useTokenStore.getState().actions.setToken(cookieToken);

  // API URL is handled by the token now
  removeCookie("x-prisma-api-url");

  const tos = getCookie("tosAccepted");
  if (tos) {
    localStorage.setItem(SSO_TOS_ACCEPTED, tos);
    removeCookie("tosAccepted");
  }

  const panSSO: Record<string, unknown> = {};
  [
    "x-pan-auth-accesstoken",
    "x-pan-account",
    "x-pan-auth-email",
    "x-pan-auth-firstname",
    "x-pan-auth-lastname",
    "x-pan-auth-notonorafter",
    "x-pan-auth-pingcert",
    "x-pan-serial",
    "x-pan-auth-supportaccountids",
  ].map((name) => {
    const cookieValue = getCookie(name);

    if (cookieValue) {
      if (compressedCookies.includes(name)) {
        panSSO[name] = decompressCookie(cookieValue);
      } else {
        panSSO[name] = cookieValue;
      }
      removeCookie(name);
    }
  });
  localStorage.setItem(PAN_SSO_KEY, JSON.stringify(panSSO));
}

function setLogoutUrl(cookieToken: string) {
  const token = parseToken(cookieToken);

  if (token.panSSO && token.appPortalUrl) {
    localStorage.setItem(logoutRedirectUrlStorageKey, token.appPortalUrl);
  } else {
    getLogoutUrlFromAPI();
  }
}
