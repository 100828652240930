import { type cloudTypesWithOther } from "requests";
import {
  AlibabaIcon,
  AwsIcon,
  AzureIcon,
  GcpIcon,
  IbmIcon,
  OciIcon,
  OtherIcon,
} from "../../icons";
import { type SvgIconProps } from "../SvgIcon";

export interface CloudTypeIconProps extends Omit<SvgIconProps, "src"> {
  type: Exclude<(typeof cloudTypesWithOther)[number], "all">;
}

/**
 * Icons for cloud service providers.
 *
 * Cloud-specific icons are available as well as a generic icon that accepts a type and renders the appropriate cloud icon.
 *
 * ### Import Guide - Cloud-specific
 *
 * ```jsx
 * import { AlibabaIcon, AwsIcon, AzureIcon, GcpIcon, OciIcon } from '@prismacloud/react-ui';
 * ```
 *
 * ### Import Guide - Generic
 *
 * ```jsx
 * import { CloudTypeIcon } from '@prismacloud/react-ui';
 * ```
 */
export const CloudTypeIcon = (props: CloudTypeIconProps) => {
  const { type, ...passThrough } = props;
  switch (type) {
    case "alibaba":
    case "alibaba_cloud":
      return <AlibabaIcon {...passThrough} />;
    case "aws":
      return <AwsIcon {...passThrough} />;
    case "azure":
      return <AzureIcon {...passThrough} />;
    case "gcp":
      return <GcpIcon {...passThrough} />;
    case "ibm":
      return <IbmIcon {...passThrough} />;
    case "oci":
      return <OciIcon {...passThrough} />;
    case "other":
      return <OtherIcon {...passThrough} />;
    /* c8 ignore next */
    /* The clause catches non-typescript errors  */
    default:
      if (process.env.NODE_ENV === "development") {
        throw new Error(`Unexpected cloud type: ${type}`);
      }
      return null;
  }
};
