import { z } from "zod";
import {
  PaginatedResponseSchema,
  ServerPaginatedTableParamsSchema,
} from "../../schemas";

export const ContainerImagesFilterSchema = z
  .object({
    hasVulnerabilities: z.boolean(),
    hasRunningContainers: z.boolean(),
    scanPassed: z.boolean(),
    stage: z.string(),
  })
  .partial();

export const ContainerImagesRequestSchema =
  ServerPaginatedTableParamsSchema.merge(ContainerImagesFilterSchema);

export const ContainerImagesResponseSchema = PaginatedResponseSchema(
  z.object({
    uaiID: z.string(),
    base: z.boolean(),
    name: z.string(),
    relatedImages: z.number(),
    runningContainers: z.number(),
    scanPassed: z.boolean(),
    stages: z.object({
      build: z.number(),
      deploy: z.number(),
      run: z.number(),
    }),
    vulnFunnel: z.object({
      exploitable: z.number(),
      patchable: z.number(),
      total: z.number(),
      urgent: z.number(),
    }),
  }),
);
