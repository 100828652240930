import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  invalid_json_file: {
    defaultMessage:
      "The uploaded file does not meet the .json requirements, please upload the correct file.",
    id: "+yNUdC",
    description:
      "Error message for Invalid JSON file uploaded in Configure Account page",
  },
  required_json_file: {
    defaultMessage: "Please upload a JSON file before proceeding.",
    id: "2/Ka/b",
    description: "Error message for mandatory field",
  },
});

export const browseOrUpdateMap = defineMessages({
  browse: {
    defaultMessage: "Browse File",
    id: "9TTyhB",
    description: "A description to display on the JSON file upload field.",
  },
  update: {
    defaultMessage: "Update File",
    id: "v9Mpw5",
    description: "A description to display on the JSON file upload field.",
  },
});

export const jsonFileKeys = defineMessages({
  project_id: {
    defaultMessage: "Project ID",
    id: "U2ACXq",
    description: "Label for Project ID",
  },
  private_key_id: {
    defaultMessage: "Private Key ID",
    id: "7Qs3nY",
    description: "Label for Private Key ID",
  },
  client_email: {
    defaultMessage: "Client Email",
    id: "cgoSpH",
    description: "Label for Client Email",
  },
  client_id: {
    defaultMessage: "Client ID",
    id: "tGPzWj",
    description: "Label for Client ID",
  },
  service_account: {
    defaultMessage: "Service Account",
    id: "cO+4U4",
    description: "Label for Credential Service Account",
  },
  wif_pool_location: {
    defaultMessage: "WIF Pool Location",
    id: "z42Dmz",
    description: "Label for Credential WIF Pool Location",
  },
  environment_id: {
    defaultMessage: "Environment ID",
    id: "ek+JtD",
    description: "Title for Credential Environment ID",
  },
});

export const jsonFileUploadMap = defineMessages({
  dragOrDrop: {
    defaultMessage: "Drag and drop file here or",
    id: "gNjZSH",
    description: "A description to display on the JSON file upload fielsd.",
  },
  uploadJsonFileHeader: {
    defaultMessage:
      "Upload your <b>{fileType} (JSON) file</b>, review the Configurations.",
    id: "AYruSw",
    description:
      "heading label for for Upload your JSON file, review the Configurations.",
  },
});

export const selectAuthenticationTypeMap = defineMessages({
  heading: {
    defaultMessage: "Authentication Mode",
    id: "HMIB0Q",
    description: "heading Label for Authentication Mode",
  },
  serviceAccountKey: {
    defaultMessage: "Service Account Key",
    id: "xU5uYG",
    description: "description for Service Account Key",
  },
  workloadIdentityFederation: {
    defaultMessage: "Workload Identity Federation",
    id: "O/xOuh",
    description: "description for Workload Identity Federation",
  },
  tooltip: {
    defaultMessage:
      "Determines how Prisma Cloud authenticates itself with GCP.",
    id: "rOZM0D",
    description: "Tooltip text for the label select auth type",
  },
  delete: {
    defaultMessage: "Delete",
    id: "sWkpbM",
    description: "Delete File uploaded",
  },
  fileDetailsHeader: {
    defaultMessage: "Uploaded file details for {fileType} (JSON) file",
    id: "saTiz4",
    description: "description for uploaded credentials",
  },
});
