import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../SvgIcon";

export const LowSeverityIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Low",
    id: "hYDdpC",

    description: "severity icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} {...props} viewBox="0 0 20 20" fill="none">
      {showNativeTooltip && <title>{alt}</title>}
      <circle
        cx="2.5"
        cy="10"
        r="2"
        className="fill-[#FFD745] dark:fill-[#FFD745]"
      />
      <circle
        cx="7.5"
        cy="10"
        r="1.5"
        className="stroke-[#B8B8B8] dark:stroke-[#707070]"
      />
      <circle
        cx="12.5"
        cy="10"
        r="1.5"
        className="stroke-[#B8B8B8] dark:stroke-[#707070]"
      />
      <circle
        cx="17.5"
        cy="10"
        r="1.5"
        className="stroke-[#B8B8B8] dark:stroke-[#707070]"
      />
    </SvgIcon>
  );
};
