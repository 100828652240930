import { forwardRef, useMemo } from "react";
import { get, useFormContext, type RegisterOptions } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  Field,
  Input as ReactInput,
  mergeRefs,
  type FieldProps,
  type InputProps as ReactInputProps,
} from "ui";

import { requiredMessage } from "../../messages";

export type InputProps = {
  /**
   * The name the entered value will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
} & Omit<FieldProps, "error" | "control"> &
  ReactInputProps;

/**
 * Renders an Input connected to the form. All extra props will get passed to the Input.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, userRef) => {
    const {
      name,
      label,
      register: registerOptions = {},
      description,
      tooltip,
      disabled,
      showLabel,
      ...rest
    } = props;
    const intl = useIntl();
    const context = useFormContext();

    /* c8 ignore next */
    if (process.env.NODE_ENV === "development" && context === null) {
      throw new Error(
        "Attempted to render Input outside of a Form context. Make sure your component is rendered inside <Form>.",
      );
    }
    const { formState, register } = context;
    registerOptions.required =
      registerOptions.required ??
      intl.formatMessage(requiredMessage, {
        label,
      });
    const errorMessage = get(formState.errors, name)?.message;
    const { onBlur, onChange, ref, ...formRegister } = register(
      name,
      registerOptions,
    );

    const inputRef = useMemo(() => mergeRefs([userRef, ref]), [userRef, ref]);

    return (
      <Field
        label={label}
        optional={!registerOptions.required}
        control={
          <ReactInput
            appearance={errorMessage ? "error" : "default"}
            disabled={disabled}
            {...formRegister}
            {...rest}
            onBlur={(event) => {
              onBlur(event);
              rest.onBlur?.(event);
            }}
            onChange={(event) => {
              onChange(event);
              rest.onChange?.(event);
            }}
            ref={inputRef}
          />
        }
        error={errorMessage}
        description={description}
        tooltip={tooltip}
        disabled={disabled}
        showLabel={showLabel}
      />
    );
  },
);

Input.displayName = "Input";
