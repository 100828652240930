import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../hooks";
import { validGuid } from "../../../../utils";

export default function TenantId({
  isEdit,
  onBlur,
}: {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  isEdit: boolean;
}) {
  const intl = useIntl();

  useTrimField("tenantId");

  return (
    <Input
      data-selector="tenantid-input"
      name="tenantId"
      readOnly={isEdit}
      label={intl.formatMessage({
        defaultMessage: "Directory (Tenant) ID",
        id: "XeyOJ9",
        description:
          "The Tenant id of an input field for onboarding an Azure account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Your Azure Tenant ID",
        id: "eSFBl/",
        description:
          "Placeholder for the Tenant id of an input field for onboarding an Azure account",
      })}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Azure Tenant ID is required",
          id: "VTJr7P",
          description: "Required message for cloud account",
        }),
        validate: validGuid(intl),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "Retrieve Azure Directory Tenant ID from Azure Portal > Azure Tenant ID > Properties. Copy and paste Tenant ID information.",
        id: "TFNkfX",
        description: "Tooltip for retrieve Azure TenantID for onboarding",
      })}
    />
  );
}
