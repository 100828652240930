import { defineMessages } from "react-intl";

export const featuresMessageMap = defineMessages({
  agentlessScan: {
    defaultMessage: "Agentless Workload Scanning",
    id: "iNAQTa",
    description: "Card title for Agentless Workload Scanning",
  },
  agentlessScan_desc: {
    defaultMessage:
      "Scan hosts & containers for vulnerabilities & compliance risks without deploying agents.",
    id: "TMYWJd",
    description: "Card subtitle for Agentless Workload Scanning",
  },
  agentlessScan_tooltip_content: {
    defaultMessage:
      "Additional permissions are required to initiate container and instance scanning.",
    id: "7W8t58",
    description: "Tooltip text for Agentless Workload Scanning",
  },
  agentlessScan_btn: {
    defaultMessage: "Enable Agentless Workload Scanning",
    id: "VWnl9x",
    description: "Button text for Agentless Workload Scanning",
  },
  autoProtect: {
    defaultMessage: "Agent-Based Workload Protection",
    id: "+vR2Ig",
    description: "Card title for Agent based Workload Protection",
  },
  autoProtect_desc: {
    defaultMessage:
      "Enables permissions for Host & Serverless Defender deployments, registry scans, and K8S audit.",
    id: "gWqI0p",
    description: "Card subtitle for Agent based Workload Protection",
  },
  autoProtect_btn: {
    defaultMessage: "Enable Agent-based Workload Protection",
    id: "zrZ5yp",
    description: "Button text for Agent based Workload Protection",
  },
  autoProtect_tooltip_content: {
    defaultMessage:
      "Enabling this feature grants additional permissions to enable Workload Protection.",
    id: "jp3QFh",
    description: "Tooltip text for Agent based Workload Protection",
  },
  autoProtect_learn_more: {
    defaultMessage: "Learn More",
    id: "dor2k5",
    description:
      "Button text of Link for documentation about Agent based Workload Protection",
  },
  dataSecurity: {
    defaultMessage: "Data Security",
    id: "GoM22Z",
    description: "Card title for Remediation",
  },
  dataSecurity_desc: {
    defaultMessage:
      "Detect sensitive data, publicly exposed assets and malware.",
    id: "DUrV9S",
    description: "Card subtitle for Remediation",
  },
  dataSecurity_tooltip_content: {
    defaultMessage:
      "Enabling this feature grants additional permissions to initiate scanning of ObjectStore.",
    id: "54Znmp",
    description: "Tooltip text for Data Security",
  },
  identitySecurity: {
    defaultMessage: "Identity Security",
    id: "iW+uk6",
    description: "Card title for Identity Security",
  },
  identitySecurity_desc: {
    defaultMessage: "Calculate net effective permissions for identities.",
    id: "2sAJwp",
    description: "Card subtitle for Identity Security",
  },
  identitySecurity_tooltip_content: {
    defaultMessage:
      "Onboarding your cloud account grants the permissions required to evaluate identities and manage access.",
    id: "91Kz30",
    description: "Tooltip text for Identity Security",
  },
  misconfigurations: {
    defaultMessage: "Misconfigurations (CSPM)",
    id: "0dliID",
    description: "Card title for Misconfigurations",
  },
  misconfigurations_desc: {
    defaultMessage: "Detect misconfigurations, and check compliance.",
    id: "ojNHhd",
    description: "Card subtitle for Misconfigurations",
  },
  misconfigurations_tooltip_content: {
    defaultMessage:
      "Onboarding your cloud account grants the permissions required to scan cloud resources and ingest metadata.",
    id: "4duhBm",
    description: "Tooltip text for Misconfigurations (CSPM)",
  },
  remediation: {
    defaultMessage: "Remediation",
    id: "a94PUM",
    description: "Card title for Remediation",
  },
  remediation_desc: {
    defaultMessage: "Remediate misconfigured resources.",
    id: "Lczlg3",
    description: "Card subtitle for Remediation",
  },
  remediation_tooltip_content: {
    defaultMessage:
      "Enabling this feature grants permissions to remediate misconfigured resources.",
    id: "7nWxAq",
    description: "Tooltip text for Remediation",
  },
  serverlessScan: {
    defaultMessage: "Serverless Function Scanning",
    id: "RI4tqE",
    description: "Card title for Serverless Function Scanning",
  },
  serverlessScan_desc: {
    defaultMessage:
      "Scan serverless functions for vulnerabilities & compliance risks without deploying agents.",
    id: "OIlF0R",
    description: "Card subtitle for Serverless Function Scanning",
  },
  serverlessScan_tooltip_content: {
    defaultMessage:
      "Enabling this feature grants additional permissions to scan Serverless Functions.",
    id: "wXiGH3",
    description: "Tooltip text for Serverless Function Scanning",
  },
  serverlessScan_btn: {
    defaultMessage: "Enable Serverless Function Scanning",
    id: "ZdJ+wx",
    description: "Button text for Serverless Function Scanning",
  },
  apiDiscovery_btn: {
    defaultMessage: "Enable Agentless API Discovery",
    id: "vESpdN",
    description: "Button text for Agentless API discovery Scanning",
  },
  threatDetection: {
    defaultMessage: "Threat Detection",
    id: "4Mc1WZ",
    description: "Card title for Threat Detection",
  },
  threatDetection_desc: {
    defaultMessage: "Detect Network and user threats.",
    id: "6lrC03",
    description: "Card subtitle for Threat Detection",
  },
  threatDetection_tooltip_content: {
    defaultMessage:
      "Onboarding your cloud account grants the permissions required to detect Network and Identity threats.",
    id: "7WS6+D",
    description: "Tooltip text for Serverless Function Scanning",
  },
  threatDetection_aws_desc: {
    defaultMessage: "Detect DNS, network and user threats.",
    id: "W/8hcA",
    description: "Card subtitle for Threat Detection",
  },
  threatDetection_aws_tooltip_content: {
    defaultMessage:
      "Onboarding your cloud account grants the permissions required to detect DNS, Network and Identity threats.",
    id: "H+7xsb",
    description: "Tooltip text for Serverless Function Scanning",
  },
  workloadDiscovery: {
    defaultMessage: "Workload Discovery",
    id: "u/I/kp",
    description: "Card title for Workload Discovery",
  },
  workloadDiscovery_desc: {
    defaultMessage:
      "Discover all compute workloads in your account and build serverless radar.",
    id: "B/4NKi",
    description: "Card subtitle for Workload Discovery",
  },
  agentlessApiDiscovery: {
    defaultMessage: "Agentless API Discovery",
    id: "flbSW6",
    description: "Card title for API Discovery",
  },
  agentlessApiDiscovery_desc: {
    defaultMessage: "Discover and protect Web Application & APIs",
    id: "FiYg29",
    description: "Card subtitle for API Discovery",
  },
  agentlessApiDiscovery_tooltip_content: {
    defaultMessage:
      "Additional permissions are required in order to initiate API discovery scans",
    id: "QRDUpB",
    description: "Tooltip text for API Discovery",
  },
  enabled_message: {
    defaultMessage: "Permissions granted, and Enabled",
    id: "0WxrAW",
    description: "Card subtitle for enabled features",
  },
});
