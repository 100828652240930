import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { dataSettingsLayoutRoute } from "../dataSettingsLayoutRoute";

const SnippetMasking = lazy(() => import("./SnippetMasking"));

export const snippetMaskingLayoutRoute = new Route({
  getParentRoute: () => dataSettingsLayoutRoute,
  path: "snippetMasking",
  component: SnippetMasking,
});
