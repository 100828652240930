import { useTableInstance } from "../../components/TableContext";

import { type Data } from "../../types";

/**
 * This hook will return the data for rows that have been selected.
 */
export function useSelectedRowData<D extends Data>(): D[] {
  const tableInstance = useTableInstance<D>();
  const selectedRows = Object.keys(tableInstance.state.selectedRowIds);
  const tableData = tableInstance.data ?? [];

  return selectedRows.map((index) => tableData[Number(index)]);
}
