import { useIntl } from "react-intl";
import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const ScanSameAccountIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Scan in your environment",
    id: "geJe++",
    description: "Scan in your environment mode icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} viewBox="0 0 44 44" {...props}>
      {showNativeTooltip && <title>{alt}</title>}
      <rect
        x="0.5"
        width="40"
        height="40"
        rx="3.86364"
        fill="black"
        fillOpacity="0.08"
      />
      <path
        d="M28.218 16.9237L20.8314 11.6478C20.6338 11.5067 20.3685 11.5067 20.1709 11.6478L12.7848 16.9237C12.6355 17.0305 12.5469 17.2027 12.5469 17.3862V27.8893C12.5469 28.203 12.8013 28.4574 13.1151 28.4574H27.8878C28.2016 28.4574 28.456 28.203 28.456 27.8893V17.3862C28.456 17.2027 28.3673 17.0305 28.218 16.9237ZM22.3954 25.7111C22.3954 25.9727 22.1834 26.1847 21.9219 26.1847H19.081C18.8195 26.1847 18.6075 25.9728 18.6075 25.7111V22.3968C18.6075 21.3507 19.4554 20.5029 20.5014 20.5029C21.5474 20.5029 22.3954 21.3507 22.3954 22.3968V25.7111Z"
        fill="#707070"
      />
    </SvgIcon>
  );
};
