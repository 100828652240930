/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../../inventoryLayoutRoute";

export const AssetInventoryCloudAccountServiceName = lazy(
  () => import("./AssetInventoryCloudAccountServiceName"),
);

export const assetInventoryCloudAccountServiceNameRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "account/$accountId/service/$serviceName/cloud/$cloud",
  component: AssetInventoryCloudAccountServiceName,
});
/* c8 ignore start */
