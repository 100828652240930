import { FormLayout } from "form";
import { FormattedMessage, useIntl } from "react-intl";
import {
  PrevButton,
  WizardForm,
  type WizardStepState,
} from "../../../../../components/Wizard";
import { useAWSSelectMemberAccountsContext } from "../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext";
import { handleSubmitAWSOrgUnitSelection } from "../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext/state/actions";
import { getAwsOUSelectProjectsIsLoading } from "../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext/state/getters";
import CFTDownloadButtonGroup from "../../components/CFTDownloadButtonGroup";
import ConnectToAwsLink from "../../components/ConnectToAwsLink";
import SaveAndNextButton from "../../components/SaveAndNextButton";
import AccountDetails from "./AccountDetails";
import SecurityCapabilitiesAndPermissions from "./SecurityCapabilitiesAndPermissions";

type CustomConfigurationProps = {
  context: {
    closeModal: () => void;
    customConfigurationStep: WizardStepState[];
    setCftDownloadedOrCreated: React.Dispatch<React.SetStateAction<boolean>>;
    isCftDownloadedOrCreated: boolean;
    fromFreemium: boolean;
  };
};

export default function CustomConfiguration({
  context: {
    closeModal: closeOnboardingWizard,
    setCftDownloadedOrCreated,
    isCftDownloadedOrCreated,
    fromFreemium,
  },
}: CustomConfigurationProps) {
  const intl = useIntl();
  const {
    state: selectMemberAccountsState,
    dispatch: selectMemberAccountsDispatch,
  } = useAWSSelectMemberAccountsContext();

  const isSelectAccountsLoading = getAwsOUSelectProjectsIsLoading(
    selectMemberAccountsState,
  );

  const onNextBtnClick = () => {
    return handleSubmitAWSOrgUnitSelection(
      intl,
      selectMemberAccountsState,
      selectMemberAccountsDispatch,
    );
  };

  const disabled = isSelectAccountsLoading || !isCftDownloadedOrCreated;

  return (
    <WizardForm
      activeStepTitle={
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <div className="flex flex-col">
              <FormattedMessage
                defaultMessage="Custom Configuration"
                id="CacJTC"
                description="Title for Custom Configuration Step"
              />
            </div>
          </div>
          <ConnectToAwsLink />
        </div>
      }
      actions={
        <>
          <PrevButton />
          <CFTDownloadButtonGroup
            closeModal={closeOnboardingWizard}
            setCftDownloadedOrCreated={setCftDownloadedOrCreated}
          />
          <SaveAndNextButton
            disabled={disabled}
            validateStep={onNextBtnClick}
            closeModal={closeOnboardingWizard}
          />
        </>
      }
    >
      <FormLayout>
        <AccountDetails />
        <SecurityCapabilitiesAndPermissions
          fromFreemium={fromFreemium}
          closeModal={closeOnboardingWizard}
          disabled={disabled}
        />
      </FormLayout>
    </WizardForm>
  );
}
