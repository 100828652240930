import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  titleize,
} from "ui";
import { AZURE, GCP, ORGANIZATION, TENANT } from "../../../../../constants";
import { type CloudType } from "../../../../../types";
import { Details } from "../Details";
import Table from "./Table";

type AccountGroupsType = {
  accountId: string;
  accountType: string;
  accountGroups: string[];
  accountGroupCreationMode?: string;
  accountGroupMode?: string;
  cloudType: CloudType;
};

export default function AccountGroups({
  accountId,
  accountType,
  accountGroups,
  accountGroupCreationMode = "",
  accountGroupMode = "",
  cloudType,
}: AccountGroupsType) {
  const { azureAutoMap } = useFlags();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Details
        label={intl.formatMessage({
          defaultMessage: "Account Groups",
          id: "jHTCJP",
          description: "Label for Account Groups",
        })}
      >
        <div className="-ml-2 flex">
          <Button appearance="clear" onClick={() => setIsOpen(true)} size="sm">
            {intl.formatMessage(
              {
                defaultMessage: "{count} Account {label} Selected",
                id: "RyshgG",
                description:
                  "Description for selected Account Group with count",
              },
              {
                count: accountGroups?.length,
                label: accountGroups?.length > 1 ? "Groups" : "Group",
              },
            )}
          </Button>

          {cloudType === GCP && accountType === ORGANIZATION && (
            <span className="ml-1 mt-1">
              (
              {intl.formatMessage({
                defaultMessage: "Creation Mode",
                id: "q7BMID",
                description: "Label for Creation Mode",
              })}
              : {titleize(accountGroupCreationMode.toLowerCase())})
            </span>
          )}
          {azureAutoMap && cloudType === AZURE && accountType === TENANT && (
            <span className="ml-1 mt-1">
              {intl.formatMessage(
                {
                  defaultMessage: "Creation Mode ({accountGroupMode})",
                  id: "DeUB76",
                  description: "Label for Creation Mode",
                },
                { accountGroupMode },
              )}
            </span>
          )}
        </div>
      </Details>
      <Modal onClose={closeModal} isOpen={isOpen} size="md">
        <ModalHeader
          enableClose
          title={
            <FormattedMessage
              defaultMessage="Account Groups"
              id="jHTCJP"
              description="Label for Account Groups"
            />
          }
        />
        <ModalBody>
          <Table accountId={accountId} cloudType={cloudType} />
        </ModalBody>
        <ModalFooter>
          <Button appearance="primary" onClick={closeModal}>
            <FormattedMessage
              defaultMessage="Done"
              id="iGJo9G"
              description="label for Done"
            />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
