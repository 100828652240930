import { FormattedMessage, useIntl } from "react-intl";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../../components";

const defendFeatures = {
  vulnerabilities: [
    {
      featureName: "computePolicyCodeRepos",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyContainers",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyHosts",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyServerless",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyComplianceCustomRules",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  runtime: [
    {
      featureName: "computePolicyRuntimeContainer",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyRuntimeHosts",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyRuntimeServerless",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  waas: [
    {
      featureName: "computePolicyWAAS",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  cnnf: [
    {
      featureName: "computePolicyCNNF",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  access: [
    {
      featureName: "computePolicyAccessDocker",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyAccessSecrets",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
    {
      featureName: "computePolicyAccessKubernetes",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
  custom: [
    {
      featureName: "computePolicyCustomRules",
      operations: {
        UPDATE: false,
        READ: false,
      },
    },
  ],
};

export function DefendTab() {
  const intl = useIntl();
  const { vulnerabilities, runtime, waas, cnnf, access, custom } =
    defendFeatures as {
      vulnerabilities: Features[];
      runtime: Features[];
      waas: Features[];
      cnnf: Features[];
      access: Features[];
      custom: Features[];
    };

  const { selectionStatus, onClickSelectAll } = useOperationSelectAll([
    ...vulnerabilities,
    ...runtime,
    ...waas,
    ...cnnf,
    ...access,
    ...custom,
  ]);

  return (
    <div className="divide-y divide-primary">
      <Row
        label={intl.formatMessage({
          defaultMessage: "Select All",
          id: "aYkfGO",
          description: "Select all checkbox row",
        })}
        addClassName="border-b-2"
        read={
          <SelectAllCheckbox
            status={selectionStatus.read}
            onChange={() => {
              onClickSelectAll("READ");
            }}
          >
            <FormattedMessage
              defaultMessage="Select view for all compute defend rows"
              id="k/Hb2U"
              description="Permission group compute defend section header"
            />
          </SelectAllCheckbox>
        }
        update={
          <SelectAllCheckbox
            status={selectionStatus.update}
            onChange={() => {
              onClickSelectAll("UPDATE");
            }}
          >
            <FormattedMessage
              defaultMessage="Select update for all compute defend rows"
              id="dv2j6E"
              description="Permission group compute defend section header"
            />
          </SelectAllCheckbox>
        }
      />

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Vulnerabilities &amp; Compliance Policies"
          id="FPTiF2"
          description="Permission group section header"
        />
      </div>
      {vulnerabilities.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Runtime Policies"
          id="9+ZtnI"
          description="Permission group section header"
        />
      </div>
      {runtime.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="WAAS Policies"
          id="yJV7z6"
          description="Permission group section header"
        />
      </div>
      {waas.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="CNNF Policies"
          id="0Y7Y1j"
          description="Permission group section header"
        />
      </div>
      {cnnf.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Access Policies"
          id="repgdh"
          description="Permission group section header"
        />
      </div>
      {access.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}

      <div className="bg-gray-100 px-4 py-1 dark:bg-blue-steel-830">
        <FormattedMessage
          defaultMessage="Custom Rules"
          id="e39HaG"
          description="Permission group section header"
        />
      </div>
      {custom.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}
    </div>
  );
}
