import { useIntl } from "react-intl";

import { Select } from "form";
import { Body } from "ui";
import { useGetAwsPartitionItems } from "../../../../../hooks";

export default function DeploymentType() {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Deployment Type",
    id: "f4w5cf",
    description: "Description for deployment type label",
  });

  const { awsPartitionItems = [] } = useGetAwsPartitionItems();

  return (
    <section className="space-y-2">
      <Body size="md" appearance="secondary" addClassName="mb-1 font-bold">
        {title}
      </Body>
      <Select
        simpleFormat
        name="partitionType"
        showLabel={false}
        label={title}
        items={awsPartitionItems}
      />
    </section>
  );
}
