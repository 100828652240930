import { fromJS } from "immutable";
import { useReducer, type ReactNode } from "react";

import { createCtx } from "ui";

import {
  azureSelectMonitoredProjectInitialState,
  type AzureSelectMonitoredProjectInitialStateType,
} from "./state/initialState";
import { azureSelectMonitoredProjectReducer } from "./state/reducer";

type UseAzureSelectMonitoredProjectContextType = {
  state: AzureSelectMonitoredProjectInitialStateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
};

const [useAzureSelectMonitoredProjectContext, CtxProvider] =
  createCtx<UseAzureSelectMonitoredProjectContextType>();

export { useAzureSelectMonitoredProjectContext };

interface AzureSelectMonitoredProjectContextProps {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any;
}

export function AzureSelectMonitoredProjectContextProvider({
  children,
  initialState,
}: AzureSelectMonitoredProjectContextProps) {
  const [state, dispatch] = useReducer(
    azureSelectMonitoredProjectReducer,
    fromJS(azureSelectMonitoredProjectInitialState).merge(initialState),
  );

  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>;
}
