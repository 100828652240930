import {
  NextButton,
  PrevButton,
  WizardForm,
} from "../../../../../components/Wizard";

import { FormLayout } from "form";
import { ProductDocumentationLink } from "../../components";
import AccountDetails from "./AccountDetails";

type ConfigureAccountProps = {
  context: { isEdit: boolean; accountId: string; closeModal: () => void };
};

export default function ConfigureAccount({
  context: { isEdit, accountId, closeModal },
}: ConfigureAccountProps) {
  return (
    <WizardForm
      addClassName="[&>div:nth-child(2)]:relative"
      actions={
        <>
          <PrevButton />
          <NextButton />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <AccountDetails
          isEdit={isEdit}
          accountId={accountId}
          closeModal={closeModal}
        />
      </FormLayout>
    </WizardForm>
  );
}
