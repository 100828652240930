import { type ReactElement, type ReactNode } from "react";
import { Body, Link, Tooltip } from "ui";

import { useIntl } from "react-intl";

export interface ItemValueProps {
  item: ReactNode;
  value: string[];
  itemsToDisplay?: number;
}

export function ItemValue({
  item,
  value,
  itemsToDisplay = 5,
}: ItemValueProps): ReactElement {
  const intl = useIntl();

  const showTooltip = value?.length > itemsToDisplay;
  const [mainData, tooltipData] = showTooltip
    ? [value?.slice(0, itemsToDisplay), value?.slice(itemsToDisplay)]
    : [value, []];

  return (
    <>
      <Body appearance="primary" size="sm">
        {item}
      </Body>
      <div className="flex gap-1">
        <Body appearance="secondary" size="sm">
          {mainData.join(", ")}
        </Body>
        {showTooltip && (
          <Tooltip
            label={
              <div className="flex flex-col space-y-1">
                {tooltipData?.map((type, index) => (
                  <Body
                    appearance="secondary"
                    size="sm"
                    key={index}
                    addClassName="text-xs"
                  >
                    {type}
                  </Body>
                ))}
              </div>
            }
          >
            <span className="-mt-1.5 ml-1">
              <Link size="sm">
                {intl.formatMessage(
                  {
                    defaultMessage: "+{len} more",
                    id: "h3kPrr",
                    description:
                      "Info about how many values are present in tooltip",
                  },
                  {
                    len: tooltipData.length,
                  },
                )}
              </Link>
            </span>
          </Tooltip>
        )}
      </div>
    </>
  );
}
