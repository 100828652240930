import { cloudAccountsKeys, getSupportedFeatures } from "requests";
import {
  IDENTITY_SECURITY,
  THREAT_DETECTION,
  WORKSPACE_DOMAIN_PAYLOAD,
} from "../constants";
import { type AccountType, type CloudType } from "../types";

import { useQuery } from "@tanstack/react-query";
import { isChina } from "environment";
import { useMemo } from "react";

type UseGetSupportedFeaturesListProps = {
  cloudType: CloudType;
  options?: Record<string, string | boolean>;
  payload: {
    accountType: AccountType;
    awsPartition?: string;
    deploymentType?: string;
  };
};

export function useGetSupportedFeaturesList({
  cloudType,
  options = {},
  payload,
}: UseGetSupportedFeaturesListProps) {
  const {
    data: {
      supportedFeatures: supportedFeaturesList = [],
      licenseType = "ENTERPRISE",
      accountType,
    } = {},
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: cloudAccountsKeys.supportedFeatures({ cloudType, payload }),
    queryFn: getSupportedFeatures,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 30000,
    ...options,
  });

  const supportedFeatures = useMemo(() => {
    const features = [...supportedFeaturesList];
    if (
      features.includes("Cloud Visibility Compliance and Governance") &&
      licenseType === "ENTERPRISE" &&
      accountType !== WORKSPACE_DOMAIN_PAYLOAD
    ) {
      features.push(THREAT_DETECTION);
    }
    if (licenseType === "ENTERPRISE" && !isChina()) {
      features.push(IDENTITY_SECURITY);
    }
    return features;
  }, [licenseType, supportedFeaturesList, accountType]);

  return {
    supportedFeaturesList: supportedFeatures,
    licenseType,
    isLoading: isLoading && isFetching,
  };
}
