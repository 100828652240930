import { createContext, forwardRef } from "react";
import { noop } from "utils";
import { ControlGroup } from "../ControlGroup";

import { type ChangeEvent, type ReactNode, type Ref } from "react";
import { type ControlGroupProps } from "../ControlGroup";

export type RadioGroupContextInstance = {
  disabled?: boolean;
  ref?: Ref<HTMLInputElement> | null;
  /**
   * The name of the radio group. This is the field the selected value would be stored as.
   */
  name: string;
  /**
   * An onchange function that will be called with the change event.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Use to set the value that is selected
   */
  value?: string;
};

export type RadioGroupProps = {
  disabled?: boolean;
  children: ReactNode;
} & RadioGroupContextInstance &
  Omit<
    ControlGroupProps,
    "onChange" | "name" | "value" | "disabled" | "children"
  >;

export const RadioGroupContext = createContext<RadioGroupContextInstance>({
  name: "",
  onChange: noop,
});

/**
 * Group radios together into a field.
 */
export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (props: RadioGroupProps, ref) => {
    const { children, disabled, onChange, name, value, ...rest } = props;

    return (
      <RadioGroupContext.Provider
        value={{
          disabled,
          name,
          onChange,
          ref,
          value,
        }}
      >
        <ControlGroup role="radiogroup" {...rest}>
          {children}
        </ControlGroup>
      </RadioGroupContext.Provider>
    );
  },
);

RadioGroup.displayName = "RadioGroup";
