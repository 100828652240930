import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AwsS3Icon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "AWS S3",
    id: "zY8pkO",

    description: "AWS S3 icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M17.2725 26.4848L22.1209 24.0605V75.4545L17.2725 73.2727V26.4848Z"
        className="fill-[url(#paint0_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M77.3936 24.0605L82.7269 26.7272V73.0302L77.3936 75.4545V24.0605Z"
        className="fill-[url(#paint1_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M77.3936 24.0605L49.7572 30.8484V69.3939L77.3936 75.4545V24.0605Z"
        className="fill-[url(#paint2_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M22.1211 24.0605L49.7575 30.8484V69.3939L22.1211 75.4545V24.0605Z"
        className="fill-[url(#paint3_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M38.1211 16.0606L50.2423 10V30.3636L38.1211 33.0303V16.0606Z"
        className="fill-[url(#paint4_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M62.3633 83.9394L50.2421 90L50.2421 69.6364L62.3633 66.9697L62.3633 83.9394Z"
        className="fill-[url(#paint5_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M38.1211 41.5151L50.2423 40.0605V60.4242L38.1211 58.4848V41.5151Z"
        className="fill-[url(#paint6_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M62.3633 16.0606L50.2421 10V30.3636L62.3633 33.0303V16.0606Z"
        className="fill-[url(#paint7_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M38.1211 83.9394L50.2423 90L50.2423 69.6364L38.1211 66.9697L38.1211 83.9394Z"
        className="fill-[url(#paint8_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M62.3633 41.7575L50.2421 40.0605V60.4242L62.3633 58.7272V41.7575Z"
        className="fill-[url(#paint9_linear_10671_277284)] dark:fill-none"
      />
      <path
        d="M38.1211 32.7881L50.2426 30.3641L62.3635 32.7881L50.2426 35.2121L38.1211 32.7881Z"
        className="fill-[#9C6514] dark:fill-none"
      />
      <path
        d="M62.3633 67.2119L50.2418 69.6359L38.1208 67.2119L50.2418 64.7879L62.3633 67.2119Z"
        className="fill-[#9C6514] dark:fill-none"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10671_277284"
          x1={15.9409}
          y1={26.979}
          x2={21.3668}
          y2={26.9202}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10671_277284"
          x1={79.1079}
          y1={50.0627}
          x2={82.9762}
          y2={50.2014}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10671_277284"
          x1={84.9834}
          y1={26.979}
          x2={54.1696}
          y2={25.0767}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10671_277284"
          x1={31.0047}
          y1={50.0627}
          x2={50.4056}
          y2={53.6667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_10671_277284"
          x1={1.77246}
          y1={25.5}
          x2={28.188}
          y2={-0.0165338}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_10671_277284"
          x1={50.2725}
          y1={78}
          x2={70.4772}
          y2={78.8989}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_10671_277284"
          x1={21.2725}
          y1={87.5}
          x2={21.4516}
          y2={50.0992}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_10671_277284"
          x1={50.2725}
          y1={22}
          x2={74.0399}
          y2={27.4124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_10671_277284"
          x1={4.77246}
          y1={88.5}
          x2={36.2401}
          y2={104.132}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_10671_277284"
          x1={52.2725}
          y1={50}
          x2={64.8136}
          y2={56.0032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
      {/* Dark */}
      <path
        opacity="0.4"
        d="M17.2725 26.4848L22.1209 24.0605V75.4545L17.2725 73.2727V26.4848Z"
        className="fill-none dark:fill-[#E3E3E3]"
      />
      <path
        opacity="0.4"
        d="M77.3936 24.0605L82.7269 26.7272V73.0302L77.3936 75.4545V24.0605Z"
        className="fill-none dark:fill-white"
      />
      <path
        opacity="0.4"
        d="M77.3936 24.0605L49.7572 30.8484V69.3939L77.3936 75.4545V24.0605Z"
        className="fill-none dark:fill-[#E3E3E3]"
      />
      <path
        opacity="0.4"
        d="M22.1211 24.0605L49.7575 30.8484V69.3939L22.1211 75.4545V24.0605Z"
        className="fill-none dark:fill-white"
      />
      <path
        d="M38.1211 16.0606L50.2423 10V30.3636L38.1211 33.0303V16.0606Z"
        className="fill-none dark:fill-[#E3E3E3]"
      />
      <path
        d="M62.3633 83.9394L50.2421 90L50.2421 69.6364L62.3633 66.9697L62.3633 83.9394Z"
        className="fill-none dark:fill-white"
      />
      <path
        d="M38.1211 41.5151L50.2423 40.0605V60.4242L38.1211 58.4848V41.5151Z"
        className="fill-none dark:fill-[#E3E3E3]"
      />
      <path
        d="M62.3633 16.0606L50.2421 10V30.3636L62.3633 33.0303V16.0606Z"
        className="fill-none dark:fill-white"
      />
      <path
        d="M38.1211 83.9394L50.2423 90L50.2423 69.6364L38.1211 66.9697L38.1211 83.9394Z"
        className="fill-none dark:fill-[#E3E3E3]"
      />
      <path
        d="M62.3633 41.7575L50.2421 40.0605V60.4242L62.3633 58.7272V41.7575Z"
        className="fill-none dark:fill-white"
      />
      <path
        d="M38.1211 32.7881L50.2426 30.3641L62.3635 32.7881L50.2426 35.2121L38.1211 32.7881Z"
        className="fill-none dark:fill-[#D3D3D3]"
      />
      <path
        d="M62.3633 67.2119L50.2418 69.6359L38.1208 67.2119L50.2418 64.7879L62.3633 67.2119Z"
        className="fill-none dark:fill-[#D3D3D3]"
      />
    </SvgIcon>
  );
};
