import { defineMessages } from "react-intl";

export const configureMessages = defineMessages({
  error: {
    defaultMessage: "Subscription not found",
    id: "5rXphl",
    description:
      "Error for validating cloudtrail for configuring Forward Scan during AWS Onboarding",
  },
  instruction1: {
    defaultMessage:
      "1. Download the .zip file that contains the scripts to set up Forward Scan.",
    id: "N1Y3UQ",
    description: "Introductory text at the top of a wizard page",
  },
  instruction3: {
    defaultMessage:
      "3. After the above script runs successfully in CloudShell, click Validate Setup.",
    id: "uFdtfv",
    description: "Wizard page descriptive text",
  },
  title: {
    defaultMessage: "Configure Forward Scan",
    id: "ddKrgU",
    description: "Wizard page heading",
  },
});
