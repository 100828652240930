import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const NpmIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Npm",
    id: "JphEyL",
    description: "Npm alt text",
  });

  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <title>{alt}</title>
      <path
        d="m31.985.457-2.18-.411L0 0v32h31.985V16.046H32V.457h-.015Zm-5.349 26.21h-5.379V13.379l-.046-2.667h-5.333v15.955H5.333V5.333h21.319v21.334h-.016Z"
        fill="#E62B3F"
      />
    </SvgIcon>
  );
};
