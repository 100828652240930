import { createRoute, rootRoute } from "router";
import { z } from "zod";
import { useCopilotStore } from "../../components/Copilot/useCopilotStore";
import Logout from "./Logout";

const LogoutSearchSchema = z.object({
  timeout: z.optional(z.boolean().catch(false)),
});

export const logoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/logout",
  component: Logout,
  validateSearch: LogoutSearchSchema,
  onEnter: () => {
    useCopilotStore.getState().clearConversation();
    useCopilotStore.getState().setCurrentView("home");
  },
});
