import { useQuery } from "@tanstack/react-query";
import { featureStatuskeys, getMetaInfo } from "requests";

export const useIsBusinessLicenseType = () => {
  const { data: metaInfo, isLoading } = useQuery({
    queryKey: featureStatuskeys.metaInfo(),
    queryFn: getMetaInfo,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });

  const isBusinessLicenseType =
    metaInfo?.licenseType?.toLowerCase() === "business";

  return {
    isLoading,
    isBusinessLicenseType,
  };
};
