import { useCallback, useEffect, useState } from "react";

export type UseLocalStorageStateOptions<T = unknown> = {
  /**
   * Default value to return and store in the localStorage.
   */
  defaultValue?: T | (() => T);
  /**
   * Enable rerendering when the value changes from other components or browser tabs
   */
  subscribe?: boolean;
  /**
   * Use to format the value before storing in  localStorage.
   * By default it does `JSON.stringify`.
   */
  serialize?: (input: T) => string;
  /**
   * Use to format the value from localStorage. By default it does `JSON.parse`.
   */
  deserialize?: (input: string) => T;
};

export type UseLocalStorageStateReturn<T> = [T, (value: T) => void];

/**
 * useLocalStorageState behaves like useState but persists data to localStorage.
 *
 * It offers the ability to rerender when other pieces of the page, or other
 * tabs, change the state in local storage by passing `subscribe: true` as an option.
 */
export function useLocalStorageState<T = unknown>(
  key: string,
  defaultValue: T | (() => T),
  {
    subscribe = false,
    serialize = JSON.stringify,
    deserialize = JSON.parse,
  }: UseLocalStorageStateOptions<T> = {},
): UseLocalStorageStateReturn<T> {
  const [state, setState] = useState<T>(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      return deserialize(valueInLocalStorage);
    }

    const value =
      defaultValue instanceof Function ? defaultValue() : defaultValue;

    value !== undefined && window.localStorage.setItem(key, serialize(value));
    return value;
  });

  const setAndSave = useCallback(
    (value: T) => {
      setState(value);
      window.localStorage.setItem(key, serialize(value));
      window.dispatchEvent(
        new StorageEvent("storage", { key, newValue: serialize(value) }),
      );
    },
    [key, serialize],
  );

  useEffect(() => {
    if (!subscribe) return;
    const handleChanges = (storageEvent: StorageEvent) => {
      if (storageEvent.key === key) {
        const newState = deserialize(storageEvent.newValue ?? "");
        setState(newState);
      }
    };

    window.addEventListener("storage", handleChanges);
    return () => {
      window.removeEventListener("storage", handleChanges);
    };
  }, [key, deserialize, subscribe, setState]);

  return [state, setAndSave];
}
