import { format } from "date-fns";
import { type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { type AbsoluteTimeType, type TimeRange } from "requests";
import { type SelectItem, type SelectedDateRange } from "ui";
import { customTimeRangeItem, useCustomDate } from "../../utils/useCustomDate";

export type TimeRangeItemNames = keyof typeof timeRangeItemMap;

export type useTimeRangeProps = {
  selectedValue?: TimeRange;
  /**
   * The names of the pre-defined items to include in the dropdown
   */
  itemNames: TimeRangeItemNames[];
  /**
   * The Picker component to render when Custom is selected
   */
  picker: ReactElement;
};

export const useTimeRange = ({
  itemNames,
  picker,
  selectedValue,
}: useTimeRangeProps) => {
  return useCustomDate({
    // @ts-expect-error TS is having trouble with the union type for itemNames
    getItemsFn: getTimeRangeItems,
    itemNames,
    picker,
    selectedValue,
  });
};

export const defaultInitialTimeRangeFromValue = (value: TimeRange) => {
  if (!value) return;
  if (value.type !== "absolute") return;
  const { endTime, startTime } = value.value;
  if (!startTime || !endTime) return;

  return {
    end: new Date(endTime),
    start: new Date(startTime),
  };
};

export const defaultTimeRangeOnCustomApply = (dateRange: SelectedDateRange) => {
  return {
    type: "absolute",
    value: {
      endTime: dateRange.end.valueOf(),
      startTime: dateRange.start.valueOf(),
    },
  };
};

export const timeFormat = (time: string | number | Date): string => {
  return format(new Date(time), "PPp");
};

export const defaultTimeRangeCustomLabel = (absoluteTime: AbsoluteTimeType) => {
  if (!absoluteTime?.value) return "";

  const startLabel = timeFormat(Number(absoluteTime.value.startTime));
  const endLabel = timeFormat(Number(absoluteTime.value.endTime));

  return `${startLabel} - ${endLabel}`;
};

export const allTimeTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="All Time"
      id="PTzY9u"
      description="Time Range Filter option"
    />
  ),
  key: "allTime",
  value: {
    type: "to_now",
    value: "epoch",
  },
};
export const lastLoginTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Since Last Login"
      id="GLMlVY"
      description="Time Range Filter option"
    />
  ),
  key: "lastLogin",
  value: {
    type: "to_now",
    value: "login",
  },
};
export const past24HoursTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Past 24 Hours"
      id="sCWsKL"
      description="Time Range Filter option"
    />
  ),
  key: "past24Hours",
  value: {
    type: "relative",
    value: {
      amount: "24",
      unit: "hour",
    },
  },
};
export const past7DaysTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Past 7 Days"
      id="JnelkD"
      description="Time Range Filter option"
    />
  ),
  key: "past7Days",
  value: {
    type: "relative",
    value: {
      amount: "1",
      unit: "week",
    },
  },
};
export const pastMonthTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Past Month"
      id="N2+UnZ"
      description="Time Range Filter option"
    />
  ),
  key: "pastMonth",
  value: {
    type: "relative",
    value: {
      amount: "1",
      unit: "month",
    },
  },
};
export const past3MonthsTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Past 3 Months"
      id="QEGT73"
      description="Time Range Filter option"
    />
  ),
  key: "past3Months",
  value: {
    type: "relative",
    value: {
      amount: "3",
      unit: "month",
    },
  },
};
export const past6MonthsTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Past 6 Months"
      id="NMDFeA"
      description="Time Range Filter option"
    />
  ),
  key: "past6Months",
  value: {
    type: "relative",
    value: {
      amount: "6",
      unit: "month",
    },
  },
};
export const pastYearTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Past Year"
      id="H3t246"
      description="Time Range Filter option"
    />
  ),
  key: "pastYear",
  value: {
    type: "relative",
    value: {
      amount: "1",
      unit: "year",
    },
  },
};
export const yearToDateTimeRangeItem = {
  children: (
    <FormattedMessage
      defaultMessage="Year To Date"
      id="fPjRF2"
      description="Time Range Filter option"
    />
  ),
  key: "yearToDate",
  value: {
    type: "to_now",
    value: "year",
  },
};

const timeRangeItemMap = {
  allTime: allTimeTimeRangeItem,
  lastLogin: lastLoginTimeRangeItem,
  past24Hours: past24HoursTimeRangeItem,
  past7Days: past7DaysTimeRangeItem,
  pastMonth: pastMonthTimeRangeItem,
  past3Months: past3MonthsTimeRangeItem,
  past6Months: past6MonthsTimeRangeItem,
  pastYear: pastYearTimeRangeItem,
  yearToDate: yearToDateTimeRangeItem,
  custom: customTimeRangeItem,
};

export const getTimeRangeItems = ({
  itemNames,
  customItemOnClick,
}: {
  itemNames: TimeRangeItemNames[];
  customItemOnClick?: () => void;
}) => {
  return itemNames.reduce((items, itemName) => {
    const item = timeRangeItemMap[itemName];
    if (itemName === "custom") {
      items.push({ ...item, onClick: customItemOnClick } as SelectItem);
    } else {
      items.push(item as SelectItem);
    }
    return items;
  }, [] as SelectItem[]);
};
