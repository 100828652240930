import {
  forwardRef,
  useCallback,
  useRef,
  useState,
  type ChangeEvent,
  type ComponentPropsWithoutRef,
} from "react";

import { classNames } from "utils";
import { InputCard, type InputCardProps } from "../InputCard";
import { CheckboxInput, type CheckboxInputProps } from "./CheckboxInput";

export type CheckboxCardProps = ComponentPropsWithoutRef<"input"> &
  CheckboxInputProps &
  Pick<InputCardProps, "title" | "description" | "icon" | "addClassName">;

/**
 * Lets users toggle a boolean value.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { CheckboxCard } from "ui";
 * ```
 */
export const CheckboxCard = forwardRef<HTMLInputElement, CheckboxCardProps>(
  (
    {
      disabled = false,
      readOnly = false,
      title,
      description,
      icon,
      onChange,
      addClassName,
      ...props
    }: CheckboxCardProps,
    incomingRef,
  ) => {
    const labelClasses = classNames(
      "flex items-center space-x-2",
      disabled && "cursor-not-allowed",
      !disabled && !readOnly && "cursor-pointer",
    );
    const [checked, setChecked] = useState(props.checked);

    const defaultRef = useRef<HTMLInputElement | null>(null);
    const useableRef = incomingRef ?? defaultRef;

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
        onChange?.(e);
      },
      [setChecked, onChange],
    );

    return (
      <label className={labelClasses}>
        <InputCard
          title={title}
          description={description}
          icon={icon}
          disabled={disabled}
          selected={checked}
          readOnly={readOnly}
          addClassName={addClassName}
          input={
            <CheckboxInput
              appearance="circle"
              disabled={disabled}
              ref={useableRef}
              readOnly={readOnly}
              onChange={handleOnChange}
              {...props}
            />
          }
        />
      </label>
    );
  },
);
CheckboxCard.displayName = "CheckboxCard";
