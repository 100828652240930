import { classNames } from "utils";

import {
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";

export type ControlGroupProps = ComponentPropsWithoutRef<"div"> & {
  addClassName?: string;
  children: ReactNode;
};

/**
 * Use to space controls like multiple radios or checkboxes on a form.
 */
export function ControlGroup({
  addClassName,
  children,
  ...rest
}: ControlGroupProps): ReactElement {
  return (
    <div
      className={classNames("ml-2 flex flex-col gap-2", addClassName)}
      {...rest}
    >
      {children}
    </div>
  );
}
