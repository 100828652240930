import { FormattedMessage, useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

import { useEnabledFeatures } from "./useEnabledFeatures";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../components";

export const actionPlanFeatures: Features[] = [
  {
    featureName: "actionPlanOverview",
    operations: {
      READ: false,
    },
  },
  {
    featureName: "actionPlanNotificationTemplates",
    operations: {
      CREATE: false,
      READ: false,
      UPDATE: false,
      DELETE: false,
    },
  },
  {
    featureName: "actionPlanStatusAndAssignment",
    operations: {
      UPDATE: false,
    },
  },
  {
    featureName: "actionPlanRemediation",
    operations: {
      UPDATE: false,
    },
  },
];

export function ActionPlanSection() {
  const intl = useIntl();
  const { isLoading, isFeatureEnabled } = useEnabledFeatures();
  const availableFeatures = actionPlanFeatures.filter(({ featureName }) =>
    isFeatureEnabled(featureName),
  );

  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(availableFeatures);

  if (isLoading || !availableFeatures.length) return null;

  return (
    <Accordion allowNoneOpen allowMultipleOpen defaultOpenPanels={[]}>
      <AccordionPanel
        title={intl.formatMessage({
          defaultMessage: "Action Plans",
          id: "JUbGkc",
          description: "Assign permissions section header",
        })}
        noPadding
        testId="permission-accordion"
      >
        <div className="divide-y divide-primary">
          <Row
            label={intl.formatMessage({
              defaultMessage: "Select All",
              id: "aYkfGO",
              description: "Select all checkbox row",
            })}
            addClassName="border-b-2"
            read={
              <SelectAllCheckbox
                status={selectionStatus.read}
                onChange={() => onClickSelectAll("READ")}
              >
                <FormattedMessage
                  defaultMessage="Select view for all Action Plan rows"
                  id="nNQi29"
                  description="Permission group Action Plan section header"
                />
              </SelectAllCheckbox>
            }
            create={
              <SelectAllCheckbox
                status={selectionStatus.create}
                onChange={() => onClickSelectAll("CREATE")}
              >
                <FormattedMessage
                  defaultMessage="Select create for all Action Plan rows"
                  id="aqnoPS"
                  description="Permission group Action Plan section header"
                />
              </SelectAllCheckbox>
            }
            update={
              <SelectAllCheckbox
                status={selectionStatus.update}
                onChange={() => onClickSelectAll("UPDATE")}
              >
                <FormattedMessage
                  defaultMessage="Select update for all Action Plan rows"
                  id="3MsDxB"
                  description="Permission group Action Plan section header"
                />
              </SelectAllCheckbox>
            }
            del={
              <SelectAllCheckbox
                status={selectionStatus.delete}
                onChange={() => onClickSelectAll("DELETE")}
              >
                <FormattedMessage
                  defaultMessage="Select delete for all Action Plan rows"
                  id="z187He"
                  description="Permission group Action Plan section header"
                />
              </SelectAllCheckbox>
            }
          />
          {availableFeatures.map((feature) => (
            <CheckboxRow key={feature.featureName} feature={feature} />
          ))}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}
