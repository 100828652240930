import { classNames } from "utils";

export type TabPanelProps = {
  /**
   * The ID of the selected tab.
   *
   * If not set, the content will be rendered.
   */
  activeTabId?: string | null;
  /**
   * Add a className to the tab panel
   */
  addClassName?: string;
  /**
   * The content that should be displayed when the panels associated tab is selected.
   */
  children: React.ReactNode;
  /**
   * The id of the Tab component this panel is associated with.
   */
  forTabId: string;
  /**
   * Set this to true to render/mount all tabs and hide all inactive tabs.
   * When false, only the active tab will be rendered/mounted.
   */
  hideInactiveTabs?: boolean;
  /**
   * Enable overflow-auto on the content of the tab so it can be made scrollable.
   *
   * The layout should be wrapped in a `flex` div with a set height for this to work.
   */
  overflow?: boolean;
};

export const TabPanel = ({
  activeTabId = null,
  addClassName,
  children,
  forTabId,
  hideInactiveTabs = false,
  overflow,
}: React.PropsWithChildren<TabPanelProps>): React.ReactElement | null => {
  const active = activeTabId === null || activeTabId === forTabId;
  const className = classNames(
    "outline-none",
    addClassName,
    !active && "hidden",
    overflow && "overflow-auto",
  );

  if (!active && !hideInactiveTabs) return null;

  return (
    <div
      className={className}
      aria-labelledby={forTabId}
      aria-expanded={active}
      role="tabpanel"
      tabIndex={active ? 0 : -1}
    >
      {children}
    </div>
  );
};
