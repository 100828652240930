import { useReducer } from "react";
import { GlobalModalContext, reducer } from "./GlobalModalContext";
import { useModal } from "./useModal";

import { type ReactElement, type ReactNode } from "react";

import { type IndividualModalState } from "./GlobalModalContext";
export interface ModalProviderProps {
  children: ReactNode;
}

/**
 * Keeps track of the modals passed to useModal and renders them.
 */

export function ModalProvider({ children }: ModalProviderProps): ReactElement {
  const [modals, dispatch] = useReducer(reducer, {});

  return (
    <GlobalModalContext.Provider value={{ dispatch }}>
      {children}
      {Object.keys(modals).map((key) => (
        <ModalRenderer key={key} {...modals[key]} />
      ))}
    </GlobalModalContext.Provider>
  );
}

function ModalRenderer({ isOpen, props, RenderModal }: IndividualModalState) {
  const { closeModal } = useModal(RenderModal);

  return <RenderModal closeModal={closeModal} isOpen={isOpen} {...props} />;
}
