import {
  type CloudTrailType,
  type ScriptDataRequestType,
  type ValidateFwScanRequestType,
} from "requests";
type ValidateParamsStateType = {
  accountId: string;
  cloudTrailName: string;
  externalId: string;
  roleArn: string;
  snsSubscriptionEndpoint: string;
  uuid: string;
};

type GenerateParamsStateType = {
  accountId: string;
  cloudTrailName: string;
  externalId: string;
  roleArn: string;
  snsSubscriptionEndpoint: string;
  uuid: string;
  cloudTrailBucket: string;
  snsTopicName: string;
};

// the UUID should be fixed, while the url can have different UUIDs appended to it
// this function enforces that the same UUID in the url sent to the /script API is sent to later APIs
export const enforceSameUuid = (url = "", uuid = "") => {
  const uuidPrefix = "/consume/uuid/";
  const startIndex = url.indexOf(uuidPrefix);
  const prefix = url.substring(0, startIndex);
  const result = `${prefix}${uuidPrefix}${uuid}`;

  return result;
};

export const generateValidateParams = (
  paramsFromState: ValidateParamsStateType,
  APIData: CloudTrailType,
): ValidateFwScanRequestType => {
  const {
    accountId,
    cloudTrailName,
    externalId,
    roleArn,
    snsSubscriptionEndpoint,
    uuid,
  } = paramsFromState;

  const existingCloudTrail = APIData?.existingCloudtrails?.find(
    ({ name }) => name === cloudTrailName,
  );

  const additionalParams = existingCloudTrail
    ? {
        cloudtrailName: existingCloudTrail.name,
        cloudtrailRegion: existingCloudTrail.region,
      }
    : { cloudtrailName: cloudTrailName };

  return {
    accountId,
    externalId,
    ...additionalParams,
    roleArn,
    snsSubscriptionEndpoint: enforceSameUuid(snsSubscriptionEndpoint, uuid),
  };
};

export const generateScriptParams = (
  paramsFromState: GenerateParamsStateType,
  APIData: CloudTrailType,
) => {
  const { accountId, cloudTrailBucket, cloudTrailName, snsTopicName, uuid } =
    paramsFromState;

  const {
    existingBuckets,
    existingCloudtrails,
    existingSnsTopic,
    isCommonLogging = false, // can be missing / undefined
    snsSubscriptionEndpoint,
  } = APIData || {};

  const snsEndpoint = enforceSameUuid(snsSubscriptionEndpoint, uuid);

  const result: ScriptDataRequestType = {
    accountId,
    // careful! BE uses a different case on this key
    cloudtrailBucket: {
      name: cloudTrailBucket,
      requestType: "",
    },
    // same
    cloudtrailRequest: {
      name: cloudTrailName,
      requestType: "",
    },
    snsEndpoint,
    snsTopic: {
      name: snsTopicName,
      requestType: "",
    },
    isCommonLogging,
  };

  let cloudTrailRequestType;
  let bucketRequestType;
  let snsTopicRequestType;

  const existingCloudtrail = existingCloudtrails?.find(
    ({ name }) => name === cloudTrailName,
  );

  if (existingCloudtrail) {
    // these are always the values when user selects an existing cloudtrail
    cloudTrailRequestType = "update";
    bucketRequestType = "linked";

    // this value is only set if user selects an existing cloudtrail
    result.cloudtrailRegion = existingCloudtrail.region;
    result.isCommonLogging = existingCloudtrail.commonLogging;

    snsTopicRequestType =
      snsTopicName === existingCloudtrail.snsTopic
        ? "linked"
        : existingSnsTopic?.includes(snsTopicName)
          ? "unlinked"
          : "new";
  } else {
    // this is always the value when user does NOT select an existing cloudtrail
    cloudTrailRequestType = "new";

    bucketRequestType = existingBuckets?.includes(cloudTrailBucket)
      ? "unlinked"
      : "new";
    snsTopicRequestType = existingSnsTopic?.includes(snsTopicName)
      ? "unlinked"
      : "new";
  }

  result.cloudtrailBucket.requestType = bucketRequestType;
  result.cloudtrailRequest.requestType = cloudTrailRequestType;
  result.isCommonLogging = isCommonLogging;
  result.snsTopic.requestType = snsTopicRequestType;

  return result;
};
