import { CardFooter } from "../Card";

import { type ReactElement } from "react";
import { type CardFooterProps as ModalFooterProps } from "../Card";

export type { ModalFooterProps };

export function ModalFooter({
  appearance = "striped",
  ...passThrough
}: ModalFooterProps): ReactElement {
  return <CardFooter appearance={appearance} {...passThrough} />;
}
