import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { baseApi } from "../../apis/baseApi";
import {
  ExtendTokenResponseSchema,
  LoginSSOResponseSchema,
  PostAuthTokenGenerateRequestSchema,
  PostAuthTokenGenerateResponseSchema,
  PostAuthTokenRegenerateRequestSchema,
  PostAuthTokenRegenerateResponseSchema,
  PostResetPasswordRequestSchema,
  PostResetPasswordResponseSchema,
  PostTermsOfServiceRequestSchema,
  PostTermsOfServiceResponseSchema,
  ValidateResetResponseSchema,
} from "./schemas";

export const postLogin = async ({
  username,
  password,
  customerName,
  baseUrl,
}: {
  username: string;
  password: string;
  customerName: string;
  baseUrl: string;
}) => {
  return baseApi({
    path: "login",
    config: {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password, customerName }),
      method: "post",
    },
    baseUrl,
  });
};

export const loginSSO = async ({
  username,
  prismaId,
  baseUrl,
}: {
  username: string;
  prismaId?: string;
  baseUrl: string;
}) => {
  return jsonApi({
    path: "authn/api/v1/oauth2/login",
    params: prismaId
      ? { user_name: username, prisma_id: prismaId }
      : { user_name: username },
    baseUrl,
    requestSchema: z.void(),
    responseSchema: LoginSSOResponseSchema,
  });
};

export const postTermsOfService = async ({
  customerName,
  ssoSession,
  username,
  password,
  baseUrl,
}: {
  customerName: string;
  ssoSession: boolean;
  username: string;
  password: string | undefined;
  baseUrl: string | undefined;
}) => {
  return jsonApi({
    path: "tos",
    baseUrl,
    body: {
      tosAccepted: true,
      customerName,
      ssoSession,
      username,
      password,
    },
    config: {
      method: "post",
    },
    requestSchema: PostTermsOfServiceRequestSchema,
    responseSchema: PostTermsOfServiceResponseSchema,
  });
};

export const extendToken = async () => {
  return jsonApi({
    path: "auth_token/extend",
    requestSchema: z.void(),
    responseSchema: ExtendTokenResponseSchema,
  });
};

export const postAuthTokenGenerate = async ({
  baseurl,
  username,
}: {
  baseurl: string;
  username: string;
}) => {
  return jsonApi({
    baseUrl: baseurl,
    path: "auth_token/generate",
    body: { username },
    config: {
      method: "post",
    },
    requestSchema: PostAuthTokenGenerateRequestSchema,
    responseSchema: PostAuthTokenGenerateResponseSchema,
  });
};

export const postAuthTokenRegenerate = async ({
  resetToken,
}: {
  resetToken: string;
}) => {
  return jsonApi({
    path: "auth_token/regenerate",
    body: { resetToken },
    config: {
      method: "post",
    },
    requestSchema: PostAuthTokenRegenerateRequestSchema,
    responseSchema: PostAuthTokenRegenerateResponseSchema,
  });
};

export const validateResetKey = (resetToken: string) =>
  [
    {
      scope: "validateReset",
      resetToken,
    },
  ] as const;

export const validateResetToken = async ({
  queryKey: [{ resetToken }],
}: QueryFunctionContext<ReturnType<typeof validateResetKey>>) => {
  return jsonApi({
    path: "auth_token/validate",
    body: { resetToken },
    config: {
      method: "post",
    },
    requestSchema: PostAuthTokenRegenerateRequestSchema,
    responseSchema: ValidateResetResponseSchema,
  });
};

export const postResetPassword = async ({
  newPassword,
  token,
}: {
  newPassword: string;
  token: string;
}) => {
  return jsonApi({
    path: "password/reset",
    body: { newPassword },
    config: {
      method: "post",
      headers: {
        "x-redlock-auth": token,
      },
    },
    requestSchema: PostResetPasswordRequestSchema,
    responseSchema: PostResetPasswordResponseSchema,
  });
};
