/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { justInTimeRoute } from "../../justInTimeRoute";

export const RoleBindingsTable = lazy(
  () => import("../tables/RoleBindingsTable/RoleBindingsTable"),
);

export const roleBindingsRoute = new Route({
  getParentRoute: () => justInTimeRoute,
  path: "role-bindings",
  component: RoleBindingsTable,
});
