import { SettingsPageIcon } from "icons";
import { useIntl } from "react-intl";
import { PageHeader, PageLayout } from "ui";

export default function SettingsIntegrationsCatalog() {
  const intl = useIntl();
  return (
    <PageLayout
      documentTitle={[
        intl.formatMessage({
          defaultMessage: "Settings",
          id: "Pgzph9",
          description: "page header",
        }),
        intl.formatMessage({
          defaultMessage: "Integrations Catalog",
          id: "dCaaCs",
          description: "page header",
        }),
      ]}
      header={
        <PageHeader
          icon={<SettingsPageIcon size="lg" />}
          title={intl.formatMessage({
            defaultMessage: "Settings Integrations Catalog",
            id: "uA7zDG",
            description: "page header",
          })}
        />
      }
      overflow
    >
      <></>
    </PageLayout>
  );
}
