import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { RequestActivationType } from "requests";
import { z } from "zod";
import { justInTimeRoute } from "../../justInTimeRoute";

export const AccessRequestsTable = lazy(
  () => import("../tables/AccessRequestsTable/AccessRequestsTable"),
);

const requestActionTypes = [
  "create",
  ...Object.values(RequestActivationType),
] as const;
export const RequestActionSchema = z.enum(requestActionTypes);
export const RequestSearchSchema = z.object({
  action: RequestActionSchema.optional(),
  roleId: z.string().optional(),
  accountId: z.number().or(z.string()).optional(),
  type: z.enum(["ALL", "READY_FOR_ACTIVATION"]).optional(),
});

export const requestsRoute = new Route({
  getParentRoute: () => justInTimeRoute,
  path: "requests",
  component: AccessRequestsTable,
  validateSearch: RequestSearchSchema,
});

export const requestByRequestIdRoute = new Route({
  getParentRoute: () => requestsRoute,
  path: "$requestId",
  component: AccessRequestsTable,
  validateSearch: RequestSearchSchema,
});
