export default function ErrorMessages({ errors = [] }: { errors: string[] }) {
  return (
    <div className="ml-6 mt-0.5 text-red" data-selector="error-messages">
      {errors.map((error) => (
        <p className="p-0 pr-8 text-xs leading-5 dark:text-dark-bg" key={error}>
          {error}
        </p>
      ))}
    </div>
  );
}
