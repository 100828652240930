import { useIntl } from "react-intl";
import { SvgIcon, type SvgIconProps } from "../SvgIcon";

export const CriticalSeverityVulnerabilityIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Critical",
    id: "uq1LNe",

    description: "severity icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} {...props} viewBox="0 0 20 20" fill="none">
      {showNativeTooltip && <title>{alt}</title>}
      <rect
        x="2"
        y="2"
        width="16"
        height="16"
        rx="4"
        className="fill-[#8F000E] dark:fill-[#FF3C4F]"
      />
      <path
        d="M12.347 12.1245C12.4277 12.1245 12.4992 12.1557 12.5615 12.218L13.1445 12.8505C12.8218 13.2502 12.424 13.5563 11.951 13.769C11.4817 13.9817 10.917 14.088 10.257 14.088C9.66667 14.088 9.135 13.9872 8.662 13.7855C8.19267 13.5838 7.79117 13.3033 7.4575 12.944C7.12383 12.5847 6.86717 12.1557 6.6875 11.657C6.5115 11.1583 6.4235 10.6138 6.4235 10.0235C6.4235 9.42583 6.51883 8.8795 6.7095 8.3845C6.90017 7.88583 7.16783 7.45683 7.5125 7.0975C7.86083 6.73817 8.27517 6.4595 8.7555 6.2615C9.23583 6.05983 9.7675 5.959 10.3505 5.959C10.9298 5.959 11.4432 6.05433 11.8905 6.245C12.3415 6.43567 12.7247 6.685 13.04 6.993L12.545 7.6805C12.5157 7.7245 12.4772 7.763 12.4295 7.796C12.3855 7.829 12.3232 7.8455 12.2425 7.8455C12.1875 7.8455 12.1307 7.83083 12.072 7.8015C12.0133 7.7685 11.9492 7.73 11.8795 7.686C11.8098 7.63833 11.7292 7.587 11.6375 7.532C11.5458 7.477 11.4395 7.4275 11.3185 7.3835C11.1975 7.33583 11.0563 7.29733 10.895 7.268C10.7373 7.235 10.554 7.2185 10.345 7.2185C9.98933 7.2185 9.663 7.28267 9.366 7.411C9.07267 7.53567 8.81967 7.719 8.607 7.961C8.39433 8.19933 8.22933 8.49267 8.112 8.841C7.99467 9.18567 7.936 9.57983 7.936 10.0235C7.936 10.4708 7.99833 10.8687 8.123 11.217C8.25133 11.5653 8.42367 11.8587 8.64 12.097C8.85633 12.3353 9.11117 12.5187 9.4045 12.647C9.69783 12.7717 10.0132 12.834 10.3505 12.834C10.5522 12.834 10.7337 12.823 10.895 12.801C11.06 12.779 11.2103 12.7442 11.346 12.6965C11.4853 12.6488 11.6155 12.5883 11.7365 12.515C11.8612 12.438 11.984 12.3445 12.105 12.2345C12.1417 12.2015 12.1802 12.1758 12.2205 12.1575C12.2608 12.1355 12.303 12.1245 12.347 12.1245Z"
        className="fill-white dark:fill-gray-900"
      />
    </SvgIcon>
  );
};
