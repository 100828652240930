import { type FC } from "react";

import { useTableInstance } from "../TableContext";
import { DownloadButton } from "./DownloadButton";

export interface ConnectedDownloadButtonProps {
  /**
   * The name of the file to be downloaded, or a function that returns the name.
   *
   * Currently, only `csv` is supported and the extension is required in the `fileName`.
   */
  fileName: string | (() => string);
}

/**
 * Renders a button that triggers a download of the current table data.
 *
 * How the data is exported can be customized by passing an `export` function in the column options.
 *
 * A column can be omitted from export using the `disableExport` column option.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConnectedDownloadButton, ConnectedTable, DownloadButton, TableProvider } from "table";
 * ```
 */
export const ConnectedDownloadButton: FC<ConnectedDownloadButtonProps> = ({
  fileName,
}: ConnectedDownloadButtonProps) => {
  const tableInstance = useTableInstance();

  return <DownloadButton fileName={fileName} tableInstance={tableInstance} />;
};
