import { useWizardContext } from "../../../../../../components/Wizard";

import { AccountGroupSelect } from "prisma";
import { type AlibabaConfigureAccountStepValuesType } from "../../../../../types";
import AccountName from "../../../components/AccountName";
import RamArn from "./RamArn/RamArn";

export default function AccountDetails() {
  const {
    state: { steps },
  } = useWizardContext();

  const { groupIds: accountGroupDefaultValue } = steps[1]
    ?.values as AlibabaConfigureAccountStepValuesType;

  return (
    <section className="space-y-2">
      <div className="space-y-4">
        <AccountName />
        <RamArn />
        <AccountGroupSelect
          defaultValue={accountGroupDefaultValue}
          enableMultiSelect={true}
          itemValue="id"
          name="groupIds"
        />
      </div>
    </section>
  );
}
