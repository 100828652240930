/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { accessControlRoute } from "../../accessControlRoute";

const SSO = lazy(() =>
  import("./SSO").then((res) => ({
    default: res.SSO,
  })),
);

export const ssoRoute = new Route({
  getParentRoute: () => accessControlRoute,
  path: "sso",
  component: SSO,
});
/* c8 ignore stop */
