import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsProvidersRoute } from "../providers/settingsProvidersRoute";
const CasProviders = lazy(() => import("./CasProviders"));

export const settingsCICDRunsRoute = new Route({
  getParentRoute: () => settingsProvidersRoute,
  path: "/cicd-runs",
  component: CasProviders,
});

export const settingsCICDRunsNestedRoute = new Route({
  getParentRoute: () => settingsCICDRunsRoute,
  path: "$",
  component: CasProviders,
});
