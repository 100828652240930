import { LoadingIcon } from "icons";
import { type ReactElement, type ReactNode } from "react";

import { classNames } from "utils";
import { Body, Title } from "../Typography";

export interface CardHeaderProps {
  /**
   * Actions
   */
  actions?: ReactNode;
  /**
   * Change the look of the card header
   */
  appearance?: "primary" | "striped" | "clear";
  /**
   * Add an icon to the header.
   */
  icon?: ReactNode;
  /**
   * Renders a loading indicator in the header. Only use for background loading,
   * where the primary card content is already loaded.
   */
  isBackgroundFetching?: boolean;
  /**
   * The level of the card header in the semantic hierarchy of the page.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements.
   */
  level?: 2 | 3 | 4 | 5 | 6;
  /**
   * Describes the purpose of the Card.
   */
  title: ReactNode;
  /**
   * Refines the purpose of the Card
   */
  subTitle?: ReactNode;
}

const appearanceClasses = {
  primary: "border-b border-primary",
  striped:
    "border-b border-gray-300 bg-gray-100 dark:border-blue-steel-850 dark:bg-black/[.30]",
  clear: "",
};

/**
 * A styled container to be used within a Card.
 */
export function CardHeader({
  actions,
  appearance = "primary",
  icon: userIcon,
  isBackgroundFetching = false,
  level = 3,
  title,
  subTitle,
}: CardHeaderProps): ReactElement {
  const icon = userIcon;
  return (
    <div
      className={classNames(
        "rounded-t px-4 py-2",
        appearanceClasses[appearance],
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          {icon && <div>{icon}</div>}
          <div className="flex flex-col">
            <Title data-testid="card-title" level={level} size="xxs">
              {title}
            </Title>
            {subTitle && (
              <Body
                appearance="secondary"
                data-testid="card-subtitle"
                size="sm"
              >
                {subTitle}
              </Body>
            )}
          </div>
        </div>
        {(actions || isBackgroundFetching) && (
          <div className="flex items-center space-x-2">
            {isBackgroundFetching && <LoadingIcon />}
            {actions}
          </div>
        )}
      </div>
    </div>
  );
}
