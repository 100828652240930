import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const OtherAssetClassIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Other Asset Class",
    id: "obPHoc",
    description: "Asset Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <title>{alt}</title>
      <path d="M13.7232 9.32842C13.4507 9.50123 13.1783 9.65047 12.9058 9.77614C12.6485 9.88611 12.3761 9.96466 12.0885 10.0118C12.6788 10.106 13.2237 10.326 13.7232 10.6716L16.5085 12.3625C16.9686 12.6418 17.1251 13.2352 16.8625 13.7051L16.522 14.3145C16.2457 14.8089 15.6143 14.9754 15.1301 14.6815L12.4063 13.028C12.1339 12.8552 11.8766 12.6745 11.6344 12.486C11.4074 12.2975 11.2106 12.0933 11.0441 11.8734C11.2409 12.3918 11.3393 12.9887 11.3393 13.6642V17C11.3393 17.5523 10.8915 18 10.3393 18H9.66021C9.10792 18 8.66021 17.5523 8.66021 17V13.6878C8.66021 13.3736 8.67534 13.0673 8.70562 12.7688C8.75102 12.4703 8.81914 12.1875 8.90995 11.9205C8.74346 12.1404 8.54669 12.3368 8.31965 12.5096C8.09261 12.6824 7.85044 12.8552 7.59313 13.028L4.88494 14.6647C4.40331 14.9557 3.77646 14.7922 3.49836 14.303L3.14892 13.6883C2.88059 13.2162 3.03795 12.6163 3.50343 12.3368L6.27631 10.6716C6.85147 10.326 7.39636 10.106 7.91098 10.0118C7.38123 9.91753 6.83633 9.68974 6.27631 9.32842L3.49098 7.63754C3.03085 7.35821 2.87439 6.76478 3.13697 6.2949L3.4751 5.68983C3.75231 5.19376 4.38667 5.02811 4.87103 5.32531L7.59313 6.99558C7.86558 7.16838 8.11532 7.34904 8.34236 7.53756C8.56939 7.71036 8.77373 7.90672 8.95536 8.12666C8.75859 7.60825 8.66021 7.01915 8.66021 6.35935V3C8.66021 2.44772 9.10792 2 9.66021 2H10.3393C10.8916 2 11.3393 2.44771 11.3393 3V6.31222C11.3393 6.64212 11.3166 6.95631 11.2712 7.25479C11.2409 7.55327 11.1728 7.83603 11.0668 8.10309C11.2484 7.88316 11.4528 7.68679 11.6798 7.51399C11.9069 7.34119 12.149 7.16838 12.4063 6.99558L15.1145 5.35891C15.5962 5.06785 16.223 5.23135 16.5011 5.72057L16.8482 6.33119C17.1175 6.80485 16.958 7.40689 16.4896 7.68514L13.7232 9.32842Z" />
    </SvgIcon>
  );
};
