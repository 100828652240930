import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const EnterpriseSettings = lazy(() => import("./Enterprise"));

export const enterpriseSettingsRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "enterprise-settings",
  component: EnterpriseSettings,
});
