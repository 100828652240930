import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Body } from "ui";
import CFTButton from "../../../components/CFTButton";

type ConfigHelpersProps = {
  downloadManagementIAMRoleCft: UseMutateAsyncFunction<
    void,
    unknown,
    void,
    unknown
  >;
  downloadMemberIAMRoleCft: UseMutateAsyncFunction<
    void,
    unknown,
    void,
    unknown
  >;
  disabled: boolean;
  isDownloadingMemberCft: boolean;
  isDownloadingManagementCft: boolean;
  isMemberCftDownloaded: boolean;
  isManagementCftDownloaded: boolean;
  setIsOpenConfigHelper: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMemberIAMRoleSteps: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ConfigHelpers({
  downloadMemberIAMRoleCft,
  downloadManagementIAMRoleCft,
  disabled,
  isDownloadingMemberCft,
  isDownloadingManagementCft,
  setIsOpenConfigHelper,
  setShowMemberIAMRoleSteps,
  isMemberCftDownloaded,
  isManagementCftDownloaded,
}: ConfigHelpersProps) {
  return (
    <Body as="div" addClassName="flex space-x-4 pt-2">
      <CFTButton
        dataSelector="download-management-cft-link"
        disabled={disabled}
        external={false}
        isLoading={isDownloadingManagementCft}
        isFileDownloaded={isManagementCftDownloaded}
        onButtonClick={downloadManagementIAMRoleCft}
        onStepClick={() => {
          setIsOpenConfigHelper(true);
          setShowMemberIAMRoleSteps(false);
        }}
        title={
          <FormattedMessage
            defaultMessage="Download Management IAM Role CFT"
            id="ucjKpF"
            description="AWS cft download link"
          />
        }
      />
      <CFTButton
        dataSelector="download-member-cft-link"
        disabled={disabled}
        external={false}
        isLoading={isDownloadingMemberCft}
        isFileDownloaded={isMemberCftDownloaded}
        onButtonClick={downloadMemberIAMRoleCft}
        onStepClick={() => {
          setIsOpenConfigHelper(true);
          setShowMemberIAMRoleSteps(true);
        }}
        title={
          <FormattedMessage
            defaultMessage="Download Member IAM Role CFT"
            id="xrfuGS"
            description="AWS cft download link"
          />
        }
      />
    </Body>
  );
}
