import { classNames } from "utils";

type HorizontalDividerProps = {
  addClassName?: string;
  use1pxBorder?: boolean;
};

export function HorizontalDivider({
  addClassName,
  use1pxBorder,
}: HorizontalDividerProps) {
  return (
    <div
      className={classNames(
        use1pxBorder ? "border-b" : "border-b-2",
        "h-1 border-gray-200 dark:border-blue-steel-850",
        addClassName,
      )}
    ></div>
  );
}
