/* c8 ignore start */

export type XRedLockStatus<E> = Array<{
  i18nKey: E;
  subject: string;
}>;

export type XRedlockRequestId = string;

export type ErrorResponse = Pick<
  Response,
  "ok" | "bodyUsed" | "status" | "redirected" | "statusText" | "type" | "url"
> & {
  headers: Record<string, string>;
  body: unknown;
};

export type ApiError<E = void> = Omit<ErrorResponse, "headers" | "body"> & {
  headers: {
    "x-redlock-status": XRedLockStatus<E>;
    "x-redlock-request-id": XRedlockRequestId;
    "x-record-count"?: number;
  };
  body: { message?: E; error?: E };
};

export class ApiErrorClass extends Error {
  bodyUsed: ErrorResponse["bodyUsed"];
  headers: ErrorResponse["headers"];
  ok: ErrorResponse["ok"];
  redirected: ErrorResponse["redirected"];
  status: ErrorResponse["status"];
  statusText: ErrorResponse["statusText"];
  type: ErrorResponse["type"];
  url: ErrorResponse["url"];
  body: ErrorResponse["body"];

  constructor({
    bodyUsed,
    headers,
    ok,
    redirected,
    status,
    statusText,
    type,
    url,
    body,
  }: ErrorResponse) {
    super(
      `url: ${url} status: ${status} statusText: ${statusText} x-redlock-status: ${headers["x-redlock-status"]}`,
    );

    this.bodyUsed = bodyUsed;
    this.headers = headers;
    this.ok = ok;
    this.redirected = redirected;
    this.status = status;
    this.statusText = statusText;
    this.type = type;
    this.url = url;
    this.body = body;
  }
}

export type ApiSearchError<E = void> = Omit<
  ErrorResponse,
  "headers" | "body"
> & {
  headers: {
    "x-redlock-status": XRedLockStatus<E>;
    "x-redlock-request-id": XRedlockRequestId;
  };
  body: {
    error?: {
      code: string;
      message: string;
      target?: string;
      details?: Error[];
    };
  };
};
