import { useEffect, useRef } from "react";

/**
 * Get the previous value (was passed into hook on last render)
 */
export function usePrevious<T>(value: T): T {
  const ref: React.MutableRefObject<T> = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
