import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, type ReactElement } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";

import { Checkbox, Select } from "form";
import { Button, Tooltip } from "ui";

import { useQuery } from "@tanstack/react-query";
import { InfoCircleIcon, LoadingIcon } from "icons";
import { isNil } from "remeda";
import {
  awsFeatureKeys,
  getAWSFeature,
  getBCRepositories,
  rolesKeys,
  type BCRepositories,
} from "requests";
import { type InitialValues } from "../roleTypes";
import { hideRepositories, sortByKey } from "./utils";

type RespositoryInfo = {
  id: string;
  source: string;
  repository: string;
  owner: string;
  name?: string;
};

const REPOSITORY_LIMIT = 1500;

export default function RepositoriesField({
  initialValues,
  role,
}: {
  initialValues?: InitialValues;
  role: string;
}) {
  const intl = useIntl();
  const { pcBridgecrewEnabled } = useFlags();

  const { watch, setValue } = useFormContext();
  const additionalAttributes = watch(
    "additionalAttributes",
    initialValues
      ? initialValues?.additionalAttributes
      : { onlyAllowCIAccess: false, onlyAllowComputeAccess: false },
  );

  const { data: featureStatus } = useQuery({
    queryKey: awsFeatureKeys.awsFeature(),
    queryFn: getAWSFeature,
    staleTime: 6 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });

  const isBCEnabled = !!featureStatus?.is_bc_service_enabled;

  const enableRepositories = pcBridgecrewEnabled && isBCEnabled;

  const { data = [], isLoading } = useQuery({
    queryKey: rolesKeys.bcRepositories(),
    queryFn: getBCRepositories,
    enabled: enableRepositories,
    staleTime: 60000,
  });

  const repositories = useMemo(
    () =>
      !isLoading && !isNil(data)
        ? sortByKey(data, "repository").map((r: RespositoryInfo) => ({
            value: r.id,
            context: {
              id: r.id,
              source: r.source,
              name: r.repository,
              owner: r.owner,
              repository: r.repository,
            },
            children: (
              <div className="flex justify-between">
                <div className="w-3/5 truncate">{r.repository}</div>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <div className="w-2/5 truncate text-2xs text-secondary">{`${r.source} (${r.owner})`}</div>
              </div>
            ),
          }))
        : [],
    [data, isLoading],
  );

  const defaultValues = useMemo(
    () =>
      initialValues?.codeRepositoryIds &&
      initialValues?.codeRepositoryIds.length > 0 &&
      !isLoading
        ? repositories.filter(
            (value: {
              value: string;
              context: RespositoryInfo;
              children: ReactElement;
            }) => {
              const id = value.context.id;
              return initialValues?.codeRepositoryIds?.indexOf(id) !== -1;
            },
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const hideRepositoriesField =
    !enableRepositories || hideRepositories(role, additionalAttributes);

  useEffect(() => {
    if (hideRepositoriesField) setValue("repositories", []);
  }, [additionalAttributes, hideRepositoriesField, setValue]);

  const defaultValueForSelectAll =
    initialValues?.codeRepositoryIds?.length === data?.length;

  const selectAllRepositoriesValue = useWatch({
    name: "selectAllRepositories",
    defaultValue: defaultValueForSelectAll,
  });

  if (hideRepositoriesField) return null;

  const selectAllLabel = intl.formatMessage({
    defaultMessage: "Select All Repositories",
    id: "oYfjHz",
    description: "select all Repositories",
  });

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      {data?.length >= REPOSITORY_LIMIT && (
        <div className="flex flex-row items-center space-x-2">
          <Checkbox
            name="selectAllRepositories"
            label={selectAllLabel}
            defaultValue={defaultValueForSelectAll}
            onChange={(event) => {
              if (!event.target.checked) {
                setValue("repositories", []);
              }
            }}
          />
          <Tooltip
            label={intl.formatMessage({
              defaultMessage:
                "This option selects all repositories that are defined on the platform at present.",
              id: "GYLdlV",
              description: "Toggle tooltip",
            })}
          >
            <span>
              <InfoCircleIcon className="text-icon-gray" />
            </span>
          </Tooltip>
        </div>
      )}
      <SelectField
        {...{
          repositories,
          defaultValues,
          isLoading: !!isLoading,
          data,
          selectAllRepositoriesValue,
        }}
      />
    </>
  );
}

function SelectField({
  repositories,
  defaultValues,
  isLoading,
  data,
  selectAllRepositoriesValue = false,
}: {
  repositories?:
    | {
        value: string;
        context: RespositoryInfo;
        children: ReactElement;
      }[]
    | [];
  defaultValues?:
    | {
        value: string;
        context: RespositoryInfo;
        children: ReactElement;
      }[]
    | [];
  isLoading: boolean;
  data: BCRepositories;
  selectAllRepositoriesValue: boolean;
}) {
  const intl = useIntl();
  const selectedItems = useWatch({
    name: "repositories",
    defaultValue: defaultValues,
  });

  return (
    <Select
      button={
        <Button isMenu fullWidth>
          {selectedItems
            .map(
              ({ context }: { context: RespositoryInfo }) =>
                `${context.name} ${context.source} (${context.owner})`,
            )
            .join(", ")}
        </Button>
      }
      enableMultiSelect
      enableSearch
      enableSelectAll={data?.length < REPOSITORY_LIMIT}
      label={intl.formatMessage({
        defaultMessage: "Repositories",
        id: "sktiZH",
        description: "Label for repositories field",
      })}
      isLoading={isLoading}
      name="repositories"
      defaultValue={defaultValues}
      items={repositories}
      itemToString={(item) =>
        `${item?.context?.name} ${item?.context?.owner} ${item?.context?.source}`
      }
      register={{ required: false }}
      disabled={
        isLoading ||
        (data?.length >= REPOSITORY_LIMIT && selectAllRepositoriesValue)
      }
      menuStyle={{ minWidth: "34rem" }}
    />
  );
}
