import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const FileMedicalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 15 15"}>
      <path
        d="M8.75 4.46875V0.75H3.28125C2.89844 0.75 2.625 1.05078 2.625 1.40625V7.75H1.09375C0.957031 7.75 0.875 7.85938 0.875 7.96875V8.40625C0.875 8.54297 0.957031 8.625 1.09375 8.625H4.92188C5.00391 8.625 5.08594 8.67969 5.14062 8.76172L5.6875 9.85547L7.21875 6.73828C7.30078 6.57422 7.54688 6.57422 7.62891 6.73828L8.55859 8.625H10.5C10.7188 8.625 10.9375 8.84375 10.9375 9.0625C10.9375 9.30859 10.7188 9.5 10.5 9.5H8.03906L7.4375 8.29688L5.87891 11.4141C5.79688 11.5781 5.55078 11.5781 5.46875 11.4141L4.53906 9.5H2.625V14.0938C2.625 14.4766 2.89844 14.75 3.28125 14.75H12.4688C12.8242 14.75 13.125 14.4766 13.125 14.0938V5.125H9.40625C9.02344 5.125 8.75 4.85156 8.75 4.46875ZM12.9336 3.62109L10.2539 0.941406C10.1172 0.832031 9.95312 0.75 9.78906 0.75H9.625V4.25H13.125V4.08594C13.125 3.92188 13.043 3.75781 12.9336 3.62109Z"
        fill="#707070"
      />
    </SvgIcon>
  );
};
