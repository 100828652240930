import { FormattedMessage, useIntl } from "react-intl";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../../components";

const manageFeatures: Features[] = [
  {
    featureName: "computePolicyCloud",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeMonitorCloud",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeSystemLogs",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeManageDefenders",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeManageAlerts",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeCollections",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeManageCreds",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeAuth",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeSystemOperations",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computePrivilegedOperations",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeDownloads",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
];

export function ManageTab() {
  const intl = useIntl();
  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(manageFeatures);

  return (
    <div className="divide-y divide-primary">
      <Row
        label={intl.formatMessage({
          defaultMessage: "Select All",
          id: "aYkfGO",
          description: "Select all checkbox row",
        })}
        addClassName="border-b-2"
        read={
          <SelectAllCheckbox
            status={selectionStatus.read}
            onChange={() => {
              onClickSelectAll("READ");
            }}
          >
            <FormattedMessage
              defaultMessage="Select view for all compute manage rows"
              id="CUmTWj"
              description="Permission group section header"
            />
          </SelectAllCheckbox>
        }
        update={
          <SelectAllCheckbox
            status={selectionStatus.update}
            onChange={() => {
              onClickSelectAll("UPDATE");
            }}
          >
            <FormattedMessage
              defaultMessage="Select update for all compute manage rows"
              id="f0A3KQ"
              description="Permission group section header"
            />
          </SelectAllCheckbox>
        }
      />

      {manageFeatures.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}
    </div>
  );
}
