import { FormattedMessage } from "react-intl";
import { Step3Icon } from "../../../icons";

import { StepNumber } from "./AWSConsoleSteps";
export default function Step2() {
  return (
    <div className="flex">
      <span className="inline-block">
        <Step3Icon size="5xl" />
      </span>
      <span className="mx-2 inline-block">
        <StepNumber stepNumber={3} />
      </span>
      <span className="mt-2 inline-block text-xs">
        <FormattedMessage
          defaultMessage="Return to Prisma Cloud to continue your Code to Cloud journey."
          id="YtOt6t"
          description="Connect to the AWS Console Step3 description"
        />
      </span>
    </div>
  );
}
