import { type ReactNode } from "react";
import { classNames } from "utils";
import { Tooltip } from "../Tooltip";

const cardIconAppearanceVariants = {
  default: "bg-blue-100 dark:bg-blue-steel-950",
  provider: "bg-transparent shadow",
};

interface CardIconProps {
  /**
   * Tooltip content to display when hovering over the icon.
   */
  tooltip?: ReactNode;
  /**
   * The icon to display.
   */
  icon: ReactNode;
  /**
   * The appearance variant for the card icon.
   */
  appearance?: keyof typeof cardIconAppearanceVariants;
  /**
   * Additional class names to apply.
   */
  addClassName?: string;
}
/**
 * An icon wrapper suitable for both expandable and standard cards.
 */
export function CardIcon({
  tooltip,
  icon,
  appearance = "default",
  addClassName,
}: CardIconProps) {
  const className = classNames(
    "flex h-10 w-10 items-center justify-center rounded",
    cardIconAppearanceVariants[appearance],
    addClassName,
  );

  return (
    <div className={className}>
      <Tooltip label={tooltip} isVisible={!tooltip ? false : undefined}>
        <span className="text-lg">{icon}</span>
      </Tooltip>
    </div>
  );
}
