import { LoadingIcon } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";

export function LoadingPage(): ReactElement {
  const intl = useIntl();
  return (
    <div className="flex grow items-center justify-center">
      <LoadingIcon
        size="5xl"
        aria-label={intl.formatMessage({
          defaultMessage: "Loading Page",
          id: "eDknPP",

          description: "Loading Icon label",
        })}
      />
    </div>
  );
}

export function LoadingIconOnFilterBackground(): ReactElement {
  return (
    <>
      <div className="absolute inset-0 z-10 bg-white/50 dark:bg-white/10" />
      <div className="absolute inset-0 z-20 flex items-center justify-center">
        <LoadingIcon size="5xl" />
      </div>
    </>
  );
}
