import { type Dispatch, type SetStateAction } from "react";
import { type SelectItem } from "ui";
import BucketInput from "./subcomponents/BucketInput";
import BucketSelect from "./subcomponents/BucketSelect";
import BucketType from "./subcomponents/BucketType";

type BucketRowPropTypes = {
  accountId: string;
  bucketItems: SelectItem[];
  cloudTrailBucket: string | undefined;
  disabled: boolean;
  externalId: string;
  roleArn: string;
  bucketType: SelectItem;
  setBucketType: Dispatch<SetStateAction<SelectItem>>;
  setCloudTrailBucket: Dispatch<SetStateAction<string | undefined>>;
  showClTrailSelect: boolean;
};

export default function BucketRow({
  accountId,
  bucketItems = [],
  cloudTrailBucket = "",
  disabled,
  externalId,
  roleArn,
  bucketType,
  setBucketType,
  setCloudTrailBucket,
  showClTrailSelect,
}: BucketRowPropTypes) {
  const showSelect =
    showClTrailSelect || bucketType.value === "Select Existing";

  const disableBucketSelect = !showSelect || disabled || !bucketItems?.length;

  return (
    <div className="mb-4 mt-1 flex w-full">
      <div className="w-1/4">
        <BucketType
          disabled={disabled}
          selectedItem={bucketType}
          setCloudTrailBucket={setCloudTrailBucket}
          setSelectedType={setBucketType}
        />
      </div>
      <div className="mx-2 w-3/4">
        {showSelect && (
          <BucketSelect
            cloudTrailBucket={cloudTrailBucket}
            disabled={disableBucketSelect}
            items={bucketItems}
            setCloudTrailBucket={setCloudTrailBucket}
          />
        )}
        {!showSelect && (
          <BucketInput
            accountId={accountId}
            cloudTrailBucket={cloudTrailBucket}
            disabled={showSelect || disabled}
            externalId={externalId}
            roleArn={roleArn}
            setCloudTrailBucket={setCloudTrailBucket}
          />
        )}
      </div>
    </div>
  );
}
