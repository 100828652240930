import {
  type SecurityCapabilitiesType,
  type SecurityCapabilityValuesType,
} from "requests";
import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import {
  ACCOUNT,
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  CONFIGURE_ACCOUNT,
  ENABLED,
  ERRORS,
  EXTERNAL_ACCOUNT_KEY,
  GCP,
  GCP_ACCOUNT_GROUP_CREATION_MODE,
  GCP_SELECTED_NODES_INITIAL,
  GET_STARTED,
  IS_EDIT,
  MASTER_SERVICE_ACCOUNT,
  NAME,
  ORGANIZATION,
  ORG_ID,
  ORG_NAME,
  REVIEW_STATUS,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
  SERVICE_ACCOUNT,
  SERVICE_ACCOUNT_KEY,
  VALUE,
  WIZARD_HEIGHT,
  WORKSPACE_DOMAIN,
} from "../../../constants";
import {
  useGetDefaultAccountGroupId,
  useGetGcpCloudAccountsCas,
} from "../../../hooks";
import { stepTitle, wizardTitle } from "../../../messages/commonMessages";

import { fromJS } from "immutable";
import { useGetGcpFeatureFlags, useIsBusinessLicenseType } from "prisma";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";
import LoadingModal from "../../../components/LoadingModal";
import { type OnboardingProps } from "../../../types";
import { ActiveStepTitle } from "../components";
import ConfigureAccount from "./ConfigureAccount";
import GetStarted from "./GetStarted";
import ReviewStatus from "./ReviewStatus";
import { GCPSelectMonitoredProjectContextProvider } from "./context/GCPSelectMonitoredProjectContext";
import { GCPCredentialsFileContextProvider } from "./context/GcpCredentialsFileContext";

const featureMap: Record<
  string,
  Exclude<
    SecurityCapabilitiesType,
    | "Cloud Visibility Compliance and Governance"
    | "Identity Security"
    | "Threat Detection"
    | "Remediation"
    | "Data Security"
  >
> = {
  "compute-agentless": AGENTLESS_SCAN,
  "compute-auto-protect": AUTO_PROTECT,
  "compute-serverless-scan": SERVERLESS_SCAN,
};

export function GcpOnboarding({
  accountId,
  closeModal,
  isOpen,
  isEdit = false,
  initialActiveIndex = 0,
  fromProvidersPage,
}: OnboardingProps) {
  const intl = useIntl();
  const { isBusinessLicenseType } = useIsBusinessLicenseType();
  const {
    isGcpWifEnabled,
    isFederatedGcpWifEnabled,
    isLoading: isGcpFeatureFlagLoading,
  } = useGetGcpFeatureFlags();

  const { data: gcpData, isLoading } = useGetGcpCloudAccountsCas({
    accountId,
    options: { enabled: isEdit },
  });

  const {
    accountGroupCreationMode = "AUTO",
    authenticationType = SERVICE_ACCOUNT,
    compressionEnabled = false,
    credentials,
    defaultAccountGroupId = "",
    groupIds = [],
    dataflowEnabledProject = "",
    flowLogStorageBucket = "",
    organizationName = "",
    hierarchySelection = [],
    cloudAccount,
  } = gcpData || {};

  const {
    accountType = ORGANIZATION,
    enabled = true,
    features = [],
    name = "Google Cloud Account",
  } = cloudAccount ?? {};
  const isMSA = accountType === MASTER_SERVICE_ACCOUNT;

  const remediation = useMemo(
    () =>
      features.filter(({ featureName }) => featureName === "remediation")[0]
        ?.featureState === "enabled",
    [features],
  );

  const securityCapabilitiesValues = useMemo(
    () =>
      features.reduce(
        (
          values: Record<SecurityCapabilitiesType, boolean>,
          { featureName, featureState },
        ) => {
          /* c8 ignore next */
          if (featureMap[featureName])
            values[featureMap[featureName]] = featureState === "enabled";
          return values;
        },
        {} as Record<SecurityCapabilitiesType, boolean>,
      ),
    [features],
  );

  const orgSecurityCapabilitiesValues = useMemo(
    () =>
      features
        .filter(({ featureName }) => {
          const feature = featureMap[featureName];
          return feature === AGENTLESS_SCAN || feature === SERVERLESS_SCAN;
        })
        .reduce(
          (
            values: Record<SecurityCapabilityValuesType, boolean>,
            { featureName, defaultMemberState },
          ) => {
            featureMap[featureName] === AGENTLESS_SCAN
              ? (values[AGENTLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilitiesValues[AGENTLESS_SCAN] &&
                      !isBusinessLicenseType),
                ))
              : (values[SERVERLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilitiesValues[SERVERLESS_SCAN] &&
                      !isBusinessLicenseType),
                ));
            return values;
          },
          {} as Record<SecurityCapabilityValuesType, boolean>,
        ),
    [features, isBusinessLicenseType, securityCapabilitiesValues],
  );

  const {
    defaultAccountGroupId: newDefaultAccountGroupId = [],
    isLoading: isAccountGroupItemsLoading,
  } = useGetDefaultAccountGroupId();

  const accountGroupDefaultValue = useMemo(() => {
    if (isEdit) {
      if (
        [ORGANIZATION, MASTER_SERVICE_ACCOUNT].includes(accountType) &&
        !isEmpty(defaultAccountGroupId)
      ) {
        return defaultAccountGroupId;
      } else if (
        (accountType === ACCOUNT || accountType === WORKSPACE_DOMAIN) &&
        !isEmpty(groupIds)
      ) {
        return groupIds;
      } else {
        return undefined;
      }
    }
    const id = newDefaultAccountGroupId[0]?.key;
    return accountType === ACCOUNT ? [id] : id;
  }, [
    accountType,
    defaultAccountGroupId,
    groupIds,
    isEdit,
    newDefaultAccountGroupId,
  ]);

  const status = isEdit ? "valid" : "pending";

  const steps = useMemo((): WizardStepState[] => {
    const wizardSteps: WizardStepState[] = [
      {
        Component: GetStarted,
        disabled: false,
        key: GET_STARTED,
        label: intl.formatMessage(stepTitle[GET_STARTED]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountType,
          securityCapabilities: isEdit
            ? { ...securityCapabilitiesValues }
            : { [AGENTLESS_SCAN]: !isBusinessLicenseType },
          orgSecurityCapabilities: isEdit
            ? { ...orgSecurityCapabilitiesValues }
            : {
                [AGENTLESS_DEFAULT_MEMBER_STATE]: !isBusinessLicenseType,
                [SERVERLESS_DEFAULT_MEMBER_STATE]: !isBusinessLicenseType,
              },
        },
      },
      {
        Component: ConfigureAccount,
        disabled: !isEdit,
        key: CONFIGURE_ACCOUNT,
        label: intl.formatMessage(stepTitle[CONFIGURE_ACCOUNT]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountGroupCreationMode,
          autoOnboardProjects: isMSA,
          authenticationType,
          dataFlowCheckbox: compressionEnabled,
          credentials: credentials ?? {},
          dataflowEnabledProject: isBusinessLicenseType
            ? ""
            : dataflowEnabledProject,
          flowLogStorageBucket: isBusinessLicenseType
            ? ""
            : flowLogStorageBucket,
          groupIds: accountGroupDefaultValue,
          ...(accountType === ORGANIZATION && { hierarchySelection }),
          name,
          organizationId: accountId,
          projectId: accountId,
          remediation,
        },
      },
      {
        Component: ReviewStatus,
        disabled: !isEdit,
        key: REVIEW_STATUS,
        label: intl.formatMessage(stepTitle[REVIEW_STATUS]),
        hasBeenSubmitted: isEdit,
        status,
      },
    ];
    return wizardSteps;
  }, [
    intl,
    isEdit,
    status,
    accountType,
    securityCapabilitiesValues,
    isBusinessLicenseType,
    accountGroupCreationMode,
    isMSA,
    authenticationType,
    compressionEnabled,
    credentials,
    dataflowEnabledProject,
    flowLogStorageBucket,
    accountGroupDefaultValue,
    hierarchySelection,
    name,
    accountId,
    remediation,
    orgSecurityCapabilitiesValues,
  ]);

  const fileUploadInitialState = useMemo(() => {
    const key = credentials
      ? credentials["type"] === SERVICE_ACCOUNT
        ? SERVICE_ACCOUNT_KEY
        : EXTERNAL_ACCOUNT_KEY
      : "";
    return {
      [key]: { value: credentials ?? {}, errors: "", name: "", size: "" },
    };
  }, [credentials]);

  const selectProjectInitialData = useMemo(() => {
    if (isEdit && accountType !== ORGANIZATION) return fromJS({});

    return fromJS({
      [ACCOUNT_ID]: {
        [VALUE]: accountId,
        [ERRORS]: [],
      },
      [ACCOUNT_TYPE]: {
        [VALUE]: accountType,
        [ERRORS]: [],
      },
      [ENABLED]: {
        [VALUE]: enabled,
        [ERRORS]: [],
      },
      [NAME]: {
        [VALUE]: name,
        [ERRORS]: [],
      },
      [ORG_ID]: {
        [VALUE]: accountId,
        [ERRORS]: [],
      },
      [ORG_NAME]: {
        [VALUE]: organizationName,
        [ERRORS]: [],
      },
      [IS_EDIT]: { [VALUE]: isEdit },
      [GCP_SELECTED_NODES_INITIAL]: {
        [VALUE]: hierarchySelection,
        [ERRORS]: [],
      },
      [GCP_ACCOUNT_GROUP_CREATION_MODE]: {
        [VALUE]: accountGroupCreationMode,
        [ERRORS]: [],
      },
    });
  }, [
    isEdit,
    accountType,
    accountId,
    enabled,
    name,
    organizationName,
    hierarchySelection,
    accountGroupCreationMode,
  ]);

  const title = (
    <ActiveStepTitle
      label={intl.formatMessage(isEdit ? wizardTitle.edit : wizardTitle.add)}
      cloudType={GCP}
      iconSize={"lg"}
    />
  );

  if (isLoading || isAccountGroupItemsLoading || isGcpFeatureFlagLoading) {
    return <LoadingModal title={title} />;
  }

  return (
    <GCPCredentialsFileContextProvider initialState={fileUploadInitialState}>
      <GCPSelectMonitoredProjectContextProvider
        initialState={selectProjectInitialData}
      >
        <Wizard
          context={{
            accountId,
            closeModal,
            enabled,
            isEdit,
            isGcpWifEnabled,
            isFederatedGcpWifEnabled,
            fromProvidersPage,
          }}
          initialActiveIndex={initialActiveIndex}
          isOpen={isOpen}
          onClose={closeModal}
          size="xl"
          steps={steps}
          style={{ minHeight: WIZARD_HEIGHT }}
          title={title}
        />
      </GCPSelectMonitoredProjectContextProvider>
    </GCPCredentialsFileContextProvider>
  );
}
