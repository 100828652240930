import { usePreferences, ViewPreferencesSchema } from "requests";

export function useAssetInventoryView() {
  const { data, isLoading } = usePreferences(
    "assetsInventory",
    "view",
    ViewPreferencesSchema,
  );

  return {
    isCardsView: data?.view === "cards",
    isLoading,
  };
}
