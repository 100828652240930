import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const ThreatDetectionIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Threat Detection",
    id: "PvYnv8",

    description: "Security Capabilities Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <title>{alt}</title>
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="M11.3477 9.19922L12.0039 7.47656C12.1133 7.20312 11.9219 6.875 11.5938 6.875H10.0078C10.3086 6.38281 10.5 5.78125 10.5 5.125C11.5664 4.90625 12.25 4.60547 12.25 4.25C12.25 3.89453 11.4844 3.56641 10.3359 3.34766C10.0625 2.47266 9.59766 1.57031 9.21484 1.10547C8.94141 0.777344 8.50391 0.667969 8.12109 0.859375L7.38281 1.24219C7.13672 1.35156 6.83594 1.35156 6.58984 1.24219L5.85156 0.859375C5.46875 0.667969 5.03125 0.777344 4.75781 1.10547C4.40234 1.57031 3.91016 2.47266 3.66406 3.34766C2.48828 3.56641 1.75 3.89453 1.75 4.25C1.75 4.60547 2.40625 4.90625 3.5 5.125C3.5 5.78125 3.66406 6.38281 3.96484 6.875H2.40625C2.07812 6.875 1.88672 7.20312 1.99609 7.50391L2.70703 9.14453C1.61328 9.77344 0.875 10.9492 0.875 12.3164V13.5469C0.875 14.2031 1.42188 14.75 2.07812 14.75H11.8945C12.5508 14.75 13.125 14.2031 13.125 13.5469V12.3164C13.125 10.9766 12.4141 9.82812 11.3477 9.19922ZM5.6875 13.875L4.53906 8.625L5.90625 9.5L6.5625 10.5938L5.6875 13.875ZM8.3125 13.875L7.4375 10.5938L8.09375 9.5L9.43359 8.625L8.3125 13.875ZM9.43359 5.72656C9.32422 6.05469 9.24219 6.41016 8.99609 6.62891C8.72266 6.90234 7.68359 7.25781 7.24609 5.94531C7.16406 5.72656 6.80859 5.72656 6.72656 5.94531C6.26172 7.33984 5.19531 6.84766 4.97656 6.62891C4.73047 6.41016 4.64844 6.05469 4.53906 5.72656C4.51172 5.64453 4.375 5.5625 4.375 5.5625V5.26172C5.14062 5.37109 6.04297 5.42578 7 5.42578C7.95703 5.42578 8.83203 5.37109 9.625 5.26172V5.5625C9.625 5.5625 9.46094 5.64453 9.43359 5.72656Z"
      />
    </SvgIcon>
  );
};
