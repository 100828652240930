import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import { usePermissions } from "prisma";
import { useCallback } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import {
  accessKeys,
  updateAccessKeyStatus,
  useToken,
  type AllAccessKeysResponseObjectSchema,
} from "requests";
import { type RowInstance } from "table";

import { Body } from "ui";
import { classNames } from "utils";
import { type z } from "zod";

const statusMessages = defineMessages({
  active: {
    defaultMessage: "Active",
    id: "XqBMTg",
    description: "Active status message",
  },
  expired: {
    defaultMessage: "Expired",
    id: "OBD0LD",
    description: "Expired status message",
  },
  inactive: {
    defaultMessage: "Inactive",
    id: "11u0OY",
    description: "Inactive status message",
  },
  make_active: {
    defaultMessage: "Make Active",
    id: "UnsYbi",
    description: "Make Active status message",
  },
  make_inactive: {
    defaultMessage: "Make Inactive",
    id: "m2pp56",
    description: "Make Inactive status message",
  },
});

export default function Status({
  row,
  value,
}: {
  row: RowInstance<z.infer<typeof AllAccessKeysResponseObjectSchema>>;
  value: string;
}) {
  const token = useToken();
  const queryClient = useQueryClient();

  const {
    settingsAccessKeys: { UPDATE: permissionAllowed },
  } = usePermissions();

  const currentUsersRole = row.original.username === token.username;
  const updateAllowed = !!(currentUsersRole || permissionAllowed);
  const { mutate: updateStatus, isPending } = useMutation({
    mutationFn: (params: { id: string; value: boolean }) =>
      updateAccessKeyStatus(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accessKeys.all });
    },
  });
  const statusClasses = classNames(
    "mr-1",
    value === "active" && "text-green dark:text-dark-bg-green",
    value === "inactive" && "text-orange dark:text-dark-bg-orange",
    value === "expired" && "text-red dark:text-dark-bg-red",
  );

  const isActive = value === "active";

  const handleStatusToggle = useCallback(
    () => updateStatus({ id: row.original.id, value: !isActive }),
    [isActive, row.original.id, updateStatus],
  );

  return (
    <>
      <span className={statusClasses}>
        <FormattedMessage
          {...statusMessages[value as keyof typeof statusMessages]}
        />
      </span>
      {value !== "expired" && updateAllowed && (
        <>
          {isPending ? (
            <LoadingIcon />
          ) : (
            <Body
              appearance="link"
              as="button"
              className="ml-2"
              onClick={handleStatusToggle}
            >
              <FormattedMessage
                {...statusMessages[
                  `make_${value === "inactive" ? "active" : "inactive"}`
                ]}
              />
            </Body>
          )}
        </>
      )}
    </>
  );
}
