import { WizardStepForm } from "form";
import { useIntl } from "react-intl";
import { Body } from "ui";
import { AssignPermissionsBody } from "./AssignPermissionsBody";

export const AssignPermissions = () => {
  const intl = useIntl();

  return (
    <WizardStepForm>
      <Body addClassName="mb-4">
        {intl.formatMessage({
          defaultMessage:
            "Assign permissions to this group by selecting which sections and/or features a role will be able to View, Create, Update or Delete. Please note that by selecting Create, Update, or Delete for a feature, View permissions will be automatically granted.",
          id: "pFMxEZ",
          description: "Sub header explaining permission groups",
        })}
      </Body>
      <AssignPermissionsBody />
    </WizardStepForm>
  );
};
