import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { ComplianceRequirementsFiltersSchema } from "requests";
import { z } from "zod";
import { pageTableProviderSchema } from "../../../utils/PageTableProvider";
import { complianceStandardsLayoutRoute } from "../complianceStandardsLayoutRoute";

// import ComplianceRequirements from "./ComplianceRequirements";
const ComplianceRequirements = lazy(() => import("./ComplianceRequirements"));

export const ComplianceRequirementsSearchSchema = z
  .object({
    filters: ComplianceRequirementsFiltersSchema.optional(),
    // This is needed for breadcrumb to go back to standards
    standards: z
      .object({ viewId: z.optional(z.string().catch("")) })
      .merge(pageTableProviderSchema)
      .optional(),
  })
  .merge(pageTableProviderSchema);

export const complianceRequirementsLayoutRoute = createRoute({
  getParentRoute: () => complianceStandardsLayoutRoute,
  path: "$standardId/requirements",
});

export const complianceRequirementsRoute = createRoute({
  getParentRoute: () => complianceRequirementsLayoutRoute,
  path: "/",
  component: ComplianceRequirements,
  validateSearch: ComplianceRequirementsSearchSchema,
});
