import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { cloudAccountsKeys, updateSupportedFeatures } from "requests";
import { useToastActions } from "stores";
import { AWS, AZURE, GCP } from "../constants";
import { type AccountType, type CloudType } from "../types";

type UseDisableFeatureProps = {
  accountId: string;
  accountType: AccountType;
  cloudType: CloudType;
  featureKey: string;
  setIsFeatureEnabled: (value: boolean) => void;
};

export function useDisableFeature({
  accountId,
  accountType,
  cloudType,
  featureKey,
  setIsFeatureEnabled,
}: UseDisableFeatureProps) {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { toast } = useToastActions();
  const { mutate: update, isPending } = useMutation({
    mutationFn: async () => {
      try {
        await updateSupportedFeatures({ accountId, featureKey });
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsCas({
            accountId,
            cloudType,
          }),
        });
        if (cloudType === AWS)
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.awsCloudAccountsCas({ accountId }),
          });
        if (cloudType === AZURE)
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.azureCloudAccountsCas({ accountId }),
          });
        if (cloudType === GCP)
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.gcpCloudAccountsCas({ accountId }),
          });

        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.supportedFeatures({
            cloudType,
            payload: {
              accountType,
            },
          }),
        });
        setIsFeatureEnabled(false);
      } catch (error) {
        toast(
          intl.formatMessage({
            defaultMessage:
              "Something went wrong. Please contact your Prisma Cloud support team.",
            id: "727FsV",
            description: "warning toast message",
          }),
          { appearance: "warning" },
        );
      }
    },
  });

  return { update, isLoading: isPending };
}
