import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { DataInventoryFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";
import { BreadcrumbsSchema, TableLevelEnumSchema } from "../types";

export const ResourceLayout = lazy(() => import("./ResourceLayout"));

export const DataInventoryResourceSearchSchema = z.object({
  filters: DataInventoryFilterSchema.catch({}).optional(),
  tableLevel: TableLevelEnumSchema.optional(),
  breadcrumbs: BreadcrumbsSchema,
});

export const resourceLayoutRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "dataResource/$resourceName",
  component: ResourceLayout,
  validateSearch: DataInventoryResourceSearchSchema,
});
