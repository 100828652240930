import { fromJS } from "immutable";
import { useReducer, type ReactNode } from "react";

import { createCtx } from "ui";

import {
  gcpSelectMonitoredProjectsInitialState,
  type GcpSelectMonitoredProjectsInitialStateType,
} from "./state/initialState/initialState";
import { gcpSelectMonitoredProjectsReducer } from "./state/reducer/reducer";

type UseGCPSelectMonitoredProjectsContextType = {
  state: GcpSelectMonitoredProjectsInitialStateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
};

const [useGCPSelectMonitoredProjectsContext, CtxProvider] =
  createCtx<UseGCPSelectMonitoredProjectsContextType>();

export { useGCPSelectMonitoredProjectsContext };

type GCPSelectMonitoredProjectContextProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any;
  children: ReactNode;
};

export function GCPSelectMonitoredProjectContextProvider({
  children,
  initialState,
}: GCPSelectMonitoredProjectContextProviderProps) {
  const [state, dispatch] = useReducer(
    gcpSelectMonitoredProjectsReducer,
    fromJS({
      ...gcpSelectMonitoredProjectsInitialState,
    }).merge(initialState),
  );

  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>;
}
