import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";

const appearanceVariants = {
  bold: "font-bold",
  semibold: "font-semibold",
};

export type BoldProps<T = Omit<ComponentPropsWithoutRef<"span">, "className">> =
  T & {
    /**
     * Add a class name to the element.
     */
    addClassName?: string;
    /**
     * How bold the text should be.
     */
    appearance?: keyof typeof appearanceVariants;
    children: ReactNode;
    /**
     * The type of element to render.
     *
     * By default, `Bold` renders a `span` element, but any element type can be used.
     */
    as?: ElementType;
  };

/**
 * Renders its children in **bold** font.
 *
 *  ### Import Guide
 *
 * ```jsx
 * import { Bold } from "ui";
 * ```
 */
export function Bold<T = Omit<ComponentPropsWithoutRef<"span">, "className">>({
  addClassName,
  appearance = "bold",
  children,
  as: Component = "span",
  ...passThrough
}: BoldProps<T>): ReactElement {
  const className = classNames(appearanceVariants[appearance], addClassName);

  return (
    <Component {...passThrough} className={className}>
      {children}
    </Component>
  );
}
