import {
  autoUpdate,
  flip,
  limitShift,
  offset,
  shift,
} from "@floating-ui/react";
import {
  Children,
  cloneElement,
  useEffect,
  useRef,
  useState,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";
import { classNames } from "utils";
import { v4 as uuidV4 } from "uuid";
import { useFloating, type UseFloatingOptions } from "../../utils";
import { Portal } from "../Portal";

export interface TooltipProps
  extends Omit<ComponentPropsWithoutRef<"div">, "className" | "style"> {
  /**
   * An interactive element that will trigger the tooltip when focused or hovered on.
   */
  children: ReactNode;
  isVisible?: boolean;
  /**
   * Enables the glass effect. Disabled by default.
   */
  backdropBlur?: boolean;
  /**
   * The primary content of the tooltip.
   */
  label: ReactNode;
  /**
   * Options passed to useFloating, mainly to set the placement and poitioning strategy.
   *  https://floating-ui.com/docs/usefloating
   *
   * type Placement =
   * | 'auto'
   * | 'auto-start'
   * | 'auto-end'
   * | 'top'
   * | 'top-start'
   * | 'top-end'
   * | 'bottom'
   * | 'bottom-start'
   * | 'bottom-end'
   * | 'right'
   * | 'right-start'
   * | 'right-end'
   * | 'left'
   * | 'left-start'
   * | 'left-end';
   * type Strategy = 'absolute' | 'fixed';
   */
  popperOptions?: Partial<UseFloatingOptions>;
  /**
   * Render the children directly without the border and padding.
   */
  noContainer?: boolean;
}

/**
 * Tooltips displays additional information in a popup when users hover over or focus on an element.
 *
 * The element should be an interactive element, like a button or link; not a div or span.
 *
 * The element is connected to the tooltip via the `aria-describedby` attribute.
 *
 * Best practices:
 * * Don’t use tooltips for information that is vital to task completion.
 * * Avoid adding interactive content to a tooltip. Use a Modal or Dialog instead.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Tooltip } from "ui";
 * ```
 *
 */
export function Tooltip({
  label,
  children,
  isVisible,
  popperOptions,
  backdropBlur,
  noContainer,
}: TooltipProps): ReactElement {
  const child = Children.only(children) as ReactElement;
  const { setReference, setFloating, floatingStyles } = useFloating({
    middleware: [offset(0), flip(), shift({ limiter: limitShift() })],
    whileElementsMounted: autoUpdate,
    ...popperOptions,
  });
  const [isOpen, setIsOpen] = useState(false);
  const keyboardWasLastUsed = useRef(false);
  const containerId = useRef(uuidV4());
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const handlePointerEnter = () => {
    timerRef.current = setTimeout(() => setIsOpen(true), 300);
  };

  const handlePointerLeave = () => {
    setIsOpen(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <>
      {children &&
        cloneElement(child, {
          ref: setReference,
          "data-state": `tooltip-${isVisible ?? isOpen ? "visible" : "hidden"}`,
          onFocus: (event: FocusEvent) => {
            if (event.relatedTarget) {
              keyboardWasLastUsed.current = true;
            }
            keyboardWasLastUsed.current && setIsOpen(true);
          },
          onBlur: () => setIsOpen(false),
          onPointerUp: () => {
            keyboardWasLastUsed.current = false;
            handlePointerLeave();
          },
          onPointerEnter: handlePointerEnter,
          onPointerLeave: handlePointerLeave,
          "aria-describedby": isOpen ? containerId.current : undefined,
        })}

      <Portal>
        {(isVisible ?? isOpen) && (
          <div
            role="tooltip"
            id={containerId.current}
            ref={setFloating}
            className="pointer-events-none z-40"
            style={floatingStyles}
          >
            {noContainer ? (
              label
            ) : (
              <div
                className={classNames(
                  "border text-default dark:border-blue-steel-850  dark:text-dark-bg",
                  "z-40 m-2 max-w-lg break-words rounded p-2 text-xs shadow-md",
                  backdropBlur
                    ? "bg-white/30 backdrop-blur-xl dark:bg-blue-steel-950/30"
                    : "bg-white dark:bg-blue-steel-950",
                )}
              >
                {label}
              </div>
            )}
          </div>
        )}
      </Portal>
    </>
  );
}
