import { useFlags } from "launchdarkly-react-client-sdk";
import { determineAssetExplorerApiVersion } from "requests";

export function useAssetExplorerApiVersion(apiVersionOverride?: number) {
  const {
    assetAggDisableDateFilter: useV2Api,
    assetExplorerApiVersion: ldApiVersion,
  } = useFlags();

  return determineAssetExplorerApiVersion({
    requestedApiVersion: apiVersionOverride ?? ldApiVersion,
    useV2Api,
  });
}
