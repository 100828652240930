import { isNil, isTruthy } from "remeda";

import { useTenantsFeatures } from "prisma";

type ProvisionFeatures =
  | "pcn"
  | "compute"
  | "asm"
  | "iam-security"
  | "bridgecrew";

export function useASMProvisionStatus() {
  const { isLoading, isError, data } = useTenantsFeatures();

  const asmFeature = data?.find(
    ({ feature_name }) => (feature_name as ProvisionFeatures) === "asm",
  );

  const {
    state,
    enabled,
    additional_data,
    billing_type = "TRIAL",
    created_ts,
    end_ts,
  } = asmFeature || {};

  const { asm_scan_completed, pilot = false } = additional_data || {};

  const isFeatureProvisionedSuccessful = state === "PROVISION_SUCCESSFUL";

  const isFeatureProvisioned =
    (isFeatureProvisionedSuccessful && enabled) ?? false;

  const isFeatureExists = !isNil(asmFeature) && isTruthy(asmFeature);

  return {
    isLoading,
    isFeatureProvisioned,
    isFeatureExists,
    isFeatureProvisionedSuccessful,
    isTrail: billing_type === "TRIAL",
    isAsmScanCompleted: asm_scan_completed,
    isError,
    createdTimestamp: created_ts,
    endTimestamp: end_ts,
    isPilot: pilot as boolean,
  };
}
