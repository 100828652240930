import { createRoute } from "@tanstack/react-router";
import { isDevelopment } from "environment";
import { lazy } from "react";
import { ComplianceStandardsFiltersSchema } from "requests";
import { complianceStandardsSavedViews } from "saved-views-next";
import { z } from "zod";
import { pageTableProviderSchema } from "../../../utils/PageTableProvider";
import { complianceStandardsLayoutRoute } from "../complianceStandardsLayoutRoute";

const ComplianceStandards = lazy(() => import("./ComplianceStandards"));

const ComplianceStandardsSearchSchema = pageTableProviderSchema.merge(
  z.object({
    filters: ComplianceStandardsFiltersSchema.catch((ctx: unknown) => {
      if (isDevelopment()) {
        // eslint-disable-next-line no-console
        console.warn(ctx);
      }
      return {};
    }).optional(),
    viewId: z.string().catch("default").optional(),
  }),
);

export type ComplianceStandardsSearchType = z.infer<
  typeof ComplianceStandardsSearchSchema
>;

export const complianceStandardsRoute = createRoute({
  getParentRoute: () => complianceStandardsLayoutRoute,
  path: "/",
  component: ComplianceStandards,
  validateSearch: ComplianceStandardsSearchSchema,
  loaderDeps: ({ search: { viewId } }) => ({ viewId }),
  loader: ({ context, deps: { viewId } }) => {
    context?.queryClient.prefetchQuery(
      complianceStandardsSavedViews.getViewQueryOptions(viewId ?? "default"),
    );
  },
});
