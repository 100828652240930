import { useMemo } from "react";
import { useIntl } from "react-intl";

import { isNil } from "remeda";
import {
  type PermissionsStatus,
  type SecurityCapabilitiesType,
} from "requests";
import { dynamicFormatMessage } from "ui";
import AccountDetails, {
  Authentication,
  Details,
  SecurityCapabilities,
} from "../../../../../components/ReviewStatus/AccountDetails";
import {
  accountDetailsLabelMap,
  scopeMap,
} from "../../../../../messages/reviewStatusMessages";
import { type AccountType } from "../../../../../types";

type Field = {
  renderComponent: (key: string) => JSX.Element;
  key: string;
  label: string;
  transform?: (value: string[] | string) => JSX.Element;
  rightIcon?: JSX.Element;
  defaultCount?: number;
  allowCopy?: boolean;
};

type AccountDetailsStatusProps = {
  accountGroups: string[];
  accountName: string;
  authentication?: PermissionsStatus;
  accountId: string;
  accountType: AccountType;
  svcIdIamId: string;
  securityCapabilities: SecurityCapabilitiesType[];
  isLoading: boolean;
};

export default function AccountDetailsStatus(props: AccountDetailsStatusProps) {
  const intl = useIntl();
  const { securityCapabilities, authentication, isLoading } = props;

  const fieldsMap = useMemo(() => {
    return [
      {
        renderComponent: (key: string) => (
          <Authentication
            key={key}
            data={authentication}
            isCollapsibleDetails={true}
            isAccountDetails
            isLoading={isLoading}
          />
        ),
        key: "authentication",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "authentication",
        ),
      },
      {
        key: "accountType",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "scope"),
        transform: (value: string) =>
          dynamicFormatMessage(intl, scopeMap, value),
      },
      {
        renderComponent: (key: string) => (
          <SecurityCapabilities
            key={key}
            defaultCount={2}
            value={securityCapabilities}
          />
        ),
        defaultCount: 2,
        key: "securityCapabilities",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "securityCapabilities",
        ),
      },
      {
        key: "accountId",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "accountId"),
        allowCopy: true,
      },
      {
        key: "accountName",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountName",
        ),
      },
      {
        key: "svcIdIamId",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "serviceIamId",
        ),
      },
      {
        key: "accountGroups",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountGroups",
        ),
        transform: (value: string[]) =>
          intl.formatMessage(
            {
              defaultMessage: "{count} Account {label} Selected",
              id: "RyshgG",
              description: "Description for selected Account Group with count",
            },
            {
              count: value?.length,
              label: value?.length > 1 ? "Groups" : "Group",
            },
          ),
      },
    ].filter((field) => !isNil(field)) as Field[];
  }, [intl, authentication, securityCapabilities, isLoading]);

  const getContent = (
    transform: Field["transform"],
    key: string,
    value: unknown,
  ): JSX.Element => {
    if (!isNil(transform)) {
      if (key === "accountType") return transform(value as string);
      if (key === "accountGroups") return transform(value as string[]);
    }
    return value as JSX.Element;
  };

  return (
    <AccountDetails>
      {fieldsMap.map(({ key, renderComponent, transform, ...rest }) => {
        return !isNil(renderComponent) ? (
          renderComponent(key)
        ) : (
          <Details key={key} {...rest}>
            {getContent(transform, key, props[key as keyof typeof props])}
          </Details>
        );
      })}
    </AccountDetails>
  );
}
