import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { type FormStatus } from "./types";

export function useFormStatus(): FormStatus {
  const {
    formState: { errors, isValid },
  } = useFormContext();

  const errorsLength = Object.keys(errors).length;

  return useMemo(() => {
    if (isValid) return "valid";
    if (errorsLength) return "error";
    return "pending";
  }, [errorsLength, isValid]);
}
