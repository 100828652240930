import { DataInventoryFilterSchema } from "requests";
import { Breadcrumbs } from "ui";
import { z } from "zod";

export const tableLevels = ["csp", "service", "resource", "object"] as const;

export const TableLevelEnumSchema = z.enum(tableLevels);

export const BreadcrumbsSchema = z
  .array(
    z.object({
      title: z.string().optional(),
      to: z.string().optional(),
      filters: DataInventoryFilterSchema.optional(),
      tableLevel: TableLevelEnumSchema.optional(),
    }),
  )
  .optional();

export type Breadcrumbs = z.infer<typeof BreadcrumbsSchema>;
