import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";

export default function PolicyName({ policyName }: { policyName: string }) {
  const intl = useIntl();
  useTrimField("policyName");

  return (
    <Input
      data-selector="oci-policy-name-input"
      name="policyName"
      label={intl.formatMessage({
        defaultMessage: "Policy Name",
        id: "QTtNci",
        description: "The name of an input field for onboarding an OCI account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "eg - prisma-cloud-policy",
        id: "ij4qHc",
        description: "Placeholder for Policy Name",
      })}
      defaultValue={policyName}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Policy Name is required",
          id: "M3H2uH",
          description: "Error message for field for onboarding a OCI tenant",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "Oracle Cloud Infrastructure Identity Policy Name. New policy will be created (recommended) or you can use an existing policy with right policy statements, refer Terraform template below for details on policy statements",
        id: "mdg30A",
        description: "Tooltip for Policy Name input field",
      })}
    />
  );
}
