import { FormattedMessage, type IntlShape } from "react-intl";
import { Bold, Tag } from "ui";
import {
  ACCOUNT,
  ACTIVE_DIRECTORY,
  ALIBABA_CLOUD,
  AWS,
  AZURE,
  GCP,
  IBM,
  OCI,
  ORGANIZATION,
  TENANT,
  WORKSPACE_DOMAIN,
} from "../../../../constants";

import { type CloudType } from "../../../../types";

export function getAccountTypeItems(
  intl: IntlShape,
  cloudType: CloudType,
  isDisabled?: boolean,
) {
  const AwsItems = [
    {
      value: ORGANIZATION,
      title: intl.formatMessage({
        defaultMessage: "Organization",
        id: "lzEgQJ",
        description: "Description for Organization label",
      }),
      tag: isDisabled ? null : (
        <Bold>
          <Tag>
            <FormattedMessage
              defaultMessage="Recommended"
              id="pnEgtL"
              description="Description for Recommended label"
            />
          </Tag>
        </Bold>
      ),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your AWS Organization including its member accounts"
            id="Y/HeNE"
            description="Description for Secure your AWS Organization including its member accounts"
          />
        </span>
      ),
    },
    {
      value: ACCOUNT,
      title: intl.formatMessage({
        defaultMessage: "Account",
        id: "H/G6Un",
        description: "Description for Account label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your specific AWS account"
            id="XT/Ec3"
            description="Description for Secure your AWS specific account."
          />
        </span>
      ),
    },
  ];

  const GcpItems = [
    {
      value: ORGANIZATION,
      title: intl.formatMessage({
        defaultMessage: "Organization",
        id: "lzEgQJ",
        description: "Description for Organization label",
      }),
      tag: isDisabled ? null : (
        <Bold>
          <Tag>
            <FormattedMessage
              defaultMessage="Recommended"
              id="pnEgtL"
              description="Description for Recommended label"
            />
          </Tag>
        </Bold>
      ),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your GCP Organization and all associated Projects"
            id="bK8MG8"
            description="Description for Secure your GCP Organization including its member accounts"
          />
        </span>
      ),
    },
    {
      value: ACCOUNT,
      title: intl.formatMessage({
        defaultMessage: "Project",
        id: "MMmIBi",
        description: "Description for Project label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your GCP Project"
            id="ExYpdL"
            description="Description for Secure your GCP specific account."
          />
        </span>
      ),
    },
    {
      value: WORKSPACE_DOMAIN,
      title: intl.formatMessage({
        defaultMessage: "Workspace",
        id: "fayepJ",
        description: "Description for Workspace label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your specific Google Workspace Domain"
            id="QgWsen"
            description="Description for Secure your specific Google Workspace Domain"
          />
        </span>
      ),
    },
  ];

  const AzureItems = [
    {
      value: TENANT,
      title: intl.formatMessage({
        defaultMessage: "Tenant",
        id: "azZedo",
        description: "Description for tenant label",
      }),
      tag: isDisabled ? null : (
        <Bold>
          <Tag>
            <FormattedMessage
              defaultMessage="Recommended"
              id="pnEgtL"
              description="Description for Recommended label"
            />
          </Tag>
        </Bold>
      ),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure all Management Groups, Subscriptions and Active Directory"
            id="eEtEEm"
            description="Description for Tenant Card"
          />
        </span>
      ),
    },
    {
      value: ACCOUNT,
      title: intl.formatMessage({
        defaultMessage: "Subscription",
        id: "hDr70U",
        description: "Description for Account label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your specific Azure Subscription"
            id="PPbnrs"
            description="Description for Secure your Azure specific Subscription."
          />
        </span>
      ),
    },
    {
      value: ACTIVE_DIRECTORY,
      title: intl.formatMessage({
        defaultMessage: "Active Directory",
        id: "nwgG2W",
        description: "Description for Account label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure single dedicated instance of Azure AD"
            id="3einSF"
            description="Description for Secure single dedicated instance of Azure AD."
          />
        </span>
      ),
    },
  ];

  const IbmItems = [
    {
      value: ACCOUNT,
      title: intl.formatMessage({
        defaultMessage: "Account",
        id: "H/G6Un",
        description: "Description for Account label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your IBM Cloud Account."
            id="4nHHNn"
            description="Description for Secure your IBM specific account."
          />
        </span>
      ),
    },
  ];

  const OciItems = [
    {
      value: TENANT,
      title: intl.formatMessage({
        defaultMessage: "Tenant",
        id: "azZedo",
        description: "Description for tenant label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your specific OCI Tenant."
            id="Xisz5j"
            description="Description for Secure your OCI specific account."
          />
        </span>
      ),
    },
  ];

  const AlibabaItems = [
    {
      value: ACCOUNT,
      title: intl.formatMessage({
        defaultMessage: "Account",
        id: "H/G6Un",
        description: "Description for Account label",
      }),
      description: (
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Secure your Alibaba Account"
            id="R9nbrZ"
            description="Description for Secure your Alibaba specific account."
          />
        </span>
      ),
    },
  ];

  switch (cloudType) {
    case AWS:
      return AwsItems;
    case GCP:
      return GcpItems;
    case AZURE:
      return AzureItems;
    case IBM:
      return IbmItems;
    case OCI:
      return OciItems;
    case ALIBABA_CLOUD:
      return AlibabaItems;
    default:
      return [];
  }
}
