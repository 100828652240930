import { FormattedMessage, useIntl } from "react-intl";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormLayout } from "form";
import { useCallback, useEffect, useMemo } from "react";
import { isEmpty, isNil } from "remeda";
import {
  cloudAccountsKeys,
  getGcpCloudAccountStatus,
  saveGcpCloudAccount,
  updateGcpCloudAccount,
  type GcpRequestPayloadType,
  type GcpStatusResponseType,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { useModal } from "ui";
import { snakeCase } from "utils";
import { useCloudAccountPendoGuide } from "../../../../../../../utils/useCloudAccountPendoGuide";
import {
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  CLOUD_ACCOUNT,
  COMPRESSION_ENABLED,
  CONFIGURE_ACCOUNT,
  CREDENTIALS,
  DATA_FLOW_ENABLED_PROJECT_ID,
  DEFAULT_ACCOUNT_GROUP_ID,
  DISABLED,
  ENABLED,
  FEATURES,
  FLOW_LOG_STORAGE_BUCKET,
  GCP,
  GCP_ACCOUNT_GROUP_CREATION_MODE,
  GCP_HIERARCHY_SELECTION,
  GET_STARTED,
  GROUP_IDS,
  MANUAL,
  MASTER_SERVICE_ACCOUNT,
  NAME,
  ORGANIZATION,
  PROJECT_ID,
  REMEDIATION,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
  SERVICE_ACCOUNT,
  WORKSPACE_DOMAIN,
  WORKSPACE_DOMAIN_PAYLOAD,
} from "../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIdentitySubscription,
  useIsRenderedFirstTime,
} from "../../../../hooks";
import {
  type AccountType,
  type GcpConfigureAccountStepValuesType,
  type GcpGetStartedStepValuesType,
} from "../../../../types";
import {
  getAuthenticationModeInfo,
  parseI18nKeyFromResponse,
} from "../../../../utils";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import SaveAndCloseButton from "../../components/SaveAndCloseButton";
import SaveAndOnboardButton from "../../components/SaveAndOnboardButton";
import {
  getAuthenticationData,
  getSecurityCapabilities,
  getWizardStepIndex,
  handleSuccess,
} from "../../utils";
import { useGCPCredentialsFileContext } from "../context/GcpCredentialsFileContext";
import { getProjectIdWif } from "../context/GcpCredentialsFileContext/state/getters";
import AccountDetailsStatus from "./AccountDetailsStatus";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";
import { getProjectViewer } from "./utils";

type ReviewStatusProps = {
  context: {
    closeModal: () => void;
    enabled: boolean;
    isEdit: boolean;
    isFederatedGcpWifEnabled: boolean;
  };
};

export default function ReviewStatus({
  context: { closeModal, enabled, isEdit, isFederatedGcpWifEnabled },
}: ReviewStatusProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const queryClient = useQueryClient();
  const { showGuide } = useCloudAccountPendoGuide();

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();
  const { state: jsonUploadState } = useGCPCredentialsFileContext();

  const {
    state: { steps },
  } = useWizardContext();
  const { openModal } = useModal(CloudSelection);
  const getStepIndex = getWizardStepIndex(steps);
  const getStartedStepIndex = getStepIndex(GET_STARTED);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);

  const { accountType, securityCapabilities, orgSecurityCapabilities } = (steps[
    getStartedStepIndex
  ]?.values ?? {}) as GcpGetStartedStepValuesType;

  const {
    accountGroupCreationMode = "",
    authenticationType: selectedAuthenticationType,
    autoOnboardProjects = false,
    dataFlowCheckbox = false,
    credentials = {},
    dataflowEnabledProject = "",
    flowLogStorageBucket = "",
    groupIds = [],
    hierarchySelection = [],
    name = "",
    organizationId = "",
    projectId = "",
    remediation = false,
  } = (steps[configureAccountIndex]?.values ??
    {}) as GcpConfigureAccountStepValuesType;

  const isOrg = accountType === ORGANIZATION;
  const accountId = isOrg ? organizationId : projectId;
  const isMSA = !isOrg && autoOnboardProjects;
  const selectedAccountType = (
    isMSA ? MASTER_SERVICE_ACCOUNT : accountType
  ) as AccountType;

  const credential_projectId =
    selectedAuthenticationType !== SERVICE_ACCOUNT
      ? getProjectIdWif(jsonUploadState)
      : !isNil(credentials)
        ? credentials[snakeCase(PROJECT_ID)]
        : "";

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: GCP,
      payload: {
        accountType:
          selectedAccountType === WORKSPACE_DOMAIN
            ? WORKSPACE_DOMAIN_PAYLOAD
            : selectedAccountType,
      },
      options: {
        retry: false,
      },
    });

  const { isSubscribed, isLoading: identitySecurityLoading } =
    useIdentitySubscription();

  const features = [
    {
      name: AGENTLESS_SCAN,
      state: securityCapabilities[AGENTLESS_SCAN] ? "enabled" : "disabled",
      ...((isOrg || isMSA) && {
        defaultMemberState:
          securityCapabilities[AGENTLESS_SCAN] &&
          orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
    {
      name: AUTO_PROTECT,
      state: securityCapabilities[AUTO_PROTECT] ? "enabled" : "disabled",
    },
    {
      name: REMEDIATION,
      state: remediation ? "enabled" : "disabled",
    },
    {
      name: SERVERLESS_SCAN,
      state: securityCapabilities[SERVERLESS_SCAN] ? "enabled" : "disabled",
      ...((isOrg || isMSA) && {
        defaultMemberState:
          securityCapabilities[SERVERLESS_SCAN] &&
          orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
  ].filter(({ name }) =>
    supportedFeaturesList.includes(name as SecurityCapabilitiesType),
  ) as SecurityCapabilitiesFeatures[];

  const requestData: GcpRequestPayloadType = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: Record<string, any> = {
      [CLOUD_ACCOUNT]: {
        [ACCOUNT_ID]: accountId,
        [ACCOUNT_TYPE]: selectedAccountType,
        [ENABLED]: enabled,
        [NAME]: name,
        [PROJECT_ID]: credential_projectId,
      },
      [COMPRESSION_ENABLED]: isOrg
        ? !isEmpty(dataflowEnabledProject)
        : dataFlowCheckbox,
      [CREDENTIALS]: credentials,
      [DATA_FLOW_ENABLED_PROJECT_ID]: dataflowEnabledProject,
      [FEATURES]: features,
      [FLOW_LOG_STORAGE_BUCKET]: flowLogStorageBucket,
    };

    if (selectedAccountType !== ORGANIZATION && !autoOnboardProjects) {
      payload[CLOUD_ACCOUNT][GROUP_IDS] = groupIds;
    } else {
      payload[DEFAULT_ACCOUNT_GROUP_ID] = groupIds;
    }

    if (selectedAccountType === ORGANIZATION) {
      if (accountGroupCreationMode !== MANUAL) {
        delete payload[DEFAULT_ACCOUNT_GROUP_ID];
      }
      payload[GCP_ACCOUNT_GROUP_CREATION_MODE] = accountGroupCreationMode;
      payload[GCP_HIERARCHY_SELECTION] = hierarchySelection;
    }
    return payload as GcpRequestPayloadType;
  }, [
    accountGroupCreationMode,
    accountId,
    autoOnboardProjects,
    credential_projectId,
    credentials,
    dataFlowCheckbox,
    dataflowEnabledProject,
    enabled,
    features,
    flowLogStorageBucket,
    groupIds,
    hierarchySelection,
    isOrg,
    name,
    selectedAccountType,
  ]);

  const {
    data: accountStatus = [] as GcpStatusResponseType,
    isFetching: isCloudStatusLoading,
    isError: isAccountStatusError,
    error,
  } = useQuery({
    queryKey: cloudAccountsKeys.gcpCloudAccountStatus({
      payload: requestData,
    }),
    queryFn: getGcpCloudAccountStatus,
    enabled: !isRenderedFirstTime && !isFeaturesLoading,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isAccountStatusError) return;

    const message = parseI18nKeyFromResponse(intl, error);
    toast(message, { appearance: "error" });
    if (
      typeof message === "string" ||
      (Array.isArray(message) &&
        snakeCase(message[0]) !== "invalid_credentials")
    ) {
      closeModal();
    }
  }, [isAccountStatusError, error, intl, toast, closeModal]);

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({
      mutationFn: () => saveGcpCloudAccount({ payload: requestData }),
    });

  const { mutateAsync: updateConfig, isPending: isAccountUpdating } =
    useMutation({
      mutationFn: () =>
        updateGcpCloudAccount({
          accountId,
          payload: requestData,
        }),
    });

  const onSubmit = useCallback(
    async ({ openNewOnboarding = false }) => {
      try {
        if (isEdit) {
          await updateConfig();
        } else {
          await createConfig();
        }

        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });
        if (isEdit) {
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.cloudAccountsCas({
              accountId,
              cloudType: GCP,
              includeGroupInfo: false,
            }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.gcpCloudAccountsCas({ accountId }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.permissionsStatus({ accountId }),
          });
        }
        closeModal();
        openNewOnboarding && openModal();
        if (!isEdit) showGuide();
        toast(handleSuccess(isEdit, intl), { appearance: "success" });
      } catch (error: unknown) {
        toast(parseI18nKeyFromResponse(intl, error), {
          appearance: "error",
        });
      }
    },
    [
      accountId,
      closeModal,
      createConfig,
      intl,
      isEdit,
      openModal,
      showGuide,
      queryClient,
      toast,
      updateConfig,
    ],
  );

  const isGcpWorkspace = accountType === WORKSPACE_DOMAIN;
  const accountDetailsData = {
    accountGroups: Array.isArray(groupIds) ? groupIds : [groupIds],
    accountGroupCreationMode,
    accountName: name,
    accountType: selectedAccountType,
    authentication: getAuthenticationData(accountStatus)!,
    projectViewer: getProjectViewer(accountStatus),
    dataFlowEnabled: isOrg
      ? !isEmpty(dataflowEnabledProject)
      : dataFlowCheckbox,
    organizationId,
    ...getAuthenticationModeInfo(
      credentials as Record<string, string>,
      isFederatedGcpWifEnabled,
    ),
    securityCapabilities: getSecurityCapabilities(
      securityCapabilities,
      supportedFeaturesList,
      isSubscribed,
      isMSA,
    ),
    domainName: isGcpWorkspace ? projectId : "",
  };

  const isLoading =
    isCloudStatusLoading || isFeaturesLoading || identitySecurityLoading;

  const isSubmitting = isAccountCreating || isAccountUpdating;
  const disableBtn = isLoading || isSubmitting;
  const disableSaveBtn = disableBtn || isAccountStatusError;

  return (
    <WizardForm
      actions={
        <>
          <PrevButton data-selector="prev-button" disabled={disableBtn} />
          {!isEdit && (
            <SaveAndOnboardButton
              disabled={disableSaveBtn}
              onSubmit={onSubmit}
            />
          )}
          <SaveAndCloseButton disabled={disableSaveBtn} onSubmit={onSubmit} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <section className="space-y-4">
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Review account details, security capabilities and permissions."
            id="ocHZwf"
            description="description for review status title"
          />
        </span>

        <FormLayout>
          <AccountDetailsStatus {...accountDetailsData} isLoading={isLoading} />
          <SecurityCapabilitiesPermissions
            accountStatus={accountStatus}
            features={features}
            isMSA={isMSA}
            isSubscribed={isSubscribed}
            isLoading={isLoading}
          />
        </FormLayout>
      </section>
    </WizardForm>
  );
}
