/* c8 ignore start */
import { useQuery } from "@tanstack/react-query";
import { featureStatuskeys, gcpFeatureStatus } from "requests";

type UseGetGcpFeatureFlagsProps = Record<string, string | boolean>;

export const useGetGcpFeatureFlags = (
  options: UseGetGcpFeatureFlagsProps = {},
) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: featureStatuskeys.gcp(),
    queryFn: gcpFeatureStatus,
    ...options,
  });

  const {
    is_gcp_wif_enabled: isGcpWifEnabled = false,
    is_google_workspace_enabled: isGoogleWorkspaceEnabled = false,
    gcp_pub_sub_onboarding_enabled: isGcpPubSubOnboardingEnabled = false,
    is_federated_gcp_wif_enabled: isFederatedGcpWifEnabled = false,
  } = data ?? {};

  return {
    isGcpWifEnabled,
    isGoogleWorkspaceEnabled,
    isGcpPubSubOnboardingEnabled,
    isFederatedGcpWifEnabled,
    error,
    isError,
    isLoading,
  };
};
