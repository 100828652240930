import { ModalWizardFieldSummary, ModalWizardStepSummary } from "form";
import { useIntl } from "react-intl";
import { type PermissionGroupData } from "requests";
import { type PermissionGroupStepNames } from "../../PermissionGroupWizard";

export const DetailsSummary = ({ data }: { data: PermissionGroupData }) => {
  const intl = useIntl();

  return (
    <ModalWizardStepSummary<PermissionGroupStepNames>
      name="details"
      title={intl.formatMessage({
        defaultMessage: "Details",
        id: "1ECOTh",
        description: "Form Summary Header",
      })}
    >
      <ModalWizardFieldSummary
        name={intl.formatMessage({
          defaultMessage: "Name",
          id: "7GQc6x",
          description: "Name of the alert rule",
        })}
        value={data.name}
      />
      <ModalWizardFieldSummary
        name={intl.formatMessage({
          defaultMessage: "Description",
          id: "4dYkpa",
          description: "Description for alert rule",
        })}
        value={data.description}
      />
    </ModalWizardStepSummary>
  );
};
