import { Map, Set } from "immutable";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  ALL,
  CREDENTIALS,
  ENABLED,
  ERRORS,
  GCP_DEFAULT_EXPANDED_NODES,
  GCP_FOLDER_VIEW_CREDENTIALS_ERROR,
  GCP_LOADED_NODES,
  GCP_OU_IS_LOADING,
  GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  GCP_PROJECT_SELECT_MODE,
  GCP_SELECTED_NODES,
  GCP_SELECTED_NODES_INITIAL,
  GCP_SELECT_PROJECTS_INITIALIZED,
  GCP_TREE_MAP,
  INIT_GCP_TREE_MAP,
  IS_EDIT,
  IS_RATE_LIMIT_HIT,
  NAME,
  ORG_ID,
  ORG_NAME,
  VALUE,
} from "../../../../../../../constants";

export const gcpSelectMonitoredProjectsInitialState = {
  [ACCOUNT_ID]: { [VALUE]: "", [ERRORS]: [] },
  [ACCOUNT_TYPE]: { [VALUE]: "", [ERRORS]: [] },
  [ENABLED]: { [VALUE]: false, [ERRORS]: [] },
  [NAME]: { [VALUE]: "", [ERRORS]: [] },
  [ORG_ID]: { [VALUE]: "", [ERRORS]: [] },
  [ORG_NAME]: { [VALUE]: "Root" },
  [CREDENTIALS]: { [VALUE]: "", [ERRORS]: [] },
  [IS_EDIT]: { [VALUE]: false },
  [GCP_SELECTED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [GCP_PROJECT_SELECT_MODE]: { [VALUE]: ALL, [ERRORS]: [] },
  [GCP_SELECT_PROJECTS_INITIALIZED]: { [VALUE]: false, [ERRORS]: [] },
  [GCP_TREE_MAP]: { [VALUE]: Map(), [ERRORS]: [] },
  [GCP_SELECTED_NODES_INITIAL]: { [VALUE]: [], [ERRORS]: [] },
  [INIT_GCP_TREE_MAP]: { [VALUE]: Set(), [ERRORS]: [] },
  [GCP_DEFAULT_EXPANDED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [GCP_LOADED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND]: { [VALUE]: [], [ERRORS]: [] },
  [GCP_FOLDER_VIEW_CREDENTIALS_ERROR]: { [VALUE]: "", [ERRORS]: [] },
  [IS_RATE_LIMIT_HIT]: { [VALUE]: false, [ERRORS]: [] },
  [GCP_OU_IS_LOADING]: { [VALUE]: false },
};

type StateKeys = keyof typeof gcpSelectMonitoredProjectsInitialState;
export type GcpSelectMonitoredProjectsInitialStateType = Map<
  StateKeys,
  { value: unknown; errors: unknown }
>;
