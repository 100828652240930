import { Select, type SelectProps } from "form";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useAccountGroupItems, type UseAccountGroupItemsProps } from "..";
import { useResetSelectItems } from "../../utils/useResetSelectItems";

export type AccountGroupSelectProps = Omit<
  SelectProps,
  "label" | "defaultValue" | "simpleFormat"
> &
  UseAccountGroupItemsProps & {
    label?: string;
  };

/**
 *
 * A select that gets its items from the `useAccountGroupItems` hook.
 *
 * It has a default `label` and `itemToString`. By default enableMultiSelect are true and `enableSearch` is true.
 *
 * The item values can be either an object of `{name: string, id: string}`, the name, or the id.  By default it is an object.  Use the itemValue prop to set the type of value.
 *
 * All props except `simpleFormat are overridable.  Values will always be returned in `simpleFormat`.
 */
export function AccountGroupSelect({
  name,
  defaultValue,
  disabled,
  label,
  itemValue,
  cloudTypes,
  ...rest
}: AccountGroupSelectProps) {
  const intl = useIntl();
  const { items, isLoading, normalizedDefaultValue } = useAccountGroupItems({
    itemValue,
    defaultValue,
    cloudTypes,
  });
  const { setValue } = useFormContext();

  useResetSelectItems({
    fieldName: name,
    isLoading,
    items,
    itemFilters: [cloudTypes ?? []],
  });

  const stringifiedNormalizedDefaultValue = JSON.stringify(
    normalizedDefaultValue,
  );
  useEffect(() => {
    // Manually set the value because defaultValue may have gotten set to an
    // empty array on first render when the itemValue is an object or object[]
    // but the defaultValue is a string or string[] and does not get reset to
    // this value on the next render.
    if (normalizedDefaultValue) setValue(name, normalizedDefaultValue);
    // Have to stringify the object so it correctly determines equality
    // normalizedDefaultValue is new every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, stringifiedNormalizedDefaultValue, setValue]);

  return (
    <Select
      name={name}
      disabled={disabled}
      defaultValue={normalizedDefaultValue}
      label={
        label ??
        intl.formatMessage({
          defaultMessage: "Account Group",
          id: "5Vw0qb",

          description: "Label for account group select",
        })
      }
      enableSearch
      enableMultiSelect
      simpleFormat={true}
      isLoading={isLoading}
      items={items}
      itemToString={(item) => item?.context?.itemToString}
      {...rest}
    />
  );
}
