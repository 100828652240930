import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { LoadingIcon } from "icons";
import {
  ACTIVE_DIRECTORY,
  AZURE,
  BUSINESS,
  TENANT,
} from "../../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../../../hooks";
import ComputeLimitBanner from "../../../components/ComputeLimitBanner";
import SecurityCapabilities from "../../../components/SecurityCapabilities";

export default function SecurityCapabilitiesAndPermissions() {
  const { watch } = useFormContext();

  const [accountType, deploymentType] = watch([
    "accountType",
    "environmentType",
  ]);

  const isActiveDirectory = accountType === ACTIVE_DIRECTORY;

  const payload = useMemo(
    () => ({
      ...(isActiveDirectory ? { accountType: TENANT } : { accountType }),
      deploymentType,
      ...(accountType === TENANT && { rootSyncEnabled: true }),
    }),
    [accountType, deploymentType, isActiveDirectory],
  );

  const { isLoading, licenseType } = useGetSupportedFeaturesList({
    cloudType: AZURE,
    payload,
  });

  const { isLoading: isIdentitySubscriptionLoading } =
    useIdentitySubscription();

  if (isLoading || isIdentitySubscriptionLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <ComputeLimitBanner />
      <SecurityCapabilities
        cloudType={AZURE}
        licenseType={isActiveDirectory ? BUSINESS : licenseType}
        payload={payload}
      />
    </>
  );
}
