import {
  CLEAR_FTU_FIELD_ERRORS,
  PROJECT_ID_WIF,
  SET_EXTERNAL_ACCOUNT_KEY,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  SET_SERVICE_ACCOUNT_KEY,
} from "../../../../../../../constants";
import { type GcpCredentialsFileStateType } from "../initialState";
import {
  clearJsonFieldErrors,
  setExternalAccountKey,
  setFTUFieldErrors,
  setFTUFieldValue,
  setProjectIdWif,
  setServiceAccountKey,
} from "../setters";

type Actions =
  | {
      type: "clear_ftu_field_errors";
      payload: {
        field: "service_account_key";
      };
    }
  | {
      type: "set_service_account_key";
      payload: {
        json: Record<string, unknown> | null;
        name: string | null;
        size: number;
      };
    }
  | {
      type: "set_external_account_key";
      payload: {
        json: Record<string, unknown> | null;
        name: string | null;
        size: number;
      };
    }
  | {
      type: "set_ftu_field_errors";
      payload: {
        errors: string;
        field: string;
      };
    }
  | {
      type: "projectId_wif";
      payload: {
        projectId: string;
      };
    }
  | {
      type: "set_ftu_field_value";
      payload: {
        value: string;
        field: string;
      };
    };

export function gcpCredentialsFileReducer(
  state: GcpCredentialsFileStateType,
  action: Actions,
) {
  switch (action?.type) {
    case SET_EXTERNAL_ACCOUNT_KEY:
      return setExternalAccountKey({
        json: action.payload.json,
        name: action.payload.name,
        size: action.payload.size,
        state,
      });
    case SET_SERVICE_ACCOUNT_KEY:
      return setServiceAccountKey({
        json: action.payload.json,
        name: action.payload.name,
        size: action.payload.size,
        state,
      });
    case CLEAR_FTU_FIELD_ERRORS:
      return clearJsonFieldErrors({ field: action.payload.field, state });
    case SET_FTU_FIELD_ERRORS:
      return setFTUFieldErrors({
        field: action.payload.field,
        errors: action.payload.errors,
        state,
      });
    case PROJECT_ID_WIF:
      return setProjectIdWif({ projectId: action.payload.projectId, state });
    case SET_FTU_FIELD_VALUE:
      return setFTUFieldValue({
        field: action.payload.field,
        value: action.payload.value,
        state,
      });
    default:
      return state;
  }
}
