import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Body } from "ui";
import {
  CREATE_IAM_ROLE_CFT,
  DOWNLOAD_IAM_ROLE_CFT,
} from "../../../../../../constants";
import CFTButton from "../../../../components/CFTButton/CFTButton";

type ConfigHelpersProps = {
  createIAMRoleCft: UseMutateAsyncFunction<void, unknown, void, unknown>;
  downloadIAMRoleCft: UseMutateAsyncFunction<void, unknown, void, unknown>;
  disabled: boolean;
  iamRoleCftMode: string;
  isIAMRoleCftDownloaded: boolean;
  isDownloadingCft: boolean;
  isCreatingCft: boolean;
  isEdit: boolean;
  setIsOpenConfigHelper: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCreateIAMRoleSteps: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ConfigHelpers({
  createIAMRoleCft,
  downloadIAMRoleCft,
  disabled,
  iamRoleCftMode,
  isIAMRoleCftDownloaded,
  isDownloadingCft,
  isCreatingCft,
  isEdit,
  setIsOpenConfigHelper,
  setShowCreateIAMRoleSteps,
}: ConfigHelpersProps) {
  return (
    <Body as="div" addClassName="flex space-x-4 pt-2">
      {!isEdit && (
        <>
          <CFTButton
            dataSelector="config-helper-create-stack-link"
            disabled={disabled}
            external={true}
            isLoading={isCreatingCft}
            isFileDownloaded={
              isIAMRoleCftDownloaded && iamRoleCftMode === CREATE_IAM_ROLE_CFT
            }
            onButtonClick={createIAMRoleCft}
            onStepClick={() => {
              setIsOpenConfigHelper(true);
              setShowCreateIAMRoleSteps(true);
            }}
            title={
              <FormattedMessage
                defaultMessage="Create IAM Role"
                id="Kvc5ef"
                description="AWS create iam role link"
              />
            }
          />
          <section className="pt-1.5">
            <FormattedMessage
              defaultMessage="or"
              id="we0tNE"
              description="description for or role actions"
            />
          </section>
        </>
      )}

      {/* TODO Use common CftDownload btn */}
      <CFTButton
        dataSelector="config-helper-download-dynamic-cft-link"
        disabled={disabled}
        external={false}
        isLoading={isDownloadingCft}
        isFileDownloaded={
          isIAMRoleCftDownloaded && iamRoleCftMode === DOWNLOAD_IAM_ROLE_CFT
        }
        onButtonClick={downloadIAMRoleCft}
        onStepClick={() => {
          setIsOpenConfigHelper(true);
          setShowCreateIAMRoleSteps(false);
        }}
        title={
          <FormattedMessage
            defaultMessage="Download IAM Role CFT"
            id="yglsjr"
            description="AWS cft download link"
          />
        }
      />
    </Body>
  );
}
