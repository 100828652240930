import {
  forwardRef,
  useLayoutEffect,
  useRef,
  type ComponentPropsWithoutRef,
  type MutableRefObject,
} from "react";

import { classNames } from "utils";

export interface CheckboxInputProps extends ComponentPropsWithoutRef<"input"> {
  /**
   * Add a className to the wrapper
   */
  addClassName?: string;
  /**
   * How the checkbox will be displayed
   */
  appearance?: "square" | "circle";
  /**
   * The current state of the checkbox.
   *
   * Optional. Puts the checkbox into the [controlled](https://reactjs.org/docs/forms.html#controlled-components) state.
   */
  checked?: boolean;
  /**
   * Disables the checkbox.
   */
  disabled?: boolean;
  /**
   * Partially checked, generally used when children are selected, but the associated element is not.
   */
  indeterminate?: boolean;
  /**
   * Puts the checkbox in display only mode. The state is controlled by something other than an action on the checkbox itself,
   * but the checkbox is still active (it is not disabled).
   */
  readOnly?: boolean;
  tabIndex?: number;
}

/**
 * Lets users toggle a boolean value.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Checkbox } from "ui";
 * ```
 */
export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      addClassName = "",
      appearance = "square",
      checked,
      disabled = false,
      indeterminate,
      onChange,
      readOnly = false,
      ...passThrough
    }: CheckboxInputProps,
    incomingRef,
  ) => {
    const checkboxClasses = classNames(
      "form-checkbox border-gray-300 text-icon-blue dark:border-blue-steel-500",
      appearance === "circle" ? "rounded-full" : "rounded-sm",
      !disabled && !readOnly
        ? "hover:ring focus:border-gray-300 focus:ring focus:ring-blue-300 focus:ring-offset-0 dark:focus:border-gray-700 dark:focus:ring-blue-700"
        : "focus:ring-0 focus:ring-offset-0",
      disabled
        ? "cursor-not-allowed bg-gray-400 dark:bg-gray-600"
        : "bg-white dark:bg-blue-steel-950",
      !disabled && !readOnly && "cursor-pointer",
      addClassName,
    );
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const useableRef =
      (incomingRef as MutableRefObject<HTMLInputElement | null>) ?? defaultRef;

    useLayoutEffect(() => {
      if (useableRef?.current && checked !== undefined)
        useableRef.current.checked = checked;
      if (useableRef?.current && indeterminate !== undefined)
        useableRef.current.indeterminate = indeterminate;
    }, [checked, indeterminate, useableRef]);

    return (
      <input
        aria-checked={indeterminate ? "mixed" : checked}
        checked={checked}
        disabled={disabled || readOnly}
        onChange={onChange}
        ref={useableRef}
        readOnly={readOnly}
        type="checkbox"
        className={checkboxClasses}
        {...passThrough}
      />
    );
  },
);
CheckboxInput.displayName = "CheckboxInput";
