import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../hooks";

type OrganizationIdProps = {
  isEdit: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};
export default function OrganizationId({
  isEdit,
  onBlur,
}: OrganizationIdProps) {
  const intl = useIntl();
  useTrimField("organizationId");

  return (
    <Input
      label={intl.formatMessage({
        defaultMessage: "Org ID",
        id: "nFVZq5",
        description:
          "The name of an input field org id for onboarding an GCP account",
      })}
      name="organizationId"
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Org ID",
        id: "Qw8TU+",
        description: "Placeholder for GCP Org ID",
      })}
      readOnly={isEdit}
    />
  );
}
