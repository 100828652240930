import { LoadingIcon } from "icons";
import { type ReactElement, type ReactNode } from "react";

export interface CardListLayoutProps {
  /**
   * The main body of the card list layout. The connected CardList should go here.
   */
  children: ReactNode;
  /**
   * Left side of the controls. Typically, a group select or filters.
   */
  controlLeft?: ReactNode;
  /**
   * Right side of the controls. Filtering, downloads, and other controls typically go here.
   */
  controlRight?: ReactNode;
  /**
   * Use to signify the card list is being updated by a background fetch.
   */
  isBackgroundFetching?: boolean;
}

/**
 * CardListLayout helps layout controls and content of a card list.
 * Will handle spacing and can add scrolling functionality to the card list.
 */
export function CardListLayout({
  children,
  controlLeft,
  controlRight,
  isBackgroundFetching,
}: CardListLayoutProps): ReactElement {
  return (
    <div className="flex grow flex-col gap-y-4">
      {(controlLeft || controlRight) && (
        <div className="flex justify-between gap-x-1">
          <div className="flex grow items-center gap-x-1">
            {controlLeft}
            {isBackgroundFetching && <LoadingIcon className="ml-3" />}
          </div>
          <div className="flex items-center gap-x-1">{controlRight}</div>
        </div>
      )}
      <div className="flex flex-1 flex-col">{children}</div>
    </div>
  );
}
