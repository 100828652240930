import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { accessControlRoute } from "../../accessControlRoute";

const Roles = lazy(() =>
  import("./Roles").then((res) => ({
    default: res.Roles,
  })),
);

export const rolesRoute = new Route({
  getParentRoute: () => accessControlRoute,
  path: "roles",
  component: Roles,
});
