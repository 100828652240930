import "./index.css";

import "./monacoWorker";
import "./pages/dashboard/react-grid-layout-overrides.css";

import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { GlobalFilterProvider, defaultFilterState } from "filters";
import { MonitoringProvider, SentryIntegrationFactory } from "monitoring";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary, RouterErrorBoundary } from "ui";
import WrappedErrorComponent, { logError } from "./ErrorComponentWrapper";
import IntlProvider from "./IntlProvider";
import { queryClient } from "./queryClient";
import { router } from "./router";
import { processSSO } from "./setup/processSSO/processSSO";
import { setupSentry, stopBootstrapTracking } from "./setupSentry";

stopBootstrapTracking();
setupSentry();

const initialFilterState = {
  vulnerabilityAssetsFilter: {
    ...defaultFilterState,
    config: [],
  },
};

const monitoringInstance = SentryIntegrationFactory.create(Sentry);

if (processSSO()) {
  createRoot(document.getElementById("root") as HTMLElement).render(
    <StrictMode>
      <MonitoringProvider monitoringInstance={monitoringInstance}>
        <ErrorBoundary onError={logError}>
          <QueryClientProvider client={queryClient}>
            <IntlProvider>
              <GlobalFilterProvider initialState={initialFilterState}>
                <RouterProvider
                  router={router}
                  defaultErrorComponent={WrappedErrorComponent(
                    RouterErrorBoundary,
                  )}
                />
              </GlobalFilterProvider>
            </IntlProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </MonitoringProvider>
    </StrictMode>,
  );
}
