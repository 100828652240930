import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { cloudAccountsKeys, getCloudAccountCas } from "requests";
import { AWS } from "../constants";

type UseTransformAwsPartitionTypeProps = {
  accountId: string;
  awsPartitionsEnabled: string[];
  options?: Record<string, unknown>;
};

export function useTransformAwsPartitionType({
  accountId,
  awsPartitionsEnabled,
  options = {},
}: UseTransformAwsPartitionTypeProps) {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsCas({ accountId, cloudType: AWS }),
    queryFn: getCloudAccountCas,
    ...options,
  });
  const deploymentType = data?.cloudAccount?.deploymentType?.toLowerCase();

  const awsPartition = useMemo(() => {
    if (deploymentType === "aws-cn") {
      return awsPartitionsEnabled?.filter((element) =>
        element.includes("cn"),
      )[0]; // 'cn-north-1'
    } else if (deploymentType === "aws-us-gov") {
      return awsPartitionsEnabled?.filter((element) =>
        element.includes("gov"),
      )[0]; // 'aws-gov-west-1'
    } else if (deploymentType === "aws") {
      const arrayWithoutGovPartition = awsPartitionsEnabled?.filter(
        (element) => !element.includes("gov"),
      );
      const globalValue = arrayWithoutGovPartition?.filter(
        (element) => !element.includes("cn"),
      )[0];
      return globalValue; // 'aws-east-1'
    } else {
      return "";
    }
  }, [deploymentType, awsPartitionsEnabled]);

  return {
    awsPartition,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
}
