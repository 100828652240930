import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

export const Applications = lazy(() => import("./Applications"));
export const L2AssetLists = lazy(() => import("./L2AssetLists"));
export const L3AssetLists = lazy(() => import("./L3AssetLists"));

export const inventoryApplicationsRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "applications",
  component: Applications,
});

export const inventoryApplicationGroupedAssetsRoute = new Route({
  getParentRoute: () => inventoryApplicationsRoute,
  path: "$applicationId",
  component: L2AssetLists,
});

export const inventoryApplicationAssetsRoute = new Route({
  getParentRoute: () => inventoryApplicationGroupedAssetsRoute,
  path: "$assetType",
  component: L3AssetLists,
});
