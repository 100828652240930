import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { reportsLayoutRoute } from "../reportsLayoutRoute";

export const ComplianceReports = lazy(() => import("./ComplianceReports"));

export const complianceReportsRoute = new Route({
  getParentRoute: () => reportsLayoutRoute,
  path: "compliance",
  component: ComplianceReports,
});

export const complianceReportAutoDownloadRoute = new Route({
  getParentRoute: () => complianceReportsRoute,
  path: "$reportId/download",
});
