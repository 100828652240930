import { Set, is } from "immutable";
import {
  ALL,
  APPLICATION_ID,
  APPLICATION_KEY,
  AZURE_DEFAULT_EXPANDED_NODES,
  AZURE_FOLDER_VIEW_CREDENTIALS_ERROR,
  AZURE_LOADED_NODES,
  AZURE_OU_IS_LOADING,
  AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  AZURE_PROJECT_SELECT_MODE,
  AZURE_SELECTED_NODES,
  AZURE_SELECTED_NODES_INITIAL,
  AZURE_SELECT_PROJECTS_INITIALIZED,
  AZURE_TREE_MAP,
  ENABLED,
  ENVIRONMENT_TYPE,
  ERRORS,
  MONITOR_FLOW_LOGS,
  NAME,
  SERVICE_PRINCIPAL_ID,
  TENANT_ID,
  VALUE,
} from "../../../../../../../constants";
import { type AzureSelectMonitoredProjectInitialStateType } from "../initialState";

export const getFieldValue = ({
  field,
  state,
}: {
  field: string;
  state: AzureSelectMonitoredProjectInitialStateType;
}) => state.getIn([field, VALUE]);

export const getFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: AzureSelectMonitoredProjectInitialStateType;
}) => state.getIn([field, ERRORS]);

export const getTenantId = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: TENANT_ID, state });

export const getAzureRootId = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getTenantId(state);

export const getApplicationId = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: APPLICATION_ID, state });

export const getCloudAccountEnable = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: ENABLED, state });

export const getAccountName = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: NAME, state });

export const getEnvironmentType = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: ENVIRONMENT_TYPE, state });

export const getApplicationKey = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: APPLICATION_KEY, state });

export const getMonitorFlowLogs = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: MONITOR_FLOW_LOGS, state });

export const getServicePrincipalId = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: SERVICE_PRINCIPAL_ID, state });

export const getAzureTreeRoot = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getAzureTreeMap(state).get(getAzureRootId(state));

export const getAzureSelectedNodes = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_SELECTED_NODES, state });

export const getAzureSelectedNodesErrors = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldErrors({ field: AZURE_SELECTED_NODES, state });

export const getAzureDefaultExpandedNodes = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_DEFAULT_EXPANDED_NODES, state });

export const getAzureFoldersCredentialError = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_FOLDER_VIEW_CREDENTIALS_ERROR, state });

export const getAzureLoadedNodes = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_LOADED_NODES, state });

export const getAzurePreviouslySelectedNodesNotFound = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND, state });

export const getAzureProjectSelectMode = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_PROJECT_SELECT_MODE, state });

export const getAzureSelectProjectsInitialized = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_SELECT_PROJECTS_INITIALIZED, state });

export const getAzureSelectedNodesInitial = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_SELECTED_NODES_INITIAL, state });

export const getAzureTreeMap = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_TREE_MAP, state });

export const getAzureSelectProjectsIsLoading = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => getFieldValue({ field: AZURE_OU_IS_LOADING, state });

function selectionChanged(state: AzureSelectMonitoredProjectInitialStateType) {
  const selectionType = getAzureProjectSelectMode(state);
  const selectedNodes = getAzureSelectedNodes(state);
  const initialSelectedNodes = getAzureSelectedNodesInitial(state);
  const initialSelectionType = initialSelectedNodes?.[0]?.selectionType;

  if (!getAzureSelectProjectsInitialized(state)) {
    return false;
  }

  if (!initialSelectedNodes || !initialSelectionType) {
    return true;
  }

  if (selectionType !== initialSelectionType) {
    return true;
  }

  const initial = Set(
    initialSelectedNodes.map(
      ({ resourceId }: { resourceId: string }) => resourceId,
    ),
  );
  const current = Set(selectedNodes);

  return !is(initial, current);
}

const convertNodeToAPIPayload = (
  node: Map<string, unknown>,
  selectionType: string,
) => ({
  displayName: node.get("displayName"),
  nodeType: node.get("type"),
  resourceId: node.get("id"),
  selectionType,
});

export const getHierarchySelection = (
  state: AzureSelectMonitoredProjectInitialStateType,
) => {
  const selectionType = getAzureProjectSelectMode(state);
  const treeMap = getAzureTreeMap(state);
  const selectedNodes = getAzureSelectedNodes(state);

  if (!selectionChanged(state)) {
    return getAzureSelectedNodesInitial(state);
  }

  if (selectionType === ALL) {
    const rootNode = getAzureTreeRoot(state);

    return [convertNodeToAPIPayload(rootNode, selectionType)];
  }

  return selectedNodes
    .toJS()
    .map((id: string) =>
      convertNodeToAPIPayload(treeMap.get(id), selectionType),
    );
};
