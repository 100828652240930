import { usePermissions } from "prisma";
import { FormattedMessage } from "react-intl";
import {
  type CloudGroupAllName,
  type CloudGroupNamesResponseType,
} from "requests";

import {
  ConnectedPaginator,
  ConnectedTable,
  ConnectedTableFilter,
  TableProvider,
  type ColumnOptions,
} from "table";
import { Link } from "ui";
import { useRoleModalForm } from "../../../roles/Forms";
import { useCodeRolesModal } from "./CodeRolesModal";

export function Table({ data }: { data: CloudGroupAllName }) {
  const {
    settingsUserRole: { UPDATE: updateAllowed },
  } = usePermissions();

  const { openModal } = useRoleModalForm();
  const { closeModal } = useCodeRolesModal();

  const columns = [
    {
      accessor: "name",
      Cell: ({ value }: { value: string }) => {
        const isMsspSystemAdminRole = value === "MSSP System Admin";

        return (
          <>
            {updateAllowed && !isMsspSystemAdminRole ? (
              <Link
                size="sm"
                onClick={() => {
                  openModal({
                    action: "edit",
                    // @ts-expect-error match InitialValues type in roles with UserRolesType
                    initialValues: data?.find((r) => r.name === value),
                    dataRoles: data,
                  });
                  closeModal();
                }}
              >
                {value}
              </Link>
            ) : (
              value
            )}
          </>
        );
      },
      Header: (
        <FormattedMessage
          defaultMessage="Name"
          id="PIZW8F"
          description="Name column header"
        />
      ),
      width: 525,
    },
  ] as ColumnOptions<CloudGroupNamesResponseType>[];

  return (
    <TableProvider
      columns={columns}
      data={data}
      initialState={{
        sortBy: [
          {
            id: "name",
            desc: true,
          },
        ],
      }}
      enableColumnSort
      enablePagination
    >
      <div className="flex flex-col space-y-4" style={{ maxHeight: "50vh" }}>
        <div className="flex flex-none justify-between">
          <FormattedMessage
            defaultMessage="Total Results: {count, number}"
            id="/rYK5i"
            description="Total results label"
            values={{ count: data?.length }}
          />
          <ConnectedTableFilter />
        </div>
        <div className="overflow-x-auto">
          <ConnectedTable
            appearance="secondary"
            selector="roles-codeRepositories"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <ConnectedPaginator />
        </div>
      </div>
    </TableProvider>
  );
}
