import { FormattedMessage } from "react-intl";

import { DownloadIcon } from "icons";
import { Button, Link } from "ui";

type DownloadButtonPropTypes = {
  downloadUrl: string;
  isCreatingScript: boolean;
  wizardDataIsIncomplete: boolean;
};

export default function DownloadButton({
  downloadUrl,
  isCreatingScript,
  wizardDataIsIncomplete,
}: DownloadButtonPropTypes) {
  return (
    <Button
      addClassName="m-2"
      appearance="secondary"
      disabled={isCreatingScript || wizardDataIsIncomplete}
      icon={<DownloadIcon />}
      isLoading={isCreatingScript}
    >
      <Link href={downloadUrl} download>
        <FormattedMessage
          defaultMessage="Download Template"
          id="doM16a"
          description="Button on Account Onboarding Wizard"
        />
      </Link>
    </Button>
  );
}
