import { Route } from "@tanstack/react-router";
import { governanceRoute } from "../../governanceRoute";

export const editPolicyWizardBackwardCompatibleRoute = new Route({
  getParentRoute: () => governanceRoute,
  path: "$policyId/$policyType/edit",
  beforeLoad: ({ navigate, params, search }) => {
    const policyId = params.policyId!;
    navigate({
      to: `/governance/${policyId}/edit`,
      search,
    });
  },
});
