import { animated, config, useSpring } from "@react-spring/web";
import { useRef, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import useMeasure from "react-use-measure";
import { securityCapabilitiesMap } from "../../../../../messages/reviewStatusMessages";

import { blueScale } from "colors";
import { Bold, DynamicFormattedMessage, Tag } from "ui";

import { SecurityCapabilitiesIcon } from "icons";
import { type SecurityCapabilitiesType } from "requests";
import { snakeCase } from "utils";
import { Details } from "../Details";

type SecurityCapabilitiesPropTypes = {
  defaultCount: number;
  value: SecurityCapabilitiesType[];
  classNames?: string;
};

export function SecurityCapabilities({
  value,
  defaultCount,
  classNames = "",
}: SecurityCapabilitiesPropTypes) {
  const [showAll, setShowAll] = useState(false);
  const outerRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);

  const [innerRef, bounds] = useMeasure();
  const { height, ...style } = useSpring({
    immediate: !isMounted,
    config: { ...config.stiff, clamp: true },
    from: {
      height: 0,
      opacity: 0,
      translateY: "-100%",
    },
    to: {
      height: bounds.height,
      opacity: 1,
      translateY: "0%",
    },
    onStart: {
      height: () => {
        setIsMounted(true);
      },
    },
    reverse: !open,
  });
  let content = null;

  if (value.length > 0) {
    if (showAll) {
      content = (
        <>
          {value.map((item, index) => (
            <BoldTag key={index} value={item} />
          ))}
          {value.length > defaultCount ? (
            <ToggleLoadMore
              label={
                <FormattedMessage
                  defaultMessage="Show Less"
                  id="0Pkjzq"
                  description="Description for show less SecurityCapabilities options on review status"
                />
              }
              onClick={() => setShowAll(false)}
            />
          ) : null}
        </>
      );
    } else {
      content = (
        <>
          {value.slice(0, defaultCount).map((item, index) => (
            <BoldTag key={index} value={item} />
          ))}
          {value.length > defaultCount ? (
            <ToggleLoadMore
              label={
                <FormattedMessage
                  defaultMessage="+ {count} More"
                  id="/jmitn"
                  description="Description for load more SecurityCapabilities options on review status"
                  values={{
                    count: value.length - defaultCount,
                  }}
                />
              }
              onClick={() => setShowAll(true)}
            />
          ) : null}
        </>
      );
    }
  }

  return (
    <Details
      label={
        <FormattedMessage
          defaultMessage="Security Capabilities"
          id="TxQBx9"
          description="Description for Security Capabilities on status"
        />
      }
      // classNames="items-start [&>div]:mt-1.5"
      classNames={classNames}
    >
      <animated.div ref={outerRef} style={{ height }}>
        <div
          className="-ml-0.5 flex flex-wrap [&>button]:m-0.5 [&>div]:m-0.5"
          ref={innerRef}
          style={style as Record<string, unknown>}
        >
          {content}
        </div>
      </animated.div>
    </Details>
  );
}

function ToggleLoadMore({
  onClick,
  label,
}: {
  label: ReactNode;
  onClick: () => void;
}) {
  return (
    <Tag onClick={onClick}>
      <Bold style={{ color: blueScale[600] }}>{label}</Bold>
    </Tag>
  );
}

function BoldTag({ value }: { value: SecurityCapabilitiesType }) {
  return (
    <Tag size="md" icon={<SecurityCapabilitiesIcon type={value} />}>
      <Bold>
        <DynamicFormattedMessage
          messageMap={securityCapabilitiesMap}
          token={snakeCase(value)}
        />
      </Bold>
    </Tag>
  );
}
