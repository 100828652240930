import { defineMessages } from "react-intl";

export const fttvExperienceSidecarNav = defineMessages({
  VISIBILITY: {
    defaultMessage: "VISIBILITY",
    id: "MUVXVE",
    description: "sidenav link",
  },
  Repositories: {
    defaultMessage: "Repositories",
    id: "GnB43n",
    description: "sidenav link",
  },
  Technologies: {
    defaultMessage: "Technologies",
    id: "rKvTTs",
    description: "sidenav link",
  },
  SBOM: {
    defaultMessage: "SBOM",
    id: "THRI6c",
    description: "sidenav link",
  },
  CODE: {
    defaultMessage: "CODE",
    id: "GtJ1hY",
    description: "sidenav link",
  },
  Projects: {
    defaultMessage: "Projects",
    id: "CyI8m1",
    description: "sidenav link",
  },
  "CI/CD": {
    defaultMessage: "CI/CD",
    id: "RjNxbC",
    description: "sidenav link",
  },
  "CI/CD Risks": {
    defaultMessage: "CI/CD Risks",
    id: "hVt5sX",
    description: "sidenav link",
  },
  RADARS: {
    defaultMessage: "RADARS",
    id: "vTZ39E",
    description: "sidenav link",
  },
  Cloud: {
    defaultMessage: "Cloud",
    id: "FbVpPn",
    description: "sidenav link",
  },
  Hosts: {
    defaultMessage: "Hosts",
    id: "6WnV9y",
    description: "sidenav link",
  },
  Containers: {
    defaultMessage: "Containers",
    id: "M6sz5L",
    description: "sidenav link",
  },
  Serverless: {
    defaultMessage: "Serverless",
    id: "uT0+7Z",
    description: "sidenav link",
  },
  Settings: {
    defaultMessage: "Settings",
    id: "QprIGu",
    description: "sidenav link",
  },
  DEFEND: {
    defaultMessage: "DEFEND",
    id: "nIMUrm",
    description: "sidenav link",
  },
  Vulnerabilities: {
    defaultMessage: "Vulnerabilities",
    id: "yPb6mV",
    description: "sidenav link",
  },
  Compliance: {
    defaultMessage: "Compliance",
    id: "ufCOk0",
    description: "sidenav link",
  },
  Runtime: {
    defaultMessage: "Runtime",
    id: "nl0vw9",
    description: "sidenav link",
  },
  WAAS: {
    defaultMessage: "WAAS",
    id: "dkZ3Ny",
    description: "sidenav link",
  },
  CNNS: {
    defaultMessage: "CNNS",
    id: "ZHj5WT",
    description: "sidenav link",
  },
  Access: {
    defaultMessage: "Access",
    id: "NutSEo",
    description: "sidenav link",
  },
  "Custom Rules": {
    defaultMessage: "Custom Rules",
    id: "rdGuC0",
    description: "sidenav link",
  },
  Welcome: {
    defaultMessage: "Welcome",
    id: "FZAI91",
    description: "sidenav link",
  },
  "Adoption Advisor": {
    defaultMessage: "Adoption Advisor",
    id: "IUeCaA",
    description: "sidenav link",
  },
});
