import { z } from "zod";
export const successMessages = {
  login_successful: z.literal("login_successful"),
  login_needs_customer_name: z.literal("login_needs_customer_name"),
  tos_not_accepted: z.literal("tos_not_accepted"),
};
export const TenantSchema = z.object({
  customerName: z.string(),
  prismaId: z.string(),
  tosAccepted: z.boolean(),
  apiUrl: z.string().optional(),
});

export const PostLoginRequestSchema = z.object({
  username: z.string(),
  password: z.string(),
  customerName: z.string().optional(),
});

const LoginSuccessResponseSchema = z.object({
  message: successMessages["login_successful"],
  token: z.string(),
  customerNames: z.array(TenantSchema),
});
const errorResponseSchema = z.object({
  error: z.string().optional(),
});

export const PostLoginResponseSchema = z.discriminatedUnion("message", [
  LoginSuccessResponseSchema,
  z.object({
    message: successMessages["login_needs_customer_name"],
    customerNames: z.array(TenantSchema),
  }),
  z.object({
    message: successMessages["tos_not_accepted"],
    customerNames: z.array(TenantSchema),
  }),
  errorResponseSchema.extend({
    message: z.literal("access_key_login_not_permitted"),
  }),
  errorResponseSchema.extend({
    message: z.literal("invalid_src_credentials"),
  }),
  errorResponseSchema.extend({
    message: z.literal("tenant_expired"),
  }),
  errorResponseSchema.extend({
    message: z.literal("user_locked"),
  }),
  errorResponseSchema.extend({
    message: z.literal("sso_enabled_password_login_attempt"),
  }),
  errorResponseSchema.extend({
    message: z.literal("invalid_credentials"),
  }),
  errorResponseSchema.extend({
    message: z.literal("login_failed_saml_error"),
  }),
  errorResponseSchema.extend({
    message: z.literal("login_failed_src_error"),
  }),
  errorResponseSchema.extend({
    message: z.literal("login_failed_unknown_error"),
  }),
  errorResponseSchema.extend({
    message: z.literal("Unauthorized"),
  }),
]);

export const ExtendTokenResponseSchema = z.object({
  token: z.string(),
});

export const PostTermsOfServiceRequestSchema = z.object({
  tosAccepted: z.boolean(),
  customerName: z.string(),
  ssoSession: z.boolean(),
  username: z.string(),
  password: z.string().nullish(),
});

export const PostTermsOfServiceResponseSchema = z.object({
  token: z.string(),
});

export const PostAuthTokenGenerateRequestSchema = z.object({
  username: z.string(),
});

export const PostAuthTokenGenerateResponseSchema = z.object({
  message: z.string().optional(),
  status: z.string().optional(),
});

export const PostAuthTokenRegenerateRequestSchema = z.object({
  resetToken: z.string(),
});

export const PostAuthTokenRegenerateResponseSchema = z.object({
  message: z.string().optional(),
  status: z.string().optional(),
});

export const ValidateResetResponseSchema = z.object({
  status: z.union([
    z.literal("SUCCESS"),
    z.literal("FORGOT_PASSWORD_TOKEN_EXPIRED"),
    z.literal("USER_ACTIVATION_TOKEN_EXPIRED"),
    z.literal("INVALID"),
  ]),
  message: z.string().optional(),
  token: z.string().optional(),
});

export const PostResetPasswordRequestSchema = z.object({
  newPassword: z.string(),
});

export const PostResetPasswordResponseSchema = z.object({
  message: z.string().optional(),
  status: z.string().optional(),
});

export const LoginSSOResponseSchema = z.object({
  url: z.string(),
});
