import { CircleCheckIcon, DoNotEnterIcon } from "icons";
import { type FC, type HTMLAttributes } from "react";

import { classNames } from "utils";

export interface HandleProps extends HTMLAttributes<HTMLSpanElement> {
  checked: boolean;
}

export const Handle: FC<HandleProps> = ({
  checked,
  ...passThrough
}: HandleProps) => (
  <span
    className={classNames(
      "pointer-events-none inline-block h-3 w-3 rounded-full text-dark-bg shadow dark:text-default",
      "ease-in-out motion-safe:transition motion-safe:duration-200",
      checked ? "translate-x-4" : "translate-x-0",
    )}
    aria-hidden="true"
    {...passThrough}
  >
    <span
      className={classNames(
        "absolute inset-0 flex h-full w-full items-center justify-center motion-safe:transition-opacity",
        checked
          ? "opacity-0 ease-out motion-safe:duration-100"
          : "opacity-100 ease-in motion-safe:duration-200",
      )}
    >
      <DoNotEnterIcon style={{ height: "100%", width: "100%" }} />
    </span>
    <span
      className={classNames(
        "absolute inset-0 flex h-full w-full items-center justify-center motion-safe:transition-opacity",
        checked
          ? "opacity-100 ease-in motion-safe:duration-200"
          : "opacity-0 ease-out motion-safe:duration-100",
      )}
    >
      <CircleCheckIcon style={{ height: "100%", width: "100%" }} />
    </span>
  </span>
);
