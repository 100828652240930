import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { publicRootRoute } from "../../publicRootRoute";

const ResetPassword = lazy(() => import("./ResetPassword"));

const ResetPasswordSearchSchema = z.object({
  fpt: z.string().catch(""),
});

export const resetPasswordRoute = new Route({
  getParentRoute: () => publicRootRoute,
  path: "/reset_password",
  component: ResetPassword,
  validateSearch: ResetPasswordSearchSchema,
});
