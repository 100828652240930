import { type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { type AbsoluteTimeType, type TimeRange } from "requests";
import { type SelectItem } from "ui";
import { timeFormat } from "..";
import { customTimeRangeItem, useCustomDate } from "../utils/useCustomDate";

export type DateItemNames = keyof typeof dateItemMap;

export type useDateProps = {
  selectedValue?: TimeRange;
  /**
   * The names of the pre-defined items to include in the dropdown
   */
  itemNames: DateItemNames[];
  /**
   * The Picker component to render when Custom is selected
   */
  picker: ReactElement;
};

export const useDate = ({ itemNames, picker, selectedValue }: useDateProps) => {
  return useCustomDate({
    // @ts-expect-error TS is having trouble with the union type for itemNames
    getItemsFn: getDateItems,
    // @ts-expect-error TS is having trouble with the union type for itemNames
    itemNames,
    picker,
    selectedValue,
  });
};

export const defaultInitialDateFromValue = (value: AbsoluteTimeType) => {
  if (!value) return;
  if (value.type !== "absolute") return;

  const { endTime } = value.value;
  if (!endTime) return;

  return new Date(Number(endTime));
};

export const defaultDateOnCustomApply = (date: Date) => {
  return {
    type: "absolute",
    value: {
      startTime: date.valueOf(),
      endTime: date.valueOf(),
    },
  };
};

export const defaultDateCustomLabel = (absoluteTime: AbsoluteTimeType) => {
  if (!absoluteTime?.value) return "";
  const endTime = timeFormat(absoluteTime?.value?.endTime);

  return (
    <FormattedMessage
      defaultMessage="Up to {endTime}"
      id="nQXVOU"
      description="Up to End Time Custom Label"
      values={{
        endTime,
      }}
    />
  );
};

export const mostRecentDateItem = {
  children: (
    <FormattedMessage
      defaultMessage="Most Recent"
      id="M+5gjk"
      description="Value in dropdown to select a date to return data for"
    />
  ),
  key: "mostRecent",
  value: {
    type: "to_now",
    value: "epoch",
  },
};

const dateItemMap = {
  mostRecent: mostRecentDateItem,
  custom: customTimeRangeItem,
};

export const getDateItems = ({
  itemNames,
  customItemOnClick,
}: {
  itemNames: DateItemNames[];
  customItemOnClick?: () => void;
}) => {
  return itemNames.reduce((items, itemName) => {
    const item = dateItemMap[itemName];
    if (itemName === "custom") {
      items.push({ ...item, onClick: customItemOnClick } as SelectItem);
    } else {
      items.push(item as SelectItem);
    }
    return items;
  }, [] as SelectItem[]);
};
