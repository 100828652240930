import { type ReactNode } from "react";

export type SvgIconProps = {
  showNativeTooltip?: boolean;
  size?: IconSizes;
  disabled?: boolean;
} & React.SVGProps<SVGSVGElement>;

const iconSizes = {
  auto: "auto",
  xxs: "10",
  xs: "12",
  sm: "14",
  md: "16",
  lg: "20",
  xl: "24",
  xxl: "28",
  "3xl": "32",
  "4xl": "40",
  "5xl": "48",
  "6xl": "56",
  "7xl": "64",
} as const;

export type IconSizes = keyof typeof iconSizes;

type ComponentProps = { viewBox: string; children: ReactNode } & SvgIconProps;

export const SvgIcon = ({
  size = "auto",
  viewBox,
  children,
  ...passThrough
}: ComponentProps) => {
  const pixels = iconSizes[size];

  return (
    <svg
      role="img"
      viewBox={viewBox}
      fill={"currentColor"}
      height={pixels === "auto" ? "1em" : `${pixels}px`}
      width={pixels === "auto" ? "1em" : `${pixels}px`}
      {...passThrough}
    >
      {children}
    </svg>
  );
};
