import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";

import {
  AlibabaRequestPayload,
  AwsRequestPayload,
  AzureRequestPayload,
  GcpRequestPayload,
  IbmRequestPayload,
  OciRequestPayload,
  type AlibabaRequestPayloadSchema,
  type AwsRequestPayloadType,
  type AzureRequestPayloadType,
  type IbmRequestPayloadSchema,
  type OciRequestPayloadSchema,
} from "../cloudAccountStatus";
import { type cloudAccountsKeys } from "../cloudAccountsKeys";
import {
  AWSAccountDataSecurityResponseSchema,
  AWSOrgDataSecurityResponseSchema,
  AWSTempAccountDetailsResponseSchema,
  AwsOrgUpdateConfigSchema,
  AwsResponseSchema,
  AzureDataSecurityResponseSchema,
  AzureDataSecurityStatusResponseSchema,
  AzureResponseSchema,
  CloudAccountResponseSchema,
  GcpResponseSchema,
  IbmResponseSchema,
  SaveAwsTempAccountRoleArnPayload,
  TempCloudAccountPayload,
  type AwsOrgUpdateConfigType,
  type TempCloudAccountPayloadSchema,
} from "./schemas";

export const getCloudAccountCas = async ({
  queryKey: [{ accountId, cloudType, includeGroupInfo }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["cloudAccountsCas"]>
>) => {
  const endpoint = ["aws", "azure"].includes(cloudType)
    ? `v1/cloudAccounts/${cloudType}Accounts/${accountId}`
    : `cas/v1/cloud_account/${cloudType}/${accountId}`;
  return jsonApi({
    path: endpoint,
    params: {
      includeGroupInfo: `${includeGroupInfo}`,
    },
    requestSchema: z.void(),
    responseSchema: CloudAccountResponseSchema,
  });
};

export const getAwsCloudAccountsCas = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsCloudAccountsCas"]>
>) => {
  return jsonApi({
    path: `v1/cloudAccounts/awsAccounts/${accountId}`,
    requestSchema: z.void(),
    responseSchema: AwsResponseSchema,
  });
};

export const getAzureCloudAccountsCas = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["azureCloudAccountsCas"]>
>) => {
  return jsonApi({
    path: `v1/cloudAccounts/azureAccounts/${accountId}`,
    requestSchema: z.void(),
    responseSchema: AzureResponseSchema,
  });
};

export const getGcpCloudAccountsCas = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["gcpCloudAccountsCas"]>
>) => {
  return jsonApi({
    path: `cas/v1/cloud_account/gcp/${accountId}`,
    requestSchema: z.void(),
    responseSchema: GcpResponseSchema,
  });
};

export const getIbmCloudAccountsCas = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["ibmCloudAccountsCas"]>
>) => {
  return jsonApi({
    path: `cas/v1/cloud_account/ibm/${accountId}`,
    requestSchema: z.void(),
    responseSchema: IbmResponseSchema,
  });
};

export const getAzureAccountDataSecurityDetails = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["getAzureAccountDataSecurityDetails"]>
>) => {
  return jsonApi({
    path: `pcds/config/v3/account/${accountId}`,
    requestSchema: z.void(),
    responseSchema: AzureDataSecurityResponseSchema,
  });
};

export const getAzureAccountDataSecurityStatus = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["getAzureAccountDataSecurityStatus"]>
>) => {
  return jsonApi({
    path: `pcds/config/v3/account/${accountId}/status`,
    requestSchema: z.void(),
    responseSchema: AzureDataSecurityStatusResponseSchema,
  });
};

export const getAwsTempAccountDetails = async ({
  queryKey: [{ accountName }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsTempcloudAccountDetails"]>
>) => {
  return jsonApi({
    path: `cas/api/v1/cloud-account/temp/aws/account/${accountName}`,
    requestSchema: z.void(),
    responseSchema: AWSTempAccountDetailsResponseSchema,
  });
};

export const saveIbmCloudAccount = async ({
  payload,
}: {
  payload: IbmRequestPayloadSchema;
}) => {
  return jsonApi({
    path: "cas/v1/cloud-type/ibm/account",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: IbmRequestPayload,
    responseSchema: z.any(),
  });
};

export const updateIbmCloudAccount = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: IbmRequestPayloadSchema;
}) => {
  return jsonApi({
    path: `cas/v1/cloud-type/ibm/account/${accountId}`,
    body: { ...payload },
    config: { method: "put" },
    requestSchema: IbmRequestPayload,
    responseSchema: z.any(),
  });
};

export const saveAwsCloudAccount = async ({
  payload,
}: {
  payload: AwsRequestPayloadType;
}) => {
  return jsonApi({
    path: "cas/v1/aws_account",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: AwsRequestPayload,
    responseSchema: z.void(),
  });
};

export const saveAwsTempAccount = async ({
  payload,
}: {
  payload: TempCloudAccountPayloadSchema;
}) => {
  return jsonApi({
    path: "cas/api/v1/cloud-account/temp/aws",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: TempCloudAccountPayload,
    responseSchema: z.any(),
  });
};

export const saveAwsTempAccountRoleARN = async ({
  payload,
}: {
  payload: {
    name: string;
    roleArn: string;
  };
}) => {
  return jsonApi({
    path: "cas/v1/aws_account/temp",
    config: {
      method: "put",
    },
    body: payload,
    requestSchema: SaveAwsTempAccountRoleArnPayload,
    responseSchema: z.void(),
  });
};

export const accountNameExists = async ({
  queryKey: [{ name }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["accountNameExists"]>
>) => {
  return jsonApi({
    path: "cas/api/v1/cloud-account/exists",
    params: {
      name,
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};

export const updateAwsCloudAccount = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: AwsRequestPayloadType;
}) => {
  return jsonApi({
    path: `cas/v1/aws_account/${accountId}`,
    body: { ...payload },
    config: { method: "put" },
    requestSchema: AwsRequestPayload,
    responseSchema: z.any(),
  });
};

export const saveAzureCloudAccount = async ({
  payload,
}: {
  payload: AzureRequestPayloadType;
}) => {
  return jsonApi({
    path: "cas/v1/azure_account",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: AzureRequestPayload,
    responseSchema: z.void(),
  });
};

export const updateAzureCloudAccount = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: AzureRequestPayloadType;
}) => {
  return jsonApi({
    path: `cas/v1/azure_account/${accountId}`,
    body: { ...payload },
    config: { method: "put" },
    requestSchema: AzureRequestPayload,
    responseSchema: z.any(),
  });
};

export const saveAlibabaCloudAccount = async ({
  payload,
}: {
  payload: AlibabaRequestPayloadSchema;
}) => {
  return jsonApi({
    path: "cloud/alibaba_cloud",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: AlibabaRequestPayload,
    responseSchema: z.void(),
  });
};

export const updateAlibabaCloudAccount = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: AlibabaRequestPayloadSchema;
}) => {
  return jsonApi({
    path: `cloud/alibaba_cloud/${accountId}`,
    body: { ...payload },
    config: { method: "put" },
    requestSchema: AlibabaRequestPayload,
    responseSchema: z.any(),
  });
};

export const saveOciCloudAccount = async ({
  payload,
}: {
  payload: OciRequestPayloadSchema;
}) => {
  return jsonApi({
    path: "cloud/oci",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: OciRequestPayload,
    responseSchema: z.void(),
  });
};

export const updateOciCloudAccount = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: OciRequestPayloadSchema;
}) => {
  return jsonApi({
    path: `cloud/oci/${accountId}`,
    body: { ...payload },
    config: { method: "put" },
    requestSchema: OciRequestPayload,
    responseSchema: z.any(),
  });
};

export const saveGcpCloudAccount = async ({
  payload,
}: {
  payload: z.infer<typeof GcpRequestPayload>;
}) => {
  return jsonApi({
    path: "cas/v1/gcp_account",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: GcpRequestPayload,
    responseSchema: z.void(),
  });
};

export const updateGcpCloudAccount = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: z.infer<typeof GcpRequestPayload>;
}) => {
  return jsonApi({
    path: `cas/v1/gcp_account/${accountId}`,
    body: { ...payload },
    config: { method: "put" },
    requestSchema: GcpRequestPayload,
    responseSchema: z.any(),
  });
};

export const getAWSOrgDataSecurity = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsOrgDataSecurity"]>
>) => {
  return jsonApi({
    path: `pcds/config/v3/account/awsorg/${accountId}`,
    requestSchema: z.void(),
    responseSchema: AWSOrgDataSecurityResponseSchema,
  });
};

export const updateAwsOrgConfig = async (
  accountId: string,
  body: AwsOrgUpdateConfigType,
) => {
  return jsonApi({
    path: `pcds/config/v3/account/${accountId}`,
    body,
    config: {
      method: "put",
    },
    requestSchema: AwsOrgUpdateConfigSchema,
    responseSchema: AwsOrgUpdateConfigSchema,
  });
};

export const createAwsOrgConfig = async (body: AwsOrgUpdateConfigType) => {
  return jsonApi({
    path: "pcds/config/v3/account",
    body,
    config: {
      method: "post",
    },
    requestSchema: AwsOrgUpdateConfigSchema,
    responseSchema: z.any(),
  });
};

export const getAwsOrgStatus = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsOrgDataSecurityStatus"]>
>) => {
  return jsonApi({
    path: `pcds/config/v3/account/awsorg/${accountId}/status`,
    requestSchema: z.void(),
    responseSchema: z.object({
      isSuccess: z.boolean(),
      message: z.string().nullish(),
    }),
  });
};

export const getAwsAccountDataSecurity = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsAccountDataSecurity"]>
>) => {
  return jsonApi({
    path: `dlp/api/v1/tenant/config/aws/${accountId}`,
    requestSchema: z.void(),
    responseSchema: AWSAccountDataSecurityResponseSchema,
  });
};
