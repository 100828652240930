import { type FC } from "react";
import { useIntl } from "react-intl";
import { SearchFilter } from "ui";

export interface TableFilterProps {
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  isTooltipVisible?: boolean;
  tooltip?: string;
}

export const TableFilter: FC<TableFilterProps> = ({
  onChange,
  placeholder,
  value,
  isTooltipVisible,
  tooltip,
}: TableFilterProps) => {
  const intl = useIntl();

  return (
    <SearchFilter
      tooltip={
        tooltip ||
        intl.formatMessage({
          defaultMessage: "Search for specific data within the table",
          id: "u/y5Zn",

          description: "Table filter tooltip",
        })
      }
      aria-label={intl.formatMessage({
        defaultMessage: "Table filter",
        id: "I5fba4",

        description: "Label for table filter",
      })}
      onChange={onChange}
      placeholder={
        placeholder ||
        intl.formatMessage({
          defaultMessage: "Search table data...",
          id: "R9SAk/",
          description: "Placeholder for table filter",
        })
      }
      value={value}
      isTooltipVisible={isTooltipVisible}
    />
  );
};
