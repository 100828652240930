import { GridHorizontalIcon, GridVerticalIcon, type IconSizes } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "ui";
import { classNames } from "utils";

type DragHandleProps = {
  label: string;
  isHeld?: boolean;
  direction?: "vertical" | "horizontal";
  disabled?: boolean | string;
  iconSize?: IconSizes;
  [key: string]: unknown;
};

export function DragHandle({
  isHeld,
  direction = "vertical",
  disabled = false,
  label,
  iconSize = "md",
  ...rest
}: DragHandleProps): ReactElement {
  const intl = useIntl();

  const button = (
    <span
      aria-label={label}
      tabIndex={0}
      className={classNames(
        "flex-none rounded",
        isHeld ? "text-default" : "text-icon-gray",
        disabled ? "cursor-not-allowed" : "cursor-move focus:outline-none",
      )}
      {...rest}
    >
      {direction === "vertical" ? (
        <GridHorizontalIcon size={iconSize} />
      ) : (
        <GridVerticalIcon size={iconSize} />
      )}
    </span>
  );

  if (isHeld) return button;

  if (disabled) {
    /* c8 ignore next */
    if (typeof disabled === "boolean") return button;

    return <Tooltip label={disabled}>{button}</Tooltip>;
  }

  return (
    <Tooltip
      label={intl.formatMessage({
        defaultMessage: "Drag to reorder",
        id: "Y/mR9l",

        description: "Drag icon user can click on to reorder",
      })}
    >
      {button}
    </Tooltip>
  );
}
