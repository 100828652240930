import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { Select } from "form";

import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isNil } from "remeda";
import { getResourceListAllNames, rolesKeys } from "requests";
import { type InitialValues } from "../roleTypes";
import { sortByKey } from "./utils";

const BUILD_AND_DEPLOY_SECURITY = "Build and Deploy Security";

export default function ResourceListField({
  roleType,
  initialValues,
}: {
  initialValues?: InitialValues;
  roleType: { value: string };
}) {
  const { resourceListRbacM3 } = useFlags();
  const intl = useIntl();

  const { data = [], isLoading } = useQuery({
    queryKey: rolesKeys.resourceListAllNames(),
    queryFn: getResourceListAllNames,
  });

  const resourceLists = useMemo(
    () =>
      !isLoading && !isNil(data)
        ? sortByKey(data, "name").map((r: { id: string; name: string }) => ({
            value: r.id,
            children: r.name,
          }))
        : [],
    [data, isLoading],
  );

  const { watch } = useFormContext();

  const additionalAttributes = watch(
    "additionalAttributes",
    initialValues
      ? initialValues?.additionalAttributes
      : { onlyAllowCIAccess: false },
  );

  const hideResourceListForCiRole =
    roleType?.value === BUILD_AND_DEPLOY_SECURITY &&
    additionalAttributes?.onlyAllowCIAccess;

  if (hideResourceListForCiRole) return null;
  return (
    <Select
      enableMultiSelect
      enableSearch
      enableSelectAll={resourceLists.length > 1}
      label={intl.formatMessage({
        defaultMessage: "Resource List",
        id: "zcfpxK",
        description: "Label for Reource List  field",
      })}
      name="resourceLists"
      defaultValue={initialValues?.resourceListIds?.map((id) => ({
        value: id,
      }))}
      items={resourceLists}
      register={{ required: false }}
      disabled={isLoading}
      menuStyle={{ minWidth: "34rem" }}
      itemToString={(item) => `${item?.children}`}
      isLoading={isLoading}
      tooltip={
        resourceListRbacM3 &&
        intl.formatMessage({
          defaultMessage:
            "Tag-based Resource Lists are only applicable on Asset Inventory, Explorer, Alert, Alert Report, Compliance, and Compliance Report",
          id: "uLIUAc",
          description: "Tooltip message for Resource List dropdown",
        })
      }
    />
  );
}
