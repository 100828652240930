import { isCasDevelopment, isProduction } from "environment";
import { useTokenStore } from "requests";

export const casModulesBaseUrlKey = "casModulesBaseUrl";

/**
 * Get the Cloud Application Security module static files base URL based on the runtime environment
 * @returns {string}
 */
export const getCasUrl = () => {
  const casModulesBaseUrl = sessionStorage.getItem(casModulesBaseUrlKey);

  if (!isProduction() && casModulesBaseUrl) {
    return casModulesBaseUrl;
  }

  if (isCasDevelopment()) {
    return "http://localhost:9872";
  }

  const apiHost = new URL(useTokenStore.getState().token.iss).host;
  const subdomain = apiHost
    .replace("api", "app")
    .replace(/(.sam)?.prismacloud.(io|cn)/, "");

  switch (apiHost) {
    case "apibriddev.k8sdev.prismacloud.io":
      return `https://www.pcdev.bridgecrew.cloud`;
    case "api3briddev.k8sdev.prismacloud.io":
      return `https://www.pcdev2.bridgecrew.cloud`;
    case "api2coreqa.k8sdev.prismacloud.io":
    case "apiliceqa.k8sdev.prismacloud.io":
      return `https://www.stage2.bridgecrew.cloud`;
    case "localhost":
    case "api-stage.prismacloud.io":
      return `https://www.stage.bridgecrew.cloud`;
    case "api-preprod.sam.prismacloud.io":
      return `https://www.apppreprod.bridgecrew.cloud`;
    case "api3.prismacloud.io":
      return `https://www.bridgecrew.cloud`;
    case "api2.eu.prismacloud.io":
      return `https://www.app2-eu.bridgecrew.cloud`;
    case "api.eu.prismacloud.io":
      return `https://www.app-eu.bridgecrew.cloud`;
    case "api.ca.prismacloud.io":
      return `https://www.app-ca.bridgecrew.cloud`;
    case "api.uk.prismacloud.io":
      return `https://www.app-uk.bridgecrew.cloud`;
    case "api.sg.prismacloud.io":
      return `https://www.app-sg.bridgecrew.cloud`;
    case "api.anz.prismacloud.io":
      return `https://www.app-anz.bridgecrew.cloud`;
    case "api.ind.prismacloud.io":
      return `https://www.app-ind.bridgecrew.cloud`;
    case "api.jp.prismacloud.io":
      return `https://www.app-jp.bridgecrew.cloud`;
    case "api.gov.prismacloud.io":
      return `https://www.app-fedramp-prod.bridgecrew.cloud`;
    case "api.gov-qa.prismacloud.io":
      return `https://www.app-fedramp-dev.bridgecrew.cloud`;
    case "api-qa-gov.gov.prismacloud.io":
      return `https://www.app-fedramp-stage.bridgecrew.cloud`;
    case "api.fr.prismacloud.io":
      return `https://www.app-fr.bridgecrew.cloud`;
    case "api.id.prismacloud.io":
      return `https://www.app-id.bridgecrew.cloud`;
    default:
      return `https://www.${subdomain}.bridgecrew.cloud`;
  }
};
