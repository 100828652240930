import { useCallback } from "react";
import { useRole } from "../useRole";

const allowedPagesByRole = {
  "Build and Deploy Security": {
    settingsResourceLists: true,
  },
  "Build and Deploy Security CI": {
    settingsResourceLists: true,
  },
  "Compute Admin": {},
  Developer: {
    codeSecurityProjects: true,
    repositories: true,
    sbom: true,
  },
  "System Admin": {
    repositories: true,
    technologies: true,
    sbom: true,
    codeSecurityProjects: true,
    pipelineRisks: true,
  },
  "Enhanced Compute": {
    roles: true,
    accessKeys: true,
  },
  NetSecOps: {
    dashboardNetSecOps: true,
    investigate: true,
    networkSecurity: true,
    settingsCloudAccounts: true,
    settingsAccountGroups: true,
    prismaCloudFirewall: true,
  },
};

export const useFallbackPermissions = () => {
  const { syntheticRole } = useRole();

  return useCallback(
    (page: string): boolean => {
      const pageRoles =
        allowedPagesByRole?.[syntheticRole as keyof typeof allowedPagesByRole];

      const hasRoleToAccesPage = pageRoles?.[page as keyof typeof pageRoles];

      if (!pageRoles) return true;

      return hasRoleToAccesPage ?? false;
    },
    [syntheticRole],
  );
};
