import { useIntl } from "react-intl";

import { Body, Bold, DynamicFormattedMessage, Tooltip, TruncateText } from "ui";

import { blueScale } from "colors";
import { CheckIcon, DeleteIcon } from "icons";
import { type ReactNode } from "react";
import { selectAuthenticationTypeMap } from "../../messages";

type FileUploadDetailsProps = {
  children: ReactNode;
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isServiceAccount: boolean;
  name: string;
};

export default function FileUploadDetails({
  children,
  handleClose,
  isServiceAccount,
  name,
}: FileUploadDetailsProps) {
  const intl = useIntl();

  return (
    <div className="mt-4 space-y-2 rounded bg-blue-100 p-2 text-xs dark:bg-blue-steel-850 dark:text-dark-bg">
      {name ? (
        <div className="flex justify-between">
          <DynamicFormattedMessage
            messageMap={selectAuthenticationTypeMap}
            token="fileDetailsHeader"
            values={{
              b: (chunks) => <Bold addClassName="mx-0.5">{chunks}</Bold>,
              fileType: isServiceAccount
                ? intl.formatMessage(
                    selectAuthenticationTypeMap.serviceAccountKey,
                  )
                : intl.formatMessage(
                    selectAuthenticationTypeMap.workloadIdentityFederation,
                  ),
            }}
          />
          <div className="mr-2 flex items-start space-x-4">
            <Body as="span" addClassName="flex items-start space-x-1" size="sm">
              <CheckIcon className="mt-0.5 text-icon-green" size="sm" />
              <div className="flex max-w-[14rem]">
                <TruncateText>{name}</TruncateText>
              </div>
            </Body>
            <Tooltip
              label={intl.formatMessage(selectAuthenticationTypeMap.delete)}
            >
              <DeleteIcon
                onClick={handleClose as unknown as () => void}
                className="mr-2 cursor-pointer"
                aria-label={intl.formatMessage(
                  selectAuthenticationTypeMap.delete,
                )}
                size="sm"
                color={blueScale[700]}
              />
            </Tooltip>
          </div>
        </div>
      ) : null}
      <ol className="list-none">{children}</ol>
    </div>
  );
}
