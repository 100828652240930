import { ChipInput, Input } from "form";

import { LoadingIcon } from "icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Body, Link } from "ui";
import { type AdditionalDataType } from "./ASMSubscriptionModal";

type DUNSandDomainsInputProps = {
  additionalData?: AdditionalDataType;
  isLoading: boolean;
};
export default function DUNSandDomainsInput({
  additionalData,
  isLoading,
}: DUNSandDomainsInputProps) {
  const intl = useIntl();
  if (isLoading)
    return (
      <div className="my-4 flex w-full justify-center py-4">
        <LoadingIcon />
      </div>
    );
  return (
    <div className="my-4 flex w-full flex-col flex-nowrap">
      <div className="w-full">
        <Body addClassName="py-1" size="sm">
          <FormattedMessage
            defaultMessage="DUNS"
            id="4aOrIH"
            description="Label for DUNS input field"
          />
          <Link
            external
            href="https://www.dnb.com/business-directory.html#BusinessDirectoryPageNumber=1&ContactDirectoryPage[…]=1&SiteContentPageNumber=1&tab=Business%20Directory"
          >
            <span className="mx-2 text-2xs">
              <FormattedMessage
                defaultMessage="( What is DUNS? )"
                id="A6B/bs"
                description="Label for DUNS description link"
              />
            </span>
          </Link>
        </Body>
        <Input
          name="dunsNumber"
          showLabel={false}
          label="DUNS"
          placeholder="##-###-####"
          defaultValue={additionalData?.dunsNumber}
          register={{
            validate: (value) => {
              if (Number.isNaN(Number(value))) {
                return intl.formatMessage({
                  defaultMessage: "The DUNS value should only contain numbers.",
                  id: "mbW7GB",
                  description: "Validation message for DUNS number input field",
                });
              }
              return true;
            },
          }}
        />
      </div>
      <div className="mt-4 w-full">
        <ChipInput
          name="domains"
          label={intl.formatMessage({
            defaultMessage: "Domain",
            id: "Z6AW8s",
            description: "Label for Domain input field",
          })}
          isLoading={isLoading}
          items={additionalData?.domains}
          defaultValue={additionalData?.domains}
        />
      </div>
    </div>
  );
}
