import { animated, useSpring } from "@react-spring/web";
import { useEffect, useMemo, useState } from "react";
import {
  Body,
  Button,
  DynamicFormattedMessage,
  Logo,
  Select,
  type SelectItem,
} from "ui";

import { Link } from "@tanstack/react-router";
import { useCodeEnabled, useComputeEnabled } from "prisma";
import { FormattedMessage } from "react-intl";
import { type ColorTheme, type UCDMode } from "requests";
import { CrystalIcon } from "../../../components/CrystalIcon";
import { UCDIcon } from "../../../components/Navigation/UCDSelect/UCDIcon";
import { fttvExperienceSidecarNav } from "./message";

export default function PrismaUILayout({
  theme,
  selectedRole,
  setRole,
}: {
  theme: ColorTheme;
  selectedRole: UCDMode;
  setRole: React.Dispatch<
    React.SetStateAction<{
      value: UCDMode;
    }>
  >;
}) {
  const { codeEnabled } = useCodeEnabled();
  const computeEnabled = useComputeEnabled();

  const items = useMemo<SelectItem[]>(
    () =>
      [
        {
          value: "cloud",
          iconLeft: <UCDIcon mode="cloud" theme={theme} />,
          children: (
            <Body addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Cloud Security"
                id="k1eYQ6"
                description="Cloud Security UCD dropdown option"
              />
            </Body>
          ),
        },
        computeEnabled && {
          value: "runtime",
          iconLeft: <UCDIcon mode="runtime" theme={theme} />,
          children: (
            <Body addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Runtime Security"
                id="SM88rH"
                description="Runtime Security UCD dropdown option"
              />
            </Body>
          ),
        },
        codeEnabled && {
          value: "application",
          iconLeft: <UCDIcon mode="application" theme={theme} />,
          children: (
            <Body addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Application Security"
                id="rog4IP"
                description="Application Security UCD dropdown option"
              />
            </Body>
          ),
        },
      ].filter(Boolean) as SelectItem[],
    [theme, codeEnabled, computeEnabled],
  );
  const [selectedItem, setSelectedItem] = useState<SelectItem | null>();

  const linkClass = useMemo(() => {
    return `rounded py-2 px-1.5 font-semibold hover:no-underline ${theme} hover:bg-skin-fill dark:hover:bg-skin-darkFill dark:text-white text-xs`;
  }, [theme]);

  const activeLinkClass = useMemo(() => {
    return `rounded py-2 px-1.5 font-semibold ${theme} bg-skin-fill dark:hover:bg-skin-darkFill dark:text-white text-xs`;
  }, [theme]);

  const [prismaUILayoutProps] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: { mass: 100, tension: 90, friction: 100, clamp: true },
  }));

  useEffect(() => {
    const selectedItem = items.find(
      (item) => item.value === selectedRole,
    ) as SelectItem;
    setSelectedItem(selectedItem);
  }, [items, selectedRole]);

  return (
    <div className="relative h-4/6 w-[50rem] overflow-hidden rounded-l-lg bg-white dark:bg-blue-steel-950 ">
      <RenderIcon theme={theme} />
      <animated.div style={prismaUILayoutProps} className="h-full">
        <div className="flex flex-wrap rounded-t-lg border-b border-gray-300 bg-gray-100/50 px-4 py-3 text-xs text-default backdrop-blur-sm dark:border-blue-steel-850 dark:bg-blue-steel-950 dark:text-dark-bg">
          <div className="w-full">
            <nav className="flex justify-between bg-gray-100/50 px-4 py-3 text-xs text-default dark:border-blue-steel-850 dark:bg-blue-steel-950 dark:text-dark-bg">
              <div className="flex items-center">
                <Link>
                  <Logo className="mx-2 h-7" width={150} />
                </Link>
                <Select
                  button={
                    <Button
                      icon={
                        <UCDIcon
                          mode={selectedItem?.value as UCDMode}
                          theme={theme}
                        />
                      }
                      isMenu
                    >
                      {selectedItem?.children}
                    </Button>
                  }
                  items={items}
                  selectedItem={selectedItem}
                  onChange={(changes) => {
                    setSelectedItem(changes.selectedItem as SelectItem);
                    setRole({
                      value: changes?.selectedItem?.value as UCDMode,
                    });
                  }}
                  menuStyle={{ width: "11.5rem" }}
                />

                <div className="ml-6 space-x-2">
                  <Link className={activeLinkClass}>
                    <FormattedMessage
                      defaultMessage="Home"
                      id="stEcrD"
                      description="nav link"
                    />
                  </Link>
                  <Link className={linkClass}>
                    <FormattedMessage
                      defaultMessage="Dashboards"
                      id="oAxdeR"
                      description="nav link"
                    />
                  </Link>
                  {selectedRole !== "application" && (
                    <Link className={linkClass}>
                      <FormattedMessage
                        defaultMessage="Reports"
                        id="mWl0N1"
                        description="nav link"
                      />
                    </Link>
                  )}
                  <Link className={linkClass}>
                    <FormattedMessage
                      defaultMessage="Inventory"
                      id="CLFPqO"
                      description="nav link"
                    />
                  </Link>
                  {selectedRole !== "application" && (
                    <Link className={linkClass}>
                      <FormattedMessage
                        defaultMessage="Alerts"
                        id="Y83je+"
                        description="nav link"
                      />
                    </Link>
                  )}
                  <Link className={linkClass}>
                    <FormattedMessage
                      defaultMessage="Investigate"
                      id="HGBtDd"
                      description="nav link"
                    />
                  </Link>
                  <Link className={linkClass}>
                    <FormattedMessage
                      defaultMessage="Governance"
                      id="GQ9FZF"
                      description="nav link"
                    />
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="flex h-full">
          <div
            className="flex w-64 flex-col justify-stretch border-r border-gray-300 p-4 backdrop-blur-2xl dark:border-blue-steel-850"
            style={{
              height: "calc(100% - 76px)",
            }}
          >
            <CloudSideNav
              selectedRole={selectedRole}
              linkClass={linkClass}
              activeLinkClass={activeLinkClass}
            />
            <SideNav selectedRole={selectedRole} linkClass={linkClass} />
          </div>
          <div
            className="w-full"
            style={{
              height: "calc(100% - 76px)",
            }}
          ></div>
        </div>
      </animated.div>
    </div>
  );
}

function RenderIcon({ theme }: { theme: ColorTheme }) {
  return (
    <div className="absolute right-[-85px] top-[61px]">
      <CrystalIcon theme={theme} />
    </div>
  );
}

function displayNavItem({
  className,
  token,
}: {
  className?: string;
  token: string;
}) {
  return className ? (
    <Link className={className}>
      <DynamicFormattedMessage
        messageMap={fttvExperienceSidecarNav}
        token={token}
      />
    </Link>
  ) : (
    <DynamicFormattedMessage
      messageMap={fttvExperienceSidecarNav}
      token={token}
    />
  );
}

const navItems = {
  application: {
    VISIBILITY: ["Repositories", "Technologies", "SBOM"],
    CODE: ["Projects"],
    "CI/CD": ["CI/CD Risks"],
  },
  runtime: {
    RADARS: ["Cloud", "Hosts", "Containers", "Serverless", "Settings"],
    DEFEND: [
      "Vulnerabilities",
      "Compliance",
      "Runtime",
      "WAAS",
      "CNNS",
      "Access",
      "Custom Rules",
    ],
  },
};

function SideNav({
  selectedRole,
  linkClass,
}: {
  selectedRole: UCDMode;
  linkClass: string;
}) {
  return (
    <>
      {Object.entries(
        navItems[selectedRole as keyof typeof navItems] ?? {},
      ).map(([key, value], index) => {
        return (
          <>
            <span
              className={`${
                index && "mt-2"
              } text-sm text-secondary dark:text-dark-bg-secondary`}
            >
              {displayNavItem({ token: key })}
            </span>
            {value.map((token) =>
              displayNavItem({ className: linkClass, token }),
            )}
          </>
        );
      })}
    </>
  );
}

function CloudSideNav({
  selectedRole,
  linkClass,
  activeLinkClass,
}: {
  selectedRole: UCDMode;
  linkClass: string;
  activeLinkClass: string;
}) {
  return (
    <>
      {selectedRole === "cloud" && (
        <>
          {displayNavItem({ className: activeLinkClass, token: "Welcome" })}
          {displayNavItem({
            className: linkClass,
            token: "Adoption Advisor",
          })}
        </>
      )}
    </>
  );
}
