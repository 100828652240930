import { Button, ModalFooter } from "ui";
import { useWizard, useWizardSubmitHandlers } from ".";

import { type ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { SubmitButton } from "../components/SubmitButton";

type ModalWizardFooterProps = {
  customFooterActions?: ReactNode;
};

export const ModalWizardFooter = ({
  customFooterActions,
}: ModalWizardFooterProps) => {
  const { currentStep, steps } = useWizard();
  const { appendValuesMoveBackward } = useWizardSubmitHandlers();
  const { getValues } = useFormContext();

  return (
    <ModalFooter>
      {currentStep.previousStepName && (
        <Button
          onClick={() => {
            appendValuesMoveBackward(getValues(), currentStep.previousStepName);
          }}
        >
          <FormattedMessage
            defaultMessage="Previous"
            id="ao9rKw"
            description="Previous button"
          />
        </Button>
      )}

      {customFooterActions ? (
        customFooterActions
      ) : currentStep.nextStepName ? (
        <SubmitButton>
          <FormattedMessage
            defaultMessage="Next"
            id="3Wcqsy"
            description="Next button"
          />
        </SubmitButton>
      ) : (
        <SubmitButton disabled={steps.some((s) => s.status === "error")}>
          <FormattedMessage
            defaultMessage="Save"
            id="+itf/D"
            description="Save button"
          />
        </SubmitButton>
      )}
    </ModalFooter>
  );
};
