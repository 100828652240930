import { DangerIcon, InfoIcon, SuccessIcon, WarningIcon } from "icons";
import { useCallback, useMemo, type ReactNode } from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "ui";
import { type Status } from "../../../../../types";

import { type PermissionsStatusComponent } from "requests";
import { type RowInstance } from "table";

type TypeProps = {
  row: Pick<RowInstance<PermissionsStatusComponent>, "original">;
};

const iconMapping: Record<Exclude<Status, "pending">, ReactNode> = {
  disabled: <InfoIcon size="md" />,
  error: <DangerIcon size="md" />,
  ok: <SuccessIcon size="md" />,
  success: <SuccessIcon size="md" />,
  warning: <WarningIcon size="md" />,
  enabled: undefined,
};

export default function StatusType({ row }: TypeProps) {
  const { status } = row.original;

  const intl = useIntl();

  const statusText = useCallback(() => {
    switch (status) {
      case "ok":
        return intl.formatMessage({
          defaultMessage: "Data ingestion successful",
          id: "2ctudn",
          description: "description for ok status",
        });
      case "warning":
        return intl.formatMessage({
          defaultMessage: "Unable to ingest data from one or more services",
          id: "DxB56X",
          description: "description for warning status",
        });
      case "error":
        return intl.formatMessage({
          defaultMessage: "Unable to ingest the data",
          id: "2Z9Rzs",
          description: "description for error status",
        });
      case "disabled":
        return intl.formatMessage({
          defaultMessage: "Unable to recognize ingestion progress",
          id: "EtOFSo",
          description: "description for disabled status",
        });
      default:
        return undefined;
    }
  }, [intl, status]);

  const IconComponent = useMemo(() => {
    return iconMapping[status as Exclude<Status, "pending">];
  }, [status]);

  return (
    <Tooltip
      label={intl.formatMessage(
        {
          defaultMessage: "{statusText}",
          id: "il0CaN",
          description: "Account details status text",
        },
        { statusText: statusText() },
      )}
    >
      <span>{IconComponent}</span>
    </Tooltip>
  );
}
