import {
  useCombobox,
  useMultipleSelection,
  type UseMultipleSelectionProps,
} from "downshift";
import {
  useEffect,
  useMemo,
  type CSSProperties,
  type FormEvent,
  type FormEventHandler,
  type ReactElement,
} from "react";
import { FormattedMessage } from "react-intl";
import { classNames, noop } from "utils";
import { v4 as uuidV4 } from "uuid";
import { useFloating, type Placement } from "../../utils";
import {
  useOptionallyControlledState,
  type SetControlledState,
} from "../../utils/useOptionallyControlledState";
import { Portal } from "../Portal";
import { SelectList, type SelectItem } from "../Select";
import { Tag, type TagAppearances } from "../Tag";
import { Body } from "../Typography/Body";

export type ChipInputSelectItem = SelectItem & {
  appearance?: TagAppearances;
};

const defaultItemToString = (item: SelectItem) => String(item.value);

const defaultFilterFn = (filter: string, value: string) =>
  value.toLowerCase().includes(filter.toLowerCase());

export interface ChipInputProps {
  /**
   * Defaults to true. Set to false to disable arbitrary items.
   */
  allowArbitraryItems?: boolean;
  appearance?: "default" | "input-error";
  /**
   * When inside a modal, apply focus to the select once the modal mounts.
   */
  ["data-autofocus"]?: boolean;
  /**
   * Placement of the menu relative to the button anchor element
   */
  defaultPlacement?: Placement;
  disabled?: boolean;
  filterFn?: typeof defaultFilterFn;
  /**
   * Set to true to add a loading icon if the items are still pending.
   */
  isLoading?: boolean;
  /**
   * Show Clear All link.
   */
  clearAll?: boolean;
  /**
   * Items the user can select from. When undefined the dropdown will not be displayed.
   */
  items?: ChipInputSelectItem[];
  itemToString?: UseMultipleSelectionProps<SelectItem>["itemToString"];
  /**
   * Use to pass what the label ID is. This will be used to set the appropriate aria attributes
   * on the input and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `inputId`.
   *
   * Be sure to also set `inputId`.
   */
  labelId?: string;
  /**
   * Use to pass what the input ID should be. This will be used to set the appropriate aria attributes
   * on the input and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `inputId`.
   *
   * Be sure to also set `labelID`.
   */
  inputId?: string;
  /**
   * The value of the text input, when controlled.
   *
   * This can be useful for providing items based on a server response to the input value.
   *
   * Use in conjunction with `onInputValueChange`.
   */
  inputValue?: string;
  /**
   * Apply styles to the menu.
   *
   * Useful for overriding the default width and height.
   */
  menuStyle?: CSSProperties;
  onInputBlur?: FormEventHandler<HTMLInputElement>;
  /**
   * Invoked when the input value changes. Use for controlling the `inputValue`.
   */
  onInputValueChange?: SetControlledState<string>;
  /**
   * Invoked when the user selects an item.
   */
  onSelectedItemsChange: UseMultipleSelectionProps<SelectItem>["onSelectedItemsChange"];
  /**
   * A placeholder to display on the input
   */
  placeholder?: HTMLInputElement["placeholder"];
  /**
   * A function that is called on submit of the arbitrary input.
   * Return false to prevent the submission of the input.
   */
  onInputSubmit?: (value: string) => boolean | undefined;
  /**
   * The selected items.
   *
   * Currently, this component only supports the [controlled](https://reactjs.org/docs/forms.html#controlled-components) pattern.
   */
  selectedItems?: ChipInputSelectItem[];
}

export function ChipInput({
  allowArbitraryItems = true,
  clearAll = true,
  "data-autofocus": dataAutofocus,
  appearance,
  defaultPlacement = "bottom-start",
  disabled,
  filterFn = defaultFilterFn,
  inputValue: userInputValue,
  isLoading,
  items,
  itemToString = defaultItemToString,
  labelId,
  inputId,
  menuStyle,
  onInputBlur,
  onInputValueChange,
  onInputSubmit,
  onSelectedItemsChange,
  placeholder,
  selectedItems = [],
}: ChipInputProps): ReactElement {
  const baseId = useMemo((): string => uuidV4(), []);
  const hasError = appearance === "input-error";
  const [inputValue, handleInputValueChange] =
    useOptionallyControlledState<string>({
      currentValue: userInputValue,
      defaultValue: "",
      onChange: onInputValueChange,
    });

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    reset,
  } = useMultipleSelection<SelectItem>({
    itemToString,
    onSelectedItemsChange,
    selectedItems,
  });

  const filteredItems = useMemo(() => {
    const base: SelectItem[] =
      allowArbitraryItems && inputValue ? [{ value: inputValue }] : [];
    const unselectedItems = base.concat(
      (items ?? []).filter(
        (item) =>
          !selectedItems.some(
            (selectedItem) => selectedItem.value === item.value,
          ),
      ),
    );

    return unselectedItems.filter((item) =>
      filterFn(inputValue, itemToString(item)),
    );
  }, [
    allowArbitraryItems,
    inputValue,
    items,
    selectedItems,
    filterFn,
    itemToString,
  ]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    setHighlightedIndex,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    inputValue,
    id: baseId,
    items: filteredItems,
    labelId,
    inputId,
    selectedItem: null,
    stateReducer: (_state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputFocus:
          // There is a weird issue with ChipInput in wizards when there is a
          // ChipInput on a form that is not the initial form/step rendered where the
          // last ChipInput will be automatically focused upon mount. This causes the menu
          // to open by default.  This will prevent the menu from opening by default when those
          // forms/steps are first rendered.
          // NOTE:  Use the Forms's focusOnMount prop to name the field that should receive focus
          // when the form is first rendered.
          // THIS IS A HACK. There seems to be some interplay between
          // downshift/useMultipleSelection's ref, which is used for focus management,
          // and react-hook-form at play I can't figure out.
          // It may be bug with one or the other?
          // New update: reverted the isOpen value to true to fix PCUI-3198
          return {
            ...changes,
            isOpen: items === undefined ? false : true,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        }
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      const inputVal = String(inputValue).trim();

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          if (
            allowArbitraryItems &&
            inputVal &&
            onInputSubmit?.(inputVal) !== false
          ) {
            handleInputValueChange("");
            addSelectedItem({ value: inputVal });
          }
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem && onInputSubmit?.(inputVal) !== false) {
            handleInputValueChange("");
            selectedItem.value = String(selectedItem?.value).trim();
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (filteredItems[highlightedIndex]?.disabled) {
      const nextHighlightedIndex = filteredItems.findIndex(
        (item) => !item.disabled,
      );
      setHighlightedIndex(nextHighlightedIndex);
    }
  }, [highlightedIndex, filteredItems, setHighlightedIndex]);

  const { setReference, setFloating, floatingStyles } = useFloating({
    placement: defaultPlacement,
    strategy: "fixed",
  });

  // TODO fix placeholder style
  // eslint-disable-next-line tailwindcss/no-custom-classname
  const inputClassName = classNames(
    "w-full bg-transparent text-xs placeholder:text-gray-300 focus:outline-none dark:placeholder:text-gray-600",
    disabled && "cursor-not-allowed",
    hasError && "text-red",
  );

  const rootClassName = classNames(
    "flex justify-between rounded border bg-white focus-within:ring dark:border-blue-steel-850 dark:bg-blue-steel-950",
    hasError && "border-red-700 dark:border-red-400",
    disabled &&
      "cursor-not-allowed border-gray-300 text-disabled dark:border-blue-steel-850 dark:text-dark-bg-disabled ",
  );

  return (
    <>
      <div className={rootClassName}>
        <div
          className="flex grow flex-wrap items-center gap-1 px-2"
          style={{
            paddingBottom: "0.1875rem",
            paddingTop: "0.1875rem",
            minHeight: "2rem",
          }}
        >
          {selectedItems.map((selectedItem, index) => {
            const display =
              selectedItem.children ?? selectedItem.value.toString();

            if (disabled) {
              return (
                <Tag
                  key={index}
                  data-testid="selected-item"
                  readOnly={disabled}
                  icon={selectedItem.iconLeft}
                >
                  {display}
                </Tag>
              );
            }

            return (
              <Tag
                data-testid="selected-item"
                key={index}
                appearance={selectedItem.appearance}
                icon={selectedItem.iconLeft}
                closeButtonProps={{
                  ...getSelectedItemProps({
                    selectedItem,
                    index,
                  }),
                  // Note that we are overriding, not extending, the onClick from downshift
                  // The onClick from downshift would apply focus to the Tag
                  // Instead, we remove it (because the click is on a remove button)
                  onClick() {
                    removeSelectedItem(selectedItem);
                  },
                }}
              >
                {display}
              </Tag>
            );
          })}
          <span className="grow" ref={setReference}>
            <input
              className={inputClassName}
              data-autofocus={dataAutofocus}
              placeholder={placeholder}
              {...getInputProps(
                getDropdownProps({
                  disabled,
                  onBlur: onInputBlur,
                  onChange(e: FormEvent<HTMLInputElement>) {
                    handleInputValueChange(e.currentTarget.value);
                  },
                  onFocus() {
                    if (!isOpen) {
                      openMenu();
                    }
                  },
                  preventKeyAction: !!inputValue,
                  value: inputValue,
                }),
              )}
            />
          </span>
        </div>
        <div
          className="whitespace-nowrap px-2"
          style={{
            paddingTop: "0.3875rem",
          }}
        >
          {clearAll && selectedItems.length >= 2 && !disabled && (
            <Body
              appearance="link"
              onClick={() => reset()}
              addClassName="cursor-pointer text-red"
            >
              <FormattedMessage
                defaultMessage="Clear All"
                id="k56MOA"
                description="Clear All link label"
              />
            </Body>
          )}
        </div>
      </div>

      <Portal>
        <div className="z-30" ref={setFloating} style={floatingStyles}>
          <SelectList
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            isLoading={isLoading}
            isOpen={isOpen}
            items={filteredItems}
            menuStyle={menuStyle}
            showSelectAll={false}
            selectAllItem={{ value: "select all" }}
            selectedItems={[]}
            selectedValues={[]}
            allowClear={false}
            clearSelection={noop}
          />
        </div>
      </Portal>
    </>
  );
}
