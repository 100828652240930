import { RadioGroup, useWatch } from "form";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  RadioCard as DisabledRadioCard,
  RadioGroup as DisabledRadioGroup,
  Title,
} from "ui";
import { ACCOUNT } from "../../../../../constants";
import { type CloudType } from "../../../../../types";
import { getAccountTypeItems } from "../../../components/AccountType/items";

type AzureAccountTypeProps = {
  cloudType: CloudType;
  defaultValue?: string;
  isEdit: boolean;
  name: string;
  apiAzureActiveDir?: boolean;
  getAccountTypeValue?: (args: string) => void;
};

export default function AzureAccountType({
  cloudType,
  defaultValue,
  isEdit,
  name,
  apiAzureActiveDir,
  getAccountTypeValue,
}: AzureAccountTypeProps) {
  const intl = useIntl();
  const isDisabled = isEdit && !apiAzureActiveDir;

  const title = intl.formatMessage({
    defaultMessage: "Scope",
    id: "Zt3b2v",
    description: "Description for Scope/AccountType label",
  });

  let value = useWatch({ name });
  value =
    getAccountTypeValue && typeof getAccountTypeValue === "function"
      ? getAccountTypeValue(value)
      : value;

  const items = useMemo(() => {
    const accountTypeItems = getAccountTypeItems(intl, cloudType, isDisabled);
    return apiAzureActiveDir
      ? accountTypeItems.map((item) => {
          if (item.value === ACCOUNT) {
            return { ...item, disabled: true };
          } else {
            return item;
          }
        })
      : accountTypeItems;
  }, [cloudType, isDisabled, apiAzureActiveDir, intl]);

  let component;

  if (isEdit && !apiAzureActiveDir) {
    component = (
      <DisabledRadioGroup
        name={name}
        value={value}
        addClassName="flex space-x-2 space-y-0 [&>label>div>div:last-child>p]:items-start [&>label>div]:h-full [&>label]:relative [&>label]:flex-1"
        style={{ flexDirection: "row" }}
      >
        {items.map(({ description, title, value }) => (
          <DisabledRadioCard
            key={value}
            title={title}
            description={description}
            value={value}
            disabled
          />
        ))}
      </DisabledRadioGroup>
    );
  } else {
    component = (
      <RadioGroup
        addClassName="flex space-x-2 space-y-0 [&>label>div>div:last-child>p]:items-start [&>label>div]:h-full [&>label]:relative [&>label]:flex-1"
        style={{ flexDirection: "row" }}
        disabled={false}
        defaultValue={defaultValue}
        items={items}
        name={name}
        label={title}
        register={{
          required: true,
        }}
        showLabel={false}
      />
    );
  }

  return (
    <section className="space-y-2">
      <Title level={5} size="xxs">
        {title}
      </Title>
      {component}
    </section>
  );
}
