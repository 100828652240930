import { useEffect, useMemo, type ReactNode } from "react";
import { useFormContext, type FieldValues } from "react-hook-form";
import { ModalWizardNavItem, ModalWizardBody as UIModalWizardBody } from "ui";
import { useWizard } from "./WizardContext";
import { useWizardSubmitHandlers } from "./useWizardSubmitHandlers";

export const ModalWizardBody = <T extends FieldValues>({
  children,
  submit,
  hideSideNav = false,
  hideTitle = false,
}: {
  children: ReactNode;
  submit: (formValues: T, goTo: string) => void;
  hideSideNav?: boolean;
  hideTitle?: boolean;
}) => {
  const {
    isWizardDirty,
    setIsWizardDirty,
    currentStepName,
    currentStep,
    steps,
    visitedSteps,
    setVisitedSteps,
  } = useWizard();
  const { appendValuesMoveBackward } = useWizardSubmitHandlers();
  const {
    trigger,
    getValues,
    formState: { errors, isDirty, dirtyFields },
  } = useFormContext();

  const currentFormHasErrors = Object.keys(errors).length;
  useEffect(() => {
    if (currentFormHasErrors) {
      setVisitedSteps((visitedSteps) => {
        visitedSteps[currentStepName] = {
          ...visitedSteps[currentStepName],
          status: "error",
        };
        return visitedSteps;
      });
    }
  }, [currentStepName, currentFormHasErrors, setVisitedSteps]);

  useEffect(() => {
    if (!isDirty || !Object.keys(dirtyFields).length) return;
    if (isWizardDirty) return;
    setIsWizardDirty(true);
  }, [dirtyFields, isDirty, isWizardDirty, setIsWizardDirty]);

  const navItems = useMemo(() => {
    let currentIndex = 0;
    let invalidStepIndex = 1000;
    return steps.map((step, index) => {
      const stepHasBeenVisited = visitedSteps[step.name]?.visited;
      const isCurrentStep = currentStepName === step.name;
      const isPreviousStepValid =
        steps.find((s) => s.name === step.previousStepName)?.status === "valid";

      if (isCurrentStep) {
        currentIndex = index;
      }
      if (step.status === "error") {
        invalidStepIndex = index;
      }

      return (
        <ModalWizardNavItem
          onClick={async () => {
            if (index > currentIndex) {
              const validForm = await trigger();
              if (validForm) {
                submit(getValues() as T, step.name);
              }
            } else {
              appendValuesMoveBackward(getValues(), step.name);
            }
          }}
          isActive={isCurrentStep}
          key={step.name}
          disabled={
            (index > invalidStepIndex && index > currentIndex + 1) ||
            (!stepHasBeenVisited &&
              !isPreviousStepValid &&
              index > currentIndex + 1)
          }
          status={
            isCurrentStep
              ? currentFormHasErrors
                ? "error"
                : step.status
              : step.status
          }
        >
          {step.title}
        </ModalWizardNavItem>
      );
    });
  }, [
    steps,
    visitedSteps,
    currentStepName,
    currentFormHasErrors,
    trigger,
    submit,
    getValues,
    appendValuesMoveBackward,
  ]);

  return (
    <UIModalWizardBody
      activeStepTitle={currentStep.title}
      navItems={navItems}
      hideSideNav={hideSideNav}
      hideTitle={hideTitle}
    >
      {children}
    </UIModalWizardBody>
  );
};
