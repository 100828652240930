import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const Licensing = lazy(() => import("./Licensing"));

export const licensingRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "licensing",
  component: Licensing,
});
