import { type PropsWithChildren, type ReactElement } from "react";

import { classNames } from "utils";

export type ButtonGroupProps = {
  /**
   * Adds a className to the root element.
   */
  addClassName?: string;
  /**
   * Defines a string value that labels an interactive element.
   */
  ariaLabel?: string;
};

/**
 * Groups a series of buttons together on a single line.
 *
 * Buttons wrapped by `ButtonGroup` must have a `buttonGroupPosition` property with values `"left" "middle" "right"` for the button border CSS styling.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ButtonGroup } from "ui";
 * ```
 */
export const ButtonGroup = ({
  addClassName,
  ariaLabel = "Button group",
  children,
}: PropsWithChildren<ButtonGroupProps>): ReactElement => {
  return (
    <div
      role="group"
      aria-label={ariaLabel}
      className={classNames("isolate flex", addClassName)}
    >
      {children}
    </div>
  );
};
