import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

interface CloudSecurityIconProps extends SvgIconProps {
  color: string;
  alt?: string;
}

export const CloudSecurityIcon = ({
  color,
  alt,
  ...passThrough
}: CloudSecurityIconProps) => {
  return (
    <SvgIcon
      width={55}
      height={40}
      viewBox="0 0 55 40"
      fill="none"
      style={{ margin: "-10px -10px -10px 2px" }}
      {...passThrough}
    >
      <title>{alt}</title>
      <g filter="url(#filter0_b_12071_309909)">
        <path
          d="M14.4 18.4C15.2837 19.2837 15.2837 20.7163 14.4 21.6L9.6 26.4C8.71634 27.2837 7.28366 27.2837 6.4 26.4L1.6 21.6C0.716346 20.7163 0.716346 19.2837 1.6 18.4L6.4 13.6C7.28366 12.7163 8.71635 12.7163 9.6 13.6L14.4 18.4Z"
          fill={color}
        />
      </g>
      <g filter="url(#filter1_b_12071_309909)">
        <path
          d="M14.4 18.4C15.2837 19.2837 15.2837 20.7163 14.4 21.6L9.6 26.4C8.71634 27.2837 7.28366 27.2837 6.4 26.4L1.6 21.6C0.716346 20.7163 0.716346 19.2837 1.6 18.4L6.4 13.6C7.28366 12.7163 8.71635 12.7163 9.6 13.6L14.4 18.4Z"
          fill="black"
          fillOpacity="0.25"
        />
      </g>
      <g filter="url(#filter2_b_12071_309909)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8 25.6L13.6 20.8C14.0418 20.3582 14.0418 19.6418 13.6 19.2L8.8 14.4C8.35817 13.9582 7.64183 13.9582 7.2 14.4L2.4 19.2C1.95817 19.6418 1.95817 20.3582 2.4 20.8L7.2 25.6C7.64183 26.0418 8.35817 26.0418 8.8 25.6ZM14.4 21.6C15.2837 20.7163 15.2837 19.2837 14.4 18.4L9.6 13.6C8.71635 12.7163 7.28366 12.7163 6.4 13.6L1.6 18.4C0.716346 19.2837 0.716346 20.7163 1.6 21.6L6.4 26.4C7.28366 27.2837 8.71634 27.2837 9.6 26.4L14.4 21.6Z"
          fill="#181C20"
        />
      </g>
      <g filter="url(#filter3_b_12071_309909)">
        <path
          d="M23.4 18.4C24.2837 19.2837 24.2837 20.7163 23.4 21.6L18.6 26.4C17.7163 27.2837 16.2837 27.2837 15.4 26.4L10.6 21.6C9.71635 20.7163 9.71635 19.2837 10.6 18.4L15.4 13.6C16.2837 12.7163 17.7163 12.7163 18.6 13.6L23.4 18.4Z"
          fill={color}
        />
      </g>
      <g filter="url(#filter4_b_12071_309909)">
        <path
          d="M23.4 18.4C24.2837 19.2837 24.2837 20.7163 23.4 21.6L18.6 26.4C17.7163 27.2837 16.2837 27.2837 15.4 26.4L10.6 21.6C9.71635 20.7163 9.71635 19.2837 10.6 18.4L15.4 13.6C16.2837 12.7163 17.7163 12.7163 18.6 13.6L23.4 18.4Z"
          fill="black"
          fillOpacity="0.15"
        />
      </g>
      <g filter="url(#filter5_b_12071_309909)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8 25.6L22.6 20.8C23.0418 20.3582 23.0418 19.6418 22.6 19.2L17.8 14.4C17.3582 13.9582 16.6418 13.9582 16.2 14.4L11.4 19.2C10.9582 19.6418 10.9582 20.3582 11.4 20.8L16.2 25.6C16.6418 26.0418 17.3582 26.0418 17.8 25.6ZM23.4 21.6C24.2837 20.7163 24.2837 19.2837 23.4 18.4L18.6 13.6C17.7163 12.7163 16.2837 12.7163 15.4 13.6L10.6 18.4C9.71635 19.2837 9.71635 20.7163 10.6 21.6L15.4 26.4C16.2837 27.2837 17.7163 27.2837 18.6 26.4L23.4 21.6Z"
          fill="#181C20"
        />
      </g>
      <g filter="url(#filter6_b_12071_309909)">
        <path
          d="M32.4 18.4C33.2837 19.2837 33.2837 20.7163 32.4 21.6L27.6 26.4C26.7163 27.2837 25.2837 27.2837 24.4 26.4L19.6 21.6C18.7163 20.7163 18.7163 19.2837 19.6 18.4L24.4 13.6C25.2837 12.7163 26.7163 12.7163 27.6 13.6L32.4 18.4Z"
          fill={color}
        />
      </g>
      <g filter="url(#filter7_b_12071_309909)">
        <path
          d="M32.4 18.4C33.2837 19.2837 33.2837 20.7163 32.4 21.6L27.6 26.4C26.7163 27.2837 25.2837 27.2837 24.4 26.4L19.6 21.6C18.7163 20.7163 18.7163 19.2837 19.6 18.4L24.4 13.6C25.2837 12.7163 26.7163 12.7163 27.6 13.6L32.4 18.4Z"
          fill="black"
          fillOpacity="0.05"
        />
      </g>
      <g filter="url(#filter8_b_12071_309909)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8 25.6L31.6 20.8C32.0418 20.3582 32.0418 19.6418 31.6 19.2L26.8 14.4C26.3582 13.9582 25.6418 13.9582 25.2 14.4L20.4 19.2C19.9582 19.6418 19.9582 20.3582 20.4 20.8L25.2 25.6C25.6418 26.0418 26.3582 26.0418 26.8 25.6ZM32.4 21.6C33.2837 20.7163 33.2837 19.2837 32.4 18.4L27.6 13.6C26.7163 12.7163 25.2837 12.7163 24.4 13.6L19.6 18.4C18.7163 19.2837 18.7163 20.7163 19.6 21.6L24.4 26.4C25.2837 27.2837 26.7163 27.2837 27.6 26.4L32.4 21.6Z"
          fill="#181C20"
        />
      </g>
      <g filter="url(#filter9_d_12071_309909)">
        <g filter="url(#filter10_b_12071_309909)">
          <path
            d="M41.4 18.4C42.2837 19.2837 42.2837 20.7163 41.4 21.6L36.6 26.4C35.7163 27.2837 34.2837 27.2837 33.4 26.4L28.6 21.6C27.7163 20.7163 27.7163 19.2837 28.6 18.4L33.4 13.6C34.2837 12.7163 35.7163 12.7163 36.6 13.6L41.4 18.4Z"
            fill={color}
          />
        </g>
        <g filter="url(#filter11_b_12071_309909)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.8 25.6L40.6 20.8C41.0418 20.3582 41.0418 19.6418 40.6 19.2L35.8 14.4C35.3582 13.9582 34.6418 13.9582 34.2 14.4L29.4 19.2C28.9582 19.6418 28.9582 20.3582 29.4 20.8L34.2 25.6C34.6418 26.0418 35.3582 26.0418 35.8 25.6ZM41.4 21.6C42.2837 20.7163 42.2837 19.2837 41.4 18.4L36.6 13.6C35.7163 12.7163 34.2837 12.7163 33.4 13.6L28.6 18.4C27.7163 19.2837 27.7163 20.7163 28.6 21.6L33.4 26.4C34.2837 27.2837 35.7163 27.2837 36.6 26.4L41.4 21.6Z"
            fill="#181C20"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_12071_309909"
          x="-9.06274"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_12071_309909"
          x="-9.06274"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_12071_309909"
          x="-9.06274"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_b_12071_309909"
          x="-0.0627441"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_b_12071_309909"
          x="-0.0627441"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_b_12071_309909"
          x="-0.0627441"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_b_12071_309909"
          x="8.93726"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_b_12071_309909"
          x="8.93726"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_b_12071_309909"
          x="8.93726"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_12071_309909"
          x="15"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.329412 0 0 0 0 0.745098 0 0 0 0 0.894118 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_b_12071_309909"
          x="17.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_b_12071_309909"
          x="17.9373"
          y="2.93726"
          width="34.1255"
          height="34.1255"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12071_309909"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12071_309909"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};
