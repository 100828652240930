import { Map, Set } from "immutable";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  ALL,
  AZURE_DEFAULT_EXPANDED_NODES,
  AZURE_FOLDER_VIEW_CREDENTIALS_ERROR,
  AZURE_LOADED_NODES,
  AZURE_OU_IS_LOADING,
  AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  AZURE_PROJECT_SELECT_MODE,
  AZURE_SELECTED_NODES,
  AZURE_SELECTED_NODES_INITIAL,
  AZURE_SELECT_PROJECTS_INITIALIZED,
  AZURE_TREE_MAP,
  ENABLED,
  ERRORS,
  INIT_AZURE_TREE_MAP,
  IS_EDIT,
  MONITOR_FLOW_LOGS,
  NAME,
  TENANT_ID,
  VALUE,
} from "../../../../../../../constants";

export const azureSelectMonitoredProjectInitialState = {
  [AZURE_PROJECT_SELECT_MODE]: { [VALUE]: ALL, [ERRORS]: [] },
  [AZURE_SELECT_PROJECTS_INITIALIZED]: { [VALUE]: false, [ERRORS]: [] },
  [AZURE_SELECTED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [AZURE_TREE_MAP]: { [VALUE]: Map(), [ERRORS]: [] },
  [TENANT_ID]: { [VALUE]: "", [ERRORS]: [] },
  [NAME]: { [VALUE]: "", [ERRORS]: [] },
  [ACCOUNT_ID]: { [VALUE]: "", [ERRORS]: [] },
  [AZURE_SELECTED_NODES_INITIAL]: { [VALUE]: [], [ERRORS]: [] },
  [ENABLED]: { [VALUE]: true, [ERRORS]: [] },
  [ACCOUNT_TYPE]: { [VALUE]: "", [ERRORS]: [] },
  [INIT_AZURE_TREE_MAP]: { [VALUE]: Set(), [ERRORS]: [] },
  [AZURE_DEFAULT_EXPANDED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [AZURE_LOADED_NODES]: { [VALUE]: Set(), [ERRORS]: [] },
  [AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND]: { [VALUE]: [], [ERRORS]: [] },
  [AZURE_FOLDER_VIEW_CREDENTIALS_ERROR]: { [VALUE]: "", [ERRORS]: [] },
  [IS_EDIT]: { [VALUE]: false },
  [AZURE_OU_IS_LOADING]: { [VALUE]: false },
  [MONITOR_FLOW_LOGS]: { [VALUE]: false },
};

type StateKeys = keyof typeof azureSelectMonitoredProjectInitialState;
export type AzureSelectMonitoredProjectInitialStateType = Map<
  StateKeys,
  { value: unknown; errors: unknown }
>;
