import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { accessControlRoute } from "../../accessControlRoute";

const Users = lazy(() =>
  import("./Users").then((res) => ({
    default: res.Users,
  })),
);

export const usersRoute = new Route({
  getParentRoute: () => accessControlRoute,
  path: "users",
  component: Users,
});
