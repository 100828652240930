import { CopyIcon, type IconSizes } from "icons";
import { forwardRef, useEffect, useRef, useState } from "react";
import { defineMessage, useIntl } from "react-intl";
import { classNames } from "utils";
import { copy } from "../../utils";
import { Tooltip } from "../Tooltip";
import { Button, type ButtonProps } from "./Button";

type Timeout = ReturnType<typeof setTimeout>;

export interface CopyButtonProps extends ButtonProps {
  iconSize?: IconSizes;
  text: string | (() => string);
}

const copyMessage = defineMessage({
  defaultMessage: "Copy to clipboard",
  id: "2YgpgP",
  description: "Copy the associated text to the clipboard",
});

const copiedMessage = defineMessage({
  defaultMessage: "Copied!",
  id: "XfyhUA",
  description: "Indicates the associated text has been copied to the clipboard",
});

/**
 * A button that copies the provided `text` to the clipboard when clicked`.
 */
export const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
  (
    {
      addClassName,
      appearance = "tertiary-clear",
      iconSize = "sm",
      onClick,
      size = "sm",
      text,
      ...passThrough
    }: CopyButtonProps,
    ref,
  ) => {
    const [hasCopied, setHasCopied] = useState(false);
    const timeout = useRef<Timeout>();

    const intl = useIntl();
    const message = hasCopied ? copiedMessage : copyMessage;
    const label = intl.formatMessage(message);

    useEffect(() => {
      if (hasCopied) {
        timeout.current = setTimeout(() => {
          setHasCopied(false);
        }, 2000);
      }
      return () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      };
    }, [hasCopied]);

    return (
      <Tooltip isVisible={hasCopied || undefined} label={label}>
        <Button
          addClassName={classNames("items-center", addClassName)}
          aria-label={label}
          appearance={appearance}
          icon={<CopyIcon size={iconSize} />}
          onMouseDown={() => {
            // Set copied on the initial mouse down to avoid the tooltip from unmounting and mounting again
            setHasCopied(true);
          }}
          onClick={(e) => {
            // Set copied again, in case the button was "clicked" with the keyboard
            setHasCopied(true);
            onClick?.(e);
            const copyText = typeof text === "string" ? text : text();
            copy(copyText);
          }}
          ref={ref}
          size={size}
          {...passThrough}
        />
      </Tooltip>
    );
  },
);

CopyButton.displayName = "CopyButton";
