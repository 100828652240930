import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { type SelectItem } from "ui";

/**
 * This hook will filter out any selected values that are not present in the items that are passed in.
 *
 * For example:  If you select a gcp cloud region, and then change the cloud type select to  "aws", the new items
 *               will be filtered to only be aws regions and the gcp region previously selected is no longer valid.
 *               This hook will remove the gpc region from the select's selected value.
 *
 * @param fieldName: The name of the select to reset the value of
 * @param isLoading: This hook will not do anything if isLoading is true
 * @param items: The list of items for the select
 * @param itemFilters: This is an array or array of arrays of filters that have been applied to the items.
 *                     If empty, this hook will not do anything.
 *                     ex. ["aws", "gcp"] or [["aws", "gcp"], ["123", "456"]]
 *
 */
export const useResetSelectItems = ({
  fieldName,
  isLoading,
  items,
  itemFilters,
}: {
  fieldName: string;
  isLoading: boolean;
  items: SelectItem[];
  itemFilters?: string[] | string[][];
}) => {
  const { setValue, getValues } = useFormContext();
  const selectedItems = getValues(fieldName);
  const flatItemFilters = useMemo(() => itemFilters?.flat(), [itemFilters]);
  const flatItemFiltersString = useMemo(
    () => flatItemFilters?.toString(),
    [flatItemFilters],
  );
  const selectedItemsString = useMemo(
    () => selectedItems?.toString(),
    [selectedItems],
  );
  const itemsString = useMemo(() => items.toString(), [items]);

  useEffect(() => {
    if (isLoading) return;
    if (
      !selectedItems ||
      (Array.isArray(selectedItems) && !selectedItems?.length)
    )
      return;
    if (!flatItemFilters?.length) return;
    if (!items?.length) {
      setValue(fieldName, undefined);
      return;
    }
    // Using a set to achieve O(1) lookup with .has
    const idsFromItems = new Set(items?.map((item) => item.key));

    let filteredSelectedItems;
    if (typeof selectedItems === "string") {
      filteredSelectedItems = idsFromItems?.has(selectedItems)
        ? selectedItems
        : undefined;
    } else if (
      typeof selectedItems === "object" &&
      !Array.isArray(selectedItems)
    ) {
      filteredSelectedItems = idsFromItems?.has(
        (selectedItems as Record<"id", string>)?.id,
      )
        ? selectedItems
        : undefined;
    } else if (typeof selectedItems?.[0] === "string") {
      filteredSelectedItems = (selectedItems as string[])?.filter(
        (id: string) => idsFromItems?.has(id),
      );
    } else {
      filteredSelectedItems = (selectedItems as Record<"id", string>[])?.filter(
        (item: { id: string }) => idsFromItems?.has(item?.id),
      );
    }

    if (selectedItemsString === filteredSelectedItems?.toString()) return;

    setValue(fieldName, filteredSelectedItems);
    // Since items, ItemFilters, and selectedItems are arrays,
    // need to add stringified version for the array to be compared correctly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    isLoading,
    selectedItemsString,
    flatItemFiltersString,
    itemsString,
  ]);
};
