// TODO: add memoize
export const convertIdsToNames = (
  objectsWithNameAndId: {
    name: string;
    id?: string;
    apiIdentifier?: string;
  }[],
) => {
  const lookup = objectsWithNameAndId.reduce<
    Record<string, (typeof objectsWithNameAndId)[0]>
  >((acc, object) => {
    acc[object.id || (object.apiIdentifier as string)] = object;
    return acc;
  }, {});

  return (ids?: string[]) => {
    return ids?.map((id) => {
      const matchedObject = lookup[id];
      return matchedObject?.name ?? id;
    });
  };
};
export const convertIdsToNamesAndIds = (
  objectsWithNameAndId: {
    name: string;
    id?: string;
    apiIdentifier?: string;
  }[],
) => {
  const lookup = objectsWithNameAndId.reduce<
    Record<string, (typeof objectsWithNameAndId)[0]>
  >((acc, object) => {
    acc[object.id || (object.apiIdentifier as string)] = object;
    return acc;
  }, {});

  return (ids?: string[]) => {
    return ids?.map((id) => {
      const matchedObject = lookup[id];
      return {
        name: matchedObject?.name ?? id,
        id,
      };
    });
  };
};
