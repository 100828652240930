import { z } from "zod";

export type AccountGroupType = z.infer<typeof AccountGroupNameSchema>;

export const AccountGroupNameSchema = z.object({
  name: z.string(),
  id: z.string(),
  parentGroupId: z.string().optional(),
});

export const AccountGroupNameResponseSchema = z.array(AccountGroupNameSchema);
