import { SecurityCapabilitiesIcon } from "icons";
import {
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  DATA_SECURITY,
  IDENTITY_SECURITY,
  MISCONFIGURATIONS,
  REMEDIATION,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
} from "../../../../constants";

import { type FeatureItem } from "../FeatureGroup/FeatureGroup";

export function getSecurityCapabilitiesItems(): FeatureItem[] {
  const items: FeatureItem[] = [
    {
      id: "misconfigurations",
      icon: <SecurityCapabilitiesIcon type={MISCONFIGURATIONS} />,
      value: MISCONFIGURATIONS,
      tag: "default",
    },
    {
      id: "identitySecurity",
      icon: <SecurityCapabilitiesIcon type={IDENTITY_SECURITY} />,
      value: IDENTITY_SECURITY,
    },
    {
      id: "agentlessScan",
      icon: <SecurityCapabilitiesIcon type={AGENTLESS_SCAN} size="lg" />,
      value: AGENTLESS_SCAN,
      showToggle: true,
      orgScanToggleValue: AGENTLESS_DEFAULT_MEMBER_STATE,
    },
    {
      id: "serverlessScan",
      icon: <SecurityCapabilitiesIcon type={SERVERLESS_SCAN} size="lg" />,
      value: SERVERLESS_SCAN,
      showToggle: true,
      orgScanToggleValue: SERVERLESS_DEFAULT_MEMBER_STATE,
    },
    {
      id: "agentlessApiDiscovery",
      icon: (
        <SecurityCapabilitiesIcon type={AGENTLESS_API_DISCOVERY} size="lg" />
      ),
      value: AGENTLESS_API_DISCOVERY,
      orgScanToggleValue: AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
      showToggle: true,
    },
    {
      id: "autoProtect",
      icon: <SecurityCapabilitiesIcon type={AUTO_PROTECT} size="lg" />,
      value: AUTO_PROTECT,
    },
    {
      id: "remediation",
      icon: <SecurityCapabilitiesIcon type={REMEDIATION} size="lg" />,
      value: REMEDIATION,
    },
    {
      id: "dataSecurity",
      icon: <SecurityCapabilitiesIcon type={DATA_SECURITY} />,
      value: DATA_SECURITY,
      showToggle: true,
    },
  ];

  return items;
}
