import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { privateRootRoute } from "../../privateRootRoute";

const ReportsLayout = lazy(() =>
  import("./ReportsLayout").then((res) => ({
    default: res.ReportsLayout,
  })),
);

export const reportsLayoutRoute = new Route({
  getParentRoute: () => privateRootRoute,
  path: "reports",
  component: ReportsLayout,
});
