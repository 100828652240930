import { useNavigate, type LinkOptions } from "@tanstack/react-router";
import { Form, SubmitButton } from "form";
import { useCallback, useMemo, useState, type ReactElement } from "react";
import { getEntitlements, tenantKeys, useToken } from "requests";
import {
  Button,
  LoadingIconOnFilterBackground,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
  type SelectItem,
} from "ui";

import { useQuery } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "remeda";
import { useASMProvisionStatus } from "../../../../../../pages//dashboard/charts/asm/hooks/useASMProvisionStatus";
import { ASMConfirmationModalContent } from "./ASMConfirmationModalContent";
import { ASMSubscribeBuyModalContent } from "./ASMSubscribeBuyModalContent";
import { ASMSubscribeTrailModalContent } from "./ASMSubscribeTrailModalContent";
import { ASMUnsubscribeModalContent } from "./ASMUnsubscribeModalContent";
import DUNSandDomainsInput from "./DUNSandDomainsInput";
import { useASMEnable } from "./useASMEnable";

export type AdditionalDataType = {
  dunsNumber?: string;
  domains?: SelectItem[];
};

export default function ASMSubscriptionModal({
  closeModal,
  isOpen = false,
}: RenderModalProps): ReactElement {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const { isUpdatingAsmFeature, updateAsmFeature } = useASMEnable({
    onSuccess: () => setShowConfirmation(true),
  });

  const {
    isLoading,
    isFeatureProvisioned,
    isTrail,
    isFeatureExists,
    isFeatureProvisionedSuccessful,
  } = useASMProvisionStatus();

  const { prismaId } = useToken();
  const { data, isLoading: isEntitlementLoading } = useQuery({
    queryKey: tenantKeys.entitlements({
      prismaId,
    }),
    queryFn: getEntitlements,
    refetchOnWindowFocus: false,
  });

  const defaultAdditionalData = useMemo(() => {
    if (data) {
      return {
        ...(data?.dunsNumber && { dunsNumber: data?.dunsNumber }),
        ...(data?.seedDomains && {
          domains: data?.seedDomains.map((domain) => {
            return { value: domain };
          }),
        }),
      } as AdditionalDataType;
    } else {
      return undefined;
    }
  }, [data]);

  const [isActivateRadioButtonSelected, setActivateRadioButtonSelected] =
    useState(false);

  const ASMSubscriptionModalBody = () => {
    if (isLoading) {
      return <LoadingIcon />;
    }

    if (showConfirmation) {
      return (
        <ASMConfirmationModalContent isSubscribed={isFeatureProvisioned} />
      );
    }

    return (
      <>
        {isUpdatingAsmFeature && <LoadingIconOnFilterBackground />}
        {isFeatureProvisioned ? (
          <ASMUnsubscribeModalContent />
        ) : isTrail ? (
          <ASMSubscribeTrailModalContent
            isActivating={isUpdatingAsmFeature}
            isActivateRadioButtonSelected={isActivateRadioButtonSelected}
            setActivateRadioButtonSelected={setActivateRadioButtonSelected}
          />
        ) : (
          <ASMSubscribeBuyModalContent />
        )}
      </>
    );
  };

  const handleOnClose = () => {
    closeModal();
    isFeatureProvisioned &&
      navigate({
        to: "/dashboards/cdem",
      } as unknown as LinkOptions);
  };

  const handleOnSubmit = useCallback(
    (values: { dunsNumber: string; domains: SelectItem[] }) => {
      const domainsData = values?.domains.map((item) => item.value) as string[];
      const payload = {
        ...(values?.dunsNumber ? { dunsNumber: values?.dunsNumber } : {}),
        ...(domainsData.length ? { domains: domainsData } : {}),
      };

      updateAsmFeature({
        enabled: true,
        operation:
          isTrail && (!isFeatureProvisionedSuccessful || !isFeatureExists)
            ? "ADD"
            : "UPDATE",
        ...(!isEmpty(payload) && { additionalData: payload }),
      });
    },
    [
      isFeatureExists,
      isFeatureProvisionedSuccessful,
      isTrail,
      updateAsmFeature,
    ],
  );

  const ASMSubscriptionModalFooter = () => {
    return (
      <>
        <Button
          onClick={handleOnClose}
          appearance={showConfirmation ? "primary" : "secondary"}
          focusOnMount={showConfirmation}
        >
          {showConfirmation ? (
            <FormattedMessage
              defaultMessage="Close"
              id="Px7S/2"
              description="Close button"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Dismiss"
              id="heUZX9"
              description="Dismiss button"
            />
          )}
        </Button>
        {!showConfirmation && isFeatureProvisioned && (
          <Button
            onClick={() =>
              updateAsmFeature({
                enabled: false,
                operation: "UPDATE",
              })
            }
            appearance="primary"
            disabled={isUpdatingAsmFeature}
            isLoading={isUpdatingAsmFeature}
          >
            <FormattedMessage
              defaultMessage="Confirm"
              id="rce0Y4"
              description="confirm unsubscribe from CDEM module"
            />
          </Button>
        )}
        {!showConfirmation && !isFeatureProvisioned && (
          <SubmitButton
            disabled={
              (isTrail && !isActivateRadioButtonSelected) ||
              isUpdatingAsmFeature ||
              isEntitlementLoading
            }
          >
            {isTrail ? (
              <FormattedMessage
                defaultMessage="Agree and Submit"
                id="GWeTrC"
                description="Enable ASM Security button"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Confirm"
                id="SVf/iD"
                description="Enable ASM Security button"
              />
            )}
          </SubmitButton>
        )}
      </>
    );
  };

  return (
    <Modal onClose={closeModal} isOpen={isOpen} size="md">
      <ModalHeader enableClose title="" />
      <Form onSubmit={handleOnSubmit}>
        <ModalBody addClassName="min-h-[500px] p-6">
          <ASMSubscriptionModalBody />
          {!isFeatureProvisioned && !showConfirmation && (
            <DUNSandDomainsInput
              isLoading={isEntitlementLoading}
              additionalData={defaultAdditionalData}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ASMSubscriptionModalFooter />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
