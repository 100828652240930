import { useMemo, type FC } from "react";
import { FormattedMessage } from "react-intl";
import { Select } from "ui";
import { v4 as uuidV4 } from "uuid";

export interface SelectPageSizeProps {
  onChangePageSize: (pageSize: number) => void;
  pageSize: number;
  pageSizeOptions: number[];
}

export const SelectPageSize: FC<SelectPageSizeProps> = ({
  onChangePageSize,
  pageSize,
  pageSizeOptions,
}: SelectPageSizeProps) => {
  const labelId = useMemo((): string => `table-rows-${uuidV4()}-label`, []);
  const memoizedPageSizeOptions = useMemo(
    () => pageSizeOptions.map((i) => ({ value: i })),
    [pageSizeOptions],
  );
  const selectedPageSize = useMemo(
    () => memoizedPageSizeOptions.find((i) => i.value === pageSize) || null,
    [memoizedPageSizeOptions, pageSize],
  );

  return (
    <>
      <label id={labelId} className="flex space-x-4 text-sm leading-loose">
        <FormattedMessage
          defaultMessage="Rows"
          id="C6PEBC"
          description="Label for a table page size selector"
        />
      </label>
      <Select
        labelId={labelId}
        data-testid="select-page-size"
        defaultPlacement="top-start"
        selectedItem={selectedPageSize}
        items={memoizedPageSizeOptions}
        onChange={({ selectedItem }) => {
          const value = selectedItem?.value as number;
          onChangePageSize(value);
        }}
      />
    </>
  );
};
