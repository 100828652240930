import {
  NextButton,
  PrevButton,
  WizardForm,
} from "../../../../../components/Wizard";

import { FormLayout } from "form";
import { ProductDocumentationLink } from "../../components";
import AccountDetails from "./AccountDetails";

export default function ConfigureAccount() {
  return (
    <WizardForm
      actions={
        <>
          <PrevButton />
          <NextButton />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <AccountDetails />
      </FormLayout>
    </WizardForm>
  );
}
