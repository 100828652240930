import { type PropGetters } from "downshift";
import { type FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { classNames } from "utils";
import { type SelectItem } from ".";
import { ListItem } from "../List";
import { Body, Bold } from "../Typography";

export type SelectAllListItemProps = {
  highlightedIndex: number;
  allSelected: boolean;
  selectAllItem: SelectItem;
  selectedItems: SelectItem[];
  isSelectAllDisabled?: boolean;
  isLoading?: boolean;
  onClick: (action: string) => void;
  getItemProps: PropGetters<SelectItem>["getItemProps"];
};

export const SelectAllListItem: FC<SelectAllListItemProps> = (
  props: SelectAllListItemProps,
) => {
  const intl = useIntl();
  const {
    highlightedIndex,
    selectAllItem,
    allSelected,
    isSelectAllDisabled,
    isLoading,
    getItemProps,
    onClick,
  } = props;

  const disableSelectAll = isLoading || isSelectAllDisabled || allSelected;
  const disableDeselectAll =
    isLoading || (!allSelected && !selectAllItem.indeterminate);

  const selectAll = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    onClick("selectAll");
  };

  const deselectAll = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onClick("deselectAll");
  };

  return (
    <ListItem
      active={highlightedIndex === 0}
      hoverClassName=""
      {...selectAllItem}
      {...getItemProps({
        disabled: selectAllItem.disabled,
        item: selectAllItem,
        index: 0,
      })}
      onClick={(event) => event.stopPropagation()}
    >
      <span className="flex justify-between">
        <Bold size="sm">
          {typeof selectAllItem.value === "string"
            ? selectAllItem.value
            : intl.formatMessage({
                defaultMessage: "Select All",
                id: "wekR9y",

                description: "select all text",
              })}
        </Bold>
        <span className="flex">
          <Body
            onClick={selectAll}
            appearance={disableSelectAll ? "disabled" : "link"}
            as="button"
            size="sm"
            disabled={disableSelectAll}
            addClassName={classNames(
              "pr-3",
              disableSelectAll ? "cursor-not-allowed" : "cursor-pointer",
            )}
            aria-label={intl.formatMessage({
              defaultMessage: "Select All",
              id: "dZ8HrB",

              description: "Label for Select All link",
            })}
          >
            <Bold>
              <FormattedMessage
                defaultMessage="Select All"
                id="dZ8HrB"
                description="Label for Select All link"
              />
            </Bold>
          </Body>
          <Body
            onClick={deselectAll}
            as="button"
            size="sm"
            disabled={disableDeselectAll}
            appearance={disableDeselectAll ? "disabled" : "link"}
            addClassName={classNames(
              disableDeselectAll ? "cursor-not-allowed" : "cursor-pointer",
            )}
            aria-label={intl.formatMessage({
              defaultMessage: "Deselect All",
              id: "hCch5+",

              description: "Label for Deselect All link",
            })}
          >
            <Bold>
              <FormattedMessage
                defaultMessage="Deselect All"
                id="hCch5+"
                description="Label for Deselect All link"
              />
            </Bold>
          </Body>
        </span>
      </span>
    </ListItem>
  );
};
