import { fromJS } from "immutable";
import { useReducer, type ReactNode } from "react";

import { createCtx } from "ui";

import {
  gcpCredentialsFileInitialState,
  type GcpCredentialsFileStateType,
} from "./state/initialState";
import { gcpCredentialsFileReducer } from "./state/reducer";

type UseGCPCredentialsFileContextType = {
  state: GcpCredentialsFileStateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
};

const [useGCPCredentialsFileContext, CtxProvider] =
  createCtx<UseGCPCredentialsFileContextType>();

export { useGCPCredentialsFileContext };

export type GCPCredentialsFileContextProps = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any;
};

export function GCPCredentialsFileContextProvider({
  children,
  initialState,
}: GCPCredentialsFileContextProps) {
  const [state, dispatch] = useReducer(
    gcpCredentialsFileReducer,
    fromJS({
      ...gcpCredentialsFileInitialState,
    }).merge(initialState),
  );

  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>;
}
/* c8 ignore stop */
