import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { homeCloudLayoutRoute } from "./homeCloudLayoutRoute";

export const Welcome = lazy(() => import("./Welcome"));

export const welcomeRoute = new Route({
  getParentRoute: () => homeCloudLayoutRoute,
  path: "/welcome",
  component: Welcome,
  validateSearch: z.object({
    modalName: z.string().optional(),
    skipAnimation: z.optional(z.boolean()),
  }),
});
