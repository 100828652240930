/* c8 ignore start */
import { type Set } from "immutable";
import { type IntlShape } from "react-intl";
import { type GcpSelectMonitoredProjectsAncestorsType } from "requests";
import { dynamicFormatMessage } from "ui";
import {
  ALL,
  CLEAR_FTU_FIELD_ERRORS,
  GCP_ACCOUNT_GROUP_CREATION_MODE,
  GCP_DEFAULT_EXPANDED_NODES,
  GCP_FOLDER_VIEW_CREDENTIALS_ERROR,
  GCP_LOADED_NODES,
  GCP_OU_IS_LOADING,
  GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  GCP_PROJECT_SELECT_MODE,
  GCP_SELECTED_NODES,
  GCP_SELECTED_NODES_INITIAL,
  GCP_SELECT_PROJECTS_INITIALIZED,
  GCP_TREE_MAP,
  INIT_GCP_TREE_MAP,
  IS_RATE_LIMIT_HIT,
  NAME,
  ORG_ID,
  ORG_NAME,
  SET_FTU_FIELD_ERRORS,
  SET_FTU_FIELD_VALUE,
  UPDATE_GCP_NODES,
} from "../../../../../../../constants";
import { memberAccountValidationErrorMessages } from "../../../../../../../messages/memberAccountValidationErrorMessages";
import {
  getGCPProjectSelectMode,
  getGCPRootId,
  getGCPSelectedNodes,
} from "../getters";
import { type GcpSelectMonitoredProjectsInitialStateType } from "../initialState";

export const setFTUFieldErrors = (payload: {
  field: string;
  errors: string[];
}) => ({
  payload,
  type: SET_FTU_FIELD_ERRORS,
});

export const clearFTUFieldErrors = (payload: { field: string }) => ({
  payload,
  type: CLEAR_FTU_FIELD_ERRORS,
});

export const setFTUFieldValue = (payload: {
  field: string;
  value: unknown;
}) => ({
  payload,
  type: SET_FTU_FIELD_VALUE,
});

export const initGCPTreeMap = (payload: Record<string, unknown>) => ({
  payload,
  type: INIT_GCP_TREE_MAP,
});

export const updateGCPTreeData = (payload: Record<string, unknown>) => ({
  payload,
  type: UPDATE_GCP_NODES,
});

export const setGCPProjectSelectMode = (
  value: string,
  dispatch: React.Dispatch<unknown>,
) => {
  dispatch(setFTUFieldValue({ field: GCP_PROJECT_SELECT_MODE, value }));
  dispatch(clearFTUFieldErrors({ field: GCP_SELECTED_NODES }));
};

export const setGCPSelectedNodes = (
  value: Set<string>,
  dispatch: React.Dispatch<unknown>,
) => {
  dispatch(setFTUFieldValue({ field: GCP_SELECTED_NODES, value }));
  dispatch(clearFTUFieldErrors({ field: GCP_SELECTED_NODES }));
};

export const setGCPAccountGroupCreationMode = (value: string) =>
  setFTUFieldValue({ field: GCP_ACCOUNT_GROUP_CREATION_MODE, value });

export const setGCPFoldersCredentialError = (
  value:
    | {
        field: string;
        value: unknown;
      }
    | string,
) => setFTUFieldValue({ field: GCP_FOLDER_VIEW_CREDENTIALS_ERROR, value });

export const setGCPPreviouslySelectedNodesNotFound = (
  value: GcpSelectMonitoredProjectsAncestorsType,
) =>
  setFTUFieldValue({ field: GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND, value });

export const setGCPSelectProjectsInitialized = (value: boolean) =>
  setFTUFieldValue({ field: GCP_SELECT_PROJECTS_INITIALIZED, value });

export const setGCPSelectedNodesInitial = (value: {
  field: string;
  value: unknown;
}) => setFTUFieldValue({ field: GCP_SELECTED_NODES_INITIAL, value });

export const setGCPOUSelectProjectsIsLoading = (value: boolean) =>
  setFTUFieldValue({ field: GCP_OU_IS_LOADING, value });

export const setOrgId = (value: string) =>
  setFTUFieldValue({ field: ORG_ID, value });

export const setAccountName = (value: string) =>
  setFTUFieldValue({ field: NAME, value });

export const setOrganizationName = (value: string) =>
  setFTUFieldValue({ field: ORG_NAME, value });

export const setGCPLoadedNodes = (payload: string) => ({
  payload,
  type: GCP_LOADED_NODES,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setGCPTreeMap = (payload: any) => ({
  payload,
  type: GCP_TREE_MAP,
});

export const setIsRateLimit503 = (value: boolean) =>
  setFTUFieldValue({
    field: IS_RATE_LIMIT_HIT,
    value,
  });

export const setGCPExpandedNodes = (value: Set<string>) =>
  setFTUFieldValue({ field: GCP_DEFAULT_EXPANDED_NODES, value });

export const handleSubmitGCPOrgUnitSelection = (
  intl: IntlShape,
  state: GcpSelectMonitoredProjectsInitialStateType,
  dispatch: React.Dispatch<unknown>,
) => {
  const mode = getGCPProjectSelectMode(state);
  const selectedNodes = getGCPSelectedNodes(state);
  const rootId = getGCPRootId(state);

  const noItemSelected = selectedNodes.size === 0;
  const allItemsSelected = selectedNodes.has(rootId);

  let errors = [];
  if (mode !== ALL && (noItemSelected || allItemsSelected)) {
    const type = noItemSelected ? "none" : "all";
    errors = [
      dynamicFormatMessage(
        intl,
        memberAccountValidationErrorMessages,
        `gcp_${type}_${mode.toLowerCase()}`,
      ),
    ];
    dispatch(setFTUFieldErrors({ errors, field: GCP_SELECTED_NODES }));
  } else {
    dispatch(clearFTUFieldErrors({ field: GCP_SELECTED_NODES }));
  }
  return errors.length === 0;
};
/* c8 ignore stop */
