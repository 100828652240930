import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  cloudAccountsKeys,
  getIbmCloudAccountStatus,
  saveIbmCloudAccount,
  updateIbmCloudAccount,
  type IbmRequestPayloadSchema,
  type IbmStatusResponseSchema,
  type OnboardingStatusResponseSchema,
} from "requests";
import { useToastActions } from "stores";
import { useModal } from "ui";
import {
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import {
  CONFIGURE_ACCOUNT,
  GET_STARTED,
  IBM,
  MISCONFIGURATIONS,
} from "../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIsRenderedFirstTime,
} from "../../../../hooks";
import {
  type IbmConfigureAccountStepValuesType,
  type IbmGetStartedStepValuesType,
  type UnKnownError,
} from "../../../../types";
import {
  getAuthenticationData,
  getWizardStepIndex,
  handleSuccess,
} from "../../utils";

import { FormLayout } from "form";
import { useCloudAccountPendoGuide } from "../../../../../../../utils/useCloudAccountPendoGuide";
import { handleError } from "../../../../utils";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import SaveAndCloseButton from "../../components/SaveAndCloseButton";
import SaveAndOnboardButton from "../../components/SaveAndOnboardButton";
import AccountDetailsStatus from "./AccountDetailsStatus";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";

type ReviewStatusProps = {
  context: {
    closeModal: () => void;
    enabled: boolean;
    isEdit: boolean;
  };
};

export default function ReviewStatus({
  context: { closeModal, enabled, isEdit },
}: ReviewStatusProps) {
  const intl = useIntl();
  const { showGuide } = useCloudAccountPendoGuide();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { openModal } = useModal(CloudSelection);
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedForFirstTime = useIsRenderedFirstTime();
  const getStepIndex = getWizardStepIndex(steps);
  const getStartedStepIndex = getStepIndex(GET_STARTED);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);

  const { accountType } = (steps[getStartedStepIndex]?.values ??
    {}) as IbmGetStartedStepValuesType;

  const {
    accountId = "",
    name = "",
    groupIds = [],
    serviceApiKey = "",
    serviceIamId = "",
  } = (steps[configureAccountIndex]?.values ??
    {}) as IbmConfigureAccountStepValuesType;

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: IBM,
      payload: {
        accountType,
      },
    });

  const payload: IbmRequestPayloadSchema = useMemo(
    () => ({
      accountId,
      accountType,
      groupIds,
      enabled,
      name,
      apiKey: isEdit && serviceApiKey === "********" ? "" : serviceApiKey,
      svcIdIamId: serviceIamId,
    }),
    [
      accountId,
      accountType,
      groupIds,
      enabled,
      name,
      isEdit,
      serviceApiKey,
      serviceIamId,
    ],
  );

  const {
    data: accountStatus = {} as IbmStatusResponseSchema,
    isLoading: isCloudStatusLoading,
    isError,
    error,
  } = useQuery({
    queryKey: cloudAccountsKeys.ibmCloudAccountStatus({ accountId, payload }),
    queryFn: getIbmCloudAccountStatus,
    enabled: !isRenderedForFirstTime && !isFeaturesLoading,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isError) return;

    handleError({
      intl,
      toast,
      error: (error as unknown as UnKnownError)?.body?.error,
    });
  }, [isError, error, intl, toast]);

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({ mutationFn: () => saveIbmCloudAccount({ payload }) });

  const { mutateAsync: updateConfig, isPending: isAccountUpdating } =
    useMutation({
      mutationFn: () => updateIbmCloudAccount({ accountId, payload }),
    });

  const onSubmit = useCallback(
    async ({ openNewOnboarding = false }) => {
      try {
        if (isEdit) {
          await updateConfig();
        } else {
          await createConfig();
        }

        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });

        if (isEdit) {
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.cloudAccountsCas({
              accountId,
              cloudType: IBM,
              includeGroupInfo: true,
            }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.ibmCloudAccountsCas({ accountId }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.permissionsStatus({ accountId }),
          });
        }
        closeModal();
        openNewOnboarding && openModal();
        if (!isEdit) showGuide();
        toast(handleSuccess(isEdit, intl), { appearance: "success" });
      } catch (error) {
        const _error = error as UnKnownError;
        handleError({
          intl,
          toast,
          closeModal,
          error: _error?.body?.error,
        });
      }
    },
    [
      isEdit,
      queryClient,
      accountId,
      closeModal,
      openModal,
      showGuide,
      toast,
      updateConfig,
      createConfig,
      intl,
    ],
  );

  const accountDetailsData = {
    accountGroups: groupIds,
    accountId,
    accountName: name,
    apiKey: serviceApiKey,
    svcIdIamId: serviceIamId,
    accountType,
    securityCapabilities: supportedFeaturesList.filter((feature) =>
      [MISCONFIGURATIONS].includes(feature),
    ),
    authentication: getAuthenticationData(
      accountStatus?.status as OnboardingStatusResponseSchema,
    ),
  };

  const isLoading = isCloudStatusLoading || isFeaturesLoading;
  const isSubmitting = isAccountCreating || isAccountUpdating;
  const disableBtn = isLoading || isSubmitting;

  return (
    <WizardForm
      actions={
        <>
          <PrevButton disabled={disableBtn} />
          {!isEdit && (
            <SaveAndOnboardButton disabled={disableBtn} onSubmit={onSubmit} />
          )}
          <SaveAndCloseButton disabled={disableBtn} onSubmit={onSubmit} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <section className="space-y-4">
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Review account details, security capabilities and permissions."
            id="ocHZwf"
            description="description for review status title"
          />
        </span>
        <FormLayout>
          <AccountDetailsStatus {...accountDetailsData} isLoading={isLoading} />
          <SecurityCapabilitiesPermissions
            accountStatus={accountStatus?.status}
            isLoading={isLoading}
          />
        </FormLayout>
      </section>
    </WizardForm>
  );
}
