import { CaretLeftIcon, CaretRightIcon } from "icons";
import { type FC } from "react";
import { Button, Tooltip } from "ui";

import { FormattedMessage } from "react-intl";
import { SelectPage } from "./SelectPage";
import { SelectPageSize } from "./SelectPageSize";

type PageSelectionProps =
  | {
      /**
       * An array of pages the user can navigate to.
       */
      pageOptions: number[];
      /**
       * A callback invoked with a `pageIndex` when the user selects a specific page.
       */
      onSelectPage: (pageIndex: number) => void;
    }
  | {
      pageOptions?: undefined;
      onSelectPage?: undefined;
    };

export const defaultPageSizeOptions = [10, 25, 50, 100];

export type PaginatorProps = {
  /**
   * The user can navigate to the previous page.
   */
  canPreviousPage: boolean;
  /**
   * The user can navigate to the next page.
   */
  canNextPage: boolean;
  /**
   * The total number of pages.
   */
  pageCount: number;
  /**
   * The zero-based index of the current page.
   */
  pageIndex: number;
  /**
   * The number of rows displayed per page.
   */
  pageSize: number;
  /**
   * The page size options.
   */
  pageSizeOptions?: number[];
  /**
   * A callback invoked when the user clicks the next page button.
   */
  onNextPage: () => void;
  /**
   * A callback invoked when the user clicks the previous page button.
   */
  onPreviousPage: () => void;
  /**
   * A callback invoked with a `pageSize` when the user selects a new page size.
   */
  onChangePageSize: (pageSize: number) => void;
} & PageSelectionProps;

export const Paginator: FC<PaginatorProps> = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  pageSizeOptions = defaultPageSizeOptions,
  onSelectPage,
  onNextPage,
  onPreviousPage,
  onChangePageSize,
}: PaginatorProps) => {
  return (
    <div className="flex items-center space-x-2 text-default dark:text-dark-bg">
      {pageSizeOptions?.length > 0 && (
        <SelectPageSize
          onChangePageSize={onChangePageSize}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      )}
      {pageOptions && onSelectPage && (
        <>
          <SelectPage
            disabled={!pageOptions.length}
            onSelectPage={onSelectPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
          <span className="whitespace-nowrap pr-2 text-sm leading-loose">
            <FormattedMessage
              defaultMessage="of {pageCount}"
              id="RYfuDa"
              description="follows a page selector to show out of how many pages the page is selected"
              values={{ pageCount }}
            />
          </span>
        </>
      )}
      <Tooltip label="Go to previous page">
        <Button
          aria-label="Previous"
          disabled={!canPreviousPage}
          onClick={onPreviousPage}
          icon={<CaretLeftIcon />}
        />
      </Tooltip>
      <Tooltip label="Go to next page">
        <Button
          aria-label="Next"
          disabled={!canNextPage}
          onClick={onNextPage}
          icon={<CaretRightIcon />}
        />
      </Tooltip>
    </div>
  );
};
