import { useMutation } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";
import {
  createDynamicIAMRoleCft,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { regexes } from "utils";
import { useWizardContext } from "../../../../../components/Wizard";
import {
  ACCOUNT_ID,
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  ADD_DETAILS,
  AGENTLESS_SCAN,
  AWS,
  AWS_PARTITION,
  CREATE_IAM_ROLE_CFT,
  DOWNLOAD_IAM_ROLE_CFT,
  FEATURES,
  REMEDIATION,
} from "../../../../constants";
import { useGetSupportedFeaturesList } from "../../../../hooks";
import { type AwsAddDetailsStepValues } from "../../../../types";
import { parseErrorsFromResponse } from "../../../../utils";
import { getWizardStepIndex } from "../../../Onboarding/utils";
import ConfigHelpers from "./ConfigHelpers";
import { downloadDynamicIAMRoleCft, redirectUrl } from "./utils";

type CreateOrDownloadIAMRoleCftProps = {
  closeModal: () => void;
  setCftDownloadedOrCreated: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
};

export default function CreateOrDownloadIAMRoleCft({
  closeModal,
  setCftDownloadedOrCreated,
  disabled,
}: CreateOrDownloadIAMRoleCftProps) {
  const intl = useIntl();
  const {
    state: { steps, activeIndex },
  } = useWizardContext();
  const { toast } = useToastActions();
  const { setValue, watch } = useFormContext();
  const { cloudScanMode } = useFlags();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);

  const [isIAMRoleCftDownloaded, setIAMRoleCftDownloaded] = useState(false);
  const [iamRoleCftMode, setIAMRoleCftMode] = useState("");

  let {
    accountType,
    partitionType: awsPartition,
    name: accountName,
    onboardType,
  } = (steps[addDetailsStepIndex]?.values ?? {}) as AwsAddDetailsStepValues;
  const isGovcloudSelected = awsPartition?.includes("gov");

  if (activeIndex === 0) {
    [accountType, accountName, awsPartition, onboardType] = watch([
      "accountType",
      "name",
      "partitionType",
      "onboardType",
      "groupIds",
    ]);
  }

  const accountId = watch("accountId");

  disabled = useMemo(
    () =>
      onboardType === "automated"
        ? disabled
        : isEmpty(accountId)
          ? true
          : !regexes.awsAccountId.test(accountId),
    [accountId, disabled, onboardType],
  );

  disabled = useMemo(
    () =>
      onboardType === "automated"
        ? disabled
        : isEmpty(accountId)
          ? true
          : !regexes.awsAccountId.test(accountId),
    [accountId, disabled, onboardType],
  );

  const securityCapabilities = watch("securityCapabilities") ?? {};
  const mode = watch("mode") ?? "";
  const isAutomatedOnboardType = onboardType === "automated";

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: AWS,
    payload: {
      accountType,
      awsPartition,
    },
  });

  const features = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeaturesList.includes(feature as SecurityCapabilitiesType),
    );

  const remediation = securityCapabilities[REMEDIATION];
  const customMemberRoleNameEnabled = watch("customMemberRoleNameEnabled");
  const useTenantExternalId = watch("useTenantExternalId");

  const featuresList = remediation ? [...features, REMEDIATION] : features;

  const featureWithModes = featuresList.map((feature) => {
    if (feature === AGENTLESS_SCAN) {
      return {
        feature,
        mode,
      };
    }
    return { feature };
  });

  const payload = isAutomatedOnboardType
    ? {
        [ACCOUNT_NAME]: accountName,
        [ACCOUNT_TYPE]: accountType,
        [AWS_PARTITION]: awsPartition,
        automatedFlow: true,
      }
    : {
        [ACCOUNT_ID]: accountId,
        [ACCOUNT_TYPE]: accountType,
        [AWS_PARTITION]: awsPartition,
        [FEATURES]: featuresList,
        ...(cloudScanMode && !isGovcloudSelected && { featureWithModes }),
        customMemberRoleNameEnabled,
        useTenantExternalId,
      };

  const { mutateAsync: downloadIAMRoleCft, isPending: isDownloadingCft } =
    useMutation({
      onMutate: () => setIAMRoleCftMode(DOWNLOAD_IAM_ROLE_CFT),
      mutationFn: () => downloadDynamicIAMRoleCft(payload),
      onSuccess: (response) => {
        const externalId = response["external-id"];
        setValue("externalId", externalId);
        setIAMRoleCftDownloaded(true);
        setCftDownloadedOrCreated(true);
      },
      onError: (err) => {
        toast(parseErrorsFromResponse(intl, err), {
          appearance: "error",
        });
        closeModal();
      },
    });

  const { mutateAsync: createIAMRoleCft, isPending: isCreatingCft } =
    useMutation({
      onMutate: () => setIAMRoleCftMode(CREATE_IAM_ROLE_CFT),
      mutationFn: () => createDynamicIAMRoleCft(payload),
      onSuccess: (response) => {
        const externalId = response.externalId;
        setValue("externalId", externalId);
        setIAMRoleCftDownloaded(true);
        setCftDownloadedOrCreated(true);
        redirectUrl(response.createStackLinkWithS3PresignedUrl);
      },
      onError: (err) => {
        toast(parseErrorsFromResponse(intl, err), {
          appearance: "error",
        });
        closeModal();
      },
    });

  return (
    <ConfigHelpers
      createIAMRoleCft={createIAMRoleCft}
      downloadIAMRoleCft={downloadIAMRoleCft}
      iamRoleCftMode={iamRoleCftMode}
      isIAMRoleCftDownloaded={isIAMRoleCftDownloaded}
      isDownloadingCft={isDownloadingCft}
      isCreatingCft={isCreatingCft}
      onboardType={onboardType}
      disabled={disabled}
      closeModal={closeModal}
    />
  );
}
