import { Checkbox, useFormContext } from "form";
import { FormattedMessage, useIntl } from "react-intl";
import { Banner } from "ui";

export default function RotateKeys() {
  const intl = useIntl();
  const { watch } = useFormContext();
  const isKeyRotated = watch("rotateKeys");
  return (
    <div className="mb-2">
      <Checkbox
        data-selector="oci-rotate-keys-checkbox"
        name="rotateKeys"
        label={intl.formatMessage({
          defaultMessage: "Rotate Keys",
          id: "Y84XCK",
          description: "Label for Rotate Keys Checkbox",
        })}
      ></Checkbox>
      {isKeyRotated && (
        <div className="mt-1 flex text-xs">
          <Banner type="inline" appearance="warning" showIcon>
            <FormattedMessage
              defaultMessage="If you select Rotate Keys, you must download the terraform template. The key generated will be valid for 24 hours. Please run the template in OCI and complete all the onboarding steps"
              id="P6ONH9"
              description="Describes impact rotating keys"
            />
          </Banner>
        </div>
      )}
    </div>
  );
}
