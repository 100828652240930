import { z } from "zod";

export const AllAccessKeysResponseObjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdBy: z.string(),
  createdTs: z.number(),
  lastUsedTime: z.number(),
  status: z.string(),
  expiresOn: z.number(),
  role: z.object({ id: z.string(), name: z.string() }),
  roleType: z.string(),
  username: z.string(),
});

export type AllAccessKeysResponseObjectType = z.infer<
  typeof AllAccessKeysResponseObjectSchema
>;

export const AllAccessKeysResponseSchema = z.array(
  AllAccessKeysResponseObjectSchema,
);

export type AllAccessKeysResponseType = z.infer<
  typeof AllAccessKeysResponseSchema
>;

export const GenerateAccessKeyRequestSchema = z.object({
  expiresOn: z.number().optional(),
  name: z.string(),
});

export const GenerateAccessKeyResponseSchema = z.object({
  id: z.string(),
  secretKey: z.string(),
});
