import * as schemas from "../../schemas";

import { z } from "zod";
import { CloudTypesEnumSchema } from "../cloudType";
import { severities } from "../policies";

const severitiesWithBadData = [
  ...severities,
  "LOW",
  "MEDIUM",
  "HIGH",
  "CRITICAL",
  "INFORMATIONAL",
] as const;

const PolicyFilterSchema = z
  .object({
    "cloud.type": z.array(CloudTypesEnumSchema),
    "policy.complianceStandard": z.array(z.string()),
    "policy.label": z.array(z.string()),
    "policy.severity": z.array(z.enum(severitiesWithBadData)),
  })
  .partial();

export const alertRuleNotificationTypes = [
  "amazon_sqs",
  "aws_s3",
  "aws_security_hub",
  "azure_service_bus_queue",
  "email",
  "slack",
  "splunk",
  "jira",
  "microsoft_teams",
  "webhook",
  "google_cscc",
  "service_now",
  "pager_duty",
  "demisto",
  "snowflake",
  "okta_idp",
  "tenable",
  "qualys",
  "aws_sdl",
] as const;

export type AlertRuleNotificationsType = z.infer<
  typeof AlertRuleNotificationTypeEnumSchema
>;

export const AlertRuleNotificationTypeEnumSchema = z.enum(
  alertRuleNotificationTypes,
);

const alertRuleNotificationFrequencies = [
  "as_it_happens",
  "daily",
  "weekly",
  "monthly",
  "recurring",
] as const;
export const AlertRuleNotificationFrequencyEnumSchema = z.enum(
  alertRuleNotificationFrequencies,
);

const scanConfigType = ["STANDARD", "SHIFTLEFT"] as const;
const scanConfigTypeEnumSchema = z.enum(scanConfigType);

export const AlertRuleNotificationConfigSchema = z.object({
  id: z.string().optional(),
  customerId: z.number().positive().optional(),
  frequency: AlertRuleNotificationFrequencyEnumSchema.optional(),
  enabled: z.boolean(),
  recipients: z.array(z.string()),
  detailedReport: z.boolean().optional(),
  withCompression: z.boolean().optional(),
  includeRemediation: z.boolean().optional(),
  lastUpdated: schemas.TimeStampSchema.optional(),
  last_sent_ts: z.number().optional(),
  rruleSchedule: z.string().optional(),
  templateId: z.string().nullish(),
  type: AlertRuleNotificationTypeEnumSchema,
});
export type AlertRuleNotificationConfig = z.infer<
  typeof AlertRuleNotificationConfigSchema
>;

export type AlertRuleType = z.infer<typeof AlertRuleSchema>;

export const AlertRuleSchema = z.object({
  policyScanConfigId: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  policyLabels: z.array(z.string()).optional(),
  excludedPolicies: z.array(z.string()).optional(),
  enabled: z.boolean(),
  scanAll: z.boolean(),
  createdOn: schemas.TimeStampSchema.optional(),
  createdBy: z.string().optional(),
  lastModifiedOn: schemas.TimeStampSchema.optional(),
  lastModifiedBy: z.string().optional(),
  deleted: z.boolean().optional(),
  alertRuleNotificationConfig: z
    .array(AlertRuleNotificationConfigSchema)
    .optional(),
  allowAutoRemediate: z.boolean().optional(),
  delayNotificationMs: z.number().nonnegative().optional(),
  scanConfigType: scanConfigTypeEnumSchema.optional(),
  notifyOnOpen: z.boolean().optional(),
  notifyOnSnoozed: z.boolean().optional(),
  notifyOnDismissed: z.boolean().optional(),
  notifyOnResolved: z.boolean().optional(),
  owner: z.string().optional(),
  notificationChannels: z.array(AlertRuleNotificationTypeEnumSchema).optional(),
  openAlertsCount: z.number().optional(),
  readOnly: z.boolean().optional(),
  policies: z.array(z.string()).optional(),
  target: z
    .object({
      alertRulePolicyFilter: PolicyFilterSchema.optional(),
      accountGroups: z.array(z.string()).optional(),
      excludedAccounts: z.array(z.string()).optional().nullable(),
      includedResourceLists: z
        .object({
          computeAccessGroupIds: z.array(z.string()).optional(),
        })
        .optional(),
      regions: z.array(z.string()).optional().nullable(),
      tags: z
        .array(
          z.object({
            key: z.string().optional(),
            values: z.array(z.string()).optional(),
          }),
        )
        .optional(),
      targetResourceList: z
        .object({
          action: z.literal("AUTO_DISMISS").optional(),
          enabled: z.boolean().optional(),
          additionalNotes: z.string().optional(),
          approver: z.string().optional(),
          ids: z.array(z.string()).nullish(),
          reason: z.string().optional(),
          requestor: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
});

export const AlertRulesSchema = z.array(AlertRuleSchema);
