import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const MoreIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 4 16"}>
      <path d="M1.93437 0H2.06564C3.18674 0 4 0.82632 4 2.00001C4 3.1737 3.18702 4 2.06564 4H1.93437C0.81328 4 0 3.1737 0 2.00001C0 0.82632 0.81299 0 1.93437 0Z" />
      <path d="M2.06564 6H1.93437C0.81299 6 0 6.82632 0 8.00001C0 9.1737 0.81328 10 1.93437 10H2.06564C3.18702 10 4 9.1737 4 8.00001C4 6.82632 3.18674 6 2.06564 6Z" />
      <path d="M2.06564 12H1.93437C0.81299 12 0 12.8263 0 14C0 15.1737 0.81328 16 1.93437 16H2.06564C3.18702 16 4 15.1737 4 14C4 12.8263 3.18674 12 2.06564 12Z" />
    </SvgIcon>
  );
};
