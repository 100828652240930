import { createRoute, redirect } from "@tanstack/react-router";
import { lazy } from "react";
import { getSuggestions } from "requests";
import { rqlConfigFunctionsQueryKey } from "../../components/RQL/search/useFetchRQLConfigFunctions";
import { privateRootRoute } from "../../privateRootRoute";

const InvestigateLayout = lazy(() =>
  import("./InvestigateLayout").then((res) => ({
    default: res.InvestigateLayout,
  })),
);

export const investigateLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "investigate",
  component: InvestigateLayout,
  loader: ({ context }) => {
    // context can be undefined when the beforeLoad throws on a fresh page load
    context?.queryClient?.ensureQueryData({
      queryKey: rqlConfigFunctionsQueryKey,
      queryFn: getSuggestions,
    });
  },
});

export const investigateIndexRoute = createRoute({
  getParentRoute: () => investigateLayoutRoute,
  path: "/",
  component: InvestigateLayout,
  beforeLoad: ({ search }) => {
    throw redirect({ to: "/investigate/search", search });
  },
});
