import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const ArtifactoryIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Artifactory",
    id: "9zOyBa",
    description: "Artifactory alt text",
  });

  return (
    <SvgIcon viewBox="0 0 250 250" {...props}>
      <title>{alt}</title>
      <path
        d="M125 250c69.036 0 125-55.964 125-125S194.036 0 125 0 0 55.964 0 125s55.964 125 125 125Z"
        fill="#61A63A"
      />
      <path
        d="M182.044 176.227H68V185h114.044v-8.773ZM124.78 152.465c-26.94 0-48.767-21.827-48.767-48.698C76.013 76.897 97.84 55 124.71 55c26.939 0 48.767 21.828 48.767 48.698 0 26.94-21.758 48.698-48.698 48.767Zm0-88.14c-21.759 0-39.442 17.614-39.511 39.442 0 21.759 17.614 39.442 39.442 39.511 21.759 0 39.442-17.614 39.511-39.442v-.069c-.069-21.758-17.683-39.373-39.442-39.442Z"
        fill="#000000"
      />
    </SvgIcon>
  );
};
