import { produce } from "immer";
import { actions } from "./actions";
import { init } from "./reducers/init";

import { type Draft } from "immer";
import { type FilterObject, type GlobalState } from "../types";
import { type FilterState, type ReducerActions } from "./actions";

export const initialState: GlobalState = {};
export const defaultFilterState = {
  filters: {},
  displayedFilters: [],
  initialized: false,
};

export const reducer = produce(reducerFunction);

function reducerFunction(
  draft: Draft<GlobalState>,
  action: ReducerActions,
): void {
  const { name } = action.payload;
  if (!name)
    throw `Name is required in the payload to ${action.type}. Make sure you passed a name to useFilters or wrapped your component in a FilterProvider`;
  if (!draft[name] && action.type !== actions.init)
    throw `State not initialized for ${name}. Render a FilterBar with the name ${name} before trying to modify it.`;

  switch (action.type) {
    case actions.init: {
      init(draft, action);
      break;
    }

    case actions.mergeFilters: {
      const { filters } = action.payload;
      const mergedFilters = {
        ...draft[name].filters,
        ...filters,
      };

      const displayedFilters = getDisplayedFilters(
        mergedFilters,
        draft[name].displayedFilters,
      );

      draft[name].config.forEach((filter) => {
        if (filter.required) {
          const currentValue = mergedFilters[filter.name];

          if (Array.isArray(currentValue)) {
            if (!currentValue.length) {
              mergedFilters[filter.name] = filter.defaultValues;
            }
          } else {
            mergedFilters[filter.name] = currentValue || filter.defaultValues;
          }
        }
      });

      draft[name].filters = mergedFilters;
      draft[name].displayedFilters = displayedFilters;
      break;
    }

    case actions.replaceFilters: {
      const { filters } = action.payload;
      const filterKeys = Object.keys(filters);
      draft[name].config.forEach((filter) => {
        if (filter.required) {
          if (!filterKeys.includes(filter.name)) {
            filters[filter.name] = filters[filter.name] || filter.defaultValues;
          }
        }
      });
      const displayedFilters = getDisplayedFilters(
        filters,
        draft[name].displayedFilters,
      );

      draft[name].filters = filters;
      draft[name].displayedFilters = displayedFilters;
      break;
    }

    case actions.displayFilter: {
      if (draft[name].displayedFilters.includes(action.payload.filterName))
        return;

      draft[name].displayedFilters.push(action.payload.filterName);

      break;
    }

    case actions.resetFilters: {
      const displayedFilters: string[] = [];
      const filters: FilterObject = {};
      draft[name].config.forEach((filter) => {
        if (filter.defaultValues) {
          displayedFilters.push(filter.name);
          filters[filter.name] = filter.defaultValues;
        } else {
          if (filter.static) displayedFilters.push(filter.name);
        }
      });

      draft[name].filters = filters;
      draft[name].displayedFilters = displayedFilters;
      break;
    }

    case actions.removeFilter: {
      const displayedFilters = [...draft[name].displayedFilters];
      displayedFilters.splice(
        displayedFilters.indexOf(action.payload.filterName),
        1,
      );

      delete draft[name].filters[action.payload.filterName];

      draft[name].displayedFilters = displayedFilters;
      break;
    }

    case actions.remove: {
      delete draft[name];

      break;
    }

    /* c8 ignore start */
    default:
      if (process.env.NODE_ENV === "development") {
        /* eslint-disable no-console */
        console.error({ action });
      }
      throw new Error(`Unknown Action ${(action as ReducerActions).type}`);
    /* c8 ignore stop */
  }
}

const getDisplayedFilters = (
  filters: FilterState["filters"],
  displayedFilters: FilterState["displayedFilters"],
): FilterState["displayedFilters"] => {
  const clonedDisplay = [...displayedFilters];
  Object.keys(filters).forEach((filterName) => {
    if (clonedDisplay.includes(filterName)) return;
    clonedDisplay.push(filterName);
  });
  return clonedDisplay;
};
