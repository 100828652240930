import {
  type BreadcrumbOptions,
  type Exception,
  type MonitoringTool,
  type ReportOptions,
  type Scope,
  type Tags,
} from "./MonitoringTool";
import { FailSafe } from "./utils";

@FailSafe
export class Monitoring implements MonitoringTool {
  private adapter: MonitoringTool;

  constructor(adapter: MonitoringTool) {
    this.adapter = adapter;
  }

  setupScope(scope: Scope) {
    this.adapter.setupScope(scope);
  }

  setTags(tags: Tags) {
    this.adapter.setTags(tags);
  }

  leaveBreadcrumb(message: string, options?: BreadcrumbOptions) {
    this.adapter.leaveBreadcrumb(message, options);
  }

  reportException(exception: Exception, options?: ReportOptions) {
    this.adapter.reportException(exception, options);
  }

  reportMessage(message: string, options?: ReportOptions) {
    this.adapter.reportMessage(message, options);
  }
}
