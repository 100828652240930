import { useQuery } from "@tanstack/react-query";
import { deploymentTypeKeys, getDeploymentType } from "requests";

type UseGetCloudAccountsRestProps = {
  options?: Record<string, string | boolean>;
};

export const useGetCloudDeploymentTypes = ({
  options = {},
}: UseGetCloudAccountsRestProps) => {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: deploymentTypeKeys.all,
    queryFn: getDeploymentType,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
};
