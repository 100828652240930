import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { ACCOUNT, AWS, AZURE, GCP } from "../../../constants";

import { cloudAccountsKeys, enableCloudAccount } from "requests";
import { useToastActions } from "stores";
import { Toggle } from "ui";
import { type AccountType, type CloudType } from "../../../types";

type IngestionEnabledProps = {
  accountId: string;
  accountType: AccountType;
  cloudType: CloudType;
  enabled: boolean;
};

export default function IngestionEnabled({
  accountId,
  accountType,
  cloudType,
  enabled,
}: IngestionEnabledProps) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();

  const updateChildren =
    [AWS, AZURE, GCP].includes(cloudType) && accountType !== ACCOUNT;

  const accountDetailsCacheKey = cloudAccountsKeys.cloudAccountsCas({
    accountId,
    cloudType,
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () =>
      enableCloudAccount({ accountId, enabled: !enabled, updateChildren }),
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: accountDetailsCacheKey });
    },
    onError: () => {
      toast(
        intl.formatMessage({
          defaultMessage:
            "Something went wrong. Please contact your Prisma Cloud support team.",
          id: "727FsV",
          description: "warning toast message",
        }),
        { appearance: "warning" },
      );
    },
    onSuccess: () => {
      toast(
        intl.formatMessage(
          {
            defaultMessage: "Cloud Account {accountId} {action} successfully!",
            id: "QxeSZ5",
            description: "success toast message",
          },
          {
            accountId,
            action: !enabled ? "enabled" : "disabled",
          },
        ),
        { appearance: "success" },
      );

      queryClient.invalidateQueries({ queryKey: accountDetailsCacheKey });
      if (cloudType === "aws")
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.awsCloudAccountsCas({ accountId }),
        });
      if (cloudType === "azure")
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.azureCloudAccountsCas({ accountId }),
        });
      if (cloudType === "gcp")
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.gcpCloudAccountsCas({ accountId }),
        });
      if (cloudType === "ibm")
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.ibmCloudAccountsCas({ accountId }),
        });
      if (cloudType === "alibaba_cloud" || cloudType === "oci")
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsRest({
            accountId,
            cloudType,
            includeGroupInfo: "true",
          }),
        });

      queryClient.invalidateQueries({
        queryKey: cloudAccountsKeys.cloudAccountsTable({
          excludeAccountGroupDetails: "true",
        }),
      });
    },
  });

  return (
    <div className="flex items-center space-x-2">
      <label className="text-xs text-secondary">
        <FormattedMessage
          defaultMessage="Enable"
          id="eH4CH6"
          description="Label for Enable"
        />
      </label>
      <Toggle
        checked={enabled}
        disabled={isPending}
        onClick={() => mutateAsync()}
        data-testid="cloud-accounts-ingestion-toggle"
      />
    </div>
  );
}
