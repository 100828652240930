import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const GoogleIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Google",
    id: "RGRZIJ",
    description: "Google alt text",
  });

  return (
    <SvgIcon viewBox="0 0 26 26" {...props}>
      <title>{alt}</title>
      <g clipPath="url(#google_svg__a)">
        <path
          d="M5.26 15.28c.093.234.18.47.28.699.17.389.379.757.618 1.107.216.315.453.612.713.892a7.176 7.176 0 0 0 1.622 1.302 6.787 6.787 0 0 0 1.99.787c.26.058.526.09.79.13.363.053.729.05 1.093.034a7.598 7.598 0 0 0 2.14-.396c.513-.177 1-.406 1.455-.704.034-.022.07-.041.103-.062.008.007.014.015.022.021l.916.713 2.265 1.761.653.51c-.458.427-.954.805-1.482 1.142a10.802 10.802 0 0 1-2.218 1.076 10.7 10.7 0 0 1-1.271.367c-.26.057-.52.112-.782.16-.162.03-.327.044-.491.064-.179.022-.357.045-.537.063-.088.009-.177.007-.265.013-.104.007-.207.017-.31.025h-1.05l-.245-.023c-.214-.018-.428-.03-.642-.055a11.076 11.076 0 0 1-2.163-.451 11.882 11.882 0 0 1-3.924-2.08 12.01 12.01 0 0 1-3.235-3.969l-.02-.045.504-.39 2.83-2.196c.213-.166.428-.33.642-.495Z"
          fill="#34A753"
        />
        <path
          d="M1.285 7.607c.143-.263.277-.532.431-.788a11.967 11.967 0 0 1 3.552-3.765 11.798 11.798 0 0 1 4.798-1.915c.25-.04.504-.066.757-.092.179-.019.358-.027.538-.039.09-.005.182-.008.272-.012.083-.004.166-.012.249-.012.232.002.465.003.697.013a11.951 11.951 0 0 1 2.28.32c.443.11.877.24 1.304.398.426.158.84.34 1.244.55.58.3 1.136.644 1.65 1.047.302.236.59.49.885.736.024.02.045.042.062.058L16.58 7.529c-.093-.085-.187-.177-.287-.261a6.437 6.437 0 0 0-.364-.284 5.845 5.845 0 0 0-1.04-.616 8.124 8.124 0 0 0-.78-.306c-.246-.08-.502-.132-.755-.19a5.374 5.374 0 0 0-.418-.076 2.917 2.917 0 0 0-.281-.025c-.196-.01-.392-.021-.588-.024-.177-.002-.353.005-.53.012a3.764 3.764 0 0 0-.35.024c-.188.025-.377.046-.56.09-.305.074-.61.149-.907.25a6.793 6.793 0 0 0-1.975 1.07c-.285.22-.554.457-.802.717a7.414 7.414 0 0 0-1.672 2.754l-.011.022-.45-.345-1.938-1.503-1.588-1.232Z"
          fill="#E94335"
        />
        <path
          d="m19.92 22.075-.653-.51-2.265-1.762-.916-.713c-.008-.006-.014-.014-.022-.021 1.265-.873 2.079-2.05 2.396-3.571h-6.464v-4.514h.066c3.72 0 7.44 0 11.161-.002.058 0 .074.015.081.075.032.266.08.531.106.798.027.276.039.554.053.832a11.36 11.36 0 0 1-.002 1.395c-.01.205-.024.41-.049.613-.044.36-.083.722-.152 1.079-.08.42-.18.838-.29 1.253a9.177 9.177 0 0 1-.537 1.494c-.17.37-.36.731-.553 1.09a8.112 8.112 0 0 1-.781 1.171c-.188.238-.383.47-.586.694-.174.193-.363.373-.546.558-.014.015-.03.027-.047.04Z"
          fill="#4285F3"
        />
        <path
          d="m1.284 7.607 1.588 1.23 1.938 1.504.45.345c-.077.286-.16.57-.228.857-.043.186-.066.377-.095.566a6.22 6.22 0 0 0-.048 1.173c.008.22.027.441.06.66.044.282.102.563.164.842.038.168.097.33.146.496-.214.165-.43.329-.643.495l-2.83 2.196-.503.39a10.69 10.69 0 0 1-.531-1.203 11.532 11.532 0 0 1-.605-2.268c-.032-.2-.061-.402-.083-.604a7.378 7.378 0 0 1-.05-.62 34.408 34.408 0 0 1-.012-1.285c.002-.203.03-.405.05-.608a11.962 11.962 0 0 1 1.21-4.13l.022-.037Z"
          fill="#FABB05"
        />
      </g>
      <defs>
        <clipPath id="google_svg__a">
          <path fill="#fff" transform="translate(0 .984)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
