import { createRoute, redirect } from "@tanstack/react-router";
import { isGov } from "environment";
import { lazy } from "react";
import { z } from "zod";
import { homeLayoutRoute } from "../home/homeLayoutRoute";
import { APPSEC_ROUTE } from "./consts";

const CasProjects = lazy(() => import("./CasProjects"));
const CasRepositories = lazy(() => import("./CasRepositories"));
const CasSbom = lazy(() => import("./CasSbom"));
const CasTechnologies = lazy(() => import("./CasTechnologies"));
const CasCicdRisks = lazy(() => import("./CasCicdRisks"));
const HomeAppSecLayout = lazy(() =>
  import("./HomeAppSecLayout").then((res) => ({
    default: res.HomeAppSecLayout,
  })),
);

export const AppSecSearchSchema = z.object({
  skipAnimation: z.optional(z.boolean()),
});

export const homeAppSecLayoutRoute = createRoute({
  getParentRoute: () => homeLayoutRoute,
  path: APPSEC_ROUTE,
  component: HomeAppSecLayout,
  validateSearch: AppSecSearchSchema,
});

export const homeAppSecIndexRoute = createRoute({
  getParentRoute: () => homeAppSecLayoutRoute,
  path: "/",
  beforeLoad: ({ search }) => {
    if (isGov()) {
      throw redirect({
        to: "/home/appsec/projects",
        replace: true,
      });
    } else {
      throw redirect({
        to: "/home/appsec/repositories",
        replace: true,
        search,
      });
    }
  },
});

export const casProjectsRoute = createRoute({
  getParentRoute: () => homeAppSecLayoutRoute,
  path: "projects",
  component: CasProjects,
});
export const casProjectsNestedRoute = createRoute({
  getParentRoute: () => casProjectsRoute,
  // /enforcement-rules and maybe others?
  path: "$",
});

export const casRepositoriesRoute = createRoute({
  getParentRoute: () => homeAppSecLayoutRoute,
  path: "repositories",
  component: CasRepositories,
});

export const casRepositoriesNestedRoute = createRoute({
  getParentRoute: () => casRepositoriesRoute,
  path: "$",
  component: CasRepositories,
});

export const casSbomRoute = createRoute({
  getParentRoute: () => homeAppSecLayoutRoute,
  path: "sbom",
  component: CasSbom,
});

export const casSbomNestedRoute = createRoute({
  getParentRoute: () => casSbomRoute,
  path: "$",
  component: CasSbom,
});

export const casTechnologiesRoute = createRoute({
  getParentRoute: () => homeAppSecLayoutRoute,
  path: "technologies",
  component: CasTechnologies,
});

export const casTechnologiesNestedRoute = createRoute({
  getParentRoute: () => casTechnologiesRoute,
  path: "$",
});

export const casCicdRisksRoute = createRoute({
  getParentRoute: () => homeAppSecLayoutRoute,
  path: "cicd-risks",
  component: CasCicdRisks,
});

export const casCicdRisksNestedRoute = createRoute({
  getParentRoute: () => casCicdRisksRoute,
  path: "$",
  component: CasCicdRisks,
});
