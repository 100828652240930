import {
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  DATA_SECURITY,
  REMEDIATION,
  SERVERLESS_SCAN,
} from "../../../../../constants";

import AzureSecurityCapabilitiesPermissions, {
  AgentbasedWorkloadProtection,
  AgentlessWorkloadScanning,
  IdentitySecurity,
  Misconfigurations,
  Remediation,
  ServerlessFunctionScanning,
  ThreatDetection,
} from "../../../../../components/ReviewStatus/SecurityCapabilitiesPermissions";

import {
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { type Status } from "../../../../../types";
import { getSecurityCapabilitiesData } from "../utils";

type SecurityCapabilities = Exclude<
  SecurityCapabilitiesType,
  | "Cloud Visibility Compliance and Governance"
  | "Identity Security"
  | "Threat Detection"
  | "AWS Guard Duty"
  | "AWS Inspector"
  | "Agentless API Discovery"
>;
const featuresMap: Record<SecurityCapabilities, string> = {
  [AGENTLESS_SCAN]: "agentless_scanning",
  [AUTO_PROTECT]: "auto_protect",
  [DATA_SECURITY]: "storage_scan",
  [REMEDIATION]: "remediation",
  [SERVERLESS_SCAN]: "serverless_function_scanning",
};

type SecurityCapabilitiesPermissionsPropTypes = {
  accountStatus: {
    id: string;
    name: string;
    statusMessage: {
      message: string;
      staticMessage: boolean;
    };
    status: Status;
  }[];
  features: SecurityCapabilitiesFeatures[];
  isSubscribed: boolean;
  isActiveDirectory: boolean;
  isLoading: boolean;
};

export default function SecurityCapabilitiesPermissions({
  accountStatus,
  features,
  isSubscribed,
  isActiveDirectory,
  isLoading,
}: SecurityCapabilitiesPermissionsPropTypes) {
  const statusData = accountStatus.map((data) => ({
    ...data,
    message: data?.statusMessage?.message,
  }));
  const disabledFeatures = features
    .filter(({ state }) => state === "disabled")
    .map(({ name, state }) => ({
      name: featuresMap[name as SecurityCapabilities],
      status: state,
    }));

  const {
    misconfigurationsData,
    threatDetectionData,
    agentlessWorkloadScanningData,
    serverlessFunctionScanningData,
    agentBasedWorkloadProtectionData,
    remediationData,
  } = getSecurityCapabilitiesData([...statusData, ...disabledFeatures]);

  const identitySecurityData = {
    status: (isSubscribed ? "enabled" : "disabled") as Status,
    name: "identitySecurity",
  };

  return (
    <AzureSecurityCapabilitiesPermissions isLoading={isLoading}>
      <Misconfigurations data={misconfigurationsData} />
      {!isActiveDirectory && <IdentitySecurity data={identitySecurityData} />}
      <AgentlessWorkloadScanning data={agentlessWorkloadScanningData} />
      <ThreatDetection data={threatDetectionData} />
      <ServerlessFunctionScanning data={serverlessFunctionScanningData} />
      <AgentbasedWorkloadProtection data={agentBasedWorkloadProtectionData} />
      <Remediation data={remediationData} />
    </AzureSecurityCapabilitiesPermissions>
  );
}
