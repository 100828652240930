import { FormLayout } from "form";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button, useModal } from "ui";
import { NextButton, WizardForm } from "../../../../../components/Wizard";
import { useGetAwsPartitionItems } from "../../../../hooks";
import { buttonNames } from "../../../../messages/commonMessages";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import DeploymentType from "./DeploymentType";
import Scope from "./Scope";
import SecurityCapabilitiesAndPermissions from "./SecurityCapabilitiesAndPermissions/SecurityCapabilitiesAndPermissions";

type GetStartedProps = {
  context: {
    accountId: string;
    fromFreemium: boolean;
    isEdit: boolean;
    closeModal: () => void;
  };
};

export default function GetStarted({
  context: {
    closeModal: closeOnboardingWizard,
    isEdit,
    accountId,
    fromFreemium,
  },
}: GetStartedProps) {
  const { openModal: openCloudSelectionModal } = useModal(CloudSelection);

  const { awsPartitionItems } = useGetAwsPartitionItems();

  const showPartitionSection = awsPartitionItems?.length > 1;

  const handleClick = useCallback(() => {
    closeOnboardingWizard();
    openCloudSelectionModal();
  }, [closeOnboardingWizard, openCloudSelectionModal]);

  return (
    <WizardForm
      actions={
        <>
          {!isEdit && (
            <Button onClick={handleClick}>
              <FormattedMessage {...buttonNames.previous} />
            </Button>
          )}
          <NextButton />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        {showPartitionSection && <DeploymentType isEdit={isEdit} />}
        <Scope isEdit={isEdit} />
        <SecurityCapabilitiesAndPermissions
          fromFreemium={fromFreemium}
          accountId={accountId}
          isEdit={isEdit}
        />
      </FormLayout>
    </WizardForm>
  );
}
