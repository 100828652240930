import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { publicRootRoute } from "../../publicRootRoute";

const RequestPasswordReset = lazy(() => import("./RequestPasswordReset"));

export const requestPasswordResetRoute = new Route({
  getParentRoute: () => publicRootRoute,
  path: "/request-password-reset",
  component: RequestPasswordReset,
});
