import { useFormContext } from "form";
import { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useToastActions } from "stores";
import { Bold, Title } from "ui";
import {
  useWizardContext,
  type WizardStepState,
} from "../../../../../../components/Wizard";
import {
  ADD_DETAILS,
  AWS,
  CONFIGURE_DATA_SECURITY,
  CONFIGURE_FW,
  DATA_SECURITY,
  DEFINE_FW,
  ORGANIZATION,
} from "../../../../../constants";
import { type AwsAddDetailsStepValues } from "../../../../../types";
import SelectMemberAccounts from "../../../../Onboarding/AwsOnboarding/ConfigureAccount/AccountDetails/SelectMemberAccounts";
import ConfigureDataSecurity from "../../../../Onboarding/AwsOnboarding/ConfigureDataSecurity";
import ConfigureFwScan from "../../../../Onboarding/AwsOnboarding/ConfigureFwScan";
import DefineFwScan from "../../../../Onboarding/AwsOnboarding/DefineFwScan";
import { useAWSSelectMemberAccountsContext } from "../../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext";
import {
  setMemberRoleName,
  setRoleArn,
} from "../../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext/state/actions";
import { getWizardStepIndex } from "../../../../Onboarding/utils";
import MemberRoleName from "../../../components/MemberRoleName";
import IAMRoleARN from "../../../components/RoleARN";
import SecurityCapabilities from "../../../components/SecurityCapabilities";
import AdvancedConfiguration from "./AdvancedConfiguration";

export default function SecurityCapabilitiesAndPermissions({
  fromFreemium,
  closeModal,
  disabled,
}: {
  fromFreemium: boolean;
  closeModal: () => void;
  disabled: boolean;
}) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { steps },
    removeStep,
    addStep,
  } = useWizardContext();
  const { watch, setValue } = useFormContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);

  const { accountType, partitionType: awsPartition } = steps[
    addDetailsStepIndex
  ].values as AwsAddDetailsStepValues;
  const securityCapabilities = watch("securityCapabilities");
  const customMemberRoleNameEnabled = watch("customMemberRoleNameEnabled");

  const { dispatch: selectMemberAccountsDispatch } =
    useAWSSelectMemberAccountsContext();

  const onRoleArnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) =>
    selectMemberAccountsDispatch(setRoleArn(event.target.value ?? ""));

  const onMemberRoleNameBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) =>
    selectMemberAccountsDispatch(setMemberRoleName(event.target.value ?? ""));

  const dlpSteps: WizardStepState[] = useMemo(
    () => [
      {
        Component: ConfigureDataSecurity,
        disabled: true,
        key: CONFIGURE_DATA_SECURITY,
        hasBeenSubmitted: false,
        label: intl.formatMessage({
          defaultMessage: "Configure Data Security",
          id: "rmpD2C",
          description: "Cloud Account Wizard page heading",
        }),
        values: {
          scanOption: "Custom",
        },
      },
      {
        Component: DefineFwScan,
        disabled: true,
        key: DEFINE_FW,
        hasBeenSubmitted: false,
        label: intl.formatMessage({
          defaultMessage: "Define Forward Scan",
          id: "/0Aclu",
          description: "Cloud Account Wizard page heading",
        }),
        values: {
          cloudTrailTopic: "",
        },
      },
      {
        Component: ConfigureFwScan,
        disabled: true,
        key: CONFIGURE_FW,
        hasBeenSubmitted: false,
        label: intl.formatMessage({
          defaultMessage: "Configure Forward Scan",
          id: "Ufg/5/",
          description: "Cloud Account Wizard page heading",
        }),
      },
    ],
    [intl],
  );

  useEffect(() => {
    if (accountType === ORGANIZATION && securityCapabilities?.[DATA_SECURITY]) {
      delete securityCapabilities[DATA_SECURITY];
      setValue("securityCapabilities", securityCapabilities);
    }
    if (securityCapabilities?.[DATA_SECURITY]) {
      if (!steps.some((step) => step.key === DEFINE_FW)) {
        addStep({ index: 2, steps: dlpSteps });
      }
    } else {
      if (steps.some((step) => step.key === DEFINE_FW)) {
        removeStep({ index: 2 });
        removeStep({ index: 2 });
        removeStep({ index: 2 });
      }
    }
    if (fromFreemium && !securityCapabilities?.[DATA_SECURITY]) {
      toast(
        intl.formatMessage({
          defaultMessage:
            "Data Security checkbox must be selected to enable Data Security",
          id: "fmk5gd",
          description: "Warning in a toast for the Data Security option",
        }),
        { appearance: "warning" },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityCapabilities, accountType]);

  return (
    <>
      <section className="space-y-2">
        <Title
          level={5}
          size="xxs"
          addClassName="text-secondary dark:text-dark-bg-secondary"
        >
          <Bold>
            <FormattedMessage
              defaultMessage="Security Capabilities And Permissions"
              id="eIczYB"
              description="Title for Security Capabilities And Permissions section"
            />
          </Bold>
        </Title>

        <SecurityCapabilities
          cloudType={AWS}
          payload={{
            accountType,
            awsPartition,
          }}
        />
      </section>
      <AdvancedConfiguration />
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div
            className={`${customMemberRoleNameEnabled ? "w-1/2" : "w-full"}`}
          >
            <IAMRoleARN onBlur={onRoleArnBlur} disabled={disabled} />
          </div>
          {customMemberRoleNameEnabled && (
            <div className="w-1/2">
              <MemberRoleName
                onBlur={onMemberRoleNameBlur}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
      <SelectMemberAccounts closeModal={closeModal} />
    </>
  );
}
