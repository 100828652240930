import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "form";
import { Button } from "ui";
import { type InitialValues } from "../roleTypes";

export default function DisabledRepositoriesField({
  initialValues,
}: {
  initialValues?: InitialValues;
}) {
  const intl = useIntl();

  const defaultValues = useMemo(() => {
    const initial = { ...initialValues };
    const { codeRepositories = [] } = initial ?? {};

    if (!codeRepositories.length) return [];

    return initial?.codeRepositories?.map((v) => ({
      value: v.id,
      children: v.name,
      context: { id: v.id, name: v.name },
    }));
  }, [initialValues]);

  return (
    <Select
      button={
        <Button isMenu fullWidth>
          {defaultValues?.map((v) => v.children).join(", ")}
        </Button>
      }
      label={intl.formatMessage({
        defaultMessage: "Repositories",
        id: "sktiZH",
        description: "Label for repositories field",
      })}
      name="repositories"
      defaultValue={defaultValues}
      items={defaultValues}
      register={{ required: false }}
      disabled
      menuStyle={{ minWidth: "34rem" }}
    />
  );
}
