import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AtlassianIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Atlassian",
    id: "dGy1/H",
    description: "Atlassian alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <g clipPath="url(#atlassian_svg__a)">
        <path
          d="M7.123 11.075a.682.682 0 0 0-1.16.127L.074 22.98A.704.704 0 0 0 .702 24H8.9a.678.678 0 0 0 .632-.4c1.766-3.628.696-9.206-2.409-12.526Z"
          fill="url(#atlassian_svg__b)"
        />
        <path
          d="M11.443.377a15.538 15.538 0 0 0-.907 15.338l3.972 7.886a.704.704 0 0 0 .628.392h8.198a.725.725 0 0 0 .605-1.013L12.64.377a.664.664 0 0 0-1.196 0Z"
          fill="#2684FF"
        />
      </g>
      <defs>
        <linearGradient
          id="atlassian_svg__b"
          x1={10.351}
          y1={12.893}
          x2={4.134}
          y2={23.661}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset={0.92} stopColor="#2684FF" />
        </linearGradient>
        <clipPath id="atlassian_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
