import { UploadIcon } from "icons";
import { Bold, Button, DynamicFormattedMessage } from "ui";
import { browseOrUpdateMap } from "../../messages";

export default function UploadFileBtn({
  isFileUploaded = false,
}: {
  isFileUploaded?: boolean;
}) {
  return (
    <Button
      icon={<UploadIcon />}
      appearance="secondary"
      addClassName="space-x-1 px-7"
      data-selector="gcp-upload-json"
    >
      <Bold>
        <DynamicFormattedMessage
          messageMap={browseOrUpdateMap}
          token={isFileUploaded ? "update" : "browse"}
        />
      </Bold>
    </Button>
  );
}
