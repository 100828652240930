import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const TrustedIpAddresses = lazy(() => import("./TrustedIpAddresses"));

export const trustedIpAddressesRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "trusted-ip-addresses",
  component: TrustedIpAddresses,
});
