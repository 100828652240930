import { iconColor } from "colors";
import {
  BanIcon,
  DangerIcon,
  ExclamationCircleIcon,
  LoadingIcon,
  SuccessIcon,
  WarningIcon,
} from "icons";
import { useMemo, type ReactNode } from "react";
import {
  defineMessages,
  useIntl,
  type IntlShape,
  type MessageDescriptor,
} from "react-intl";
import { isString, isTruthy } from "remeda";
import { type PermissionsStatus } from "requests";
import {
  DynamicFormattedMessage,
  TruncateText,
  dynamicFormatMessage,
} from "ui";
import { classNames, snakeCase } from "utils";
import { statusModalMessagesMap } from "../../../../../../../messages/statusModalMessages";
import { type Status } from "../../../../../../../types";
import Details from "../Details";

function sanitizeMessage(intl: IntlShape, message: string) {
  if (!isTruthy(message) || (isString(message) && !message?.trim()))
    return message;
  return snakeCase(message) in statusModalMessagesMap
    ? dynamicFormatMessage(intl, statusModalMessagesMap, snakeCase(message))
    : message;
}

const statusTextMap: Record<Status, MessageDescriptor> = defineMessages({
  ok: {
    defaultMessage: "Successful",
    id: "e5AJT4",
    description: "Status message",
  },
  success: {
    defaultMessage: "Successful",
    id: "e5AJT4",
    description: "Status message",
  },
  warning: {
    defaultMessage: "Issues Found",
    id: "MHgVpS",
    description: "Status message",
  },
  error: {
    defaultMessage: "Checks Failed",
    id: "zD0Vmt",
    description: "Status message",
  },
  disabled: {
    defaultMessage: "Not Enabled",
    id: "A7KCXo",
    description: "Status message",
  },
  enabled: {
    defaultMessage: "Enabled",
    id: "cdh8Ka",
    description: "Status message",
  },
  pending: {
    defaultMessage: "Pending",
    id: "1H9gBv",
    description: "Status message",
  },
});

const colors: Record<Status, string> = {
  disabled: "text-gray-400 text-dark-bg-disabled",
  ok: "",
  enabled: "",
  error: "",
  warning: "",
  success: "",
  pending: "",
};

const IconMapping: Record<Status, ReactNode> = {
  disabled: <BanIcon size="md" />,
  error: <DangerIcon size="md" />,
  ok: <SuccessIcon size="md" />,
  success: <SuccessIcon size="md" />,
  enabled: <SuccessIcon size="md" />,
  warning: <WarningIcon size="md" />,
  pending: <ExclamationCircleIcon fill={iconColor.orange} size="md" />,
};

type StatusDetailsProps = PermissionsStatus & {
  detailsClassName?: string;
  icon?: ReactNode;
  label: string;
  normalFont?: boolean;
  isLoading?: boolean;
  message?: string;
  status?: string;
  isSingleSecurityCapabity?: boolean;
};

StatusDetails.defaultProps = {
  message: "",
  status: "",
};

export default function StatusDetails({
  detailsClassName,
  icon,
  label,
  message,
  normalFont,
  status,
  isLoading = false,
  isSingleSecurityCapabity = false,
}: StatusDetailsProps) {
  const intl = useIntl();
  const Icon = useMemo(() => status && IconMapping[status], [status]);

  if (!status && !isLoading) return <></>;

  return (
    <Details
      className={detailsClassName}
      icon={icon}
      label={label}
      normalFont={normalFont}
    >
      {isLoading ? (
        <LoadingIcon size="md" />
      ) : (
        <>
          <div className={`flex ${isSingleSecurityCapabity && "pl-6"}`}>
            <span className={`${classNames(colors[status])} mr-2`}>{Icon}</span>
            <TruncateText>
              <DynamicFormattedMessage
                messageMap={statusTextMap}
                token={status}
              />
            </TruncateText>
          </div>

          <div
            className={`col-span-2 flex items-center ${
              isSingleSecurityCapabity && "pl-4"
            }`}
          >
            <TruncateText>{sanitizeMessage(intl, message)}</TruncateText>
          </div>
        </>
      )}
    </Details>
  );
}
