import { Set } from "immutable";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { ALL } from "../../../../../../../constants";
import { useIsRenderedFirstTime } from "../../../../../../../hooks";
import {
  initAwsOUTreeMap,
  setAwsOUFoldersCredentialError,
  setAwsOULoadedNodes,
  setAwsOUPreviouslySelectedNodesNotFound,
  setAwsOUProjectSelectMode,
  setAwsOUSelectProjectsInitialized,
  setAwsOUSelectProjectsIsLoading,
  setAwsOUSelectedNodes,
  updateAwsOUTreeData,
} from "../../../../context/AwsSelectMemberAccountsContext/state/actions";
import {
  getAccountId,
  getAccountName,
  getAwsOUFolderViewCredentialsError,
  getAwsOURootId,
  getAwsOUSelectProjectsInitialized,
  getAwsOUSelectedNodesInitial,
  getAwsOUTreeMap,
  getRoleARN,
} from "../../../../context/AwsSelectMemberAccountsContext/state/getters";

import { useToastActions } from "stores";
import { usePrevious } from "ui";

import { useMutation } from "@tanstack/react-query";
import { isEmpty, partition } from "remeda";
import {
  getAwsSelectMemberAccountAncestors,
  getAwsSelectMemberAccountChildrens,
} from "requests";
import { snakeCase } from "utils";
import { parseErrorsFromResponse } from "../../../../../../../utils";
import { getAccountIdFromRoleArn } from "../../../../../utils";
import { useAWSSelectMemberAccountsContext } from "../../../../context/AwsSelectMemberAccountsContext";
import AWSOrgUnitSelection from "./AWSOrgUnitSelection";
import {
  getChildrenApiPayload,
  getPayload,
} from "./AWSOrgUnitSelection/utils/payload";

type AWSChooseMemberAccountsProps = {
  closeModal: () => void;
};

export default function AWSChooseMemberAccounts({
  closeModal,
}: AWSChooseMemberAccountsProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const { state, dispatch } = useAWSSelectMemberAccountsContext();
  const { watch } = useFormContext();

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();
  const externalId = watch("externalId");
  const useTenantExternalId = watch("useTenantExternalId");

  const accountId = getAccountId(state);
  const roleArn = getRoleARN(state);

  const isAwsOUSelectProjectsInitialized =
    getAwsOUSelectProjectsInitialized(state);
  const credentialError = getAwsOUFolderViewCredentialsError(state);
  const accountIdFromRoleArn = getAccountIdFromRoleArn(roleArn) as string;

  const isRoleArnChanged = usePrevious(roleArn) !== roleArn;
  const isAccountIdChanged = usePrevious(accountId) !== accountId;
  const isExternalIdChanged = usePrevious(externalId) !== externalId;

  const isValidAccountId =
    !isEmpty(accountId) &&
    !isEmpty(accountIdFromRoleArn) &&
    accountId === accountIdFromRoleArn;

  // Children Api
  const { mutateAsync: loadAwsOUNodeData } = useMutation({
    mutationFn: async (treeNode: {
      childrenType: string;
      loadType: string;
      parentId: string;
    }) => {
      try {
        const { id, body, params } = getChildrenApiPayload(
          treeNode,
          accountIdFromRoleArn,
          state,
        );
        const data = await getAwsSelectMemberAccountChildrens({
          id,
          body: {
            ...body,
            ...{ useTenantExternalId },
          },
          params,
        });
        dispatch(
          updateAwsOUTreeData({
            childrenType: treeNode.childrenType,
            data,
            loadType: treeNode.loadType,
            parentId: treeNode.parentId,
          }),
        );
        dispatch(setAwsOULoadedNodes(treeNode.parentId));
      } catch (error) {
        const errorMessages = [
          "permission_error",
          "external_id_empty_or_not_generated",
        ];
        const errorMessage = snakeCase(
          parseErrorsFromResponse(intl, error)[0] ?? "",
        );

        if (errorMessages.includes(errorMessage)) {
          dispatch(setAwsOUFoldersCredentialError(errorMessage));
          setAwsOUProjectSelectMode(ALL, dispatch);
        } else {
          const toastMessage = parseErrorsFromResponse(intl, error);
          toast(toastMessage, {
            appearance: "error",
          });
          closeModal();
        }
      }
    },
  });

  // Ancestor Api
  const { mutateAsync: loadAwsOUNodeAncestorData } = useMutation({
    mutationFn: async ({ selectedNodes }: { selectedNodes: string[] }) => {
      try {
        const payload = getPayload(state);
        const body = { ...payload, resourceIds: selectedNodes };
        const data = await getAwsSelectMemberAccountAncestors({
          accountId,
          body: {
            ...body,
            ...{ useTenantExternalId },
          },
        });

        const [ancestorsOfSelected, notFound] = partition(
          data,
          (el) => !!el.ancestors,
        );
        dispatch(setAwsOUPreviouslySelectedNodesNotFound(notFound));
        const rootId = getAwsOURootId();
        const orgName = getAccountName(state);
        dispatch(initAwsOUTreeMap({ ancestorsOfSelected, orgName, rootId }));
        return false;
      } catch (error) {
        return true;
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const flag =
        isEmpty(externalId) || // dynamic cft not ran or externalId is not generated
        isEmpty(roleArn) || // roleArn is empty
        !isValidAccountId ||
        (!isAccountIdChanged &&
          !isRoleArnChanged &&
          !isExternalIdChanged &&
          !isRenderedFirstTime) || // accountId, external and roleArn doesn't changed
        (!credentialError &&
          isRenderedFirstTime &&
          isAwsOUSelectProjectsInitialized); // On first render, if there is no credential error then don't make new api call

      // Don't make new api call or return
      if (flag) return;

      dispatch(setAwsOUSelectProjectsIsLoading(true));
      dispatch(setAwsOUSelectProjectsInitialized(false));

      const rootId = getAwsOURootId();
      const orgName = getAccountName(state);
      let childrenApi;

      if (isRoleArnChanged || isAccountIdChanged) {
        dispatch(setAwsOUFoldersCredentialError(""));
        if (!getAwsOUTreeMap(state)?.size) {
          dispatch(initAwsOUTreeMap({ orgName, rootId }));
        }
        childrenApi = loadAwsOUNodeData({
          childrenType: "all",
          loadType: "initial",
          parentId: "root",
        });
      }

      const nodeInitial = getAwsOUSelectedNodesInitial(state);
      const hierarchySelection =
        nodeInitial && "size" in nodeInitial ? nodeInitial.toJS() : nodeInitial;
      const selectionMode =
        (Array.isArray(hierarchySelection) &&
          hierarchySelection.length &&
          hierarchySelection[0]?.selectionType) ||
        ALL;
      const selectedNodes = hierarchySelection?.map(
        (node: { resourceId: string }) => node.resourceId,
      );

      setAwsOUProjectSelectMode(selectionMode, dispatch);
      setAwsOUSelectedNodes(Set(selectedNodes), dispatch);
      dispatch(setAwsOUFoldersCredentialError(""));

      let isAncestorApiFailed = false;

      if (hierarchySelection?.length && selectionMode !== ALL) {
        isAncestorApiFailed = await loadAwsOUNodeAncestorData({
          selectedNodes: selectedNodes ?? [],
        });
        // wait for children api to get resolve when ancestor & children are called together
        if (childrenApi) await childrenApi;
      } else {
        dispatch(initAwsOUTreeMap({ orgName, rootId }));
      }

      if (!isAccountIdChanged && !isRoleArnChanged) {
        await loadAwsOUNodeData({
          childrenType: "all",
          loadType: "initial",
          parentId: "root",
        });
      }

      // wait for children api to get resolved
      if (childrenApi) await childrenApi;
      dispatch(setAwsOUSelectProjectsIsLoading(false));

      if (!isAncestorApiFailed) {
        dispatch(setAwsOUSelectProjectsInitialized(true));
      }
    };

    try {
      fetchData();
    } catch (error) {
      dispatch(setAwsOUSelectProjectsIsLoading(false));
      dispatch(setAwsOUSelectProjectsInitialized(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    externalId,
    isAccountIdChanged,
    isExternalIdChanged,
    isRoleArnChanged,
    isValidAccountId,
    roleArn,
  ]);

  return <AWSOrgUnitSelection loadAwsOUNodeData={loadAwsOUNodeData} />;
}
