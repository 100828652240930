import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEnabledFeatures } from "../useEnabledFeatures";

import { Accordion, AccordionPanel, Tab, TabList, TabPanel } from "ui";

import { DefendTab } from "./DefendTab";
import { ManageTab } from "./ManageTab";
import { MonitorTab } from "./MonitorTab";
import { RadarsTab } from "./RadarsTab";

export function ComputeSection() {
  const intl = useIntl();
  const [activeTabId, setActiveTabId] = useState("radars");
  const { isLoading, data: enabledFeatures } = useEnabledFeatures();

  const hasComputeFeaturesEnabled = enabledFeatures?.some((feature) =>
    feature.match(/^compute/),
  );

  if (isLoading || !hasComputeFeaturesEnabled) return null;

  return (
    <Accordion allowNoneOpen allowMultipleOpen defaultOpenPanels={[]}>
      <AccordionPanel
        title={intl.formatMessage({
          defaultMessage: "Compute",
          id: "NBBDEn",
          description: "Assign permissions section header",
        })}
        noPadding
        testId="permission-accordion"
      >
        <TabList
          activeTabId={activeTabId}
          onActiveChange={(id) => setActiveTabId(id)}
        >
          <Tab id="radars">
            <FormattedMessage
              defaultMessage="Radars"
              id="CEGw0u"
              description="Compute permission group tab label"
            />
          </Tab>
          <Tab id="defend">
            <FormattedMessage
              defaultMessage="Defend"
              id="H/JcEK"
              description="Compute permission group tab label"
            />
          </Tab>
          <Tab id="monitor">
            <FormattedMessage
              defaultMessage="Monitor"
              id="fiUJih"
              description="Compute permission group tab label"
            />
          </Tab>
          <Tab id="manage">
            <FormattedMessage
              defaultMessage="Manage"
              id="zt9PBl"
              description="Compute permission group tab label"
            />
          </Tab>
        </TabList>

        <TabPanel hideInactiveTabs activeTabId={activeTabId} forTabId="radars">
          <RadarsTab />
        </TabPanel>

        <TabPanel hideInactiveTabs activeTabId={activeTabId} forTabId="defend">
          <DefendTab />
        </TabPanel>

        <TabPanel hideInactiveTabs activeTabId={activeTabId} forTabId="monitor">
          <MonitorTab />
        </TabPanel>

        <TabPanel hideInactiveTabs activeTabId={activeTabId} forTabId="manage">
          <ManageTab />
        </TabPanel>
      </AccordionPanel>
    </Accordion>
  );
}
