import { v4 as isCidrV4, v6 as isCidrV6 } from "is-cidr";
import { isIPv4, isIPv6 } from "is-ip";
import { type Row } from "react-table";
import { ipv4ToNumber, ipv6ToNumber } from "../../utils/ipToNumber";

import { type Data } from "../../types";

const severities = {
  critical: 5,
  high: 4,
  medium: 3,
  low: 2,
  informational: 1,
};

export function severitySortType<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): -1 | 0 | 1 {
  const keyA = getRowValueByColumnID(rowA, columnId) as keyof typeof severities;
  const keyB = getRowValueByColumnID(rowB, columnId) as keyof typeof severities;

  const a = severities[keyA] || 0;
  const b = severities[keyB] || 0;

  return compareBasic(a, b);
}

export function stringsSortType<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): -1 | 0 | 1 {
  const a = getRowValueByColumnID(rowA, columnId);
  const b = getRowValueByColumnID(rowB, columnId);

  if (typeof a === "string" && typeof b === "string") {
    return compareBasic(a.toLowerCase(), b.toLowerCase());
  }

  /* c8 ignore next */
  /* fallback, shouldn't be called */
  return compareBasic(a, b);
}

export function arrayOfStringsSortType<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): -1 | 0 | 1 {
  const a = getRowValueByColumnID<D>(rowA, columnId);
  const b = getRowValueByColumnID<D>(rowB, columnId);

  if (Array.isArray(a) && Array.isArray(b)) {
    return compareBasic(a.join(", ").toLowerCase(), b.join(", ").toLowerCase());
  }

  /* c8 ignore next */
  /* fallback, shouldn't be called */
  return compareBasic(a, b);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function compareBasic(a: any, b: any): -1 | 0 | 1 {
  return a === b ? 0 : a > b ? 1 : -1;
}

export function getRowValueByColumnID<D extends Data>(
  row: Row<D>,
  columnId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
): any {
  return row.values[columnId];
}

export function sortByValueKey(key: string) {
  return function <D extends Data>(
    rowA: Row<D>,
    rowB: Row<D>,
    columnId: string,
  ) {
    const keyA = rowA.values[columnId];
    const keyB = rowB.values[columnId];

    const a = keyA?.[key] ?? 0;
    const b = keyB?.[key] ?? 0;

    return a === b ? 0 : a > b ? 1 : -1;
  };
}

export function secureStatusTooltipSort<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
) {
  const a = getRowValueByColumnID(rowA, "secureStatusTs");
  const b = getRowValueByColumnID(rowB, "secureStatusTs");

  return compareBasic(a, b);
}

export function severity<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): -1 | 0 | 1 {
  const keyA = getRowValueByColumnID(rowA, columnId) as keyof typeof severities;
  const keyB = getRowValueByColumnID(rowB, columnId) as keyof typeof severities;

  const a = severities[keyA] || 0;
  const b = severities[keyB] || 0;

  return compareBasic(a, b);
}

export function strings<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
): -1 | 0 | 1 {
  const a = getRowValueByColumnID(rowA, columnId) as keyof typeof severities;
  const b = getRowValueByColumnID(rowB, columnId) as keyof typeof severities;

  if (typeof a === "string" && typeof b === "string") {
    return compareBasic(a.toLowerCase(), b.toLowerCase());
  }

  return compareBasic(a, b);
}

export function ipAddressSort<D extends Data>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: string,
) {
  const a = getRowValueByColumnID(rowA, columnId) as string | undefined;
  const b = getRowValueByColumnID(rowB, columnId) as string | undefined;

  if (!a) return -1; // keep empty at top
  if (!b) return 1;

  // Sort order is ipv4 sorted first and ipv6 sorted after
  // Ip address in ipv4 and ipv6 format
  if (isIPv4(a) && isIPv4(b)) {
    // both v4s or v6s
    return compareBasic(ipv4ToNumber(a), ipv4ToNumber(b));
  }

  if (isIPv6(a) && isIPv6(b)) {
    return compareBasic(ipv6ToNumber(a), ipv6ToNumber(b));
  }

  // Sort order is ipv4/cidr sorted first and ipv6/cidr sorted after
  // Ip address with cidr in ipv4/cidr and ipv6/cidr format
  if ((isCidrV4(a) && isCidrV4(b)) || (isCidrV6(a) && isCidrV6(b))) {
    // both v4s or v6s with cidr
    const subnetA = a?.split("/")[0];
    const subnetB = b?.split("/")[0];

    if (subnetA === subnetB) {
      // when subnet is same and prefix is different then sort as per prefix
      const prefixA = parseInt(a?.split("/")[1]);
      const prefixB = parseInt(b?.split("/")[1]);

      return compareBasic(prefixA, prefixB);
    }

    if (isCidrV6(a) && isCidrV6(b)) {
      return compareBasic(ipv6ToNumber(subnetA), ipv6ToNumber(subnetB));
    }
    if (isCidrV4(a) && isCidrV4(b)) {
      return compareBasic(ipv4ToNumber(subnetA), ipv4ToNumber(subnetB));
    }
    return 1;
  }

  if ((isIPv4(a) || isCidrV4(a)) && !isIPv4(b)) return -1;

  if (isIPv6(a) && isCidrV6(b)) return -1;

  if ((isIPv4(a) && isIPv6(b)) || (isCidrV4(a) && isCidrV6(b))) return -1;

  return 1;
}

export const sortIgnoreCase = (a = "", b = "") => {
  return String(a)
    .trim()
    .toLowerCase()
    .localeCompare(String(b).trim().toLowerCase());
};
