import { defineMessages } from "react-intl";
import { type SeverityUpperCase } from "requests";

export const severitiesText = defineMessages<SeverityUpperCase>({
  CRITICAL: {
    defaultMessage: "Critical",
    id: "51Ul7S",
    description: "The name of a severity category type",
  },
  HIGH: {
    defaultMessage: "High",
    id: "HFUnXl",
    description: "The name of a severity category type",
  },

  MEDIUM: {
    defaultMessage: "Medium",
    id: "58qTd2",
    description: "The name of a severity category type",
  },

  LOW: {
    defaultMessage: "Low",
    id: "ywV7E8",
    description: "The name of a severity category type",
  },
  INFO: {
    defaultMessage: "Informational",
    id: "OPw9eF",
    description: "The name of a severity category type",
  },
});
