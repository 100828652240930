import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const LaptopCodeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 22 22"}>
      <path
        className="fill-[#707070] dark:fill-white"
        d="M20.125 15.75H3.875C3.52985 15.75 3.25 15.4701 3.25 15.125V3.85738C3.25 3.52191 3.52191 3.25 3.85738 3.25H20.1426C20.4781 3.25 20.75 3.52191 20.75 3.85738V15.125C20.75 15.4701 20.4701 15.75 20.125 15.75ZM14.7969 7.298C14.6539 7.42443 14.6404 7.64285 14.7669 7.78583L16.2818 9.49999L14.7669 11.2141C14.6405 11.3571 14.6539 11.5755 14.7969 11.7019L15.3145 12.1595C15.4576 12.2859 15.6759 12.2724 15.8023 12.1295L17.9249 9.72888C18.0406 9.59819 18.0406 9.4018 17.9249 9.27104L15.8023 6.87045C15.6759 6.72748 15.4576 6.71404 15.3145 6.84046L14.7969 7.298ZM12.9912 4.91092C12.8093 4.85294 12.6149 4.95341 12.557 5.1353L9.90886 13.4449C9.85087 13.6267 9.95127 13.8211 10.1332 13.8791L10.7915 14.089C10.9734 14.147 11.1679 14.0466 11.2258 13.8647L13.8739 5.55507C13.9318 5.37319 13.8314 5.1788 13.6496 5.12081L12.9912 4.91092ZM8.19766 6.87045L6.07509 9.27104C5.95942 9.4018 5.95942 9.5982 6.07509 9.72889L8.19766 12.1295C8.32409 12.2725 8.54244 12.2859 8.68549 12.1595L9.20306 11.7019C9.34611 11.5755 9.35955 11.3572 9.23312 11.2141L7.71815 9.5L9.23312 7.78583C9.35954 7.64285 9.34611 7.42443 9.20306 7.298L8.68549 6.84046C8.54244 6.71405 8.32409 6.72748 8.19766 6.87045Z"
      />
      <path
        className="fill-[#707070] dark:fill-white"
        d="M14.557 17.6369H21.3583C21.7987 17.6369 22.108 18.1635 21.9644 18.6689C21.6106 19.9143 20.645 20.75 19.5599 20.75H4.44011C3.35497 20.75 2.38932 19.9143 2.03555 18.6689C1.89196 18.1635 2.20126 17.6369 2.64162 17.6369H9.4431C9.63131 17.6369 9.79923 17.7551 9.86278 17.9322L10.0994 18.5916C10.163 18.7687 10.3309 18.8869 10.5191 18.8869H13.4809C13.6691 18.8869 13.837 18.7687 13.9005 18.5916L14.1373 17.9322C14.2008 17.755 14.3688 17.6369 14.557 17.6369Z"
      />
    </SvgIcon>
  );
};
