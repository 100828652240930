import { type FC } from "react";
import {
  get,
  useFormContext,
  useWatch,
  type RegisterOptions,
} from "react-hook-form";
import { useIntl } from "react-intl";
import {
  Field,
  Radio,
  RadioCard,
  RadioGroup as ReactRadioGroup,
  type FieldProps,
  type RadioCardProps,
  type RadioProps,
  type RadioGroupProps as ReactRadioGroupProps,
} from "ui";

import { requiredMessage } from "../../messages";

export type RadioGroupProps = {
  /**
   * The name the entered value will be stored under.
   */
  name: string;
  /**
   * A label for the control.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: FieldProps["label"];
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
  /**
   * A list of items to render radios for.
   */
  items: RadioProps[] | RadioCardProps[];
  /**
   * A default value for the RadioGroup
   */
  defaultValue?: string;
} & Omit<FieldProps, "error" | "control"> &
  Partial<ReactRadioGroupProps>;

function isCardItem(obj: RadioGroupProps["items"][0]): obj is RadioCardProps {
  return "title" in obj;
}

/**
 * Group radios together into a field and is connected to the form.
 *
 * All extra props are passed to the RadioGroup wrapping the Radios.
 */
export const RadioGroup: FC<RadioGroupProps> = (props: RadioGroupProps) => {
  const {
    name,
    label,
    items,
    register: registerOptions = {},
    defaultValue,
    description,
    tooltip,
    disabled,
    showLabel,
    ...rest
  } = props;
  const context = useFormContext();
  const intl = useIntl();

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development" && context === null) {
    throw new Error(
      "Attempted to render RadioGroup outside of a Form context. Make sure your component is rendered inside <Form>.",
    );
  }
  const { formState, register } = context;
  const value = useWatch({ name });
  registerOptions.required =
    registerOptions.required ??
    intl.formatMessage(requiredMessage, {
      label,
    });

  const { onBlur, onChange, ...formRegister } = register(name, registerOptions);

  return (
    <Field
      label={label}
      optional={!registerOptions.required}
      control={
        <ReactRadioGroup
          value={defaultValue}
          disabled={disabled}
          {...formRegister}
          {...rest}
          onBlur={(event) => {
            onBlur(event);
            rest.onBlur?.(event);
          }}
          onChange={(event) => {
            onChange(event);
            rest.onChange?.(event);
          }}
        >
          {items.map((item) =>
            isCardItem(item) ? (
              <RadioCard
                key={item.value}
                {...item}
                checked={value === item.value}
              />
            ) : (
              <Radio key={item.value} {...item} />
            ),
          )}
        </ReactRadioGroup>
      }
      error={get(formState.errors, name)?.message}
      description={description}
      tooltip={tooltip}
      disabled={disabled}
      connectLabel={false}
      showLabel={showLabel}
    />
  );
};
