import { createRoute } from "@tanstack/react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCodeEnabled, useUCDMode } from "prisma";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

export const CasInventory = lazy(() => import("./CasInventory"));

export const casInventoryRoute = createRoute({
  getParentRoute: () => inventoryLayoutRoute,
  path: "iac-resources",
  component: CasInventory,
});

export const casInventoryNestedRoute = createRoute({
  getParentRoute: () => casInventoryRoute,
  path: "$",
});

export const useShouldShowCasInventory = () => {
  const selectedUCDMode = useUCDMode();
  const { codeEnabled } = useCodeEnabled();
  const {
    enableApplicationSecurityInventoryTab:
      isApplicationSecurityInventoryEnabled,
  } = useFlags();

  const isApplicationOrCloudSecurityPersona =
    selectedUCDMode === "application" || selectedUCDMode === "cloud";

  return (
    isApplicationSecurityInventoryEnabled &&
    isApplicationOrCloudSecurityPersona &&
    codeEnabled
  );
};
