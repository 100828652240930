import { useMemo, type FC } from "react";
import { FormattedMessage } from "react-intl";
import { Select } from "ui";
import { v4 as uuidV4 } from "uuid";

export interface SelectPageProps {
  disabled?: boolean;
  onSelectPage: (pageSize: number) => void;
  pageIndex: number;
  pageOptions: number[];
}

export const SelectPage: FC<SelectPageProps> = ({
  disabled = false,
  onSelectPage,
  pageIndex,
  pageOptions,
}: SelectPageProps) => {
  const labelId = useMemo((): string => `table-page-${uuidV4()}-label`, []);
  const buttonId = useMemo((): string => `table-page-${uuidV4()}-button`, []);
  const memoizedPageOptions = useMemo(
    () => pageOptions.map((i) => ({ value: i + 1 })),
    [pageOptions],
  );

  return (
    <>
      <label id={labelId} className="flex space-x-4 text-sm leading-loose">
        <FormattedMessage
          defaultMessage="Page"
          id="eQM+RM"
          description="Label for a table page selector"
        />
      </label>
      <Select
        labelId={labelId}
        toggleButtonId={buttonId}
        data-testid="select-page"
        defaultPlacement="top-start"
        disabled={disabled}
        selectedItem={memoizedPageOptions[pageIndex] || null}
        items={memoizedPageOptions}
        onChange={({ selectedItem }) => {
          onSelectPage((selectedItem?.value as number) - 1);
        }}
      />
    </>
  );
};
