import { type FilterValue } from "filters";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComparatorType = (a: any, b: any) => boolean;

/**
 *
 * Used to locally filter table date. Pass the result of this to Array.prototype.filter
 *
 * data
 *    .filter(
 *      makeLocalFilter((item) =>
 *        filters["schedule.status"],
 *        item?.target?.scheduleEnabled,
 *        (t, v) => t === v?.toString() ?? "false"
 *      )
 *    )
 *    .filter((item) => makeLocalFilter(filters["cloud.type"], item?.cloudType, anyMatch))
 *
 * @param target: The filter value
 * @param value
 * @param comparator: A function that takes the target value and the object value and returns a boolean.
 * @returns
 */
export function makeLocalFilter(
  target: FilterValue,
  value: FilterValue,
  options: {
    comparator?: ComparatorType;
  },
) {
  // Ignore filter if nothing is selected
  // TODO - to look again filter logic for policies page
  if (target === undefined || (Array.isArray(target) && target.length === 0))
    return true;

  if (!Array.isArray(target)) return options?.comparator?.(target, value);

  return target.some((t) => options?.comparator?.(t, value));
}
