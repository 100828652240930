import { FormattedMessage } from "react-intl";
import { useGetComputeLimit } from "../../../../hooks";

import { isChina } from "environment";
import { Banner, Body, Link } from "ui";

export default function ComputeLimitBanner() {
  const { data } = useGetComputeLimit({
    options: {
      enabled: !isChina(),
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

  const { limit: computeScanLimit = -1 } = data ?? {};

  return (
    <>
      {Number(computeScanLimit) > 0 ? (
        <Banner appearance="warning" showIcon>
          <Body size="sm" appearance="secondary">
            <FormattedMessage
              defaultMessage="You have reached the compute workload scan threshold limits. Newer accounts are disabled for scanning. <a>Learn more</a> about the workload scans and thresholds. Contact Prisma Cloud support for further assistance."
              id="EqYdFT"
              description="standard info banner text for compute scan limit"
              values={{
                a: (chunks) => (
                  <Link
                    external
                    size="sm"
                    href="https://docs.prismacloud.io/en/enterprise-edition/content-collections/runtime-security/cloud-service-providers/cloud-service-providers"
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Body>
        </Banner>
      ) : null}
    </>
  );
}
