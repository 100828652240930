import { useQuery } from "@tanstack/react-query";
import { cloudAccountsKeys, getComputeLimit } from "requests";

type UseGetComputeLimitProps = {
  accountId?: string;
  options: Record<string, string | boolean>;
};

export function useGetComputeLimit({
  accountId,
  options = {},
}: UseGetComputeLimitProps) {
  const { data, isError, isSuccess, error, isLoading, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.computeLimit({ accountId }),
    queryFn: getComputeLimit,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isSuccess,
    isLoading: isLoading && isFetching,
  };
}
