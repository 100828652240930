import { useCallback, useEffect, useMemo, useState } from "react";
import {
  awsAccountDataSecurity,
  getCloudTrailData,
  type ApiError,
  type CloudTrailBucketType,
} from "requests";
import { useToastActions } from "stores";
import { Body, type SelectItem } from "ui";
import {
  NextButton,
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import { CONFIGURE_ACCOUNT, CUSTOM_CONFIGURATION } from "../../../../constants";
import { ProductDocumentationLink } from "../../components";
import { getAccountIdFromRoleArn, getWizardStepIndex } from "../../utils";
import {
  addItemIfMissing,
  getExistingOrNew,
  itemize,
} from "./DefineFwScanComponents/utils";

import { useQuery } from "@tanstack/react-query";
import { FormLayout } from "form";
import { LoadingIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIntl } from "react-intl";
import { useIsUnMounted } from "../../../../hooks";
import BucketRow from "./DefineFwScanComponents/BucketRow";
import CloudTrailRow from "./DefineFwScanComponents/CloudTrailRow";
import SNSTopicRow from "./DefineFwScanComponents/SNSTopicRow";
import { fwScanMessages } from "./DefineFwScanComponents/messages";
import ErrorRow from "./DefineFwScanComponents/subcomponents/ErrorRow";

export default function DefineFwScan() {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { activeIndex: index, steps },
    valuesUpdate,
  } = useWizardContext();
  const { simplifiedOnboardingExperience } = useFlags();

  // for errors
  const [showErrorPage, setShowErrorPage] = useState(false);

  const getStepIndex = getWizardStepIndex(steps);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);
  const customConfigStepIndex = getStepIndex(CUSTOM_CONFIGURATION);

  // get values from earlier page
  let externalId = steps[configureAccountIndex]?.values?.externalId;
  let roleArn = steps[configureAccountIndex]?.values?.roleArn;

  if (simplifiedOnboardingExperience) {
    externalId = steps[customConfigStepIndex]?.values?.externalId;
    roleArn = steps[customConfigStepIndex]?.values?.roleArn;
  }
  const accountId = getAccountIdFromRoleArn(roleArn as string) as string;

  // get values from wizard state
  const cloudTrailBucket = steps[index]?.values?.cloudTrailBucket as string;
  const cloudTrailName = steps[index]?.values?.cloudTrailName as string;
  const cloudTrailTopic = steps[index]?.values?.cloudTrailTopic as string;
  const cloudTrailUUID = steps[index]?.values?.cloudTrailUUID as string;

  // for setting values in wizard state via Inputs
  const [wizardCTBucket, setWizardCTBucket] = useState<string | undefined>(
    cloudTrailBucket,
  );
  const [wizardCTName, setWizardCTName] = useState<string | undefined>(
    cloudTrailName,
  );
  const [wizardCTTopic, setWizardCTTopic] = useState<string | undefined>(
    cloudTrailTopic,
  );
  const [wizardCTUUID, setWizardCTUUID] = useState<string | undefined>(
    cloudTrailUUID,
  );

  // items for Selects, arrays of strings
  const [bucketItems, setBucketItems] = useState<SelectItem[] | undefined>(
    undefined,
  );
  const [cloudTrailItems, setCloudTrailItems] = useState<
    SelectItem[] | undefined
  >(undefined);
  const [snsTopicItems, setSnsTopicItems] = useState<SelectItem[] | undefined>(
    undefined,
  );

  const [topicForSelectedCloudTrail, setTopicForSelectedCloudTrail] = useState<
    string | undefined
  >(undefined);

  const selectExisting = intl.formatMessage(fwScanMessages.selectExisting);

  const [topicType, setTopicType] = useState<SelectItem>({
    children: selectExisting,
    value: "Select Existing",
  });
  const [bucketType, setBucketType] = useState<SelectItem>({
    children: selectExisting,
    value: "Select Existing",
  });
  const [cloudTrailType, setCloudTrailType] = useState<SelectItem>({
    children: selectExisting,
    value: "Select Existing",
  });

  // array of objects with name, bucket and SNS topic
  const [cloudTrailObjects, setCloudTrailObjects] = useState<
    CloudTrailBucketType[]
  >([]);

  const { isLoading, data, isError, error } = useQuery({
    queryKey: awsAccountDataSecurity.cloudTrail({
      accountId: accountId as string,
      externalId: externalId as string,
      roleArn: roleArn as string,
    }),
    queryFn: getCloudTrailData,
    enabled: !!(accountId && externalId && roleArn),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isError) return;

    const code = (error as unknown as ApiError)?.status;
    if (code === 400) {
      setShowErrorPage(true);
    } else {
      toast(
        intl.formatMessage({
          defaultMessage: "Error getting CloudTrail data",
          id: "/qMfKu",
          description:
            "Error message for failure to retrieve AWS Onboarding data",
        }),
        { appearance: "warning" },
      );
    }
  }, [isError, error, intl, toast]);

  useEffect(() => {
    if (data) {
      const {
        existingBuckets,
        existingCloudtrails,
        existingSnsTopic,
        hasValidPcdsCloudtrail,
        pcdsCloudtrail,
        storageUUID,
      } = data;

      // store for future steps only if they're not already there (to avoid stomping previous values)
      if (!cloudTrailUUID) setWizardCTUUID(storageUUID);

      // if there is an existingCloudtrails, add any missing buckets or topics
      // NOTE: valid cloud trails may be associated with a bucket not in the existing bucket list
      const bucketItems = hasValidPcdsCloudtrail
        ? itemize(
            addItemIfMissing(
              existingBuckets as string[],
              pcdsCloudtrail?.bucket as string,
            ),
          )
        : itemize(existingBuckets);

      setCloudTrailObjects(existingCloudtrails as CloudTrailBucketType[]);

      const cloudTrailItems = existingCloudtrails?.map(({ name }) => ({
        value: name,
      }));

      const snsTopicItems = itemize(existingSnsTopic);

      // set items for select components
      setBucketItems(bucketItems);
      setCloudTrailItems(cloudTrailItems);
      setSnsTopicItems(snsTopicItems);

      // check if the user has been to this page before and is now navigating back to it
      if (cloudTrailBucket || cloudTrailName) {
        // have to generate bc bucket from existingCloudtrails won't exist in existingBuckets
        const additionalBuckets = existingCloudtrails?.map(
          ({ bucket }) => bucket,
        );

        // same, but for SNS topic and existingSnsTopic
        const additionalSnsTopics = existingCloudtrails?.map(
          ({ snsTopic }) => snsTopic,
        );

        const cloudTrailNames = existingCloudtrails?.map(({ name }) => name);

        if (cloudTrailBucket) {
          setBucketType(
            getExistingOrNew(cloudTrailBucket, [
              ...(existingBuckets as string[]),
              ...(additionalBuckets as string[]),
            ]),
          );
        }
        if (cloudTrailName) {
          setCloudTrailType(
            getExistingOrNew(cloudTrailName, cloudTrailNames as string[]),
          );
        }
        if (cloudTrailTopic) {
          setTopicType(
            getExistingOrNew(cloudTrailTopic, [
              ...(existingSnsTopic as string[]),
              ...(additionalSnsTopics as string[]),
            ]),
          );
        }
      }
      // set selected values if they exist
      else if (hasValidPcdsCloudtrail) {
        const { pcdsCloudtrail } = data;

        // set values in page state
        setWizardCTBucket(pcdsCloudtrail?.bucket);
        setWizardCTName(pcdsCloudtrail?.name);
        setWizardCTTopic(pcdsCloudtrail?.snsTopic);

        // set values for page state
        setTopicForSelectedCloudTrail(pcdsCloudtrail?.snsTopic);
        setTopicType({ value: "Select Existing" });
      }
      // when onboarding new accounts, there can be cases where there are no existing CloudTrails (or buckets or topics)
      else if (!existingCloudtrails?.length) {
        const addNew = intl.formatMessage(fwScanMessages.addNew);

        const addNewItem = { children: addNew, value: "Add New" };
        setCloudTrailType(addNewItem);

        if (!existingBuckets?.length) setBucketType(addNewItem);
        if (!existingSnsTopic?.length) setTopicType(addNewItem);
      }
    }
  }, [
    isError,
    data,
    error,
    intl,
    toast,
    cloudTrailUUID,
    cloudTrailBucket,
    cloudTrailName,
    cloudTrailTopic,
  ]);

  // needed bc an SNS Topic in an existing CloudTrail may not be in the array of topics
  const getSnsTopicItems = useCallback(() => {
    let results = snsTopicItems;

    if (snsTopicItems && topicForSelectedCloudTrail) {
      // don't add the selected topic item if it already exists in the array of items
      const hasDuplicate = snsTopicItems.some(
        ({ value }) => value === topicForSelectedCloudTrail,
      );

      results = hasDuplicate
        ? snsTopicItems
        : [{ value: topicForSelectedCloudTrail }, ...snsTopicItems];
    }

    return results;
  }, [snsTopicItems, topicForSelectedCloudTrail]);

  // setSnsTopicItems(getSnsTopicItems());

  const showClTrailSelect = cloudTrailType?.value === "Select Existing";

  // if user selects an existing CloudTrail, they cannot change the bucket
  const disableBucketRow = cloudTrailType?.value === "Select Existing";

  const showLoader = isLoading || !bucketItems; // there is time bn the API returning and selects being populated

  const isUnMounted = useIsUnMounted();

  const isIncomplete = useMemo(
    () => !wizardCTBucket || !wizardCTName || !wizardCTTopic || !wizardCTUUID,
    [wizardCTBucket, wizardCTName, wizardCTTopic, wizardCTUUID],
  );

  // Save form state to context and save cloudTrail api values on unmount of component
  const saveState = useCallback(() => {
    if (isUnMounted.current) {
      valuesUpdate({
        index,
        values: {
          cloudTrailBucket: wizardCTBucket,
          cloudTrailName: wizardCTName,
          cloudTrailTopic: wizardCTTopic,
          storageUUID: wizardCTUUID,
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardCTBucket, wizardCTName, wizardCTTopic, wizardCTUUID, valuesUpdate]);

  useEffect(() => {
    return () => {
      saveState();
    };
  }, [saveState]);

  return (
    <WizardForm
      actions={
        <>
          <PrevButton />
          <NextButton disabled={isIncomplete} />
        </>
      }
      secondaryFooterContent={
        !simplifiedOnboardingExperience && <ProductDocumentationLink />
      }
    >
      <FormLayout>
        <Body size="sm">{intl.formatMessage(fwScanMessages.intro)}</Body>
        <div className="mt-4">
          {showErrorPage ? (
            <ErrorRow />
          ) : showLoader ? (
            <div>
              <LoadingIcon size="3xl" />
            </div>
          ) : (
            <>
              <Body addClassName="mb-1" size="sm">
                {intl.formatMessage(fwScanMessages.cloudTrail)}
              </Body>
              <CloudTrailRow
                cloudTrailName={wizardCTName}
                cloudTrailObjects={cloudTrailObjects}
                cloudTrailType={cloudTrailType}
                cloudTrailItems={cloudTrailItems}
                setCloudTrailBucket={setWizardCTBucket}
                setCloudTrailName={setWizardCTName}
                setCloudTrailTopic={setWizardCTTopic}
                setCloudTrailType={setCloudTrailType}
                setTopicType={setTopicType}
                setTopicForSelectedCloudTrail={setTopicForSelectedCloudTrail}
                showClTrailSelect={showClTrailSelect}
              />
              <Body size="sm">
                {intl.formatMessage(fwScanMessages.snsTopic)}
              </Body>
              <SNSTopicRow
                cloudTrailTopic={wizardCTTopic}
                setCloudTrailTopic={setWizardCTTopic}
                setTopicType={setTopicType}
                topicItems={getSnsTopicItems()}
                topicType={topicType}
              />
              <Body size="sm">
                {intl.formatMessage(fwScanMessages.bucketForLogFiles)}
              </Body>
              <BucketRow
                accountId={accountId}
                bucketItems={bucketItems}
                cloudTrailBucket={wizardCTBucket}
                disabled={disableBucketRow}
                externalId={externalId as string}
                roleArn={roleArn as string}
                bucketType={bucketType}
                setBucketType={setBucketType}
                setCloudTrailBucket={setWizardCTBucket}
                showClTrailSelect={showClTrailSelect}
              />
            </>
          )}
        </div>
      </FormLayout>
    </WizardForm>
  );
}
