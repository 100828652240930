import { forwardRef, useEffect, useRef } from "react";
import { classNames } from "utils";
import { Tooltip } from "../Tooltip";
import { TruncateText } from "../TruncateText";

import {
  type ComponentPropsWithRef,
  type KeyboardEvent,
  type ReactElement,
  type ReactNode,
  type SyntheticEvent,
} from "react";

export type TabProps = {
  /**
   * The ID of the tab.  This should be associated with
   * its corresponding tabpanel through aria-labelledby
   */
  id: string;
  /**
   * The children become the text on the tab
   */
  children: ReactNode;
  /**
   * Disable selecting the tab
   */
  disabled?: boolean;
  /**
   * A message for a tooltip explaining why this tab is disabled.
   */
  disabledReason?: string;
  /**
   * When true, this is the selected tab
   *
   * Only use this when you want the controlled version of the Tab
   */
  active?: boolean;
  /**
   * Only use this when you want the controlled version of the Tab
   */
  onClick?: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  /**
   * Only use this when you want the controlled version of the Tab
   */
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  /**
   * When true, set focus on this tab
   *
   * Only use this when you want the controlled version of the Tab
   */
  hasFocus?: boolean;
  /**
   * When true, change styles on the tabs
   *
   * Only use this when you want the controlled version of the Tab
   */
  isHeader?: boolean;

  activeBorderColor?: string;
  height?: string;
  width?: string;
} & ButtonProps;

export function Tab({ disabledReason, ...props }: TabProps): ReactElement {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (props.hasFocus) buttonRef?.current?.focus();
  }, [props.hasFocus, buttonRef]);

  const buttonProps = {
    ...props,
    // set tabIndex to zero for focusable if the button should have focus or is active, otherwise set it to -1
    tabIndex: props.hasFocus || props.active ? 0 : -1,
  };

  if (props.disabled && disabledReason) {
    return (
      <Tooltip label={disabledReason}>
        <div>
          <Button {...buttonProps} ref={buttonRef} />
        </div>
      </Tooltip>
    );
  }

  return <Button {...buttonProps} ref={buttonRef} />;
}

type ButtonProps = {
  active?: boolean;
  isHeader?: boolean;
  height?: string;
  width?: string;
  activeBorderColor?: string;
} & Pick<
  ComponentPropsWithRef<"button">,
  "id" | "children" | "disabled" | "onClick" | "onKeyDown" | "tabIndex"
>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      active = false,
      children,
      disabled = false,
      onClick,
      onKeyDown,
      tabIndex,
      isHeader = false,
      activeBorderColor,
      height,
      width,
    }: ButtonProps,
    ref,
  ): ReactElement => {
    const className = classNames(
      isHeader
        ? "ml-2 min-w-[50px] max-w-[15rem] overflow-hidden whitespace-nowrap rounded-t p-2 py-1 text-xs font-bold hover:cursor-pointer hover:bg-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-inset dark:text-dark-bg dark:hover:bg-blue-steel-950"
        : `box-content flex ${
            height ?? "h-8"
          } items-center px-4 text-sm focus:outline-none focus-visible:ring focus-visible:ring-inset ${width} justify-center`,
      disabled
        ? "cursor-not-allowed text-sm text-disabled dark:text-dark-bg-disabled"
        : "hover:bg-blue-100 dark:hover:bg-blue-steel-850",
      active
        ? isHeader
          ? "shrink-0 bg-blue-100 dark:bg-blue-steel-850"
          : `border-b-2 hover:border-b-2 ${
              activeBorderColor ?? "border-blue-600  dark:border-blue-600"
            }`
        : "",
    );

    const labelText = <TruncateText>{children}</TruncateText>;

    return (
      <button
        id={id}
        className={className}
        role="tab"
        aria-selected={active}
        disabled={disabled}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={ref}
        tabIndex={tabIndex}
        type="button"
        style={width ? {} : { maxWidth: "16rem" }}
      >
        {labelText}
      </button>
    );
  },
);
Button.displayName = "Button";
