import { type FilterConfig, type FilterObject } from "../types";

export type DisplayedFilters = string[];

export interface FilterState {
  config: FilterConfig[];
  displayedFilters: DisplayedFilters;
  filters: FilterObject;
  initialized?: boolean;
}

export enum actions {
  init = "init",
  mergeFilters = "mergeFilters",
  replaceFilters = "replaceFilters",
  displayFilter = "displayFilter",
  removeFilter = "removeFilter",
  remove = "remove",
  resetFilters = "resetFilters",
}

export type InitAction = {
  type: actions.init;
  payload: {
    name: string;
    initialFilters: FilterObject;
    defaultState?: {
      filters?: FilterObject;
      displayedFilters?: DisplayedFilters;
    };
    filterConfig: FilterConfig[];
  };
};

export type ReducerActions =
  | InitAction
  | {
      type: actions.mergeFilters;
      payload: { name: string; filters: FilterState["filters"] };
    }
  | {
      type: actions.replaceFilters;
      payload: { name: string; filters: FilterState["filters"] };
    }
  | {
      type: actions.displayFilter;
      payload: {
        name: string;
        filterName: string;
      };
    }
  | {
      type: actions.removeFilter;
      payload: {
        name: string;
        filterName: string;
      };
    }
  | {
      type: actions.resetFilters;
      payload: { name: string };
    }
  | {
      type: actions.remove;
      payload: { name: string };
    };
