import { CompressIcon, ExpandIcon } from "icons";
import { forwardRef } from "react";
import { defineMessage, useIntl } from "react-intl";

import { Button, type ButtonProps } from "../Button";
import { Tooltip } from "../Tooltip";

export interface FullScreenButtonProps extends ButtonProps {
  isFullScreen: boolean;
}

const expandMessage = defineMessage({
  defaultMessage: "View in fullscreen mode",
  id: "zEQdVX",

  description: "Modal header button to make modal size full screen",
});

const collapseMessage = defineMessage({
  defaultMessage: "Exit fullscreen mode",
  id: "if+Jjj",

  description: "Modal header button to exit full screen modal size",
});

/**
 * A button to use for entering/exiting fullscreen size to be used in a ModalHeader.
 */
export const FullScreenButton = forwardRef<
  HTMLButtonElement,
  FullScreenButtonProps
>(({ isFullScreen, ...passThrough }: FullScreenButtonProps, ref) => {
  const intl = useIntl();
  const label = intl.formatMessage(
    isFullScreen ? collapseMessage : expandMessage,
  );

  return (
    <Tooltip label={label}>
      <Button
        aria-label={label}
        appearance="tertiary-clear"
        size="sm"
        isSelected={isFullScreen}
        icon={
          isFullScreen ? <CompressIcon size="sm" /> : <ExpandIcon size="sm" />
        }
        ref={ref}
        {...passThrough}
      />
    </Tooltip>
  );
});

FullScreenButton.displayName = "FullScreenButton";
