import { Body, DynamicFormattedMessage, Tooltip } from "ui";
import {
  EXTERNAL_ACCOUNT,
  SERVICE_ACCOUNT,
} from "../../../../../../../../constants";

import { RadioInput } from "form";
import { InfoTooltipIcon } from "icons";
import { useIntl } from "react-intl";
import { selectAuthenticationTypeMap } from "../../messages";

export default function SelectAuthenticationType(
  { isGcpWorkspace }: { isGcpWorkspace: boolean } = { isGcpWorkspace: false },
) {
  const intl = useIntl();

  return (
    <section>
      <div className="flex items-center space-x-1 text-xs font-bold leading-5">
        <DynamicFormattedMessage
          messageMap={selectAuthenticationTypeMap}
          token="heading"
        />
        <Tooltip
          label={intl.formatMessage(selectAuthenticationTypeMap.tooltip)}
        >
          <span>
            <InfoTooltipIcon
              className="ml-1"
              aria-label={intl.formatMessage(
                selectAuthenticationTypeMap.tooltip,
              )}
            />
          </span>
        </Tooltip>
      </div>

      <div className="ml-4 mt-2.5 flex space-x-8">
        <label
          key="Service Account Key"
          className="flex w-max cursor-pointer items-center space-x-2"
        >
          <RadioInput name="authenticationType" value={SERVICE_ACCOUNT} />
          <Body size="sm">
            <DynamicFormattedMessage
              messageMap={selectAuthenticationTypeMap}
              token="serviceAccountKey"
            />
          </Body>
        </label>
        {!isGcpWorkspace && (
          <label
            key="Workload Identity Federation"
            className="flex w-max cursor-pointer items-center space-x-2"
          >
            <RadioInput name="authenticationType" value={EXTERNAL_ACCOUNT} />
            <Body size="sm">
              <DynamicFormattedMessage
                messageMap={selectAuthenticationTypeMap}
                token="workloadIdentityFederation"
              />
            </Body>
          </label>
        )}
      </div>
    </section>
  );
}
