import { Form, FormLayout, Input, SubmitButton } from "form";
import { EyeIcon, EyeSlashIcon } from "icons";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { parseToken, postResetPassword } from "requests";
import { Body, Button, Card } from "ui";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { isChina } from "environment";
import { PrismaLogoIcon } from "icons";
import { useFormContext } from "react-hook-form";
import { useToastActions } from "stores";
import { regexes } from "utils";
import { AnimatedBackground } from "../../components/AnimatedBackground";
import { CrystalIcon } from "../../components/CrystalIcon";
import { RouterLink } from "../../components/Navigation/RouterLink/RouterLink";
import { CopyrightNotice } from "../login/components/CopyrightNotice";
import { ChinaFooter } from "../requestPasswordReset/ChinaFooter";
import { PasswordCheck } from "./PasswordCheck";

type FormProps = {
  newPassword: string;
};

export const passwordValidators = {
  minLength: (value: string): boolean => value.length >= 8,
  maxLength: (value: string): boolean => value.length <= 30,
  hasSymbols: (value: string): boolean => !!value.match(regexes.symbol),
  noSpaces: (value: string): boolean => !value.includes(" "),
  hasNumber: (value: string): boolean => !!value.match(regexes.hasNumber),
  hasUppercase: (value: string): boolean => !!value.match(regexes.hasUppercase),
  hasLowercase: (value: string): boolean => !!value.match(regexes.hasLowercase),
};

const passwordRules =
  "minlength: 8; maxLength: 30; required: lower; required: upper; required: digit; required: [~`!@#$%^&*()-_+={}[]|:;',.<>?/</>];";

export function NewPassword({ token }: { token: string }) {
  const { username } = parseToken(token);
  const intl = useIntl();
  const { toast } = useToastActions();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { mutateAsync } = useMutation({
    mutationFn: async (values: FormProps) => {
      try {
        await postResetPassword({ newPassword: values.newPassword, token });
        toast(
          intl.formatMessage({
            defaultMessage: "Password change successful.",
            id: "6N3AW6",
            description: "Toast that password was successfully changed",
          }),
        );
        navigate({ to: "/login" });
      } catch (error: unknown) {
        const { body } = error as {
          body: {
            message: string;
          };
        };
        if (body?.message === "invalid_new_password") {
          toast(
            intl.formatMessage({
              defaultMessage:
                "New password does not meet password requirements.",
              id: "04WAKy",
              description: "New password submit request failed toast message",
            }),
            { appearance: "error" },
          );
        } else {
          toast(
            intl.formatMessage({
              defaultMessage:
                "Something went wrong changing your password. Please try again.",
              id: "5kwBGX",
              description: "Error message on failed request",
            }),
            { appearance: "error" },
          );
        }
      }
    },
  });

  const handleSubmit = useCallback(
    (values: FormProps) => mutateAsync(values),
    [mutateAsync],
  );

  return (
    <div className="dark flex min-h-screen flex-col bg-white bg-auto bg-top bg-no-repeat text-sm text-default dark:bg-blue-steel-950 dark:text-dark-bg">
      <AnimatedBackground />
      <div className="relative z-10 mx-auto w-96 grow pb-4 pt-28 dark:text-dark-bg">
        <div className="flex justify-center">
          <RouterLink className="mx-auto mb-10 cursor-pointer" to="/login">
            <PrismaLogoIcon size="7xl" color="white" />
          </RouterLink>
        </div>
        <div className="absolute left-0 top-0 -z-10 -ml-32 mt-48">
          <CrystalIcon theme="theme-robinblue" width="640px" height="500px" />
        </div>
        <Card addClassName="p-10" shadow="md" appearance="glass">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{ username, newPassword: "" }}
          >
            <FormLayout>
              <Input
                name="username"
                label={intl.formatMessage({
                  defaultMessage: "Email",
                  id: "shlTMm",
                  description: "input label",
                })}
                readOnly
              />

              <Input
                name="newPassword"
                label={intl.formatMessage({
                  defaultMessage: "New Password",
                  id: "cMFlOp",
                  description: "input label",
                })}
                register={{ validate: passwordValidators }}
                type={showPassword ? "text" : "password"}
                // @ts-expect-error is used by password managers to generate passwords
                passwordrules={passwordRules}
                suffix={
                  <Button
                    aria-label={intl.formatMessage({
                      defaultMessage: "Show/Hide password",
                      id: "yBsLEC",
                      description: "button to show or hide password on screen",
                    })}
                    icon={showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                    appearance="tertiary-clear"
                    size="sm"
                    onClick={() => setShowPassword((b) => !b)}
                  />
                }
              />

              <PasswordConfirm />
              <PasswordCheck />

              <SubmitButton fullWidth centerText>
                <FormattedMessage
                  defaultMessage="Change Password"
                  id="3HrH05"
                  description="Submit button for setting new password"
                />
              </SubmitButton>
            </FormLayout>
          </Form>
        </Card>
      </div>
      <div className="absolute bottom-0 z-[2] w-full bg-gray-100 p-3 text-center dark:bg-[#23424D]">
        <Body appearance="secondary" size="sm">
          <CopyrightNotice />
        </Body>
        {isChina() && <ChinaFooter />}
      </div>
    </div>
  );
}

function PasswordConfirm() {
  const [showPassword, setShowPassword] = useState(false);
  const { getValues } = useFormContext();
  const intl = useIntl();

  return (
    <Input
      name="newPasswordConfirm"
      label={intl.formatMessage({
        defaultMessage: "Confirm New Password",
        id: "0wr8go",
        description: "input label",
      })}
      register={{
        validate: {
          matches: (value: string) => {
            if (getValues().newPassword === value) return;
            return intl.formatMessage({
              defaultMessage: "Passwords must match.",
              id: "V7gzJM",
              description:
                "Error message when password does not match confirmation",
            });
          },
        },
      }}
      // @ts-expect-error is used by password managers to generate passwords
      passwordrules={passwordRules}
      type={showPassword ? "text" : "password"}
      suffix={
        <Button
          aria-label={intl.formatMessage({
            defaultMessage: "Show/Hide password",
            id: "yBsLEC",
            description: "button to show or hide password on screen",
          })}
          icon={showPassword ? <EyeIcon /> : <EyeSlashIcon />}
          appearance="tertiary-clear"
          size="sm"
          onClick={() => setShowPassword((b) => !b)}
        />
      }
    />
  );
}
