import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import {
  BCRepositoriesResponseSchema,
  CloudGroupAllNamesResponseSchema,
  CreateUserRoleItemSchema,
  ResourceListAllNamesResponseSchema,
  UpdateRolesRequestSchema,
  UserRoleItemSchema,
  UserRoleNameResponseSchema,
  UserRoleResponseSchema,
  type UserRoleItemType,
} from "./schemas";

export const rolesKeys = {
  all: [{ scope: "roles" }] as const,
  userRole: () => [{ ...rolesKeys.all[0], entity: "userRole" }] as const,
  userRoleInfo: (id: string) =>
    [{ ...rolesKeys.all[0], id, entity: "userRole" }] as const,
  cloudGroupAllNames: () =>
    [{ ...rolesKeys.all[0], entity: "cloudGroupAllNames" }] as const,
  resourceListAllNames: () =>
    [{ ...rolesKeys.all[0], entity: "resourceListAllNames" }] as const,
  bcRepositories: () =>
    [{ ...rolesKeys.all[0], entity: "bcRepositories" }] as const,
  userRoleName: () =>
    [{ ...rolesKeys.all[0], entity: "userRoleName" }] as const,
  userRoleLimit: () =>
    [{ ...rolesKeys.all[0], entity: "userRoleLimit" }] as const,
} as const;

export const getUserRole = async (
  _props: QueryFunctionContext<ReturnType<(typeof rolesKeys)["userRole"]>>,
) => {
  return jsonApi({
    path: "user/role",
    requestSchema: z.void(),
    responseSchema: UserRoleResponseSchema,
  });
};

export const getUserRoleInfo = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof rolesKeys)["userRoleInfo"]>>) => {
  return jsonApi({
    path: `user/role/${id}`,
    requestSchema: z.void(),
    responseSchema: UserRoleItemSchema,
  });
};

export const createUserRole = async (
  userRole: z.infer<typeof CreateUserRoleItemSchema>,
) => {
  return jsonApi({
    path: `user/role`,
    body: userRole,
    config: {
      method: "POST",
    },
    requestSchema: CreateUserRoleItemSchema,
    responseSchema: CreateUserRoleItemSchema,
  });
};

export const updateUserRole = async (
  id: string,
  userRole: UserRoleItemType,
) => {
  return jsonApi({
    path: `user/role/${id}`,
    body: userRole,
    config: {
      method: "PUT",
    },
    requestSchema: UpdateRolesRequestSchema,
    responseSchema: z.string(),
  });
};

export const deleteUserRole = async (id: string) => {
  return jsonApi({
    path: `user/role/${id}`,
    config: {
      method: "DELETE",
    },
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};

export const getCloudGroupAllNames = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof rolesKeys)["cloudGroupAllNames"]>
  >,
) => {
  return jsonApi({
    path: "entitlement/cloud/group/all_names",
    requestSchema: z.void(),
    responseSchema: CloudGroupAllNamesResponseSchema,
  });
};
export const getResourceListAllNames = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof rolesKeys)["resourceListAllNames"]>
  >,
) => {
  return jsonApi({
    path: "entitlement/v1/resource_list/all_names",
    requestSchema: z.void(),
    responseSchema: ResourceListAllNamesResponseSchema,
  });
};

export const getBCRepositories = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof rolesKeys)["bcRepositories"]>
  >,
) => {
  return jsonApi({
    path: "bridgecrew/api/v1/repositories",
    requestSchema: z.void(),
    responseSchema: BCRepositoriesResponseSchema,
  });
};

export const getUserRoleName = async () => {
  return jsonApi({
    path: "user/role/name",
    requestSchema: z.void(),
    responseSchema: UserRoleNameResponseSchema,
  });
};

export const getUserRoleLimit = async () => {
  return jsonApi({
    path: "user/role/limit",
    requestSchema: z.void(),
    responseSchema: z.number(),
  });
};
