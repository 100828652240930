import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getProfile, profileKeys } from "requests";

export type Operations = {
  READ?: boolean;
  CREATE?: boolean;
  UPDATE?: boolean;
  DELETE?: boolean;
};

export type OperationName = keyof Operations;

/**
 * Uses the token to determine the current role. Will return extra roles based on permissions.
 */
export function useProfile() {
  const query = useQuery({
    queryKey: profileKeys.list(),
    queryFn: getProfile,
    refetchOnMount: false,
    placeholderData: keepPreviousData,
    staleTime: 3 * 60 * 1000, // 3 minutes
  });

  return query;
}
