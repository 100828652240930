import { createRoute, redirect } from "@tanstack/react-router";
import { lazy } from "react";
import { privateRootRoute } from "../../privateRootRoute";

const ActionPlansLayout = lazy(() =>
  import("./ActionPlansLayout").then((res) => ({
    default: res.ActionPlansLayout,
  })),
);

export const actionPlansLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "action-plans",
  component: ActionPlansLayout,
  beforeLoad: ({ location, search }) => {
    if (location.pathname === "/action-plans") {
      throw redirect({ to: "/action-plans/overview", search });
    }
  },
});

export const actionPlansIndexRoute = createRoute({
  getParentRoute: () => actionPlansLayoutRoute,
  path: "/",
  beforeLoad: ({ search }) => {
    throw redirect({ to: "/action-plans/overview", search });
  },
});
