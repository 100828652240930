import { Form, FormLayout, Input, SubmitButton } from "form";
import { FormattedMessage, useIntl } from "react-intl";
import { getDefaultProxy, postAuthTokenGenerate } from "requests";
import { Body, Card } from "ui";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { isChina } from "environment";
import { PrismaLogoIcon } from "icons";
import { useCallback } from "react";
import { useToastActions } from "stores";
import { regexes } from "utils";
import { AnimatedBackground } from "../../components/AnimatedBackground";
import { CrystalIcon } from "../../components/CrystalIcon";
import { RouterLink } from "../../components/Navigation/RouterLink/RouterLink";
import { CopyrightNotice } from "../login/components/CopyrightNotice";
import { ChinaFooter } from "./ChinaFooter";

export default function RequestPasswordReset() {
  const intl = useIntl();
  const { toast } = useToastActions();
  const navigate = useNavigate();
  const baseurl = getDefaultProxy(false);

  const { mutateAsync } = useMutation({
    mutationFn: async (values: { username: string }) => {
      try {
        await postAuthTokenGenerate({ baseurl, ...values });
        toast(
          intl.formatMessage({
            defaultMessage:
              "An email with a link to finish resetting your password has been sent to you.",
            id: "gy5+1i",
            description: "Toast that password reset was successfully requested",
          }),
          { appearance: "success" },
        );
        navigate({ to: "/login" });
      } catch (error) {
        toast(
          intl.formatMessage({
            defaultMessage:
              "Something went wrong requesting a password reset link. Please try again.",
            id: "2i8y60",
            description: "Error message on failed request",
          }),
          { appearance: "error" },
        );
      }
    },
  });

  const handleSubmit = useCallback(
    (values: { username: string }) => mutateAsync(values),
    [mutateAsync],
  );

  return (
    <div className="dark flex min-h-screen flex-col bg-white bg-auto bg-top bg-no-repeat text-sm text-default dark:bg-blue-steel-950 dark:text-dark-bg">
      <AnimatedBackground />
      <div className="relative z-10 mx-auto w-96 grow pb-4 pt-28 dark:text-dark-bg">
        <div className="flex justify-center">
          <RouterLink className="mx-auto mb-10 cursor-pointer" to="/login">
            <PrismaLogoIcon size="7xl" color="white" />
          </RouterLink>
        </div>
        <div className="absolute left-0 top-0 -z-10 -ml-32 mt-48">
          <CrystalIcon theme="theme-robinblue" width="640px" height="500px" />
        </div>
        <Card addClassName="p-10" shadow="md" appearance="glass">
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <Body size="sm">
                <FormattedMessage
                  defaultMessage="Enter your email address below and we will send you a password reset link."
                  id="NsRAH8"
                  description="Request reset password instructions"
                />
              </Body>

              <Input
                name="username"
                label={intl.formatMessage({
                  defaultMessage: "Email",
                  id: "FTNl8q",
                  description: "Email input label",
                })}
                register={{
                  pattern: {
                    value: regexes.email,
                    message: intl.formatMessage({
                      defaultMessage: "Email must be valid",
                      id: "gZvTch",
                      description: "Email format error on input",
                    }),
                  },
                }}
              />
              <SubmitButton fullWidth centerText>
                <FormattedMessage
                  defaultMessage="Send Link"
                  id="r1BwCn"
                  description="Submit button for requesting reset password link"
                />
              </SubmitButton>
            </FormLayout>
          </Form>
        </Card>
      </div>
      <div className="absolute bottom-0 z-[2] w-full bg-gray-100 p-3 text-center dark:bg-[#23424D]">
        <Body appearance="secondary" size="sm">
          <CopyrightNotice />
        </Body>
      </div>
      {isChina() && <ChinaFooter />}
    </div>
  );
}
