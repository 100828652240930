import { useMemo } from "react";
import { useTokenStore } from "requests";

const rolesWithEditPrivileges = [
  "Cloud Provisioning Admin",
  "Account and Cloud Provisioning Admin",
  "System Admin",
];

export const useCloudAccountEditPrivileges = () => {
  const {
    token: { userRoleTypeName },
  } = useTokenStore();

  return useMemo(() => {
    return rolesWithEditPrivileges.includes(userRoleTypeName);
  }, [userRoleTypeName]);
};
