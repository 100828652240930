import { useQuery } from "@tanstack/react-query";
import { getSuggestions, RqlSearchType, rqlSuggestionKeys } from "requests";

export const rqlConfigFunctionsQueryKey = rqlSuggestionKeys.suggestions({
  query: "config from cloud.resource where api.name = '_' AND json.rule = _",
  searchType: RqlSearchType.config,
});

export function useFetchUnsupportedConfigAttributes() {
  const { data, isLoading } = useQuery({
    queryKey: rqlConfigFunctionsQueryKey,
    queryFn: async () => {
      return await getSuggestions({
        queryKey: rqlConfigFunctionsQueryKey,
      });
    },
    enabled: true,
    staleTime: Infinity,
  });

  return { isLoading, unsupportedConfigAttributes: data?.suggestions };
}
