import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const BoxesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 16 13"}>
      <path d="M11.25 0H15.27C15.67 0 16 0.32996 16 0.72998V11.27C16 11.67 15.67 12 15.27 12H10.5V5.5C10.5 4.95001 10.05 4.5 9.5 4.5H4V0.72998C4 0.32996 4.33002 0 4.72998 0H9.75V1.5C9.75 1.77612 9.97388 2 10.25 2H10.75C11.0261 2 11.25 1.77612 11.25 1.5V0Z" />
      <path d="M5.25 6H8.42267C8.74152 6 9 6.25848 9 6.57733V12.4226C9 12.7415 8.74152 13 8.42267 13H0.57733C0.25848 13 0 12.7415 0 12.4226V6.57733C0 6.25848 0.25848 6 0.57733 6H3.75V7.5C3.75 7.77612 3.97388 8 4.25 8H4.75C5.02612 8 5.25 7.77612 5.25 7.5V6Z" />
    </SvgIcon>
  );
};
