import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const IdentitySecurityModuleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.96 10.525a3.006 3.006 0 0 0-3.387-2.497c-1.646.236-2.966 1.795-2.59 3.787l-1.966.37C6.421 9.03 8.562 6.44 11.29 6.049a5.007 5.007 0 0 1 5.642 4.141c.048.229.728 3.172 4.565 5.187l-.93 1.77c-4.664-2.449-5.516-6.151-5.6-6.589l-.004-.016-.002-.016Zm3.396-2.208-.012-.043 1.924-.548v.004c.153.533.312 1.09.503 1.581.205.526.387.811.506.917l-1.33 1.494c-.5-.444-.82-1.123-1.04-1.685-.225-.58-.406-1.213-.55-1.72Zm-7.348 2.823.99-.142.99-.142v-.004.004l.004.025.026.13c.025.12.07.304.14.538.14.47.383 1.139.792 1.91.817 1.537 2.298 3.485 4.96 5.058l-1.018 1.722c-3.024-1.787-4.745-4.029-5.708-5.842-.48-.904-.771-1.699-.943-2.276a9.004 9.004 0 0 1-.218-.892 3.848 3.848 0 0 1-.01-.059l-.003-.019-.001-.007v-.004Zm-.899 3.544a16.293 16.293 0 0 0 3.644 4.96l-1.362 1.463A18.294 18.294 0 0 1 8.3 15.54l1.808-.855Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.998 3.998a7 7 0 0 0-7 7c0 .122.032.46.076.837.02.175.042.34.059.465l.018.135c.006.038.006.042.002.022l.003.014.002.014c.416 2.19 1.65 5.304 4.148 8.255L7.78 22.032c-2.726-3.22-4.108-6.652-4.587-9.175-.023-.129-.069-.48-.105-.788-.04-.346-.09-.808-.09-1.07a9 9 0 0 1 9-9.001c2.834 0 4.826.983 6.189 2.277l-1.378 1.45c-.99-.94-2.487-1.727-4.811-1.727Z"
    />
  </SvgIcon>
);
