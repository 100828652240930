import { useQuery } from "@tanstack/react-query";

import AWSSecurityCapabilitiesPermissions, {
  AgentbasedWorkloadProtection,
  AgentlessApiDiscovery,
  AgentlessWorkloadScanning,
  DataSecurity,
  IdentitySecurity,
  Misconfigurations,
  Remediation,
  ServerlessFunctionScanning,
  ThreatDetection,
} from "../../../../../components/ReviewStatus/SecurityCapabilitiesPermissions";
import {
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  DATA_SECURITY,
  GET_STARTED,
  REMEDIATION,
  SERVERLESS_SCAN,
} from "../../../../../constants";

import { isChina } from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getDlpStatus,
  tenantKeys,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { useDLPFeature } from "../../../../../../../../utils/useDLPFeatures";
import { useWizardContext } from "../../../../../../components/Wizard";
import {
  type AwsGetStartedStepValuesType,
  type Status,
} from "../../../../../types";
import { getWizardStepIndex } from "../../../utils";
import { getSecurityCapabilitiesData } from "../utils";

type SecurityCapabilities = Exclude<
  SecurityCapabilitiesType,
  | "Cloud Visibility Compliance and Governance"
  | "Identity Security"
  | "Threat Detection"
  | "AWS Guard Duty"
  | "AWS Inspector"
>;
const featuresMap: Record<SecurityCapabilities, string> = {
  [AGENTLESS_SCAN]: "agentless_scanning",
  [AUTO_PROTECT]: "auto_protect",
  [DATA_SECURITY]: "storage_scan",
  [REMEDIATION]: "remediation",
  [SERVERLESS_SCAN]: "serverless_function_scanning",
  [AGENTLESS_API_DISCOVERY]: "agentless_api_discovery",
};

type SecurityCapabilitiesPermissionsPropTypes = {
  accountStatus: {
    id: string;
    name: string;
    statusMessage: {
      message: string;
      staticMessage: boolean;
    };
    status: Status;
  }[];
  features: SecurityCapabilitiesFeatures[];
  isSubscribed: boolean;
  isLoading: boolean;
  isOrg: boolean;
};

export default function SecurityCapabilitiesPermissions({
  accountStatus,
  features,
  isSubscribed,
  isLoading,
  isOrg,
}: SecurityCapabilitiesPermissionsPropTypes) {
  const { agentlessApiDiscovery, cloudScanMode, hubAccountScanType } =
    useFlags();
  const {
    state: { steps },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const getStartedStepIndex = getStepIndex(GET_STARTED);
  const getStartedStepValues = steps[getStartedStepIndex]
    ?.values as AwsGetStartedStepValuesType;
  const dlpHasPermission = useDLPFeature(true);
  const { data: dlpData } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: dlpHasPermission,
  });
  const provisionStatus = dlpData?.status || "provisionNotStarted";
  const dlpReady = provisionStatus === "provisionSuccessful";

  const statusData = accountStatus.map((data) => ({
    ...data,
    message: data?.statusMessage?.message,
  }));
  const disabledFeatures = features
    .filter(({ state }) => state === "disabled")
    .map(({ name, state }) => ({
      name: featuresMap[name as SecurityCapabilities],
      status: state,
    }));

  const {
    misconfigurationsData,
    threatDetectionData,
    agentlessWorkloadScanningData,
    serverlessFunctionScanningData,
    agentBasedWorkloadProtectionData,
    dataSecurityData,
    remediationData,
    agentlessApiDiscoveryData,
  } = getSecurityCapabilitiesData(
    [...statusData, ...disabledFeatures],
    cloudScanMode,
    isOrg,
    getStartedStepValues,
    hubAccountScanType,
  );

  const identitySecurityData = {
    status: (isSubscribed ? "enabled" : "disabled") as Status,
    name: "identitySecurity",
  };

  return (
    <AWSSecurityCapabilitiesPermissions isLoading={isLoading}>
      <Misconfigurations data={misconfigurationsData} />
      {!isChina() ? <IdentitySecurity data={identitySecurityData} /> : null}
      <AgentlessWorkloadScanning data={agentlessWorkloadScanningData} />
      <ThreatDetection data={threatDetectionData} />
      <ServerlessFunctionScanning data={serverlessFunctionScanningData} />
      {agentlessApiDiscovery && (
        <AgentlessApiDiscovery data={agentlessApiDiscoveryData} />
      )}
      <AgentbasedWorkloadProtection data={agentBasedWorkloadProtectionData} />
      {dlpReady && <DataSecurity data={dataSecurityData} />}
      <Remediation data={remediationData} />
    </AWSSecurityCapabilitiesPermissions>
  );
}
