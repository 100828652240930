import {
  CaretDownIcon,
  CaretRightIcon,
  FileIcon,
  FolderIcon,
  FolderOpenIcon,
  LoadingIcon,
} from "icons";

const ExpandClose = <CaretRightIcon size="xs" aria-label="ExpandClose" />;

const ExpandOpen = <CaretDownIcon size="xs" aria-label="ExpandOpen" />;

const Folder = <FolderIcon size="sm" aria-label="Folder" />;

const FolderOpen = <FolderOpenIcon size="sm" aria-label="FolderOpen" />;

const File = <FileIcon size="sm" aria-label="File" />;

const Spinner = <LoadingIcon size="xs" aria-label="Loading" />;

export { ExpandClose, ExpandOpen, File, Folder, FolderOpen, Spinner };
