import { useIntl } from "react-intl";

import { RadioGroup, useWatch } from "form";
import {
  RadioCard as DisabledRadioCard,
  RadioGroup as DisabledRadioGroup,
  Title,
} from "ui";
import { useGetAwsPartitionItems } from "../../../../../hooks";

type DeploymentTypeProps = {
  isEdit: boolean;
};

export default function DeploymentType({ isEdit }: DeploymentTypeProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Deployment Type",
    id: "f4w5cf",
    description: "Description for deployment type label",
  });
  const addClassName =
    "flex space-x-2 [&>label]:flex-1 space-y-0 [&>label]:relative [&>label>div]:h-full [&>label>div>div:last-child>p]:items-start";
  const name = "partitionType";

  const value = useWatch({ name });

  const { awsPartitionItems } = useGetAwsPartitionItems();

  let component;
  if (isEdit) {
    component = (
      <DisabledRadioGroup
        name={name}
        value={value}
        addClassName={addClassName}
        style={{ flexDirection: "row" }}
      >
        {awsPartitionItems?.map(({ description, title, value }) => (
          <DisabledRadioCard
            key={value}
            title={title}
            description={description}
            value={value}
            disabled
          />
        ))}
      </DisabledRadioGroup>
    );
  } else {
    component = (
      <RadioGroup
        addClassName={addClassName}
        disabled={isEdit}
        items={awsPartitionItems}
        label={title}
        name="partitionType"
        register={{
          required: true,
        }}
        showLabel={false}
        style={{ flexDirection: "row" }}
      />
    );
  }

  return (
    <section className="space-y-2">
      <Title level={5} size="xxs">
        {title}
      </Title>
      <div className="-ml-2">{component}</div>
    </section>
  );
}
