import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { dataSettingsLayoutRoute } from "../dataSettingsLayoutRoute";

const DataProfilesTable = lazy(() => import("./DataProfilesTable"));

export const dataProfilesLayoutRoute = new Route({
  getParentRoute: () => dataSettingsLayoutRoute,
  path: "dataProfiles",
  component: DataProfilesTable,
});
