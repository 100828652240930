import { z } from "zod";
import { TimeRangeSchema } from "../../../schemas";

export const cnsNodeData = z.object({
  APIID: z.number().optional(),
  ID: z.string(),
  RRN: z.string().optional(),
  VPCID: z.string().optional(),
  accountId: z.string().optional(),
  annotations: z.any(),
  associatedTags: z.any(),
  cloudTags: z.array(z.string()).optional(),
  cloudType: z.string(),
  createTime: z.string().nullable().optional(),
  name: z.string().optional(),
  namespace: z.string(),
  nativeID: z.string(),
  normalizedTags: z.any(),
  protected: z.boolean(),
  regionName: z.string().optional(),
  type: z.string(),
  unifiedAssetID: z.string().optional(),
  updateTime: z.any(),
  network: z.any().optional(),
  subType: z.string().optional(),
  parameters: z
    .object({
      serviceName: z.string().optional(),
      imageID: z.string().optional(),
      productInfo: z.string().optional(),
    })
    .optional(),
});

export const CnsNodeSchema = z.object({
  nodeData: cnsNodeData,
  policy: z
    .object({
      action: z.string().optional(),
    })
    .optional(),
  routeTableIDs: z.array(z.string()).optional(),
  type: z.string().optional(),
});

export const RqlCnsRowSchema = z.object({
  source: cnsNodeData,
  destination: cnsNodeData,
  query: z.record(z.string(), CnsNodeSchema),
  graph: z.object({
    nodes: z.record(z.string(), CnsNodeSchema),
  }),
  verdict: z.string().optional(),
});

export const CnsGraphSchema = z.object({
  nodes: z.record(z.string(), CnsNodeSchema),
  sourceDestinationMap: z.record(
    z.string(),
    z.record(
      z.string(),
      z.object({
        isIndirect: z.boolean(),
        reachable: z.boolean(),
        type: z.string(),
        verdict: z.string(),
      }),
    ),
  ),
});

export const CnsSearchResponseSchema = z.object({
  data: CnsGraphSchema,
  cloudType: z.string().optional(),
  parsedQuery: z.object({
    sourceSelector: z.record(z.any()),
    destinationSelector: z.record(z.any()),
  }),
  id: z.string(),
  limit: z.number().optional(),
  query: z.string(),
  searchType: z.string(),
  timeRange: TimeRangeSchema.optional(),
  sourceDestinationMap: z.record(z.string(), z.any()).optional(),
});

export const CnsCloudGraphRequest = z.object({
  ModelVersion: z.number().optional(),
  addressMatchCriteria: z.string().optional(),
  alertOn: z.string().optional(),
  sourceIP: z.string().optional(),
  sourceSelector: z
    .object({
      ModelVersion: z.number(),
      resourceType: z.string(),
    })
    .optional(),
  effectiveAction: z.string().optional(),
  name: z.string().optional(),
  rawRQL: z.string().optional(),
  destinationSelector: z
    .object({
      ModelVersion: z.number().optional(),
      cloudTypes: z.array(z.string()).optional(),
      objectIDs: z.array(z.string()).optional(),
      resourceType: z.string().optional(),
    })
    .optional(),
  type: z.string().optional(),
  baseUrl: z.string().optional(),
  namespace: z.string().optional(),
});

export const CnsCloudGraphsPathsSchema = z.array(
  z.array(
    z.object({
      nativeID: z.string().optional(),
      policy: z.object({ action: z.string(), policyID: z.string() }).optional(),
      routeTables: z.object({}).optional(),
    }),
  ),
);

export const CnsCloudGraphsResponseSchema = z.object({
  internalEdges: z.object({}).optional(),
  nodes: z.record(z.string(), CnsNodeSchema),
  paths: CnsCloudGraphsPathsSchema,
  publicEdges: z.object({}).optional(),
  query: z.object({
    ID: z.string().optional(),
    addressMatchCriteria: z.string().optional(),
    alertOn: z.string().optional(),
    annotations: z.object({}).optional(),
    associatedTags: z.array(z.string()).optional(),
    description: z.string().optional(),
    destinationIP: z.string().optional(),
    destinationSelector: z
      .object({
        VPCIDs: z.array(z.string()).optional(),
        accountIDs: z.array(z.string()).optional(),
        cloudTypes: z.array(z.string()).optional(),
        imageIDs: z.array(z.string()).optional(),
        objectIDs: z.array(z.string()).optional(),
        paasTypes: z.array(z.string()).optional(),
        regions: z.array(z.string()).optional(),
        resourceType: z.string(),
        securityTags: z.array(z.string()).optional(),
        serviceNames: z.array(z.string()).optional(),
        serviceOwners: z.array(z.string()).optional(),
        serviceTypes: z.array(z.string()).optional(),
        subnets: z.array(z.string()).optional(),
        tags: z.array(z.string()).optional(),
        ModelVersion: z.number().optional(),
      })
      .optional(),
    effectiveAction: z.string().optional(),
    excludeEnterpriseIPs: z.boolean().optional(),
    excludedNetworks: z.array(z.string()).optional(),
    ModelVersion: z.number().optional(),
    name: z.string().optional(),
    namespace: z.string().optional(),
    normalizedTags: z.array(z.string()).optional(),
    protected: z.boolean().optional(),
    protocolPorts: z.array(z.string()).optional(),
    rawRQL: z.string().optional(),
    sourceIP: z.string().optional(),
    sourceSelector: z
      .object({
        VPCIDs: z.array(z.string()).optional(),
        accountIDs: z.array(z.string()).optional(),
        cloudTypes: z.array(z.string()).optional(),
        imageIDs: z.array(z.string()).optional(),
        objectIDs: z.array(z.string()).optional(),
        paasTypes: z.array(z.string()).optional(),
        regions: z.array(z.string()).optional(),
        resourceType: z.string(),
        securityTags: z.array(z.string()).optional(),
        serviceNames: z.array(z.string()).optional(),
        serviceOwners: z.array(z.string()).optional(),
        serviceTypes: z.array(z.string()).optional(),
        subnets: z.array(z.string()).optional(),
        tags: z.array(z.string()).optional(),
        ModelVersion: z.number().optional(),
      })
      .optional(),
    type: z.string().optional(),
  }),
  sourceDestinationMap: z
    .record(
      z.string(),
      z.record(
        z.string(),
        z.object({
          isIndirect: z.boolean(),
          reachable: z.boolean(),
          type: z.string(),
          verdict: z.string(),
        }),
      ),
    )
    .optional(),
});

export const CnsCloudNodesResponseSchema = z
  .array(
    z.object({
      APIID: z.number().optional(),
      ID: z.string().optional(),
      RRN: z.string().optional(),
      VPCID: z.string().optional(),
      accountId: z.string().optional(),
      annotations: z.object({}).optional(),
      associatedTags: z.array(z.string()).optional(),
      cloudType: z.string().optional(),
      createTime: z.string().optional(),
      customerID: z.number().optional(),
      ingestionTime: z.string().optional(),
      name: z.string().optional(),
      namespace: z.string().optional(),
      normalizedTags: z.array(z.string()).optional(),
      parameters: z.object({
        ModelVersion: z.number().optional(),
        incomingRules: z
          .array(
            z.object({
              action: z.string().optional(),
              object: z.array(z.array(z.string()).optional()).optional(),
              protocolports: z.array(z.string()).optional(),
            }),
          )
          .optional(),
        outgoingRules: z
          .array(
            z.object({
              action: z.string().optional(),
              networks: z.array(z.string()).optional(),
              object: z.array(z.string()).optional(),
              protocolports: z.array(z.string()).optional(),
              storednetworks: z
                .array(
                  z.object({
                    ip: z.string().optional(),
                    mask: z.string().optional(),
                  }),
                )
                .optional(),
            }),
          )
          .optional(),
        subject: z.array(z.array(z.string()).optional()).optional(),
        type: z.string().optional(),
      }),
      protected: z.boolean().optional(),
      regionName: z.string().optional(),
      resourceID: z.number().optional(),
      securityTags: z.array(z.string()).optional(),
      type: z.string().optional(),
      unifiedAssetID: z.string().optional(),
      updateTime: z.string().optional(),
    }),
  )
  .optional();

export const CnsGCPAssetsResponseSchema = z
  .array(
    z.object({
      ID: z.string().optional(),
      createTime: z.string().optional(),
      data: z.string().optional(),
      denormedFields: z.array(z.string()).optional(),
      geoScope: z.string().optional(),
      kind: z.string().optional(),
      name: z.string().optional(),
      namespace: z.string().optional(),
      numericID: z.string().optional(),
      prismaRRN: z.string().optional(),
      prismaRegion: z.string().optional(),
      prismaUnifiedAssetID: z.string().optional(),
      projectID: z.string().optional(),
      selflink: z.string().optional(),
      tags: z.any().optional(),
      updateTime: z.string().optional(),
    }),
  )
  .optional();

export const CnsK8sAssetsResponseSchema = z
  .array(
    z.object({
      ID: z.string().optional(),
      accountID: z.string(),
      cloudProvider: z.enum(["AWS", "Azure", "GCP", "Other"]),
      clusterID: z.string(),
      data: z.string(),
      k8sID: z.string(),
      kind: z.enum([
        "Cluster",
        "DaemonSet",
        "Deployment",
        "Endpoints",
        "EndpointSlices",
        "Ingress",
        "Namespace",
        "NetworkPolicy",
        "Node",
        "ReplicaSet",
        "Service",
        "StatefulSet",
      ]),
      uid: z.string().optional(),
    }),
  )
  .optional();

export const SearchCnsAlertRequestSchema = z.object({
  alertID: z.string(),
  policyID: z.string(),
});

export const SearchCnsAlertResponseSchema = z.object({
  alertID: z.string(),
  policyID: z.string(),
  query: z.string(),
  valid: z.boolean(),
});

export type CnsGraph = z.infer<typeof CnsGraphSchema>;
export type CnsNode = z.infer<typeof CnsNodeSchema>;
export type CnsSearchResponse = z.infer<typeof CnsSearchResponseSchema>;
export type RqlCnsRowSchemaType = z.infer<typeof RqlCnsRowSchema>;
export type CnsCloudGraphRequestType = z.infer<typeof CnsCloudGraphRequest>;

export const CnsCloudGraphsRecordNodeSchema = z.record(
  z.string(),
  CnsNodeSchema,
);
export type CnsCloudGraphsResponseNodeType = z.infer<
  typeof CnsCloudGraphsRecordNodeSchema
>;

export type cnsNodeDataType = z.infer<typeof cnsNodeData>;
export type CnsCloudGraphsPathsType = z.infer<typeof CnsCloudGraphsPathsSchema>;
