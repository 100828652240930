import { useFormContext } from "react-hook-form";
import { ModalWizardNavHeading, ModalWizardNavItem } from "ui";
import { useFormStatus } from "./useFormStatus";

import { type ReactElement } from "react";
import { type ModalWizardNavItemProps } from "ui";
import { type ModalWizardNavigationHandler } from "./types";

export interface WizardFormNavItemProps
  extends Omit<ModalWizardNavItemProps, "onClick"> {
  onClick: ModalWizardNavigationHandler;
  hasHeader?: boolean;
  headerLabel?: string;
}

export function WizardFormNavItem({
  onClick,
  ...passThrough
}: WizardFormNavItemProps): ReactElement {
  const { getValues } = useFormContext();
  const status = useFormStatus();

  return (
    <>
      {passThrough?.hasHeader && (
        <ModalWizardNavHeading {...{ ...passThrough, status: undefined }}>
          {passThrough.headerLabel}
        </ModalWizardNavHeading>
      )}
      <ModalWizardNavItem
        onClick={() => {
          onClick({ status, values: getValues() });
        }}
        {...passThrough}
      />
    </>
  );
}
