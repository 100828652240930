import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";
import { getValidateOCID } from "../../../../utils";
type TenantOCIDProps = {
  isEdit: boolean;
  accountId: string;
};

export default function TenantOCID({ isEdit, accountId }: TenantOCIDProps) {
  const intl = useIntl();
  useTrimField("accountId");

  return (
    <Input
      data-selector="oci-accountid-input"
      name="accountId"
      label={intl.formatMessage({
        defaultMessage: "Tenant/Root OCID",
        id: "mwbeeX",
        description: "The name of an input field for onboarding an OCI account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "eg - ocid.tenancy.oc..uniqueId",
        id: "KPt3Ue",
        description: "Placeholder for Root OCID",
      })}
      defaultValue={accountId}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Tenant/Root OCID is required",
          id: "Phr9so",
          description: "Error message for field for onboarding a OCI tenant",
        }),
        validate: getValidateOCID(intl),
      }}
      readOnly={isEdit}
      tooltip={intl.formatMessage({
        defaultMessage: "OCID of the Oracle Cloud Infrastructure tenancy",
        id: "sBwNDH",
        description: "Tooltip for Root OCID input field",
      })}
    />
  );
}
