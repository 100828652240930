import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

export const AssetInventoryCloudType = lazy(
  () => import("./AssetInventoryCloudType"),
);

export const assetInventoryCloudTypeRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "cloud/$cloudType/",
  component: AssetInventoryCloudType,
});
