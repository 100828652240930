import {
  NextButton,
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import { TENANT } from "../../../../constants";

import { FormLayout } from "form";
import { useIntl } from "react-intl";
import { type AzureGetStartedStepValuesType } from "../../../../types";
import { ProductDocumentationLink } from "../../components";
import { useAzureSelectMonitoredProjectContext } from "../context/AzureSelectMonitoredProjectContext";
import { handleSubmitAzureOrgUnitSelection } from "../context/AzureSelectMonitoredProjectContext/state/actions";
import { getAzureSelectProjectsIsLoading } from "../context/AzureSelectMonitoredProjectContext/state/getters";
import AccountDetails from "./AccountDetails";

type ConfigureAccountProps = {
  context: {
    closeModal: () => void;
    isEdit: boolean;
  };
};

export default function ConfigureAccount({
  context: { closeModal, isEdit },
}: ConfigureAccountProps) {
  const intl = useIntl();
  const {
    state: { steps },
  } = useWizardContext();

  const {
    state: selectMonitoredProjectsState,
    dispatch: selectMonitoredProjectsDispatch,
  } = useAzureSelectMonitoredProjectContext();

  const isLoading = getAzureSelectProjectsIsLoading(
    selectMonitoredProjectsState,
  );
  const { accountType } = steps[0]?.values as AzureGetStartedStepValuesType;
  const isTenant = accountType === TENANT;

  const onNextBtnClick = () => {
    const isValidMonitoredProjectsLoaded = isTenant
      ? handleSubmitAzureOrgUnitSelection(
          intl,
          selectMonitoredProjectsState,
          selectMonitoredProjectsDispatch,
        )
      : true;
    return isValidMonitoredProjectsLoaded;
  };

  const disabled = isLoading;

  return (
    <WizardForm
      addClassName="[&>div:nth-child(2)]:relative"
      actions={
        <>
          <PrevButton />
          <NextButton disabled={disabled} validateStep={onNextBtnClick} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <AccountDetails closeModal={closeModal} isEdit={isEdit} />
      </FormLayout>
    </WizardForm>
  );
}
