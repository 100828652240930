import { type CSSProperties, type ReactNode } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  type ModalProps,
  type RenderModalProps,
} from "..";

import { FormattedMessage } from "react-intl";
import { Button } from "../../Button";

export type ConfirmationModalProps = {
  disabledPrimary?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  prompt: ReactNode;
  style?: CSSProperties;
  size?: ModalProps["size"];
  title: string;
  isDelete?: boolean;
  confirmationBtnText?: string | null;
  cancelBtnText?: string | null;
};

export default function ConfirmationModal({
  closeModal,
  disabledPrimary = false,
  isOpen,
  onConfirm,
  onCancel,
  prompt,
  style = {},
  size,
  title,
  isDelete = false,
  confirmationBtnText = null,
  cancelBtnText = null,
}: RenderModalProps<ConfirmationModalProps>) {
  const onCloseHandler = onCancel || closeModal;
  return (
    <Modal onClose={onCloseHandler} style={style} isOpen={isOpen} size={size}>
      <ModalHeader enableClose title={title} />
      <ModalBody>{prompt}</ModalBody>
      <ModalFooter>
        <Button data-autofocus onClick={onCloseHandler}>
          {cancelBtnText || (
            <FormattedMessage
              defaultMessage="Cancel"
              id="pr1jUo"
              description="Button label Confirmation Modal"
            />
          )}
        </Button>
        <Button
          appearance={isDelete ? "danger" : "primary"}
          onClick={onConfirm}
          disabled={disabledPrimary}
          data-testid="modal-confirmation-button"
        >
          {confirmationBtnText || (
            <FormattedMessage
              defaultMessage="Confirm"
              id="fLjFma"
              description="Button label Confirmation Modal"
            />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function useConfirmationModal() {
  return useModal(ConfirmationModal);
}
