import { ORGANIZATION, TENANT } from "../../../../../constants";

import { useNavigate } from "@tanstack/react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "ui";
import { computeLayoutRoute } from "../../../../../../../compute/computeLayoutRoute";

type ComputeButtonProps = {
  accountType: string;
  accountId: string;
  disabled: boolean;
  feature: string;
};

export default function ComputeButton({
  accountType,
  accountId,
  disabled,
  feature,
}: ComputeButtonProps) {
  const { serverlessScanRedirection } = useFlags();
  const navigate = useNavigate();

  const gotoCompute = useCallback(() => {
    const searchObj =
      accountType === ORGANIZATION || accountType === TENANT
        ? { computeState: "%2Fmanage%2FcloudAccounts" }
        : feature === "compute-serverless-scan" && !serverlessScanRedirection
          ? {
              computeState:
                "%2Fdefend%2Fvulnerabilities%2Ffunctions%2Ffunctions",
            }
          : {
              state: "manage/cloudAccounts/overview",
              computeState: "%2Fmanage%2FcloudAccounts",
              params: { accountID: accountId, feature },
            };

    navigate({
      to: computeLayoutRoute.id,
      search: searchObj,
    });
  }, [accountId, accountType, feature, navigate, serverlessScanRedirection]);

  return (
    <Button
      data-testid="scan-settings-button"
      onClick={gotoCompute}
      disabled={disabled}
    >
      <FormattedMessage
        defaultMessage="Scan Settings"
        id="a4qgny"
        description="Scan Settings Button text"
      />
    </Button>
  );
}
