import { isEmpty, isNil } from "remeda";
import {
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  DISABLED,
  ENABLED,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
} from "../../../../constants";
import {
  type AwsGetStartedStepValuesType,
  type Status,
} from "../../../../types";

type SecurityCapabilitiesDataProps = {
  message?: string;
  id?: string;
  name: string;
  status: Status;
  statusMessage?: {
    message: string;
    staticMessage: boolean;
  };
  orgSecurityCapabilities?: {
    name: string;
    status: Status;
  }[];
}[];

export function getSecurityCapabilitiesData(
  data: SecurityCapabilitiesDataProps,
  cloudScanMode: boolean,
  isOrg: boolean,
  getStartedStepValues: AwsGetStartedStepValuesType,
  hubAccountScanType?: boolean,
) {
  const {
    securityCapabilities = {},
    orgSecurityCapabilities,
    mode,
    envScanType = "",
    hubAccountIds = "",
  } = (getStartedStepValues ?? {}) as AwsGetStartedStepValuesType;

  const misconfigurationsData = data.filter(({ name }) =>
    ["guard_duty", "inspector", "config", "compute_discovery", "org"].includes(
      name.toLowerCase(),
    ),
  );
  const threatDetectionData = data.filter(({ name }) =>
    ["audit_logs", "vpc_flow"].includes(name.toLowerCase()),
  );
  const agentlessWorkloadScanningData = data.find(
    ({ name }) => name.toLowerCase() === "agentless_scanning",
  );
  const serverlessFunctionScanningData = data.find(
    ({ name }) => name.toLowerCase() === "serverless_function_scanning",
  );
  const agentBasedWorkloadProtectionData = data.find(
    ({ name }) => name.toLowerCase() === "auto_protect",
  );
  const dataSecurityData = data.find(
    ({ name }) => name.toLowerCase() === "storage_scan",
  );
  const remediationData = data.find(
    ({ name }) => name.toLowerCase() === "remediation",
  );
  const agentlessApiDiscoveryData = data.find(
    ({ name }) => name.toLowerCase() === "agentless_api_discovery",
  );

  if (!isEmpty(securityCapabilities) && !isEmpty(orgSecurityCapabilities)) {
    const isServerlessScanningEnabled =
      securityCapabilities[SERVERLESS_SCAN] &&
      !isNil(serverlessFunctionScanningData);
    const isAgentlessApiDiscoveryEnabled =
      securityCapabilities[AGENTLESS_API_DISCOVERY] &&
      !isNil(agentlessApiDiscoveryData);
    const isAgentlessWorkloadScanningEnabled =
      securityCapabilities[AGENTLESS_SCAN] &&
      !isNil(agentlessWorkloadScanningData);

    if (cloudScanMode && isOrg) {
      isServerlessScanningEnabled &&
        Object.assign(serverlessFunctionScanningData, {
          orgSecurityCapabilities: [
            {
              name: "defaultMemberState",
              status:
                securityCapabilities[SERVERLESS_SCAN] &&
                orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
                  ? ENABLED
                  : DISABLED,
            },
          ],
        });

      isAgentlessApiDiscoveryEnabled &&
        Object.assign(agentlessApiDiscoveryData, {
          orgSecurityCapabilities: [
            {
              name: "defaultMemberState",
              status:
                securityCapabilities[AGENTLESS_API_DISCOVERY] &&
                orgSecurityCapabilities[
                  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE
                ]
                  ? ENABLED
                  : DISABLED,
            },
          ],
        });
      isAgentlessWorkloadScanningEnabled &&
        Object.assign(agentlessWorkloadScanningData, {
          orgSecurityCapabilities: [
            {
              name: "defaultMemberState",
              status:
                securityCapabilities[AGENTLESS_SCAN] &&
                orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
                  ? ENABLED
                  : DISABLED,
            },
          ],
        });
    }

    isAgentlessWorkloadScanningEnabled &&
      Object.assign(agentlessWorkloadScanningData, {
        ...agentlessWorkloadScanningData,
        orgSecurityCapabilities: [
          ...(agentlessWorkloadScanningData?.orgSecurityCapabilities ?? []),
          {
            name: "scanMode",
            status:
              hubAccountScanType && mode === "target-scan" ? envScanType : mode,
            statusIcon: true,
          },
          ...(hubAccountScanType &&
          mode === "target-scan" &&
          envScanType === "hub-scan"
            ? [
                {
                  name: "hubAccountIds",
                  status: hubAccountIds.replace(" ", ""),
                  statusIcon: true,
                },
              ]
            : []),
        ],
      });
  }

  return {
    misconfigurationsData,
    threatDetectionData,
    agentlessWorkloadScanningData,
    serverlessFunctionScanningData,
    agentBasedWorkloadProtectionData,
    dataSecurityData,
    remediationData,
    agentlessApiDiscoveryData,
  };
}
