import { useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  AZURE,
  AZURE_GOV,
  CHINA,
  FINANCE,
  GLOBAL_INT,
  GOV_CLOUD,
} from "../../../../../constants";
import { Details } from "../Details";

export function DeploymentType({
  value = "",
  classNames = "",
}: {
  value: string;
  classNames?: string;
}) {
  const deploymentTypeText = useCallback(() => {
    if (value.includes("cn") || value.includes("china")) {
      return CHINA;
    } else if ([AZURE, "az"].includes(value)) {
      return AZURE;
    } else if ([AZURE_GOV, "az-us-gov"].includes(value)) {
      return AZURE_GOV;
    } else if (value.includes("gov")) {
      return GOV_CLOUD;
    } else if (value.includes("fn")) {
      return FINANCE;
    } else {
      return GLOBAL_INT;
    }
  }, [value]);

  const deploymentTypeMessage = defineMessages({
    china: {
      defaultMessage: "China",
      id: "GIGQyB",
      description: "Value for China type",
    },
    gov_cloud: {
      defaultMessage: "GovCloud (US)",
      id: "dLgqXH",
      description: "Value for GovCloud (US) type",
    },
    global: {
      defaultMessage: "Global",
      id: "73p89D",
      description: "Value for Global type",
    },
    azure_gov: {
      defaultMessage: "Government",
      id: "WY1Y5H",
      description: "Value for Government type",
    },
    azure: {
      defaultMessage: "Commercial",
      id: "OZH87y",
      description: "Value for Global type",
    },
    finance: {
      defaultMessage: "Finance",
      id: "aEedIi",
      description: "Value for Global type",
    },
  });

  return (
    <Details
      label={
        <FormattedMessage
          defaultMessage="Deployment Type"
          id="oqdL5e"
          description="Description for Deployment Type on review status"
        />
      }
      classNames={classNames}
    >
      <FormattedMessage {...deploymentTypeMessage[deploymentTypeText()]} />
    </Details>
  );
}
