import { useWizardContext } from "../../../../../../components/Wizard";

import { useIntl } from "react-intl";

import { AccountGroupSelect } from "prisma";
import { IBM } from "../../../../../constants";
import { type IbmConfigureAccountStepValuesType } from "../../../../../types";
import AccountId from "../../../components/AccountId";
import AccountName from "../../../components/AccountName";
import DownloadTerraform from "./DownloadTerraform";
import ServiceApiKey from "./ServiceApiKey";
import ServiceIamId from "./ServiceIamId";

type AccountDetailsProps = {
  closeModal: () => void;
  isEdit: boolean;
};

export default function AccountDetails({
  closeModal,
  isEdit,
}: AccountDetailsProps) {
  const {
    state: { steps },
  } = useWizardContext();
  const intl = useIntl();

  const { groupIds: accountGroupDefaultValue } = steps[1]
    ?.values as IbmConfigureAccountStepValuesType;

  return (
    <section className="space-y-2">
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <AccountId
              isEdit={isEdit}
              tooltip={intl.formatMessage({
                defaultMessage:
                  "Account id from Manage -> Account Settings on IBM Cloud Console.",
                id: "o8miuc",
                description: "Tooltip message for AccountId field",
              })}
              cloudType={IBM}
            />
          </div>
          <div className="w-1/2">
            <AccountName />
          </div>
        </div>

        <DownloadTerraform closeModal={closeModal} isEdit={isEdit} />

        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <ServiceApiKey isEdit={isEdit} />
          </div>
          <div className="w-1/2">
            <ServiceIamId />
          </div>
        </div>

        <AccountGroupSelect
          defaultValue={accountGroupDefaultValue}
          enableMultiSelect={true}
          itemValue="id"
          name="groupIds"
        />
      </div>
    </section>
  );
}
