import { z } from "zod";
import { type Severity } from "../../policies/schemas";

const RqlResponseSuggestions = z
  .array(z.string())
  .or(
    z.array(
      z.object({
        id: z.string().optional(),
        name: z.string(),
        displayName: z.string(),
      }),
    ),
  )
  .or(z.array(z.boolean()));

export const RqlSuggestionResponseSchema = z.object({
  links: z.string().optional(),
  needsOffsetUpdate: z.boolean().optional(),
  offset: z.number().optional(),
  suggestions: RqlResponseSuggestions,
  translate: z.boolean().optional(),
  valid: z.boolean().optional(),
});

// export type RqlSuggestion = z.infer<typeof RqlResponseSuggestion>;

// export type RqlSuggestions = z.infer<typeof RqlResponseSuggestions>;

// export type RqlSuggestionResponse = z.infer<typeof RqlSuggestionResponseSchema>;

export type RqlSuggestion =
  | string
  | {
      findingId?: string;
      name: string;
      displayName: string;
      assetClass?: { id?: string };
      findingTypes?: string[];
      cloudType?: string;
      severity?: Severity;
      description?: string;
      cloudAccountId?: string;
      relationshipDisplayName?: string;
      targetResourceTypeName?: string;
      targetResourceTypeDisplayName?: string;
      value?: string;
    }
  | boolean;

export type RqlSuggestions = Array<
  string | { id?: string; name: string; displayName: string } | boolean
>;

export type RqlSuggestionResponse = {
  links?: string;
  needsOffsetUpdate?: boolean;
  offset?: number;
  suggestions: Array<
    string | { id?: string; name: string; displayName: string } | boolean
  >;
  translate?: boolean;
  valid?: boolean;
};

export const RqlCatalogSuggestionCapabilitiesAttribute = z.object({
  name: z.string(),
  dataType: z.string(),
  values: z.array(z.string()).optional(),
  mutuallyExclusiveTo: z.array(z.string()).optional(),
  operators: z.array(
    z.object({
      operator: z.string(),
      multiselect: z.boolean(),
      suggestible: z.boolean(),
    }),
  ),
});
export type RqlCatalogSuggestionCapabilitiesAttributeType = z.infer<
  typeof RqlCatalogSuggestionCapabilitiesAttribute
>;
export const RqlCatalogSuggestionCapability = z.object({
  name: z.string(),
  attributes: z.array(RqlCatalogSuggestionCapabilitiesAttribute),
});
export type RqlCatalogSuggestionCapabilityType = z.infer<
  typeof RqlCatalogSuggestionCapability
>;
export const RqlCatalogSuggestionSupportedRelationships = z.object({
  sourceResourceTypeName: z.string(),
  relationships: z.array(
    z.object({
      targetResourceTypeName: z.string(),
      targetResourceTypeDisplayName: z.string(),
      relationshipDisplayName: z.string(),
      relationshipTypeId: z.number(),
    }),
  ),
});
export type RqlCatalogSuggestionSupportedRelationshipsType = z.infer<
  typeof RqlCatalogSuggestionSupportedRelationships
>;
export const RqlCatalogSuggestionsResponseSchema = z.object({
  capabilities: z.array(RqlCatalogSuggestionCapability),
  supportedRelationships: z
    .array(RqlCatalogSuggestionSupportedRelationships)
    .optional(),
  exclusiveElements: z.record(z.string(), z.array(z.string())).optional(),
});
export type RqlCatalogSuggestionsResponseType = z.infer<
  typeof RqlCatalogSuggestionsResponseSchema
>;
export const RqlSuggestionRequestSchema = z.object({
  cursor: z.number(),
  query: z.string(),
});

export type RqlSuggestionRequestParams = z.infer<
  typeof RqlSuggestionRequestSchema
>;

export const AssetSuggestionConstraint = z.object({
  lval: z.string(),
  dataType: z.string().optional(),
  operator: z.string(),
  rval: z
    .string()
    .or(z.array(z.string().or(z.number())))
    .optional(),
});

export const AssetSuggestionConstraints = z.array(AssetSuggestionConstraint);

export const AssetSuggestionRequestSchema = z.object({
  field: z.string().optional(),
  constraints: AssetSuggestionConstraints,
});

export type AssetConstraint = z.infer<typeof AssetSuggestionConstraint>;
export type AssetConstraints = z.infer<typeof AssetSuggestionConstraints>;
export type AssetSuggestionParams = z.infer<
  typeof AssetSuggestionRequestSchema
>;
export type NullableAssetSuggestionParams = AssetSuggestionParams | null;

export type RqlSuggestionRequestProps =
  | RqlSuggestionRequestParams
  | AssetSuggestionParams;

export type RqlQuery = string | AssetSuggestionParams;
