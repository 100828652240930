import { type ComputeEvent } from "./types";

/**
 * Publish an event to the compute iframe.
 *
 * For data there are two types:
 *
 * "state" is for communicating navigation changes - either PC to PCC or vice versa.
 *
 * "innerURL" is for communicating the deep link.
 *
 *     publishCompute({
 *       type: "computeNavStateChange",
 *       data: { innerURL: '/radars/serverless' },
 *     })
 */
export function publishCompute(data: ComputeEvent) {
  const element = document.getElementById("computeIFrame");
  if (isIFrame(element)) {
    element.contentWindow?.postMessage(data, "*");
  }
}

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === "IFRAME";

/**
 * Publishes an event to Compute iframe, notifying it that it's time to refresh its auth token
 */
export function publishAuthRefreshToken() {
  publishCompute({
    type: "refreshAuthToken",
    data: {},
  });
}
