import { Navigate, createRoute, rootRoute, useSearch } from "router";
import { AppSecSearchSchema, casProjectsRoute } from "./codeRoute";

export const CasProjectsRedirect = () => {
  const search = useSearch({ from: casProjectsRedirectRoute.id });
  return <Navigate to={casProjectsRoute.id} search={search} replace />;
};

export const casProjectsRedirectRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/projects",
  component: CasProjectsRedirect,
  validateSearch: AppSecSearchSchema,
});
