import {
  cloudTypes,
  type cloudTypesWithOther,
  type policyCloudTypes,
} from "requests";
import { DynamicFormattedMessage, Tooltip } from "ui";
import { cloudTypeAggregatedMessages, cloudTypeMessages } from "./messages";

import { CloudTypeIcon } from "icons";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";

export interface CloudTypeCellProps {
  value?: (typeof cloudTypesWithOther)[number];
}

export function CloudTypeCell({
  value,
}: CloudTypeCellProps): ReactElement | null {
  if (!value) return null;

  if (value === "all") {
    return (
      <DynamicFormattedMessage
        tagName="span"
        messageMap={cloudTypeMessages}
        token="all"
      />
    );
  }

  return (
    <Tooltip
      label={
        <DynamicFormattedMessage messageMap={cloudTypeMessages} token={value} />
      }
    >
      <span className="text-lg">
        <CloudTypeIcon showNativeTooltip={false} type={value} />
      </span>
    </Tooltip>
  );
}

export interface CloudTypeAggregatedCellProps {
  value: Record<(typeof policyCloudTypes)[number], number>;
}

export function CloudTypeAggregatedCell({
  value,
}: CloudTypeAggregatedCellProps): ReactElement {
  const intl = useIntl();

  return (
    <div className="flex flex-col space-y-2">
      {cloudTypes.map((type) => {
        const count: number | undefined = value[type];

        if (!count) return null;

        const label = intl.formatMessage(cloudTypeAggregatedMessages[type], {
          count,
        });

        return (
          <Tooltip label={label} key={type}>
            <span
              aria-label={label}
              className=" flex flex-row space-x-1 whitespace-nowrap"
            >
              <CloudTypeCell value={type} />
              <span>{count}</span>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}
