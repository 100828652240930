import { useMemo, type ElementType, type ReactNode } from "react";
import { type DeepPartial, type FieldValues } from "react-hook-form";
import { omit } from "remeda";
import { createCtx, type ModalWizardNavItemProps } from "ui";
import { useImmer } from "use-immer";
import { type ModalWizardProps, type WizardStep } from "./ModalWizard";

export type StepStatus = {
  visited: boolean;
  status: ModalWizardNavItemProps["status"];
};

export type WizardStepConfig<StepName extends string = string> = Omit<
  WizardStep<StepName>,
  "Component"
> &
  StepStatus;

export type VisitedStep<StepName extends string = string> = Record<
  StepName,
  StepStatus
>;

export type WizardState<
  StepName extends string,
  D extends FieldValues,
  Context extends Record<string, unknown> = Record<string, unknown>,
> = {
  wizardValues?: DeepPartial<D>;
  /**
   * This should only be used internally by the wizard.  Use setValue
   * from useFormContext to manually update form values.  The wizard
   * values will sync on the next form validation.
   *
   * You may want to pass state setters and getters into the wizard's
   * context to persist state outside the form and wizard instead.
   *
   * If you absolutely need to update form wizard manually, then you
   * are responsible for syncing the form data at the same time.
   */
  setWizardValues: React.Dispatch<React.SetStateAction<D | undefined>>;
  isWizardDirty: boolean;
  setIsWizardDirty: React.Dispatch<React.SetStateAction<boolean>>;
  currentStepName: StepName;
  setCurrentStepName: React.Dispatch<React.SetStateAction<StepName>>;
  steps: WizardStepConfig<StepName>[];
  currentStep: WizardStepConfig<StepName>;
  visitedSteps: VisitedStep;
  setVisitedSteps: React.Dispatch<React.SetStateAction<VisitedStep<StepName>>>;
  hideSideNav: boolean;
  hideTitle: boolean;
  context: Context;
};

type WizardProviderProps<
  D extends FieldValues,
  StepName extends string,
  Context extends Record<string, unknown> = Record<string, unknown>,
> = Pick<
  ModalWizardProps<D, StepName, Context>,
  "initialStepName" | "initialValues" | "context" | "hideSideNav" | "hideTitle"
> & {
  children: ReactNode;
  steps: (Omit<WizardStep<StepName>, "Component"> & {
    Component?: ElementType;
  })[];
};

const [useWizardInternal, WizardProviderInner] =
  createCtx<WizardState<string, FieldValues>>();

export const WizardProvider = <
  D extends FieldValues,
  StepName extends string,
  Context extends Record<string, unknown> = Record<string, unknown>,
>({
  children,
  initialStepName,
  initialValues,
  steps,
  hideSideNav = false,
  hideTitle = false,
  context,
}: WizardProviderProps<D, StepName, Context>) => {
  const startingStep = useMemo(() => {
    return (
      (initialStepName && steps.find((s) => s.name === initialStepName)) ??
      steps[0]
    );
  }, [initialStepName, steps]);
  const startingIndex = useMemo(() => {
    return steps.indexOf(startingStep);
  }, [startingStep, steps]);
  const [currentStepName, setCurrentStepName] = useImmer(startingStep.name);

  const initialVisitedSteps = useMemo(() => {
    return steps.reduce((accum, step, index) => {
      accum[step.name] = {
        visited: index < startingIndex,
        status: index < startingIndex ? "valid" : "pending",
      };
      return accum;
    }, {} as VisitedStep<StepName>);
  }, [startingIndex, steps]);

  const [visitedSteps, setVisitedSteps] =
    useImmer<VisitedStep>(initialVisitedSteps);

  const stepConfig = steps.map((step) => {
    const history = visitedSteps[step.name];

    return {
      ...omit(step, ["Component"]),
      ...history,
    };
  });

  const currentStep = useMemo(() => {
    const step = steps.find((step) => step.name === currentStepName);
    /* c8 ignore next */
    if (!step) throw new Error(`${currentStepName} is not a valid step name`);

    return { ...step, ...visitedSteps[currentStepName] };
  }, [currentStepName, steps, visitedSteps]);

  const [wizardValues, setWizardValues] = useImmer(initialValues);
  const [isWizardDirty, setIsWizardDirty] = useImmer(false);

  // @ts-expect-error TS says Context could be instantiated with
  // something unrelated to the Context type.
  const WizardContext = useMemo<WizardState<StepName, D, Context>>(() => {
    return {
      wizardValues,
      setWizardValues,
      isWizardDirty,
      hideSideNav,
      hideTitle,
      setIsWizardDirty,
      currentStepName,
      setCurrentStepName,
      steps: stepConfig,
      currentStep: omit(currentStep, ["Component"]),
      visitedSteps,
      setVisitedSteps,
      context,
    };
  }, [
    context,
    hideSideNav,
    hideTitle,
    currentStep,
    currentStepName,
    isWizardDirty,
    setCurrentStepName,
    setIsWizardDirty,
    setVisitedSteps,
    setWizardValues,
    stepConfig,
    visitedSteps,
    wizardValues,
  ]);

  return (
    // @ts-expect-error Typescript is not acknowledging that D and FieldValues
    // are the same type despite D extending FieldValues. Therefore, we are expecting
    // a typescript error here.
    <WizardProviderInner value={WizardContext}>{children}</WizardProviderInner>
  );
};

export const useWizard = <
  StepName extends string,
  D extends FieldValues = FieldValues,
  Context extends Record<string, unknown> = Record<string, unknown>,
>() => useWizardInternal() as unknown as WizardState<StepName, D, Context>;
