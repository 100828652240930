import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { type AccountGroupType } from "..";
import { jsonApi } from "../../apis";
import { TimeRangeSchema, type TimeRange } from "../../schemas";
import {
  CloudTypesWithRepositoriesEnumSchema,
  GetLicenseResponse,
  GetLicenseUsageResponse,
  GetTimeSeriesResponse,
  LicenseConsumptionTableRequestSchema,
  LicenseConsumptionTableResponseSchema,
  OptimizeSubscriptionResponseSchema,
  licenseFileDownloadHelperRequestSchema,
  repositoriesSchema,
  type CloudTypesWithRepositories,
  type LicenseFilterSchema,
  type NewFilterOptions,
} from "./schemas";

export const licenseKeys = {
  all: [{ scope: "license" }] as const,
  license: () => [{ ...licenseKeys.all[0], entity: "license" }] as const,
  licenseTimeSeries: ({
    accountIds,
    cloudTypes,
    accountGroupIds,
    timeRange,
  }: {
    timeRange: TimeRange;
    accountGroupIds: string[];
    accountIds: string[];
    cloudTypes: CloudTypesWithRepositories[];
  }) =>
    [
      {
        ...licenseKeys.all[0],
        entity: "licenseTimeSeries",
        accountIds,
        cloudTypes,
        accountGroupIds,
        timeRange,
      },
    ] as const,
  licenseUsage: ({
    accountIds,
    cloudTypes,
    accountGroupIds,
    timeRange,
  }: {
    timeRange: TimeRange;
    accountGroupIds: string[];
    accountIds: string[];
    cloudTypes: z.infer<typeof CloudTypesWithRepositoriesEnumSchema>[];
  }) =>
    [
      {
        ...licenseKeys.all[0],
        entity: "licenseUsage",
        accountIds,
        cloudTypes,
        accountGroupIds,
        timeRange,
      },
    ] as const,
  licenseTableUsage: (params: {
    payload: z.infer<typeof LicenseFilterSchema>;
    pageParam?: string;
  }) => [{ ...licenseKeys.all[0], entity: "licenseUsage", params }] as const,
  optimizeSubscription: (params: { payload: TimeRange }) =>
    [
      {
        ...licenseKeys.all[0],
        entity: "optimizeSubscription",
        params,
      },
    ] as const,
  repositories: () =>
    [
      {
        ...licenseKeys.all[0],
        entity: "repositories",
      },
    ] as const,
} as const;

export const getLicense = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: QueryFunctionContext<ReturnType<(typeof licenseKeys)["license"]>>,
) => {
  return jsonApi({
    path: "license",
    requestSchema: z.void(),
    responseSchema: GetLicenseResponse,
  });
};

export const GetTimeSeriesRequest = z.object({
  timeRange: TimeRangeSchema,
  accountGroupIds: z.array(z.string()),
  accountIds: z.array(z.string()),
  cloudTypes: z.array(CloudTypesWithRepositoriesEnumSchema),
});

export const getLicenseTimeSeries = async ({
  queryKey: [{ accountIds, cloudTypes, accountGroupIds, timeRange }],
}: QueryFunctionContext<
  ReturnType<(typeof licenseKeys)["licenseTimeSeries"]>
>) => {
  return jsonApi({
    body: { accountIds, cloudTypes, accountGroupIds, timeRange },
    path: "license/api/v2/time_series",
    config: {
      method: "post",
    },
    requestSchema: GetTimeSeriesRequest,
    responseSchema: GetTimeSeriesResponse,
  });
};

export const GetLicenseUsageRequest = z.object({
  timeRange: TimeRangeSchema,
  accountGroupIds: z.array(z.string()),
  accountIds: z.array(z.string()),
  cloudTypes: z.array(CloudTypesWithRepositoriesEnumSchema),
  limit: z.number(),
});

export const getLicenseUsage = async ({
  queryKey: [{ accountIds, cloudTypes, accountGroupIds, timeRange }],
}: QueryFunctionContext<ReturnType<(typeof licenseKeys)["licenseUsage"]>>) => {
  return jsonApi({
    body: { accountIds, cloudTypes, accountGroupIds, timeRange, limit: 300 },
    path: "license/api/v2/usage",
    config: {
      method: "post",
    },
    requestSchema: GetLicenseUsageRequest,
    responseSchema: GetLicenseUsageResponse,
  });
};

export const getLicenseTableUsage = async (params: {
  payload: z.infer<typeof LicenseFilterSchema> & {
    accountGroupIds: (string | AccountGroupType)[];
  };
  pageParam?: string;
}) => {
  const newFilterOptions: { data: NewFilterOptions } = {
    data: JSON.parse(JSON.stringify(params.payload)),
  };
  newFilterOptions.data.limit = 300;
  if (params?.pageParam) {
    newFilterOptions.data.pageToken = params?.pageParam;
  }
  const payloadData = { ...newFilterOptions.data };
  return jsonApi({
    path: "license/api/v2/usage",
    body: payloadData,
    config: {
      method: "post",
    },
    requestSchema: LicenseConsumptionTableRequestSchema,
    responseSchema: LicenseConsumptionTableResponseSchema,
  });
};

export const OptimizeSubscriptionData = async (params: {
  payload: TimeRange;
}) => {
  return jsonApi({
    path: "license/api/v1/optimize_subscription",
    body: params.payload,
    config: {
      method: "POST",
    },
    requestSchema: TimeRangeSchema,
    responseSchema: OptimizeSubscriptionResponseSchema,
  });
};
export const getRepositoriesData = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof licenseKeys)["repositories"]>
  >,
) => {
  return await jsonApi({
    path: "bridgecrew/api/v1/repositories",
    requestSchema: z.void(),
    responseSchema: repositoriesSchema,
  });
};

export const getLicenseFileDownloadData = async ({
  payload,
}: Record<string, unknown>) => {
  return await jsonApi({
    path: "license/api/v1/usage",
    body: payload,
    config: {
      method: "post",
      headers: {
        ["Accept"]: "application/octet-stream",
      },
    },
    requestSchema: licenseFileDownloadHelperRequestSchema,
    responseSchema: z.string(),
  });
};
