import { type FC } from "react";

import { useTableInstance } from "../TableContext";
import { Paginator } from "./Paginator";

export interface ConnectedPaginatorProps {
  /**
   * The options available to set the page size.
   */
  pageSizeOptions?: number[];
}

/**
 * Renders controls for navigating between pages of the table.
 *
 * Pass `enablePagination` to the `TableProvider` to activate.
 *
 * The `pageSize` and `pageIndex` values can be initialized by passing them via `initialState` to the `TableProvider`.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ConnectedPaginator, ConnectedTable, Paginator, TableProvider } from "table";
 * ```
 */
export const ConnectedPaginator: FC<ConnectedPaginatorProps> = ({
  pageSizeOptions,
}: ConnectedPaginatorProps) => {
  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    pageOptions,
    previousPage,
    nextPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTableInstance();

  return (
    <Paginator
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageOptions={pageOptions}
      pageSize={pageSize}
      pageSizeOptions={pageSizeOptions}
      onSelectPage={(pageIndex: number) => gotoPage(pageIndex)}
      onNextPage={nextPage}
      onPreviousPage={previousPage}
      onChangePageSize={(pageSize: number) => setPageSize(pageSize)}
    />
  );
};
