import { isDevelopment, isProduction, isTest } from "environment";
import { regexes } from "utils";
import { useMsspTokenStore, useTokenStore } from "../hooks";
/**
 * Combines URL & Endpoint and ensures only a single slash is inserted between them
 */
export function makeUrl(baseUrl: string, endpoint: string): string {
  if (endpoint[0] === "/") return `${baseUrl}${endpoint}`;
  return `${baseUrl}/${endpoint}`;
}

export function fullUrl(endpoint: string): string {
  try {
    // If valid it contains a base URL
    new URL(endpoint);

    return endpoint;
  } catch {
    if (endpoint[0] === "/") return `${baseApiUrl()}${endpoint}`;
    return `${baseApiUrl()}/${endpoint}`;
  }
}

export const fallbackDevUrl = "https://api-stage.prismacloud.io";
export const fallbackMsspDevUrl =
  "https://apilicedev.k8sdev.prismacloud.io/mssp-portal";

export function baseApiUrl(): string {
  const tokenAPI = useTokenStore.getState().token.iss;

  if (tokenAPI) return tokenAPI;

  const currentHostApi = location.hostname
    .replace(".next", "")
    .replace("next-", "")
    .replace("app", "api");

  return `https://${currentHostApi}`;
}

export function msspBaseApiUrl(): string {
  const tokenAPI = useMsspTokenStore.getState()["mssp-token"].iss;

  if (tokenAPI) return tokenAPI;

  if (isTest()) return "https://localhost";

  return fallbackMsspDevUrl;
}

export const getDefaultMsspProxy = (apiUrl?: string) => {
  const hostname: string = window.location.hostname;

  if (isTest()) return "https://localhost";

  if (
    !isProduction() &&
    apiUrl &&
    apiUrl.includes("prismacloud.io") &&
    regexes.url.test(apiUrl)
  )
    return `${apiUrl}/mssp-portal`;

  const mappedHost = loginMSSPHostsMap[hostname];
  if (mappedHost) return mappedHost;

  return hostname.includes("prismacloud.io")
    ? msspBaseApiUrl()
    : fallbackMsspDevUrl;
};

const loginMSSPHostsMap: Record<string, string> = {
  // DEV URLs:
  "ui-mssp.dev.prismacloud.io":
    "https://apilicedev.k8sdev.prismacloud.io/mssp-portal",
  // STAGE URLs:
  "mssp-preprod.prismacloud.io": "https://mssp-preprod-api.prismacloud.io",
  // PROD URLS:
  "mssp.prismacloud.io": "https://mssp-api.prismacloud.io",
};

export const getDefaultProxy = (
  loginProxyEnabled: boolean,
  apiUrl?: string,
) => {
  const hostname: string = window.location.hostname;

  if (
    !isProduction() &&
    apiUrl &&
    apiUrl.includes("prismacloud.io") &&
    regexes.url.test(apiUrl)
  )
    return apiUrl;

  /*
    If login-proxy prefix present, "app" should be used here e.g. https://login-proxy-*app*.prismacloud.io.
    If login-proxy prefix NOT present, "api" should be used, hence the replace logic e.g. https://*api*.prismacloud.io.
  */
  const mappedHost = loginHostsMap[hostname];
  if (mappedHost) return mappedHost;

  if (loginProxyEnabled && !isProduction()) {
    return `https://login-proxy-${
      isDevelopment() ? "appcoredev.k8sdev.prismacloud.io" : hostname
    }`;
  }

  // falls back to this url if other criteria not followed
  return (window.location.hostname.includes("prismacloud.io") ||
    window.location.hostname.includes("prismacloud.cn")) &&
    window.location.hostname.includes("app")
    ? baseApiUrl()
    : fallbackDevUrl;
};

const loginHostsMap: Record<string, string> = {
  // DEV URLs:
  "us-appcoredev.k8sdev.prismacloud.io":
    "https://login-proxy-appcoredev.k8sdev.prismacloud.io",
  "us-appuai1.k8sdev.prismacloud.io":
    "https://login-proxy-appuai1.k8sdev.prismacloud.io",
  "us-appuai2.k8sdev.prismacloud.io":
    "https://login-proxy-appuai2.k8sdev.prismacloud.io",
  // STAGE URLs:
  "us-app-stage.prismacloud.io": "https://login-proxy-app-stage.prismacloud.io",
  // PROD URLS:
  "app.us.prismacloud.io": "https://login-proxy-app0.prismacloud.io",
  // Temporary prod cdn testing
  "appnext.dev.prismacloud.io": "https://login-proxy-app0.prismacloud.io",
  "app0.next.prismacloud.io": "https://login-proxy-app0.prismacloud.io",
  "demo2.prismacloud.io": "https://api-sam103671.sam.prismacloud.io",
  "private-preview.prismacloud.io": "https://api-sam103671.sam.prismacloud.io",
  "sko-demo.prismacloud.io": "https://api-sam103379.sam.prismacloud.io",
  "darwin-latest.prismacloud.io": "https://api-sam104561.sam.prismacloud.io",
  "ui.dev.prismacloud.io": "https://api-stage.prismacloud.io",
  "pendo.prismacloud.dev": "https://api-stage.prismacloud.io",
  "avengers.prismacloud.dev": "https://apiuai1.k8sdev.prismacloud.io",
  "system-test.prismacloud.io": "https://api-sam106332.sam.prismacloud.io",
};

export const convertComputeParamsToString = (
  params: Record<string, unknown> = {},
): Record<string, string> =>
  Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value?.toString() && { [key]: value.toString() }),
    }),
    {},
  );
