import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { TruncateText } from "../TruncateText";
import { Bold, type BoldProps } from "./Bold";

const sizeVariants = {
  sm: "text-xs leading-normal tracking-wide",
  lg: "text-sm",
};

export type SubtitleProps<
  T = Omit<ComponentPropsWithoutRef<"span">, "className">,
> = BoldProps<T> & {
  /**
   * Add a class name to the element.
   */
  addClassName?: string;
  /**
   * The type of element to render.
   *
   * By default, `Overline` renders an `span` element, but any element type can be used.
   */
  as?: ElementType;
  children: ReactNode;
  size?: keyof typeof sizeVariants;
  /**
   * Truncate text when there is no room.
   *
   * This will add a flex class to the Subtitle, so it will no longer wrap.
   */
  truncate?: boolean;
};

/**
 * A subordinate title that provides additional information about the content on a page or in a section.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Subtitle } from "ui";
 * ```
 */
export function Subtitle<
  T = Omit<ComponentPropsWithoutRef<"span">, "className">,
>({
  addClassName,
  as = "span",
  children,
  size = "sm",
  truncate = false,
  ...passThrough
}: SubtitleProps<T>): ReactElement {
  const className = classNames(
    sizeVariants[size],
    truncate && "flex",
    addClassName,
  );

  return (
    <Bold
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2590 complex union type error
      as={as}
      addClassName={className}
      appearance="semibold"
      {...passThrough}
    >
      {truncate ? <TruncateText>{children}</TruncateText> : children}
    </Bold>
  );
}
