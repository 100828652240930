import { createRoute } from "@tanstack/react-router";
import { isDevelopment } from "environment";
import { lazy } from "react";
import { ComplianceSectionsFiltersSchema } from "requests";
import { z } from "zod";
import { pageTableProviderSchema } from "../../../utils/PageTableProvider";
import { complianceStandardsLayoutRoute } from "../complianceStandardsLayoutRoute";

const ComplianceSections = lazy(() => import("./ComplianceSections"));

export const ComplianceSectionsSearchSchema = z
  .object({
    filters: ComplianceSectionsFiltersSchema.catch((ctx: unknown) => {
      if (isDevelopment()) {
        // eslint-disable-next-line no-console
        console.warn(ctx);
      }
      return {};
    }).optional(),
    // This is needed for breadcrumb to go back to standards
    standards: z
      .object({ viewId: z.optional(z.string().catch("")) })
      .merge(pageTableProviderSchema)
      .optional(),
    // This is needed for breadcrumb to go back to standards
    requirements: pageTableProviderSchema.optional(),
  })
  .merge(pageTableProviderSchema);

export const complianceSectionsRoute = createRoute({
  getParentRoute: () => complianceStandardsLayoutRoute,
  path: "/$standardId/requirements/$requirementId/sections",
  component: ComplianceSections,
  validateSearch: ComplianceSectionsSearchSchema,
});
