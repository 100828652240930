import {
  TableProvider as BaseTableProvider,
  type Context,
  type Data,
  type TableProviderProps,
  type TableState,
} from "table";

import { useSearch } from "@tanstack/react-router";
import { ResetPaginatorOnFilterBarChangeEffect } from "prisma";
import { useCallback, type ReactElement } from "react";
import { type PageName } from "requests";
import { noop } from "utils";
import useTablePreferences from "../useTablePreferences";
import { type PageTableProviderType } from "./pageTableProviderSchema";
import { useOnPaginationChange } from "./useOnPaginationChange";
import { useOnTableFilterChange } from "./useOnTableFilterChange";
import { useStateReducerWithResetPagination } from "./useStateReducerWithResetPagination";

export type PageTableState<D extends Data> = Pick<
  Partial<TableState<D>>,
  | "columnOrder"
  | "hiddenColumns"
  | "globalFilter"
  | "groupBy"
  | "sortBy"
  | "columnResizing"
>;

export type PageTableProviderProps<
  D extends Data,
  C extends Context = Context,
> = TableProviderProps<D, C> & {
  /**
   * The page name to store preferences under
   */
  pageName: PageName;
  /**
   * The table name to store preferences under
   */
  tableName: string;
  /**
   * When enabled, the table's search/filter value will be persisted as query params
   */
  enableFilterQueryParams?: boolean;
  /**
   * When enabled, the table's pagination values will be persisted as query params
   */
  enablePaginationQueryParams?: boolean;
};

/**
 * A `TableProvider` that persists the table state:
 *
 * * Pagination, table filter, groupBy and sortBy to the URL
 * * Column order and hidden columns to the preferences service
 *
 * In general, this should be used for the primary table on the page.
 *
 * This component extends `TableProvider` with some additional functionality.
 *
 * This will store the table sorting, table grouping, column order, size and visibility
 * in preferences. This way on a refresh the table will render the same view.
 *
 * Additionally, enabling enableFilterQueryParams and enablePaginationQueryParams will persist
 * the filter and pagination values in the url as query params.
 *
 * All of the props provided by the `table` `TableProvider` are supported here,
 * with the addition of `pageName` and `tableName` which are required for the table preferences. The combination of these `names` should be
 * unique across the whole application.  And filterBarName of the filter bar that should trigger a page reset when filters change.
 *
 */
export function PageTableProvider<D extends Data, C extends Context = Context>({
  children,
  pageName,
  tableName,
  enableFilterQueryParams = false,
  enablePaginationQueryParams = false,
  ...passThrough
}: PageTableProviderProps<D, C>): ReactElement | null {
  const { onPaginationChange } = useOnPaginationChange();
  const { onTableFilterChange } = useOnTableFilterChange();
  const { stateReducer } = useStateReducerWithResetPagination<D>();
  const search: PageTableProviderType = useSearch({ strict: false });

  // @ts-expect-error typing of feature is a bit funky since it's dynamic based on type P
  const tablePreferences = useTablePreferences(pageName, tableName);

  const onStateChange = useCallback(
    (newState: TableState<D>) => {
      tablePreferences.onStateChange(newState);
      passThrough.onStateChange?.(newState);
    },
    [passThrough, tablePreferences],
  );

  if (tablePreferences.isLoading) return null;

  const defaultPageSize = passThrough.initialState?.pageSize || 25;

  return (
    <BaseTableProvider
      {...passThrough}
      onStateChange={onStateChange}
      initialState={{
        ...passThrough.initialState,
        ...(tablePreferences.initialState as TableState<D>),
        globalFilter:
          search.tableFilter ?? passThrough.initialState?.globalFilter,
        pageIndex: search.page?.number
          ? search.page?.number - 1
          : passThrough.initialState?.pageIndex ?? 0,
        pageSize:
          search.page?.size ??
          passThrough.initialState?.pageSize ??
          defaultPageSize,
      }}
      onPaginationChange={
        enablePaginationQueryParams ? onPaginationChange : noop
      }
      onTableFilterChange={enableFilterQueryParams ? onTableFilterChange : noop}
      stateReducer={stateReducer}
      autoResetPage={false}
    >
      <ResetPaginatorOnFilterBarChangeEffect />
      {children}
    </BaseTableProvider>
  );
}
