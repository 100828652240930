import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { InventoryFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";

export const ComputeWorkload = lazy(() =>
  import("./ComputeWorkload").then((res) => ({
    default: res.ComputeWorkload,
  })),
);

export const ComputeWorkloadSearchSchema = z.object({
  filters: InventoryFilterSchema.catch({}).optional(),
});

export const computeWorkloadRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "compute-workload",
  component: ComputeWorkload,
  validateSearch: ComputeWorkloadSearchSchema,
});
