import { useMemo } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type Locales = "en" | "ja" | "zh" | "fr" | "system";

export const defaultLocale = "en";

interface LocaleState {
  selectedLocale?: Locales;
  actions: {
    setLocale: (selectedLocale: Locales) => void;
  };
}

function getBrowserLocale() {
  const browserLocales = navigator.languages;
  if (!browserLocales) {
    return defaultLocale;
  }
  const locale = browserLocales.find((locale) =>
    /^en|ja|zh|fr\b/.test(locale as Locales),
  );
  return (locale?.trim().split(/-|_/)[0] as Locales) ?? defaultLocale;
}

function getDerivedLocale(selectedLocale?: string) {
  return !selectedLocale || selectedLocale === "system"
    ? getBrowserLocale()
    : selectedLocale;
}

function getSelectedLocale(selectedLocale?: string) {
  return !selectedLocale ? getBrowserLocale() : selectedLocale;
}

/**
 * Returns the locale, either from the browser, or local storage, and a function to set the locale in local storage.
 */
const useLocaleStore = create<LocaleState>()(
  persist(
    (set) => ({
      // this is what the user selected, if "system" or undefined, browser settings will be used
      selectedLocale: undefined,
      actions: {
        setLocale: (selectedLocale: Locales) => set({ selectedLocale }),
      },
    }),
    {
      name: "locale",
      // only store selectedLocale in local storage, locale will be derived from that
      // and setLocale in actions is lost without this.
      partialize: (state) => ({ selectedLocale: state.selectedLocale }),
    },
  ),
);

export const useLocale = () => {
  const selectedLocale = useLocaleStore((state) => state.selectedLocale);
  return useMemo(
    () => ({
      selectedLocale: getSelectedLocale(selectedLocale),
      derivedLocale: getDerivedLocale(selectedLocale),
    }),
    [selectedLocale],
  );
};
export const useLocaleActions = () =>
  useLocaleStore((state) => {
    return state.actions;
  });
