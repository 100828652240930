import { forwardRef, type ComponentPropsWithRef, type MouseEvent } from "react";

import { classNames } from "utils";
import { Handle } from "./Handle";

export interface ToggleProps
  extends Omit<ComponentPropsWithRef<"button">, "aria-checked" | "className"> {
  /**
   * Add a class name to the root element.
   */
  addClassName?: string;
  /**
   * The state of the Toggle.
   *
   * Currently, this component only supports the [controlled](https://reactjs.org/docs/forms.html#controlled-components) pattern.
   */
  checked: boolean;
  /**
   * Disables the Toggle.
   */
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Lets the user toggle a setting on or off.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Toggle } from "ui";
 * ```
 */
export const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  (props: ToggleProps, ref) => {
    const {
      addClassName = "",
      checked,
      disabled = false,
      ...passThrough
    } = props;

    return (
      <button
        disabled={disabled}
        ref={ref}
        role="switch"
        type="button"
        {...passThrough}
        aria-checked={checked}
        className={classNames(
          "relative inline-flex h-4 w-8 shrink-0 focus:outline-none focus:ring",
          "rounded-full border-2 border-transparent",
          "ease-in-out motion-safe:transition-colors motion-safe:duration-200",
          !disabled && "cursor-pointer hover:ring",
          !disabled &&
            (checked
              ? "bg-blue-600 dark:bg-blue-400"
              : "bg-gray-600 dark:bg-gray-400"),
          disabled && "cursor-not-allowed bg-gray-400 dark:bg-gray-600",
          addClassName,
        )}
      >
        <Handle checked={checked} />
      </button>
    );
  },
);

Toggle.displayName = "Toggle";
