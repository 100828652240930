import { SecurityCapabilitiesIcon } from "icons";
import { type ReactNode } from "react";
import {
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  DATA_SECURITY,
  IDENTITY_SECURITY,
  MISCONFIGURATIONS,
  REMEDIATION,
  SERVERLESS_SCAN,
  THREAT_DETECTION,
} from "../../../../constants";

import { type SecurityCapabilitiesType } from "requests";
import { Body, Bold } from "ui";

type FeatureTooltipPropTypes = {
  content: ReactNode;
  header: ReactNode;
  id: string;
};

const iconMap: Record<string, SecurityCapabilitiesType> = {
  agentlessScan: AGENTLESS_SCAN,
  autoProtect: AUTO_PROTECT,
  dataSecurity: DATA_SECURITY,
  identitySecurity: IDENTITY_SECURITY,
  misconfigurations: MISCONFIGURATIONS,
  threatDetection: THREAT_DETECTION,
  serverlessScan: SERVERLESS_SCAN,
  remediation: REMEDIATION,
  agentlessApiDiscovery: AGENTLESS_API_DISCOVERY,
};

export default function FeatureTooltip({
  content,
  header,
  id,
}: FeatureTooltipPropTypes) {
  return (
    <div className="m-2 flex space-x-3">
      <span className="flex h-10 w-10 flex-none items-center justify-center rounded-full bg-gray-200 dark:bg-blue-300/[.12]">
        <SecurityCapabilitiesIcon type={iconMap[id]} size="lg" />
      </span>
      <div className="flex flex-col space-y-2">
        <Bold>{header}</Bold>
        <Body size="sm" addClassName="text-dark-bg">
          {content}
        </Body>
      </div>
    </div>
  );
}
