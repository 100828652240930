import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { isTruthy } from "remeda";
import { Bold, CopyButton, TruncateText } from "ui";

type DetailsProps = {
  allowCopy?: boolean;
  classNames?: string;
  children?: ReactNode;
  icon?: ReactNode;
  label?: ReactNode | string;
  rightIcon?: ReactNode;
};

export function Details({
  allowCopy = false,
  children,
  icon,
  label,
  classNames = "",
  rightIcon,
}: DetailsProps) {
  const copy =
    allowCopy && isTruthy(children) && typeof children === "string" ? (
      <CopyButton addClassName="pl-0" text={children} />
    ) : null;

  return (
    <div
      className={`mt-4 flex min-h-[1.25rem] space-x-4 text-xs ${
        classNames || "items-center"
      }`}
      data-testid="details-item"
    >
      <div className="flex w-[35%] items-center space-x-2 break-all px-4">
        {icon && icon}
        <TruncateText>
          <Bold>{label}</Bold>
        </TruncateText>
      </div>
      <div className="flex w-[60%] items-center">
        {copy}
        {rightIcon}
        {isTruthy(children) ? (
          typeof children === "string" ? (
            <TruncateText addClassName="whitespace-pre">
              {children}
            </TruncateText>
          ) : (
            children
          )
        ) : (
          <FormattedMessage
            defaultMessage="N/A"
            id="DJPcRZ"
            description="Label for not applicable"
          />
        )}
      </div>
    </div>
  );
}
