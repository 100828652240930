import { type ListItemProps } from "../List";
import {
  SelectWithoutSearch,
  type SelectWithoutSearchProps,
} from "./SelectWithoutSearch";
import {
  SelectWithSearch,
  type SelectWithSearchProps,
} from "./SelectWithSearch";

export type SelectItemValue =
  | string
  | number
  | boolean
  | Record<string, unknown>;

export interface SelectItem
  extends Omit<
    Partial<ListItemProps>,
    "value" | "style" | "active" | "checked" | "hasCheckbox"
  > {
  value: SelectItemValue;
  /**
   * Extra keys should be passed via context
   */
  context?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type SelectProps =
  | ({
      /**
       * Enable searching on the select items. When enabled the select will handle filtering the provided items.
       *
       * If you need to override how search behaves you may provide an `onSearch` function.
       */
      enableSearch: true;
    } & SelectWithSearchProps)
  | ({
      /**
       * Enable searching on the select items. When enabled the select will handle filtering the provided items.
       *
       * If you need to override how search behaves you may provide an `onSearch` function.
       */
      enableSearch?: false | undefined;
    } & SelectWithoutSearchProps);

/**
 * Lets the user select an item from a list of options.
 * This component can handle multiselect and searching.
 * Depending on if searching is enabled, this component just switches between rendering `SelectWithSearch` and `SelectWithoutSearch`.
 *
 * **Note** it is not recommended to switch searching on and off on the same mounted component. The implementation between selects with searching and without are very different and this could lead to unexpected issues.
 *
 * ### Testing
 *
 * The select component uses react-virtual. Unfortunately the calculation for virtualizing
 * will not work in tests without a mock. If you are opening a select in tests and trying
 * to find options, you should add this code to your test. This will render the first ~9
 * items in your select. If you need more than that you will need to scroll or arrow down the list.
 *
 * ```jsx
 * beforeEach(() => {
 *   jest
 *     .spyOn(window.Element.prototype, "getBoundingClientRect")
 *     .mockImplementation(() => ({ height: 231 }));
 * });
 * ```
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Select } from "ui";
 * ```
 */
export function Select({ enableSearch = false, ...rest }: SelectProps) {
  return (
    <>
      {enableSearch ? (
        <SelectWithSearch {...(rest as SelectWithSearchProps)} />
      ) : (
        <SelectWithoutSearch {...(rest as SelectWithoutSearchProps)} />
      )}
    </>
  );
}
