import { usePermissions, useRole } from "prisma";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  type AssociatedRoles,
  type PermissionGroupData,
  type UserRolesType,
} from "requests";
import {
  TruncateCell,
  makeRelativeTimestampCell,
  stringsSortType,
  type ColumnOptions,
  type RowInstance,
} from "table";
import { dateTimeToString } from "utils";
import { RolesCell } from "../../users/Table/cellRenderers";
import { ActionsCell } from "./cellRenderers/ActionsCell";
import { PermissionGroupNameCell } from "./cellRenderers/PermissionGroupNameCell";

type UsePermissionGroupsColumnsProp = {
  setSelectedPermissionGroupId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

const mapRolesToString = ({
  value: roles,
}: {
  value: AssociatedRoles;
}): string => {
  if (!roles) return "";
  return `${Object.keys(roles)
    .map((id) => roles[id])
    .join(", ")}`;
};

export function usePermissionGroupsColumns({
  setSelectedPermissionGroupId,
}: UsePermissionGroupsColumnsProp): ColumnOptions<PermissionGroupData>[] {
  const intl = useIntl();
  const { syntheticRole } = useRole();
  const isSystemAdmin = syntheticRole === "System Admin";
  const { settingsUserRole } = usePermissions();

  return useMemo(
    () =>
      [
        {
          accessor: "name",
          Cell: ({
            row,
          }: {
            row: Pick<RowInstance<PermissionGroupData>, "original">;
          }) => {
            return PermissionGroupNameCell({
              row,
              setSelectedPermissionGroupId,
            });
          },
          Header: intl.formatMessage({
            defaultMessage: "Name",
            id: "uqMN0J",
            description: "Permission groups table column header",
          }),
          sortType: stringsSortType,
          width: 300,
        },
        {
          accessor: "associatedRoles",
          cellToString: mapRolesToString,
          Cell: ({
            value,
            context,
          }: {
            value?: AssociatedRoles;
            context: { roles: UserRolesType };
            row: RowInstance<PermissionGroupData>;
          }) => {
            if (!value || !Object.keys(value)?.length) return null;
            const mappedValues = Object.keys(value).map((id) => {
              return {
                id,
                name: `${value[id]}`,
              };
            });
            // The PG page is now visible to all users. The table has a row to display
            // roles associated with Permission Groups.The requirement is to display
            // the role name even if the user does not have permission to view the role.
            // Therefore, we are passing the associatedRoles values in the context.
            if (!settingsUserRole?.READ) {
              context.roles = mappedValues as UserRolesType;
            }
            return RolesCell({
              context,
              value: mappedValues,
            });
          },
          Header: intl.formatMessage({
            defaultMessage: "Roles",
            id: "dLEH3I",
            description: "Permission groups table column header",
          }),
          width: 300,
        },
        {
          accessor: "type",
          Header: intl.formatMessage({
            defaultMessage: "Type",
            id: "3fhWbz",
            description: "Permission groups table column header",
          }),
          sortType: "basic",
        },
        {
          accessor: "lastModifiedBy",
          Cell: TruncateCell,
          Header: intl.formatMessage({
            defaultMessage: "Last Modified By",
            id: "zwRPTH",
            description: "Permission groups table column header",
          }),
          sortType: stringsSortType,
        },
        {
          accessor: "lastModifiedTs",
          Cell: makeRelativeTimestampCell(),
          cellToString: dateTimeToString,
          Header: intl.formatMessage({
            defaultMessage: "Last Modified",
            id: "yZz5ux",
            description: "Permission groups table column header",
          }),
          sortType: "basic",
        },
        ...(isSystemAdmin
          ? [
              {
                disableExport: true,
                disableHideColumn: true,
                disableResizing: true,
                Header: intl.formatMessage({
                  defaultMessage: "Actions",
                  id: "hqZXKN",
                  description: "Permission groups table column header",
                }),
                id: "actions",
                sticky: true,
                width: 140,
                Cell: ({ row }: { row: RowInstance<PermissionGroupData> }) => {
                  return ActionsCell({
                    row,
                  });
                },
              },
            ]
          : []),
      ] as ColumnOptions<PermissionGroupData>[],
    [intl, isSystemAdmin, setSelectedPermissionGroupId, settingsUserRole?.READ],
  );
}
