import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const Collections = lazy(() => import("./Collections"));

export const collectionsRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "collections",
  component: Collections,
});
