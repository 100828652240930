import { FilterSchema, TimeRangeSchema, TimeStampSchema } from "../../schemas";
import {
  CloudTypesEnumSchema,
  CloudTypesWithOtherEnumSchema,
} from "../cloudType";

import { z } from "zod";
import { ObjectExposureEnumSchema } from "../alerts";
import { SeverityEnumSchema } from "../policies";

export const InventoryGroupedAggregateSchema = z.object({
  cloudTypeName: CloudTypesWithOtherEnumSchema.optional(),
  serviceName: z.string().optional(),
  accountName: z.string().optional(),
  accountId: z.string().optional(),
  regionName: z.string().optional(),
  failedResources: z.number().nonnegative(),
  passedResources: z.number().nonnegative(),
  totalResources: z.number().nonnegative(),
  highSeverityFailedResources: z.number().nonnegative(),
  mediumSeverityFailedResources: z.number().nonnegative(),
  lowSeverityFailedResources: z.number().nonnegative(),
  criticalSeverityFailedResources: z.number().nonnegative(),
  informationalSeverityFailedResources: z.number().nonnegative(),
  allowDrillDown: z.boolean(),
  criticalVulnerabilityFailedResources: z.number().nonnegative().optional(),
  highVulnerabilityFailedResources: z.number().nonnegative().optional(),
  mediumVulnerabilityFailedResources: z.number().nonnegative().optional(),
  lowVulnerabilityFailedResources: z.number().nonnegative().optional(),
  totalVulnerabilityFailedResources: z.number().nonnegative().optional(),
  resourceTypeName: z.string().optional(),
});

const SummarySchema = z.object({
  timestamp: TimeStampSchema,
  failedResources: z.number().nonnegative(),
  passedResources: z.number().nonnegative(),
  totalResources: z.number().nonnegative(),
  highSeverityFailedResources: z.number().nonnegative(),
  mediumSeverityFailedResources: z.number().nonnegative(),
  lowSeverityFailedResources: z.number().nonnegative(),
  criticalSeverityFailedResources: z.number().nonnegative(),
  informationalSeverityFailedResources: z.number().nonnegative(),
  criticalVulnerabilityFailedResources: z.number().optional(),
  highVulnerabilityFailedResources: z.number().optional(),
  mediumVulnerabilityFailedResources: z.number().optional(),
  lowVulnerabilityFailedResources: z.number().optional(),
  totalVulnerabilityFailedResources: z.number().optional(),
});

export const InventoryResponseSchema = z.object({
  timestamp: TimeStampSchema,
  requestedTimestamp: TimeStampSchema,
  summary: SummarySchema.optional(),
  groupedAggregates: z.array(InventoryGroupedAggregateSchema).optional(),
  nextPageToken: z.string().optional(),
});

export const SummaryResponseSchema = z.object({
  timestamp: TimeStampSchema,
  requestedTimestamp: TimeStampSchema,
  summary: SummarySchema,
});

export type InventoryResponse = z.infer<typeof InventoryResponseSchema>;

export const InventoryTrendSchema = z.array(SummarySchema);

export type InventoryTrendResponse = z.infer<typeof InventoryTrendSchema>;

export const InventoryFilterSchema = z
  .object({
    timeRange: TimeRangeSchema,
    "asset.class": z.array(z.string()),
    "cloud.account": z.array(z.string()),
    "cloud.accountId": z.array(z.string()),
    "cloud.region": z.array(z.string()),
    "cloud.type": z.array(CloudTypesWithOtherEnumSchema),
    "cloud.service": z.array(z.string()),
    "policy.complianceStandard": z.array(z.string()),
    "policy.complianceRequirement": z.array(z.string()),
    "policy.complianceSection": z.array(z.string()),
    "account.group": z.array(z.string()),
    "resource.group": z.array(z.string()),
    "resource.type": z.array(z.string()),
    "asset.severity": z.array(SeverityEnumSchema),
    "vulnerability.severity": z.array(SeverityEnumSchema),
    "resource.tagv2": z.array(z.string()),
    "resourceList.tag": z.array(z.string()).optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
  })
  .partial();

export type InventoryFilter = z.infer<typeof InventoryFilterSchema>;

export const InventoryFiltersPostBodySchema = z.array(
  z.discriminatedUnion("name", [
    FilterSchema.extend({ name: z.literal("asset.class") }),
    FilterSchema.extend({ name: z.literal("cloud.service") }),
    FilterSchema.extend({
      name: z.literal("cloud.type"),
      value: CloudTypesWithOtherEnumSchema,
    }),
    FilterSchema.extend({ name: z.literal("resource.group") }),
    FilterSchema.extend({ name: z.literal("policy.complianceRequirement") }),
    FilterSchema.extend({ name: z.literal("policy.complianceStandard") }),
    FilterSchema.extend({ name: z.literal("resource.type") }),
    FilterSchema.extend({ name: z.literal("policy.complianceSection") }),
    FilterSchema.extend({ name: z.literal("account.group") }),
    FilterSchema.extend({ name: z.literal("cloud.account") }),
    FilterSchema.extend({ name: z.literal("cloud.region") }),
    FilterSchema.extend({ name: z.literal("cloud.accountId") }),
    FilterSchema.extend({ name: z.literal("asset.severity") }),
    FilterSchema.extend({ name: z.literal("vulnerability.severity") }),
    FilterSchema.extend({ name: z.literal("resource.tagv2") }),
    FilterSchema.extend({ name: z.literal("resourceList.tag") }),
  ]),
);

export type InventoryFiltersPostBody = z.infer<
  typeof InventoryFiltersPostBodySchema
>;

export const InventoryGroupByPropertySchema = z.enum([
  "cloud.type",
  "cloud.service",
  "cloud.account",
  "cloud.region",
  "resource.type",
]);

export const GroupByArray = z.array(InventoryGroupByPropertySchema);

export const InventoryRequestSchema = z.object({
  filters: InventoryFiltersPostBodySchema,
  groupBy: z.array(InventoryGroupByPropertySchema).optional(),
  nextPageToken: z.string().optional(),
  timeRange: TimeRangeSchema.optional(),
});

export type InventoryGroupedAggregate = z.infer<
  typeof InventoryGroupedAggregateSchema
>;

export type InventoryGroupByPropertyComplete = z.infer<
  typeof InventoryGroupByPropertySchema
>;

export type InventoryGroupByProperty = Exclude<
  InventoryGroupByPropertyComplete,
  "resource.type"
>;

export const DataInventoryFilterSchema = z
  .object({
    "account.group": z.array(z.string()),
    "resource.exposure": z.array(z.string()),
    "resource.name": z.array(z.string()),
    "container.name": z.array(z.string()),
    "object.identifier": z.array(z.string()),
    "object.classification": z.array(z.string()),
    "object.tag": z.array(z.string()),
    "object.owner": z.array(z.string()),
    malware: z.array(z.literal("true")),
    "object.exposure": z.array(ObjectExposureEnumSchema),
    "cloud.account": z.array(z.string()),
    "cloud.region": z.array(z.string()),
    "cloud.type": z.array(CloudTypesWithOtherEnumSchema),
    "cloud.service": z.array(z.string()),
    "policy.complianceStandard": z.array(z.string()),
    "account.id": z.array(z.string()),
    "resource.type": z.array(z.string()),
    "cloud.accountId": z.array(z.string()),
    timeRange: TimeRangeSchema,
  })
  .partial();

export const DataInventoryFiltersPostBodySchema = z.array(
  z.discriminatedUnion("name", [
    FilterSchema.extend({ name: z.literal("account.group") }),
    FilterSchema.extend({ name: z.literal("resource.exposure") }),
    FilterSchema.extend({ name: z.literal("resource.name") }),
    FilterSchema.extend({ name: z.literal("cloud.account") }),
    FilterSchema.extend({ name: z.literal("cloud.region") }),
    FilterSchema.extend({ name: z.literal("cloud.service") }),
    FilterSchema.extend({
      name: z.literal("cloud.type"),
      value: CloudTypesEnumSchema,
    }),
    FilterSchema.extend({ name: z.literal("container.name") }),
    FilterSchema.extend({ name: z.literal("object.identifier") }),
    FilterSchema.extend({ name: z.literal("object.classification") }),
    FilterSchema.extend({
      name: z.literal("object.malware"),
      value: z.literal(true),
    }),
    FilterSchema.extend({
      name: z.literal("object.exposure"),
      value: ObjectExposureEnumSchema,
    }),
    FilterSchema.extend({ name: z.literal("object.tag") }),
    FilterSchema.extend({ name: z.literal("object.owner") }),
  ]),
);

export const DataInventoryAggregateSchema = z.object({
  cloudType: CloudTypesWithOtherEnumSchema,
  totalResources: z.number(),
  publicResources: z.number(),
  totalObjects: z.number(),
  publicObjects: z.number(),
  sensitiveObjects: z.number(),
  malwareObjects: z.number(),
  alerts: z.number(),
  dssSnippetViewed: z.boolean(),
});
export const DataInventoryAggregateResponseSchema = z.array(
  DataInventoryAggregateSchema,
);

export const DataInventoryAggregateRequestSchema = z.object({
  filters: DataInventoryFiltersPostBodySchema,
  tableLevel: z.number(),
  timeRange: TimeRangeSchema,
});
export type DataInventoryAggregate = z.infer<
  typeof DataInventoryAggregateSchema
>;

const serverSideSortableColumns = [
  "containerName",
  "objectName",
  "serviceName",
  "objectExposure",
  "publicResource",
  "resourceName",
  "malwareScannedTime",
  "objectOwner",
  "metadataScannedTime",
] as const;

const serverSideSortableColumnSchema = z.enum(serverSideSortableColumns);
export type ServerSideSortableColumnType = z.infer<
  typeof serverSideSortableColumnSchema
>;

const sortBySchema = z.object({
  asc: z.boolean(),
  column: serverSideSortableColumnSchema,
});

export const DataInventoryObjectAggregateRequestSchema = z.object({
  filters: DataInventoryFiltersPostBodySchema,
  searchTerm: z.union([z.string(), z.number()]).nullish(),
  limit: z.number().nullish(),
  page: z.number().nullish(),
  timeRange: TimeRangeSchema,
  orderBy: sortBySchema,
});

export type DataInventoryObjectAggregateRequest = z.infer<
  typeof DataInventoryObjectAggregateRequestSchema
>;

export const DataInventoryServiceAggregateSchema = z.object({
  cloudType: CloudTypesWithOtherEnumSchema,
  serviceName: z.string(),
  totalResources: z.number(),
  publicResources: z.number(),
  totalObjects: z.number(),
  publicObjects: z.number(),
  sensitiveObjects: z.number(),
  malwareObjects: z.number(),
  alerts: z.number(),
});

export type DataInventoryServiceAggregate = z.infer<
  typeof DataInventoryServiceAggregateSchema
>;

export const DataInventoryServiceAggregateResponseSchema = z.array(
  DataInventoryServiceAggregateSchema,
);

export const DataInventoryObjectResourceAggregateSchema = z.object({
  cloudType: CloudTypesWithOtherEnumSchema,
  resourceName: z.string(),
  publicResource: z.string(),
  totalObjects: z.number(),
  publicObjects: z.number(),
  sensitiveObjects: z.number(),
  malwareObjects: z.number(),
  alerts: z.number(),
  dssSnippetViewed: z.boolean(),
  resourceStatus: z.boolean(),
  resourceRrn: z.string(),
  assetId: z.string(),
  regionName: z.string().nullish(),
  serviceName: z.string().nullish(),
});

export type DataInventoryObjectResourceAggregate = z.infer<
  typeof DataInventoryObjectResourceAggregateSchema
>;

export const DataInventoryResourceAggregateResponseSchema = z.array(
  DataInventoryObjectResourceAggregateSchema,
);

export const DataInventoryObjectAggregateSchema = z.object({
  cloudType: CloudTypesWithOtherEnumSchema,
  totalResources: z.number().nullish(),
  publicResources: z.number().nullish(),
  totalObjects: z.number(),
  publicObjects: z.number(),
  sensitiveObjects: z.number(),
  malwareObjects: z.number(),
  alerts: z.number(),
  dssSnippetViewed: z.boolean(),
  serviceName: z.string().nullish(),
  resourceName: z.string().nullish(),
  resourceStatus: z.boolean().nullish(),
  resourceRrn: z.string().nullish(),
  assetId: z.string().nullish(),
});

export const DataInventoryObjectLevelItemSchema = z.object({
  cloudType: CloudTypesWithOtherEnumSchema,
  accountId: z.string(),
  accountName: z.string(),
  regionName: z.string(),
  serviceName: z.string(),
  resourceName: z.string(),
  publicResource: z.string(),
  objectId: z.string(),
  objectName: z.string(),
  objectExposure: z.string(),
  objectOwner: z.string(),
  contentType: z.string(),
  dataProfiles: z.array(z.string()),
  dataPatterns: z.array(z.string()),
  malware: z.string(),
  metadataScannedTime: z.number(),
  malwareScannedTime: z.number(),
  dssScannedTime: z.number(),
  dssSnippetStatus: z.string(),
  dssSnippetViewed: z.boolean(),
  dssSnippetCreatedOn: z.number(),
  rrn: z.string(),
  assetId: z.string().nullish(),
  containerName: z.string().nullish(),
  objectUrl: z.string(),
  resourceExposure: z.string().nullish(),
});

export const DataInventoryObjectLevelAggregateSchema = z.object({
  totalItems: z.number(),
  page: z.number(),
  limit: z.number(),
  startTime: z.number(),
  endTime: z.number(),
  items: z.array(DataInventoryObjectLevelItemSchema),
});

export type DataInventoryObjectLevelAggregate = z.infer<
  typeof DataInventoryObjectLevelAggregateSchema
>;

export type DataInventoryObjectLevelItem = z.infer<
  typeof DataInventoryObjectLevelItemSchema
>;
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);

export const DataInventoryObjectDetailsResponseSchema = z.object({
  objectName: z.string(),
  objectExposure: z.string(),
  objectOwner: z.string(),
  malware: z.string(),
  objectUrl: z.string(),
  regionName: z.string(),
  resourceName: z.string(),
  createdOn: z.number(),
  updatedOn: z.number(),
  exposureCalculatedOn: z.number(),
  contentType: z.string(),
  objectAcl: z.string().nullish(),
  sha256: z.string(),
  awsAccountId: z.string(),
  resourceRRN: z.string(),
  containerName: z.string().nullish(),
  assetId: z.string(),
});

export type DataInventoryObjectDetails = z.infer<
  typeof DataInventoryObjectDetailsResponseSchema
>;

export const DataInventoryObjectDetailsRequestSchema = z.object({
  objectId: z.string(),
});

export const DataInventoryClassificationReportV2Schema = z.object({
  dataPattern: z.string(),
  dataPatternId: z.string(),
  dataProfiles: z.array(z.string()),
  detectionTime: z.number(),
  frequency: z.number(),
  snippet: z.string(),
  snippetViewed: z.boolean(),
});

export const DataInventoryClassificationReportV3StructuredDataSchema = z.object(
  {
    columnName: z.string(),
    dataPatterns: z.array(z.string()),
    dataProfiles: z.array(z.string()),
    sensitivity: z.string(),
  },
);

export const DataInventoryClassificationReportV2ResponseSchema = z.array(
  DataInventoryClassificationReportV2Schema,
);

export const DataInventoryClassificationReportV3StructuredDataResponseSchema =
  z.array(DataInventoryClassificationReportV3StructuredDataSchema);

export type DataInventoryClassificationReportV2 = z.infer<
  typeof DataInventoryClassificationReportV2Schema
>;

export type DataInventoryClassificationReportV3StructuredData = z.infer<
  typeof DataInventoryClassificationReportV3StructuredDataSchema
>;

export const ConfidenceDetectionSchema = z.object({
  left: z.string().nullish(),
  right: z.string().nullish(),
  detection: z.string().nullish(),
});

const confidenceLevel = ["high", "low", "medium"] as const;
const ConfidenceLevelEnumSchema = z.enum(confidenceLevel);

export const DataInventorySnippetsReponseSchema = z.object({
  matchedConfidenceLevel: ConfidenceLevelEnumSchema,
  highConfidenceDetections: z.array(ConfidenceDetectionSchema).nullish(),
  lowConfidenceDetections: z.array(ConfidenceDetectionSchema).nullish(),
  mediumConfidenceDetections: z.array(ConfidenceDetectionSchema).nullish(),
  dataPattern: z.string().nullish(),
});

export type DataInventorySnippets = z.infer<
  typeof DataInventorySnippetsReponseSchema
>;

export type ConfidenceLevel = z.infer<typeof ConfidenceLevelEnumSchema>;

export type ConfidenceDetection = z.infer<typeof ConfidenceDetectionSchema>;

export const QuotaResponseSchema = z.object({
  limit: z.number(),
  dataScanned: z.number(),
  unit: z.string(),
});

export type QuotaResponse = z.infer<typeof QuotaResponseSchema>;

export const InventoryCountRequestSchema = z.object({
  filters: InventoryFiltersPostBodySchema,
});

export const InventoryCountResponseSchema = z.object({
  timestamp: TimeStampSchema,
  requestedTimestamp: TimeStampSchema,
  count: z.number(),
  numberOfAccounts: z.number().optional(),
});
