import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const AccountGroups = lazy(() => import("./AccountGroups"));

export const accountGroupsRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "account-groups",
  component: AccountGroups,
});
