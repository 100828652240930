import { animated, useSpring } from "@react-spring/web";
import { useEffect, useMemo, useState } from "react";
import { Body, Button, Logo, Select, useDarkMode, type SelectItem } from "ui";

import { Link } from "@tanstack/react-router";
import { useCodeEnabled, useComputeEnabled } from "prisma";
import { FormattedMessage } from "react-intl";
import { type ColorTheme, type UCDMode } from "requests";
import { classNames } from "utils";
import { CrystalIcon } from "../../../components/CrystalIcon";
import { UCDIcon } from "../../../components/Navigation/UCDSelect/UCDIcon";
import { themeColorClass } from "../../../utils/useThemeColor";
import AvatarBg from "../avatar-bg.webp";
import Darkbg from "../dark-bg.webp";
import DarkGrain from "../dark-grain.webp";
import Lightbg from "../light-bg.webp";
import LightGrain from "../light-grain.webp";
import AvatarIcon from "./AvatarIcon";

export function textureStyle(hasTexture: boolean, isDark: boolean) {
  return hasTexture
    ? {
        background: isDark ? `url(${DarkGrain})` : `url(${LightGrain})`,
      }
    : {};
}

export function bgStyle(hasTexture: boolean, isDark: boolean) {
  return hasTexture
    ? {
        backgroundImage: isDark ? `url(${Darkbg})` : `url(${Lightbg})`,
      }
    : {};
}

export const avatarInlineStyle = {
  backgroundBlendMode: "multiply",
  backgroundImage: `url(${AvatarBg})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  maskImage: `url(${AvatarBg})`,
  WebkitMaskImage: `url(${AvatarBg})`,
  maskMode: "alpha",
  maskSize: "contain",
  WebkitMaskSize: "contain",
};

export default function PrismaUILayout({
  theme,
  selectedAvatar = 0,
  selectedRole,
  setRole,
  currStep,
}: {
  theme: ColorTheme;
  selectedAvatar: number;
  selectedRole: UCDMode;
  setRole: React.Dispatch<
    React.SetStateAction<{
      value: UCDMode;
    }>
  >;
  currStep: number;
}) {
  const { isDark } = useDarkMode();
  const { codeEnabled } = useCodeEnabled();
  const computeEnabled = useComputeEnabled();
  const items = useMemo<SelectItem[]>(
    () =>
      [
        {
          value: "cloud",
          iconLeft: <UCDIcon mode="cloud" theme={theme} />,
          children: (
            <Body addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Cloud Security"
                id="k1eYQ6"
                description="Cloud Security UCD dropdown option"
              />
            </Body>
          ),
        },
        computeEnabled && {
          value: "runtime",
          iconLeft: <UCDIcon mode="runtime" theme={theme} />,
          children: (
            <Body addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Runtime Security"
                id="SM88rH"
                description="Runtime Security UCD dropdown option"
              />
            </Body>
          ),
        },
        codeEnabled && {
          value: "application",
          iconLeft: <UCDIcon mode="application" theme={theme} />,
          children: (
            <Body addClassName="text-xs">
              <FormattedMessage
                defaultMessage="Application Security"
                id="rog4IP"
                description="Application Security UCD dropdown option"
              />
            </Body>
          ),
        },
      ].filter(Boolean) as SelectItem[],
    [theme, codeEnabled, computeEnabled],
  );
  const [selectedItem, setSelectedItem] = useState<SelectItem | null>();

  const linkClass = useMemo(() => {
    return `rounded py-2 px-1.5 font-semibold hover:no-underline ${theme} hover:bg-skin-fill dark:hover:bg-skin-darkFill dark:text-white text-xs`;
  }, [theme]);

  const activeLinkClass = useMemo(() => {
    return `rounded py-2 px-1.5 font-semibold ${theme} bg-skin-fill dark:hover:bg-skin-darkFill dark:text-white text-xs`;
  }, [theme]);

  const [prismaUILayoutProps] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: { mass: 50, tension: 90, friction: 100, clamp: true },
  }));

  useEffect(() => {
    const selectedItem = items.find(
      (item) => item.value === selectedRole,
    ) as SelectItem;
    setSelectedItem(selectedItem);
  }, [items, selectedRole]);

  return (
    <div
      className={classNames(
        "relative h-4/6 w-[50rem] overflow-hidden border border-r-0 border-primary bg-white drop-shadow-2xl dark:bg-blue-steel-950",
        currStep === 3 ? "rounded-r-lg" : "rounded-l-lg",
      )}
    >
      <RenderIcon currStep={currStep} theme={theme} />
      <animated.div style={prismaUILayoutProps} className="h-full">
        <div className="flex flex-wrap rounded-t-lg border-b border-gray-300 bg-gray-100/50 px-4 py-3 text-xs text-[#333] backdrop-blur-sm dark:border-blue-steel-850 dark:bg-blue-steel-950 dark:text-dark-bg">
          <div className="w-full">
            <nav
              className={classNames(
                "flex bg-gray-100/50 px-4 py-3 text-xs text-[#333] dark:border-blue-steel-850 dark:bg-blue-steel-950 dark:text-dark-bg ",
                currStep === 3 ? "justify-end" : "justify-between",
              )}
            >
              {currStep !== 3 && (
                <div className="flex items-center">
                  <Link>
                    <Logo className="mx-2 h-7" width={150} />
                  </Link>
                  <Select
                    button={
                      <Button
                        icon={
                          <UCDIcon
                            mode={selectedItem?.value as UCDMode}
                            theme={theme}
                          />
                        }
                        isMenu
                      >
                        {selectedItem?.children}
                      </Button>
                    }
                    items={items}
                    selectedItem={selectedItem}
                    onChange={(changes) => {
                      setSelectedItem(changes?.selectedItem as SelectItem);
                      setRole({
                        value: changes?.selectedItem?.value as UCDMode,
                      });
                    }}
                    menuStyle={{ width: "11.5rem" }}
                  />

                  <div className="ml-6 space-x-2">
                    <Link className={activeLinkClass}>
                      <FormattedMessage
                        defaultMessage="Home"
                        id="stEcrD"
                        description="nav link"
                      />
                    </Link>
                    <Link className={linkClass}>
                      <FormattedMessage
                        defaultMessage="Dashboards"
                        id="oAxdeR"
                        description="nav link"
                      />
                    </Link>
                    {selectedRole !== "application" && (
                      <Link className={linkClass}>
                        <FormattedMessage
                          defaultMessage="Reports"
                          id="mWl0N1"
                          description="nav link"
                        />
                      </Link>
                    )}
                    <Link className={linkClass}>
                      <FormattedMessage
                        defaultMessage="Inventory"
                        id="CLFPqO"
                        description="nav link"
                      />
                    </Link>
                    {selectedRole !== "application" && (
                      <Link className={linkClass}>
                        <FormattedMessage
                          defaultMessage="Alerts"
                          id="Y83je+"
                          description="nav link"
                        />
                      </Link>
                    )}
                    <Link className={linkClass}>
                      <FormattedMessage
                        defaultMessage="Investigate"
                        id="HGBtDd"
                        description="nav link"
                      />
                    </Link>
                    <Link className={linkClass}>
                      <FormattedMessage
                        defaultMessage="Governance"
                        id="GQ9FZF"
                        description="nav link"
                      />
                    </Link>
                  </div>
                </div>
              )}
              {currStep === 3 && (
                <div className="flex items-center justify-center">
                  <div className="h-3 w-10 rounded-sm border-[#DADBDB] bg-[#DADBDB] dark:border-[#2C3239] dark:bg-[#2C3239]"></div>
                  <div className="mx-2 h-8 w-8	rounded-2xl border-[#DADBDB] bg-[#DADBDB] dark:border-[#2C3239] dark:bg-[#2C3239]"></div>
                  <div className="h-8 w-8 rounded-2xl	border-[#DADBDB] bg-[#DADBDB] dark:border-[#2C3239] dark:bg-[#2C3239]"></div>
                  <div className="mx-2 h-8 w-8 rounded-2xl">
                    <div
                      className={`${themeColorClass(theme)} p-[6px]`}
                      style={{
                        width: "2.2rem",
                        height: "2.2rem",
                        ...avatarInlineStyle,
                      }}
                    >
                      <AvatarIcon iconToBeRendered={selectedAvatar} />
                    </div>
                  </div>
                </div>
              )}
            </nav>
          </div>
        </div>
        <div className="flex h-full">
          {currStep !== 3 && (
            <div
              className="flex w-64 flex-col justify-stretch border-r border-gray-300 p-4 backdrop-blur-2xl dark:border-blue-steel-850"
              style={{
                height: "calc(100% - 76px)",
                ...textureStyle(false, isDark),
              }}
            >
              <CloudSideNav
                selectedRole={selectedRole}
                linkClass={linkClass}
                activeLinkClass={activeLinkClass}
              />
              <RuntimeSideNav
                selectedRole={selectedRole}
                linkClass={linkClass}
              />
              <ApplicationSideNav
                selectedRole={selectedRole}
                linkClass={linkClass}
              />
            </div>
          )}
          <div
            className="w-full"
            style={{
              height: "calc(100% - 76px)",
              ...bgStyle(false, isDark),
            }}
          ></div>
        </div>
      </animated.div>
    </div>
  );
}

function RenderIcon({
  currStep,
  theme,
}: {
  currStep: number;
  theme: ColorTheme;
}) {
  return (
    <>
      {currStep !== 3 ? (
        <div className="absolute bottom-[-121px] left-[131px]">
          <CrystalIcon theme={theme} />
        </div>
      ) : (
        <div className="absolute right-[-85px] top-[61px]">
          <CrystalIcon theme={theme} />
        </div>
      )}
    </>
  );
}

function ApplicationSideNav({
  selectedRole,
  linkClass,
}: {
  selectedRole: UCDMode;
  linkClass: string;
}) {
  return (
    <>
      {selectedRole === "application" && (
        <>
          <span className="text-sm text-secondary dark:text-dark-bg-secondary">
            <FormattedMessage
              defaultMessage="VISIBILITY"
              id="MUVXVE"
              description="sidenav link"
            />
          </span>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Repositories"
              id="GnB43n"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Technologies"
              id="rKvTTs"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="SBOM"
              id="THRI6c"
              description="sidenav link"
            />
          </Link>
          <span className="mt-2 text-sm text-secondary dark:text-dark-bg-secondary">
            <FormattedMessage
              defaultMessage="CODE"
              id="GtJ1hY"
              description="sidenav link"
            />
          </span>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Projects"
              id="CyI8m1"
              description="sidenav link"
            />
          </Link>
          <span className="mt-2 text-sm text-secondary dark:text-dark-bg-secondary">
            <FormattedMessage
              defaultMessage="CI/CD"
              id="RjNxbC"
              description="sidenav link"
            />
          </span>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="CI/CD Risks"
              id="hVt5sX"
              description="sidenav link"
            />
          </Link>
        </>
      )}
    </>
  );
}

function RuntimeSideNav({
  selectedRole,
  linkClass,
}: {
  selectedRole: UCDMode;
  linkClass: string;
}) {
  return (
    <>
      {selectedRole === "runtime" && (
        <>
          <span className="text-sm text-secondary dark:text-dark-bg-secondary">
            <FormattedMessage
              defaultMessage="RADARS"
              id="vTZ39E"
              description="sidenav link"
            />
          </span>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Cloud"
              id="FbVpPn"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Hosts"
              id="6WnV9y"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Containers"
              id="M6sz5L"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Serverless"
              id="uT0+7Z"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Settings"
              id="QprIGu"
              description="sidenav link"
            />
          </Link>
          <span className="mt-2 text-sm text-secondary dark:text-dark-bg-secondary">
            <FormattedMessage
              defaultMessage="DEFEND"
              id="nIMUrm"
              description="sidenav link"
            />
          </span>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Vulnerabilities"
              id="yPb6mV"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Compliance"
              id="ufCOk0"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Runtime"
              id="nl0vw9"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="WAAS"
              id="dkZ3Ny"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="CNNS"
              id="ZHj5WT"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Access"
              id="NutSEo"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Custom Rules"
              id="rdGuC0"
              description="sidenav link"
            />
          </Link>
        </>
      )}
    </>
  );
}

function CloudSideNav({
  selectedRole,
  linkClass,
  activeLinkClass,
}: {
  selectedRole: UCDMode;
  linkClass: string;
  activeLinkClass: string;
}) {
  return (
    <>
      {selectedRole === "cloud" && (
        <>
          <Link className={activeLinkClass}>
            <FormattedMessage
              defaultMessage="Welcome"
              id="FZAI91"
              description="sidenav link"
            />
          </Link>
          <Link className={linkClass}>
            <FormattedMessage
              defaultMessage="Adoption Advisor"
              id="IUeCaA"
              description="sidenav link"
            />
          </Link>
        </>
      )}
    </>
  );
}
