import { type Map } from "immutable";
import { isNil } from "remeda";
import { AWS } from "../../../constants";
import LoadMore from "../LoadMore";
import {
  type ConvertAwsTreeMapToTreeDataType,
  type LoadMoreType,
  type TreeNodeType,
} from "../types";

export default function convertAwsTreeMapToTreeData({
  nodeId,
  treeMap,
  disabled,
  loadMoreProps,
}: ConvertAwsTreeMapToTreeDataType): TreeNodeType {
  const node = treeMap.get(nodeId) as unknown as Map<string, string>;
  if (!node) return {} as TreeNodeType;

  let loadMore: LoadMoreType | undefined = undefined;
  let loadMoreProjects: TreeNodeType | undefined = undefined;
  let loadMoreFolders: TreeNodeType | undefined = undefined;

  if (
    !isNil(node.getIn(["cursorToken", "folders"])) &&
    !isNil(node.getIn(["cursorToken", "projects"]))
  ) {
    loadMore = node.getIn(["cursorToken", "folders"]) && {
      checkable: false,
      isLeaf: true,
      id: `${node.get("id")}:load-more-accounts`,
      key: `${node.get("id")}:load-more-accounts`,
      title: (
        <LoadMore
          {...loadMoreProps}
          childrenType="folders"
          parentId={node.get("id")}
          parentName={node.get("displayName")}
          type={AWS}
        />
      ),
    };
  }
  if (
    !isNil(node.getIn(["cursorToken", "folders"])) &&
    isNil(node.getIn(["cursorToken", "projects"]))
  ) {
    loadMoreFolders = node.getIn(["cursorToken", "folders"]) && {
      checkable: false,
      isLeaf: true,
      id: `${node.get("id")}:load-more-accounts`,
      key: `${node.get("id")}:load-more-accounts`,
      title: (
        <LoadMore
          {...loadMoreProps}
          childrenType="projects"
          parentId={node.get("id")}
          parentName={node.get("displayName")}
          type={AWS}
        />
      ),
    };
  }

  if (
    isNil(node.getIn(["cursorToken", "folders"])) &&
    !isNil(node.getIn(["cursorToken", "projects"]))
  ) {
    loadMoreProjects = node.getIn(["cursorToken", "projects"]) && {
      checkable: false,
      isLeaf: true,
      id: `${node.get("id")}:load-more-accounts`,
      key: `${node.get("id")}:load-more-accounts`,
      title: (
        <LoadMore
          {...loadMoreProps}
          childrenType="projects"
          parentId={node.get("id")}
          parentName={node.get("displayName")}
          type={AWS}
        />
      ),
    };
  }

  const childFolders = node.getIn(["children", "folders"]).map((id = "") =>
    convertAwsTreeMapToTreeData({
      nodeId: id,
      treeMap,
      disabled,
      loadMoreProps,
    }),
  );

  const childProjects = node.getIn(["children", "projects"]).map((id = "") =>
    convertAwsTreeMapToTreeData({
      nodeId: id,
      treeMap,
      disabled,
      loadMoreProps,
    }),
  );

  const children = childFolders
    .add(loadMore)
    .union(childProjects)
    .add(loadMoreFolders)
    .add(loadMoreProjects)
    .filter((c: unknown) => !!c)
    .toJS();

  return {
    children,
    disabled: disabled.has(node.get("id")),
    id: node.get("id"),
    isLeaf: !node.get("hasChild"),
    key: node.get("id"),
    title: node.get("displayName"),
  };
}
