import { useEffect, useMemo, type ElementType, type ReactNode } from "react";
import { type FieldValues } from "react-hook-form";
import {
  LoadingPage,
  Modal,
  ModalHeader,
  usePrevious,
  type ModalProps,
} from "ui";
import { WizardProvider, useWizard, type WizardState } from "./WizardContext";

export type WizardStep<StepName extends string> = {
  name: StepName;
  title?: ReactNode;
  Component: ElementType;
  previousStepName?: StepName;
  previousButtonClick?: () => void;
  nextStepName?: StepName;
  /**
   * Excess keys can be set in context to be passed to the step component
   */
  context?: Record<string, unknown> & { closeModal?: () => void };
};

export type ModalWizardProps<
  D extends FieldValues,
  StepName extends string,
  Context extends Record<string, unknown> = Record<string, unknown>,
> = Omit<ModalProps, "children"> & {
  isLoading?: boolean;
  initialValues?: WizardState<StepName, D, Context>["wizardValues"];
  /**
   * The name of the initial active step.
   *
   * For a create process, this is generally the first step.
   *
   * For an edit process, this will often be the last step (often the summry step).
   */
  initialStepName?: StepName;
  /**
   * The wizard's steps. See `WizardStep` for the expected properties.
   */
  steps: WizardStep<StepName>[];
  /**
   * The title of the wizard.
   */
  title: ReactNode;
  /**
   * An object that will be available from the useWizard hook.
   */
  context?: Context;

  /**
   * opt to hide side navigation. defaults to false
   */
  hideSideNav?: boolean;
  /**
   * opt to hide header. defaults to false
   */
  hideTitle?: boolean;
};

// Wizards need a static hight so each step remains a consistent size.
// These are the maxHeight values in Modal
const sizes = {
  sm: { height: "48vh" },
  md: { height: "72vh" },
  lg: { height: "84vh" },
  xl: { height: "92vh" },
  full: { height: "92vh" },
};

/**
 * Wizards are multi-step forms for complex workflows.
 *
 * Steps are passed as an array of config objects. See `WizardStepForm`.
 *
 * The "Component" for each step can be built independently and will use the `WizardStepForm` component.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { ModalWizard } from 'form';
 * ```
 *
 */
export function ModalWizard<
  D extends FieldValues,
  StepName extends string,
  Context extends Record<string, unknown> = Record<string, unknown>,
>({
  isLoading,
  initialValues,
  initialStepName,
  title,
  steps,
  hideSideNav = false,
  hideTitle = false,
  context,
  size = "md",
  ...modalProps
}: ModalWizardProps<D, StepName, Context>) {
  return (
    <WizardProvider
      initialValues={initialValues}
      initialStepName={initialStepName}
      steps={steps}
      hideSideNav={hideSideNav}
      hideTitle={hideTitle}
      context={context}
    >
      <InnerModalWizard
        isLoading={isLoading}
        initialValues={initialValues}
        title={title}
        steps={steps}
        size={size}
        {...modalProps}
      />
    </WizardProvider>
  );
}

const InnerModalWizard = <
  D extends FieldValues,
  StepName extends string,
  Context extends Record<string, unknown> = Record<string, unknown>,
>({
  isLoading,
  initialValues,
  title,
  steps,
  size = "md",
  ...modalProps
}: ModalWizardProps<D, StepName, Context>) => {
  const { isWizardDirty, currentStepName, setWizardValues } = useWizard();
  const previousInitialValues = usePrevious(initialValues);

  const currentStep = useMemo(
    () => steps.find((step) => step.name === currentStepName),
    [currentStepName, steps],
  );

  // On edit, the initial data is originally empty, and then is set on a subsequent render.
  // The wizard and form initial values do not get reset on the subsequent render, so
  // manually update the data if it was previously empty.  This will not affect the isWizardDirty flag.
  useEffect(() => {
    if (isLoading) return;
    if (JSON.stringify(previousInitialValues) === JSON.stringify(initialValues))
      return;

    setWizardValues(initialValues);
  }, [initialValues, isLoading, previousInitialValues, setWizardValues]);

  return (
    <Modal
      confirmClose={isWizardDirty}
      eventsToClose={{
        enableClickOutside: false,
        enableEscapeKey: true,
      }}
      style={sizes[size]}
      size={size}
      {...modalProps}
    >
      <ModalHeader enableFullScreen title={title} />

      {isLoading ? (
        <LoadingPage />
      ) : (
        currentStep && (
          <currentStep.Component
            {...currentStep.context}
            closeModal={modalProps.onClose}
          />
        )
      )}
    </Modal>
  );
};
