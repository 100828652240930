import { Set, is, type Map } from "immutable";
import {
  ACCOUNT_ID,
  ALL,
  AWS_OU_DEFAULT_EXPANDED_NODES,
  AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR,
  AWS_OU_IS_LOADING,
  AWS_OU_LOADED_NODES,
  AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
  AWS_OU_PROJECT_SELECT_MODE,
  AWS_OU_SELECTED_NODES,
  AWS_OU_SELECTED_NODES_INITIAL,
  AWS_OU_SELECT_PROJECTS_INITIALIZED,
  AWS_OU_TREE_MAP,
  ENABLED,
  ERRORS,
  IS_EDIT,
  NAME,
  ROLE_ARN,
  ROOT_ID_FROM_API,
  VALUE,
} from "../../../../../../../constants";
import { type AwsSelectMemberAccountsInitialStateType } from "../initialState";

const getFieldValue = ({
  field,
  state,
}: {
  field: string;
  state: AwsSelectMemberAccountsInitialStateType;
}) => {
  return state.getIn([field, VALUE]);
};

export const getFieldErrors = ({
  field,
  state,
}: {
  field: string;
  state: AwsSelectMemberAccountsInitialStateType;
}) => {
  return state.getIn([field, ERRORS]);
};

export const getIsEdit = (state: AwsSelectMemberAccountsInitialStateType) => {
  return getFieldValue({ field: IS_EDIT, state });
};

export const getAwsOUDefaultExpandedNodes = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_DEFAULT_EXPANDED_NODES, state });
};

export const getAwsOuLoadedNodes = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_LOADED_NODES, state });
};

export const getAwsOUSelectProjectsIsLoading = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_IS_LOADING, state });
};

export const getAwsOUSelectProjectsInitialized = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_SELECT_PROJECTS_INITIALIZED, state });
};

export const getAwsOUFolderViewCredentialsError = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({
    field: AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR,
    state,
  });
};

export const getAwsOUSelectedNodesErrors = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldErrors({ field: AWS_OU_SELECTED_NODES, state });
};

export const getAwsOUTreeMap = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_TREE_MAP, state });
};

export const getAwsOUNextPageToken = (
  state: AwsSelectMemberAccountsInitialStateType,
  { parentId }: { parentId: string },
): unknown => {
  return getAwsOUTreeMap(state)?.getIn([parentId, "cursorToken", "folders"]);
};

export const getAwsAccountNextPageToken = (
  state: AwsSelectMemberAccountsInitialStateType,
  { parentId }: { parentId: string },
): unknown => {
  return getAwsOUTreeMap(state)?.getIn([parentId, "cursorToken", "projects"]);
};

export const getAwsOUProjectSelectMode = (
  state: AwsSelectMemberAccountsInitialStateType,
): string => {
  return getFieldValue({ field: AWS_OU_PROJECT_SELECT_MODE, state });
};

export const getAwsOUSelectedNodes = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_SELECTED_NODES, state });
};

export const getAwsOURootId = () => "root";

export const getAwsOUPreviouslySelectedNodesNotFound = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({
    field: AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND,
    state,
  });
};

export const getAwsOUSelectedNodesInitial = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: AWS_OU_SELECTED_NODES_INITIAL, state });
};

export const getAwsOUTreeRoot = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getAwsOUTreeMap(state).get(getAwsOURootId());
};

export const getEnabled = (state: AwsSelectMemberAccountsInitialStateType) => {
  return getFieldValue({ field: ENABLED, state });
};

export const getAccountName = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: NAME, state });
};

export const getRoleARN = (state: AwsSelectMemberAccountsInitialStateType) => {
  return getFieldValue({ field: ROLE_ARN, state });
};

export const getRootIdFromAPI = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return getFieldValue({ field: ROOT_ID_FROM_API, state });
};

export const getAccountId = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  return state.getIn([ACCOUNT_ID, VALUE]);
};

function selectionChanged(state: AwsSelectMemberAccountsInitialStateType) {
  const selectionType = getAwsOUProjectSelectMode(state);
  const selectedNodes = getAwsOUSelectedNodes(state);
  const initialSelectedNodes = getAwsOUSelectedNodesInitial(state);
  const initialSelectionType = initialSelectedNodes?.[0]?.selectionType;

  if (!initialSelectedNodes || !initialSelectionType) {
    return true;
  }

  if (selectionType !== initialSelectionType) {
    return true;
  }

  const initial = Set(
    initialSelectedNodes.map(
      ({ resourceId }: { resourceId: string }) => resourceId,
    ),
  );
  const current = Set(selectedNodes);

  return !is(initial, current);
}

const convertNodeToAPIPayload = (
  node: Map<string, unknown>,
  selectionType: string,
) => {
  return node
    ? {
        displayName: node.get("displayName"),
        nodeType: node.get("type"),
        resourceId: node.get("id"),
        selectionType,
      }
    : {};
};

export const getAwsOUHierarchySelectionPayload = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  const selectionType = getAwsOUProjectSelectMode(state);
  const treeMap = getAwsOUTreeMap(state);
  const selectedNodes = getAwsOUSelectedNodes(state);

  if (!selectionChanged(state)) {
    return getAwsOUSelectedNodesInitial(state);
  }

  if (selectionType === ALL) {
    const rootNode = getAwsOUTreeRoot(state);

    return [convertNodeToAPIPayload(rootNode, selectionType)];
  }

  return selectedNodes
    .toJS()
    .map((id: string) =>
      convertNodeToAPIPayload(treeMap.get(id), selectionType),
    );
};

/**
 * `DeNormalize Data` takes the data entered by the user and creates the request payload that is unique for each cloud
 * type. Azure and GCP is particular have complicated rules around how that data is supposed to be structured.
 */
export const getHierarchySelection = (
  state: AwsSelectMemberAccountsInitialStateType,
) => {
  const isEdit = getIsEdit(state);
  const deletedNodes = getAwsOUPreviouslySelectedNodesNotFound(state);
  // Filtering out the list opf nodes that were selected earlier but are deleted now
  let selectedNodes = getAwsOUHierarchySelectionPayload(state);
  selectedNodes = selectedNodes.filter(
    (ar: { resourceId: string }) =>
      !deletedNodes.find(
        (rm: { resourceId: string }) => rm.resourceId === ar.resourceId,
      ),
  );

  // Backend APIs are using OU for Root element. UI needed it to be ORG to recognize the Root element
  // Also, while editing the account BE will send rootId while fetching the account
  // In case of edit, UI is using the root Id from fetch cloud account to be used in status and save API payload
  if (getAwsOUProjectSelectMode(state) === ALL) {
    if (isEdit) {
      return [
        {
          displayName: "Root",
          nodeType: "ORG",
          resourceId: getRootIdFromAPI(state) || "root",
          selectionType: ALL,
        },
      ];
    } else {
      return [
        {
          displayName: "Root",
          nodeType: "ORG",
          resourceId: "root",
          selectionType: ALL,
        },
      ];
    }
  } else {
    return selectedNodes;
  }
};
