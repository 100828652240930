import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import { EmptyStateLayout, InvalidPermissionsSVG } from "../Graphics";

export function PermissionDenied(): ReactElement {
  const intl = useIntl();
  return (
    <div className="flex h-full items-center justify-center">
      <EmptyStateLayout
        graphic={<InvalidPermissionsSVG className="w-96" />}
        heading={intl.formatMessage({
          defaultMessage: "Permission Denied",
          id: "8HJ+9p",

          description: "Header",
        })}
        description={intl.formatMessage({
          defaultMessage:
            "You do not have sufficient privileges to view this page.",
          id: "jOf3FT",

          description: "permission denied description",
        })}
      />
    </div>
  );
}
