import { Route } from "@tanstack/react-router";
import { isDevelopment } from "environment";
import { lazy } from "react";
import { AssetExplorerFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../inventoryLayoutRoute";

const AssetExplorer = lazy(() => import("./AssetExplorer"));

export const AssetExplorerSearchSchema = z.object({
  filters: AssetExplorerFilterSchema.catch((ctx: unknown) => {
    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.warn(ctx);
    }
    return {};
  }).optional(),
  includeEventForeignEntities: z.boolean().optional(),
  assetId: z.string().optional(),
  jobId: z.string().optional(),
});
export type AssetExplorerSearchType = z.infer<typeof AssetExplorerSearchSchema>;

export const assetExplorerRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "asset-explorer",
  component: AssetExplorer,
  validateSearch: AssetExplorerSearchSchema,
});

export const assetInventoryAssetExplorerRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "assets/asset-explorer",
  component: AssetExplorer,
  validateSearch: AssetExplorerSearchSchema,
});

export const assetInventoryCloudAccountAssetExplorerRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "account/$accountId/asset-explorer",
  component: AssetExplorer,
  validateSearch: AssetExplorerSearchSchema,
});

export const assetInventoryCloudAccountServiceNameAssetExplorerRoute =
  new Route({
    getParentRoute: () => inventoryLayoutRoute,
    path: "account/$accountId/service/$serviceName/cloud/$cloud/asset-explorer",
    component: AssetExplorer,
    validateSearch: AssetExplorerSearchSchema,
  });

export const assetInventoryCloudTypeAssetExplorerRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "cloud/$cloudType/asset-explorer",
  component: AssetExplorer,
  validateSearch: AssetExplorerSearchSchema,
});

export const assetInventoryCloudTypeServiceNameRouteAssetExplorerRoute =
  new Route({
    getParentRoute: () => inventoryLayoutRoute,
    path: "cloud/$cloudType/service/$serviceName/asset-explorer",
    component: AssetExplorer,
    validateSearch: AssetExplorerSearchSchema,
  });

export const assetInventoryRegionAssetExplorerRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "region/$regionName/cloud/$cloud/asset-explorer",
  component: AssetExplorer,
  validateSearch: AssetExplorerSearchSchema,
});

export const assetInventoryServiceNameAssetExplorerRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "service/$serviceName/cloud/$cloud/asset-explorer",
  component: AssetExplorer,
  validateSearch: AssetExplorerSearchSchema,
});
