import {
  useCallback,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";

export type SetControlledState<D> = Dispatch<SetStateAction<D>> | Dispatch<D>;

export interface UseOptionallyControlledState<D> {
  currentValue?: D;
  defaultValue?: D;
  onChange?: SetControlledState<D>;
}

/**
 * In some components, we'd like to support both controlled and uncontrolled state. This
 * hook simplifies supporting both.
 */
export function useOptionallyControlledState<D>({
  currentValue,
  defaultValue,
  onChange,
}: UseOptionallyControlledState<D>): [D, SetControlledState<D>] {
  const isControlled = useRef(currentValue !== undefined);

  const [internalValue, setInternalValue] = useState<D | undefined>(
    defaultValue,
  );

  /* c8 ignore next */
  if (process.env.NODE_ENV === "development") {
    const latestIsControlled = currentValue !== undefined;
    if (isControlled.current !== latestIsControlled) {
      throw new Error(
        "Attempted to change state management after initial render. A component prop should either be controlled or uncontrolled; it should never switch between the two patterns.",
      );
    }
  }
  const extendOnChange = useCallback(
    (value: D) => {
      setInternalValue(value);
      onChange?.(value);
    },
    [onChange],
  );

  if (isControlled.current) {
    return [currentValue as D, onChange as SetControlledState<D>];
  }
  return [internalValue as D, extendOnChange];
}
