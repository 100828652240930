import { accessKeys, getAllAccessKeys, getUserRole, rolesKeys } from "requests";
import {
  ConnectedConfigureColumns,
  ConnectedDownloadButton,
  ConnectedTable,
  ConnectedTableFilter,
  ConnectedTableLayout,
  TruncateCell,
} from "table";

import { useQuery } from "@tanstack/react-query";
import { usePerformanceTracker, usePermissions, useProfile } from "prisma";
import { PermissionDenied } from "ui";
import { PageTableProvider } from "../../../../../utils/PageTableProvider";
import { useAccessKeysColumns } from "./Table/columns";

const defaultColumn = {
  Cell: TruncateCell,
  sortType: "basic",
  width: 175,
};

export function AccessKeys() {
  const columns = useAccessKeysColumns();
  const { data: profileData } = useProfile();
  const permissions = usePermissions();
  usePerformanceTracker(
    "AccessControlAccessKeysSettings",
    "AccessControlAccessKeysSettings",
  );
  const accessKeysAllowed =
    permissions?.settingsAccessKeys?.READ || profileData?.accessKeysAllowed;
  const {
    data = [],
    isError,
    error,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: accessKeys.allAccessKeys(),
    queryFn: getAllAccessKeys,
    enabled: accessKeysAllowed,
  });

  const { data: roles = [] } = useQuery({
    queryKey: rolesKeys.userRole(),
    queryFn: getUserRole,
    enabled: accessKeysAllowed,
  });

  if (!accessKeysAllowed) {
    <PermissionDenied />;
  }

  return (
    <div className="flex flex-col overflow-auto">
      <PageTableProvider
        columns={columns}
        data={data}
        defaultColumn={defaultColumn}
        context={{ roles }}
        pageName="accessKey"
        tableName="table"
        enableColumnOrder
        enableColumnSort
        enablePagination
        enableResizeColumns
        error={error}
        isLoading={isLoading}
        isError={isError}
      >
        <ConnectedTableLayout
          controlRight={
            <>
              <ConnectedTableFilter />
              <ConnectedDownloadButton
                fileName={() =>
                  `accessKeysTable-${new Date().toISOString()}.csv`
                }
              />
              <ConnectedConfigureColumns />
            </>
          }
          isBackgroundFetching={isFetching}
          overflow
        >
          <ConnectedTable selector="access-keys-table" />
        </ConnectedTableLayout>
      </PageTableProvider>
    </div>
  );
}
