import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { integrationAndNotificationRoute } from "../integrationAndNotificationRoute";

export const Notifications = lazy(() => import("./Notifications"));

export const notificationsRoute = new Route({
  getParentRoute: () => integrationAndNotificationRoute,
  path: "notifications",
  component: Notifications,
});
