import { type ReactNode } from "react";
import { Checkbox, type CheckboxProps } from "ui";

export function SelectAllCheckbox({
  status,
  onChange,
  children,
}: {
  status: string;
  onChange: CheckboxProps["onChange"];
  children: ReactNode;
}) {
  return (
    <Checkbox
      addClassName="ml-2"
      onChange={onChange}
      checked={status === "all"}
      indeterminate={status === "indeterminate"}
    >
      <span className="sr-only">{children}</span>
    </Checkbox>
  );
}
