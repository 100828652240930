import { FormattedMessage } from "react-intl";

export function CopyrightNotice() {
  return (
    <FormattedMessage
      defaultMessage="{copyIcon} {year} Palo Alto Networks, Inc. All rights reserved."
      id="pEefNb"
      description="Copyright notice"
      values={{ year: new Date().getFullYear(), copyIcon: <>&copy;</> }}
    />
  );
}
