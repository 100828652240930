import {
  asmConfirmationDescriptionMessagesMap,
  asmConfirmationTitleMessagesMap,
} from "./messages";

import { IdentitySecurityModuleIcon } from "icons";
import { DynamicFormattedMessage } from "ui";
import { AddonModalMainCard } from "../AddonModalMainCard";

export type ASMConfirmationModalContentProps = {
  isSubscribed: boolean;
};

export function ASMConfirmationModalContent({
  isSubscribed,
}: ASMConfirmationModalContentProps) {
  return (
    <AddonModalMainCard
      icon={<IdentitySecurityModuleIcon size="xxl" />}
      title={
        <DynamicFormattedMessage
          messageMap={asmConfirmationTitleMessagesMap}
          token={isSubscribed ? "afterSubscribe" : "afterUnsubscribe"}
        />
      }
      titleStyle="secondaryHeader"
      description={
        <DynamicFormattedMessage
          messageMap={asmConfirmationDescriptionMessagesMap}
          token={isSubscribed ? "afterSubscribe" : "afterUnsubscribe"}
        />
      }
    />
  );
}
