import { isDevelopment } from "environment";
import { FormattedMessage } from "react-intl";
import { type ApiError } from "requests";
import { EmptyStateLayout, SomethingWentWrongSVG } from "../Graphics";
import { Logo } from "../Logo";
import { Body, Title } from "../Typography";
import Img404 from "./404.svg";

// TODO: This unknown should be removed when both TanStack router and query libraries start using `Error` instead of `unknown` https://github.com/TanStack/query/issues/483#issuecomment-1381424006
export type SomethingWentWrongError = unknown | Error | ApiError;

type SomethingWentWrongType = {
  componentStack?: string | null;
  error?: SomethingWentWrongError;
  eventId?: string | null;
};

export function getErrorMessage(error: SomethingWentWrongError) {
  if (!error) {
    return null;
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === "object") {
    if ("message" in error) {
      return (error as { message: string }).message;
    }
    if ("body" in error && error.body) {
      if (typeof error.body === "string") {
        return error.body;
      }
      if ("message" in (error as ApiError).body) {
        return (error as ApiError<string>).body.message;
      }
      if (
        "message" in ((error as ApiError<{ message: string }>).body.error || {})
      ) {
        return (error as ApiError<{ message: string }>).body.error?.message;
      }
      if (
        "Message" in ((error as ApiError<{ Message: string }>).body.error || {})
      ) {
        return (error as ApiError<{ Message: string }>).body.error?.Message;
      }
    } else if (
      "statusText" in error &&
      error.statusText &&
      typeof error.statusText === "string"
    ) {
      return error.statusText;
    }
  }
  if (typeof error === "string") {
    return error;
  }
  return null;
}

const SomethingWentWrongHeader = () => (
  <FormattedMessage
    defaultMessage="Something Went Wrong"
    id="aCoJYZ"
    description="Error page header"
  />
);

export const SomethingWentWrongFooter = ({
  eventId,
}: {
  eventId?: string | null;
}) =>
  eventId ? (
    <FormattedMessage
      defaultMessage="Please try again and if you continue to see this error, please contact the <a>Prisma Cloud team</a>"
      id="AEdb3g"
      description="Error page help"
      values={{
        code: eventId,
        a: (chunks) => (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://live.paloaltonetworks.com/t5/prisma-cloud/ct-p/PrismaCloud"
          >
            {chunks}
          </a>
        ),
      }}
    />
  ) : null;

export function SomethingWentWrongErrorPage({
  componentStack,
  error,
  eventId,
}: SomethingWentWrongType) {
  return (
    <div className="flex h-full flex-col overflow-scroll bg-gray-100 text-default dark:bg-blue-steel-950 dark:text-dark-bg">
      <div className="grow">
        <div className="mx-8 mt-8 font-light">
          <Logo className="mb-8" />
          <Title level={1}>
            <SomethingWentWrongHeader />
          </Title>
          <Body size="lg">
            <SomethingWentWrongFooter
              eventId={
                eventId ??
                (error as ApiError)?.headers?.["x-redlock-request-id"]
              }
            />
          </Body>
        </div>

        {isDevelopment() && (
          <>
            <pre className="mx-8 mt-4 text-base">{getErrorMessage(error)}</pre>
            <pre className="mx-8 mt-4 text-base">{componentStack}</pre>
          </>
        )}
      </div>
      <img className="w-full" src={Img404} />
    </div>
  );
}

export function SomethingWentWrongErrorSmall({
  componentStack,
  error,
  eventId,
}: SomethingWentWrongType) {
  return (
    <div className="flex flex-col">
      <EmptyStateLayout
        graphic={<SomethingWentWrongSVG className="w-48" />}
        heading={<SomethingWentWrongHeader />}
        description={
          <SomethingWentWrongFooter
            eventId={
              eventId ?? (error as ApiError)?.headers?.["x-redlock-request-id"]
            }
          />
        }
      />
      {isDevelopment() && (
        <>
          <pre className="mx-8 mt-4 text-left text-xs">
            {getErrorMessage(error)}
          </pre>
          <pre className="mx-8 mt-4 text-left text-xs">{componentStack}</pre>
        </>
      )}
    </div>
  );
}
