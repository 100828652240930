import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import {
  ResourceList,
  ResourceListRequest,
  type ResourceListType,
} from "./schemas";

export const resourceListKeys = {
  all: [{ scope: "resourceList" }] as const,
  resourceListTypes: () =>
    [{ ...resourceListKeys.all[0], entity: "resourceListTypes" }] as const,
  resourceGroupNames: () =>
    [{ ...resourceListKeys.all[0], entity: "resourceGroupNames" }] as const,
  resourceListById: ({ id }: { id?: string }) =>
    [{ ...resourceListKeys.all[0], id }] as const,
  resourceListByType: ({ type }: { type?: ResourceListType }) =>
    [{ ...resourceListKeys.all[0], type }] as const,
  deleteResourceList: ({ id }: { id?: string }) =>
    [{ ...resourceListKeys.all[0], id }] as const,
} as const;

export const getResourceList = async () => {
  return jsonApi({
    path: "v1/resource_list",
    requestSchema: z.void(),
    responseSchema: z.array(ResourceList),
  });
};

export const getResourceListTypes = async () => {
  return jsonApi({
    path: "v1/resource_list/types",
    requestSchema: z.void(),
    responseSchema: z.array(z.string()),
  });
};

export const getResourceGroupNames = async () => {
  return jsonApi({
    path: "v1/resource_list/resource_group_names",
    requestSchema: z.void(),
    responseSchema: z.array(z.string()),
  });
};

export const getResourceListById = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<
  ReturnType<(typeof resourceListKeys)["resourceListById"]>
>) => {
  return jsonApi({
    path: `v1/resource_list/${id}`,
    requestSchema: z.void(),
    responseSchema: ResourceList,
  });
};

export const updateResourceList = async ({
  id,
  payload,
}: {
  id: string;
  payload: z.infer<typeof ResourceListRequest>;
}) => {
  return jsonApi({
    body: {
      ...payload,
    },
    config: {
      method: "PUT",
    },
    path: `v1/resource_list/${id}`,
    requestSchema: ResourceListRequest,
    responseSchema: ResourceList,
  });
};

export const createResourceList = async ({
  payload,
}: {
  payload: z.infer<typeof ResourceListRequest>;
}) => {
  return jsonApi({
    body: {
      ...payload,
    },
    config: {
      method: "POST",
    },
    path: `v1/resource_list`,
    requestSchema: z.object({
      description: z.string().nullish(),
      members: z.union([
        z.array(
          z.record(z.string(), z.union([z.string(), z.array(z.string())])),
        ),
        z.array(z.string()),
      ]),
      name: z.string(),
      resourceListType: z.string(),
    }),
    responseSchema: ResourceList,
  });
};

export const getResourceListByType = async ({
  queryKey: [{ type }],
}: QueryFunctionContext<
  ReturnType<(typeof resourceListKeys)["resourceListByType"]>
>) => {
  return jsonApi({
    path: `v1/resource_list/names?listType=${type}`,
    requestSchema: z.void(),
    responseSchema: z.array(ResourceList),
  });
};

export const deleteResourceList = async (id: string) => {
  return jsonApi({
    config: {
      method: "DELETE",
    },
    path: `v1/resource_list/${id}`,
    requestSchema: z.void(),
    responseSchema: z.any(),
  });
};
