import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";

export const AgentbasedWorkloadProtectionIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Agent-Based Workload Protection",
    id: "5X3k3x",

    description: "Security Capabilities Class Icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <title>{alt}</title>
      <path
        className="fill-[#252F3E] dark:fill-white"
        d="M13.7812 7.75H12.25C12.25 10.4023 10.3086 12.5625 7.76562 12.9453C7.65625 13.2188 7.30078 13.4375 7 13.4375C6.67188 13.4375 6.31641 13.2188 6.20703 12.9453C4.10156 12.6172 2.13281 10.6484 1.80469 8.54297C1.53125 8.43359 1.3125 8.07812 1.3125 7.75C1.3125 7.44922 1.53125 7.09375 1.80469 6.98438C2.1875 4.44141 4.34766 2.5 7 2.5C7.79297 2.52734 8.99609 2.85547 9.67969 3.26562L9.02344 3.92188C8.42188 3.59375 7.73828 3.375 7 3.375C4.8125 3.375 3.00781 4.98828 2.67969 7.06641C2.89844 7.23047 3.0625 7.47656 3.0625 7.75C3.0625 8.05078 2.89844 8.29688 2.67969 8.46094C2.95312 10.1836 4.56641 11.7969 6.28906 12.0703C6.45312 11.8516 6.69922 11.6875 7 11.6875C7.27344 11.6875 7.51953 11.8516 7.68359 12.0703C9.76172 11.7422 11.375 9.9375 11.375 7.75H9.625C9.625 9.19922 8.42188 10.375 7 10.375C5.55078 10.375 4.375 9.19922 4.375 7.75C4.375 6.32812 5.55078 5.125 7 5.125C7.19141 5.15234 7.49219 5.17969 7.68359 5.26172L6.91797 6.02734C5.98828 6.05469 5.25 6.84766 5.25 7.77734C5.25 8.73438 6.01562 9.52734 7 9.52734C7.95703 9.52734 8.75 8.73438 8.75 7.77734V7.75H7.02734L12.5508 2.25391C12.6055 2.19922 12.6875 2.03516 12.6875 1.95312C12.6875 1.84375 12.6055 1.70703 12.5508 1.625L12.25 1.32422C12.168 1.26953 12.0312 1.1875 11.9219 1.1875C11.8398 1.1875 11.6758 1.26953 11.6211 1.32422L10.8008 2.14453C9.84375 1.51562 8.14844 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.03125 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7188 14.5312 13.7812 11.4961 13.7812 7.75Z"
      />
    </SvgIcon>
  );
};
