import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";

export default function UserName({ userName }: { userName: string }) {
  const intl = useIntl();
  useTrimField("userName");

  return (
    <Input
      data-selector="oci-user-name-input"
      name="userName"
      label={intl.formatMessage({
        defaultMessage: "User Name",
        id: "PAZmLh",
        description: "The name of an input field for onboarding an OCI account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "eg - prisma-cloud-user",
        id: "EZio6t",
        description: "Placeholder for User Name",
      })}
      defaultValue={userName}
      register={{
        required: intl.formatMessage({
          defaultMessage: "User Name is required",
          id: "Zzezaq",
          description: "Error message for field for onboarding a OCI tenant",
        }),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "Oracle Cloud Infrastructure Identity User Name. New user will be created (recommended) or you can use an existing user with right privileges, refer Terraform template below for details on user privileges",
        id: "tdSfn2",
        description: "Tooltip for User Name input field",
      })}
    />
  );
}
