import { useEffect } from "react";

/**
 * Sets the document title and joins with a | character.
 *
 * Note: It does not put the title back on unmount, so your title may not be correct
 * if you try to nest calls to useTitle and then remove them.
 */
export function useTitle(title: string | string[]): void {
  useEffect(() => {
    const titles = Array.isArray(title) ? title : [title];
    const titlesFiltered = titles.filter(Boolean);
    if (titlesFiltered.length) {
      document.title = titlesFiltered.join(" | ");
    }
  }, [title]);
}
