import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { type CloudType } from "../cloudType";
import { AccountGroupNameResponseSchema } from "./schemas";

export const accountGroupNameKeys = {
  all: [{ scope: "accountGroupNameAll" }] as const,
  accountGroupNames: (params?: {
    include_auto_created?: "true" | "false";
    cloudType?: CloudType;
    excludeGroupId?: string;
  }) =>
    [
      {
        ...accountGroupNameKeys.all[0],
        entity: "accountGroupNames",
        params,
      },
    ] as const,
} as const;

export const getAccountGroupNames = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof accountGroupNameKeys)["accountGroupNames"]>
>) => {
  const { cloudType, ...rest } = params ?? {};
  return jsonApi({
    path: cloudType ? `cloud/group/name/${cloudType}` : "cloud/group/name",
    params: rest,
    requestSchema: z.void(),
    responseSchema: AccountGroupNameResponseSchema,
  });
};
