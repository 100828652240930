import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Body } from "ui";
import CFTButton from "../../../components/CFTButton";

type ConfigHelpersProps = {
  downloadTerraform: UseMutateAsyncFunction<void, unknown, void, unknown>;
  isTerraformDownloaded: boolean;
  isDownloadingTerraform: boolean;
  setIsOpenConfigHelper: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ConfigHelpers({
  downloadTerraform,
  isTerraformDownloaded,
  isDownloadingTerraform,
  setIsOpenConfigHelper,
}: ConfigHelpersProps) {
  return (
    <Body as="div" addClassName="flex space-x-4 pt-2">
      <CFTButton
        dataSelector="config-helper-download-dynamic-cft-link"
        external={false}
        isLoading={isDownloadingTerraform}
        isFileDownloaded={isTerraformDownloaded}
        onButtonClick={downloadTerraform}
        onStepClick={() => setIsOpenConfigHelper(true)}
        title={
          <FormattedMessage
            defaultMessage="Download the terraform script"
            id="rfv5xK"
            description="IBM terraform script download link"
          />
        }
      />
    </Body>
  );
}
