import { Button } from "../Button";
import { useModalContext } from "./Modal";

import { type ReactElement } from "react";
import { type ButtonProps } from "../Button";

/**
 * A Button that when clicked, will call closeModal
 * with the confirm close logic if confirmClose is true on Modal.
 */
export const ModalCloseButton = ({
  children,
  ...props
}: ButtonProps): ReactElement => {
  const { closeModalWithConfirm } = useModalContext();

  return (
    <Button
      data-testid="modal-close-button"
      onClick={closeModalWithConfirm}
      {...props}
    >
      {children}
    </Button>
  );
};
