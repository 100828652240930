import { defineMessages } from "react-intl";

export const statusModalMessagesMap = defineMessages({
  azure_tenant_not_found: {
    defaultMessage: "Azure Tenant Not Found",
    id: "Yhrx9j",
    description: "Azure Tenant Not Found status message",
  },
  azure_invalid_active_directory_id: {
    defaultMessage: "Active Directory ID is invalid",
    id: "IpS1Bm",
    description: "Active Directory ID is invalid status message",
  },
  azure_disabled_flow_monitoring: {
    defaultMessage: "Flow Logs Monitoring is disabled",
    id: "oOFigG",
    description: "Flow Logs Monitoring is disabled status message",
  },
  azure_authentication_error: {
    defaultMessage: "Authentication failed, please check credentials",
    id: "bDzcms",
    description: "Authentication failed is disabled status message",
  },
  azure_invalid_application_id: {
    defaultMessage: "Application ID is invalid",
    id: "bFhHrp",
    description: "Application ID is invalid status message",
  },
  azure_invalid_application_key: {
    defaultMessage: "Application Key is invalid",
    id: "hF/mnj",
    description: "Application Key is invalid status message",
  },
  azure_invalid_subscription_id: {
    defaultMessage: "Subscription ID is invalid",
    id: "/ISeg4",
    description: "Subscription ID is invalid status message",
  },
  no_flow_log_enabled: {
    defaultMessage: "No Flow Log Enabled",
    id: "J8JpJ/",
    description: "No Flow Log Enabled status message",
  },
  either_invalid_external_id_or_missing_trust_in_role: {
    defaultMessage:
      "Incorrect External ID or missing trust relationship for Prisma Cloud in the provided Role",
    id: "iTzx4B",
    description: "Incorrect external id status message",
  },
  no_assessment_templates_found: {
    defaultMessage:
      "None of the regions have Assessment Templates for Inspector",
    id: "nPXXv5",
    description: "Inspector status message",
  },
  no_guardduty_detectors_found: {
    defaultMessage: "None of the regions are enabled for GuardDuty",
    id: "0E/xyO",
    description: "GuardDuty status message",
  },
  no_flow_log_configured_properly: {
    defaultMessage: "No Flow Logs configured properly",
    id: "VInGN7",
    description: "Flow logs status message",
  },
  no_flow_logs_found: {
    defaultMessage: "No Flow Logs were found in any region",
    id: "T4VK8F",
    description: "Flow logs status message",
  },
  storage_account_access_probhited: {
    defaultMessage: "Storage Account Access Prohibited",
    id: "PD0Eqv",
    description: "Storage Account Access Prohibited status message",
  },
  invalid_client_secret_provided: {
    defaultMessage: "Invalid client secret provided",
    id: "3mHCOD",
    description: "Invalid client secret provided status message",
  },
});
