import { FormattedMessage } from "react-intl";

export default function Header() {
  return (
    <div className="grid grid-cols-4 gap-4 pl-2">
      <div className="flex items-center space-x-2 break-all">
        <span className="grow text-left font-normal">
          <FormattedMessage
            defaultMessage="Name"
            id="GHg6AA"
            description="Security Capabilities Permission column header label"
          />
        </span>
      </div>
      <div className="flex pl-6">
        <FormattedMessage
          defaultMessage="Status"
          id="9B/89L"
          description="Security Capabilities Permission column header label"
        />
      </div>
      <div className="col-span-2 pl-4">
        <FormattedMessage
          defaultMessage="Remediation"
          id="q9TlS5"
          description="Security Capabilities Permission column header label"
        />
      </div>
    </div>
  );
}
