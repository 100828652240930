import { type Column, type Hooks } from "react-table";

import { type ColumnOptions, type Data, type TableOptions } from "../../types";

export const SortRowColumn: ColumnOptions<Data> = {
  id: "sort-row",
  disableResizing: true,
  disableExport: true,
  disableGlobalFilter: true,
  disableHideColumn: true,
  disableSortBy: true,
  disableGroupBy: true,
  isSortRowColumn: true,
  sticky: true,
  stickyPosition: "left",
  width: 48,
};

export function useInsertSortRowColumn<D extends Data>(hooks: Hooks<D>): void {
  hooks.visibleColumns.push((columns, meta: { instance: TableOptions<D> }) => {
    if (meta.instance.enableRowSort) {
      return [SortRowColumn as Column<D>, ...columns];
    }
    return columns;
  });
}
