import { Form, FormLayout, Input, Select, SubmitButton } from "form";
import { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getDefaultProxy,
  parseToken,
  postLogin,
  successMessages,
  type ApiError,
  type TenantSchema,
} from "requests";
import { Body, Button, TruncateText } from "ui";

import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { isProduction } from "environment";
import jsCookie from "js-cookie";
import { type z } from "zod";
import { RouterLink } from "../../../components/Navigation/RouterLink/RouterLink";
import { loginRoute } from "../loginRoute";
import { useSignIn } from "../utils/useSignIn";
import TermsOfService from "./TermsOfService";
import { errorMessagesMap } from "./messages";

type ErrorMessageType = keyof typeof errorMessagesMap;

type Tenant = z.infer<typeof TenantSchema>;
type FormProps = {
  loginProxy: string;
  tenant: string;
  username: string;
  password: string;
};

export default function LoginForm() {
  const {
    login_needs_customer_name: { value: LOGIN_NEEDS_CUSTOMER_NAME },
    login_successful: { value: LOGIN_SUCCESSFUL },
    tos_not_accepted: { value: TOS_NOT_ACCEPTED },
  } = successMessages;
  const search = useSearch({ from: loginRoute.id });
  const { loginProxy: loginProxyEnabled = false, apiUrl = "" } = search;
  const signIn = useSignIn();
  const navigate = useNavigate();
  const intl = useIntl();
  const [error, setError] = useState<ErrorMessageType | "">();
  const [tenants, setTenants] = useState<Array<Tenant>>([]);
  const [showTos, setShowTos] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<string>("");
  const [tosApiUrl, setTosApiUrl] = useState("");

  const tenantItems = useMemo(() => {
    return tenants?.map(({ customerName, apiUrl }: Tenant) => ({
      context: { apiUrl },
      key: `${customerName}-${apiUrl}`,
      value: customerName,
      children: (
        <span className="flex justify-between">
          <TruncateText addClassName="w-44 text-left">
            {customerName}
          </TruncateText>
          <span className="ml-2 text-disabled">
            {apiUrl &&
              new URL(apiUrl).host
                .replace(/.prismacloud.(io|cn)/, "")
                .replace(".k8sdev", "")
                .replace("api", "app")}
          </span>
        </span>
      ),
    }));
  }, [tenants]);

  const formRef = useRef({} as FormProps);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({ loginProxy, tenant, ...formValues }: FormProps) => {
      try {
        setError("");
        const selectedTenant = tenants?.find((i) => i.customerName === tenant);
        let baseUrl = selectedTenant?.apiUrl ?? loginProxy;
        if (baseUrl?.length === 0) baseUrl = getDefaultProxy(loginProxyEnabled);
        const { username, password } = formValues;
        const response = await postLogin({
          username: username,
          password: password,
          customerName: selectedTenant?.customerName || tenant,
          baseUrl,
        });

        const version = response.headers.get("X-Ui-Version");
        const isLegacyLogin = version === "legacy";

        const body = await response.json();

        if (body.message === LOGIN_SUCCESSFUL) {
          const { accessKeyId } = parseToken(body.token);
          if (accessKeyId) {
            setError("access_key_login_not_permitted");
          } else {
            if (isLegacyLogin) {
              jsCookie.set("x-ui-version", "legacy", {
                domain: ".prismacloud.io",
              });
            }
            signIn(body.token, isLegacyLogin);
          }
        } else if (body.message === LOGIN_NEEDS_CUSTOMER_NAME) {
          setTenants(body.customerNames ?? []);
        } else if (body.message === TOS_NOT_ACCEPTED) {
          setCustomerName(selectedTenant?.customerName || tenant);
          setTosApiUrl(
            selectedTenant?.apiUrl ??
              body?.customerNames?.[0].apiUrl ??
              baseUrl,
          );
          setShowTos(true);
        } else {
          setError(body?.message ?? "login_failed_unknown_error");
        }
      } catch (e: unknown) {
        const { headers, body } = e as ApiError<ErrorMessageType>;
        const status =
          headers?.["x-redlock-status"]?.[0]?.i18nKey ??
          body?.message ??
          body?.error;
        setError(status ?? "login_failed_unknown_error");
      }
    },
  });

  const handleSubmit = useCallback(
    (values: FormProps) => {
      formRef.current = values;
      return mutateAsync(values);
    },
    [mutateAsync],
  );

  return (
    <Form
      defaultValues={{
        loginProxy: getDefaultProxy(loginProxyEnabled, apiUrl),
      }}
      onSubmit={handleSubmit}
    >
      <TermsOfService
        customerName={customerName}
        isOpen={showTos}
        isSSOSession={false}
        onAccept={(response: { token: string }) => {
          signIn(response.token);
        }}
        baseUrl={tosApiUrl}
      />
      <FormLayout>
        <div>
          <Input
            name="username"
            readOnly={tenants && tenants.length > 1}
            label={intl.formatMessage({
              defaultMessage: "Email",
              id: "zbBwEg",
              description: "Sign in Email label",
            })}
          />
        </div>
        <div className="relative">
          <FormLayout>
            <Input
              name="password"
              label={intl.formatMessage({
                defaultMessage: "Password",
                id: "ukwMJB",
                description: "Sign in Password label",
              })}
              type="password"
              readOnly={tenants && tenants.length > 1}
            />

            {tenants?.length > 1 && (
              <Select
                label={
                  intl.formatMessage({
                    defaultMessage: "Tenant",
                    id: "qH6x2d",
                    description: "Tenant input label",
                  }) as string
                }
                name="tenant"
                items={tenantItems}
                disabled={isPending}
                enableSearch
                simpleFormat
                itemToString={(item) =>
                  `${item?.value}${item?.context?.apiUrl}`
                }
                onChange={(event) => {
                  formRef.current = {
                    ...formRef.current,
                    tenant: event?.selectedItem?.value as string,
                  };

                  handleSubmit(formRef.current);
                }}
              />
            )}

            {!isProduction() && (
              <Input
                name="loginProxy"
                label={intl.formatMessage({
                  defaultMessage: "API URL",
                  id: "6DZyOc",
                  description: "API URL Input Label",
                })}
                register={{ required: false }}
                onChange={(event) => {
                  formRef.current = {
                    ...formRef.current,
                    loginProxy: event?.target.value,
                  };
                }}
              />
            )}

            {error && (
              <Body as="p" appearance="error" data-testid="login-error">
                {intl.formatMessage(
                  errorMessagesMap[error] ??
                    errorMessagesMap["login_failed_unknown_error"],
                )}
              </Body>
            )}

            <SubmitButton fullWidth centerText>
              <FormattedMessage
                defaultMessage="Sign in"
                id="smS9oI"
                description="Message for logging into an application"
              />
            </SubmitButton>
          </FormLayout>

          <div className="absolute right-0 top-0 text-xs">
            <RouterLink to={"/request-password-reset"} className="text-dark-bg">
              {intl.formatMessage({
                defaultMessage: "Forgot Password?",
                id: "fbM395",
                description: "Link to reset forgotten password",
              })}
            </RouterLink>
          </div>
        </div>

        <div className="flex justify-center">
          <FormattedMessage
            defaultMessage="or"
            id="Kf7G0w"
            description="Or text"
          />
        </div>

        <Button
          fullWidth
          centerText
          appearance="tertiary"
          onClick={() => {
            navigate({ to: "/sso" });
          }}
        >
          <FormattedMessage
            defaultMessage="Sign in with SSO"
            id="aXeUnV"
            description="Text on button to sign in with SSO"
          />
        </Button>
      </FormLayout>
    </Form>
  );
}
