import { z } from "zod";

export const AwsFeatureResponseSchema = z.object({
  guardDutyEnabled: z.boolean(),
  inspectorEnabled: z.boolean(),
  is_aws_storage_scan_available: z.boolean(),
  aws_flow_logs_s3_feature_enabled: z.boolean(),
  flowlogsEnabled: z.boolean(),
  is_pcn_security_service_enabled: z.boolean(),
  is_bc_service_enabled: z.boolean(),
  cloudtrailEnabled: z.boolean(),
  awsPartitionsEnabled: z.array(z.string()),
  is_aws_event_bridge_enabled: z.boolean(),
  aws_audit_logs_s3_read_write_onboarding_enabled: z.boolean(),
});
