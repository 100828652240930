import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const IntegrationsSplunkIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Splunk",
    id: "GS0p7Y",

    description: "Splunk icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.738 89.7861H22.8342C15.5615 89.7861 10 84.2246 10 76.9518V23.0481C10 15.7754 15.5615 10.2139 22.8342 10.2139H77.1658C84.4385 10.2139 90 15.7754 90 23.0481V77.3796C89.5722 84.2246 84.0107 89.7861 76.738 89.7861Z"
        className="fill-[#77B539] dark:fill-none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5294 29.4648V37.5932L66.4706 48.7162L33.5294 62.8338V70.5344L72.0321 54.2777V43.1547L33.5294 29.4648Z"
        className="fill-white dark:fill-none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8342 89.7861H76.738C84.0107 89.7861 89.5722 84.2246 90 77.3796V23.0481C90 15.7754 84.4385 10.2139 77.1658 10.2139H22.8342C15.5615 10.2139 10 15.7754 10 23.0481V76.9518C10 84.2246 15.5615 89.7861 22.8342 89.7861ZM33.5294 37.5932V29.4648L72.0321 43.1547V54.2777L33.5294 70.5344V62.8338L66.4706 48.7162L33.5294 37.5932Z"
        className="fill-none dark:fill-white"
      />
    </SvgIcon>
  );
};
