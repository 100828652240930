import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Bold, Link } from "ui";
import {
  ACCOUNT_TYPE,
  FEATURES,
  FLOW_LOG_BUCKET,
  GCP,
  GCP_AUTHENTICATION_TYPE,
  MASTER_SERVICE_ACCOUNT,
  NAME,
  ORGANIZATION,
  ORG_ID,
  PROJECT_ID,
  REMEDIATION,
  SERVICE_ACCOUNT,
  WORKSPACE_DOMAIN,
} from "../../../../../../../../constants";

import { useFormContext } from "react-hook-form";
import { isEmpty } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { useWizardContext } from "../../../../../../../../../components/Wizard";
import DownloadTerraformScript from "../../../../../../../../components/DownloadTerraformScript";
import { useGetSupportedFeaturesList } from "../../../../../../../../hooks";
import { type GcpGetStartedStepValuesType } from "../../../../../../../../types";
import InstructionsSidecar from "../../../../../../components/InstructionsSidecar";
import { getInstructions } from "./instructions";

export default function DownloadTerraform() {
  const intl = useIntl();
  const { watch } = useFormContext();
  const {
    state: { steps },
  } = useWizardContext();

  const [isOpen, setOpen] = useState(false);

  const { accountType = "account", securityCapabilities = {} } = steps[0]
    .values as GcpGetStartedStepValuesType;

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: GCP,
    payload: {
      accountType,
    },
    options: {
      enabled: accountType !== WORKSPACE_DOMAIN, //TODO: remove this after API support
    },
  });

  const features = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeaturesList.includes(feature as SecurityCapabilitiesType),
    );

  const projectId = watch("projectId");
  const isMSA = accountType !== ORGANIZATION && watch("autoOnboardProjects");
  const authenticationType = watch("authenticationType");
  const flowLogStorageBucket = watch("flowLogStorageBucket");
  const organizationId = watch("organizationId");
  const name = watch("name");
  const remediation = watch("remediation");
  const isServiceAccount = authenticationType === SERVICE_ACCOUNT;

  const disabled =
    isEmpty(accountType === ORGANIZATION ? organizationId : projectId) ||
    isEmpty(authenticationType) ||
    isEmpty(name);

  const instructions = getInstructions(intl, isServiceAccount);

  const requestData = useMemo(() => {
    const selectedAccountType = isMSA ? MASTER_SERVICE_ACCOUNT : accountType;
    const payload: Record<string, unknown> = {
      [ACCOUNT_TYPE]: selectedAccountType,
      [NAME]: name,
      [GCP_AUTHENTICATION_TYPE]: authenticationType,
      [FEATURES]: remediation ? [...features, REMEDIATION] : features,
    };

    if (flowLogStorageBucket) {
      payload[FLOW_LOG_BUCKET] = flowLogStorageBucket;
    }

    if (accountType === ORGANIZATION) {
      payload[ORG_ID] = organizationId;
    } else {
      payload[PROJECT_ID] = projectId;
    }
    return payload;
  }, [
    accountType,
    authenticationType,
    features,
    flowLogStorageBucket,
    isMSA,
    name,
    organizationId,
    projectId,
    remediation,
  ]);

  return (
    <section className="space-y-1">
      <div className="mb-2">
        <Bold size="sm">
          <FormattedMessage
            defaultMessage="Download the terraform and complete the steps to configure account."
            id="4msnrp"
            description="Instructions for downloading the terraform button"
          />
        </Bold>
      </div>

      <DownloadTerraformScript
        endPoint="/cas/v1/gcp_template"
        isDownloadDisabled={disabled}
        payload={requestData}
        method={"post"}
        fileName={`prisma-cloud-gcp-terraform-${Math.floor(
          Date.now() / 1000,
        )}.tf.json`}
      />
      <div className="text-2xs">
        <FormattedMessage
          defaultMessage="<a>View Steps</a> for more information"
          id="AO0igu"
          description="Open help section"
          values={{
            a: (chunks) => (
              <Link
                data-selector="config-helper-open-sidecar-instructions"
                size="sm"
                onClick={() => setOpen(true)}
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </div>
      <InstructionsSidecar
        instructions={instructions}
        isOpen={isOpen}
        setOpen={setOpen}
        title={intl.formatMessage({
          defaultMessage: "Download Terraform script",
          id: "bKpjxQ",
          description: "Instructions for downloading the terraform script",
        })}
      />
    </section>
  );
}
