import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis/jsonApi";
import { ConfigureNotificationIntegrationResponse } from "./schemas";

export const alertRuleConfigureNotificationKeys = {
  all: [{ scope: "alertRuleConfigureNotification" }] as const,
  amazonSQS: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "amazonSQS",
        tenantId,
      },
    ] as const,
  amazonS3: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "amazonS3",
        tenantId,
      },
    ] as const,
  awsSDL: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "awsSDL",
        tenantId,
      },
    ] as const,
  awsSecurityHub: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "awsSecurityHub",
        tenantId,
      },
    ] as const,
  azureBusQueue: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "azureBusQueue",
        tenantId,
      },
    ] as const,
  cortexXSOAR: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "cortexXSOAR",
        tenantId,
      },
    ] as const,
  googleCSCC: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "googleCSCC",
        tenantId,
      },
    ] as const,
  microsoftTeams: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "microsoftTeams",
        tenantId,
      },
    ] as const,
  pagerDuty: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "pagerDuty",
        tenantId,
      },
    ] as const,
  slack: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "slack",
        tenantId,
      },
    ] as const,
  snowflake: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "snowflake",
        tenantId,
      },
    ] as const,
  splunk: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "splunk",
        tenantId,
      },
    ] as const,
  webhook: ({ tenantId }: { tenantId: string }) =>
    [
      {
        ...alertRuleConfigureNotificationKeys.all[0],
        entity: "webhook",
        tenantId,
      },
    ] as const,
};

export const getAmazonSQSIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["amazonSQS"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=amazon_sqs&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getAmazonS3Integration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["amazonS3"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=aws_s3&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getAwsSDLIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["awsSDL"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=aws_sdl&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getAWSSecurityHubIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["awsSecurityHub"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=aws_security_hub&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getAzureServiceBusIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["azureBusQueue"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=azure_service_bus_queue&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getCortexXSOARIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["cortexXSOAR"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=demisto&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getGoogleCSCCIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["googleCSCC"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=google_cscc&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getMicrosoftTeamsIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["microsoftTeams"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=microsoft_teams&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getPagerDutyIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["pagerDuty"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=pager_duty&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getSlackIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["slack"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=slack&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getSnowflakeIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["snowflake"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=snowflake&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getSplunkIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["splunk"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=splunk&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};

export const getWebhookIntegration = async ({
  queryKey: [{ tenantId }],
}: QueryFunctionContext<
  ReturnType<(typeof alertRuleConfigureNotificationKeys)["webhook"]>
>) => {
  return jsonApi({
    path: `api/v1/tenant/${tenantId}/integration?type=webhook&onlyEnabled=false`,
    requestSchema: z.void(),
    responseSchema: z.array(ConfigureNotificationIntegrationResponse),
  });
};
