export function AnimatedBackground() {
  return (
    <div
      className="absolute h-full w-full bg-[#23424D] backdrop-blur-md motion-safe:animate-background-rotate motion-reduce:hidden"
      style={{
        backgroundImage:
          "radial-gradient(at 81.5% 80.6%, hsl(196, 37%, 22%) 0px, transparent 50%), radial-gradient(at 52.5% 28.6%, hsl(210, 25%, 3%) 0px, transparent 50%), radial-gradient(at 48.0% 9.0%, hsl(249, 18%, 8%) 0px, transparent 50%), radial-gradient(at 6.0% 38.0%, hsl(246, 12%, 16%) 0px, transparent 50%)",
      }}
    ></div>
  );
}
