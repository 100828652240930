import { SvgIcon, type SvgIconProps } from "../SvgIcon";

import { useIntl } from "react-intl";
import { classNames } from "utils";

export const ServerlessFunctionScanningIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Serverless Function Scanning",
    id: "ZcSkww",

    description: "Security Capabilities Class Icon alt text",
  });

  // eslint-disable-next-line react/prop-types
  const { disabled, ...passThrough } = props;
  // eslint-disable-next-line tailwindcss/no-custom-classname
  const iconClassName = classNames(
    "dark:fill-white",
    `fill-[${disabled ? "#DADBDB" : "#7A7B7B"}]`,
  );

  return (
    <SvgIcon viewBox="0 0 17 17" {...passThrough}>
      <title>{alt}</title>
      <path
        className={iconClassName}
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4572 16 8C16 3.54279 12.4572 0 8 0ZM12.0572 4.05721C11.9012 4.2162 11.6909 4.31042 11.4684 4.32103C11.2459 4.33164 11.0276 4.25785 10.8572 4.11442C10.6856 3.94279 10.6284 3.77164 10.6284 3.54279C10.6284 3.42836 10.6284 3.31442 10.6856 3.2C10.5639 3.26463 10.4644 3.36428 10.4 3.48606C10.0572 3.88558 9.77164 4.45721 9.6 5.25721H11.0284L10.5716 7.02836H9.2L8.68606 9.02836C8.34279 10.3428 8.05721 11.2572 7.71442 11.8856C7.37164 12.5144 6.97164 12.9716 6.57164 13.2572C6.11442 13.5433 5.6 13.7144 5.02836 13.7144C4.62836 13.7144 4.34279 13.6572 4.11442 13.4856C3.82836 13.2572 3.77164 13.0284 3.77164 12.8C3.77164 12.5716 3.88558 12.3428 4.05721 12.1716C4.22836 12 4.45721 11.9428 4.74279 11.9428C4.97164 11.9428 5.2 12 5.31442 12.1716C5.42836 12.2856 5.54279 12.4572 5.54279 12.6284L5.71442 12.2856C5.77164 12.1144 5.94279 11.6572 6.11442 10.8572L7.14279 6.97164H5.77164L6.22836 5.2H6.97164C7.31442 5.2 7.42836 5.14279 7.48558 5.14279C7.54279 5.14279 7.65721 5.02836 7.82836 4.68558C8.28558 3.82836 8.74279 3.25721 9.25721 2.85721C9.77115 2.45721 10.4 2.28558 11.0284 2.28558C11.4284 2.28558 11.7716 2.4 12 2.57164C12.2284 2.74279 12.3428 3.02836 12.3428 3.31442C12.3428 3.65721 12.2289 3.88558 12.0572 4.05721Z"
        fill="#707070"
      />
    </SvgIcon>
  );
};
