import {
  useCallback,
  useState,
  type ReactEventHandler,
  type ReactNode,
} from "react";

import { Banner, type BannerAppearance } from "ui";

type AlertProp = {
  description: ReactNode;
  appearance: BannerAppearance;
  showIcon: boolean;
  closable: boolean;
  dataSelector?: string;
};

export default function Alert({
  description,
  appearance,
  showIcon,
  closable,
  dataSelector,
}: AlertProp) {
  const [show, setState] = useState(true);
  const setClose: ReactEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      setState(false);
    },
    [setState],
  );

  return show ? (
    <Banner
      data-selector={dataSelector}
      appearance={appearance}
      showIcon={showIcon}
      onClose={closable ? setClose : undefined}
    >
      {description}
    </Banner>
  ) : null;
}
