import { type Draft } from "immer";

import { type FilterObject, type GlobalState } from "../../types";
import { type InitAction } from "../actions";

export const defaultFilterState = {
  defaultFilters: {},
  filters: {},
  displayedFilters: [],
};

export const init = (draft: Draft<GlobalState>, action: InitAction): void => {
  const { defaultState, filterConfig, initialFilters, name } = action.payload;

  const initialFilterKeys = Object.keys(initialFilters);
  const hasInitialFilters = !!Object.keys(initialFilters).length;
  const displayedFilters: string[] = defaultState?.displayedFilters
    ? [...(defaultState?.displayedFilters ?? [])]
    : [];
  const filters: FilterObject = {};

  if (draft[name]?.initialized && !hasInitialFilters) return;

  filterConfig.forEach((filter) => {
    if (hasInitialFilters) {
      if (initialFilterKeys.includes(filter.name)) {
        pushUnique(displayedFilters, filter.name);
        const filterValues = initialFilters[filter.name];
        filters[filter.name] = Array.isArray(filterValues)
          ? Array.from(new Set(filterValues))
          : filterValues;
      } else if (filter.required) {
        pushUnique(displayedFilters, filter.name);
        filters[filter.name] = filter.defaultValues;
      } else {
        if (filter.static) pushUnique(displayedFilters, filter.name);
      }
    } else {
      if (defaultState?.filters?.[filter.name]) {
        filters[filter.name] = defaultState?.filters?.[filter.name];
        pushUnique(displayedFilters, filter.name);
      } else if (!filter.defaultValues) {
        if (filter.static) pushUnique(displayedFilters, filter.name);
      } else {
        pushUnique(displayedFilters, filter.name);
        filters[filter.name] = filter.defaultValues;
      }
    }
  });

  draft[name] = {
    config: filterConfig,
    displayedFilters,
    filters: filters,
    initialized: true,
  };
};

const pushUnique = (arr: string[], item: string) => {
  if (arr.includes(item)) return;
  arr.push(item);
};
