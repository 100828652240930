import { FormattedMessage } from "react-intl";

import { DangerIcon, SuccessIcon } from "icons";
import { Button } from "ui";

type ValidateButtonPropTypes = {
  isCreatingScript: boolean;
  isError: boolean;
  isValidating: boolean;
  settled: boolean;
  validateClTrail: () => void;
  validated: boolean;
  wizardDataIsIncomplete: boolean;
};

export default function ValidateButton({
  isCreatingScript,
  isError,
  isValidating,
  settled,
  validateClTrail,
  validated,
  wizardDataIsIncomplete,
}: ValidateButtonPropTypes) {
  const showBaseButton = !settled;
  const showInvalidButton = !showBaseButton && !validated;
  const showValidButton = !showBaseButton && validated;

  const baseButtonDisabled =
    isCreatingScript || isError || wizardDataIsIncomplete;

  if (showBaseButton) {
    return (
      <Button
        appearance="secondary"
        disabled={baseButtonDisabled}
        isLoading={isValidating}
        onClick={validateClTrail}
      >
        <FormattedMessage
          defaultMessage="Validate Setup"
          id="EGnRkW"
          description="Button text in Account Onboarding Wizard"
        />
      </Button>
    );
  } else if (showValidButton) {
    return (
      <Button
        appearance="secondary"
        addClassName="text-green"
        icon={<SuccessIcon />}
      >
        <FormattedMessage
          defaultMessage="Script Validated"
          id="4qZwLT"
          description="Button text when validation is successful"
        />
      </Button>
    );
  } else if (showInvalidButton) {
    return (
      <Button
        appearance="secondary"
        addClassName="text-red"
        icon={<DangerIcon />}
        onClick={validateClTrail}
      >
        <FormattedMessage
          defaultMessage="Script Invalid"
          id="EBd8zp"
          description="Button text when validation is unsuccessful"
        />
      </Button>
    );
  } else {
    return null;
  }
}
