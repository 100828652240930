import { InfoTooltipIcon } from "icons";
import { type IntlShape } from "react-intl";
import { Tooltip } from "ui";
import {
  additionalAttributesMessages,
  additionalAttributesTooltipMessages,
} from "./messages";

export const ACCOUNT_ADMIN = "Account Group Admin";
export const ACCOUNT_AND_CLOUD_PROVISIONING_ADMIN =
  "Account and Cloud Provisioning Admin";
export const ACCOUNT_GROUP_READ_ONLY = "Account Group Read Only";
export const CLOUD_PROVISIONING_ADMIN = "Cloud Provisioning Admin";
export const BUILD_AND_DEPLOY_SECURITY = "Build and Deploy Security";
export const SYSTEM_ADMIN = "System Admin";
export const SYSTEM_AUDITOR = "System Auditor";
export const NETSECOPS = "NetSecOps";
export const DEVELOPER = "Developer";
export const ONLY_ALLOW_COMPUTE_ACCESS_ROLES = [
  SYSTEM_ADMIN,
  ACCOUNT_ADMIN,
  ACCOUNT_GROUP_READ_ONLY,
  ACCOUNT_AND_CLOUD_PROVISIONING_ADMIN,
];
export const RESTRICT_DISMISSAL_ROLES = [
  ACCOUNT_ADMIN,
  ACCOUNT_AND_CLOUD_PROVISIONING_ADMIN,
];
export const RESTRICT_HAS_DEFENDER_PERMISSIONS = [
  BUILD_AND_DEPLOY_SECURITY,
  CLOUD_PROVISIONING_ADMIN,
  SYSTEM_ADMIN,
  NETSECOPS,
];
export const NETSECOPS_ROLES = [NETSECOPS];
export const HAS_ADVANCED_OPTIONS_ROLES = [
  ...RESTRICT_DISMISSAL_ROLES,
  BUILD_AND_DEPLOY_SECURITY,
  SYSTEM_ADMIN,
  NETSECOPS,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByKey = (data: any[], key: string) => {
  return data.sort((objA, objB) => {
    const a = objA[key].toLowerCase();
    const b = objB[key].toLowerCase();

    return a < b ? -1 : a > b ? 1 : 0;
  });
};

export const hideRepositories = (
  role: string,
  additionalAttributes: {
    onlyAllowCIAccess: boolean;
    onlyAllowComputeAccess: boolean;
  },
) => {
  const HIDE_REPOSITORY_COMPUTE_ACCESS = [
    "Account Group Admin",
    "Account Group Read Only",
    "Account and Cloud Provisioning Admin",
  ];
  const BUILD_AND_DEPLOY_SECURITY = "Build and Deploy Security";

  return (
    (role === BUILD_AND_DEPLOY_SECURITY &&
      !additionalAttributes?.onlyAllowCIAccess) ||
    (HIDE_REPOSITORY_COMPUTE_ACCESS.includes(role) &&
      additionalAttributes?.onlyAllowComputeAccess)
  );
};

export const additionalAttributesItems = (intl: IntlShape, type: string) => {
  const tooltipLabel = intl.formatMessage(
    additionalAttributesTooltipMessages[
      type as keyof typeof additionalAttributesTooltipMessages
    ],
  );
  return (
    <div className="flex w-fit flex-row items-center justify-center">
      <span className="pr-4">
        {intl.formatMessage(
          additionalAttributesMessages[
            type as keyof typeof additionalAttributesMessages
          ],
        )}
      </span>
      <Tooltip label={tooltipLabel}>
        <span>
          <InfoTooltipIcon aria-label={tooltipLabel} />
        </span>
      </Tooltip>
    </div>
  );
};

export const getAdditionalAttributesList = (
  roleType: string,
  intl: IntlShape,
  computeAllowed: boolean,
) => {
  return [
    ...(RESTRICT_DISMISSAL_ROLES.includes(roleType)
      ? [
          {
            value: "restrictDismissalAccess",
            children: additionalAttributesItems(
              intl,
              "restrictDismissalAccess",
            ),
          },
        ]
      : []),
    ...(roleType === BUILD_AND_DEPLOY_SECURITY
      ? [
          {
            value: "onlyAllowCIAccess",
            children: additionalAttributesItems(intl, "onlyAllowCIAccess"),
          },
        ]
      : []),
    ...(ONLY_ALLOW_COMPUTE_ACCESS_ROLES.includes(roleType)
      ? [
          {
            value: "onlyAllowComputeAccess",
            children: additionalAttributesItems(intl, "onlyAllowComputeAccess"),
          },
        ]
      : []),
    ...(roleType &&
    roleType !== DEVELOPER &&
    !RESTRICT_HAS_DEFENDER_PERMISSIONS.includes(roleType) &&
    computeAllowed
      ? [
          {
            value: "hasDefenderPermissions",
            children: additionalAttributesItems(intl, "hasDefenderPermissions"),
          },
        ]
      : []),
    ...(roleType === NETSECOPS
      ? [
          {
            value: "onlyAllowReadAccess",
            children: additionalAttributesItems(intl, "onlyAllowReadAccess"),
          },
        ]
      : []),
  ];
};
