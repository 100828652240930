import { type ReactElement } from "react";
import { useFormContext, type RegisterOptions } from "react-hook-form";
import {
  RadioInput as ReactRadioInput,
  type RadioInputProps as ReactRadioInputProps,
} from "ui";

export interface RadioInputProps extends Omit<ReactRadioInputProps, "ref"> {
  name: string;
  /**
   * Options to pass to the react-hook-form register.
   */
  register?: RegisterOptions;
}

/**
 * For most cases, prefer `RadioGroup`. `RadioInput` gives lower level access for custom layouts and use cases not covered by `RadioGroup`.
 *
 * Please note - if using a `RadioInput` in a Portal (Modal), there is an issue with the timing surrounding the Portal's parent wrapper being attached to the DOM and the registration of the `RadioInput` form fields. See the storybook for workarounds if you absolutely must use `RadioInput`s outside of a `RadioGroup`.
 */
export function RadioInput({
  name,
  register: registerOptions,
  ...rest
}: RadioInputProps): ReactElement {
  const { register } = useFormContext();
  const { onBlur, onChange, ...formRegister } = register(name, registerOptions);

  return (
    <ReactRadioInput
      {...formRegister}
      {...rest}
      onBlur={(event) => {
        onBlur(event);
        rest.onBlur?.(event);
      }}
      onChange={(event) => {
        onChange(event);
        rest.onChange?.(event);
      }}
    />
  );
}
