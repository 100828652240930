import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";

type DataSecurityProps = {
  data?: SecurityCapabilitiesPermissionsStatus;
  isCollapsibleDetails?: boolean;
};

export default function DataSecurity({
  data,
  isCollapsibleDetails = true,
}: DataSecurityProps) {
  if (isNil(data)) return null;

  return (
    <CollapsibleDetails
      icon={<SecurityCapabilitiesIcon type="Data Security" />}
      label="data_security"
      isCollapsibleDetails={isCollapsibleDetails}
      {...data}
    />
  );
}
