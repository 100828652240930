import type * as Sentry from "@sentry/react";
import {
  type BreadcrumbOptions,
  type Exception,
  type MonitoringTool,
  type ReportOptions,
  type Scope,
  type Tags,
} from "../../MonitoringTool";

export class SentryAdapter implements MonitoringTool {
  private sentry;

  constructor(sentry: typeof Sentry) {
    this.sentry = sentry;
  }

  setupScope({ user, tags }: Scope) {
    if (user) this.sentry.setUser(user);
    if (tags) this.setTags(tags);
  }

  setTags(tags: Tags) {
    this.sentry.setTags(tags);
  }

  leaveBreadcrumb(message: string, options: BreadcrumbOptions = {}) {
    const { logLevel, extraInfo, subSystem } = options;
    this.sentry.addBreadcrumb({
      message,
      level: logLevel,
      data: extraInfo,
      category: subSystem,
    });
  }

  reportException(exception: Exception, options: ReportOptions = {}) {
    const { extraInfo, attachments } = options;
    this.sentry.captureException(exception, (scope) => {
      if (extraInfo) scope.setExtra("extraInfo", extraInfo);
      if (attachments)
        attachments.forEach((attachment) => scope.addAttachment(attachment));
      return scope;
    });
  }

  reportMessage(message: string, options: ReportOptions = {}) {
    const { extraInfo, attachments } = options;
    this.sentry.captureMessage(message, (scope) => {
      if (extraInfo) scope.setExtra("extraInfo", extraInfo);
      if (attachments)
        attachments.forEach((attachment) => scope.addAttachment(attachment));
      return scope;
    });
  }
}
