import { Route, redirect } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { homeLayoutRoute } from "../homeLayoutRoute";

const HomeCloudLayout = lazy(() =>
  import("./HomeCloudLayout").then((res) => ({
    default: res.HomeCloudLayout,
  })),
);

export const homeCloudLayoutRoute = new Route({
  getParentRoute: () => homeLayoutRoute,
  path: "cloud",
  component: HomeCloudLayout,
  validateSearch: z.object({
    skipAnimation: z.optional(z.boolean()),
  }),
});
export const homeCloudIndexRoute = new Route({
  getParentRoute: () => homeCloudLayoutRoute,
  path: "/",

  beforeLoad: ({ search }) => {
    throw redirect({ to: "/home/cloud/welcome", replace: true, search });
  },
});
