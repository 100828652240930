import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

const CasIntegrationRoutes = lazy(
  () => import("../../code/CasIntegrationRoutes"),
);

const ProvidersLayout = lazy(() =>
  import("./ProvidersLayout").then((res) => ({
    default: res.ProvidersLayout,
  })),
);

export const settingsProvidersRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "/providers",
  component: () => (
    <>
      <CasIntegrationRoutes />
      <ProvidersLayout />
    </>
  ),
});
