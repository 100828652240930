import { forwardRef } from "react";
import { classNames, noop } from "utils";
import { CheckboxInput } from "../Checkbox";
import { Tooltip } from "../Tooltip";
import { TruncateText } from "../TruncateText";

import {
  type ComponentPropsWithRef,
  type MouseEvent,
  type ReactNode,
} from "react";
import { type TooltipProps } from "../Tooltip";

export interface ListItemProps
  extends Omit<ComponentPropsWithRef<"li">, "className"> {
  active?: boolean;
  addClassName?: string;
  hoverClassName?: string;
  checked?: boolean;
  children: ReactNode;
  disabled?: boolean;
  hasCheckbox?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  indeterminate?: boolean;
  tooltip?: Omit<TooltipProps, "children">;
  onClick?: (event: MouseEvent<HTMLLIElement>) => void;
  hasCustomListItem?: boolean;
}

/**
 * A ListItem, expected to be used as children of `List`.
 */
export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      addClassName = "",
      hoverClassName = "hover:bg-blue-100 cursor-pointer dark:hover:bg-blue-steel-850",
      children,
      disabled = false,
      active = false,
      hasCheckbox = false,
      checked = false,
      indeterminate = false,
      iconLeft,
      iconRight,
      tooltip,
      hasCustomListItem,
      ...passThrough
    }: ListItemProps,
    ref,
  ) => {
    const className = classNames(
      "flex w-full items-center space-x-1 px-4 py-2 text-xs",
      "border-b last:border-b-0 dark:border-blue-steel-850",
      disabled
        ? "cursor-not-allowed text-disabled dark:text-dark-bg-disabled"
        : classNames("text-default dark:text-dark-bg", hoverClassName),
      active
        ? "bg-blue-100 dark:bg-blue-steel-850"
        : checked && !hasCheckbox
          ? "bg-blue-200 dark:bg-blue-steel-970"
          : "bg-white dark:bg-blue-steel-950",
      addClassName,
    );
    const iconClasses = "inline-flex flex-none items-center text-base";

    const listItem = (
      <li {...{ disabled }} {...passThrough} className={className} ref={ref}>
        {hasCheckbox && (
          <CheckboxInput
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            tabIndex={-1}
            onChange={noop}
          />
        )}
        {iconLeft && (
          <span className={iconClasses} aria-hidden>
            {iconLeft}
          </span>
        )}
        {hasCustomListItem ? (
          children
        ) : (
          <TruncateText addClassName="flex-auto">{children}</TruncateText>
        )}
        {iconRight && (
          <span className={iconClasses} aria-hidden>
            {iconRight}
          </span>
        )}
      </li>
    );

    return tooltip ? <Tooltip {...tooltip}>{listItem}</Tooltip> : listItem;
  },
);
ListItem.displayName = "ListItem";
