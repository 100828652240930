import { useEffect, useRef } from "react";
/**
 * Returns a ref that indicates if the component is render for first time.
 */
export function useIsRenderedFirstTime() {
  const isRenderedForFirstTimeRef = useRef(true);
  useEffect(() => {
    isRenderedForFirstTimeRef.current = false;
  }, []);
  return isRenderedForFirstTimeRef.current;
}
