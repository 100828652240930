import invariant from "tiny-invariant";
import isIconOnly from "./isIconOnly";

import { type ButtonProps } from "./Button";

export default function validateProps(props: ButtonProps): void {
  const { children, appearance, icon, isMenu, size } = props;

  invariant(
    isIconOnly(props)
      ? !!props["aria-label"] || !!props["aria-labelledby"]
      : true,
    "`aria-label` or `aria-labelledby` is required for icon-only buttons.",
  );

  invariant(
    !(appearance === "danger" && icon),
    "Button appearance `danger` doesn't support an `icon`.",
  );
  invariant(
    !(appearance === "danger" && isMenu),
    "Button appearance `danger` doesn't support `isMenu`.",
  );
  invariant(
    !(appearance === "danger" && size === "lg"),
    "Button appearance `danger` doesn't support `size` `lg`.",
  );
  invariant(
    !(appearance === "primary" && icon && isMenu),
    "Button appearance `primary` doesn't support both an `icon` and `isMenu`.",
  );
  invariant(
    !(appearance === "primary" && !children),
    "Button appearance `primary` requires children.",
  );
}
