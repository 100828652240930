import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Card, CardHeader } from "ui";
import { PermissionGroupCardBody } from "../PermissionGroupCardBody";
import { type PermissionGroupMap } from "../PermissionGroupsSidecar";

type ComputeCardProps = {
  featureMap: PermissionGroupMap;
};

export const computeFeatureKeys = [
  "computeRadarsCloud",
  "computeRadarsHosts",
  "computeRadarsContainers",
  "computeRadarsServerless",
  "computePolicyCodeRepos",
  "computePolicyContainers",
  "computePolicyHosts",
  "computePolicyServerless",
  "computePolicyComplianceCustomRules",
  "computePolicyRuntimeContainer",
  "computePolicyRuntimeHosts",
  "computePolicyRuntimeServerless",
  "computePolicyWAAS",
  "computePolicyCNNF",
  "computePolicyAccessDocker",
  "computePolicyAccessSecrets",
  "computePolicyAccessKubernetes",
  "computePolicyCustomRules",
  "computeMonitorVuln",
  "computeMonitorCompliance",
  "computeMonitorCodeRepos",
  "computeMonitorImages",
  "computeMonitorHosts",
  "computeMonitorServerless",
  "computeMonitorCI",
  "computeMonitorRuntimeContainers",
  "computeMonitorRuntimeHosts",
  "computeMonitorRuntimeServerless",
  "computeMonitorRuntimeIncidents",
  "computeSandbox",
  "computeMonitorWAAS",
  "computeMonitorCNNF",
  "computeMonitorAccessDocker",
  "computeMonitorAccessKubernetes",
  "computeUIEventSubscriber",
  "computePolicyCloud",
  "computeMonitorCloud",
  "computeSystemLogs",
  "computeManageDefenders",
  "computeManageAlerts",
  "computeCollections",
  "computeManageCreds",
  "computeAuth",
  "computeSystemOperations",
  "computePrivilegedOperations",
  "computeDownloads",
] as const;

export function ComputeCard({ featureMap }: ComputeCardProps) {
  const intl = useIntl();

  const features = useMemo(
    () =>
      computeFeatureKeys
        .filter((key) => !!featureMap[key])
        .map((key) => featureMap[key]),
    [featureMap],
  );

  if (!features.length) return null;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Compute",
          id: "VQ3tLg",
          description: "Permission Group sidecar card header title",
        })}
      />
      <PermissionGroupCardBody features={features} />
    </Card>
  );
}
