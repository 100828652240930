import { fetchAndDownloadFile } from "../../../../utils";

export const downloadDynamicTerraform = async (
  payload: Record<string, unknown>,
) => {
  const response = await fetchAndDownloadFile({
    endPoint: `cas/v1/ibm_template`,
    fileName: `prisma-cloud-ibm-terraform-${Math.floor(
      Date.now() / 1000,
    )}.tf.json`,
    payload,
  });
  const responseHeaders: Record<string, unknown> = {};
  response.headers.forEach((val, key) => (responseHeaders[key] = val));
  return responseHeaders;
};
