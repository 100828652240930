import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const GcpIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "GCP",
    id: "iluT+k",

    description: "cloud icon alt text",
  });

  return (
    <SvgIcon aria-label={alt} viewBox="0 0 34 28" {...props}>
      {showNativeTooltip && <title>{alt}</title>}
      <path
        className="fill-[#EA4335] dark:fill-[#FAFAFA]"
        d="M21.85,7.41l1,0,2.85-2.85.14-1.21A12.81,12.81,0,0,0,5,9.6a1.55,1.55,0,0,1,1-.06l5.7-.94s.29-.48.44-.45a7.11,7.11,0,0,1,9.73-.74Z"
      />
      <path
        className="fill-[#4285F4] dark:fill-[#D0D0D0]"
        d="M29.76,9.6a12.84,12.84,0,0,0-3.87-6.24l-4,4A7.11,7.11,0,0,1,24.5,13v.71a3.56,3.56,0,1,1,0,7.12H17.38l-.71.72v4.27l.71.71H24.5A9.26,9.26,0,0,0,29.76,9.6Z"
      />
      <path
        className="fill-[#34A853] dark:fill-[#E3E3E3]"
        d="M10.25,26.49h7.12v-5.7H10.25a3.54,3.54,0,0,1-1.47-.32l-1,.31L4.91,23.63l-.25,1A9.21,9.21,0,0,0,10.25,26.49Z"
      />
      <path
        className="fill-[#FBBC05] dark:fill-[#BDBDBD]"
        d="M10.25,8A9.26,9.26,0,0,0,4.66,24.6l4.13-4.13a3.56,3.56,0,1,1,4.71-4.71l4.13-4.13A9.25,9.25,0,0,0,10.25,8Z"
      />
    </SvgIcon>
  );
};
