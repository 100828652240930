import { useIntl } from "react-intl";
import { Body, Title } from "../Typography";
import { NoResultsAvailableSVG } from "./NoResultsAvailableSVG";

import { type CSSProperties, type ReactElement, type ReactNode } from "react";
import { classNames } from "utils";

export interface EmptyStateLayoutProps {
  /**
   * Follow up actions the user can take to remedy the empty state.
   */
  actions?: ReactNode;
  /**
   * A description of why the empty state was reached.
   */
  description?: ReactNode;
  /**
   * A graphic.
   */
  graphic?: ReactNode;
  /**
   * A short description of the empty state.
   */
  heading?: ReactNode;
  /**
   * Generally used to constrain the size of the empty state graphic.
   */
  style?: CSSProperties;
  /**
   * Add `flex-grow`. Useful when used inside a flexbox.
   */
  grow?: boolean;
}

/**
 * Layout for an empty state graphic, heading, desciption, and follow up actions.
 */
export function EmptyStateLayout({
  actions,
  description,
  graphic,
  heading,
  style,
  grow,
}: EmptyStateLayoutProps): ReactElement {
  const intl = useIntl();
  const children = graphic || (
    <NoResultsAvailableSVG style={{ maxWidth: "15rem" }} />
  );
  return (
    <div className={classNames("flex justify-center", grow && "grow")}>
      <div
        className="flex w-3/4 flex-col items-center space-y-4 px-4 pt-4"
        style={style}
      >
        {children}
        <div className="text-center">
          <Title level={4} size="xxs">
            {heading ??
              intl.formatMessage({
                defaultMessage: "No Results Available",
                id: "Q58RPw",

                description: "No results available for section",
              })}
          </Title>
          {description && (
            <Body appearance="secondary" size="sm">
              {description}
            </Body>
          )}
        </div>
        <div>{actions}</div>
      </div>
    </div>
  );
}
