/* c8 ignore start */
import AvatarPersonaIcon1 from "../avatarIcons/AvatarPersonaIcon1.webp";
import AvatarPersonaIcon10 from "../avatarIcons/AvatarPersonaIcon10.webp";
import AvatarPersonaIcon11 from "../avatarIcons/AvatarPersonaIcon11.webp";
import AvatarPersonaIcon12 from "../avatarIcons/AvatarPersonaIcon12.webp";
import AvatarPersonaIcon13 from "../avatarIcons/AvatarPersonaIcon13.webp";
import AvatarPersonaIcon14 from "../avatarIcons/AvatarPersonaIcon14.webp";
import AvatarPersonaIcon15 from "../avatarIcons/AvatarPersonaIcon15.webp";
import AvatarPersonaIcon2 from "../avatarIcons/AvatarPersonaIcon2.webp";
import AvatarPersonaIcon3 from "../avatarIcons/AvatarPersonaIcon3.webp";
import AvatarPersonaIcon4 from "../avatarIcons/AvatarPersonaIcon4.webp";
import AvatarPersonaIcon5 from "../avatarIcons/AvatarPersonaIcon5.webp";
import AvatarPersonaIcon6 from "../avatarIcons/AvatarPersonaIcon6.webp";
import AvatarPersonaIcon7 from "../avatarIcons/AvatarPersonaIcon7.webp";
import AvatarPersonaIcon8 from "../avatarIcons/AvatarPersonaIcon8.webp";
import AvatarPersonaIcon9 from "../avatarIcons/AvatarPersonaIcon9.webp";
import NoPersonaIcon from "../avatarIcons/NoPersonaIcon.webp";

function icon(i: number) {
  switch (i) {
    case 0:
      return NoPersonaIcon;
    case 1:
      return AvatarPersonaIcon1;
    case 2:
      return AvatarPersonaIcon2;
    case 3:
      return AvatarPersonaIcon3;
    case 4:
      return AvatarPersonaIcon4;
    case 5:
      return AvatarPersonaIcon5;
    case 6:
      return AvatarPersonaIcon6;
    case 7:
      return AvatarPersonaIcon7;
    case 8:
      return AvatarPersonaIcon8;
    case 9:
      return AvatarPersonaIcon9;
    case 10:
      return AvatarPersonaIcon10;
    case 11:
      return AvatarPersonaIcon11;
    case 12:
      return AvatarPersonaIcon12;
    case 13:
      return AvatarPersonaIcon13;
    case 14:
      return AvatarPersonaIcon14;
    case 15:
      return AvatarPersonaIcon15;
    default:
      return;
  }
}

export default function AvatarIcon({
  iconToBeRendered,
  width = 100,
  height = 100,
}: {
  iconToBeRendered: number;
  width?: number;
  height?: number;
}) {
  return (
    <img
      src={icon(iconToBeRendered)}
      width={width}
      height={height}
      className="mt-1.5"
    />
  );
}
/* c8 ignore stop */
