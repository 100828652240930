import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { anomaliesLayoutRoute } from "../anomaliesLayoutRoute";

export const TrustedList = lazy(() => import("./TrustedList"));

export const trustedListRoute = new Route({
  getParentRoute: () => anomaliesLayoutRoute,
  path: "trusted-list",
  component: TrustedList,
});
