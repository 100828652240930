import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { settingsLayoutRoute } from "../settingsLayoutRoute";

export const IntegrationAndNotificationLayout = lazy(
  () => import("./IntegrationAndNotificationLayout"),
);

export const integrationAndNotificationRoute = new Route({
  getParentRoute: () => settingsLayoutRoute,
  path: "integrationAndNotification",
  component: IntegrationAndNotificationLayout,
});
