import { useQuery } from "@tanstack/react-query";
import { cloudAccountsKeys, getAzureCloudAccountsCas } from "requests";

type UseGetAzureCloudAccountsCasProps = {
  accountId: string;
  options?: Record<string, string | boolean>;
};

export const useGetAzureCloudAccountsCas = ({
  accountId,
  options = {},
}: UseGetAzureCloudAccountsCasProps) => {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.azureCloudAccountsCas({
      accountId,
    }),
    queryFn: getAzureCloudAccountsCas,
    retry: false,
    ...options,
  });

  return {
    data,
    error,
    isError,
    isLoading: isLoading && isFetching,
  };
};
