import { useQuery } from "@tanstack/react-query";
import {
  ADD_DETAILS,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  CUSTOM_CONFIGURATION,
  DATA_SECURITY,
  ORGANIZATION,
  REMEDIATION,
  SERVERLESS_SCAN,
} from "../../../../../constants";

import { isChina } from "environment";
import { LoadingIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import {
  getDlpStatus,
  tenantKeys,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { useDLPFeature } from "../../../../../../../../utils/useDLPFeatures";
import { useWizardContext } from "../../../../../../components/Wizard";
import {
  type AwsAddDetailsStepValues,
  type AwsCustomConfigurationStepValues,
  type Status,
} from "../../../../../types";
import { getSecurityCapabilitiesData } from "../../../../Onboarding/AwsOnboarding/ReviewStatus/utils";
import { getWizardStepIndex } from "../../../../Onboarding/utils";
import AWSSecurityCapabilitiesPermissions, {
  MultipleSecurityCapability,
  SingleSecurityCapability,
} from "../../../components/ReviewStatus/SecurityCapabilitiesPermissions";
import Header from "./Header";
type SecurityCapabilities = Exclude<
  SecurityCapabilitiesType,
  | "Cloud Visibility Compliance and Governance"
  | "Identity Security"
  | "Threat Detection"
  | "AWS Guard Duty"
  | "AWS Inspector"
>;
const featuresMap: Record<SecurityCapabilities, string> = {
  [AGENTLESS_SCAN]: "agentless_scanning",
  [AUTO_PROTECT]: "auto_protect",
  [DATA_SECURITY]: "storage_scan",
  [REMEDIATION]: "remediation",
  [SERVERLESS_SCAN]: "serverless_function_scanning",
  [AGENTLESS_API_DISCOVERY]: "agentless_api_discovery",
};

type accountDetails = AwsAddDetailsStepValues &
  AwsCustomConfigurationStepValues;

type SecurityCapabilitiesPermissionsProps = {
  accountStatus: {
    id: string;
    name: string;
    statusMessage: {
      message: string;
      staticMessage: boolean;
    };
    status: Status;
  }[];
  features: SecurityCapabilitiesFeatures[];
  isSubscribed?: boolean;
  isLoading?: boolean;
  isTempEdit?: boolean;
  accountDetails?: accountDetails;
};

export default function SecurityCapabilitiesPermissions({
  accountStatus,
  features,
  isSubscribed = false,
  isLoading = false,
  isTempEdit = false,
  accountDetails = {} as accountDetails,
}: SecurityCapabilitiesPermissionsProps) {
  const { agentlessApiDiscovery, cloudScanMode } = useFlags();
  const {
    state: { steps },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);
  const customConfigStepIndex = getStepIndex(CUSTOM_CONFIGURATION);

  const { accountType, partitionType, onboardType } = !isTempEdit
    ? (steps[addDetailsStepIndex].values as AwsAddDetailsStepValues)
    : accountDetails;
  const { securityCapabilities, orgSecurityCapabilities, mode } =
    (!isTempEdit
      ? (steps[customConfigStepIndex]
          ?.values as AwsCustomConfigurationStepValues)
      : accountDetails) ?? {};

  const getStartedStepValues = {
    accountType,
    partitionType,
    securityCapabilities,
    orgSecurityCapabilities,
    mode,
  };
  const isOrg = accountType === ORGANIZATION;
  const isAutomatedOnboarding = onboardType === "automated";

  const dlpHasPermission = useDLPFeature(true);
  const options = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: dlpHasPermission && !isAutomatedOnboarding,
  };
  const { data: dlpData } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    ...options,
  });
  const provisionStatus = dlpData?.status || "provisionNotStarted";
  const dlpReady = provisionStatus === "provisionSuccessful";

  const statusData = accountStatus.map((data) => ({
    ...data,
    message: data?.statusMessage?.message,
  }));

  const disabledFeatures = features
    .filter(({ state }) => state === "disabled")
    .map(({ name, state }) => ({
      name: featuresMap[name as SecurityCapabilities],
      status: state,
    }));

  const {
    misconfigurationsData,
    threatDetectionData,
    agentlessWorkloadScanningData,
    serverlessFunctionScanningData,
    agentBasedWorkloadProtectionData,
    dataSecurityData,
    remediationData,
    agentlessApiDiscoveryData,
  } = getSecurityCapabilitiesData(
    [...statusData, ...disabledFeatures],
    cloudScanMode,
    isOrg,
    getStartedStepValues,
  );

  const identitySecurityData = {
    status: (isSubscribed ? "enabled" : "disabled") as Status,
    name: "identitySecurity",
  };

  const misconfigurationNames = {
    config: "Asset Configuration",
    compute_discovery: "Workload Discovery",
    guard_duty: "GuardDuty",
    inspector: "Inspector",
    org: "Organization",
  };

  const sortedMisconfigurationsData = useMemo(
    () =>
      misconfigurationsData.sort((a, b) => {
        const aName =
          misconfigurationNames[a.name as keyof typeof misconfigurationNames] ??
          a.name;
        const bName =
          misconfigurationNames[b.name as keyof typeof misconfigurationNames] ??
          b.name;

        return aName < bName ? -1 : 1;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [misconfigurationsData],
  );

  return (
    <AWSSecurityCapabilitiesPermissions defaultOpen={true}>
      <Header />
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <MultipleSecurityCapability
            data={sortedMisconfigurationsData}
            type="Cloud Visibility Compliance and Governance"
            label="misconfigurations"
          />
          {!isChina() && (
            <SingleSecurityCapability
              data={identitySecurityData}
              type="Identity Security"
              label="identity_security"
            />
          )}
          <SingleSecurityCapability
            data={agentlessWorkloadScanningData}
            type="Agentless Scanning"
            label="agentless_workload_scanning"
          />
          <MultipleSecurityCapability
            data={threatDetectionData}
            type="Threat Detection"
            label="threat_detection"
          />
          <SingleSecurityCapability
            data={serverlessFunctionScanningData}
            type="Serverless Function Scanning"
            label="serverless_function_scanning"
          />
          {agentlessApiDiscovery && (
            <SingleSecurityCapability
              data={agentlessApiDiscoveryData}
              type="Agentless API Discovery"
              label="agentless_api_discovery"
            />
          )}
          <SingleSecurityCapability
            data={agentBasedWorkloadProtectionData}
            type="Auto Protect"
            label="auto_protect"
          />
          {dlpReady && (
            <SingleSecurityCapability
              data={dataSecurityData}
              type="Data Security"
              label="data_security"
            />
          )}
          {onboardType === "template" && (
            <SingleSecurityCapability
              data={remediationData}
              type="Remediation"
              label="remediation"
            />
          )}
        </>
      )}
    </AWSSecurityCapabilitiesPermissions>
  );
}
