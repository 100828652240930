import { lazy } from "react";
import { createRoute, privateRootRoute, redirect } from "router";
import { APPSEC_ROUTE } from "../code/consts";

const SettingsLayout = lazy(() =>
  import("./SettingsLayout").then((res) => ({
    default: res.SettingsLayout,
  })),
);

const CasCodeSecuritySettings = lazy(
  () => import("./appsecSettings/CasCodeSecuritySettings"),
);

export const settingsLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "settings",
  component: SettingsLayout,
});

export const casSettingsRoute = createRoute({
  getParentRoute: () => settingsLayoutRoute,
  path: APPSEC_ROUTE,
  component: CasCodeSecuritySettings,
});

export const casSettingsNestedRoute = createRoute({
  getParentRoute: () => casSettingsRoute,
  path: "$",
});

const CatchAll = lazy(() =>
  import("../CatchAll/CatchAll").then((res) => ({ default: res.CatchAll })),
);

export const settingsCatchAllRoute = createRoute({
  getParentRoute: () => settingsLayoutRoute,
  path: "$",
  component: CatchAll,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/settings/cloud_accounts") {
      throw redirect({
        to: "/settings/providers/cloud-accounts",
        replace: true,
        search,
      });
    } else if (pathname === "/settings/integrations") {
      throw redirect({
        to: "/settings/integrationAndNotification/integrations",
        replace: true,
        search,
      });
    }
  },
});
