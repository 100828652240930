import { type ReactNode } from "react";
import { useIntl } from "react-intl";
import { Body, Button, Subtitle } from "ui";

import { useWizard } from "./WizardContext";

export const ModalWizardStepSummary = <StepName extends string>({
  name,
  title,
  children,
  subTitle,
}: {
  name: StepName;
  title: ReactNode;
  children: ReactNode;
  subTitle?: ReactNode;
}) => {
  const intl = useIntl();
  const { setCurrentStepName } = useWizard();

  return (
    <dl className="flex flex-col space-y-2 text-xs">
      <div className="flex flex-col space-y-2 text-default dark:text-dark-bg">
        <div className="flex justify-between border-b border-gray-300 dark:border-blue-steel-850">
          <div className="flex flex-col">
            <Subtitle as="h4">{title}</Subtitle>
            {subTitle ?? (
              <Body appearance="secondary" size="sm" addClassName="my-1">
                {subTitle}
              </Body>
            )}
          </div>
          <Button
            addClassName="mb-1 mr-1"
            appearance="clear"
            onClick={() => setCurrentStepName(name)}
            size="sm"
          >
            {intl.formatMessage({
              defaultMessage: "Edit",
              id: "PjdmrT",

              description: "Label for a button to go to a form and edit it",
            })}
          </Button>
        </div>
        {children}
      </div>
    </dl>
  );
};
