/* c8 ignore start */
import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { homeLayoutRoute } from "../home/homeLayoutRoute";

export const ComputeLazy = lazy(() => import("./ComputeLayout"));

export const computeLayoutRoute = new Route({
  getParentRoute: () => homeLayoutRoute,
  path: "runtime",
  component: ComputeLazy,
  validateSearch: z.object({
    params: z.any().optional(),
    state: z.string().optional(),
    computeState: z.optional(z.string().catch("")),
  }),
});
/* c8 ignore stop */
