import {
  NextButton,
  PrevButton,
  WizardForm,
} from "../../../../../components/Wizard";

import { FormLayout } from "form";
import { ProductDocumentationLink } from "../../components";
import AccountDetails from "./AccountDetails";

type ConfigureAccountProps = {
  context: {
    closeModal: () => void;
    isEdit: boolean;
  };
};

export default function ConfigureAccount({
  context: { closeModal, isEdit },
}: ConfigureAccountProps) {
  return (
    <WizardForm
      addClassName="[&>div:nth-child(2)]:relative"
      actions={
        <>
          <PrevButton />
          <NextButton />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <AccountDetails closeModal={closeModal} isEdit={isEdit} />
      </FormLayout>
    </WizardForm>
  );
}
