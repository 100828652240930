import { memo, type ChangeEvent, type ReactNode } from "react";
import { Checkbox } from "ui";

type TreeNodeType = {
  treeId: string;
  icons: {
    expandClose: ReactNode;
    expandOpen: ReactNode;
    folder: ReactNode;
    folderOpen: ReactNode;
    file: ReactNode;
    spinner: ReactNode;
  };
  disabled: boolean;
  checked: number;
  expanded: boolean;
  loading: boolean;
  showCheckbox: boolean;
  isLeaf: boolean;
  isParent: boolean;
  label: string;
  showNodeIcon: boolean;
  icon: ReactNode;
  value: string;
  onCheck: (_props: { checked: boolean; value: string }) => void;
  onClick?: (_props: { checked: boolean; value: string }) => void;
  onExpand: (_props: { expanded: boolean; value: string }) => void;
  expandOnLabelClick: boolean;
  children: ReactNode;
  checkable?: boolean;
};

const defaultProps = {
  children: null,
  expandOnLabelClick: false,
  icon: null,
  loading: false,
  showCheckbox: true,
  value: "",
  label: "",
  onClick: () => undefined,
  onCheck: () => undefined,
  onExpand: () => undefined,
};

function TreeNode(props: TreeNodeType) {
  const onCheck = (_event: ChangeEvent<HTMLInputElement>) => {
    const { value, disabled } = props;

    if (disabled) return;

    props.onCheck({ value, checked: getCheckState({ toggle: true }) });
  };

  // Call this function on label click
  const onClick = () => {
    const { expandOnLabelClick, isParent, value, disabled, onClick } = props;

    if (disabled) return;

    // Auto expand if enabled
    if (isParent && expandOnLabelClick) {
      onExpand();
    }

    onClick && onClick({ value, checked: getCheckState({ toggle: false }) });
  };

  // Call this function on collapse/expand button click
  const onExpand = () => {
    const { expanded, value } = props;

    props.onExpand({ value, expanded: !expanded });
  };

  const getCheckState = ({ toggle }: { toggle: boolean }) => {
    const { checked } = props;

    // Toggle off state to checked
    if (checked === 0 && toggle) {
      return true;
    }

    // Node is already checked and we are not toggling
    if (checked === 1 && !toggle) {
      return true;
    }

    // Get/toggle partial state(Select all child nodes if partial parent node is selected)
    if (checked === 2) {
      return true;
    }

    return false;
  };

  // Render Collapse button
  const renderCollapseButton = () => {
    const { isLeaf } = props;
    const className =
      "py-0 px-1.5 m-0 self-stretch border-0 bg-transparent leading-normal color-inherit text-sm inline-block w-7";
    if (isLeaf) {
      return <span className={className} />;
    }

    return (
      <button
        type="button"
        className={`cursor-pointer ${className}`}
        onClick={onExpand}
      >
        {renderCollapseIcon()}
      </button>
    );
  };

  // Render collapse/ expand/ spinner icon based on state
  const renderCollapseIcon = () => {
    const {
      expanded,
      icons: { expandClose, expandOpen, spinner },
      loading,
    } = props;

    if (loading) {
      return spinner;
    }

    if (!expanded) {
      return expandClose;
    }

    return expandOpen;
  };

  // Render node icon
  const renderNodeIcon = () => {
    const {
      expanded,
      icon,
      icons: { file, folder, folderOpen },
      isLeaf,
      checkable = true,
    } = props;

    if (!checkable) return null;

    if (icon !== null) {
      return icon;
    }

    if (isLeaf) {
      return file;
    }

    if (!expanded) {
      return folder;
    }

    return folderOpen;
  };

  // Renders label
  const renderBareLabel = (children: ReactNode) => {
    const clickable = props.onClick !== null;

    return (
      <span className="cursor-default">
        {clickable ? (
          <span
            className="cursor-pointer focus:outline-none"
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex={0}
          >
            {children}
          </span>
        ) : (
          children
        )}
      </span>
    );
  };

  // Render label with checkbox
  const renderCheckboxLabel = (children: ReactNode) => {
    const { checked, disabled, treeId, value, label } = props;
    const clickable = props.onClick !== null;
    const inputId = `${treeId}-${String(value).split(" ").join("_")}`;

    const render = [
      <label
        key={inputId}
        htmlFor={inputId}
        className={`flex ${
          disabled
            ? "cursor-not-allowed opacity-30 hover:bg-transparent active:bg-transparent"
            : ""
        }`}
      >
        <Checkbox
          aria-label={label}
          checked={checked === 1}
          disabled={disabled}
          indeterminate={checked === 2}
          onChange={onCheck}
        />
        {!clickable ? children : null}
      </label>,
    ];

    if (clickable) {
      render.push(
        <span
          key={1}
          className={
            disabled
              ? "cursor-not-allowed opacity-30"
              : "cursor-pointer focus:outline-none"
          }
          onClick={onClick}
          onKeyPress={onClick}
          role="link"
          tabIndex={0}
        >
          {children}
        </span>,
      );
    }

    return render;
  };

  // Render Label
  const renderLabel = () => {
    const { label, showCheckbox, showNodeIcon, checkable = true } = props;
    const labelChildren = [
      showNodeIcon ? (
        <span key={0} className="m-0 ml-2.5 mr-1 inline-block w-3.5">
          {renderNodeIcon()}
        </span>
      ) : null,
      <span
        key={1}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={
          checkable ? (showNodeIcon ? "px-1.25 py-0" : "px-2.5 py-0") : ""
        }
      >
        {label}
      </span>,
    ];

    if (!showCheckbox || !checkable) {
      return renderBareLabel(labelChildren);
    }

    if (checkable) {
      return renderCheckboxLabel(labelChildren);
    }
    return null;
  };

  // Render children
  const renderChildren = () => {
    if (!props.expanded) {
      return null;
    }

    return props.children;
  };

  return (
    <li className="my-2.5 pl-3.5">
      <span className="mb-1.5 flex items-center">
        {renderCollapseButton()}
        {renderLabel()}
      </span>
      {renderChildren()}
    </li>
  );
}

TreeNode.defaultProps = defaultProps;

export default memo(TreeNode);
