import { Input, useFormContext, useWatch } from "form";
import { InfoIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Bold, Button, ButtonGroup, Tooltip } from "ui";
import { regexes } from "utils";
import { ACCOUNT, ORGANIZATION } from "../../../../../constants";

type scanTypeOptions = {
  accountType?: string;
};

export default function ScanTypeOptions({ accountType }: scanTypeOptions) {
  const intl = useIntl();
  const { setValue } = useFormContext();
  const { cloudScanMode } = useFlags();
  const mode = useWatch({ name: "mode" });
  const isOrg = accountType === ORGANIZATION;
  const isAccountType = accountType === ACCOUNT;
  const envScanType = useWatch({ name: "envScanType" });
  const [scanType, setScanType] = useState(envScanType ?? "target-scan");

  useEffect(() => {
    if (mode === "target-scan") setValue("envScanType", scanType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanType, mode]);

  const validateAccountIds = (value: string) => {
    const accounts = value.replace(" ", "").split(",");
    let hasInvalidAccountId = false;

    accounts.map((account) => {
      if (!regexes.awsAccountId.test(account)) hasInvalidAccountId = true;
    });

    if (hasInvalidAccountId)
      return intl.formatMessage({
        defaultMessage: "Please enter valid Account IDs",
        id: "unUBhk",
        description: "Error message",
      });

    return true;
  };

  return (
    <div className={`${!cloudScanMode && "ml-12"} mt-4 text-xs`}>
      <div className="flex flex-wrap">
        <Bold addClassName="flex flex-wrap">
          <span>
            <FormattedMessage
              defaultMessage="Environment scan type"
              id="7u7Tmw"
              description="Label for scan type"
            />
          </span>
          <Tooltip
            label={
              <Bold>
                <FormattedMessage
                  defaultMessage="Same account: Scan all hosts of this account within the same cloud account"
                  id="nMQVFF"
                  description="Label for scan in same account option"
                />
                <div className="mt-4">
                  <FormattedMessage
                    defaultMessage="Scan with hub: Scan all hosts of this account using a dedicated centralized hub account"
                    id="hequUo"
                    description="Label for Scan in hub account option"
                  />
                </div>
                {isAccountType && (
                  <div className="mt-4">
                    <FormattedMessage
                      defaultMessage="Set as hub: Set this account as a dedicated, centralized account and use it to scan other accounts"
                      id="U22xlp"
                      description="Label for set as hub account option"
                    />
                  </div>
                )}
              </Bold>
            }
          >
            <span>
              <InfoIcon className="ml-2 h-full" />
            </span>
          </Tooltip>
        </Bold>
      </div>

      <div className="mb-4 mt-2">
        <ButtonGroup>
          <Button
            isSelected={scanType == "target-scan"}
            onClick={() => setScanType("target-scan")}
          >
            <FormattedMessage
              defaultMessage="Same account"
              id="QZ1Dmh"
              description="Label for Same account button"
            />
          </Button>
          <Button
            isSelected={scanType == "hub-scan"}
            onClick={() => setScanType("hub-scan")}
          >
            <FormattedMessage
              defaultMessage="Hub account"
              id="reOmoz"
              description="Label for Hub account button"
            />
          </Button>
          {isAccountType && (
            <Button
              isSelected={scanType == "hub"}
              onClick={() => setScanType("hub")}
            >
              <FormattedMessage
                defaultMessage="Set as hub"
                id="n9lwgC"
                description="Label for Set as hub button"
              />
            </Button>
          )}
        </ButtonGroup>
      </div>

      {scanType === "hub-scan" && (
        <Input
          tooltip={
            isOrg
              ? intl.formatMessage({
                  defaultMessage:
                    "Example: 00000000001,00000000002,00000000003",
                  id: "0OJqAj",
                  description: "Tooltip text",
                })
              : ""
          }
          autoFocus
          addClassName="mt-1"
          name="hubAccountIds"
          label={intl.formatMessage({
            defaultMessage: "Hub account IDs",
            id: "4ijYmu",
            description: "Label for Hub account IDs input",
          })}
          placeholder={
            isOrg
              ? intl.formatMessage({
                  defaultMessage:
                    "Enter member account IDs that will serve as hub accounts seperated by commas",
                  id: "4TsNdi",
                  description: "Placeholder Label",
                })
              : isAccountType
                ? intl.formatMessage({
                    defaultMessage:
                      "Enter the account ID that will serve as your hub account",
                    id: "5JpB9i",
                    description: "Placeholder label",
                  })
                : ""
          }
          register={{
            validate: (value) => validateAccountIds(value),
          }}
        />
      )}
    </div>
  );
}
