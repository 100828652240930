import { ALIBABA_CLOUD } from "../../../../../constants";
import AccountType from "../../../components/AccountType";

export default function Scope() {
  return (
    <div className="-ml-2">
      <AccountType cloudType={ALIBABA_CLOUD} name="accountType" />
    </div>
  );
}
