import { useIntl } from "react-intl";
import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const CloudScanIcon = ({
  showNativeTooltip = true,
  ...props
}: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Cloud Scan",
    id: "uubZas",
    description: "Cloud scan mode icon alt tex",
  });

  return (
    <SvgIcon aria-label={alt} viewBox="0 0 44 44" {...props}>
      {showNativeTooltip && <title>{alt}</title>}
      <rect width="40" height="40" rx="3.86364" fill="#EFF8FF" />
      <path
        d="M28.3996 21.3635C28.2973 21.227 28.1723 21.0907 28.0474 20.9657C27.945 20.8635 27.8428 20.7725 27.7291 20.693C27.5587 20.568 27.3655 20.4543 27.1723 20.3521C27.0019 20.2725 26.8428 20.2044 26.661 20.1589C26.7859 19.6134 26.8542 19.0339 26.7973 18.4316C26.5928 15.693 24.445 13.4884 21.7178 13.2157C21.5019 13.193 21.2974 13.1816 21.0814 13.1816C21.0473 13.1816 21.0133 13.1816 20.9791 13.193C20.82 13.193 20.661 13.2043 20.5019 13.2271C20.4451 13.2271 20.3769 13.2384 20.3087 13.2498C20.161 13.2612 20.0133 13.2953 19.8769 13.3294C19.8428 13.3294 19.8087 13.3408 19.7746 13.3521C19.411 13.4316 19.0701 13.5566 18.7405 13.7157C18.7178 13.7271 18.7064 13.7385 18.695 13.7385C18.6269 13.7725 18.5587 13.8066 18.4905 13.8521C18.3428 13.9203 18.2064 13.9998 18.0814 14.0907C17.7519 14.2839 17.4451 14.5339 17.1724 14.8066C17.1496 14.8407 17.1269 14.8634 17.0928 14.8862C16.9564 15.0226 16.8315 15.1703 16.7064 15.3294C16.4564 15.6362 16.6155 16.1248 17.0019 16.2271C17.6155 16.3748 18.2064 16.6248 18.7519 16.9544C19.0246 17.1362 19.0701 17.5226 18.8769 17.7725L18.5246 18.2271C18.3428 18.4657 18.0246 18.5112 17.7633 18.3521C17.1042 17.9657 16.3541 17.7498 15.57 17.7385C15.536 17.7385 15.4906 17.7271 15.4564 17.7271C14.5132 17.7271 13.6383 18.0112 12.911 18.5112C12.661 18.6702 12.4337 18.8635 12.2405 19.068C11.9337 19.3748 11.6723 19.7271 11.4564 20.1134C11.32 20.3635 11.2064 20.6362 11.1155 20.9203C10.9451 21.4884 10.8769 22.1021 10.9337 22.727C10.9792 23.193 11.0928 23.6248 11.2746 24.0339C11.9564 25.6703 13.5701 26.818 15.4564 26.818H25.6837C25.7291 26.818 25.786 26.818 25.8314 26.8066C27.2291 26.7612 28.411 25.852 28.8655 24.6021C28.911 24.4998 28.9451 24.3975 28.9678 24.2952C28.9905 24.193 29.0132 24.1021 29.036 24.0111C29.0587 23.8862 29.07 23.7498 29.0814 23.6248C29.1382 22.7725 28.8769 21.9885 28.3996 21.3635Z"
        fill="#006FCC"
      />
    </SvgIcon>
  );
};
