import { useState, type Dispatch, type SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Input } from "ui";

import ErrorString from "./ErrorString";

type CloudTrailInputPropTypes = {
  disabled: boolean;
  cloudTrailName?: string;
  setCloudTrailName: Dispatch<SetStateAction<string | undefined>>;
};

export default function CloudTrailInput({
  disabled,
  cloudTrailName = "",
  setCloudTrailName,
}: CloudTrailInputPropTypes) {
  const intl = useIntl();
  const [isDirty, setIsDirty] = useState(false);
  const [name, setName] = useState(cloudTrailName);

  const requiredErrorMessage = intl.formatMessage({
    defaultMessage: "CloudTrail is required",
    id: "tbl+wT",
    description: "Error message for empty CloudTrail input",
  });

  return (
    <>
      <Input
        disabled={disabled}
        onChange={(e) => {
          if (!isDirty) setIsDirty(true);
          const newName = e.target?.value;
          setCloudTrailName(newName);
          setName(newName);
        }}
        placeholder={intl.formatMessage({
          defaultMessage: "Enter CloudTrail Name...",
          id: "8JscoR",
          description: "cloudtrail name input placeholder",
        })}
        value={name}
      />
      {isDirty && name.length === 0 && (
        <ErrorString errorMessage={requiredErrorMessage} />
      )}
    </>
  );
}
