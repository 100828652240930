import { z } from "zod";

export const GetTenantFeaturesSchema = z.object({
  customerName: z.string().optional(),
  prismaId: z.string(),
  features: z.string().optional(),
});

export type GetTenantFeaturesParams = z.infer<typeof GetTenantFeaturesSchema>;

export const UpdateTenantFeatureSchema = z.object({
  url: z.string().optional(),
  customer_name: z.string().optional(),
  provision_status_list: z.array(z.any().optional()).optional(),
});

export const UpdateTenantFeatureResponseSchema = z.array(
  z.object({
    timestamp: z.number(),
    status: z.number(),
    error: z.string(),
    message: z.string(),
    path: z.string(),
    traceId: z.string(),
  }),
);

const FeatureNameSchema = z.union([
  z.literal("asm"),
  z.literal("pcn"),
  z.literal("compute"),
  z.literal("bridgecrew"),
  z.literal("iam-security"),
  z.literal("cspm-plus-trial"),
  z.literal("dspm"),
]);

const BillingTypeSchema = z.union([z.literal("TRIAL"), z.literal("BUY")]);

const StateSchema = z.union([
  z.literal("PROVISION_STARTED"),
  z.literal("PROVISION_SUCCESSFUL"),
  z.literal("PROVISION_FAILED"),
]);

export const TenantFeaturesResponseSchema = z.array(
  z.object({
    prismaId: z.number(),
    customer_name: z.string(),
    feature_name: FeatureNameSchema,
    additional_data: z.record(z.string(), z.unknown()).optional(),
    enabled: z.boolean(),
    state: StateSchema,
    billing_type: BillingTypeSchema,
    updated_by: z.string(),
    last_updated_ts: z.number(),
    created_ts: z.number(),
    end_ts: z.number(),
    external_tenant_id: z.string().optional(),
  }),
);

export type TenantFeaturesResponse = z.infer<
  typeof TenantFeaturesResponseSchema
>;

export const FeatureStatusResponseSchema = z.object({
  guardDutyEnabled: z.boolean(),
  inspectorEnabled: z.boolean(),
  is_aws_storage_scan_available: z.boolean(),
  aws_flow_logs_s3_feature_enabled: z.boolean(),
  flowlogsEnabled: z.boolean(),
  is_pcn_security_service_enabled: z.boolean(),
  is_bc_service_enabled: z.boolean(),
  cloudtrailEnabled: z.boolean(),
  awsPartitionsEnabled: z.array(z.string()),
  is_aws_event_bridge_enabled: z.boolean(),
  aws_audit_logs_s3_read_write_onboarding_enabled: z.boolean(),
});

export const DlpStatusResponseSchema = z.object({
  status: z.string(),
  dlpTenantId: z.string().optional(),
});

export const TenantEntitlementsSchema = z.object({
  dunsNumber: z.string().nullish(),
  seedDomains: z.array(z.string()).nullish(),
});
