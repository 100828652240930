import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { dashboardsSavedViews } from "saved-views-next";
import { dashboardsLayoutRoute } from "./dashboardsLayoutRoute";

const Dashboard = lazy(() =>
  import("./Dashboard").then((res) => ({
    default: res.Dashboard,
  })),
);

export const dashboardRoute = createRoute({
  getParentRoute: () => dashboardsLayoutRoute,
  path: "$dashboardId",
  component: Dashboard,
  loader: ({ context, params: { dashboardId } }) => {
    context?.queryClient.prefetchQuery(
      dashboardsSavedViews.getViewQueryOptions(dashboardId),
    );
  },
});
