import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isNil } from "remeda";
import {
  type PermissionsStatus,
  type SecurityCapabilitiesType,
} from "requests";
import { dynamicFormatMessage } from "ui";
import AzureAccountDetails, {
  Authentication as AuthenticationStatus,
  DeploymentType,
  Details,
  SecurityCapabilities,
} from "../../../../../components/ReviewStatus/AccountDetails";
import { AUTO_MAP, TENANT } from "../../../../../constants";
import {
  accountDetailsLabelMap,
  scopeMap,
} from "../../../../../messages/reviewStatusMessages";
import { type AccountType } from "../../../../../types";
import { AccountTypeIcon } from "../../../../../utils";

type AccountDetailsStatusPropTypes = {
  autoMap: boolean;
  accountGroups: string[];
  accountName: string;
  accountType: AccountType;
  authentication?: PermissionsStatus;
  securityCapabilities: SecurityCapabilitiesType[];
  deploymentType?: string;
  isLoading: boolean;
  isAccountType: boolean;
};

type Field = {
  renderComponent: (key: string) => JSX.Element;
  key: string;
  label: string;
  transform?: (value: string | string[]) => JSX.Element;
  rightIcon?: JSX.Element;
  defaultCount?: number;
  allowCopy?: boolean;
};

export default function AccountDetailsStatus(
  props: AccountDetailsStatusPropTypes,
) {
  const { azureAutoMap } = useFlags();
  const intl = useIntl();
  const {
    autoMap,
    accountType,
    authentication,
    securityCapabilities,
    deploymentType = "",
    isAccountType,
    isLoading,
  } = props;
  const isTenant = accountType === TENANT;

  const Icon = AccountTypeIcon(accountType);

  const fields = useMemo(() => {
    const componentList = [
      {
        renderComponent: (key: string) => (
          <AuthenticationStatus
            isLoading={isLoading}
            key={key}
            data={authentication}
            isCollapsibleDetails={true}
            isAccountDetails
          />
        ),
        key: "authentication",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "authentication",
        ),
      },
      {
        key: "accountType",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "scope"),
        transform: (value: string) =>
          dynamicFormatMessage(intl, scopeMap, value),
        rightIcon: <Icon className="mr-1" size="sm" />,
      },
      {
        renderComponent: (key: string) => (
          <SecurityCapabilities
            key={key}
            defaultCount={2}
            value={securityCapabilities}
          />
        ),
        key: "securityCapabilities",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "securityCapabilities",
        ),
      },
      {
        key: "accountName",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountName",
        ),
      },
      {
        key: "tenantId",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "tenantId"),
      },
      {
        key: "clientId",
        label: dynamicFormatMessage(intl, accountDetailsLabelMap, "clientId"),
      },
      {
        key: "servicePrincipalId",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "servicePrincipalId",
        ),
      },
      {
        key: "accountGroups",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountGroups",
        ),
        transform: (value: string[]) =>
          intl.formatMessage(
            {
              defaultMessage: "{label}",
              id: "wLJgeG",
              description: "Description for selected Account Group with count",
            },
            {
              count: value?.length,
              label:
                azureAutoMap && isTenant && autoMap
                  ? `Creation Mode (${AUTO_MAP})`
                  : `${value?.length} Account ${
                      value?.length > 1 ? "Groups" : "Group"
                    } Selected`,
            },
          ),
      },
      {
        renderComponent: (key: string) => (
          <DeploymentType key={key} value={deploymentType} />
        ),
        key: "deploymentType",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "deploymentType",
        ),
      },
    ];

    if (isAccountType) {
      componentList.splice(5, 0, {
        key: "subscriptionId",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "subscriptionId",
        ),
      });
    }
    return componentList.filter((field) => !isNil(field)) as Field[];
  }, [
    Icon,
    authentication,
    autoMap,
    azureAutoMap,
    deploymentType,
    intl,
    isAccountType,
    isLoading,
    isTenant,
    securityCapabilities,
  ]);

  const getContent = (
    transform: Field["transform"],
    key: string,
    value: unknown,
  ): JSX.Element => {
    if (!isNil(transform)) {
      if (key === "accountType") return transform(value as string);
      if (key === "accountGroups") return transform(value as string[]);
    }
    return value as JSX.Element;
  };

  return (
    <AzureAccountDetails>
      {fields.map(({ key, renderComponent, transform, ...rest }) =>
        !isNil(renderComponent) ? (
          renderComponent(key)
        ) : (
          <Details key={key} {...rest}>
            {getContent(transform, key, props[key as keyof typeof props])}
          </Details>
        ),
      )}
    </AzureAccountDetails>
  );
}
