import { FormattedMessage } from "react-intl";
import { ACCOUNT, AWS, ORGANIZATION } from "../../../../constants";

import { type CloudType } from "../../../../types";

export function getAccountTypeItems(cloudType: CloudType) {
  const AwsItems = [
    {
      value: ORGANIZATION,
      children: (
        <div>
          <FormattedMessage
            defaultMessage="Organization"
            id="cA/eHt"
            description="Option for scope type for onboarding an AWS account"
          />
        </div>
      ),
    },
    {
      value: ACCOUNT,

      children: (
        <div>
          <FormattedMessage
            defaultMessage="Account"
            id="BFaQoC"
            description="Option for scope type for onboarding an AWS account"
          />
        </div>
      ),
    },
  ];

  switch (cloudType) {
    case AWS:
      return AwsItems;
    default:
      return [];
  }
}
