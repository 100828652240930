import { ModalWizard, type WizardStep } from "form";

import { useQuery } from "@tanstack/react-query";
import { type Operations } from "prisma";
import type React from "react";
import { useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import {
  getPermissionGroup,
  permissionGroupsKeys,
  type FeatureNames,
  type Features,
  type PermissionGroupData,
} from "requests";
import { useModal, type RenderModalProps } from "ui";
import { AssignPermissions, Details, Summary } from "./steps";

export type PermissionGroupStepNames =
  | "details"
  | "assignPermissions"
  | "summary";

export type PermissionGroupWizardContext = {
  shouldAddRoleAfterSubmitRef: React.MutableRefObject<boolean>;
};
export type PermissionGroupWizardValues = {
  name: PermissionGroupData["name"];
  features: Record<FeatureNames, Operations>;
  description: PermissionGroupData["description"];
  id?: PermissionGroupData["id"];
};

export default function PermissionGroupWizard({
  isOpen,
  closeModal,
  record: permissionGroupData,
  actionType,
}: RenderModalProps<{
  record?: PermissionGroupData;
  actionType?: "edit" | "clone";
}>) {
  const intl = useIntl();
  const isEdit = actionType === "edit";

  const {
    data: editPermissionGroupData,
    isLoading: isLoadingEditData = false,
  } = useQuery({
    queryKey: permissionGroupsKeys.permissionGroup({
      id: permissionGroupData?.id ?? "",
    }),
    queryFn: getPermissionGroup,
    enabled: isEdit,
  });

  const shouldAddRoleAfterSubmitRef = useRef(false);

  const formattedFeaturesForForm = useMemo(() => {
    const features =
      editPermissionGroupData?.features ?? permissionGroupData?.features;
    const formattedFeatures = features?.reduce(
      (acc, curr) => ({ ...acc, [`${curr.featureName}`]: curr.operations }),
      {},
    );
    return formattedFeatures as Features[];
  }, [editPermissionGroupData?.features, permissionGroupData?.features]);

  const permissionGroupFormData = useMemo(() => {
    if (actionType === "clone") {
      return {
        ...permissionGroupData,
        name: intl.formatMessage(
          {
            defaultMessage: "Copy of {name}",
            id: "sVqf43",
            description: "Cloned name of permission group",
          },
          { name: permissionGroupData?.name },
        ),
        ...(!!formattedFeaturesForForm && {
          features: formattedFeaturesForForm,
        }),
        id: undefined,
        permissionGroupsList: {
          value: permissionGroupData?.name,
          children: permissionGroupData?.name,
          context: {
            ...(!!formattedFeaturesForForm && {
              features: formattedFeaturesForForm,
            }),
          },
        },
      };
    } else
      return {
        ...permissionGroupData,
        ...(editPermissionGroupData ?? {}),
        ...(!!formattedFeaturesForForm && {
          features: formattedFeaturesForForm,
        }),
      };
  }, [
    actionType,
    editPermissionGroupData,
    formattedFeaturesForForm,
    intl,
    permissionGroupData,
  ]);

  const steps = useMemo<WizardStep<PermissionGroupStepNames>[]>(() => {
    return [
      {
        name: "details",
        title: intl.formatMessage({
          defaultMessage: "Details",
          id: "tVq+R3",
          description: "Form Header",
        }),
        nextStepName: "assignPermissions",
        Component: Details,
      },
      {
        name: "assignPermissions",
        title: intl.formatMessage({
          defaultMessage: "Assign Permissions",
          id: "qus4Le",
          description: "Form Header",
        }),
        previousStepName: "details",
        nextStepName: "summary",
        Component: AssignPermissions,
      },
      {
        name: "summary",
        title: intl.formatMessage({
          defaultMessage: "Summary",
          id: "CtWCDH",
          description: "Form Header",
        }),
        previousStepName: "assignPermissions",
        Component: Summary,
      },
    ];
  }, [intl]);

  return (
    <ModalWizard<
      PermissionGroupData,
      PermissionGroupStepNames,
      PermissionGroupWizardContext
    >
      isOpen={isOpen}
      onClose={closeModal}
      size="xl"
      initialValues={actionType ? permissionGroupFormData : {}}
      title={intl.formatMessage(
        {
          defaultMessage: "{name} Permission Group",
          id: "8TDVN5",
          description: "Modal title",
        },
        { name: isEdit ? "Edit" : "Add" },
      )}
      steps={steps}
      initialStepName={actionType ? "summary" : "details"}
      isLoading={isEdit ? isLoadingEditData : false}
      context={{
        shouldAddRoleAfterSubmitRef,
      }}
    />
  );
}
export function usePermissionGroupWizard() {
  return useModal(PermissionGroupWizard);
}
