import { z } from "zod";
import {
  TimeRangeSchema,
  TimeRangeTypeSchema,
  TimeStampSchema,
} from "../../../schemas";
import { CloudTypesWithOtherEnumSchema } from "../../cloudType";

export enum RqlSearchType {
  appsec = "appsec",
  asset = "asset",
  event = "audit_event",
  config = "config",
  iam = "iam",
  network = "network",
  network_config = "network_config",
  vulnerability = "vulnerability",
  application = "application",
}

export const GetRqlListJobsSchema = z.object({
  page: z.number(),
  size: z.number(),
});

export type GetRqlListJobs = z.infer<typeof GetRqlListJobsSchema>;

export const GetRqlSearchHistorySchema = z.object({
  filter: z.enum(["saved", "recent"]),
  limit: z.number(),
});

export const SavedSearchhistoryRequestSchema = z.object({
  searchText: z.string().optional(),
  searchType: z.nativeEnum(RqlSearchType).optional(),
  aiPoweredSearch: z.boolean().optional(),
});

export type GetRqlSearchHistory = z.infer<typeof GetRqlSearchHistorySchema>;

export const RqlSearchTimeRangeComponentsSchema = z.object({
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  timeType: TimeRangeTypeSchema.optional(),
  timeAmount: z.union([z.string(), z.number()]).optional(),
  timeUnit: z
    .enum(["hour", "day", "week", "month", "year", "epoch", "login"])
    .optional(),
});

export type RqlSearchTimeRangeComponentsType = z.infer<
  typeof RqlSearchTimeRangeComponentsSchema
>;

export const GetRqlSearchSchema = z
  .object({
    id: z.string(),
  })
  .merge(RqlSearchTimeRangeComponentsSchema);

export type GetRqlSearch = z.infer<typeof GetRqlSearchSchema>;
export type DeleteRqlSearch = GetRqlSearch;

export const RqlSearchTypesSchema = z.nativeEnum(RqlSearchType);

const RqlSearchLinkedPoliciesSchema = z.object({
  policyId: z.string(),
  policyName: z.string(),
});

export type RqlSearchLinkedPolicies = z.infer<
  typeof RqlSearchLinkedPoliciesSchema
>;

export const RqlSearchModelSchema = z.object({
  async: z.boolean().optional(),
  asyncResultUrl: z.string().optional(),
  cloudType: z.union([CloudTypesWithOtherEnumSchema, z.null()]).optional(),
  description: z.string().optional(),
  id: z.string().optional(),
  name: z.string(),
  alertPolicyName: z.string().optional(),
  readOnly: z.boolean().optional(),
  query: z.string(),
  queryWithFindingNames: z.union([z.string(), z.null()]).optional(),
  queryWithFindingIDs: z.string().optional(),
  saved: z.boolean(),
  searchType: RqlSearchTypesSchema,
  timeRange: TimeRangeSchema.optional(),
  systemDefault: z.boolean().optional(),
  nodeCount: z.number().optional(),
});

export const RqlSavedSearchHistorySchema = z.object({
  searchText: z.string().optional(),
  searchType: z.string().optional(),
  aiPoweredSearch: z.boolean().optional(),
});

export const RqlListJobsItemSchema = z.object({
  systemDefault: z.boolean().optional(),
  createdOn: TimeStampSchema.optional(),
  downloadUrl: z.string().optional(),
  jobId: z.string().optional(),
  status: z.string().optional(),
  additionalInformation: z.object({
    query: z.union([z.string(), z.null()]).optional(),
    queryType: z.nativeEnum(RqlSearchType),
    searchId: z.union([z.null(), z.string()]).optional(),
  }),
  payload: z
    .object({
      fp: z.boolean().optional(),
      limit: z.number().optional(),
      query: z.union([z.string(), z.null()]).optional(),
      timeRange: z.union([z.null(), TimeRangeSchema]).optional(),
      withResourceJson: z.boolean().optional(),
      searchName: z.union([z.null(), z.string()]).optional(),
      searchDescription: z.union([z.null(), z.string()]).optional(),
    })
    .optional(),
});

export type RqlListJobsItemType = z.infer<typeof RqlListJobsItemSchema>;
export type RqlListJobsSchemaType = z.infer<typeof RqlListJobsSchema>;

export const RqlListJobsSchema = z.object({
  currentPage: z.number().optional(),
  pageSize: z.number().optional(),
  totalItems: z.number().optional(),
  totalPages: z.number().optional(),
  rqlJobDetailsList: z.array(RqlListJobsItemSchema).optional(),
});

export const RqlSearchHistoryItemSchema = z.object({
  createdBy: z.string(),
  description: z.string(),
  displayQuery: z.string().optional(),
  id: z.string(),
  lastModifiedBy: z.string(),
  policies: z
    .union([z.array(RqlSearchLinkedPoliciesSchema), z.null()])
    .optional(),
  query: z.string(),
  searchModel: RqlSearchModelSchema,
  searchName: z.string(),
  timestamp: z.number(),
  systemDefault: z.boolean().optional(),
});

export type RqlSearchHistoryItem = z.infer<typeof RqlSearchHistoryItemSchema>;

export const RqlSearchHistorySchema = z.array(RqlSearchHistoryItemSchema);

export type RqlSearchHistory = z.infer<typeof RqlSearchHistorySchema>;

export const RqlSearchHistoryByIdSchema = z.object({
  cloudType: CloudTypesWithOtherEnumSchema.optional(),
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  searchType: RqlSearchTypesSchema,
  saved: z.boolean(),
  timeRange: TimeRangeSchema.optional(),
  query: z.string(),
  queryWithFindingNames: z.string().optional(),
  searchModel: RqlSearchModelSchema.optional(),
  default: z.boolean().optional(),
  systemDefault: z.boolean().optional(),
});

export type RqlSearchHistoryById = z.infer<typeof RqlSearchHistoryByIdSchema>;

export const RqlBackgroundSearchRequestSchema = z.object({
  clientType: z.string(),
  configRuleSearchParams: z.object({
    query: z.string(),
    timeRange: TimeRangeSchema.optional(),
    withResourceJson: z.boolean(),
  }),
  emailContext: z.object({}),
});

export const RqlAssetBackgroundSearchRequestSchema = z.object({
  query: z.string(),
});

export const RqlBackgroundSearchResponseSchema = z.object({
  createdBy: z.string(),
  createdOn: z.union([TimeStampSchema, z.string()]),
  downloadUrl: z.string(),
  jobId: z.string().optional(),
  reportId: z.string().optional(),
  status: z.string(),
  statusUrl: z.string(),
});

export const SearchAIFeedbackSchema = z.object({
  liked: z.boolean(),
  searchScope: z.object({
    searchText: z.string(),
    searchType: z.union([z.string(), z.null()]),
  }),
  searchResults: z.array(z.string()),
  comments: z.union([z.string(), z.null()]),
  suggestions: z.union([z.array(z.string()), z.null()]),
});

export type SearchAIFeedback = z.infer<typeof SearchAIFeedbackSchema>;
