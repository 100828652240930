import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { publicRootRoute } from "../../publicRootRoute";

export const SsoError = lazy(() => import("./SsoError"));

export const ssoErrorRoute = new Route({
  getParentRoute: () => publicRootRoute,
  path: "/sso_error",
  component: SsoError,
});
