import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";
import StatusHealthTable from "./StatusHealthTable";

type statusModalProps = RenderModalProps<{
  errorType?: string;
  lastUpdated?: number;
  message?: string;
}>;

export default function StatusModal({
  closeModal,
  isOpen,
  errorType,
  lastUpdated,
  message,
}: statusModalProps) {
  const intl = useIntl();

  return (
    <Modal onClose={closeModal} isOpen={isOpen} size="lg">
      <ModalHeader
        title={intl.formatMessage({
          defaultMessage: "Status",
          id: "fLvgEC",
          description: "header for Integration Status Modal",
        })}
      />

      <ModalBody>
        <StatusHealthTable data={[{ errorType, lastUpdated, message }]} />
      </ModalBody>

      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Close"
            id="zXZG/C"
            description="Footer for Integration Status Modal"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
