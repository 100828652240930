import { classNames } from "utils";

import {
  type ComponentPropsWithoutRef,
  type CSSProperties,
  type ReactElement,
  type ReactNode,
} from "react";

// TODO: Connect aria-labelledby to Title in CardHeader, if present
// TODO: Create an API to easily connect aria-describedby.

const shadowVariants = {
  none: "shadow-none",
  default: "shadow",
  sm: "shadow-sm",
  md: "shadow-md",
  lg: "shadow-lg",
  xl: "shadow-xl",
  xxl: "shadow-2xl",
};

type ShadowVariant = boolean | keyof typeof shadowVariants;

function getShadowClassName(
  shadow: ShadowVariant,
): (typeof shadowVariants)[keyof typeof shadowVariants] {
  if (typeof shadow === "boolean") {
    return shadow ? shadowVariants.default : shadowVariants.none;
  }
  return shadowVariants[shadow];
}

export const cardAppearanceVariants = {
  primary: "bg-white dark:bg-blue-steel-940",
  glass: "bg-gray-100/30 dark:bg-blue-steel-940/50 backdrop-blur-2xl",
};

export interface CardProps extends ComponentPropsWithoutRef<"div"> {
  /**
   * Add a className to the card
   */
  addClassName?: string;
  appearance?: keyof typeof cardAppearanceVariants;
  /**
   * The contents of the Card. Generally, use CardHeader, CardBody, and CardFooter.
   */
  children: ReactNode;
  /**
   * Amount of box shadow
   */
  shadow?: ShadowVariant;
  style?: CSSProperties;
}

/**
 * This component should be used to house similar information or data.
 * It may be used for widgets on a dashboard, data visualizations or data
 * counters, or sections on a page or in a form. These components can be
 * used together to segment the information on a page.
 *
 * Use the CardHeader, CardBody, and CardFooter components for the standard style and behavior. Each are exported from the root:
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Card, CardBody, CardFooter, CardHeader } from '@prismacloud/react-ui';
 * ```
 */
export function Card({
  addClassName,
  appearance = "primary",
  children,
  shadow = false,
  style = {},
  ...passThrough
}: CardProps): ReactElement {
  const className = classNames(
    "flex flex-col rounded border",
    "border-gray-300 text-default dark:text-dark-bg",
    "dark:border-blue-steel-850 dark:shadow-xl",
    getShadowClassName(shadow),
    cardAppearanceVariants[appearance],
    addClassName,
  );
  return (
    <div
      data-testid="card"
      style={style}
      className={className}
      {...passThrough}
    >
      {children}
    </div>
  );
}
