import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../hooks";
import { validGuid } from "../../../../utils";

export default function SubscriptionId({ isEdit }: { isEdit: boolean }) {
  const intl = useIntl();

  useTrimField("subscriptionId");

  return (
    <Input
      data-selector="subscriptionid-input"
      name="subscriptionId"
      label={intl.formatMessage({
        defaultMessage: "Subscription ID",
        id: "uPMETA",
        description:
          "The Subscription id of an input field for onboarding an Azure account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "Your Azure Subscription ID",
        id: "JFSXDf",
        description:
          "Placeholder for the Subscription id of an input field for onboarding an Azure account",
      })}
      readOnly={isEdit}
      register={{
        required: intl.formatMessage({
          defaultMessage: "Azure Subscription ID is required",
          id: "XUcYoY",
          description: "Required message for cloud account",
        }),
        validate: validGuid(intl),
      }}
      tooltip={intl.formatMessage({
        defaultMessage:
          "In order to retrieve your Azure Subscription information for onboarding. simply login to Azure Portal, select All services Subscriptions, select your subscription, copy the Subscription ID and paste it in the field provided.",
        id: "SYthiZ",
        description: "Tooltip for retrieve Azure SubscriptionID for onboarding",
      })}
    />
  );
}
