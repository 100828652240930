import {
  ACCOUNT,
  GCP,
  MASTER_SERVICE_ACCOUNT,
  ORGANIZATION,
  WORKSPACE_DOMAIN,
} from "../../../../../constants";

import { useWatch } from "form";
import { useEffect } from "react";
import { useWizardContext } from "../../../../../../components/Wizard";
import AccountType from "../../../components/AccountType";

export default function Scope({ isEdit }: { isEdit: boolean }) {
  const {
    state: { steps, activeIndex },
    valuesUpdate,
  } = useWizardContext();

  const accountType = useWatch({ name: "accountType" });

  const autoOnboardProjects =
    steps[activeIndex + 1]?.values?.autoOnboardProjects;
  const isMSA = accountType !== ORGANIZATION && autoOnboardProjects;
  const groupIds = steps[activeIndex + 1]?.values?.groupIds;

  const getAccountTypeValue = (value: string) =>
    value === MASTER_SERVICE_ACCOUNT ? ACCOUNT : value;

  useEffect(() => {
    if (!isEdit) {
      if (
        [ACCOUNT, WORKSPACE_DOMAIN].includes(accountType) &&
        !isMSA &&
        !Array.isArray(groupIds)
      ) {
        valuesUpdate({
          index: activeIndex + 1,
          values: {
            ...steps[activeIndex + 1]?.values,
            groupIds: [groupIds],
          },
        });
      } else if (
        (accountType === ORGANIZATION || isMSA) &&
        Array.isArray(groupIds)
      ) {
        valuesUpdate({
          index: activeIndex + 1,
          values: {
            ...steps[activeIndex + 1]?.values,
            groupIds: groupIds[0],
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  return (
    <section className="space-y-2">
      <div className="-ml-2">
        <AccountType
          cloudType={GCP}
          defaultValue={accountType}
          getAccountTypeValue={getAccountTypeValue}
          isDisabled={isEdit}
          name="accountType"
        />
      </div>
    </section>
  );
}
