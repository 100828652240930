/**
 * Counts the number of `true` values in the column
 */
export function countTrue(values: boolean[]): number {
  return values.filter((v) => v === true).length;
}

/**
 * Counts the number of occurences of each value in the column
 */
export function histogram(values: string[]): Record<string, number> {
  return values.reduce<Record<string, number>>((accum, v) => {
    accum[v] ||= 0;
    accum[v] += 1;

    return accum;
  }, {});
}

/**
 * Finds the lowest and highest values in the column
 */
export function minAndMax(values: number[]): { max: number; min: number } {
  return {
    max: Math.max(...values),
    min: Math.min(...values),
  };
}
