import { type ReactNode } from "react";
import { defineMessage } from "react-intl";

import { DynamicFormattedMessage, TruncateText } from "ui";
import { classNames, snakeCase } from "utils";
import { statusHeaderMap } from "../../../../../../../messages/statusHeaderMessages";

type DetailsProps = {
  children?: ReactNode;
  className?: string;
  description?: ReactNode;
  icon?: ReactNode;
  label: string;
  normalFont?: boolean;
};

Details.defaultProps = {
  className: "mt-5",
  normalFont: false,
};

export default function Details({
  children,
  className,
  description,
  icon,
  label,
  normalFont,
}: DetailsProps) {
  const fallback = defineMessage({
    defaultMessage: "Status not found",
    id: "m6fYMN",
    description: "Status not found message as fallback",
  });

  return (
    <>
      <div className={`grid grid-cols-4 gap-4 pl-2 text-xs ${className}`}>
        <div className="flex items-center space-x-2 break-all">
          {icon}
          <TruncateText>
            <span
              className={classNames(
                "grow text-left",
                normalFont ? "font-normal" : "font-bold",
              )}
            >
              <DynamicFormattedMessage
                fallback={fallback}
                messageMap={statusHeaderMap}
                token={snakeCase(label)}
              />
            </span>
          </TruncateText>
        </div>
        {children}
      </div>
      {description || null}
    </>
  );
}
