import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { PolicyFilterSchema, getPolicies } from "requests";
import { policiesSavedViews } from "saved-views-next";
import { z } from "zod";
import { privateRootRoute } from "../../privateRootRoute";
import { governancePoliciesQueryKey } from "./usePoliciesData";

const Governance = lazy(() =>
  import("./Governance").then((res) => ({
    default: res.Governance,
  })),
);

const GovernanceSearchSchema = z.object({
  filters: PolicyFilterSchema.catch({}).optional(),
  viewId: z.string().catch("default").optional(),
});

export type GovernanceSearchType = z.infer<typeof GovernanceSearchSchema>;

export const governanceRoute = new Route({
  getParentRoute: () => privateRootRoute,
  path: "/governance",
  component: Governance,
  validateSearch: GovernanceSearchSchema,
  loaderDeps: ({ search: { viewId } }) => ({ viewId }),
  loader: ({ context, deps: { viewId } }) => {
    context?.queryClient.prefetchQuery(
      policiesSavedViews.getViewQueryOptions(viewId ?? "default"),
    );
    // prefetching governance table data as per PCUI-4669
    context?.queryClient.prefetchQuery({
      queryKey: governancePoliciesQueryKey,
      queryFn: getPolicies,
    });
  },
});
