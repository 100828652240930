import { type ReactElement, type ReactNode } from "react";

import { classNames } from "utils";
import { TruncateText } from "../../TruncateText";
import { StatusIcon, type StatusIconProps } from "./StatusIcon";

export interface ModalWizardNavHeadingProps {
  children: ReactNode;
  status?: StatusIconProps["status"];
}

const rootClassName = classNames(
  "flex items-center justify-between space-x-1",
  "w-full truncate py-2 pl-4 pr-2",
  "border-b border-gray-300 dark:border-blue-steel-850",
  "text-left text-2xs capitalize text-secondary dark:text-dark-bg-secondary",
);

export function ModalWizardNavHeading({
  children,
  status,
}: ModalWizardNavHeadingProps): ReactElement {
  return (
    <li className={rootClassName} data-testid="wizard-nav-heading">
      <TruncateText addClassName="shrink">{children}</TruncateText>
      <StatusIcon status={status} />
    </li>
  );
}
