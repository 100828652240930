import { type SeverityIconProps } from "icons";
import { SeverityLabel, Tooltip, dynamicFormatMessage } from "ui";
import { severityAggregatedMessages } from "./messages";

import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import { severities } from "requests";

export interface SeverityCellProps {
  value: SeverityIconProps["level"];
}

export function SeverityCell({ value }: SeverityCellProps): ReactElement {
  return (
    <span className="flex flex-row items-center space-x-2">
      <SeverityLabel level={value} />
    </span>
  );
}

export interface SeverityAggregatedCellProps {
  value: Record<SeverityIconProps["level"], number>;
}

export function SeverityAggregatedCell({
  value,
}: SeverityAggregatedCellProps): ReactElement {
  const intl = useIntl();

  return (
    <div className="flex flex-col gap-y-1">
      {severities.map((severity) => {
        const level = severity as keyof typeof value;
        const count = value[level] || 0;
        const label = dynamicFormatMessage(
          intl,
          severityAggregatedMessages,
          severity,
          { count },
        );

        return (
          <Tooltip label={label} key={severity}>
            <span
              aria-label={label}
              className=" flex flex-row space-x-2 whitespace-nowrap"
            >
              <SeverityLabel count={count} level={level} />
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}
