import { captureMessage } from "@sentry/react";
import { isDevelopment } from "environment";
import jwtDecode from "jwt-decode";
import { z } from "zod";
import { create } from "zustand";

export const TokenSchema = z.object({
  /** The API URL for this token */
  aud: z.string().url(),
  /** The API URL for this token */
  iss: z.string().url(),
  /** When this token expires */
  exp: z.number(),
  /** If this is the users first login */
  firstLogin: z.boolean(),
  hasDefenderPermissions: z.boolean(),
  iat: z.number(),
  /** Wether the user signed in by SSO */
  isSSOSession: z.boolean(),
  lastLoginTime: z.number(),
  /** The users tenant ID */
  prismaId: z.string(),
  /** If the user signed in by access key, the ID of that access key */
  accessKeyId: z.string().optional(),
  /**
   * When set to 0 the token may be used until expired.
   * When set to 1 the token may only be used 1 time.
   */
  restrict: z.union([z.literal(0), z.literal(1)]),
  /** The tenant the user is signed in as */
  selectedCustomerName: z.string(),
  /** How long the session is set to */
  sessionTimeout: z.number(),
  sub: z.string(),
  userRoleId: z.string(),
  userRoleName: z.string(),
  userRoleTypeDetails: z.object({
    hasOnlyReadAccess: z.boolean().optional(),
    hasOnlyComputeAccess: z.boolean().optional(),
    hasOnlyCIAccess: z.boolean().optional(),
  }),
  userRoleTypeId: z.number(),
  userRoleTypeName: z.union([
    z.literal("System Admin"),
    z.literal("Account Group Admin"),
    z.literal("Account Group Read Only"),
    z.literal("Account and Cloud Provisioning Admin"),
    z.literal("Cloud Provisioning Admin"),
    z.literal("Build and Deploy Security"),
    z.literal("NetSecOps"),
    z.literal("Developer"),
    z.string(),
  ]),
  username: z.string(),
  /** The SSO portal URL if the user signed in via SSO */
  appPortalUrl: z.string().optional(),
  pingLogoutUrl: z.string().optional(),
  entitlementUrl: z.string().optional(),
  panSSO: z.boolean().optional(),
  pingSamlUrl: z.string().optional(),
});
export type Token = z.infer<typeof TokenSchema>;

export type TokenState = {
  token: Token | Record<string, never>;
  expiryDate?: Date;
  // isAuthenticated is a function because the expiration needs to be evaluated at the time of calling
  isAuthenticated: () => boolean;
  rawToken?: string;
  actions: {
    setToken: (token: string) => void;
    removeToken: () => void;
  };
};

export function parseToken(
  rawToken?: string | null,
): Token | Record<string, never> {
  if (!rawToken) return {};

  const token = jwtDecode(rawToken);
  const result = TokenSchema.safeParse(token);

  if (!result.success) {
    /* c8 ignore start */
    if (isDevelopment()) {
      /* eslint-disable no-console */
      console.error(`Invalid token`);
      throw result.error;
    } else {
      captureMessage(`Invalid token`, (scope) => {
        scope.addAttachment({
          filename: "zodError.txt",
          data: result.error.toString(),
        });
        return scope;
      });
    }
    /* c8 ignore stop */

    return token as Token;
  }

  return result.data;
}

export const monitorTokenStorage = () => {
  const tokenChanged = (storageEvent: StorageEvent) => {
    if (storageEvent.key === "token") {
      useTokenStore.getState().actions.setToken(storageEvent.newValue ?? "");
    }
  };

  window.addEventListener("storage", tokenChanged);
  return () => window.removeEventListener("storage", tokenChanged);
};

function expiryDate(exp?: number) {
  return exp ? new Date(exp * 1000) : undefined;
}

function isAuthenticated(token: Token | Record<string, never>) {
  const date = expiryDate(token.exp);
  return (
    !!token.username &&
    !token.restrict &&
    !!(date && date.getTime() - Date.now() > 0)
  );
}

function getDerivedState(rawToken: string | null) {
  const parsedToken = parseToken(rawToken);

  if (!Object.keys(parsedToken).length || rawToken === null) {
    return {
      rawToken: undefined,
      token: {},
      isAuthenticated: () => false,
      expiryDate: undefined,
    };
  }

  return {
    rawToken: rawToken,
    token: parsedToken,
    expiryDate: expiryDate(parsedToken.exp),
    isAuthenticated: () => isAuthenticated(parsedToken),
  };
}

/**
 * Returns the users authentication token, actions for setting, and persists to local storage.
 */
export const useTokenStore = create<TokenState>()((set) => {
  return {
    // Using local storage directly as persist stores in a different format and we have
    // external dependencies like modules that depend on the token being stored as is
    ...getDerivedState(localStorage.getItem("token")),

    actions: {
      setToken: (rawToken: string) => {
        localStorage.setItem("token", rawToken);

        set(getDerivedState(rawToken));
      },
      removeToken: () => {
        localStorage.removeItem("token");

        set({
          rawToken: undefined,
          token: {},
          isAuthenticated: () => false,
          expiryDate: undefined,
        });
      },
    },
  };
});

export const useToken = () => useTokenStore((state) => state.token);
export const useIsSystemAdmin = () =>
  useTokenStore((state) => state.token.userRoleTypeName === "System Admin");
export const useRawToken = () => useTokenStore((state) => state.rawToken);
export const useTokenExpiryDate = () =>
  useTokenStore((state) => state.expiryDate);
export const useIsAuthenticated = () =>
  useTokenStore((state) => state.isAuthenticated);
export const useTokenActions = () => useTokenStore((state) => state.actions);

export function setTestToken(token: string | Partial<Token>) {
  useTokenStore
    .getState()
    .actions.setToken(
      typeof token === "string"
        ? token
        : `foo.${window.btoa(JSON.stringify(token))}.bar`,
    );
}
