import GcpAccountDetails, {
  Authentication,
  Details,
  SecurityCapabilities,
} from "../../../../../components/ReviewStatus/AccountDetails";
import {
  ACCOUNT,
  AUTO,
  MASTER_SERVICE_ACCOUNT,
  ORGANIZATION,
  RECURSIVE,
  SERVICE_ACCOUNT,
  WORKSPACE_DOMAIN,
} from "../../../../../constants";
import {
  accountDetailsLabelMap,
  dataFlowEnabledMap,
  scopeMap,
} from "../../../../../messages/reviewStatusMessages";
import { type AccountType } from "../../../../../types";

import { useIsBusinessLicenseType } from "prisma";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isNil } from "remeda";
import {
  type PermissionsStatus,
  type SecurityCapabilitiesType,
} from "requests";
import { dynamicFormatMessage } from "ui";
import { startCase } from "../../../../../../../../components/VulnerabilitySidecar/AssetsTab/utils";
import { AccountTypeIcon } from "../../../../../utils";

type AccountDetailsStatusPropTypes = {
  accountName: string;
  accountGroups: string[];
  accountGroupCreationMode: string;
  accountType: AccountType;
  authenticationMode: string;
  authentication?: PermissionsStatus;
  clientEmail: string;
  dataFlowEnabled: boolean;
  organizationId: string;
  privateKeyId?: string;
  projectId: string;
  projectViewer?: string;
  securityCapabilities: SecurityCapabilitiesType[];
  serviceAccount?: string;
  isLoading: boolean;
  domainName?: string;
};

type Field = {
  renderComponent: (key: string) => JSX.Element;
  key: string;
  label: string;
  transform?: (value: string | string[]) => JSX.Element;
  rightIcon?: JSX.Element;
  defaultCount?: number;
  allowCopy?: boolean;
};

export default function AccountDetailsStatus(
  props: AccountDetailsStatusPropTypes,
) {
  const intl = useIntl();
  const { isBusinessLicenseType } = useIsBusinessLicenseType();

  const {
    accountType,
    authentication,
    accountGroupCreationMode,
    authenticationMode,
    projectViewer,
    isLoading,
    securityCapabilities,
  } = props;
  const isOrg = accountType === ORGANIZATION;
  const isMSA = accountType === MASTER_SERVICE_ACCOUNT;

  const Icon = AccountTypeIcon(accountType);

  const fields = useMemo(
    () =>
      [
        {
          renderComponent: (key: string) => (
            <Authentication
              key={key}
              data={authentication}
              isCollapsibleDetails={true}
              isAccountDetails
              isLoading={isLoading}
            />
          ),
          key: "authentication",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "authentication",
          ),
        },
        {
          key: "accountType",
          label: dynamicFormatMessage(intl, accountDetailsLabelMap, "scope"),
          transform: (value: string) =>
            dynamicFormatMessage(
              intl,
              scopeMap,
              value === ACCOUNT ? "project" : value,
            ),
          rightIcon: <Icon className="mr-1" size="sm" />,
        },
        (isMSA || isOrg) && projectViewer
          ? {
              key: "projectViewer",
              label: dynamicFormatMessage(
                intl,
                accountDetailsLabelMap,
                "projectViewer",
              ),
            }
          : undefined,
        {
          renderComponent: (key: string) => (
            <SecurityCapabilities
              key={key}
              defaultCount={2}
              value={securityCapabilities}
            />
          ),
          key: "securityCapabilities",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "securityCapabilities",
          ),
        },
        accountType === WORKSPACE_DOMAIN && {
          key: "domainName",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "domainName",
          ),
        },
        {
          key: "accountName",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "accountName",
          ),
        },
        isOrg
          ? {
              key: "organizationId",
              label: dynamicFormatMessage(
                intl,
                accountDetailsLabelMap,
                "organizationId",
              ),
            }
          : undefined,
        isBusinessLicenseType
          ? null
          : {
              key: "dataFlowEnabled",
              label: dynamicFormatMessage(
                intl,
                accountDetailsLabelMap,
                "dataFlowEnabled",
              ),
              transform: (value: string) =>
                dynamicFormatMessage(intl, dataFlowEnabledMap, value),
            },
        {
          key: "authenticationMode",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "authenticationMode",
          ),
          transform: (value: string) =>
            dynamicFormatMessage(intl, accountDetailsLabelMap, value),
        },
        {
          key: "projectId",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "projectId",
          ),
        },
        authenticationMode === SERVICE_ACCOUNT
          ? null
          : {
              key: "serviceAccount",
              label: dynamicFormatMessage(
                intl,
                accountDetailsLabelMap,
                "serviceAccount",
              ),
            },
        {
          key: "clientEmail",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "clientEmail",
          ),
        },
        {
          key: "accountGroups",
          label: dynamicFormatMessage(
            intl,
            accountDetailsLabelMap,
            "accountGroups",
          ),
          transform: (value: string) =>
            intl.formatMessage(
              {
                defaultMessage: "{label}",
                id: "wLJgeG",
                description:
                  "Description for selected Account Group with count",
              },
              {
                label:
                  isOrg && [AUTO, RECURSIVE].includes(accountGroupCreationMode)
                    ? `Creation Mode (${startCase(
                        accountGroupCreationMode.toLowerCase(),
                      )})`
                    : `${value?.length} Account ${
                        value?.length > 1 ? "Groups" : "Group"
                      } Selected`,
              },
            ),
        },
      ].filter((v) => !!v) as Field[],
    [
      Icon,
      accountGroupCreationMode,
      accountType,
      authentication,
      authenticationMode,
      intl,
      isBusinessLicenseType,
      isLoading,
      isMSA,
      isOrg,
      projectViewer,
      securityCapabilities,
    ],
  );

  const getContent = (
    transform: Field["transform"],
    key: string,
    value: unknown,
  ): JSX.Element => {
    if (!isNil(transform)) {
      if (key === "accountType") return transform(value as string);
      if (key === "accountGroups") return transform(value as string[]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return transform(value as any);
    }
    return value as JSX.Element;
  };

  return (
    <GcpAccountDetails>
      {fields.map(({ key, renderComponent, transform, ...rest }) =>
        !isNil(renderComponent) ? (
          renderComponent(key)
        ) : (
          <Details key={key} {...rest}>
            {getContent(transform, key, props[key as keyof typeof props])}
          </Details>
        ),
      )}
    </GcpAccountDetails>
  );
}
