import { type ReactElement } from "react";
import { RelativeTimestamp, type RelativeTimestampProps } from "ui";

/**
 * Returns a cell renderer using the given formatting options.
 *
 * See `RelativeTimestamp` from @prismacloud/react-ui for formatting options.
 */
export function makeRelativeTimestampCell(
  options: Omit<RelativeTimestampProps, "value"> = {},
) {
  return function RelativeTimestampCell({
    value,
  }: {
    value?: number | string | Date;
  }): ReactElement {
    if (value == undefined) {
      return <span className="w-full text-left">-</span>;
    }
    return <RelativeTimestamp size="sm" {...options} value={value} />;
  };
}
