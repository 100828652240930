import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { Checkbox } from "form";
import { isEmpty } from "remeda";

export const DataFlowCheckbox = ({ disabled }: { disabled?: boolean }) => {
  const intl = useIntl();
  const { watch } = useFormContext();
  const flowLogStorageBucket = watch("flowLogStorageBucket");

  return (
    <Checkbox
      disabled={disabled ?? isEmpty(flowLogStorageBucket)}
      name="dataFlowCheckbox"
      data-testid="gcp-data-flow-checkbox-form"
      label={intl.formatMessage({
        defaultMessage:
          "Use Dataflow to generate compressed logs (significantly reduces network egress costs)",
        id: "jx6gj5",
        description:
          "Label for Dataflow to generate compressed logs for GCP account",
      })}
      register={{ required: false }}
    />
  );
};
