import { useMemo } from "react";
import { defineMessages, useIntl, type IntlShape } from "react-intl";

import { usePermissions, useProfile } from "prisma";
import {
  makeRelativeTimestampCell,
  stringsSortType,
  type ColumnOptions,
} from "table";
import { AbsoluteTimestamp, Link, Tooltip, TruncateText } from "ui";

import { InfoIcon } from "icons";
import {
  type AllAccessKeysResponseObjectType,
  type UserRolesType,
} from "requests";
import { useRoleModalForm } from "../../roles/Forms";
import { ActionsCell, StatusCell } from "./cellRenderers";

function isValidTimestamp(timestamp: number) {
  const date = new Date(timestamp);
  return !isNaN(date.getTime());
}

export function exportDateTime(
  value: number,
  translate: (key: keyof typeof messageMap) => string,
) {
  if (value === 0) return translate("notApplicable");

  // The number 8640000000000000 is the maximum number of milliseconds accurately
  // representable, corresponding to about 285, 616 years from 1970, which defines
  // the limit of JavaScript's Date object.
  if (value > 8640000000000000) {
    const tsInMilliseconds = value / 1000000;
    if (isValidTimestamp(tsInMilliseconds)) {
      return new Date(tsInMilliseconds).toISOString();
    } else {
      return translate("notApplicable");
    }
  }

  return new Date(value).toISOString();
}

const messageMap = defineMessages({
  actions: {
    defaultMessage: "Actions",
    id: "Tv9I/4",
    description: "Actions column header",
  },
  createdBy: {
    defaultMessage: "Created By",
    id: "aLAzo3",
    description: "Created By column header",
  },
  createdOn: {
    defaultMessage: "Created On",
    id: "EVy5rI",
    description: "Created On column header",
  },
  expiresOn: {
    defaultMessage: "Expires On",
    id: "hJOYVV",
    description: "Expires On column header",
  },
  id: {
    defaultMessage: "ID",
    id: "38sWtG",
    description: "ID column header",
  },
  infoIcon: {
    defaultMessage:
      "Please be cognizant that anyone using this access key will have system admin privileges.",
    id: "3ZVqhK",
    description: "Sys Admin warning",
  },
  lastUsed: {
    defaultMessage: "Last Used",
    id: "Ykskxu",
    description: "Last Used column header",
  },
  name: {
    defaultMessage: "Name",
    id: "PIZW8F",
    description: "Name column header",
  },
  notApplicable: {
    defaultMessage: "N/A",
    id: "S09J6v",
    description: "Not Applicable abbreviation",
  },
  permissionGroup: {
    defaultMessage: "Permission Group",
    id: "7pGYAS",
    description: "Permission Group column header`",
  },
  role: {
    defaultMessage: "Role",
    id: "MPKiQS",
    description: "Role column header",
  },
  status: {
    defaultMessage: "Status",
    id: "eLQPek",
    description: "Status column header",
  },
});

const generateTranslator =
  (intl: IntlShape) => (key: keyof typeof messageMap) =>
    intl.formatMessage(messageMap[key]);

export function useAccessKeysColumns() {
  const intl = useIntl();
  const { data } = useProfile();
  const permissions = usePermissions();
  const deleteAllowed =
    permissions?.settingsAccessKeys?.["DELETE"] || data?.accessKeysAllowed;
  const updateRoleAllowed = permissions?.settingsUserRole?.["UPDATE"] ?? true;

  return useMemo(() => {
    const translate = generateTranslator(intl);

    return [
      {
        accessor: "id",
        Header: translate("id"),
        width: 300,
      },
      {
        accessor: "name",
        Header: translate("name"),
        sortType: stringsSortType,
      },
      {
        accessor: "role",
        Cell: ({
          context,
          value,
        }: {
          context: { roles: UserRolesType };
          value: { id: string; name: string };
        }) => {
          const { openModal } = useRoleModalForm();
          const { roles } = context;
          const isMsspSystemAdminRole = value.name === "MSSP System Admin";

          return (
            <TruncateText>
              {updateRoleAllowed && !isMsspSystemAdminRole ? (
                <Link
                  size="sm"
                  onClick={() =>
                    openModal({
                      action: "edit",
                      id: value?.id,
                      // @ts-expect-error Going to fix this in a separate MR for Roles TS fixes.
                      initialValues: roles.find((role) => role.id === value.id),
                    })
                  }
                >
                  {value.name}
                </Link>
              ) : (
                value.name
              )}
            </TruncateText>
          );
        },
        cellToString: ({ value }: { value: { id: string; name: string } }) =>
          value.name,
        Header: translate("role"),
        sortType: stringsSortType,
      },
      {
        accessor: "roleType",

        Cell: ({ value }: { value: string }) => {
          return (
            <>
              <TruncateText>
                <span>{value}</span>
              </TruncateText>
              <span className="ml-1">
                {value === "System Admin" && (
                  <Tooltip label={translate("infoIcon")}>
                    <span>
                      <InfoIcon aria-label={translate("infoIcon")} />
                    </span>
                  </Tooltip>
                )}
              </span>
            </>
          );
        },
        Header: translate("permissionGroup"),
        sortType: stringsSortType,
      },
      {
        accessor: "createdBy",
        Header: translate("createdBy"),
        sortType: stringsSortType,
        width: 300,
      },
      {
        accessor: "createdTs",
        Cell: makeRelativeTimestampCell(),
        cellToString: ({ value }: { value: number }) => {
          return exportDateTime(value, translate);
        },
        Header: translate("createdOn"),
      },
      {
        accessor: "expiresOn",

        Cell: ({ value }: { value: number }) => {
          if (!value) return translate("notApplicable");

          if (value > 8640000000000000) {
            const tsInMilliseconds = value / 1000000;
            if (isValidTimestamp(tsInMilliseconds)) {
              return <AbsoluteTimestamp value={tsInMilliseconds} />;
            } else {
              return translate("notApplicable");
            }
          }

          return <AbsoluteTimestamp value={value} />;
        },
        cellToString: ({ value }: { value: number }) => {
          return exportDateTime(value, translate);
        },
        Header: translate("expiresOn"),
        width: 200,
      },
      {
        accessor: "lastUsedTime",
        Cell: makeRelativeTimestampCell(),
        cellToString: ({ value }: { value: number }) => {
          return exportDateTime(value, translate);
        },
        Header: translate("lastUsed"),
      },
      {
        accessor: "status",
        Cell: StatusCell,
        Header: translate("status"),
      },
      deleteAllowed && {
        Aggregated: () => null,
        Cell: ActionsCell,
        disableExport: true,
        disableGroupBy: true,
        disableHideColumn: true,
        disableResizing: true,
        disableSortBy: true,
        Header: translate("actions"),
        id: "actions",
        sticky: true,
        width: 100,
      },
    ].filter(Boolean) as ColumnOptions<AllAccessKeysResponseObjectType>[];
  }, [intl, deleteAllowed, updateRoleAllowed]);
}
