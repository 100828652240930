import { type ReactElement } from "react";
import { classNames } from "utils";

import { type Data, type HeaderGroup } from "../../types";
import { useTableLayoutEnabled, type TableLayoutProps } from "../TableLayout";
import { FooterCell } from "./FooterCell";
import { groupColumns } from "./HeaderRow";
import { StickyBorder } from "./StickyBorder";

export interface FooterRowProps<D extends Data> {
  footerGroup: HeaderGroup<D>;
}

export function FooterRow<D extends Data>({
  appearance = "primary",
  footerGroup,
}: FooterRowProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  const {
    selectRowColumn,
    groupedColumns,
    leftStickyColumns,
    generalColumns,
    rightStickyColumns,
  } = groupColumns(footerGroup.headers);
  const tableLayoutEnabled = useTableLayoutEnabled();
  const className = classNames(
    "box-content bg-white dark:bg-blue-steel-950",
    tableLayoutEnabled || appearance === "secondary"
      ? "border-r-0"
      : "border border-r-0 dark:border-blue-steel-850",
  );

  return (
    <div
      {...footerGroup.getFooterGroupProps({ role: "row" })}
      className={className}
    >
      {!!selectRowColumn.length && (
        <div className="flex">
          {selectRowColumn.map((column) => (
            <FooterCell
              column={column}
              key={column.id}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      {!!groupedColumns.length && (
        <div className="flex">
          {groupedColumns.map((column) => (
            <FooterCell
              column={column}
              key={column.id}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      {!!leftStickyColumns.length && (
        <div className="flex">
          {leftStickyColumns.map((column) => (
            <FooterCell
              column={column}
              key={column.id}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      {!!generalColumns.length && (
        <div className="flex grow">
          {generalColumns.map((column) => (
            <FooterCell
              column={column}
              key={column.id}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      {!!rightStickyColumns.length && (
        <div
          style={{
            background: "inherit",
            position: "sticky",
            right: 0,
          }}
          className="flex"
        >
          <StickyBorder />
          {rightStickyColumns.map((column) => (
            <FooterCell
              column={column}
              key={column.id}
              appearance={appearance}
            />
          ))}
        </div>
      )}
    </div>
  );
}
