import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const MoonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 16 15"}>
      <path d="M12.3177 0.662725L12.149 0.110205C12.1041 -0.036735 11.8961 -0.036735 11.8512 0.110205L11.6825 0.662725C11.311 1.87898 10.3929 2.85199 9.20023 3.29331L9.03622 3.354C8.90069 3.40415 8.90069 3.59585 9.03622 3.646L9.20023 3.70669C10.3929 4.14802 11.311 5.12102 11.6825 6.33727L11.8512 6.88979C11.8961 7.03673 12.1041 7.03673 12.149 6.88979L12.3177 6.33727C12.6891 5.12101 13.6072 4.14802 14.7999 3.70669L14.9639 3.646C15.0995 3.59585 15.0995 3.40415 14.9639 3.354L14.7999 3.29331C13.6072 2.85199 12.6891 1.87897 12.3177 0.662725Z" />
      <path d="M6.66537 14.3845C10.2474 15.0035 13.4784 13.0716 14.8269 10.1012C15.0169 9.68264 14.6012 9.23396 14.1824 9.4235C13.0016 9.95796 11.6273 10.1517 10.2005 9.87328C7.66486 9.37845 5.62084 7.33398 5.12648 4.79822C4.84839 3.3718 5.04222 1.99807 5.57653 0.817634C5.76608 0.398854 5.31742 -0.0169362 4.89886 0.173084C1.92839 1.52162 -0.00358355 4.75275 0.615556 8.33485C1.14319 11.3875 3.61266 13.8569 6.66537 14.3845Z" />
    </SvgIcon>
  );
};
