import { QueryClient } from "@tanstack/react-query";
import { type ApiError } from "requests";

export const retryFunction = (failureCount: number, error: unknown) => {
  if (failureCount > 3) {
    return false;
  }

  const status: number | undefined = (error as ApiError)?.status;
  if (status && status >= 400 && status < 500 && status !== 429) {
    return false;
  }

  return true;
};

/**
 * @deprecated Do NOT import this. You should use `useQueryClient` in components, or `context?.queryClient` in route definitions.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 30 * 1000,
      retry: retryFunction,
    },
  },
});
