import { type Set } from "immutable";
import { type TreeMapType } from "./types";

type AncestorSelectedProps = {
  id: string;
  treeMap: TreeMapType;
  selectedNodes: Set<string>;
};

export default function ancestorSelected({
  id,
  treeMap,
  selectedNodes,
}: AncestorSelectedProps): boolean {
  const parentId = treeMap.getIn([id, "parent"]);

  if (!parentId) {
    return false;
  }
  if (selectedNodes.has(parentId)) {
    return true;
  }

  return ancestorSelected({ id: parentId, treeMap, selectedNodes });
}
