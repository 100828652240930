import { type ReactNode } from "react";
import { Overline } from "ui";

export function SubNavHeader({ children }: { children: ReactNode }) {
  return (
    <Overline addClassName="text-secondary dark:text-dark-bg-secondary">
      {children}
    </Overline>
  );
}
