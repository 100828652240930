import {
  useEffect,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type ReactNode,
} from "react";
import {
  FormProvider,
  useForm,
  type FieldValues,
  type Path,
  type SubmitHandler,
  type UseFormProps,
} from "react-hook-form";
import { Modal, useModalConfirmCloseContext, type ModalProps } from "ui";
import { classNames, noop } from "utils";

export type FormProps<T extends FieldValues> = {
  /**
   * Add a class name to the form.
   */
  addClassName?: string;
  children: ReactNode;
  /**
   * The name of the form element that should be focused when the form mounts.
   *
   * Gotcha: When the Form is in a Modal, the Modal may override the focus applied by the Form here. Add `data-autofocus` to the field so the Modal will focus it on mount.
   */
  focusOnMount?: Path<T>;
  /**
   * To turn this form into a modal, simply pass `modalProps`. All props supported by `Modal` are supported here.
   */
  modalProps?: Omit<ModalProps, "children">;
  /**
   * Pass any props to the HTML form element.
   */
  formProps?: Omit<ComponentPropsWithoutRef<"form">, "onSubmit">;
  /**
   * A function that gets called when the form is submitted.
   *
   * This will only be called if the form is also valid.
   */
  onSubmit?: SubmitHandler<T>;
  /**
   * A function that will be called with the errors if there are any validation errors on submit.
   */
  onError?: SubmitHandler<Record<string, unknown>>;
} & UseFormProps<T>;

/**
 * Renders a Form and wraps the children in a FormProvider. All extra props will be passed to `useForm` from react-hook-form.
 */
export function Form<T extends FieldValues>(props: FormProps<T>): ReactElement {
  const {
    addClassName,
    children,
    focusOnMount,
    modalProps,
    formProps = {},
    onSubmit = noop,
    onError = noop,
    ...rest
  } = props;
  const formMethods = useForm<T>(rest);
  const setConfirmClose = useModalConfirmCloseContext();

  const {
    formState: { dirtyFields },
  } = formMethods;
  const isDirty = Object.keys(dirtyFields).length > 0;

  useEffect(() => {
    if (focusOnMount) {
      // There is a weird issue with ChipInput in wizards when there is a
      // ChipInput on a form that is not the initial form/step rendered where the
      // last ChipInput will be automatically focused upon mount. This timeout will force the focus
      // to the field named by focusOnMount after the ChipInput is focused.
      // THIS IS A HACK. There seems to be some interplay between
      // downshift/useMultipleSelection's ref, which is used for focus management,
      // and react-hook-form at play I can't figure out.
      // It may be bug with one or the other?
      const timeout = setTimeout(() => formMethods.setFocus(focusOnMount));

      return () => {
        clearTimeout(timeout);
      };
    } else return noop;
  }, [focusOnMount, formMethods]);

  useEffect(() => {
    setConfirmClose?.(isDirty);
  }, [isDirty, setConfirmClose]);

  useEffect(() => {
    return () => setConfirmClose?.(false);
  }, [setConfirmClose]);

  const body = (
    <FormProvider {...formMethods}>
      <form
        className={classNames(
          addClassName,
          modalProps && "flex flex-auto flex-col overflow-auto",
        )}
        onSubmit={formMethods.handleSubmit(onSubmit, onError)}
        {...formProps}
      >
        {children}
      </form>
    </FormProvider>
  );

  if (modalProps) {
    return (
      <Modal confirmClose={isDirty} {...modalProps}>
        {body}
      </Modal>
    );
  }
  return body;
}
