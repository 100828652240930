import { lazy } from "react";
import { createRoute, privateRootRoute, redirect } from "router";

const ComplianceLayout = lazy(() =>
  import("./ComplianceLayout").then((res) => ({
    default: res.ComplianceLayout,
  })),
);

export const complianceLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "compliance",
  component: ComplianceLayout,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/compliance") {
      throw redirect({ to: "/compliance/standards", replace: true, search });
    }
  },
});

const PageNotFound = lazy(() =>
  import("../CatchAll/CatchAll").then((res) => ({ default: res.CatchAll })),
);

export const complianceCatchAllRoute = createRoute({
  getParentRoute: () => complianceLayoutRoute,
  path: "$",
  component: PageNotFound,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/compliance/reports") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error report_id may or may not be there - no error
      const { report_id = null, timestamp: _timestamp, ...newSearch } = search;
      if (report_id) {
        throw redirect({
          to: "/reports/compliance/$reportId/download",
          params: { reportId: report_id },
          replace: true,
          search: newSearch,
        });
      }
      throw redirect({ to: "/reports/compliance", replace: true, search });
    } else if (pathname === "/compliance/overview") {
      throw redirect({ to: "/compliance/standards", replace: true, search });
    }
  },
});
