import { Checkbox, useFormContext } from "form";
import { CogsIcon } from "icons";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AccordionPanel, Banner, Body, Bold, Tag } from "ui";
import { classNames } from "utils";
import { useWizardContext } from "../../../../../../components/Wizard";
import { ADD_DETAILS, ORGANIZATION } from "../../../../../constants";
import { type AwsAddDetailsStepValues } from "../../../../../types";
import { getWizardStepIndex } from "../../../../Onboarding/utils";

export default function AdvancedConfiguration() {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { setValue, watch } = useFormContext();
  const {
    state: { steps },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);
  const isCustomMemberRoleNameEnabled = watch("customMemberRoleNameEnabled");

  const { accountType, onboardType } = steps[addDetailsStepIndex]
    ?.values as AwsAddDetailsStepValues;

  const isOrg = accountType === ORGANIZATION;
  const isGenerateTemplate = onboardType === "template";

  useEffect(() => {
    !isCustomMemberRoleNameEnabled && setValue("unifiedCftDisabled", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomMemberRoleNameEnabled]);

  useEffect(() => {
    !isOrg && setValue("customMemberRoleNameEnabled", false);
  }, [isOrg, setValue]);

  return (
    <AccordionPanel
      isOpen={isOpen}
      onClick={() => {
        setIsOpen((prev) => !prev);
      }}
      icon={<CogsIcon />}
      title={
        <>
          <div className="flex">
            <span className="flex-1">
              <FormattedMessage
                defaultMessage="Advanced Settings"
                id="hR5KZa"
                description="description for Advanced Settings card header"
              />
            </span>

            {isCustomMemberRoleNameEnabled && isOrg && isGenerateTemplate && (
              <span className="-ml-16">
                <Tag size="md" appearance="blue">
                  <FormattedMessage
                    defaultMessage="One or more settings enabled"
                    id="I8wcX+"
                    description="Tag text when feature is checked"
                  />
                </Tag>
              </span>
            )}
          </div>
        </>
      }
    >
      <div
        className={classNames(
          isOrg ? "grid-rows-2" : "grid-rows-1",
          "grid px-10",
        )}
      >
        <div className="flex px-4">
          <div className="w-7 [&>div>label:nth-child(1)]:hidden ">
            <Checkbox
              name="useTenantExternalId"
              showLabel={false}
              label={intl.formatMessage({
                defaultMessage: "Use Tenant Specific External ID",
                id: "ldQCba",
                description: "Checkbox label",
              })}
            />
          </div>

          <div className="flex space-x-2">
            <div className="-mt-1">
              <Bold as="p" addClassName="flex items-center space-x-1">
                <FormattedMessage
                  defaultMessage="Use Tenant Specific External ID"
                  id="/p81Zw"
                  description="Advance Configuration Settings card body"
                />
              </Bold>
            </div>
          </div>
        </div>

        {isOrg && (
          <>
            <div className="flex px-4">
              <div className="w-7 [&>div>label:nth-child(1)]:hidden">
                <Checkbox
                  name="skipOverrideMemberRoleName"
                  showLabel={false}
                  label={intl.formatMessage({
                    defaultMessage:
                      "Preserve Role ARN and External ID of already onboarded accounts that belong to this Org.",
                    id: "xlqdmO",
                    description: "Checkbox label",
                  })}
                />
              </div>
              <div className="flex space-x-2">
                <div className="-mt-1">
                  <Bold as="p" addClassName="flex items-center space-x-1">
                    <FormattedMessage
                      defaultMessage="Preserve Role ARN and External ID of already onboarded accounts that belong to this Org."
                      id="WsUv20"
                      description="Advance Configuration Settings card body"
                    />
                  </Bold>
                  <Body appearance="secondary" size="sm">
                    <FormattedMessage
                      defaultMessage="Prisma Cloud will retain the existing Role Name and external ID for the member accounts."
                      id="dHZAE2"
                      description="Advance Configuration Settings card body"
                    />
                  </Body>
                </div>
              </div>
            </div>
            {isGenerateTemplate && (
              <>
                <div className="flex px-4 pt-2">
                  <div className="w-7 [&>div>label:nth-child(1)]:hidden">
                    <Checkbox
                      name="customMemberRoleNameEnabled"
                      label={intl.formatMessage({
                        defaultMessage:
                          "Use a different role name for Member Accounts.",
                        id: "Py0BWw",
                        description: "Checkbox label",
                      })}
                      showLabel={false}
                    />
                  </div>
                  <div className="flex space-x-2">
                    <div className="-mt-1">
                      <Bold as="p" addClassName="flex items-center space-x-1">
                        <FormattedMessage
                          defaultMessage="Use a different role name for Member Accounts."
                          id="uQutKb"
                          description="Advance Configuration Settings card body"
                        />
                      </Bold>
                      <Body appearance="secondary" size="sm">
                        <FormattedMessage
                          defaultMessage="On enabling, you must enter the same Member Account IAM Role Name below that you entered while running the CFT"
                          id="vNhg1H"
                          description="Advance Configuration Settings card body"
                        />
                      </Body>
                    </div>
                  </div>
                </div>

                <div className="flex px-12 pt-4">
                  <div className="w-7 [&>div>label:nth-child(1)]:hidden">
                    <Checkbox
                      name="unifiedCftDisabled"
                      disabled={!isCustomMemberRoleNameEnabled}
                      label={intl.formatMessage({
                        defaultMessage:
                          "Use separate CFTs for Management & Member account.",
                        id: "rcAqlO",
                        description: "Checkbox label",
                      })}
                      showLabel={false}
                    />
                  </div>
                  <div className="flex space-x-2">
                    <div className="-mt-1">
                      <Bold as="p" addClassName="flex items-center space-x-1">
                        <FormattedMessage
                          defaultMessage="Use separate CFTs for Management & Member account."
                          id="79uIjO"
                          description="Advance Configuration Settings card body"
                        />
                      </Bold>
                      <Body appearance="secondary" size="sm">
                        <FormattedMessage
                          defaultMessage="On enabling, you must download and run both the CFTs."
                          id="nNbojK"
                          description="Advance Configuration Settings card body"
                        />
                      </Body>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {isCustomMemberRoleNameEnabled && isOrg && isGenerateTemplate && (
        <div className="pt-2">
          <Banner
            type="inline"
            appearance="warning"
            showIcon
            data-testid="advanced-settings-banner"
          >
            <ol className="list-decimal pl-2 pt-2">
              <li>
                <FormattedMessage
                  defaultMessage="Do not delete the existing Prisma role from the member accounts until you have downloaded and executed the CFT below in the member accounts and saved the changes."
                  id="vq1cin"
                  description="Banner messagE for Separate CFTs for Management & Member account"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Do not proceed to the next step until you downloaded and executed the CFT."
                  id="UBdYRq"
                  description="Banner messagE for Separate CFTs for Management & Member account"
                />
              </li>
            </ol>
          </Banner>
        </div>
      )}
    </AccordionPanel>
  );
}
