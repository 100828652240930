import {
  useCallback,
  useMemo,
  useReducer,
  type ElementType,
  type ReactNode,
} from "react";

import { WizardFormNavItem } from "./NavItem";
import { type FormStatus } from "./types";

export interface WizardStepState {
  /**
   * The contents of the Step, `WizardForm` in the vast majority of cases
   */
  Component: ElementType | ReactNode;
  /**
   * Indicates the step is disabled and the user cannot navigate directly to it
   */
  disabled: boolean;
  /**
   * Indicates this step has been submitted. (Not the entire wizard, but just this step)
   */
  hasBeenSubmitted: boolean;
  /**
   * A unique identifier for the step
   */
  key: string;
  /**
   * The name of the step. Used for the nav item and the default step title
   */
  label: string;
  /**
   * The current status of the form
   */
  status?: FormStatus;
  /**
   * The initial or last submitted values of the form
   */
  values?: Record<string, unknown>;
  /**
   * Indicates this step nav-item have header
   */
  hasHeader?: boolean;
  /**
   * The header name for the current nav-item
   */
  headerLabel?: string;
}

export interface WizardState {
  activeIndex: number;
  isDirty: boolean;
  steps: WizardStepState[];
  title: ReactNode;
}

export interface UseWizardStateOptions {
  initialState: WizardState;
  reducer?: typeof defaultReducer;
}

interface GotoStepPayload {
  next: number;
  currentStep?: { index: number & Partial<WizardStepState> };
}

interface SubmitStepPayload {
  index: number;
  values: Record<string, unknown>;
}

interface StatusUpdatePayload {
  index: number;
  status: FormStatus;
}

interface DisableStepPayload {
  index: number;
  value: boolean;
}

interface ResetSubmitPayload {
  index: number;
}

interface ValuesUpdatePayload {
  index: number;
  values: Record<string, unknown>;
}

interface AddStepPayload {
  index: number;
  steps: WizardStepState[];
}

interface RemoveStepPayload {
  index: number;
}

export interface UseWizardStateReturn {
  gotoStep: (payload: GotoStepPayload) => void;
  navItems: ReactNode;
  state: WizardState;
  statusUpdate: (payload: StatusUpdatePayload) => void;
  submitStep: (payload: SubmitStepPayload) => void;
  disableStep: (payload: DisableStepPayload) => void;
  resetSubmitStep: (payload: ResetSubmitPayload) => void;
  valuesUpdate: (payload: ValuesUpdatePayload) => void;
  addStep: (payload: AddStepPayload) => void;
  removeStep: (payload: RemoveStepPayload) => void;
}
type Action =
  | {
      type: "submitStep";
      payload: SubmitStepPayload;
    }
  | {
      type: "gotoStep";
      payload: GotoStepPayload;
    }
  | {
      type: "statusUpdate";
      payload: StatusUpdatePayload;
    }
  | {
      type: "disableStep";
      payload: DisableStepPayload;
    }
  | {
      type: "resetSubmitStep";
      payload: ResetSubmitPayload;
    }
  | {
      type: "valuesUpdate";
      payload: ValuesUpdatePayload;
    }
  | {
      type: "addStep";
      payload: AddStepPayload;
    }
  | {
      type: "removeStep";
      payload: RemoveStepPayload;
    };

export function defaultReducer(
  state: WizardState,
  action: Action,
): WizardState {
  switch (action.type) {
    case "submitStep": {
      const { index, values } = action.payload;
      const activeIndex = state.steps[index + 1] ? index + 1 : index;
      const steps = state.steps.map((step, i) => {
        // The step being submitted
        if (i === index) {
          return {
            ...step,
            disabled: false,
            hasBeenSubmitted: true,
            status: "valid" as FormStatus,
            values,
          };
        }

        // The next step
        if (i === index + 1) {
          return { ...step, disabled: false };
        }

        return step;
      });
      return { ...state, activeIndex, isDirty: true, steps };
    }
    case "gotoStep": {
      const activeIndex = action.payload.next;
      const currentStep = action.payload.currentStep;

      if (currentStep) {
        const { index, ...stateUpdate } = currentStep;
        const steps = state.steps.map((step, i) => {
          if (i === index) {
            return { ...step, ...stateUpdate };
          }
          return step;
        });

        return { ...state, activeIndex, steps };
      }

      return { ...state, activeIndex };
    }
    case "statusUpdate": {
      const { index, status } = action.payload;

      return {
        ...state,
        steps: state.steps.map((step, i) => {
          if (i === index) {
            return { ...step, status };
          } else if (i > index) {
            if (status === "error") {
              return { ...step, disabled: true };
            } else if (
              status === "valid" &&
              state.steps[Math.max(i - 1, 0)].hasBeenSubmitted
            ) {
              return { ...step, disabled: false };
            }
          }
          return step;
        }),
      };
    }
    case "disableStep": {
      const { index, value } = action.payload;

      return {
        ...state,
        steps: state.steps.map((step, i) => {
          if (i === index) {
            return { ...step, disabled: value };
          }
          return step;
        }),
      };
    }

    case "resetSubmitStep": {
      const { index } = action.payload;
      return {
        ...state,
        steps: state.steps.map((step, i) => {
          if (i === index) {
            return {
              ...step,
              hasBeenSubmitted: false,
              status: "pending",
            };
          }
          return step;
        }),
      };
    }

    case "valuesUpdate": {
      const { index, values } = action.payload;

      return {
        ...state,
        steps: state.steps.map((step, i) => {
          if (i === index) {
            return { ...step, values };
          }
          return step;
        }),
      };
    }
    case "addStep": {
      const { index, steps } = action.payload;
      return {
        ...state,
        steps: [
          ...state.steps.slice(0, index),
          ...steps,
          ...state.steps.slice(index),
        ],
      };
    }

    case "removeStep": {
      const { index } = action.payload;
      return {
        ...state,
        steps: [
          ...state.steps.slice(0, index),
          ...state.steps.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
}

export function useWizardState({
  initialState,
  reducer = defaultReducer,
}: UseWizardStateOptions): UseWizardStateReturn {
  const [state, dispatch] = useReducer(reducer, initialState);

  const gotoStep = useCallback(
    (payload: GotoStepPayload) => dispatch({ type: "gotoStep", payload }),
    [],
  );
  const statusUpdate = useCallback(
    (payload: StatusUpdatePayload) =>
      dispatch({ type: "statusUpdate", payload }),
    [],
  );
  const submitStep = useCallback(
    (payload: SubmitStepPayload) => dispatch({ type: "submitStep", payload }),
    [],
  );
  const disableStep = useCallback(
    (payload: DisableStepPayload) => dispatch({ type: "disableStep", payload }),
    [],
  );
  const valuesUpdate = useCallback(
    (payload: ValuesUpdatePayload) =>
      dispatch({ type: "valuesUpdate", payload }),
    [],
  );
  const addStep = useCallback(
    (payload: AddStepPayload) => dispatch({ type: "addStep", payload }),
    [],
  );
  const removeStep = useCallback(
    (payload: RemoveStepPayload) => dispatch({ type: "removeStep", payload }),
    [],
  );
  const resetSubmitStep = useCallback(
    (payload: ResetSubmitPayload) =>
      dispatch({ type: "resetSubmitStep", payload }),
    [],
  );

  const navItems = useMemo(() => {
    return state.steps.map(
      (
        {
          disabled,
          hasBeenSubmitted,
          hasHeader,
          headerLabel,
          key,
          label,
          status,
        },
        index,
      ) => {
        return (
          <WizardFormNavItem
            key={key}
            disabled={disabled}
            isActive={index === state.activeIndex}
            onClick={(currentStepState) => {
              gotoStep({
                next: index,
                currentStep: { index: state.activeIndex, ...currentStepState },
              });
            }}
            hasHeader={hasHeader}
            headerLabel={headerLabel}
            status={hasBeenSubmitted ? status : undefined}
          >
            {label}
          </WizardFormNavItem>
        );
      },
    );
  }, [gotoStep, state.activeIndex, state.steps]);

  return {
    gotoStep,
    navItems,
    state,
    statusUpdate,
    submitStep,
    disableStep,
    valuesUpdate,
    addStep,
    removeStep,
    resetSubmitStep,
  };
}
