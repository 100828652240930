import { FormattedMessage, useIntl } from "react-intl";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../../components";

const radarsFeatures: Features[] = [
  {
    featureName: "computeRadarsCloud",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeRadarsHosts",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeRadarsContainers",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
  {
    featureName: "computeRadarsServerless",
    operations: {
      UPDATE: false,
      READ: false,
    },
  },
];

export function RadarsTab() {
  const intl = useIntl();
  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(radarsFeatures);

  return (
    <div className="divide-y divide-primary">
      <Row
        label={intl.formatMessage({
          defaultMessage: "Select All",
          id: "aYkfGO",
          description: "Select all checkbox row",
        })}
        addClassName="border-b-2"
        read={
          <SelectAllCheckbox
            status={selectionStatus.read}
            onChange={() => {
              onClickSelectAll("READ");
            }}
          >
            <FormattedMessage
              defaultMessage="Select view for all compute radars rows"
              id="VfvC9u"
              description="Permission group section header"
            />
          </SelectAllCheckbox>
        }
        update={
          <SelectAllCheckbox
            status={selectionStatus.update}
            onChange={() => {
              onClickSelectAll("UPDATE");
            }}
          >
            <FormattedMessage
              defaultMessage="Select update for all compute radars rows"
              id="sYh1Gh"
              description="Permission group section header"
            />
          </SelectAllCheckbox>
        }
      />

      {radarsFeatures.map((feature) => (
        <CheckboxRow key={feature.featureName} feature={feature} />
      ))}
    </div>
  );
}
