import { defineMessages } from "react-intl";

export const messageMap = defineMessages({
  description: {
    defaultMessage:
      "Prisma Cloud recommends selecting all member accounts (default). Disable to include or exclude specific accounts from monitoring.",
    id: "jTTgUr",
    description:
      "description for Select All Member Accounts card header description",
  },
  title: {
    defaultMessage: "Select All Member Accounts",
    id: "3Za+Qz",
    description: "description for Select All Member Accounts card header",
  },
});
