import { SecurityCapabilitiesIcon } from "icons";
import { isNil } from "remeda";

import { type SecurityCapabilitiesPermissionsStatus } from "../../../../../types";
import CollapsibleDetails from "../CollapsibleDetails";

type RemediationProps = {
  data?: SecurityCapabilitiesPermissionsStatus;
  isCollapsibleDetails?: boolean;
};

export default function Remediation({
  data,
  isCollapsibleDetails = true,
}: RemediationProps) {
  if (isNil(data)) return null;

  return (
    <CollapsibleDetails
      icon={<SecurityCapabilitiesIcon type="Remediation" />}
      label="remediation"
      isCollapsibleDetails={isCollapsibleDetails}
      {...data}
    />
  );
}
