import { useIntl } from "react-intl";

import { Input } from "form";
import { useTrimField } from "../../../../../../hooks";
import { getValidateUserOCID } from "../../../../utils";

export default function UserOCID({ name }: { name: string }) {
  const intl = useIntl();
  useTrimField("userOcid");

  return (
    <Input
      data-selector="oci-user-ocid-input"
      name="userOcid"
      label={intl.formatMessage({
        defaultMessage: "User OCID",
        id: "pH2BWL",
        description: "The name of an input field for onboarding an OCI account",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "eg - ocid.user.oc..uniqueId",
        id: "eRBQFN",
        description: "Placeholder for User OCID",
      })}
      defaultValue={name}
      register={{
        required: intl.formatMessage({
          defaultMessage: "User OCID is required",
          id: "XPQsBJ",
          description: "Error message for field for onboarding a OCI tenant",
        }),
        validate: getValidateUserOCID(intl),
      }}
      tooltip={intl.formatMessage({
        defaultMessage: "Your User OCID here",
        id: "u8lqak",
        description: "Tooltip for Group Name input field",
      })}
    />
  );
}
