import { type ActiveRoleSchema, type PermissionOperations } from "requests";

import { isTest } from "environment";
import type z from "zod";
import { create } from "zustand";

export type PermissionFeatures = z.infer<
  typeof ActiveRoleSchema.shape.permissionGroup.shape.features
>;

type PermissionState = {
  permissions: PermissionOperations;
  initialized: boolean;
  reset: () => void;
  setPermissions: (features: PermissionFeatures) => void;
};

const fallbackOperationsHandler = {
  get(target: Record<string, unknown>, prop: string, receiver: string) {
    const result = Reflect.get(target, prop, receiver);

    if (result !== undefined) return result;

    return isTest() ? true : false;
  },
};

const fallbackObjectHandler = {
  get(target: Record<string, unknown>, prop: string, receiver: string) {
    const result = Reflect.get(target, prop, receiver);

    if (result !== undefined) return result;

    return new Proxy({}, fallbackOperationsHandler);
  },
};

/**
 * This is used to store and reference the permissions for the current user.
 */
export const usePermissionStore = create<PermissionState>()((set) => ({
  initialized: false,
  permissions: new Proxy({}, fallbackObjectHandler) as PermissionOperations,
  reset: () =>
    set({
      initialized: false,
      permissions: new Proxy({}, fallbackObjectHandler) as PermissionOperations,
    }),
  setPermissions: (features) =>
    set({
      initialized: true,
      // Using a proxy for fallbacks incase the API does not return a feature
      permissions: new Proxy(
        Object.fromEntries(
          features.map(({ featureName, operations }) => [
            featureName,
            new Proxy(operations, fallbackOperationsHandler),
          ]),
        ),
        fallbackObjectHandler,
      ) as PermissionOperations,
    }),
}));

// Mock permissions for tests
export function mockPermissions(permissionFeatures: PermissionFeatures) {
  usePermissionStore.getState().setPermissions(permissionFeatures);
}

export const usePermissions = () =>
  usePermissionStore((state) => state.permissions);
export const usePermissionsInitialized = () =>
  usePermissionStore((state) => state.initialized);
export const useSetPermissions = () =>
  usePermissionStore((state) => state.setPermissions);
