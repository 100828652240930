import {
  NextButton,
  PrevButton,
  WizardForm,
} from "../../../../../components/Wizard";
import { ProductDocumentationLink } from "../../components";

import { FormLayout } from "form";
import { useFlags } from "launchdarkly-react-client-sdk";
import ScanOptionsRadioGroup from "./ScanOptionsRadioGroup";

export default function ConfigureDataSecurity() {
  const { simplifiedOnboardingExperience } = useFlags();
  return (
    <WizardForm
      actions={
        <>
          <PrevButton />
          <NextButton />
        </>
      }
      secondaryFooterContent={
        !simplifiedOnboardingExperience && <ProductDocumentationLink />
      }
    >
      <FormLayout>
        <ScanOptionsRadioGroup />
      </FormLayout>
    </WizardForm>
  );
}
