/**
 * usage
 * import regexes from 'models/regexes';
 *
 * regexes.email.test(value);
 * or
 * value.match(regexes.email);
 */

export const regexesMaintainedByBE = {
  alibabaAccountId: "^(\\d{8}|\\d{16})$",
  alibabaRamArn: "^acs:ram::(\\d{8}|\\d{16}):role\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  amazonSqsUrl:
    "^https://sqs\\.(us-gov-(west|east)-1|((us|ca|eu|sa|ap)-(north|south|east|west|central){1,2}-\\d)).amazonaws.com/\\d{12,12}/\\S{1,80}$",
  amazonSqsUrlChina:
    "https://sqs\\.cn-((north|south|east|west|central){1,2}-\\d)\\.amazonaws.com.cn/\\d{12,12}/\\S{1,80}$",
  awsAccountId: "^\\d{12,12}$",
  awsIamKeyArn:
    "^arn:aws.*:kms:(us-gov-(west|east)-1|((us|ca|eu|sa|ap)-(north|south|east|west|central){1,2}-\\d)):\\d{12}:key\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  awsIamKeyArnChina:
    "^arn:aws-cn:kms:(us-gov-(west|east)-1|((us|ca|eu|sa|ap)-(north|south|east|west|central){1,2}-\\d)):\\d{12}:key\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  awsIamKeyArnGov:
    "^arn:aws-us-gov:kms:(us-gov-(west|east)-1|((us|ca|eu|sa|ap)-(north|south|east|west|central){1,2}-\\d)):\\d{12}:key\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  awsIamRoleArn: "^arn:aws.*:iam::\\d{12}:role\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  awsIamRoleArnChina:
    "^arn:aws-cn:iam::\\d{12}:role\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  awsIamRoleArnGov:
    "^arn:aws-us-gov:iam::\\d{12}:role\\/?[a-zA-Z_0-9+=,.@\\-\\/]+$",
  azureSasUrl:
    "https:\\/\\/([a-z0-9]{3,24}\\.blob\\.core\\.windows\\.net)\\/?\\?[\\w-]+=[^\u0026]+(?:\u0026[\\w-]+=[^\u0026]+)*",
  azureSasUrlEndTs: "[?\u0026]se=(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z)",
  azureSasUrlSig: "[?\u0026]sig=([^\u0026]+)",
  azureSasUrlStartTs:
    "[?\u0026]st=(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z)",
  domainName:
    "^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]",
  email:
    '^(([^\u003C\u003E()\\[\\]\\\\.,;:\\s@"]+(\\.[^\u003C\u003E()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  gcpProjectName: "[A-Za-z0-9-]+",
  guid: "^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$",
  // integrationName is not used in the app, instead beginsWithAlphaNumeric regex and the length validator are used separately
  integrationName: "^[A-Za-z0-9](.){0,99}$",
  oracleGroupName: "^[0-9a-zA-Z-._]+$",
  oraclePolicyName: "^[0-9a-zA-Z-._]+$",
  oracleTenantOCID:
    "^(ocid.?[0-9])(.{1}tenancy.*)([0-9a-zA-Z-_]*[.:]){3,}([0-9a-zA-Z-_]+)$",
  oracleUserName: "^[0-9a-zA-Z-._+@]+$",
  oracleUserOCID:
    "^(ocid.?[0-9])(.{1}user.*)([0-9a-zA-Z-_]*[.:]){3,}([0-9a-zA-Z-_]+)$",
  passwordStrength:
    '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~\\`!@#$%^\u0026*\\(\\)_\\-+=\\[\\]{}|\\\\:";\u0027\u003C\u003E,.?\\/])(?=\\S+$).{8,30}$',
  serviceAccountName: "^[a-zA-Z0-9-_\\s]*$",
  slackPrefix: "https://hooks.slack.com/",
};

type Regexes = {
  alibabaAccountId: RegExp;
  alibabaRamArn: RegExp;
  amazonSqsUrl: RegExp;
  amazonSqsUrlChina: RegExp;
  awsAccountId: RegExp;
  awsIamKeyArn: RegExp;
  awsIamKeyArnChina: RegExp;
  awsIamKeyArnGov: RegExp;
  awsIamRoleArn: RegExp;
  awsIamRoleArnChina: RegExp;
  awsIamRoleArnGov: RegExp;
  azureSasUrl: RegExp;
  azureSasUrlEndTs: RegExp;
  azureSasUrlSig: RegExp;
  azureSasUrlStartTs: RegExp;
  domainName: RegExp;
  email: RegExp;
  gcpProjectName: RegExp;
  guid: RegExp;
  integrationName: RegExp;
  ibmAccountId: RegExp;
  memberRoleName: RegExp;
  awsAccountName: RegExp;
  oracleGroupName: RegExp;
  oraclePolicyName: RegExp;
  oracleTenantOCID: RegExp;
  oracleUserName: RegExp;
  oracleUserOCID: RegExp;
  passwordStrength: RegExp;
  serviceAccountName: RegExp;
  slackPrefix: RegExp;
  positiveNumbers: RegExp;
  awsSNSTopicArn: RegExp;
  beginsWithAlphaNumeric: RegExp;
  from1To250: RegExp;
  noSlash: RegExp;
  noSpecialChars: RegExp;
  oktaDomain: RegExp;
  onlySpacesInInputField: RegExp;
  parentheticalExpression: RegExp;
  amazonS3Url: RegExp;
  snowflakeURL: RegExp;
  url: RegExp;
  symbol: RegExp;
  hasNumber: RegExp;
  hasUppercase: RegExp;
  hasLowercase: RegExp;
  awsEksArn: RegExp;
  azureAksSubscription: RegExp;
  gcpGksSelfLink: RegExp;
  gcpContainerApiPrefix: RegExp;
  phoneNumber: RegExp;
};
const regex: Record<string, RegExp> = {};

for (const [name, regexString] of Object.entries(regexesMaintainedByBE)) {
  regex[name] = new RegExp(regexString);
}

/*
 * Add new regex here. These regex are not maintained by the backend.
 */
regex.phoneNumber = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
regex.positiveNumbers = /^[0-9]*[1-9][0-9]*$/;
// eslint-disable-next-line max-len
regex.awsSNSTopicArn =
  /^arn:aws((-cn)|(-us-gov))*:sns:(us(-gov)?|ap|ca|cn|eu|sa)-(central|(north|south)?(east|west)?)-\d:\d{12}:?[a-zA-Z_0-9+=,.@-]+$/;
regex.beginsWithAlphaNumeric = /^[A-Za-z0-9]/;
regex.from1To250 = /^([1-9]|[0-9][0-9]|[1][0-9][0-9]|[2][0-4][0-9]|250)$/;
(regex.noSlash = /^[^\\/]+$/),
  (regex.noSpecialChars = /^[A-Za-z0-9_\-.\s.,[\]]+$/);
regex.oktaDomain = /^[^\s./]+(\.[^\s./]+)+$/;
regex.onlySpacesInInputField = /.*[^ ].*/;
regex.parentheticalExpression = /([^(]+)\(([^)]+)\)(.*)/;
regex.amazonS3Url = /^s3:\/\/.+\/$/;
regex.snowflakeURL = /^([a-z0-9.-]+).snowflakecomputing.com$/;
regex.url = /^(https?):\/\/[^\s/$.?#].[^\s]*\S+$/;
regex.ibmAccountId = /^[a-zA-Z0-9\\-]+$/;
regex.memberRoleName = /[-_a-zA-Z0-9]+/;
regex.awsAccountName = /^[a-zA-Z0-9 _-]+$/;
regex.awsEksArn =
  /^arn:aws:eks:[a-z0-9\\-]+:[0-9]+:cluster\/[a-zA-Z0-9\-\\_]+$/;
regex.azureAksSubscription =
  /\/subscriptions\/[a-fA-F0-9-]+\/resourcegroups\/[a-zA-Z0-9-().]{0,89}[a-zA-Z0-9-()]\/providers\/Microsoft[.]ContainerService\/managedClusters\/([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9_-]{0,61}[a-zA-Z0-9])/i;
regex.gcpGksSelfLink =
  /^(https:\/\/container\.googleapis\.com\/v1\/|\/)?projects\/[a-zA-Z0-9-]+\/(zones|locations)\/[a-zA-Z0-9-]+\/clusters\/[a-zA-Z0-9_-]+$/i;
regex.gcpContainerApiPrefix =
  /^(https:\/\/container\.googleapis\.com\/v1\/|\/)/;
/**
 * Copied from previously used password-validator library
 *
 * https://github.com/tarunbatra/password-validator/blob/master/src/constants.js#L9
 */
regex.symbol = new RegExp(
  "([`~\\!@#\\$%\\^\\&\\*\\(\\)\\-_\\=\\+\\[\\{\\}\\]\\\\|;:\\'\",<.>\\/\\?].*)",
);
regex.hasNumber = /(\d+)/;
regex.hasUppercase = /[A-Z]/;
regex.hasLowercase = /[a-z]/;

export const regexes = regex as Regexes;
