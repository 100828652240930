import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { DataInventoryFilterSchema } from "requests";
import { z } from "zod";
import { inventoryLayoutRoute } from "../../inventoryLayoutRoute";
import { BreadcrumbsSchema, TableLevelEnumSchema } from "../types";

export const ServiceLayout = lazy(() => import("./ServiceLayout"));

export const DataInventoryServiceSearchSchema = z.object({
  filters: DataInventoryFilterSchema.catch({}).optional(),
  tableLevel: TableLevelEnumSchema.optional(),
  breadcrumbs: BreadcrumbsSchema,
});

export const serviceLayoutRoute = new Route({
  getParentRoute: () => inventoryLayoutRoute,
  path: "dataService/$provider",
  component: ServiceLayout,
  validateSearch: DataInventoryServiceSearchSchema,
});
