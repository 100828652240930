import {
  type QueryClient,
  type QueryKey,
  type Updater,
} from "@tanstack/react-query";
import {
  defineMessages,
  type IntlShape,
  type MessageDescriptor,
} from "react-intl";

import { type ApiError } from "requests";
import { type useToastActions } from "stores";
import { noop } from "utils";

export type ValueType = { id?: string; name?: string };

export type MutateDataProps<T extends ValueType> = {
  intl: IntlShape;
  queryClient: QueryClient;
  toast: ReturnType<typeof useToastActions>["toast"];
  dataCacheKey: QueryKey;
  cacheUpdaterFn?: Updater<T[] | undefined, T[] | undefined>;
  record: Partial<T>;
  successMessage: string;
  defaultErrorMessage?: string;
  knownErrorMessages?: ReturnType<typeof defineMessages>;
  /**
   *
   * @param data The return type is set to unknown because mutateData is used in various contexts, and the type of data it returns can vary.
   * @returns Returning false will prevent the default success message from displaying
   */
  onSuccess?: (data?: unknown) => boolean | void;
  /**
   *
   * @returns Returning false will prevent any error messages from displaying
   */
  onError?: (error?: ApiError) => boolean | void;
};

export async function mutateData<T extends ValueType>(
  updateFn: () => Promise<unknown>,
  {
    intl,
    queryClient,
    toast,
    dataCacheKey,
    cacheUpdaterFn,
    record,
    successMessage,
    defaultErrorMessage,
    knownErrorMessages,
    onSuccess = noop,
    onError = noop,
  }: MutateDataProps<T>,
) {
  queryClient.cancelQueries({ queryKey: dataCacheKey });
  const previous = queryClient.getQueryData(dataCacheKey);

  try {
    queryClient.setQueryData(dataCacheKey, cacheUpdaterFn);
    const response = await updateFn();

    queryClient.invalidateQueries({ queryKey: dataCacheKey });

    const showMessage = onSuccess(response);
    if (typeof showMessage === "boolean" && showMessage === false) return;

    toast(successMessage, { appearance: "success" });
  } catch (error) {
    type ErrorMessageType = keyof typeof knownErrorMessages;
    const showMessage = onError(error as ApiError<ErrorMessageType>);
    if (typeof showMessage === "boolean" && showMessage === false) return;

    const { headers } = error as ApiError<ErrorMessageType>;
    const statusHeader = headers?.["x-redlock-status"]?.[0];

    const message: MessageDescriptor | string =
      knownErrorMessages?.[statusHeader?.i18nKey] ??
      defaultErrorMessage ??
      defineMessages({
        defaultError: {
          defaultMessage:
            "The record could not be saved due to an unknown error. Please contact your Prisma Cloud support team.",
          id: "G3kj4P",

          description: "An unknown error occurred during save",
        },
      }).defaultError;
    const subject = statusHeader?.subject;

    queryClient.setQueryData(dataCacheKey, previous);
    const errorToastMessage =
      typeof message === "string"
        ? message
        : intl.formatMessage(message, {
            name: record.name,
            subject,
          });
    toast(errorToastMessage, { appearance: "error" });
  }
}
