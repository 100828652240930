import { FormattedMessage, useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

import { useEnabledFeatures } from "./useEnabledFeatures";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../components";

export const complianceFeatures: Features[] = [
  {
    featureName: "complianceStandards",
    operations: {
      CREATE: false,
      READ: false,
      UPDATE: false,
      DELETE: false,
    },
  },
  {
    featureName: "complianceOverview",
    operations: {
      READ: false,
    },
  },
  {
    featureName: "complianceReports",
    operations: {
      CREATE: false,
      READ: false,
      UPDATE: false,
      DELETE: false,
    },
  },
];

export function ComplianceSection() {
  const intl = useIntl();
  const { isLoading, isFeatureEnabled } = useEnabledFeatures();
  const availableFeatures = complianceFeatures.filter((feature) =>
    isFeatureEnabled(feature.featureName),
  );

  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(availableFeatures);

  if (isLoading) return null;

  if (!availableFeatures.length) {
    return null;
  }

  return (
    <Accordion allowNoneOpen allowMultipleOpen defaultOpenPanels={[]}>
      <AccordionPanel
        title={intl.formatMessage({
          defaultMessage: "Compliance",
          id: "Nc6SEd",
          description: "Assign permissions section header",
        })}
        noPadding
        testId="permission-accordion"
      >
        <div className="divide-y divide-primary">
          <Row
            label={intl.formatMessage({
              defaultMessage: "Select All",
              id: "aYkfGO",
              description: "Select all checkbox row",
            })}
            addClassName="border-b-2"
            read={
              <SelectAllCheckbox
                status={selectionStatus.read}
                onChange={() => {
                  onClickSelectAll("READ");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select view for all compliance rows"
                  id="hozOnW"
                  description="Permission group compliance section header"
                />
              </SelectAllCheckbox>
            }
            create={
              <SelectAllCheckbox
                status={selectionStatus.create}
                onChange={() => {
                  onClickSelectAll("CREATE");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select create for all compliance rows"
                  id="zXU5P5"
                  description="Permission group compliance section header"
                />
              </SelectAllCheckbox>
            }
            update={
              <SelectAllCheckbox
                status={selectionStatus.update}
                onChange={() => {
                  onClickSelectAll("UPDATE");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select update for all compliance rows"
                  id="2grgQD"
                  description="Permission group compliance section header"
                />
              </SelectAllCheckbox>
            }
            del={
              <SelectAllCheckbox
                status={selectionStatus.delete}
                onChange={() => {
                  onClickSelectAll("DELETE");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select delete for all compliance rows"
                  id="uDTWio"
                  description="Permission group compliance section header"
                />
              </SelectAllCheckbox>
            }
          />

          {availableFeatures.map((feature) => {
            return <CheckboxRow key={feature.featureName} feature={feature} />;
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}
