import { useIntl } from "react-intl";
import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const PrismaLogoIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 20 20"
      aria-label={intl.formatMessage({
        defaultMessage: "Prisma Cloud Logo",
        id: "y2is6a",

        description: "label for prisma logo",
      })}
    >
      <path d="m12.1261 13.0149v-8.33493l4.4058 8.33493z" />
      <path d="m12.1263 4.6798-4.40581 8.335h4.40581v5.9852l-9.1263-9 9.1263-9z" />
    </SvgIcon>
  );
};
