import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { UserNameSchema } from "./schemas";

export const userNameKeys = {
  all: [{ scope: "userName" }] as const,
  userNames: (params?: { fetchSakUser?: "true" | "false" }) =>
    [{ ...userNameKeys.all[0], params }] as const,
} as const;

export const getUserNames = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<ReturnType<(typeof userNameKeys)["userNames"]>>) => {
  return jsonApi({
    path: "user/name",
    params,
    requestSchema: z.void(),
    responseSchema: z.array(UserNameSchema),
  });
};
