import { createContext, useContext, type Dispatch, type FC } from "react";

interface GlobalModalContextProps {
  dispatch: Dispatch<GlobalModalActions>;
}

export type RenderModalProps<T = Record<string, unknown>> = {
  closeModal: () => void;
  isOpen: boolean;
} & T;

const invariantViolation = () => {
  throw new Error(
    "Attempted to call useModal outside of modal context. Make sure your app is rendered inside ModalProvider.",
  );
};

export const GlobalModalContext = createContext<GlobalModalContextProps>({
  dispatch: invariantViolation,
});

export function useGlobalModalContext(): GlobalModalContextProps {
  return useContext(GlobalModalContext);
}

type GlobalModalActions = {
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RenderModal: FC<RenderModalProps<any>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: any;
  };
  type: "openModal" | "closeModal";
};

export type IndividualModalState = {
  isOpen: boolean;
  props: Record<string, unknown>;
  RenderModal: FC<RenderModalProps>;
};

export type GlobalModalState = Record<string, IndividualModalState>;

export function reducer(
  state: GlobalModalState,
  action: GlobalModalActions,
): GlobalModalState {
  const { payload, type } = action;
  const id = payload.RenderModal.name;
  switch (type) {
    case "openModal":
      return {
        ...state,
        [id]: {
          isOpen: true,
          props: payload.props || {},
          RenderModal: payload.RenderModal,
        },
      };

    case "closeModal": {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _omitted, ...nextState } = state;
      return nextState;
    }
  }
}
