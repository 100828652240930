import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { CheckIcon, DownloadIcon, ExternalLinkIcon, LoadingIcon } from "icons";
import { useMemo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Link } from "ui";

type CFTButtonProps = {
  dataSelector: string;
  disabled?: boolean;
  external: boolean;
  isLoading: boolean;
  isFileDownloaded: boolean;
  onButtonClick: UseMutateAsyncFunction<void, unknown, void, unknown>;
  onStepClick: () => void;
  title: ReactNode;
};

export default function CFTButton({
  dataSelector,
  disabled = false,
  external,
  isLoading,
  isFileDownloaded,
  onButtonClick,
  onStepClick,
  title,
}: CFTButtonProps) {
  const icon = useMemo(
    () =>
      isLoading ? (
        <LoadingIcon />
      ) : isFileDownloaded ? (
        <CheckIcon />
      ) : external ? null : (
        <DownloadIcon />
      ),
    [external, isLoading, isFileDownloaded],
  );

  return (
    <section className="space-y-1">
      <Button
        addClassName="w-full"
        data-selector={dataSelector}
        disabled={disabled}
        appearance="primary"
        icon={icon}
        onClick={() => onButtonClick()}
      >
        <span className="ml-1 flex items-center text-sm">
          {title}
          {external && (
            <span className="ml-2">
              <ExternalLinkIcon size="sm" />
            </span>
          )}
        </span>
      </Button>
      <Steps onClick={onStepClick} />
    </section>
  );
}

function Steps({ onClick }: { onClick: () => void }) {
  return (
    <div className="text-2xs">
      <FormattedMessage
        defaultMessage="<a>View Steps</a> for more information"
        id="AO0igu"
        description="Open help section"
        values={{
          a: (chunks) => (
            <Link
              data-selector="config-helper-open-sidecar-instructions"
              size="sm"
              onClick={onClick}
            >
              {chunks}
            </Link>
          ),
        }}
      />
    </div>
  );
}
