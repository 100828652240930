import { Input } from "form";
import { useIntl } from "react-intl";
import AccountName from "../../../../components/AccountName";
import { type AccountDetailsProps } from "../AccountDetails";
import JsonFileUpload from "../JsonFileUpload";
import SelectAccountGroups from "../SelectAccountGroups";
import SelectMonitoredProjects from "../SelectMonitoredProjects";

type GcpWorkspaceConfigurationProps = AccountDetailsProps & {
  onAccountNameBlur: (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => void;
};

export default function GcpWorkspaceConfiguration({
  onAccountNameBlur,
  isEdit,
  isGcpWifEnabled = true, //TODO: remove true once GcpWif is supported from BE
  setAuthenticationType,
  closeModal,
  isFederatedGcpWifEnabled,
}: GcpWorkspaceConfigurationProps) {
  const intl = useIntl();
  return (
    <section className="space-y-2">
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <Input
              name="projectId"
              label={intl.formatMessage({
                defaultMessage: "Domain Name",
                id: "ekuOgy",
                description: "Label for Domain name input",
              })}
            />
          </div>
          <div className="w-1/2">
            <AccountName onBlur={onAccountNameBlur} />
          </div>
        </div>

        <JsonFileUpload
          isEdit={isEdit}
          isGcpWifEnabled={isGcpWifEnabled}
          isGcpWorkspace={true}
          setAuthenticationType={setAuthenticationType}
          isFederatedGcpWifEnabled={isFederatedGcpWifEnabled}
        />

        <SelectMonitoredProjects closeModal={closeModal} />

        <SelectAccountGroups isEdit={isEdit} />
      </div>
    </section>
  );
}
