export function ipv4ToNumber(ip: string) {
  return ip.split(".").reduce((int, value) => int * 256 + +value, 0);
}

export function ipv6ToNumber(ip: string) {
  let preparedIp = ip;

  // ipv6 can abbreviated with double colons so handle that case
  if (ip.includes("::")) {
    const colonCount = (ip.match(/:/g) || []).length;
    preparedIp = preparedIp.replace("::", ":".repeat(9 - colonCount));
  }

  return preparedIp
    .split(":")
    .map((str) => Number("0x" + (str || "0")))
    .reduce((int, value) => {
      return BigInt(int) * BigInt(65536) + BigInt(+value);
    }, BigInt(0));
}
