import { type OperationName } from "prisma";
import { FormattedMessage } from "react-intl";
import { type PermissionGroupData } from "requests";
import { CardBody, Tag } from "ui";
import { featureNameMap, operationNames } from "./messages";

type PermissionGroupCardBodyProps = {
  features: PermissionGroupData["features"];
};

export function PermissionGroupCardBody({
  features,
}: PermissionGroupCardBodyProps) {
  return (
    <CardBody addClassName="divide-y divide-primary p-0">
      {features.map(({ featureName, operations }) => {
        return (
          <div
            className="flex space-x-2 px-4 py-2 text-sm"
            key={featureName}
            data-testid={`${featureName}-operations`}
          >
            <span className="w-1/3">
              <FormattedMessage {...featureNameMap[featureName]} />
            </span>

            {operations &&
              Object.keys(operations)
                // @ts-expect-error This operation will always be either READ, UPDATE, CREATE, DELETE
                // which is the type I have coerced here. Yet, TS still complains about operation not being a proper
                // index type for operations.
                .filter((operation) => operations[operation as OperationName])
                .map((operation) => {
                  return (
                    <Tag key={operation} appearance="gray" size="md" readOnly>
                      <FormattedMessage
                        {...operationNames[operation as OperationName]}
                      />
                    </Tag>
                  );
                })}
          </div>
        );
      })}
    </CardBody>
  );
}
