import { parse, type ParsedQs } from "qs";

type LocationProps = Location;

/**
 * @deprecated use TanStack useSearch
 */
export function hashParams(
  location: LocationProps = window.location,
): ParsedQs {
  return parse(location.hash.replace("#", ""), { arrayLimit: 10000 });
}
