import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const ClockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 16 17"}>
      <path d="M8.78003 2.02997V1.01001C8.78003 0.72998 8.56 0.5 8.28003 0.5H7.51001C7.22998 0.5 7 0.72998 7 1.01001V2.06C7.32001 2.01996 7.65997 2 8 2C8.26001 2 8.52002 2.01001 8.78003 2.02997Z" />
      <path d="M15.0098 9.23999V8.77997C15.0098 5.09998 12.47 2.37 8.78003 2.02997L8.77979 7.76997L12.2698 10.01C12.3898 10.08 12.4797 10.18 12.5298 10.31C12.5698 10.44 12.5598 10.57 12.4998 10.7L12.1498 11.38C12.0698 11.56 11.8898 11.66 11.6998 11.66C11.6197 11.66 11.5397 11.65 11.4697 11.61L7.27979 9.01001C7.10975 8.93 6.99976 8.75 6.99976 8.56L7 2.06C3.42999 2.47998 0.989746 5.17999 0.989746 8.77997V9.23999C0.989746 13.16 3.88977 16.01 7.99976 16.01C12.1097 16.01 15.0098 13.16 15.0098 9.23999Z" />
    </SvgIcon>
  );
};
